export const extractAccessRightObject = (
  accessObj: any,
  parentName: string,
  childName?: string
) => {
  try {
  
    if (childName) {
      const tempParentAccess = accessObj?.access?.AccessRights.filter(
        (item: any) => item.name == parentName
      );
      const tempAccess = tempParentAccess[0]?.child.filter(
        (item: any) => item.name == childName
      );
      const access = tempAccess[0];
      return access;
    } else {
      const tempAccess = accessObj?.access?.AccessRights.filter(
        (item: any) => item.name == parentName
      );

      const access = tempAccess[0];
      return access;
    }
  } catch (error) {}
};
