import React, { useState } from "react";
import "./index.css";
import { Button, Space, TablePaginationConfig } from "antd";

import { GiRegeneration } from "react-icons/gi";
import { useSelector } from "react-redux";

import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { TbListDetails } from "react-icons/tb";
import GenrateView from "./GenrateView";
import SummaryView from "./SummaryView";
import DetailView from "./DetailView";

interface GenratePaySlipListProps {
  searchValue?: string;
}

const GenratePaySlip: React.FC<GenratePaySlipListProps> = ({ searchValue }) => {
  const [viewType, setViewType] = useState("Genrate");
  const [payrollId, setPayrollId] = useState<string>();
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Generate Pay");
  const create = access?.createAccess;
  const read = access?.readAccess;

  return (
    <>
      <div className="genrate-pay-slip-container table-content-search">
        <div className="slary-genrate-view-top">
          <Space>
            {create && (
              <Button
                className="global-primary-btn"
                icon={<GiRegeneration />}
                onClick={() => setViewType("Genrate")}
              >
                Generate
              </Button>
            )}

            {read && (
              <Button
                className="global-default-btn"
                icon={<TbListDetails />}
                onClick={() => setViewType("Detail")}
              >
                Current Month Overview
              </Button>
            )}
          </Space>
        </div>

        {viewType == "Genrate" ? (
          <GenrateView
            searchValue={searchValue}
            handleViewChange={(type: string, id: string) => {
              setViewType(type);
              setPayrollId(id); 
            }}
          />
        ) : viewType == "Detail" ? (
          <DetailView searchValue={searchValue} />
        ) : (
          <SummaryView searchValue={searchValue} payrollId={payrollId} />
        )}
      </div>
    </>
  );
};

export default GenratePaySlip;
