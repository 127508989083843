import { Col, Empty, Row, Tour } from "antd";
import React, { useRef, useEffect, useState } from "react";
import EmployeesAttendenceCard from "../EmployeeAttendenceCards";

import LeaveBalancesAndHistory from "../LeaveBalancesAndHistory";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../../redux/EmployeeProfileSlice";
import PerformanceMetrics from "../PerformanceMetrics";
import MyCheckinCheckoutOnDashBoard from "../../../DashboardAdminandHr/CheckinCheckOut";
import type { TourProps } from "antd";
import workhoursImage from "../../../../assets/images/employee-attendace-total-working.png";
import productiveImage from "../../../../assets/images/employee-attendace-productive.png";
import overtimeImage from "../../../../assets/images/employee-attendace-overtime.png";
import shortHoursImage from "../../../../assets/images/employee-attendace-shorthours.png";
import "./index.css";
import { updatedEmployeeTourStatusApi } from "../../../../services/Global";
import EmployeeChartMenu from "../../../../common/ChartsMenu/EmployeeMenu/index";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";

const EmployeePortalDashboardLayout: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const [isBirthdayModalVisible, setIsBirthdayModalVisible] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Dashboard");

  const read = access?.readAccess;

  useEffect(() => {
    setOpen(
      !loggedInEmployee?.logedInemployee?.tour?.employee_dashboard_tour || false
    );
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");
    const formattedCurrentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const hasModalBeenShownToday =
      localStorage.getItem("birthdayModalShownDate") === formattedCurrentDate;

    if (employeeBirthday === formattedCurrentDate && !hasModalBeenShownToday) {
      setIsBirthdayModalVisible(true);
      localStorage.setItem("birthdayModalShownDate", formattedCurrentDate);
    }
    return () => {};
  }, []);

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);

  const employeeBirthday =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.DOB;

  const steps: TourProps["steps"] = [
    {
      title: (
        <>
          <span className="tour-step-3-heading">Check-in and Check-out</span>
        </>
      ),
      description: (
        <span className="tour-step-2-description">
          This component allows employees to easily check in and check out.
          Click the button to begin your workday or end it seamlessly.
        </span>
      ),
      target: () => ref1.current!,
    },
    {
      title: (
        <>
          <span className="tour-step-3-heading">
            Attendance Insights Section
          </span>
        </>
      ),
      description: (
        <>
          <span className="tour-step-2-description">
            Gain valuable insights into your attendance patterns. Monitor your
            punctuality and attendance history to stay on top of your work
            schedule.
          </span>
        </>
      ),
      target: () => ref2.current!,
    },
    {
      title: (
        <>
          <span className="tour-step-3-heading">Time Inspection</span>
        </>
      ),
      description: (
        <>
          <Row>
            <Col span={24}>
              <Row gutter={12}>
                <Col span={6}>
                  <img
                    className="tour-step-3-image"
                    alt="tour.png"
                    src={workhoursImage}
                  />
                </Col>
                <Col span={6}>
                  <img
                    className="tour-step-3-image"
                    alt="tour.png"
                    src={productiveImage}
                  />
                </Col>
                <Col span={6}>
                  <img
                    className="tour-step-3-image"
                    alt="tour.png"
                    src={overtimeImage}
                  />
                </Col>
                <Col span={6}>
                  <img
                    className="tour-step-3-image"
                    alt="tour.png"
                    src={shortHoursImage}
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={6} className="tour-step-3-content-container">
                  <span className="tour-step-3-content">
                    This will indicate your total hours.
                  </span>
                </Col>
                <Col span={6} className="tour-step-3-content-container">
                  <span className="tour-step-3-content">
                    This will indicate how much hours you have worked.
                  </span>
                </Col>
                <Col span={6} className="tour-step-3-content-container">
                  <span className="tour-step-3-content">
                    This will indicate total hours of overtime.
                  </span>
                </Col>
                <Col span={6} className="tour-step-3-content-container">
                  <span className="tour-step-3-content">
                    This will indicate total time, you did'nt work.
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ),
      target: () => ref3.current!,
    },
    {
      title: (
        <>
          <span className="tour-step-3-heading">
            Leave Balances and History
          </span>
        </>
      ),
      description: (
        <>
          <span className="tour-step-2-description">
            Gain valuable insights into your attendance patterns. Monitor your
            punctuality and attendance history to stay on top of your work
            schedule.
          </span>
        </>
      ),
      target: () => ref4.current!,
    },
  ];
  const TourCloas = () => {
    setOpen(false);

    const toureObject = loggedInEmployee?.logedInemployee?.tour;

    if (toureObject) {
      const updatedToureObject = {
        ...toureObject,
        employee_dashboard_tour: true,
      };

      dispatch(
        setlogedInemployee({
          logedInemployee: {
            tour: updatedToureObject,
          },
        })
      );
      updatedEmployeeTourStatusApi(
        loggedInEmployee?.logedInemployee?.employee?.organization_id,
        loggedInEmployee?.logedInemployee?.employeeId,
        "employee_dashboard_tour"
      );
    }
  };

  return (
    <div className="employee-dashboard-layout-container">
      {read ? (
        <>
          <Tour
            open={open}
            onClose={() => TourCloas()}
            steps={steps}
            indicatorsRender={(current, total) => (
              <span>
                {current + 1} / {total}
              </span>
            )}
          />
          <Row
            gutter={[10, 10]}
            className="employee-greetings-attendance-style"
          >
            <Col span={24} ref={ref1}>
              <MyCheckinCheckoutOnDashBoard />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div ref={ref2}>
                <EmployeesAttendenceCard />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} ref={ref3}>
              <EmployeeChartMenu />
            </Col>
          </Row>
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};

export default EmployeePortalDashboardLayout;
