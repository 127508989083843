import { CustomformatDate } from "../../../common/validation";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchMonthlyAttendanceEmployeeApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const formattedFirstDay = CustomformatDate(firstDayOfMonth) ;
    const formattedLastDay = CustomformatDate(lastDayOfMonth) ;

    const query = supabase_client_for_Org_schema(orgid)
      .from("employee_attendance")
      .select(`*`)
      .eq("organization_id", orgid)
      .gte("check_in", formattedFirstDay + "T00:00:00")
      .lte("check_in", formattedLastDay + "T23:59:59");
    if (companyId) {
      query.eq("company_id", companyId);
    }
    if (branchId) {
      query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
