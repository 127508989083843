import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Input,
  Button,
  Col,
  Row,
  Divider,
  Form,
  DatePicker,
  Radio,
  Select,
  Modal,
  Upload,
} from "antd";
import { Country } from "country-state-city";
import "./index.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SetEmploymentTypeModel from "./../SetEmploymentType";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../common/Alert";
import { useForm } from "antd/es/form/Form";
import { setEmailDomain } from "../../../../redux/emailDomain";
import { TriangleLoader } from "../../../common/ReactLoader";
import { PlusOutlined } from "@ant-design/icons";
import d_dp from "../../../assets/images/blankProfile.png";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../services/Global";
import {
  fetchAcessRigtGroupApi,
  fetchDepartmentsApi,
  fetchSubGroupByParentGroup,
  fetchTeamsApi,
  setPersonalDetailsInfoApi,
  setProbationAndInternshipInfoApi,
  uploadEmployeeCreatePicApi,
} from "./services";
import { capitalizeWords } from "../../../common/validation";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface selectedDataType {
  selectedCompany: string;
  selectedBranch: string;
  selectedDepartment: string;
  selectedTeam: string;
}

/**
 * Functional component for displaying personal details using React.
 * Manages state for company, branch, department, and team options.
 * Utilizes refs for timeout handling and dispatch for Redux actions.
 * @returns JSX element for rendering personal details section.
 */

const PersonalDetails: React.FC = () => {
  const [companyOption, setCompanyOption] = useState<any[]>([]);
  const [branchOption, setBranchOption] = useState<any[]>([]);
  const [departmentOption, setDepartmentOption] = useState<any[]>([]);
  const [teamOption, setTeamOption] = useState<any[]>([]);
  const [acessRightOption, setAcessRightOption] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [employmentType, setEmploymentType] = useState<string>("");
  const [employmentTypeData, setEmploymentTypeData] = useState<any>();
  const [showEmployeementTypeModel, setShowEmployeementTypeModel] =
    useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const personal_details = useSelector(selectEmployee);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const activeButtonList = useSelector(selectEmployee);
  const logedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [selectedData, setSelectedData] = useState<selectedDataType>({
    selectedCompany: "",
    selectedBranch: "",
    selectedDepartment: "",
    selectedTeam: "",
  });

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const [parentAccessRightGroupName, setParentAccessRightGroupName] =
    useState<string>("");
  const [subGroupOption, setSubGroupOption] = useState<any[]>([]);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(
    null
  );
  const [form] = useForm();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  /**
   * useEffect hook that fetches company names and sets selected company and branch values
   * based on the logged-in employee's company and branch IDs.
   * Also clears any timeout set before the component unmounts.
   * @returns None
   */
  useEffect(() => {
    if (logedInemployee?.employee.company_id) {
      form.setFieldValue(
        "selectedCompany",
        logedInemployee.employee?.company_id
      );
      setSelectedData((prevData) => ({
        ...prevData,
        selectedCompany: logedInemployee.employee?.company_id,
      }));
    } else {
      fetchCompanyNames();
    }
  }, []);

  useEffect(() => {}, []);
  /**
   * useEffect hook that fetches branches and resets form values based on selected company.
   * @param {Function} fetchBranches - Function to fetch branches.
   * @param {Object} form - Formik form object.
   * @param {string} selectedData.selectedCompany - Selected company value.
   * @param {Function} setAdminAccessRightGroup - Function to set admin access right group.
   * @param {Function} setSubGroupOption - Function to set subgroup options.
   * @returns None
   */
  useEffect(() => {
    if (
      logedInemployee?.employee?.branch_id ||
      logedInemployee?.employee?.company_id
    ) {
      const workEmail = logedInemployee?.employee?.work_email;
      if (workEmail) {
        const getEmailDomain = workEmail.split("@");
        if (getEmailDomain.length > 1) {
          const finalDomain = getEmailDomain[getEmailDomain.length - 1];
          dispatch(setEmailDomain(finalDomain));
        }
      }
      form.setFieldValue(
        "selectedBranch",
        logedInemployee?.employee?.branch_id
      );
      setSelectedData((prevData) => ({
        ...prevData,
        selectedBranch: logedInemployee?.employee?.branch_id,
      }));
    } else if (selectedData.selectedCompany) {
      const company = companyOption?.find(
        (item: any) => item.id == selectedData.selectedCompany
      );
      if (company?.email) {
        const getEmailDomain = company.email.split("@");
        if (getEmailDomain.length > 1) {
          const finalDomain = getEmailDomain[getEmailDomain.length - 1];
          dispatch(setEmailDomain(finalDomain));
        }
      }
      fetchBranches();
    }
  }, [selectedData.selectedCompany]);

  /**
   * useEffect hook that triggers when selectedData.selectedCompany or selectedData.selectedBranch changes.
   * It fetches department data, resets form field values, and updates state variables.
   * @returns None
   */
  useEffect(() => {
    if (selectedData.selectedCompany || selectedData.selectedBranch) {
      featchDepartment();
    }
  }, [selectedData.selectedCompany, selectedData.selectedBranch]);

  /**
   * useEffect hook that fetches teams and sets the selected team field value when the selected company, branch, or department changes.
   * @returns None
   */
  useEffect(() => {
    if (selectedData.selectedDepartment) {
      fetchTeams();
    }
  }, [
    selectedData.selectedCompany,
    selectedData.selectedBranch,
    selectedData.selectedDepartment,
  ]);

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
    setShowUploadButton(false);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values: any) => {
    const normalizedFirstName = capitalizeWords(values.firstName);
    const normalizedSurname = capitalizeWords(values.surname);
    setButtonLoading(true);
    setshowAlert(false);
    setShowLoader(true);
    try {
      const fileRes = await uploadImage(selectedFile);
      const selectedParentGroupName = acessRightOption.find(
        (item) => item.id == values.accessrightgroup
      )?.group;
      setParentAccessRightGroupName(selectedParentGroupName);
      const body = {
        first_name: normalizedFirstName,
        last_name: normalizedSurname,
        full_name: `${normalizedFirstName} ${normalizedSurname}`,
        nationality: values.nationality,
        dob: values.DOB,
        cnic: values.cnic,
        gender: values.gender,
        marital_status: values.maritalStatus,
        organization_id: logedInemployee?.employee?.organization_id,
        status: "Listed",
        avatar_url: fileRes?.data?.publicUrl,
        access_right_group: selectedParentGroupName,
        access_right_group_id: values.accessrightsubgroup
          ? values.accessrightsubgroup
          : values.accessrightgroup,
        sub_access_rights_group_id: values.accessrightsubgroup || null,
        parent_access_right_group_id: values.accessrightgroup || null,
        company_id:
          (logedInemployee?.employee?.company_id
            ? logedInemployee.employee?.company_id
            : values.selectedCompany) || null,
        branch_id:
          (logedInemployee?.employee?.branch_id
            ? logedInemployee?.employee?.branch_id
            : values.selectedBranch) || null,
        department_id: values.selectedDepartment || null,
        team_id: values.selectedTeam || null,
        employment_type: employmentType,
      };

      const Employees_res = await setPersonalDetailsInfoApi(
        body,
        logedInemployee?.employee?.organization_id
      );

      let updatedActiveButtons: any = activeButtonList.Employee
        .Activebutton || [{ key: "Personl_Details", status: "Active" }];
      if (Employees_res.data && Employees_res.data.id) {
        if (
          employmentType == "internship" ||
          employmentType == "probation" ||
          employmentType == "permanent" ||
          employmentType == "contractor" ||
          employmentType == "consultant"
        ) {
          const bodyProbationInternship = {
            company_id:
              (logedInemployee?.employee?.company_id
                ? logedInemployee.employee?.company_id
                : values.selectedCompany) || null,
            branch_id:
              (logedInemployee?.employee?.branch_id
                ? logedInemployee?.employee?.branch_id
                : values.selectedBranch) || null,
            department_id: values.selectedDepartment || null,
            team_id: values.selectedTeam || null,
            organization_id: logedInemployee?.employee?.organization_id,
            employment_type: employmentType,
            duration: employmentTypeData.duration,
            starting_date: employmentTypeData.startDate,
            completion_date: employmentTypeData.completionDate,
            description: employmentTypeData.description,
            employee_id: Employees_res.data.id,
          };
          const res_2 = await setProbationAndInternshipInfoApi(
            bodyProbationInternship
          );
        }
        if (
          !activeButtonList.Employee.Activebutton?.some(
            (button) => button.key == "Contact_Details"
          )
        ) {
          updatedActiveButtons = [
            ...(activeButtonList.Employee.Activebutton
              ? activeButtonList.Employee.Activebutton
              : []),
            {
              key: "Contact_Details",
              status: "Active",
            },
          ];
        }
        dispatch(
          setEmployeeProfileDetails({
            Employee: {
              PersonalDetails: {
                firstname: values.firstName,
                surname: values.surname,
                name: values.firstName + values.surname,
                nationality: values.nationality,
                dob: values.DOB,
                cnic: values.cnic,
                gender: values.gender,
                maritalstatus: values.maritalStatus,
                accessrightgroup: selectedParentGroupName,
                selectedCompanyId: logedInemployee?.employee?.company_id
                  ? logedInemployee?.employee?.company_id
                  : values.selectedCompany,
                selectedBranchId: logedInemployee?.employee?.branch_id
                  ? logedInemployee?.employee?.branch_id
                  : values.selectedBranch,
                selectedDepartmentId: values.selectedDepartment,
                selectedTeamId: values.selectedTeam,
                accessparentGroupId: values.accessrightgroup,
                accesssubGroupId: values.accessrightsubgroup,
              },
              Avatarurl: fileRes?.data?.publicUrl,
              EmployeeId: Employees_res.data.id,
              Activebutton: updatedActiveButtons,
            },
          })
        );
        setshowAlert(true);
        setAlertType("success");
      } else if (Employees_res.error) {
        setshowAlert(true);
        setAlertType("error");
      } else if (Employees_res.status == 404 || Employees_res.status == 400) {
        setshowAlert(true);
        setAlertType("error");
      }
      setShowLoader(false);
      setButtonLoading(false);
    } catch {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  /**
   * Fetches company names asynchronously and sets the company options state with the retrieved data.
   * @returns None
   */
  const fetchCompanyNames = async () => {
    const res = await fetchallCompanyByOrgIdApi(
      logedInemployee?.employee?.organization_id
    );
    if (res?.data) {
      setCompanyOption(res?.data);
      if (!personal_details?.Employee?.PersonalDetails) {
        form.setFieldValue("selectedCompany", res?.data[0]?.id);
        setSelectedData((prevData) => ({
          ...prevData,
          selectedCompany: res?.data[0]?.id,
        }));
      } else {
        setSelectedData((prevData) => ({
          ...prevData,
          selectedCompany:
            personal_details?.Employee?.PersonalDetails?.selectedCompanyId ||
            "",
        }));

        setShowUploadButton(false);
      }
    }
  };
  /**
   * Fetches branches for the selected company and sets the branch options.
   * @returns None
   */

  const fetchBranches = async () => {
    if (selectedData.selectedCompany) {
      const res = await fetchAllBranchesByComapnyIdApi(
        logedInemployee?.employee?.organization_id,
        selectedData.selectedCompany
      );
      if (res?.data) {
        setBranchOption(res?.data);
        if (!personal_details?.Employee?.PersonalDetails) {
          form.setFieldValue("selectedBranch", "");
          form.setFieldValue("selectedDepartment", "");
          form.setFieldValue("selectedTeam", "");
          form.setFieldValue("accessrightgroup", "");
          form.setFieldValue("accessrightsubgroup", "");
          form.setFieldValue("selectedBranch", res?.data[0]?.id);
          setSelectedData((prevData) => ({
            ...prevData,
            selectedBranch: res?.data[0]?.id,
          }));

          setSubGroupOption([]);
        } else {
          setSelectedData((prevData) => ({
            ...prevData,
            selectedBranch:
              personal_details?.Employee?.PersonalDetails?.selectedBranchId ||
              "",
          }));
          fetchSubGroupOnParentGroup(
            personal_details?.Employee?.PersonalDetails?.accessparentGroupId
          );
        }
      }
      const data = {
        organizationId: logedInemployee?.employee?.organization_id,
        companyId: selectedData.selectedCompany,
      };
      const accessrightres = await fetchAcessRigtGroupApi(data);

      if (accessrightres.data) {
        setAcessRightOption(accessrightres.data);
      }
    }
  };
  /**
   * Fetches department data based on the logged-in employee's organization, company, and branch.
   * Updates the department options in the component state.
   * @returns None
   */
  const featchDepartment = async () => {
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData.selectedCompany
        ? selectedData.selectedCompany
        : logedInemployee?.employee?.company_id,
      branchId: selectedData.selectedBranch
        ? selectedData.selectedBranch
        : logedInemployee?.employee?.branch_id,
    };
    const res = await fetchDepartmentsApi(data);
    if (res?.data) {
      setDepartmentOption(res?.data);
      if (!personal_details?.Employee?.PersonalDetails) {
        form.setFieldValue("selectedDepartment", "");
        form.setFieldValue("selectedTeam", "");
        form.setFieldValue("accessrightgroup", "");
        form.setFieldValue("accessrightsubgroup", "");
        setSubGroupOption([]);
        form.setFieldValue("selectedDepartment", res.data[0]?.id);
        setSelectedData((prevData) => ({
          ...prevData,
          selectedDepartment: res?.data[0]?.id,
        }));
      } else {
        setSelectedData((prevData) => ({
          ...prevData,
          selectedDepartment:
            personal_details?.Employee?.PersonalDetails?.selectedDepartmentId ||
            "",
        }));
      }
    }
    const accessrightres = await fetchAcessRigtGroupApi(data);

    if (accessrightres.data) {
      setAcessRightOption(accessrightres.data);
    }
  };
  /**
   * Fetches teams data based on the provided organization, company, branch, and department IDs.
   * Updates the team options state with the fetched data.
   * @returns None
   */
  const fetchTeams = async () => {
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData.selectedCompany
        ? selectedData.selectedCompany
        : logedInemployee?.employee?.company_id,
      branchId: selectedData.selectedBranch
        ? selectedData.selectedBranch
        : logedInemployee?.employee?.branch_id,
      departmentId: selectedData?.selectedDepartment,
    };
    const res = await fetchTeamsApi(data);

    if (res.data) {
      setTeamOption(res?.data);
      if (!personal_details?.Employee?.PersonalDetails) {
        form.setFieldValue("selectedTeam", "");
        form.setFieldValue("selectedTeam", res?.data[0]?.id);
        setSelectedData((prevData) => ({
          ...prevData,
          selectedTeam: res?.data[0]?.id,
        }));
      } else {
        setSelectedData((prevData) => ({
          ...prevData,
          selectedTeam:
            personal_details?.Employee?.PersonalDetails?.selectedTeamId || "",
        }));
      }
    }
  };
  const handleEmploymentTypeData = (data: any) => {
    setShowEmployeementTypeModel(false);
    setEmploymentType(data?.type);
    setEmploymentTypeData(data);
  };
  const fetchSubGroupOnParentGroup = async (value: any) => {
    const res = await fetchSubGroupByParentGroup(
      value,
      logedInemployee?.employee?.organization_id
    );

    if (res?.data) {
      setSubGroupOption(res?.data);
      if (!personal_details?.Employee?.PersonalDetails) {
        form.setFieldValue("accessrightsubgroup", "");
        form.setFieldValue("accessrightsubgroup", res?.data[0]?.id);
      }
    }
  };
  const uploadImage = async (file: File | null) => {
    try {
      if (file) {
        const fileExt = file.name.split(".").pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;
        const res = await uploadEmployeeCreatePicApi(filePath, file);
        return res;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <div>
      <span className="admin-hr-potal-table-headings">Personal Details</span>
      <Divider />
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          firstName:
            personal_details?.Employee.PersonalDetails?.firstname || "",
          surname: personal_details.Employee.PersonalDetails?.surname || "",
          nationality:
            personal_details.Employee.PersonalDetails?.nationality || "",
          cnic: personal_details.Employee.PersonalDetails?.cnic || "",
          DOB: personal_details.Employee.PersonalDetails?.dob
            ? dayjs(
                personal_details.Employee.PersonalDetails?.dob,
                "YYYY-MM-DD"
              )
            : dayjs().subtract(15, "years"),
          gender: personal_details.Employee.PersonalDetails?.gender || "",
          maritalStatus:
            personal_details.Employee.PersonalDetails?.maritalstatus || "",
          selectedCompany:
            personal_details.Employee.PersonalDetails?.selectedCompanyId || "",
          selectedBranch:
            personal_details.Employee.PersonalDetails?.selectedBranchId || "",
          selectedDepartment:
            personal_details.Employee.PersonalDetails?.selectedDepartmentId ||
            "",
          selectedTeam:
            personal_details.Employee.PersonalDetails?.selectedTeamId || "",
          accessrightsubgroup:
            personal_details.Employee?.PersonalDetails?.accesssubGroupId || "",
          accessrightgroup:
            personal_details.Employee?.PersonalDetails?.accessparentGroupId ||
            "",
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col xs={15} sm={15} md={20} lg={20} xl={21} xxl={22}>
            <Upload
              name="orglogo"
              accept=".png,.jpg,.jpeg"
              listType="picture-card"
              maxCount={1}
              multiple={false}
              beforeUpload={(file) => {
                handleFileChange(file);
                return false;
              }}
              onRemove={() => {
                setPreviewImage(personal_details?.Employee?.Avatarurl || d_dp);
                setSelectedFile(null);
                setShowUploadButton(true);
              }}
              defaultFileList={[
                {
                  uid: "-1",
                  name: "test.png",
                  status: "done",
                  url: personal_details?.Employee?.Avatarurl || d_dp,
                },
              ]}
              onPreview={() => setPreviewOpen(true)}
            >
              {showUploadButton && (
                <button className="user-profile-upload-button" type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              )}
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please Enter First Name!" }]}
            >
              <Input size="large" placeholder="Enter First Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Last Name" name="surname">
              <Input size="large" placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Nationality"
              name="nationality"
              rules={[{ required: true, message: "Please Enter Nationality!" }]}
            >
              <Select
                placeholder="Enter Nationality"
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {Country.getAllCountries().map((country) => (
                  <Select.Option key={country.name} value={country.name}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="CNIC/ID"
              name="cnic"
              rules={[
                {
                  required: true,
                  message: "Please enter your CNIC/ID!",
                  pattern: /^[0-9]+$/,
                },
              ]}
            >
              <Input size="large" placeholder="Enter CNIC/ID" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
            <Form.Item
              label="Date of Birth"
              name="DOB"
              rules={[
                { required: true, message: "Please Enter Date of Birth!" },
              ]}
              initialValue={dayjs().subtract(15, "years")}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                defaultValue={dayjs().subtract(15, "years")}
                disabledDate={(current) => {
                  const fifteenYearsAgo = moment().subtract(15, "years");
                  return current && current > fifteenYearsAgo;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please Select Gender!" }]}
            >
              <Radio.Group
                options={[
                  { label: "Female", value: "female" },
                  { label: "Male", value: "male" },
                  { label: "Non Binary", value: "nonbinary" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
            <Form.Item label="Marital Status" name="maritalStatus">
              <Select
                placeholder="Enter The Martial Status"
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                <Select.Option value="single">Single</Select.Option>
                <Select.Option value="married">Married</Select.Option>
                <Select.Option value="widowed">Widowed</Select.Option>
                <Select.Option value="divorced">Divorced</Select.Option>
                <Select.Option value="separated">Separated</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {!logedInemployee?.employee?.company_id && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Select Company"
                name="selectedCompany"
                rules={[
                  {
                    required: true,
                    message: "Please select a company!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  onChange={(value: any) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      selectedCompany: value,
                      selectedBranch: "",
                      selectedDepartment: "",
                      selectedTeam: "",
                    }));
                  }}
                  disabled={logedInemployee?.employee?.company_id}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companyOption.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!logedInemployee?.employee?.company_id && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item label="Select Branch" name="selectedBranch">
                <Select
                  showSearch
                  allowClear
                  onChange={(value: any) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      selectedBranch: value,
                      selectedDepartment: "",
                      selectedTeam: "",
                    }));
                  }}
                  disabled={logedInemployee?.employee.branch_id}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branchOption.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Select Department" name="selectedDepartment">
              <Select
                showSearch
                placeholder="Select a department..."
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={(value) =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    selectedDepartment: value,
                    selectedTeam: "",
                  }))
                }
                disabled={departmentOption.length > 0 ? false : true}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {departmentOption.map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Select Team" name="selectedTeam">
              <Select
                placeholder="Select a team.."
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
                onChange={(value) =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    selectedTeam: value,
                  }))
                }
                disabled={teamOption.length > 0 ? false : true}
              >
                {teamOption.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Access Right's Group"
              name="accessrightgroup"
              rules={[
                {
                  required: true,
                  message: "Please Enter Access Right's Group!",
                },
              ]}
            >
              <Select
                placeholder="Enter The Access Right's Group"
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                showSearch
                onChange={(selectedValue) => {
                  fetchSubGroupOnParentGroup(selectedValue);
                }}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {acessRightOption.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {capitalizeWords(item.group)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Access Right's Sub-Group"
              name="accessrightsubgroup"
            >
              <Select
                placeholder="Enter The Access Right's Sub Group"
                allowClear
                showSearch
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {subGroupOption.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.sub_group_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!personal_details?.Employee.PersonalDetails && (
            <Col span={24} className="save-profile-btn">
              {employmentType ? (
                <Form.Item>
                  <Button
                    className="global-btn-width"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={buttonLoading}
                  >
                    Save progress
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    className="global-btn-width"
                    type="primary"
                    size="large"
                    onClick={() => {
                      setShowEmployeementTypeModel(true);
                    }}
                    id="SetEmploymentTypeOnCreateEmployee"
                    loading={buttonLoading}
                  >
                    Set Employment Type
                  </Button>
                </Form.Item>
              )}
            </Col>
          )}
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      {employmentType != null && showEmployeementTypeModel && (
        <Modal
          title={
            <span className="global-modal-title">Set Employment Types</span>
          }
          onCancel={() => setShowEmployeementTypeModel(false)}
          visible={showEmployeementTypeModel}
          width={600}
          footer={false}
          className="global-modal-main-container"
        >
          <SetEmploymentTypeModel
            setData={(data: any) => handleEmploymentTypeData(data)}
          />
        </Modal>
      )}
      {showLoader && <TriangleLoader />}
      <Modal
        open={previewOpen}
        title={"Image Preview"}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img src={previewImage || d_dp} className="user-update-avatar-image" />
      </Modal>
    </div>
  );
};
export default PersonalDetails;
