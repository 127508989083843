import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
/**
 * Fetches and updates the resignation status in the database.
 * @param {string} status - The new status of the resignation.
 * @param {string} comment - The comment associated with the status change.
 * @param {any} loggedEmpId - The ID of the employee making the status change.
 * @param {any} selectedRecordId - The ID of the selected resignation record.
 * @returns {Promise<any>} A promise that resolves to the updated resignation status.
 */
export const fetchResignationStatus = async (
  status: string,
  comment: string,
  loggedEmpId: any,
  selectedRecordId: any
) => {
  try {
    const res = await supabase
      .from("resignation")
      .update({
        Status: status,
        comment: comment,
        authorizedPersonId: loggedEmpId,
      })
      .match({ id: selectedRecordId.id });
    return res;
  } catch (error: any) {
    return catch_return;
  }
};

/**
 * Fetches resignation data based on the logged-in employee's information.
 * @param {any} loggedInEmp - The information of the logged-in employee.
 * @returns {Promise} A promise that resolves to the resignation data fetched from the database.
 */
export const fetchResignationData = async (loggedInEmp: any) => {
  try {
    let query = supabase
      .from("resignation")
      .select("* , Employees!resignation_employeeId_fkey(full_name, avatarUrl)")
      .eq("organizationId", loggedInEmp);
    if (loggedInEmp) {
      query = query.eq("companyId", loggedInEmp);
    }
    query = query.order("created_at", { ascending: false });
    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Retrieves existing termination records for a given organization ID from the 'resignation' table.
 * @param {number} organizationId - The ID of the organization to retrieve termination records for.
 * @returns {Promise<any>} A promise that resolves to the termination records retrieved from the database.
 */
export const getExistingTerminationRecords = async (
  organizationId: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .select("id, employee_Id")
      .eq("organization_id", organizationId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches a list of employees for the termination form based on the provided organization ID,
 * selected company ID, and selected branch ID.
 * @param {number} organizationId - The ID of the organization.
 * @param {string} selectedCompanyId - The ID of the selected company.
 * @param {string} selectedBranchId - The ID of the selected branch.
 * @returns {Promise<any>} A promise that resolves to the list of employees fetched.
 */
export const fetchEmployeesForTerminationForm = async (
  organizationId: number,
  selectedCompanyId: string,
  selectedBranchId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", organizationId);

    if (selectedCompanyId) {
      query = query.eq("company_id", selectedCompanyId);
    }
    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Handles the submission of termination form data to the "resignation" table in Supabase.
 * @param {any} data - The data to be inserted into the "resignation" table.
 * @returns {Promise<any>} A promise that resolves to the response from the database insertion.
 */
export const onFinishTerminationForm = async (data: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("employee_resignation")
      .insert(data);
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const updateTerminationListStatus = async (
  status: string,
  comment: string,
  id: string,
  selectedRecord: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .update({
        status: status,
        reviwer_comment: comment,
        authorized_person_id: id,
      })
      .match({ id: selectedRecord });

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches termination list data based on the organization ID and company ID.
 * @param {number} organizationId - The ID of the organization.
 * @param {string} companyId - The ID of the company.
 * @returns {Promise<any>} A promise that resolves to the termination list data.
 */
export const fetchTerminationListData = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .select(
        "description,status,authorized_person_id,reviwer_comment,created_at,employee_Id,id,notice_period,no_of_notice_days,reason,start_date,end_date , employee!resignation_employee_Id_fkey(full_name, avatar_url)"
      )
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    query = query.order("created_at", { ascending: false });

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Handles the cancellation of termination data for a record with the given ID.
 * @param {number} recordId - The ID of the record to cancel termination for.
 * @returns {Promise<any>} A promise that resolves with the response from the deletion query.
 */
export const handleCancelTerminationData = async (
  recordId: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .delete()
      .eq("id", recordId);

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};
