import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";

interface SetPolicyWarningModel {
  data: any;
  showModel: boolean;
  setShowModel: () => void;
}

const SetPolicyWarningModel: React.FC<SetPolicyWarningModel> = (props) => {
  const navigate = useNavigate();
  const [ShowModel, setShowModel] = useState<boolean>(false);
  const loggedInEmployee =
    useSelector(selectLogedEmployee).logedInemployee?.employee;
  
  useEffect(() => {
    if (props.showModel === true) {
      setShowModel(true);
    }
  }, [props.data, props.showModel]);
  const handleClick = (link: string) => {
    const role = loggedInEmployee?.access_right_group;
    const type = role === "hr" ? "hr" : "admin";
    navigate(`/${type}/policydocuments`);
  };
  const handelCancel = () => {
    setShowModel(false);
    props.setShowModel();
  };
  return (
    <>
      <Modal
        open={ShowModel}
        onCancel={handelCancel}
        footer={false}
      >
        <div className="set-policy-container">
          <span className="policy-set-heading">Set Policies</span>
          <span className="policy-set-text">
            To enhance system performance and attendance calculation, set these
            configurations directly by clicking the link or accessing the policy
            settings menu.{" "}
          </span>
          <ul style={{marginLeft: "-1rem"}}>
            {props.data?.map((item: any, index: number) => (
              <li key={index} className="policy-set-text-as-link-container">
                <span
                  className="policy-set-text-as-link"
                  onClick={() => handleClick(item.link)}
                >
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default SetPolicyWarningModel;
