import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches employee addresses from the Supabase API based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, and searchInput.
 * @returns {Promise<any>} A promise that resolves to the response from the API call.
 */
export const fetchEmployeeAddressesApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_address")
      .select(`id, present_address_detail, employee(full_name)`)
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchInput) {
      query.ilike("employee.full_name", `%${data.searchInput}%`);
    }
    if (data.filterCity) {
      query.ilike("address_detail.city", `%${data.filterCity}%`);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes an employee's address from the database based on the provided ID.
 * @param {any} id - The ID of the employee whose address is to be deleted.
 * @returns {Promise<any>} A promise that resolves to the result of the deletion operation.
 */
export const deleteEmployeeAddressesByIdApi = async (
  id: any,
  organization_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee_address")
      .delete()
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
/**
 * Fetches all employees without addresses based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, and branchId.
 * @returns {Promise<any>} A promise that resolves to an array of employees without addresses.
 */
export const fetchAllEmployeeWithOutAdresssApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    if (res.data) {
      const EmergencyContactres = await supabase_client_for_Org_schema(
        data.organizationId
      )
        .from("employee_address")
        .select("employee_id");
      const employeeIdsWithSalary = new Set(
        EmergencyContactres?.data?.map((item: any) => item.employee_id)
      );
      const employeeswithoutsalary = res.data
        ?.filter((employee: any) => !employeeIdsWithSalary.has(employee.id))
        .map((employee: any) => ({
          value: employee.id,
          label: employee.full_name,
        }));

      return employeeswithoutsalary;
    } else {
      return [];
    }
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts data into the "Addresses" table in the Supabase database.
 * @param {any} data - The data to be inserted into the table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insetAddressesApi = async (
  data: any,
  organization_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee_address")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Updates the address in the "Addresses" table in the database with the provided data.
 * @param {any} data - The data object containing the updated data and the id of the address to update.
 * @returns {Promise<any>} A promise that resolves to the result of the update operation.
 */
export const updateAddressesApi = async (
  data: any,
  organizationId: any,
  selectedRecordId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .update({
        permanent_address_detail: data.permanent_address_detail,
        present_address_detail: data.present_address_detail,
      })
      .eq("id", selectedRecordId);
    const res = await query;
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchAddressesbyIdApi = async (
  id: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .select(`*, employee!employee_address_employee_id_fkey(full_name)`)
      .eq("id", id)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return error;
  }
};

export const getEmployeeAddressAPI = async (
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .select(`*, employee(full_name)`)
      .eq("id", recordId)
      .maybeSingle();

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getEmployeesPicturesAndPostalCodes = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .select(` present_address_detail, employee(full_name, avatar_url) `)
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;
    return response;
  } catch (error) {
    return error;
  }
};
