export const peopleToHireArray = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "10+",
  "I have an ongoing hiring to fill this role",
];

export const jobTypesArray = [
  "Full-time",
  "Part-time",
  "Temporary",
  "Contract",
  "Internship",
  "Fresher",
];

export const showByArray = ["Fixed hours", "Range", "Maximum", "Minimum"];
export const payArray = ["Exact amount", "Range", "Maximum", "Starting"];
export const hiringTimelineArray = [
  "1 to 3 days",
  "3 to 7 days",
  "1 to 2 weeks",
  "2 to 4 weeks",
  "More than 4 weeks",
];

export const predefinedQuestions = [
  {
    label: "Experience",
    id: 1,
    inputType: "number",
    isFilterable: true,
    answer: [],
  },
  {
    label: "Able to Relocate",
    id: 2,
    isFilterable: true,
    inputType: "radio",
    options: ["yes", "no"],
    answer: [],
  },
  {
    label: "Qualifications",
    inputType: "input",
    id: 3,
    isFilterable: true,
    answer: [],
  },
  {
    label: "Skills",
    id: 4,
    inputType: "select",
    mode: "tags",
    isFilterable: true,
    answer: [],
  },
  {
    label: "Language",
    mode: "tags",
    inputType: "select",
    id: 5,
    isFilterable: true,
    answer: [],
  },
];
