import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const deleteKPIScalesDataApi = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("kpi_scale")
      .delete()
      .eq("id", recordId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const setKPIScalesData = async (data: any, organizationId: string) => {
  try {
    const responses = await Promise.all(
      data.map(async (item: any) => {
        if (item.id) {
          return await supabase_client_for_Org_schema(organizationId)
            .from("kpi_scale")
            .update({
              performance_metrics: item.performance_metrics,
              scale: item.scale,
            })
            .match({ id: item.id });
        } else {
          return await supabase_client_for_Org_schema(organizationId)
            .from("kpi_scale")
            .insert([
              {
                performance_metrics: item.performance_metrics,
                scale: item.scale,
                organization_id: item.organizationId,
                company_id: item.companyId,
                branch_id: item.branchId,
              },
            ]);
        }
      })
    );

    if (responses) {
      checkKpiScalePercentage(data, organizationId);
      return responses;
    }
  } catch (error) {
    return catch_return;
  }
};

export const checkKpiScalePercentage = async (
  data: any,
  organizationId: string
) => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .select("*")
      .eq("company_id", data?.companyId);

    if (data.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getKPIScalesData = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_scale")
      .select("*")
      .eq("company_id", data?.companyId);

    if (data?.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }
    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getBranchesByCompanyId = async (
  value: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", value);

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const editKPIScalesDataApi = async (
  record: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("kpi_scale")
      .update({
        performance_metrics: record.performanceMetrics,
        scale: record.scale,
      })
      .eq("id", record.id);

    return response;
  } catch (error) {
    return catch_return;
  }
};
