import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import {
  Form,
  Input,
  Button,
  Space,
  Checkbox,
  Row,
  Col,
  Select,
  Divider,
  DatePicker,
  Upload,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { allBranchesForCompanyDataApi, allOrgCompaniesDataApi } from "../../Deduction/services";
import { genralPolicyfilteinsertApi, setPolicydoumentData } from "../services";
import { UploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const CreatePolicyDocument = () => {
  const [submissionOptions, setSubmissionOptions] = useState<string[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companies, setCompanies] = useState<any[]>([]);

  const [form] = Form.useForm();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [effectiveUpTo, setEffectiveUpTo] = useState<string | string[]>()
  const [companySelectLoading, setCompanySelectLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [branchSelectLoading, setBranchSelectLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;
  const branchId = logedInemployee?.employee?.branch_id
  const companyId = logedInemployee?.employee?.company_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    getBranches();
  }, [selectedCompany]);



  const getBranches = async (companyId?: number) => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      const companyIdIncoming = form.getFieldValue("company")
        ? form.getFieldValue("company")
        : companyId;
      const { data, error } = await allBranchesForCompanyDataApi(
        companyIdIncoming,
        organizationId
      );

      if (data) {
        setFilteredBranches(data);
        form.setFieldValue("branchId", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };



  const getCompanies = async () => {
    setCompanySelectLoading(true);
    const { data, error } = await allOrgCompaniesDataApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("companyId", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleContentChange = (value: string) => {
    setContent(value);
  };

  const handleChange = (info: any) => {
    setFileList(info.fileList);
  };
  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error('Please select a file to upload.');
      return;
    }
    const file = fileList[0];
    const { name } = file;
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${name}`;
    const res = await genralPolicyfilteinsertApi(fileName, (file as any).originFileObj, organizationId)
    return res;
  };

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const url_res = await handleUpload()
    if (url_res && url_res.data) {
      values.url = url_res.data.publicUrl;
    }
    const body = {
      version_name: values.policyName,
      description: content,
      effective_up_to: effectiveUpTo || values.effective_up_to,
      organization_id: organizationId,
      company_id: selectedCompany || form.getFieldValue("companyId") || companyId || null,
      branch_id: selectedBranch || form.getFieldValue("branchId") || branchId || null,
      attachment: values.url
    };
    const policydocumentres = await setPolicydoumentData(body);

    if (
      policydocumentres.status == 200 ||
      policydocumentres.status == 201 ||
      policydocumentres.status == 203 ||
      policydocumentres.status == 204 ||
      policydocumentres.status == 202
    ) {
      setshowAlert(true);
      setAlertType("success");
      form.resetFields();
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };


  return (
    <div className="global-card-inner-container">
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Policy Name"
              name="policyName"
              rules={[
                {
                  required: true,
                  message: "Enter a policy name",
                },
              ]}
            >
              <Input
                type="text"
                size="large"
                value={title}
                onChange={(e) => handleTitleChange(e.target.value)}
                placeholder="Enter the document title"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Effective up to" name="effective_up_to">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD"
                onChange={(d: any, ds: string | string[]) => { setEffectiveUpTo(ds) }}

              />
            </Form.Item>
          </Col>

          {!companyId && (
            <Col span={24} md={12}>
              <Form.Item
                name={"companyId"}
                label="Select a company"
                rules={[
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ]}
              >
                <Select
                  placeholder="Select a company..."
                  disabled={disabled}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(branchId) && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Select Branch (If any)">
                <Select
                  disabled={disabled}
                  placeholder="Select a Branch"
                  allowClear
                  loading={branchSelectLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="attachment"
              label="Attachment"
              rules={[
                {
                  required: true,
                  message: "Please upload an Attachment.",
                },
              ]}
            >
              <Upload
                accept=".png,.jpg,.jpeg,.zip,.pdf,.doc,.docx,.txt"
                maxCount={1}

                onChange={handleChange}
              >
                <Button
                  className="global-btn-width payroll-tax-rebates-upload-btn"
                  icon={<UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Policies" name="template">
          <ReactQuill
            className="policy-texteditor"
            value={content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            placeholder="Enter text here..."
          />
        </Form.Item>

        <Form.Item>
          <Col
            span={24}
            className="global-modal-bottom-row create-policy-ducument-btn-container"
          >
            <Button
              className= "global-btn-width"
              htmlType="submit"
              type="primary"
              size="large"
              loading={buttonLoading}
            >
              Create
            </Button>
          </Col>
        </Form.Item>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default CreatePolicyDocument;
