import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getBranchesByComapnyId = async (
  companyId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const filterAndGetEmployeesDetails = async (Ids: any): Promise<any> => {
  try {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    let employeeQuery = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", Ids.organizationId);
    if (Ids.companyId) {
      employeeQuery = employeeQuery.eq("company_id", Ids.companyId);
    }

    if (Ids.branchId) {
      employeeQuery = employeeQuery.eq("branch_id", Ids.branchId);
    }

    const employeeResponse = await employeeQuery;

    const employees = employeeResponse.data;

    let kpisQuery = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee_kpi")
      .select("*")
      .eq("organization_id", Ids.organizationId);

    if (Ids.companyId) {
      kpisQuery = kpisQuery.eq("company_id", Ids.companyId);
    }

    const KPIResponse = await kpisQuery;

    const kpiEmployeeIdsForCurrentMonth = KPIResponse?.data
      ?.filter((kpi) => {
        const kpiDate = new Date(kpi.created_at);
        return (
          kpiDate.getFullYear() === currentYear &&
          kpiDate.getMonth() + 1 === currentMonth
        );
      })
      .map((kpi) => kpi.employee_id);

    const filteredEmployees = employees?.filter(
      (employee) => !kpiEmployeeIdsForCurrentMonth?.includes(employee.id)
    );

    return filteredEmployees;
  } catch (error) {
    return catch_return;
  }
};

export const getKpiAttributeNames = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .select("id, name, percentage,branch_id")
      .eq("company_id", data?.companyId);

    if (data.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getKPIScalesDetail = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_scale")
      .select("id, performance_metrics, scale,branch_id")
      .eq("company_id", data?.companyId);

    if (data?.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }
    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const sendEmployeeKPIsData = async (data: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("employee_kpi")
      .insert(data);
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeDataKPIs = async (Ids: any, organizationId: string) => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_kpi")
      .select(
        `employee!employee_kpi_employee_id_fkey(full_name, first_name), *`
      )
      .eq("company_id", Ids.companyId);

    if (Ids.branchId) {
      query = query.eq("branch_id", Ids.branchId);
    }
    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeKPIHistoryData = async (
  employeeId: any,
  organizationId: string
) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_kpi")
      .select("*")
      .eq("employee_id", employeeId);

    return response;
  } catch (error) {
    return catch_return;
  }
};
