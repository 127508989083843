import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { advance_salary_pf_request } from "../../../../common/Constants";
import TextArea from "antd/es/input/TextArea";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  fetchNextRequesteeDataList,
  submitAdvanceRequestData,
} from "../../services";
import dayjs from "dayjs";
import moment from "moment";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface CreateAdvanceRequestPorps {
  createModalCloseHanlder: () => void;
}

const CreateAdvanceRequest: React.FC<CreateAdvanceRequestPorps> = ({
  createModalCloseHanlder,
}) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [selectedRequestType, setSelectedRequestType] = useState<string>("");
  const [selectedNextRequestee, setSelectedNextRequestee] =
    useState<string>("");
  const [nextRequesteeOptions, setNextRequesteeOptions] = useState<any[]>([]);
  const [nextRequesteeLoading, setNextRequesteeLoading] =
    useState<boolean>(true);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const employeeId = loggedInEmployee?.employeeId;
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const { Option } = Select;

  useEffect(() => {
    getNextRequestee();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getNextRequestee = async () => {
    try {
      setNextRequesteeLoading(true);
      const response = await fetchNextRequesteeDataList(
        organizationId,
        companyId,
        branchId
      );
      if (response.data) {
        const filteredData = response.data?.map((item: any) => {
          return { id: item.employee.id, full_name: item.employee.full_name };
        });
        setNextRequesteeOptions(filteredData);
      }
      setNextRequesteeLoading(false);
    } catch (error) {
      setNextRequesteeLoading(false);
    }
  };

  const onFinish = async () => {
    try {
      setBtnLoading(true);
      const body = {
        request_type: form.getFieldValue("request_type"),
        amount: form.getFieldValue("amount") || "",
        start_date: form.getFieldValue("noOfMonths")
          ? dayjs(form.getFieldValue("noOfMonths")[0])?.format("YYYY-MM")
          : null,
        end_date: form.getFieldValue("noOfMonths")
          ? dayjs(form.getFieldValue("noOfMonths")[1])?.format("YYYY-MM")
          : null,
        description: form.getFieldValue("description"),
        organization_id: organizationId,
        company_id: companyId ? companyId : null,
        branch_id: branchId ? branchId : null,
        employee_id: employeeId,
        status: "requested",
        next_requestee: selectedNextRequestee,
        required_date: dayjs(form.getFieldValue("requiredDate")),
      };
      const response = await submitAdvanceRequestData(organizationId, body);
      if (response.status == 201 || response.status == 204) {
        setBtnLoading(false);
        setshowAlert(true);
        setAlertType("success");
        form.resetFields();
        createModalCloseHanlder();
      } else {
        setBtnLoading(false);
        setshowAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      setBtnLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"request_type"}
              label="Select Advance Request Type"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Select
                placeholder="Select Request Type"
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={(value) => setSelectedRequestType(value)}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {advance_salary_pf_request.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {selectedRequestType === "withdrawProvidentFund" ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"pfValue"}
                label="Provident Fund Value Available"
                initialValue={"200000"}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {selectedRequestType === "advanceSalary" ? (
              <Form.Item
                name={"noOfMonths"}
                label="Select Month Period Range"
                rules={[
                  {
                    required: true,
                    message: "Please select",
                  },
                ]}
              >
                <RangePicker
                  picker="month"
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  disabledDate={(current) =>
                    current && current < moment().startOf("month")
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item
                name={"amount"}
                label="Enter Amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter an amount",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter an amount" />
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"requiredDate"}
              label="Required By Date"
              rules={[
                {
                  required: true,
                  message: "Please select a date",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"nextRequestee"}
              label="Next Requestee"
              rules={[
                {
                  required: selectedNextRequestee ? false : true,
                  message: "Please select an option",
                },
              ]}
            >
              <Select
                placeholder="Select an option"
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                loading={nextRequesteeLoading}
                onChange={(value) => setSelectedNextRequestee(value)}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {nextRequesteeOptions?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.full_name}
                  </Option>
                ))}
              </Select>{" "}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"description"}
              label={"Enter Reason"}
              rules={[
                {
                  required: true,
                  message: "Please enter a reason",
                },
              ]}
            >
              <TextArea placeholder="Enter Reason" />
            </Form.Item>
          </Col>
          <Row className="global-modal-bottom-row">
            <Col span={24} className="advance-request-btn">
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                size="large"
              >
                Request
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
      {showAlert && (
        <CustomAlert
          type={alertType}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </div>
  );
};

export default CreateAdvanceRequest;
