import supabase, {
  supabase_client_for_Org_schema,
} from "../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const setAllowanceApi = async (data: any): Promise<any> => {
  try {
    const body = {
      organization_Id: data.organizationId,
      company_id: data.companyId,
      branch_id: data.branchId,
      name: data.allowanceName,
      unit: data.unit,
      questions: data.questions,
      allow_attachment: data.allowAttachment,
      need_approval: data.needApproval,
      add_question: data.addQuestion,
    };

    const employeeres = await supabase_client_for_Org_schema(
      data.organizationId
    )
      .from("allowance")
      .insert(body);

    return employeeres;
  } catch {
    return catch_return;
  }
};

export const fetchAllowanceApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("allowance")
      .select(
        "unit,name,id, company!allowance_company_id_fkey(name) , branch!allowance_branch_id_fkey(name)"
      )
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("name", `%${data.searchQuery}%`);
    }
    const res = await query;
    if (data.searchQuery && res.data && res.data.length > 0) {
      res.data = res.data.filter((item) => item.name);
    }
    return res;
  } catch {
    return catch_return;
  }
};
export const setGradeApi = async (data: any, orgId: string): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(orgId)
      .from("grade")
      .insert(data);
    return res;
  } catch {
    return catch_return;
  }
};
export const updateGradeApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(orgId)
      .from("grade")
      .update(data)
      .eq("id", data.id);
    return res;
  } catch {
    return catch_return;
  }
};
export const fetchGradeApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("grade")
      .select("*")
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_Id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_Id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("name", `%${data.searchQuery}%`);
    }
    const res = await query;
    if (data.searchQuery && res.data && res.data.length > 0) {
      res.data = res.data.filter((item: any) => item.name);
    }
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllowancebyIdApi = async (data: any): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(data.organizationId)
      .from("allowance")
      .select("*")
      .eq("organization_Id", data.organizationId)
      .eq("id", data.id)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const updateAllowanceApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const body = {
      name: data.name,
      unit: data.unit,
      questions: data.questions,
      allow_attachment: data.allow_attachment,
      need_approval: data.need_approval,
      add_question: data.add_question,
    };
    let res = await supabase_client_for_Org_schema(orgId)
      .from("allowance")
      .update(body)
      .eq("id", data.id);
    return res;
  } catch {
    return catch_return;
  }
};

export const setTaxationApi = async (data: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.organization_id)
      .from("taxation")
      .insert(data);

    return res;
  } catch {
    return catch_return;
  }
};

export const deleteTaxationApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(orgId)
      .from("taxation")
      .delete()
      .eq("id", data.id);
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchTaxationbyIdApi = async (data: any): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(data.organizationId)
      .from("taxation")
      .select("*")
      .eq("organization_id", data.organizationId)
      .eq("id", data.id)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const updateTaxationApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const body = {
      taxation_version_name: data.taxation_version_name,
      slabs: data.slabs,
    };
    let res = await supabase_client_for_Org_schema(orgId)
      .from("taxation")
      .update(body)
      .eq("id", data.id);
    return res;
  } catch {
    return catch_return;
  }
};
export const fetchSalaryDetailsbyId = async (
  id: string,
  orgId: string
): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(orgId)
      .from("employee_salary")
      .select(
        "company_id,created_at,revision_history, branch_id, employee_id,payment_type,pf_amount,pf_dedection_method,go_with_percentage,bank_account,currency,base_salary,payroll_id,grade_id, EOBI,is_base_salary_taxable,per_day_salary,provident_fund_value,is_provident_fund_apply_on_base_salary,working_day"
      )
      .eq("id", id)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchSalaryDetailsbyEmployeeID = async (
  employeeId: string | number
): Promise<any> => {
  try {
    let res = await supabase
      .from("Salaries")
      .select("selectedGradeId,id")
      .eq("employeeId", employeeId)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchGradeByIdApi = async (id: string | number): Promise<any> => {
  try {
    let res = await supabase
      .from("Grade")
      .select("allowedOptions")
      .eq("id", id)
      .single();

    return res;
  } catch {
    return catch_return;
  }
};

export const saveToAllowanceAttachmentStorageApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("AllowanceAttachment")
      .upload(data.filePath, data.file);

    return res;
  } catch {
    return catch_return;
  }
};

export const setallowanceClaim = async (data: any): Promise<any> => {
  try {
    const res = await supabase.from("allowanceClaim").insert(data);
    return res;
  } catch {
    return catch_return;
  }
};

export const getCurentMonthClaimAllowanceApi = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const res = await supabase
      .from("allowanceClaim")
      .select(`allowanceId`)
      .eq("employeeId", data.employeeId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());

    return res;
  } catch (error) {
    return catch_return;
  }
};
export const getEmployeeAllowanceHisteryApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase
      .from("allowanceClaim")
      .select(`created_at, allowanceName,amountToClaim ,status`)
      .eq("employeeId", data.employeeId);
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchJoiningdateByIdApi = async (data: any): Promise<any> => {
  try {
    const res = await supabase
      .from("Employees")
      .select(`joiningDate`)
      .eq("id", data.employeeId)
      .eq("status", "active")
      .single();
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setsalaryProveConfigurationApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data[0].organization_Id)
      .from("salary_prove_configuration")
      .insert(data);
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchsalaryProveConfigurationByIdApi = async (
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("salary_prove_configuration")
      .select("*")
      .eq("id", id)
      .single();
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchSalaryProveConfigurationApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_Id)
      .from("salary_prove_configuration")
      .select(
        `id,employee_id,company_id,branch_id ,company(name) , branch(name) , employee(full_name)`
      )
      .eq("organization_Id", data.organization_Id);
    if (data.company_id) {
      query = query.eq("company_id", data.company_id);
    }
    if (data.branch_id) {
      query = query.eq("branch_id", data.branch_id);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getCurentMonthClaimAllowancedetailsByEmployeeIdApi = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const res = await supabase
      .from("allowanceClaim")
      .select(`allowanceId,allowanceName,amountToClaim ,status`)
      .eq("employeeId", data.employeeId)
      .eq("status", "Approved")
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());

    return res;
  } catch (error) {
    return catch_return;
  }
};
export const saveGenratedSalaryApi = async (data: any): Promise<any> => {
  try {
    const res = await supabase.from("GenratedSalary").insert(data);

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchGenratedSalaryApi = async (data: any): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase
      .from("GenratedSalary")
      .select("employeeId")
      .eq("organizationId", data.organizationId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());

    if (data.company_id) {
      query = query.eq("companyId", data.company_id);
    }
    if (data.branch_id) {
      query = query.eq("branchId", data.branch_id);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchGenratedSalaryMonthsbyEmployeeIsApi = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_salary_history")
      .select("id,created_at,net_salary,gross_deduction")
      .eq("organization_id", data.organizationId)
      .eq("employee_id", data.employeeId);
    // .gte("created_at", firstDayOfMonth.toISOString())
    // .lte("created_at", lastDayOfMonth.toISOString());
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchGenratedSalaryByIdApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_salary_history")
      .select(
        `*, employee(full_name,joining_date, department:department_id(name),designation:designation_id(name)) , company(name,location,email),branch(name)`
      )
      .eq("id", data.id)
      .single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getAllowanceToApproveApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("allowance_claim")
      .select(
        `created_at, name,amount_to_claim ,status,employee(full_name,avatar_url),id `
      )
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchSalaryProveConfigurationByEmployeeIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("salary_prove_configuration")
      .select(`id,company(name), branch(name),employee(full_name),employee_id`)
      .eq("organization_Id", data.organizationId)
      .eq("employee_id", data.employeeId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateAllowanceToApproveApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("allowance_claim")
      .update(data.details)
      .eq("id", data.id);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeClaimRequestApi = async (data: any): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase
      .from("overTimeClaim")
      .select(
        `totalHours, date,id,checkedBy,status,
          Employees!overTimeClaim_requestedEmployeeId_fkey(full_name) as requestedEmployee,
        `
      )
      .eq("organizationId", data.organizationId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());
    if (data.companyId) {
      query = query.eq("companyId", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branchId", data.branchId);
    }
    const res = await query;
    if (res.data) {
      const promises = res.data.map(async (item: any) => {
        if (item.checkedBy) {
          let query = await supabase
            .from("Employees")
            .select(`full_name`)
            .eq("organizationId", data.organizationId)
            .eq("id", item.checkedBy)
            .eq("status", "active")
            .single();

          if (query.data) {
            item.checkybydetails = query.data;
          }
        }
        return item;
      });

      const temp_data = await Promise.all(promises);
      res.data = temp_data;
    }
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeApprovedCliamOfcurrentMonthByEmployeeIdApi = async (
  employeeId: string | number
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase
      .from("overTimeClaim")
      .select(`totalHours , date`)
      .eq("requestedEmployeeId", employeeId)
      .eq("status", "Approved")
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchcurrentmonthShortHouresByEmployeeIdApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("id, short_working_hours, date")
      .order("created_at", { ascending: false })
      .eq("employee_id", data.employeeId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString())
      .not("short_working_hours", "is", null)
      .eq("manually_accomodated", false);

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchLatestByCompanyAndBranchIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase
      .from("policyDeduction")
      .select("id, slabs")
      .order("created_at", { ascending: false })
      .eq("organizationId", data.organizationId)
      .limit(1);
    if (data.companyId) {
      query = query.eq("companyId", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branchId", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchOverTimeByCompanyAndBranchIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase
      .from("overtimePolicy")
      .select("id, overtimePayCalculation")
      .order("created_at", { ascending: false })
      .eq("organizationId", data.organizationId)
      .limit(1);
    if (data.companyId) {
      query = query.eq("companyId", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branchId", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeesAPI = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getCompaniesAPI = async (organizationId: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name");
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getBranchesAPI = async (
  organizationId: any,
  companyId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getTaxationVersionAPI = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("taxation")
      .select("id, taxation_version_name");

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;
    return response;
  } catch (error) {
    return error;
  }
};

export const submitDataToDatabase = async (
  organizationId: any,
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .insert(data);

    return response;
  } catch (error) {
    return error;
  }
};

export const updatePayrollGenerationData = async (
  organizationId: any,
  data: any,
  id: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .update(data)
      .eq("id", id);

    return response;
  } catch (error) {
    return error;
  }
};

export const updateLastDispatchData = async (
  organizationId: any,
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .update({ last_dispatched: data.last_dispatched })
      .eq("id", data.id);

    return response;
  } catch (error) {
    return error;
  }
};
export const deleteGenerateViewAaccountData = async (
  organizationId: any,
  id: number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .delete()
      .eq("id", id);

    return response;
  } catch (error) {
    return error;
  }
};

export const createAccountAPI = async (
  organizationId: any,
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company_account_list")
      .insert(data);

    return response;
  } catch (error) {
    return error;
  }
};
export const updateAccountAPI = async (
  organizationId: any,
  data: any,
  id: number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company_account_list")
      .update(data)
      .eq("id", id);

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAccountDataAPI = async (
  organizationId: any,
  id: number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company_account_list")
      .select("*")
      .eq("id", id)
      .single();

    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyAccountLisytAPI = async (
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company_account_list")
      .select("*");

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCompanyAccountAPI = async (
  organizationId: number,
  accountId: number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company_account_list")
      .delete()
      .match({ id: accountId });

    if (response.error) {
      throw new Error(response.error.message);
    }
    return response;
  } catch (error) {
    console.error('Error deleting account:', error);
    return { error };
  }
};

export const submitAdvanceRequestData = async (
  organizationId: any,
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("advances_request")
      .insert(data);

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAdvanceRequestDataForEmployee = async (
  organizationId: string,
  id: string | number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("advances_request")
      .select(
        "*,employee:employee!advances_request_reviewer_id_fkey(full_name),nextRequesteeEmployee:employee!advances_request_next_requestee_fkey(full_name,avatar_url)"
      )
      .eq("employee_id", id);

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAdvanceRequestDataList = async (
  organizationId: string,
  companyId: string,
  branchId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("advances_request")
      .select(
        "*,nextRequesteeEmployee:employee!advances_request_next_requestee_fkey(full_name,avatar_url),employee:employee!advances_request_employee_id_fkey(full_name,avatar_url)"
      );
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return error;
  }
};

export const saveAdvanceRequestDataApprove = async (
  organizationId: string,
  data: any,
  id: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("advances_request")
      .update({
        comments: data.comments,
        reviewer_id: data.reviewer_id,
        status: data.status,
      })
      .eq("id", id);

    const response = await query;

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchNextRequesteeDataList = async (
  organizationId: string,
  companyId: string,
  branchId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("salary_prove_configuration")
      .select(
        "employee!salary_prove_configuration_employee_id_fkey(id,full_name) as employee"
      );
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return error;
  }
};
