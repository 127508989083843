import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";

import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import CustomAlert from "../../../../common/Alert";
import {
  updateEmployeeEmploymentTypebyIdApi,
  updateProbationsandInternshipsbyIdApi,
} from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface ViewEmployeementTypeModelprops {
  closeModel: () => void;
  id: string;
  data: any;
}
/**
 * EditEmploymentTypeModel component is a React functional component that allows users to edit employment type details.
 * @param {ViewEmployeementTypeModelprops} props - The properties passed to the component.
 * @returns JSX element that represents the employment type editing form.
 */
const EditEmploymentTypeModel: React.FC<ViewEmployeementTypeModelprops> = (
  props
) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [form] = Form.useForm();
  const [type, setType] = useState<string>();
  const [startDate, setStartDate] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [completeDate, setCompleteDate] = useState("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * useEffect hook that calculates the completion date based on the start date and duration.
   * If both startDate and duration are provided, it calculates the completion date by adding
   * the duration in months to the start date. It then updates the form field "completionDate"
   * with the new completion date and sets the state variable completeDate to the formatted date.
   * Additionally, it clears any existing timeout when the component unmounts.
   * @param {Date} startDate - The start date of the task.
   * @param {number} duration - The duration of the task in months.
   * @returns None
   */
  useEffect(() => {
    if (startDate && duration) {
      {
        const start = dayjs(startDate, "YYYY-MM-DD");
        const durationInMonths = parseInt(duration, 10);
        const newCompleteDate = start.add(durationInMonths, "month");
        form.setFieldValue(
          "completionDate",
          dayjs(newCompleteDate, "YYYY-MM-DD")
        );
        setCompleteDate(newCompleteDate.format("YYYY-MM-DD"));
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [startDate, duration]);

  /**
   * useEffect hook that updates form field values based on props data when props.id changes.
   * It sets the field values for type, duration, startDate, completionDate, and description.
   * @param {Function} form - the form object to update field values
   * @param {Object} props - the props object containing data to update form fields
   * @returns None
   */
  useEffect(() => {
    form.setFieldValue("type", props.data.employment_type);
    setType(props.data.employment_type);
    if (props.data.duration) {
      form.setFieldValue("duration", props.data.duration);
      setDuration(props.data.duration);
      form.setFieldValue(
        "startDate",
        dayjs(props.data.starting_date, "YYYY-MM-DD")
      );
      setStartDate(props.data.starting_date);
      form.setFieldValue(
        "completionDate",
        dayjs(props.data.completion_date, "YYYY-MM-DD")
      );
    }
    form.setFieldValue("description", props.data.description);
  }, [props.id]);
  /**
   * Updates the start date based on the selected date and its string representation.
   * @param {any} date - The selected date object.
   * @param {any} dateString - The string representation of the selected date.
   * @returns None
   */
  const onChangeStartDate = (date: any, dateString: any) => {
    if (dateString) {
      setStartDate(dateString);
    }
  };
  /**
   * Executes the necessary actions based on the type of employment.
   * Retrieves form field values and constructs an object with relevant information.
   * Updates probation/internship data if the type is "internship" or "probation",
   * otherwise updates employee data if the type is "permanent".
   * @returns None
   */
  const onFinish = () => {
    const values = form.getFieldsValue();
    const bodyProbationInternship = {
      employment_type: values.type,
      duration: values.duration,
      starting_date: values.startDate,
      completion_date: values.completionDate,
      description: values.description,
    };

    if (type == "internship" || type == "probation" || type == "contractor") {
      updateProbationsandInternships(bodyProbationInternship);
    } else if (type == "permanent" || type == "consultant") {
      updateEmployeeData(bodyProbationInternship, type);
    }
  };

  /**
   * Updates the employment type of an employee to "permanent" using the provided data.
   * Calls the API to update the employment type by employee ID and then updates probations and internships if the API call is successful.
   * @param {any} data - The data containing information about the employee.
   * @returns None
   */
  const updateEmployeeData = async (data: any, type: string) => {
    const updateddata = {
      employment_type: type,
    };
    const res = await updateEmployeeEmploymentTypebyIdApi(
      updateddata,
      props.data?.employee_id,
      organizationId
    );
    if (res.status == 201 || res.status == 204) {
      updateProbationsandInternships(data);
    }
  };

  /**
   * Updates the probations and internships based on the provided body data.
   * @param {any} body - The data to update the probations and internships.
   * @returns None
   */
  const updateProbationsandInternships = async (body: any) => {
    try {
      const probationInternshipres =
        await updateProbationsandInternshipsbyIdApi(
          body,
          props.id,
          organizationId
        );
      if (probationInternshipres.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (
        probationInternshipres.status == 201 ||
        probationInternshipres.status == 204
      ) {
        const temp_type =
          props.data.employment_type == "internship" &&
          body.employment_type != "internship"
            ? "internshipEnd"
            : props.data.employment_type == "probation" &&
              body.employment_type != "probation"
            ? "probationEnd"
            : props.data.employment_type == "probation" &&
              body.employment_type == "probation"
            ? "probationDateChange"
            : "internshipDateChange";
        const sendNotificationBody = {
          type: temp_type,
          sender_Id: loggedInEmployee?.employeeId || null,
          receiver_id: props.data.employeeId,
          name: loggedInEmployee?.employee?.first_name,
          avatar: loggedInEmployee?.employee?.avatar_url,
        };
        await pushNotifictionIntoNotificationTable(
          sendNotificationBody,
          organizationId
        );

        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
          props.closeModel();
        }, 3000);
      }
    } catch {}
  };
  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" autoComplete="off" form={form}>
        <Row gutter={16}>
          <Col
            span={
              type == "internship" ||
              type == "probation" ||
              type == "contractor"
                ? 12
                : 24
            }
          >
            <Form.Item
              label="Selecte Employment Type"
              name="type"
              rules={[
                { required: true, message: "Please Selecte Employment Type" },
              ]}
            >
              <Select
                placeholder="Select Employment Type"
                onChange={(value) => setType(value)}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                <Select.Option value="permanent">Permanent</Select.Option>
                <Select.Option value="internship">Internship</Select.Option>
                <Select.Option value="probation">Probation</Select.Option>
                <Select.Option value="contractor">Contractor</Select.Option>
                <Select.Option value="consultant">Consultant</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {(type == "internship" ||
            type == "probation" ||
            type == "contractor") && (
            <Col span={12}>
              <Form.Item label="Duration(In Months)" name="duration">
                <InputNumber
                  size="large"
                  className="global-input-number-width"
                  placeholder="Enter Duration(In Months)"
                  onChange={(e:any) => setDuration(e.target.value)}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {(type == "internship" ||
          type == "probation" ||
          type == "contractor") &&
        duration ? (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    onChange={onChangeStartDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Completion Date" name="completionDate">
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    value={
                      completeDate ? dayjs(completeDate, "YYYY-MM-DD") : null
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    placeholder="Enter Description.........."
                    rows={2}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to Update Details?"
              onConfirm={onFinish}
              okText="Yes"
              cancelText="No"
            >
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};
export default EditEmploymentTypeModel;
