import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import CustomAlert from "../../../../common/Alert";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  deleteKPIScalesDataApi,
  editKPIScalesDataApi,
  getBranchesByCompanyId,
  getKPIScalesData,
} from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import CustomTable from "../../../../common/CustomTable";
import { capitalizeWords } from "../../../../common/validation";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const ListKPIScales = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [scaleNumbers, setScaleNumbers] = useState<number[]>([1, 2, 3, 4, 5]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [refCounter, setRefCounter] = useState<number>(0);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [form] = useForm();
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Performance",
    "KPI Scale"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  const columns = [
    {
      title: "Performance Metric",
      dataIndex: "performance_metrics",
      key: "performance_metrics",
      render: (text: string) => capitalizeWords(text),
    },
    {
      title: "Scale",
      dataIndex: "scale",
      key: "scale",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <>
          {edit && (
            <a onClick={() => toggleOpenModal(record)}>
              <EditOutlined />
            </a>
          )}
          <Space size="middle">
            <Popconfirm
              placement="topLeft"
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDeleteMetric(record)}
            >
              {deletee && (
                <a>
                  <DeleteOutlined />
                </a>
              )}
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const getAvailableScaleNumbers = () => {
    const usedScales = new Set(tableData.map((item: any) => item.scale));
    return scaleNumbers.filter((scale) => !usedScales.has(scale));
  };

  const getData = async () => {
    if (selectedCompany) {
      const data = {
        companyId: selectedCompany,
        branchId: selectedBranch,
      };

      setLoading(true);
      if (selectedCompany) {
        const response = await getKPIScalesData(data, organizationId);

        if (response.data) {
          const sorted_data = response.data.sort(
            (a: any, b: any) => b.scale - a.scale
          );
          if (selectedBranch) {
            setTableData(sorted_data);
          } else {
            const filteredData = sorted_data.filter(
              (item: any) => item.branch_id === null
            );

            setTableData(filteredData);
          }
          setLoading(false);
        } else if (response.error) {
          setLoading(false);
          setTableData([]);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, [selectedCompany, selectedBranch, refCounter]);

  const handleDeleteMetric = async (record: any) => {
    const deleteResponse = await deleteKPIScalesDataApi(
      record.id,
      organizationId
    );
    if (deleteResponse) {
      setshowAlert(true);
      setAlertType("success");
    }
    const updatedTableData = tableData.filter(
      (item: any) => item.performanceMetrics !== record.performance_metrics
    );
    setTableData(updatedTableData);
  };

  const toggleOpenModal = (record: any) => {
    setRecordId(record.id);
    setShowModal(true);
    form.setFieldsValue({
      performanceMetrics: record.performance_metrics,
      scale: record.scale,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = async () => {
    setButtonLoading(true);
    const newEntry = form.getFieldValue("performanceMetrics");
    const isDuplicate = tableData.some(
      (item: any) =>
        item.performanceMetrics === newEntry && item.id !== recordId
    );
    if (isDuplicate) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      const formattedData = {
        id: recordId,
        performanceMetrics: form.getFieldValue("performanceMetrics"),
        scale: form.getFieldValue("scale"),
      };

      const response = await editKPIScalesDataApi(
        formattedData,
        organizationId
      );
      if (response.status) {
        setshowAlert(true);
        setAlertType("success");
        setRefCounter(refCounter + 1);
      } else {
        setshowAlert(true);
        setRefCounter(refCounter + 1);

        setAlertType("error");
      }
      setButtonLoading(false);
    }
    setShowModal(false);
    setButtonLoading(false);
  };

  const getBranches = async (value: any) => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      if (!selectedCompany) {
        setBranches([]);
        setSelectedBranch(null);
        form.setFieldsValue({ branch: undefined });
      } else {
        const { data, error } = await getBranchesByCompanyId(
          form.getFieldValue("company"),
          organizationId
        );

        if (data) {
          setBranches(data);
          form.setFieldsValue({ branch: data[0]?.name });
          setSelectedBranch(data[0]?.id);
        }
      }
    }
  };

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (!branchId) {
      getBranches(selectedCompany);
    } else {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    }
  }, [selectedCompany]);

  const getCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);

    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };

  return (
    <>
      {read ? (
        <>
          <Form
            layout="vertical"
            form={form}
            className="global-kpi-list-header"
          >
            <Row gutter={16} className="row-above-table">
              {!companyId && (
                <Col span={24} md={12}>
                  <Form.Item
                    name={"company"}
                    label="Companies"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company...",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Choose a company"
                      onChange={setSelectedCompany}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companies?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {!branchId && (
                <Col span={24} md={12}>
                  <Form.Item name={"branch"} label="Branches">
                    <Select
                      onChange={setSelectedBranch}
                      placeholder="Select a branch"
                      allowClear
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branches.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
          <CustomTable
            loading={loading}
            columns={columns}
            dataSource={tableData}
          />
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      <Modal
        width={1000}
        open={showModal}
        onCancel={closeModal}
        footer={false}
        title={<span className="global-modal-title">Edit KPI Scales </span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Form layout="vertical" form={form}>
            <Row>
              <Col className="modal-content">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Performance Metric"
                      name={"performanceMetrics"}
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter performance metric..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name={"scale"} label="Scale">
                      <Select
                        placeholder="Select a scale..."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {getAvailableScaleNumbers().map((item: any) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="global-modal-bottom-row kpis-scale-edit-details-modal-btn-row">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleSaveChanges}
                loading={buttonLoading}
                size="large"
              >
                Update
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ListKPIScales;
