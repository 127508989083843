import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";

import moment from "moment";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { handleGetJobDetails } from "../../services";
import EditJobSteps from "../../EditJobJSX/steps";

interface editjob {
  id?: any;
  onCancel: () => void;
  visible: boolean;
}

const EditJobDetails: React.FC<editjob> = ({ id, onCancel, visible }) => {
  const [expectedHoursType, setExpectedHoursType] = useState<string>("");
  const [expectedPayType, setExpectedPayType] = useState<string>("");
  const [jobDescription, setJobDescription] = useState<string>("");
  const [isApplicationDeadline, setIsApplicationDeadline] = useState<
    "yes" | "no"
  >("no");
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);

  const [jobDetails, setJobDetails] = useState<any>(null);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const [jobRequirements, setJobRequirements] = useState<string>("");
  const [date, setDate] = useState<any>(null);
  const [form] = useForm();

  useEffect(() => {
    getJobDetails();
  }, [id]);

  const getJobDetails = async () => {
    const response = await handleGetJobDetails(id, organizationId);

    if (response.data) {
      setJobDetails(response.data);
      setJobRequirements(response?.data.requirements);
      setSelectedQuestions(response?.data?.questions);

      const fetchedData = response.data;

      const fetchedHoursType = fetchedData?.basic_details?.expectedHoursType;

      const fetchedPayType = fetchedData?.basic_details?.payType;

      if (fetchedData?.applicationDeadline) {
        const deadline = moment(fetchedData?.applicationDeadline);

        setDate(deadline);
        form.setFieldsValue({
          deadlineDate: deadline,
        });
      }

      if (fetchedHoursType) {
        setExpectedHoursType(fetchedHoursType);

        if (fetchedHoursType === "Range") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            minPay: fetchedData?.basic_details?.minHours,
            maxHours: fetchedData?.basic_details?.maxHours,
          });
        } else if (fetchedHoursType === "Fixed hours") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            fixedHours: fetchedData?.basic_details?.fixedHours,
          });
        } else if (fetchedHoursType === "Maximum") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            maxOnlyHours: fetchedData?.basic_details?.maxOnlyHours,
          });
        } else if (fetchedHoursType === "Minimum") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            minOnlyHours: fetchedData?.basic_details?.minOnlyHours,
          });
        }
      }
      if (fetchedPayType) {
        setExpectedPayType(fetchedPayType);

        if (fetchedPayType === "Range") {
          form.setFieldsValue({
            payType: fetchedPayType,
            minPay: fetchedData?.basic_details?.minPay,
            maxPay: fetchedData?.basic_details?.maxPay,
          });
        } else if (fetchedPayType === "Exact amount") {
          form.setFieldsValue({
            payType: fetchedPayType,
            exactAmount: fetchedData?.basic_details?.exactAmount,
          });
        } else if (fetchedPayType === "Maximum") {
          form.setFieldsValue({
            payType: fetchedPayType,
            maximumPay: fetchedData?.basic_details?.maximumPay,
          });
        } else if (fetchedPayType === "Starting") {
          form.setFieldsValue({
            payType: fetchedPayType,
            minOnlyHours: fetchedData?.basic_details?.minimumPay,
          });
        }
      }

      if (fetchedData?.description) {
        setJobDescription(fetchedData?.description);
      }

      if (fetchedData?.requirements) {
        setJobRequirements(fetchedData?.requirements);
      }

      if (fetchedData?.basic_details) {
        form.setFieldsValue({
          applicationDeadline:
            fetchedData?.basic_details?.applicationDeadline || "no",
        });
        setIsApplicationDeadline(
          fetchedData?.basic_details?.applicationDeadline || "no"
        );
      }

      form.setFieldsValue({
        jobDescription: fetchedData?.description,
        jobTitle: fetchedData?.basicDetails?.jobTitle,
        numberOfPeopleToHire: fetchedData?.basicDetails?.numberOfPeopleToHire,
        jobType: fetchedData?.basicDetails?.jobType,
        jobLocation: fetchedData?.basicDetails?.jobLocation,
        hiringTimeline: fetchedData?.basicDetails?.hiringTimeline,
      });
    }
  };

  return (
    <>
      <EditJobSteps id={id} onCancel={onCancel} visible={visible} />
    </>
  );
};

export default EditJobDetails;
