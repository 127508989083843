import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Spin } from "antd";
import {
  BarChart,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";
import { useLocation, useNavigate } from "react-router-dom";


interface LeaveType {
  leaveType: string;
  maxAllowed: number;
}

interface LeaveCount {
  [key: string]: number;
}

interface CurrentMonthData {
  leaveType: string;
  Balance: number;
  Availed: number;
  Available: number;
}

const LeaveBalancesAndHistory: React.FC = () => {
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;

  const [leaveNames, setLeaveNames] = useState<LeaveType[]>([]);
  const [currentMonthData, setCurrentMonthData] = useState<CurrentMonthData[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const navigate = useNavigate();
  let loggedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;

  const location = useLocation();

 

  const getLatestRecord = async () => {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .select("*")
      .eq("organization_id", organizationId)
      .order("created_at", { ascending: false })
      .limit(1);

    const response = await query;
    if (response.data) {
      const leaveTypes: LeaveType[] = response.data[0]?.policy_types.map(
        (policyType: any) => ({
          leaveType: policyType.leaveType,
          maxAllowed: policyType.maxAllowed,
        })
      );

      setLeaveNames(leaveTypes);
      setUpdateCounter(updateCounter + 1);
    }
  };

  const getEmployeeLeaves = async () => {
    const currentYear = new Date().getFullYear();

    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select("*")
      .eq("employee_id", employeeId)
      .eq("organization_id", organizationId)
      .eq("company_id", companyId)
      .ilike("leave_status", "%Approved%");

    const response = await query;

    if (response.data) {
      const leaveTypeCounts: LeaveCount = {};

      response.data.forEach((leaveRequest: any) => {
        const requestYear = new Date(leaveRequest.created_at).getFullYear();
        if (requestYear === currentYear) {
          const leaveType = leaveRequest.leave_type.toLowerCase();
          leaveTypeCounts[leaveType] =
            (leaveTypeCounts[leaveType] || 0) + leaveRequest.leave_count;
        }
      });

      const updatedCurrentMonthData: CurrentMonthData[] = leaveNames?.map(
        (leaveName) => {
          const availed =
            leaveTypeCounts[leaveName.leaveType?.toLowerCase()] || 0;
          return {
            leaveType: leaveName.leaveType,
            Balance: leaveName.maxAllowed - availed,
            Availed: availed,
            Available: leaveName.maxAllowed,
          };
        }
      );
      setCurrentMonthData(updatedCurrentMonthData);
      setUpdateCounter(updateCounter + 1);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLatestRecord();
  }, []);
  useEffect(() => {
    getEmployeeLeaves();
  }, [leaveNames]);

  const handleLeaveReqBtnClick = () => {
    navigate(
      `/${loggedInemployee?.employee?.access_right_group?.toLowerCase()}/requests`
    );
  };

  return (
    <Card
      className="bar-card dasboard-cards dasboard-card-sizes"
      title={
        <Row>
          <Col span={12}>
            <span className="leaves-history-title">
              Leave Balances and History
            </span>
          </Col>
          <Col span={12} className="leaves-history-btn-container">
            <Button
              className="custom-primary-btn"
              onClick={handleLeaveReqBtnClick}
            >
              Leave Request
            </Button>
          </Col>
        </Row>
      }
      bordered={true}
    >
      {loading ? (
        <Spin size="large" className="dashboard-leave-graph-spinner" />
      ) : (
        <Row gutter={10}>
          <Col span={24}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                width={730}
                height={250}
                data={currentMonthData}
                key={updateCounter}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="leaveType" />
                <YAxis
                  label={{
                    value: "Number of Leaves",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="Balance" fill="#4169e1" barSize={15} />
                <Bar dataKey="Availed" fill="#ff8c00" barSize={15} />
                <Bar dataKey="Available" fill="#009900" barSize={15} />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default LeaveBalancesAndHistory;
