import React, { useEffect, useState } from "react";
import "./index.css";
import { Row, Form, Col, Input, Space, Button, Select, InputNumber } from "antd";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { GrSubtractCircle } from "react-icons/gr";
import { IoIosAddCircle } from "react-icons/io";
import {
  allBranchesForCompanyDataApi,
  allOrgCompaniesDataApi,
  setDeductionPolicyApi,
} from "../services";
import { handleAddSlabFunction, hanldeInputChangeForSlabs } from "../viewmodel";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const DeductionCreateView: React.FC = () => {
  const [slabs, setSlabs] = useState<any[]>([{ id: Date.now() }]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [alertType, setAlertType] = useState<"success" | "info" | "warning" | "error">("info");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [alertMessage,setAlertMessage] = useState<string>("")
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("companyId", companyId);
      setSelectedCompanyId(companyId);
      if (branchId) {
        form.setFieldValue("branchId", branchId);
      }
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!(branchId || companyId)) {
      getBranches();
    }
  }, [selectedCompanyId]);

  const fetchCompanies = async () => {
    const resCompanies = await allOrgCompaniesDataApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies.data[0]?.id);
      setSelectedCompanyId(resCompanies.data[0]?.id);
    }
  };

  const handleAddSlab = () => {
    const { Slabs, defaultStart } = handleAddSlabFunction(slabs);

    setSlabs([
      ...Slabs,
      { id: Date.now(), start: defaultStart, stop: "", percent: "" },
    ]);
  };

  const handleRemoveSlab = (slabId: string) => {
    const updatedSlabs = slabs?.filter((slab) => slab.id !== slabId);
    setSlabs(updatedSlabs);
  };

  const handleInputChange = async (
    slabId: string,
    field: string,
    value: string
  ) => {
    const updatedSlabs = await hanldeInputChangeForSlabs(
      slabs,
      slabId,
      field,
      value
    );
    if (updatedSlabs) {
      setSlabs(updatedSlabs);
    }
  };
  const validateSlabs = () => {
    const sortedSlabs = [...slabs].sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
    let missingRanges = [];
   
    for (let i = 1; i < sortedSlabs.length; i++) {
      const prevStop = parseFloat(sortedSlabs[i - 1].stop) + 1;
      const currentStart = parseFloat(sortedSlabs[i].start);
      if (currentStart > prevStop) {
        missingRanges.push(`${prevStop} - ${currentStart}`);
      }
    }
    return missingRanges;
  };
  const onFinish = async (values: any) => {
    setShowAlert(false);
    setButtonLoader(true);

    if (slabs.length > 0) {
      const missingRanges = validateSlabs();
      if (missingRanges.length > 0) {
        setAlertType("error");
        setShowAlert(true);
        setAlertMessage(`You missed a slab or slabs for values between: ${missingRanges.join(", ")}`);
        setButtonLoader(false);
        return;
      }
      const data = {
        company_id: values.companyId || companyId || null,
        branch_id: values.branchId || branchId || null,
        organization_id: organizationId,
        version_name: values.deductionName,
        slabs: slabs,
      };
      const res = await setDeductionPolicyApi(data);
      setButtonLoader(false);
      if (res.status == 201 || res.status == 204) {
        setShowAlert(true);
        setAlertType("success");
        setSlabs([{ id: Date.now(), start: "", stop: "", percent: "" }]);
        form.resetFields();
      }
      if (res.error) {
        setShowAlert(true);
        setAlertType("error");
      }

      setButtonLoader(false);
    } else {
      setButtonLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
    } else {
      const companyId = form.getFieldValue("companyId");
      const resBranches = await allBranchesForCompanyDataApi(
        companyId,
        organizationId
      );

      if (resBranches?.data) {
        setFilteredBranches(resBranches?.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
      }
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          {!companyId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="companyId"
                label="Select a Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
                initialValue={filteredCompanies[0]?.id}
              >
                <Select
                  disabled={disabled}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  defaultValue={filteredCompanies[0]?.id}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!( branchId) && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="branchId"
                label="Select Branch (If any)"
                initialValue={loggedInEmployee?.employee?.branch_id}
              >
                <Select
                  disabled={disabled}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  defaultValue={filteredBranches[0]?.id}
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="deductionName"
              label="Deduction version"
              rules={[
                {
                  required: true,
                  message: "Please Enter Deduction version",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Deduction Version Name....." />
            </Form.Item>
          </Col>
        </Row>

        {slabs.map((slab) => (
          <div key={slab.id}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name={`${slab.id}start`}
                  label="Threshold Start(minutes)"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Threshold Start(minutes)",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const stopValue = getFieldValue(`${slab.id}stop`);
                        const slabIndex = slabs.findIndex(
                          (s) => s.id === slab.id
                        );
                        const previousSlabStop =
                          slabIndex > 0 ? slabs[slabIndex - 1].stop : null;

                        if (slabIndex > 0) {
                          if (
                            value &&
                            stopValue &&
                            parseFloat(value) > parseFloat(stopValue)
                          ) {
                            return Promise.resolve();
                          }
                          if (
                            value &&
                            previousSlabStop &&
                            parseFloat(value) <= parseFloat(previousSlabStop)
                          ) {
                            return Promise.reject(
                              "This field's value should be greater than the previous slab's Threshold Stop(minutes) value"
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="global-input-number-width"
                    placeholder="Threshold (Start - minutes)"
                    onChange={(e:any) => {
                      if (e.currentTarget.value === "") {
                        form.setFieldsValue({ [`${slab.id}stop`]: "" });
                      }
                      handleInputChange(slab.id, "start", e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name={`${slab.id}stop`}
                  label="Threshold Stop(minutes)"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Threshold Stop(minutes)",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const stopValue = getFieldValue(`${slab.id}start`);
                        if (
                          value &&
                          stopValue &&
                          parseFloat(value) > parseFloat(stopValue)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Threshold Stop(minutes) must be greater than Threshold Start(minutes)"
                        );
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="global-input-number-width"
                    placeholder="Threshold (Stop - minutes)"
                    onChange={(e:any) =>
                      handleInputChange(slab.id, "stop", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Form.Item
                  name={`${slab.id}%`}
                  label="Deduction%"
                  rules={[
                    {
                      required: true,
                      message: "Deduction%",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="global-input-number-width"
                    placeholder="Deduction%"
                    onChange={(e:any) =>
                      handleInputChange(slab.id, "percent", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <Form.Item label=" ">
                  <Button
                    onClick={() => handleRemoveSlab(slab.id)}
                    icon={<GrSubtractCircle />}
                    type="default"
                    size="large"
                  >
                    Slab
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}
        <Row className="global-modal-bottom-row policy-deduction-button-position">
          <Space>
            <Button
              onClick={handleAddSlab}
              icon={<IoIosAddCircle />}
              className="global-btn-width"
              type="primary"
              size="large"
            >
              Short Hours Slab
            </Button>

            <Button
              className="global-btn-width"
              htmlType="submit"
              loading={buttonLoader}
              type="primary"
              size="large"
            >
              Create Dedection Version
            </Button>
          </Space>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} description={alertMessage}/>}
    </div>
  );
};

export default DeductionCreateView;
