import React, { useEffect, useState } from "react";
import {
  Space,
  TablePaginationConfig,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Button,
  Tag,
  Empty,
  Card,
} from "antd";
import CustomAlert from "../../../../common/Alert";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import "./index.css";
import d_dp from "../../../../assets/images/blankProfile.png";
import dayjs from "dayjs";
import { capitalizeWords } from "../../../../common/validation";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { SortOrder } from "antd/lib/table/interface";
import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import {
  fetchTerminationListData,
  handleCancelTerminationData,
  updateTerminationListStatus,
} from "../services";
import CreateTermination from "../Create";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import searchIcon from "../../../../assets/images/search-icon.png";
import { FiPlus } from "react-icons/fi";
import CustomTable from "../../../../common/CustomTable";

const { RangePicker } = DatePicker;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

const ResignationListView = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [data, setData] = useState<any | null>(null);
  const [comment, setComment] = useState("");
  const [recordId, setRecordId] = useState<any>(null);
  const [disableField, setDisableField] = useState<boolean>(false);
  const [receiverId, setReceiverId] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [resignationText, setResignationText] = useState<string>(
    "Resignation Details"
  );
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [searchInput, setSearchInput] = useState<string | null | any>(null);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createTermination, setCreateTermination] = useState<any>(null);
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "End Of Employment");
  const create = access?.createAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchData();
  }, [loggedInEmployee]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleRangeChange = (dates: any, dateStrings: any) => {
    if (dates) {
      const [start, end] = dates;
      setSelectedRecord({
        ...selectedRecord,
        start_date: start,
        end_date: end,
      });
    }
  };

  const updateResignationStatus = async (status: string) => {
    try {
      if (status) {
        const { error } = await updateTerminationListStatus(
          status,
          comment,
          loggedInEmployee?.employee?.id,
          selectedRecord.id,
          organizationId
        );
        if (error) {
          setShowAlert(true);
          setAlertType("error");
        } else {
          setShowAlert(true);
          setAlertType("success");
          setIsModalVisible(false);
          fetchData();
        }
      }
    } catch (error: any) {
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const handleAccept = () => {
    updateResignationStatus("Accepted");
  };

  const handleReject = () => {
    updateResignationStatus("Rejected");
  };

  const handleSearch = (e: any) => {
    setLoading(true);
    const value = e.target.value;
    setSearchInput(value);
    const filteredData = originalData?.filter((item: any) =>
      item?.employee?.full_name.toLowerCase().includes(value?.toLowerCase())
    );
    setData(filteredData);
    setLoading(false);
  };

  const fetchData = async () => {
    const response = await fetchTerminationListData(organizationId, companyId);
    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.length,
        },
      });

      const filteredEmployees = response.data?.filter(
        (item: any) => item.employee_Id !== loggedInEmployee?.employee?.id
      );
      setOriginalData(filteredEmployees);
      setData(filteredEmployees);
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleView = async (record: any) => {
    if (record?.status && record?.status === "Terminate") {
      setResignationText("Termination Details");
      setRecordId(record?.id);
      setReceiverId(record?.employee_Id);
      setDisableField(true);
    } else {
      setDisableField(false);
      setResignationText("Resignation Details");
    }
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: any, b: any) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (record: any) => moment(record).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Name",
      dataIndex: "employee",
      key: "employee",
      width: 280,
      render: (record: any) => (
        <Row gutter={16}>
          <Col span={5}>
            <div className="circle-avatarUrl-cell">
              <img
                src={record?.avatar_url ? record.avatar_url : d_dp}
                alt={d_dp}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col span={19}>
            <strong>{record?.full_name}</strong>
          </Col>
        </Row>
      ),
    },
    {
      title: "Notice Period Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Notice Period End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        let color =
          capitalizeWords(record) == "Requested"
            ? "red"
            : capitalizeWords(record) == "Rejected"
            ? "orange"
            : capitalizeWords(record) == "Accepted"
            ? "green"
            : capitalizeWords(record) == "Terminate"
            ? "blue"
            : "blue";
        return (
          <div>
            <Tag color={color} className="list-view-tag">
              {capitalizeWords(record)}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => {
        const today = moment().startOf("day");
        const endDate = moment(record?.end_date).startOf("day");
        if (
          endDate.isSameOrAfter(today) &&
          record?.status &&
          record?.status === "Terminate"
        ) {
          return (
            <Space>
              <a onClick={() => handleView(record)}>
                <EyeOutlined />
              </a>
            </Space>
          );
        } else if (record?.status && record?.status !== "Terminate") {
          return (
            <Space>
              <a onClick={() => handleView(record)}>
                <EyeOutlined />
              </a>
            </Space>
          );
        }
        return null;
      },
    },
  ];

  const handleCancelTermination = async () => {
    setButtonLoading(true);

    const sendNotificationBody = {
      type: "termination",
      senderId: loggedInEmployee?.employee?.id,
      Receiver: receiverId,
      name: loggedInEmployee?.employee?.full_name,
      avatar: loggedInEmployee?.employee?.avatar_url,
    };

    await pushNotifictionIntoNotificationTable(
      sendNotificationBody,
      organizationId
    );

    const response = await handleCancelTerminationData(
      recordId,
      organizationId
    );

    if (response && (response?.status === 204 || response?.status === 201)) {
      setButtonLoading(false);
      setIsModalVisible(false);
      setShowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const handleCreateClick = (record: any) => {
    setCreateTermination(record);
    setCreateModalVisible(true);
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
           
            <Row align="middle" className="termination-search-bar-container">
              <Col span={24} lg={12}>
                <Input
                  placeholder="Enter Employee Name..."
                  value={searchInput}
                  onChange={handleSearch}
                  prefix={<img src={searchIcon} alt="Search icon" />}
                  onPressEnter={fetchData}
                  allowClear
                  size="large"
                  className="termination-search-input"
                />
              </Col>
              <Col span={24} lg={12} className="termination-header-create-container">
                {create && (
                  <Button
                    size="large"
                 
                    onClick={handleCreateClick}
                    type="primary"
                  >
                    <FiPlus className="termination-tab-header-icon" />
                    <span>Create</span>
                  </Button>
                )}
              </Col>
            </Row>

            {read ? (
              <CustomTable
                dataSource={data}
                columns={columns}
                loading={loading}
                onChange={handleTableChange}
              />
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
            <Modal
              open={isModalVisible}
              width={1000}
              onCancel={() => setIsModalVisible(false)}
              footer={false}
              title={
                <span className="global-modal-title">{resignationText}</span>
              }
              className="global-modal-main-container"
            >
              <div className="global-card-inner-container">
                <Form layout="vertical">
                  <Row gutter={16}>
                    <Col className="modal-content another-class">
                      <Form.Item label="reason">
                        <Input
                          value={selectedRecord?.reason}
                          placeholder="Enter a  reason..."
                          disabled
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item label="Description">
                        <Input.TextArea
                          rows={3}
                          value={selectedRecord?.description}
                          placeholder="Enter a  Description..."
                          disabled
                        />
                      </Form.Item>
                      <Form.Item label="Notice Period">
                        <RangePicker
                          disabled={
                            selectedRecord?.status === "Terminate"
                              ? true
                              : !disableField
                          }
                          style={{ width: "100%" }}
                          onChange={handleRangeChange}
                          value={
                            selectedRecord
                              ? [
                                  dayjs(selectedRecord?.start_date),
                                  dayjs(selectedRecord?.end_date),
                                ]
                              : null
                          }
                        />
                      </Form.Item>

                      <Form.Item label="Notice Period Span">
                        <Input size="large" value={selectedRecord?.notice_period} disabled />
                      </Form.Item>
                      {!disableField && (
                        <Form.Item label="Comment">
                          <Input.TextArea
                            rows={3}
                            value={
                              selectedRecord?.status === "Accepted"
                                ? selectedRecord?.reviwer_comment
                                : selectedRecord?.status === "Rejected"
                                ? selectedRecord?.reviwer_comment
                                : comment
                            }
                            onChange={handleCommentChange}
                            placeholder="Enter a comment..."
                            disabled={
                              selectedRecord?.status &&
                              selectedRecord?.status === "Accepted"
                                ? true
                                : selectedRecord?.status === "Rejected"
                                ? true
                                : false
                            }
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  {!disableField &&
                    selectedRecord?.status &&
                    selectedRecord?.status !== "Accepted" && (
                      <Row className="modal-buttons-container" gutter={16}>
                        <Col>
                          {deletee && (
                            <Button
                              className="global-btn-width"
                              onClick={handleReject}
                              type="default"
                            >
                              Reject
                            </Button>
                          )}
                        </Col>
                        <Col>
                          {create && (
                            <Button
                              className="global-btn-width"
                              onClick={handleAccept}
                              type="primary"
                            >
                              Accept
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  <Row className="records-sorting-btn">
                    {disableField && (
                      <Row gutter={[16, 16]}>
                        <Button
                          type="primary"
                          className="global-btn-width"
                          onClick={handleCancelTermination}
                        >
                          Cancel Termination
                        </Button>
                      </Row>
                    )}
                  </Row>
                </Form>
              </div>
            </Modal>
          
          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}

          {createTermination && (
            <Modal
              title={
                <span className="global-modal-title">End of Employment</span>
              }
              open={createModalVisible}
              onCancel={() => setCreateModalVisible(false)}
              width={1000}
              footer={false}
              className="global-modal-main-container"
            >
              <CreateTermination
                onCancel={() => {
                  setCreateModalVisible(false);
                  fetchData();
                }}
              />
            </Modal>
          )}
        </Card>
      </div>
    </>
  );
};

export default ResignationListView;
