import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";

import { Modal, Space, Popconfirm, TablePaginationConfig } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import PolicyDocumentEditView from "../Edit";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import { deletePolicydoumentData, fetchPolicydoumentData } from "../services";
import { downloadPolicyAsPdfFunction } from "../viewmodal";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface PolicyDocumentListProps {
  searchValue?: string;
}

const PolicyDocumentListView: React.FC<PolicyDocumentListProps> = ({
  searchValue,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [selectedPolicy, setSelectedPolicy] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingPolicy, setEditingPolicy] = useState<any | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const loggedInEmployee = useSelector(selectLogedEmployee);

  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.logedInemployee?.employee?.company_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "General Policy"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;

  useEffect(() => {
    fetchPolicyDoc();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setData(originalData.apiData);
    }
  }, [searchValue]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const openEditModal = (policy: any) => {
    setEditingPolicy(policy);
    setEditModalVisible(true);
  };

  const fetchPolicyDoc = async () => {
    setLoading(true);
    try {
      const { data: policydocumentres, error } = await fetchPolicydoumentData(
        organizationId,
        companyId
      );

      if (policydocumentres) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.length,
          },
        });

        setData(policydocumentres);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: policydocumentres,
        }));
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const deletePolicyDetail = async (policyId: number) => {
    try {
      const { error } = await deletePolicydoumentData(policyId, organizationId);

      if (error) {
        setshowAlert(true);
        setAlertType("error");
        return;
      }
      setshowAlert(true);
      setAlertType("success");
      fetchPolicyDoc();
    } catch (error) {}
  };

  const downloadPolicyAsPDF = (policy: any) => {
    downloadPolicyAsPdfFunction(policy);
  };

  const showPolicyDetail = (policy: any) => {
    setSelectedPolicy(policy);
  };

  const closeModal = () => {
    setSelectedPolicy(null);
    setEditModalVisible(false);
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setData(filterData);

    setLoading(false);
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Effective up to",
      dataIndex: "effective_up_to",
      key: "effective_up_to",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a onClick={() => showPolicyDetail(record)}>
            <EyeOutlined />
          </a>
          {edit && (
            <a onClick={() => openEditModal(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            onConfirm={() => deletePolicyDetail(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>

          <a onClick={() => downloadPolicyAsPDF(record)}>
            <DownloadOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        dataSource={data}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
      />

      {selectedPolicy && (
        <Modal
          title={selectedPolicy?.title}
          open={!!selectedPolicy}
          onCancel={closeModal}
          footer={null}
          width={700}
        >
          <div className="ant-modal-body-no-scrollbar">
            <div
              id="policy-content"
              dangerouslySetInnerHTML={{ __html: selectedPolicy.description }}
            />
          </div>
        </Modal>
      )}

      {editModalVisible && editingPolicy && (
        <PolicyDocumentEditView
          policy={editingPolicy}
          onClose={closeModal}
          fetchData={fetchPolicyDoc}
        />
      )}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default PolicyDocumentListView;
