import React, { useState, useEffect } from "react";
import { Input, Button, Col, Row, Form, Select } from "antd";
import "./index.css";

import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";

import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchCompanies, getBranches, updateRecords } from "../services";
import { TriangleLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

const DsignationEdit: React.FC<any> = (props) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [branchData, setBranchData] = useState<any[]>([]);

  const [companyData, setCompanyData] = useState<
    { id: string; name: string }[]
  >([]);
  const [form] = useForm();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue({
        designation: props.data.designationname,
        comment: props.data.comment,
        companies: props.data.companyIds,
        branches: props.data.branchId,
      });
    }
    fetchCompanyNames();
    fetchBranchNames();
  }, []);

  const fetchCompanyNames = async () => {
    try {
      const response = await fetchCompanies(organizationId);
      if (response.data) {
        const companyData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setCompanyData(companyData);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchBranchNames = async () => {
    const response = await getBranches(props.data.companyIds, organizationId);
    if (response.data) {
      setBranchData(response.data);
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setshowAlert(false);
    try {
      setButtonLoading(true);
      const body = {
        mission: form.getFieldValue("comment")
          ? form.getFieldValue("comment")
          : "",

        name: form.getFieldValue("designation")
          ? form.getFieldValue("designation")
          : props.data.designationname,
      };

      const response = await updateRecords(
        body,
        props.data.designationid,
        organizationId
      );

      if (response.status == 201 || response.status == 204) {
        props.fetchData();
        setshowAlert(true);
        setAlertType("success");
        setButtonLoading(false);
        form.resetFields();
      } else {
        setshowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <Row>
          <Col className="modal-content">
            <Row gutter={16}>
              <Col span={24} md={12} lg={8}>
                <Form.Item
                  label="Designation"
                  name="designation"
                  rules={[
                    { required: true, message: "Please Enter Designation!" },
                  ]}
                >
                  <Input size="large" placeholder="Enter your designation..." />
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item label="Companies" name="companies">
                  <Select
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    showSearch
                    placeholder="Selected Company"
                    optionFilterProp="children"
                    disabled
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companyData.map((company) => (
                      <Option key={company.id} value={company.id}>
                        {company.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={8}>
                <Form.Item label="Select a Branch (if any)" name={"branches"}>
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    placeholder="Please select a branch"
                    optionFilterProp="children"
                    disabled
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {branchData.map((branch: any) => (
                      <Option key={branch.id} value={branch.id}>
                        {branch.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={12} lg={24}>
                <Form.Item label="Description" name="comment">
                  <Input.TextArea
                    placeholder="Enter your description..."
                    rows={3}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            className="global-modal-bottom-row designation-edit-modal-step-1-btn-col"
          >
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                onClick={handleUpdate}
                loading={buttonLoading}
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loading && <TriangleLoader />}
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default DsignationEdit;
