import React, { useEffect, useState } from "react";
import { Modal, Popconfirm, Space, TablePaginationConfig } from "antd";
import "./index.css";
import { SortOrder } from "antd/lib/table/interface";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import EditViewTiming from "../Edit";
import { deleteTimingPolicy, fetchTimingPolicyData } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import TimingEmployeeView from "../EmployeeView";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface TimingListProps {
  searchValue?: string;
}

type DaysObject = {
  [key: string]: boolean;
};

const TimingListView: React.FC<TimingListProps> = ({ searchValue }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [viewModalId, setViewModalId] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showModel, setShowModel] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [selectedId, setSelectedId] = useState<string>("");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const organizationId =
    loggedInEmployee?.logedInemployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.logedInemployee?.employee?.company_id;
  const branchId = loggedInEmployee?.logedInemployee?.employee?.branch_id;
  const daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "Timing Policy"
  );

  const deletee = access?.deleteAccess;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setData(originalData.apiData);
    }
  }, [searchValue]);

  const sortDays = (days: DaysObject): DaysObject => {
    return daysOrder
      .filter((day) => day in days)
      .reduce<DaysObject>((acc, day) => {
        acc[day] = days[day];
        return acc;
      }, {});
  };

  const fetchData = async () => {
    setLoading(true);

    const response = await fetchTimingPolicyData(
      organizationId,
      companyId,
      branchId
    );

    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.length,
        },
      });
      setData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setLoading(false);
    }
  };

  const handleDelete = async (record: any) => {
    const { error } = await deleteTimingPolicy(record.id, organizationId);

    if (error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Working Hours Per Day",
      dataIndex: "working_hours_per_day",
      key: "working_hours_per_day",
    },
    {
      title: "Working Days",
      dataIndex: "working_days",
      key: "working_days",
      render: (workingDays: any) => {
        return Object?.entries(sortDays(workingDays))
          ?.filter(([key, value]) => value == true)
          ?.map(([key]) => key)
          .join(", ");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
          <a>
            <EyeOutlined
              onClick={() => handleViewTimingDetailsModalVisible(record)}
            />
          </a>
        </Space>
      ),
    },
  ];

  const handelCancel = async () => {
    setSelectedId("");
    setShowModel(false);
    fetchData();
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setData(filterData);

    setLoading(false);
  };

  const handleViewTimingDetailsModalVisible = (value: any) => {
    setShowViewModal(true);
    setViewModalId(value);
  };
  const handleViewTimingDetailsModalCancel = () => {
    setShowViewModal(false);
    setViewModalId(null);
  };

  return (
    <div>
      <CustomTable
        scroll={{ x: true }}
        dataSource={data}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
      />

      {selectedId && (
        <Modal
          title="Timing Policy Details"
          open={showModel}
          onCancel={handelCancel}
          width={1000}
          footer={false}
          className="deduction-listview-edit-model"
        >
          <EditViewTiming id={selectedId} oncancel={() => handelCancel()} />
        </Modal>
      )}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      {showViewModal && (
        <Modal
          open={showViewModal}
          onCancel={handleViewTimingDetailsModalCancel}
          width={1000}
          footer={false}
          className="global-modal-main-container"
          destroyOnClose
          title="Timing and Punctuality Policy"
        >
          <div className="global-card-inner-container ">
            <TimingEmployeeView data={viewModalId} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TimingListView;
