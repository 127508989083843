import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getOverTimePolicyVersion = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_policy")
      .select("id,company_id,branch_id,version_name,organization_id")
      .eq("organization_id", organizationId);
    if (companyId) {
      query.eq("company_id", companyId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const createOverTimePolicyApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("over_time_policy")
      .insert(data);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeEarlyLeftPolicy = async (
  organizationId: number,
  companyId: string,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_policy")
      .select("*")
      .eq("organization_id", organizationId)
      .order("id", { ascending: true })
      .limit(1);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchViewOverTimePolicyData = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_policy")
      .select(
        "version_name,working_hours_allowed_per_working_day,working_hours_allowed_per_nonworking_day,hourly_pay_calculation_multiplier,id"
      )
      .eq("organization_id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const deleteOverTimePolicyData = async (
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_policy")
      .delete()
      .match({ id: id });

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
