import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  Divider,
  Form,
  Select,
  DatePicker,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import dayjs from "dayjs";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import {
  fetchDesignationsApi,
  fetchJobTitleApi,
  fetchReportToEmployeeApi,
  updateEmployeeApi,
} from "./services";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface optionType {
  label: string;
  id: string | number;
}
const { Option } = Select;

const ReportTo: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const org_details = useSelector(selectLogedEmployee);
  const emp_details = useSelector(selectEmployee);
  const organizationId =
    org_details?.logedInemployee?.employee?.organization_id;
  const activeButtons = emp_details.Employee.Activebutton;
  const [responsibility, setResponsibility] = useState({
    roleToReport: "",
    reportToEmployee: "",
    employeeDesignation: "",
    selectedJobTitle: "",
    employeeResponsibility: "",
  });
  const [joiningDate, setJoiningDate] = useState<string | string[]>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [jobTitleOption, setJobTitleOption] = useState<optionType[]>([]);
  const [roleToEmployeeOption, setRoleToEmployeeOption] = useState<
    optionType[]
  >([]);
  const [designationOption, setDesignationOption] = useState<optionType[]>([]);
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);

  useEffect(() => {
    fetchReportToDesignation();
    featchEmployeeTitle();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useEffect(() => {
    featchReportToEmployeedata();
  }, [form.getFieldValue("roleToReport")]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchReportToDesignation = async () => {
    const data = {
      organizationId: organizationId,
      companyId: emp_details?.Employee?.PersonalDetails?.selectedCompanyId,
      branchId: emp_details?.Employee?.PersonalDetails?.selectedBranchId,
    };
    const res = await fetchDesignationsApi(data, organizationId);
    if (res.data) {
      const boption: optionType[] = res.data?.map((item: any) => ({
        id: item.id,
        label: item.name,
      }));
      setDesignationOption(boption);
    }
  };
  const featchReportToEmployeedata = async () => {
    const data = {
      organizationId: organizationId,
      companyId: emp_details?.Employee?.PersonalDetails?.selectedCompanyId,
      branchId: emp_details?.Employee?.PersonalDetails?.selectedBranchId,
      employeeDesignation: form.getFieldValue("roleToReport"),
      employeeId: emp_details.Employee.EmployeeId,
    };
    const res = await fetchReportToEmployeeApi(data, organizationId);
    if (res.data) {
      const boption: optionType[] = res.data?.map((item: any) => ({
        id: item.id,
        label:
          item.full_name +
          (item.department ? "(" + (item.department?.name || "") + ")" : ""),
      }));
      setRoleToEmployeeOption(boption);
    }
  };
  const featchEmployeeTitle = async () => {
    const data = {
      organizationId: organizationId,
      companyId: emp_details?.Employee?.PersonalDetails?.selectedCompanyId,
      branchId: emp_details?.Employee?.PersonalDetails?.selectedBranchId,
    };
    const res = await fetchJobTitleApi(data, organizationId);
    if (res.data) {
      const boption: optionType[] = res.data?.map((item: any) => ({
        id: item.id,
        label:
          item.name +
          (item.company ? "(" + (item.company?.name || "") + ")" : ""),
      }));
      setJobTitleOption(boption);
    }
  };
  const handlesaveprofile = async (values: any) => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const body = {
        reporting_to_role_id: values.roleToReport || null,
        report_to_employee_id: values.reportToEmployee || null,
        designation_id: values.selectedDesignation || null,
        job_title_id: values.selectedJobTitle || null,
        responsibilities: values.employeeResponsibility || null,
        work_type: values.workType,
        joining_date: joiningDate,
      };
      const updatedBody = {
        body: body,
        id: emp_details.Employee.EmployeeId,
      };
      const Employees_res = await updateEmployeeApi(
        updatedBody,
        organizationId
      );

      if (Employees_res.data && Employees_res.data.id) {
        const buttonsToAdd = [
          "Emergency_Details",
          "Qualification",
          "Salary",
          "Bank_Details",
        ];
        let updatedActiveButtons: any = activeButtons || [
          { key: "Personl_Details", status: "Active" },
          { key: "Contact_Details", status: "Active" },
          { key: "Report_to", status: "Active" },
        ];
        buttonsToAdd.forEach((buttonKey) => {
          if (!activeButtons?.some((button) => button.key === buttonKey)) {
            updatedActiveButtons = [
              ...(activeButtons || []),
              {
                key: buttonKey,
                status: "Active",
              },
              ...updatedActiveButtons,
            ];
          }
        });

        dispatch(
          setEmployeeProfileDetails({
            Employee: {
              ResponsibilityReportto: {
                roleToReport: values.roleToReport,
                reportToEmployee: values.reportToEmployee,
                employeeDesignation: values.selectedDesignation,
                selectedJobTitle: values.selectedJobTitle,
                employeeResponsibility: values.employeeResponsibility,
                // workType: values.workType,
                // joiningDate: dayjs(values.joiningDate),
              },
              EmployeeId: Employees_res.data.id,
              Activebutton: updatedActiveButtons,
            },
          })
        );
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (Employees_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (Employees_res.status == 404 || Employees_res.status == 400) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }

      setButtonLoading(false);
    } catch {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  const handleSelectChange = (fieldName: string, value: string) => {
    setResponsibility((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setResponsibility((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handlesaveprofile}
        initialValues={{
          roleToReport:
            emp_details?.Employee?.ResponsibilityReportto?.roleToReport || "",
          reportToEmployee:
            emp_details?.Employee?.ResponsibilityReportto?.reportToEmployee ||
            "",
          selectedDesignation:
            emp_details?.Employee?.ResponsibilityReportto
              ?.employeeDesignation || "",
          selectedJobTitle:
            emp_details?.Employee?.ResponsibilityReportto?.selectedJobTitle ||
            "",
          employeeResponsibility:
            emp_details?.Employee?.ResponsibilityReportto
              ?.employeeResponsibility || "",
          // workType:
          //   emp_details?.Employee?.ResponsibilityReportto?.workType || "",
          // joiningDate: dayjs(
          //   emp_details?.Employee?.ResponsibilityReportto?.joiningDate
        }}
      >
        <h3>Responsibility & Report to</h3>
        <Divider />
        <strong className="strong-report-to-content-center">Report To</strong>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Designation" name="roleToReport">
              <Select
                placeholder="Select an option..."
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
                value={responsibility.roleToReport}
                onChange={(value) =>
                  setResponsibility((prevData) => ({
                    ...prevData,
                    roleToReport: value,
                    reportToEmployee: "",
                  }))
                }
              >
                {designationOption.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Report To" name="reportToEmployee">
              <Select
                placeholder="Select an option..."
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                value={responsibility.reportToEmployee}
                onChange={(value) =>
                  setResponsibility((prevData) => ({
                    ...prevData,
                    reportToEmployee: value,
                  }))
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {roleToEmployeeOption.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <strong className="strong-report-to-content-center">
          Position And Responsibility
        </strong>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Employee Designation"
              name="selectedDesignation"
              rules={[
                {
                  required: true,
                  message: "Please Select Employee Designation",
                },
              ]}
            >
              <Select
                placeholder="Select a designation..."
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                value={responsibility.employeeDesignation}
                onChange={(value) =>
                  handleSelectChange("employeeDesignation", value)
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {designationOption.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Job Title"
              name="selectedJobTitle"
              rules={[
                {
                  required: true,
                  message: "Please Select Job Title",
                },
              ]}
            >
              <Select
                placeholder="Select a job title..."
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                value={responsibility.selectedJobTitle}
                onChange={(value) =>
                  handleSelectChange("selectedJobTitle", value)
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {jobTitleOption.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name={"joiningDate"} label="Joining Date">
              <DatePicker
                className="date-picker"
                onChange={(d: any, ds: string | string[]) => {
                  setJoiningDate(ds);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item name={"workType"} label="Work Type">
              <Select
                showSearch
                placeholder="Select a work-type..."
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {["Remote", "Hybrid", "On-site"].map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item label="Responsibility" name="employeeResponsibility">
              <Input.TextArea
                name="employeeResponsibility"
                value={responsibility.employeeResponsibility}
                onChange={handleTextAreaChange}
                placeholder="Enter responsibility..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="last-row">
          {!emp_details?.Employee?.ResponsibilityReportto && (
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  onClick={handlesaveprofile}
                  loading={buttonLoading}
                >
                  Save progress
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default ReportTo;
