import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Plan {
  key: string;
  name: string;
  readAccess: boolean;
  child: { [key: string]: SubPlan } | null;
}

interface SubPlan {
  key: string;
  name: string;
  parentKey: string;
  readAccess: boolean;
  child: { [key: string]: SubPlan } | null;
}

interface PlansData {
  [key: string]: Plan;
}

interface RootState {
  organizationModulePermission: any | null;
}

const initialState: RootState = {
  organizationModulePermission: null,
};

const organizationModulePermissionSlice = createSlice({
  name: "organizationModulePermission",
  initialState,
  reducers: {
    setOrganizationModulePermission: (state, action: PayloadAction<any>) => {
      state.organizationModulePermission = action.payload;
    },
  },
});

export const { setOrganizationModulePermission } =
  organizationModulePermissionSlice.actions;

export const selectOrganizationModulePermission = (state: RootState) =>
  state.organizationModulePermission;

export default organizationModulePermissionSlice.reducer;
