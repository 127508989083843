import type { Empty, ThemeConfig } from "antd";
import NoDataFoundIcon from "./HCM/common/NoDataFoundIcon";

export const config: ThemeConfig = {
  token: {
    //Global Tokens
    colorPrimary: "var(--green-color-primary-light)",
    colorPrimaryHover: "var(--green-color-primary-light)",
    colorSuccessBorder: "#52c41a",
    colorSuccessBg: "var(--white-colour)",
    colorSuccess: "#52c41a",
    colorInfoBorder: "",
    colorInfoBg: "",
    colorInfo: "",
    colorErrorBorder: "red",
    colorErrorBg: "#fff2f0",
    colorError: "#ff4d4f",
    colorErrorBgHover: "#fff1f0",
    colorErrorHover: "#ff7875",
    colorErrorOutline: "rgba(255, 38, 5, 0.06)",
    colorWarningBorder: "#faad14",
    colorWarningBg: "#fffbe6",
    colorWarningBgHover: "#fff1b8",
    colorWarningHover: "#ffd666",
    colorWarningOutline: "rgba(255, 215, 5, 0.1)",
    colorWarning: "#faad14",
    colorTextHeading: "var(--black-color)",
    colorText: "var(--black-color)",
    colorIcon: "var(--green-color-primary-light)",
    colorIconHover: "var(--green-color-primary-light)",
    colorLink: "var(--green-color-primary-light)",
    colorSplit: "rgba(5, 5, 5, 0.06)",
    colorBgContainer: "var(--white-colour)",
    colorBgElevated: "var(--white-colour)",
    colorTextDescription: "rgba(0, 0, 0, 0.45)",
    colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
    colorTextDisabled: "rgba(0, 0, 0, 0.25)",
    colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
    colorBorderBg: "var(--border-color-light)",
    borderRadiusSM: 10,
    lineWidth: 1,
    colorBorder: "var(--border-color-light)",
    colorBorderSecondary: "var(--border-color-light)",
    fontSizeLG: 14,
    fontSizeSM: 12,
    fontSize: 13,
    controlHeight: 40,
    controlHeightLG: 40,
    controlHeightSM: 40,
    controlInteractiveSize: 10,
    controlItemBgActive: "var(--green-color-secondary-light-alpha)",
    colorLinkActive: "var(--green-color-primary-light)",
    colorLinkHover: "var(--green-color-primary-light)",
    colorPrimaryActive: "var(--green-color-primary-light)",
    colorPrimaryBorder: "var(--green-color-primary-light)",
    colorTextLightSolid: "var(--white-color-solid)",
    fontFamily: "var(--paragraph-fonts)",
    colorFillSecondary: "rgba(0, 0, 0, 0.06)",
    colorFillTertiary: "rgba(0, 0, 0, 0.04)",
    colorTextQuaternary: "rgba(0, 0, 0, 0.25)",
    fontSizeIcon: 14,
    motionDurationMid: "0.2s",
    motionDurationSlow: "0.3s",
    motionEaseInOut: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    motionEaseInOutCirc: "cubic-bezier(0.78, 0.14, 0.15, 0.86)",
    motionEaseInQuint: "cubic-bezier(0.755, 0.05, 0.855, 0.06)",
    motionEaseOutCirc: "cubic-bezier(0.08, 0.82, 0.17, 1)",
    motionEaseOutQuint: "cubic-bezier(0.23, 1, 0.32, 1)",
    paddingSM: 12,
    paddingXS: 8,
    paddingXXS: 4,
    controlOutline: "var(--green-color-secondary-light-alpha)",
    lineType: "solid",
    lineWidthFocus: 2,
    fontWeightStrong: 600,
    marginXL: 32,
    opacityImage: 1,
    marginXS: 8,
  },
};

//Alert

export const alertTheme = {
  defaultPadding: "",
  withDescriptionPadding: "",
  withDescriptionIconSize: 16,
};

//Anchor
export const anchorTheme = {
  linkPaddingBlock: "",
  linkPaddingInlineStart: "",
};

//Avatar
export const avatarTheme = {
  containerSize: 48,
  containerSizeLG: 48,
  containerSizeSM: 32,
  textFontSize: 14,
  textFontSizeLG: 16,
  textFontSizeSM: 12,
  groupSpace: 16,
  colorBorderBg: "var(--border-color-light)",
};

//BreadCrumb
export const breadcrumbTheme = {
  itemColor: "",
  lastItemColor: "",
  iconFontSize: "",
  linkColor: "",
  linkHoverColor: "",
  separatorColor: "",
  separatorMargin: "",
};

//Button
export const buttonTheme = {
  fontWeight: 500,
  lineStyle: "solid",
  primaryColor: "var(--white-colour)",
  primaryBgColor: "var(--green-color-primary-light)",
  primaryShadow: "none",
  defaultShadow: "none",
  colorText: "",
  colorBgContainer: "var(--white-colour)",
  colorBorder: "var(--lightgray-colour)",
  colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
  borderColorDisabled: "var(--lightgray-colour)",
  contentFontSizeLG: 16,
  contentFontSizeSM: 14,
  colorBgTextActive: "rgba(0, 0, 0, 0.15)",
  contentFontSize: 16,
  controlHeight: 40,
  controlHeightLG: 40,
  defaultActiveBg: "var(--white-colour)",
  defaultHoverBorderColor: "var(--green-color-primary-light)",
  defaultHoverColor: "var(--green-color-primary-light)",
  defaultActiveBorderColor: "var(--green-color-primary-light)",
  defaultActiveColor: "var(--green-color-primary-light)",
  defaultBorderColor: "#d9d9d9",
  defaultColor: "rgba(0, 0, 0, 0.88)",
  controlHeightSM: 40,
  onlyIconSize: 16,
  groupBorderColor: "var(--border-color-light)",
  linkHoverBg: "var(--green-color-secondary-light-alpha)",
  textHoverBg: "var(--green-color-secondary-light-alpha)",
  colorLink: "var(--green-color-primary-light)",
  colorPrimary: "var(--green-color-primary-light)",
  colorLinkActive: "var(--green-color-primary-light)",
  colorLinkHover: "var(--green-color-primary-light)",
  colorPrimaryActive: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  colorTextLightSolid: "var(--white-colour)",
};

//Table
export const tableTheme = {
  colorBgContainer: "var(--white-color)",
  headerBg: "var(--white-colour)",
  headerColor: "var(--gray-colour)",
  rowHoverBg: "var(--green-color-secondary-light-alpha)",
  rowSelectedBg: "",
  rowSelectedHoverBg: "",
  colorText: "var(--black-color)",
  rowExpandedBg: "var(--green-color-secondary-light-alpha)",
  borderColor: "var(--border-color-light)",
  footerBg: "var(--white-colour)",
  footerColor: "var(--gray-colour)",
  headerSplitColor: "var(--white-colour)",
  headerSortHoverBg: "var(--green-color-secondary-light-alpha)",
  colorIcon: "var(--green-color-primary-light)",
  bodySortBg: "#fafafa",
  cellFontSize: 14,
  cellFontSizeMD: 14,
  cellFontSizeSM: 14,
  expandIconBg: "var(--white-colour)",
  filterDropdownBg: "var(--white-colour)",
  filterDropdownMenuBg: "var(--white-colour)",
  fixedHeaderSortActiveBg: "var(--green-color-secondary-light-alpha)",
  headerBorderRadius: 8,
  headerFilterHoverBg: "rgba(0, 0, 0, 0.06)",
  headerSortActiveBg: "#f0f0f0",
  headerSortActiveColor: "var(--green-color-primary-light)",
  selectionColumnWidth: 32,
  stickyScrollBarBg: "rgba(0, 0, 0, 0.25)",
  stickyScrollBarBorderRadius: 100,
};

//Spin
export const spinTheme = {
  dotSize: 20,
  colorBgContainer: "var(--white-colour)",
  dotColorbg: "var(--green-color-primary-light)",
  colorPrimary: "var(--green-color-primary-light)",
};

//Pagination
export const paginationTheme = {
  itemActiveBgDisabled: "var(--lightgray-colour)",
  itemActiveColorDisabled: "var(--lightgray-colour)",
  itemSize: 30,
  colorBgContainerDisabled: "var(--lightgray-colour)",
  colorPrimary: "var(--green-color-primary-light)",
  colorText: "var(--black-color)",
  controlOutline: "var(--green-color-secondary-light-alpha)",
};

//Input
export const inputTheme = {
  controlHeight: 40,
  controlHeightLG: 40,
  controlHeightXS: 40,
  controlHeightSM: 40,
  controlItemBgActive: "var(--green-color-secondary-light-alpha)",
  colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
  colorText: "var(--black-color)",
  colorBorder: "var(--border-color-light)",
  colorBgContainer: "var(--white-colour)",
  hoverBorderColor: "var(--green-color-primary-light)",
  colorBgContainerDisabled: "var(--lightgray-colour)",
  activeShadow: "none",
  colorIconHover: "var(--green-color-primary-light)",
  colorTextDisabled: "var(-gray-colour)",
  handleBg: "var(--green-color-primary-light)",
  handleBorderColor: "var(--green-color-primary-light)",
  handleHoverColor: "var(--green-color-primary-light)",
  handleActiveBg: "var(--green-color-primary-light)",
  activeBg: "var(--white-colour)",
  activeBorderColor: "var(--green-color-primary-light)",
  addonBg: "rgba(0, 0, 0, 0.02)",
  errorActiveShadow: "none",
  hoverBg: "var(--white-colour)",
  inputFontSize: 14,
  inputFontSizeLG: 16,
  inputFontSizeSM: 14,
  paddingBlock: 4,
  paddingBlockLG: 7,
  paddingBlockSM: 0,
  paddingInline: 11,
  paddingInlineLG: 11,
  paddingInlineSM: 7,
  warningActiveShadow: "none",

  //Global
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryActive: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
};

//Input Number
export const inputNumberTheme = {
  activeBg: "var(--white-colour)",
  activeBorderColor: "var(--green-color-primary-light)",
  activeShadow: "none",
  addonBg: "rgba(0, 0, 0, 0.02)",
  errorActiveShadow: "none",
  filledHandleBg: "#f0f0f0",
  handleActiveBg: "rgba(0, 0, 0, 0.02)",
  handleBg: "var(--white-colour)",
  handleBorderColor: "var(--green-color-primary-light)",
  handleFontSize: 7,
  handleHoverColor: "var(--green-color-primary-light)",
  handleWidth: 22,
  hoverBg: "var(--white-colour)",
  hoverBorderColor: "var(--green-color-primary-light)",
  inputFontSize: 14,
  inputFontSizeLG: 16,
  inputFontSizeSM: 14,
  paddingBlock: 4,
  paddingBlockLG: 7,
  paddingBlockSM: 0,
  paddingInline: 11,
  paddingInlineLG: 11,
  paddingInlineSM: 7,
  warningActiveShadow: "none",
  colorPrimary: "var(--green-color-primary-light)",
  controlHeight: 40,
  controlHeightLG: 40,
  controlHeightSM: 40,
  controlItemBgActive: "var(--green-color-secondary-light-alpha)",
  colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
  colorText: "var(--black-color)",
  colorBorder: "var(--lightgray-colour)",
  colorBgContainer: "var(--white-colour)",
  colorBgContainerDisabled: "var(--lightgray-colour)",
  colorIconHover: "var(--green-color-primary-light)",
  colorTextDisabled: "var(--lightgray-colour)",
};

//DatePicker
export const datePickerTheme = {
  controlHeight: 40,
  controlHeightLG: 40,
  controlHeightSM: 40,
  cellHoverBg: "var(--green-color-secondary-light-alpha)",
  activeBg: "var(--white-colour)",
  activeBorderColor: "var(--green-color-primary-light)",
  activeShadow: "none",
  addonBg: "rgba(0, 0, 0, 0.02)",
  cellActiveWithRangeBg: "var(--green-color-secondary-light-alpha)",
  cellBgDisabled: "rgba(0, 0, 0, 0.04)",
  cellHeight: 24,
  cellHoverWithRangeBg: "var(--green-color-secondary-light-alpha)",
  cellRangeBorderColor: "var(--green-color-primary-light)",
  cellWidth: 36,
  errorActiveShadow: "0 0 0 2px rgba(255, 38, 5, 0.06)",
  hoverBg: "var(--white-colour)",
  hoverBorderColor: "var(--green-color-primary-light)",
  inputFontSize: 14,
  multipleItemBg: "var(--green-color-secondary-light-alpha)",
  multipleItemBorderColor: "transparent",
  multipleItemBorderColorDisabled: "transparent",
  multipleItemColorDisabled: "var(--lightgray-colour)",
  multipleItemHeight: 24,
  multipleItemHeightLG: 32,
  multipleItemHeightSM: 16,
  multipleSelectorBgDisabled: "rgba(0, 0, 0, 0.04)",
  colorIcon: "var(--green-color-primary-light)",
  colorIconHover: "var(--green-color-primary-light)",
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-primary-light)",
  colorText: "var(--black-color)",
  colorTextDescription: "rgba(0, 0, 0, 0.45)",
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  colorTextHeading: "rgba(0, 0, 0, 0.88)",
  colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
  colorTextTertiary: "rgba(0, 0, 0, 0.45)",
  borderRadius: 6,
  boxShadowSecondary: "none",
  controlItemBgActive: "var(--green-color-secondary-light-alpha)",
  fontSize: 14,
  fontWeightStrong: 600,
  fontWeight: 400,
  lineWidthBold: 1,
  lineWidth: 1,
  sizePopupArrow: 16,
  colorFillSecondary: "rgba(0, 0, 0, 0.06)",
  colorFillTertiary: "rgba(0, 0, 0, 0.04)",
  colorBorder: "#d9d9d9",
  colorBgElevated: "var(--white-colour)",
  colorTextLightSolid: "var(--white-colour)",
};

// Select input Field
export const selectInputTheme = {
  clearBg: "var(--white-colour)",
  multipleItemBg: "var(--green-color-secondary-light-alpha)",
  multipleItemBorderColor: "transparent",
  multipleItemBorderColorDisabled: "transparent",
  multipleItemColorDisabled: "var(--lightgray-colour)",
  multipleItemHeight: 24,
  multipleItemHeightLG: 32,
  multipleItemHeightSM: 16,
  multipleSelectorBgDisabled: "rgba(0, 0, 0, 0.04)",
  optionActiveBg: "var(--green-color-secondary-light-alpha)",
  optionFontSize: 14,
  optionHeight: 32,
  optionHeightLG: 32,
  optionHeightSM: 16,
  optionLineHeight: 1.5715,
  optionLineHeightLG: 1.5715,
  optionLineHeightSM: 1.5715,
  optionPaddingHorizontal: 12,
  optionSelectedBg: "var(--green-color-secondary-light-alpha)",
  optionSelectedColor: "rgba(0, 0, 0, 0.88)",
  optionSelectedFontWeight: 600,
  selectorBg: "var(--white-colour)",
  showArrowPaddingInlineEnd: 18,
  singleItemHeightLG: 40,
  zIndexPopup: 9999,
  //Global
  colorBgBase: "var(--white-colour)",
  colorBgContainer: "var(--white-colour)",
  colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
  colorBgElevated: "var(--white-colour)",
  colorFillSecondary: "rgba(0, 0, 0, 0.06)",
  colorFillTertiary: "rgba(0, 0, 0, 0.04)",
  colorPrimary: "var(--green-color-primary-light)",
  colorTextLightSolid: "var(--white-colour)",
  colorIcon: "var(--green-color-primary-light)",
  colorIconHover: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
  colorPrimaryActive: "var(--green-color-primary-light)",
  fontFamily: "var(--paragraph-fonts)",
  fontSize: 14,
  colorSplit: "rgba(5, 5, 5, 0.06)",
  controlHeight: 40,
  controlHeightLG: 40,
  controlHeightSM: 40,
};

//Radio
export const radioTheme = {
  buttonBg: "var(--white-colour)",
  buttonCheckedBg: "var(--white-colour)",
  buttonCheckedBgDisabled: "rgba(0, 0, 0, 0.015)",
  buttonCheckedColorDisabled: "rgba(0, 0, 0, 0.25)",
  buttonColor: "var(--black-color)",
  buttonPaddingInline: 15,
  buttonSolidCheckedActiveBg: "var(--green-color-primary-light)",
  buttonSolidCheckedBg: "var(--green-color-primary-light)",
  buttonSolidCheckedColor: "var(--white-colour)",
  buttonSolidCheckedColorDisabled: "rgba(0, 0, 0, 0.25)",
  buttonSolidColor: "var(--green-color-primary-light)",
  buttonSolidColorDisabled: "rgba(0, 0, 0, 0.25)",
  buttonSolidCheckedHoverBg: "var(--green-color-primary-light)",
  buttonSolidHoverColor: "var(--white-colour)",
  buttonSolidPaddingInline: 15,
  dotColorDisabled: "rgba(0, 0, 0, 0.25)",
  dotColor: "var(--black-color)",
  dotColorHover: "var(--green-color-primary-light)",
  dotSize: 8,
  radioSize: 16,
  wrapperMarginInlineEnd: 8,

  //Global
  colorBorder: "var(--border-color-light)",
  controlHeight: 40,
  controlHeightLG: 40,
  controlHeightSM: 40,
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
};

//Checkbox
export const checkboxTheme = {
  //Global
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--border-color-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
  colorText: "var(--black-color)",
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  colorTextDisabledChecked: "rgba(0, 0, 0, 0.25)",
  colorTextDisabledHover: "rgba(0, 0, 0, 0.25)",
  colorTextHover: "var(--green-color-primary-light)",
  borderRadiusSM: 4,
  controlInteractiveSize: 16,
  colorBorder: "var(--border-color-light)",
  colorBgContainerDisabled: "rgba(0, 0, 0, 0.04)",
  colorBgContainer: "var(--white-colour)",
  lineWidthBold: 2,
  lineWidthFocus: 4,
  marginXS: 8,
  paddingXS: 8,
  lineType: "solid",
  lineHeight: 1.5715,
  fontSizeLG: 16,
  fontSize: 14,
  fontFamily: "var(--paragraph-fonts)",
};

//Switch
export const switchTheme = {
  handleBg: "#fff",
  handleShadow: "none",
  handleSize: 18,
  handleSizeSM: 12,
  handleSizeLG: 20,
  handleBorder: "1px solid var(--green-color-primary-light)",
  handleBorderRadius: 100,
  handleBorderRadiusSM: 100,
  handleBorderRadiusLG: 100,
  handleColor: "var(--green-color-primary-light)",
  innerMaxMargin: 24,
  innerMaxMarginSM: 18,
  innerMinMargin: 9,
  innerMinMarginSM: 6,
  trackHeight: 22,
  trackHeightSM: 16,
  trackMinWidth: 44,
  trackMinWidthSM: 28,
  trackPadding: 2,

  // Global
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
  colorText: "var(--white-colour)",
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  colorTextDisabledChecked: "rgba(0, 0, 0, 0.25)",
  colorTextDisabledHover: "rgba(0, 0, 0, 0.25)",
  colorTextHover: "var(--green-color-primary-light)",
  opacityLoading: 0.65,
};

//Form
export const formTheme = {
  inlineItemMarginBottom: 0,
  itemMarginBottom: 24,
  labelColonMarginInlineEnd: 8,
  labelColonMarginInlineStart: 2,
  labelColor: "#000",
  labelFontSize: 14,
  labelHeight: 32,
  labelRequiredMarkColor: "var(--green-color-primary-light)",
  verticalLabelMargin: 0,
  verticalLabelPadding: "0 0 8px 0",
  labelFontWeight: 600,

  // Global
  colorBorder: "var(--border-color-light)",
  colorPrimary: "var(--green-color-primary-light)",
};

// Tootltip
export const tooltipTheme = {
  zIndexPopup: 1070,

  // Global
  colorBgSpotlight: "var(--green-color-primary-light)",
  colorText: "var(--white-colour)",
  colorTextLightSolid: "var(--white-colour)",
  borderRadius: 6,
  borderRadiusXS: 2,
  boxShadowSecondary: "none",
  controlHeight: 32,
  fontSize: 14,
  paddingSM: 12,
  paddingXS: 8,
  sizePopupArrow: 16,
};

// Tour
export const tourTheme = {
  closeBtnSize: 22,
  primaryNextBtnHoverBg: "rgb(240, 240, 240)",
  primaryPrevBtnBg: "rgba(255, 255, 255, 0.15)",
  zIndexPopup: 1070,

  // Global
  colorIcon: "var(--green-color-primary-light)",
  colorIconHover: "var(--green-color-primary-light)",
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-primary-light)",
  colorText: "var(--black-color)",
  borderRadius: 6,
  borderRadiusXS: 2,
  borderRadiusLG: 8,
  borderRadiusSM: 4,
  boxShadowTertiary: "none",
  fontSize: 14,
  padding: 16,
  sizePopupArrow: 16,
  fontWeightStrong: 600,
  lineHeight: 1.5715,
  lineWidthFocus: 4,
  marginXS: 8,
  paddingXS: 8,
};

//Menu
export const menuTheme = {
  activeBarBorderWidth: 1,
  activeBarHeight: 2,
  activeBarWidth: 0,
  collapsedIconSize: 16,
  collapsedWidth: 80,
  dangerItemActiveBg: "#fff2f0",
  dangerItemColor: "#ff4d4f",
  dangerItemHoverColor: "#ff4d4f",
  dangerItemSelectedBg: "#fff2f0",
  dangerItemSelectedColor: "#ff4d4f",
  darkDangerItemActiveBg: "#ff4d4f",
  darkDangerItemColor: "#ff4d4f",
  darkDangerItemHoverColor: "ff7875",
  darkDangerItemSelectedColor: "#fff",
  darkGroupTitleColor: "rgba(255, 255, 255, 0.65)",
  darkItemBg: "var(--green-color-primary-light)",
  darkItemColor: "var(--white-colour)",
  darkItemDisabledColor: "rgba(255, 255, 255, 0.25)",
  darkItemHoverBg: "transparent",
  darkItemHoverColor: "var(--white-colour)",
  darkItemSelectedBg: "var(--green-color-primary-light)",
  darkItemSelectedColor: "var(--white-colour)",
  darkPopupBg: "#001529",
  darkSubMenuItemBg: "#000c17",
  dropdownWidth: 160,
  groupTitleColor: "rgba(0, 0, 0, 0.45)",
  groupTitleFontSize: 14,
  groupTitleLineHeight: 1.5715,
  horizontalItemBorderRadius: 0,
  horizontalItemHoverBg: "transparent",
  horizontalItemHoverColor: "var(--green-color-primary-light)",
  horizontalItemSelectedBg: "transparent",
  horizontalItemSelectedColor: "var(--green-color-primary-light)",
  horizontalLineHeight: "46px",
  iconMarginInlineEnd: 10,
  iconSize: 14,
  itemActiveBg: "var(--green-color-secondary-light-alpha)",
  itemBg: "var(--white-colour)",
  itemBorderRadius: 8,
  itemColor: "var(--black-color)",
  itemDisabledColor: "rgba(0, 0, 0, 0.25)",
  itemHeight: 40,
  itemHoverBg: "var(--green-color-secondary-light-alpha)",
  itemHoverColor: "var(--green-color-primary-light)",
  itemMarginBlock: 4,
  itemMarginInline: 4,
  itemPaddingInline: 16,
  itemSelectedBg: "var(--green-color-secondary-light-alpha)",
  itemSelectedColor: "var(--green-color-primary-light)",
  subMenuItemBg: "var(--white-colour)",
  subMenuItemBorderRadius: 4,
  zIndexPopup: 1050,

  // Global
  colorPrimaryBorder: "var(--green-color-secondary-light-alpha)",
  colorSplit: "rgba(5, 5, 5, 0.06)",
  colorText: "rgba(0, 0, 0, 0.88)",
  borderRadius: 6,
  borderRadiusLG: 8,
  boxShadowSecondary: "none",
  controlHeightLG: 40,
  colorBorder: "var(--border-color-light)",
};

//Card
export const cardTheme = {
  actionsBg: "var(--white-colour)",
  actionsLiMargin: "12px 0",
  extraColor: "rgba(0, 0, 0, 0.88)",
  headerBg: "transparent",
  headerFontSize: 16,
  headerFontSizeSM: 14,
  headerHeight: 56,
  headerHeightSM: 38,
  tabsMarginBottom: -17,

  // Global
  colorPrimary: "var(--green-color-primary-light)",
  colorText: "var(--black-color)",
  colorFillAlter: "rgba(0, 0, 0, 0.02)",
  colorBorderSecondary: "var(--border-color-light)",
  colorTextDescription: "rgba(0, 0, 0, 0.45)",
  colorTextHeading: "var(--black-color)",
  boxShadowTertiary: "none",
  fontWeightStrong: 600,
  colorBorder: "var(--border-color-light)",
};

//Steps
export const stepsTheme = {
  customIconFontSize: 24,
  iconSize: 40,
  customIconTop: 0,
  descriptionMaxWidth: 140,
  dotCurrentSize: 10,
  dotSize: 8,
  iconFontSize: 14,
  customIconSize: 32,
  iconTop: -0.5,
  iconSizeSM: 24,
  navArrowColor: "rgba(0, 0, 0, 0.25)",
  navContentMaxWidth: "auto",
  titleLineHeight: 32,

  //Global
  colorBorderBg: "#ffffff",
  colorBorderSecondary: "#f0f0f0",
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-secondary-light-alpha)",
  colorTextDescription: "rgba(0, 0, 0, 0.45)",
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  colorText: "var(--black-color)",
  colorTextLightSolid: "#fff",
  colorTextQuaternary: "rgba(0, 0, 0, 0.25)",
  borderRadiusSM: 4,
  controlHeight: 32,
  controlHeightLG: 40,
  controlHeightSM: 24,
  fontWeightStrong: 600,
  controlItemBgHover: "rgba(0, 0, 0, 0.04)",
  fontSize: 14,
  fontSizeIcon: 12,
  fontSizeLG: 16,
  fontSizeSM: 12,
  borderRadius: 10,
};

// Tags theme
export const tagsTheme = {
  defaultColor: "var(--green-color-primary-light)",
  defaultBg: "var(--green-color-secondary-light-alpha)",

  // Global
  colorBorder: "var(--green-color-primary-light)",
  colorFillSecondary: "var(--green-color-secondary-light-alpha)",
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryActive: "var(--green-color-primary-light)",
  colorPrimaryHover: "var(--green-color-primary-light)",
  colorText: "var(--green-color-primary-light)",
  colorTextDescription: "rgba(0, 0, 0, 0.45)",
  colorTextHeading: "var(--black-color)",
  colorTextLightSolid: "#fff",
  borderRadiusSM: 4,
  fontFamily: "var(--paragraph-fonts)",
  fontSize: 14,
  fontSizeIcon: 12,
  fontSizeSM: 12,
};
//Empty Theme
export const emptyTheme ={
  colorTextDescription: `rgba(0, 0, 0, 0.45)`,
  controlHeightLG:30,
  fontSize:12,
  lineHeight: 1.5714285714285714,
  margin:16,
  marginXL:32,
  marginXS:8,
  opacityImage:1,
}

//Dropdown
export const dropdownTheme = {
  paddingBlock: 5,
  zIndexPopup: 1050,

  // Global
  colorBgElevated: "#ffffff",
  colorError: "#ff4d4f",
  colorPrimary: "var(--green-color-primary-light)",
  colorPrimaryBorder: "var(--green-color-primary-light)",
  colorSplit: "rgba(5, 5, 5, 0.06)",
  colorText: "var(--black-color)",
  colorTextDescription: "rgba(0, 0, 0, 0.45)",
  colorTextHeading: "var(--black-color)",
  colorTextLightSolid: "#fff",
  borderRadiusXS: 2,
  fontFamily: "var(--paragraph-fonts)",
  fontSize: 14,
  fontSizeSM: 12,
  fontSizeLG: 16,
  lineHeight: 1.5714285714285714,
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  borderRadiusLG: 8,
  borderRadiusSM: 4,
  boxShadowSecondary: "none",
  controlItemBgActive: "var(--green-color-secondary-light-alpha)",
  controlItemBgActiveHover: "var(--green-color-secondary-light-alpha)",
  controlItemBgHover: "var(--green-color-secondary-light-alpha)",
  controlPaddingHorizontal: 12,
  fontSizeIcon: 12,
  lineWidthFocus: 4,
  marginXS: 8,
  marginXXS: 4,
  sizePopupArrow: 16,
}

//Description Theme
export const descriptionTheme = {
  colonMarginLeft: 2,
  colonMarginRight: 8,
  contentColor: "var(--black-color)",
  extraColor: "var(--black-color)",
  itemPaddingBottom: 16,
  itemPaddingEnd: 16,
  labelBg: "var(--green-color-primary-light)",
  titleColor: "var(--white-colour)",
  titleMarginBottom: 20,

  // Global
  colorSplit: "var(--border-color-light)",
  colorText: "var(--black-color)",
  colorTextSecondary: "var(--white-colour)",
  colorTextTertiary: "rgba(0, 0, 0, 0.45)",
  borderRadiusLG: 10,
  fontFamily: "var(--paragraph-fonts)",
  fontSize: 14,
  fontSizeSM: 12,
  fontSizeLG: 16,
  lineHeight: 1.5714285714285714,
  colorTextDisabled: "rgba(0, 0, 0, 0.25)",
  borderRadiusSM: 4,
  controlHeight: 32,
  controlHeightLG: 40,
  controlHeightSM: 24,
  fontWeightStrong: 600,
  controlItemBgHover: "rgba(0, 0, 0, 0.04)",
  fontSizeIcon: 12,
  borderRadius: 10,
  lineType: 'solid',
  lineWidth: 1,
}