const getValueByPath = (obj: any, path: any) => {
  return path.split(".").reduce((acc: any, part: any) => {
    part = part?.replace(/\[(\d+)\]/, (match: any, index: any) => `.${index}`);
    return acc && acc[part];
  }, obj);
};

export const filterLogicHandler = (filterArray: any[], originalData: any) => {
  const dataSource =
    originalData?.searchedData.length > 0
      ? originalData?.searchedData
      : originalData.apiData;

  return dataSource.filter((item: any) => {
    return filterArray.every((filterCriterion) => {
      const { path, value } = filterCriterion;
      const itemValue = getValueByPath(item, path);
      return (
        itemValue !== undefined &&
        itemValue?.toString().toLowerCase().includes(value?.toLowerCase())
      );
    });
  });
};

export const searchLogicHandler = (
  value: string,
  originalData: any,
  searchKey1: string,
  searchKey2?: string
) => {
  try {
    let filterData;

    if (value.trim() !== "") {
      const dataSource =
        originalData.filteredData.length > 0
          ? originalData?.filteredData
          : originalData?.apiData;
      filterData = dataSource.filter((item: any) => {
        const valueToCheck = searchKey2
          ? item?.[searchKey1]?.[searchKey2]
          : item?.[searchKey1];

        return valueToCheck?.toLowerCase().includes(value?.toLowerCase());
      });
    } else {
      filterData =
        originalData.filteredData.length > 0
          ? originalData?.filteredData
          : originalData.apiData;
    }
    return filterData;
  } catch (error) {
    return null;
  }
};

export const searchLogicHandlerForConfigurations = (
  value: string,
  originalData: any,
  searchKey: string,
  nestedKey?: string
) => {
  try {
    let filterData;

    if (value.trim() !== "") {
      const dataSource =
        originalData.filteredData.length > 0
          ? originalData?.filteredData
          : originalData?.apiData;

      filterData = dataSource.filter((item: any) => {
        const employees = item[searchKey];
        if (Array.isArray(employees)) {
          return employees.some((employee: any) => {
            const valueToCheck = nestedKey ? employee?.[nestedKey] : employee;
            return valueToCheck?.toLowerCase().includes(value?.toLowerCase());
          });
        }
        return false;
      });
    } else {
      filterData =
        originalData.filteredData.length > 0
          ? originalData?.filteredData
          : originalData.apiData;
    }
    return filterData;
  } catch (error) {
    return null;
  }
};
