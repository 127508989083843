export const currencies = [
  "USD",
  "PKR",
  "EUR",
  "GBP",
  "JPY",
  "CAD",
  "AUD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
  "SGD",
  "HKD",
  "NOK",
  "KRW",
  "INR",
  "BRL",
  "ZAR",
  "RUB",
  "AED",
  "MXN",
  "TRY",
  "THB",
  "IDR",
  "MYR",
  "PHP",
  "SAR",
  "QAR",
  "EGP",
  "ARS",
  "COP",
  "CLP",
  "VND",
  "PLN",
  "ILS",
  "CZK",
  "HUF",
  "DKK",
];

export const employeeAssets = [
  "Electronic Devices",
  "Office Furniture",
  "Vehicles",
  "Tools and Equipment",
  "Safety Gear",
  "Uniforms and Clothing",
  "Company Credit Cards",
  "Company Credit Accounts",
  "Software Licenses",
  "Office Supplies",
  "Inventory and Stock",
  "Training and Education",
  "Travel and Accommodation",
  "Expenses for Client Entertainment",
  "Company Credit for Food Delivery",
  "Company Housing or Housing Allowance",
  "Health and Wellness Benefits",
  "Retirement Benefits",
  "Stock Options and Equity",
  "Printers",
  "Scanners",
  "Company Shares",
  "Others",
];
