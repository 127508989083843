import React from "react";
import { Table, ConfigProvider } from "antd";
import { TableProps, TablePaginationConfig } from "antd/es/table";
import "./index.css";
import { Empty } from 'antd';

interface CustomTableProps<T> extends TableProps<T> {
  pagination?: false | TablePaginationConfig; // Only allow false or TablePaginationConfig
}
const CustomTable = <T extends object>(props: CustomTableProps<T>) => {
  const staticProps = {
    bordered: true,
    locale: {
      emptyText: (
        <div className="no-data-custom-loader">{!props.loading &&
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={<span>No Data Available</span>}
          />  
        }</div>
      ),
    },
    pagination:
      props?.dataSource?.length && props?.dataSource?.length > 1
        ? {
          position: ["bottomRight"],
          total: props.dataSource.length,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 40, 50, 100],
          showTotal: (total: number, range: [number, number]) => (
            <>
              {range[0]}-{range[1]} of {total} items
            </>
          ),
        }
        : false,
  };

  const mergedProps: any = { ...staticProps, ...props };

  return (
    <div className="custom-table">
      <Table {...mergedProps} />
    </div>
  );
};

export default CustomTable;
