import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal, Popconfirm, Space } from "antd";
import CustomTable from "../../../../../common/CustomTable";

import {
  deleteSalarySheetTemplateApi,
  fetchSalarySheetTemplateApi,
} from "../../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../../common/Alert";
import EditSalarySheetTemplate from "../EditView";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";

const ListSalarySheetTemplate: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [tableData, setTableData] = useState<any[]>();
  const [tableloading, setTableLoading] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [openEditModel, setOpenEditModel] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Payroll", "Templates");

  const edit = access?.writeAccess;
  const deleteAccess = access?.deleteAccess;
  const columns: any = [
    {
      title: "Sheet Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Company",
      key: "company",
      dataIndex: "company",
      render: (record: any) => <div>{record?.name}</div>,
    },
    {
      title: "Branch",
      key: "name",
      dataIndex: "branch",
      render: (record: any) => <div>{record?.name}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <Space size="middle">
          {deleteAccess && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this record?"
              onConfirm={() => deleteSalarySheetTemplat(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
          {edit && (
            <a onClick={() => handleModalOpening(record.id)}>
              <EditOutlined />
            </a>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchSalarySheetTemplat();
  }, []);

  const fetchSalarySheetTemplat = async () => {
    setTableLoading(true);
    const data = {
      organization_Id: loggedInEmployee?.employee?.organization_id,
    };
    const res = await fetchSalarySheetTemplateApi(data);
    setTableLoading(false);
    if (res.data) {
      setTableData(res.data);
    }
  };
  const deleteSalarySheetTemplat = async (id: string) => {
    const res = await deleteSalarySheetTemplateApi(
      loggedInEmployee?.employee?.organization_id,
      id
    );
    if (res.error) {
      setShowAlert(true);
      setAlertType("error");
    } else {
      setShowAlert(true);
      setAlertType("success");
      fetchSalarySheetTemplat();
    }
  };
  const handleModalOpening = async (id: string) => {
    setOpenEditModel(true);
    setSelectedRecordId(id);
  };
  const onClose = () => {
    setOpenEditModel(false);
    setSelectedRecordId("");
  };
  const onCancel = () => {
    setOpenEditModel(false);
    setSelectedRecordId("");
    fetchSalarySheetTemplat();
  };
  return (
    <>
      <CustomTable
        dataSource={tableData}
        columns={columns}
        loading={tableloading}
      />
      <Modal
        open={openEditModel}
        width={1000}
        onCancel={onClose}
        footer={false}
        className="global-modal-main-container"
        title={<span className="global-modal-title">Edit Template</span>}
      >
        <EditSalarySheetTemplate
          recordId={selectedRecordId}
          onCancel={onCancel}
        />
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};
export default ListSalarySheetTemplate;
