import { Button, Card, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { ProgressLoader } from "../../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import { SearchOutlined } from "@ant-design/icons";
import { ResponsivePie } from "@nivo/pie";
import {
  fetchApplicationsStatusByOrganizationidandJobidApi,
  fetchBranches,
  fetchCompanies,
} from "../../../services";
import { IoSearch } from "react-icons/io5";

interface ApplicationStatusDataItem {
  id: string;
  label: string;
  value: number;
  average?: string;
}

const ShortlistedApplicationsChart = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [jobId, setJobId] = useState<string>("");
  const [applicationList, setApplicationList] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      getBranches();
    } else {
      setBranches([]);
      setSelectedBranch("");
    }
  }, [selectedCompany]);

  useEffect(() => {
    fetchApplicationsByOrganizationidandJobid();
  }, [selectedCompany, selectedBranch, organizationId, jobId]);

  const fetchApplicationsByOrganizationidandJobid = async () => {
    setShowLoader(true);
    const data = {
      organizationId: organizationId,
      jobId: jobId,
      branchId: selectedBranch,
      companyId: selectedCompany,
    };
    const res = await fetchApplicationsStatusByOrganizationidandJobidApi(data);
    if (res.data) {
      setApplicationList(res.data);
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  };

  const getCompanies = async () => {
    const response = await fetchCompanies(organizationId);

    if (response.data) {
      setCompaniesData(response.data);
    }
  };

  const getBranches = async () => {
    const response = await fetchBranches(selectedCompany, organizationId);
    if (response.data) {
      setBranches(response.data);
    }
  };

  const getUserRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  useEffect(() => {
    if (getUserRole == "Hr") {
      setSelectedCompany(loggedInEmployee?.employee?.company_id);
      setSelectedBranch(loggedInEmployee?.employee?.branch_id);
    }
  }, [getUserRole]);

  const calculateApplicationStatusData = (): ApplicationStatusDataItem[] => {
    let submittedCount = 0;
    let hiredCount = 0;
    let shortlistedCount = 0;

    applicationList.forEach((application) => {
      switch (application.application_status) {
        case "submitted":
          submittedCount += 1;
          break;
        case "hired":
          hiredCount += 1;
          break;
        case "shortlisted":
          shortlistedCount += 1;
          break;
        default:
          break;
      }
    });

    return [
      { id: "submitted", label: "Submitted", value: submittedCount },
      { id: "hired", label: "Hired", value: hiredCount },
      { id: "shortlisted", label: "Shortlisted", value: shortlistedCount },
    ];
  };

  const calculateAverage = (value: number, totalCount: number): string => {
    return totalCount > 0 ? ((value / totalCount) * 100).toFixed(2) : "0";
  };

  const dataForPieChart: ApplicationStatusDataItem[] =
    calculateApplicationStatusData();
  const totalCount = dataForPieChart.reduce((acc, item) => acc + item.value, 0);
  dataForPieChart.forEach((item) => {
    item.average = calculateAverage(item.value, totalCount);
  });

  const uniqueJobs = Array.from(
    new Set(applicationList.map((job) => job.job_id))
  ).map((jobId) => {
    return applicationList.find((job) => job.job_id === jobId);
  });

  const filteredDataForPieChart = dataForPieChart.filter(
    (item) => item.value > 0
  );

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      title={
        <Row
          gutter={[8, 8]}
          justify="space-between"
          align="middle"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Col span={24} md={10}>
            <span className="shorlisted-job-card-heading">
              Applications Status
            </span>
          </Col>

          {isToggled && (
            <>
              {/* <Col
                span={24}
                md={8}
                lg={8}
                xl={6}
                className="shortlisted-cvs-dropdown-container"
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select a company..."
                  onChange={setSelectedCompany}
                  className="totalemployee-card-selectors-width"
                >
                  {companiesData.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col
                span={24}
                md={8}
                lg={8}
                xl={5}
                className="shortlisted-cvs-dropdown-container"
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select a branch"
                  onChange={setSelectedBranch}
                  className="totalemployee-card-selectors-width"
                >
                  {branches.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col> */}
              <Col
                span={24} md={10}
                className={`header-inputs ${
                  isToggled ? "visible" : "hidden"
                }`}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select a Job..."
                  onChange={setJobId}
                  className="totalemployee-card-selectors-width"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {uniqueJobs?.map((job) => (
                    <Select.Option key={job.job_id} value={job.job_id}>
                      {job?.job?.basic_details?.jobTitle}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </>
          )}

          <Col
            span={3}
            className="working-icon-container"
            onClick={handleToggle}
          >
            {isToggled ? (
              <div className="working-search-icon-container">
                <IoSearch className="working-search-icon" />
              </div>
            ) : (
              <div className="working-search-icon-container">
                <IoSearch className="working-search-icon" />
              </div>
            )}
          </Col>
        </Row>
      }
      className="job-by-type-card-row"
    >
      {showLoader ? (
        <ProgressLoader />
      ) : applicationList.length > 0 ? (
        <div style={{ height: "350px" }}>
          <ResponsivePie
            data={filteredDataForPieChart}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.2}
            padAngle={1}
            cornerRadius={5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom1",
                background: "inherit",
                color: "#218b8c",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom2",
                background: "inherit",
                color: "#c8eae1",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom3",
                type: "none",
                background: "inherit",
                color: "var(--green-color-primary-light)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            height: "19rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoDataFoundIcon />
        </div>
      )}
    </Card>
  );
};

export default ShortlistedApplicationsChart;
