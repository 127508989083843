import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { supabase_client_for_Org_schema } from "../../../services/supabase";
import { Scheduler } from "@aldabil/react-scheduler";
import { useSelector } from "react-redux";
import CustomAlert from "../../../common/Alert";
import anniversery_icon from "../../../assets/images/scheduler-images/scheduler-anniversery-icon.png";
import appointment_icon from "../../../assets/images/scheduler-images/scheduler-appointment-icon.svg";
import birthday_icon from "../../../assets/images/scheduler-images/scheduler-birthday-icon.svg";
import holiday_icon from "../../../assets/images/scheduler-images/scheduler-holiday-icon.svg";
import meeting_icon from "../../../assets/images/scheduler-images/scheduler-meeting-icon.svg";
import reminder_icon from "../../../assets/images/scheduler-images/scheduler-reminder-icon.svg";
import announcement_icon from "../../../assets/images/scheduler-images/scheduler-announcement-icon.svg";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../common/validation";
import {
  getEvents,
  handleDeletion,
  updateEventScheduler,
  updation,
} from "./services";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../services/Global";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { Card, Empty } from "antd";

const CustomScheduler = () => {
  const eventListRef = useRef<any[]>([]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [rerenderCounter, setRerenderCounter] = useState<number>(0);
  const [fetchCompanyCounter, setFetchCompanyCounter] = useState<number>(0);
  const [eadCounter, seteadCounter] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "info" | "success" | "error" | "warning"
  >("info");
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Scheduler");

  const create = access?.createAccess;
  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  const eventOptions = [
    { id: "meeting", text: "Meeting", value: "meeting", color: "#adad85" },
    {
      id: "appointment",
      text: "Appointment",
      value: "appointment",
      color: "#009999",
    },
    {
      id: "anniversary",
      text: "Anniversary",
      value: "anniversary",
      color: "#6666ff",
    },
    { id: "reminder", text: "Reminder", value: "reminder", color: "#bf40bf" },
    { id: "birthday", text: "Birthday", value: "birthday", color: "#ffb3b3" },
    { id: "holiday", text: "Holiday", value: "holiday", color: "#ff50bf" },
    {
      id: "announcement",
      text: "Announcement",
      value: "announcement",
      color: "#ff7350",
    },
  ];

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchEvents();
  }, [eadCounter]);

  useEffect(() => {
    fetchCompanies();
    fetchBranches();
  }, []);

  const fetchCompanies = async () => {
    const resCompanies = await fetchallCompanyByOrgIdApi(organizationId);
    if (resCompanies?.data) {
      const updated = resCompanies?.data.map((item: any) => ({
        id: item.id,
        text: item.name,
        value: item.id,
      }));
      setFilteredCompanies(updated);
      setFetchCompanyCounter((prev) => prev + 1);
    }
  };

  const fetchBranches = async () => {
    const resBranches = await fetchAllBranchesByComapnyIdApi(
      organizationId,
      loggedInEmployee?.employee?.company_id
    );
    if (resBranches?.data) {
      const updated = resBranches?.data.map((item: any) => ({
        id: item.id,
        text: item.name,
        value: item.id,
      }));
      setFilteredBranches(updated);
      setFetchCompanyCounter((prev) => prev + 1);
    }
  };

  const handleConfirm = async (event: any, action: any) => {
    try {
      const body = {
        start_date_and_time: await formatDate(event.start),
        end_date_and_time: await formatDate(event.end),
        title: event.title,
        description: event.description,
        event_id:
          action === "create"
            ? `${event.title}${await formatDate(event.start)}-${organizationId}`
            : event.event_id,
        organization_id: organizationId,
        created_by_Id: loggedInEmployee?.employeeId,
        type_of_event: event.typeofevent,
        company_id: event.companyId || null,
        branch_id: event.branchId || null,
        color:
          eventOptions.find((option) => option.value === event.typeofevent)
            ?.color || "gray",
      };
      let eventSchedulerRes;
      if (action === "create") {
        eventSchedulerRes = await supabase_client_for_Org_schema(organizationId)
          .from("event_scheduler")
          .insert(body);
      } else if (action === "edit") {
        const response = await updateEventScheduler(body);
        if (response.data) {
          eventListRef.current = [...eventListRef.current, event];
          seteadCounter(eadCounter + 1);

          setAlertType("success");
          setshowAlert(true);
        } else if (response.error) {
          setAlertType("error");
          setshowAlert(true);
        }
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
    return event;
  };

  const formatDate = async (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDelete = async (eventId: string) => {
    try {
      const response = await handleDeletion(eventId, organizationId);

      if (response.error) {
        setAlertType("error");
        setshowAlert(true);
      } else {
        seteadCounter(eadCounter + 1);
        setAlertType("success");
        setshowAlert(true);
      }
    } catch (error) {}
  };

  const fetchEvents = async () => {
    try {
      const Ids = {
        organizationId: organizationId,
        companyId: loggedInEmployee?.employee.company_id,
        branchId: loggedInEmployee?.employee.branchId,
      };
      const response = await getEvents(Ids);
      if (response.data) {
        const events = response.data.map((item) => ({
          id: item.id,
          event_id: item.event_id,
          title: item.title,
          description: item.description,
          typeofevent: item.type_of_event,
          branchId: item.branch_id,
          companyId: item.company_id,
          start: new Date(item.start_date_and_time),
          end: new Date(item.end_date_and_time),
          color:
            eventOptions.find((option) => option.value === item.type_of_event)
              ?.color || "gray",
        }));
        setRerenderCounter(rerenderCounter + 1);
        eventListRef.current = events;
      }
    } catch (error) {
      return error;
    }
  };

  const handleEventDrop = async (updatedEvent: any, originalEvent: any) => {
    const updatedBody = {
      start_date_and_time: originalEvent.start,
      end_date_and_time: originalEvent.end,
      title: originalEvent.title,
      description: originalEvent.description,
      event_id: originalEvent.event_id,
      organization_id: organizationId,
      created_by_Id: loggedInEmployee?.employeeId,
      type_of_event: originalEvent.type_of_event,
      color:
        eventOptions.find((option) => option.value == originalEvent.typeofevent)
          ?.color || "gray",
    };

    try {
      const Body = {
        body: updatedBody,
        id: originalEvent.event_id,
      };

      const response = await updation(Body, organizationId);

      if (response.data) {
        eventListRef.current = [...eventListRef.current, originalEvent];
        seteadCounter(eadCounter + 1);
        setAlertType("success");
        setshowAlert(true);
      } else if (response.error) {
        setAlertType("error");
        setshowAlert(true);
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }

    return originalEvent;
  };

  return (
    <div key={fetchCompanyCounter}>
      <Card className="body-content-card-container">
        {read ? (
          <Scheduler
            view="month"
            timeZone={
              Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Karachi"
            }
            fields={[
              {
                name: "companyId",
                type: "select",
                default: loggedInEmployee?.employee?.company_id || "",

                config: {
                  label: "Select Company",
                  required: true,
                  disabled: loggedInEmployee?.employee?.company_id
                    ? true
                    : false,
                },

                options: filteredCompanies,
              },

              {
                name: "branchId",
                type: "select",
                default: loggedInEmployee?.employee?.branch_id || "",
                config: {
                  label: "Select Branch",
                  disabled: loggedInEmployee?.employee?.branch_id
                    ? true
                    : false,
                },
                options: filteredBranches,
              },
              {
                name: "description",
                type: "input",
                config: { label: "Description", multiline: true, rows: 4 },
              },
              {
                name: "typeofevent",
                type: "select",
                config: { label: "Type Of Event" },
                options: eventOptions,
              },
            ]}
            events={eventListRef.current}
            onConfirm={handleConfirm}
            onDelete={handleDelete}
            eventRenderer={({ event }: any) => {
              const formatTime = (date: Date) => {
                return date?.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                });
              };
              return (
                <div
                  className="admin-custom-event"
                  style={{ backgroundColor: event.color }}
                >
                  <div className="admin-typeOf-event">
                    Event :{" " + capitalizeWords(event?.type_of_event)}
                  </div>
                  <div className="set-position-event-details">
                    <div className="event-title">
                      {capitalizeWords(event.title)}
                    </div>
                    <div className="event-start-end-time">
                      {formatTime(event?.start)}-{formatTime(event?.end)}
                    </div>
                    {event.type_of_event === "meeting" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={meeting_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "appointment" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={appointment_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "anniversary" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={anniversery_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "reminder" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={reminder_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "birthday" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={birthday_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "holiday" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={holiday_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                    {event.typeofevent === "announcement" && (
                      <div className="svg-class">
                        <img
                          className="svg-icon-class"
                          src={announcement_icon}
                          alt=""
                        />{" "}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
            onEventDrop={handleEventDrop}
            week={{
              weekDays: [0, 1, 2, 3, 4, 5],
              weekStartOn: 6,
              step: 30,
              startHour: 0,
              endHour: 24,
            }}
            day={{
              startHour: 0,
              endHour: 24,
              step: 30,
            }}
            deletable={deletee}
            editable={create}
            draggable={edit}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </Card>
    </div>
  );
};

export default CustomScheduler;
