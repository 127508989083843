import React from "react";
import { Col, Tabs, Row } from "antd";
import TimeAtWork from "../../../components/EmployeePortal/Dashboard/TimeAtWork";
import PerformanceMetrics from "../../../components/EmployeePortal/Dashboard/PerformanceMetrics";
import LeaveBalancesAndHistory from "../../../components/EmployeePortal/Dashboard/LeaveBalancesAndHistory";

import "../index.css";
const { TabPane } = Tabs;

const ChartsMenu = () => {
  return (
    <div className="dasboard-analytics-menu">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Time & Attendance" key="1">
          <Row>
            <Col span={24}>
              <TimeAtWork />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Leave Management" key="2">
          <Row>
            <Col span={24}>
              <LeaveBalancesAndHistory />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Performance Metrics" key="3">
          <Row>
            <Col span={24}>
              <PerformanceMetrics />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ChartsMenu;
