import React, { useEffect, useState } from "react";
import "./index.css";
import { Space, Popconfirm, TablePaginationConfig, Empty, Modal } from "antd";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import { useSelector } from "react-redux";
import CustomTable from "../../../../../common/CustomTable";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

import EditExperienceModal from "../Edit";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../../common/SearchAndFilterFunction";
import ExperienceFilterModal from "../Filter";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface ExperiencesListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const Experiences: React.FC<ExperiencesListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const org_id = useSelector(selectLogedEmployee);
  const [tableDataSource, setTableDataSource] = useState<any[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const organizationId = org_id.logedInemployee?.employee?.organization_id;
  const company = useSelector(selectLogedEmployee);
  const companyId = company.logedInemployee?.employee.company_id;
  const branchId = company.logedInemployee?.employee.branch_id;

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Employee", "Experiences");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableDataSource(originalData.apiData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchData = async () => {
    setIsModalVisible(false);
    setLoading(true);
    try {
      let query = supabase_client_for_Org_schema(organizationId)
        .from("employee_work_experience")
        .select(`*, employee(full_name) as employee`)
        .eq("organization_id", organizationId);

      if (companyId) {
        query = query.eq("company_id", companyId);
      }
      if (branchId) {
        query = query.eq("branch_id", branchId);
      }

      const response = await query;

      if (response.error) {
        setLoading(false);
        return;
      }

      if (response.data) {
        const updatedList = response.data.filter((item: any) => item?.employee);
        setTableDataSource(updatedList);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: updatedList,
        }));
      } else {
        setTableDataSource([]);
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (recordId: number) => {
    setLoading(true);
    const { data, error } = await supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .delete()
      .eq("id", recordId);

    if (error) {
      setshowAlert(true);
      setAlertType("error");
      setLoading(false);
    } else {
      setshowAlert(true);
      setAlertType("success");
      setLoading(false);
      fetchData();
    }
  };

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleEditClick = (recordId: number) => {
    setSelectedRecordId(recordId);
    setIsModalVisible(true);
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableDataSource(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (jobTitle: string) => {
    const filterArray = [{ path: "jobTitle", value: jobTitle }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableDataSource(response);
    }
  };

  const tableColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) =>
        (a.employee.full_name || "").localeCompare(b.employee.full_name || ""),
      render: (employee: any) => <span>{employee?.full_name}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleEditClick(record.id)}>
              <EditOutlined />
            </a>
          )}
          {deletee && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this Experience?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record: any) => {
    const experiences = record.details.map((detail: any) => ({
      ...detail,
      key: detail.id,
    }));

    const columns = [
      { title: "Company", dataIndex: "company", key: "company" },
      { title: "Job Title", dataIndex: "jobTitle", key: "jobTitle" },
      { title: "From", dataIndex: "from", key: "from" },
      { title: "To", dataIndex: "to", key: "to" },
    ];

    return (
      <CustomTable
        dataSource={experiences}
        columns={columns}
        pagination={false}
      />
    );
  };

  return (
    <>
      <div className="experience-table">
        {read ? (
          <CustomTable
            columns={tableColumns}
            dataSource={tableDataSource}
            rowKey="id"
            loading={loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <ExperienceFilterModal
          getFilterDataHandler={(jobTitle) => getFilterDataHandler(jobTitle)}
        />
      </Modal>

      {isModalVisible && (
        <EditExperienceModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          selectedRecordId={selectedRecordId}
          fetchData={fetchData}
        />
      )}

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default Experiences;
