import { Button, Card, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { ProgressLoader } from "../../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";

import {
  fetchAllTypeJobApi,
  fetchBranches,
  fetchCompanies,
} from "../../../services";
import { IoSearch } from "react-icons/io5";

interface JobTypeDataItem {
  id: string;
  label: string;
  value: number;
  percent?: string;
}

const JobByTypesChart = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [jobList, setjobList] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [isToggled, setIsToggled] = useState<boolean>(false);

  useEffect(() => {
    fetchJob();
  }, [selectedCompany, selectedBranch, organizationId]);

  useEffect(() => {
    getCompanies();
  }, []);

  const fetchJob = async () => {
    setShowLoader(true);
    const data = {
      organizationId: organizationId,
      branchId: selectedBranch,
      companyId: selectedCompany,
    };

    const res = await fetchAllTypeJobApi(data);

    if (res.data) {
      setjobList(res.data);
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  };

  const getCompanies = async () => {
    const response = await fetchCompanies(organizationId);

    if (response.data) {
      setCompaniesData(response.data);
    }
  };

  const getBranches = async () => {
    const response = await fetchBranches(selectedCompany, organizationId);
    if (response.data) {
      setBranches(response.data);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      getBranches();
    } else {
      setBranches([]);
      setSelectedBranch("");
    }
  }, [selectedCompany]);

  const getUserRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  useEffect(() => {
    if (getUserRole === "Hr") {
      setSelectedCompany(loggedInEmployee?.employee?.company_id);
      setSelectedBranch(loggedInEmployee?.employee?.branch_id);
    }
  }, [getUserRole]);

  const calculateJobTypeData = (): JobTypeDataItem[] => {
    const jobTypeCounts: Record<string, number> = {};

    jobList.forEach((job) => {
      const jobTypes = job.basic_details?.jobType;
      if (Array.isArray(jobTypes)) {
        jobTypes.forEach((type) => {
          jobTypeCounts[type] = (jobTypeCounts[type] || 0) + 1;
        });
      } else {
        const type = jobTypes || "Other";
        jobTypeCounts[type] = (jobTypeCounts[type] || 0) + 1;
      }
    });

    return Object.entries(jobTypeCounts)
      .map(([key, value]) => ({
        id: key,
        label: key,
        value: value,
        percent:
          (
            (value /
              Object.values(jobTypeCounts).reduce(
                (acc, count) => acc + count,
                0
              )) *
            100
          ).toFixed(2) + "%",
      }))
      .filter((data) => data.value >= 1); // Exclude values less than 1
  };

  const dataForPieChart = calculateJobTypeData();

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      title={
        <Row
          gutter={[8, 8]}
          justify="space-between"
          align="middle"
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Col span={24} md={7}>
            <span className="shorlisted-job-card-heading">Job Types</span>
          </Col>

          {isToggled && (
            <>
              <Col
                span={24}
                md={7}
                className={`header-inputs ${isToggled ? "visible" : "hidden"}`}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select a company..."
                  onChange={setSelectedCompany}
                  className="totalemployee-card-selectors-width"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companiesData.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col
                span={24}
                md={7}
                className={`header-inputs ${isToggled ? "visible" : "hidden"}`}
              >
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Select a branch"
                  onChange={setSelectedBranch}
                  className="totalemployee-card-selectors-width"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </>
          )}

          <Col
            span={3}
            className="working-icon-container"
            onClick={handleToggle}
          >
            {isToggled ? (
              <div className="working-search-icon-container">
                <IoSearch className="working-search-icon" />
              </div>
            ) : (
              <div className="working-search-icon-container">
                <IoSearch className="working-search-icon" />
              </div>
            )}
          </Col>
        </Row>
      }
      className="job-by-type-card-row"
    >
      {showLoader ? (
        <ProgressLoader />
      ) : jobList.length > 0 ? (
        <div style={{ height: "350px", width: "100%" }}>
          <ResponsivePie
            data={dataForPieChart}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.2}
            padAngle={1}
            cornerRadius={5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom1",
                background: "inherit",
                color: "#218b8c",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom2",
                background: "inherit",
                color: "#c8eae1",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom3",
                type: "none",
                background: "inherit",
                color: "var(--green-color-primary-light)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
          />
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            height: "19rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoDataFoundIcon />
        </div>
      )}
    </Card>
  );
};

export default JobByTypesChart;
