import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Button, Table, Modal } from "antd";
import CustomAlert from "../../../../../common/Alert";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { getTheSkillsAgainstRecord, updateSkills } from "../services";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../../../../../common/CustomTable";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";

interface props {
  visible: any;
  onCancel: () => void;
  recordId: any;
  refreshTable: () => void;
}

const EditSkills: React.FC<props> = ({
  visible,
  onCancel,
  recordId,
  refreshTable,
}) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = useForm();
  const organization_id =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    getTheRecords();
  }, [recordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getTheRecords = async () => {
    setLoading(true);
    const response = await getTheSkillsAgainstRecord(recordId, organization_id);

    if (response && response.data) {
      setLoading(false);
      setTableData(response.data.skill_details);
    } else {
      setLoading(false);
      setTableData([]);
    }
  };
  const handleAddToTable = () => {
    form
      .validateFields()
      .then(() => {
        const newRecord = {
          id: Date.now(),
          skill: form.getFieldValue("skill"),
          experience: form.getFieldValue("yearsOfExperience"),
          comment: form.getFieldValue("comments"),
        };

        setTableData([...tableData, newRecord]);
      })
      .catch((errorInfo) => {
        return errorInfo;
      });
  };

  const handleSkillUpdation = async () => {
    if (tableData.length == 0) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      const response = await updateSkills(tableData, recordId, organization_id);
      if (response.status == 201 || response.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        form.resetFields();
        setTableData([]);
        refreshTable();
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
    }
  };
  const handleRemoveFromTable = async (recordId: any) => {
    const filteredRecords = tableData.filter(
      (item: any) => item.id !== recordId
    );
    setTableData(filteredRecords);
  };

  const columns = [
    {
      title: "Skills",
      dataIndex: "skill",
      key: "skill",
    },
    {
      title: "Years of Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <a>
          <DeleteOutlined onClick={() => handleRemoveFromTable(record.id)} />
        </a>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      width={1000}
      onCancel={onCancel}
      footer={false}
      title={<span className="global-modal-title">Edit Skill</span>}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form layout="vertical" autoComplete="off" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Skill"
                name="skill"
                rules={[{ required: true, message: "Please Enter Skill!" }]}
              >
                <Input size="large" placeholder="Enter Skill" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Years of Experience"
                name="yearsOfExperience"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Years of Experience!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Year of Experience" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Comments" name="comments">
                <Input.TextArea placeholder="Enter Comment..." />
              </Form.Item>
            </Col>
          </Row>
          <Row className="add-btn">
            <Col span={24} className="save-profile-btn">
              <Button
                onClick={handleAddToTable}
                size="large"
                className="global-btn-width skill-edit-btn-margin"
                type="primary"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
        <CustomTable
          columns={columns}
          dataSource={tableData}
          loading={loading}
        />
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleSkillUpdation}
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </Modal>
  );
};

export default EditSkills;
