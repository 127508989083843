import { useDispatch, useSelector } from "react-redux";
import { selectAccess } from "../../../redux/AccessRight";
import { useEffect, useMemo, useState } from "react";
import { Card, Empty, Modal } from "antd";
import TabsHeader from "../../common/TabsHeader";
import AssetsList from "./AssetCreation/View";
import AssignedAssetsList from "./AssetAssigning/View";
import CreateAssets from "./AssetCreation/Create";
import AssetAssigning from "./AssetAssigning/Create";
import { extractAccessRightObject } from "../../common/ExtractAccessRightObj";
import { selectOrganizationModulePermission } from "../../../redux/organizationModulePermission";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../redux/MenuBreadcrumbSlice";

const AssetManagement: React.FC = () => {
  const accessObj = useSelector(selectAccess);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const dispatch = useDispatch();

  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.Assets;
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  const radioOptions = useMemo(
    () => [
      {
        label: "Asset Creation",
        value: "Assets Creation",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Assigning Asset",
        value: "Assigning Assets",
        create: true,
        filter: false,
        showSearch: false,
      },
    ],
    []
  );

  const [selected, setSelected] = useState("Assets Creation");

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "asset"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
  }, [filteredRadioOptions]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "asset",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Assets";
    let childValue =
      value == "Assets Creation" ? "Asset Creation" : "Asset Assigning";
    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={filteredRadioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("")
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            selected={selected}
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Assets Creation" ? (
                <AssetsList
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Assigning Assets" ? (
                <AssignedAssetsList searchValue={searchValue} />
              ) : null
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <div key={fetchModalData}>
            <Modal
              title={<span className="global-modal-title">{selected}</span>}
              open={createModalVisible}
              onCancel={handleCreateModalCancel}
              width={1000}
              footer={false}
              className="global-modal-main-container"
            >
              {createModalVisible && selected === "Assets Creation" && (
                <CreateAssets onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Assigning Assets" && (
                <AssetAssigning onCancel={handleCreateModalCancel} />
              )}
            </Modal>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AssetManagement;
