import React, { useEffect, useState } from "react";
import { Card, Empty } from "antd";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../redux/AccessRight";
import { useSelector } from "react-redux";
import TabsHeader from "../../../common/TabsHeader";
import { getLeavesApprovalEmployeeIds } from "./service";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import LeaveApprovalTable from "../../OrganisationManagement/LeaveApproval/LeaveApprovalTable";

const EmloyeeRequestApprovalsComp: React.FC = () => {
    const [selected, setSelected] = useState("Leave");
    const accessObj = useSelector(selectAccess);
    const [readAccess, setReadAccess] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [fetchTableData, setFetchTableData] = useState<number>(0);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
    const orgainzationId = loggedInEmployee?.employee?.organization_id;
    const companyId = loggedInEmployee?.employee?.company_id;
    const branchId = loggedInEmployee?.employee?.branch_id;
    const loggedInemployeeId = loggedInEmployee?.employeeId

    const [leaveTableData, setLeaveTableData] = useState<any>([])
    const [showOnEmployeeProtal, setShowOnEmployeeProtal] = useState<boolean>(true)



    const radioOptions = [
        {
            label: "Leave",
            value: "Leave",
            create: true,
            filter: false,
            showSearch: false,
        },

    ];



    useEffect(() => {
        handleRadioChange(selected);
        if (selected === "Leave") {
            showLeaveApprovalTableHandler()
        }
    }, [selected, accessObj]);

    const handleRadioChange = (value: string) => {

        const access = extractAccessRightObject(accessObj, "Requests", "Leave Approval");
        setReadAccess(access?.readAccess);
    };

    const handleCreateClick = () => {
        setCreateModalVisible(true);
    };

    const handleToggleFilterModal = () => {
        setFilterModalVisible(!filterModalVisible);
    };


    const handleSearchChange = (input: string) => {
        setSearchValue(input);
    };

    const showLeaveApprovalTableHandler = async () => {
        try {
            const body = {
                orgainzationId,
                companyId,
                branchId
            }
            const res = await getLeavesApprovalEmployeeIds(body)
            if (res.data) {
                const filteredResp =  res.data?.filter((item: any) => (
                    item?.leave_approval_employee_ids?.some((temp:any)=>temp?.employeeID ===loggedInemployeeId)
                ))
  
                setLeaveTableData(filteredResp)
                filteredResp?.length > 0 ? setShowOnEmployeeProtal(true) : setShowOnEmployeeProtal(false)
                
            }
        } catch (error) {

        }
    }

    const onClose = ()=>{
        showLeaveApprovalTableHandler()
    }


    return (
        <>
            <div className="requests-module-card-container">
                <Card className="requests-module-card-inner-container">
                    <TabsHeader
                        radioOptions={radioOptions}
                        onChange={(value) => {
                            setSelected(value);
                        }}
                        onCreateClick={handleCreateClick}
                        onFilterClick={handleToggleFilterModal}
                        onSearchChange={handleSearchChange}
                        createAccess={false}
                        selected={selected}

                    />
                    <div key={fetchTableData}>
                        {readAccess ? (
                            selected === "Leave" && showOnEmployeeProtal ? (
                                <LeaveApprovalTable leaveTableData={leaveTableData} onClose={onClose}  />)
                                :  <Empty
                                description="Not Authorized For This Action"
                                image={Empty.PRESENTED_IMAGE_DEFAULT}
                            />
                        ) : (
                            <Empty
                                description="Not Authorized For This Action"
                                image={Empty.PRESENTED_IMAGE_DEFAULT}
                            />
                        )}
                    </div>


                </Card>
            </div>
        </>
    );
};

export default EmloyeeRequestApprovalsComp;
