import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchEmployeesApi } from "../../../components/EmployeesManagement/EmployeeViewDetails/services";

interface PersonalDetailsProps {
  employeeId: any;
  loadingState: (state: boolean) => void;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  employeeId,
  loadingState,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const [employeeData, setEmployeeData] = useState<any>(null);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    loadingState(true);
    const employeesRes = await fetchEmployeesApi(organizationId, employeeId);
    loadingState(false);
    if (employeesRes.data) {
      setEmployeeData(employeesRes.data);
    }
  };

  return (
    <div className="pd-container">
      {employeeData && (
        <>
          {employeeData.full_name && (
            <div className="pd-text-container">
              <span className="pd-text">Name: </span>
              <span className="pd-text-value">{employeeData.full_name}</span>
            </div>
          )}
          {employeeData.nationality && (
            <div className="pd-text-container">
              <span className="pd-text">Nationality: </span>
              <span className="pd-text-value">{employeeData.nationality}</span>
            </div>
          )}
          {employeeData.cnic && (
            <div className="pd-text-container">
              <span className="pd-text">CNIC: </span>
              <span className="pd-text-value">{employeeData.cnic}</span>
            </div>
          )}
          {employeeData.dob && (
            <div className="pd-text-container">
              <span className="pd-text">Date of Birth: </span>
              <span className="pd-text-value">
                {new Date(employeeData.dob).toLocaleDateString()}
              </span>
            </div>
          )}
          {employeeData.gender && (
            <div className="pd-text-container">
              <span className="pd-text">Gender: </span>
              <span className="pd-text-value">{employeeData.gender}</span>
            </div>
          )}
          {employeeData.marital_status && (
            <div className="pd-text-container">
              <span className="pd-text">Marital Status: </span>
              <span className="pd-text-value">
                {employeeData.marital_status}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PersonalDetails;
