import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  TimePicker,
  Tooltip,
} from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import { TbManualGearbox } from "react-icons/tb";
import CustomAlert from "../../../../common/Alert";
import { IoFlagSharp } from "react-icons/io5";
import CustomTable from "../../../../common/CustomTable";
import { capitalizeWords } from "../../../../common/validation";
import {
  getCurrentAttendanceDataByEmployeeIdApi,
  updateEmployeeAttendanceAaccommodateDataApi,
} from "../../../OrganisationManagement/AttendanceLogs/services";
import { useForm } from "antd/es/form/Form";
import dayjs, { Dayjs } from "dayjs";

interface employeeAttandanceProps {
  employeeId: string;
}
const EmployeeAttandanceDetails: React.FC<employeeAttandanceProps> = (
  props
) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const loggedInEmployeeId = loggedInEmployee?.employeeId;
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [accomodateModalVisible, setAccomodateModalVisible] =
    useState<boolean>(false);
  const [shortHoursOriginalValue, setShortHoursOriginalValue] =
    useState<any>(null);
  const [shortHours, setShortHours] = useState<any>(null);
  const [selectedRecordId, setSelectedRecordId] = useState<any>(null);
  const [accomodatedHours, setAccomodatedHours] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [form] = useForm();

  useEffect(() => {
    fetchTableData();
  }, [props.employeeId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const columns: any = [
    {
      title: "Date",
      key: "date",
      fixed: "left",
      render: (record: any) => (
        <>
          {record?.date}
          <div className="employee-attendance-logs-manual-flag">
            {record?.manually_accomodated ? (
              <Tooltip title="Manually Aaccommodate">
                <IoFlagSharp className="employee-attendance-logs-set-flag" />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    },
    {
      title: "Checked In",
      dataIndex: "check_in",
      key: "check_in",
      render: (record: any) => moment(record).format("MMM-DD hh:mm:ss"),
    },
    {
      title: "Checkin Status",
      dataIndex: "check_in_status",
      key: "check_in_status",
      render: (record: any) => capitalizeWords(record),
    },
    {
      title: "Checked Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "--",
    },
    {
      title: "Checkout Status",
      dataIndex: "check_out_status",
      key: "check_out_status",
      render: (record: any) => (record ? capitalizeWords(record) : "--"),
    },
    {
      title: "Short Hours",
      dataIndex: "short_working_hours",
      key: "short_working_hours",
      render: (record: any) => {
        if (!record) return "--";

        const timeParts = record.split(":");
        if (timeParts.length === 2) {
          const hours = timeParts[0].padStart(2, "0");
          const minutes = timeParts[1].padStart(2, "0");
          return `${hours}h : ${minutes}m`;
        }
        return capitalizeWords(record);
      },
    },
    {
      title: "Accomodated Hours",
      dataIndex: "accomodated_hours",
      key: "accomodated_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Worked Hours",
      dataIndex: "worked_hours",
      key: "worked_hours",
      render: (record: any) => {
        if (!record) return "--";
        const timeParts = record.split(":");
        if (timeParts.length === 2) {
          const hours = timeParts[0].padStart(2, "0");
          const minutes = timeParts[1].padStart(2, "0");
          return `${hours} : ${minutes}`;
        }
        return capitalizeWords(record);
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) =>
        !record.manually_accomodated &&
        record.short_working_hours !== "0" &&
        record?.check_out &&
        loggedInEmployeeId !== record?.employee?.id ? (
          <Button
            onClick={() => accomodateModalVisibleHanlder(record)}
            icon={<TbManualGearbox />}
          >
            Accomodate
          </Button>
        ) : null,
    },
  ];

  const fetchTableData = async () => {
    setTableLoading(true);
    setTableData([]);
    const data = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      employeeId: props.employeeId,
    };
    const attendance_res = await getCurrentAttendanceDataByEmployeeIdApi(data);
    if (attendance_res) {
      setTableData(attendance_res.data);
    }
    setTableLoading(false);
  };
  const handleAaccommodate = async () => {
    setButtonLoading(true);
    const data = {
      body: {
        short_working_hours: shortHours,
        accomodated_hours: accomodatedHours,
        manually_accomodated: true,
      },
      id: selectedRecordId,
    };
    const res = await updateEmployeeAttendanceAaccommodateDataApi(
      data,
      loggedInEmployee?.employee?.organization_id
    );
    if (res.status == 204) {
      fetchTableData();
      setshowAlert(true);
      setAlertType("success");
      accomodateModalCloseHanlder();
    }
    setButtonLoading(false);
  };

  const accomodateModalVisibleHanlder = (value: any) => {
    if (value?.short_working_hours) {
      setSelectedRecordId(value.id);
      setShortHours(value?.short_working_hours);
      setShortHoursOriginalValue(value?.short_working_hours);
    }
    setAccomodateModalVisible(true);
  };
  const accomodateModalCloseHanlder = () => {
    setAccomodateModalVisible(false);
    setShortHours(null);
    setAccomodatedHours(null);
    setSelectedRecordId(null);
    form.setFieldValue("accomodatedHSortHours", "");
  };

  const handleTimeChange = (
    time: Dayjs | null,
    timeString: string | string[]
  ) => {
    if (time) {
      const hours = time.hour();
      const minutes = time.minute();
      const seconds = time.second();

      const initialMoment = dayjs(shortHoursOriginalValue, "HH:mm:ss");

      const newTime = initialMoment
        .subtract(hours, "hour")
        .subtract(minutes, "minute")
        .subtract(seconds, "second");
      setShortHours(newTime.format("HH:mm:ss"));

      form.setFieldValue("accomodatedHSortHours", newTime.format("HH:mm:ss"));

      const formattedInput = dayjs()
        .startOf("day")
        .add(hours, "hour")
        .add(minutes, "minute")
        .add(seconds, "second")
        .format("HH:mm:ss");
      setAccomodatedHours(formattedInput);
    } else {
      setShortHours(shortHoursOriginalValue);
    }
  };

  return (
    <>
      <div className="body-content-card-container attendance-logs-mai-container">
        <Card className="body-content-card-inner-container">
          <div className="attendancelogs-table-content">
            <CustomTable
              scroll={{ x: true }}
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
            />
            <Modal
              title={
                <span className="global-modal-title">
                  Accomodate Short Hours
                </span>
              }
              open={accomodateModalVisible}
              onCancel={accomodateModalCloseHanlder}
              width={800}
              footer={false}
              className="global-modal-main-container"
              destroyOnClose
            >
              <div className="global-card-inner-container">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleAaccommodate}
                >
                  <Row
                    gutter={[16, 16]}
                    className="attendance-logs-accomodate-modal"
                  >
                    <Col span={24}>
                      <Form.Item
                        label="Total Short Hours"
                        name="totalShortHours"
                        initialValue={shortHoursOriginalValue}
                      >
                        <Input
                          size="large"
                          disabled
                          value={shortHoursOriginalValue}
                        />
                      </Form.Item>
                    </Col>{" "}
                    <Col span={24}>
                      <Form.Item
                        label="Accomate Hours"
                        name="accomodatedHours"
                        rules={[
                          { required: true, message: "Please enter a value" },
                        ]}
                      >
                        <TimePicker
                          placeholder="Enter number of hours to accomodate"
                          format="HH:mm:ss"
                          onChange={handleTimeChange}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>{" "}
                    <Col span={24}>
                      <Form.Item
                        label="Short hours After Accomodation"
                        name="accomodatedHSortHours"
                      >
                        <Input size="large" disabled value={shortHours} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className="global-modal-bottom-row">
                    <Button
                      className="global-primary-btn global-btn-width"
                      htmlType="submit"
                      loading={buttonLoading}
                      size="large"
                    >
                      Update
                    </Button>
                  </Row>
                </Form>
              </div>
            </Modal>
            {showAlert && (
              <CustomAlert type={alertType} showAlert={showAlert} />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default EmployeeAttandanceDetails;
