import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  DatePicker,
  Popconfirm,
  Empty,
  Modal,
} from "antd";
import "./index.css";
import "react-phone-input-2/lib/style.css";
import { FaUserCheck } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { CgOptions } from "react-icons/cg";
import { useForm } from "antd/es/form/Form";
import CustomAlert from "../../../common/Alert";
import dayjs from "dayjs";
import { TriangleLoader } from "../../../common/ReactLoader";
import {
  fetchAccessRightGroupNameApi,
  fetchAccessRightSubGroupNameApi,
  fetchAllDepartmentData,
  fetchAllDesignationData,
  fetchAllEmployeeListData,
  fetchAllJobTitlesData,
  fetchAllTeamsData,
  fetchBranchesData,
  fetchEmployeeData,
  handleUpdateData,
} from "./services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { getAllCompanies } from "../../../services/Global";
import { capitalizeWords } from "../../../common/validation";
import { supabase_admin } from "../../../services/supabase";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import AddCompanyModal from "./AddCompanyModal";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface props {
  employeeId: any;
}
const UserResponsibilityandReportInfoUpdate: React.FC<props> = ({
  employeeId,
}) => {
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [companyId, setCompanyId] = useState<string | any>("");
  const [branchesList, setBranchesList] = useState<any[]>([]);
  const [AllDesignationsList, setAllDesignationsList] = useState<any[]>([]);
  const [AllJobtitlesList, setAllJobTitlesList] = useState<any[]>([]);
  const [departmentsList, setDepartmentsList] = useState<any[]>([]);
  const [teamsList, setTeamsList] = useState<any[]>([]);
  const [employeesList, setEmployeesList] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<string | any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subGroupLoading, setSubGroupLoading] = useState<boolean>(false);
  const [subGroupOption, setSubGroupOption] = useState<any[]>([]);
  const [accessRightGroupOption, setAccessRightGroupOption] = useState<any[]>(
    []
  );
  const employesres = useRef<any>();
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [form] = useForm();
  const organization_id =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const [isParentGroupChanged, setIsParentGroupChanged] =
    useState<boolean>(false);
  const [openAddCompanyModal, setOpenAddCompanyModal] =
    useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const workTypeOptions = ["Remote", "On-site", "Hybrid"];

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Responsibility & Report To"
  );

  const read = access?.readAccess;

  useEffect(() => {
    getCompanieoption();
  }, [organization_id]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    form.setFieldsValue({
      branch: "",
      department: "",
      team: "",
      reportTo: "",
      employeeDesignation: "",
      accessRightSubGroup: "",
      accessRightGroup: "",
    });
    getAllBranches();
  }, [companyId]);
  useEffect(() => {
    const getAccessRigth = async () => {
      await fetchGroupName(organization_id, companyId, selectedBranchId);
    };
    form.setFieldsValue({
      department: "",
      team: "",
      reportTo: "",
      employeeDesignation: "",
      accessRightSubGroup: "",
      accessRightGroup: "",
    });
    getAccessRigth();
    getAllDepartments();
    getAllTeams();
    getAllEmployees();
    getAllDesignations();
    getAllJobtitles();
  }, [companyId, selectedBranchId]);
  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  const fetchGroupName = async (
    organizationId: string | number,
    company_id: string | number,
    branch_id: string | number
  ) => {
    const res = await fetchAccessRightGroupNameApi(
      organizationId,
      company_id,
      branch_id
    );

    if (res.data) {
      if (!company_id && !branch_id) {
        const groupsforadmin = res.data.filter(
          (item: any) => item.company_id == null && item.branch_id == null
        );
        setAccessRightGroupOption(groupsforadmin);
      } else {
        setAccessRightGroupOption(res?.data);
      }
    }
  };

  const getCompanieoption = async () => {
    const res = await getAllCompanies(organization_id);
    if (res.data) {
      setCompanyList(res.data);
    }
  };
  const fetchEmployee = async () => {
    setLoading(true);
    const employes_res = await fetchEmployeeData(employeeId, organization_id);

    if (employes_res.data) {
      setLoading(false);
      const data = employes_res.data;
      employesres.current = employes_res?.data;
      setCompanyId(data.company_id);
      await fetchGroupName(
        employes_res?.data.organization_id,
        employes_res?.data.company_id,
        employes_res?.data?.branch_id
      );
      await fetchSubGroupOnParentGroup(
        data?.parent_access_right_group_id,
        employes_res?.data.organization_id,
        employes_res?.data.company_id,
        employes_res?.data?.branch_id
      );
      form.setFieldsValue({
        companies: data?.company_name,
        branch: data?.branch_id,
        department: data?.department_id,
        team: data?.team_id,
        reportTo: data?.report_to_employee_id,
        employeeDesignation: data?.designation_id,
        jobTitle: data?.job_title_id,
        employeeResponsibility: data?.responsibilities,
        workType: data?.work_type,
        employmentType: data?.employment_type,
        joiningDate: data?.joining_date ? dayjs(data?.joining_date) : "",
        accessRightSubGroup: data?.sub_access_rights_group_id,
        accessRightGroup: data?.access_right_group_id,
      });
    } else if (employes_res.error) {
      setLoading(false);
    }
  };

  const getAllBranches = async () => {
    setLoading(true);
    const response = await fetchBranchesData(companyId, organization_id);

    if (response.data) {
      setLoading(false);
      setBranchesList(response.data);
    } else {
      setLoading(false);
      setBranchesList([]);
    }
  };

  const getAllDepartments = async () => {
    setLoading(true);
    const finalResponse = await fetchAllDepartmentData(
      companyId,
      selectedBranchId,
      organization_id
    );
    if (finalResponse.data) {
      setLoading(false);
      setDepartmentsList(finalResponse.data);
    } else if (finalResponse.error) {
      setLoading(false);
    }
  };

  const getAllTeams = async () => {
    setLoading(true);
    const finalResponse = await fetchAllTeamsData(
      companyId,
      selectedBranchId,
      organization_id
    );
    if (finalResponse.data) {
      setLoading(false);
      setTeamsList(finalResponse.data);
    }
  };

  const getAllEmployees = async () => {
    setLoading(true);
    const response = await fetchAllEmployeeListData(companyId, organization_id);

    if (response.data) {
      setLoading(false);
      setEmployeesList(
        response.data.filter((obj: any) => obj.id != employeeId)
      );
    }
  };

  const getAllDesignations = async () => {
    setLoading(true);

    const response = await fetchAllDesignationData(
      companyId,
      selectedBranchId,
      organization_id
    );
    if (response.data) {
      setLoading(false);
      setAllDesignationsList(response.data);
    } else if (response.error) {
      setLoading(false);
    }
  };

  const getAllJobtitles = async () => {
    setLoading(true);
    const response = await fetchAllJobTitlesData(
      companyId,
      selectedBranchId,
      organization_id
    );
    if (response.data) {
      setLoading(false);
      setAllJobTitlesList(response.data);
    } else if (response.error) {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setButtonLoading(true);
    const selectedParentGroupName = accessRightGroupOption.find(
      (item: any) => item.id == form.getFieldValue("accessRightGroup")
    )?.group;

    const body = {
      branch_id: form.getFieldValue("branch") || null,
      department_id: form.getFieldValue("department") || null,
      team_id: form.getFieldValue("team") || null,
      report_to_employee_id: form.getFieldValue("reportTo") || null,
      designation_id: form.getFieldValue("employeeDesignation") || null,
      job_title_id: form.getFieldValue("jobTitle") || null,
      responsibilities: form.getFieldValue("employeeResponsibility"),
      company_id: companyId || null,
      work_type: form.getFieldValue("workType"),
      employment_type: form.getFieldValue("employmentType"),
      joining_date: form.getFieldValue("joiningDate")
        ? dayjs(form.getFieldValue("joiningDate"))
        : null,
      access_right_group: selectedParentGroupName,
      access_right_group_id: form.getFieldValue("accessRightSubGroup")
        ? form.getFieldValue("accessRightSubGroup")
        : form.getFieldValue("accessRightGroup"),
      sub_access_rights_group_id:
        form.getFieldValue("accessRightSubGroup") || null,
      parent_access_right_group_id:
        form.getFieldValue("accessRightGroup") || null,
    };

    const response = await handleUpdateData(body, employeeId, organization_id);

    if (response.status == 204 || 200) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("success");
      setTimeout(() => setshowAlert(false), 1500);
      if (isParentGroupChanged == true && response?.data[0]?.user_id) {
        updateUserMetadata(response?.data[0]?.user_id);
      }
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
      setTimeout(() => setshowAlert(false), 1500);
    }
  };

  const updateUserMetadata = async (userId: string) => {
    await supabase_admin.auth.admin.updateUserById(userId, {
      user_metadata: { session_valid: false },
    });
  };

  const fetchSubGroupOnParentGroup = async (
    parentGroupId: number,
    organizationId: number,
    company_id: number,
    branch_id: number
  ) => {
    try {
      setSubGroupLoading(true);
      const res = await fetchAccessRightSubGroupNameApi(
        organizationId,
        company_id,
        parentGroupId,
        branch_id
      );

      if (res?.data) {
        setSubGroupOption(res?.data);
      }
      setSubGroupLoading(false);
    } catch (error) {
      setSubGroupLoading(false);
    }
  };
  return (
    <>
      {read ? (
        <>
          <Form layout="vertical" form={form}>
            {" "}
            <Row>
              <Col span={24} className="employememnt-create-btn-container">
                <Button
                  type="primary"
                  onClick={() => setOpenAddCompanyModal(true)}
                  loading={buttonLoading}
                >
                  Add an other company
                </Button>
              </Col>
            </Row>
            <div className="org-form-fields">
              <h5>
                <CgOptions /> Employment
              </h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Company" name="companies">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Choose a Company..."
                      onChange={setCompanyId}
                      disabled
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {companyList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Branch" name="branch">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Choose a branch..."
                      onChange={setSelectedBranchId}
                      disabled
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {branchesList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Department" name="department">
                    <Select
                      allowClear
                      placeholder="Choose a department...  "
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {departmentsList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Team" name="team">
                    <Select
                      allowClear
                      placeholder="Choose a team...  "
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {teamsList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Access Right's Group"
                    name="accessRightGroup"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Access Right's Group",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select access rights..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                      onChange={(value) => {
                        form.setFieldValue("accessRightSubGroup", "");
                        fetchSubGroupOnParentGroup(
                          value,
                          employesres.current?.organization_id,
                          employesres.current?.company_id,
                          employesres.current?.branch_id
                        );
                        const isGroupChanged =
                          form.getFieldValue("accessRightGroup") ===
                          employesres.current?.parent_access_right_group_id
                            ? false
                            : true;
                        setIsParentGroupChanged(isGroupChanged);
                      }}
                    >
                      {accessRightGroupOption.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {capitalizeWords(item.group)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Access Right's Sub-Group"
                    name="accessRightSubGroup"
                  >
                    <Select
                      placeholder="Enter The Access Right's Sub Group"
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                      loading={subGroupLoading}
                    >
                      {subGroupOption.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.sub_group_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <h5>
                {" "}
                <FaUserCheck /> Report To
              </h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Report to" name="reportTo">
                    <Select
                      placeholder="Choose an Employee.."
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {employeesList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.full_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <h5>
                <TbListDetails /> Position And Responsibility
              </h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Employee Designation"
                    name="employeeDesignation"
                  >
                    <Select
                      showSearch
                      placeholder="Select a designation..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {AllDesignationsList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Job Title" name="jobTitle">
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a job title..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {AllJobtitlesList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name={"workType"} label="Work Type">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select a work type..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={<NoDataFoundIcon />}
                    >
                      {workTypeOptions.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={"employmentType"} label="Employment Type">
                    <Input
                      disabled={true}
                      size="large"
                      placeholder="Select employment type..."
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={"joiningDate"} label="Joining Date">
                    <DatePicker className="date-picker" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Responsibility"
                    name="employeeResponsibility"
                  >
                    <Input.TextArea
                      name="employeeResponsibility"
                      placeholder="Enter your responsibility..."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row className="save-changes-btn">
              {loggedInEmployee?.employeeId == employeeId &&
              isParentGroupChanged === true ? (
                <Popconfirm
                  okText="Ok"
                  cancelText="Cancel"
                  title="You will need to log in to the system again. Are you sure you want to proceed with the changes?"
                  onConfirm={handleUpdate}
                >
                  <Button
                    loading={buttonLoading}
                    className="global-btn-width"
                    type="primary"
                  >
                    Save
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  onClick={handleUpdate}
                  className="global-btn-width"
                  type="primary"
                  loading={buttonLoading}
                >
                  Save
                </Button>
              )}
            </Row>
          </Form>
          {loading && <TriangleLoader />}
          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      <Modal
        open={openAddCompanyModal}
        title={<span className="global-modal-title">Add Company</span>}
        onCancel={() => setOpenAddCompanyModal(false)}
        footer={false}
        className="global-modal-main-container"
        width={900}
        destroyOnClose
      >
        <AddCompanyModal employeeId={employeeId} />
      </Modal>
    </>
  );
};

export default UserResponsibilityandReportInfoUpdate;
