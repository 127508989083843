import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Inserts emergency contact data into the "EmergencyContact" table in the Supabase database.
 * @param {any} data - The data to be inserted into the table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertEmergencyContactApi = async (
  data: any,
  organization_id: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organization_id)
      .from("employee_emergency_contact_information")
      .insert(data);
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
