import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  Form,
  DatePicker,
  Select,
  Table,
  Space,
  Popconfirm,
  AutoComplete,
} from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { validateTextOnly } from "../../../../../common/validation";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import { handleRecordRemoval } from "./viewmodel";
import {
  fetchAllEmployeeWithOutBanksApi,
  insertBankDetailsApi,
} from "../services";

import { getCountries } from "vk-country-state-city";
import { banks } from "../../../../../common/Constants";
import { isValidIBAN } from "ibantools";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateBankDetails: React.FC<props> = ({ onCancel }) => {
  const loggedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [form] = useForm();
  const companyId = loggedInemployee?.employee?.company_id;
  const branchId = loggedInemployee?.employee?.branch_id;
  const organization_id = loggedInemployee?.employee.organization_id;
  const [countries, setCountries] = useState<any>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );
  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };
  /**
   * useEffect hook that calls getAllCompanies function when the component mounts.
   * @param {function} getAllCompanies - Function to fetch all companies data.
   * @returns None
   */
  useEffect(() => {
    if (companyId) {
      form.setFieldValue("selectedCompany", companyId);
      if (branchId) {
        form.setFieldValue("selectedBranch", branchId);
      }
    } else {
      getAllCompaniess();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * useEffect hook that calls getAllBranches() when the selectedCompany changes.
   * This hook is used to fetch all branches based on the selected company.
   */
  useEffect(() => {
    if (!(branchId || companyId)) {
      getAllBranches();
    }
  }, [selectedCompany]);

  /**
   * useEffect hook that triggers a function to get employees without skills when either a selected company or branch changes.
   * @param {function} getEmployeesWithoutSkills - Function to fetch employees without skills.
   * @param {string} selectedCompany - The selected company.
   * @param {string} selectedBranch - The selected branch.
   * @returns None
   */
  useEffect(() => {
    if (selectedCompany || selectedBranch) {
      getEmployeesWithoutSkills();
    }
  }, [selectedCompany, selectedBranch]);

  /**
   * Asynchronously fetches all companies associated with the logged-in employee's organization.
   * Sets loading state to true while fetching data and false once data is fetched.
   * Updates the fetched companies state with the retrieved data if successful.
   * @returns None
   */
  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await fetchallCompanyByOrgIdApi(
      loggedInemployee?.employee?.organization_id
    );
    setCompanySelectLoading(false);
    if (response.data) {
      setFetchedCompanies(response?.data);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
    }
  };
  /**
   * Asynchronously fetches all branches for a given company and updates the state accordingly.
   * Resets the branches array, sets the selected branch field value in the form, and sets branch select loading to true.
   * @returns None
   */
  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
  }, []);
  const getAllBranches = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranches([]);
      form.setFieldValue("selectedBranch", "");
      setBranchSelectLoading(true);
      const companyId = form.getFieldValue("selectedCompany");
      const response = await fetchAllBranchesByComapnyIdApi(
        organization_id,
        companyId
      );
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  /**
   * Retrieves a list of employees without specific skills asynchronously.
   * Sets loading state for employee selection, clears filtered employees list, and resets
   * the employeeId field value in the form.
   * Fetches a list of employees without specific skills based on organization, company, and branch IDs.
   * Updates the filtered employees list with the fetched data and sets loading state to false.
   * @returns None
   */
  const getEmployeesWithoutSkills = async () => {
    setEmployeeSelectLoading(true);
    setFilteredEmployees([]);
    form.setFieldValue("employeeId", "");
    const data = {
      organizationId: loggedInemployee?.employee?.organization_id,
      companyId: selectedCompany,
      branchId: selectedBranch,
    };
    const res = await fetchAllEmployeeWithOutBanksApi(data);
    setFilteredEmployees(res);
    form.setFieldValue("employeeId", res[0]?.label);

    setEmployeeSelectLoading(false);
  };
  /**
   * Retrieves form data and adds it to the table data state.
   * @returns None
   */
  const getAllData = () => {
    const formData = form.getFieldsValue();
    const formDataToBeAdded = {
      accountNumber: formData.accountNumber,
      accountTitle: formData.accountTitle,
      bankName: formData.bankName,
      branchName: formData.branchName,
      branchCode: formData.branchCode,
      dateEffective: moment(formData.dateEffective),
      country: formData.country,
      id: Date.now(),
      iban: formData.iban,
    };
    setTableData([...tableData, formDataToBeAdded]);
  };

  /**
   * Handles the submission of bank details by sending the table data to the server.
   * It formats the data and makes an API call to insert the bank details.
   * If the API call is successful, it resets the form fields, updates the alert type to success,
   * clears the table data, branches, and filtered employees, and fetches employees without skills.
   * If there is an error in the API call, it updates the alert type to error and shows an alert.
   * @returns None
   */
  const handleSubmission = async () => {
    setButtonLoading(true);
    const data = {
      bank_information: tableData,
      organization_id: loggedInemployee?.employee?.organization_id,
      company_id: selectedCompany || loggedInemployee?.employee.company_id,
      branch_id: selectedBranch || loggedInemployee?.employee.branch_id,
      employee_id: selectedEmployee,
    };
    const res = await insertBankDetailsApi(data, organization_id);

    setButtonLoading(false);
    if (res.error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("success");
      setTableData([]);
      setBranches([]);
      setFilteredEmployees([]);
      form.resetFields();
      getEmployeesWithoutSkills();
      onCancel();
    }
  };
  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Bank Code",
      dataIndex: "branchCode",
      key: "branchCode",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Account Title",
      dataIndex: "accountTitle",
      key: "accountTitle",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size={"middle"}>
          <Popconfirm
            placement="topLeft"
            okText="Ok"
            cancelText="Cancel"
            title="Are you sure you want to remove this record?"
            onConfirm={(record: any) => {
              setTableData(handleRecordRemoval(tableData, record));
            }}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" autoComplete="off" form={form}>
        <Row gutter={16}>
          {!companyId && (
            <Col span={24} md={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a company"
                rules={[
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  onChange={setSelectedCompany}
                  placeholder="Select a company"
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!branchId && (
            <Col span={24} md={12}>
              <Form.Item name={"selectedBranch"} label="Select a branch">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedBranch}
                  placeholder="Select a branch"
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Employee"
                loading={employeeSelectLoading}
                onChange={setSelectedEmployee}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Country"
              name={"country"}
              rules={[{ required: true, message: "Select a country" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Country"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {countries?.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Enter/Select Bank Name"
              name="bankName"
              rules={[{ required: true, message: "Please Enter Bank Name" }]}
            >
              <AutoComplete
                options={options}
                onSearch={handleSearch}
                placeholder="Select bank..."
                allowClear
                filterOption={(inputValue, option) =>
                  option?.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Branch Name"
              name="branchName"
              rules={[
                {
                  validator: validateTextOnly,
                },
              ]}
            >
              <Input size="large" placeholder="Enter Branch Name" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Bank Code" name="branchCode">
              <Input size="large" placeholder="Enter Bank Code" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Account Title"
              name="accountTitle"
              rules={[
                { required: true, message: "Please Enter Account Title" },
                {
                  validator: validateTextOnly,
                },
              ]}
            >
              <Input size="large" placeholder="Enter Account Title" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[
                { required: true, message: "Please Enter Account Number" },
              ]}
            >
              <Input size="large" placeholder="Enter Account Number" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="IBAN"
              name="iban"
              rules={[
                { required: true, message: "Please Enter IBAN" },
                {
                  validator: (_, value) =>
                    value && isValidIBAN(value)
                      ? Promise.resolve()
                      : Promise.reject(new Error("Invalid IBAN number")),
                },
              ]}
            >
              <Input size="large" placeholder="Enter Account Number" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Effective up to" name="dateEffective">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="add-record-to-table">
          <Button
            className="global-btn-width"
            type="primary"
            size="large"
            onClick={getAllData}
          >
            Add to table
          </Button>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <CustomTable
              dataSource={tableData}
              pagination={false}
              columns={columns}
            />
          </Col>
        </Row>

        <Row className="global-modal-bottom-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleSubmission}
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateBankDetails;
