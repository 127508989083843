import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { capitalizeWords } from "../../../common/validation";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../services/Global";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import {
  fetchAcessRigtGroupApi,
  fetchDepartmentsApi,
  fetchSubGroupByParentGroup,
  fetchTeamsApi,
  insertEmployeeMultipleAccountRemainingData,
  setPersonalDetailsInfoApi,
} from "../PersonalDetails/services";
import { fetchEmployeeDetailDataApi } from "./services";
import {
  fetchDesignationsApi,
  fetchJobTitleApi,
  fetchReportToEmployeeApi,
} from "../ReportTo/services";
import CustomAlert from "../../../common/Alert";

interface AddCompanyModalProps {
  employeeId: number;
}
const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ employeeId }) => {
  const [form] = Form.useForm();
  const logedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [selectedData, setSelectedData] = useState<any>({
    selectedCompany: "",
    selectedBranch: "",
    selectedDepartment: "",
    selectedTeam: "",
    reportToEmployee: "",
    reportToEmployeeRole: "",
    employeeDesignation: "",
    jobTitle: "",
  });

  const [companyOption, setCompanyOption] = useState<any[]>([]);
  const [branchOption, setBranchOption] = useState<any[]>([]);
  const [departmentOption, setDepartmentOption] = useState<any[]>([]);
  const [teamOption, setTeamOption] = useState<any[]>([]);
  const [acessRightOption, setAcessRightOption] = useState<any[]>([]);
  const [parentAccessRightGroupName, setParentAccessRightGroupName] =
    useState<string>("");
  const [subGroupOption, setSubGroupOption] = useState<any[]>([]);
  const [editedEmployeeData, setEditedEmployeeData] = useState<any>();
  const [employeeList, setEmployeeList] = useState<any[]>();
  const [jobTitleList, setJobTitleList] = useState<any[]>();
  const [designationList, setDesignationList] = useState<any[]>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchCompanyNames();
    fetchEmployeeDetails();
  }, []);
  useEffect(() => {
    fetchBranches();
  }, [selectedData.selectedCompany]);

  useEffect(() => {
    if (selectedData.selectedCompany || selectedData.selectedBranch) {
      featchDepartment();
      fetchReportToDesignation();
      featchEmployeeTitle();
    }
  }, [selectedData.selectedCompany, selectedData.selectedBranch]);

  useEffect(() => {
    if (selectedData.selectedDepartment) {
      fetchTeams();
    }
  }, [
    selectedData.selectedCompany,
    selectedData.selectedBranch,
    selectedData.selectedDepartment,
  ]);

  useEffect(() => {
    featchReportToEmployeedata();
  }, [
    selectedData.reportToEmployeeRole,
    selectedData.selectedCompany,
    selectedData.selectedBranch,
  ]);

  const fetchCompanyNames = async () => {
    const res = await fetchallCompanyByOrgIdApi(
      logedInemployee?.employee?.organization_id
    );
    if (res?.data) {
      setCompanyOption(res?.data);

      form.setFieldValue("companies", res?.data[0]?.id);
      setSelectedData((prevData: any) => ({
        ...prevData,
        selectedCompany: res?.data[0]?.id,
      }));
    }
  };

  const fetchBranches = async () => {
    setBranchOption([]);
    form.setFieldValue("branches", "");
    if (selectedData.selectedCompany) {
      const res = await fetchAllBranchesByComapnyIdApi(
        logedInemployee?.employee?.organization_id,
        selectedData.selectedCompany
      );
      if (res?.data) {
        setBranchOption(res?.data);

        form.setFieldValue("departments", "");
        form.setFieldValue("team", "");
        form.setFieldValue("accessRightGroup", "");
        form.setFieldValue("accessRightSubGroup", "");
        form.setFieldValue("branches", res?.data[0]?.id);
        setSelectedData((prevData: any) => ({
          ...prevData,
          selectedBranch: res?.data[0]?.id,
        }));

        setSubGroupOption([]);

        const data = {
          organizationId: logedInemployee?.employee?.organization_id,
          companyId: selectedData.selectedCompany,
        };
        const accessrightres = await fetchAcessRigtGroupApi(data);

        if (accessrightres.data) {
          setAcessRightOption(accessrightres.data);
        }
      }
    }
  };
  const featchDepartment = async () => {
    setDepartmentOption([]);
    form.setFieldValue("departments", "");
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData.selectedCompany
        ? selectedData.selectedCompany
        : logedInemployee?.employee?.company_id,
      branchId: selectedData.selectedBranch
        ? selectedData.selectedBranch
        : logedInemployee?.employee?.branch_id,
    };
    const res = await fetchDepartmentsApi(data);
    if (res?.data) {
      setDepartmentOption(res?.data);

      form.setFieldValue("team", "");
      form.setFieldValue("accessRightGroup", "");
      form.setFieldValue("accessRightSubGroup", "");
      setSubGroupOption([]);
      form.setFieldValue("departments", res.data[0]?.id);
      setSelectedData((prevData: any) => ({
        ...prevData,
        selectedDepartment: res?.data[0]?.id,
      }));
    }
    const accessrightres = await fetchAcessRigtGroupApi(data);

    if (accessrightres.data) {
      setAcessRightOption(accessrightres.data);
    }
  };
  const fetchTeams = async () => {
    setTeamOption([]);
    form.setFieldValue("team", "");

    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData.selectedCompany
        ? selectedData.selectedCompany
        : logedInemployee?.employee?.company_id,
      branchId: selectedData.selectedBranch
        ? selectedData.selectedBranch
        : logedInemployee?.employee?.branch_id,
      departmentId: selectedData?.selectedDepartment,
    };
    const res = await fetchTeamsApi(data);

    if (res.data) {
      setTeamOption(res?.data);

      form.setFieldValue("team", res?.data[0]?.id);
      setSelectedData((prevData: any) => ({
        ...prevData,
        selectedTeam: res?.data[0]?.id,
      }));
    }
  };

  const fetchSubGroupOnParentGroup = async (value: any) => {
    form.setFieldValue("accessRightSubGroup", "");
    const res = await fetchSubGroupByParentGroup(
      value,
      logedInemployee?.employee?.organization_id
    );

    if (res?.data) {
      setSubGroupOption(res?.data);

      form.setFieldValue("accessRightSubGroup", res?.data[0]?.id);
    }
  };

  const fetchEmployeeDetails = async () => {
    try {
      const res = await fetchEmployeeDetailDataApi(
        employeeId,
        logedInemployee?.employee?.organization_id
      );
      if (res.data) {
        setEditedEmployeeData(res.data);
      }
    } catch (error) {}
  };

  const fetchReportToDesignation = async () => {
    form.setFieldValue("employeeDesignation", "");
    form.setFieldValue("roleToReport", "");
    setDesignationList([]);
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData?.selectedCompany,
      branchId: selectedData?.selectedBranch,
    };
    const res = await fetchDesignationsApi(
      data,
      logedInemployee?.employee?.organization_id
    );
    if (res.data) {
      const boption: any[] = res.data?.map((item: any) => ({
        id: item.id,
        label: item.name,
      }));
      setDesignationList(boption);
    }
  };
  const featchReportToEmployeedata = async () => {
    setEmployeeList([]);
    form.setFieldValue("reportTo", "");
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData?.selectedCompany,
      branchId: selectedData?.selectedBranch,
      employeeDesignation: form.getFieldValue("roleToReport"),
      employeeId: employeeId,
    };
    const res = await fetchReportToEmployeeApi(
      data,
      logedInemployee?.employee?.organization_id
    );
    if (res.data) {
      const boption: any[] = res.data?.map((item: any) => ({
        id: item.id,
        label:
          item.full_name +
          (item.department ? "(" + (item.department?.name || "") + ")" : ""),
      }));
      setEmployeeList(boption);
    }
  };
  const featchEmployeeTitle = async () => {
    form.setFieldValue("jobTitle", "");
    setJobTitleList([]);
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: selectedData?.selectedCompany,
      branchId: selectedData?.selectedBranch,
    };
    const res = await fetchJobTitleApi(
      data,
      logedInemployee?.employee?.organization_id
    );
    if (res.data) {
      const boption: any[] = res.data?.map((item: any) => ({
        id: item.id,
        label:
          item.name +
          (item.company ? "(" + (item.company?.name || "") + ")" : ""),
      }));
      setJobTitleList(boption);
    }
  };

  const handleAddComapnyCreate = async (values: any) => {
    const selectedParentGroupName = acessRightOption?.find(
      (item) => item?.id == values?.accessRightGroup
    )?.group;
    setParentAccessRightGroupName(selectedParentGroupName);
    try {
      setSubmitBtnLoading(true);
      const body = {
        first_name: editedEmployeeData?.first_name,
        last_name: editedEmployeeData?.last_name,
        full_name: editedEmployeeData?.full_name,
        nationality: editedEmployeeData?.nationality,
        dob: editedEmployeeData?.dob,
        cnic: editedEmployeeData?.cnic,
        gender: editedEmployeeData?.gender,
        marital_status: editedEmployeeData?.marital_status,
        organization_id: logedInemployee?.employee?.organization_id,
        status: "active",
        avatar_url: editedEmployeeData?.avatar_url,
        access_right_group: selectedParentGroupName,
        access_right_group_id: values.accessRightSubGroup
          ? values.accessRightSubGroup
          : values.accessRightGroup,
        sub_access_rights_group_id: values.accessRightSubGroup || null,
        parent_access_right_group_id: values.accessRightGroup || null,
        company_id: values.companies || null,
        branch_id: values.branches || null,
        department_id: values.departments || null,
        team_id: values.team || null,
        employment_type: editedEmployeeData?.employment_type,
        reporting_to_role_id: values.roleToReport || null,
        report_to_employee_id: values.reportTo || null,
        designation_id: values.employeeDesignation || null,
        job_title_id: values.jobTitle || null,
        responsibilities: editedEmployeeData?.responsibilities || null,
        work_type: values.workType,
        joining_date: editedEmployeeData?.joining_date,
        work_phone_number: editedEmployeeData?.work_phone_number,
        work_email: editedEmployeeData?.work_email,
        phone_number: editedEmployeeData?.phone_number,
        other_email: editedEmployeeData?.other_email,
      };
      const Employees_res = await setPersonalDetailsInfoApi(
        body,
        logedInemployee?.employee?.organization_id
      );
      if (Employees_res.data) {
        const newEmployeeId = Employees_res?.data?.id;
        const { status, error } =
          await insertEmployeeMultipleAccountRemainingData(
            logedInemployee?.employee?.organization_id,
            values.companies,
            values.branches,
            editedEmployeeData?.id,
            newEmployeeId
          );
        if (status === 204) {
          setShowAlert(true);
          setAlertType("success");
          form.resetFields();
        } else {
          setShowAlert(true);
          setAlertType("error");
        }
      } else {
        setShowAlert(true);
        setAlertType("error");
      }
      setSubmitBtnLoading(false);
    } catch (error) {
      setShowAlert(true);
      setAlertType("error");
      setSubmitBtnLoading(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        className="global-card-inner-container"
        onFinish={handleAddComapnyCreate}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Company"
              name="companies"
              rules={[{ required: true, message: "Please select a company." }]}
            >
              <Select
                allowClear
                placeholder="Choose a company..."
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value: any) => {
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    selectedCompany: value,
                    selectedBranch: "",
                    selectedDepartment: "",
                    selectedTeam: "",
                  }));
                }}
              >
                {companyOption.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Branch" name="branches">
              <Select
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Choose a branch..."
                onChange={(value: any) => {
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    selectedBranch: value,
                    selectedDepartment: "",
                    selectedTeam: "",
                  }));
                }}
                notFoundContent={<NoDataFoundIcon />}
              >
                {branchOption.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Department" name="departments">
              <Select
                allowClear
                placeholder="Choose a department...  "
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    selectedDepartment: value,
                    selectedTeam: "",
                  }))
                }
              >
                {departmentOption.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Team" name="team">
              <Select
                allowClear
                placeholder="Choose a team...  "
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    selectedTeam: value,
                  }))
                }
              >
                {teamOption.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Access Right's Group"
                  name="accessRightGroup"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Access Right's Group",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select access rights..."
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={<NoDataFoundIcon />}
                    onChange={(value) => {
                      form.setFieldValue("accessRightSubGroup", "");
                      fetchSubGroupOnParentGroup(value);
                    }}
                  >
                    {acessRightOption.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {capitalizeWords(item.group)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Access Right's Sub-Group"
                  name="accessRightSubGroup"
                >
                  <Select
                    placeholder="Enter The Access Right's Sub Group"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={<NoDataFoundIcon />}
                    //   loading={subGroupLoading}
                  >
                    {subGroupOption.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.sub_group_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Report To Employee Designation"
              name="roleToReport"
            >
              <Select
                placeholder="Select an option..."
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    reportToEmployeeRole: value,
                  }))
                }
              >
                {designationList?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Report to Employee" name="reportTo">
              <Select
                placeholder="Choose an Employee.."
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    reportToEmployee: value,
                  }))
                }
              >
                {employeeList?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee Designation"
              name="employeeDesignation"
              rules={[
                {
                  required: true,
                  message: "Please Select Employee Designation",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a designation..."
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    employeeDesignation: value,
                  }))
                }
              >
                {designationList?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Job Title"
              name="jobTitle"
              rules={[
                {
                  required: true,
                  message: "Please Select Job Title",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select a job title..."
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
                onChange={(value) =>
                  setSelectedData((prevData: any) => ({
                    ...prevData,
                    jobTitle: value,
                  }))
                }
              >
                {jobTitleList?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name={"workType"} label="Work Type">
              <Select
                allowClear
                showSearch
                placeholder="Select a work type..."
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={<NoDataFoundIcon />}
              >
                {["Remote", "Hybrid", "On-site"].map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} className="add-company-modal-content-btn-container">
            <Button type="primary" htmlType="submit" loading={submitBtnLoading}>
              Add Company
            </Button>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default AddCompanyModal;
