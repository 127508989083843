import React, { useEffect, useState } from "react";
import "./index.css";
import { Tooltip, type TableColumnsType } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { fetchComplainReportingData, fetchComplaintDataById } from "./services";
import ComplaintViewModal from "./ViewModal";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import { TbMobiledataOff } from "react-icons/tb";
import CustomTable from "../CustomTable";

type MessagesData = {
  name?: string;
  id?: number;
  status?: string;
  email?: string;
  type?: string;
  response?: string;
  complaineeEmployeeId?: string;
  complaintMessage?: string;
};

const ComplaintReporting = () => {
  const [data, setData] = useState<MessagesData[]>([]);
  const loggedInEmployeeId =
    useSelector(selectLogedEmployee).logedInemployee;
  const [selectedComplaintResponse, setSelectedComplaintResponse] =
    useState<MessagesData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    const complainAndBugData = await fetchComplainReportingData(
      loggedInEmployeeId?.employee?.id, loggedInEmployeeId?.employee?.organization_id
    );
 
    setLoading(false);
    if (complainAndBugData.data) {
      setData(complainAndBugData.data);
    }
  };

  const handleView = async (Id: number) => {
    const complaintData = await fetchComplaintDataById(Id);
    if (complaintData.data) {
      setSelectedComplaintResponse(complaintData.data);
    }
    setViewModalVisible(true);
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (_: any, record: any) =>
        record?.email && <span className="email-column">{record?.email}</span>,
    },
    {
      title: "Complain Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Action/Status",
      key: "actions",
      render: (_: any, record: any) =>
        record.status === "Answered" ? (
          <Tooltip title={"Click to view complaint details and response."}>
            <EyeOutlined onClick={() => handleView(record.id)} />
          </Tooltip>
        ) : (
          <Tooltip title="You haven't got any response yet.">
            <TbMobiledataOff />
          </Tooltip>
        ),
    },
  ];

  return (
    <div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
      />
      <ComplaintViewModal
        visible={viewModalVisible}
        onRequestClose={() => setViewModalVisible(false)}
        selectedComplaintResponse={selectedComplaintResponse}
      />
    </div>
  );
};

export default ComplaintReporting;
