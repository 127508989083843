import React, { useEffect, useState } from "react";
import { Button, Card, Col, Empty, Modal, Row, Space, Table, Tag } from "antd";
import "./index.css";
import LeaveRequestForm from "../CreateLeaveRequest";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  fetchLeavePolicyDataApi,
  getEmployeeApprovedLeavesData,
} from "../services";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../../redux/AccessRight";
import { BsFillCalendarEventFill } from "react-icons/bs";
import LeaveRequestsHistory from "../LeaveRequestsHistory";
import CustomTable from "../../../../common/CustomTable";
import { FiPlus } from "react-icons/fi";

interface LeaveType {
  leaveType: string;
  maxAllowed: number;
  id: number;
}
interface LeaveCount {
  [key: string]: number;
}

interface CurrentMonthData {
  leaveType: string;
  Balance: number;
  Availed: number;
  Available: number;
  id: number;
}

const EmployeeLeaveRequest: React.FC = () => {
  const [tableData, setTableData] = useState<any>();
  const [tableLoader, setTableLoader] = useState<boolean>(true);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const [leaveNames, setLeaveNames] = useState<LeaveType[]>([]);

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createLeaves, setCreateLeaves] = useState<any>(null);
  const [LeavRequestModelOpen, setLeaveRequestModelOpen] =
    useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Leave Request"
  );

  const read = access?.readAccess;
  const create = access?.createAccess;

  useEffect(() => {
    getLatestRecord();
  }, []);
  useEffect(() => {
    getEmployeeLeaves();
  }, [leaveNames]);

  const getLatestRecord = async () => {
    const response = await fetchLeavePolicyDataApi(organizationId, companyId);
    if (response.data) {
      const leaveTypes: LeaveType[] = response.data[0]?.policy_types?.map(
        (policyType: any) => ({
          leaveType: policyType.leaveType,
          maxAllowed: policyType.maxAllowed,
          id: policyType.id,
        })
      );
      setLeaveNames(leaveTypes);
    }
  };

  const getEmployeeLeaves = async () => {
    const currentYear = new Date().getFullYear();
    const response = await getEmployeeApprovedLeavesData(
      organizationId,
      employeeId,
      companyId
    );
    if (response.data) {
      const leaveTypeCounts: LeaveCount = {};
      response.data.forEach((leaveRequest: any) => {
        const requestYear = new Date(leaveRequest.created_at).getFullYear();
        if (requestYear === currentYear) {
          const leaveType = leaveRequest?.leave_type?.toLowerCase();
          leaveTypeCounts[leaveType] =
            (leaveTypeCounts[leaveType] || 0) + leaveRequest?.leave_count;
        }
      });

      const updatedCurrentMonthData: CurrentMonthData[] = leaveNames?.map(
        (leaveName) => {
          const availed =
            leaveTypeCounts[leaveName.leaveType.toLowerCase()] || 0;

          return {
            leaveType: leaveName.leaveType,
            Balance: leaveName.maxAllowed - availed,
            Availed: availed,
            Available: leaveName.maxAllowed,
            id: leaveName.id,
          };
        }
      );

      setTableData(updatedCurrentMonthData);
    }
    setTableLoader(false);
  };

  const tableColumns = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },

    {
      title: "Availed",
      dataIndex: "Availed",
      key: "Availed",
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "Balance",
      render: (text: any, record: any) =>
        record.Balance < 0 ? <Tag color="red">{text}</Tag> : text,
    },
    {
      title: "Allowed Leaves",
      dataIndex: "Available",
      key: "Available",
    },
  ];

  const onCreateClick = () => {
    setCreateLeaves(true);
    setCreateModalVisible(true);
  };

  const onCreateClose = () => {
    setCreateModalVisible(false);
    getLatestRecord();
  };

  return (
    <div
      className={`${
        employeeRole === "employee"
          ? "global-request-container-styling"
          : "global-request-container-styling global-requests-container"
      }`}
    >
      <Card
        className=" bar-card dasboard-cards-for-requests employee-leave-req-padding"
        title={
          employeeRole !== "employee" ? (
            <div className="leave-request-list-create-btn-container">
              {read && (
                <Button
                  icon={<BsFillCalendarEventFill />}
                  type="default"
                  onClick={() => setLeaveRequestModelOpen(true)}
                  size="large"
                >
                  Leave History
                </Button>
              )}
              {create && (
                <Button
                  size="large"
                  type="primary"
                  onClick={onCreateClick}
                  icon={<FiPlus className="tab-header-icon" />}
                >
                  Create
                </Button>
              )}
            </div>
          ) : (
            <div className="leave-request-list-create-btn-container">
              {read && (
                <Button
                  icon={<BsFillCalendarEventFill />}
                  type="default"
                  onClick={() => setLeaveRequestModelOpen(true)}
                  size="large"
                >
                  Leave History
                </Button>
              )}
            </div>
          )
        }
        bordered={true}
      >
        <Row gutter={10}>
          <Col span={24}>
            {read ? (
              <CustomTable
                loading={tableLoader}
                dataSource={tableData}
                columns={tableColumns}
                pagination={false}
              />
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </Col>
        </Row>
      </Card>

      {createLeaves && (
        <Modal
          title="Create Leave Request"
          open={createModalVisible}
          onCancel={onCreateClose}
          width={1000}
          footer={false}
          destroyOnClose
        >
          <LeaveRequestForm onCreateClose={onCreateClose} />
        </Modal>
      )}
      <Modal
        title="Leave History"
        open={LeavRequestModelOpen}
        onCancel={() => setLeaveRequestModelOpen(false)}
        width={1000}
        footer={false}
      >
        <LeaveRequestsHistory />
      </Modal>
    </div>
  );
};

export default EmployeeLeaveRequest;
