import supabase, { supabase_client_for_Org_schema } from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Updates an employee record in the "Employees" table in the database based on the provided data.
 * @param {any} data - The data object containing the update body and employee ID.
 * @returns {Promise<any>} A promise that resolves to the updated employee record.
 */
export const updateEmployeesByIdApi = async (data: any): Promise<any> => {
  try {
    const query =  supabase_client_for_Org_schema(data.org_id)
      .from("employee")
      .update(data.updateBody)
      .eq("id", data.employeeId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Calls the Supabase authentication signUp method to register a new employee.
 * @param {any} data - The data object containing employee sign up information.
 * @returns {Promise<any>} A promise that resolves to the result of the sign up operation.
 */
export const employeeSignUpApi = async (data: any): Promise<any> => {
  try {
    const query = supabase.auth.signUp(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
