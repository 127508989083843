import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Popconfirm, Row, Space, Tooltip } from "antd";
import { FaFileDownload } from "react-icons/fa";
import { DeleteOutlined } from "@ant-design/icons";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../common/Alert";
import {
  deleteFromRecord,
  downloadFilesFromSupabase,
  getDetails,
  resumeDeletion,
} from "../services";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import CustomTable from "../../../common/CustomTable";
import { searchLogicHandler } from "../../../common/SearchAndFilterFunction";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import "./index.css";

interface ResumeBankListProps {
  searchValue?: string;
}

const ResumeBank: React.FC<ResumeBankListProps> = ({ searchValue }) => {
  const [jobApplications, setJobApplications] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("info");
  const [resumeURLS, setResumeURLS] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Recruitment", "CV Bank");

  const deleteAccess = access?.deleteAccess;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;

  useEffect(() => {
    getJobDetails();
  }, [organizationId, companyId]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setJobApplications(originalData.apiData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getJobDetails = async () => {
    setIsLoading(true);
    const response = await getDetails(organizationId, companyId);
    if (response.data) {
      const resumeURLS = response?.data?.map((item: any) => item.resume_url);
      setResumeURLS(resumeURLS);
      setJobApplications(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setIsLoading(false);
    }
  };

  const handleDownloadResume = async (resume_url: any) => {
    const fileName = resume_url?.split("/")?.pop();
    const response = await downloadFilesFromSupabase(fileName);
    if (response.data instanceof Blob) {
      downloadBlob(response.data, fileName);
    }
  };

  function downloadBlob(blob: any, fileName: any) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleSearch = (value: string) => {
    setIsLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(value, originalData, "name");
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setJobApplications(filterData);
    setIsLoading(false);
  };
  const handleDownloadAllResumes = async () => {
    setIsLoading(true);
    const zip = new JSZip();
    const folder = zip.folder("resumes");

    for (let url of resumeURLS) {
      const fileName = url?.split("/")?.pop();
      const response = await downloadFilesFromSupabase(fileName);
      if (response.data instanceof Blob) {
        folder?.file(fileName, response.data);
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "resumes.zip");
      setIsLoading(false);
    });
  };
  const handleDeleteResume = async (record: any) => {
    const fileName = record.resume_url?.split("/")?.pop();
    const finalResponse = await resumeDeletion(decodeURIComponent(fileName));
    if (finalResponse.error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      const response = await deleteFromRecord(record.id, organizationId);
      if (response.status === 201 || response.status === 204) {
        setshowAlert(true);
        setAlertType("success");
        getJobDetails();
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: any) =>
        record?.email && <span className="email-column">{record?.email}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Download Resume">
            <FaFileDownload
              onClick={() => handleDownloadResume(record.resume_url)}
            />
          </Tooltip>
          {deleteAccess && (
            <Popconfirm
              placement="topLeft"
              okText="Ok"
              cancelText="Cancel"
              title="Are you sure you want to delete this Job?"
              onConfirm={() => handleDeleteResume(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <div
          className="resume-bank-row"
        >
          <Button
            disabled={isLoading || resumeURLS.length === 0}
            onClick={handleDownloadAllResumes}
            type="primary"
            className="global-btn-width"
          >
            Download All Resumes
          </Button>
        </div>

        <CustomTable
          columns={columns}
          dataSource={jobApplications}
          loading={isLoading}
        />

      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default ResumeBank;
