import React, { useState, useEffect } from "react";
import { Input, Button, Col, Row, Form, Select } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { fetchData, onFinishData } from "../services";
import { fetchCompanies, getBranches } from "../../Designation/services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

type jobtitleField = {
  jobtitle: string;
  comment: string;
  companies: string;
  branches: string;
  departments: string;
};

interface props {
  onCancel: (status: string) => void;
}

const CreateJobTitle: React.FC<props> = ({ onCancel }) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fetchBranches, setFetchBranches] = useState<any>([]);
  const [companyId, setCompanyId] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(true);
  const [branchSelectLoading, setBranchSelectLoading] = useState<boolean>(true);

  const { Option } = Select;

  const [formData, setFormData] = useState<jobtitleField>({
    jobtitle: "",
    comment: "",
    companies: "",
    branches: "",
    departments: "",
  });

  const [form] = useForm();
  const [companyData, setCompanyData] = useState<
    { id: string; name: string }[]
  >([]);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      fetchBranchesData();
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompanyNames();
  }, []);

  const handleInputChange = (event: any) => {
    const { name, value } = event?.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFinish = async (values: jobtitleField) => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const body = {
        name: values.jobtitle,
        mission: values.comment,
        company_id: values.companies,
        branch_id: values.branches,
        organization_id: organizationId,
      };

      const response = await onFinishData(body, organizationId);

      if (response.error) {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertType("error");
      }
      if (response.status == 201 || response.status == 204) {
        form.resetFields();
        setFormData({
          jobtitle: "",
          comment: "",
          companies: "",
          branches: "",
          departments: "",
        });
        setButtonLoading(false);
        setCompanyId(null);
        onCancel("success");
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const fetchCompanyNames = async () => {
    try {
      const response = await fetchCompanies(organizationId);

      if (response.data) {
        const companyData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));

        setCompanyData(companyData);
        setCompanySelectLoading(false);
      }
      setCompanySelectLoading(false);
    } catch (error) {
      setCompanyData([]);
      setCompanySelectLoading(false);
    }
  };

  const fetchBranchesData = async () => {
    const response = await getBranches(companyId, organizationId);

    if (response.data) {
      setFetchBranches(response.data);
      setBranchSelectLoading(false);
    }
    setBranchSelectLoading(false);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedFormData = {
      ...formData,
      comment: e.target.value,
    };
    setFormData(updatedFormData);
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={24} md={8}>
            <Form.Item
              label="Job title"
              name="jobtitle"
              rules={[{ required: true, message: "Please Enter Job Title!" }]}
            >
              <Input
                size="large"
                placeholder="Enter Job Title"
                name="jobtitle"
                onChange={handleInputChange}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item
              label="Company"
              name="companies"
              rules={[{ required: true, message: "Please Select Company!" }]}
            >
              <Select
                showSearch
                placeholder="Select Company"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                optionFilterProp="children"
                onChange={(value) => {
                  setFormData({ ...formData, companies: value });
                  setCompanyId(value);
                }}
                loading={companySelectLoading}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {companyData.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={8}>
            <Form.Item label="Branch (if any)" name="branches">
              <Select
                showSearch
                placeholder="Select a Branch"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={(value) => {
                  setFormData({ ...formData, branches: value });
                }}
                loading={branchSelectLoading}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {fetchBranches &&
                  fetchBranches.map((branch: any) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="comment">
              <Input.TextArea
                rows={4}
                placeholder="Enter Description..."
                name="comment"
                onChange={handleCommentChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row button-row-jobtitle">
          <Button
            className="global-btn-width"
            type="primary"
            htmlType="submit"
            loading={buttonLoading}
            size="large"
          >
            Create
          </Button>
        </Row>
      </Form>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateJobTitle;
