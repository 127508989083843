import React, { useEffect, useMemo, useState } from "react";
import "../index.css";
import TabsHeader from "../../../../common/TabsHeader";
import { Card, Empty, Modal } from "antd";
import ConsultantListView from "../Consultant/List";
import ContractorListView from "../Contractor/List";
import PermanentListView from "../Permanent/List";
import CreatePermanent from "../Permanent/Create";
import CreateContractor from "../Contractor/Create";
import CreateConsultant from "../Consultant/Create";
import { selectOrganizationModulePermission } from "../../../../../redux/organizationModulePermission";
import { useDispatch, useSelector } from "react-redux";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../../redux/AccessRight";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../../../redux/MenuBreadcrumbSlice";

const EmploymentTypeComp: React.FC = () => {
  const [selected, setSelected] = useState("Permanent");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const accessObj = useSelector(selectAccess);

  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );
  const dispatch = useDispatch();

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.Employment;
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  const radioOptions = useMemo(
    () => [
      {
        label: "Permanent",
        value: "Permanent",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Contractor",
        value: "Contractor",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Consultant",
        value: "Consultant",
        create: true,
        filter: false,
        showSearch: true,
      },
    ],
    []
  );

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "employment",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Assessment";
    let childValue =
      value == "Permanent"
        ? "Permanent"
        : value == "Contractor"
        ? "Contract"
        : "Consultant";
    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "employment"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
  }, [filteredRadioOptions]);

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };

  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };
  return (
    <>
      <div className="assessment-module-card-container">
        <Card className="assessment-module-card-inner-container">
          <TabsHeader
            radioOptions={filteredRadioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("");
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            selected={selected}
          />
          {readAccess ? (
            <div key={fetchTableData}>
              {selected === "Permanent" && (
                <PermanentListView searchValue={searchValue} />
              )}
              {selected === "Contractor" && (
                <ContractorListView searchValue={searchValue} />
              )}
              {selected === "Consultant" && (
                <ConsultantListView searchValue={searchValue} />
              )}
            </div>
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}

          <div key={fetchModalData}>
            <Modal
              title={
                <span className="global-modal-title">Create {selected}</span>
              }
              open={createModalVisible}
              onCancel={handleCreateModalCancel}
              width={1000}
              footer={false}
              className="global-modal-main-container"
              destroyOnClose
            >
              {createModalVisible && selected === "Permanent" && (
                <CreatePermanent onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Contractor" && (
                <CreateContractor onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Consultant" && (
                <CreateConsultant onCancel={handleCreateModalCancel} />
              )}
            </Modal>
          </div>
        </Card>
      </div>
    </>
  );
};

export default EmploymentTypeComp;
