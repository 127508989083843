export const childCheckboxChangeHanlder = (
  prevData: any,
  parentKey: string,
  childKey: string,
  childField: string
) => {
  try {
    return prevData.map((parentItem: any) => {
      if (parentItem.key === parentKey && parentItem.child) {
        const updatedChild = parentItem.child.map((childItem: any) => {
          if (childItem.key === childKey) {
            return {
              ...childItem,
              [childField]: !childItem[childField],
            };
          }
          return childItem;
        });
        const allChildrenTrue = updatedChild.every(
          (child: any) => child[childField]
        );
        const anyChildFalse = updatedChild.some(
          (child: any) => !child[childField]
        );

        let parentFieldUpdate = parentItem[childField];
        if (allChildrenTrue) {
          parentFieldUpdate = true;
        } else if (anyChildFalse) {
          parentFieldUpdate = false;
        }

        return {
          ...parentItem,
          [childField]: parentFieldUpdate,
          child: updatedChild,
        };
      }
      return parentItem;
    });
  } catch (error) {
    return null;
  }
};

export const parentCheckboxChangeHandler = (
  filteredTableData: any,
  key: string,
  field: string
) => {
  try {
    const updatedData = [...filteredTableData];
    const index = updatedData.findIndex((item) => item.key === key);

    if (index > -1) {
      updatedData[index][field] = !updatedData[index][field];
      const newValue = updatedData[index][field];

      if (updatedData[index].child) {
        updatedData[index].child.forEach((child: any) => {
          child[field] = newValue;
        });
      }
    }
    return updatedData;
  } catch (error) {
    return null;
  }
};
