import moment from "moment";

export const checkForResignationRequests = (requests: any) => {
  try {
    const currentMonth = moment().startOf("month");
    const acceptedRequest = requests.some(
      (request: any) =>
        request.Status === "Accepted" &&
        moment(request.created_at).isSame(currentMonth, "month")
    );
    const excessiveRequests =
      requests.filter(
        (request: any) =>
          (request.Status === "Rejected" || request.Status === "Requested") &&
          moment(request.created_at).isSame(currentMonth, "month")
      ).length >= 3;

    return { acceptedRequest, excessiveRequests };
  } catch (error) {}
};
