import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { featchEmployeeByDesignationIdApi } from "../services";
import { allorgCompanyBranchesApi } from "../../../../services/Global";
import { allorgCompanyApi } from "../../../../services/Global";
import {
  fetchSalaryProveConfigurationApi,
  setsalaryProveConfigurationApi,
} from "../../services";
import CustomAlert from "../../../../common/Alert";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const SalaryProveConfigurationsCreate = () => {
  const [form] = Form.useForm();
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<number>();
  const [filteredEmployee, setFilteredEmployee] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");

  useEffect(() => {
    if (logedInemployee?.employee.company_id) {
      setSelectedCompanyId(logedInemployee?.employee.company_id);
      form.setFieldValue("companyId", logedInemployee?.employee.company_id);
    }
    if (logedInemployee?.employee.branch_id) {
      setSelectedBranchId(logedInemployee?.employee.branch_id);
      form.setFieldValue("branchId", logedInemployee?.employee.branch_id);
    }

    fetchCompanies();
  }, []);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    form.setFieldValue("branchId", "");
    form.setFieldValue("employeeId", "");

    fetchBranches();
  }, [selectedCompanyId]);

  useEffect(() => {
    featchEmployeeByDesignationId();
  }, [selectedCompanyId, selectedBranchId]);

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(
      logedInemployee?.employee.organization_id
    );
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
  };
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      const resBranches = await allorgCompanyBranchesApi(
        logedInemployee?.employee.organization_id,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
        setSelectedBranchId(resBranches?.data[0]?.id);
      }
    }
  };

  const featchEmployeeByDesignationId = async () => {
    const data = {
      organizationId: logedInemployee?.employee.organization_id,
      selectedCompanyId: selectedCompanyId,
      selectedBranchId: selectedBranchId,
    };
    const res = await featchEmployeeByDesignationIdApi(data);

    if (res.data) {
      const boption = res.data?.map((item: any) => ({
        id: item.id,
        label: item.full_name,
      }));
      setFilteredEmployee(boption);
      form.setFieldValue("employeeId", res.data[0]?.id);
    }
  };

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const employeeIds = Array.isArray(values.employeeId)
      ? values.employeeId
      : [values.employeeId];

    const employee_list = employeeIds.map((item: any) => {
      return {
        employee_id: item,
        organization_Id: logedInemployee?.employee.organization_id || null,
        company_id: values.companyId || null,
        branch_id: values.branchId || null,
      };
    });

    let data = {
      organization_Id: logedInemployee?.employee?.organization_id || null,
      company_id: values.companyId || null,
      branch_id: values.branchId || null,
    };

    const checkres = await fetchSalaryProveConfigurationApi(data);
    if (checkres.status == 200 && checkres.data.length == 0) {
      setshowAlert(false);
      const res = await setsalaryProveConfigurationApi(employee_list);
      if (res.status == 201 || res.status == 204) {
        form.resetFields();
        setshowAlert(true);
        setAlertMessage("");
        setAlertDescription("Details Add Sucessfully");
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else {
        setshowAlert(true);
        setButtonLoading(false);
        setAlertMessage("");
        setAlertDescription(
          res.error?.message ||
            "oops we are facing some temporary issue with your request"
        );
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }
    } else {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Details Already Exists");
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
    setButtonLoading(false);
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="companyId"
                label="Select Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  disabled={logedInemployee?.employee.company_id}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Select Branch (If any)">
                <Select
                  disabled={logedInemployee?.employee.branch_id}
                  onChange={(value) => setSelectedBranchId(value)}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Employee"
                name="employeeId"
                rules={[
                  {
                    required: true,
                    message: "Please Select Employee",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Employee"
                  allowClear
                  size="large"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  mode="multiple"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredEmployee.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="global-modal-bottom-row salary-prove-configurations-form-container-last-row">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default SalaryProveConfigurationsCreate;
