import { Card, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import "../index.css";
import { ResponsivePie } from "@nivo/pie";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import dayjs from "dayjs";
import { ProgressLoader } from "../../../../common/ReactLoader";

const MonthlyAllowanceInspection: React.FC = () => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs().format("MMMM")
  );
  const [chartData, setChartData] = useState<any[]>([]);

  const getEmployeeSalarySummary = async (month: string) => {
    try {
      setSelectedMonth(month);
      let startDate;
      let endDate;
      const year = new Date().getFullYear();

      const dayjsmonthNumber = dayjs(month, "MMMM", true);
      const monthNumber: any = dayjsmonthNumber.format("MM");
      startDate = new Date(year, monthNumber - 1, 1).toISOString();
      endDate = new Date(year, monthNumber, 0, 23, 59, 59).toISOString();

      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_salary_history")
        .select("allowance_detail")
        .eq("organization_id", organizationId)
        .gte("created_at", startDate)
        .lte("created_at", endDate);

      if (response.data) {
        const allowanceTotals: { [key: string]: number } = {};

        response.data.forEach((employee) => {
          if (employee.allowance_detail) {
            employee.allowance_detail.forEach(
              (allowance: { name: string; amount: number }) => {
                if (allowance.amount > 0) {
                  if (!allowanceTotals[allowance.name]) {
                    allowanceTotals[allowance.name] = 0;
                  }
                  allowanceTotals[allowance.name] += allowance.amount;
                }
              }
            );
          }
        });

        const newData = Object.keys(allowanceTotals).map((key) => ({
          id: key,
          label: key,
          value: allowanceTotals[key],
          color: `hsl(${Math.random() * 360}, 70%, 50%)`,
        }));

        setChartData(newData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  useEffect(() => {
    getEmployeeSalarySummary(selectedMonth);
  }, [selectedMonth]);

  const handleMonthChange = (date: any, dateString: string | string[]) => {
    const month = Array.isArray(dateString)
      ? dateString[0]
      : dayjs(dateString).format("MMMM");
    getEmployeeSalarySummary(month);
  };

  const numberWithCommas = (value: number) => value.toLocaleString();

  return (
    <div className="payroll-dashboard-monthly-allowance-container">
      <Card
        title={
          <div className="payroll-dashboard-allowance-card-header">
            <div className="payroll-dashboard-allowance-title-container">
              <span>Allowances</span>
            </div>
            <div>
              <DatePicker
                picker="month"
                onChange={handleMonthChange}
                defaultValue={dayjs()}
              />
            </div>
          </div>
        }
        className="payroll-dashboard-monthly-allowance"
      >
        {loading ? (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader />
          </div>
        ) : chartData.length > 0 ? (
          <div style={{ height: 350 }}>
            <ResponsivePie
              data={chartData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.2}
              padAngle={1}
              cornerRadius={5}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="var(--green-color-primary-light)"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLinkLabel={(d) => `${d.id}`}
              arcLabel={(d) => numberWithCommas(d.value)}
              valueFormat={(value) => numberWithCommas(value)}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom1",
                  background: "inherit",
                  color: "#218b8c",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom2",
                  background: "inherit",
                  color: "#c8eae1",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom3",
                  type: "none",
                  background: "inherit",
                  color: "var(--green-color-primary-light)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              
            />
          </div>
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#888",
            }}
          >
            <span>😕 No allowance data available for the selected month. Please try a different month.</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default MonthlyAllowanceInspection;
