import React, { useEffect, useState } from "react";
import { Button, Dropdown, Input, Menu, Tabs } from "antd";
import "./index.css";
import { CiFilter } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import searchIcon from "../../assets/images/search-icon.png";
import { PiDownloadSimpleLight, PiUploadSimpleLight } from "react-icons/pi";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import { BsCloudUpload } from "react-icons/bs";

interface TabsHeaderProps {
  radioOptions: {
    label: string;
    value: string;
    create?: boolean;
    filter?: boolean;
    showSearch?: boolean;
    showDownloadBtn?: boolean;
    showUploadDataBtn?: boolean;
  }[];
  onChange: (value: string) => void;
  onCreateClick: () => void;
  onFilterClick?: () => void;
  templateDownloadHandler?: () => void;
  handleUploadOtherData?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUploadEmployeeData?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchChange?: (input: string) => void;
  createAccess?: boolean;
  showFullwidthHeader?: boolean;
  searchValue?: string;
  selected?: any;
}

const TabsHeader: React.FC<TabsHeaderProps> = ({
  radioOptions,
  onChange,
  onCreateClick,
  onFilterClick,
  templateDownloadHandler,
  handleUploadOtherData,
  handleUploadEmployeeData,
  onSearchChange,
  createAccess,
  showFullwidthHeader,
  searchValue,
  selected,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [showCreateBtn, setShowCreateBtn] = useState(
    radioOptions[0].create || false
  );
  const [showFilterBtn, setShowFilterBtn] = useState(
    radioOptions[0].filter || false
  );
  const [showSearchBar, setShowSearchBar] = useState(
    radioOptions[0].showSearch || false
  );
  const [showDownloadBtn, setShowDownloadBtn] = useState(
    radioOptions[0].showDownloadBtn || false
  );
  const [showUploadDataBtn, setShowUploadDataBtn] = useState(
    radioOptions[0].showUploadDataBtn || false
  );
  useEffect(() => {
    if (selected) {
      setSelectedValue(selected);
      const returnedObject = radioOptions?.find(
        (item) => item.value === selected
      );

      returnedObject?.create
        ? setShowCreateBtn(returnedObject?.create)
        : setShowCreateBtn(false);
      returnedObject?.filter
        ? setShowFilterBtn(returnedObject?.filter)
        : setShowFilterBtn(false);
      returnedObject?.showSearch
        ? setShowSearchBar(returnedObject?.showSearch)
        : setShowSearchBar(false);
      returnedObject?.showDownloadBtn
        ? setShowDownloadBtn(returnedObject?.showDownloadBtn)
        : setShowDownloadBtn(false);
      returnedObject?.showUploadDataBtn
        ? setShowUploadDataBtn(returnedObject?.showUploadDataBtn)
        : setShowUploadDataBtn(false);
    }
  }, [selected]);

  const handleTabChange = (value: string) => {
    onChange(value);
    setSelectedValue(value);
    hanldeCreateBtnVisibility(value);
  };

  const hanldeCreateBtnVisibility = (value: string) => {
    const temp = radioOptions.find(
      (item) => item?.value && item?.value === value
    );

    setShowCreateBtn(temp?.create || false);
    setShowFilterBtn(temp?.filter || false);
    setShowSearchBar(temp?.showSearch || false);
    setShowDownloadBtn(temp?.showDownloadBtn || false);
    setShowUploadDataBtn(temp?.showUploadDataBtn || false);
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadType: string
  ) => {
    if (!event.target.files) return;

    if (uploadType === "employee" && handleUploadEmployeeData) {
      handleUploadEmployeeData(event);
    } else {
      if (handleUploadOtherData) {
        handleUploadOtherData(event);
      }
    }
  };

  const triggerFileInput = (uploadType: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.style.display = "none";

    input.onchange = (event) =>
      handleFileSelection(
        event as unknown as React.ChangeEvent<HTMLInputElement>,
        uploadType
      );

    document.body.appendChild(input);
    input.click();
    input.remove();
  };

  return (
    <div className="tab-header-container">
      {/* Search Bar and Buttons */}
      <div className="tab-header-search-and-buttons">
        <div className="tab-header-title-container">
          <span className="tab-header-title">
            {loggedInEmployee?.currentactivepage}
          </span>
        </div>
        {showSearchBar && (
          <Input
            placeholder="Search"
            size="large"
            prefix={<img src={searchIcon} alt="Search icon" />}
            value={onSearchChange && searchValue ? searchValue : ""}
            onChange={(e) => {
              if (onSearchChange) {
                onSearchChange(e.target.value);
              }
            }}
          />
        )}
        {showFilterBtn && (
          <Button
            className="tab-header-deafult-btn"
            type="default"
            onClick={() => {
              if (onFilterClick) {
                onFilterClick();
              }
            }}
            icon={<CiFilter />}
          >
            Filter
          </Button>
        )}
        {showDownloadBtn && showUploadDataBtn && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="Download Template"
                  onClick={templateDownloadHandler}
                  icon={<PiDownloadSimpleLight />}
                  title="Download Template"
                >
                  Download Template
                </Menu.Item>
                <Menu.Item
                  key="Upload Employee Data"
                  icon={<PiUploadSimpleLight />}
                  onClick={() => triggerFileInput("employee")}
                  title="Upload Employee Data"
                >
                  Upload Employee Data
                </Menu.Item>
                <Menu.Item
                  key="Upload Other Records"
                  icon={<PiUploadSimpleLight />}
                  onClick={() => triggerFileInput("other")}
                  title="Upload Other Records"
                >
                  Upload Employee Remaining Data
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div className="shadow-icon download-icon">
              <BsCloudUpload className="tab-header-dropdown-hamburger-icon tab-header-upload-pointer" />
            </div>
          </Dropdown>
        )}

        {showCreateBtn && createAccess && (
          <Button
            type="primary"
            onClick={onCreateClick}
            icon={<FiPlus className="tab-header-icon" />}
            className="tab-header-deafult-btn"
          >
            Create
          </Button>
        )}
      </div>

      {/* Tabs */}
      <div
        className={
          showFullwidthHeader ? "tab-header-full-width-tabs" : "tab-header-tabs"
        }
      >
        <Tabs
          activeKey={selectedValue}
          onChange={handleTabChange}
          tabBarGutter={8}
          className="antd-tab-custamization"
        >
          {radioOptions.map((option) => (
            <Tabs.TabPane
              key={option.value}
              tab={
                <span
                  className={
                    selectedValue === option.value
                      ? "tab-header-button-clicked"
                      : "tab-header-button-default"
                  }
                >
                  {option.label}
                </span>
              }
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default TabsHeader;
