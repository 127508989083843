import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

export const getEmployeeKPIHistory = async (
  employeeId: any,
  organizationId: any
) => {
  const response = await supabase_client_for_Org_schema(organizationId)
    .from("employee_kpi")
    .select("*")
    .eq("employee_id", employeeId);

  return response;
};
