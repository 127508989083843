import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Row, Col, Divider } from "antd";
import "./index.css";
import { storeComplaintFormData } from "./services";
import { useSelector } from "react-redux";
import CustomAlert from "../Alert";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";

interface ComplaintFormProps {
  onCancel: () => void
}

const { Option } = Select;

const ComplaintForm: React.FC<ComplaintFormProps> = ({ onCancel }) => {
  const loggedInUser =
    useSelector(selectLogedEmployee).logedInemployee?.employee;
  const employeeId = loggedInUser.id;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [form] = Form.useForm();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const onFinish = async (values: any) => {
    const body = {
      complainee_employee_id: employeeId,
      name: values.name,
      email: values.email,
      type: values.complaintType,
      complaint_message: values.complaint,
      organization_id: loggedInUser?.organization_id
    };

    const data = await storeComplaintFormData(body);

    if (data.status == 201) {
      form.resetFields();
      setshowAlert(true);
      setAlertType("success");
      setTimeout(() => {
        onCancel()
      }, 500)
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        className="complaint-form-container"
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input
                className="complaint-modal-input-field"
                placeholder="Enter your name ..."
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              initialValue={loggedInUser?.workEmail}
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input
                className="complaint-modal-input-field"
                placeholder="Enter your email ..."
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Complaint Type"
              name="complaintType"
              rules={[
                { required: true, message: "Please select a complaint type!" },
              ]}
            >
              <Select
                placeholder="Select a complaint type"
                className="complaint-modal-input-field"
                
              >
                <Option value="Accessibility Issues">
                  Accessibility Issues
                </Option>
                <Option value="System Performance">System Performance</Option>
                <Option value="Security Concerns">Security Concerns</Option>
                <Option value="User Interface and Design">
                  User Interface and Design
                </Option>
                <Option value="Functionality Problems">
                  Functionality Problems
                </Option>
                <Option value="Account Management">Account Management</Option>
                <Option value="Data Accuracy and Integrity">
                  Data Accuracy and Integrity
                </Option>
                <Option value="Training and Support">
                  Training and Support
                </Option>
                <Option value="Communication Problems">
                  Communication Problems
                </Option>
                <Option value="Legal and Compliance Concerns">
                  Legal and Compliance Concerns
                </Option>
                <Option value="Feedback and Suggestions">
                  Feedback and Suggestions
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Complaint"
              name="complaint"
              rules={[
                { required: true, message: "Please input your complaint!" },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter your complaint ..." />
            </Form.Item>
          </Col>
          <Col span={24} className="float-button-complaint-modal-btn-container">
            <Form.Item>
              <Button
                className=" storefront-green-button"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default ComplaintForm;
