import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Form, Row, Col, Input, Checkbox, Button } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";
import { fetchAllowanceApi, updateGradeApi } from "../../services";

interface PayrollGradeEditprops {
  recordId: string;
  data: any;
  onclose: () => void;
}
const PayrollGradeEdit: React.FC<PayrollGradeEditprops> = (props) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);

  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [form] = Form.useForm();
  const [initalAllowanceOption, setInitalAllowanceOption] = useState<any>();
  const [allowedOptions, setAllowedOptions] = useState(
    props.data.allowedOptions
  );
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useEffect(() => {
    fetchTableData();
  }, []);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    const newAllowedOptions = initalAllowanceOption?.filter(
      (option: any) => option.isActive
    );
    setAllowedOptions(newAllowedOptions);
  }, [initalAllowanceOption]);

  const handleCheckboxChange = (index: any) => {
    const newOptions = [...initalAllowanceOption];
    newOptions[index].isActive = !newOptions[index].isActive;
    setInitalAllowanceOption(newOptions);
  };

  const handleInputChange = (index: any, key: any, value: any) => {
    const newOptions = [...initalAllowanceOption];
    newOptions[index][key] = value;
    if (key == "unitAllowed" || key == "unitPrice") {
      newOptions[index]["totalprice"] = newOptions[index]["unitPrice"] * value;
    }
    setInitalAllowanceOption(newOptions);
  };

  const fetchTableData = async () => {
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
    };
    const res = await fetchAllowanceApi(data);
    if (res.data && res.data.length > 0) {
      const newArray = res.data?.map((item: any) => {
        return {
          ...item,
          isActive: false,
          unitAllowed: "",
          totalprice: 0,
          unitPrice: "",
        };
      });

      let updatedArray = [...newArray];
      for (const objdata of props?.data?.allowed_option) {
        const indexInA = updatedArray.findIndex(
          (objnewArray) => objnewArray.id === objdata.id
        );

        if (indexInA !== -1) {
          updatedArray[indexInA] = objdata;
        }
      }
      setInitalAllowanceOption(updatedArray);
    }
  };
  const onFinish = async (values: any) => {
    setshowAlert(false);
    if (allowedOptions?.length > 0) {
      const data = {
        id: props.recordId,
        name: capitalizeWords(values.grade),
        allowed_option: allowedOptions,
      };
      const res = await updateGradeApi(data, organizationId);
      if (res.status == 201 || res.status == 204) {
        props.onclose();
        setshowAlert(true);
        setAlertMessage("");
        setAlertDescription("Deatils Update Sucessfully");
        setAlertType("success");
      } else {
        setshowAlert(true);
        setAlertMessage("");
        setAlertDescription(
          res.error?.message ||
            "oops we are facing some temporary issue with your request"
        );
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }
    } else {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        "please check at least one allowance" ||
          "oops we are facing some temporary issue with your request"
      );
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row>
            <Col className="modal-content">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="grade"
                    label="Enter Grade"
                    initialValue={props?.data?.name}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter an Grade",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter grade..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <strong>Name </strong>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <strong>Unit(if any) </strong>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <strong>Unit/Price(if any) </strong>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <strong>Unit Allowed(if any) </strong>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                  <strong>Total Price </strong>
                </Col>
              </Row>
              {initalAllowanceOption?.map((option: any, index: any) => (
                <Row
                  key={index}
                  gutter={8}
                  className={
                    option.isActive
                      ? "payrollgrads-form-container-set-row-postion-check"
                      : "payrollgrads-form-container-set-row-postion-uncheck"
                  }
                >
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                    <Checkbox
                      onChange={() => handleCheckboxChange(index)}
                      checked={option.isActive}
                    >
                      {option.name}
                    </Checkbox>
                  </Col>
                  {option.isActive && (
                    <>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Input
                          value={option.unit}
                          placeholder="Enter Unit"
                          disabled
                          size="large"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Input
                          value={option?.unitPrice}
                          placeholder="Enter Unit/Price"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "unitPrice",
                              e.target.value
                            )
                          }
                          disabled={!option.unit}
                          size="large"
                        />
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                        <Input
                          placeholder="Enter Unit Allowed"
                          size="large"
                          value={option?.unitAllowed}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "unitAllowed",
                              e.target.value
                            )
                          }
                          disabled={!option.unit}
                        />
                      </Col>

                      <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                        <Input
                          placeholder="Enter Total Price"
                          size="large"
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "totalprice",
                              e.target.value
                            )
                          }
                          value={option.totalprice}
                          disabled={
                            option.unitPrice && option.unit ? true : false
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ))}
            </Col>
          </Row>
          <Row className="global-modal-bottom-row">
            <Col span={24} className="modal-update-btn-col">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default PayrollGradeEdit;
