import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchEarlyLeftAbsentPolicies = async (
  organizationId: number,
  companyId: string,

  matchCondition: string,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("absent_policy")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("company_id", companyId)
      .order("id", { ascending: true })
      .limit(1);
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const AbsentPolicyCreationVersion = async (
  organizationId: number,
  companyId: string,
  branchId:string,
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("absent_policy")
      .select("version_name,company_id,organization_id,branch_id,id")
      .eq("organization_id", organizationId);
    if (companyId) {
      query.eq("company_id", companyId);
    }
    if(branchId){
      query.eq("branch_id", branchId);

    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setAbsentPolicyData = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("absent_policy")
      .insert([data]);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAbsentPolicyData = async (
  organizationId: number,
  companyId: string,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("absent_policy")
      .select("*")
      .eq("organization_id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const deleteAbsentPolicyData = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("absent_policy")
      .delete()
      .match({ id: id });

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
