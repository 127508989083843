import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getAvailableLeavesDataApi = async (
  companyId: string | number,
  branchId: string | number
): Promise<any> => {
  try {
    let query = supabase
      .from("leavePolicy")
      .select("policyTypes,id")
      .eq("companyId", companyId)
      .order("created_at", { ascending: false })
      .limit(1);

    if (branchId) {
      query = query.eq("branchId", branchId);
    }

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const onFinishLeaveRequestApi = async (body: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(body.organization_id)
      .from("employee_leave_request")
      .insert(body)
      .select("id")
    return res;
  } catch {
    return catch_return;
  }
};

export const getHeadManagerId = async (data: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.orgid)
      .from("employee")
      .select("department!employee_department_id_fkey(head_manager_id)")
      .eq("id",data.id)
      .maybeSingle()
    return res;
  } catch {
    return catch_return;
  }
};

export const getLeaveRequestReceiverIdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.org_id)
      .from("employee")
      .select(`id`)
      .eq("status", "active")
      .eq("organization_id", data.org_id)
      .eq("access_right_group", data.role);
    if (data.company_id) {
      query = query.eq("company_id", data.company_id);
    }
    if (data.branch_id) {
      query = query.eq("branch_id", data.branch_id);
    }
    const Employees_res = await query;
    return Employees_res;
  } catch {
    return catch_return;
  }
};

export const fetchLeaveRequestListDataApi = async (
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    const currentYear = new Date().getFullYear();
    const startOfYear = `${currentYear}-01-01`;
    const endOfYear = `${currentYear}-12-31`;
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select(
        "leave_type,leave_count,starting_date,ending_date,created_at,leave_status,id"
      )
      .eq("employee_id", employeeId)
      .gte("starting_date", startOfYear)
      .lte("starting_date", endOfYear)
      .order("created_at", { ascending: false });
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchLeavePolicyDataApi = async (
  organizationId: number,
  companyId: string | number | undefined | null
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .select("policy_types,id, slabs")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const response = await query
      .order("created_at", { ascending: false })
      .limit(1);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeApprovedLeavesData = async (
  organizationId: number,
  employeeId: string,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select("id,created_at,leave_type,leave_count")
      .eq("employee_id", employeeId)
      .eq("organization_id", organizationId)
      .eq("company_id", companyId)
      .ilike("leave_status", "%Approved%");

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeWorkingDaysData = async (
  organizationId: number,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_timing_policies")
      .select("working_days")
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};


export const updateEmployeeArrayForApproval = async (body: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(body.orgid)
      .from("employee_leave_request")
      .update({ leave_approval_employee_ids: body.leave_approval_employee_ids })
      .eq("id",body.id)
    return res;
  } catch {
    return catch_return;
  }
};
