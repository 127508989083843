import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Form, Row, Col, Input, Select, Button, Checkbox } from "antd";
import { customunits } from "../UnitsList/index";

import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import { setAllowanceApi } from "../../services";
import CustomAlert from "../../../../common/Alert";
import { DeleteFilled } from "@ant-design/icons";
import { allorgCompanyBranchesApi } from "../../../../services/Global";
import { allorgCompanyApi } from "../../../../services/Global";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface PayrollAllowanceprops {
  onCancel: () => void;
}
const PayrollAllowanceCreate: React.FC<PayrollAllowanceprops> = (props) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [rowUpdateCounter, setRowUpdateCounter] = useState<number>(0);
  const [form] = Form.useForm();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const [needApproval, setNeedApproval] = useState<boolean>(false);
  const [allowAttachment, setAllowAttachment] = useState<boolean>(false);
  const [questions, setQuestions] = useState<string[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchCompanies();
    return () => {
      setNeedApproval(false);
      setAddQuestion(false);
      setQuestions([]);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
    }
    fetchBranches();
  }, [selectedCompanyId]);

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      if (companyId) {
        form.setFieldValue("companyId", companyId);
      } else {
        form.setFieldValue("companyId", resCompanies?.data[0]?.id);
        setSelectedCompanyId(resCompanies?.data[0]?.id);
      }
    }
  };
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      const resBranches = await allorgCompanyBranchesApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        if (branchId) {
          form.setFieldValue("branchId", branchId);
        } else {
          form.setFieldValue("branchId", resBranches?.data[0]?.id);
        }
      }
    }
  };

  const handleAddQuestion = () => {
    const updatedQuestions = [...questions, ""];
    setQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const data = {
      organizationId: organizationId,
      companyId: values.companyId || companyId,
      branchId: values.branchId || branchId,
      allowanceName: capitalizeWords(values.allowanceName),
      unit: values.unit,
      questions: questions,
      allowAttachment: allowAttachment,
      needApproval: needApproval,
      addQuestion: addQuestion,
    };

    const res = await setAllowanceApi(data);
    if (res.status == 201 || res.status == 204) {
      form.resetFields();
      setQuestions([]);
      setAllowAttachment(false);
      setAddQuestion(false);
      setNeedApproval(false);
      setshowAlert(true);
      setButtonLoading(false);
      setAlertMessage("");
      setAlertDescription("Allowance Add Sucessfully");
      setAlertType("success");
      props.onCancel();
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        res.error?.message ||
          "oops we are facing some temporary issue with your request"
      );
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            {!companyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="companyId"
                  label="Select Company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Company",
                    },
                  ]}
                  initialValue={companyId}
                >
                  <Select
                    onChange={(value) => setSelectedCompanyId(value)}
                    placeholder="Select a Company"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredCompanies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="branchId"
                  label="Select Branch (If any)"
                  initialValue={branchId}
                >
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    placeholder="Select a Branch"
                    allowClear
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="allowanceName"
                label="Enter Allowance Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter an Allowance Name",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter allowance name..." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="unit"
                label="Select Unit(If any)"
                initialValue={customunits[0]}
              >
                <Select
                  placeholder="Select Unit(If any)"
                  allowClear
                  showSearch
                  defaultValue={customunits[0]}
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={() => setRowUpdateCounter(rowUpdateCounter + 1)}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {customunits.map((unit, index) => (
                    <Select.Option key={index} value={unit}>
                      {unit}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name="needApproval" label="">
                <Checkbox
                  onChange={() => setNeedApproval(!needApproval)}
                  value={needApproval}
                >
                  Need Approval?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name="allowAttachment" label="">
                <Checkbox
                  onChange={() => setAllowAttachment(!allowAttachment)}
                  value={allowAttachment}
                >
                  Allow any Attachment?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="addQuestion" label="">
                <Checkbox
                  onChange={() => setAddQuestion(!addQuestion)}
                  value={addQuestion}
                >
                  Would you like to ask any questions before the claim
                  allowance?
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <div className="allaowance-last-row">
            {addQuestion &&
              questions?.map((question, index) => (
                <Row gutter={[16, 16]}>
                  <Col span={20}>
                    <Form.Item key={index} label="Enter Question?">
                      <Input
                        placeholder="Enter question..."
                        size="large"
                        value={question}
                        onChange={(e) => {
                          const updatedQuestions = [...questions];
                          updatedQuestions[index] = e.target.value;
                          setQuestions(updatedQuestions);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label=" ">
                      <Button
                        className="global-btn-width"
                        type="default"
                        onClick={() => handleDeleteQuestion(index)}
                        icon={<DeleteFilled />}
                        size="large"
                      >
                        Delete
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            {addQuestion && (
              <Button onClick={handleAddQuestion}>Add Question</Button>
            )}
          </div>
          <Row className="global-modal-bottom-row allowance-form-container-last-row">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default PayrollAllowanceCreate;
