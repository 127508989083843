import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Row, Col, Divider, Checkbox, Select } from "antd";
import "./index.css";
import TextArea from "antd/es/input/TextArea";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  allOrgCompaniesDataApi,
  allOrgCompanyBranchesApi,
} from "../../Deduction/services";
import { createOverTimePolicyApi, getOverTimePolicyVersion } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const OverTime: React.FC = () => {
  const [form] = Form.useForm();
  const [questions, setQuestions] = useState<string[]>([]);
  const [submissionOptions, setSubmissionOptions] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [version, setVersion] = useState<number>(0);
  const [tempVersion, setTempVersion] = useState<string>();
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [disabled, setIsDisabled] = useState<boolean>(false);

  const organizationId = loggedInemployee?.employee?.organization_id;
  const companyId = loggedInemployee?.employee?.company_id;
  const branchId = loggedInemployee?.employee?.branch_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("companyId", companyId);
      setSelectedCompanyId(companyId);
      if (branchId) {
        form.setFieldValue("branchId", branchId);
      }
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    getVersion();
  }, [counter]);

  const fetchCompanies = async () => {
    const resCompanies = await allOrgCompaniesDataApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies?.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
  };

  const fetchBranches = async () => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
    } else {
      if (selectedCompanyId) {
        const resBranches = await allOrgCompanyBranchesApi(
          organizationId,
          selectedCompanyId
        );
        if (resBranches?.data) {
          setFilteredBranches(resBranches?.data);
          form.setFieldValue("branchId", resBranches?.data[0]?.id);
        }
      }
    }
  };

  const getVersion = async () => {
    const { data, error } = await getOverTimePolicyVersion(
      organizationId,
      companyId
    );
    if (data) {
      setVersion(data.length);
      const tempVersionValue = "Version #" + (data.length + 1);
      setTempVersion(tempVersionValue);
      form.setFieldsValue({
        policyName: tempVersionValue,
      });
    }
  };

  const onFinish = async (values: any) => {
    setButtonLoader(true);
    const data = {
      version_name: values.policyName,
      organization_id: organizationId,
      company_id: values.companyId || companyId || null,
      branch_id: values.branchId || branchId || null,
      working_hours_allowed_per_working_day: values.maxhoursWorkingDay,
      working_hours_allowed_per_nonworking_day: values.maxhoursNonWorkingDay,
      hourly_pay_calculation_multiplier: values.hourlyovertime,
      description: values.Description,
    };
    try {
      const { data: insertedData, error } = await createOverTimePolicyApi(data);

      if (error) {
        setshowAlert(true);
        setAlertType("error");
        setButtonLoader(false);
      } else {
        setshowAlert(true);
        setAlertType("success");
        setButtonLoader(false);

        form.resetFields();
        setCounter(counter + 1);
      }
      setQuestions([]);
    } catch {
      setButtonLoader(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className=" global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          {!companyId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="companyId"
                label="Select a Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
                initialValue={loggedInemployee?.employee?.company_id}
              >
                <Select
                  disabled={disabled}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  defaultValue={filteredCompanies[0]?.id}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Select Branch (If any)">
                <Select
                  disabled={disabled}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="policyName"
              label="Policy Name"
              rules={[{ required: true }]}
            >
              <Input size="large" value={tempVersion} placeholder="Enter policy name..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="maxhoursWorkingDay"
              label="Maximum Hours/working-Day Allow?"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]+$/),
                  message:
                    "Please enter a valid number between 0 and 24 (e.g., 1.5, 2)",
                },
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]+$/),
                  validator: (rule, value) => {
                    const floatValue = parseFloat(value);
                    if (value && floatValue > 24) {
                      return Promise.reject(
                        "Please enter a number less than or equal to 24."
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Maximum hours/working-Day Allow?" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="maxhoursNonWorkingDay"
              label="Maximum Hours/Non-Working-Day Allow?"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]+$/),
                  message:
                    "Please enter a valid number between 0 and 24 (e.g., 1.5, 2)",
                },
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]+$/),
                  validator: (rule, value) => {
                    const floatValue = parseFloat(value);
                    if (value && floatValue > 24) {
                      return Promise.reject(
                        "Please enter a number less than or equal to 24."
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Maximum Hours/Non-Working-Day Allow?" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="hourlyovertime"
              label="Enter multiplier for hourly overtime pay(e.g. 1 for same Hourly pay, 1.5 for time-and-a-half Hourly pay, 2 for double-time Hourly pay)"
              rules={[
                {
                  required: true,
                  message: "Please enter the overtime pay multiplier",
                },
                {
                  pattern: new RegExp(/^[0-9]*\.?[0-9]+$/),
                  message: "Please enter a valid number (e.g., 1.5, 2)",
                },
              ]}
            >
              <Input size="large" placeholder="Enter the Multiplier for Overtime Pay" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="Description"
              label="Description"
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Enter Description..." rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row">
          <Col span={24} className="overtime-button-policy-position">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoader}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default OverTime;
