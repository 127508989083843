import React, { useEffect, useState } from "react";
import "./index.css";
import { Space } from "antd";
import { getExistingEmployeeBankRecords } from "../../../components/EmployeesManagement/EmployeeViewDetails/services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../NoDataFoundIcon";

interface BankDetailsProps {
  employeeId: any;
  loadingState: (state: boolean) => void;
}

const BankDetails: React.FC<BankDetailsProps> = ({
  employeeId,
  loadingState,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;
  const [bankRecords, setBankRecords] = useState<any[]>([]);

  useEffect(() => {
    getExistingRecords();
  }, []);

  const getExistingRecords = async () => {
    loadingState(true);
    const response = await getExistingEmployeeBankRecords(
      employeeId,
      organizationId
    );
    loadingState(false);
    if (response.data) {
      const tableData = response.data.flatMap((item: any) => {
        if (Array.isArray(item.bank_information)) {
          return item.bank_information;
        } else if (
          item.bank_information &&
          typeof item.bank_information === "object"
        ) {
          return [item.bank_information];
        }
        return [];
      });
      setBankRecords(tableData);
     }
  };

  const bankPriorityArray  = [
    "Primary", "Secondary", "Tertiary", "Quaternary", "Quinary"
  ]

  return (
    <div className="bank-container">
      {bankRecords.length === 0 && <NoDataFoundIcon />}
      {bankRecords.length === 1 && (
        <span className="bank-text-bold">Primary Bank Details</span>
      )}
      {bankRecords.length > 1 &&
        bankRecords.map((record, index) => (
          <div key={index} className="bank-inner-container">
            <span className="bank-text-bold">{bankPriorityArray[index]} Bank Details</span>
            {record.country && (
              <div className="bank-text-container">
                <span className="bank-text">Country: </span>
                <span className="bank-text-value">{record.country}</span>
              </div>
            )}
            {record.bankName && (
              <div className="bank-text-container">
                <span className="bank-text">Bank Name: </span>
                <span className="bank-text-value">{record.bankName}</span>
              </div>
            )}
            {record.branchName && (
              <div className="bank-text-container">
                <span className="bank-text">Branch Name: </span>
                <span className="bank-text-value">{record.branchName}</span>
              </div>
            )}
            {record.branchCode && (
              <div className="bank-text-container">
                <span className="bank-text">Branch Code: </span>
                <span className="bank-text-value">{record.branchCode}</span>
              </div>
            )}
            {record.accountTitle && (
              <div className="bank-text-container">
                <span className="bank-text">Account Title: </span>
                <span className="bank-text-value">{record.accountTitle}</span>
              </div>
            )}
            {record.accountNumber && (
              <div className="bank-text-container">
                <span className="bank-text">Account Number: </span>
                <span className="bank-text-value">{record.accountNumber}</span>
              </div>
            )}
            {record.iban && (
              <div className="bank-text-container">
                <span className="bank-text">IBAN: </span>
                <span className="bank-text-value">{record.iban}</span>
              </div>
            )}
            {record.dateEffective && (
              <div className="bank-text-container">
                <span className="bank-text">Effective up to: </span>
                <span className="bank-text-value">
                  {new Date(record.dateEffective).toLocaleDateString()}
                </span>
              </div>
            )}
          </div>
        ))}
      {bankRecords.length === 1 &&
        bankRecords.map((record, index) => (
          <div key={index} className="bank-inner-container">
            {record.country && (
              <div className="bank-text-container">
                <span className="bank-text">Country: </span>
                <span className="bank-text-value">{record.country}</span>
              </div>
            )}
            {record.bankName && (
              <div className="bank-text-container">
                <span className="bank-text">Bank Name: </span>
                <span className="bank-text-value">{record.bankName}</span>
              </div>
            )}
            {record.branchName && (
              <div className="bank-text-container">
                <span className="bank-text">Branch Name: </span>
                <span className="bank-text-value">{record.branchName}</span>
              </div>
            )}
            {record.branchCode && (
              <div className="bank-text-container">
                <span className="bank-text">Branch Code: </span>
                <span className="bank-text-value">{record.branchCode}</span>
              </div>
            )}
            {record.accountTitle && (
              <div className="bank-text-container">
                <span className="bank-text">Account Title: </span>
                <span className="bank-text-value">{record.accountTitle}</span>
              </div>
            )}
            {record.accountNumber && (
              <div className="bank-text-container">
                <span className="bank-text">Account Number: </span>
                <span className="bank-text-value">{record.accountNumber}</span>
              </div>
            )}
            {record.iban && (
              <div className="bank-text-container">
                <span className="bank-text">IBAN: </span>
                <span className="bank-text-value">{record.iban}</span>
              </div>
            )}
            {record.dateEffective && (
              <div className="bank-text-container">
                <span className="bank-text">Effective up to: </span>
                <span className="bank-text-value">
                  {new Date(record.dateEffective).toLocaleDateString()}
                </span>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default BankDetails;
