import React, { useEffect, useState } from "react";
import { Modal, Popconfirm, Space, TablePaginationConfig } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { SortOrder } from "antd/lib/table/interface";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import {
  deleteOverTimePolicyData,
  fetchViewOverTimePolicyData,
} from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import OvertimeEmployeeView from "../EmployeeView";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface OvertimeListProps {
  searchValue?: string;
}

const OvertimeListView: React.FC<OvertimeListProps> = ({ searchValue }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [showOvertimeViewModal, setShowOvertimeViewModal] =
    useState<boolean>(false);
  const [overtimeViewModalId, setOvertimeViewModalId] = useState<any>(null);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "Overtime Policy"
  );
  const organizationId = loggedInEmployee?.employee?.organization_id;

  const companyId = loggedInEmployee?.employee?.company_id;
  const deletee = access?.deleteAccess;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchData = async () => {
    setLoading(true);

    const response = await fetchViewOverTimePolicyData(
      organizationId,
      companyId
    );

    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.length,
        },
      });
      setData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setLoading(false);
    }
    if (response.error) {
    }
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setData(filterData);

    setLoading(false);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleDelete = async (record: any) => {
    const { data, error } = await deleteOverTimePolicyData(
      record.id,
      organizationId
    );

    if (error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const hanldeViewOvetimeModalVisible = (value: any) => {
    setShowOvertimeViewModal(true);
    setOvertimeViewModalId(value);
  };

  const hanldeViewOvetimeModalCancel = () => {
    setShowOvertimeViewModal(false);
    setOvertimeViewModalId(null);
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Overtime Per Day",
      dataIndex: "working_hours_allowed_per_working_day",
      key: "working_hours_allowed_per_working_day",
    },
    {
      title: "Overtime in non-working day",
      dataIndex: "working_hours_allowed_per_nonworking_day",
      key: "working_hours_allowed_per_nonworking_day",
    },
    {
      title: "Overtime Pay Calculation",
      dataIndex: "hourly_pay_calculation_multiplier",
      key: "hourly_pay_calculation_multiplier",
    },

    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
          <a>
            <EyeOutlined
              onClick={() => hanldeViewOvetimeModalVisible(record)}
            />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        dataSource={data}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
      />

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}

      <Modal
        open={showOvertimeViewModal}
        onCancel={hanldeViewOvetimeModalCancel}
        width={1000}
        footer={false}
        className="global-modal-main-container"
        destroyOnClose
        title={"Overtime Policy"}
      >
        <div className="global-card-inner-container">
          <OvertimeEmployeeView propsData={overtimeViewModalId} />
        </div>
      </Modal>
    </div>
  );
};

export default OvertimeListView;
