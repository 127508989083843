import React, { useState, useEffect } from "react";
import { Row, Col, Descriptions, Divider } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { fetchOverTimeEarlyLeftPolicy } from "../services";
import { TriangleLoader } from "../../../../common/ReactLoader";

const OvertimeEmployeeView: React.FC<any> = ({ propsData }) => {
  const [earlyLeftPolicy, setEarlyLeftPolicy] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const organizationId =
    loggedInEmployee?.logedInemployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.logedInemployee?.employee?.company_id;
  const branchId = loggedInEmployee?.logedInemployee?.employee?.branch_id;

  const fetchEarlyLeftPolicies = async () => {
    if (employeeRole !== "employee") {
      const propDataArray = [propsData];
      setEarlyLeftPolicy(propDataArray);
    } else {
      const { data, error } = await fetchOverTimeEarlyLeftPolicy(
        organizationId,
        companyId,
        branchId
      );
      if (data) {
        setEarlyLeftPolicy(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEarlyLeftPolicies();
  }, []);

  const generateItemsArray = (policy: any): any[] => {
    return [
      {
        key: "1",
        label: (
          <span className="policy-description-title">
            Allowed Working Hours in a Day
          </span>
        ),
        span: 3,
        children: (
          <span className="description">
            The policy on allowed overtime in a day determines the maximum
            number of extra hours an employee can work within a single day. As
            per this policy, employees are permitted to work up to{" "}
            <strong>{policy?.working_hours_allowed_per_working_day}</strong>{" "}
            hours per day.
          </span>
        ),
      },
      {
        key: "2",
        span: 3,
        label: (
          <span className="policy-description-title policy-descriptions">
            Allowed Working Hours in a non-working Day
          </span>
        ),
        children: (
          <span className="description">
            The policy on allowed overtime in a non-working day outlines the
            maximum number of hours an employee can work on a day designated as
            a non-working day. According to this policy, employees are allowed
            to work up to{" "}
            <strong>{policy?.working_hours_allowed_per_nonworking_day}</strong>{" "}
            hours on non-working days.
          </span>
        ),
      },
      {
        key: "3",
        span: 3,
        label: (
          <span className="policy-description-title">
            How is overtime pay calculated?
          </span>
        ),
        children: (
          <span className="description">
            The policy on overtime pay calculation specifies the method used to
            determine the additional compensation provided to employees for
            overtime work. According to this policy, overtime pay is calculated
            based on{" "}
            <strong>{policy?.hourly_pay_calculation_multiplier}</strong> times
            the hourly pay.
          </span>
        ),
      },
     
      {
        key: "5",
        span: 3,
        label: <span className="policy-description-title">Description</span>,
        children: (
          <span className="description">
            The policy description provides additional context and details
            regarding the overall overtime policy. This includes any specific
            guidelines, expectations, or consequences related to overtime work.{" "}
            <br></br> {policy?.description}
          </span>
        ),
      },
    ];
  };

  return (
    <div className="hr-overtime-policy-view-container">
      <div className="hr-overtime-policy-view-content">
        <Row justify="center">
          <Col span={24}>
            {earlyLeftPolicy.length < 1 ? (
              <div className="no-data-found-policy">
                <NoDataFoundIcon />
              </div>
            ) : (
              earlyLeftPolicy?.map((policy) => (
                <>
                  <Row>
                    <Col span={24}>
                      <p>
                        Our company's overtime policy is designed to support
                        employees in meeting additional work requirements while
                        maintaining a healthy work-life balance. Key features of
                        the policy include:
                        <br />
                      </p>
                    </Col>
                  </Row>
                  <Descriptions
                    className="overtime-policy-descriptions"
                    key={policy.id}
                    layout="horizontal"
                    bordered
                    items={generateItemsArray(policy)}
                  />
                </>
              ))
            )}
          </Col>
        </Row>
      </div>
      {loading && <TriangleLoader />}
    </div>
  );
};

export default OvertimeEmployeeView;
