import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//-------------------------------------Create Jobtitle------------------------------------------

export const fetchData = async (data: any) => {
  try {
    const query = supabase_client_for_Org_schema(data)
      .from("job_title")
      .select(`*,  company(name)`)
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }
    if (data.searchQuery) {
      query.ilike("name", `%${data.searchQuery}%`);
    }

    if (data.jobTitleFilter) {
      query.ilike("name", `%${data.jobTitleFilter}%`);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const onFinishData = async (data: any, orgId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("job_title")
      .insert(data);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//----------------------------------------Table Listview----------------------------------------

export const getTableDetails = async (data: any) => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("job_title")
      .select(`*,  company(name)`)
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const deleteRecord = async (id: string, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("job_title")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//----------------------------------------Edit Jobtitle----------------------------------------

export const getOtherData = async (
  id: string | number | any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("job_title")
      .select(
        `name, mission,company_id,  branch_id, company(name), branch(name)`
      )
      .eq("id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handlingDataUpdation = async (body: any, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("job_title")
      .update(body.body)
      .eq("id", body.id);

    return response;
  } catch (error) {
    return catch_return;
  }
};
