import { supabase_client_for_Org_schema } from "../../../services/supabase";

export const submitWorkExperienceAPI = async (data: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("employee_work_experience")
      .insert(data);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
