import React, { useState, useEffect } from "react";
import { Input, Button, Col, Row, Form, Select } from "antd";
import "./index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import {
  fetchAllEmployeeWithOutEmergencyContactApi,
  insertEmergencyContactdApi,
} from "../services";
import TextArea from "antd/es/input/TextArea";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateRelativeDetails: React.FC<props> = ({ onCancel }) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const loggedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [form] = useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const organization_id = loggedInemployee?.employee.organization_id;
  const companyId = loggedInemployee?.employee?.company_id;
  const branchId = loggedInemployee?.employee?.branch_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * useEffect hook that calls getAllCompanies function when the component mounts.
   * @param {function} getAllCompanies - Function to fetch all companies data.
   * @returns None
   */
  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("selectedCompany", companyId);
      if (branchId) {
        setSelectedBranch(branchId);
        form.setFieldValue("selectedBranch", branchId);
      }
    } else {
      getAllCompaniess();
    }
  }, []);

  /**
   * useEffect hook that calls the getAllBranchess function when the selectedCompany state changes.
   * @param {function} getAllBranchess - Function to fetch all branches.
   * @param {any} selectedCompany - The selected company that triggers the effect.
   * @returns None
   */
  useEffect(() => {
    if (!(branchId || companyId)) {
      getAllBranchess();
    }
  }, [selectedCompany]);

  /**
   * useEffect hook that triggers a function to get employees without skills when a selected company is present.
   * @param {function} getEmployeesWithoutSkills - Function to get employees without skills.
   * @param {object} selectedCompany - The selected company object.
   * @param {string} selectedBranch - The selected branch within the company.
   * @returns None
   */
  useEffect(() => {
    if (selectedCompany || selectedBranch) {
      getEmployeesWithoutSkills();
    }
  }, [selectedCompany, selectedBranch]);

  /**
   * Asynchronously fetches all branches for a given company and updates the state accordingly.
   * Resets the branches array, sets branch select loading to true, resets selected branch in the form,
   * fetches all branches by company ID, and updates the branches state if data is received.
   * @returns None
   */
  const getAllBranchess = async () => {
    if (branchId) {
      setSelectedBranch(branchId);
      form.setFieldValue("selectedBranch", branchId);
    } else {
      setBranches([]);
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      const companyId = form.getFieldValue("selectedCompany");
      const response = await fetchAllBranchesByComapnyIdApi(
        organization_id,
        companyId
      );
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };
  /**
   * Fetches all companies associated with the logged-in employee's organization asynchronously.
   * Sets loading state to true while fetching.
   * Updates the fetched companies state with the response data if available.
   * Sets loading state to false after fetching is complete.
   * @returns None
   */
  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await fetchallCompanyByOrgIdApi(
      loggedInemployee?.employee?.organization_id
    );
    if (response.data) {
      setFetchedCompanies(response.data);
      setCompanySelectLoading(false);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
      setSelectedCompany(response?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  /**
   * Handles the saving of emergency contact information by making an API call to insert the data.
   * Sets loading state for the save button, prepares the data to be sent, and handles the response accordingly.
   * @returns None
   */
  const handleSave = async () => {
    setButtonLoading(true);
    const data = {
      employee_id: selectedEmployeeId,
      email: form.getFieldValue("email"),
      relative_name: form.getFieldValue("name"),
      relation_with_employee: form.getFieldValue("relation"),
      phone_number: form.getFieldValue("mobile"),
      work_phone_number: form.getFieldValue("worktelephone"),
      organization_id: loggedInemployee?.employee?.organization_id,
      company_id: selectedCompany || companyId || null,
      branch_id: selectedBranch || branchId || null,
      address: form.getFieldValue("address"),
    };
    const res = await insertEmergencyContactdApi(data);

    if (res.error) {
      setshowAlert(true);
      setButtonLoading(false);
      setAlertType("error");
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("success");
      form.resetFields();
      setFilteredEmployees([]);
      setBranches([]);
      getEmployeesWithoutSkills();
      onCancel();
    }
  };

  /**
   * Retrieves a list of employees without specific skills asynchronously.
   * Clears the current list of filtered employees, resets the "employeeId" field value in the form,
   * and then fetches a list of employees without emergency contact information based on the provided data.
   * @returns None
   */
  const getEmployeesWithoutSkills = async () => {
    setFilteredEmployees([]);
    setEmployeeSelectLoading(true);
    form.setFieldValue("employeeId", "");
    const data = {
      organizationId: loggedInemployee?.employee?.organization_id,
      companyId: form.getFieldValue("selectedCompany"),
      branchId: form.getFieldValue("selectedBranch"),
    };
    const res = await fetchAllEmployeeWithOutEmergencyContactApi(data);
    setFilteredEmployees(res);
    form.setFieldValue("employeeId", res[0]?.label);
    setSelectedEmployeeId(res[0]?.value);
    setEmployeeSelectLoading(false);
  };

  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleSave}
      >
        <Row gutter={16}>
          {!companyId && (
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a company..."
                rules={[
                  {
                    required: true,
                    message: "Please select a company..",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={setSelectedCompany}
                  placeholder="Select a company"
                  loading={companySelectLoading}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name={"selectedBranch"} label="Select a branch...">
                <Select
                  allowClear
                  showSearch
                  onChange={setSelectedBranch}
                  placeholder="Select a branch"
                  loading={branchSelectLoading}
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select an employee"
                loading={employeeSelectLoading}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={setSelectedEmployeeId}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input size="large" placeholder="Enter Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Relationship"
              name="relation"
              rules={[
                {
                  required: true,
                  message: "Please enter your Relationship",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Relationship" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Home Telephone"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter your Relationship",
                },

                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter your phone number..."
                inputProps={{
                  required: true,
                }}
                defaultCountry="pk"
              />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Work Telephone"
              name="worktelephone"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number...",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter your phone number..."
                inputProps={{
                  required: true,
                }}
                defaultCountry="pk"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Street Address" name="address">
              <TextArea placeholder="Enter street address" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="global-modal-bottom-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                loading={buttonLoading}
                size="large"
                htmlType="submit"
              >
                Create
              </Button>{" "}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateRelativeDetails;
