import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Empty } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { FaUserCheck } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { TriangleLoader } from "../../ReactLoader";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchEmployeeResponsibilityAndReports } from "../services";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../ExtractAccessRightObj";
const UserResponsibilityandReportInfoUpdate: React.FC = () => {
  const loggedIneemployee = useSelector(selectLogedEmployee).logedInemployee;
  let org_details = loggedIneemployee?.employee?.organization_id;
  const employeeId = loggedIneemployee?.employeeId;
  const [employesRes, setEmployeeRes] = useState<any>();
  const [employescounter, setEmployesCounter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Responsibility & Report To"
  );
  const read = access?.readAccess;

  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  const fetchEmployee = async () => {
    setLoading(true);

    const employes_res = await fetchEmployeeResponsibilityAndReports(
      org_details,
      employeeId
    );

    if (employes_res.data) {
      setLoading(false);
      setEmployeeRes(employes_res.data);
      setEmployesCounter(employescounter + 1);
    } else if (employes_res.error) {
      setLoading(false);
    }
  };

  return (
    <>
      {read ? (
        <>
          <Form
            layout="vertical"
            initialValues={{
              companies: employesRes?.company?.name || "",
              branch: employesRes?.branch?.name || "",
              department: employesRes?.department?.name || "",
              team: employesRes?.team?.name || "",
              employeeDseignation: employesRes?.designation?.name || "",
              jobTitle: employesRes?.job_title?.name || "",
              reportTo: employesRes?.reporting_to_role_id?.full_name || "",
              reportToEmployeeworkEmail:
                employesRes?.reporting_to_role_id?.work_email || "",
              employeeResponsibility: employesRes?.responsibilities || "",
            }}
            key={employescounter}
          >
            {" "}
            <div className="org-form-fields">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Company" name="companies">
                    <Input size="large" placeholder="Enter company..." disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Branch" name="branch">
                    <Input size="large" placeholder="Enter branch..." disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Department" name="department">
                    <Input size="large" placeholder="Enter department..." disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Team" name="team">
                    <Input size="large" placeholder="Enter team..." disabled />
                  </Form.Item>
                </Col>
              </Row>
              <h5>
                {" "}
                <FaUserCheck /> Report To
              </h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Report to" name="reportTo">
                    <Input size="large" placeholder="Enter report to..." disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Work Email"
                    name="reportToEmployeeworkEmail"
                  >
                    <Input size="large" placeholder="Enter work email..." disabled />
                  </Form.Item>
                </Col>
              </Row>
              <h5>
                <TbListDetails /> Position And Responsibility
              </h5>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Employee Designation"
                    name="employeeDseignation"
                  >
                    <Input size="large" disabled placeholder="Enter designation..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Job Title" name="jobTitle">
                    <Input size="large" disabled placeholder="Enter job title..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Responsibility"
                    name="employeeResponsibility"
                  >
                    <Input.TextArea
                      name="employeeResponsibility"
                      disabled
                      placeholder="Enter responsibility..."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>

          {loading && <TriangleLoader />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};

export default UserResponsibilityandReportInfoUpdate;
