import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Space,
  Modal,
  Row,
  Col,
  Popconfirm,
  TablePaginationConfig,
  Empty,
} from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import EditTeamModal from "../Edit";
import { DeleteOutlined } from "@ant-design/icons";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";

import CustomAlert from "../../../../common/Alert";

import { SortOrder } from "antd/lib/table/interface";
import { getData, handleDeletion } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import TeamsFilterModal from "../Filter";
import d_dp from "../../../../assets/images/blankProfile.png";

interface TeamData {
  id: number;
  name: string;
  manager_id: string;
  departmentName: string;
  departmentCode: number;
  branch_id: string;
  teamMembers: string;
  employee: any;
  department: any;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface TeamsListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const TeamsList: React.FC<TeamsListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const organizationDetails = useSelector(selectLogedEmployee);
  const organizationIdTrue =
    organizationDetails?.logedInemployee?.employee?.organization_id;
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletingRecord, setDeletingRecord] = useState<TeamData | null>(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [filteredTeamData, setFilteredTeamData] = useState<TeamData[]>([]);
  const [viewingRecord, setViewingRecord] = useState<TeamData | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [alertDescription, setAlertDescription] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState<any>(null);
  const [editModalVisible, setEditModalVisible] = useState<
    boolean | null | undefined
  >(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Organization", "Teams");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchData();
  }, [organizationIdTrue]);
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setFilteredTeamData(originalData.apiData);
    }
  }, [searchValue]);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.company_id;

  const fetchData = async () => {
    try {
      setSelectedTeamId(null);

      setEditModalVisible(false);

      setLoading(true);
      const data = {
        organizationId: organizationIdTrue,
        companyId: companyId,
        searchQuery: searchQuery,
      };

      const response = await getData(data);

      if (response.error) {
      } else {
        const updatedData = response.data.filter((item: any) => item.employee);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: filteredTeamData.length,
          },
        });

        setFilteredTeamData(updatedData as TeamData[]);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: updatedData,
        }));
      }

      setLoading(false);
    } catch (error) {
      return error;
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const onEditClick = (record: TeamData) => {
    setEditModalVisible(true);
    setSelectedTeamId(record.id);
  };

  const onDeleteClick = (record: TeamData) => {
    setDeletingRecord(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setDeletingRecord(null);
  };

  const handleDeleteConfirm = async () => {
    setAlertDescription("");
    if (deletingRecord) {
      try {
        const response = await handleDeletion(
          deletingRecord.id,
          organizationIdTrue
        );
        if (response.error) {
          setshowAlert(true);
          setAlertType("error");
          setAlertDescription(
            "Deletion failed:This Team has assigned employee. Please reassign or remove employee before deleting."
          );
          setDeleteModalVisible(false);
        } else {
          setshowAlert(true);
          setAlertType("success");
          setDeleteModalVisible(false);
          fetchData();
        }
      } catch (error) {
        return error;
      }
    }
    setDeletingRecord(null);
  };

  const onViewClick = (record: TeamData) => {
    setViewingRecord(record);
    setViewModalVisible(true);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setSelectedTeamId(null);
  };
  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Project Lead",
      dataIndex: "employee",
      key: "employee",
      render: (Employees: any) => Employees?.full_name,
    },

    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: TeamData) => (
        <Space size="middle">
          <a onClick={() => onViewClick(record)}>
            <EyeOutlined />
          </a>
          {edit && (
            <a onClick={() => onEditClick(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this team?"
            okText="Yes"
            cancelText="No"
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            open={deleteModalVisible && deletingRecord?.id === record?.id}
          >
            {deletee && (
              <a onClick={() => onDeleteClick(record)}>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setFilteredTeamData(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (full_name: string) => {
    const filterArray = [{ path: "employee.full_name", value: full_name }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setFilteredTeamData(response);
    }
  };

  return (
    <>
      {read ? (
        <CustomTable
          dataSource={filteredTeamData}
          columns={columns}
          loading={loading}
          rowKey="id"
          onChange={handleTableChange}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      <EditTeamModal
        selectedTeamId={selectedTeamId}
        fetchData={fetchData}
        visibility={editModalVisible}
        onCancel={handleEditModalCancel}
      />

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <TeamsFilterModal
          getFilterDataHandler={(full_name) => getFilterDataHandler(full_name)}
        />
      </Modal>
      {showAlert && (
        <CustomAlert
          showAlert={showAlert}
          type={alertType}
          description={alertDescription}
        />
      )}
      <Modal
        open={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={null}
        width={700}
        title={<span className="global-modal-title">Team Details</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Row gutter={10} className="team-row-1">
            <Col span={12}>
              <b> Team Name </b>
              <br />

              {viewingRecord?.name}
            </Col>
            <Col span={12}>
              <b> Project Lead </b>
              <br />
              <div className="team-view-modal-avatar">
                <div className="advance-request-circle-avatarUrl-cell">
                  <img
                    src={
                      viewingRecord?.employee?.avatar_url
                        ? viewingRecord?.employee?.avatar_url
                        : d_dp
                    }
                    alt={d_dp}
                    className="advance-request-circle-avatarUrl-cell-img"
                  />
                </div>
                {viewingRecord?.employee?.full_name}
              </div>
            </Col>
            {viewingRecord?.employee?.job_title && (
              <Col span={12}>
                <b> Job Title </b>
                <br />

                {viewingRecord?.employee?.job_title?.name}
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default TeamsList;
