import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches emergency contact information from the Supabase database based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, branchId, and searchQuery.
 * @returns {Promise<any>} A promise that resolves to the fetched emergency contact information.
 */
export const fetchEmergencyContactApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_emergency_contact_information")
      .select(
        `id, email,address, work_phone_number,phone_number,relation_with_employee,relative_name,employee(full_name)`
      )
      .eq("organization_id", data?.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("employee.full_name", `%${data.searchQuery}%`);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes an emergency contact from the Supabase database based on the provided data.
 * @param {any} data - The data object containing the ID of the emergency contact to delete.
 * @returns {Promise<any>} A promise that resolves to the result of the deletion operation.
 */
export const deleteEmergencyContactApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_emergency_contact_information")
      .delete()
      .eq("id", data.id);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches an emergency contact by ID from the Supabase database.
 * @param {number | string} id - The ID of the emergency contact to fetch.
 * @returns {Promise<any>} A promise that resolves to the fetched emergency contact data.
 */
export const fetchEmergencyContactByIdApi = async (
  id: number | string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_emergency_contact_information")
      .select("* ,employee(full_name)")
      .eq("id", id)
      .maybeSingle();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Updates an emergency contact record in the database by its ID.
 * @param {any} data - An object containing the updated data and the ID of the emergency contact.
 * @returns {Promise<any>} A promise that resolves to the updated record in the database.
 */
export const updateEmergencyContactByIdApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_emergency_contact_information")
      .update(data.updatedData)
      .eq("id", data.id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts emergency contact data into the "EmergencyContact" table in the Supabase database.
 * @param {any} data - The data to be inserted into the table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertEmergencyContactdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_emergency_contact_information")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches all employees without emergency contact information based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, and branchId.
 * @returns {Promise<any>} A promise that resolves to an array of employees without emergency contact information.
 */

export const fetchAllEmployeeWithOutEmergencyContactApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    if (res.data) {
      const EmergencyContactres = await supabase_client_for_Org_schema(
        data.organizationId
      )
        .from("employee_emergency_contact_information")
        .select("employee_id");
      const employeeIdsWithSalary = new Set(
        EmergencyContactres?.data?.map((item: any) => item.employee_id)
      );
      const employeeswithoutsalary = res.data
        ?.filter((employee: any) => !employeeIdsWithSalary.has(employee.id))
        .map((employee: any) => ({
          value: employee.id,
          label: employee.full_name,
        }));
      return employeeswithoutsalary;
    }
    return [];
  } catch (error) {
    return catch_return;
  }
};
