import React, { useContext, useEffect, useState } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  DatePicker,
  Button,
  Table,
  Space,
  Popconfirm,
  Select,
  AutoComplete,
  Empty,
} from "antd";
import moment from "moment";
import CustomAlert from "../../../common/Alert";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getExistingEmployeeBankRecords,
  handleDeleteEmployeeBankRecords,
  setEmployeeBankDetailsApi,
} from "./services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { banks } from "../../../common/Constants";
import { getCountries } from "vk-country-state-city";
import { isValidIBAN } from "ibantools";
import { validateTextOnly } from "../../../common/validation";
import { fetchEmployeecompanyandbranchbyemployeeidApi } from "../../../../services/LoginApi";
import { TriangleLoader } from "../../../common/ReactLoader";
import { FormInstance } from "antd/lib";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface props {
  employeeId: any;
}

const UserBankInfoUpdate: React.FC<props> = ({ employeeId }) => {
  const [form] = Form.useForm();
  const [editingForm] = Form.useForm();

  const [tableData, setTableData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setbuttonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [refCounter, setRefCounter] = useState<number>(0);
  const organization_id =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Bank Details"
  );
  const write = access?.writeAccess;
  const read = access?.readAccess;

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [countries, setCountries] = useState<any>(null);
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );

  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
  }, []);

  const fetchEmployeecomapny = async (
    employee_id: string,
    organization_id: string
  ) => {
    const res = await fetchEmployeecompanyandbranchbyemployeeidApi(
      employee_id,
      organization_id
    );
    if (res.data) {
      return [res.data.company_id, res.data.branch_id];
    }
    return ["", ""];
  };

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const EditableRow: React.FC = ({ ...props }) => {
    return (
      <Form form={editingForm} component={false}>
        <EditableContext.Provider value={editingForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell: React.FC<any> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const form = useContext(EditableContext)!;
    const inputNode =
      dataIndex === "dateEffective" ? (
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      ) : (
        <Input size="large" />
      );

    useEffect(() => {
      if (editingKey === record?.id) {
        form.setFieldsValue({
          ...record,
          dateEffective: record.dateEffective
            ? moment(record.dateEffective)
            : null,
        });
      }
    }, [editingKey, record, form]);

    return (
      <td {...restProps}>
        {editable && editingKey === record?.id ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
              ...(dataIndex === "branchName" || dataIndex === "accountTitle"
                ? [{ validator: validateTextOnly }]
                : []),
              ...(dataIndex === "iban"
                ? [
                    {
                      validator: (_: any, value: any) =>
                        value && isValidIBAN(value)
                          ? Promise.resolve()
                          : Promise.reject(new Error("Invalid IBAN number")),
                    },
                  ]
                : []),
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleSave = async () => {
    try {
      const row = await editingForm.validateFields();
      const newData = [...tableData];
      const index = newData.findIndex((item) => editingKey === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setTableData(newData);
        setEditingKey(null);
        handleRecordInsertion(newData);
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey(null);
      }
    } catch (errInfo) {}
  };

  const handleRecordInsertion = async (data: any) => {
    setbuttonLoading(true);
    if (data.length === 0) {
      return;
    }
    const [comId, branId] = await fetchEmployeecomapny(
      employeeId,
      organization_id
    );
    const body = {
      bank_information: data,
      organization_id: organization_id,
      company_id: comId,
      branch_id: branId,
      employee_id: employeeId,
    };
    const response = await setEmployeeBankDetailsApi(body, organization_id);
    if (
      response.status === 204 ||
      response.status === 201 ||
      response.status === 200
    ) {
      setshowAlert(true);
      setbuttonLoading(false);
      setAlertType("success");
    } else {
      setshowAlert(true);
      setbuttonLoading(false);
      setAlertType("error");
    }

    setRefCounter(refCounter + 1);
    editingForm.resetFields();
  };

  const handleAddingDataToTable = () => {
    setAlertDescription("");
    const body = {
      bankName: form.getFieldValue("bankName"),
      branchName: form.getFieldValue("branchName"),
      branchCode: form.getFieldValue("branchCode"),
      accountTitle: form.getFieldValue("accountTitle"),
      accountNumber: form.getFieldValue("accountNumber"),
      dateEffective: moment(form.getFieldValue("dateEffective")),
      country: form.getFieldValue("country"),
      id: Date.now(),
      iban: form.getFieldValue("iban"),
    };

    const accountNumberFromBody = form.getFieldValue("accountNumber");

    const existingTableRecord = tableData.find((record: any) => {
      return record.accountNumber === accountNumberFromBody;
    });

    if (existingTableRecord) {
      setshowAlert(true);
      setbuttonLoading(false);
      setAlertDescription("Account Number Already Exists");
      setAlertType("error");
    } else {
      setTableData([...tableData, body]);
      form.resetFields();
    }
  };

  const getExistingRecords = async () => {
    setLoading(true);
    const response = await getExistingEmployeeBankRecords(
      employeeId,
      organization_id
    );

    if (response.data) {
      const tableData = response.data.flatMap((item: any) => {
        if (Array.isArray(item.bank_information)) {
          return item.bank_information;
        } else if (
          item.bank_information &&
          typeof item.bank_information === "object"
        ) {
          return [item.bank_information];
        }
        return [];
      });

      setTableData(tableData);
    }
    setLoading(false);
  };

  useEffect(() => {
    getExistingRecords();
  }, [refCounter]);

  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      width: "20%",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      editable: true,
    },
    {
      title: "Branch Code",
      dataIndex: "branchCode",
      key: "branchCode",
      editable: true,
    },
    {
      title: "Account Title",
      dataIndex: "accountTitle",
      key: "accountTitle",
      editable: true,
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      editable: true,
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      key: "iban",
      editable: true,
    },
    {
      title: "Effective Up to",
      dataIndex: "dateEffective",
      key: "dateEffective",
      render: (text: any, record: any) => (
        <span>
          {moment(record.dateEffective).format("YYYY-MM-DD").toString()}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => {
        const editable = editingKey === record.id;
        return editable ? (
          <Space size={"middle"}>
            <SaveOutlined onClick={handleSave} style={{ color: "green" }} />
            <CloseOutlined onClick={cancel} style={{ color: "red" }} />
          </Space>
        ) : (
          <Space size={"middle"}>
            <EditOutlined onClick={() => edit(record)} />
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this record?"
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editingKey === record.id,
      }),
    };
  });

  const handleDelete = async (record: any) => {
    setshowAlert(false);
    const updatedList = tableData.filter(
      (item: any) => item.accountNumber !== record.accountNumber
    );
    setTableData(updatedList);

    const deletedRecord = await handleDeleteEmployeeBankRecords(
      updatedList,
      employeeId,
      organization_id
    );

    if (deletedRecord.status == 204) {
      setshowAlert(true);
      setAlertType("success");
    }
    setRefCounter(refCounter + 1);
  };

  const edit = (record: any) => {
    setEditingKey(record.id);
    editingForm.setFieldsValue({
      ...record,
      dateEffective: record.dateEffective ? moment(record.dateEffective) : null,
    });
  };

  const cancel = () => {
    setEditingKey(null);
    editingForm.resetFields();
  };

  return (
    <div>
      {read ? (
        <>
          <div className="bank-form-container">
            <Form layout="vertical" autoComplete="off" form={form}>
              <Row gutter={[16, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Country"
                    name={"country"}
                    rules={[{ required: true, message: "Select a country" }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Select Country"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {countries?.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Bank Name"
                    name="bankName"
                    rules={[
                      { required: true, message: "Please Enter Bank Name" },
                    ]}
                  >
                    <AutoComplete
                      options={options}
                      onSearch={handleSearch}
                      placeholder="Select bank..."
                      allowClear
                      filterOption={(inputValue, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Branch Name"
                    name="branchName"
                    rules={[
                      {
                        validator: validateTextOnly,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Branch Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Branch Code"
                    name="branchCode"
                    rules={[
                      { required: true, message: "Please Enter Branch code" },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Branch Code" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Account Title"
                    name="accountTitle"
                    rules={[
                      { required: true, message: "Please Enter Account Title" },
                      {
                        validator: validateTextOnly,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Account Title" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Account Number"
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Account Number",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Account Number" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="IBAN"
                    name="iban"
                    rules={[
                      { required: true, message: "Please Enter IBAN" },
                      {
                        validator: (_, value) =>
                          value && isValidIBAN(value)
                            ? Promise.resolve()
                            : Promise.reject(new Error("Invalid IBAN number")),
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter IBAN" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item label="Effective up to" name="dateEffective">
                    <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="add-record-to-table">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleAddingDataToTable}
              >
                Add Record to Table
              </Button>
            </Row>
            <Row className="table-row">
              <Col span={24}>
                <Table
                  components={{
                    body: {
                      row: EditableRow,
                      cell: EditableCell,
                    },
                  }}
                  dataSource={tableData}
                  pagination={false}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                />
              </Col>
              <Col span={24} className="bank-details-save-btn">
                {write && (
                  <Button
                    className="global-btn-width"
                    type="primary"
                    onClick={() => handleRecordInsertion(tableData)}
                    loading={buttonLoading}
                    disabled={editingKey ? true : false}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>

            {showAlert && (
              <CustomAlert
                type={alertType}
                showAlert={showAlert}
                description={alertDescription}
              />
            )}
          </div>
          {loading && <TriangleLoader />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};

export default UserBankInfoUpdate;
