import React, { useEffect, useState } from "react";
import { Card, Row, Col, DatePicker, Avatar, Tag } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import moment from "moment";
import d_dp from "../../../../assets/images/blankProfile.png";
import { getAllAttendanceData } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { ProgressLoader } from "../../../../common/ReactLoader";
import dayjs from "dayjs";
import { BsClockHistory } from "react-icons/bs";
import { generateFormatedId } from "../../../../common/validation";

const LateComers = () => {
  const [lateComers, setLateComers] = useState<any[]>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  useEffect(() => {
    getAttendanceData();
  }, [selectedDate, loggedInEmployee?.employee?.organizationId]);

  const getAttendanceData = async () => {
    setLoadingState(true);
    setLateComers([]);
    const Ids = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      status: "late",
    };
    const response = await getAllAttendanceData(Ids);

    if (response.data) {
      setLateComers(response.data);
      setLoadingState(false);
    }
  };

  const parseShortHours = (shortHours: any) => {
    if (typeof shortHours !== "string" || !shortHours.includes(":")) {
      return 0;
    }
    const [hours, minutes] = shortHours.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const formatMinutesToHours = (minutes: any) => {
    if (isNaN(minutes)) {
      return "0h 0m";
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${remainingMinutes}m`;
  };

  // eslint-disable-next-line no-lone-blocks
  {
    loadingState && <ProgressLoader />;
  }

  return (
    <Card
      className="main-late-comer-container dasboard-cards"
      bordered={true}
      title={
        <div className="late-comers-title-div">
          <span className="late-comers-title">Late Comers</span>
          <DatePicker
            defaultValue={dayjs(selectedDate)}
            onChange={(date, dateString) =>
              setSelectedDate(date ? dateString : moment().format("YYYY-MM-DD"))
            }
            allowClear
          />
        </div>
      }
    >
      <div className="late-comers-scrollable-content">
        {loadingState ? (
          <ProgressLoader />
        ) : lateComers.length === 0 ? (
          <div className="no-data-icon-div">
            <p className="no-data-text no-data-container">
              No late arrivals today 🕒
            </p>{" "}
          </div>
        ) : (
          <div key={lateComers.length}>
            {lateComers &&
              lateComers?.map((item: any, index: number) => (
                <div key={item.id}>
                  <div className="late-comers-custom-new-container">
                    <div className="late-comers-custom-new-avatarCol">
                      <Avatar
                        shape="circle"
                        size={{
                          xxl: 60,
                          xl: 60,
                          lg: 60,
                          md: 60,
                          sm: 60,
                          xs: 60,
                        }}
                        src={item?.employee.avatar_url || d_dp}
                      />
                    </div>
                    <Row gutter={12} className="admin-dashborad-late-comers">
                      <Col
                        xxl={19}
                        xl={19}
                        lg={19}
                        md={19}
                        sm={18}
                        xs={18}
                        className="late-comers-title-col"
                      >
                        <Row>
                          <p className="late-comers-title">
                            {item?.employee.full_name}
                          </p>
                        </Row>

                        <Row>
                          <p className="late-comers-designation">
                            {" "}
                            {item?.company?.name
                              ? generateFormatedId(
                                  item?.company?.name,
                                  item?.employee?.cnic
                                )
                              : "No Data"}{" "}
                          </p>
                        </Row>
                      </Col>
                      <Col
                        xxl={5}
                        xl={5}
                        lg={5}
                        md={5}
                        sm={6}
                        xs={6}
                        className="late-comers-data-col"
                      >
                        <Tag className="late-comers-join-date">
                          <BsClockHistory />
                          {"  "}
                          {formatMinutesToHours(
                            parseShortHours(item?.late_checkin_duration)
                          )}
                        </Tag>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default LateComers;
