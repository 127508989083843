import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Steps, Row, Col, Select } from "antd";
import { useSelector } from "react-redux";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { getCountries, getStates, getCities } from "vk-country-state-city";
import "./index.css";
import { domaincheck } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";
import {
  getBranchesBasedOnCompanies,
  getCompany,
  getManagerNames,
  getOrganizationCompanies,
  handleSaveRecords,
} from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Step } = Steps;

interface EditBranchDetailsProps {
  branchId: number | null;
  onCancel: () => void;
  visible: boolean;
  fetchData: () => void;
}

const EditBranchDetails: React.FC<EditBranchDetailsProps> = ({
  branchId,
  onCancel,
  visible,
  fetchData,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [form] = Form.useForm();
  const [branchManager, setBranchManger] = useState<any[] | null>([]);
  const [branchCompany, setBranchCompany] = useState<any>(null);
  const [companyNames, setCompanyNames] = useState<any[] | null>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const { Option } = Select;

  const branchParentOrg: any =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_name;

  useEffect(() => {
    fetchBranchManagerNames();
    fetchCompanyNames();
    fetchBranchDetails(branchId);
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }

    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      const states = await getStates(selectedCountry);
      setStates(states);
    }

    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    getCompanyOfBranch();
  }, [form.getFieldValue("companyname")]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedCountry && selectedState) {
        const cities = await getCities(selectedCountry, selectedState);
        setCities(cities);
      }
    }
    fetchCities();
  }, [selectedState, selectedCountry]);

  const getCompanyOfBranch = async (value?: string) => {
    const data = form.getFieldValue("companyname");
    const res = await getCompany(data, organizationId);
    if (res.data) {
      setBranchCompany(res.data.email);
    }
  };

  const fetchBranchManagerNames = async () => {
    const res = await getManagerNames(organizationId);

    if (res.data) {
      setBranchManger(res.data);
    }
  };

  const fetchCompanyNames = async () => {
    const response = await getOrganizationCompanies(organizationId);
    if (response.data) {
      setCompanyNames(response.data);
    }
  };

  const fetchBranchDetails = async (id: number | null | string) => {
    try {
      setLoading(true);
      const payload = id;
      const response = await getBranchesBasedOnCompanies(
        payload,
        organizationId
      );

      if (response.data) {
        setSelectedCountry(response.data.address.country);
        setSelectedState(response.data.address.state);

        form.setFieldsValue(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const handleSave = async (values: any) => {
    try {
      const formValidaiton = await form.validateFields();
      if (
        !formValidaiton.errorFields ||
        formValidaiton.errorFields.length === 0
      ) {
        const values = form.getFieldsValue([
          "company_id",
          "address",
          "unique_identifier",
          "head_manager_id",
          "email",
          "phone_number",
          "name",
        ]);

        setButtonLoading(true);

        const payload = {
          id: branchId,
          body: values,
        };
        const response: any = await handleSaveRecords(payload, organizationId);

        if (response.status == 204 || response.status == 201) {
          fetchData();
          form.resetFields();
          setButtonLoading(false);
          setshowAlert(true);
          setAlertType("success");
        } else if (response.error) {
          setshowAlert(true);
          setButtonLoading(false);
          setAlertType("error");
        }
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const handleNext = async () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const stepsContent = [
    {
      title: "Branch Information",
      content: (
        <div>
          <Row className="">
            <Col className="modal-content">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Parent Organization"
                    name="organizationName"
                  >
                    <Input
                      size="large"
                      disabled
                      defaultValue={branchParentOrg}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  {" "}
                  <Form.Item label="Company" name="company_id">
                    <Select
                      placeholder="Select company..."
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      disabled
                      onChange={(value) => getCompanyOfBranch(value)}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companyNames?.map((company) => (
                        <Option key={company.id} value={company.id}>
                          {company.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="name"
                    label="Branch Name"
                    rules={[
                      { required: true, message: "Please Enter Branch Name" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Branch Manager"
                    name="head_manager_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the branch manager's name",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a branch manager..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branchManager?.map((manager) => (
                        <Option key={manager.id} value={manager.id}>
                          {manager.full_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    name="unique_identifier"
                    label="Branch Code"
                    rules={[
                      { required: true, message: "Please enter branch name" },
                    ]}
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      {
                        validator: (_, value) => {
                          if (value && !domaincheck(branchCompany, value)) {
                            return Promise.reject(
                              "Email domain not Match with comapany Email"
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Email" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Contact Number"
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter Phone Number"
                      inputProps={{
                        name: "Phone Number",
                        required: true,
                      }}
                      defaultCountry={"pk"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: "Address Details",
      content: (
        <div>
          <Row>
            <Col className="modal-content">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Country"
                    name={["address", "country"]}
                    rules={[
                      {
                        required: true,
                        message: "Enter your country",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Country"
                      showSearch
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={(value: any) => setSelectedCountry(value)}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {countries.map((item: any) => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="State/Province"
                    name={["address", "province"]}
                    rules={[
                      {
                        message: "Please enter the province",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a state/province"
                      showSearch
                      onChange={(value: any) => setSelectedState(value)}
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {states.map((state: any) => (
                        <Option key={state.code} value={state.name}>
                          {state.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="City"
                    name={["address", "city"]}
                    rules={[
                      {
                        message: "Enter your city",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select City"
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {cities.map((cityName: any) => (
                        <Option key={cityName} value={cityName}>
                          {cityName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Street Address"
                    name={["address", "street"]}
                    rules={[
                      {
                        required: true,
                        message: "Enter your street address",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Street Address" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        open={visible}
        onCancel={onCancel}
        width={1000}
        footer={false}
        title={<span className="global-modal-title">Edit Branch</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Form form={form} layout="vertical">
            <Steps current={currentStep} style={{ marginBottom: 16 }}>
              {stepsContent.map((step, index) => (
                <Step
                  key={index}
                  title={step.title}
                  description={`Step ${index + 1}`}
                />
              ))}
            </Steps>
            <div className="global-steps-container-margin">
              {stepsContent[currentStep].content}
            </div>
          </Form>
        </div>

        <Row
          gutter={16}
          className="set-Button-position-to-right global-modal-bottom-row edit-branch-button-width"
        >
          {currentStep > 0 && (
            <Button
              key="prev"
              onClick={handlePrev}
              style={{ marginRight: 8 }}
              className="global-btn-width"
              type="default"
              size="large"
            >
              Previous
            </Button>
          )}
          {currentStep < stepsContent.length - 1 && (
            <Button
              className="global-btn-width"
              type="primary"
              key="next"
              onClick={handleNext}
              style={{ marginRight: 8 }}
              size="large"
            >
              Next
            </Button>
          )}
          {currentStep === stepsContent.length - 1 && (
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                key="save"
                htmlType="submit"
                loading={buttonLoading}
                onClick={handleSave}
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          )}
        </Row>
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
        {loading && <TriangleLoader />}
      </Modal>
    </>
  );
};

export default EditBranchDetails;
