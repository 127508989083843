import React, { useEffect, useState } from "react";
import { Collapse, Table, Button, Modal, Empty } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { FaAmericanSignLanguageInterpreting } from "react-icons/fa";
import { FaBusinessTime, FaGraduationCap } from "react-icons/fa6";
import { GiSkills } from "react-icons/gi";
import {
  fetchEmployeeEducationData,
  fetchEmployeeLanguageData,
  fetchEmployeeSkillsData,
  fetchEmployeeWorkExperienceData,
} from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import CreateSkillsForm from "./QualificationModals/SkillsModal";
import QualificationForm from "./QualificationModals/EducationModal";
import LanguageForm from "./QualificationModals/LanguageModal";
import ExperienceForm from "./QualificationModals/WorkExperienceModal";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { FiPlus } from "react-icons/fi";
import CustomTable from "../../../common/CustomTable";

interface Props {
  employeeId: any;
}

const UserQualificationAndOtherInfoUpdate: React.FC<Props> = ({
  employeeId,
}) => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;

  const [Skillsres, setSkillsRes] = useState<any[]>([]);
  const [Educationres, setEducationRes] = useState<any[]>([]);
  const [WorkExperienceres, setWorkExperienceRes] = useState<any[]>([]);
  const [Languageres, setLanguageRes] = useState<any[]>([]);
  const [employescounter, setEmployesCounter] = useState<number>(0);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string>("");
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Qualification"
  );

  const read = access?.readAccess;

  useEffect(() => {
    if (employeeId) {
      fetchSkillsData();
      fetchEducationData();
      fetchWorkExperienceData();
      fetchLanguageData();
    }
  }, [employeeId]);

  const fetchSkillsData = async () => {
    try {
      const Skills_res = await fetchEmployeeSkillsData(
        organizationId,
        employeeId
      );
      if (Skills_res.data) {
        const SkillsResponse = Skills_res.data.flatMap((item: any) =>
          Array.isArray(item?.skill_details)
            ? item?.skill_details
            : [item?.skill_details]
        );
        setSkillsRes(SkillsResponse);
        setEmployesCounter((prevCount) => prevCount + 1);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchEducationData = async () => {
    try {
      const Education_res = await fetchEmployeeEducationData(
        organizationId,
        employeeId
      );
      if (Education_res.data) {
        const EducationResponse = Education_res.data.flatMap((item: any) =>
          Array.isArray(item?.qualification_details)
            ? item?.qualification_details
            : [item?.qualification_details]
        );
        setEducationRes(EducationResponse);
        setEmployesCounter((prevCount) => prevCount + 1);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchWorkExperienceData = async () => {
    try {
      const WorkExperience_res = await fetchEmployeeWorkExperienceData(
        organizationId,
        employeeId
      );
      if (WorkExperience_res.data) {
        const WorkExperienceResponse = WorkExperience_res.data.flatMap(
          (item: any) =>
            Array.isArray(item.details) ? item.details : [item.details]
        );
        setWorkExperienceRes(WorkExperienceResponse);
        setEmployesCounter((prevCount) => prevCount + 1);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchLanguageData = async () => {
    try {
      const Language_res = await fetchEmployeeLanguageData(
        organizationId,
        employeeId
      );
      if (Language_res.data) {
        const LanguageResponse = Language_res.data.flatMap((item: any) =>
          Array.isArray(item.language_details)
            ? item.language_details
            : [item.language_details]
        );
        setLanguageRes(LanguageResponse);
        setEmployesCounter((prevCount) => prevCount + 1);
      }
    } catch (error) {
      return error;
    }
  };

  const handleCreateClick = (content: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setModalContent(content);
    setIsModalVisible(true);
  };

  const handleModalClose = async () => {
    setIsModalVisible(false);
    if (modalContent === "Work Experience") {
      fetchWorkExperienceData();
    } else if (modalContent === "Education") {
      await fetchEducationData();
    } else if (modalContent === "Skills") {
      fetchSkillsData();
    } else if (modalContent === "Language") {
      await fetchLanguageData();
    }
  };

  return (
    <div
      className="employee-details-qualification-tables"
      key={employescounter}
    >
      {read ? (
        <>
          <Collapse className="employee-qualification-bg-color">
            <Collapse.Panel
              key="1"
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>
                    <FaBusinessTime /> Work Experience
                  </h5>
                  <Button
                    type="primary"
                    onClick={(e) => handleCreateClick("Work Experience", e)}
                    className="tab-header-create-btn"
                    icon={<FiPlus className="tab-header-icon" />}
                  >
                    Create
                  </Button>
                </div>
              }
            >
              {WorkExperienceres ? (
                <CustomTable dataSource={WorkExperienceres}>
                  <Table.Column
                    title="Company"
                    dataIndex="company"
                    key="company"
                  />
                  <Table.Column
                    title="Job Title"
                    dataIndex="jobTitle"
                    key="jobTitle"
                  />
                  <Table.Column title="From" dataIndex="from" key="from" />
                  <Table.Column title="To" dataIndex="to" key="to" />
                </CustomTable>
              ) : (
                <p>No work experience data available.</p>
              )}
            </Collapse.Panel>
          </Collapse>
          <Collapse className="employee-qualification-bg-color">
            <Collapse.Panel
              key="2"
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>
                    <FaGraduationCap /> Education
                  </h5>
                  <Button
                    type="primary"
                    onClick={(e) => handleCreateClick("Education", e)}
                    className="tab-header-create-btn"
                    icon={<FiPlus className="tab-header-icon" />}
                  >
                    Create
                  </Button>
                </div>
              }
            >
              {Educationres ? (
                <CustomTable dataSource={Educationres}>
                  <Table.Column title="Level" dataIndex="level" key="level" />
                  <Table.Column
                    title="Institute"
                    dataIndex="institute"
                    key="institute"
                  />
                  <Table.Column
                    title="Specialization"
                    dataIndex="specialization"
                    key="specialization"
                  />
                  <Table.Column
                    title="Starting Year"
                    dataIndex="from"
                    key="from"
                  />
                  <Table.Column title="Pass Year" dataIndex="to" key="to" />
                </CustomTable>
              ) : (
                <p>No education data available.</p>
              )}
            </Collapse.Panel>
          </Collapse>
          <Collapse className="employee-qualification-bg-color">
            <Collapse.Panel
              key="3"
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>
                    <GiSkills /> Skills
                  </h5>
                  <Button
                    type="primary"
                    onClick={(e) => handleCreateClick("Skills", e)}
                    className="tab-header-create-btn"
                    icon={<FiPlus className="tab-header-icon" />}
                  >
                    Create
                  </Button>
                </div>
              }
            >
              {Skillsres ? (
                <CustomTable dataSource={Skillsres}>
                  <Table.Column title="Skill" dataIndex="skill" key="skill" />
                  <Table.Column
                    title="Years of Experience"
                    dataIndex="experience"
                    key="experience"
                  />
                </CustomTable>
              ) : (
                <p>No skills data available.</p>
              )}
            </Collapse.Panel>
          </Collapse>
          <Collapse className="employee-qualification-bg-color">
            <Collapse.Panel
              key="4"
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h5>
                    <FaAmericanSignLanguageInterpreting /> Language
                  </h5>
                  <Button
                    type="primary"
                    onClick={(e) => handleCreateClick("Language", e)}
                    className="tab-header-create-btn"
                    icon={<FiPlus className="tab-header-icon" />}
                  >
                    Create
                  </Button>
                </div>
              }
            >
              {Languageres ? (
                <CustomTable dataSource={Languageres}>
                  <Table.Column
                    title="Language"
                    dataIndex="language"
                    key="language"
                  />
                  <Table.Column
                    title="Fluency"
                    dataIndex="fluency"
                    key="fluency"
                  />
                </CustomTable>
              ) : (
                <p>No language data available.</p>
              )}
            </Collapse.Panel>
          </Collapse>

          <Modal
            title={`Create ${modalContent}`}
            visible={isModalVisible}
            onCancel={handleModalClose}
            footer={null}
            width={1000}
          >
            {modalContent === "Work Experience" && (
              <ExperienceForm onClose={handleModalClose} />
            )}
            {modalContent === "Education" && (
              <QualificationForm onClose={handleModalClose} />
            )}
            {modalContent === "Skills" && (
              <CreateSkillsForm onClose={handleModalClose} />
            )}
            {modalContent === "Language" && (
              <LanguageForm onClose={handleModalClose} />
            )}
          </Modal>
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};

export default UserQualificationAndOtherInfoUpdate;
