import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Empty, Table } from "antd";
import moment from "moment";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { getEmployeeKPIHistory } from "./services";
import "./index.css";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import CustomTable from "../../../common/CustomTable";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const MyPerformance = () => {
  const [employeeKPIData, setEmployeeKPIData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "My Performance");

  const read = access?.readAccess;

  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employeeId;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const getEmployeeKPIDetails = async () => {
    setLoading(true);
    const response = await getEmployeeKPIHistory(employeeId, organizationId);
    setLoading(false);
    if (response.data) {
      setEmployeeKPIData(response.data);
    }
  };

  useEffect(() => {
    getEmployeeKPIDetails();
  }, [employeeId]);

  const formatKPIData = () => {
    return employeeKPIData.map((kpi) => ({
      key: kpi.id,
      month: moment(kpi.created_at).format("MMMM, YYYY"),
      details: kpi.data,
      aggregate: kpi.aggregate,
    }));
  };

  const columns = [
    { title: "Month", dataIndex: "month", key: "month" },
    { title: "Obtained Aggregate", dataIndex: "aggregate", key: "aggregate" },
  ];

  const renderKPIDetails = (record: any) => {
    const detailColumns = [
      {
        title: "Attribute Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Points",
        dataIndex: "percentage",
        key: "percentage",
        render: (text: any) => `${text}`,
      },
      {
        title: "Obtained Percentage",
        dataIndex: "selectedMetric",
        key: "obtainedMarks",
        render: (_: any, detail: any) => (
          <span>{detail?.selectedMetric?.obtainedMarks}</span>
        ),
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
      },
    ];

    return (
      <CustomTable
        columns={detailColumns}
        dataSource={record.details}
        pagination={false}
        bordered
        rowKey="id"
      />
    );
  };

  return (
    <>
      <div className="main-container dasboard-cards">
        {read ? (
          <CustomTable
            scroll={{ x: true }}
            dataSource={formatKPIData()}
            loading={loading}
            pagination={false}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => renderKPIDetails(record),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>
    </>
  );
};

export default MyPerformance;
