import React, { useEffect, useState } from "react";
import {
  Space,
  Modal,
  Row,
  Col,
  Popconfirm,
  TablePaginationConfig,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./index.css";
import CustomAlert from "../../../../common/Alert/index";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { SortOrder } from "antd/lib/table/interface";
import { deleteCompany, fetchCompaniesApi } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import CompanyEditModel from "../Edit";
import CompanyFilterModal from "../Filter";
import { ClassNames } from "@emotion/react";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface CompanyListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const CompanyList: React.FC<CompanyListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [editedCompany, setEditedCompany] = useState<any>(null);
  const [viewedCompany, setViewedCompany] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [filteredCompanys, setFilteredCompanys] = useState<any[]>([]);
  const [filteredCompany, setFilteredCompany] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Organization",
    "Companies"
  );
  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;

  useEffect(() => {
      if (organizationId) {
        fetchCompanies();
      }
  }, [organizationId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setFilteredCompanys(originalData.apiData);
    }
  }, [searchValue]);

  const fetchCompanies = async () => {
    setLoading(true);
    setEditModalVisible(false);
    setFilteredCompany(null);
    const payload = {
      organizationId: organizationId,
    
  
    };
    const response = await fetchCompaniesApi(payload, organizationId);
    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: filteredCompanys.length,
        },
      });
      setLoading(false);
      setFilteredCompanys(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
    } else {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Area of focus",
      dataIndex: "area_of_focus",
      key: "area_of_focus",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: any) =>
        record.email && <span className="email-column">{record.email}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <span className="link" onClick={() => handleViewClick(record)}>
            <EyeOutlined />
          </span>
          {edit && (
            <span className="link" onClick={() => handleEditClick(record)}>
              <EditOutlined />
            </span>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this Company?"
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            open={
              deleteConfirmVisible &&
              editedCompany?.unique_identifier === record.unique_identifier
            }
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link" onClick={() => handleDeleteClick(record)}>
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleEditClick = (record: any) => {
    setEditedCompany(record);
    setEditModalVisible(true);
  };
  const handleDeleteClick = (record: any) => {
    setEditedCompany(record);
    setDeleteConfirmVisible(true);
  };
  const handleViewClick = (record: any) => {
    setViewedCompany(record);
    setViewModalVisible(true);
  };
  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setEditedCompany(null);
    fetchCompanies();
  };
  const handleViewModalCancel = () => {
    setViewModalVisible(false);
    fetchCompanies();
    setViewedCompany(null);
  };
  const handleDeleteConfirm = async () => {
    setshowAlert(false);
    try {
      const response: any = await deleteCompany(
        editedCompany.id,
        organizationId
      );
      if (editedCompany) {
        if (response.error) {
          setshowAlert(true);
          setAlertType("error");
        }
        if (response.status == 201 || response.status == 204) {
          setshowAlert(true);
          setAlertType("success");
          setDeleteConfirmVisible(false);
          setEditedCompany(null);
          fetchCompanies();
        }
      }
    } catch (error) {
      return error;
    }
  };
  const handleDeleteCancel = () => {
    setDeleteConfirmVisible(false);
    setEditedCompany(null);
  };
  const handleSearch = (value: string) => {
    setLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(value, originalData, "name");
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setFilteredCompanys(filterData);
    setLoading(false);
  };
  const getFilterDataHandler = (focusArea: string) => {
    const filterArray = [{ path: "area_of_focus", value: focusArea }];
    let response: any;
    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));
      setFilteredCompanys(response);
    }
  };
  return (
    <>
      <CustomTable
        columns={columns}
        dataSource={filteredCompanys}
        loading={loading}
        onChange={handleTableChange}
      />

      {editedCompany && (
        <Modal
          title={<span className="global-modal-title">Edit Company</span>}
          open={editModalVisible}
          onCancel={handleEditModalCancel}
          width={1000}
          footer={false}
          className="global-modal-main-container"
        >
          <CompanyEditModel
            data={editedCompany}
            fetchCompanys={fetchCompanies}
          />
        </Modal>
      )}
      {viewedCompany && (
        <Modal
          open={viewModalVisible}
          onCancel={handleViewModalCancel}
          footer={null}
          width={700}
          title={<span className="global-modal-title">Company Details</span>}
          className="global-modal-main-container"
        >
          <div className="global-card-inner-container">
            <Row gutter={10} className="company-row-1">
              <Col span={8}>
                <b>Name</b>
                <p>{viewedCompany?.name ? viewedCompany?.name : ""}</p>
              </Col>
              <Col span={8}>
                <b>Area of Focus</b>
                <p>
                  {" "}
                  {viewedCompany?.area_of_focus
                    ? viewedCompany?.area_of_focus
                    : ""}
                </p>
              </Col>
              <Col span={8}>
                <b>Head Quarter</b>
                <p> {viewedCompany?.location ? viewedCompany?.location : ""}</p>
              </Col>
            </Row>
            <Row gutter={10} className="company-row-2">
              <Col span={8}>
                <b> Phone Number</b>
                <p>
                  {viewedCompany?.phone_number
                    ? viewedCompany?.phone_number
                    : ""}
                </p>
              </Col>
              <Col span={12}>
                <b>Email</b>
                <p>{viewedCompany?.email ? viewedCompany?.email : ""}</p>
              </Col>
            </Row>
            {viewedCompany?.mission !== " " ? (
              <Row gutter={10} className="company-row-3">
                <Col span={24}>
                  <b> Mission/Vision </b>
                  <p>{viewedCompany?.mission ? viewedCompany?.mission : ""}</p>
                </Col>
              </Row>
            ) : null}
          </div>
        </Modal>
      )}
      <Modal
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
        className="global-modal-main-container"
      >
        <CompanyFilterModal
          getFilterDataHandler={(focusArea) => getFilterDataHandler(focusArea)}
        />
      </Modal>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default CompanyList;
