import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, DatePicker } from "antd";
import CustomAlert from "../../../../common/Alert";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  getTheCompanyAndBranchId,
  getQualifications,
  createOrUpdateQualifications,
} from "../qualificationServices";

interface Props {
  onClose: () => void;
}

const QualificationForm: React.FC<Props> = ({ onClose }) => {
  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    const fetchQualifications = async () => {
      try {
        const response = await getQualifications(organizationId, id);
        if (response.data) {
          setTableData(response.data[0]?.qualification_details || []);
        } else {
          setTableData([]);
        }
      } catch (error) {
        return error;
      }
    };

    fetchQualifications();
  }, [organizationId, id]);

  const handleSubmitData = async (values: any) => {
    try {
      setButtonLoading(true);
      const companyBranchResponse = await getTheCompanyAndBranchId(
        organizationId,
        id
      );
      const newRecord = {
        level: values.level,
        institute: values.institute,
        specialization: values.specialization,
        from: values.from?.format("YYYY-MM-DD"),
        to: values.to?.format("YYYY-MM-DD"),
        id: Date.now(),
      };

      const updatedTableData = [...tableData, newRecord];

      if (companyBranchResponse.data.company_id) {
        const data = {
          organization_id: organizationId,
          company_id: companyBranchResponse.data.company_id,
          branch_id: companyBranchResponse.data.branch_id,
          employee_id: id,
          qualification_details: updatedTableData,
        };

        const response = await createOrUpdateQualifications(
          organizationId,
          id,
          data
        );
        if (
          (response && response.status === 201) ||
          (response && response.status === 204)
        ) {
          setShowAlert(true);
          setAlertType("success");
          setTableData(updatedTableData);
          form.resetFields();
        } else {
          setShowAlert(true);
          setAlertType("error");
        }
      }
    } catch (error) {
      setShowAlert(true);
      setAlertType("error");
    } finally {
      setButtonLoading(false);
      setTimeout(() => {
        onClose();
      }, 1500);
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleSubmitData}
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Level"
              name="level"
              rules={[{ required: true, message: "Please enter the level!" }]}
            >
              <Input size="large" placeholder="Enter Level" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Institute"
              name="institute"
              rules={[
                { required: true, message: "Please enter the institute!" },
              ]}
            >
              <Input size="large" placeholder="Enter Institute" />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Specialization"
              name="specialization"
              rules={[
                { required: true, message: "Please enter the specialization!" },
              ]}
            >
              <Input size="large" placeholder="Enter Specialization" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Start Date"
              name="from"
              rules={[
                { required: true, message: "Please enter the start date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="End Date" name="to">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-primary-btn global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default QualificationForm;
