import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import searchIcon from "../../../../assets/images/search-icon.png";
import { fetchOverTimeClaimRequestByEmployeeId } from "../services";
import { SortOrder } from "antd/lib/table/interface";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import OverTimeApproveCreate from "../Create";
import { FiPlus } from "react-icons/fi";
import d_dp from "../../../../assets/images/blankProfile.png";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";

const OverTimeApproveHistoryView: React.FC = () => {
  const [tableloader, setTableLoader] = useState<boolean>(true);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;

  const organizationId = logedInemployee?.employee?.organization_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Overtime Request"
  );

  const create = access?.createAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    setTableLoader(true);
    setSearchQuery("");
    if (logedInemployee?.employeeId) {
      const res = await fetchOverTimeClaimRequestByEmployeeId(
        logedInemployee?.employeeId,
        organizationId
      );

      if (res.data) {
        setTableData(res.data);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: res.data,
        }));
      }
    }
    setTableLoader(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      render: (record: any) => (
        <div className="overtime-claim-request-emp-avatar-container">
          <div className="advance-request-circle-avatarUrl-cell">
            <img
              src={record?.avatar_url ? record?.avatar_url : d_dp}
              alt={d_dp}
              className="advance-request-circle-avatarUrl-cell-img"
            />
          </div>
          <div>
            <strong>{record?.full_name}</strong>
          </div>
        </div>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.employee?.full_name || "";
        const nameB = b?.employee?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Hours",
      dataIndex: "total_working_houre",
      key: "total_working_houre",
    },
    {
      title: "Next Requestee",
      dataIndex: "reviewer_comment",
      key: "reviewer_comment",
      render: (record: any) => (
        <div className="overtime-claim-request-emp-avatar-container">
          <div className="advance-request-circle-avatarUrl-cell">
            <img
              src={record?.avatar_url ? record?.avatar_url : d_dp}
              alt={d_dp}
              className="advance-request-circle-avatarUrl-cell-img"
            />
          </div>
          <div>
            <strong>{record?.full_name}</strong>
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      key: "actions",
      render: (text: any, record: any) => record.status,
    },
  ];

  const handleSearch = (value: any) => {
    setTableLoader(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setTableLoader(false);
  };

  const onCreateClick = () => {
    setCreateModalVisible(true);
  };
  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    fetchRequests();
  };

  const onSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  return (
    <div
      className={`${
        employeeRole === "employee"
          ? ""
          : "global-request-container-styling global-requests-container"
      }`}
    >
      {employeeRole !== "employee" && (
        <div className="overtime-request-header">
          <span className="overtime-request-heading">
            Overtime Claim Request
          </span>
          <div className="overtime-request-input-header">
            <Input
              className="global-input-number-width"
              size="large"
              placeholder="Search"
              prefix={<img src={searchIcon} alt="Search icon" />}
              onChange={(e: any) => {
                onSearchChange(e.target.value);
              }}
            />
            {create && (
              <Button
                size="large"
                type="primary"
                onClick={onCreateClick}
                icon={<FiPlus className="tab-header-icon" />}
              >
                Create
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="dashboard-cards-for-requests">
        {read && (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={columns}
            loading={tableloader}
          />
        )}
        {employeeRole === "employee" && (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={columns}
            loading={tableloader}
          />
        )}
      </div>
      <Modal
        title={
          <span className="global-modal-title">Create Over-Time Request</span>
        }
        open={createModalVisible}
        onCancel={handleCreateModalCancel}
        destroyOnClose
        width={1000}
        footer={false}
        className="global-modal-main-container"
      >
        <OverTimeApproveCreate />
      </Modal>
    </div>
  );
};

export default OverTimeApproveHistoryView;
