import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchEmployeeDesignationNamesApi = async (
  data: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("designation")
      .select(`id, name, company(name)`)
      .eq("organization_id", data.organizationId);
    if (data.selectedCompanyId) {
      query.eq("company_id", data.selectedCompanyId);
    }
    if (data.selectedBranchId) {
      query.eq("branch_id", data.selectedBranchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const featchEmployeeByDesignationIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select(`id, full_name`)
      .eq("organization_id", data.organizationId);

    if (data.selectedCompanyId) {
      query.eq("company_id", data.selectedCompanyId);
    }
    if (data.selectedBranchId) {
      query.eq("branch_id", data.selectedBranchId);
    }
    if (data.desId) {
      query = query.eq("designation_id", data.desId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
