import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Table,
} from "antd";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import "./index.css";
import { useSelector } from "react-redux";
import { getCountries } from "vk-country-state-city";
import CustomAlert from "../../../../../common/Alert";

import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { updateData } from "../services";
import { TriangleLoader } from "../../../../../common/ReactLoader";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface EditExperienceModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedRecordId: number | null;
  fetchData: () => void;
}

const EditExperienceModal: React.FC<EditExperienceModalProps> = ({
  visible,
  onCancel,
  selectedRecordId,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [countryList, setCountryList] = useState<string[]>([]);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const [countries, setCountries] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [countrySelectLoading, setCountrySelectLoading] =
    useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const { Option } = Select;

  useEffect(() => {
    if (visible && selectedRecordId !== null) {
      fetchExperienceDetails();
    }
  }, [visible, selectedRecordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    const countriesList = await getCountries();
    setCountryList(countriesList);
  };

  useEffect(() => {
    async function fetchCountries() {
      setCountrySelectLoading(true);
      const countries = await getCountries();
      setCountries(countries);
      setCountrySelectLoading(false);
    }
    fetchCountries();
  }, []);

  const fetchExperienceDetails = async () => {
    try {
      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_work_experience")
        .select(`*, employee(full_name)`)
        .eq("id", selectedRecordId);

      if (response.data && response.data.length > 0) {
        const experience = response.data[0];
        setTableData(experience.details);
        form.setFieldsValue({
          employeeId: experience.employee?.full_name,
        });
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Start Date",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "End Date",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => removeExperienceFromTable(record.key)}>
          Remove
        </Button>
      ),
    },
  ];

  const addExperienceToTable = async (values: any) => {
    const newData = {
      key: tableData.length + 1,
      employeeId: values.employeeId,
      country: values.country,
      company: values.company,
      jobTitle: values.jobTitle,
      from: values.from ? values.from.format("YYYY-MM-DD") : "",
      to: values.to ? values.to.format("YYYY-MM-DD") : "",
    };
    setTableData([...tableData, newData]);
    form.resetFields();
  };

  const removeExperienceFromTable = (key: any) => {
    const newData = tableData.filter((item: any) => item.key !== key);
    setTableData(newData);
  };

  const updatedData = async () => {
    try {
      setBtnLoading(true);
      const response = await updateData(
        tableData,
        organizationId,
        selectedRecordId
      );
      if (response.status === 204 || 201) {
        setshowAlert(true);
        setAlertType("success");
        onCancel();
        fetchData();
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1000}
      title={<span className="global-modal-title">Edit Experience</span>}
      footer={false}
      className="global-modal-main-container"
      destroyOnClose
    >
      <div className="global-card-inner-container">
        <Form layout="vertical" onFinish={addExperienceToTable} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="employeeId"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Employee Name!",
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please enter Country Name!",
                  },
                ]}
              >
                <Select
                  placeholder="Enter Country"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  loading={countrySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {countries.map((item: any) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Company"
                name="company"
                rules={[
                  { required: true, message: "Please Enter Experience!" },
                ]}
              >
                <Input size="large" placeholder="Enter Company" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Job Title" name="jobTitle">
                <Input size="large" placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="from"
                rules={[
                  { required: true, message: "Please Enter Starting Date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="End Date" name="to">
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="last-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Add Experience
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CustomTable columns={columns} dataSource={tableData} />
        <Row className="global-row-content-right">
          <Button
            className="global-btn-width"
            type="primary"
            disabled={!tableData.length}
            size="large"
            onClick={updatedData}
            loading={btnLoading}
          >
            Update
          </Button>
        </Row>
      </div>
      {loading && <TriangleLoader />}
      {showAlert && <CustomAlert message={alertMessage} type={alertType} />}
    </Modal>
  );
};

export default EditExperienceModal;
