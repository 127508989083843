import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  Collapse,
  Form,
  Table,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import {
  removeSkillDetail,
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { insertSkillsDataAPI } from "./services";

const Skill: React.FC = () => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const organizationDetails = useSelector(selectLogedEmployee);

  const employeeDetails = useSelector(selectEmployee);

  const organizationId =
    organizationDetails?.logedInemployee?.employee.organization_id;

  const company_id =
    employeeDetails.Employee.PersonalDetails?.selectedCompanyId;

  const branch_id = employeeDetails.Employee.PersonalDetails?.selectedBranchId;
  const employeeId = employeeDetails.Employee.EmployeeId;
  useEffect(() => {
    if (employeeDetails.Employee.skill) {
      setTableData(employeeDetails.Employee.skill);
    }
  }, [employeeDetails.Employee.skill]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const [form] = useForm();

  const handleSubmit = async () => {
    setButtonLoading(true);
    const body = {
      skill_details: employeeDetails.Employee.skill,
      organization_id: organizationId,
      employee_id: employeeId,
      branch_id: branch_id,
      company_id: company_id,
    };
    const WorkExperience_res = await insertSkillsDataAPI(body);
    if (WorkExperience_res.status == 201) {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Progress Saved Successfully!");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);

      form.resetFields();
      setButtonLoading(false);
    } else if (WorkExperience_res.error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        WorkExperience_res.error.message ||
          "oops we are facing some temporary issue with your request"
      );
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const handleAddingRecordToTable = async () => {
    const newRecord = {
      id: Date.now(),
      skill: form.getFieldValue("skill"),
      comment: form.getFieldValue("comment"),
      experience: form.getFieldValue("experience"),
    };

    const updatedList = [...tableData, newRecord];
    setTableData(updatedList);

    dispatch(
      setEmployeeProfileDetails({
        Employee: {
          ...employeeDetails.Employee,
          skill: updatedList,
        },
      })
    );
  };

  const onFinish = (values: any) => {
    values.id = values.yearsOfExperience + values.skill;
    const updatedList = [
      ...(employeeDetails?.Employee?.skill
        ? employeeDetails?.Employee?.skill
        : []),
      values,
    ];
    setshowAlert(false);
    if (values?.yearsOfExperience && values?.skill) {
      dispatch(
        setEmployeeProfileDetails({
          Employee: {
            skill: updatedList,
          },
        })
      );
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Work Experiance Add Successfully");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      form.resetFields();
    } else {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Please fill all required fields");
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const handleDeleteRecord = (index: any) => {
    dispatch(removeSkillDetail(index));
  };

  const columns = [
    {
      title: "Skill",
      dataIndex: "skill",
      key: "skill",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <Popconfirm
          placement="topLeft"
          title="Do you want to delete this record?"
        >
          <a>
            <DeleteOutlined onClick={() => handleDeleteRecord(index)} />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Collapse className="employee-create-qualification-collapse-style">
        <Collapse.Panel key="1" header="Add Skill Details">
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Skill"
                  name="skill"
                  rules={[{ required: true, message: "Please Enter Skill!" }]}
                >
                  <Input size="large" placeholder="Enter Skill" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Years of Experience"
                  name="experience"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Years of Experience!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Year of Experience" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Comments" name="comment">
                  <Input.TextArea placeholder="Enter Comment..." />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  className="global-btn-width"
                  type="primary"
                  onClick={handleAddingRecordToTable}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <Table className="table-class" columns={columns} dataSource={tableData} />
      {showAlert && <CustomAlert message={alertMessage} type={alertType} />}
      <Row className="last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Save progress
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Skill;
