import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Upload,
  Button,
  Tooltip,
  Modal,
  message,
  Empty,
  Space,
  Popconfirm,
} from "antd";
import { useForm } from "antd/es/form/Form";
import "./index.css";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import type { TableColumnsType } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import CustomAlert from "../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import type { UploadFile } from "antd";

import {
  deleteEmployeeDocumentFromTable,
  fetchEmployeeDocumentsDataApi,
  handleDocumentDeletion,
  insertEmployeeDocumentsDataApi,
  uploadEMployeeDocumentsApi,
} from "./services";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import CustomTable from "../../../common/CustomTable";

interface props {
  employeeId: any;
}
const UploadDocument: React.FC<props> = ({ employeeId }) => {
  const [form] = useForm();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "My Document"
  );

  const read = access?.readAccess;
  const create = access?.createAccess;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const handleDownload = async (record: any) => {
    if (record.attachment) {
      try {
        const response = await fetch(record.attachment);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${record.name}_document`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {}
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Document Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Upload Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (record: any) => (
        <span>{new Date(record).toLocaleDateString()}</span>
      ),
    },

    {
      title: "Action",
      key: "actions",

      render: (_: any, record: any) => (
        <Space size={"middle"}>
          <Tooltip title={"Click to download document."}>
            <a onClick={() => handleDownload(record)}>
              <MdOutlineFileDownload />
            </a>
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => delDocument(record)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setTableLoading(true);
      const res = await fetchEmployeeDocumentsDataApi(
        organizationId,
        companyId,
        branchId,
        employeeId
      );

      if (res.data) {
        setTableLoading(false);
        setTableData(res.data);
      } else {
        setTableLoading(false);
      }
    } catch (error) {
      setTableLoading(false);
    }
  };

  const handleChange = (info: any) => {
    setFileList(info.fileList);
  };
  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error("Please select a file to upload.");
      return;
    }
    const file = fileList[0];
    const { name } = file;
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${name}`;

    const res = await uploadEMployeeDocumentsApi(
      fileName,
      (file as any).originFileObj
    );

    return res;
  };

  const handleSubmit = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    const url_res = await handleUpload();
    if (url_res && url_res.data) {
      values.url = url_res.data.publicUrl;
    } else {
      setshowAlert(true);
      setAlertType("error");
      return;
    }

    const formData = {
      employee_id: employeeId,
      name: form.getFieldValue("documentName"),
      attachment: values.url || "",
      organization_id: organizationId,
      company_id: companyId || null,
      branch_id: branchId || null,
    };

    const res = await insertEmployeeDocumentsDataApi(formData);

    setshowAlert(true);
    setButtonLoading(false);
    if (res.error) {
      setAlertType("error");
    } else {
      setAlertType("success");
      form.resetFields();
      closeCreateModal();
    }
  };

  const onCreateClick = () => {
    setCreateModalVisible(true);
  };
  const closeCreateModal = () => {
    setCreateModalVisible(false);
    fetchData();
  };

  const delDocument = async (record: any) => {
    const response = await handleDocumentDeletion(record);
    if (response.data) {
      const secondResponse = await deleteEmployeeDocumentFromTable(
        organizationId,
        record.id
      );
      if (secondResponse.status) {
        setshowAlert(true);
        setAlertType("success");
        fetchData();
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
    }
  };
  return (
    <>
      <div className="user-profile-salaries-detail-card">
        <Row gutter={[16, 16]} className="global-row-content-right">
          <Col span={3}>
            {create && (
              <Button
                size="large"
                className="global-btn-width upload-document-btn"
                type="primary"
                onClick={onCreateClick}
                icon={<FiPlus className="tab-header-icon" />}
              >
                Create
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          {read ? (
            <CustomTable
              loading={tableLoading}
              columns={columns}
              dataSource={tableData}
              scroll={{ x: true }}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </Col>
      </Row>
      <Modal
        title={<span className="global-modal-title">Upload Documents</span>}
        open={createModalVisible}
        onCancel={closeCreateModal}
        width={1000}
        footer={false}
        destroyOnClose
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="documentName" label="Document Name">
                  <Input
                    size="large"
                    placeholder="Enter the name of the Document..."
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="upload" label="Upload Document">
                  <Upload
                    accept=".png,.jpg,.jpeg,.zip,.pdf,.doc,.docx,.txt"
                    maxCount={1}
                    onChange={handleChange}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="global-modal-bottom-row">
                <Form.Item>
                  <Button
                    className="global-btn-width"
                    type="primary"
                    htmlType="submit"
                    loading={buttonLoading}
                    size="large"
                  >
                    Create
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default UploadDocument;
