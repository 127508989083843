
/**
 * Maps the currency information object to an array of objects containing the currency name and symbol.
 * @param {any} currencyInfo - The currency information object to map.
 * @returns {Array<{name: string, symbol: string}>} An array of objects with currency name and symbol.
 */
export const currencyArrayMapping = (currencyInfo: any) => {
    const finalmapping = Object.keys(currencyInfo).map((key) => {
        return {
            name: key,
            symbol: currencyInfo[key].symbol,
        };
    })
    return finalmapping
}

/**
 * Calculates the daily salary based on the base salary and number of working days in a month.
 * @param {string} baseSalary - The base salary of the employee.
 * @param {string} workingDays - The number of working days in a month.
 * @returns The calculated daily salary with two decimal places.
 */
export const calculatePerDaySalary = (baseSalary: string, workingDays: string) => {
    const totalWorkingDaysMap: any = {
        1: 30 - 24,
        2: 30 - 20,
        3: 30 - 16,
        4: 30 - 12,
        5: 30 - 8,
        6: 30 - 4,
        7: 30,
    };
    const totalWorkingDays = totalWorkingDaysMap[workingDays] || 30;
    return (parseInt(baseSalary) / totalWorkingDays).toFixed(2);
};
/**
 * Calculate the final salary based on the provided parameters.
 * @param {string} [basicSalary] - The basic salary amount.
 * @param {number} [totalAllowanceAmount] - The total allowance amount.
 * @param {boolean} [isBasicSalaryPF] - Indicates if basic salary is subject to PF.
 * @param {string} [pfPct] - The PF percentage.
 * @param {string} [customTax] - Custom tax amount.
 * @param {any} [selectedTaxation] - Selected taxation object.
 * @returns {number} - The final calculated salary.
 */
export const calFinalSalary = (basicSalary?: string, totalAllowanceAmount?: number, isBasicSalaryPF?: boolean,
    pfPct?: string, 
) => {
    let tempfinaldsa = 0;
    if (basicSalary) {
        tempfinaldsa += parseInt(basicSalary || "0");
    }
    if (totalAllowanceAmount) {
        tempfinaldsa += totalAllowanceAmount;
    }
  
    if (isBasicSalaryPF && pfPct) {
        tempfinaldsa -=
            parseInt(basicSalary || "0") * (parseInt(pfPct || "0") / 100);
    }
    return tempfinaldsa
}