import React, { useState } from "react";
import { Col, Row, Radio, Tabs, TabsProps } from "antd";
import WorkExperience from "../WorkExperience/index";
import Education from "../Education/index";
import Skill from "../Skill/index";
import Language from "../Language/index";

const Qualification: React.FC = () => {
  const [qualifictionTabMode, setQualifictionTabMode] = useState<
    "left" | "right" | "top" | "bottom"
  >("top");
  const handleModeChange = (e: any) => {
    setQualifictionTabMode(e.target.value);
  };
  const items: TabsProps["items"] = [
    {
      label: "Work Experience",
      key: "1",
      children: <WorkExperience />,
      closeIcon: true,
    },
    {
      label: "Education",
      key: "2",
      children: <Education />,
      closeIcon: true,
    },
    {
      label: "Skill",
      key: "3",
      children: <Skill />,
      closeIcon: true,
    },
    {
      label: "Language",
      key: "4",
      children: <Language />,
      closeIcon: true,
    },
    // {
    //   label: "Attachments",
    //   key: "5",
    //   children: <Attachment />,
    //   closeIcon: true,
    // },
  ];
  return (
    <>
      <Row>
        <Col span={6}>
          {" "}
          <h3>Qualification</h3>{" "}
        </Col>
        <Col span={18}>
          <Radio.Group onChange={handleModeChange} value={qualifictionTabMode}>
            <Radio.Button value="top">Horizontal</Radio.Button>
            <Radio.Button value="left">Vertical</Radio.Button>
          </Radio.Group>{" "}
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        tabPosition={qualifictionTabMode}
        items={items}
      />
    </>
  );
};
export default Qualification;
