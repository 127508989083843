import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//--------------------------------------Create Access Rights-------------------------------------

export const checkTheRole = async (Ids: any) => {
  try {
    const response = await supabase
      .from("Branches")
      .select("id, branchName")
      .eq("organizationId", Ids.organizationId)
      .eq("companyId", Ids.companyId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchTheGroupData = async (Ids: any) => {
  try {
    let query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("access_right")
      .select("id,group")
      .eq("organization_Id", Ids.organizationId)
      .is("sub_group_name", null)
      .is("parent_group_id", null);

    if (Ids.companyId) {
      query = query.eq("company_id", Ids.companyId);
    }

    if (Ids.branchId) {
      query = query.eq("branch_id", Ids.branchId);
    } else {
      query = query.is("branch_id", null);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAccessRightTableDataByGroup = async (
  id: number,
  organizationId: string
) => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .select("id,group,access_rights")
      .eq("id", id)
      .single();

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchTheCompanies = async (organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name")
      .eq("organization_Id", organizationId);
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchTheBranches = async (Ids: any) => {
  try {
    const response = await supabase_client_for_Org_schema(Ids.organizationId)
      .from("branch")
      .select("id, unique_identifier,name")
      .eq("organization_Id", Ids.organizationId)
      .eq("company_id", Ids.companyId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const createAccessRights = async (data: any) => {
  try {
    const handlerResponse = await checkForUnqueSubgroupNameHanlder(data);
    if (!handlerResponse) {
      const { status } = await supabase_client_for_Org_schema(
        data.organization_Id
      )
        .from("access_right")
        .insert(data);
      return status;
    }
  } catch (error) {
    return catch_return;
  }
};

const checkForUnqueSubgroupNameHanlder = async (data: any) => {
  let query = supabase_client_for_Org_schema(data.organization_Id)
    .from("access_right")
    .select("sub_group_name");
  if (data.company_id) {
    query = query.eq("company_id", data.company_id);
  }
  if (data.branch_id) {
    query = query.eq("branch_id", data.branch_id);
  }
  if (data.parent_group_id) {
    query = query.eq("parent_group_id", data.parent_group_id);
  }
  if (data.group) {
    query = query.eq("group", data.group);
  }
  const checkUniqueSubGroup = await query;

  if (checkUniqueSubGroup) {
    const exists = checkUniqueSubGroup?.data?.some(
      (subgroup) => subgroup.sub_group_name === data.sub_group_name
    );
    return exists;
  }
};
//----------------------------------------View Access Rights---------------------------------------

export const getCompanies = async (Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(Id)
      .from("company")
      .select("id, name")
      .eq("organization_Id", Id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getBranches = async (Ids: any) => {
  try {
    const response = await supabase_client_for_Org_schema(Ids.organizationId)
      .from("branch")
      .select("id, unique_identifier,name")
      .eq("organization_Id", Ids.organizationId)
      .eq("company_id", Ids.companyId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSubgroupBasedOnParentGroup = async (
  value: number,
  organizationId: string,
  branchId: string
) => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .select("id,sub_group_name")
      .eq("parent_group_id", value);
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    if (!branchId) {
      query = query.is("branch_id", null);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getGroupedData = async (Ids: any) => {
  try {
    let query = supabase
      .from("AccessRights")
      .select("*")
      .eq("organizationId", Ids.organizationId)
      .eq("companyId", Ids.companyId);

    if (Ids.subgroup) {
      query = query.eq("parentGroup", Ids.parentGroup);
    } else {
      query = query.is("parentGroup", null);
    }

    if (Ids.branchId) {
      query = query.eq("branchId", Ids.branchId);
    }
    if (!Ids.branchId) {
      query = query.is("branchId", null);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAccessRightTableDataById = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .select("id,group,sub_group_name,access_rights")
      .eq("id", id)
      .single();

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleSaveChanges = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .update({ access_rights: data.body })
      .eq("id", data.dbTableRowId);

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};
