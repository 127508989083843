import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Button, Row, Col } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { setEmployeeEducationModalDataApi } from "../services";
import CustomTable from "../../../CustomTable";

interface EducationModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (values: any) => void;
  data: any;
}

const EducationModal: React.FC<EducationModalProps> = ({
  visible,
  onClose,
  onFinish,
  data,
}) => {
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [tableData, setTableData] = useState<any>();

  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    const incomingData = data.flatMap((item: any) => {
      return item.qualification_details;
    });
    setTableData(incomingData);
  }, []);

  const getAllFormValues = () => {
    const values = form.getFieldsValue();
    const valuesToBeAdded = {
      specialization: values.specialization,
      from: values.from.toISOString().split("T")[0],
      to: values.to.toISOString().split("T")[0],
      id: Date.now(),
      level: values.level,
      institute: values.institute,
    };
    setTableData([...tableData, valuesToBeAdded]);
  };

  const handleSubmit = async (values: any) => {
    try {
      setButtonLoading(true);

      const formData = {
        employee_id: loggedInEmployee?.employeeId || null,
        organization_id: loggedInEmployee?.employee?.organization_id || null,
        company_id: loggedInEmployee?.employee?.company_id || null,
        branch_id: loggedInEmployee?.employee?.branch_id || null,
        qualification_details: tableData,
      };

      const { data, error } = await setEmployeeEducationModalDataApi(
        formData,
        loggedInEmployee?.employee.organization_id
      );

      if (data) {
        setshowAlert(true);

        setAlertType("success");
        setButtonLoading(false);
      }
      if (error) {
        setButtonLoading(false);
        setshowAlert(true);

        setAlertType("error");
      }
      setButtonLoading(false);
      onFinish(values);
      form.resetFields();
      onClose();
    } catch (error) {}
  };
  const disabledDate = (current: any) => {
    const currentDate = new Date();

    return current && current > currentDate;
  };

  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Starting Year",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Passing Year",
      dataIndex: "to",
      key: "to",
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      width={1000}
      destroyOnClose
      footer={false}
      className="global-modal-main-container"
      title={<span className="global-modal-title">Add Education</span>}
    >
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          name="educationForm"
          onFinish={handleSubmit}
        >
          <Row>
            <Col className="modal-content">
              <Form.Item
                name="level"
                label="Level"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter Level ..." />
              </Form.Item>
              <Form.Item
                name="institute"
                label="Institute"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter Institute ..." />
              </Form.Item>
              <Form.Item
                name="specialization"
                label="Specialization"
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="Enter Specialization ..." />
              </Form.Item>
              <Form.Item
                name="from"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder="Enter Start Date ..."
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="to"
                label="End Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder="Enter End Date ..."
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width educationModal-btn-position"
                  onClick={getAllFormValues}
                  type="primary"
                >
                  Add Data
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CustomTable dataSource={tableData} columns={columns} />
        <Form.Item>
          <Button
            key="submit"
            className="global-btn-width educationModal-btn-position"
            htmlType="submit"
            loading={buttonLoading}
            onClick={handleSubmit}
            type="primary"
          >
            Submit
          </Button>
        </Form.Item>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </Modal>
  );
};

export default EducationModal;
