import React, { useState, useEffect, useRef } from "react";
import { Input, Col, Row, Form, Button, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setEmployeeProfileDetails } from "../../../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../../../common/Alert";
import "./index.css";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { domaincheck } from "../../../../../common/validation";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  getAllBranches,
  getAllCompanies,
} from "../../../../../services/Global";
import { getAllEmployeesWithoutContactDetails } from "../services";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const EmployeeForm: React.FC<props> = ({ onCancel }) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<number>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [workPhoneNumber, setWorkPhoneNumber] = useState<string>("");
  const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState<
    any | null
  >(null);
  const [selectedEmployeeCompanyId, setSelectedEmployeeCompanyId] =
    useState<any>();
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [form] = Form.useForm();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const organizationId = loggedInEmployee?.employee.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    dispatch(
      setEmployeeProfileDetails({
        Employee: {
          ContactDetails: {
            workemail: "",
            workmobilenumber: "",
            mobile: "",
            otheremail: "",
          },
          PersonalDetails: {
            firstname: "",
          },
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    fetchCompanyEmailAgainstId();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleSubmit = async () => {
    setButtonLoading(true);
    const employeeData = {
      phone_number: phoneNumber,
      work_email: form.getFieldValue("workEmail"),
      work_phone_number: workPhoneNumber,
      other_email: form.getFieldValue("otherEmail"),
    };

    const res = await supabase_client_for_Org_schema(
      loggedInEmployee?.employee.organization_id
    )
      .from("employee")
      .update([employeeData])
      .eq("id", form.getFieldValue("employeeid"));

    if (res.status === 201 || res.status === 204 || res.status === 200) {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Employee data saved successfully!");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      setButtonLoading(false);
      form.resetFields();
      setFilteredEmployees([]);
      setBranches([]);
      onCancel();
    } else if (res.error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        res.error.message ||
          "Oops, we are facing some temporary issues with your request."
      );
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const fetchEmployeesWithMissingContactDetails = async () => {
    setFilteredEmployees([]);
    form.setFieldValue("employeeid", "");

    try {
      setEmployeeSelectLoading(true);
      let query = supabase_client_for_Org_schema(organizationId)
        .from("employee")
        .select("id, full_name")
        .or(
          'phone_number.is.null,phone_number.eq."",' +
            'work_phone_number.is.null,work_phone_number.eq."",' +
            'work_email.is.null,work_email.eq.""'
        );

      if (selectedCompany) {
        query = query.eq("company_id", selectedCompany);
      }
      if (selectedBranch) {
        query = query.eq("branch_id", selectedBranch);
      }
      const { data } = await query;

      if (data) {
        setFilteredEmployees(data);
        form.setFieldsValue({ employeeid: data[0]?.id });
        setSelectedEmployee(data[0]?.id);
      }
      setEmployeeSelectLoading(false);
    } catch (error) {
      setEmployeeSelectLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchEmployeesWithMissingContactDetails();
    } else {
      setFilteredEmployees([]);
      setBranches([]);
    }
  }, [organizationId, selectedCompany, selectedBranch]);

  const fetchSelectedEmployeeEmail = async () => {
    try {
      if (selectedEmployee) {
        const { data } = await supabase_client_for_Org_schema(organizationId)
          .from("employee")
          .select("company_id")
          .eq("id", selectedEmployee)
          .eq("status", "active");

        if (data) {
          const companyId = data[0].company_id;
          setSelectedEmployeeCompanyId(companyId);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchSelectedEmployeeEmail();
  }, [selectedEmployee]);

  const fetchCompanyEmailAgainstId = async () => {
    try {
      const response = await supabase_client_for_Org_schema(organizationId)
        .from("company")
        .select("email")
        .eq("id", selectedEmployeeCompanyId);

      if (response.data) {
        const email = response.data[0].email;
        setSelectedEmployeeEmail(email);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedEmployeeCompanyId !== null) {
      fetchCompanyEmailAgainstId();
    }
  }, [selectedEmployeeCompanyId]);

  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await getAllCompanies(organizationId);
    if (response.data) {
      setFetchedCompanies(response.data);
      setCompanySelectLoading(false);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
      setSelectedCompany(response?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      setBranchSelectLoading(true);
      const Ids = form.getFieldValue("selectedCompany");
      const response = await getAllBranches(Ids, organizationId);

      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("selectedCompany", companyId);
    } else {
      getAllCompaniess();
    }
  }, []);

  useEffect(() => {
    getEmployeesWithoutContacts();
  }, [selectedCompany, selectedBranch]);
  useEffect(() => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      getAllBranchess();
    }
  }, [selectedCompany]);

  const getEmployeesWithoutContacts = async () => {
    const response = await getAllEmployeesWithoutContactDetails(
      loggedInEmployee?.employee?.organization_id
    );

    setFilteredEmployees(response);
  };

  return (
    <div className="global-card-inner-container">
      <Form form={form} layout="vertical" autoComplete="off">
        <Row gutter={16}>
          {!companyId && (
            <Col span={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a Company..."
                rules={[
                  { required: true, message: "Please choose a company..." },
                ]}
              >
                <Select
                  loading={companySelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompany}
                  placeholder="Select a company"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col span={12}>
              <Form.Item name={"selectedBranch"} label="Select a branch">
                <Select
                  loading={branchSelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedBranch}
                  placeholder="Select a branch"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="employeeid"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                loading={employeeSelectLoading}
                placeholder="Please select an Employee..."
                onChange={(value) => setSelectedEmployee(value)}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"mobileNumber"}
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please enter the mobile number!",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter Mobile Number"
                defaultCountry="pk"
                inputProps={{
                  required: true,
                }}
                onChange={setPhoneNumber}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={"workNumber"}
              label="Work Number"
              rules={[
                {
                  required: true,
                  message: "Please enter the work number!",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                placeholder="Enter Work Number"
                defaultCountry="pk"
                inputProps={{
                  required: true,
                }}
                onChange={setWorkPhoneNumber}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"workEmail"}
              label="Work Email"
              rules={[
                {
                  required: true,
                  message: "Please enter the work email!",
                },
                {
                  validator: (_, value) => {
                    if (value && !domaincheck(selectedEmployeeEmail, value)) {
                      return Promise.reject(
                        "Email domain not Match with company Email"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Work Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={"otherEmail"}
              label="Other Email"
              rules={[
                {
                  type: "email",
                  required: false,
                },
              ]}
            >
              <Input size="large" placeholder="Enter Other Email" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="global-modal-bottom-row contact-create-btn-position">
          <Col>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSubmit}
              loading={buttonLoading}
              size="large"
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>

      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </div>
  );
};

export default EmployeeForm;
