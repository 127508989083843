import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { Button, Col, Form, Input, Row, Select, Table } from "antd";
import "./index.css";
import TextArea from "antd/es/input/TextArea";
import CustomAlert from "../../../../common/Alert";
import { useForm } from "antd/es/form/Form";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  filterAndGetEmployeesDetails,
  getBranchesByComapnyId,
  getKPIScalesDetail,
  getKpiAttributeNames,
  sendEmployeeKPIsData,
} from "../services";
import { capitalizeWords } from "../../../../common/validation";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface SelectedMetric {
  [key: string]: {
    performanceMetric: string;
    obtainedMarks: number;
  };
}

interface CreateEmployeeKPIsProps {
  oncancel: () => void;
}

const CreateEmployeeKPIs: React.FC<CreateEmployeeKPIsProps> = ({
  oncancel,
}) => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [scales, setScales] = useState<any[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectedMetric>({});
  const [totalAggregate, setTotalAggregate] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [remarks, setRemarks] = useState<{ [key: string]: string }>({});
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(true);
  const [branchSelectLoading, setBranchSelectLoading] = useState<boolean>(true);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(true);
  const [alertDescription, setAlertDescription] = useState<string>("");
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const [form] = useForm();

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      if (selectedCompany) {
        getBranches();
      }
    }
  }, [selectedCompany]);
  useEffect(() => {
    const Ids = {
      organizationId: organizationId,
      companyId: companyId || selectedCompany,
      branchId: branchId || selectedBranch,
    };

    const getRecords = async () => {
      const response = await filterAndGetEmployeesDetails(Ids);
      if (response) {
        setEmployees(response);
      }
      setEmployeeSelectLoading(false);
    };
    getRecords();
  }, [selectedCompany, selectedBranch]);

  useEffect(() => {
    const data = {
      companyId: form.getFieldValue("company"),
      branchId: form.getFieldValue("branch"),
    };
    const getKPIAttributes = async () => {
      const response = await getKpiAttributeNames(data, organizationId);

      setLoading(true);
      if (response.data) {
        setAttributes(response.data);
        if (selectedBranch || selectedCompany) {
          setAttributes(response.data);
        } else {
          const filteredData = response.data.filter(
            (item: any) => item.branchId === null
          );

          setAttributes(filteredData);
        }
      } else if (response.error) {
        setAttributes([]);
        setLoading(false);
      } else if (!selectedCompany || !selectedBranch) {
        setEmployees([]);
      }
      setLoading(false);
    };

    const fetchKPIScales = async () => {
      const data = {
        companyId: selectedCompany,
        branchId: selectedBranch,
      };
      const response = await getKPIScalesDetail(data, organizationId);
      setLoading(true);
      if (response.data) {
        const sorted_date = response?.data?.sort(
          (a: any, b: any) => a.scale - b.scale
        );

        setScales(sorted_date);
        setLoading(false);
      }
    };

    getKPIAttributes();
    fetchKPIScales();
  }, [selectedCompany, selectedBranch]);

  const getCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      const { data } = await getBranchesByComapnyId(
        selectedCompany,
        organizationId
      );
      if (data) {
        setBranches(data);
        form.setFieldValue("branch", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
    }
    setBranchSelectLoading(false);
  };

  const handleRemarksChange = (attributeId: any, value: string) => {
    setRemarks({
      ...remarks,
      [attributeId]: value,
    });
  };
  const handleMetricChange = (attributeId: any, value: any) => {
    const scaleValue = scales.find(
      (scale) => scale.performance_metrics === value
    )?.scale;
    const obtainedMarks = scaleValue ? (scaleValue / 5) * 100 : 0;
    const newSelectedMetrics = {
      ...selectedMetrics,
      [attributeId]: {
        performanceMetric: value,
        obtainedMarks,
      },
    };

    setSelectedMetrics(newSelectedMetrics);

    updateTotalAggregate(newSelectedMetrics);
  };
  const updateTotalAggregate = (metrics: SelectedMetric) => {
    const total = Object.values(metrics).reduce(
      (acc, metric) => acc + metric.obtainedMarks,
      0
    );
    const numberOfAttributes = attributes.length;
    const aggregate = numberOfAttributes > 0 ? total / numberOfAttributes : 0;
    setTotalAggregate(aggregate);
  };
  const columns = [
    {
      title: "Attribute Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => capitalizeWords(text),
    },
    {
      title: "Performance Metrics",
      key: "performance_metrics",
      render: (text: any, record: any) => (
        <Select
          placeholder="Select a metric"
          allowClear
          onChange={(value) => handleMetricChange(record.id, value)}
          className="metric-select"
          showSearch
          filterOption={(input: any, option: any) =>
            option && option.children
              ? (option.children as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              : false
          }
          notFoundContent={
            <NoDataFoundIcon />
          }
        >
          {scales.map((scale) => (
            <Select.Option key={scale.id} value={scale.performance_metrics}>
              {scale.performance_metrics}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Attribute Points",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Obtained Percentage %",
      key: "obtained",
      render: (text: any, record: any) => (
        <span>{selectedMetrics[record.id]?.obtainedMarks || ""}</span>
      ),
    },
    {
      title: "Remarks",
      key: "remarks",
      render: (text: any, record: any) => (
        <Form.Item name={"remarks"}>
          <TextArea
            rows={2}
            value={remarks[record.id] || ""}
            onChange={(e) => handleRemarksChange(record.id, e.target.value)}
          />{" "}
        </Form.Item>
      ),
    },
  ];

  const dataSource = attributes.map((attribute) => ({
    ...attribute,
    obtained: selectedMetrics[attribute.id]?.obtainedMarks || "",
  }));
  const sendDataToSupabase = async () => {
    setAlertDescription("");

    if (Object.keys(selectedMetrics).length > 0) {
      setButtonLoading(true);
      const employeeKPIData = {
        company_id: selectedCompany || companyId,
        organization_id: organizationId,
        branch_id: selectedBranch || branchId || null,
        employee_id: selectedEmployee,
        aggregate: totalAggregate,
        data: attributes.map((attribute) => ({
          ...attribute,
          selectedMetric: selectedMetrics[attribute.id],
          remarks: remarks[attribute.id] || "",
        })),
      };
      const response = await sendEmployeeKPIsData(employeeKPIData);
      if (response.status === 201) {
        setshowAlert(true);
        setAlertType("success");
        setButtonLoading(false);
        setSelectedMetrics({});
        setTotalAggregate(0);
        setRemarks({});
        setSelectedEmployee(null);
        form.resetFields();
        setSelectedCompany(null);
        setSelectedBranch(null);
        setSelectedEmployee(null);
        setEmployees([]);
        setCompanies([]);
        setBranches([]);
        setAttributes([]);
        getCompanies();
        oncancel();
      } else {
        setshowAlert(true);
        setButtonLoading(false);
        setAlertType("error");
      }
    } else {
      setAlertDescription("Please at least enter one attribute value");
      setshowAlert(true);
      setAlertType("error");
    }
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form} onFinish={sendDataToSupabase}>
          <Row gutter={16}>
            {!companyId && (
              <Col span={12}>
                <Form.Item
                  name={"company"}
                  label="Companies"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company...",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    allowClear
                    onChange={setSelectedCompany}
                    placeholder="Please select a company..."
                    loading={companySelectLoading}
                  >
                    {companies?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col span={12}>
                <Form.Item name={"branch"} label="Branches">
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedBranch}
                    placeholder="Please select a branch..."
                    loading={branchSelectLoading}
                  >
                    {branches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <Form.Item
                label="Select Employees"
                name="employee"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Employee",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select an Employee.."
                  onChange={setSelectedEmployee}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  loading={employeeSelectLoading}
                >
                  {employees?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item?.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Total Aggregate in Percentage %">
                <Input
                  size="large"
                  value={totalAggregate.toFixed(2) + "%"}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <CustomTable
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={loading}
            pagination={false}
          />
          <Row className="global-modal-bottom-row save-data-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                loading={buttonLoading}
                size="large"
                htmlType="submit"
              >
                {" "}
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
        {showAlert && (
          <CustomAlert
            type={alertType}
            showAlert={showAlert}
            description={alertDescription}
          />
        )}
      </div>
    </>
  );
};

export default CreateEmployeeKPIs;
