import React from 'react';
import { Card } from 'antd';
import './index.css';


const AboutProduct: React.FC = () => {
  return (
    <div className='about-product-container'> 
    <Card className='about-product-card'>
        <h2> Mis Genie </h2>
        <p> Mis Genie is a HRMS System. A human resources management system or Human Resources Information System or Human Capital Management is a form of Human Resources software that combines a number of systems and processes to ensure the easy management of human resources, business processes and data. </p>
    </Card>
    </div>
  );
};

export default AboutProduct;
