import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Modal,
  Select,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  checkKpiThePercentage,
  getBranchesByCompanyId,
  setKPIAttributesData,
} from "../services";
import { capitalizeWords } from "../../../../common/validation";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface abc {
  oncancel: () => void;
}
const AddKPIAttributes: React.FC<abc> = ({ oncancel }) => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertDescription, setAlertDescription] = useState<any>(null);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id ||
    selectedCompany;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id ||
    selectedBranch;

  const companyid =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchid =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      getBranches();
    }
  }, [selectedCompany]);

  useEffect(() => {
    const existingPercentage = async () => {
      const data = {
        companyId: selectedCompany,
        branchId: selectedBranch,
      };
      if (selectedCompany || companyId) {
        setLoading(true);
        const existingPercentageCount = await checkKpiThePercentage(
          data,
          organizationId
        );
        if (existingPercentageCount?.data) {
          setTableData(existingPercentageCount.data);
          setLoading(false);
        } else if (existingPercentageCount?.error) {
        }
      }
    };

    existingPercentage();
  }, [selectedCompany, selectedBranch]);

  const addToTable = async () => {
    try {
      const values = await form.validateFields();
      const { name, percentage } = values;
      const newEntry = name.toLowerCase();
      const newPercentage = parseInt(percentage);

      const currentSumOfPercentage = tableData.reduce(
        (acc, record) => acc + record.percentage,
        0
      );

      const checkIfDuplicate = tableData.some(
        (item) => item.name.toLowerCase() === newEntry
      );

      const percentageTotal = currentSumOfPercentage + newPercentage;

      if (checkIfDuplicate) {
        setshowAlert(true);
        setAlertType("error");
        setAlertDescription(
          "Attribute name already exists or percentages exceed 100%. Please re-check your values."
        );
      } else if (percentageTotal > 100) {
        setshowAlert(true);
        setAlertType("error");
        setAlertDescription(
          "Percentages exceed 100%. Please re-check your values."
        );
      } else {
        setTableData([
          ...tableData,
          { id: null, name: newEntry, percentage: newPercentage },
        ]);

        form.resetFields(["name", "percentage"]);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertDescription(
        "Either you are trying to enter duplicate values or the percentage count exceeds 100"
      );
      setAlertType("error");
      setTimeout(() => setshowAlert(false), 1500);
    }
  };

  const getCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };

  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      const { data } = await getBranchesByCompanyId(
        selectedCompany,
        organizationId
      );

      if (data) {
        setBranches(data);
        form.setFieldValue("branch", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
    }
  };

  const deleteRecordFromTable = (record: any) => {
    const updatedTableRecords = tableData.filter(
      (item: any) => item.name !== record.name
    );
    setTableData(updatedTableRecords);
  };

  const handleOpeningOfModal = (record: any) => {
    setSelectedRecord(record);
    form1.setFieldsValue({
      editAttributeName: record.name,
      editPercentage: parseInt(record.percentage),
    });

    setOpenModal(true);
  };

  const handleModalClosure = () => {
    setOpenModal(false);
  };

  const handleSaveEdit = async () => {
    try {
      const values = await form1.validateFields();
      const editedEntry = values.editAttributeName.toLowerCase();
      const editedPercentage = parseInt(values.editPercentage);
      const isDuplicate = tableData.some(
        (item) =>
          item.name.toLowerCase() === editedEntry &&
          item.name.toLowerCase() !== selectedRecord.name.toLowerCase()
      );
      const currentTotalPercentage = tableData.reduce(
        (acc, record) =>
          acc + (record.name !== selectedRecord.name ? record.percentage : 0),
        0
      );

      const newTotalPercentage = currentTotalPercentage + editedPercentage;

      if (isDuplicate) {
        setshowAlert(true);
        setAlertDescription(
          "Attribute name already exists. Please re-check your values."
        );
        setAlertType("error");
      } else if (newTotalPercentage > 100) {
        setshowAlert(true);
        setAlertDescription(
          "Percentages exceed 100%. Please re-check your values."
        );
        setAlertType("error");
      } else {
        const updatedRecords = tableData.map((record) =>
          record.name === selectedRecord.name
            ? {
                ...record,
                id: record.id,
                name: editedEntry,
                percentage: editedPercentage,
              }
            : record
        );
        setTableData(updatedRecords);
        setOpenModal(false);
        form1.resetFields();
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const saveToDatabase = async () => {
    setButtonLoading(true);
    const formattedData = tableData.map((item: any) => ({
      ...item,
      organizationId,
      companyId: selectedCompany || companyId,
      branchId: selectedBranch || branchId || null,
    }));
    const responses = await setKPIAttributesData(formattedData, organizationId);

    if (responses && responses.length > 0) {
      setshowAlert(true);
      setAlertType("success");
      setButtonLoading(false);
      setSelectedBranch(null);
      setSelectedCompany(null);
      setTableData([]);
      oncancel();
    } else if (!responses) {
      setshowAlert(true);
      setAlertType("error");
      setButtonLoading(false);
    }
    setButtonLoading(false);
  };

  const columns = [
    {
      title: "Attribute Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => capitalizeWords(text),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size={"middle"}>
          <a onClick={() => handleOpeningOfModal(record)}>
            <EditOutlined />
          </a>
          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this record?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteRecordFromTable(record)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            {!companyid && (
              <Col span={24} md={12}>
                <Form.Item
                  name={"company"}
                  label="Companies"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company...",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedCompany}
                    placeholder="Please select a company"
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchid && (
              <Col span={24} md={12}>
                <Form.Item name={"branch"} label="Branches...">
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedBranch}
                    placeholder="Please select a branch"
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {branches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24} md={12}>
              <Form.Item
                label="Attribute Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter an attribute name.",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter an attribute name..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="Percentage %"
                name="percentage"
                rules={[
                  {
                    required: true,
                    message: "Please enter a percentage.",
                  },
                  {
                    pattern: /^[1-9]\d*$/,
                    message:
                      "Please enter a number only (0 and negative values are not allowed)",
                  },
                ]}
              >
                <Input
                  size="large"
                  min={0}
                  placeholder="Enter a percentage..."
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row className="add-to-table-btn">
          <Button
            className="global-btn-width"
            type="primary"
            onClick={addToTable}
            size="large"
          >
            Add Record
          </Button>
        </Row>
        <CustomTable
          scroll={{ x: true }}
          columns={columns}
          pagination={false}
          dataSource={tableData}
          loading={loading}
        />
        <Row className="global-modal-bottom-row global-row-content-right kpi-attribute-bottom-btn">
          <Button
            onClick={saveToDatabase}
            className="global-btn-width"
            type="primary"
            loading={buttonLoading}
            size="large"
          >
            Create
          </Button>
        </Row>
        {showAlert && (
          <CustomAlert
            type={alertType}
            showAlert={showAlert}
            description={alertDescription}
          />
        )}
      </div>
      <Modal
        open={openModal}
        onCancel={handleModalClosure}
        title={<span className="global-modal-title">Edit Table Record</span>}
        footer={null}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          {selectedRecord && (
            <Form layout="vertical" form={form1}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Attribute Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter an attribute name.",
                      },
                    ]}
                    name="editAttributeName"
                  >
                    <Input size="large" placeholder="Enter attribute name..." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Percentage %"
                    rules={[
                      {
                        required: true,
                        message: "This field cannot be left empty.",
                      },
                      {
                        pattern: /^[1-9]\d*$/,
                        message:
                          "Only numbers are accepted. No negative numbers and 0 allowed",
                      },
                    ]}
                    name="editPercentage"
                  >
                    <Input size="large" placeholder="Enter percentage..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="global-modal-bottom-row global-row-content-right">
                <Col className="edit-modal-save-btn">
                  <Button
                    onClick={handleSaveEdit}
                    className="global-btn-width"
                    type="primary"
                    size="large"
                  >
                    Save Details
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddKPIAttributes;
