import React, { useState, useEffect } from "react";
import "./index.css";
import { Table, Popconfirm, TablePaginationConfig, Space, Modal } from "antd";
import { DeleteOutlined, EyeOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { deleteLeavePolicy, fetchLeavePolicies } from "../services";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import LeavesEmployeeView from "../EmployeeView";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface LeavesListProps {
  searchValue?: string;
}

const LeavesListView: React.FC<LeavesListProps> = ({ searchValue }) => {
  const [policies, setPolicies] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee?.logedInemployee?.employee?.organization_id;
  const companyid = loggedInEmployee?.logedInemployee?.employee?.company_id;
  const [showLeaveViewModal, setLeaveViewModal] = useState<boolean>(false);
  const [leaveViewModalId, setLeaveViewModalId] = useState<any>(null);

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "Leaves Policy"
  );

  const deletee = access?.deleteAccess;

  useEffect(() => {
    fetchPolicies();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setPolicies(originalData.apiData);
    }
  }, [searchValue]);
  const fetchPolicies = async () => {
    try {
      setTableLoading(true);

      const { data } = await fetchLeavePolicies(organizationId, companyid);

      if (data) {
        setPolicies(data || []);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: data,
        }));
      }
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
    }
  };

  const deletePolicy = async (id: any) => {
    try {
      const { error } = await deleteLeavePolicy(id, organizationId);

      if (error) {
        setshowAlert(true);
        setAlertType("error");
      }

      setshowAlert(true);
      setAlertType("success");
      setPolicies((prevPolicies) =>
        prevPolicies.filter((policy) => policy.id !== id)
      );
    } catch (error) {}
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const expandedRowRender = (policy: any) => {
    const columns = [
      { title: "Leave Type", dataIndex: "leaveType", key: "leaveType" },
      {
        title: "Maximum Allowed",
        dataIndex: "maxAllowed",
        key: "maxAllowed",
      },
      {
        title: "Consecutive Allowed?",
        dataIndex: "consecutiveAllowed",
        key: "consecutiveAllowed",
      },
      {
        title: "Maximum Consecutive Allowed",
        dataIndex: "maxConsecutiveAllowed",
        key: "maxConsecutiveAllowed",
      },
    ];

    return (
      <>
        <CustomTable
          scroll={{ x: true }}
          dataSource={policy?.policy_types}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
      </>
    );
  };

  const handleSearch = (value: any) => {
    setTableLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setPolicies(filterData);

    setTableLoading(false);
  };

  const hanldeViewLeaveModalVisible = (value: any) => {
    setLeaveViewModal(true);
    setLeaveViewModalId(value);
  };

  const hanldeViewLeaveModalCancel = () => {
    setLeaveViewModal(false);
    setLeaveViewModalId(null);
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },

    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this policy?"
            onConfirm={() => deletePolicy(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
          <a>
            <EyeOutlined onClick={() => hanldeViewLeaveModalVisible(record)} />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className="leaves-policy-main-container">
      <CustomTable
        dataSource={policies}
        columns={columns}
        rowKey="id"
        onChange={handleTableChange}
        expandable={{
          expandedRowRender,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <a onClick={(e) => onExpand(record, e)}>
                <MinusOutlined />
              </a>
            ) : (
              <a onClick={(e) => onExpand(record, e)}>
                <PlusOutlined />
              </a>
            ),
        }}
        loading={tableLoading}
      />

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}

      <Modal
        open={showLeaveViewModal}
        onCancel={hanldeViewLeaveModalCancel}
        width={1000}
        footer={false}
        className="global-modal-main-container"
        destroyOnClose
        title="Leaves Policy"
      >
        <div className="global-card-inner-container">
          <LeavesEmployeeView propsData={leaveViewModalId} />
        </div>
      </Modal>
    </div>
  );
};

export default LeavesListView;
