import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "antd";
import {
  BarChart,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { FaClock, FaHourglassStart } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import "./index.css";
import { BiTrendingDown } from "react-icons/bi";
import {
  getWorkingHoursApi,
  calculateWorkingHours,
  getAttendanceApi,
  calculateProductiveHours,
  prepareChatData,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";
import { useLocation, useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface WorkTimeSummaryProps {
  totalRegularHours: number;
  totalProductiveHours: number;
  summaryRefCounter:number
}

const WorkTimeSummary: React.FC<WorkTimeSummaryProps> = (props) => {
 
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [totalOvertimeHours, setTotalOvertimehours] = useState<any>(null);
  const [totalOvertimeMins, setTotalovertimemis] = useState<any>(null);
  const organizationId = useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
 
  useEffect(() => {
    getTheOvertimeDetails();
  }, []);

  const getTheOvertimeDetails = async () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedFirstDayOfMonth =
      firstDayOfMonth.toISOString()?.slice(0, 19)?.replace("T", " ") + "+00";
    const { data, error } = await supabase_client_for_Org_schema(organizationId)
      .from("over_time_claim")
      .select("total_working_houre")
      .eq("requested_employee_Id", loggedInEmployee?.employee?.id)
      .eq("status", "Approved")
      .gte("created_at", formattedFirstDayOfMonth);
    if (error) {
      return;
    }
    if (data) {
      let totalHours = 0;
      let totalMinutes = 0;
      data.forEach((item) => {
        const timeString = item.total_working_houre.toString();
        if (item && item.total_working_houre) {
          const [hoursPart, decimalPart] = timeString?.split(".");
          totalHours += parseInt(hoursPart, 10);
          totalMinutes += parseFloat(`0.${decimalPart}`) * 60;
        }
      });
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
      setTotalOvertimehours(totalHours);
      setTotalovertimemis(totalMinutes);
    }
  };

  const formatHoursAndMinutes = (totalHoursString: any) => {
    if (totalHoursString) {
      const totalHours = parseFloat(String(totalHoursString)?.replace("h", ""));
      const hours = Math.floor(totalHours);
      const minutes = Math.round((totalHours - hours) * 60);
      if (hours== 0 &&  minutes == 0) {
        return `No Data`;
      }
      return `${hours}h : ${minutes}m`;
    }
  };

  return (
    <div key={props.summaryRefCounter}>
      <Row className="work-time-summary" gutter={[16, 16]}>
        <Col xs={20} sm={20} md={10} lg={5} xl={5} xxl={5}>
          <div className="total-hours">
            <FaClock className="totalhoursicon" />{" "}
            <span className="work-time">Total Hours:</span>
            <br />
            <span className="per-houre">
              {props?.totalRegularHours ? formatHoursAndMinutes(props?.totalRegularHours) : "--h:--m"}
            </span>
            
          </div>
        </Col>
        <Col xs={20} sm={20} md={10} lg={5} xl={5} xxl={5}>
          <div className="productive">
            <FaHourglassStart className="productiveicon" />{" "}
            <span className="productive-time">Productive:</span>
            <br />
            <span className="per-houre">
              {props?.totalProductiveHours
                ? formatHoursAndMinutes(props?.totalProductiveHours)
                : "--h:--m"}
            </span>
            <span className="hour-label"></span>
          </div>
        </Col>
        <Col xs={20} sm={20} md={10} lg={5} xl={5} xxl={5}>
          <div className="overtime">
            <GiProgression className="overtimeicon" />{" "}
            <span className="productive-time">Overtime:</span>
            <br />
            <span className="per-houre">
              {totalOvertimeHours && totalOvertimeMins
                ? `${totalOvertimeHours}h : ${totalOvertimeMins}m`
                : "--h:--m"}
            </span>
            <span className="hour-label"></span>
          </div>
        </Col>
        <Col xs={20} sm={20} md={10} lg={5} xl={5} xxl={5}>
          <div className="short-item">
            <BiTrendingDown className="shortitemicon" />{" "}
            <span className="productive-time">Short Hours:</span>
            <br />
            <span className="per-houre">
              {props?.totalProductiveHours && props?.totalRegularHours
                ? props?.totalProductiveHours >= props?.totalRegularHours ? "0h:0m":formatHoursAndMinutes(props?.totalRegularHours - props?.totalProductiveHours)
                : "--h:--m"}
            </span>
            <span className="hour-label"></span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const TimeAtWork: React.FC = () => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [totalProductiveHours, setProductiveHours] = useState<number>(0);
  const [totalRegularHours, setTotalRegularHours] = useState<number>(0);
  const [normalRegularHours, setNormalRegularHours] = useState<number>(0);
  const [summaryRefCounter, setSummeryRefCounter] = useState<number>(0);
  const [intialChartData, setIntialChartData] = useState<any>();
  const [FinalChartData, setFinalChartData] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    calTotalRegularHours();
  }, []);

  useEffect(() => {
    if (intialChartData && normalRegularHours) {
      getChatData();
    }
  }, [intialChartData, normalRegularHours]);

  const calTotalRegularHours = async () => {
    const data = {
      employee_id: logedInemployee?.employee?.id,
      organizationId: logedInemployee?.employee?.organization_id,
    };
    const res = await getWorkingHoursApi(data);
    if (res?.data) {
      setNormalRegularHours(res?.data?.working_hours_per_day);
      const workinghour = calculateWorkingHours(
        res?.data?.working_hours_per_day,
        res?.data?.working_days
      );
     
      setTotalRegularHours(workinghour);
      setSummeryRefCounter(summaryRefCounter+1)
    }
    getAttendanceData(res?.data?.working_hours_per_day);
  };
  const getAttendanceData = async (WorkingHoursperday: string) => {
    const organizationId = logedInemployee?.employee.organization_id;
    const employeeId = logedInemployee?.employee.id;
    const res = await getAttendanceApi(organizationId, employeeId);

    if (res.data) {
      setIntialChartData(res.data);
      setProductiveHours(calculateProductiveHours(res.data, WorkingHoursperday));
      setSummeryRefCounter(summaryRefCounter+1)
    }
  };

  const getChatData = () => {
    const temp_data = prepareChatData(intialChartData, normalRegularHours);
    const sortedTempData = sortByDay(temp_data);
    setFinalChartData(sortedTempData);
  };
  const sortByDay = (data:any) => {
    return data.sort((a :any, b:any) => {
      const dayA = parseInt(a?.name?.split('-')[1], 10); // Extract day part and convert to integer
      const dayB = parseInt(b?.name?.split('-')[1], 10); // Extract day part and convert to integer
      return dayA - dayB;
    });
  };
  

  const handleTimeAtWorkBtnClick = () => {
    navigate(
      `/${logedInemployee?.employee?.access_right_group?.toLowerCase()}/myattendance`
    );
  };

  return (
    <>
      <Card
        className="bar-card  employee-portal-set-legend-position-barchart dasboard-cards dasboard-card-sizes"
        title={
          <Row className="time-at-work-header-row">
            <Col span={12}>
              <span className="time-at-work-title">Time at work</span>
            </Col>
            <Col span={12} className="time-at-work-btn-container">
              <Button
                type="primary"
                onClick={handleTimeAtWorkBtnClick}
                icon={<EyeOutlined className="time-at-work-btn-icon" />}
              >
                View Time Logs
              </Button>
            </Col>
          </Row>
        }
        bordered={true}
      >
        <WorkTimeSummary
          totalProductiveHours={totalProductiveHours}
          totalRegularHours={totalRegularHours}
          summaryRefCounter={summaryRefCounter}
        />
        <ResponsiveContainer width="100%" height={300}>
        {FinalChartData.length == 0 ? (
            <div className="performance-metrices-nodata">
              <NoDataFoundIcon />
            </div>
          ) : (
          <BarChart
            width={730}
            height={250}
            data={FinalChartData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" interval={0} angle={-65} textAnchor="end" />
            <YAxis
              label={{ value: "Hours", angle: -90, position: "insideLeft" }}
            />
            <Tooltip
              formatter={(value, name) => [
                value,
                `${name === "ShortHours"
                  ? "Short Hours"
                  : name === "ExtraHours"
                    ? "Extra Hours"
                    : "Worked Hours"
                }`,
              ]}
            />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              align="center"
              height={36}
            />
            <Bar
              dataKey="WorkedHours"
              stackId="hours"
              fill="var(--green-color-accent-dark)"
              barSize={15}
            />
            <Bar
              dataKey="ExtraHours"
              stackId="hours"
              fill="var(--green-color-primary-light)"
              barSize={15}
            />
            <Bar
              dataKey="ShortHours"
              stackId="hours"
              fill="#EE4B2B"
              barSize={15}
            />
          </BarChart>)
          }
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default TimeAtWork;
