import React, { FC, useEffect, useState } from "react";
import { Card, Col, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";
import "./index.css";

import { capitalizeWords } from "../../../../common/validation";
import { ProgressLoader } from "../../../../common/ReactLoader";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";

interface NaviProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const ByGender: React.FC<NaviProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [departmentData, setDepartmentData] = useState<
    { id: string; label: string; value: number }[]
  >([]);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    createDepartmentDataForChart(props.employeeData);
  }, [props.employeeData]);

  useEffect(() => {
    filterBranches();
  }, [selectedCompany]);

  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterBranches();
  }, [props.companies, props.branches]);

  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);

  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    createDepartmentDataForChart(tempData);
  };

  const filterBranches = () => {
    if (props.branches && selectedCompany) {
      const filteredBranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );
      setBranchOptions(filteredBranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const createDepartmentDataForChart = async (data: any[]) => {
    if (data) {
      const genderCounts = data.reduce((counts: any, employee: any) => {
        const gender = capitalizeWords(employee.gender) || "Unknown";
        counts[gender] = (counts[gender] || 0) + 1;
        return counts;
      }, {});
      const DataArray = Object.keys(genderCounts).map((gender) => ({
        id: gender,
        label: capitalizeWords(gender),
        value: genderCounts[gender],
      }));
      DataArray.sort((a, b) => b.value - a.value);

      setDepartmentData(DataArray);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      className="total-employee-graph-container dasboard-cards"
      title={
        <Row className="bygender-icon-toggle-container">
          <Col span={24} md={8} className="total-employee-title-text">
            By Gender
          </Col>
          <Col span={24} md={16}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id && loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="bygender-card-selectors-row">
                {isToggled && (
                  <>
                    <Col
                      span={10}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Company..."
                        onChange={setSelectedCompany}
                        className="totalemployee-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col
                      span={9}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Branch..."
                        onChange={setSelectedBranch}
                        className="totalemployee-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOptions.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col
                  span={5}
                  className="bygender-icon-container"
                  onClick={handleToggle}
                >
                  {isToggled ? (
                    <div className="bygender-search-icon-container">
                      <IoSearch className="bygender-search-icon" />
                    </div>
                  ) : (
                    <div className="bygender-search-icon-container">
                      <IoSearch className="bygender-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      <div style={{ height: 300 }}>
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : departmentData && departmentData.length > 0 ? (
          <ResponsivePie
            data={departmentData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.3}
            padAngle={2}
            cornerRadius={5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={3}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
          />
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataFoundIcon />
          </div>
        )}
      </div>
    </Card>
  );
};
export default ByGender;
