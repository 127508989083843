import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getAllBranchesDataApi = async (
  companyId: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);
    return response;
  } catch {
    return catch_return;
  }
};

export const submitAssignAssets = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const firstResponse = await supabase_client_for_Org_schema(orgId)
      .from("assign_assets")
      .select("employee_Id")
      .eq("employee_Id", data.employee_Id);

    if (firstResponse.data && firstResponse.data?.length > 0) {
      const update = supabase_client_for_Org_schema(orgId)
        .from("assign_assets")
        .update(data)
        .eq("employee_Id", data.employee_Id);

      return update;
    } else {
      const insert = supabase_client_for_Org_schema(orgId)
        .from("assign_assets")
        .insert([data]);

      return insert;
    }
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeAssetsDataApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("assign_assets")
      .select("*")
      .eq("employee_Id", data);
    return response;
  } catch {
    return catch_return;
  }
};

export const getAllAssetsForEmployeesApi = async (
  companyId: string,
  branchId: string,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("asset")
      .select("name, manufacturer , id")
      .eq("company_id", companyId);
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const response = await query;
    return response;
  } catch {
    return catch_return;
  }
};

export const getEmployeesWithNoAssetsApi = async (
  companyId: string,
  branchId: string,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("employee")
      .select("id, full_name")
      .eq("company_id", companyId)
      .eq("status", "active");
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const employeeResponse = await query;

    return employeeResponse;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeesInAssetsTableApi = async (
  companyId: any,
  organizationId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("assign_assets")
      .select(`employee(full_name), employee_Id `)
      .eq("organization_Id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const getlistofAssetsApi = async (
  companyId: string,
  branchId: string,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("asset")
      .select("name, manufacturer")
      .eq("company_id", companyId);
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const updateEmployeeAssetsApi = async (
  updatedData: any,
  currentRecordId: string | number,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("assign_assets")
      .update({ assets: updatedData })
      .eq("id", currentRecordId)
      .select()
      .single();

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const deleteEmployeeAssetApi = async (
  currentRecordId: string | number,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("assign_assets")
      .delete()
      .eq("id", currentRecordId);

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};
