import supabase, { supabase_client_for_operation_portal_schema } from "../../services/supabase";

const customCatchError = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const storeBugFormData = async (body: any): Promise<any> => {
  try {
    const res = await  supabase_client_for_operation_portal_schema.from("bug_report").insert(body);
    return res;
  } catch {
    return customCatchError;
  }
};
