import React from "react";
import { Tabs } from "antd";
import "./index.css";
import { useLocation } from "react-router-dom";
import UserPersonalInfoUpdate from "./PersonalInformation";
import UserContactInfoUpdate from "./ContactInformation";
import UserResponsibilityandReportInfoUpdate from "./ResponsibilityandReport";
import UserQualifictionAndOtherInfoUpdate from "./QualificationandOther";
import UserBankInfoUpdate from "./BankDetails";
import UserSalaryDetails from "./SalaryDetails";
import UploadDocument from "./UploadDoc";

const EmployeeProfileUpdate: React.FC = () => {
  const location = useLocation();
  const lastElement = location.pathname.split("/").pop();

  const items = [
    {
      label: "Personal Information",
      key: "1",
      children: <UserPersonalInfoUpdate employeeId={lastElement} />,
    },
    {
      label: "Contact details",
      key: "2",
      children: <UserContactInfoUpdate employeeId={lastElement} />,
    },
    {
      label: "Employment",
      key: "3",
      children: (
        <UserResponsibilityandReportInfoUpdate employeeId={lastElement} />
      ),
    },
    {
      label: "Qualification",
      key: "4",
      children: <UserQualifictionAndOtherInfoUpdate employeeId={lastElement} />,
    },
    {
      label: "Bank Details",
      key: "5",
      children: <UserBankInfoUpdate employeeId={lastElement} />,
    },
    {
      label: "Salary Details",
      key: "6",
      children: <UserSalaryDetails employeeId={lastElement} />,
    },
    {
      label: "My Documents",
      key: "7",
      children: <UploadDocument employeeId={lastElement} />,
    },
  ];

  return (
    <div className="table-main-container">
      <Tabs
        defaultActiveKey="1"
        tabPosition={"top"}
        items={items}
        tabBarGutter={8}
        className="antd-tab-custamization edit-employee-tabs-custom"
      />
    </div>
  );
};

export default EmployeeProfileUpdate;
