// import React, { useEffect, useState } from "react";
// import "./index.css";
// import { Col, Row, Select, Form, Input, Button, Checkbox } from "antd";
// import { useSelector } from "react-redux";
// import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
// import { useForm } from "antd/es/form/Form";
// import {
//   fetchAllBranchesByComapnyIdApi,
//   fetchallCompanyByOrgIdApi,
// } from "../../../../../services/Global";
// import { insertSalarySheetTemplateApi } from "../../services";
// import CustomAlert from "../../../../../common/Alert";
// import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

// interface CreateAccount {
//   onCancel: () => void;
// }
// const CreateSalarySheetTemplate: React.FC<CreateAccount> = ({ onCancel }) => {
//   const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
//   const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
//   const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
//   const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
//   const [companySelectLoading, setCompanySelectLoading] =
//     useState<boolean>(false);
//   const [branchSelectLoading, setBranchSelectLoading] =
//     useState<boolean>(false);
//   const [showAlert, setShowAlert] = useState<boolean>(false);
//   const [alertType, setAlertType] = useState<
//     "success" | "info" | "warning" | "error"
//   >("info");
//   const [btnLoading, setBtnLoading] = useState<boolean>(false);
//   const [form] = useForm();
//   const comapnyId = loggedInEmployee?.employee?.company_id;
//   const branchId = loggedInEmployee?.employee?.branch_id;
//   const organizationId = loggedInEmployee?.employee.organization_id;

//   const [optionsState, setOtionState] = useState<any[]>([
//     {
//       label: "Bank Account",
//       value: "bankAccount",
//       customName: "Bank Account",
//       checked: false,
//     },
//     {
//       label: "Bank IBAN",
//       value: "bankIban",
//       customName: "Bank IBAN",
//       checked: false,
//     },
//     {
//       label: "Account Title",
//       value: "employeeName",
//       customName: "Account Title",
//       checked: false,
//     },
//     {
//       label: "Customer Ref No",
//       value: "customerRefNo",
//       customName: "Customer Ref No",
//       checked: false,
//     },
//     {
//       label: "Gross Salary",
//       value: "GrossSalary",
//       customName: "Gross Salary",
//       checked: false,
//     },
//     {
//       label: "Bank Code",
//       value: "bankCode",
//       customName: "Bank Code",
//       checked: false,
//     },
//   ]);

//   useEffect(() => {
//     if (comapnyId) {
//       setSelectedCompanyId(comapnyId);
//       form.setFieldValue("companyId", comapnyId);
//     } else {
//       fetchCompanies();
//     }
//   }, []);

//   useEffect(() => {
//     if (showAlert) {
//       setTimeout(() => {
//         setShowAlert(false);
//       }, 3000);
//     }
//   }, [showAlert]);

//   useEffect(() => {
//     if (branchId) {
//       form.setFieldValue("branchId", branchId);
//     } else {
//       form.setFieldValue("branchId", "");
//       fetchBranches();
//     }
//   }, [selectedCompanyId]);

//   const fetchCompanies = async () => {
//     setCompanySelectLoading(true);
//     const resCompanies = await fetchallCompanyByOrgIdApi(organizationId);
//     if (resCompanies?.data) {
//       setCompanySelectLoading(false);
//       setFilteredCompanies(resCompanies.data);
//       form.setFieldValue("companyId", resCompanies?.data[0]?.id);
//       setSelectedCompanyId(resCompanies?.data[0]?.id);
//     }
//     setCompanySelectLoading(false);
//   };

//   const fetchBranches = async () => {
//     if (selectedCompanyId) {
//       setBranchSelectLoading(true);
//       const resBranches = await fetchAllBranchesByComapnyIdApi(
//         organizationId,
//         selectedCompanyId
//       );
//       if (resBranches?.data) {
//         setBranchSelectLoading(false);
//         setFilteredBranches(resBranches.data);
//         form.setFieldValue("branchId", resBranches?.data[0]?.id);
//       }
//       setBranchSelectLoading(false);
//     }
//   };

//   const handleSubmit = async (values: any) => {
//     const data = {
//       organization_id: organizationId,
//       company_id: values.companyId || comapnyId,
//       branch_id: values.branchId || branchId,
//       name: values.name,
//       type: values.type,
//       options: optionsState,
//     };
//     const response = await insertSalarySheetTemplateApi(data);
//     if (response.status === 201 || response.status === 204) {
//       setBtnLoading(false);
//       setShowAlert(true);
//       setAlertType("success");
//       setTimeout(() => {
//         onCancel();
//       }, 1000);
//     } else {
//       setBtnLoading(false);
//       setShowAlert(true);
//       setAlertType("error");
//     }
//   };

//   const handleTypeChange = (type: string) => {
//     if (type === "FT") {
//       form.setFieldsValue({
//         options: ["customerRefNo", "bankAccount", "GrossSalary"],
//       });
//     } else if (type === "IBFT") {
//       form.setFieldsValue({
//         options: ["customerRefNo", "bankAccount", "bankCode", "GrossSalary"],
//       });
//     }
//   };

//   const handleInputChange = (itemValue: string, newCustomName: string) => {
//     setOtionState(
//       optionsState.map((optionItem) =>
//         optionItem.value === itemValue
//           ? { ...optionItem, customName: newCustomName }
//           : optionItem
//       )
//     );
//   };

//   const onCheckedChange = (itemValue: string) => {
//     setOtionState(
//       optionsState.map((optionItem) =>
//         optionItem.value === itemValue
//           ? { ...optionItem, checked: !optionItem?.checked }
//           : optionItem
//       )
//     );
//   };

//   const onChangeOfType = (typeComing: string) => {
//     if (typeComing === "FT") {
//       const valueToChecked = ["bankAccount", "GrossSalary", "customerRefNo"];
//       setOtionState(
//         optionsState.map((optionItem) => ({
//           ...optionItem,
//           checked: valueToChecked.includes(optionItem.value),
//         }))
//       );
//     } else if (typeComing === "IBFT") {
//       const valueToChecked = [
//         "bankAccount",
//         "GrossSalary",
//         "customerRefNo",
//         "bankCode",
//       ];
//       setOtionState(
//         optionsState.map((optionItem) => ({
//           ...optionItem,
//           checked: valueToChecked.includes(optionItem.value),
//         }))
//       );
//     }
//   };

//   return (
//     <>
//       <Form
//         layout="vertical"
//         autoComplete="off"
//         onFinish={handleSubmit}
//         form={form}
//         onValuesChange={(changedValues) => {
//           if (changedValues.type) {
//             handleTypeChange(changedValues.type);
//           }
//         }}
//       >
//         <Row gutter={16}>
//           {!comapnyId && (
//             <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
//               <Form.Item
//                 name="companyId"
//                 label="Company"
//                 rules={[
//                   {
//                     required: true,
//                     message: "Please select a Company",
//                   },
//                 ]}
//               >
//                 <Select
//                   disabled={comapnyId}
//                   onChange={(value) => setSelectedCompanyId(value)}
//                   placeholder="Select a Company"
//                   allowClear
//                   loading={companySelectLoading}
//                   optionFilterProp="children"
//                   showSearch
//                   filterOption={(input, option: any) =>
//                     option && option.children
//                       ? (option.children as string)
//                           ?.toLowerCase()
//                           ?.indexOf(input.toLowerCase()) >= 0
//                       : false
//                   }
//                   notFoundContent={
//                     <NoDataFoundIcon />
//                   }
//                 >
//                   {filteredCompanies.map((item: any) => (
//                     <Select.Option key={item.id} value={item.id}>
//                       {item.name}
//                     </Select.Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//           )}
//           {!branchId && (
//             <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
//               <Form.Item name="branchId" label="Branch">
//                 <Select
//                   disabled={branchId}
//                   placeholder="Select a Branch"
//                   allowClear
//                   showSearch
//                   filterOption={(input, option: any) =>
//                     option && option.children
//                       ? (option.children as string)
//                           .toLowerCase()
//                           .indexOf(input.toLowerCase()) >= 0
//                       : false
//                   }
//                   loading={branchSelectLoading}
//                   notFoundContent={
//                     <NoDataFoundIcon />
//                   }
//                 >
//                   {filteredBranches.map((item: any) => (
//                     <Select.Option key={item.id} value={item.id}>
//                       {item.name}
//                     </Select.Option>
//                   ))}
//                 </Select>
//               </Form.Item>
//             </Col>
//           )}
//           <Col span={24} md={12}>
//             <Form.Item
//               label="Name"
//               name="name"
//               rules={[{ required: true, message: "Please enter name!" }]}
//             >
//               <Input size="large" placeholder="Enter Sheet Name" />
//             </Form.Item>
//           </Col>
//           <Col span={24} md={12}>
//             <Form.Item
//               name="type"
//               label="Type"
//               rules={[{ required: true, message: "Please select a Type!" }]}
//             >
//               <Select
//                 showSearch
//                 allowClear
//                 filterOption={(input: any, option: any) =>
//                   option && option.children
//                     ? (option.children as string)
//                         .toLowerCase()
//                         .indexOf(input.toLowerCase()) >= 0
//                     : false
//                 }
//                 placeholder="Select Type"
//                 onChange={onChangeOfType}
//                 notFoundContent={
//                   <NoDataFoundIcon />
//                 }
//               >
//                 {["FT", "IBFT"].map((item: any) => (
//                   <Select.Option key={item} value={item}>
//                     {item}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </Form.Item>
//           </Col>

//           <Row gutter={16} className="salary-template-sheet-options-row">
//             {optionsState?.map((item: any) => (
//               <>
//                 <Col span={2}>
//                   <Checkbox
//                     checked={item?.checked}
//                     key={item?.value}
//                     onChange={(e) => onCheckedChange(item.value)}
//                   />
//                 </Col>
//                 <Col span={10} className="salary-template-sheet-options-input">
//                   <Input value={item?.label} disabled />
//                 </Col>
//                 <Col span={12}>
//                   <Form.Item label="Custom Name">
//                     <Input
//                       key={item?.value}
//                       value={item?.customName}
//                       onChange={(e) =>
//                         handleInputChange(item.value, e.target.value)
//                       }
//                     />
//                   </Form.Item>
//                 </Col>{" "}
//               </>
//             ))}
//           </Row>
//           <Col span={24} className="save-profile-btn">
//             <Form.Item>
//               <Button
//                 className="global-btn-width"
//                 type="primary"
//                 htmlType="submit"
//                 loading={btnLoading}
//               >
//                 Save
//               </Button>
//             </Form.Item>
//           </Col>
//         </Row>
//       </Form>
//       {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
//     </>
//   );
// };
// export default CreateSalarySheetTemplate;


import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row, Select, Form, Input, Button, Checkbox, Table } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useForm } from "antd/es/form/Form";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import { insertSalarySheetTemplateApi } from "../../services";
import CustomAlert from "../../../../../common/Alert";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import CustomTable from "../../../../../common/CustomTable";

interface CreateAccount {
  onCancel: () => void;
}

const CreateSalarySheetTemplate: React.FC<CreateAccount> = ({ onCancel }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [form] = useForm();
  const comapnyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const organizationId = loggedInEmployee?.employee.organization_id;

  const [optionsState, setOtionState] = useState<any[]>([
    {
      key: "bankAccount",
      label: "Bank Account",
      value: "bankAccount",
      customName: "Bank Account",
      checked: false,
    },
    {
      key: "bankIban",
      label: "Bank IBAN",
      value: "bankIban",
      customName: "Bank IBAN",
      checked: false,
    },
    {
      key: "employeeName",
      label: "Account Title",
      value: "employeeName",
      customName: "Account Title",
      checked: false,
    },
    {
      key: "customerRefNo",
      label: "Customer Ref No",
      value: "customerRefNo",
      customName: "Customer Ref No",
      checked: false,
    },
    {
      key: "GrossSalary",
      label: "Gross Salary",
      value: "GrossSalary",
      customName: "Gross Salary",
      checked: false,
    },
    {
      key: "bankCode",
      label: "Bank Code",
      value: "bankCode",
      customName: "Bank Code",
      checked: false,
    },
  ]);

  useEffect(() => {
    if (comapnyId) {
      setSelectedCompanyId(comapnyId);
      form.setFieldValue("companyId", comapnyId);
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
    } else {
      form.setFieldValue("branchId", "");
      fetchBranches();
    }
  }, [selectedCompanyId]);

  const fetchCompanies = async () => {
    setCompanySelectLoading(true);
    const resCompanies = await fetchallCompanyByOrgIdApi(organizationId);
    if (resCompanies?.data) {
      setCompanySelectLoading(false);
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const fetchBranches = async () => {
    if (selectedCompanyId) {
      setBranchSelectLoading(true);
      const resBranches = await fetchAllBranchesByComapnyIdApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setBranchSelectLoading(false);
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const data = {
      organization_id: organizationId,
      company_id: values.companyId || comapnyId,
      branch_id: values.branchId || branchId,
      name: values.name,
      type: values.type,
      options: optionsState,
    };
    const response = await insertSalarySheetTemplateApi(data);
    if (response.status === 201 || response.status === 204) {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("success");
      setTimeout(() => {
        onCancel();
      }, 1000);
    } else {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const handleTypeChange = (type: string) => {
    if (type === "FT") {
      form.setFieldsValue({
        options: ["customerRefNo", "bankAccount", "GrossSalary"],
      });
    } else if (type === "IBFT") {
      form.setFieldsValue({
        options: ["customerRefNo", "bankAccount", "bankCode", "GrossSalary"],
      });
    }
  };

  const handleInputChange = (itemValue: string, newCustomName: string) => {
    setOtionState(
      optionsState.map((optionItem) =>
        optionItem.value === itemValue
          ? { ...optionItem, customName: newCustomName }
          : optionItem
      )
    );
  };

  const onCheckedChange = (itemValue: string) => {
    setOtionState(
      optionsState.map((optionItem) =>
        optionItem.value === itemValue
          ? { ...optionItem, checked: !optionItem?.checked }
          : optionItem
      )
    );
  };

  const onChangeOfType = (typeComing: string) => {
    if (typeComing === "FT") {
      const valueToChecked = ["bankAccount", "GrossSalary", "customerRefNo"];
      setOtionState(
        optionsState.map((optionItem) => ({
          ...optionItem,
          checked: valueToChecked.includes(optionItem.value),
        }))
      );
    } else if (typeComing === "IBFT") {
      const valueToChecked = [
        "bankAccount",
        "GrossSalary",
        "customerRefNo",
        "bankCode",
      ];
      setOtionState(
        optionsState.map((optionItem) => ({
          ...optionItem,
          checked: valueToChecked.includes(optionItem.value),
        }))
      );
    }
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "checked",
      render: (checked: boolean, record: any) => (
        <Checkbox
          checked={checked}
          onChange={() => onCheckedChange(record.value)}
        />
      ),
    },
    {
      title: "Label",
      dataIndex: "label",
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: "Custom Name",
      dataIndex: "customName",
      render: (text: string, record: any) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(record.value, e.target.value)}
        />
      ),
    },
  ];
  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        form={form}
        onValuesChange={(changedValues) => {
          if (changedValues.type) {
            handleTypeChange(changedValues.type);
          }
        }}
      >
        <Row gutter={16}>
          {!comapnyId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="companyId"
                label="Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  disabled={comapnyId}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  allowClear
                  loading={companySelectLoading}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!branchId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Branch">
                <Select
                  disabled={branchId}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  loading={branchSelectLoading}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input size="large" placeholder="Enter Sheet Name" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: "Please select a Type!" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Type"
                onChange={onChangeOfType}
                notFoundContent={<NoDataFoundIcon />}
              >
                {["FT", "IBFT"].map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
  
          <Col span={24}>
            <CustomTable
              columns={columns}
              dataSource={optionsState}
              pagination={false}
              rowKey="value"
            />
          </Col>
  
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={btnLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
  };
  export default CreateSalarySheetTemplate;
  
