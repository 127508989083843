export const handleInputChangeHandler = (
  slabs: any[],
  slabId: string,
  field: string,
  value: string | boolean
) => {
  try {
    const updatedSlabs = slabs.map((slab) => {
      if (slab.id === slabId) {
        return { ...slab, [field]: value };
      }
      return slab;
    });
    return updatedSlabs;
  } catch (error) {}
};
