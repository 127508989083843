import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Table,
  Select,
  Space,
  Popconfirm,
  AutoComplete,
} from "antd";
import "./index.css";
import CustomAlert from "../../../../../common/Alert";
import dayjs from "dayjs";
import {
  fetchBankDetailsbyIdApi,
  getRecordAgainstId,
  updateBankDetailsbyIdApi,
} from "../services";
import { validateTextOnly } from "../../../../../common/validation";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { getCountries } from "vk-country-state-city";
import moment from "moment";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { banks } from "../../../../../common/Constants";
import { isValidIBAN } from "ibantools";
import { FormInstance } from "antd/lib";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface EditBankModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedRecordId: number | null;
  fetchData: () => void;
}

const EditBankModal: React.FC<EditBankModalProps> = ({
  visible,
  onCancel,
  selectedRecordId,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [editingForm] = Form.useForm();

  const [tableData, setTableData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>(null);
  const [alertDescription, setAlertDescription] = useState<string>("");

  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );

  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    if (visible && selectedRecordId !== null) {
      fetchBankDetails();
    }
  }, [visible, selectedRecordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
    getExistingDetails();
  }, []);

  const fetchBankDetails = async () => {
    const res = await fetchBankDetailsbyIdApi(selectedRecordId, organizationId);
    if (res.data && res.data.length > 0) {
      const bank = res.data[0];
      form.setFieldsValue({
        employeeId: bank.employee?.full_name,
        country: bank.bank_information?.country,
        bankName: bank.bank_information?.bankName,
        dateEffective: dayjs(bank.bank_information?.dateEffective),
        branchName: bank.bank_information?.branchName,
        branchCode: bank.bank_information?.branchCode,
        accountTitle: bank.bank_information?.accountTitle,
        accountNumber: bank.bank_information?.accountNumber,
      });
    }
  };

  const handleUpdateBank: any = async () => {
    try {
      setButtonLoading(true);
      if (tableData.length > 0) {
        const res = await updateBankDetailsbyIdApi(
          tableData,
          organizationId,
          selectedRecordId
        );

        if (res.error) {
          setshowAlert(true);
          setAlertType("error");
        } else {
          setshowAlert(true);
          setAlertType("success");
          onCancel();
          setTimeout(() => {
            fetchData();
          }, 1000);
        }
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
      setButtonLoading(false);
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      setButtonLoading(false);
    }
  };

  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  const EditableRow: React.FC = ({ ...props }) => {
    return (
      <Form form={editingForm} component={false}>
        <EditableContext.Provider value={editingForm}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell: React.FC<any> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const form = useContext(EditableContext)!;
    const inputNode =
      dataIndex === "dateEffective" ? (
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      ) : (
        <Input size="large" />
      );

    useEffect(() => {
      if (editingKey === record?.id) {
        form.setFieldsValue({
          ...record,
          dateEffective: record?.dateEffective
            ? moment(record.dateEffective)
            : null,
        });
      }
    }, [editingKey, record, form]);

    return (
      <td {...restProps}>
        {editable && editingKey === record?.id ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
              ...(dataIndex === "branchName" || dataIndex === "accountTitle"
                ? [{ validator: validateTextOnly }]
                : []),
              ...(dataIndex === "iban"
                ? [
                    {
                      validator: (_: any, value: any) =>
                        value && isValidIBAN(value)
                          ? Promise.resolve()
                          : Promise.reject(new Error("Invalid IBAN number")),
                    },
                  ]
                : []),
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleSave = async () => {
    try {
      const row = await editingForm.validateFields();
      const newData = [...tableData];
      const index = newData.findIndex((item) => editingKey === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setTableData(newData);
        setEditingKey(null);
      } else {
        newData.push(row);
        setTableData(newData);
        setEditingKey(null);
      }
      //HERE WE CAN USE HANDLE UPDATE METHOD
      const res = await updateBankDetailsbyIdApi(
        newData,
        organizationId,
        selectedRecordId
      );

      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertType("success");
        onCancel();
        setTimeout(() => {
          fetchData();
        }, 1000);
      }
    } catch (errInfo) {}
  };

  const handleDelete = async (record: any) => {
    const updatedList = tableData.filter(
      (item: any) => item.accountNumber !== record.accountNumber
    );
    setTableData(updatedList);
    // Call your API to delete the record
  };

  const edit = (record: any) => {
    if (record) {
      setEditingKey(record.id);
      editingForm.setFieldsValue({
        ...record,
        dateEffective: record.dateEffective
          ? moment(record.dateEffective)
          : null,
      });
    }
  };

  const cancel = () => {
    setEditingKey(null);
    editingForm.resetFields();
  };

  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      width: "20%",
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      editable: true,
    },
    {
      title: "Bank Code",
      dataIndex: "branchCode",
      key: "branchCode",
      editable: true,
    },
    {
      title: "Account Title",
      dataIndex: "accountTitle",
      key: "accountTitle",
      editable: true,
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      editable: true,
    },
    {
      title: "IBAN",
      dataIndex: "iban",
      key: "iban",
      editable: true,
    },
    {
      title: "Effective Up to",
      dataIndex: "dateEffective",
      key: "dateEffective",
      render: (text: any, record: any) => (
        <span>
          {moment(record.dateEffective).format("YYYY-MM-DD").toString()}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => {
        const editable = editingKey === record?.id;
        return editable ? (
          <Space size={"middle"}>
            <SaveOutlined onClick={handleSave} style={{ color: "green" }} />
            <CloseOutlined onClick={cancel} style={{ color: "red" }} />
          </Space>
        ) : (
          <Space size={"middle"}>
            <EditOutlined onClick={() => edit(record)} />
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this record?"
              okText="Ok"
              cancelText="Cancel"
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editingKey === record.id,
      }),
    };
  });

  const getAllData = () => {
    setAlertDescription("");
    const formData = form.getFieldsValue();
    const formDataToBeAdded = {
      id: Date.now(),
      accountNumber: formData.accountNumber,
      accountTitle: formData.accountTitle,
      bankName: formData.bankName,
      branchName: formData.branchName,
      branchCode: formData.branchCode,
      dateEffective: moment(formData.dateEffective),
      country: formData.country,
      iban: formData.iban,
    };
    const accountNumberFromBody = form.getFieldValue("accountNumber");
    const existingTableRecord = tableData.find((record: any) => {
      return record.accountNumber === accountNumberFromBody;
    });
    if (existingTableRecord) {
      setshowAlert(true);
      setAlertDescription("Account Number Already Exists");
      setAlertType("error");
    } else {
      setTableData([...tableData, formDataToBeAdded]);
    }
  };

  const getExistingDetails = async () => {
    const response = await getRecordAgainstId(selectedRecordId, organizationId);
    if (response.data) {
      const bankInformation = Array.isArray(response.data.bank_information)
        ? response.data.bank_information
        : [response.data.bank_information];

      setTableData(bankInformation);

      form.setFieldsValue({
        employeeId: response.data?.employee?.full_name,
      });
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1050}
      title={<span className="global-modal-title">Edit Bank</span>}
      footer={false}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={getAllData}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="employeeId"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Employee Name!",
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Country"
                name={"country"}
                rules={[{ required: true, message: "Select a country" }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select Country"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {countries?.map((item: any) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Enter/Select Bank Name"
                name="bankName"
                rules={[{ required: true, message: "Please Enter Bank Name" }]}
              >
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  placeholder="Select bank..."
                  allowClear
                  filterOption={(inputValue, option) =>
                    option?.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Branch Name"
                name="branchName"
                rules={[
                  {
                    validator: validateTextOnly,
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Branch Name" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Bank Code" name="branchCode">
                <Input size="large" placeholder="Enter Bank Code" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Account Title"
                name="accountTitle"
                rules={[
                  { required: true, message: "Please Enter Account Title" },
                  {
                    validator: validateTextOnly,
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Account Title" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[
                  { required: true, message: "Please Enter Account Number" },
                ]}
              >
                <Input size="large" placeholder="Enter Account Title" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="IBAN"
                name="iban"
                rules={[
                  { required: true, message: "Please Enter IBAN" },
                  {
                    validator: (_, value) =>
                      value && isValidIBAN(value)
                        ? Promise.resolve()
                        : Promise.reject(new Error("Invalid IBAN number")),
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Account Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Effective up to" name="dateEffective">
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="add-record-to-table">
            <Button
              className="global-btn-width"
              type="primary"
              size="large"
              htmlType="submit"
            >
              Add record to table
            </Button>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <CustomTable
                components={{
                  body: {
                    row: EditableRow,
                    cell: EditableCell,
                  },
                }}
                dataSource={tableData}
                pagination={false}
                columns={mergedColumns}
                rowClassName="editable-row"
              />
            </Col>
          </Row>

          <Row className="global-modal-bottom-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  loading={buttonLoading}
                  size="large"
                  onClick={handleUpdateBank}
                  disabled={editingKey ? true : false}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          showAlert={showAlert}
          type={alertType}
          description={alertDescription}
        />
      )}
    </Modal>
  );
};

export default EditBankModal;
