import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  DatePicker,
  Form,
  Select,
  Upload,
} from "antd";
import "./index.css";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  fetchAllEmployeeWithOutCertificaionsApi,
  insertCertificaionsApi,
  insertCertificatesAttachmentsApi,
} from "../services";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
const { Option } = Select;

interface props {
  onCancel: () => void;
}

const CreateCertificationDetails: React.FC<props> = ({ onCancel }) => {
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [branches, setBranches] = useState<any[]>([]);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  /**
   * useEffect hook that calls getAllBranches() when the selectedCompany changes.
   * This hook is used to fetch all branches based on the selected company.
   * @returns None
   */
  useEffect(() => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      getAllBranchess();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * useEffect hook that calls getAllCompanies function when the component mounts.
   * @returns None
   */
  useEffect(() => {
    if (companyId) {
      form.setFieldValue("selectedCompany", companyId);
      setSelectedCompany(companyId);
    } else {
      getAllCompaniess();
    }
  }, []);
  useEffect(() => {
    getAllEmployeesWithoutCertificates();
  }, [selectedCompany, selectedBranch]);

  /**
   * Fetches all companies associated with the logged-in employee's organization asynchronously.
   * Sets loading state to true while fetching data.
   * @returns None
   */
  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await fetchallCompanyByOrgIdApi(
      loggedInEmployee?.employee?.organization_id
    );
    if (response.data) {
      setCompanySelectLoading(false);
      setFetchedCompanies(response.data);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
      setSelectedCompany(response?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const getAllEmployeesWithoutCertificates = async () => {
    const data = {
      organizationId: loggedInEmployee?.employee.organization_id,
      companyId: selectedCompany || loggedInEmployee?.employee.company_id,
      branchId: selectedBranch || loggedInEmployee?.employee.branch_id,
    };

    const response = await fetchAllEmployeeWithOutCertificaionsApi(data);

    if (response) {
      setFilteredEmployees(response);
    }
  };

  /**
   * Asynchronously fetches all branches by company ID and updates the state with the response data.
   * @returns None
   */
  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranches([]);
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      const response = await fetchAllBranchesByComapnyIdApi(
        loggedInEmployee?.employee?.organization_id,
        form.getFieldValue("selectedCompany")
      );
      if (response.data) {
        setBranchSelectLoading(false);
        setBranches(response.data);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  /**
   * Retrieves a list of employees without experience asynchronously and updates the state accordingly.
   * It sets the filtered employees list to an empty array, resets the "employeeid" field value in the form,
   * sets the employee select loading state to true, fetches the list of employees without certifications
   * based on the provided data (organizationId, companyId, branchId), updates the filtered employees list with the response,
   * and finally sets the employee select loading state to false.
   * @returns None
   */

  /**
   * Handles the change event when a file is selected in an input element.
   * It reads the selected file and converts it to base64 format.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event triggered by selecting a file.
   * @returns None
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          setFileBase64(event.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  /**
   * Resets the selected file and its base64 representation to null.
   * @returns None
   */
  const handleFileRemove = () => {
    setFileBase64(null);
  };
  /**
   * Handles the form submission by processing the form values, making an API call to insert certifications,
   * and updating the state accordingly.
   * @param {any} values - The form values containing employee information.
   * @returns None
   */
  const handleSubmit = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    const storageBody = {
      file: values?.attachment?.file,
      filePath: values?.name + values?.employeeid,
    };
    const attachres = await insertCertificatesAttachmentsApi(storageBody);
    const formData = {
      employee_id: values?.employeeid,
      name: values?.name,
      institute_name: values?.instituteName,
      certification_date: values?.certificationDate.format("YYYY-MM-DD"),
      attachment: attachres?.data?.publicUrl,
      organization_id: loggedInEmployee?.employee.organization_id,
      company_id: selectedCompany || companyId || null,
      branch_id: selectedBranch || branchId || null,
    };
    const res = await insertCertificaionsApi(formData);

    setshowAlert(true);
    setButtonLoading(false);
    if (res.error) {
      setAlertType("error");
    } else {
      setAlertType("success");

      form.resetFields();
      setBranches([]);
      setFilteredEmployees([]);
      onCancel();
    }
  };
  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" onFinish={handleSubmit} form={form}>
        <Row gutter={16}>
          {!companyId && (
            <Col span={24} md={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Please select a company..."
                rules={[
                  {
                    required: true,
                    message: "Please choose a company",
                  },
                ]}
              >
                <Select
                  loading={companySelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompany}
                  placeholder="Select a company"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col span={24} md={12}>
              <Form.Item
                name={"selectedBranch"}
                label="Please select a branch..."
              >
                <Select
                  loading={branchSelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedBranch}
                  placeholder="Select a branch"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="employeeid"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                placeholder="Select Employee"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((employee) => (
                  <Option key={employee.value} value={employee.value}>
                    {employee.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Certification"
              name="name"
              rules={[{ required: true, message: "Please Enter Name!" }]}
            >
              <Input size="large" placeholder="Enter Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Institute Name"
              name="instituteName"
              rules={[
                { required: true, message: "Please Enter Institute Name!" },
              ]}
            >
              <Input size="large" placeholder="Enter Institute" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Certification Date"
              name="certificationDate"
              rules={[
                {
                  required: true,
                  message: "Please Enter Certification Date!",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Attachment" name="attachment">
              <Upload
                accept=".pdf, .doc, .docx"
                beforeUpload={(file: any) => {
                  handleFileChange(file);
                  return false;
                }}
                onRemove={() => {
                  handleFileRemove();
                }}
                listType="text"
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </Form>
    </div>
  );
};

export default CreateCertificationDetails;
