import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row, Space } from "antd";
import PersonalDetails from "./PersonalDetails";
import ContactDetails from "./ContactDetails";
import { fetchEmployeesApi } from "../../components/EmployeesManagement/EmployeeViewDetails/services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import Responsibility from "./Responsibility";
import d_dp from "../../assets/images/blankProfile.png";
import BankDetails from "./BankDetails";
import { TriangleLoader } from "../ReactLoader";

interface EmployeeDrawerProps {
  record: any;
}
const EmployeeDrawer: React.FC<EmployeeDrawerProps> = ({ record }) => {
 
  const [activeTab, setActiveTab] = useState("section1");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<any>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchEmployee();
  }, []);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };

  const loading = (state: boolean) => {
    setLoadingState(state);
  };

  const fetchEmployee = async () => {
    setLoadingState(true);
    const employeesRes = await fetchEmployeesApi(
      organizationId,
      record.employee_id
    );
    if (employeesRes.data) {
      setLoadingState(false);
      setEmployeeData(employeesRes.data);
    }
  };

  const handlePreviousClick = () => {
    if (activeTab === "section4") {
      setActiveTab("section3");
    } else if (activeTab === "section3") {
      setActiveTab("section2");
    } else if (activeTab === "section2") {
      setActiveTab("section1");
    } else {
      setActiveTab("section1");
    }
  };

  const handleNextClick = () => {
    if (activeTab === "section1") {
      setActiveTab("section2");
    } else if (activeTab === "section2") {
      setActiveTab("section3");
    } else if (activeTab === "section3") {
      setActiveTab("section4");
    } else {
      setActiveTab("section4");
    }
  };

  return (
    <div className="employee-drawer-container">
      <div className="drawer-header">
        {employeeData && (
          <>
            <div className="drawer-header-image">
              <img
                src={employeeData?.avatar_url || d_dp}
                alt="Employee Avatar"
                className="drawer-image"
              />
            </div>
            <div className="drawer-header-details">
              <div className="name-and-tag-container">
                <span className="drawer-header-name">
                  {employeeData?.full_name || "No Name"}
                </span>
                <div className="drawer-header-tag">
                  <span className="drawer-header-tag-text">
                    {employeeData?.access_right_group?.toUpperCase()}
                  </span>
                </div>
              </div>

              <div className="drawer-text-container">
                {employeeData?.job_title?.name && (
                  <span className="drawer-header-text">
                    {employeeData?.job_title?.name}
                  </span>
                )}
                {employeeData?.work_email && (
                  <span className="drawer-header-text">
                    {employeeData?.work_email}
                  </span>
                )}
                {employeeData?.phone_number && (
                  <span className="drawer-header-text">
                    {employeeData?.phone_number}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="drawer-tab-container">
        <div className="drawer-tabs">
          {activeTab === "section1" ? (
            <button onClick={() => handleTabClick("section1")}>
              Personal Details
            </button>
          ) : (
            <span
              className="in-active-tabs"
              onClick={() => handleTabClick("section1")}
            >
              Personal Details
            </span>
          )}

          {activeTab === "section2" ? (
            <button onClick={() => handleTabClick("section2")}>
              Contact Details
            </button>
          ) : (
            <span
              className="in-active-tabs"
              onClick={() => handleTabClick("section2")}
            >
              Contact Details
            </span>
          )}

          {activeTab === "section3" ? (
            <button onClick={() => handleTabClick("section3")}>
              Employment
            </button>
          ) : (
            <span
              className="in-active-tabs"
              onClick={() => handleTabClick("section3")}
            >
              Employment
            </span>
          )}

          {activeTab === "section4" ? (
            <button onClick={() => handleTabClick("section4")}>
              Bank Details
            </button>
          ) : (
            <span
              className="in-active-tabs"
              onClick={() => handleTabClick("section4")}
            >
              Bank Details
            </span>
          )}
        </div>

        <div className="drawer-tabs-vertical-line"></div>

        <div className="drawer-tab-content">
          {activeTab === "section1" && (
            <div className="drawer-tab-setions">
              <section className="drawer-tab-inner-scroller">
                <PersonalDetails
                  employeeId={record.employee_id}
                  loadingState={(state) => loading(state)}
                />
              </section>
            </div>
          )}
          {activeTab === "section2" && (
            <div className="drawer-tab-setions">
              <section className="drawer-tab-inner-scroller">
                  <ContactDetails
                    employeeId={record.employee_id}
                    loadingState={(state) => loading(state)}
                  />
              </section>
            </div>
          )}
          {activeTab === "section3" && (
            <div className="drawer-tab-setions">
              <section className="drawer-tab-inner-scroller">
                <Responsibility
                  employeeId={record.employee_id}
                  loadingState={(state) => loading(state)}
                />
              </section>
            </div>
          )}
          {activeTab === "section4" && (
            <div className="drawer-tab-setions">
              <section className="drawer-tab-inner-scroller">
                <BankDetails
                  employeeId={record.employee_id}
                  loadingState={(state) => loading(state)}
                />
              </section>
            </div>
          )}
        </div>
      </div>
      <div className="drawer-footer">
        <Row>
          <Col span={24}>
            <Space>
              <button className={activeTab === "section1" ? "footer-dsiabled-btn" : "footer-prev-btn"} onClick={handlePreviousClick} disabled={activeTab === "section1"}>
                Previous
              </button>
              <button className={activeTab === "section4" ? "footer-dsiabled-btn" : "footer-next-btn"} onClick={handleNextClick} disabled={activeTab === "section4"}>
                Next
              </button>
            </Space>
          </Col>
        </Row>
      </div>
      {loadingState && <TriangleLoader />}
    </div>
  );
};

export default EmployeeDrawer;
