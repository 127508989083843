import { Modal, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { EditOutlined } from "@ant-design/icons";
import CustomTable from "../../../../common/CustomTable";
import OverTimeApproveEdit from "../../OverTimeApprove/Edit";
import { fetchOverTimeClaimRequestByEmployeeIdList } from "../services";

interface OverTimeApproveListProps {
  employeeId: string;
}

const OverTimeClaimDetails: React.FC<OverTimeApproveListProps> = ({
  employeeId,
}) => {
  const [tableloader, setTableLoader] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;

  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const organizationId = logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchRequeste();
  }, []);

  const fetchRequeste = async () => {
    setTableLoader(true);

    const data = {
      organizationId: organizationId,
      employeeId: employeeId,
    };
    const res = await fetchOverTimeClaimRequestByEmployeeIdList(data);

    if (res.data) {
      setTableData(res.data);
    }
    setTableLoader(false);
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Hours",
      dataIndex: "total_working_houre",
      key: "total_working_houre",
    },
    {
      title: "Next Requestee",
      dataIndex: "reviewer_id",
      key: "reviewer_id",
      render: (text: any, record: any) => (
        <Tag color="orange"> {record.reviewer_id?.full_name}</Tag>
      ),
    },
    {
      title: "Status",
      key: "actions",
      render: (text: any, record: any) =>
        record.status == "Requested" ? (
          <Space size="middle">
            <span
              className="link"
              onClick={() => {
                setSelectedRecordId(record.id);
                setShowEditModel(true);
              }}
            >
              <EditOutlined />
            </span>
          </Space>
        ) : (
          record.status
        ),
    },
  ];

  const onCancel = async () => {
    setSelectedRecordId("");
    setShowEditModel(false);
  };

  return (
    <>
      <div className="over-time-approve-ListView-card ">
        <CustomTable
          scroll={{ x: true }}
          dataSource={tableData}
          columns={columns}
          loading={tableloader}
        />
      </div>
      {showEditModel && (
        <Modal
          className="overTime-approve-ListView-edit"
          title={"Edit Details"}
          open={showEditModel}
          onCancel={onCancel}
          width={1000}
          footer={false}
        >
          <OverTimeApproveEdit
            onCancel={() => onCancel()}
            recordId={selectedRecordId}
          />
        </Modal>
      )}
    </>
  );
};

export default OverTimeClaimDetails;
