import React from "react";
import PerformanceComponentView from "./view";

const PerformanceModule: React.FC = () => {
  return (
    <div>
      <PerformanceComponentView />
    </div>
  );
};

export default PerformanceModule;
