import { Modal, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { fetchApplicantDetailsApi } from "../services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";

interface ApplicationSubmissionViewModalProps {
  id: number;
  visible: boolean;
  onClose: () => void;
}

const ApplicationSubmissionViewModal: React.FC<
  ApplicationSubmissionViewModalProps
> = ({ id, visible, onClose }) => {
  const [applicantData, setApplicantData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const getApplicantDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await fetchApplicantDetailsApi(
        id,
        organizationId
      );
      if (data) {
        setApplicantData(data);
        setLoading(false);
      }
      if (error) {
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplicantDetails();
  }, [id]);

  return (
    <div>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={false}
        destroyOnClose={true}
      >
        {loading === false && (
          <div className="applicationsubmission-view-modal">
            <Form
              layout="vertical"
              className="applicationsubmission-view-modal-form"
            >
              <Form.Item label={"Name"} required={true}>
                <Input size="large" value={applicantData.name} disabled={true} />
              </Form.Item>
              <Form.Item label={"Email"} required={true}>
                <Input size="large" value={applicantData.email} disabled={true} />
              </Form.Item>
              <Form.Item label={"Phone number"} required={true}>
                <Input size="large" value={applicantData.phone_number} disabled={true} />
              </Form.Item>

              {applicantData?.answer?.map((answer: any) => (
                <>
                  <Form.Item label={answer.label} required={answer.required}>
                    <Input size="large" value={answer.answer} disabled={true} />
                  </Form.Item>
                </>
              ))}
            </Form>
          </div>
        )}
        {loading === true && (
          <Spin
            className="applicationsubmission-view-modal-loader"
            size="large"
          />
        )}
      </Modal>
    </div>
  );
};

export default ApplicationSubmissionViewModal;
