import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import {
  fetchAllEmployeeOvertimeClaim,
  setOnFinishOverTimeClaimRequestApi,
} from "../services";
import dayjs from "dayjs";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const OverTimeApproveCreate = () => {
  const [form] = Form.useForm();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [totalHours, setToatalHours] = useState<string | number>(0);
  const [emplayeeOption, setEmployeeOptions] = useState<any[]>();
  const organizationId = loggedInEmployee?.employee?.organization_id;

  useEffect(() => {
    if (startTime && endTime) {
      calculateTotalHours();
    }
  }, [endTime, startTime]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchAllEmployee();
  }, []);

  const fetchAllEmployee = async () => {
    let res = await fetchAllEmployeeOvertimeClaim(
      organizationId,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id,
      loggedInEmployee?.employee?.Report_to_employee_id
    );

    if (res.data) {
      setEmployeeOptions(res.data);
      form.setFieldValue("checkedBy", res.data[0]?.id);
    }
  };

  const calculateTotalHours = () => {
    const fixedDate = "1970-01-01";
    const start = new Date(`${fixedDate}T${startTime}`).getTime();
    const end = new Date(`${fixedDate}T${endTime}`).getTime();
    if (isNaN(start) || isNaN(end)) {
      setToatalHours(0);
    }
    const diff = end - start;
    if (diff < 0) {
      setToatalHours(0);
    }
    const hours = Number((diff / 3600000).toFixed(1));
    form.setFieldValue("totalHours", hours);
    setToatalHours(hours);
  };

  const onFinish = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    const data = {
      organization_Id: organizationId,
      branch_Id: loggedInEmployee?.employee?.branch_id || null,
      company_Id: loggedInEmployee?.employee?.company_id,
      requested_employee_Id: loggedInEmployee?.employeeId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
      starting_time: startTime,
      end_time: endTime,
      total_working_houre: values.totalHours,
      task_description: values.taskDescription,
      reviewer_id: values.checkedBy,
      status: "Requested",
    };
    const res = await setOnFinishOverTimeClaimRequestApi(data);

    if (res.status == 201 || res.status == 204) {
      form.resetFields();
      setshowAlert(true);
      setAlertType("success");
      setButtonLoading(false);
    } else {
      setshowAlert(true);
      setAlertType("error");
      setButtonLoading(false);
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Employee"
                name="emplayeeName"
                initialValue={loggedInEmployee?.employee?.full_name || ""}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Employee Name",
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="Next Requestee" name="checkedBy">
                <Select
                  placeholder="Select Assigned && checked By"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {emplayeeOption?.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Date",
                  },
                ]}
              >
                <DatePicker picker={"date"} className="overtime-date-picker" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Start Time",
                  },
                ]}
              >
                <TimePicker
                  className="overtime-date-picker"
                  onChange={(timeObject, timestring) => {
                    if (typeof timestring === "string") {
                      setStartTime(timestring);
                    }
                  }}
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[
                  {
                    required: true,
                    message: "Please Enter End Time",
                  },
                ]}
              >
                <TimePicker
                  className="overtime-date-picker"
                  onChange={(timeObject, timestring) => {
                    if (typeof timestring === "string") {
                      setEndTime(timestring);
                    }
                  }}
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                label="Total Hours"
                name="totalHours"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Hours",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Total Hours"
                  disabled
                  value={totalHours}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Task Description"
                name="taskDescription"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Task Description",
                  },
                ]}
              >
                <Input.TextArea placeholder="Task Description......" rows={3} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="global-modal-bottom-row overTime-approve-edit-model-last-row">
            <Col span={24} className="leave-approvel-model-button-row">
              <Button
                key="submit"
                className="global-btn-width"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
                type="primary"
              >
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default OverTimeApproveCreate;
