import { Col, Form, Row } from "antd";
import React from "react";

interface ViewEmployeementTypeModelprops {
  closeModel: () => void;
  id: string;
  data: any;
}
/**
 * Functional component for displaying details of an employment type.
 * @param {ViewEmployeementTypeModelprops} props - The props containing data to display.
 * @returns JSX element displaying employment type details.
 */
const ViewEmploymentTypeModel: React.FC<ViewEmployeementTypeModelprops> = (
  props
) => {
  return (
    <div className="global-card-inner-container">
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={<strong> Employee Name </strong>}>
              <span>{props.data?.employee?.full_name}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<strong> Employment Type </strong>}>
              <span>{props.data.employment_type}</span>
            </Form.Item>
          </Col>
        </Row>
        {(props.data.employment_type == "internship" ||
          props.data.employment_type == "probation" ||
          props.data.employment_type == "contractor") && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={<strong>Starting Date </strong>}>
                <span>{props.data.starting_date}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<strong>Completion Date </strong>}>
                <span>{props.data.completion_date}</span>
              </Form.Item>
            </Col>
          </Row>
        )}
        {props.data.description && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<strong>Description </strong>}>
                <span>{props.data.description}</span>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};
export default ViewEmploymentTypeModel;
