import React from "react";
import { Collapse } from "antd";
import "./index.css";
import CustomTable from "../../../common/CustomTable";

interface Props {
  details: any;
  entityName: string;
  total: number;
  currency: string;
}

const SalaryItemDetails: React.FC<Props> = ({
  details,
  entityName,
  total,
  currency,
}) => {
  const pf_columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Employee Contribution",
      key: "amount",
      dataIndex: "amount",
    },
    {
      title: "Company Contribution",
      key: "amount",
      dataIndex: "amount",
    },
  ];
  const tax_columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "MONTH",
      width: "50%",
    },

    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: "50%",
    },
  ];
  return (
    <div className="global-card-inner-container">
      <CustomTable
        dataSource={details}
        columns={entityName == "Tax" ? tax_columns : pf_columns}
      />
      <div className="salary-detail-view">
        <div>
          <strong>
            {" "}
            {entityName == "PF" &&
              `Employee Contribution:   ${currency} ${total / 2}`}
          </strong>
        </div>
        <div>
          <strong>
            {entityName == "PF" &&
              `Company Contribution:   ${currency}  ${total / 2} `}{" "}
          </strong>
        </div>
        <div>
          <strong>{`Total:    ${currency}  ${total} `} </strong>
        </div>
      </div>
    </div>
  );
};

export default SalaryItemDetails;
