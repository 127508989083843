import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Form, Input, Button, Row, Col, Select, InputNumber } from "antd";
import { ResponseRequestedLeavesApi } from "../services";
import CustomAlert from "../../../../common/Alert";
import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getTheLeaveRequestData } from "../services";
import { Option } from "antd/es/mentions";
import { TriangleLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface LeaveFormModalProps {
  onCancel: () => void;
  selectedRecord: any;
  create?: boolean;
  deletee?: boolean;
  onModalClose: () => void;
}

const LeaveFormModal: React.FC<LeaveFormModalProps> = ({
  onCancel,
  selectedRecord,
  create = true,
  deletee = true,
  onModalClose,
}) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const loggedInEmployeeId = loggedInEmployee?.employeeId;
  const loggedInEmployeeRole = loggedInEmployee?.employeeRole;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [showfullScreenLoader, setShowfullScreenLoader] =
    useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<any>(null);
  //for prority calculation on approve or reject
  const [updatedData, setUpdatedData] = useState<any>(null);
  const [dates, setDates] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [requestStatus, setRequestStatus] = useState<"Rejected" | "Approved">();
  const [form] = Form.useForm();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    form.resetFields();
    setFetchedData(null);
    if (selectedRecord) {
      bringBackResponse(selectedRecord);
    }
  }, [selectedRecord]);

  const bringBackResponse = async (id: string) => {
    setShowfullScreenLoader(true);
    const response = await getTheLeaveRequestData(id, organizationId);
    if (response && response.data) {
      setDates(response?.data?.leave_dates);
      setFetchedData(response?.data);
      form.setFieldsValue({
        leavetype: response?.data?.leave_type,
        count: response?.data?.leave_count,
        leavePeriod: response?.data?.leave_dates,
        reason: response?.data?.reason_for_leave || "N/A",
        comments: response?.data?.reviewer_comment || "N/A",
      });
      setShowfullScreenLoader(false);
    }
  };

  const onFinish = async (values: any) => {
    setShowfullScreenLoader(true);

    let leaveStatusValue;

    if (updatedData?.leave_approval_employee_ids) {
      const hasHighPriorityApproved =
        updatedData?.leave_approval_employee_ids.some(
          (item: any) => item.priority === true && item.isApproved === true
        );
      const hasHighPriorityReject =
        updatedData?.leave_approval_employee_ids.some(
          (item: any) => item.priority === true && item.isApproved === false
        );

      if (hasHighPriorityApproved) {
        leaveStatusValue = "Approved";
      } else if (hasHighPriorityReject) {
        leaveStatusValue = "Rejected";
      } else {
        const lowPriorityItems =
          updatedData?.leave_approval_employee_ids.filter(
            (item: any) => item.priority === false
          );

        const totalLowPriority = lowPriorityItems.length;

        const approvedLowPriority = lowPriorityItems.filter(
          (item: any) => item.isApproved
        ).length;

        if (totalLowPriority > 0) {
          const approvalRate = (approvedLowPriority / totalLowPriority) * 100;
          if (approvalRate >= 50) {
            leaveStatusValue = "Approved";
          }
        }
      }
    }
    let body: any = {
      leave_status: leaveStatusValue ? leaveStatusValue : "Requested",
      leave_approval_employee_ids: updatedData?.leave_approval_employee_ids,
    };

    if (requestStatus == "Rejected") {
      body["reviewer_comment"] = values.comments;
    } else {
      body["reviewer_comment"] = values.comments;
    }
    const res = await ResponseRequestedLeavesApi(
      body,
      selectedRecord,
      organizationId
    );
    setShowfullScreenLoader(false);

    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    } else if (res.status == 201 || res.status == 204) {
      const sendNotificationBody = {
        type: requestStatus == "Approved" ? "leaveApproval" : "leaveRejection",
        senderId: loggedInEmployee?.employeeId || null,
        Receiver: fetchedData.employeeId,
        name: loggedInEmployee?.employee?.firstName,
        avatar: loggedInEmployee?.employee?.avatarUrl,
      };
      await pushNotifictionIntoNotificationTable(
        sendNotificationBody,
        organizationId
      );
      setshowAlert(true);
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      form.resetFields();
      onModalClose();
      onCancel();
    }
  };

  const updateFetchedData = (status: any) => {
    setRequestStatus(status);
    if (fetchedData.leave_approval_employee_ids) {
      const updatedFilteredData = fetchedData.leave_approval_employee_ids?.map(
        (item: any) => {
          const idToCompare =
            loggedInEmployeeRole === "admin"
              ? "admin"
              : loggedInEmployeeRole === "hr"
              ? "hr"
              : loggedInEmployeeId;

          if (item.employeeID === idToCompare) {
            return { ...item, isApproved: status === "Approved" };
          }
          return item;
        }
      );
      setUpdatedData({
        ...fetchedData,
        leave_approval_employee_ids: updatedFilteredData,
      });
    } else {
      setUpdatedData(fetchedData);
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          name="view-leave"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="leavetype"
                label="Leave Type"
                rules={[
                  { required: true, message: "Please select leave type" },
                ]}
              >
                <Input size="large" placeholder="Enter Leave Type" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="count"
                label="Count"
                rules={[{ required: true, message: "Please enter count" }]}
              >
                <InputNumber
                  size="large"
                  className="global-input-number-width"
                  placeholder="Enter Count"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Leave Period" name="leavePeriod">
                <Select
                  mode="tags"
                  defaultValue={dates}
                  disabled
                  className="leave-approval-select-date-form"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {dates?.map((date: any) => (
                    <Option key={date} value={date}>
                      {date}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="reason" label="Reason">
                <Input.TextArea
                  rows={4}
                  placeholder="Enter Reason..."
                  disabled={
                    fetchedData?.leave_status === "Approved" ||
                    fetchedData?.leave_status === "Requested" ||
                    fetchedData?.leave_status === "Rejected"
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="comments" label="Comments">
                <Input.TextArea
                  key={fetchedData?.id}
                  rows={4}
                  placeholder="Enter Comment..."
                  disabled={
                    fetchedData?.leave_status === "Approved" ||
                    fetchedData?.leave_status === "Rejected"
                  }
                  value={fetchedData?.reviewer_comment || "N/A"}
                />
              </Form.Item>
            </Col>
          </Row>
          {fetchedData?.leave_status == "Requested" && (
            <Row gutter={16} className="global-modal-bottom-row">
              <Col span={24} className="leave-approvel-model-button-row">
                {create && (
                  <Button
                    key="reject"
                    htmlType="submit"
                    onClick={() => updateFetchedData("Rejected")}
                    className="global-btn-width leave-approvel-model-reject-button"
                    size="large"
                    type="default"
                  >
                    Reject
                  </Button>
                )}
                {create && (
                  <Button
                    key="submit"
                    htmlType="submit"
                    onClick={() => updateFetchedData("Approved")}
                    className="global-btn-width"
                    size="large"
                    type="primary"
                  >
                    Approved
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </Form>
      </div>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      {showfullScreenLoader && <TriangleLoader />}
    </>
  );
};

export default LeaveFormModal;
