import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import TabsHeader from "../../../common/TabsHeader";
import { Card, Empty, Modal } from "antd";
import EmployeeList from "../EmployeesListView";
import SkillList from "../EmployeeData/Skill/View";
import CertificationsListView from "../EmployeeData/Certifications/View";
import ContactsListView from "../EmployeeData/Contacts/View";
import Experiences from "../EmployeeData/Experiences/View";
import Qualifications from "../EmployeeData/Qualifications/View";
import Relatives from "../EmployeeData/Relatives/View";
import BanksListView from "../EmployeeData/Banks/View";
import CreatContactDetails from "../EmployeeData/Addresses/Create";
import SkillCreateForm from "../EmployeeData/Skill/Create";
import CreateCertificationDetails from "../EmployeeData/Certifications/Create";
import EmployeeForm from "../EmployeeData/Contacts/Create";
import CreateExperienceDetails from "../EmployeeData/Experiences/Create";
import CreateQualificationDetails from "../EmployeeData/Qualifications/Create";
import CreateRelativeDetails from "../EmployeeData/Relatives/Create";
import CreateBankDetails from "../EmployeeData/Banks/Create";
import { useDispatch, useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useNavigate } from "react-router-dom";
import AddressListView from "../EmployeeData/Addresses/View";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../redux/AccessRight";
import * as XLSX from "xlsx";
import supabase from "../../../services/supabase";
import CustomAlert from "../../../common/Alert";
import { selectOrganizationModulePermission } from "../../../../redux/organizationModulePermission";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../../redux/MenuBreadcrumbSlice";

const Employees: React.FC = () => {
  const accessObj = useSelector(selectAccess);
  const dispatch = useDispatch();
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [selected, setSelected] = useState("Employee");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const navigate = useNavigate();
  const logedInemployee = useSelector(selectLogedEmployee);

  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.["Employee"];

  const radioOptions = useMemo(
    () => [
      {
        label: "Employee",
        value: "Employee",
        create: true,
        filter: false,
        showSearch: false,
        showDownloadBtn: true,
        showUploadDataBtn: true,
      },
      {
        label: "Addresses",
        value: "Addresses",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Skills",
        value: "Skills",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Certifications",
        value: "Certifications",
        create: true,
        filter: true,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Contacts",
        value: "Contacts",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Experiences",
        value: "Experiences",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Qualifications",
        value: "Qualifications",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Relatives",
        value: "Relatives",
        create: true,
        filter: true,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
      {
        label: "Banks",
        value: "Banks",
        create: true,
        filter: false,
        showSearch: true,
        showDownloadBtn: false,
        showUploadDataBtn: false,
      },
    ],
    []
  );

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "employee"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
  }, [filteredRadioOptions]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "employee",
      child: selected,
    };
    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
    setSearchValue("");
  }, [selected, accessObj]);

  useEffect(() => {
    setSearchValue("");
  }, [selected]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Employee";
    let childValue =
      value == "Employee"
        ? "Company Employee"
        : value == "Addresses"
        ? "Addresses"
        : value == "Skills"
        ? "Skills"
        : value == "Certifications"
        ? "Certificates"
        : value == "Contacts"
        ? "Contacts"
        : value == "Experiences"
        ? "Experiences"
        : value == "Qualifications"
        ? "Qualifications"
        : value == "Relatives"
        ? "Relatives"
        : "Banks";
    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };

  const templateDownloadHandler = async () => {
    try {
      const { data, error } = await supabase.rpc(
        "get_organization_module_data",
        { organization_id: `organization_${organizationId}` }
      );

      if (error) {
        setAlertType("error");
        setshowAlert(true);
      } else {
        exportOrganizationDataToExcel(data);
      }
      const fileUrl =
        "https://api-sub.misgenie.app/storage/v1/object/public/Template%20Data/employee_template.xlsx";
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "employee_template.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);

      setAlertType("success");
      setshowAlert(true);
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  const handleUploadEmployeeData = async (e: any) => {
    try {
      const file = e.target.files[0];
      const fileData = await file.arrayBuffer();
      const workbook = XLSX.read(fileData);
      const transformedData: any = {};

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
        });
        const headers: any = jsonData[0];
        const rows = jsonData.slice(1);

        const result = rows.map((row: any) => {
          let obj: any = {};
          headers.forEach((header: any, index: any) => {
            obj[header] = row[index];
          });
          return obj;
        });

        transformedData[sheetName] = result;
      });
      const orgParam = `organization_${organizationId}`;

      const { data, error } = await supabase.rpc("insert_employee_sheet_data", {
        organization_id: orgParam,
        sheet_data: transformedData,
      });

      if (error) {
        setAlertType("error");
        setshowAlert(true);
      } else {
        setAlertType("success");
        setshowAlert(true);
        downloadEmployeeExcelData(data);
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  const handleUploadOtherData = async (e: any) => {
    try {
      const file = e.target.files[0];
      const fileData = await file.arrayBuffer();
      const workbook = XLSX.read(fileData);
      const transformedData: any = {};

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
        });
        const headers: any = jsonData[0];
        const rows = jsonData.slice(1);

        const result = rows.map((row: any) => {
          let obj: any = {};
          headers.forEach((header: any, index: any) => {
            obj[header] = row[index];
          });
          return obj;
        });

        transformedData[sheetName] = result;
      });
      const orgParam = `organization_${organizationId}`;

      const { error } = await supabase.rpc("insert_remaining_sheet_data", {
        organization_id: orgParam,
        sheet_data: transformedData,
      });

      if (error) {
        setAlertType("error");
        setshowAlert(true);
      } else {
        setAlertType("success");
        setshowAlert(true);
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  const downloadEmployeeExcelData = (data: any) => {
    const columnNames = Object.keys(data[0]);

    const dataArray = [columnNames];

    data.forEach((row: any) => {
      const values = columnNames.map((columnName) => row[columnName]);
      dataArray.push(values);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(dataArray);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Employee");

    XLSX.writeFile(workbook, "Employee.xlsx");
  };

  const exportOrganizationDataToExcel = (data: any) => {
    const workbook = XLSX.utils.book_new();

    Object.keys(data).forEach((sheetName) => {
      const sheetData = data[sheetName];

      const worksheetData = [Object.keys(sheetData[0] || {})];
      sheetData.forEach((item: any) => {
        worksheetData.push(Object.values(item));
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    XLSX.writeFile(workbook, "Organization_data.xlsx");
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={filteredRadioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("")
            }}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            templateDownloadHandler={templateDownloadHandler}
            handleUploadOtherData={handleUploadOtherData}
            handleUploadEmployeeData={handleUploadEmployeeData}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            searchValue={searchValue}
            selected={selected}
          />

          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Employee" ? (
                <EmployeeList />
              ) : selected === "Addresses" ? (
                <AddressListView
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Skills" ? (
                <SkillList searchValue={searchValue} />
              ) : selected === "Certifications" ? (
                <CertificationsListView
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Contacts" ? (
                <ContactsListView searchValue={searchValue} />
              ) : selected === "Experiences" ? (
                <Experiences
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Qualifications" ? (
                <Qualifications
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Relatives" ? (
                <Relatives
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : (
                <BanksListView
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              )
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <div key={fetchModalData}>
            <Modal
              title={
                <span className="global-modal-title">Create {selected}</span>
              }
              open={createModalVisible}
              onCancel={handleCreateModalCancel}
              width={1000}
              footer={false}
              className="global-modal-main-container"
            >
              {(createModalVisible &&
                selected === "Employee" &&
                navigate(
                  `/create/${logedInemployee.logedInemployee?.employeeRole}/employees`
                )) ||
                null}

              {createModalVisible && selected === "Addresses" && (
                <CreatContactDetails onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Skills" && (
                <SkillCreateForm onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Certifications" && (
                <CreateCertificationDetails
                  onCancel={handleCreateModalCancel}
                />
              )}
              {createModalVisible && selected === "Contacts" && (
                <EmployeeForm onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Experiences" && (
                <CreateExperienceDetails onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Qualifications" && (
                <CreateQualificationDetails
                  onCancel={handleCreateModalCancel}
                />
              )}
              {createModalVisible && selected === "Relatives" && (
                <CreateRelativeDetails onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Banks" && (
                <CreateBankDetails onCancel={handleCreateModalCancel} />
              )}
            </Modal>
          </div>
        </Card>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default Employees;
