import React, { useEffect, useState } from "react";
import { Space, Popconfirm, TablePaginationConfig, Empty, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import CustomAlert from "../../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import EditCertificate from "../Edit";
import {
  deleteCertificaionsByIdApi,
  fetchCertificaionsDetailsApi,
} from "../services";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";

import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../../common/CustomTable";
import CertificationFilterModal from "../Filter";

interface Certificate {
  id: string;
  employeeId: string;
  name: string;
  instituteName: string;
  certificationDate: string;
  country: string;
  city: string;
  attachment: string;
  Employees: string | any;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface CertificationsListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const CertificationsListView: React.FC<CertificationsListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Employee",
    "Certificates"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  /**
   * useEffect hook that fetches certificates when the component mounts.
   * @returns None
   */
  useEffect(() => {
    fetchCertificates();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setCertificates(originalData.apiData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * Fetches certificates data from the API based on the logged-in employee's organization, company, branch, and search query.
   * Updates the table parameters and certificates state accordingly.
   * @returns None
   */
  const fetchCertificates = async () => {
    setLoading(true);
    setSearchQuery("");
    const data = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      searchInput: searchQuery,
    };
    const res = await fetchCertificaionsDetailsApi(data);

    if (res.data) {
      const updatedData = res.data.filter((item: any) => item.employee);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: certificates.length,
        },
      });
      setCertificates(updatedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
    }
    setLoading(false);
  };

  /**
   * Handles the editing of a certificate by setting the selected record ID to the ID of the certificate
   * and making the edit modal visible.
   * @param {Certificate} certificate - The certificate object to edit
   * @returns None
   */
  const handleEditCertificate = (certificate: any) => {
    setSelectedRecordId(certificate.id);
    setEditModalVisible(true);
  };

  /**
   * Handles the cancel action for the edit modal.
   * This function sets the edit modal visibility to false, resets the selected record ID to null,
   * and fetches the certificates data.
   * @returns None
   */
  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    setSelectedRecordId(null);
    fetchCertificates();
  };
  /**
   * Handles the deletion of a certificate by its ID.
   * @param {string} certificateId - The ID of the certificate to be deleted.
   * @returns None
   */
  const handleDeleteCertificate = async (certificateId: string) => {
    setshowAlert(false);
    const res = await deleteCertificaionsByIdApi(
      certificateId,
      loggedInEmployee?.employee.organization_id
    );
    setshowAlert(true);
    if (res.error) {
      setAlertType("error");
    } else {
      setAlertType("success");
    }
  };
  /**
   * Handles the change in table pagination or sorting and updates the table parameters accordingly.
   * @param {TablePaginationConfig} pagination - The pagination configuration for the table.
   * @param {any} sorter - The sorting configuration for the table.
   * @returns {void}
   */
  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  /**
   * An array of column objects used for displaying employee data in a table.
   * @type {Array}
   */
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = (a.employee && a.employee.full_name) || "";
        const nameB = (b.employee && b.employee.full_name) || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],

      render: (Employees: any) => Employees?.full_name,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Institute Name",
      dataIndex: "institute_name",
      key: "instituteName",
    },
    {
      title: "Certification Date",
      dataIndex: "certification_date",
      key: "certificationDate",
    },

    {
      title: "Actions",
      key: "actions",
      render: (text: any, certificate: any) => (
        <Space>
          {edit && (
            <EditOutlined onClick={() => handleEditCertificate(certificate)} />
          )}

          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this certificate?"
            onConfirm={() => handleDeleteCertificate(certificate.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && <DeleteOutlined />}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setCertificates(filterData);
    setLoading(false);
  };

  const getFilterDataHandler = (certificateName: string) => {
    const filterArray = [{ path: "name", value: certificateName }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setCertificates(response);
    }
  };

  return (
    <div>
      <div className="certification-table">
        {read ? (
          <CustomTable
            dataSource={certificates}
            columns={columns}
            loading={loading}
            rowKey="id"
            onChange={handleTableChange}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}

        <Modal
          className="global-modal-main-container"
          title={<span className="global-modal-title">Filter</span>}
          open={openFilterModal}
          footer={false}
          onCancel={toggleFilterModal}
        >
          <CertificationFilterModal
            getFilterDataHandler={(certificateName) =>
              getFilterDataHandler(certificateName)
            }
          />
        </Modal>
      </div>

      {editModalVisible && (
        <EditCertificate
          visible={editModalVisible}
          onCancel={handleEditModalCancel}
          certificateId={selectedRecordId}
        />
      )}

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CertificationsListView;
