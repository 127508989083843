import { catch_return } from "../../../common/Constants";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

export const fetchCompanies = async (organizationId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name")
      .eq("organization_Id", organizationId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchTheNumberOfAbsentees = async (Ids: any) => {
  let employeeQuery = supabase_client_for_Org_schema(Ids.organizationId)
    .from("employee")
    .select("id")
    .eq("organization_id", Ids.organizationId)
    .ilike("status", "active");

  if (Ids.companyId) {
    employeeQuery = employeeQuery.eq("company_id", Ids.companyId);
  }
  if (Ids.branchId) {
    employeeQuery = employeeQuery.eq("branch_id", Ids.branchId);
  }

  const employeeResponse = await employeeQuery;
  if (employeeResponse.data) {
    const employeeIds = employeeResponse.data.map((employee) => employee.id);

    const today = new Date().toISOString().split("T")[0];
    const attendanceQuery = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee_attendance")
      .select("employee_id, created_at")
      .eq("organization_id", Ids.organizationId)
      .gte("created_at", `${today}T00:00:00.000Z`)
      .lte("created_at", `${today}T23:59:59.999Z`);

    const attendanceResponse = await attendanceQuery;
    if (attendanceResponse.data) {
      const absentEmployees = employeeIds.filter(
        (id) =>
          !attendanceResponse.data.some(
            (attendance) =>
              attendance.employee_id === id &&
              attendance.created_at.startsWith(today)
          )
      );

      return {
        absentEmployees: absentEmployees,
        employeeResponse: employeeResponse,
      };
    }
  }
};

export const fetchTodayOnLeavesEmployeeApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0];

    const query = supabase_client_for_Org_schema(orgid)
      .from("employee_leave_request")
      .select(`employee_id`)
      .eq("organization_id", orgid)
      .eq("leave_status", "Approved")
      .lte("starting_date", formattedToday)
      .gte("ending_date", formattedToday);

    if (companyId) {
      query.eq("company_id", companyId);
    }
    if (branchId) {
      query.eq("branch_id", branchId);
    }
    const res = await query;
    if (res.data) {
      const TodayActiveEmployeeres = await fetchTodayActiveEmployeeApi(
        orgid,
        companyId,
        branchId
      );
      if (TodayActiveEmployeeres?.data) {
        res.data = res.data.filter((item: any) => {
          return !TodayActiveEmployeeres.data.some(
            (activeItem: any) => activeItem.employee_id === item.employee_id
          );
        });
      }
    }

    return res.data;
  } catch {
    return catch_return;
  }
};

export const fetchTodayActiveEmployeeApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0];
    const query = supabase_client_for_Org_schema(orgid)
      .from("employee_attendance")
      .select(`employee_id`)
      .eq("organization_id", orgid)
      .gte("check_in", formattedToday + "T00:00:00")
      .lte("check_in", formattedToday + "T23:59:59");
    if (companyId) {
      query.eq("company_id", companyId);
    }
    if (branchId) {
      query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const checkTotalEmployeeApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("employee")
      .select("id")
      .eq("organization_id", orgid)
      .ilike("status", "active");
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchBranches = async (organizationId: string): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name,company_id ")
      .eq("organization_Id", organizationId);

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const checkTimePolicyExistApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("timing_policy")
      .select("organization_id")
      .eq("organization_id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const checkabsentPolicyExistApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("absent_policy")
      .select("organization_id")
      .eq("organization_id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
export const checkleavePolicyExistApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("leave_policy")
      .select("organization_id")
      .eq("organization_id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const checkReamingPolicySet = async (data: any) => {
  try {
    const orgid = data.orgid;
    const cId = data.companyId;
    const bId = data.branchId;
    let res: { data: { name: string; link: string }[] } = { data: [] };

    const resTimePolicyExist = await checkTimePolicyExistApi(orgid, cId, bId);
    if (resTimePolicyExist.data.length == 0) {
      res?.data.push({
        name: "Timing Policy",
        link: "/policies/timing",
      });
    }
    const resabsentPolicyExist = await checkabsentPolicyExistApi(
      orgid,
      cId,
      bId
    );
    if (resabsentPolicyExist.data.length == 0) {
      res.data.push({
        name: "Absent Policy",
        link: "/policies/absent",
      });
    }
    const resleavePolicyExist = await checkleavePolicyExistApi(orgid, cId, bId);
    if (resleavePolicyExist.data.length == 0) {
      res.data.push({
        name: "Leaves Policy",
        link: "/policies/leaves",
      });
    }
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchTimingPolicyDataApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("timing_policy")
      .select("working_days")
      .eq("organization_id", orgid)
      .order("created_at", { ascending: false })
      .limit(1);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
