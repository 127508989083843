import React, { useState } from "react";
import "./index.css";
import HelpQuries from "../Quries";
import { Typography, Input } from "antd";

const Help: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <>
      <div className="help-section1">
        <Typography className="help-text"> We are here to Help </Typography>
        <div className="help-container">
          <Input
            size="large"
            className="nosubmit-help"
            type="search"
            placeholder="Search..."
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      <HelpQuries searchQuery={searchQuery} />
    </>
  );
};

export default Help;
