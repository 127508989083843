import React, { useEffect, useState } from "react";
import CustomTable from "../../../../common/CustomTable";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { deleteCompanyAccountAPI, getCompanyAccountLisytAPI } from "../../services";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import UpdateCompanyAccountDetails from "../Edit";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { Popconfirm, Space } from "antd";

const CompanyAccountList = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [isEditmodalVisisble, setIsEditmodalVisisble] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<number | null>(null);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Payroll", "Accounts");

  const edit = access?.writeAccess;

  useEffect(() => {
    getCompanyAccountsList();
  }, []);

  const getCompanyAccountsList = async () => {
    try {
      setTableLoading(true);
      const respone = await getCompanyAccountLisytAPI(organizationId);
      if (respone.data) {
        setTableData(respone.data);
      }
    
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
    }
  };

  const deleteAccount = async (accountId: number) => {
    try {
      setTableLoading(true);
  
      const response = await deleteCompanyAccountAPI(organizationId, accountId);
      if (!response.error) {
       
       
       
        setTableData(prevData => prevData.filter(item => item.id !== accountId));
      }
  
    } catch (error) {
      console.error('Error deleting account:', error);
    } finally {
      setTableLoading(false);
    }
  };

  const showCompanyAccountEditModal = (id: number) => {
    setIsEditmodalVisisble(true);
    setSelectedRecord(id);
  };
  const handleCancel = () => {
    setIsEditmodalVisisble(false);
    setSelectedRecord(null);
    getCompanyAccountsList();
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Account Title",
      dataIndex: "account_title",
      key: "account_title",
    },

    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) =>
        edit && (
          <Space size="middle">
          <a onClick={() => showCompanyAccountEditModal(record.id)}>
            <EditOutlined />
          </a>
           <Popconfirm
           placement="topLeft"
           okText="Ok"
           cancelText="Cancel"
           title="Are you sure you want to remove this record?"
           onConfirm={() => deleteAccount(record.id)}
         >
           <DeleteOutlined />
         </Popconfirm>
         </Space>
        ),
    },
  ];
  return (
    <div>
      <CustomTable
        columns={columns}
        dataSource={tableData}
        loading={tableLoading}
      />
      {isEditmodalVisisble && (
        <UpdateCompanyAccountDetails
          id={selectedRecord}
          isEditmodalVisisble={isEditmodalVisisble}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default CompanyAccountList;
