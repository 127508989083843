import moment from "moment";
import { catch_return } from "../../../common/Constants";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";
import dayjs from "dayjs";

//Gets all the necessary data
export const getAllData = async (
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_timing_policies")
      .select("*")
      .eq("employee_id", employeeId)
      .maybeSingle();

    return response;
  } catch (error) {
    return error;
  }
};

//Handles all the necessary logic to perform an Employee Checkin
export const checkIn = async (payload: any, timezone: any): Promise<any> => {
  try {
    const formatDateToISO = (date: any, timezone: any) => {
      return new Date(date).toLocaleString("sv-SE", { timeZone: timezone });
    };

    const getStartOfDay = (timezone: any) => {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      return formatDateToISO(startOfDay, timezone);
    };

    const getEndOfDay = (timezone: any) => {
      const now = new Date();
      const endOfDay = new Date(now.setHours(23, 59, 59, 999));
      return formatDateToISO(endOfDay, timezone);
    };

    const startOfCurrentDay = getStartOfDay(timezone);
    const endOfCurrentDay = getEndOfDay(timezone);

    const responseCheckIn = await supabase_client_for_Org_schema(
      payload.organizationId
    )
      .from("employee_attendance")
      .select("id, check_out")
      .eq("employee_id", payload.employeeId)
      .gte("check_in", startOfCurrentDay)
      .lte("check_in", endOfCurrentDay);

    if (responseCheckIn.error) {
      return responseCheckIn.error;
    }

    if (responseCheckIn.data.length > 0) {
      return false;
    }

    const formattedPunchIn = formatDateToISO(
      payload.currentTimeStamp,
      timezone
    );

    const dataToBeInserted = {
      check_in: formattedPunchIn,
      employee_id: payload.employeeId,
      organization_id: payload.organizationId,
      company_id: payload.companyId,
      branch_id: payload.branchId,
      date: payload.date,
      fetch_from: "Portal",
    };

    const response = await supabase_client_for_Org_schema(
      payload.organizationId
    )
      .from("employee_attendance")
      .insert(dataToBeInserted)
      .select("id")
      .maybeSingle();

    if (response.error) {
      return response.error;
    }
    return response;
  } catch (error) {
    return error;
  }
};

//Gets the necessary details pf an Employee to perform various operations including checkout, breakin and breakout
export const getTheCheckInTimeForToday = async (
  employeeId: any,
  timezone: any,
  organizationId: string
): Promise<any> => {
  try {
    const formatDateToISO = (date: any, timezone: any) => {
      return new Date(date).toLocaleString("sv-SE", { timeZone: timezone });
    };
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const formattedTodayStartTimestamp = formatDateToISO(todayStart, timezone);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    const formattedTodayEndTimestamp = formatDateToISO(todayEnd, timezone);
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("check_in, id, short_working_hours, break_in, break_out")
      .eq("employee_id", employeeId)
      .order("created_at", { ascending: false })
      .gte("check_in", formattedTodayStartTimestamp)
      .lte("check_in", formattedTodayEndTimestamp)
      .limit(1);

    return response;
  } catch (error) {
    return error;
  }
};

//Performs all the calculations required to handle the employee checkout
export const checkOut = async (
  data: any,
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .update({
        check_out: data.checkoutTime,
      })
      .eq("employee_id", employeeId)
      .eq("id", data.recordId);
    return response;
  } catch (error) {
    return error;
  }
};

export const breakIn = async (
  id: string | any,
  organizationId: any
): Promise<any> => {
  try {
    const checkIfDataIsAvailable = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_attendance")
      .select("break_in")
      .eq("id", id);
    return checkIfDataIsAvailable;
  } catch (error) {
    return error;
  }
};

export const breakInUpdate = async (
  data: any,
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const updateBreakIn = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .update({ break_in: data })
      .eq("id", id);

    return updateBreakIn;
  } catch (error) {
    return error;
  }
};

export const getTodaysCheckoutResponse = async (
  organizationId: any,
  employeeId: any,
  timezone: any
) => {
  try {
    const todayStart = moment
      .tz(timezone || "Asia/Karachi")
      .startOf("day")
      .format();
    const todayEnd = moment
      .tz(timezone || "Asia/Karachi")
      .endOf("day")
      .format();

    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("check_out")
      .eq("employee_id", employeeId)
      .gte("check_out", todayStart)
      .lte("check_out", todayEnd);

    if (response?.data && response.data.length > 0) {
      return response.data[0].check_out;
    }

    return null;
  } catch (error) {
    return error;
  }
};

export const breakOutUpdate = async (
  data: any,
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const updateBreakIn = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .update({ break_out: data })
      .eq("id", id);

    return updateBreakIn;
  } catch (error) {
    return error;
  }
};

export const getTheWorkedHours = async (
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("worked_hours")
      .eq("employee_id", employeeId)
      .order("created_at", { ascending: false })
      .limit(1);
    return response;
  } catch (error) {
    return error;
  }
};

/// today checkin exist or not

export const fetchTodayCheckInApi = async (
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    // Get the current date and time
    const now = new Date();

    // Calculate the time 24 hours ago
    const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);

    // Format the dates to 'YYYY-MM-DD HH:mm:ss' format
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const nowFormatted = formatDate(now);
    const last24HoursFormatted = formatDate(last24Hours);
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("*")
      .eq("employee_id", employeeId)
      .gte("check_in", last24HoursFormatted)
      .lte("check_in", nowFormatted);

    return response;
  } catch (error) {
    return error;
  }
};

export const insertAttendancePunchApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organization_id)
      .from("attendance_punch")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
