import { notificationDetailsList } from "../../common/NoficationStaticDetails";
import { fetchOrgDomain } from "../../components/Recruitment/services";
import supabase, {
  supabase_client_for_operation_portal_schema,
  supabase_client_for_Org_schema,
  supabase_client_for_private_schema,
} from "../supabase";
const reactAppBaseURL = process.env.REACT_APP_BASE_URL;
const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
/**
 * Fetches all companies by organization ID from the Supabase database.
 * @param {any} orgid - The organization ID to filter the companies by.
 * @returns {Promise<any>} A promise that resolves to the result of the query.
 */
export const fetchallCompanyByOrgIdApi = async (
  orgid: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("company")
      .select(`id, name , email`)
      .eq("organization_Id", orgid);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Fetches all branches by company ID from the database.
 * @param {string} orgid - The organization ID to filter branches by.
 * @param {string} companyId - The company ID to filter branches by.
 * @returns {Promise<any>} A promise that resolves to the result of the query.
 */
export const fetchAllBranchesByComapnyIdApi = async (
  organizationId: any,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select(`id, name`)
      .eq("organization_Id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Fetches all employees based on the organization, company, and branch IDs.
 * @param {any} orgid - The organization ID to filter employees by.
 * @param {any} companyId - The company ID to filter employees by.
 * @param {any} branchId - The branch ID to filter employees by.
 * @returns {Promise<any>} A promise that resolves to the result of the query.
 */
export const fetchAllEmployeebyOrganizationComapnyorBranchId = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("employee")
      .select("id , full_name")
      .eq("organization_id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Fetches salary grade data from the Grade table based on the provided data.
 * @param {any} data - The data object containing organizationId, company_id, and branch_id.
 * @returns {Promise<any>} A promise that resolves with the fetched data from the Grade table.
 */
export const fetchsalaryGradeApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("grade")
      .select("*")
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_Id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_Id", data.branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Fetches taxation data from the API based on the provided data object.
 * @param {any} data - The data object containing organizationId, companyId, branchId, and searchQuery.
 * @returns {Promise<any>} A promise that resolves to the fetched taxation data.
 */
export const fetchTaxationApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("taxation")
      .select("*")
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("taxation_version_name", `%${data.searchQuery}%`);
    }
    const res = await query;
    if (data.searchQuery && res.data && res.data.length > 0) {
      res.data = res.data.filter((item: any) => item.taxation_version_name);
    }
    return res;
  } catch {
    return catch_return;
  }
};

export const handleResendApi = async (data: any,orgId:any) => {
  try {
    
    const orgSubDomain = await fetchOrgDomain(orgId)


    const response = await supabase.auth.resend({
      type: "signup",
      email: data.email,
      options: {
        emailRedirectTo: `https://${orgSubDomain}.misgenie.app/`,
      },
    });

    return response;
  } catch {
    return catch_return;
  }
};

export const updatedEmployeeTourStatusApi = async (
  orgId: string,
  employeeId: any,
  key: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("employee")
      .update({ [key]: true })
      .eq("id", employeeId);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetcAllEmployeeApi = async (
  orgid: string | null,
  companyId: string | null,
  branchId: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("employee")
      .select(
        `id,dob,work_type,gender,status,full_name, avatar_url,designation_id,
      company_id,branch_id,job_title_id,joining_date,department_id,team_id,user_id,
      designation!employee_designation_id_fkey(id, name),
      branch!employee_branch_id_fkey(name),
      company!employee_company_id_fkey(name),
      job_title!employee_job_title_id_fkey(name),
      team!employee_team_id_fkey(name),
      department!employee_department_id_fkey(name)
     `
      )
      .eq("organization_id", orgid)
      .eq("status", "active");
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const allorgCompanyApi = async (orgid: string): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("company")
      .select(`id, name`)
      .eq("organization_Id", orgid);

    const res = await query;
    return res;
  } catch {}
};

export const allorgCompanyBranchesApi = async (
  orgid: string,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("branch")
      .select(`id, name`)
      .eq("organization_Id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const getAllBranches = async (companyId: any, organizationId: any) => {
  const response = await supabase_client_for_Org_schema(organizationId)
    .from("branch")
    .select("id, name")
    .eq("company_id", companyId);
  return response;
};

export const getAllCompanies = async (organizationId: any) => {
  const response = await supabase_client_for_Org_schema(organizationId)
    .from("company")
    .select("id, name")
    .eq("organization_Id", organizationId);

  return response;
};

export const fetchOrganizationlogoByIdApi = async (
  orgid: any
): Promise<any> => {
  try {
    let query = supabase.rpc("get_org_info", { org_id: orgid }).maybeSingle();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const pushNotifictionIntoNotificationTable = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let filteredObject = notificationDetailsList.filter(
      (item: any) => item.type == data.type
    );

    if (data.name) {
      const newfilteredObject = filteredObject.map((item: any) => {
        return {
          ...item,
          title: item.title.replace("{Name}", data.name),
          details: item.details.replace("{Name}", data.name),
        };
      });
      filteredObject = newfilteredObject;
    }

    let body = {
      sender_Id: data.sender_Id,
      receiver_id: data.receiver_id,
      title: filteredObject[0]?.title,
      detail: filteredObject[0]?.details,
      avatar: data.avatar,
      redirectLink: filteredObject[0]?.link,
      notificatio_type: data.type,
    };

    let query = supabase_client_for_Org_schema(organizationId)
      .from("notification")
      .insert(body);

    const Employees_res = await query;

    return Employees_res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchPayrollApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("payroll_generation")
      .select("id,payroll ")
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchOrganizationModulePermissionApi = async (
  orgid: number
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("organization_plans")
      .select(`plans_data`)
      .eq("organization_id", orgid)
      .single();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const subscribeToRealTimeOrgnizationPermissionModules = async (
  orgId: string,
  callback: any
) => {
  const channel = supabase
    .channel("real-time-Organization-permission-modules")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "operation_portal",
        table: "organization_plans",
        filter: `organization_id=eq.${orgId}`,
      },
      (payload: any) => {
        if (payload.new) {
          callback(payload.new?.plans_data);
        }
      }
    )
    .subscribe();

  return channel;
};

export const subscribeToRealTimeAccessRights = async (
  orgId: string,
  id: string,
  callback: any
) => {
  const channel = supabase
    .channel("real-time-access-right-changes")
    .on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: `organization_${orgId}`,
        table: "access_right",
        filter: `id=eq.${id}`,
      },
      (payload: any) => {
        if (payload.new) {
          callback(payload.new);
        }
      }
    )
    .subscribe();

  return channel;
};

export const subscribeToRealTimeEmployeeChanges = async (
  orgId: string,
  id: any,
  callback: any
) => {
  const channel = supabase
    .channel("real-time-employee-access-changes")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: `organization_${orgId}`,
        table: "employee",
        filter: `id=eq.${id}`,
      },
      (payload: any) => {
        if (payload) {
          callback(payload);
        }
      }
    )
    .subscribe();

  return channel;
};
