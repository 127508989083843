import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useForm } from "antd/es/form/Form";
import { getAllBranchesDataApi } from "../../../../AssetManagement/AssetAssigning/services";
import { fetchallCompanyByOrgIdApi } from "../../../../../services/Global";
import {
  getAllEmployees,
  getProbationsandInternshipsEmployeeData,
  insertProbationsandInternshipsData,
} from "../../services";
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import CustomAlert from "../../../../../common/Alert";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface CreateInternshipProps {
  onCancel: () => void;
}

const CreateInternship: React.FC<CreateInternshipProps> = ({ onCancel }) => {
  const [employees, setEmployees] = useState<any[]>([]);

  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [startDate, setStartDate] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [form] = useForm();

  useEffect(() => {
    if (!(branchId || companyId)) {
      getAllBranchess();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompanyId(companyId);
      form.setFieldValue("companyId", companyId);
      if (branchId) {
        form.setFieldValue("selectedBranch", branchId);
        setSelectedBranch(branchId);
      }
    } else {
      getCompanyOptions();
    }
  }, []);

  useEffect(() => {
    getEmployeesData();
  }, [selectedCompanyId, selectedBranch]);

  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      setSelectedBranch(null);
      const Ids = form.getFieldValue("companyId");
      const response = await getAllBranchesDataApi(Ids, organizationId);
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
      }
      setBranchSelectLoading(false);
    }
  };

  const getCompanyOptions = async () => {
    setCompanySelectLoading(true);
    const { data, error } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanyOptions(data);
      setCompanySelectLoading(false);
    }
    setCompanySelectLoading(false);
  };

  const getEmployeesData = async () => {
    try {
      form.setFieldValue("employeeId", "");
      setEmployees([]);
      setEmployeeSelectLoading(true);
      const alreadyExistingEmployeeRes =
        await getProbationsandInternshipsEmployeeData(organizationId);
      const alreadyExistingEmployee = alreadyExistingEmployeeRes?.data?.map(
        (item: any) => {
          return { id: item.employee_id };
        }
      );

      const company_id = selectedCompanyId ? selectedCompanyId : companyId;
      const branch_id = selectedBranch ? selectedBranch : branchId;

      const allEmployees = await getAllEmployees(
        organizationId,
        company_id,
        branch_id
      );

      const alreadyExistingEmployeeIds = alreadyExistingEmployee.map(
        (employee: any) => employee.id
      );
      const filteredEmployees = allEmployees.data?.filter(
        (employee: any) => !alreadyExistingEmployeeIds.includes(employee.id)
      );

      setEmployees(filteredEmployees);
      setEmployeeSelectLoading(false);
    } catch (error) {
      setEmployeeSelectLoading(false);
    }
  };

  const onFinish = async () => {
    try {
      setButtonLoading(true);
      const body = {
        organization_id: organizationId,
        company_id: form.getFieldValue("companyId"),
        branch_id: form.getFieldValue("selectedBranch")
          ? form.getFieldValue("selectedBranch")
          : null,
        employee_id: form.getFieldValue("employeeId").key,

        employment_type: "internship",
        duration: form.getFieldValue("duration"),
        starting_date: form.getFieldValue("startDate"),
        completion_date: form.getFieldValue("completionDate"),
      };

      const probationInternshipres = await insertProbationsandInternshipsData(
        body,

        organizationId
      );
      if (probationInternshipres.status === 201) {
        setshowAlert(true);
        setAlertType("success");
        form.resetFields();
        onCancel();
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
      setButtonLoading(false);
    } catch {
      setshowAlert(true);
      setAlertType("error");
      setButtonLoading(false);
    }
  };

  const onChangeStartDate = (date: any, dateString: any) => {
    if (dateString) {
      setStartDate(dateString);
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          {!companyId && (
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name={"companyId"}
                label="Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  placeholder="Select a company..."
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompanyId}
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companyOptions.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item name="selectedBranch" label="Select a branch">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Please select a branch (if any)"
                  onChange={setSelectedBranch}
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              name="employeeId"
              label="Select an Employee"
              rules={[
                {
                  required: true,
                  message: "Please select an Employee",
                },
              ]}
            >
              <Select
                labelInValue
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                showSearch
                loading={employeeSelectLoading}
                placeholder="Select an Employee"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {employees.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Duration(In Months)" name="duration">
              <InputNumber
                size="large"
                className="global-input-number-width"
                placeholder="Enter Duration(In Months)"
                onChange={(e:any) => setDuration(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate">
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                onChange={onChangeStartDate}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Completion Date" name="completionDate">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                htmlType="submit"
                loading={buttonLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default CreateInternship;
