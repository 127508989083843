import React, { useState, useEffect } from "react";
import { Card, Col, Row, Select } from "antd";
import "./index.css";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { ProgressLoader } from "../../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import { FaSearch } from "react-icons/fa";
import { ResponsivePie } from "@nivo/pie";
import { capitalizeWords } from "../../../../../common/validation";
import { IoSearch } from "react-icons/io5";

interface ByDesignationProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const ByDesignation: React.FC<ByDesignationProps> = (props) => {
  const COLORS = [
    "#ff8029",
    "#4b81bf",
    "#60606c",
    "#1677ff",
    "#d3d3d3",
    "#1e90ff",
  ];
  const [designationData, setDesignationData] = useState<
    { id: string; label: string; value: number; color: string }[]
  >([]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    fetchData(props.employeeData);
  }, [props.employeeData]);

  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);

  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);

  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);

  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    fetchData(tempData);
  };

  const filterbranches = () => {
    if (props.branches && selectedCompany) {
      const filterbranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );
      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const fetchData = async (data: any[]) => {
    if (data) {
      const designationCounts = data?.reduce((counts: any, employee: any) => {
        const teamId =
          capitalizeWords(employee?.designation?.name) || "Unknown";
        counts[teamId] = (counts[teamId] || 0) + 1;
        return counts;
      }, {});
      const result = Object.keys(designationCounts).map((key, index) => ({
        id: key,
        label: key,
        value: designationCounts[key],
        color: COLORS[index % COLORS.length],
      }));
      result.sort((a: any, b: any) => b.value - a.value);
      setDesignationData(result);
    } else {
      setDesignationData([]);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      className="dasboard-cards"
      title={
        <Row className="bydesignation-icon-toggle-container">
          <Col
            xxl={6}
            xl={6}
            lg={24}
            md={6}
            sm={24}
            xs={24}
            className="bydesignation-title-text"
          >
            By Designation
          </Col>
          <Col xxl={18} xl={18} lg={24} md={18} sm={24} xs={24}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id &&loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="bydesignation-card-selectors-row">
                {isToggled && (
                  <>
                    <Col span={9} className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}>
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a company..."
                        onChange={setSelectedCompany}
                        className="bydesignation-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={9} className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}>
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a branch"
                        onChange={setSelectedBranch}
                        className="bydesignation-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOptions.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col
                  span={4}
                  className="bydesignation-icon-container"
                  onClick={handleToggle}
                >
                  {isToggled ? (
                    <div className="bydesignation-search-icon-container">
                      <IoSearch className="bydesignation-search-icon" />
                    </div>
                  ) : (
                    <div className="bydesignation-search-icon-container">
                      <IoSearch className="bydesignation-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      <div style={{ height: 300 }}>
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : designationData && designationData.length > 0 ? (
          <ResponsivePie
            data={designationData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.2}
            padAngle={5}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom1",

                background: "inherit",
                color: "#218b8c",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom2",

                background: "inherit",
                color: "#c8eae1",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom3",
                type: "none",
                background: "inherit",
                color: "var(--green-color-primary-light)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={designationData.map((entry, index) => ({
              match: { id: entry.id },
              id:
                index % 3 === 0
                  ? "custom1"
                  : index % 3 === 1
                  ? "custom2"
                  : "custom3",
            }))}
           
          />
        ) : (
          <div
            style={{
              height: "18rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataFoundIcon />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ByDesignation;
