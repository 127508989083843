import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Select,
  Modal,
  Input,
  Row,
  Col,
  Steps,
  Space,
} from "antd";
import "./index.css";

import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert";

import { getEmployeesEditData, getTeamData, handleUpdation } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

interface EditTeamModalProps {
  selectedTeamId: number | null;
  fetchData: () => void;
  visibility: boolean | null | undefined | any;
  onCancel: () => void;
}

const EditTeamModal: React.FC<EditTeamModalProps> = ({
  selectedTeamId,
  fetchData,
  visibility,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    if (selectedTeamId) {
      fetchTeamData();
    }
  }, [selectedTeamId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchTeamData = async () => {
    const response = await getTeamData(selectedTeamId, organizationId);

    if (response.data && response.data.length > 0) {
      const team = response.data[0];

      fetchEmployees(team);

      form.setFieldsValue({
        teamName: team.name,
        projectLeadName: team.manager_id,
        goalsAndResponsibilities: team.mission,
      });
    }
    setLoading(false);
  };

  const fetchEmployees = async (teamData: any) => {
    const data = {
      organizationId: organizationId,
      companyId: teamData.company_id,
      branchId: teamData?.branch_id,
    };
    const response = await getEmployeesEditData(data);

    if (response.data) {
      setEmployeeList(response.data);
    }
  };

  const handleSave = async () => {
    setshowAlert(false);

    try {
      const values = await form.validateFields();

      if (!selectedTeamId) {
        return;
      }
      setButtonLoading(true);

      const body = {
        name: form.getFieldValue("teamName"),
        manager_id: form.getFieldValue("projectLeadName"),
        mission: values.goalsAndResponsibilities,
      };
      const response = await handleUpdation(
        selectedTeamId,
        body,
        organizationId
      );

      if (response.error) {
        setAlertType("error");
        setshowAlert(true);
        setButtonLoading(false);
      } else {
        setAlertType("success");
        setshowAlert(true);
        setButtonLoading(false);
        setCurrentStep(0);
      }
      setButtonLoading(false);

      fetchData();
    } catch (error) {
      return error;
    }
  };

  const handleNext = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handlePrevious = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  };

  const steps = [
    {
      title: "Team Information",
      content: (
        <>
          <Form layout="vertical" form={form}>
            <Row>
              <Col className="modal-content">
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="teamName"
                      label="Team Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input the team name!",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter team name..." />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="projectLeadName"
                      label="Project Lead"
                      rules={[
                        {
                          required: true,
                          message: "Please select a project lead!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a project lead"
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {employeeList.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.full_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                className="global-modal-bottom-row teams-edit-modal-btn-container"
              >
                <Button
                  className="global-btn-width"
                  type="primary"
                  key="next"
                  onClick={handleNext}
                  size="large"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },

    {
      title: "Details & Goals",
      content: (
        <>
          <Form layout="vertical">
            <Row>
              <Col className="modal-content">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="goalsAndResponsibilities"
                      label="Goals and Responsibilities"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the goals and responsibilities!",
                        },
                      ]}
                    >
                      <TextArea rows={4} className="team-text-area" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                className="global-modal-bottom-row teams-edit-modal-btn-container"
              >
                <Space>
                  <Button
                    className="global-btn-width"
                    type="default"
                    key="previous"
                    onClick={handlePrevious}
                    size="large"
                  >
                    Previous
                  </Button>
                  <Button
                    className="global-btn-width"
                    type="primary"
                    key="save"
                    onClick={handleSave}
                    loading={buttonLoading}
                    size="large"
                  >
                    Update
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
  ];

  return (
    <Modal
      title={<span className="global-modal-title">Edit Team</span>}
      open={visibility}
      onCancel={onCancel}
      width={1000}
      footer={false}
      className="global-modal-main-container"
      destroyOnClose
    >
      <div className="global-card-inner-container">
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              description={`Step ${index + 1}`}
            />
          ))}
        </Steps>
        <Form layout="vertical" form={form}>
          <div className="global-steps-container-margin">
            {steps[currentStep].content}
          </div>
        </Form>
      </div>
      {loading && <TriangleLoader />}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </Modal>
  );
};

export default EditTeamModal;
