import supabase, { supabase_client_for_Org_schema } from "../../../services/supabase";
import { catch_return } from "../../../common/Constants";

export const updateDepartmentHeadMangerbyIdApi = async (
  data: any,
  orgId:string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("department")
      .update({ head_manager_id: data.managerId || null })
      .eq("id", data.recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const updateBranchHeadMangerbyIdApi = async (
  data: any,
  orgId:string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("branch")
      .update({ head_manager_id: data.managerId || null })
      .eq("id", data.recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const updateTeamHeadMangerbyIdApi = async (data: any, orgId:string): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("team")
      .update({ manager_id: data.managerId || null })
      .eq("id", data.recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const updateEmployeeHeadMangerbyIdApi = async (
  data: any,
  orgId:string
): Promise<any> => {
  try {
    let query =  supabase_client_for_Org_schema(orgId)
      .from("employee")
      .update({ report_to_employee_id: data.managerId || null })
      .eq("id", data.recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
