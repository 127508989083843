import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Row, Col, Select, Space } from "antd";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import "./index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { domaincheck } from "../../../../../common/validation";
import CustomAlert from "../../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface EditContactModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedRecordId: number | null;
  fetchData: () => void;
}

const EditContactModal: React.FC<EditContactModalProps> = ({
  visible,
  onCancel,
  selectedRecordId,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [employeeCompanyId, setEmployeeCompanyId] = useState<any>();
  const [companyEmail, setCompanyEmail] = useState<any>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    if (visible && selectedRecordId !== null) {
      fetchContactDetails();
    }
  }, [visible, selectedRecordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getCompanyId = async () => {
    const { data, error } = await supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("company_id")
      .eq("id", selectedRecordId)
      .eq("status", "active");
    if (data) {
      const companyId = data[0].company_id;
      setEmployeeCompanyId(companyId);
    }
  };
  const getCompanyEmail = async () => {
    const { data, error } = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("email")
      .eq("id", employeeCompanyId);
    if (data) {
      const companyEmail = data[0].email;
      setCompanyEmail(companyEmail);
    }
  };

  useEffect(() => {
    getCompanyId();
  }, []);
  useEffect(() => {
    getCompanyEmail();
  }, [employeeCompanyId]);

  const fetchContactDetails = async () => {
    try {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee")
        .select("*")
        .eq("id", selectedRecordId)
        .eq("status", "active");

      if (data && data.length > 0) {
        const contact = data[0];

        form.setFieldsValue({
          full_name: contact.full_name,
          mobileNumber: contact.phone_number,
          workEmail: contact.work_email,
          workNumber: contact.work_phone_number,
          otherEmail: contact.other_email,
        });
      }

      if (error) {
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateContact = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee")
        .update({
          phone_number: values.mobileNumber,
          work_email: values.workEmail,
          other_email: values.otherEmail,
          work_phone_number: values.workNumber,
        })
        .eq("id", selectedRecordId);

      if (response.error) {
        setshowAlert(true);
        setAlertMessage("Not updated");
        setAlertDescription("Operation Failed");
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertMessage("Successfully Updated!");
        setAlertDescription("Updated Successfully!");
        setAlertType("success");
        form.resetFields();
        fetchData();
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1000}
      title={<span className="global-modal-title">Edit Contact</span>}
      footer={false}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form form={form} layout="vertical">
          <Row>
            <Col className="modal-content" span={24}>
              <Row gutter={[16, 0]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="full_name"
                    label="Employee Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Employee Name!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Please select an Employee..."
                      disabled
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name={"mobileNumber"}
                    label="Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the mobile number!",
                      },

                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter Mobile Number"
                      inputProps={{
                        required: true,
                      }}
                      defaultCountry="pk"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name={"workNumber"}
                    label="Work Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the work number!",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      placeholder="Enter Mobile Number"
                      inputProps={{
                        required: true,
                      }}
                      defaultCountry="pk"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name={"workEmail"}
                    label="Work Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the work email!",
                      },

                      {
                        validator: (_, value) => {
                          if (value && !domaincheck(companyEmail, value)) {
                            return Promise.reject(
                              "Email domain not Match with comapany Email"
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Work Email" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name={"otherEmail"}
                    label="Other Email"
                    rules={[
                      {
                        type: "email",
                        message: "Email format incorrect",
                        required: false,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Other Email" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="global-modal-bottom-row">
                <Col
                  span={24}
                  className="contacts-edit-modal-buttons-container"
                >
                  <Space>
                    <Button
                      className="global-btn-width"
                      type="default"
                      key="cancel"
                      onClick={onCancel}
                      size="large"
                    >
                      Cancel
                    </Button>

                    <Button
                      key="update"
                      className="global-btn-width"
                      type="primary"
                      loading={loading}
                      onClick={handleUpdateContact}
                      size="large"
                    >
                      Update
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </Modal>
  );
};

export default EditContactModal;
