import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Input, Button, Modal, Select } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { TriangleLoader } from "../../../../common/ReactLoader";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import dayjs from "dayjs";
import {
  employeeResignationCheckRequest,
  onFinishEmployeeResignationForm,
} from "../services";
import { checkForResignationRequests } from "../viewmodal";
import { employee_end_of_employeement_reason } from "../../../../common/Constants";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { RangePicker } = DatePicker;

const CreateEmployeeResignation = () => {
  const defaultStartDate = moment();
  const defaultEndDate = moment().add(30, "days");
  const defaultRange = [
    dayjs(defaultStartDate.format("YYYY-MM-DD")),
    dayjs(defaultEndDate.format("YYYY-MM-DD")),
  ];
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [form] = Form.useForm();
  const [fullLoader, setFullLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [noticePeriodSpan, setNoticePeriodSpan] = useState(30);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [showLimitModal, setShowLimitModal] = useState<boolean>(false);
  const [showAcceptedModal, setShowAcceptedModal] = useState<boolean>(false);
  const [requests, setRequests] = useState<any>([]);
  const organizationId = logedInemployee?.employee?.organization_id;

  const onRangeChange = (dates: any) => {
    if (dates) {
      const diffDays = dates[1].diff(dates[0], "days");
      setNoticePeriodSpan(diffDays);
      form.setFieldsValue({ noticePeriod: diffDays });
    }
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    form.setFieldsValue({
      noticePeriodRange: defaultRange,
      noticePeriod: 30,
    });
    const checkRequests = async () => {
      const { data, error } = await employeeResignationCheckRequest(
        logedInemployee?.employee?.id,
        organizationId
      );
      if (data) {
        setRequests(data);
      }
    };

    checkRequests();
  }, []);

  useEffect(() => {
    const res = checkForResignationRequests(requests);

    if (res?.acceptedRequest) {
      setIsFormDisabled(true);
      setShowAcceptedModal(true);
    } else if (res?.excessiveRequests) {
      setShowLimitModal(true);
    }
  }, [requests]);

  const onFinish = async (values: any) => {
    setShowAlert(false);
    setFullLoader(true);
    const startDate = values.noticePeriodRange[0].format("YYYY-MM-DD");
    const endDate = values.noticePeriodRange[1].format("YYYY-MM-DD");
    try {
      const response = await onFinishEmployeeResignationForm({
        organization_id: organizationId,
        company_id: logedInemployee?.employee?.company_id,
        branch_id: logedInemployee?.employee?.branch_id || null,
        employee_Id: logedInemployee?.employeeId,
        start_date: startDate,
        end_date: endDate,
        reason: values.reason,
        notice_period: values.noticePeriod,
        status: "Requested",
        description: values.description,
      });

      if (response.error) {
        setFullLoader(false);
        setShowAlert(true);
        setAlertType("error");
      } else {
        setFullLoader(false);
        setShowAlert(true);
        setAlertType("success");

        form.resetFields();
      }
    } catch (error) {
      setFullLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        disabled={isFormDisabled}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="reason"
              label="Select Reason"
              rules={[
                {
                  required: true,
                  message: "Please select Reason",
                },
              ]}
            >
              <Select
                placeholder="Select an Reason"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {employee_end_of_employeement_reason.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please write a description...",
                },
              ]}
            >
              <Input.TextArea rows={5} placeholder="Write a description..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={16}>
            <Form.Item
              label="Notice Period"
              name="noticePeriodRange"
              rules={[
                {
                  type: "array",
                  required: true,
                  message: "Please select the Notice Period Range",
                },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                defaultValue={[
                  dayjs(defaultStartDate.format("YYYY-MM-DD")),
                  dayjs(defaultEndDate.format("YYYY-MM-DD")),
                ]}
                onChange={onRangeChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={8}>
            <Form.Item
              label="Notice Period Span"
              name="noticePeriod"
              initialValue={noticePeriodSpan}
            >
              <Input
                disabled
                size="large"
                value={noticePeriodSpan.toString()}
                placeholder="Enter your span..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          className="global-modal-bottom-row create-resign-btn"
          shouldUpdate={true}
        >
          <Button
            className="global-btn-width"
            htmlType="submit"
            size="large"
            type="primary"
          >
            Create
          </Button>
        </Form.Item>
      </Form>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      {fullLoader && (
        <div className="request-demo-suspense-blur-screen">
          <TriangleLoader />
        </div>
      )}
      <Modal
        title="Notice"
        open={showAcceptedModal}
        onCancel={() => setShowAcceptedModal(false)}
        footer={
          <Button
            type="primary"
            onClick={() => setShowAcceptedModal(false)}
          >
            Ok
          </Button>
        }
      >
        <p>Your resignation was already accepted.</p>
      </Modal>

      <Modal
        title="Notice"
        open={showLimitModal}
        onCancel={() => setShowLimitModal(false)}
        footer={
          <Button
            type="primary"
            onClick={() => setShowLimitModal(false)}
          >
            Ok
          </Button>
        }
      >
        <p>You cannot make more than 3 requests per month.</p>
      </Modal>
    </div>
  );
};

export default CreateEmployeeResignation;
