import React, { useEffect, useState } from "react";
import "./index.css";
import { Empty, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { fetchGenratedSalaryMonthsbyEmployeeIsApi } from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../common/CustomTable";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";

interface EmployeePaySlipPorps {
  selectedEmployeeId?: any;
}
const EmployeePaySlip: React.FC<EmployeePaySlipPorps> = ({
  selectedEmployeeId,
}) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<any[]>();
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const selectedOrganization = useSelector(selectLogedEmployee);

  const employeeRole = loggedInEmployee?.employeeRole;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Pay Slip");

  const read = access?.readAccess;

  useEffect(() => {
    fetchMonthsdata();
  }, []);

  const fetchMonthsdata = async () => {
    setLoadingTable(true);

    const res = await fetchGenratedSalaryMonthsbyEmployeeIsApi({
      organizationId: loggedInEmployee?.employee.organization_id,
      employeeId: selectedEmployeeId
        ? selectedEmployeeId
        : loggedInEmployee?.employeeId,
    });

    let monthsData = [];
    if (res.data) {
      monthsData = res.data.map((item: any) => {
        const createdAtDate = new Date(item.created_at);
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(createdAtDate);
        const year = createdAtDate.getFullYear();
        return {
          month: `${month} ${year}`,
          id: item.id,
          net_salary: parseFloat(item.net_salary).toLocaleString(),
          gross_deduction: parseFloat(item.gross_deduction).toLocaleString(),
        };
      });
    }
    setTableData(monthsData);
    setLoadingTable(false);
  };

  const handlePreviewClick = (id: string) => {
    const adminPortalEmployeeId = selectedEmployeeId
      ? selectedEmployeeId
      : loggedInEmployee?.employeeId;

    navigate(
      `/employee/${adminPortalEmployeeId}/payslipPreview/${id}`
    );
  };

  const tableColumns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Total Deductions",
      dataIndex: "gross_deduction",
      key: "gross_deduction",
    },
    {
      title: "Total Earnings",
      dataIndex: "net_salary",
      key: "net_salary",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <span className="link" onClick={() => handlePreviewClick(record.id)}>
            <EyeOutlined />
          </span>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="employee-payslip-parent payslip-card">
        <div className="employee-payslip-title">Employee Payslip</div>
        {employeeRole === "employee" ? (
          read ? (
            <CustomTable
              scroll={{ x: true }}
              dataSource={tableData}
              columns={tableColumns}
              loading={selectedEmployeeId ? false : loadingTable}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )
        ) : (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={tableColumns}
            loading={selectedEmployeeId ? false : loadingTable}
          />
        )}
      </div>
    </>
  );
};

export default EmployeePaySlip;
