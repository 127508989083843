import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Empty, Modal, Space, TablePaginationConfig } from "antd";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import { useSelector } from "react-redux";

import CustomAlert from "../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import { FaStackOverflow, FaUsersViewfinder } from "react-icons/fa6";
import CustomTable from "../../../../common/CustomTable";
import { TbFileDollar } from "react-icons/tb";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import EmployeeAttandanceDetails from "../AttendanceDetails/inde";
import AllowanceClaimDetail from "../Allowance Claim";
import OverTimeClaimDetails from "../OverTimeDetails";

interface GenratePaySlipListProps {
  searchValue?: string;
}

const DetailView: React.FC<GenratePaySlipListProps> = ({ searchValue }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [salariesTableData, setSalariesTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [attendanceModelOpen, setAttandanceModelOpen] =
    useState<boolean>(false);
  const [overTimeModelOpen, setOvertimeModelOpen] = useState<boolean>(false);
  const [allowanceModelOpen, setAllowanceModelOpen] = useState<boolean>(false);

  const [selecttableRecord, setSelectedTableRecord] = useState<any>();
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Generate Pay");
  const read = access?.readAccess;

  useEffect(() => {
    fetchSalaries();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setSalariesTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchSalaries = async () => {
    setLoading(true);
    let query = supabase_client_for_Org_schema(
      loggedInEmployee?.employee?.organization_id
    )
      .from("employee_salary")
      .select(
        `*, employee!employee_salarie_employee_id_fkey(full_name,company_id,branch_id,team_id,department_id)`
      )
      .eq("organization_id", loggedInEmployee?.employee?.organization_id);
    if (loggedInEmployee?.employee?.company_id) {
      query = query.eq("company_id", loggedInEmployee?.employee?.company_id);
    }
    if (loggedInEmployee?.employee?.branch_id) {
      query = query.eq("branch_id", loggedInEmployee?.employee?.branch_id);
    }
    const res = await query;
    if (res.data) {
      setSalariesTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));

      setLoading(false);
    }
    setLoading(false);
  };

  const columns: any = [
    {
      title: "Employee Name",
      fixed: "left",
      width: 200,
      dataIndex: "employee",
      key: "employee",
      render: (employee: any) => employee?.full_name,
      sorter: (a: any, b: any) => {
        const nameA = a?.employee?.full_name || "";
        const nameB = b?.employee?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Base Salary",
      dataIndex: "base_salary",
      key: "base_salary",
      render: (record: any) =>
        record ? parseInt(record)?.toLocaleString() : "",
    },
    {
      title: "PF",
      key: "provident_fund_value",
      render: (record: any) =>
        record.provident_fund_value
          ? "-" +
            (
              (parseFloat(record.base_salary) *
                parseFloat(record.provident_fund_value)) /
              100
            ).toLocaleString()
          : "--",
    },
    {
      title: "EOBI",
      dataIndex: "EOBI",
      key: "EOBI",
      render: (_: any, item: any) =>
        item?.EOBI ? parseFloat(item?.EOBI)?.toLocaleString() : "",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Other Details",
      key: "attendance_details",
      render: (text: any, record: any) => (
        <Space>
          <Button
            className="global-default-btn"
            icon={<FaUsersViewfinder />}
            onClick={() => {
              setSelectedTableRecord(record);
              setAttandanceModelOpen(true);
            }}
          >
            Attendance
          </Button>
          <Button
            className="global-default-btn"
            icon={<TbFileDollar />}
            onClick={() => {
              setSelectedTableRecord(record);
              setAllowanceModelOpen(true);
            }}
          >
            Allowance
          </Button>
          <Button
            className="global-default-btn"
            icon={<FaStackOverflow />}
            onClick={() => {
              setSelectedTableRecord(record);
              setOvertimeModelOpen(true);
            }}
          >
            OverTime
          </Button>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));

    setSalariesTableData(filterData);

    setLoading(false);
  };

  return (
    <>
      <div>
        <div className="genrate-pay-slip-container table-content-search">
          {read ? (
            <CustomTable
              scroll={{ x: "auto" }}
              dataSource={salariesTableData}
              columns={columns}
              loading={loading}
              rowKey="employeeId"
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </div>
      </div>
      {selecttableRecord && attendanceModelOpen && (
        <Modal
          title={
            <span className="global-modal-title">Attandances Details</span>
          }
          className="global-modal-main-container"
          open={attendanceModelOpen}
          width={1000}
          footer={false}
          onCancel={() => {
            setAttandanceModelOpen(false);
            setSelectedTableRecord(null);
          }}
        >
          <div className="global-card-inner-container">
            <EmployeeAttandanceDetails
              employeeId={selecttableRecord?.employee_id}
            />
          </div>
        </Modal>
      )}
      {selecttableRecord && allowanceModelOpen && (
        <Modal
          title={<span className="global-modal-title">Allowances Details</span>}
          className="global-modal-main-container"
          open={allowanceModelOpen}
          width={1000}
          footer={false}
          onCancel={() => {
            setAllowanceModelOpen(false);
            setSelectedTableRecord(null);
          }}
          destroyOnClose
        >
          <div className="global-card-inner-container">
            <AllowanceClaimDetail employeeId={selecttableRecord?.employee_id} />
          </div>
        </Modal>
      )}
      {selecttableRecord && overTimeModelOpen && (
        <Modal
          title={
            <span className="global-modal-title">
              {selecttableRecord.employee?.full_name} OverTime Details
            </span>
          }
          className="global-modal-main-container"
          open={overTimeModelOpen}
          width={1000}
          footer={false}
          onCancel={() => {
            setOvertimeModelOpen(false);
            setSelectedTableRecord(null);
          }}
          destroyOnClose
        >
          <div className="global-modal-main-container">
            <OverTimeClaimDetails employeeId={selecttableRecord?.employee_id} />
          </div>
        </Modal>
      )}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default DetailView;
