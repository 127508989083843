import React, { useEffect, useState } from "react";
import TabsHeader from "./../../../common/TabsHeader";
import { Card, Empty } from "antd";

import MyTimeTracking from "../../EmployeePortal/MyTimeTracking/Layout";
import AttendanceLogs from "../AttendanceLogs";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../redux/AccessRight";
import { useSelector } from "react-redux";

const HrAttendanceModule: React.FC = () => {
  const [selected, setSelected] = useState("My Time Sheet");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  const radioOptions = [
    {
      label: "My Time Sheet",
      value: "My Time Sheet",
      create: false,
      filter: false,
      showSearch: false,
    },
    {
      label: "Attendance logs",
      value: "Attendance logs",
      create: false,
      filter: false,
      showSearch: false,
    },
  ];

  useEffect(() => {
    handleRadioChange(selected);
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Attendance";
    let childValue =
      value == "My Time Sheet" ? "My Time Sheet" : "Attendance Logs";

    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };
  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={radioOptions}
            onChange={(value) => {
              setSelected(value);
            }}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
          />
          {readAccess ? (
            selected === "My Time Sheet" ? (
              <MyTimeTracking />
            ) : selected === "Attendance logs" ? (
              <AttendanceLogs />
            ) : null
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default HrAttendanceModule;
