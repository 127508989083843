import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";
const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches the email of a company by its ID from the Supabase database.
 * @param {any} id - The ID of the company to fetch the email for.
 * @returns {Promise<any>} A promise that resolves to the email of the company.
 */
export const fetchCompaniesEmailByIdApi = async (id: any): Promise<any> => {
  try {
    const query = await supabase
      .from("Companies")
      .select("email")
      .eq("id", id)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts employee addresses into the "Addresses" table in the Supabase database.
 * @param {any} data - The data containing the employee addresses to be inserted.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertEmployeeAddressesApi = async (
  data: any,
  organization_id: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organization_id)
      .from("employee_address")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Updates an employee record in the database using the provided data.
 * @param {any} data - The data object containing the updated employee data and employee ID.
 * @returns {Promise<any>} A promise that resolves to the updated employee record.
 */
export const updateEmployeesApi = async (
  data: any,
  organization_id: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organization_id)
      .from("employee")
      .update(data.updatedData)
      .eq("id", data.EmployeeId)
      .select("id")
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const checkWorkEmailApi = async (
  organization_id: any,
  work_email: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organization_id)
      .from("employee")
      .select("work_email")
      .ilike("work_email", work_email);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
