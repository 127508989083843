import React, { useState, useEffect } from "react";
import { Row, Col, Descriptions, Divider } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { fetchDeductionSlabs, getEmployeeTimingPolicyData } from "../services";
import { TriangleLoader } from "../../../../common/ReactLoader";

type DaysObject = {
  [key: string]: boolean;
};

const TimingEmployeeView: React.FC<any> = ({ data }) => {
  const [earlyLeftPolicy, setEarlyLeftPolicy] = useState<any[]>([]);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employeeId;

  const [loading, setLoading] = useState<boolean>(true);

  const daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  const fetchEarlyLeftPolicies = async () => {
    if (employeeRole !== "employee") {
      const propsData = [data];
      const daysSorting = sortDays(propsData?.[0].working_days);

      const dataToUse = propsData?.map((item: any) => {
        return {
          ...item,
          working_days: daysSorting,
          slabs: item?.short_hours_deduction?.slabs,
        };
      });
      setEarlyLeftPolicy(dataToUse);
      setLoading(false);
    }

    if (employeeRole === "employee") {
      const response = await getEmployeeTimingPolicyData(
        employeeId,
        organizationId
      );

      if (response.data) {
        const deducitonSlabsResponse = await fetchDeductionSlabs(
          organizationId,
          response?.data[0]?.deduction_version_id
        );

        const daysSorting = sortDays(response?.data[0]?.working_days);

        const dataToUse = response.data?.map((item: any) => {
          return {
            ...item,
            working_days: daysSorting,
            slabs: deducitonSlabsResponse?.data?.slabs,
          };
        });
        setEarlyLeftPolicy(dataToUse);
        setLoading(false);
      }
    }
  };

  const sortDays = (days: DaysObject): DaysObject => {
    return daysOrder
      .filter((day) => day in days)
      .reduce<DaysObject>((acc, day) => {
        acc[day] = days[day];
        return acc;
      }, {});
  };

  useEffect(() => {
    fetchEarlyLeftPolicies();
  }, []);

  const generateItemsArray = (policy: any): any[] => {
    return [
      {
        key: "1",
        label: (
          <span className="policy-description-title">
            Working Hours per Day
          </span>
        ),
        span: 3,
        children: (
          <span>
            The policy on working hours per day determines the total number of
            hours an employee is expected to work within a single day. As per
            this policy, employees are required to work{" "}
            <strong>{policy.working_hours_per_day}</strong> hours each day.
          </span>
        ),
      },
      {
        key: "2",
        span: 3,
        label: (
          <span className="policy-description-title policy-descriptions">
            Working Days in a Week
          </span>
        ),
        children: (
          <span>
            The policy on working days in a week outlines the specific days
            within a week during which employees are expected to be present and
            actively working. According to this policy, the working days
            include: <br />
            <strong>
              {policy.working_days &&
                Object?.entries(policy?.working_days)
                  ?.filter(([key, value]) => value)
                  ?.map(([key]) => key)
                  ?.join(", ")}
            </strong>
            .
          </span>
        ),
      },
      {
        key: "3",
        span: 3,
        label: <span className="policy-description-title">Description</span>,
        children: (
          <span>
            The policy description provides additional context and details
            regarding the overall timing and punctuality expectations within the
            organization. This includes any specific guidelines, expectations,
            or consequences related to adherence to working hours and
            punctuality standards. <br />
            {policy.policy_description}
          </span>
        ),
      },
      {
        key: "4",
        span: 3,
        label: (
          <span className="policy-description-title">Short Hours Slabs</span>
        ),
        children: policy?.slabs?.map((slab: any, index: number) => (
          <span key={index}>
            Deduction: <strong>{slab.percent}%</strong>, Start:{" "}
            <strong>{slab.start} minutes</strong>, Stop:{" "}
            <strong>{slab.stop} minutes</strong>
            <br />
          </span>
        )),
      },
    ];
  };
  return (
    <div className="hr-timing-policy-view-container">
      <div className="hr-timing-policy-view-content">
        <Row justify="center">
          <Col span={24}>
            {earlyLeftPolicy[0]?.working_hours_per_day ? (
              earlyLeftPolicy?.map((policy) => (
                <>
                  <Row>
                    <Col span={24}>
                      <p>
                        Our timing policy establishes guidelines for working
                        hours and days to ensure operational efficiency and
                        clarity for employees. Key aspects of the policy
                        include:
                        <br />
                      </p>
                    </Col>
                  </Row>
                  <Descriptions
                    className="timing-policy-descriptions"
                    layout="horizontal"
                    bordered
                    items={generateItemsArray(policy)}
                  />
                </>
              ))
            ) : (
              <div className="no-data-found-policy">
                <NoDataFoundIcon />
              </div>
            )}
          </Col>
        </Row>
        {loading && <TriangleLoader />}
      </div>
    </div>
  );
};

export default TimingEmployeeView;
