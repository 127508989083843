import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Space,
  Tooltip,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import { setTaxationApi } from "../../services";
import CustomAlert from "../../../../common/Alert";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../../../../common/CustomTable";
import { allorgCompanyBranchesApi } from "../../../../services/Global";
import { allorgCompanyApi } from "../../../../services/Global";
import { Dayjs } from "dayjs";
import moment from "moment";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const TaxationCreateView = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [tabledata, setTableData] = useState<any>([]);
  const [recordIndex, setRecordIndex] = useState<number>(0);
  const [taxApplyFrom, setTaxApplyFrom] = useState<number>();
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("companyId", companyId);
      setSelectedCompanyId(companyId);
      if (branchId) {
        form.setFieldValue("branchId", branchId);
      }
    } else {
      fetchCompanies();
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompanyId]);

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies.data[0]?.id);
      setSelectedCompanyId(resCompanies.data[0]?.id);
    }
  };
  const fetchBranches = async () => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
    } else {
      if (selectedCompanyId) {
        const resBranches = await allorgCompanyBranchesApi(
          organizationId,
          selectedCompanyId
        );
        if (resBranches?.data) {
          setFilteredBranches(resBranches.data);
          form.setFieldValue("branchId", resBranches.data[0]?.id);
        }
      }
    }
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date ? moment(date.toISOString()) : null);
    form.setFieldsValue({ taxationEndDate: null });
  };
  const disableEndDate = (current: Dayjs) => {
    return startDate ? current && current < startDate.endOf("month") : true;
  };

  const onFinish = async () => {
    setButtonLoading(true);
    form
      .validateFields([
        "taxationversion",
        "companyId",
        "taxationStartMonth",
        "taxationEndMonth",
      ])
      .then(async () => {
        if (tabledata.length > 0) {
          const data = {
            taxation_version_name: capitalizeWords(
              form.getFieldValue("taxationversion")
            ),
            organization_id: organizationId,
            company_id: form.getFieldValue("companyId") || companyId || null,
            branch_id: form.getFieldValue("branchId") || branchId || null,
            slabs: tabledata,
            taxation_start_date: form.getFieldValue("taxationStartMonth"),
            taxation_end_date: form.getFieldValue(""),
          };
          const res = await setTaxationApi(data);
          if (res.status === 201 || res.status === 204) {
            form.resetFields();
            setTableData([]);
            setTaxApplyFrom(0);
            setshowAlert(true);
            setButtonLoading(false);
            setAlertMessage("");
            setAlertDescription("Taxation Version Details Add Successfully");
            setAlertType("success");
          } else {
            setshowAlert(true);
            setButtonLoading(false);
            setAlertMessage("");
            setAlertDescription(
              res.error?.message ||
                "Oops, we are facing a temporary issue with your request"
            );
            setAlertType("error");
            timeoutRef.current = setTimeout(() => {
              setshowAlert(false);
            }, 3000);
          }
        } else {
          setshowAlert(true);
          setButtonLoading(false);
          setAlertMessage("");
          setAlertDescription("Please add at least one taxation detail");
          setAlertType("error");
          timeoutRef.current = setTimeout(() => {
            setshowAlert(false);
          }, 3000);
        }
      })
      .catch((errorInfo) => {
        setshowAlert(true);
        setButtonLoading(false);
        setAlertMessage("");
        setAlertDescription("Please fill in the required fields correctly");
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      });
  };

  const handleDelete = (rid: number) => {
    const updatedTableData = tabledata.filter((item: any) => item.id != rid);
    setTableData(updatedTableData);
  };

  const handleAdd = (value: any) => {
    form
      .validateFields()
      .then(() => {
        const newRecord = {
          id: recordIndex + 1,
          taxApplyFrom: value.taxApplyFrom,
          taxApplyTo: value.taxApplyTo,
          taxPercentage: value.taxPercentage,
          fix_amount: value.fix_amount,
        };
        setRecordIndex(recordIndex + 1);
        setTableData([...tabledata, newRecord]);
        let new_start_tax_slab = parseInt(value.taxApplyTo) + 1;
        setTaxApplyFrom(new_start_tax_slab);
        form.setFieldsValue({
          taxApplyFrom: new_start_tax_slab,
          taxApplyTo: "",
          taxPercentage: "",
          fix_amount: "",
        });
      })
      .catch((errors) => {});
  };

  const CustomValidator = (rule: any, value: number, callback: any) => {
    if (taxApplyFrom && value && value < taxApplyFrom) {
      callback("Must be greater than tax apply starting Value");
    } else {
      callback();
    }
  };
  const tableColumns = [
    {
      title: "Tax will apply if Over",
      dataIndex: "taxApplyFrom",
      key: "taxApplyFrom",
    },
    {
      title: "Not Over",
      dataIndex: "taxApplyTo",
      key: "taxApplyTo",
    },
    {
      title: "% of applied",
      dataIndex: "taxPercentage",
      key: "taxPercentage",
    },
    {
      title: "Fix Amount",
      dataIndex: "fix_amount",
      key: "fix_amount",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <Space size="middle">
          <a onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleAdd}
        >
          <Row gutter={16}>
            {!companyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="companyId"
                  label="Select Company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Company",
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => setSelectedCompanyId(value)}
                    placeholder="Select a Company"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredCompanies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!(companyId || branchId) && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item name="branchId" label="Select Branch (If any)">
                  <Select
                    placeholder="Select a Branch"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="taxationversion"
                label="Enter Taxation version"
                rules={[
                  {
                    required: true,
                    message: "Please Enter a Taxation version",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Taxation version" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="taxationStartMonth"
                label="Taxation Starting Month"
                rules={[
                  {
                    required: true,
                    message: "Please select a starting date of Taxation.",
                  },
                ]}
              >
                <DatePicker
                  className="taxation-date-picker"
                  picker="month"
                  onChange={handleStartDateChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="taxationEndMonth"
                label="Taxation Ending Month"
                rules={[
                  {
                    required: true,
                    message: "Please select a ending date of Taxation.",
                  },
                ]}
              >
                <DatePicker
                  className="taxation-date-picker"
                  picker="month"
                  disabledDate={disableEndDate}
                  disabled={!startDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="taxApplyFrom"
                label="Min"
                rules={[
                  {
                    required: true,
                    message: "Please Enter a Tax will apply(if Over)",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                ]}
              >
                <Input
                  onChange={(e) => setTaxApplyFrom(parseInt(e.target.value))}
                  placeholder="Min"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                name="taxApplyTo"
                label="Max"
                rules={[
                  {
                    required: taxApplyFrom ? true : false,
                    message: "Please Enter a Not Over Value",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                  {
                    validator: CustomValidator,
                  },
                ]}
              >
                <Input size="large" placeholder="Max" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                name="taxPercentage"
                label="% of applied"
                rules={[
                  {
                    required: taxApplyFrom ? true : false,
                    message: "Please Enter a % of applied",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                ]}
              >
                <Input size="large" placeholder="% of applied" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                name="fix_amount"
                label="Fix Amount"
                rules={[
                  {
                    required: taxApplyFrom ? true : false,
                    message: "Please Enter a % of applied",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Fix Amount" />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={3}
              lg={3}
              xl={3}
              xxl={3}
              className="taxation-create-form-add-button"
            >
              <Form.Item label=" ">
                <Tooltip title="This Action Will Not Save Details To Database. it will add Details in Table Blow">
                  <Button type="primary" htmlType="submit" size="large">
                    Add
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <CustomTable dataSource={tabledata} columns={tableColumns} />
          </Row>
          {tabledata?.length >= 1 && (
            <Row className="global-modal-bottom-row taxation-create-form-container-last-row">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  loading={buttonLoading}
                  onClick={() => onFinish()}
                  size="large"
                >
                  Create
                </Button>
              </Form.Item>
            </Row>
          )}
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default TaxationCreateView;
