import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  Row,
  Col,
  Form,
  Checkbox,
  Typography,
  Modal,
  Input,
  Empty,
} from "antd";
import "./index.css";
import { MinusOutlined, PlusOutlined, SaveFilled } from "@ant-design/icons";
import { RiUserSearchFill } from "react-icons/ri";
import { useSelector } from "react-redux";

import { capitalizeWords } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  fetchAccessRightTableDataById,
  fetchSubgroupBasedOnParentGroup,
  fetchTheGroupData,
  getBranches,
  getCompanies,
  handleSaveChanges,
} from "../services";
import TableHeadCommonButtons from "../../../../common/TableHead/TableHeadbuttons";
import CreateAccessRights from "../CreateAccessRights";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  childCheckboxChangeHanlder,
  parentCheckboxChangeHandler,
} from "../view-modal";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Text } = Typography;
interface companiesOptiontype {
  label: string;
  id: string;
}

const ViewAccessRights = () => {
  const [showButtonchanges, setShowButtonChange] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);
  const [dbTableRowId, setDbTableRowId] = useState<string | number>();
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [searchBtnLoading, setSearchBtnLoading] = useState<boolean>(false);
  const [saveChangeBtnLoading, setSaveChangeBtnLoading] =
    useState<boolean>(false);
  const [companiesOption, setCompaniesOption] = useState<companiesOptiontype[]>(
    []
  );
  const [companyFetchLoading, setCompanyFetchLoading] =
    useState<boolean>(false);
  const [branchFetchLoading, setBranchFetchLoading] = useState<boolean>(false);
  const [subGroupLoading, setSubGroupLoading] = useState<boolean>(false);
  const [mainGroupLoading, setMainGroupLoading] = useState<boolean>(false);

  const [mainAccessGroupData, setMainAccessGroupData] = useState<any>();
  const [branchOption, setBranchOption] = useState<companiesOptiontype[]>([]);
  const [subGroupOption, setSubGroupOption] = useState<any[]>([]);

  const [subGroup, setSubGroup] = useState<string | number>();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [form] = Form.useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createAccessRights, setCreateAccessRights] = useState<any>(null);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filterCity, setFilterCity] = useState<string>("");

  const organizationId = loggedInEmployee?.employee.organization_id;
  const companyId = loggedInEmployee?.employee.company_id;
  const branchId = loggedInEmployee?.employee.branch_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "System Settings",
    "Access Rights"
  );

  const create = access?.createAccess;
  const edit = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const handleChangedValues = (changedValues: any, allValues: any) => {
    if ("companyName" in changedValues) {
      form.setFieldValue("branchName", "");
      form.setFieldValue("parentGroup", "");
      form.setFieldValue("subGroup", "");
      setBranchOption([]);
      setSubGroupOption([]);
      setMainAccessGroupData(null);
      setFilteredTableData([]);
      setShowButtonChange(0);
      setSubGroup("");
      fetchGroupData();
    }
    if ("branchName" in changedValues) {
      form.setFieldValue("parentGroup", "");
      form.setFieldValue("subGroup", "");
      setSubGroupOption([]);
      setMainAccessGroupData(null);
      setFilteredTableData([]);
      setShowButtonChange(0);
      setSubGroup("");
      fetchGroupData();
    }
    if ("parentGroup" in changedValues) {
      setFilteredTableData([]);
      setShowButtonChange(0);
      form.setFieldValue("subGroup", "");
      setSubGroup("");
    }
    if ("subGroup" in changedValues) {
      setFilteredTableData([]);
      setShowButtonChange(0);
    }
  };

  const handleReset = () => {
    setFilterCity("");
    fetchTableData();
  };

  const fetchCompanies = async () => {
    setCompanyFetchLoading(true);
    const companies_data = await getCompanies(organizationId);

    if (companies_data.data) {
      const option: companiesOptiontype[] = companies_data.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      setCompaniesOption(option);

      if (companyId) {
        form.setFieldValue("companyName", companyId);
        fetchBranches(companyId);
      }
      setCompanyFetchLoading(false);
      fetchGroupData();
    } else {
      setCompanyFetchLoading(false);
    }
  };

  const fetchBranches = async (value: any) => {
    const valueToSend = value ? value : companyId;
    const data = {
      organizationId: organizationId,
      companyId: valueToSend,
    };
    let branchres = null;
    setBranchFetchLoading(true);
    setBranchOption([]);

    if (valueToSend) {
      branchres = await getBranches(data);
    }
    if (branchres?.data) {
      form.setFieldValue("branchName", branchres?.data[0]?.id);
      const option: companiesOptiontype[] = branchres.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      if (branchId) {
        form.setFieldValue("branchName", branchId);
      }
      setBranchOption(option);
      setBranchFetchLoading(false);
      fetchGroupData();
    }
    setBranchFetchLoading(false);
  };

  const fetchSubGroupOnParentGroup = async (value: any) => {
    try {
      setSubGroupLoading(true);
      const branchIdValue = branchId
        ? branchId
        : form.getFieldValue("branchName");
      const res = await fetchSubgroupBasedOnParentGroup(
        value,
        organizationId,
        branchIdValue
      );

      if (res.data) {
        setSubGroupOption(res?.data);
        setSubGroupLoading(false);
      }
    } catch (error) {
      setSubGroupLoading(false);
    }
  };

  const fetchGroupData = async () => {
    setshowAlert(false);

    try {
      if (form.getFieldValue("companyName")) {
        const companyIdValue = companyId
          ? companyId
          : form.getFieldValue("companyName");
        const branchIdValue = branchId
          ? branchId
          : form.getFieldValue("branchName");
        const Ids = {
          organizationId: organizationId,
          companyId: companyIdValue,
          branchId: branchIdValue,
        };
        setMainGroupLoading(true);

        const accessrights_res = await fetchTheGroupData(Ids);

        setMainAccessGroupData(accessrights_res?.data);
        setMainGroupLoading(false);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      setMainGroupLoading(false);
    }
  };

  const fetchTableData = async () => {
    try {
      if (form.getFieldValue("parentGroup")) {
        setTableLoader(true);
        setSearchBtnLoading(true);
        const id = subGroup ? subGroup : form.getFieldValue("parentGroup");
        const res = await fetchAccessRightTableDataById(id, organizationId);
        setFilteredTableData(res?.data?.access_rights);
        setTableLoader(false);
        setSearchBtnLoading(false);
        setDbTableRowId(id);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      setTableLoader(false);
      setSearchBtnLoading(false);
    }
  };

  /**
   * Handles the change event of a checkbox in the table.
   * @param {string} key - The key of the item in the table data.
   * @param {string} field - The field in the item data that is being toggled.
   * @returns None
   */
  const handleCheckboxChange = (key: string, field: string) => {
    const response = parentCheckboxChangeHandler(filteredTableData, key, field);
    setShowButtonChange(1);
    if (response) {
      setFilteredTableData(response);
    }
  };

  //function to record any changes  made in the child/expanded checkboxes
  const handleChildCheckboxChange = (
    parentKey: string,
    childKey: string,
    childField: string
  ) => {
    const response = childCheckboxChangeHanlder(
      filteredTableData,
      parentKey,
      childKey,
      childField
    );
    if (response) {
      setFilteredTableData(response);
    }

    setShowButtonChange(1);
  };

  const saveChanges = async () => {
    setTableLoader(true);
    setSaveChangeBtnLoading(true);
    setshowAlert(false);
    try {
      if (form.getFieldValue("parentGroup") && dbTableRowId) {
        const body = filteredTableData;
        const data = {
          dbTableRowId: dbTableRowId,
          body: body,
        };
        const accessrights_res = await handleSaveChanges(data, organizationId);
        setTableLoader(false);
        setSaveChangeBtnLoading(false);

        if (
          accessrights_res?.status === 200 ||
          accessrights_res?.status === 204
        ) {
          setshowAlert(true);
          setAlertType("success");
          form.resetFields();
          setFilteredTableData([]);
          setBranchOption([]);
          setSubGroupOption([]);
          setMainAccessGroupData(null);
          setSubGroup("");
          fetchCompanies();
        } else {
          setshowAlert(true);
          setAlertType("error");
        }
      }
    } catch {
      setshowAlert(true);
      setAlertType("error");
      setSaveChangeBtnLoading(false);
      setTableLoader(false);
    }
  };

  const columns = [
    {
      title: <span className="table-headings">Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name: any) => <strong>{capitalizeWords(name)}</strong>,
    },
    {
      title: <span className="table-headings">Read Access</span>,
      key: "readAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.readAccess}
          onChange={() => handleCheckboxChange(record.key, "readAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Write Access</span>,
      key: "writeAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.writeAccess}
          onChange={() => handleCheckboxChange(record.key, "writeAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Create Access</span>,
      key: "createAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.createAccess}
          onChange={() => handleCheckboxChange(record.key, "createAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Delete Access</span>,
      key: "deleteAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.deleteAccess}
          onChange={() => handleCheckboxChange(record.key, "deleteAccess")}
        />
      ),
    },
  ];

  const expandedColumns = [
    {
      title: <span className="table-headings">Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name: any) => <strong>{capitalizeWords(name)}</strong>,
    },
    {
      title: <span className="table-headings">Read Access</span>,
      key: "readAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.readAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "readAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Write Access</span>,
      key: "writeAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.writeAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "writeAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Create Access</span>,
      key: "createAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.createAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "createAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Delete Access</span>,
      key: "deleteAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.deleteAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "deleteAccess"
            )
          }
        />
      ),
    },
  ];

  const handleCreateClick = (record: any) => {
    setCreateAccessRights(record);
    setCreateModalVisible(true);
  };

  const handleFilterModalCancle = () => {
    setFilterModalVisible(false);
  };

  return (
    <>
      <div className="access-rights-main-table">
        <Row>
          <Col span={24} className="access-rights-create-btn-container">
            <TableHeadCommonButtons
              handleCreateClick={handleCreateClick}
              handleReset={handleReset}
              showFilterButton={false}
              create={create}
              read={read}
            />
          </Col>
        </Row>
        {read ? (
          <>
            <Form
              form={form}
              onValuesChange={handleChangedValues}
              layout="vertical"
            >
              <Row gutter={[12, 12]} align="middle">
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name={"companyName"} label={"Select Company"}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      className="search-set-width"
                      placeholder="Select Company"
                      onChange={(value) => {
                        fetchBranches(value);
                      }}
                      loading={companyFetchLoading}
                      disabled={companyId ? true : false}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companiesOption.map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name={"branchName"} label={"Select Branch"}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      className="search-set-width"
                      placeholder="Select Branch"
                      loading={branchFetchLoading}
                      onChange={(value) => {
                        setSubGroup("");
                      }}
                      disabled={branchId ? true : false}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branchOption.map((branch) => (
                        <Select.Option key={branch.id} value={branch.id}>
                          {branch.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item name={"parentGroup"} label={"Select Main Group"}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      className="search-set-width"
                      placeholder="Select Group"
                      onChange={(value) => {
                        fetchSubGroupOnParentGroup(value);
                      }}
                      loading={mainGroupLoading}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {mainAccessGroupData &&
                        mainAccessGroupData.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {capitalizeWords(item.group)}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name={"subGroup"}
                    label={"Select Sub Group"}
                    rules={
                      companyId
                        ? [
                            {
                              required: true,
                              message: "Please Select Subgroup",
                            },
                          ]
                        : undefined
                    }
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      className="search-set-width"
                      placeholder="Select Sub Group"
                      loading={subGroupLoading}
                      onChange={(value) => {
                        setSubGroup(value);
                      }}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {subGroupOption.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.sub_group_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={12}
                sm={24}
                xs={24}
                className="accessrights-col-btns"
              >
                <Button
                  className="global-btn-width"
                  type="primary"
                  icon={<RiUserSearchFill />}
                  onClick={() => fetchTableData()}
                  loading={searchBtnLoading}
                >
                  Search
                </Button>

                {form.getFieldValue("parentGroup") &&
                  filteredTableData &&
                  showButtonchanges > 0 &&
                  edit && (
                    <Button
                      className="global-btn-width access-right-edit-button"
                      type="primary"
                      icon={
                        <SaveFilled className="access-right-edit-btn-icon" />
                      }
                      onClick={() => saveChanges()}
                      loading={saveChangeBtnLoading}
                    >
                      Save Changes
                    </Button>
                  )}
              </Col>
            </Form>

            {filteredTableData && (
              <CustomTable
                className="table-class-accessrights"
                columns={columns}
                dataSource={filteredTableData}
                loading={tableLoader}
                bordered
                expandable={{
                  expandedRowRender: (record) => (
                    <CustomTable
                      columns={expandedColumns}
                      dataSource={record.child}
                    />
                  ),
                  rowExpandable: (record) =>
                    record.child && record.child.length > 0,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <a onClick={(e) => onExpand(record, e)}>
                        <MinusOutlined />
                      </a>
                    ) : (
                      <a onClick={(e) => onExpand(record, e)}>
                        <PlusOutlined />
                      </a>
                    ),
                }}
              />
            )}
          </>
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}

        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}

        <Modal
          className="centered-modal-company"
          title={"Filter"}
          open={filterModalVisible}
          footer={false}
          onCancel={handleFilterModalCancle}
        >
          <Col>
            <Row className="filter-input">
              <Text strong> Search by City </Text>
              <Input
                size="large"
                placeholder=""
                value={filterCity}
                onChange={(e) => setFilterCity(e.target.value)}
                onPressEnter={fetchTableData}
              />
            </Row>
            <Row>
              <Col span={8}>
                <Button
                  onClick={fetchTableData}
                  className="global-btn-width"
                  type="primary"
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Modal>
        {createAccessRights && (
          <Modal
            title="Create Access Rights"
            open={createModalVisible}
            onCancel={() => setCreateModalVisible(false)}
            width={1000}
            footer={false}
          >
            <CreateAccessRights />
          </Modal>
        )}
      </div>
    </>
  );
};

export default ViewAccessRights;
