import React, { useEffect, useState } from "react";
import "../index.css";
import TabsHeader from "../../../common/TabsHeader";
import { Card, Empty, Modal } from "antd";
import PolicyDocumentListView from "../PolicyDocument/View";
import DeductionCreateView from "../Deduction/Create";
import Timing from "../Timing/Create";
import OverTime from "../Overtime/Create";
import AbsentPolicy from "../Absent/Create";
import LeavesPolicy from "../Leaves/Create";
import CreatePolicyDocument from "../PolicyDocument/Create";
import { selectAccess } from "../../../../redux/AccessRight";
import { useSelector } from "react-redux";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import TimingEmployeeView from "../Timing/EmployeeView";
import OvertimeEmployeeView from "../Overtime/EmployeeView";
import AbsentEmployeeView from "../Absent/EmployeeView";
import LeavesEmployeeView from "../Leaves/EmployeeView";
import GeneralPolicyEmployeeView from "../PolicyDocument/EmployeeView";

const PolicyDocumentViewForEmployee: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const accessObj = useSelector(selectAccess);
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [selected, setSelected] = useState("Timings");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);

  const radioOptions = [
    {
      label: "Timings",
      value: "Timings",
      create: false,
      filter: false,
      showSearch: false,
    },
    {
      label: "Overtime",
      value: "Overtime",
      create: false,
      filter: false,
      showSearch: false,
    },
    {
      label: "Absents",
      value: "Absents",
      create: false,
      filter: false,
      showSearch: false,
    },
    {
      label: "Leaves",
      value: "Leaves",
      create: false,
      filter: false,
      showSearch: false,
    },
    {
      label: "General Policy",
      value: "General Policy",
      create: false,
      filter: false,
      showSearch: false,
    },
  ];

  useEffect(() => {
    handleRadioChange(selected);
  }, [selected]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Policies";
    let childValue =
      value == "Timings"
        ? "Timing Policy"
        : value == "Overtime"
        ? "Overtime Policy"
        : value == "Absents"
        ? "Absent Policy"
        : value == "Leaves"
        ? "Leaves Policy"
        : "General Policy";

    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={radioOptions}
            onChange={(value) => {
              setSelected(value);
            }}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Timings" ? (
                <TimingEmployeeView />
              ) : selected === "Overtime" ? (
                <OvertimeEmployeeView />
              ) : selected === "Absents" ? (
                <AbsentEmployeeView />
              ) : selected === "Leaves" ? (
                <LeavesEmployeeView />
              ) : (
                <GeneralPolicyEmployeeView />
              )
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>

          <Modal
            title={
              <span className="global-modal-title">Create {selected}</span>
            }
            open={createModalVisible}
            onCancel={handleCreateModalCancel}
            width={1000}
            footer={false}
            className="global-modal-main-container"
            destroyOnClose
          >
            {createModalVisible && selected === "Deductions" && (
              <DeductionCreateView />
            )}
            {createModalVisible && selected === "Timings" && <Timing />}
            {createModalVisible && selected === "Overtime" && <OverTime />}
            {createModalVisible && selected === "Absents" && <AbsentPolicy />}
            {createModalVisible && selected === "Leaves" && (
              <LeavesPolicy onCancel={handleCreateModalCancel} />
            )}
            {createModalVisible && selected === "General Policy" && (
              <GeneralPolicyEmployeeView />
            )}
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default PolicyDocumentViewForEmployee;
