import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  Form,
  DatePicker,
  Divider,
  Select,
  AutoComplete,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { insertBankDetailsApi } from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { isValidIBAN, validateIBAN } from "ibantools";
import { banks } from "../../../common/Constants";
import { getCountries } from "vk-country-state-city";
import { generateIBAN } from "../../../common/validation";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

const BankDetails: React.FC = () => {
  const [form] = Form.useForm();
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const bank_details = useSelector(selectEmployee).Employee.BankDetails;
  const currentCreatingEmployeeId =
    useSelector(selectEmployee)?.Employee?.EmployeeId;
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [countries, setCountries] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );
  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const company_id =
    useSelector(selectEmployee).Employee?.PersonalDetails?.selectedCompanyId;
  const branch_id =
    useSelector(selectEmployee).Employee?.PersonalDetails?.selectedBranchId;

  useEffect(() => {
    fetchCountries();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  async function fetchCountries() {
    const countries = await getCountries();
    const countryOptions = countries.map((country: any) => ({
      value: country.name,
      label: `${country.name}`,
    }));
    setCountries(countryOptions);
  }
  const handleSubmit = async (values: any) => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const dateEffective = values.effectiveUpTo.format("YYYY-MM-DD");

      const body = {
        bank_information: [
          {
            id: Date.now(),
            bankName: values.bankName,
            branchName: values.branchName,
            branchCode: values.branchCode,
            accountTitle: values.accountTitle,
            accountNumber: values.accountNumber,
            dateEffective: dateEffective,
            iban: values.iban,
            country: values.country,
          },
        ],
        organization_id: organizationId,
        employee_id: currentCreatingEmployeeId,
        company_id: company_id,
        branch_id: branch_id,
      };

      const BankDetails_res = await insertBankDetailsApi(body);

      if (BankDetails_res.status === 201) {
        dispatch(
          setEmployeeProfileDetails({
            Employee: {
              BankDetails: {
                bankName: values.bankName,
                branchName: values.branchName,
                branchCode: values.branchCode,
                accountTitle: values.accountTitle,
                accountNumber: values.accountNumber,
                EffectiveUpTo: dateEffective,
                country: values.country,
                iban: values.iban,
              },
            },
          })
        );
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (BankDetails_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  return (
    <div>
      <h3>Bank Details</h3>
      <Divider />
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          bankName: bank_details?.bankName || "",
          branchName: bank_details?.branchName || "",
          branchCode: bank_details?.branchCode || "",
          accountTitle: bank_details?.accountTitle || "",
          accountNumber: bank_details?.accountNumber || "",
          effectiveUpTo: bank_details?.EffectiveUpTo
            ? dayjs(bank_details?.EffectiveUpTo, "YYYY-MM-DD")
            : "",
          iban: bank_details?.iban,
          country: bank_details?.country,
        }}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Country"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {countries.map((item: any) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Enter/Select Bank Name"
              name="bankName"
              rules={[{ required: true, message: "Please Enter Bank Name" }]}
            >
              <AutoComplete
                options={options}
                onSearch={handleSearch}
                placeholder="Select bank..."
                allowClear
                filterOption={(inputValue, option) =>
                  option?.label
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) !== -1
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Branch Name" name="branchName">
              <Input size="large" placeholder="Enter Branch Name" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Branch Code" name="branchCode">
              <Input size="large" placeholder="Enter Branch Code" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Account Title"
              name="accountTitle"
              rules={[
                { required: true, message: "Please Enter Account Title" },
              ]}
            >
              <Input size="large" placeholder="Enter Account Title" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Account Number"
              name="accountNumber"
              rules={[
                { required: true, message: "Please Enter Account Number" },
              ]}
            >
              <Input size="large" placeholder="Enter Account Number" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="IBAN"
              name="iban"
              rules={[
                { required: true, message: "Please Enter IBAN" },
                {
                  validator: (_, value) =>
                    value && isValidIBAN(value)
                      ? Promise.resolve()
                      : Promise.reject(new Error("Invalid IBAN number")),
                },
              ]}
            >
              <Input size="large" placeholder="Enter Account Number" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label="Effective up to" name="effectiveUpTo">
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
              />
            </Form.Item>
          </Col>
          {!bank_details && (
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Save progress
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default BankDetails;
