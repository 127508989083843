import { useEffect, useRef, useState } from "react";
import PlaceholderAvatar from "../../../assets/images/blankProfile.png";
import {
  Card,
  Col,
  Dropdown,
  Menu,
  Modal,
  Progress,
  Row,
  Tooltip,
  Avatar,
  Typography,
  Tag,
  Popconfirm,
  Drawer,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  MoreOutlined,
} from "@ant-design/icons";
import { capitalizeWords } from "../../../common/validation";
import "./index.css";
import { BsFillSendFill } from "react-icons/bs";
import SendEmail from "../SendEmail";
import CustomAlert from "../../../common/Alert";
import EmployeeDeleteModel from "../EmployeeDeleteModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { handleResendApi } from "../../../services/Global/index";
import {
  ActiveEmployeeByIdApi,
  fetchHeadDetailByIdApi,
  fetchReportToEmployeebyEmployeeIdApi,
  inActiveEmployeeByIdApi,
} from "./sevices";
import { VscLayersActive } from "react-icons/vsc";
import { v4 as uuidv4 } from "uuid";
import EmployeeDrawer from "../../../common/EmployeeDrawer";

const { Title, Text } = Typography;
const EmployeeProfileCard: React.FC<any> = ({ employee, onCancel }) => {
  const navigate = useNavigate();
  const logedInemployee = useSelector(selectLogedEmployee);
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [showEmailSentData, setEmailSentData] = useState<any>();
  const [showEmailSentModel, setShowEmailSentModel] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showNotDeleteModel, setShowNotDeleteModel] = useState<boolean>(false);
  const [companyDetails, setComapnyDetails] = useState<any>();
  const [deletedEmployee, setDeletedEmployee] = useState<any>();
  const [deletedEmployeeId, setDeletedEmployeeId] = useState<number | string>();

  const loggedInEmployeeId = logedInemployee?.logedInemployee?.employeeId;
  const orgId = logedInemployee?.logedInemployee?.employee?.organization_id
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * useEffect hook that clears the timeout when the component unmounts.
   * @returns None
   */
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleOpenEmailSentModel = (record: any) => {
    setEmailSentData(record);
    setShowEmailSentModel(true);
  };

  const handleEmailModelCancel = () => {
    setShowEmailSentModel(false);
  };
  const handleDeleteConfirm = async (editedEmployee: any) => {
    try {
      setDeletedEmployeeId(editedEmployee.employee_id);
      setComapnyDetails({
        company_id: editedEmployee?.company_id,
        branch_id: editedEmployee?.branch_id,
        organizationId: editedEmployee?.organization_id,
      });
      const deleteModelData = {
        team_head: [],
        department_head: [],
        branch_head: [],
        report_to_Employee: [],
      };
      setShowNotDeleteModel(false);
      const res = await fetchHeadDetailByIdApi(editedEmployee.employee_id, editedEmployee?.organization_id);

      if (res.data && res.data.length > 0) {
        let Check = "team_head";
        //Check Head For Branch
        if (Check == "team_head") {
          const uniqueTeamHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.team_head) {
              uniqueTeamHeads.add(item.team_head);
            }
          });
          if (uniqueTeamHeads.size > 0) {
            const uniqueTeamArray = Array.from(uniqueTeamHeads);
            const team_head = res.data.filter((item: any) => {
              return uniqueTeamArray.includes(item.team_head);
            });
            const departementHeadWithKeys = team_head.map((item: any) => ({
              ...item,
              uniqueKey: uuidv4(),
            }));
            deleteModelData.team_head = departementHeadWithKeys;
          } else {
            Check = "department_head";
          }
        }
        // Check Head For Department
        if (Check == "department_head") {
          const uniqueDepartmentHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.department_head) {
              let exists = false;
              for (let obj of uniqueDepartmentHeads) {
                if ((obj as any).department_head === item.department_head) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                uniqueDepartmentHeads.add(item);
              }
            }
          });
          if (uniqueDepartmentHeads.size > 0) {
            const uniqueDepartmentsArray = Array.from(
              uniqueDepartmentHeads
            ) as any;
            const departementHeadWithKeys = uniqueDepartmentsArray.map(
              (item: any) => ({
                ...item,
                uniqueKey: uuidv4(),
              })
            );
            deleteModelData.department_head = departementHeadWithKeys;
          } else {
            Check = "branch_head";
          }
        }
        // Check Head For Branch
        if ((Check = "branch_head")) {
          const uniqueBranchHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.branch_head) {
              let exists = false;
              for (let obj of uniqueBranchHeads) {
                if ((obj as any).branch_head === item.branch_head) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                uniqueBranchHeads.add(item);
              }
            }
          });
          if (uniqueBranchHeads.size > 0) {
            const uniqueBranchArray = Array.from(uniqueBranchHeads) as any;
            const branchHeadWithKeys = uniqueBranchArray.map((item: any) => ({
              ...item,
              uniqueKey: uuidv4(),
            }));
            deleteModelData.branch_head = branchHeadWithKeys;
          }
        }
      } else if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      }
      //now we will check any any employee repoted him
      const employees_head = await fetchReportToEmployeebyEmployeeIdApi(
        editedEmployee.employee_id,
        editedEmployee?.organization_id
      );

      if (employees_head.data && employees_head.data.length > 0) {
        const employeeHeadWithKeys = employees_head.data.map((item: any) => ({
          ...item,
          uniqueKey: uuidv4(),
        }));
        deleteModelData.report_to_Employee = employeeHeadWithKeys as any;
      } else if (employees_head.error) {

        setshowAlert(true);
        setAlertType("error");
      }
      // Check if any key has an array length greater than 0
      const hasNonEmptyArray = Object.values(deleteModelData).some(
        (arr) => arr.length > 0
      );
      if (!hasNonEmptyArray) {
        const inactiveRes = await inActiveEmployeeByIdApi(
          editedEmployee.employee_id,
          editedEmployee?.organization_id
        );

        if (inactiveRes.status == 204) {
          setshowAlert(true);
          setAlertType("success");
          setTimeout(() => {
            onCancel()
          }, 1000)

        } else if (inactiveRes.error) {
          setshowAlert(true);
          setAlertType("error");


        }
      } else {
        setShowNotDeleteModel(true);
        setDeletedEmployee(deleteModelData);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const handleDeleteModalCancel = () => {
    setDeletedEmployee(null);
    setDeletedEmployeeId("");
    setShowNotDeleteModel(false);
  };

  const formatJoiningDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  /**
   * Handles the click event on a view button for a specific record.
   * If the record has an id, it navigates to the employee details page of the corresponding organization.
   * @param {any} record - The record object for which the view button is clicked.
   * @returns None
   */
  const handleViewClick = (record: any) => {
    if (record.employee_id) {
     
      navigate(`/employee/${record.employee_id}`);
    }
  };

  const showDrawer = (record: any) => {
    setOpenDrawer(true);
   };

  const onClose = () => {
    setOpenDrawer(false);
  };
  /**
   * Handles the resend functionality for a given record by calling the handleResendApi
   * function with the record's work email. If the API call is successful, it sets the
   * showAlert state to true with a success alert type. If there is an error during the
   * API call, it sets the showAlert state to true with an error alert type and sets a
   * timeout to hide the alert after 3 seconds.
   * @param {any} record - The record for which the resend functionality is triggered.
   * @returns None
   */
  const handleResend = async (record: any) => {
    try {
      const data = {
        email: record.workEmail,
        employeeId: record.employee_id,
      };
      const res = await handleResendApi(data,orgId);
      if (res.data) {
        setshowAlert(true);
        setAlertType("success");
      }
    } catch {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  const handleActiveConfirm = async (record: any) => {
    try {
      const res = await ActiveEmployeeByIdApi(
        record.employee_id,
        logedInemployee?.logedInemployee?.employee?.organization_id
      );
      if (res.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        setTimeout(() => {
          onCancel()
        }, 1000)
      }
    } catch {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <Card bordered={true} className="employee-profile-card">
        <div>
          <div className="employee-profile-edit-icon">
            <Dropdown
              overlay={
                <Menu>
                  {/* <Menu.Item
                    key="view"
                    onClick={() => handleViewClick(employee)}
                  >
                    <EyeFilled /> View
                  </Menu.Item> */}
                  <Menu.Item
                    key="edit"
                    onClick={() => handleViewClick(employee)}
                  >
                    <EditOutlined /> Edit
                  </Menu.Item>
                  {(employee.status === "Created" || employee.status === "Listed") && (
                    <Menu.Item
                      key="send"
                      onClick={() => handleOpenEmailSentModel(employee)}
                    >
                      <BsFillSendFill /> Send Email
                    </Menu.Item>
                  )}
                  {employee.status === "Invited" && (
                    <Menu.Item
                      key="send"
                      onClick={() => handleResend(employee)}
                    >
                      <BsFillSendFill /> Resend Email
                    </Menu.Item>
                  )}
                  {(employee.status === "active") &&
                    employee?.employee_id !== loggedInEmployeeId && (
                      <Menu.Item key="delete" className="employee-inactive-tag">
                        <Popconfirm placement="topLeft"
                          title="Are you sure you want to InActive this record?"
                          okText="Ok"
                          cancelText="Cancel"
                          onConfirm={() => handleDeleteConfirm(employee)}
                        >
                          <DeleteOutlined className="globel-red-icon-color"/> 
                          <span className="inactive-color"> Inactive</span>
                          
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  {employee.status === "inactive" &&
                    employee?.employee_id !== loggedInEmployeeId && (
                      <Menu.Item key="delete">
                        <Popconfirm placement="topLeft"
                          title="Are you sure you want to Active this record?"
                          okText="Ok"
                          cancelText="Cancel"
                          onConfirm={() => handleActiveConfirm(employee)}
                        >
                          <VscLayersActive /> Active
                        </Popconfirm>
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={["click"]}
            >
              <span
                className="ant-dropdown-link link"
                onClick={(e) => e.preventDefault()}
              >
                <MoreOutlined />
              </span>
            </Dropdown>
          </div>
          <div
            className="emloyee-card-inner-div"
            onClick={() =>  showDrawer(employee)}
          >
            <Row className="employee-card-avatar-row">
              <Col>
                <Avatar
                  size={80}
                  shape="square"
                  className="employee-card-avatar-style"
                  src={
                    employee?.avatar_url
                      ? employee?.avatar_url
                      : PlaceholderAvatar
                  }
                />
              </Col>
            </Row>
            <Row className="employee-card-name-row">
              <Col>
                <Title className="employee-card-title-name-style">
                  {employee?.full_name
                    ? capitalizeWords(employee?.full_name)
                    : "No Data"}
                </Title>
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              className="employee-card-tag-style"
            >
              <Col>
                <Tag
                  className="employee-card-tag"
                  color="rgb(34 176 199)"
                  
                >
                  <Text
                    className="employee-card-tag-text-style employee-card-cap-all-words"
                    strong
                  >
                    {employee?.job_title_name ? (
                      capitalizeWords(employee.job_title_name)
                    ) : (
                      <Tooltip
                        title={`Specify ${employee?.full_name} role for better organization.`}
                      >
                        <span>--</span>
                      </Tooltip>
                    )}
                  </Text>
                </Tag>
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              className="employee-card-progress-bar-row"
            >
              <div className="employee-card-progressbar">
                <Progress
                  size="small"
                  percent={Math.round(employee.completion_percentage || 0)}
                  trailColor={"rgba(217, 217, 217, 1)"}
                  strokeColor={"rgb(48 146 142)"}
                  
                />
              </div>
            </Row>

            <div className="employee-card-details">
              <Row className="employee-card-deparment">
                <p className="employee-card-email-style">Joining</p>
                <div className="employee-card-Email">
                  <div className="employee-card-right-Col">
                    {employee?.joining_date ? (
                      formatJoiningDate(employee.joining_date)
                    ) : (
                      <Tooltip
                        title={`${employee.full_name} Joining date information is not provided.`}
                      >
                        <span>--</span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Row>

              <Row className="employee-card-deparment">
                <p className="employee-card-deparment-style">Department</p>
                <div className="employee-card-right-Col">
                  {employee?.department_name ? (
                    employee?.department_name
                  ) : (
                    <Tooltip
                      title={`${employee?.full_name} Department information is not provided.`}
                    >
                      <span>--</span>
                    </Tooltip>
                  )}
                </div>
              </Row>

              <Row className="employee-card-Branch">
                <p className="employee-card-branch-style">Branch</p>

                <div className="employee-card-right-Col">
                  {employee?.branch_name ? (
                    employee?.branch_name
                  ) : (
                    <Tooltip
                      title={`${employee?.full_name} Branch information is not provided.`}
                    >
                      <span>--</span>
                    </Tooltip>
                  )}
                </div>
              </Row>
            </div>
          </div>
          <Drawer
            placement="right"
            closable={true}
            headerStyle={{ display: "none" }}
            onClose={onClose}
            width={"600px"}
            className="employee-detail-drawer"
            visible={openDrawer}
          >
            <EmployeeDrawer record={employee} />
          </Drawer>
        </div>
      </Card>
      {showEmailSentModel && (
        <Modal
          title={`Send Email Invitation`}
          onCancel={handleEmailModelCancel}
          open={showEmailSentModel}
          width={1000}
          footer={false}
        >
          <SendEmail
            record={showEmailSentData}
            onCancel={handleEmailModelCancel}
          />
        </Modal>
      )}
      {showNotDeleteModel && deletedEmployee && (
        <Modal
          className="global-modal-main-container"
          title={<span className="global-modal-title">Can't in-active employee</span>}
          onCancel={handleDeleteModalCancel}
          open={showNotDeleteModel}
          width={1000}
          footer={false}
        >
          <EmployeeDeleteModel
            record={deletedEmployee}
            headid={deletedEmployeeId}
            onCancel={() => setShowNotDeleteModel(false)}
            comapnayDetails={companyDetails}
          />
        </Modal>
      )}
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default EmployeeProfileCard;
