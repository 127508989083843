import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Row,
  DatePicker,
  Col,
  Input,
  Upload,
  Select,
} from "antd";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { UploadOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../common/Alert";
import { employeeAssets } from "../../CommonFiles";
import dayjs from "dayjs";
import { getSingleAssetDataApi, updateAssetsApi } from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface EditAssets {
  visible: boolean;
  onCancel: () => void;
  recordId: any;
  getAllAssetData: () => void;
}
const EditAssets: React.FC<EditAssets> = ({
  visible,
  onCancel,
  recordId,
  getAllAssetData,
}) => {
  const [form] = useForm();
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [imageToBase64, setImageToBase64] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  function getBase64(file: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  const conditionOptions = ["New", "Old"];

  const employeeAssetOptions = employeeAssets;

  let uploadedImageFile: any = null;

  const receiveResponse = async () => {
    const response = await getSingleAssetDataApi(recordId, organizationId);
    if (response.data) {
      const data = response.data[0];
      form.setFieldsValue({
        assetName: data.name,
        assetType: data.type,
        identificationNumber: data.unique_identifier,
        manufacturer: data.manufacturer,
        condition: data.condition,
        model: data.model,
        currentValue: data.current_value,
        acquiredDate: dayjs(data.acquired_date),
      });
    }
  };

  useEffect(() => {
    receiveResponse();
  }, [recordId]);

  const updateRecord = async () => {
    try {
      const formData = await form.validateFields();
      const updateData = {
        ...formData,
        acquiredDate: formData.acquiredDate.format("YYYY-MM-DD"),
      };
      setButtonLoading(true);
      const response = await updateAssetsApi(
        updateData,
        recordId,
        organizationId
      );

      if (response.status === 204) {
        setshowAlert(true);
        setAlertType("success");
        receiveResponse();

        setButtonLoading(false);
        getAllAssetData();
      }
      setButtonLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <Modal
        width={1000}
        footer={null}
        open={visible}
        onCancel={onCancel}
        title={<span className="global-modal-title">Edit Asset Details</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Form layout="vertical" form={form}>
            <Row>
              <Col>
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <Form.Item
                      name={"assetName"}
                      label="Asset Name"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter the asset name..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name={"assetType"}
                      label="Asset Type"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Select asset.."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {employeeAssetOptions.map((item: any) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      label="Identification No."
                      name={"identificationNumber"}
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        size="large"
                        placeholder="Enter identification number..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name={"manufacturer"}
                      label="Manufacturer"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter the manufacturer..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      label="Condition of product"
                      name={"condition"}
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a company..."
                        allowClear
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {conditionOptions.map((item: any) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24} md={12}>
                    <Form.Item
                      name={"model"}
                      label="Model"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter the model..." />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item name={"productImage"} label="Attachment">
                      <Upload
                        style={{ minWidth: "100%" }}
                        name="productImage"
                        beforeUpload={(file: any) => {
                          getBase64(file, (base64: any) => {
                            setImageToBase64(base64);
                          });

                          return false;
                        }}
                        onRemove={() => {
                          uploadedImageFile = null;
                        }}
                        accept=".png, .jpeg, .jpg"
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name={"currentValue"}
                      label="Current Value"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty.",
                        },
                        {
                          validator: (_, value) => {
                            if (!value || isNaN(Number(value))) {
                              return Promise.reject(
                                new Error("Please enter numeric values only")
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter current value of asset"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name={"acquiredDate"}
                      label="Acquired Date"
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <DatePicker name="acquiredDate" className="date-picker" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="global-modal-bottom-row submission-btn">
                  <Button
                    className="global-btn-width"
                    type="primary"
                    onClick={updateRecord}
                    loading={buttonLoading}
                    size="large"
                  >
                    Update
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default EditAssets;
