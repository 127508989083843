import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Input,
  Checkbox,
  Form,
  Select,
  InputNumber,
  Button,
  Steps,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import "./index.css";
import { DeleteOutlined } from "@ant-design/icons";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { IoIosAddCircle } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { fetcAllEmployeeApi } from "../../../../services/Global";
import dayjs from "dayjs";
import {
  allBranchesForCompanyDataApi,
  allOrgCompaniesDataApi,
} from "../../Deduction/services";
import {
  leavePolicyCreationVersion,
  setLeavePolicyCreation,
} from "../services";
import { handleInputChangeHandler } from "../viewmodal";
import { DatePicker } from "antd";
import { LEAVE_OPTIONS } from "../../../../common/Constants";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Step } = Steps;

interface LeavesPolicyProps {
  onCancel?: () => void;
}

const LeavesPolicy: React.FC<LeavesPolicyProps> = ({ onCancel }) => {
  const { Option } = Select;
  const [form] = useForm();

  const [submissionOptions, setSubmissionOptions] = useState<any[]>([]);
  const [tempVersion, setTempVersion] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(0);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [availableLeaveOptions, setAvailableLeaveOptions] =
    useState<any>(LEAVE_OPTIONS);
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [filteredEmployeeSelectLoading, setfilteredEmployeeSelectLoading] =
    useState<boolean>(false);
  const [slabs, setSlabs] = useState<any[]>([
    { id: Date.now(), priority: true, employeeID: "admin" },
    { id: Date.now(), priority: true, employeeID: "hr" },
    { id: Date.now(), priority: false, employeeID: "manager" },
  ]);
  const [maxConsecutiveAllowed, setMaxmaxConsecutiveAllowed] = useState<any>(0);
  const [filteredEmployee, setFilteredEmployee] = useState<any[]>([]);
  const [selectedEmployeeIdArray, setSelectedEmployeeIdArray] = useState<any[]>(
    []
  );
  const [isHighPriorityDisabled, setIsHighPriorityDisabled] =
    useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0);
  const [isConsecutiveAllowed, setIsConsectiveAllowed] =
    useState<string>("Yes");
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee.organization_id;
  const companyId = loggedInEmployee?.logedInemployee?.employee?.company_id;
  const branchId = loggedInEmployee?.logedInemployee?.employee?.branch_id;
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "Max Allowed",
      dataIndex: "maxAllowed",
      key: "maxAllowed",
    },
    {
      title: "Consecutive Allowed",
      dataIndex: "consecutiveAllowed",
      key: "consecutiveAllowed",
    },
    {
      title: "Maximum Consecutive Allowed",
      dataIndex: "maxConsecutiveAllowed",
      key: "maxConsecutiveAllowed",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <a onClick={() => handleRemove(record.id)}>
          <DeleteOutlined />
        </a>
      ),
    },
  ];

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
      setSelectedBranch(branchId);
    } else {
      if (selectedCompany) {
        getBranches();
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    getVersion();
  }, [counter]);

  useEffect(() => {
    fetchEmployeeData();
  }, [
    selectedCompany,
    selectedBranch,
    form.getFieldValue("branchId"),
    organizationId,
  ]);

  const handleRemove = (id: any) => {
    setTableData((prevData) => {
      const removedItem = prevData.find((item) => item.id === id);
      const updatedAvailableOptions = [
        ...availableLeaveOptions,
        removedItem.leaveType,
      ];

      setAvailableLeaveOptions(updatedAvailableOptions);
      return prevData.filter((item) => item.id !== id);
    });
  };

  const getCompanies = async () => {
    setCompanySelectLoading(true);
    const { data, error } = await allOrgCompaniesDataApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const getBranches = async (companyId?: number) => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      const companyIdIncoming = form.getFieldValue("company")
        ? form.getFieldValue("company")
        : companyId;
      const { data, error } = await allBranchesForCompanyDataApi(
        companyIdIncoming,
        organizationId
      );

      if (data) {
        setFilteredBranches(data);
        form.setFieldValue("branchId", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  const getVersion = async () => {
    const { data, error } = await leavePolicyCreationVersion(
      organizationId,
      companyId
    );

    if (data) {
      const tempVersionValue = "Version #" + (data.length + 1);
      setTempVersion(tempVersionValue);
      form.setFieldsValue({
        policyName: tempVersionValue,
      });
    }
  };

  const handleSubmit = (values: any) => {
    setTableData((prevData) => [
      ...prevData,
      {
        ...values,
        companyYearStart: dayjs(form.getFieldValue("companyYearStart")).format(
          "MM-YYYY"
        ),
        companyYearEnd: dayjs(form.getFieldValue("companyYearEnd")).format(
          "MM-YYYY"
        ),
        id: prevData.length + 1,
      },
    ]);

    const newAvailableOptions = availableLeaveOptions.filter(
      (option: any) => option !== values.leaveType
    );
    setAvailableLeaveOptions(newAvailableOptions);

    form.resetFields([
      "companyYearStart",
      "companyYearEnd",
      "leaveType",
      "maxAllowed",
      "consecutiveAllowed",
      "maxConsecutiveAllowed",
    ]);
    setMaxmaxConsecutiveAllowed(0);
    setIsConsectiveAllowed("Yes");
  };

  const submitToSupabase = async () => {
    let total_allowed_leave = 0;
    tableData.map((item) => {
      total_allowed_leave += item.maxAllowed;
    });
    const insertData = {
      version_name: form.getFieldValue("policyName"),
      policy_types: tableData,
      organization_id: organizationId,
      company_id: form.getFieldValue("company") || companyId || null,
      branch_id: form.getFieldValue("branchId") || branchId || null,
      slabs: slabs,
      deduction: form.getFieldValue("deduction"),
      total_allowed_leave: total_allowed_leave,
    };

    try {
      setButtonLoading(true);
      const { data, error } = await setLeavePolicyCreation(insertData);
      if (error) {
        setshowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }

      form.resetFields();
      setTableData([]);
      setshowAlert(true);
      setAlertType("success");
      setAvailableLeaveOptions(LEAVE_OPTIONS);
      setSlabs([
        { id: Date.now(), priority: true, employeeID: "admin" },
        { id: Date.now(), priority: true, employeeID: "hr" },
        { id: Date.now(), priority: false, employeeID: "manager" },
      ]);
      setFilteredEmployee([]);
      setSelectedEmployeeIdArray([]);
      setCurrentStep(0);
      setButtonLoading(false);
      setFilteredBranches([]);
      setCompanies([]);
      setSelectedCompany(null);
      setSelectedBranch(null);
      getCompanies();
      if (onCancel) {
        onCancel();
      }

      setCounter(counter + 1);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const handleAddSlab = () => {
    setSlabs([...slabs, { id: Date.now(), priority: false }]);
    const filteredEmployees = filteredEmployee?.filter(
      (employee: any) => !selectedEmployeeIdArray?.includes(employee.id)
    );

    const count = slabs?.filter((item: any) => item?.priority === true).length;

    if (count >= 4) {
      setIsHighPriorityDisabled(true);
    } else {
      setIsHighPriorityDisabled(false);
    }

    setFilteredEmployee(filteredEmployees);
  };

  const handleRemoveSlab = (slabId: string, employeeId: number) => {
    const updatedSlabs = slabs?.filter((slab) => slab?.id !== slabId);
    setSlabs(updatedSlabs);
    setSelectedEmployeeIdArray((previousIds) =>
      previousIds.filter((id) => id !== employeeId)
    );
    setSelectedEmployeeId(0);
    const count = slabs?.filter((item: any) => item?.priority === true).length;
    if (count < 4) {
      setIsHighPriorityDisabled(false);
    } else {
      setIsHighPriorityDisabled(true);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      setSelectedBranch(branchId);
      const branchIdIncoming = form.getFieldValue("branchId")
        ? form.getFieldValue("branchId")
        : branchId;
      setFilteredEmployee([]);
      if (selectedCompany) {
        setfilteredEmployeeSelectLoading(true);
        const res = await fetcAllEmployeeApi(
          organizationId,
          selectedCompany,
          branchIdIncoming
        );
        setFilteredEmployee(res?.data);
      }
      setfilteredEmployeeSelectLoading(false);
    } catch (err) {}
  };

  const handleInputChange = (
    slabId: string,
    field: string,
    value: string | boolean
  ) => {
    const updatedSlabs = handleInputChangeHandler(slabs, slabId, field, value);
    if (updatedSlabs) {
      setSlabs(updatedSlabs);
    }
  };

  const nextStep = async () => {
    try {
      setCurrentStep(currentStep + 1);
    } catch (error) {}
  };

  const steps = [
    {
      title: "General Policies",
      content: (
        <Form
          layout="vertical"
          form={form}
          initialValues={{ consecutiveAllowed: "Yes" }}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            {!companyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name={"company"}
                  label="Select a company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company",
                    },
                  ]}
                >
                  <Select
                    disabled={disabled}
                    placeholder="Select a company..."
                    onChange={(value) => setSelectedCompany(value)}
                    loading={companySelectLoading}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item name="branchId" label="Select Branch (If any)">
                  <Select
                    disabled={disabled}
                    placeholder="Select a Branch"
                    allowClear
                    loading={branchSelectLoading}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24} md={8}>
              <Form.Item name={"policyName"} label="Policy Name">
                <Input
                  value={tempVersion}
                  size="large"
                  placeholder="Enter policy name..."
                ></Input>
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                name={"companyYearStart"}
                label="Year Start"
                rules={[
                  {
                    required: true,
                    message: "Please select a month",
                  },
                ]}
              >
                <DatePicker
                  picker="month"
                  placeholder="Select Month"
                  className="leave-policy-date-picker"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={8}>
              <Form.Item
                name={"companyYearEnd"}
                label="Year End"
                rules={[
                  {
                    required: true,
                    message: "Please select a month",
                  },
                ]}
              >
                <DatePicker
                  picker="month"
                  placeholder="Select Month"
                  disabledDate={(current: any) => {
                    return (
                      current &&
                      current < dayjs(form.getFieldValue("companyYearStart"))
                    );
                  }}
                  className="leave-policy-date-picker"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={6}>
              <Form.Item
                name={"leaveType"}
                label="Leave Types"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a leave type"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {availableLeaveOptions.map((item: any) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Maximum Allowed"
                name="maxAllowed"
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  className="global-input-number-width"
                  placeholder="Enter Max Allowed"
                  onChange={(e) => setMaxmaxConsecutiveAllowed(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                label="Consecutive Allowed"
                name="consecutiveAllowed"
                rules={[{ required: true, message: "Select an option" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Consecutive Allowed"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                  onChange={(e) => setIsConsectiveAllowed(e)}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            {isConsecutiveAllowed === "Yes" && (
              <Col span={24} md={6}>
                <Form.Item
                  label="Max Consecutive Allowed"
                  name="maxConsecutiveAllowed"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) =>
                        value > maxConsecutiveAllowed
                          ? Promise.reject(
                              new Error(
                                `Value must be less than ${maxConsecutiveAllowed}`
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    size="large"
                    className="global-input-number-width"
                    placeholder="Enter Max Consecutive Allowed"
                    disabled={isConsecutiveAllowed == "Yes" ? false : true}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  rows={3}
                  style={{ width: "100%" }}
                  placeholder="Enter Description..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="submit-policy-btn-col">
            <Button
              className="add-policy-btn"
              size="large"
              htmlType="submit"
              type="primary"
            >
              Add to Table
            </Button>
          </Row>
        </Form>
      ),
    },
    {
      title: "Priority Designation Policy",
      content: (
        <Form layout="vertical" form={form} onFinish={submitToSupabase}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {slabs.map((slab) => (
                <div key={slab.id}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                      <Form.Item
                        name={`${slab.id}employee`}
                        label={`Select Tier`}
                        rules={[
                          {
                            required:
                              slab?.employeeID === "manager" || "admin" || "hr"
                                ? false
                                : true,
                            message: "Please select an option",
                          },
                        ]}
                      >
                        {slab?.employeeID === "admin" ? (
                          <Input
                            disabled
                            value={"Admin"}
                            size="large"
                            placeholder="Admin"
                          />
                        ) : slab?.employeeID === "hr" ? (
                          <Input
                            disabled
                            value={"Hr"}
                            size="large"
                            placeholder="Hr"
                          />
                        ) : slab?.employeeID === "manager" ? (
                          <Input
                            disabled
                            value={"Manager"}
                            size="large"
                            placeholder="Manager"
                          />
                        ) : (
                          <Select
                            placeholder="Select an employee"
                            allowClear
                            loading={filteredEmployeeSelectLoading}
                            onChange={(value) => {
                              setSelectedEmployeeIdArray((previousIds) => {
                                if (!previousIds.includes(value)) {
                                  return [...previousIds, value];
                                } else {
                                  return previousIds;
                                }
                              });
                              handleInputChange(slab.id, "employeeID", value);
                              setSelectedEmployeeId(value);
                            }}
                            notFoundContent={
                              <NoDataFoundIcon />
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                              option && option.children
                                ? (option.children as string)
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                : false
                            }
                          >
                            {filteredEmployee.map((item: any, id: any) => (
                              <Select.Option key={id} value={item.id}>
                                {item?.full_name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} className="leave-policy-priority-checkbox">
                      {slab?.employeeID === "admin" ? (
                        <Checkbox checked={true} disabled={true}>
                          High Priority
                        </Checkbox>
                      ) : slab?.employeeID === "hr" ? (
                        <Checkbox checked={true} disabled={true}>
                          High Priority
                        </Checkbox>
                      ) : (
                        <Checkbox
                          onChange={(e) => {
                            handleInputChange(
                              slab.id,
                              "priority",
                              e.target.checked
                            );
                          }}
                          disabled={isHighPriorityDisabled}
                        >
                          High Priority
                        </Checkbox>
                      )}
                    </Col>

                    <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                      <Form.Item label=" ">
                        <Button
                          onClick={() =>
                            handleRemoveSlab(slab.id, selectedEmployeeId)
                          }
                          icon={<GrSubtractCircle />}
                          className="global-btn-width"
                          type="primary"
                          disabled={
                            slab?.employeeID === "manager" || "admin" || "hr"
                              ? true
                              : false
                          }
                          size="large"
                        >
                          Slab
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>

            <Col span={24} className="leave-policy-btn-position">
              <Button
                onClick={handleAddSlab}
                icon={<IoIosAddCircle />}
                className="global-btn-width"
                size="large"
                type="primary"
              >
                Add Slab
              </Button>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <>
      <div className="global-card-inner-container">
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              description={`Step ${index + 1}`}
            />
          ))}
        </Steps>
        <div className="global-steps-container-margin">
          {steps[currentStep].content}
        </div>
        {currentStep === 0 && (
          <>
            <CustomTable
              scroll={{ x: true }}
              dataSource={tableData}
              columns={columns}
              rowKey={"id"}
            ></CustomTable>
          </>
        )}
        {currentStep === 0 ? (
          <Row className="global-modal-bottom-row submit-policy-btn-col ">
            <Button
              className="global-btn-width"
              onClick={nextStep}
              size="large"
              type="primary"
            >
              Next
            </Button>
          </Row>
        ) : (
          <Row className="global-modal-bottom-row submit-policy-btn-col">
            <Button
              className="global-btn-width"
              onClick={() => setCurrentStep(currentStep - 1)}
              size="large"
              type="default"
            >
              Previous
            </Button>

            <Button
              onClick={() => {
                form.submit();
              }}
              className="database-submission-btn  global-btn-width"
              loading={buttonLoading}
              size="large"
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Row>
        )}
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};

export default LeavesPolicy;
