const AdminAccessRight = [
  {
    key: "1",
    name: "Organization",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Companies",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "2",
        name: "Branches",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "3",
        name: "Departments",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "4",
        name: "Designations",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "5",
        name: "Job Titles",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "6",
        name: "Teams",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
    ],
  },
  {
    key: "2",
    name: "Employee",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Company Employee",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "2",
        name: "Addresses",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "3",
        name: "Skills",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "4",
        name: "Certificates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "5",
        name: "Contacts",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "6",
        name: "Experiences",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "7",
        name: "Qualifications",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "8",
        name: "Relatives",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "9",
        name: "Banks",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
    ],
  },
  {
    key: "3",
    name: "Payroll",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Allowances",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "2",
        name: "Grades",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "3",
        name: "Taxation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "4",
        name: "Tax Rebates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "5",
        name: "Salaries",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "6",
        name: "Generate Pay",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "7",
        name: "Accounts",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "8",
        name: "Payroll Configuration",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "9",
        name: "Templates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "10",
        name: "Dashboard",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
    ],
  },
  {
    key: "4",
    name: "Performance",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "KPI Attributes",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "2",
        name: "KPI Scale",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "3",
        name: "Performance Metrics",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
    ],
  },
  {
    key: "5",
    name: "Scheduler",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "6",
    name: "Requests",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Leave Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "2",
        name: "Leave Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "3",
        name: "Allowance Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "4",
        name: "Allowance Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "5",
        name: "Overtime Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "6",
        name: "Overtime Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "7",
        name: "Advances Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "8",
        name: "Advances Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "9",
        name: "Resignation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
    ],
  },
  {
    key: "7",
    name: "Policy Document",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Deduction Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "2",
        name: "Timing Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "3",
        name: "Overtime Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "4",
        name: "Absent Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "5",
        name: "Leaves Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "6",
        name: "General Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
    ],
  },
  {
    key: "8",
    name: "Recruitment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Job Post",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
      {
        key: "2",
        name: "CV Bank",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
      {
        key: "3",
        name: "Dashboard",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
    ],
  },
  {
    key: "9",
    name: "Assessment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Permanent",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "2",
        name: "Internship",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "3",
        name: "Probation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "4",
        name: "Contract",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "5",
        name: "Consultant",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
    ],
  },
  {
    key: "10",
    name: "Assets",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Asset Creation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "10",
      },
      {
        key: "2",
        name: "Asset Assigning",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "10",
      },
    ],
  },
  {
    key: "11",
    name: "Attendance",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Attendance Logs",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "11",
      },
      {
        key: "2",
        name: "My Time Sheet",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "11",
      },
    ],
  },
  {
    key: "12",
    name: "End Of Employment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "13",
    name: "Dashboard",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "14",
    name: "My Profile",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Profile Information",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "2",
        name: "Contact Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "3",
        name: "Responsibility & Report To",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "4",
        name: "Qualification",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "5",
        name: "Bank Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "6",
        name: "Salary Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "7",
        name: "My Document",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
    ],
  },
  {
    key: "15",
    name: "System Settings",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Access Rights",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "2",
        name: "Organization Update",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "3",
        name: "Attendance Machine",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "4",
        name: "Help & Support",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
    ],
  },
];

const HrAccessRight = [
  {
    key: "1",
    name: "Organization",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Companies",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "2",
        name: "Branches",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "3",
        name: "Departments",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "4",
        name: "Designations",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "5",
        name: "Job Titles",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
      {
        key: "6",
        name: "Teams",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "1",
      },
    ],
  },
  {
    key: "2",
    name: "Employee",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Company Employee",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "2",
        name: "Addresses",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "3",
        name: "Skills",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "4",
        name: "Certificates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "5",
        name: "Contacts",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "6",
        name: "Experiences",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "7",
        name: "Qualifications",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "8",
        name: "Relatives",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
      {
        key: "9",
        name: "Banks",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "2",
      },
    ],
  },
  {
    key: "3",
    name: "Payroll",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Allowances",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "2",
        name: "Grades",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "3",
        name: "Taxation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "4",
        name: "Tax Rebates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "5",
        name: "Salaries",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "6",
        name: "Generate Pay",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "7",
        name: "Accounts",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "8",
        name: "Payroll Configuration",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "9",
        name: "Templates",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
      {
        key: "10",
        name: "Dashboard",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "3",
      },
    ],
  },
  {
    key: "4",
    name: "Performance",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "KPI Attributes",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "2",
        name: "KPI Scale",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "3",
        name: "Performance Metrics",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
    ],
  },
  {
    key: "5",
    name: "Scheduler",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "6",
    name: "Requests",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Leave Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "2",
        name: "Leave Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "3",
        name: "Allowance Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "4",
        name: "Allowance Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "5",
        name: "Overtime Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "6",
        name: "Overtime Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "7",
        name: "Advances Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "8",
        name: "Advances Approval",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
      {
        key: "9",
        name: "Resignation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "6",
      },
    ],
  },
  {
    key: "7",
    name: "Policy Document",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Deduction Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "2",
        name: "Timing Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "3",
        name: "Overtime Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "4",
        name: "Absent Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "5",
        name: "Leaves Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "6",
        name: "General Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
    ],
  },
  {
    key: "8",
    name: "Recruitment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Job Post",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
      {
        key: "2",
        name: "CV Bank",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
      {
        key: "3",
        name: "Dashboard",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
    ],
  },
  {
    key: "9",
    name: "Assessment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Permanent",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "2",
        name: "Internship",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "3",
        name: "Probation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "4",
        name: "Contract",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
      {
        key: "5",
        name: "Consultant",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "9",
      },
    ],
  },
  {
    key: "10",
    name: "Assets",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Asset Creation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "10",
      },
      {
        key: "2",
        name: "Asset Assigning",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "10",
      },
    ],
  },
  {
    key: "11",
    name: "Attendance",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Attendance Logs",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "11",
      },
      {
        key: "2",
        name: "My Time Sheet",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "11",
      },
    ],
  },
  {
    key: "12",
    name: "End Of Employment",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "13",
    name: "Dashboard",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "14",
    name: "My Profile",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Profile Information",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "2",
        name: "Contact Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "3",
        name: "Responsibility & Report To",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "4",
        name: "Qualification",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "5",
        name: "Bank Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "6",
        name: "Salary Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
      {
        key: "7",
        name: "My Document",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "14",
      },
    ],
  },
  {
    key: "15",
    name: "System Settings",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Access Rights",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "2",
        name: "Organization Update",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "3",
        name: "Attendance Machine",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
      {
        key: "4",
        name: "Help & Support",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "15",
      },
    ],
  },
  {
    key: "16",
    name: "Pay Slip",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
];

const employeeAccessRight = [
  {
    key: "1",
    name: "Dashboard",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "2",
    name: "Pay Slip",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "3",
    name: "My Performance",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "4",
    name: "Policies",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Timing Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "2",
        name: "Overtime Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "3",
        name: "Absent Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "4",
        name: "Leaves Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
      {
        key: "5",
        name: "General Policy",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "4",
      },
    ],
  },
  {
    key: "5",
    name: "Requests",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Leave Request",
        menushow: true,
        readAccess: true,
        writeAccess: false,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      },
      {
        key: "2",
        name: "Allowance Claim",
        menushow: true,
        readAccess: true,
        writeAccess: false,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      },
      {
        key: "3",
        name: "Overtime Claim",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      },
      {
        key: "4",
        name: "Advance Request",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      },
      {
        key: "5",
        name: "Resignation",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      },
      {
        key: "6",
        name: "Leave Approval",
        menushow: true,
        readAccess: true,
        writeAccess: false,
        createAccess: true,
        deleteAccess: false,
        parentKey: "5",
      }
    ],
  },
  {
    key: "6",
    name: "My Time Tracking",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
  },
  {
    key: "7",
    name: "My Profile",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Profile Information",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "2",
        name: "Contact Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "3",
        name: "Responsibility & Report To",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "4",
        name: "Qualification",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "5",
        name: "Bank Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
      {
        key: "6",
        name: "Salary Details",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "7",
      },
    ],
  },
  {
    key: "8",
    name: "System Settings",
    menushow: true,
    readAccess: true,
    writeAccess: true,
    createAccess: true,
    deleteAccess: true,
    child: [
      {
        key: "1",
        name: "Help & Support",
        menushow: true,
        readAccess: true,
        writeAccess: true,
        createAccess: true,
        deleteAccess: true,
        parentKey: "8",
      },
    ],
  },
];
const AccessRightgroups = [
  {
    label: "Administration",
    value: "admin",
  },
  {
    label: "Human Resource",
    value: "hr",
  },
  {
    label: "Employee",
    value: "employee",
  },
];
export {
  AdminAccessRight,
  HrAccessRight,
  employeeAccessRight,
  AccessRightgroups,
};
