import React, { useState, useEffect } from "react";
import { Input, Button, Col, Row, DatePicker, Form, Table, Select } from "antd";
import "./index.css";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import { useSelector } from "react-redux";
import { getCountries } from "vk-country-state-city";
import { useForm } from "antd/es/form/Form";
import CustomAlert from "../../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  getAllBranches,
  getAllCompanies,
} from "../../../../../services/Global";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateExperienceDetails: React.FC<props> = ({ onCancel }) => {
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [countrySelectLoading, setCountrySelectLoading] =
    useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0);

  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const { Option } = Select;
  const [form] = useForm();

  useEffect(() => {
    async function fetchCountries() {
      setCountrySelectLoading(true);
      const countries = await getCountries();
      setCountries(countries);
      setCountrySelectLoading(false);
    }

    fetchCountries();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await getAllCompanies(organizationId);

    if (response.data) {
      setFetchedCompanies(response.data);
      setCompanySelectLoading(false);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
      setSelectedCompany(response?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };

  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      const Ids = form.getFieldValue("selectedCompany");
      const response = await getAllBranches(Ids, organizationId);

      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };
  useEffect(() => {
    if (companyId) {
      form.setFieldValue("selectedCompany", companyId);
      setSelectedCompany(companyId);
    } else {
      getAllCompaniess();
    }
  }, []);

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      if (selectedCompany) {
        getAllBranchess();
      }
    }
  }, [selectedCompany]);

  const getEmployeesWithoutExperience = async () => {
    form.setFieldValue("employeeId", "");
    setFilteredEmployees([]);
    try {
      setEmployeeSelectLoading(true);
      let query = supabase_client_for_Org_schema(organizationId)
        .from("employee")
        .select("id, full_name")
        .eq("organization_id", organizationId);
      if (selectedCompany) {
        query = query.eq("company_id", selectedCompany);
      }
      if (selectedBranch) {
        query = query.eq("branch_id", selectedBranch);
      }
      let { data: employeesData, error: employeesError } = await query;

      if (employeesError) throw employeesError;

      if (employeesData) {
        let query1 = supabase_client_for_Org_schema(organizationId)
          .from("employee_work_experience")
          .select("employee_id");
        if (companyId) {
          query1 = query1.eq("company_id", companyId);
        }

        let { data: skillsData, error: skillsError } = await query1;
        if (skillsError) throw skillsError;

        skillsData = skillsData || [];

        const employeeIdsWithSkills = new Set(
          skillsData.map((skill) => skill.employee_id)
        );
        const employeesNotInSkills = employeesData
          .filter((employee: any) => !employeeIdsWithSkills.has(employee.id))
          .map((employee) => ({
            value: employee.id,
            label: employee.full_name,
          }));

        setFilteredEmployees(employeesNotInSkills);
        form.setFieldValue("employeeId", employeesNotInSkills[0]);
        setSelectedEmployeeId(employeesNotInSkills[0]?.value);
        setEmployeeSelectLoading(false);
      }
      setEmployeeSelectLoading(false);
    } catch (error) {
      setEmployeeSelectLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      getEmployeesWithoutExperience();
    }
  }, [selectedCompany, selectedBranch]);

  const addExperienceToTable = async (values: any) => {
    const newData = {
      key: tableData.length + 1,
      employeeId: values.employeeId,
      country: values.country,
      company: values.company,
      jobTitle: values.jobTitle,
      from: values.from ? values.from.format("YYYY-MM-DD") : "",
      to: values.to ? values.to.format("YYYY-MM-DD") : "",
    };

    setTableData([...tableData, newData]);
    form.resetFields(["country", "company", "jobTitle", "from", "to"]);
  };

  const createExperiences = async () => {
    const skillDetails = tableData.map((item: any) => ({
      id: item.key,
      country: item.country,
      to: item.to,
      from: item.from,
      company: item.company,
      jobTitle: item.jobTitle,
    }));
    const dataToBeInserted = {
      organization_id: loggedInEmployee?.employee.organization_id,
      company_id: selectedCompany,
      branch_id: selectedBranch,
      employee_id: selectedEmployeeId,
      details: skillDetails,
    };
    setButtonLoading(true);
    try {
      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_work_experience")
        .insert(dataToBeInserted);

      if (response.error) {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertMessage("Not Created!");
        setAlertDescription("Experience Creation failed!");
        setAlertType("error");
      } else {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertMessage("Successfully Created!");
        setAlertDescription("Created Successfully!");
        setAlertType("success");
        form.resetFields();
        setBranches([]);
        setFilteredEmployees([]);
        getEmployeesWithoutExperience();
        onCancel();
      }

      setTableData([]);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const removeExperienceFromTable = (key: any) => {
    const newData = tableData.filter((item) => item.key !== key);
    setTableData(newData);
  };

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Start Date",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "End Date",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => removeExperienceFromTable(record.key)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" onFinish={addExperienceToTable} form={form}>
        <Row gutter={16}>
          {!companyId && (
            <Col span={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a company..."
                rules={[
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ]}
              >
                <Select
                  loading={companySelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompany}
                  placeholder="Select a company"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!branchId && (
            <Col span={12}>
              <Form.Item name={"selectedBranch"} label="Select a branch">
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select a branch"
                  onChange={setSelectedBranch}
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select an employee"
                loading={employeeSelectLoading}
                onChange={setSelectedEmployeeId}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Please enter Country Name!",
                },
              ]}
            >
              <Select
                placeholder="Enter Country"
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                loading={countrySelectLoading}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {countries.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company"
              name="company"
              rules={[{ required: true, message: "Please Enter Experience!" }]}
            >
              <Input size="large" placeholder="Enter Company" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Job Title" name="jobTitle">
              <Input size="large" placeholder="Enter Job Title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="from"
              rules={[
                { required: true, message: "Please Enter Starting Date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Date" name="to">
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                size="large"
              >
                Add Experience
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomTable columns={columns} dataSource={tableData} />
      <div className="global-modal-bottom-row experience-formview-position-btn">
        <Button
          className="global-btn-width"
          type="primary"
          onClick={createExperiences}
          disabled={!tableData.length}
          style={{ marginTop: "16px" }}
          loading={buttonLoading}
          size="large"
        >
          Create
        </Button>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </div>
  );
};

export default CreateExperienceDetails;
