import React, { useEffect, useState } from "react";
import "./index.css";
import { updateAllowanceToApproveApi } from "../../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { Button, Space } from "antd";

import CustomTable from "../../../../common/CustomTable";
import { AllowanceToApproveByEmployeeIdApi } from "../services";

interface AllowanceClaimDetailProps {
  employeeId: string;
}

const AllowanceClaimDetail: React.FC<AllowanceClaimDetailProps> = ({
  employeeId,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [tableData, setTableData] = useState<any[]>();

  useEffect(() => {
    fetchAllowanceToApprove();
  }, []);

  const fetchAllowanceToApprove = async () => {
    setLoadingTable(true);
    const data = {
      organizationId: organizationId,
      employeeId: employeeId,
    };
    const res = await AllowanceToApproveByEmployeeIdApi(data);
    setLoadingTable(false);
    if (res.data) {
      setTableData(res.data);
    }
  };
  const updatedRecord = async (status: string, record: any) => {
    const data = {
      details: { status: status },
      id: record.id,
    };
    const res = await updateAllowanceToApproveApi(data, organizationId);

    if (res.status == 204) {
      fetchAllowanceToApprove();
    }
  };

  const columns = [
    {
      title: "Allowance Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Claim Amount",
      dataIndex: "amount_to_claim",
      key: "amount_to_claim",
      render: (_: any, item: any) =>
        item?.amount_to_claim
          ? parseFloat(item?.amount_to_claim)?.toLocaleString()
          : "",
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        const dateTime = new Date(text);
        const formattedDateTime = dateTime.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record: any) =>
        record.status == "Approved" ? (
          "Approved"
        ) : record.status == "Rejected" ? (
          "Rejected"
        ) : (
          <Space>
            <Button
              className=" global-primary-btn"
              onClick={() => updatedRecord("Approved", record)}
            >
              Approved
            </Button>

            <Button
              className=" global-default-btn"
              onClick={() => updatedRecord("Rejected", record)}
            >
              Rejected
            </Button>
          </Space>
        ),
    },
  ];
  return (
    <div>
      <CustomTable
        scroll={{ x: true }}
        dataSource={tableData}
        columns={columns}
        loading={loadingTable}
      />
    </div>
  );
};
export default AllowanceClaimDetail;
