import React, { useRef, useState, useEffect } from "react";
import { Input, Button, Col, Row, Divider, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { validateEmail } from "./../../../common/validation/index";
import { PhoneInput } from "react-international-phone";
import "react-phone-input-2/lib/style.css";
import { insertEmergencyContactApi } from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

const EmergencyContact: React.FC = () => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const emergencyDetails =
    useSelector(selectEmployee).Employee.EmergencyContact;
  const dispatch = useDispatch();
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const employeeId = useSelector(selectEmployee).Employee.EmployeeId;
  /**
   * useEffect hook that clears a timeout when the component unmounts.
   * @returns None
   */
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * Handles the form submission for adding an emergency contact.
   * @param {any} values - The form values containing the emergency contact information.
   * @returns None
   */
  const handleSubmit = async (values: any) => {
    setButtonLoading(true);
    setshowAlert(false);

    try {
      const body = {
        relative_name: values.name,
        email: values.email,
        work_phone_number: values.workmobile ? values.workmobile : null,
        relation_with_employee: values.relation,
        phone_number: values.mobile,
        organization_id: organizationId,
        employee_id: employeeId,
        address: values.address,
      };
      const EmergencyContact_res = await insertEmergencyContactApi(
        body,
        organizationId
      );

      if (EmergencyContact_res.status == 201) {
        dispatch(
          setEmployeeProfileDetails({
            Employee: {
              EmergencyContact: {
                name: values.name,
                email: values.email,
                worktelephone: values.workmobile,
                relation: values.relation,
                mobile: values.mobile,
                address: values.address,
              },
            },
          })
        );
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (EmergencyContact_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }
      setButtonLoading(false);
    } catch {
      setshowAlert(true);
      setButtonLoading(false);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  return (
    <div>
      <h3>Emergency Contact</h3>
      <Divider />
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        initialValues={{
          name: emergencyDetails?.name || "",
          relation: emergencyDetails?.relation || "",
          email: emergencyDetails?.email || "",
          mobile: emergencyDetails?.mobile || "",
          workmobile: emergencyDetails?.worktelephone || "",
          address: emergencyDetails?.address || "",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Relationship"
              name="relation"
              rules={[
                {
                  required: true,
                  message: "Please enter your Relationship",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Relationship" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Home Telephone"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter your Relationship",
                },
              ]}
            >
              <PhoneInput defaultCountry={"pk"} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Work Telephone" name="workmobile">
              <PhoneInput defaultCountry={"pk"} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !validateEmail(value)) {
                      return Promise.reject("Invalid email address!");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Street Address" name="address">
              <Input size="large" placeholder="Enter street address" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="last-row">
          {!emergencyDetails && (
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Save progress
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default EmergencyContact;
