import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";
import { catch_return } from "../../../../common/Constants";

//Function to get all companies
export const getCompaniesForOrganization = async (
  organizationId: number
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("name, id")
      .eq("organization_Id", organizationId);
    if (response) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

//Function to get all the companies
export const getBranchesAgainstCompany = async (
  companyId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("name, id")
      .eq("company_id", companyId);
    if (response) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

//Function to get all the Employees which are not present in the Employees table
export const getAllTheEmployeesNotPresentInSkillsTable = async (
  Ids: any
): Promise<any> => {
  try {
    let employeesQuery = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", Ids.organizationId)
      // .eq("status", "active");

    if (Ids.selectedCompany) {
      employeesQuery = employeesQuery.eq("company_id", Ids.selectedCompany);
    }

    if (Ids.selectedBranch) {
      employeesQuery = employeesQuery.eq("branch_id", Ids.branchId);
    }
    if (Ids.companyId) {
      employeesQuery = employeesQuery.eq("company_id", Ids.companyId);
    }
    if (Ids.branchId) {
      employeesQuery = employeesQuery.eq("branch_id", Ids.branchId);
    }

    const response = await employeesQuery;

    if (response) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

export const createSkills = async (data: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("employee_skill")
      .insert(data);
    if (response) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

//-------------------------------------------View Skills---------------------------------------------

export const getAllSkills = async (Ids: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee_skill")
      .select(`*, employee(full_name)`)
      .eq("organization_id", Ids.organizationId);

    if (Ids.companyId) {
      query = query.eq("company_id", Ids.companyId);
    }
    if (Ids.branchId) {
      query = query.eq("branch_id", Ids.branchId);
    }

    const response = await query.order("created_at", { ascending: false });

    if (response) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

export const handleRecordDeletion = async (
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .delete()
      .eq("id", recordId);
    if (response && response.status) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

//-----------------------------------------Update Skills-------------------------------------------

export const getTheSkillsAgainstRecord = async (
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .select("*")
      .eq("id", recordId)
      .maybeSingle();

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const updateSkills = async (
  updatedData: any,
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .update({
        skill_details: updatedData,
      })
      .eq("id", recordId);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

//--------------------------------------------Create Skill---------------------------------------
export const createSkill = async (body: any): Promise<any> => {
  try {
    let query = supabase.from("employee_skill").insert(body);

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

//--------------------------------------------Fetch Skill---------------------------------------

export const fetchSkillData = async (body: any): Promise<any> => {
  try {
    const query = supabase
      .from("employee_skill")
      .select(
        `* ,employee(
          full_name
        )`
      )
      .eq("organization_id", body.organizationId);

    if (body.selectedSkill) {
      query.eq("skill", body.selectedSkill);
    }
    if (body.companyId) {
      query.eq("company_id", body.companyId);
    }
    if (body.branchId) {
      query.eq("branch_id", body.branchId);
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

//--------------------------------------------Edit Skill---------------------------------------
export const fetchSkillsDetails = async (
  selectedRecordId: string
): Promise<any> => {
  try {
    let query = supabase
      .from("employee_skill")
      .select(`*, employee!employee_skill_employee_id_fkey(full_name)`)
      .eq("id", selectedRecordId);
    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const updateSkillsDetails = async (body: any): Promise<any> => {
  try {
    let query = supabase
      .from("employee_skills")
      .update({
        skill: body.skill,
        yearsOfExperience: body.yearsOfExperience,
      })
      .eq("id", body.selectedRecordId);

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};
