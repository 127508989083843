import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Spin } from "antd";
import { fetchAttendaceDataById } from "../AttendanceSheet/services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import moment from "moment";

interface Props {
  visible: boolean;
  onRequestClose: () => void;
  recordId: any;
}

const AttendanceSheetView: React.FC<Props> = ({
  visible,
  onRequestClose,
  recordId,
}) => {
  const [form] = Form.useForm();
  const [selectedAttendeeResponse, setselectedAttendeeResponse] =
    useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchData(recordId);
  }, [visible]);

  const fetchData = async (id: any) => {
    setLoading(true);
    const attendeeData = await fetchAttendaceDataById(id, organizationId);
    
    if (attendeeData.data) {

      setselectedAttendeeResponse(attendeeData.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      title={<span className="global-modal-title">Time Tracking Details</span>}
      className="global-modal-main-container"
      width={800}
      open={visible}
      onCancel={onRequestClose}
      footer={null}
    >
      <div className="global-card-inner-container">
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>

            <Row>
              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Check-In:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse?.check_in
                        ? moment(selectedAttendeeResponse.check_in).format(
                          "MMM-DD hh:mm:ss"
                        )
                        : "--"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Check-Out:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse?.check_out
                        ? moment(selectedAttendeeResponse?.check_out).format(
                          "MMM-DD hh:mm:ss"
                        )
                        : "--"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Checked In Status:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.check_in_status
                        ? selectedAttendeeResponse.check_in_status
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Check out Status:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.check_out_status
                        ? selectedAttendeeResponse.check_out_status
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Short Hours:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.short_working_hours
                        ? selectedAttendeeResponse.short_working_hours
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Worked Hours:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.worked_hours
                        ? selectedAttendeeResponse.worked_hours
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Break In:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.break_in
                        ? selectedAttendeeResponse.break_in.map(
                          (item: any, index: number, array: any[]) => (
                            <React.Fragment key={index}>
                              {item.break_in}
                              {index !== array.length - 1 && ","}
                            </React.Fragment>
                          )
                        )
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Break Out:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.break_out
                        ? selectedAttendeeResponse.break_out.map(
                          (item: any, index: number, array: any[]) => (
                            <React.Fragment key={index}>
                              {item.break_out}
                              {index !== array.length - 1 && ","}
                            </React.Fragment>
                          )
                        )
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Expected Hours:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.expected_working_hours
                        ? selectedAttendeeResponse.expected_working_hours
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Check In Short Hours:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse &&
                        selectedAttendeeResponse.short_working_hours
                        ? selectedAttendeeResponse.short_working_hours
                        : "No Data"}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col span={8} className="modal-content-container">
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-headings">
                      Punch In:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <span className="complaint-modal-data-text">
                      {selectedAttendeeResponse?.check_in
                        ? moment(selectedAttendeeResponse?.check_in).format(
                          "MMM-DD hh:mm:ss"
                        )
                        : "--"}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default AttendanceSheetView;
