import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Image, Row } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { selectLogedEmployee, setlogedInemployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchEmployeeProfileBycnicAndEmailaPI } from "./services";
import { setAccess } from "../../../../redux/AccessRight";
import { capitalizeWords } from "../../../common/validation";
import { fetchLoggInEmployeeDetailsApi } from "../../../common/Login/services";
import { useNavigate } from "react-router-dom";
import { TriangleLoader } from "../../../common/ReactLoader";
import { setEmailDomain } from "../../../../redux/emailDomain";
import { setInitialBreadcrumb, setMenuBreadcrumb } from "../../../../redux/MenuBreadcrumbSlice";

interface CardDataTypes {
  logo: JSX.Element;
  company: string;
  branch: string;
  department: string;
  Team: string;
  despgnation: string;
  report_to: string;
  job_title: string;
  work_type: string;
  buttonText: string;
  buttonOnClick: () => void;
}



const PrimaryProfileSetting: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [profilesData, setProfileData] = useState<any[]>([])
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee?.employee;
  useEffect(() => {
    fetchEmployeeProfile()
  }, [])

  const fetchEmployeeProfile = async () => {
    setShowLoader(true)
    const body = {
      email: loggedInEmployee?.work_email,
      cnic: loggedInEmployee?.cnic,
      org_id: loggedInEmployee?.organization_id
    }
    const res = await fetchEmployeeProfileBycnicAndEmailaPI(body)
    if (res.data) {
      setProfileData(res.data)
    }
    setShowLoader(false)
  }

  const onswitchHandle = async (record: any) => {
    setShowLoader(true)
    if (record) {
      const res = await fetchLoggInEmployeeDetailsApi(
        record?.id,
        record?.organization_id
      )

      const acessRight = res.data?.access_right;
      
      dispatch(
        setAccess({
          access: {
            group: capitalizeWords(res.data?.access_right_group),
            AccessRights: acessRight,
            organizationId: res.data?.organization_id,
            employeeId: res.data?.id,
          },
        })
      );
      delete res?.data?.access_right;
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            employee: res.data,
            employeeId: res.data?.id,
            employeeName: res.data?.full_name,
            employeeRole: res.data?.access_right_group,
            tour: {
              employee_dashboard_tour: res.data?.employee_dashboard_tour,
              employee_contact_tour: res.data?.employee_contact_tour,
              recruitment_tour: res.data?.recruitment_tour,
              payroll_tour: res.data?.payroll_tour,
              create_employee_tour: res.data?.create_employee_tour,
            },
          },
        })
      );
      dispatch(setInitialBreadcrumb())
      const role_name = res.data.access_right_group;
      const getEmailDomain = res.data.work_email.split("@");
      if (getEmailDomain.length > 1) {
        const finalDomain = getEmailDomain[getEmailDomain.length - 1];
        dispatch(setEmailDomain(finalDomain));
      }
      const newMenuItem = {
        parent: "settings",
        child: "Switch profile",
      };
  
      dispatch(
        setMenuBreadcrumb({
          menu_breadcrumb: [newMenuItem],
        })
      );
      
      setTimeout(() => {
        setShowLoader(false);
        const link = `${window.location.origin}/hrm/${role_name}/dashboard`;
        window.location.href = link;
      }, 1000);


    }
  }


  return (
    <div className="sp-container">
      <Row gutter={16}>
        <div className="sp-card-container">
          {profilesData?.map((data: any, index: number) => (
            <div key={index} className="sp-card">
              <div className="sp-card-header">
                <Image className="sp-card-logo" width="auto" height="5rem" src={data?.company?.logo || loggedInEmployee?.organization_logo} />
              </div>
              <div className="sp-card-heading-container">
                <span className="sp-card-heading">{data?.company?.name || "--"}</span>
              </div>
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Branch:</span>
                <span className="sp-card-description">{data.branch?.name || "--"}</span>
              </div>
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Department:</span>
                <span className="sp-card-description">{data.department?.name || "--"}</span>
              </div>
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Team:</span>
                <span className="sp-card-description">{data.team?.name || "--"}</span>
              </div>
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Designation:</span>
                <span className="sp-card-description">{data?.designation?.name || "--"}</span>
              </div>
              {/* <div className="sp-card-description-container">
                <span className="sp-card-description-title">Report to:</span>
                <span className="sp-card-description">{data?.job_title?.full_name ||"--"}</span>
              </div> */}
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Job Title:</span>
                <span className="sp-card-description">{data?.job_title?.name}</span>
              </div>
              <div className="sp-card-description-container">
                <span className="sp-card-description-title">Work Type:</span>
                <span className="sp-card-description">{data?.work_type}</span>
              </div>
              <div className="sp-card-button-container">
               {loggedInEmployee?.id == data?.id ?
                <Button disabled>Current</Button>
               : <Button
                  className="sp-card-btn"
                  onClick={() => onswitchHandle(data)}
                  type="primary"
                >
                  Switch Company
                </Button>
                }
              </div>
            </div>
          ))}
        </div>
      </Row>
      {showLoader && <TriangleLoader />}
    </div>
  );
};

export default PrimaryProfileSetting;
