import html2pdf from "html2pdf.js";

export const downloadPolicyAsPdfFunction = async (policy: any) => {
  try {
    const content = document.createElement("div");
    content.innerHTML = policy.description;

    const opt = {
      margin: 10,
      filename: `${policy.version_name}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    await html2pdf().from(content).set(opt).save();
  } catch (error) {}
};
