import React, { useEffect, useState } from "react";
import { Steps, Button, Form, Input, Row, Col, Upload, Image } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./index.css";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert/index";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { handleDataUpdation } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import d_dp from "../../../../assets/images/blankProfile.png";
import infoSvg from "../../../../assets/svgs/info.svg";

const { Step } = Steps;

const CompanyEditModel: React.FC<any> = (props) => {
  const { TextArea } = Input;
  const [currentStep, setCurrentStep] = useState(0);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [logoUrl, setLogoUrl] = useState(null);
  const [file, setFile] = useState<any>(null);
  const [uniqueCompanyId, setUniqueCompanyId] = useState<string>(
    props.data?.uniqueCompanyId
  );
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [formData, setFormData] = useState({
    name: "",
    area_of_focus: "",
    location: "",
    logo: "",
    phone_number: "",
    mission: " ",
    email: "",
  });

  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });

    if (field === "name" || field === "area_of_focus" || field === "location") {
      const newCompanyId = field === "name" ? value : formData.name;
      const newAreaOfFocus =
        field === "area_of_focus" ? value : formData.area_of_focus;
      const newLocation = field === "location" ? value : formData.location;
      setUniqueCompanyId(
        `${props.data?.name}-${newAreaOfFocus}-${newLocation}`
      );
    }
  };

  const phoneUtil = PhoneNumberUtil.getInstance();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 1000);
    }
  }, [showAlert]);

  const handleSubmit = async () => {
    setshowAlert(false);
    try {
      setButtonLoading(true);
      const data = {
        name: formData.name ? formData.name : props.data.name,
        area_of_focus: formData.area_of_focus
          ? formData.area_of_focus
          : props.data.area_of_focus,
        location: formData.location ? formData.location : props.data.location,
        phone_number: formData.phone_number
          ? formData.phone_number
          : props.data.phone_number,
        email: formData.email ? formData.email : props.data.email,
        mission: formData.mission ? formData.mission : props.data.mission,
        organization_Id: organizationId,
        logo: formData.logo ? formData.logo : props.data.logo,
        unique_identifier:
          props.data.name +
          "-" +
          formData.area_of_focus +
          "-" +
          formData.location,
      };

      const res = await handleDataUpdation(data, props.data.id, organizationId);

      if (res.status == 204 || res.status == 201) {
        setshowAlert(true);
        setAlertType("success");
        setButtonLoading(false);
        setTimeout(() => {
          props.fetchCompanys();
        }, 3000);
      }
      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  function onChangelogoImage(e: any) {
    const image = new Blob([e.file], { type: "image/jpeg" });
    if (e.file.status === "removed") {
      setLogoUrl(null);
    } else {
      getBase64(image, (url: any) => {
        setLogoUrl(url);
        handleFormChange("logo", url);
      });
    }
  }

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = async () => {
    const isJpgOrPng = file?.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    return isJpgOrPng && isLt2M;
  };

  const steps = [
    {
      title: "Company Information",
      content: (
        <>
          <Form layout="vertical">
            <Row>
              <Col className="modal-content">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Company Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the department name",
                        },
                      ]}
                    >
                      <Input
                        value={formData.name}
                        defaultValue={props.data.name}
                        onChange={(e) =>
                          handleFormChange("name", e.target.value)
                        }
                        disabled
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Area of focus"
                      name="area_of_focus"
                      rules={[
                        {
                          required: true,
                          message: "Please select the Area of focus",
                        },
                      ]}
                    >
                      <Input
                        value={formData.area_of_focus}
                        size="large"
                        defaultValue={props.data.area_of_focus}
                        onChange={(e) =>
                          handleFormChange("area_of_focus", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Head Quarter Location"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the head/manager name",
                        },
                      ]}
                    >
                      <Input
                        value={formData.location}
                        size="large"
                        onChange={(e) =>
                          handleFormChange("location", e.target.value)
                        }
                        defaultValue={props.data.location}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Logo" name="logo">
                      <Row className="company-edit-modal-bottom-row">
                        <Col span={24} className="company-modal-logo-container">
                          <Upload
                            name="logo"
                            className="avatar-uploader-create"
                            accept=".png,.jpg,.jpeg"
                            maxCount={1}
                            multiple={false}
                            listType="picture-card"
                            onChange={onChangelogoImage}
                            beforeUpload={beforeUpload}
                            showUploadList={{
                              showPreviewIcon: false,
                            }}
                            defaultFileList={[
                              {
                                uid: "-1",
                                name: "test.png",
                                status: "done",
                                url: logoUrl
                                  ? logoUrl
                                  : props.data.logo !== ""
                                  ? props.data.logo
                                  : d_dp,
                              },
                            ]}
                          >
                            <button
                              className="user-profile-upload-button"
                              type="button"
                            >
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                          </Upload>
                          <img
                            src={infoSvg}
                            alt="info"
                            width="20"
                            height="20"
                            className="warning-logo"
                          />
                          <p
                            style={{
                              fontSize: "14px",
                              width: "50%",
                            }}
                          >
                            Please upload your logo in a square format with a
                            minimum size of 512x512 pixels.
                          </p>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col
                span={24}
                className="global-modal-bottom-row company-edit-bottom-row"
              >
                {/* <Row>
                  <Col span={24}>
                    <strong>Company ID: </strong> &nbsp; {uniqueCompanyId}
                  </Col>
                </Row> */}
                <Row>
                  <Col span={24} className="company-edit-modal-next-btn">
                    <Button
                      onClick={() => setCurrentStep(currentStep + 1)}
                      className="global-btn-width"
                      size="large"
                      type="primary"
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
    {
      title: "Contact Information",
      content: (
        <>
          <Form layout="vertical">
            <Row>
              <Col className="modal-content">
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Company's Phone Number"
                      name="phone_number"
                      initialValue={props.data.phone_number}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the phone number.",
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              value.length > 3 &&
                              !phoneUtil.isValidNumber(
                                phoneUtil.parseAndKeepRawInput(value)
                              )
                            ) {
                              return Promise.reject("Invalid phone number");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "phone_number",
                          required: true,
                        }}
                        defaultCountry={"pk"}
                        value={
                          formData.phone_number
                            ? formData.phone_number
                            : props.data.phone_number
                        }
                        onChange={(phone) =>
                          handleFormChange("phone_number", phone)
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label="Company Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Company Email",
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email.",
                        },
                      ]}
                    >
                      <Input
                        value={formData.email}
                        size="large"
                        onChange={(e) =>
                          handleFormChange("email", e.target.value)
                        }
                        defaultValue={props.data.email}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Form layout="vertical">
            <Form.Item>
              <Row className="company-buttons global-modal-bottom-row">
                <Button
                  style={{ marginRight: "8px", marginLeft: "12px" }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="global-btn-width"
                  size="large"
                  type="default"
                >
                  Previous
                </Button>
                <Button
                  className="global-btn-width"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  size="large"
                  type="primary"
                >
                  Next
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      title: "Mission/Vision",
      content: (
        <>
          <Form layout="vertical">
            <Row>
              <Col className="modal-content">
                <Form.Item label="Mission/Vision" name="mission">
                  <TextArea
                    rows={4}
                    onChange={(e) =>
                      handleFormChange("mission", e.target.value)
                    }
                    value={formData.mission}
                    defaultValue={props.data.mission}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Row className="company-buttons global-modal-bottom-row">
                <Button
                  style={{ marginRight: "8px", marginLeft: "12px" }}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="global-btn-width"
                  size="large"
                  type="default"
                >
                  Previous
                </Button>
                <Button
                  className="global-btn-width"
                  onClick={handleSubmit}
                  loading={buttonLoading}
                  size="large"
                  type="primary"
                >
                  Update
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={`Step ${index + 1}`}
          />
        ))}
      </Steps>

      <div className="department-steps-content">
        {steps[currentStep].content}
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default CompanyEditModel;
