
/**
 * Validates a work email address to ensure that the domain matches the company domain.
 * @param {any} rule - The validation rule object.
 * @param {string} value - The email address to validate.
 * @param {Function} callback - The callback function to handle validation results.
 * @param {string} companyEmailDomain - The domain of the company's email addresses.
 * @returns None
 */
export const validateWorkEmail = (rule: any, value: string, callback: Function, companyEmailDomain: string) => {
    const splitDomain = companyEmailDomain?.split("@");
    var DomainName =
        splitDomain?.length > 0 ? splitDomain[splitDomain?.length - 1] : "";
    if (companyEmailDomain && DomainName) {
        const enteredWorkEmailDomain = value.split("@")[1];

        if (DomainName && enteredWorkEmailDomain !== DomainName) {
            callback("Work email domain must match the company domain");
        } else {
            callback();
        }
    } else {
        callback();
    }
};/**
 * Inserts employee addresses into the "Addresses" table in the Supabase database.
 * @param {any} data - The data containing the employee addresses to be inserted.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
