import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Col, Row, Collapse, Form, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLanguageDetail,
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { insertLanguageDetailsAPI } from "./services";
import CustomTable from "../../../common/CustomTable";

const Language: React.FC = () => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const organizationDetails = useSelector(selectLogedEmployee);
  const employeeDetails = useSelector(selectEmployee);
  const organizationId =
    organizationDetails?.logedInemployee?.employee.organization_id;

  const company_id =
    employeeDetails.Employee.PersonalDetails?.selectedCompanyId;
  const branch_id = employeeDetails.Employee.PersonalDetails?.selectedBranchId;
  const employeeId = employeeDetails.Employee.EmployeeId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleDelete = async (index: any) => {
    dispatch(removeLanguageDetail(index));
  };
  const handleSubmit = async () => {
    setButtonLoading(true);
    const body = {
      language_details: employeeDetails.Employee.Language,
      employee_id: employeeId,
      organization_id: organizationId,
      company_id: company_id,
      branch_id: branch_id,
    };

    const WorkExperience_res = await insertLanguageDetailsAPI(body);
    if (WorkExperience_res.status == 201) {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Progress Saved Successfully!");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      setButtonLoading(false);
    } else if (WorkExperience_res.error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        WorkExperience_res.error.message ||
          "oops we are facing some temporary issue with your request"
      );
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  const onFinish = (values: any) => {
    const updatedList = [
      ...(employeeDetails?.Employee?.Language
        ? employeeDetails?.Employee?.Language
        : []),
      values,
    ];
    setshowAlert(false);
    if (values?.language) {
      dispatch(
        setEmployeeProfileDetails({
          Employee: {
            Language: updatedList,
          },
        })
      );
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Language Add Successfully");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    } else {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription("Please fill all required fields");
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const columns = [
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Fluency",
      dataIndex: "fluency",
      key: "fluency",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: any, index: any) => (
        <Popconfirm
          placement="topLeft"
          title="Are you sure you want to delete this record? "
          onConfirm={() => handleDelete(index)}
        >
          <a>
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Collapse className="employee-create-qualification-collapse-style">
        <Collapse.Panel key="1" header="Add Language Details">
          <Form layout="vertical" autoComplete="off" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Language" name="language">
                  <Input size="large" placeholder="Enter Language" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Fluency" name="fluency">
                  <Input size="large" placeholder="Enter Fluency" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CustomTable
        className="table-class"
        columns={columns}
        dataSource={
          employeeDetails?.Employee?.Language
            ? employeeDetails?.Employee?.Language
            : []
        }
      />
      <Row className="last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Save progress
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default Language;
