import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Popconfirm, message } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { FaAmericanSignLanguageInterpreting } from "react-icons/fa";
import { FaBusinessTime, FaGraduationCap } from "react-icons/fa6";
import { GiSkills } from "react-icons/gi";
import ExperienceModal from "./WorkExperienceModal";
import EducationModal from "./EducationModal";
import SkillsModal from "./SkillsModal";
import LanguageModal from "./LanguageModal";
import {
  fetchEmployeeEducationDataApi,
  fetchEmployeeLanguageDataApi,
  fetchEmployeeSkillsDataApi,
  fetchEmployeeWorkExperienceDataApi,
  updateLanguageTable,
} from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../ExtractAccessRightObj";
import { DeleteOutlined } from "@ant-design/icons";

const UserQualifictionAndOtherInfoUpdate: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const employeeId = loggedInEmployee?.employeeId;
  const [Skillsres, setSkillsRes] = useState<any[]>([]);
  const [Educationres, setEducationRes] = useState<any[]>([]);
  const [WorkExperienceres, setWorkExperienceRes] = useState<any[]>([]);
  const [Languageres, setLanguageRes] = useState<any[]>([]);
  const [employescounter, setEmployesCounter] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [skillsModalVisible, setSkillsModalVisible] = useState<boolean>(false);
  const [workExpLoading, setWorkExpLoading] = useState<boolean>(false);
  const [educationLoading, setEducationLoading] = useState<boolean>(false);
  const [skillsLoading, setSkillsLoading] = useState<boolean>(false);
  const [languageLoading, setLanguageLoading] = useState<boolean>(false);
  const [educationModalVisible, setEducationModalVisible] =
    useState<boolean>(false);
  const [isLanguageModalVisible, setLanguageModalVisible] = useState(false);
  const [languageResId, setLanguageResId] = useState<any>(null);

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Qualification"
  );
  const write = access?.writeAccess;
  const read = access?.readAccess;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    fetchWorkExperienceData();
  };

  const handleFinish = () => {};

  const showEducationModal = () => {
    setEducationModalVisible(true);
  };

  const hideEducationModal = () => {
    setEducationModalVisible(false);
    fetchEducationData();
  };

  const handleAddEducation = () => {
    hideEducationModal();
  };

  const showSkillsModal = () => {
    setSkillsModalVisible(true);
  };

  const hideSkillsModal = () => {
    setSkillsModalVisible(false);
    fetchSkillsData();
  };

  const handleAddSkills = () => {
    hideSkillsModal();
  };

  const handleLanguageModalFinish = () => {
    setLanguageModalVisible(false);
  };

  useEffect(() => {
    if (employeeId) {
      fetchSkillsData();
      fetchEducationData();
      fetchWorkExperienceData();
      fetchLanguageData();
    }
  }, [employeeId]);

  const fetchSkillsData = async () => {
    setSkillsLoading(true);

    const Skills_res = await fetchEmployeeSkillsDataApi(
      organizationId,
      employeeId
    );

    if (Skills_res.data) {
      const processedSkills = Skills_res.data.map((skill: any) => {
        const years = parseInt(skill.experience);
        let experienceLabel;

        if (years === 0) {
          experienceLabel = "No Experience";
        } else {
          experienceLabel = years === 1 ? `${years}  Year` : `${years} Years`;
        }

        return {
          ...skill,
          experience: `${experienceLabel}`,
        };
      });
      setSkillsRes(processedSkills);
      setEmployesCounter((prevCount) => prevCount + 1);
      setSkillsLoading(false);
    }
    setSkillsLoading(false);
  };

  const fetchEducationData = async () => {
    setEducationLoading(true);

    const Education_res = await fetchEmployeeEducationDataApi(
      organizationId,
      employeeId
    );

    if (Education_res.data) {
      setEducationRes(Education_res.data);
      setEmployesCounter((prevCount) => prevCount + 1);
      setEducationLoading(false);
    }
    setEducationLoading(false);
  };

  const fetchWorkExperienceData = async () => {
    setWorkExpLoading(true);
    const WorkExperience_res = await fetchEmployeeWorkExperienceDataApi(
      organizationId,
      employeeId
    );
    if (WorkExperience_res.data) {
      setWorkExperienceRes(WorkExperience_res.data);
      setEmployesCounter((prevCount) => prevCount + 1);
      setWorkExpLoading(false);
    }
    setWorkExpLoading(false);
  };

  const fetchLanguageData = async () => {
    setLanguageLoading(true);

    const Language_res = await fetchEmployeeLanguageDataApi(
      organizationId,
      employeeId
    );

    if (Language_res.data) {
      setLanguageResId(Language_res.data[0]?.id);
      setLanguageRes(Language_res.data);
      setEmployesCounter((prevCount) => prevCount + 1);
      setLanguageLoading(false);
    }
    setLanguageLoading(false);
  };

  const handleDeleteLanguage = async (recordId: any) => {
    const filteredLanguages = Languageres?.flatMap((item) =>
      item.language_details
        .filter((lang: any) => lang?.id !== recordId)
        .map((lang: any) => ({
          fluency: lang?.fluency,
          language: lang?.language,
          ...(lang.id && { id: lang?.id }),
        }))
    );

    if (filteredLanguages.length) {
      const response = await updateLanguageTable(
        organizationId,
        languageResId,
        filteredLanguages
      );
      if (response.status === 201 || response.status === 204) {
        fetchLanguageData();
      }
    }
  };

  return (
    <div key={employescounter}>
      <h5>
        <FaBusinessTime /> Work Experience
      </h5>

      {WorkExperienceres && read ? (
        <Table
          scroll={{ x: true }}
          dataSource={WorkExperienceres.flatMap((item: any) => item.details)}
          loading={workExpLoading}
        >
          <Table.Column title="Company" dataIndex="company" key="company" />
          <Table.Column title="Job Title" dataIndex="jobTitle" key="jobTitle" />
          <Table.Column title="From" dataIndex="from" key="from" />
          <Table.Column title="To" dataIndex="to" key="to" />
        </Table>
      ) : (
        <p>No work experience data available.</p>
      )}
      <Row>
        <Col span={24} className="profile-qualification-add-btn-style">
          {write && (
            <Button
              className="global-btn-width add-experience-btn-width"
              onClick={showModal}
              type="primary"
            >
              Add Experience
            </Button>
          )}
        </Col>
      </Row>

      <h5>
        <FaGraduationCap /> Education
      </h5>

      {Educationres && read ? (
        <Table
          scroll={{ x: true }}
          dataSource={Educationres.flatMap(
            (item: any) => item.qualification_details
          )}
          loading={educationLoading}
        >
          <Table.Column title="Level" dataIndex="level" key="level" />
          <Table.Column
            title="Institute"
            dataIndex="institute"
            key="institute"
          />
          <Table.Column
            title="Specialization"
            dataIndex="specialization"
            key="specialization"
          />
          <Table.Column title="Starting Year" dataIndex="from" key="from" />
          <Table.Column title="Passing Year" dataIndex="to" key="to" />
        </Table>
      ) : (
        <p>No work experience data available.</p>
      )}
      <Row>
        <Col span={24} className="profile-qualification-add-btn-style">
          {write && (
            <Button
              className="global-btn-width add-experience-btn-width"
              onClick={showEducationModal}
              type="primary"
            >
              Add Education
            </Button>
          )}
        </Col>
      </Row>
      <h5>
        <GiSkills /> Skills
      </h5>
      {Skillsres && read ? (
        <Table
          dataSource={Skillsres.flatMap((item: any) => item.skill_details)}
          loading={skillsLoading}
        >
          <Table.Column title="Skill" dataIndex="skill" key="skill" />
          <Table.Column
            title="Years Of Experience"
            dataIndex="experience"
            key="experience"
          />
        </Table>
      ) : (
        <p>No set of Skills available.</p>
      )}
      <Row>
        <Col span={24} className="profile-qualification-add-btn-style">
          {write && (
            <Button
              className="global-btn-width add-experience-btn-width"
              onClick={showSkillsModal}
              type="primary"
            >
              Add Skills
            </Button>
          )}
        </Col>
      </Row>
      <h5>
        <FaAmericanSignLanguageInterpreting /> Language
      </h5>

      {Languageres && read ? (
        <Table
          dataSource={Languageres.flatMap((item: any) => item.language_details)}
          loading={languageLoading}
        >
          <Table.Column title="Language" dataIndex="language" key="language" />
          <Table.Column title="Fluency" dataIndex="fluency" key="fluency" />
          <Table.Column
            title="Actions"
            key="actions"
            render={(text: any, record: any) => (
              <Popconfirm
                title="Are you sure you want to delete this record?"
                onConfirm={() => handleDeleteLanguage(record.id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
          />
        </Table>
      ) : (
        <p>No set of Skills available.</p>
      )}
      <Row>
        <Col span={24} className="profile-qualification-add-btn-style">
          {write && (
            <Button
              className="global-btn-width add-experience-btn-width"
              onClick={() => setLanguageModalVisible(true)}
              type="primary"
            >
              Add Language
            </Button>
          )}
        </Col>
      </Row>

      {/* Experience Modal */}
      <ExperienceModal
        visible={isModalVisible}
        onClose={handleCancel}
        onFinish={handleFinish}
        data={WorkExperienceres}
      />

      {/* Education Modal */}
      <EducationModal
        visible={educationModalVisible}
        onClose={hideEducationModal}
        onFinish={handleAddEducation}
        data={Educationres}
      />

      {/* Skills Modal */}
      <SkillsModal
        visible={skillsModalVisible}
        onClose={hideSkillsModal}
        onFinish={handleAddSkills}
        data={Skillsres}
      />

      <LanguageModal
        visible={isLanguageModalVisible}
        onClose={() => {
          setLanguageModalVisible(false);
          fetchLanguageData();
        }}
        onFinish={handleLanguageModalFinish}
        data={Languageres}
      />
    </div>
  );
};

export default UserQualifictionAndOtherInfoUpdate;
