import React, { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Modal,
  Space,
  TablePaginationConfig,
  Popconfirm,
  Empty,
  Tag,
} from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { useSelector } from "react-redux";
import "./index.css";

import PayrollGradeEdit from "../EditView";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchGradeApi } from "../../services";
import CustomAlert from "../../../../common/Alert";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface PayrollGradeListProps {
  searchValue?: string;
}

const PayrollGradeList: React.FC<PayrollGradeListProps> = ({ searchValue }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [loading, setLoading] = useState<boolean>(true);
  const [tabledata, setTableData] = useState<any>([]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [selectedRecordData, setSelectedRecordData] = useState<any>("");
  const [showTypedModel, setShowTypedModel] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Grades");
  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchTableData = async () => {
    setLoading(true);
    setSearchQuery("");
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      searchQuery: searchQuery,
    };

    const res = await fetchGradeApi(data);
    setLoading(false);
    if (res.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tabledata.length,
        },
      });
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handelCancel = () => {
    setSearchQuery("");
    setSelectedRecordId("");
    setShowTypedModel(false);
    setSelectedRecordData("");
    fetchTableData();
  };

  const handleCloseModel = () => {
    setSearchQuery("");
    setSelectedRecordId("");
    setShowTypedModel(false);
    setSelectedRecordData("");
    fetchTableData();
  };
  const handleDelete = async (id: any) => {
    try {
      const { error } = await supabase_client_for_Org_schema(organizationId)
        .from("grade")
        .delete()
        .eq("id", id);
      if (error) {
        setshowAlert(true);
        setAlertMessage("Unable to Delete");
        setAlertDescription("Not Deleted!");
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertMessage("Deleted Successfully!");
        setAlertDescription("Record Deleted!");
        setAlertType("success");
        fetchTableData();
      }
    } catch (error) {}
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  const tableColumns = [
    {
      title: "Grade",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a?.name || "";
        const nameB = b?.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Allowance's",
      dataIndex: "allowed_option",
      key: "allowed_option",
      render: (text: any, record: any) => (
        <span>
          {record?.allowed_option?.map((item: any) => (
            <Tag>{item?.name}</Tag>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a
              onClick={() => {
                setSelectedRecordId(record.id);
                setSelectedRecordData(record);
                setShowTypedModel(true);
              }}
            >
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this Grade?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      {read ? (
        <CustomTable
          loading={loading}
          dataSource={tabledata}
          columns={tableColumns}
          rowKey="id"
          onChange={handleTableChange}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}

      {selectedRecordId && (
        <Modal
          open={showTypedModel}
          onCancel={handelCancel}
          width={1000}
          title={<span className="global-modal-title">Edit Details</span>}
          footer={false}
          className="global-modal-main-container"
        >
          <PayrollGradeEdit
            recordId={selectedRecordId}
            data={selectedRecordData}
            onclose={() => handleCloseModel()}
          />
        </Modal>
      )}
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default PayrollGradeList;
