import { supabase_client_for_Org_schema } from "../../../services/supabase";

export const fetchEmployeeProfileBycnicAndEmailaPI = async (data: any): Promise<any> => {
    try {
        const response = await supabase_client_for_Org_schema(data.org_id)
            .from("employee")
            .select(
                `id,work_type ,cnic,organization_id,access_right_group,branch!employee_branch_id_fkey(name),
                company!employee_company_id_fkey(name, logo),
                job_title!employee_job_title_id_fkey(name),
                department!employee_department_id_fkey(name),
                designation!employee_designation_id_fkey(name),
                team!employee_team_id_fkey(name)
                employee(full_name)
            `
            ).eq("work_email", data.email).eq("cnic", data.cnic)


        return response

    } catch (error) {
        return error;
    }
}