import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Col,
  Row,
  Divider,
  Form,
  Button,
  Select,
  Tour,
  TourProps,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import "./index.css";
import { getCities, getCountries, getStates } from "vk-country-state-city";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { TriangleLoader } from "../../../common/ReactLoader";
import { validateWorkEmail } from "./viewmodel";
import {
  checkWorkEmailApi,
  insertEmployeeAddressesApi,
  updateEmployeesApi,
} from "./services";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../redux/EmployeeProfileSlice";
import { selectEmailDomain } from "../../../../redux/emailDomain";
import { updatedEmployeeTourStatusApi } from "../../../services/Global";
import axios from "axios";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Functional component for rendering contact details form in a React application.
 * @returns JSX element containing the contact details form.
 */
const ContactDetails: React.FC = () => {
  const [form] = Form.useForm();
  const SelectedCompanyEmailDomain = useSelector(selectEmailDomain);
  const activeButtons = useSelector(selectEmployee).Employee.Activebutton;
  const EmployeeId = useSelector(selectEmployee).Employee.EmployeeId;
  const CompanyId =
    useSelector(selectEmployee).Employee.PersonalDetails?.selectedCompanyId;
  const branchId =
    useSelector(selectEmployee).Employee.PersonalDetails?.selectedBranchId;
  const contact_details = useSelector(selectEmployee).Employee.ContactDetails;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<any>(null);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const ref1 = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY;
  /**
   * useEffect hook that sets the state to open and fetches the list of countries from the API.
   * @returns None
   */
  useEffect(() => {
    setOpen(!loggedInEmployee?.tour?.employee_contact_tour || false);
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * useEffect hook that fetches states based on the selected country.
   * If a selected country is present, it calls the getStates function to retrieve the states
   * for that country and updates the states state accordingly.
   * @returns None
   */
  useEffect(() => {
    async function fetchStates() {
      form.setFieldValue("City", "");
      form.setFieldValue("state", "");
      if (selectedCountry) {
        const states = await getStates(selectedCountry);
        setStates(states.length > 0 ? states : []);
      }
    }
    if (selectedCountry) {
      fetchStates();
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);
  /**
   * useEffect hook that fetches cities based on the selected state and country.
   * If a selected state is present, it calls the getCities function with the selected country and state,
   * then sets the cities state to the fetched cities if any are returned.
   * This effect runs whenever the selectedState or selectedCountry changes.
   */
  useEffect(() => {
    async function fetchCities() {
      form.setFieldValue("City", "");

      if (selectedState && selectedCountry) {
        const cities = await getCities(selectedCountry, selectedState);
        setCities(cities.length > 0 ? cities : []);
      }
    }
    if (selectedState && selectedCountry) {
      fetchCities();
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  /**
   * Checks the format of a work email address based on the provided rule.
   * @param {any} rule - The rule object for validation.
   * @param {string} value - The email address to validate.
   * @param {Function} callback - The callback function to handle validation results.
   * @returns None
   */
  const checkWorkEmailFormate = (
    rule: any,
    value: string,
    callback: Function
  ) => {
    validateWorkEmail(rule, value, callback, SelectedCompanyEmailDomain);
  };
  /**
   * Handles the form submission for updating employee details.
   * @param {any} values - The form values containing employee details.
   * @returns None
   */
  const handleSubmit = async (values: any) => {
    setButtonLoading(true);
    setShowLoader(true);
    const response = await getGeoCode();
    try {
      // checking workemail
      const workEmailCheck = await checkWorkEmailApi(
        organizationId,
        values.workEmail
      );
      if (workEmailCheck?.data?.length === 0) {
        const employee_data = {
          updatedData: {
            phone_number: values.mobileNumber,
            work_phone_number: values.workNumber,
            work_email: values.workEmail,
            other_email: values.otheremail,
            organization_id: organizationId,
            status: "Created",
          },
          EmployeeId: EmployeeId,
        };
        const addresses_body = {
          permanent_address_detail: {
            permanent_country: values.country,
            permanent_street: values.street,
            permanent_city: values.city,
            permanent_postcode: values.postcode ? values.postcode : null,
            permanent_state: values.state,
          },
          present_address_detail: {
            present_country: values.country,
            present_street: values.street,
            present_city: values.city,
            present_postcode: values.postcode ? values.postcode : null,
            present_state: values.state,
            geocoordinates: [response.lat, response.lon],
          },
          employee_id: EmployeeId,
          organization_id: organizationId,
          branch_id: branchId || null,
          company_id: CompanyId || null,
        };
        const Addresses_res = await insertEmployeeAddressesApi(
          addresses_body,
          organizationId
        );
        if (Addresses_res.error) {
          setshowAlert(true);
          setAlertType("error");
          setAlertMessage("");
          timeoutRef.current = setTimeout(() => {
            setshowAlert(false);
          }, 3000);
        }
        const Employees_res = await updateEmployeesApi(
          employee_data,
          organizationId
        );

        if (Employees_res.data && Employees_res.data.id) {
          let updatedActiveButtons: any = activeButtons || [
            { key: "Personl_Details", status: "Active" },
            { key: "Contact_Details", status: "Active" },
          ];
          if (Employees_res.data && Employees_res.data.id) {
            if (!activeButtons?.some((button) => button.key == "Report_to")) {
              updatedActiveButtons = [
                ...(activeButtons || []),
                {
                  key: "Report_to",
                  status: "Active",
                },
              ];
            }
            dispatch(
              setEmployeeProfileDetails({
                Employee: {
                  ContactDetails: {
                    mobile: values.mobileNumber,
                    workmobilenumber: values.workNumber,
                    country: values.country,
                    street: values.street,
                    city: values.city,
                    state: values.state,
                    zip: values.postcode,
                    workemail: values.workEmail,
                    otheremail: values.otheremail,
                  },
                  EmployeeId: Employees_res.data.id,
                  Activebutton: updatedActiveButtons,
                },
              })
            );

            setshowAlert(true);
            setAlertMessage("");
            setAlertType("success");
            timeoutRef.current = setTimeout(() => {
              setshowAlert(false);
            }, 3000);
          } else if (Employees_res.error) {
            setshowAlert(true);
            setAlertType("error");
            setAlertMessage("");

            timeoutRef.current = setTimeout(() => {
              setshowAlert(false);
            }, 3000);
          } else if (
            Employees_res.status == 404 ||
            Employees_res.status == 400
          ) {
            setshowAlert(true);
            setAlertType("error");
            setAlertMessage("");

            timeoutRef.current = setTimeout(() => {
              setshowAlert(false);
            }, 3000);
          }
        }
      } else {
        setshowAlert(true);
        setAlertType("error");
        setAlertMessage("Email already exist. Please enter another email.");
      }
      setShowLoader(false);
      setButtonLoading(false);
    } catch {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertMessage("");

      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const getGeoCode = async () => {
    const params = {
      q: `${form.getFieldValue("postcode")}, Pakistan`,
      format: "json",
      key: API_KEY,
    };
    const response = await axios.get("https://us1.locationiq.com/v1/search", {
      params,
    });

    if (response && response.data) {
      return response.data[0];
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: <span className="custom-tour-heading">Work Email</span>,
      description: (
        <span className="custom-tour-description">
          Work email should be of the same formate of the company's assigned
          email. For example, if the company's assigned email is
          employee@companyname.com then you have to fill the value as it is.
        </span>
      ),
      target: () => ref1.current!,
    },
  ];

  const TourCloas = () => {
    setOpen(false);
    const toureObject = loggedInEmployee?.tour;
    if (toureObject) {
      const updatedToureObject = {
        ...toureObject,
        employee_contact_tour: true,
      };
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            tour: updatedToureObject,
          },
        })
      );
      updatedEmployeeTourStatusApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employeeId,
        "employee_contact_tour"
      );
    }
  };

  return (
    <div>
      <Tour
        open={open}
        onClose={() => TourCloas()}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
      />
      <h3 className="admin-hr-potal-table-headings">Contact Details</h3>
      <Divider />
      <strong className="strong-contact-content-center">Telephone</strong>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        initialValues={{
          phone_number: contact_details?.mobile || "",
          work_phone_number: contact_details?.workmobilenumber || "",
          street: contact_details?.street || "",
          country: contact_details?.country || "",
          city: contact_details?.city || "",
          postcode: contact_details?.zip || "",
          state: contact_details?.state || "",
          workEmail: contact_details?.workemail || "",
          otheremail: contact_details?.otheremail || "",
        }}
        form={form}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="phone_number"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your Mobile Number!",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                defaultCountry={"pk"}
                className="employee_create_contact_phone"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="work_phone_number"
              label="Work Phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your Work Number!",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      value.length > 3 &&
                      !phoneUtil.isValidNumber(
                        phoneUtil.parseAndKeepRawInput(value)
                      )
                    ) {
                      return Promise.reject("Invalid phone number");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                defaultCountry={"pk"}
                className="employee_create_contact_phone"
              />
            </Form.Item>
          </Col>
        </Row>
        <strong className="strong-contact-content-center">Address</strong>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <Form.Item
              label="Country"
              name={"country"}
              rules={[{ required: true, message: "Select a country" }]}
            >
              <Select
                onChange={setSelectedCountry}
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Country"
              >
                {countries?.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <Form.Item
              label="State/Province"
              name="state"
              rules={[{ required: true, message: "Please select a state" }]}
            >
              <Select
                onChange={setSelectedState}
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select State..."
                notFoundContent={<NoDataFoundIcon />}
              >
                {states?.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: "Please enter your City!",
                },
              ]}
            >
              <Select showSearch allowClear placeholder="Select City...">
                {cities?.map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="street"
              label="Street"
              rules={[
                {
                  required: true,
                  message: "Please enter your Street!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Street..."
                id="address-street"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item label="Zip/Postal Code" name="postcode">
              <Input size="large" placeholder="Enter Zip/Postal Code" />
            </Form.Item>
          </Col>
        </Row>
        <strong className="strong-contact-content-center">Email</strong>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div ref={ref1}>
              <Form.Item
                label="Work Email"
                name="workEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Email!",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email address",
                  },
                  {
                    validator: checkWorkEmailFormate,
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Work Email" />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Personal Email"
              name="otheremail"
              rules={[
                {
                  type: "email",
                  message: "Enter a valid email",
                },
              ]}
            >
              <Input size="large" placeholder="Personal Email" />
            </Form.Item>
          </Col>
        </Row>
        {!contact_details && (
          <Row className="last-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                >
                  Save progress
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {showAlert && (
        <CustomAlert
          showAlert={showAlert}
          type={alertType}
          message={alertMessage}
        />
      )}
      {showLoader && <TriangleLoader />}
    </div>
  );
};

export default ContactDetails;
