import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches bank details from the Supabase API based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, branchId, and searchQuery.
 * @returns {Promise<any>} A promise that resolves to the bank details fetched from the API.
 */
export const fetchBankDetailsApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_bank")
      .select(`*,employee!employee_bank_employee_id_fkey(full_name)`)
      .eq("organization_id", data?.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("employee.full_name", `%${data.searchQuery}%`);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes bank details from the database by the specified ID.
 * @param {any} id - The ID of the bank details to be deleted.
 * @returns {Promise<any>} A promise that resolves to the result of the deletion operation.
 */
export const deleteBankDetailsByIdApi = async (
  id: any,
  organization_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee_bank")
      .delete()
      .eq("id", id);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts bank details into the "BankDetails" table in the Supabase database.
 * @param {any} data - The bank details to be inserted.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertBankDetailsApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .insert(data);
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches bank details by ID from the Supabase database.
 * @param {any} id - The ID of the bank details to fetch.
 * @returns {Promise<any>} A promise that resolves to the bank details object.
 */
export const fetchBankDetailsbyIdApi = async (
  id: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .select(`*, employee(full_name)`)
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateBankDetailsbyIdApi = async (
  data: any,
  organizationId: any,
  recordId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .update({ bank_information: data })
      .eq("id", recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchAllEmployeeWithOutBanksApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    if (res.data) {
      const employee_banks_response = await supabase_client_for_Org_schema(
        data.organizationId
      )
        .from("employee_bank")
        .select("employee_id");
      const employeeIdsWithSalary = new Set(
        employee_banks_response?.data?.map((item) => item.employee_id)
      );
      const employeeswithoutsalary = res.data
        ?.filter((employee: any) => !employeeIdsWithSalary.has(employee.id))
        .map((employee: any) => ({
          value: employee.id,
          label: employee.full_name,
        }));
      return employeeswithoutsalary;
    }
    return [];
  } catch (error) {
    return catch_return;
  }
};

export const getRecordAgainstId = async (
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .select(`*, employee!employee_bank_employee_id_fkey(full_name)`)
      .eq("id", recordId)
      .maybeSingle();
    return response;
  } catch (error) {
    return error;
  }
};
