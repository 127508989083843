import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Button,
  Radio,
  Upload,
} from "antd";
import { useForm } from "antd/es/form/Form";
import currencyInfoMap from "currency-info-map/map.js";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";

import CustomAlert from "../../../../../common/Alert";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
  fetchPayrollApi,
  fetchsalaryGradeApi,
} from "../../../../../services/Global/index";
import { calculatePerDaySalary, currencyArrayMapping } from "./viewmodel";
import {
  fetchAllEmployeeBanksApi,
  fetchAllEmployeeWithOutSalaryApi,
  insertSalaryApi,
} from "../services";
import { UploadOutlined } from "@ant-design/icons";
import {
  insertEmployeeDocumentsDataApi,
  uploadEMployeeDocumentsApi,
} from "../../../EmployeeViewDetails/services";
import { UploadFile } from "antd/lib";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
interface CreateSalariesProps {
  onCancel: () => void;
}
/**
 * Component for creating salaries for employees.
 * This component handles the creation of salaries for employees, including selecting company, branch, employee,
 * grade, basic salary, working days, provident fund, taxation, and other details.
 * It calculates the final total salary based on the provided inputs and displays the breakdown of earnings,
 * deductions, and the total payable salary.
 * @returns JSX element for creating salaries form.
 */
const CreateSalaries: React.FC<CreateSalariesProps> = (props) => {
  const [form] = useForm();
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [selectedPayementMethod, setSelectedPaymentMethed] =
    useState<string>("");
  const [basicSalary, setBasicSalary] = useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [gradsData, setGradsData] = useState<any[]>([]);
  const [isBasicSalaryTaxAble, setisBasicSalaryTaxAble] =
    useState<boolean>(false);
  const [isBasicSalaryPF, setisBasicSalaryPF] = useState<boolean>(false);
  const [pfPct, setPfPct] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("PKR");
  const [goWithpecentage, setGoWithPercentage] = useState("percentage");
  const [payrollData, setPayrollData] = useState<any[]>([]);
  const [EOBI, setEOBI] = useState<number>(0);
  const [pfMethod, setPfMethod] = useState<"amount" | "percentage">(
    "percentage"
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [taxPaid, setTaxPaid] = useState<string>("");
  const comapnyId = logedInemployee?.employee?.company_id;
  const branchId = logedInemployee?.employee?.branch_id;
  const organizationId = logedInemployee?.employee.organization_id;
  /**
   * useEffect hook that runs when the component mounts. It sets the selected company and branch IDs
   * based on the logged-in employee's company and branch IDs. It then fetches taxation table data
   * and companies data. It also cleans up by clearing any existing timeout.
   * @returns None
   */
  useEffect(() => {
    if (comapnyId) {
      setSelectedCompanyId(comapnyId);
      form.setFieldValue("companyId", comapnyId);
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * useEffect hook that resets the branchId and employeeId form fields to empty strings
   * and fetches the branches when the selectedCompanyId changes.
   * @param {Function} fetchBranches - Function to fetch branches data.
   * @param {string} selectedCompanyId - The selected company ID.
   * @param {Object} form - The form object containing form field values and methods.
   * @returns None
   */
  useEffect(() => {
    form.setFieldValue("taxation", null);
    form.setFieldValue("customTaxValue", null);
    if (branchId) {
      setSelectedBranchId(branchId);
      form.setFieldValue("branchId", branchId);
    } else {
      form.setFieldValue("branchId", "");
      form.setFieldValue("employeeId", "");
      fetchBranches();
    }
  }, [selectedCompanyId]);

  /**
   * useEffect hook that runs when selectedCompanyId or selectedBranchId changes.
   * It resets the employeeId field in the form, fetches employees without salaries
   * if a company is selected, and fetches the grade information.
   * @returns None
   */
  useEffect(() => {
    form.setFieldValue("employeeId", "");
    getEmployeesWithoutSalaries();
    setGradsData([]);

    form.setFieldValue("selectedGrade", "");
    getGrade();
    form.setFieldValue("payrollAcount", "");
    fetchPayrollaccounts();
  }, [selectedCompanyId, selectedBranchId]);

  useEffect(() => {
    form.setFieldValue("bankAccount", "");
    setBankAccount([]);
    if (selectedPayementMethod == "bank") {
      const data = {
        employee_id: selectedEmployee,
        organizationId: organizationId,
      };
      fetchBankAccount(data);
    }
  }, [selectedPayementMethod, selectedEmployee]);

  useEffect(() => {
    form.setFieldValue("pfAmount", "");
    if (pfPct && basicSalary) {
      const amount = (parseInt(pfPct) * parseInt(basicSalary)) / 100;
      form.setFieldValue("pfAmount", amount);
    }
  }, [pfPct, basicSalary]);

  const fetchBankAccount = async (data: any) => {
    const res = await fetchAllEmployeeBanksApi(data);
    if (res.data) {
      let bankInformation = res.data.bank_information;
      // Normalize to an array if it's not already one
      if (!Array.isArray(bankInformation)) {
        bankInformation = [bankInformation];
      }

      const banks = bankInformation.map((item: any) => {
        return {
          id: res.data.id,
          label: `${item.accountNumber} (${item.bankName})`,
          row_id: item.id,
        };
      });

      setBankAccount(banks);
    }
  };

  /**
   * Retrieves the grade information asynchronously and updates the state with the fetched data.
   * @returns None
   */
  const getGrade = async () => {
    const data = {
      organizationId: organizationId,
      companyId: selectedCompanyId,
      branchId: selectedBranchId,
    };
    const res = await fetchsalaryGradeApi(data);
    if (res.data) {
      setGradsData(res.data);
    }
  };

  /**
   * Fetches the list of companies associated with the logged-in employee's organization.
   * Sets loading state to true while fetching the data.
   * Once the data is fetched, sets loading state to false and updates the list of filtered companies.
   * @returns None
   */
  const fetchCompanies = async () => {
    setCompanySelectLoading(true);
    const resCompanies = await fetchallCompanyByOrgIdApi(organizationId);
    if (resCompanies?.data) {
      setCompanySelectLoading(false);
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };
  /**
   * Fetches all branches for the selected company asynchronously.
   * If a company is selected, it sets the branch select loading state to true,
   * then fetches all branches by company ID using an API call.
   * If the response contains data, it sets the branch select loading state to false
   * and updates the filtered branches state with the fetched data.
   * Finally, it sets the branch select loading state to false.
   * @returns None
   */
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      setBranchSelectLoading(true);
      const resBranches = await fetchAllBranchesByComapnyIdApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setBranchSelectLoading(false);
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
        setSelectedBranchId(resBranches?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  /**
   * Retrieves a list of employees without salaries based on the organization, company, and branch IDs.
   * @returns None
   */
  const getEmployeesWithoutSalaries = async () => {
    const data = {
      organizationId: organizationId,
      companyId: selectedCompanyId,
      branchId: selectedBranchId,
    };
    const res = await fetchAllEmployeeWithOutSalaryApi(data);
    if (res?.data) {
      setFilteredEmployees(res?.data);
      form.setFieldValue("employeeId", res?.data[0]?.id);
      setSelectedEmployee(res?.data[0]?.id);
    }
  };
  /**
   * Handles form changes and updates the form fields accordingly.
   * @param {any} _ - Placeholder for an event object (not used in the function).
   * @param {any} allValues - An object containing all the form values.
   * @returns None
   */
  const handleFormChange = (_: any, allValues: any) => {
    form.setFieldsValue({ perDaySalary: "" });
    const { baseSalary, workingDays } = allValues;
    if (baseSalary && workingDays) {
      const perDaySalary = calculatePerDaySalary(baseSalary, workingDays);
      form.setFieldsValue({ perDaySalary: perDaySalary });
    }
  };

  /**
   * Handles the submission of form values to insert a new salary record.
   * @param {any} values - The form values to be submitted.
   * @returns None
   */
  const onFinish = async (values: any) => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const bank_details = bankAccount?.filter(
        (item) => item.row_id == values.bankAccount
      );

      if (fileList.length > 0) {
        await handleFileSubmit({
          organization_id: organizationId || null,
          company_id: values.companyId || comapnyId || null,
          branch_id: values.branchId || branchId || null,
          employee_id: values.employeeId,
        });
      }
      const currentMonth = new Date();
      const data = {
        organization_id: organizationId || null,
        company_id: values.companyId || comapnyId || null,
        branch_id: values.branchId || branchId || null,
        employee_id: values.employeeId,
        base_salary: values.baseSalary,
        grade_id: values.selectedGrade || null,
        per_day_salary: values.perDaySalary,
        working_day: values.workingDays,
        is_provident_fund_apply_on_base_salary: values.isProvidentFund,
        provident_fund_value: values.pfPct || 0,
        is_base_salary_taxable: values.basicSalaryTaxable,
        go_with_percentage:
          (goWithpecentage == "percentage" ? true : false) || true,
        currency: selectedCurrency,
        paid_tax: values.paid_tax || 0,
        EOBI: parseFloat(values.EOBI || EOBI || 0),
        payroll_id: values.payrollAcount,
        payment_type: values.paymentMethod,
        bank_account: bank_details[0] || {},
        pf_amount: values.pfAmount,
        pf_dedection_method: values.pfMethod,
        block_salary: false,
        revision_history: [{ month: currentMonth, amount: values.baseSalary }],
      };

      const res = await insertSalaryApi(data);
      if (res.status == 201 || res.status == 204) {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertType("success");
        setTimeout(() => {
          setisBasicSalaryTaxAble(false);
          setisBasicSalaryPF(false);
          getEmployeesWithoutSalaries();
          form.resetFields();
          props.onCancel();
        }, 1000);
      } else {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  /**
   * Generates a currency selector component using Ant Design's Select component.
   * The selector allows the user to choose a currency from a list of options.
   * @returns {JSX.Element} A currency selector component.
   */
  const currencySelector = () => {
    return (
      <Select
        placeholder="Select a currency"
        defaultValue={"PKR"}
        style={{ width: 80 }}
        showSearch
        // filterOption={(input: any, option: any) =>
        //   option && option.children
        //     ? (option.children as string)
        //         .toLowerCase()
        //         .indexOf(input.toLowerCase()) >= 0
        //     : false
        // }
        className="payslip-set-currency-selector"
        onChange={setSelectedCurrency}
        notFoundContent={
          <NoDataFoundIcon />
        }
      >
        {currencyArrayMapping(currencyInfoMap)?.map((item: any) => (
          <Select.Option key={item.name} value={item.name}>
            {item.name}-{item.symbol}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const fetchPayrollaccounts = async () => {
    const data = {
      organizationId: organizationId,
      companyId: selectedCompanyId,
      branchId: selectedBranchId,
    };

    const res = await fetchPayrollApi(data);

    if (res.data) {
      setPayrollData(res.data);
    }
  };

  const handleChange = (info: any) => {
    setFileList(info.fileList);
  };
  const handleUpload = async () => {
    const file = fileList[0];
    const { name } = file;
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${name}`;
    const res = await uploadEMployeeDocumentsApi(
      fileName,
      (file as any).originFileObj
    );
    return res;
  };

  const handleFileSubmit = async (data: any) => {
    const url_res = await handleUpload();
    if (url_res && url_res.data) {
      const url = url_res.data.publicUrl;
      const formData = {
        employee_id: data.employee_id,
        name: "Tax Paid in " + new Date().getFullYear(),
        attachment: url || "",
        organization_id: data.organization_id,
        company_id: data.company_id || null,
        branch_id: data.branch_id || null,
      };

      const res = await insertEmployeeDocumentsDataApi(formData);
    } else {
      setshowAlert(true);
      setAlertType("error");
      return;
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onValuesChange={handleFormChange}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            {/* GROUP 1 */}
            {!comapnyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="companyId"
                  label="Company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Company",
                    },
                  ]}
                >
                  <Select
                    disabled={comapnyId}
                    onChange={(value) => setSelectedCompanyId(value)}
                    placeholder="Select a Company"
                    allowClear
                    loading={companySelectLoading}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            ?.toLowerCase()
                            ?.indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredCompanies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item name="branchId" label="Branch">
                  <Select
                    disabled={branchId}
                    onChange={(value) => setSelectedBranchId(value)}
                    placeholder="Select a Branch"
                    allowClear
                    showSearch
                    filterOption={(input, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    loading={branchSelectLoading}
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="employeeId"
                label="Employee"
                rules={[
                  {
                    required: true,
                    message: "Please select an employee",
                  },
                ]}
                initialValue={filteredEmployees[0]?.id}
              >
                <Select
                  onChange={(value) => setSelectedEmployee(value)}
                  placeholder="Select an Employee"
                  defaultValue={filteredEmployees[0]?.id}
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredEmployees.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Payment Method"
                name="paymentMethod"
                rules={[
                  {
                    required: true,
                    message: "Select Payment Method.",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setSelectedPaymentMethed(e.target.value)}
                >
                  <Radio value="cash">Cash</Radio>
                  <Radio value="bank">Bank</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                name="bankAccount"
                label="Bank Account"
                rules={[
                  {
                    required: selectedPayementMethod === "bank",
                    message: "Select Bank Account.",
                  },
                ]}
              >
                <Select
                  placeholder="Select Bank Account"
                  allowClear
                  showSearch
                  disabled={
                    selectedPayementMethod && selectedPayementMethod == "bank"
                      ? false
                      : true
                  }
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {bankAccount?.map((item: any) => (
                    <Select.Option key={item.row_id} value={item.row_id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="EOBI"
                label="EOBI Amount"
                rules={[
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const numValue = parseFloat(value);
                        if (isNaN(numValue) || numValue <= 0) {
                          return Promise.reject(
                            new Error(
                              "Invalid EOBI! Please Enter a Number Greater Than Zero"
                            )
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter the EOBI"
                  onChange={(e) => setEOBI(parseFloat(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name="selectedGrade" label="Grade">
                <Select
                  placeholder="Select Grade"
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {gradsData?.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                label="Payroll"
                name="payrollAcount"
                rules={[
                  {
                    required: true,
                    message: "Please select an payroll",
                  },
                ]}
              >
                <Select
                  placeholder="Select Payroll"
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {payrollData?.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.payroll}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* GROUP 2 */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="baseSalary"
                label="Basic Salary"
                rules={[
                  {
                    required: true,
                    message: "Please enter the base salary.",
                  },
                  {
                    type: "number",
                    message: "Please enter a Number",
                    transform(value) {
                      return Number(value);
                    },
                  },
                ]}
              >
                <Input
                  className="employee-salaries-basic-salary"
                  placeholder="Enter the base salary of Employee"
                  addonAfter={currencySelector()}
                  onChange={(e) => setBasicSalary(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="workingDays"
                label="Working Days"
                initialValue={7}
                rules={[
                  {
                    required: true,
                    message: "Please Number Of Working Days",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Number Of Working Days"
                  allowClear
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {[1, 2, 3, 4, 5, 6, 7].map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item name="perDaySalary" label="Per Day Salary">
                <Input
                  placeholder="Per Day Salary"
                  disabled
                  addonAfter={selectedCurrency}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* GROUP 3 */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="isProvidentFund"
                valuePropName="checked"
                label="Provident Fund Applicable?"
              >
                <Checkbox
                  value={isBasicSalaryPF}
                  onChange={() => {
                    setisBasicSalaryPF(!isBasicSalaryPF);
                    form.setFieldValue("pfPct", "");
                    form.setFieldValue("pfAmount", "");
                    setPfPct("");
                  }}
                >
                  IS Provident Fund Applicable?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5}>
              <Form.Item
                label="Deduction Method"
                name="pfMethod"
                rules={[
                  {
                    required: isBasicSalaryPF,
                    message: "Select Deduction Method.",
                  },
                ]}
              >
                <Radio.Group
                  disabled={isBasicSalaryPF ? false : true}
                  value={isBasicSalaryPF ? pfMethod : ""}
                  onChange={(e) => setPfMethod(e.target.value)}
                >
                  <Radio value="amount">Fix Amount</Radio>
                  <Radio value="percentage">Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="pfPct"
                label="PF % deduct."
                rules={[
                  {
                    required: isBasicSalaryPF && pfMethod == "percentage",
                    message: "Enter PF % deduct from salary.",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const numValue = parseFloat(value);
                        if (isNaN(numValue) || numValue < 0) {
                          return Promise.reject(new Error("Invalid  Amount!"));
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  onChange={(e) => setPfPct(e.target.value)}
                  disabled={
                    (isBasicSalaryPF && pfMethod == "percentage") == false &&
                    true
                  }
                  addonAfter={"%"}
                  placeholder="Enter provident fund on base salary..."
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="pfAmount"
                label="PF amount deduct."
                rules={[
                  {
                    required: isBasicSalaryPF,
                    message: "Enter PF amount deduct from salary.",
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const numValue = parseFloat(value);
                        if (isNaN(numValue) || numValue < 0) {
                          return Promise.reject(new Error("Invalid  Amount!"));
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={
                    (isBasicSalaryPF && pfMethod == "amount") == false && true
                  }
                  placeholder="Enter provident fund on base salary..."
                />
              </Form.Item>
            </Col>
          </Row>
          {/* GROUP 4 */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
              <Form.Item
                name="basicSalaryTaxable"
                valuePropName="checked"
                label="Taxable Salary"
              >
                <Checkbox
                  value={isBasicSalaryTaxAble}
                  onChange={() =>
                    setisBasicSalaryTaxAble(!isBasicSalaryTaxAble)
                  }
                >
                  Basic Salary Taxable?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Paid any tax this year? Enter amount:"
                name="paid_tax"
                rules={[
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Please Enter a Positive Number",
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        const numValue = parseFloat(value);
                        if (isNaN(numValue) || numValue < 0) {
                          return Promise.reject(new Error("Invalid  Amount!"));
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Paid any tax this year? Enter amount"
                  disabled={isBasicSalaryTaxAble ? false : true}
                  onChange={(e) => setTaxPaid(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item label="Taxation Method">
                <Radio.Group
                  disabled={isBasicSalaryTaxAble ? false : true}
                  value={isBasicSalaryTaxAble ? goWithpecentage : ""}
                  onChange={(e) => setGoWithPercentage(e.target.value)}
                >
                  <Radio value="fixAmount">Fix Amount</Radio>
                  <Radio value="percentage">Percentage</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {taxPaid && (
              <Col span={24}>
                <Form.Item name="upload" label="Upload Document">
                  <Upload
                    accept=".png,.jpg,.jpeg,.zip,.pdf,.doc,.docx,.txt"
                    maxCount={1}
                    onChange={handleChange}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row className="global-modal-bottom-row create-button">
            <Button
              htmlType="submit"
              className="global-btn-width"
              type="primary"
              loading={buttonLoading}
              size="large"
            >
              Create
            </Button>
          </Row>
        </Form>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </>
  );
};

export default CreateSalaries;
