import React, { useState, useEffect, useRef } from "react";
import { Button, Row, TabsProps, TourProps, Tour, Tabs } from "antd";
import Qualification from "../Qualifiction";
import ReportTo from "../ReportTo";
import PersonalDetails from "../PersonalDetails";
import ContactDetails from "../ContactDetails";
import EmergencyContact from "../EmergencyContact";
import BankDetails from "../BankDetails";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import {
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { MdEmail } from "react-icons/md";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../redux/EmployeeProfileSlice";
import {
  capitalizeWords,
  generateRandomPassword,
} from "../../../common/validation";

import { employeeSignUpApi, updateEmployeesByIdApi } from "./services";
import ScreenWidth from "../../../common/ScreenWidth";
import { updatedEmployeeTourStatusApi } from "../../../services/Global";
import { PlusCircleOutlined } from "@ant-design/icons";
import { fetchOrgDomain } from "../../Recruitment/services";

const baseUrl = process.env.REACT_APP_BASE_URL;

/**
 * Functional component for creating and managing employee profiles.
 * @returns JSX element containing the employee profile creation form and related functionalities.
 */
const CreateEmployes: React.FC = () => {
  let screenwidth = ScreenWidth();
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployee);
  const employeeId = useSelector(selectEmployee).Employee.EmployeeId;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [activeButtonArray, setactiveButtonArray] = useState<any>([]);
  const [counter, setCounter] = useState<number>(0);
  const EmployeeDetails = useSelector(selectEmployee);
  const EmployeeId = EmployeeDetails.Employee.EmployeeId;
  const ref0 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLButtonElement>(null);
  const ref3 = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [orgSubDomain, setOrgSubDomain] = useState<any>(null);

  /**
   * useEffect hook that sets the activeButtonArray state based on the employeeData's Activebutton property.
   * It also clears the timeout if it exists when the component unmounts.
   * @returns None
   */

  useEffect(() => {
    setOpen(!loggedInEmployee?.tour?.create_employee_tour || false);
    setactiveButtonArray(
      employeeData?.Employee?.Activebutton
        ? employeeData?.Employee?.Activebutton
        : []
    );
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [employeeData?.Employee?.Activebutton]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getOrgDomain();
  }, []);

  const getOrgDomain = async () => {
    const response = await fetchOrgDomain(
      loggedInEmployee?.employee?.organization_id
    );
    if (response.data) {
      setOrgSubDomain(response.data?.sub_domain);
    }
  };

  /**
   * Sends the employee data to the server if EmployeeId is truthy.
   * If EmployeeId is truthy, it dispatches actions to set employee profile details,
   * set button state, and increment the counter.
   * @returns None
   */
  const createNewEmployeeData = async () => {
    if (EmployeeId) {
      dispatch(
        setEmployeeProfileDetails({
          Employee: {
            PersonalDetails: null,
            ContactDetails: null,
            EmergencyContact: null,
            ResponsibilityReportto: null,
            WorkExperience: [],
            Education: null,
            skill: null,
            Language: null,
            Attachment: null,
            BankDetails: null,
            Activebutton: [{ key: "Personl_Details", status: "Active" }],
            EmployeeId: null,
            Avatarurl: "",
            emailSent: false,
          },
        })
      );

      setCounter(counter + 1);
    }
  };
  /**
   * Sends an email to the employee with a randomly generated password and verification link.
   * If the employee's access rights group and work email are available, an email is sent with the
   * necessary details. If successful, the employee's status is updated to "Invited".
   * @returns None
   */
  const sendEmail = async () => {
    setshowAlert(false);
    const randompassword = generateRandomPassword();
    if (
      EmployeeDetails?.Employee?.PersonalDetails?.accessrightgroup &&
      EmployeeDetails?.Employee?.ContactDetails?.workemail
    ) {
      const body = {
        email: EmployeeDetails?.Employee?.ContactDetails?.workemail,
        password: randompassword,
        options: {
          emailRedirectTo: `https://${orgSubDomain}.misgenie.app/`,
          data: {
            organization_name: loggedInEmployee?.employee?.organization_name,
            org_id: loggedInEmployee?.employee?.organization_id,
            role_name: capitalizeWords(
              EmployeeDetails.Employee.PersonalDetails?.accessrightgroup
            ),
            name:
              EmployeeDetails?.Employee?.PersonalDetails?.firstname ||
              " " + EmployeeDetails?.Employee?.PersonalDetails?.surname ||
              " ",
            employee_id: employeeId,
            role_id: EmployeeDetails.Employee.PersonalDetails?.accessrightgroup,
            password: randompassword,
            subdomain: orgSubDomain,
          },
        },
      };

      const res = await employeeSignUpApi(body);

      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertType("success");
        const data = {
          updateBody: {
            status: "Invited",
            user_id: res.data?.user?.id,
          },
          employeeId: employeeId,
          org_id: loggedInEmployee?.employee?.organization_id,
        };
        await updateEmployeesByIdApi(data);
        dispatch(
          setEmployeeProfileDetails({
            Employee: {
              emailSent: true,
            },
          })
        );
      }
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
  };
  const steps: TourProps["steps"] = [
    {
      title: <span className="custom-tour-heading">Employee Details</span>,
      description: (
        <div>
          <span className="custom-tour-description">
            {" "}
            Fill information of the Employee in each respective section and
            once, any section's information saved then next section will be
            enabled.
          </span>
          <ol>
            <li className="custom-tour-description">
              Fill in the employee's personal details like name, birthdate,
              gender, and other relevant info.
            </li>
            <li className="custom-tour-description">
              Next, input contact details such as phone number, email, and
              address. Specify who the employee reports to and provide emergency
              contact info for family or next of kin.
            </li>
            <li className="custom-tour-description">
              Enter qualifications, education, certifications, and training in
              their respective sections carefully.
            </li>
          </ol>
        </div>
      ),
      placement: "right",
      target: () => ref0.current!,
    },
    {
      title: <span className="custom-tour-heading">Access Rights</span>,
      description: (
        <div>
          <span className="custom-tour-description">
            {" "}
            Access Rights Description.
          </span>
        </div>
      ),
      placement: "right",
      target: document.getElementById("accessRightsSelection"),
    },
    {
      title: <span className="custom-tour-heading">Set Employment Type</span>,
      description: (
        <ol>
          <li className="custom-tour-description">
            After filling in the personal details, click on this button to
            specify the type of employment for the employee (e.g., Intership,
            Probation or Permanent).
          </li>
          <li className="custom-tour-description">
            Upon setting the employment type, a "Save Details" button will
            appear, allowing you to proceed to the next step.
          </li>
        </ol>
      ),
      target: document.getElementById("SetEmploymentTypeOnCreateEmployee"),
    },
    {
      title: <span className="custom-tour-heading">Create New Employee</span>,
      description: (
        <span className="custom-tour-description">
          If all necessary details have been entered and the company does not
          require further information for the employee, they can click on the
          this button to create Employee profile.
        </span>
      ),
      target: () => ref2.current!,
    },
    {
      title: <span className="custom-tour-heading">Send Email</span>,
      description: (
        <div>
          <span className="custom-tour-description">
            Complete the Contact Details section information and this button
            will be enabled.
          </span>
          <ol>
            <li className="custom-tour-description">
              An email will be generated and sent to the employee's provided
              email address.
            </li>
            <li className="custom-tour-description">
              This email will contain the employee's portal credentials,
              allowing them access to relevant systems and resources within the
              organization.
            </li>
          </ol>
        </div>
      ),
      target: () => ref3.current!,
    },
  ];
  /**
   * An array of tab items with labels, keys, children components, close icons, and disabled status based on the activeButtonArray.
   * @type {TabsProps['items']}
   */
  const items: TabsProps["items"] = [
    {
      label: "Personal Details",
      key: "Personal_Details",
      children: <PersonalDetails />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) => item.key == "Personl_Details" && item.status == "Active"
      ),
    },
    {
      key: "Contact_Details",
      label: "Contact Details",
      children: <ContactDetails />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) => item.key == "Contact_Details" && item.status == "Active"
      ),
    },

    {
      label: "Report To",
      key: "Report_to",
      children: <ReportTo />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) => item.key == "Report_to" && item.status == "Active"
      ),
    },
    {
      label: "Emergency Details",
      key: "Emergency_Details",
      children: <EmergencyContact />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) =>
          item.key == "Emergency_Details" && item.status == "Active"
      ),
    },
    {
      label: "Qualification",
      key: "Qualification",
      children: <Qualification />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) => item.key == "Qualification" && item.status == "Active"
      ),
    },

    {
      label: "Bank Details",
      key: "Bank_Details",
      children: <BankDetails />,
      closeIcon: true,
      disabled: !activeButtonArray?.some(
        (item: any) => item.key == "Bank_Details" && item.status == "Active"
      ),
    },
  ];

  const TourClose = () => {
    setOpen(false);
    const toureObject = loggedInEmployee?.tour;
    if (toureObject) {
      const updatedToureObject = {
        ...toureObject,
        create_employee_tour: true,
      };
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            tour: updatedToureObject,
          },
        })
      );
      updatedEmployeeTourStatusApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employeeId,
        "create_employee_tour"
      );
    }
  };
  return (
    <div className="profile-container" key={counter}>
      <Tour
        open={open}
        onClose={() => TourClose()}
        steps={steps}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
        className="tour-on-create-employee"
      />
      <Row className="upper-btns-row">
        {employeeData?.Employee?.ContactDetails &&
          !employeeData?.Employee?.emailSent && (
            <Button
              ref={ref3}
              icon={<MdEmail />}
              onClick={() => sendEmail()}
              className="global-btn-width"
              type="default"
            >
              Send Email
            </Button>
          )}
        {employeeData?.Employee?.ContactDetails && (
          <Button
            ref={ref2}
            onClick={createNewEmployeeData}
            className="create-employee-button-position-set global-btn-width"
            type="primary"
            icon={<PlusCircleOutlined className="create-employee-btn-icon" />}
          >
            Create New Employee
          </Button>
        )}
      </Row>
      <div ref={ref0}>
        <Tabs
          defaultActiveKey="Personl_Details"
          tabPosition={screenwidth > 992 ? "left" : "top"}
          items={items}
          className="create-employee-tab"
        />
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateEmployes;
