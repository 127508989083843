import React, { useState, useEffect } from "react";
import { Col, Tabs, Row } from "antd";
import { fetcAllEmployeeApi } from "../../../services/Global";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import {
  fetchBranches,
  fetchCompanies,
} from "../../../components/DashboardAdminandHr/DashBoardLayout/services";
import ByGender from "../../../components/DashboardAdminandHr/Charts/ByGender";
import ByAge from "../../../components/DashboardAdminandHr/Charts/HeadCount/ByAge";
import Attendence from "../../../components/DashboardAdminandHr/Attendence";
import WorkingFormate from "../../../components/DashboardAdminandHr/Charts/WorkingFormate";
import ByDepartment from "../../../components/DashboardAdminandHr/Charts/HeadCount/ByDepartment";
import ByDesignation from "../../../components/DashboardAdminandHr/Charts/HeadCount/ByDesignation";
import ByTeam from "../../../components/DashboardAdminandHr/Charts/HeadCount/ByTeam";
import OnboardingDetails from "../../../components/DashboardAdminandHr/OnboardingDetails";
import WorkAnniversary from "../../../components/DashboardAdminandHr/WorkAnniversary";
import BirthdayReminder from "../../../components/DashboardAdminandHr/BirthdayReminder";
import "../index.css";
const { TabPane } = Tabs;

const ChartsMenu = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [allActiveEmployeeList, setAllActiveEmployeeList] = useState<any[]>([]);
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [branchesData, setBranchesData] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
    if (
      loggedInEmployee?.employee?.organization_id &&
      loggedInEmployee?.employeeRole == "admin"
    ) {
      getCompanies();
      getBranches();
    }
  }, [
    loggedInEmployee?.employee?.organization_id,
    loggedInEmployee?.employeeRole,
    loggedInEmployee?.employeeId,
    loggedInEmployee?.employee?.id

  ]);
  const fetchData = async () => {
    setShowLoader(true);
    const Employees_res = await fetcAllEmployeeApi(
      loggedInEmployee?.employee?.organization_id,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id
    );
    setShowLoader(false);
    if (Employees_res?.data && Employees_res?.data?.length > 0) {
      setAllActiveEmployeeList(Employees_res?.data);
    }
  };
  const getCompanies = async () => {
    const response = await fetchCompanies(
      loggedInEmployee?.employee.organization_id
    );

    if (response.data) {
      setCompaniesData(response.data);
    }
  };
  const getBranches = async () => {
    const res = await fetchBranches(
      loggedInEmployee?.employee?.organization_id
    );

    if (res.data) {
      setBranchesData(res.data);
    }
  };

  return (
    <div className="dasboard-analytics-menu">
      <Tabs defaultActiveKey="1" className="dashboard-charts-tabs">
        <TabPane tab="Work Structure" key="1">
          <Row gutter={[10, 10]} className="admin-hr-dashboard-cards-row">
            <Col span={24} className="admin-hr-dashboard-cards-height">
              <Attendence
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col span={24} className="admin-hr-dashboard-cards-height">
              <WorkingFormate
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Organizational Structure" key="2">
          <Row gutter={[16, 32]} className="admin-hr-dashboard-cards-row">
            <Col span={24} md={12} className="admin-hr-dashboard-cards-height">
              <ByTeam
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col span={24} md={12} className="admin-hr-dashboard-cards-height">
              <ByDesignation
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col span={24} className="admin-hr-dashboard-cards-height">
              <ByDepartment
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Employee Demographics" key="3">
          <Row gutter={[10, 10]} className="admin-hr-dashboard-cards-row">
            <Col span={24} md={12} className="admin-hr-dashboard-cards-height">
              <ByGender
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col span={24} md={12} className="admin-hr-dashboard-cards-height">
              <ByAge
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Celebrations" key="4">
          <Row gutter={[16, 16]} className="admin-hr-dashboard-cards-row">
            <Col
              span={24}
              md={12}
              className="admin-hr-dashboard-data-cards-height"
            >
              <OnboardingDetails
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col
              span={24}
              md={12}
              className="admin-hr-dashboard-data-cards-height"
            >
              <WorkAnniversary
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
            <Col span={24} className="admin-hr-dashboard-data-cards-height">
              <BirthdayReminder
                employeeData={allActiveEmployeeList}
                companies={companiesData}
                branches={branchesData}
                employeeDataShow={showLoader}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ChartsMenu;
