import React, { useEffect, useState } from "react";
import "./index.css";
import { Popconfirm, Space, TablePaginationConfig, Empty } from "antd";
import CustomTable from "../../../../../common/CustomTable";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import EditContactModal from "../Edit";
import { selectAccess } from "../../../../../../redux/AccessRight";

import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";

import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../../common/SearchAndFilterFunction";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface ContactListProps {
  searchValue?: string;
}

const ContactsListView: React.FC<ContactListProps> = ({ searchValue }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [tableDataSource, setTableDataSource] = useState<any[]>([]);
  const [counter, setCounter] = useState(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);

  const organization = useSelector(selectLogedEmployee);
  const organizationId =
    organization.logedInemployee?.employee?.organization_id;
  const company = useSelector(selectLogedEmployee);
  const companyId = company.logedInemployee?.employee.company_id;

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Employee", "Contacts");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableDataSource(originalData.apiData);
    }
  }, [searchValue]);

  const fetchdata = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("status", "active")
      .not("phone_number", "is", null);

    if (companyId) {
      query.eq("company_id", companyId);
    }

    const { data, error } = await query;

    setLoading(false);
    if (data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tableDataSource.length,
        },
      });
      setTableDataSource(data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: data,
      }));
      setCounter(counter + 1);
    }

    if (error) {
    }
  };

  const handleDelete = async (employeeId: any) => {
    try {
      const { error } = await supabase_client_for_Org_schema(organizationId)
        .from("employee")
        .update({
          phone_number: null,
          work_phone_number: null,
          work_email: null,
        })
        .eq("id", employeeId);

      if (error) {
        setshowAlert(true);
        setAlertMessage("Not Deleted!");
        setAlertDescription("Not Deleted");
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertMessage("Successfully Deleted!");
        setAlertDescription("Deleted Successfully!");
        setAlertType("success");
        fetchdata();
      }
    } catch (error) {}
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: (a: any, b: any) => {
        const nameA = a.full_name || "";
        const nameB = b.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Mobile Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Work Phone",
      dataIndex: "work_phone_number",
      key: "work_phone_number",
    },
    {
      title: "Email",
      dataIndex: "work_email",
      key: "work_email",
      render: (_: any, record: any) =>
        record.work_email && (
          <span className="email-column">{record.work_email}</span>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleEdit(record)}>
              <EditOutlined />
            </a>
          )}

          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleEdit = (record: any) => {
    setSelectedRecordId(record.id);
    setIsModalVisible(true);
  };

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "full_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableDataSource(filterData);

    setLoading(false);
  };

  return (
    <>
      <div className="contacts-table">
        {read ? (
          <CustomTable
            dataSource={tableDataSource}
            columns={tableColumns}
            loading={loading}
            onChange={handleTableChange}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      {isModalVisible && (
        <EditContactModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          selectedRecordId={selectedRecordId}
          fetchData={fetchdata}
        />
      )}

      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default ContactsListView;
