import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { capitalizeWords } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";

import {
  createAccessRights,
  fetchAccessRightTableDataByGroup,
  fetchTheBranches,
  fetchTheCompanies,
  fetchTheGroupData,
} from "../services";
import {
  childCheckboxChangeHanlder,
  parentCheckboxChangeHandler,
} from "../view-modal";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface companiesOptiontype {
  label: string;
  id: string;
}

const CreateAccessRights = () => {
  const [selectedGroup, SetSlectedGroup] = useState<string | number>();
  const [selectedGroupId, SetSlectedGroupId] = useState<string | number>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [subGroups, setSubGroups] = useState<string>("");
  const [filteredTableData, setFilteredTableData] = useState<any[]>([]);
  const [companiesOption, setCompaniesOption] = useState<companiesOptiontype[]>(
    []
  );
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [mainAccessGroupData, setMainAccessGroupData] = useState<any>();
  const [branchOption, setBranchOption] = useState<companiesOptiontype[]>([]);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companyFetchLoading, setCompanyFetchLoading] =
    useState<boolean>(false);
  const [branchFetchLoading, setBranchFetchLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const companyId = loggedInEmployee?.employee.company_id;
  const branchId = loggedInEmployee?.employee.branch_id;

  useEffect(() => {
    identifyTheRole();
    fetchCompanies();
  }, []);

  const handleChangedValues = (changedValues: any) => {
    if ("branch" in changedValues) {
      form.setFieldValue("group", "");
      setFilteredTableData([]);
      fetchGroupData();
    }
  };

  //function to record any changes made in the checkboxes
  const handleCheckboxChange = (key: string, field: string) => {
    const response = parentCheckboxChangeHandler(filteredTableData, key, field);
    if (response) {
      setFilteredTableData(response);
    }
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  //function to record any changes  made in the child/expanded checkboxes
  const handleChildCheckboxChange = (
    parentKey: string,
    childKey: string,
    childField: string
  ) => {
    const response = childCheckboxChangeHanlder(
      filteredTableData,
      parentKey,
      childKey,
      childField
    );
    if (response) {
      setFilteredTableData(response);
    }
  };

  //Function to identify the role
  const identifyTheRole = async () => {
    if (loggedInEmployee?.employee?.company_id) {
      const companyId = loggedInEmployee?.employee?.company_id;
      form.setFieldValue("company", companyId);
      if (loggedInEmployee?.employee?.branch_id) {
        form.setFieldValue("branch", loggedInEmployee?.employee?.branch_id);
      }
      fetchGroupData();
    }
  };

  //function to fetch the group data
  const fetchGroupData = async () => {
    setshowAlert(false);
    try {
      const companyIdValue = companyId
        ? companyId
        : form.getFieldValue("company");
      const branchIdValue = branchId ? branchId : form.getFieldValue("branch");

      const data = {
        organizationId: loggedInEmployee?.employee?.organization_id,
        companyId: companyIdValue,
        branchId: branchIdValue,
      };
      if (companyIdValue) {
        const response = await fetchTheGroupData(data);

        setTableLoader(false);

        setMainAccessGroupData(response?.data);
      } else {
        setshowAlert(true);

        setAlertType("error");
      }
    } catch {
      setshowAlert(true);

      setAlertType("error");
    }
  };

  //function to fetch all companies
  const fetchCompanies = async () => {
    setCompanyFetchLoading(true);

    const companies_data = await fetchTheCompanies(
      loggedInEmployee?.employee?.organization_id
    );

    if (companies_data.data) {
      const option: companiesOptiontype[] = companies_data.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      setCompaniesOption(option);
      if (companyId) {
        form.setFieldValue("company", companyId);
        fetchBranches(companyId);
      }

      setCompanyFetchLoading(false);
    }
    setCompanyFetchLoading(false);
  };

  //function to fetch all branches
  const fetchBranches = async (value: any) => {
    setBranchOption([]);
    let branchres = null;
    setBranchFetchLoading(true);

    if (value) {
      const data = {
        organizationId: loggedInEmployee?.employee?.organization_id,
        companyId: value,
      };
      branchres = await fetchTheBranches(data);
    }

    if (branchres?.data) {
      form.setFieldValue("branch", branchres?.data[0]?.id);
      const option: companiesOptiontype[] = branchres.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      setBranchOption(option);
      if (branchId) {
        form.setFieldValue("branch", branchId);
      }
      setBranchFetchLoading(false);
      fetchGroupData();
    }
    setBranchFetchLoading(false);
  };

  const handleCreateAccessRight = async () => {
    setTableLoader(true);
    setshowAlert(false);
    setButtonLoading(true);
    try {
      if (selectedGroup) {
        const companyIdValue = companyId
          ? companyId
          : form.getFieldValue("company");
        const branchIdValue = branchId
          ? branchId
          : form.getFieldValue("branch");
        const body = {
          access_rights: filteredTableData,
          group: selectedGroup,
          parent_group_id: selectedGroupId,
          sub_group_name: subGroups,
          organization_Id: loggedInEmployee?.employee?.organization_id || null,
          branch_id: branchIdValue || null,
          company_id: companyIdValue || null,
        };
        const accessrights_res = await createAccessRights(body);

        setTableLoader(false);
        if (accessrights_res && accessrights_res == 201) {
          setshowAlert(true);
          setBranchOption([]);
          setMainAccessGroupData([]);
          setAlertType("success");
          setButtonLoading(false);
          form.resetFields();
          setFilteredTableData([]);
        } else {
          setshowAlert(true);
          setAlertMessage("Sub Group Name already exists");
          setAlertType("error");
          setButtonLoading(false);
        }
      }
      setButtonLoading(false);
    } catch {
      setshowAlert(true);

      setButtonLoading(false);

      setAlertType("error");
    }
  };

  const columns = [
    {
      title: <span className="table-headings">Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name: any) => <strong>{capitalizeWords(name)}</strong>,
    },
    {
      title: <span className="table-headings">Read Access</span>,
      key: "readAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.readAccess}
          onChange={() => handleCheckboxChange(record.key, "readAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Write Access</span>,
      key: "writeAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.writeAccess}
          onChange={() => handleCheckboxChange(record.key, "writeAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Create Access</span>,
      key: "createAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.createAccess}
          onChange={() => handleCheckboxChange(record.key, "createAccess")}
        />
      ),
    },
    {
      title: <span className="table-headings">Delete Access</span>,
      key: "deleteAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.deleteAccess}
          onChange={() => handleCheckboxChange(record.key, "deleteAccess")}
        />
      ),
    },
  ];

  const expandedColumns = [
    {
      title: <span className="table-headings">Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name: any) => <strong>{capitalizeWords(name)}</strong>,
    },
    {
      title: <span className="table-headings">Read Access</span>,
      key: "readAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.readAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "readAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Write Access</span>,
      key: "writeAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.writeAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "writeAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Create Access</span>,
      key: "createAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.createAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "createAccess"
            )
          }
        />
      ),
    },
    {
      title: <span className="table-headings">Delete Access</span>,
      key: "deleteAccess",
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.deleteAccess}
          onChange={() =>
            handleChildCheckboxChange(
              record.parentKey,
              record.key,
              "deleteAccess"
            )
          }
        />
      ),
    },
  ];

  const onSelectSearchFunction = async (value: any) => {
    const response = await fetchAccessRightTableDataByGroup(
      value,
      loggedInEmployee?.employee?.organization_id
    );
    SetSlectedGroup(response?.data?.group);
    SetSlectedGroupId(value);

    setFilteredTableData(response?.data?.access_rights);
  };

  return (
    <div className="create-access-rights-container">
      <div className="create-access-content-container">
        <Form
          layout="vertical"
          onFinish={handleCreateAccessRight}
          form={form}
          onValuesChange={handleChangedValues}
        >
          <Row gutter={[12, 12]} align="middle">
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                label={"Select Company"}
                name={"company"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Company",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  className="search-set-width"
                  placeholder="Select Company"
                  onChange={(value) => fetchBranches(value)}
                  loading={companyFetchLoading}
                  disabled={companyId ? true : false}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companiesOption.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item label={"Select Branch"} name={"branch"}>
                <Select
                  allowClear
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                  showSearch
                  className="search-set-width"
                  placeholder="Select Branch"
                  loading={branchFetchLoading}
                  disabled={branchId ? true : false}
                >
                  {branchOption.map((branch) => (
                    <Select.Option key={branch.id} value={branch.id}>
                      {branch.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                label={"Group"}
                name={"group"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Group",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  className="search-set-width"
                  placeholder="Select Group"
                  onChange={(value) => onSelectSearchFunction(value)}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {mainAccessGroupData &&
                    mainAccessGroupData.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {capitalizeWords(item.group)}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                label={"Sub group"}
                name={"subgroup"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Subgroup",
                  },
                ]}
              >
                <Input
                size="large"
                  placeholder="Enter Sub Group"
                  value={subGroups}
                  onChange={(e) => setSubGroups(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <CustomTable
            className="table-class-accessrights-create"
            columns={columns}
            dataSource={filteredTableData}
            loading={tableLoader}
            expandable={{
              expandedRowRender: (record) => (
                <CustomTable
                  columns={expandedColumns}
                  dataSource={record.child}
                />
              ),
              rowExpandable: (record) =>
                record.child && record.child.length > 0,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e:any) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e:any) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
          <Form.Item className="create-access-right-btn">
            <Button
             className="global-btn-width"
                  type="primary"
              htmlType="submit"
              loading={buttonLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
        {showAlert && (
          <CustomAlert
            showAlert={showAlert}
            type={alertType}
            message={alertMessage}
          />
        )}
      </div>
    </div>
  );
};

export default CreateAccessRights;
