import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Select } from "antd";
import {
  createOrUpdateLanguage,
  getLanguage,
  getTheCompanyAndBranchId,
} from "../qualificationServices";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useParams } from "react-router-dom";
import CustomAlert from "../../../../common/Alert";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

interface props {
  onClose: () => void;
}
const LanguageForm: React.FC<props> = ({ onClose }) => {
  const [form] = Form.useForm();
  const [languageDetails, setLanguageDetails] = useState<any[]>([]);
  const fluencyLevels = ["Basic", "Conversational", "Fluent", "Native"];
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const { id } = useParams<{ id: string }>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    const getLanguageDetails = async () => {
      const response = await getLanguage(organizationId, id);
      if (response.data) {
        setLanguageDetails(response.data[0]?.language_details || []);
      } else {
        setLanguageDetails([]);
      }
    };
    getLanguageDetails();
  }, [organizationId, id]);

  const handleSubmitData = async (values: any) => {
    setButtonLoading(true);

    const newRecord = {
      language: values.language,
      fluency: values.fluency,
      id: Date.now(),
    };

    const updatedLanguageDetails = [...languageDetails, newRecord];

    const companyBranchResponse = await getTheCompanyAndBranchId(
      organizationId,
      id
    );

    if (companyBranchResponse.data.company_id) {
      const data = {
        organization_id: organizationId,
        company_id: companyBranchResponse.data.company_id,
        branch_id: companyBranchResponse.data.branch_id,
        employee_id: id,
        language_details: updatedLanguageDetails,
      };

      const response = await createOrUpdateLanguage(organizationId, id, data);
      if (
        (response && response.status === 201) ||
        (response && response.status === 204)
      ) {
        setShowAlert(true);
        setAlertType("success");
        setLanguageDetails(updatedLanguageDetails);
        form.resetFields();
        setButtonLoading(false);
        setTimeout(() => onClose(), 1500);
      } else {
        setShowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form} onFinish={handleSubmitData}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Language"
                name="language"
                rules={[
                  { required: true, message: "Please enter a language!" },
                ]}
              >
                <Input size="large" placeholder="Enter Language" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Fluency"
                name="fluency"
                rules={[
                  { required: true, message: "Please select fluency level!" },
                ]}
              >
                <Select
                  placeholder="Select Fluency Level"
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {fluencyLevels.map((level) => (
                    <Option key={level} value={level}>
                      {level}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="last-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-primary-btn global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={buttonLoading}
                >
                  Create
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default LanguageForm;
