import React, { useRef, useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { supabase_client_for_Org_schema } from "../../../services/supabase";
import { Card } from "antd";
import { ProgressLoader } from "../../../common/ReactLoader";
import EventCardComponent from "./NewsTemplate";
import { TfiAnnouncement } from "react-icons/tfi";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

const EventList: React.FC = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const eventListRef = useRef<any>([]);
  const org_data = useSelector(selectLogedEmployee);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setShowLoader(true);
    try {
      const eventRes = await supabase_client_for_Org_schema(
        org_data?.logedInemployee?.employee?.organization_id
      )
        .from("event_scheduler")
        .select("*")
        .eq(
          "organization_id",
          org_data?.logedInemployee?.employee?.organization_id
        )
        .order("start_date_and_time", { ascending: false });

      setShowLoader(false);
      if (eventRes.data) {
        const todaydate = new Date().toISOString().split("T")[0];
        const events: Array<any> = eventRes.data.map((item: any) => ({
          event_id: item.event_id,
          title: item.title,
          description: item.description,
          type_of_event: item.type_of_event,
          start: item.start_date_and_time,
          end: item.end_date_and_time,
          color: item.color,
        }));

        const sortedEvents = events.sort((a, b) => {
          const aDate = a.start.split("T")[0];
          const bDate = b.end.split("T")[0];

          if (aDate === todaydate) return -1;
          if (bDate === todaydate) return 1;
          return 0;
        });

        eventListRef.current = sortedEvents;
      }
    } catch (error) {
      eventListRef.current = [];
    }
  };

  const getFormattedDate = (date: any) => {
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Card
      style={{ fontFamily: "var(--storefront-heading-font-family)" }}
      title="Events"
      bordered={true}
      className="eventlist-container"
    >
      <div>
        {showLoader ? (
          <div className="eventlist-loader-height-set">
            <ProgressLoader />
          </div>
        ) : eventListRef.current?.length > 0 ? (
          <div>
            {eventListRef.current?.map((eventItem: any, index: number) => {
              return (
                <div>
                  <div key={eventItem.event_id} className="event-card">
                    <EventCardComponent
                      icon={
                        <span className="timeline-icon">
                          {eventItem.type_of_event === "birthday" ? (
                            <TfiAnnouncement />
                          ) : eventItem.type_of_event === "anniversary" ? (
                            <TfiAnnouncement />
                          ) : eventItem.type_of_event === "appointment" ? (
                            <TfiAnnouncement />
                          ) : (
                            <TfiAnnouncement />
                          )}
                        </span>
                      }
                      heading={eventItem.title}
                      subheading={eventItem.description}
                      date={getFormattedDate(new Date(eventItem?.start))}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="dashboard-upcoming-Event-nodata">
            <p className="no-data-text">No events on the horizon 🎈</p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default EventList;
