import { Button, Col, Input, Row, Typography } from "antd";
import React, { useState } from "react";

interface DepartmentFilterModel {
  getFilterDataHandler: (focusArea: string) => void;
}

const DepartmentFilterModal: React.FC<DepartmentFilterModel> = ({
  getFilterDataHandler,
}) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const { Text } = Typography;
  return (
    <div className="global-card-inner-container">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text strong>Filter by Manager Name</Text>

          <Input
            placeholder="Enter a value..."
            size="large"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            onPressEnter={() => {
              getFilterDataHandler(filterValue);
            }}
          />
        </Col>
        <Col span={24} className="global-row-content-right">
          <Button
            className="global-btn-width"
            type="primary"
            onClick={() => {
              getFilterDataHandler(filterValue);
            }}
          >
            Filter
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentFilterModal;
