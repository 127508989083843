import React, { useEffect, useState } from "react";
import "./index.css";
import type { TableColumnsType } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchTheBugReporting } from "../services";
import CustomTable from "../../CustomTable";
import ComplaintViewModal from "../ViewModal";

type BugData = {
  id?: number;
  created_at?: string;
  name?: string;
  email?: string;
  type?: string;
  category?: string;
  severity?: string;
  actual_behavior?: string;
  expected_behavior?: string;
  message?: string;
  area?: string;
  response?: string;
  response_date?: string;
  status?: string;
};

const BugReporting = () => {
  const [data, setData] = useState<BugData[]>([]);
  const loggedInEmployeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employeeId;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [selectedBugReport, setSelectedBugReport] = useState<BugData | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const bugReportingData = await fetchTheBugReporting(
      organizationId,
      loggedInEmployeeId
    );
    setLoading(false);

    if (bugReportingData.data) {
      setData(bugReportingData.data);
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (_: any, record: any) =>
        record?.email && <span className="email-column">{record?.email}</span>,
    },
    {
      title: "Bug Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Category",
      key: "category",
      dataIndex: "category",
    },
    {
      title: "Severity",
      key: "severity",
      dataIndex: "severity",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
  ];

  return (
    <div>
      <CustomTable
        loading={loading}
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
      />
      <ComplaintViewModal
        visible={viewModalVisible}
        onRequestClose={() => setViewModalVisible(false)}
        selectedComplaintResponse={selectedBugReport}
      />
    </div>
  );
};

export default BugReporting;
