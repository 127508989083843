import React from "react";
import { Row, Col, Typography, Calendar, Space } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import "./index.css";
const { Title, Text } = Typography;

interface EventCardProps {
  icon: React.ReactNode;
  heading: string;
  subheading: string;
  date: string;
}

const EventCardComponent: React.FC<EventCardProps> = ({
  icon,
  heading,
  subheading,
  date,
}) => {

  const formattedHeading = heading.length > 12 ? `${heading.slice(0, 12)}...` : heading;
  const formattedSubheading = subheading.length > 60 ? `${subheading.slice(0, 60)}...` : subheading;

  return (
    <Row>
      <Col span={4} className="event-icon">
        {icon}
      </Col>
      <Col span={20} className="event-text">
        <Row>
          <Col span={12}>
            <span className="event-heading">{formattedHeading}</span>
          </Col>
          <Col span={12} className="event-date">
            <Space>
              <CalendarOutlined className="calender-icon-style" />
              {date}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span className="event-subheading">{formattedSubheading}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EventCardComponent;
