import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  getBranchesAPI,
  getCompaniesAPI,
  getTaxationVersionAPI,
  submitDataToDatabase,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../common/Alert";
import { supabase_client_for_Org_schema } from "../../../services/supabase";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface CompanyAccountprops {
  onCancel: () => void;
}

const CompanyPayroll: React.FC<CompanyAccountprops> = (props) => {
  const [companies, setCompanies] = useState<any[]>([]);
  const [accountsList, setAccountsList] = useState<any>(null);
  const [branches, setBranches] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [taxationVersion, setTaxationVersion] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [form] = useForm();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.branch_id;

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getBranches();
  }, [selectedCompany]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getTaxStructure();
    getAccounts();
  }, [selectedCompany, selectedBranch, companyId, branchId]);

  const getCompanies = async () => {
    const response = await getCompaniesAPI(organizationId);
    if (response.data) {
      setCompanies(response.data);
    }
  };

  const getBranches = async () => {
    const response = await getBranchesAPI(
      organizationId,
      companyId || selectedCompany
    );
    if (response.data) {
      setBranches(response.data);
    }
  };
  const getTaxStructure = async () => {
    const response = await getTaxationVersionAPI(
      organizationId,
      companyId || selectedCompany,
      branchId || selectedBranch
    );
    if (response.data) {
      setTaxationVersion(response.data);
    }
  };

  const onFinish = async () => {
    setLoader(true);
    form.validateFields();
    const payload = {
      organization_id: organizationId,
      company_id: form.getFieldValue("selectCompany") || companyId,
      branch_id: form.getFieldValue("selectBranch") || branchId,
      taxation_id: form.getFieldValue("selectTaxStructure"),
      description: form.getFieldValue("description"),
      account_id: form.getFieldValue("accountName"),
      currency: form.getFieldValue("selectCurrency"),
      payroll: form.getFieldValue("payroll"),
    };

    const response = await submitDataToDatabase(organizationId, payload);
    if (response.status == 201 || response.status == 204) {
      setShowAlert(true);
      setAlertType("success");
      setLoader(false);
      setTimeout(() => {
        props.onCancel();
      }, 1000);
    } else {
      setLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };
  const getAccounts = async () => {
    try {
      form.setFieldValue("accountName", "");
      let query = supabase_client_for_Org_schema(organizationId)
        .from("company_account_list")
        .select("id, account_name");
      const temp_comp_id = companyId || selectedCompany;
      const temp_bran_id = branchId || selectedBranch;
      if (temp_comp_id) {
        query = query.eq("company_id", temp_comp_id);
      }
      if (temp_bran_id) {
        query = query.eq("branch_id", temp_bran_id);
      }
      const res = await query;
      if (res) {
        setAccountsList(res.data);
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="payroll"
              label="Payroll"
              rules={[
                {
                  required: true,
                  message: "Please enter payroll name",
                },
              ]}
            >
              <Input size="large" placeholder="Enter payroll.." />
            </Form.Item>
          </Col>
          <Col sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item
              name="selectCompany"
              label="Select Company"
              rules={[
                {
                  required: true,
                  message: "Please select a company",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                onChange={setSelectedCompany}
                placeholder="Select company"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {companies?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item name="selectBranch" label="Select a branch">
              <Select
                showSearch
                allowClear
                onChange={setSelectedBranch}
                placeholder="Select branch"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {branches?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="selectTaxStructure"
              label="Select a tax structure"
              rules={[
                {
                  required: true,
                  message: "Please select a tax structure",
                },
              ]}
            >
              <Select allowClear showSearch placeholder="Select placeholder">
                {taxationVersion?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.taxation_version_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="accountName"
              label="Account Name"
              rules={[
                {
                  required: true,
                  message: "Enter the account name",
                },
              ]}
            >
              <Select showSearch allowClear placeholder="Select an account...">
                {accountsList?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.account_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item name="description" label="Enter description">
              <Input.TextArea rows={4} placeholder="Enter description..." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="gen-pay-add-btn">
          <Button
            size="large"
            className="global-btn-width"
            type="primary"
            loading={loader}
            htmlType="submit"
          >
            Add
          </Button>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default CompanyPayroll;
