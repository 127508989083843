import React, { useState, useEffect } from "react";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import "./index.css";
import { Button, Col, Empty, Form, Popconfirm, Row, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../common/Alert";
import {
  deleteEmployeeAssetApi,
  fetchEmployeeAssetsDataApi,
  getAllBranchesDataApi,
  getEmployeesInAssetsTableApi,
  getlistofAssetsApi,
  updateEmployeeAssetsApi,
} from "../services";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";

import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import CustomTable from "../../../../common/CustomTable";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const AssignedAssetsList: React.FC<{ searchValue: string }> = ({
  searchValue,
}) => {
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  const [employees, setEmployees] = useState<any[]>([]);
  const [currentRecordId, setCurrentRecordId] = useState<string | number>("");
  const [assetsList, setAssetsList] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(true);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [form] = useForm();

  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Assets",
    "Asset Assigning"
  );

  const deletee = access?.deleteAccess;
  const read = access?.readAccess;
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("selectedCompany", companyId);
      if (branchId) {
        form.setFieldValue("selectedBranch", branchId);
        setSelectedBranch(branchId);
      }
    } else {
      getAllCompaniess();
    }
  }, []);

  const handleSearch = (value: string) => {
    setLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);

      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      setSelectedBranch(null);
      const Ids = form.getFieldValue("selectedCompany");
      const response = await getAllBranchesDataApi(Ids, organizationId);

      if (response.data) {
        setBranches(response.data);
      }
    }
    setBranchSelectLoading(false);
  };

  const getAllCompaniess = async () => {
    const response = await fetchallCompanyByOrgIdApi(organizationId);
    if (response.data) {
      setFetchedCompanies(response.data);
    }
    setCompanySelectLoading(false);
  };

  useEffect(() => {
    if (!(branchId || companyId)) {
      getAllBranchess();
    }
  }, [selectedCompany]);

  const getResponse = async () => {
    setLoading(true);
    form.setFieldValue("employeeId", "");

    setEmployees([]);
    const response = await getEmployeesInAssetsTableApi(
      selectedCompany,
      organizationId,
      selectedBranch
    );
    if (response.data) {
      setLoading(false);
      const responseFiltered = response.data.filter(
        (item: any) => item.employee_Id
      );
      setEmployees(responseFiltered);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      getResponse();
    }
  }, [selectedCompany, selectedBranch]);

  const getDataBasedUponSelectedEmployee = async () => {
    const employeeId = form.getFieldValue("employeeId");

    if (!employeeId) {
      setTableData([]);
      return;
    }
    setTableLoading(true);
    const response = await fetchEmployeeAssetsDataApi(
      employeeId,
      organizationId
    );

    if (response.data) {
      setTableLoading(false);
      setCurrentRecordId(response.data[0].id);

      const formattedData: any = [];

      response.data.forEach((item: any) => {
        if (item.assets && Array.isArray(item.assets)) {
          item.assets.forEach((asset: any) => {
            formattedData.push({
              assetId: asset.assetId,
              name: asset.name || "N/A",
              manufacturer: asset.manufacturer || "N/A",
            });
          });
        }
      });

      setTableData(formattedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: formattedData,
      }));
    }
  };

  useEffect(() => {
    getDataBasedUponSelectedEmployee();
  }, [form.getFieldValue("employeeId")]);

  const getAssetsList: any = async () => {
    const response = await getlistofAssetsApi(
      companyId,
      branchId,
      organizationId
    );
    if (response.data) {
      const assetNames = response.data.map((item: any) => item.assetName);

      setAssetsList(assetNames);
    }
  };

  useEffect(() => {
    getAssetsList();
  }, [tableData]);

  const handleEmployeeChange = (value: any) => {
    if (!value) {
      setTableData([]);
    }
  };

  const deleteAsset = async (assetIdToDelete: any) => {
    try {
      const tempdata = tableData;
      const updatedData = tempdata.filter(
        (item: any) => item.assetId != assetIdToDelete.assetId
      );

      const res = await updateEmployeeAssetsApi(
        updatedData,
        currentRecordId,
        organizationId
      );

      if (res.data) {
        if (res.status === 200) {
          form.resetFields();
          setBranches([]);
          setEmployees([]);
          setSelectedCompany(null);
          setSelectedBranch(null);
        }

        if (res.data.assets.length === 0) {
          const response = await deleteEmployeeAssetApi(
            currentRecordId,
            organizationId
          );

          if (response.status === 204 || 200) {
            setshowAlert(true);
            setAlertType("success");
            getAllCompaniess();
          } else {
            setshowAlert(true);
            setAlertType("error");
          }
        } else {
          if (res.status === 200) {
            setshowAlert(true);
            setAlertType("success");
            getAllCompaniess();
          } else {
            setshowAlert(true);
            setAlertType("error");
          }
        }
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const columns = [
    {
      title: "Asset Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size={"middle"}>
          <Popconfirm
            placement="topLeft"
            okText="Ok"
            cancelText="Cancel"
            title="Are you sure you want to remove this record?"
            onConfirm={() => deleteAsset(record)}
          >
            {deletee && <DeleteOutlined />}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="asset-assigning-main-container">
        {read ? (
          <>
            <Form layout="vertical" form={form}>
              <Row gutter={[16, 16]} className="asset-assigning-list-top-row">
                {!companyId && (
                  <Col sm={18} xs={18} md={12} lg={7} xl={7} xxl={7}>
                    <Form.Item name="selectedCompany" label="Select a company">
                      <Select
                        allowClear
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        showSearch
                        onChange={setSelectedCompany}
                        placeholder="Please select a company"
                        className="asset-assigning-list-dropdown"
                        loading={companySelectLoading}
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {fetchedCompanies.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {!(branchId) && (
                  <Col sm={18} xs={18} md={12} lg={7} xl={7} xxl={7}>
                    <Form.Item name="selectedBranch" label="Select a branch">
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Please select a branch (if any)"
                        onChange={setSelectedBranch}
                        loading={branchSelectLoading}
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branches.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col sm={18} xs={18} md={12} lg={7} xl={7} xxl={7}>
                  <Form.Item name="employeeId" label="Select an Employee">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      loading={loading}
                      placeholder="Select an employee..."
                      onChange={handleEmployeeChange}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {employees?.map((item: any) => (
                        <Select.Option
                          key={item?.employee_Id}
                          value={item?.employee_Id}
                        >
                          {item?.employee?.full_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6} xs={6} md={12} lg={3} xl={3} xxl={3}>
                  <Form.Item label=" ">
                    <Button
                      onClick={getDataBasedUponSelectedEmployee}
                      
                      type="primary"
                      size="large"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <CustomTable
              columns={columns}
              dataSource={tableData}
              loading={tableLoading}
            />
          </>
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}

        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};

export default AssignedAssetsList;
