import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal, Popconfirm, Space, Spin, Tag, Tooltip } from "antd";
import CustomTable from "../../../../common/CustomTable";
import { getTableData } from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import { FaPlay } from "react-icons/fa";
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import EditGeneratePay from "../EditGeneratePay";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";
import CustomAlert from "../../../../common/Alert";
import {
  deleteGenerateViewAaccountData,
  updateLastDispatchData,
} from "../../services";
import dayjs from "dayjs";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import { GrLinkNext } from "react-icons/gr";
import { MdGroups2 } from "react-icons/md";
import PayrollEmployeeList from "../PayrollEmployeeList";

interface GenrateViewProps {
  searchValue?: string;
  handleViewChange: (type: string, id: string) => void;
}

const GenrateView: React.FC<GenrateViewProps> = ({
  searchValue,
  handleViewChange,
}) => {
  const [tableData, setTableData] = useState<any[]>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [generateBtnLoading, setGenerateBtnLoading] = useState<any>({});
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertMessage, setAlertMessage] = useState<string>();
  const [recordId, setRecordId] = useState<string>("");
  const [selectedPayrollId, setSelectedPayrollId] = useState<string>();
  const [openEmployeelist, setOpenEmployeeList] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getCompanyAccount();
  }, []);
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const handleSearch = (value: any) => {
    setTableLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(value, originalData, "company", "name");
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setTableLoading(false);
  };

  const handleModalOpening = (recordId: any) => {
    setEditModalOpen(true);
    setRecordId(recordId);
  };

  const handleModalClosing = () => {
    setEditModalOpen(false);
  };

  const getCompanyAccount = async () => {
    setTableLoading(true);
    const response = await getTableData(organizationId);
    if (response.data) {
      setTableData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
    }

    setTableLoading(false);
  };

  const generateEmployeesSalary = async (record: any) => {
    try {
      setGenerateBtnLoading((prev: any) => ({ ...prev, [record.id]: true }));

      const res_employee = await supabase_client_for_Org_schema(organizationId)
        .from("employee_salary")
        .select("employee_id")
        .eq("payroll_id", record.id);
      if (res_employee.data && res_employee.data.length > 0) {
        const temp_employee_id = res_employee.data.map(
          (item) => item.employee_id
        );

        const { error, status } = await supabase.rpc(
          "generate_employee_salary",
          {
            orgid: organizationId,
            employee_ids: temp_employee_id,
            taxid: record.taxation_id,
            account_title: record?.company_account_list?.account_title,
            account_number: record?.company_account_list?.account_number,
            bank_name: record?.company_account_list?.bank_name,
          }
        );

        if (status === 204) {
          const data = {
            id: record.id,
            last_dispatched: dayjs(),
          };

          const response = await updateLastDispatchData(organizationId, data);
          setGenerateBtnLoading((prev: any) => ({
            ...prev,
            [record.id]: false,
          }));
          if (response.status == 204) {
            setshowAlert(true);
            setAlertType("success");
          }
        }

        if (error) {
          setshowAlert(true);
          setAlertType("error");
        }
        setGenerateBtnLoading(false);
      } else {
        setGenerateBtnLoading(false);
        setshowAlert(true);
        setAlertType("error");
        setAlertMessage("No employee attached with this account");
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      setGenerateBtnLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      const { error } = await deleteGenerateViewAaccountData(
        organizationId,
        id
      );
      if (error) {
        setshowAlert(true);
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertType("success");
        getCompanyAccount();
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };
  function isInCurrentMonth(date: any) {
    const startOfMonth = moment().startOf("month");
    const endOfMonth = moment().endOf("month");
    return moment(date).isBetween(startOfMonth, endOfMonth, null, "[]");
  }

  const columns: any = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (record: any) => record.name,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (record: any) => record?.name,
    },
    {
      title: "Current Month",
      dataIndex: "month",
      key: "month",
      render: () => moment().format("MMMM"),
    },
    {
      title: "Last dispatch",
      dataIndex: "last_dispatched",
      key: "last_dispatched",
      render: () => moment().format("YYYY-MM-DD HH:MM"),
    },
    {
      title: "Status",
      dataIndex: "last_dispatched",
      key: "status",
      render: (record: any) =>
        isInCurrentMonth(record) ? <Tag color="green">Genrated</Tag> : "",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <Space size="middle">
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>

          {generateBtnLoading[record.id] === true ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: "1rem", color: "black" }}
                  spin
                />
              }
            />
          ) : (
            <a onClick={() => generateEmployeesSalary(record)}>
              <Tooltip title={"Click to genrate current month salary."}>
              <PlayCircleOutlined />
              </Tooltip>
            </a>
          )}
          <a onClick={() => handleModalOpening(record.id)}>
            <Tooltip title={"Click to edit payroll."}>
              <EditOutlined />
            </Tooltip>
          </a>
          <a onClick={() => handleViewChange("Summary", record.id)}>
            <Tooltip title={"Click to View Detail Summay."}>
            <ArrowRightOutlined
             />
            </Tooltip>
          </a>
          <a
            onClick={() => {
              setOpenEmployeeList(true);
              setSelectedPayrollId(record.id);
            }}
          >
            <Tooltip title={"Click to View employees under this payroll"}>
              <MdGroups2 className="global-icon-color"/>
            </Tooltip>
          </a>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpenEmployeeList(false);
    setSelectedPayrollId("");
  };
  return (
    <>
      <CustomTable
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
      />
      <Modal
        open={openEmployeelist}
        width={1000}
        onCancel={onClose}
        footer={false}
        className="global-modal-main-container"
        title={<span className="global-modal-title">Employees</span>}
      >
        <PayrollEmployeeList payrollid={selectedPayrollId} />
      </Modal>
      {editModalOpen && (
        <EditGeneratePay
          visible={editModalOpen}
          onCancel={handleModalClosing}
          recordId={recordId}
        />
      )}

      {showAlert && (
        <CustomAlert
          type={alertType}
          showAlert={showAlert}
          description={alertMessage}
        />
      )}
    </>
  );
};
export default GenrateView;
