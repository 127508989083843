import React, { useState, useEffect, useRef } from "react";
import { Collapse, Button, Modal, Empty } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../../common/ReactLoader";
import {
  fetchSalayByEmployeeIdApi,
  fetchSalayHistoryByEmployeeIdApi,
} from "./services";
import EmployeePaySlip from "../../Payroll/EmployeePaySlip";
import CustomTable from "../../../common/CustomTable";
import { capitalizeWords } from "../../../common/validation";
import SalaryItemDetails from "./salaryItemDetails";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";

const { Panel } = Collapse;

interface Props {
  employeeId: any;
}

const UserSalaryDetails: React.FC<Props> = ({ employeeId }) => {
  const levels = [
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "quinary",
    "senary",
  ];
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [salaries, setSalaries] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailsModel, setDetailsModel] = useState<boolean>(false);
  const [recordDetail, setRecordDetail] = useState<any[]>([]);
  const [entityName, setEntityName] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("Pkr");

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Salary Details"
  );
  const read = access?.readAccess;

  useEffect(() => {
    fetchEmployeeSalary();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const fetchEmployeeSalary = async () => {
    setLoading(true);
    if (employeeId) {
      try {
        const res = await fetchSalayByEmployeeIdApi(
          employeeId,
          logedInemployee?.employee?.organization_id
        );
        if (res.data) {
          const sortedData = res.data.sort((a: any, b: any) => {
            const dateA = new Date(a.created_at).getTime();
            const dateB = new Date(b.created_at).getTime();
            return dateA - dateB;
          });

          const history_res = await fetchSalayHistoryByEmployeeIdApi(
            employeeId,
            logedInemployee?.employee?.organization_id
          );
          if (history_res.data) {
            const history = history_res.data
              .map((item: any) => {
                const month = new Date(item.created_at).toLocaleString(
                  "default",
                  { month: "long" }
                );
                if (item.salary_id) {
                  const allowances = item.allowance_detail?.reduce(
                    (acc: any, allowance: any) => {
                      if (allowance.name) {
                        if (!acc[allowance.name]) {
                          acc[allowance.name] = {
                            allowance_name: allowance.name,
                            total_amount: 0,
                            month,
                          };
                        }
                        acc[allowance.name].total_amount +=
                          allowance.amount || 0;
                      }
                      return acc;
                    },
                    {}
                  );

                  return {
                    salary_id: item.salary_id,
                    month: month,
                    pf: [{ month, pf_amount: item.provident_fund_amount }],
                    tax: [{ month, tax: item.tax }],
                    allowances: allowances ? Object.values(allowances) : [],
                  };
                }
                return null;
              })
              .filter((item: any) => item !== null);

            const combinedData = sortedData.map((salary: any) => {
              const salaryHistory = history.filter(
                (h: any) => h?.salary_id && h.salary_id === salary.id
              );
              const totalPF = salaryHistory.reduce(
                (acc: any, item: any) =>
                  acc + Number(item.pf[0]?.pf_amount || 0),
                0
              );
              const totalTax = salaryHistory.reduce(
                (acc: any, item: any) => acc + Number(item.tax[0]?.tax || 0),
                0
              );
              const totalAllowances = salaryHistory.reduce(
                (acc: any, item: any) => {
                  item.allowances.forEach((allowance: any) => {
                    if (!acc[allowance.allowance_name]) {
                      acc[allowance.allowance_name] = {
                        allowance_name: allowance.allowance_name,
                        total_amount: 0,
                      };
                    }
                    acc[allowance.allowance_name].total_amount +=
                      allowance.total_amount;
                  });
                  return acc;
                },
                {}
              );

              return {
                ...salary,
                history: salaryHistory,
                totalPF: totalPF.toFixed(2) * 2,
                totalTax: totalTax.toFixed(2),
                totalAllowances: Object.values(totalAllowances),
              };
            });

            setSalaries(combinedData);
            setUpdateCounter(updateCounter + 1);

            return;
          }

          setSalaries(sortedData);
          setUpdateCounter(updateCounter + 1);
        } else if (res.error) {
          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const createDataSource = (item: any) => {
    const dataSource = [
      { name: "Basic Salary", amount: item.base_salary },
      { name: "Currency", amount: item.currency },
      { name: "Grade", amount: item.grade?.name },
      { name: "Per Day Salary", amount: item.per_day_salary },
      { name: "No of the Working Days", amount: item.working_day },
      {
        name: "Taxable Salary",
        amount: item.is_base_salary_taxable ? "Yes" : "No",
      },
      { name: "Bank Account", amount: item?.bank_account?.label },
      { name: "EOBI", amount: item.EOBI },
      ...(item.totalPF > 0
        ? [
            {
              name: "PF",
              amount: item.totalPF,
              details: item.history,
              currency: item.currency,
            },
          ]
        : []),
      ...(item.totalTax > 0
        ? [
            {
              name: "Tax",
              amount: item.totalTax,
              details: item.history,
              currency: item.currency,
            },
          ]
        : []),
      ...item.totalAllowances.map((allowance: any) => ({
        name: capitalizeWords(allowance.allowance_name),
        amount: allowance.total_amount > 0 ? allowance.total_amount : null,
      })),
    ];
    return dataSource.filter((row: any) => row.amount !== null);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
    },

    {
      title: "Amount",
      key: "amount",
      width: "50%",
      render: (record: any) => (
        <div className="detail-row-view">
          <span>{record.amount}</span>
          {record.amount > 0 &&
            (record.name === "PF" || record.name === "Tax") && (
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => handelViewDetails(record)}
              />
            )}
        </div>
      ),
    },
  ];
  const handelViewDetails = async (record: any) => {
    setDetailsModel(true);
    setEntityName(record?.name);
    setTotal(record.amount);
    setCurrency(record.currency);
    if (record?.name == "PF") {
      const temp_record = record.details
        .map((item: any) => {
          if (Number(item.pf[0]?.pf_amount) > 0) {
            return {
              month: item.month,
              amount: Number(item.pf[0]?.pf_amount).toFixed(2),
            };
          }
        })
        .flat();

      setRecordDetail(temp_record);
    }
    if (record.name == "Tax") {
      const temp_record = record.details
        .map((item: any) => {
          if (Number(item.tax[0].tax) > 0) {
            return {
              month: item.month,
              amount: Number(item.tax[0].tax).toFixed(2),
            };
          }
        })
        .flat();
      setRecordDetail(temp_record);
    }
  };
  const onClose = () => {
    setDetailsModel(false);
    setEntityName("");
    setRecordDetail([]);
    setTotal(0);
  };

  return (
    <div>
      {read ? (
        <>
          <div className="user-profile-salaries-detail-card">
            <Collapse accordion>
              {salaries.map((item: any, index: number) => {
                const dataSource = createDataSource(item);
                return (
                  <Panel
                    header={capitalizeWords(
                      `${levels[index] || index} salary Breakdown`
                    )}
                    key={index}
                    className="employee-qualification-bg-color"
                  >
                    <CustomTable
                      columns={columns}
                      dataSource={dataSource}
                      pagination={false}
                      bordered={false}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </div>
          <EmployeePaySlip selectedEmployeeId={employeeId} />
          {
            <Modal
              open={detailsModel}
              onCancel={onClose}
              width={1000}
              destroyOnClose
              footer={false}
              className="global-modal-main-container"
              title={
                <span className="global-modal-title">{`${
                  entityName == "PF" ? "Provident Fund" : entityName
                } Detials`}</span>
              }
            >
              <SalaryItemDetails
                entityName={entityName}
                details={recordDetail}
                total={total}
                currency={currency}
              />
            </Modal>
          }
          {loading && <TriangleLoader />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};

export default UserSalaryDetails;
