import { Row, Form, Col, Input, DatePicker, Select, Button } from "antd";
import {
  validateEmail,
  capitalizeWords,
  generateRandomPassword,
} from "../../../common/validation";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";
import CustomAlert from "../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchOrgDomain } from "../../Recruitment/services";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface Sendprops {
  record: any;
  onCancel: () => void;
}

interface optionType {
  label: string;
  id: string | number;
}
const workTypeOption: optionType[] = [
  {
    label: "On Site",
    id: "onsite",
  },
  {
    label: "Remote",
    id: "remote",
  },
  {
    label: "Hybrid",
    id: "hybrid",
  },
];

const SendEmail: React.FC<Sendprops> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [orgSubDomain, setOrgSubDomain] = useState<any>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getOrgDomain();
  }, []);

  const getOrgDomain = async () => {
    const response = await fetchOrgDomain(props.record.organization_id);
    if (response.data) {
      setOrgSubDomain(response.data?.sub_domain);
    }
  };

  const handleEmailSent = async (values: any) => {
    setshowAlert(false);
    try {
      setBtnLoading(true);
      const randompassword = generateRandomPassword();
      const body = {
        email: values.workEmail || props.record?.work_email,
        password: randompassword,
        options: {
          emailRedirectTo: `https://${orgSubDomain}.misgenie.app/`,
          data: {
            organization_name: loggedInEmployee?.employee?.organization_name,
            org_id: props.record.organization_id,
            role_name: capitalizeWords(
              props.record.access_right_group || "employee"
            ),
            name: props.record.full_name,
            employee_id: props.record.employee_id,
            role_id: props.record?.access_right_group || "employee",
            password: randompassword,
            subdomain: orgSubDomain,
          },
        },
      };

      const res = await supabase.auth.signUp(body);

      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertType("success");
        const updateBody = {
          status: "Invited",
          work_email: values.workEmail || props.record?.work_email,
          joining_date: values.joiningdate || props.record?.joining_date,
          user_id: res.data?.user?.id,
        };
        const Employees_res = await supabase_client_for_Org_schema(
          props.record.organization_id
        )
          .from("employee")
          .update(updateBody)
          .eq("id", props.record.employee_id);
        props.onCancel();
      }
      setBtnLoading(false);
    } catch {
      setBtnLoading(false);

      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={{
          joiningdate: props.record?.joining_date
            ? dayjs(props.record?.joining_date, "YYYY-MM-DD")
            : "",
          workEmail: props.record?.work_email || "",
        }}
        onFinish={handleEmailSent}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Joining Date"
              name="joiningdate"
              rules={[
                {
                  required: true,
                  message: "Please Select Joining Date",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Work Email"
              name="workEmail"
              rules={[
                {
                  required: true,
                  message: "Please enter your Email!",
                },
                {
                  validator: (_, value) => {
                    if (!validateEmail(value)) {
                      return Promise.reject("Invalid email address!");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                size="large"
                disabled={props.record?.work_email ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                htmlType="submit"
                loading={btnLoading}
              >
                Send Email
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default SendEmail;
