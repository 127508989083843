import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Form, Row, Col, Input, Select, Button, Checkbox } from "antd";
import { customunits } from "../UnitsList/index";

import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import { fetchAllowancebyIdApi, updateAllowanceApi } from "../../services";
import CustomAlert from "../../../../common/Alert";
import { DeleteFilled } from "@ant-design/icons";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface PayrollAllowanceEditprops {
  recordid: string;
  oncancel: () => void;
}

const PayrollAllowanceEdit: React.FC<PayrollAllowanceEditprops> = (props) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [rowUpdateCounter, setRowUpdateCounter] = useState<number>(0);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const [needApproval, setNeedApproval] = useState<boolean>(false);
  const [allowAttachment, setAllowAttachment] = useState<boolean>(false);
  const [questions, setQuestions] = useState<string[]>([]);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useEffect(() => {
    getAllowanceRecordById();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getAllowanceRecordById = async () => {
    const data = {
      organizationId: organizationId,
      id: props.recordid,
    };
    const res = await fetchAllowancebyIdApi(data);
    if (res.data) {
      setQuestions(res.data.questions);
      setAllowAttachment(res.data.allow_attachment);
      setAddQuestion(res.data.add_question);
      setNeedApproval(res.data.need_approval);
      form.setFieldValue("allowanceName", res.data.name);
      form.setFieldValue("unit", res.data.unit);
      form.setFieldValue("addQuestion", res.data.add_question);
      form.setFieldValue("allowAttachment", res.data.allow_attachment);
      form.setFieldValue("needApproval", res.data.need_approval);
    }
  };

  const handleAddQuestion = () => {
    const updatedQuestions = [...questions, ""];
    setQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (index: number) => {
    // Remove a question from the questions array
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const onFinish = async (values: any) => {
    const data = {
      id: props.recordid,
      name: capitalizeWords(values.allowanceName),
      unit: values.unit,
      questions: questions,
      allow_attachment: allowAttachment,
      need_approval: needApproval,
      add_question: addQuestion,
    };
    const res = await updateAllowanceApi(data, organizationId);
    if (res.status == 201 || res.status == 204) {
      form.resetFields();
      setQuestions([]);
      setAllowAttachment(false);
      setAddQuestion(false);
      setNeedApproval(false);
      setshowAlert(true);
      props.oncancel();
      setAlertMessage("");
      setAlertDescription("Allowance Updated Sucessfully");
      setAlertType("success");
    } else {
      setshowAlert(true);
      setAlertMessage("");
      setAlertDescription(
        res.error?.message ||
          "oops we are facing some temporary issue with your request"
      );
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row>
            <Col className="modal-content">
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="allowanceName"
                    label="Enter Allowance Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter an Allowance Name",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter allowance name..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="unit" label="Select Unit(If any)">
                    <Select
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Select Unit(If any)"
                      allowClear
                      showSearch
                      onChange={() => setRowUpdateCounter(rowUpdateCounter + 1)}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {customunits.map((unit, index) => (
                        <Select.Option key={index} value={unit}>
                          {unit}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="needApproval" label="">
                    <Checkbox
                      onChange={() => setNeedApproval(!needApproval)}
                      value={needApproval}
                      checked={needApproval}
                      className="q1-text"
                    >
                      Need Approval?
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="allowAttachment" label="">
                    <Checkbox
                      onChange={() => setAllowAttachment(!allowAttachment)}
                      value={allowAttachment}
                      checked={allowAttachment}
                      className="q2-text"
                    >
                      Allow any Attachment?
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="addQuestion" label="">
                    <Checkbox
                      onChange={() => setAddQuestion(!addQuestion)}
                      value={addQuestion}
                      checked={addQuestion}
                      className="q3-text"
                    >
                      Would you like to ask any questions before the claim
                      allowance?
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {addQuestion &&
                questions?.map((question, index) => (
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                      <Form.Item key={index} label="Enter Question?">
                        <Input
                          placeholder="Enter question..."
                          value={question}
                          size="large"
                          onChange={(e) => {
                            const updatedQuestions = [...questions];
                            updatedQuestions[index] = e.target.value;
                            setQuestions(updatedQuestions);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <Form.Item label=" ">
                        <Button
                          onClick={() => handleDeleteQuestion(index)}
                          icon={<DeleteFilled />}
                          className="global-btn-width allowance-payroll-modal-delete-btn"
                          type="default"
                        >
                          Delete
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              <Row>
                <Col span={24}>
                  {addQuestion && (
                    <Button onClick={handleAddQuestion}>Add Question</Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="global-modal-bottom-row">
            <Col
              span={24}
              className="payroll-allowance-edit-modal-button-container"
            >
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            className="allowance-edit-form-container-last-row"
          ></Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default PayrollAllowanceEdit;
