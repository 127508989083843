import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchTimingPolicyByKeysApi = async (
  keys: any,
  attempt = 0
): Promise<any> => {
  try {
    let query = supabase.from("timingPolicy").select("*");

    Object.entries(keys).forEach(([key, value]) => {
      query = query.eq(key, value);
    });

    const res = await query.order("created_at", { ascending: false }).limit(1);

    if (res.data && res.data.length > 0) {
      return res;
    } else if (attempt < Object.keys(keys).length - 1) {
      const newKeys = { ...keys };
      const keysArray = Object.keys(newKeys);
      delete newKeys[keysArray[keysArray.length - 1 - attempt]];

      return fetchTimingPolicyByKeysApi(newKeys, attempt + 1);
    } else {
      return { data: [], error: "No data found" };
    }
  } catch (error) {
    return catch_return;
  }
};



export const getTableData = async (organizationId: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .select(`*, company(name), branch(name) , company_account_list(account_number,account_title,bank_name ) `)
      .eq("organization_id", organizationId);

    return response;
  } catch (error) {
    return error;
  }
};

export const getRecordByIdAPI = async (
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("payroll_generation")
      .select("*")
      .eq("id", recordId)
      .maybeSingle();

    return response;
  } catch (error) {
    return error;
  }
};

export const AllowanceToApproveByEmployeeIdApi = async (data: any): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("allowance_claim")
      .select(
        `created_at, name,amount_to_claim ,status,employee(full_name),id `
      )
      .eq("organization_Id", data.organizationId).eq("employee_id", data.employeeId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lt("created_at", new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).toISOString());
    ;

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchOverTimeClaimRequestByEmployeeIdList = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("over_time_claim")
      .select(
        `total_working_houre, date,id,reviewer_id,status,
          employee!over_time_claim_requested_employee_Id_fkey(full_name) as requestedEmployee,
        `
      )
      .eq("organization_Id", data.organizationId).eq("requested_employee_Id", data.employeeId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchPayrollEmployeesByPayrollIdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_salary")
      .select(`employee(full_name,avatar_url,joining_date,company(name), employee_designation_id_fkey(name) ,branch!employee_branch_id_fkey(name) ),id `)
      .eq("organization_id", data.organizationId).eq("payroll_id", data.payroll_id);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const insertSalarySheetTemplateApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("salary_sheet_template")
      .insert(data)
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
}
export const deleteSalarySheetTemplateApi = async (
  organizationId: any,
  id:  string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("salary_sheet_template")
      .delete()
      .eq("id", id);

    return response;
  } catch (error) {
    return error;
  }
};
export const updateSalarySheetTemplateApi = async (
  data: any,
 
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("salary_sheet_template")
      .update(data.details)
      .eq("id", data.id);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSalarySheetTemplateApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_Id)
      .from("salary_sheet_template")
      .select(`id,name,type,company(name),branch(name),options`)
      .eq("organization_id", data.organization_Id);
    if (data.company_id) {
      query = query.eq("company_id", data.company_id);
    }
    if (data.branch_id) {
      query = query.eq("branch_id", data.branch_id);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSalarySheetTemplateByIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_Id)
      .from("salary_sheet_template")
      .select(`*`)
      .eq("id", data.id).single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

