import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Button, Row, Col, Select } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getCountries } from "vk-country-state-city";
import { useForm } from "antd/es/form/Form";
import CustomAlert from "../../../../common/Alert";
import { setEmployeeExperienceModalDataApi } from "../services";
import CustomTable from "../../../CustomTable";
import NoDataFoundIcon from "../../../NoDataFoundIcon";

interface ExperienceModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (values: any) => void;
  data: any;
}

const ExperienceModal: React.FC<ExperienceModalProps> = ({
  visible,
  onClose,
  data,
}) => {
  const [countries, setCountries] = useState<string[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [tableData, setTableData] = useState<any>();

  const [form] = useForm();
  const { Option } = Select;

  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }

    fetchCountries();
    const incomingData = data.flatMap((item: any) => {
      return item.details;
    });
    setTableData(incomingData);
  }, []);

  const getAllFormValues = () => {
    const values = form.getFieldsValue();
    const valuesToBeAdded = {
      country: values.country,
      from: values.from.toISOString().split("T")[0],
      to: values.to.toISOString().split("T")[0],
      company: values.company,
      jobTitle: values.jobTitle,
      id: values.from.toISOString().split("T")[0] + values.company,
    };
    setTableData([...tableData, valuesToBeAdded]);
  };

  const handleSubmit = async () => {
    try {
      setButtonLoading(true);

      const body = {
        details: tableData,
        employee_id: loggedInEmployee?.employeeId || null,
        organization_id: loggedInEmployee?.employee?.organization_id || null,
        company_id: loggedInEmployee?.employee?.company_id || null,
        branch_id: loggedInEmployee?.employee?.branch_id || null,
      };
      const { data, error } = await setEmployeeExperienceModalDataApi(
        body,
        loggedInEmployee?.employee.organization_id,
        loggedInEmployee?.employeeId
      );

      if (data) {
        setshowAlert(true);

        setAlertType("success");
        setButtonLoading(false);
      }
      if (error) {
        setButtonLoading(false);
        setshowAlert(true);

        setAlertType("error");
      }
      setButtonLoading(false);

      form.resetFields();
      onClose();
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const disabledDate = (current: any) => {
    const currentDate = new Date();

    return current && current > currentDate;
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
  ];

  return (
    <>
      <Modal
        open={visible}
        width={1000}
        onCancel={onClose}
        footer={false}
        destroyOnClose
        className="global-modal-main-container"
        title={<span className="global-modal-title">Add Experience </span>}
      >
        <div className="global-card-inner-container">
          <Form
            form={form}
            layout="vertical"
            name="experienceForm"
            onFinish={handleSubmit}
          >
            <Row>
              <Col className="modal-content">
                <Form.Item
                  name={"country"}
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter Country Name!" },
                  ]}
                >
                  <Select
                    placeholder="Enter Country"
                    showSearch
                    allowClear
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {countries.map((item: any) => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"company"}
                  label="Company"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Enter Company ..." />
                </Form.Item>
                <Form.Item
                  name={"jobTitle"}
                  label="Job Title"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Enter Job Title ..." />
                </Form.Item>
                <Form.Item
                  name={"from"}
                  label="Start Date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    placeholder="Enter Start Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  name={"to"}
                  label="End Date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    placeholder="Enter End Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="global-btn-width workExpModal-btn-position"
                    onClick={getAllFormValues}
                    type="primary"
                  >
                    Add Data
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <CustomTable dataSource={tableData} columns={columns} />
          <Form.Item>
            <Button
              key="submit"
              className="global-btn-width workExpModal-btn-position"
              htmlType="submit"
              loading={buttonLoading}
              onClick={handleSubmit}
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default ExperienceModal;
