import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const insertBankDetailsApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_bank")
      .insert(data);
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
