import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Card, Col, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";

import { ProgressLoader } from "../../../../../common/ReactLoader";
import "./index.css";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";

interface ByDepartmentProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const ByDepartment: React.FC<ByDepartmentProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [departmentData, setDepartmentData] = useState<
    { id: string; value: number }[]
  >([]);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    setDepartmentchartData(props.employeeData);
  }, [props.employeeData]);

  useEffect(() => {
    filterBranches();
  }, [selectedCompany]);

  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterBranches();
  }, [props.companies, props.branches]);

  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);

  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    setDepartmentchartData(tempData);
  };

  const filterBranches = () => {
    if (props.branches && selectedCompany) {
      const filterBranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );
      setBranchOptions(filterBranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const setDepartmentchartData = async (data: any[]) => {
    if (data) {
      const departmentCounts = data?.reduce((counts: any, employee: any) => {
        const departmentId = employee?.department?.name || "Unknown";
        counts[departmentId] = (counts[departmentId] || 0) + 1;
        return counts;
      }, {});
      const result = Object.keys(departmentCounts).map((key) => ({
        id: key,
        value: departmentCounts[key],
      }));
      setDepartmentData(result);
    } else {
      setDepartmentData([]);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      className="dasboard-cards"
      title={
        <Row className="bydepartment-icon-toggle-container">
          <Col
            xxl={6}
            xl={6}
            lg={24}
            md={6}
            sm={24}
            xs={24}
            className="bydepartment-title-text"
          >
            By Department
          </Col>
          <Col xxl={18} xl={18} lg={24} md={18} sm={24} xs={24}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id && loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="bydepartment-card-selectors-row">
                {isToggled && (
                  <>
                    <Col
                      span={7}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a company..."
                        onChange={setSelectedCompany}
                        className="bydepartment-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col
                      span={7}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a branch"
                        onChange={setSelectedBranch}
                        className="bydepartment-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOptions?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col
                  span={2}
                  className="bydepartment-icon-container"
                  onClick={handleToggle}
                >
                  {isToggled ? (
                    <div className="bydepartment-search-icon-container">
                      <IoSearch className="bydesignation-search-icon" />
                    </div>
                  ) : (
                    <div className="bydepartment-search-icon-container">
                      <IoSearch className="bydesignation-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      {props.employeeDataShow ? (
        <ProgressLoader />
      ) : departmentData && departmentData.length > 0 ? (
        <div style={{ height: 300 }}>
          <ResponsivePie
            data={departmentData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.3}
            padAngle={8}
            cornerRadius={0}
            activeOuterRadiusOffset={5}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={3}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={departmentData.map((entry, index) => ({
              match: { id: entry.id },
              id: index % 2 === 0 ? "dots" : "lines",
            }))}
          />
        </div>
      ) : (
        <div
          style={{
            height: "18.5rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoDataFoundIcon />
        </div>
      )}
    </Card>
  );
};

export default ByDepartment;
