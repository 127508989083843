import React from 'react'
import EmployeePortalDashboardLayout from './EmployeeDashboardLayout'


const EmployeePortalDashboard: React.FC = () => {
    return (
        <EmployeePortalDashboardLayout/>
    )
}

export default EmployeePortalDashboard