import React, { useEffect, useState } from "react";
import { Space, Empty, Row, Col, Input, Card, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchRequestedLeavesApi } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import LeaveFormModal from "../LeaveApprovalModal";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import searchIcon from "../../../../assets/images/search-icon.png";
import CustomTable from "../../../../common/CustomTable";
import d_dp from "../../../../assets/images/blankProfile.png";

interface LeaveApprovalTableProp{
  onClose?: () => void;
  leaveTableData?: any;
}



const LeaveApprovalTable: React.FC<LeaveApprovalTableProp> = ({onClose,leaveTableData}) => {
  const [tableData, setTableData] = useState<any>();
  const [tableLoader, setTableLoader] = useState<boolean>(true);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [modalCounter, setModalCounter] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });


 
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const orgainzationId = loggedInEmployee?.employee?.organization_id;
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Leave Approval"
  );
  const create = access?.createAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;


  useEffect(() => {
    if(leaveTableData && leaveTableData?.length > 0){
      setTableData(leaveTableData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: leaveTableData,
      }));
      setTableLoader(false)

    }
    if(!leaveTableData)
    {

      fetchData();
    }
    
  }, [leaveTableData]);

  const fetchData = async () => {
    setTableLoader(true);
    const res = await fetchRequestedLeavesApi(
      orgainzationId,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id
    );
    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
    setTableLoader(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      width: "30%",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={3} xxl={3} className="img-col">
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={record?.avatar_url ? record?.avatar_url : d_dp}
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.first_name}</strong>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        const dateTime = new Date(text);
        const formattedDateTime = dateTime.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Count",
      dataIndex: "leave_count",
      key: "leave_count",
    },
    {
      title: "Status",
      dataIndex: "leave_status",
      key: "leave_status",
    },
    {
      title: "Actions",
      key: "action",
      render: (record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsModalVisible(true);
              setSelectedRecord(record.id);
            }}
          >
            <EyeOutlined />
          </a>
        </Space>
      ),
    },
  ];


  const handleSearch = (e: any) => {
    setTableLoader(true);
    const value = e.target.value;
    setSearchQuery(value);
    let filterData: any;
    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "first_name"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setTableLoader(false);
  };

  const filterRecordsByMonth = (selectedDate: any) => {
    if (!selectedDate) {
      setTableData(originalData.apiData);
      return;
    }
    const year = selectedDate.year();
    const month = selectedDate.month();
    const filteredData = originalData.apiData.filter((record: any) => {
      const recordDate = moment(record.created_at);
      return recordDate.year() === year && recordDate.month() === month;
    });
    setTableData(filteredData);
  };

const onModalClose = ()=>{
  setIsModalVisible(false);
    setSelectedRecord(null);
    setModalCounter(modalCounter + 1);
  if(leaveTableData?.length > 0 && onClose){
    onClose()

  }
  if(!leaveTableData)
  {
    fetchData()
    
  }

}


  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <div className="leave-approval-search  table-content-search">
            <Row gutter={[16, 16]} align="middle" justify="space-between">
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Input
                  value={searchQuery}
                  
                  onChange={handleSearch}
                  placeholder="Search Employee..."
                  onPressEnter={handleSearch}
                  prefix={<img src={searchIcon} alt="Search icon" />}
                  allowClear
                  size="large"
                  className="leave-approval-search-input"
                />
              </Col>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <DatePicker
                  picker="month"
                  onChange={(date, dateString) => {
                    filterRecordsByMonth(date);
                  }}
                  placeholder="Select Month"
                  className="month-picker"
                  disabledDate={(current) => {
                    return (
                      current && current < dayjs("2023-01-01", "YYYY-MM-DD")
                    );
                  }}
                />
              </Col>
            </Row>{" "}
          </div>
          {read ? (
            <CustomTable
              dataSource={tableData}
              columns={columns}
              loading={tableLoader}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}

          <Modal
            open={isModalVisible}
            onCancel={onModalClose}
            width={1000}
            footer={false}
            title={<span className="global-modal-title">Leave Approval</span>}
            className="global-modal-main-container"
            destroyOnClose
          >
            <LeaveFormModal
              onCancel={onModalClose}
              selectedRecord={selectedRecord}
              create={create}
              deletee={deletee}
              onModalClose={onModalClose}
            />
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default LeaveApprovalTable;
