import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import "./index.css";
import { HelpAndSupport } from "../../Constants";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const faqs = HelpAndSupport.FAQs;
const { Panel } = Collapse;

export const FAQ_items = [
  {
    key: "1",
    label: faqs.question1,
    answer: faqs.answer1,
  },
  {
    key: "2",
    label: faqs.question2,
    answer: faqs.answer2,
  },
  {
    key: "3",
    label: faqs.question3,
    answer: faqs.answer3,
  },
  {
    key: "4",
    label: faqs.question4,
    answer: faqs.answer4,
  },
  {
    key: "5",
    label: faqs.question5,
    answer: faqs.answer5,
  },
];

const FAQ: React.FC<{ items?: CollapseProps["items"] }> = ({ items }) => (
  <div className="faq-outer-dashboard-container">
    {FAQ_items.map((accordion, index) => (
      <Collapse
        bordered={false}
        expandIconPosition="right"
        className="faqs-collapse-style"
        expandIcon={({ isActive }) =>
          isActive ? <MinusOutlined /> : <PlusOutlined />
        }
      >
        <Panel
          header={accordion.label}
          key={index}
          className="faq-collapse-panel-style"
        >
          <div className="faq-collapse-panel-style-div">
            <p className="faqs-answer-style">{accordion.answer}</p>
          </div>
        </Panel>
      </Collapse>
    ))}
  </div>
);

export default FAQ;
