import supabase, {
  supabase_client_for_operation_portal_schema,
} from "../../services/supabase";

const customCatchError = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchComplainReportingData = async (id: string , org_id:string) => {
  try {

    const res = await supabase_client_for_operation_portal_schema
      .from("complaint")
      .select(
        "name, email, id, type, status, complaint_message,  complainee_employee_id"
      )
      .eq("complainee_employee_id", id)
      .eq("organization_ids", org_id)
      .order("created_at", { ascending: false });
    return res;
  } catch {
    return customCatchError;
  }
};

export const fetchTheBugReporting = async (
  organization_Id: any,
  employee_Id: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_operation_portal_schema
      .from("bug_report")
      .select("*")
      .eq("organization_id", organization_Id)
      .eq("reported_by", employee_Id);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
export const fetchComplaintDataById = async (complaintId: number) => {
  try {
    const res = await supabase_client_for_operation_portal_schema
      .from("complaint")
      .select("name, email, id, type, status, complaint_message, response")
      .eq("id", complaintId)
      .maybeSingle();

    return res;
  } catch {
    return customCatchError;
  }
};
