import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal, Popconfirm, Space, TablePaginationConfig } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { SortOrder } from "antd/lib/table/interface";
import moment from "moment";
import CustomAlert from "../../../../common/Alert";
import DeductionEditView from "../Edit";
import {
  deleteDeductionpolicyApi,
  fetchAllDeductionPoliciesApi,
} from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface DeductionListProps {
  searchValue?: string;
}

const DeductionListView: React.FC<DeductionListProps> = ({ searchValue }) => {
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [tableData, setTableData] = useState<any[]>([]);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const branchId = loggedInEmployee?.employee?.branch_id;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "Deduction Policy"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;

  useEffect(() => {
    fetchDeductionTableData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchDeductionTableData = async () => {
    setTableLoading(true);
    setSearchQuery("");

    const res = await fetchAllDeductionPoliciesApi(
      branchId,
      organizationId,
      companyId
    );
    setTableLoading(false);

    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
  };

  const deleteDetail = async (record: any) => {
    setTableLoading(false);
    if (record.id) {
      const res = await deleteDeductionpolicyApi(record.id, organizationId);
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        setshowAlert(true);
        setAlertType("success");
        fetchDeductionTableData();
      }
    }
  };

  const handleEditModel = async (record: any) => {
    setSelectedId(record.id);
    setShowModel(true);
  };

  const handelCancel = async () => {
    setSelectedId("");
    setShowModel(false);
    fetchDeductionTableData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (record: any) => moment(record).format("YYYY-MMM-DD"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <span className="link">
              <EditOutlined onClick={() => handleEditModel(record)} />
            </span>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            onConfirm={() => deleteDetail(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link">
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleSearch = (value: any) => {
    setTableLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setTableLoading(false);
  };

  return (
    <div>
      <CustomTable
        loading={tableLoading}
        dataSource={tableData}
        columns={columns}
        onChange={handleTableChange}
      />

      {selectedId && (
        <Modal
          title={<span className="global-modal-title">Dedction Details</span>}
          open={showModel}
          onCancel={handelCancel}
          width={1000}
          footer={false}
          className="global-modal-main-container"
        >
          <DeductionEditView id={selectedId} oncancel={() => handelCancel()} />
        </Modal>
      )}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default DeductionListView;
