import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Form, Row, Col, Input, Select, Checkbox, Button } from "antd";

import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchAllowanceApi, setGradeApi } from "../../services";
import { capitalizeWords } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";
import { allorgCompanyBranchesApi } from "../../../../services/Global";
import { allorgCompanyApi } from "../../../../services/Global";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface PayrollGradeCreateprops {
  onCancel: () => void;
}
const PayrollGradeCreate: React.FC<PayrollGradeCreateprops> = (props) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const comapnyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const [form] = Form.useForm();
  const [initalAllowanceOption, setInitalAllowanceOption] = useState<any>();
  const [allowedOptions, setAllowedOptions] = useState([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [alertInfoMessage, setAlertInfoMessage] = useState<string>();
  useEffect(() => {
    if (comapnyId) {
      const comanyid = comapnyId;
      form.setFieldValue("companyId", comanyid);
      setSelectedCompanyId(comanyid);
      if (branchId) {
        const branchid = branchId;
        form.setFieldValue("branchId", branchid);
        setSelectedBranchId(branchid);
      }
    } else {
      fetchCompanies();
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!(branchId || comapnyId)) {
      fetchBranches();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId || selectedBranchId) {
      fetchTableData();
    }
  }, [selectedCompanyId, selectedBranchId]);
  useEffect(() => {
    const newAllowedOptions = initalAllowanceOption?.filter(
      (option: any) => option.isActive
    );
    setAllowedOptions(newAllowedOptions);
  }, [initalAllowanceOption]);

  const handleCheckboxChange = (index: any) => {
    const newOptions = [...initalAllowanceOption];
    newOptions[index].isActive = !newOptions[index].isActive;
    setInitalAllowanceOption(newOptions);
  };

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies.data[0]?.id);
      setSelectedCompanyId(resCompanies.data[0]?.id);
    }
  };
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      const resBranches = await allorgCompanyBranchesApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches.data[0]?.id);
        setSelectedBranchId(resBranches.data[0]?.id);
      }
    }
  };

  const handleInputChange = (index: any, key: any, value: any) => {
    const newOptions = [...initalAllowanceOption];
    newOptions[index][key] = value;
    if (key == "unitAllowed" || key == "unitPrice") {
      newOptions[index]["totalprice"] = newOptions[index]["unitPrice"] * value;
    }
    setInitalAllowanceOption(newOptions);
  };

  const fetchTableData = async () => {
    setInitalAllowanceOption([]);

    const data = {
      organizationId: organizationId,
      companyId: selectedCompanyId || comapnyId,
      branchId: selectedBranchId || branchId,
    };

    const res = await fetchAllowanceApi(data);
    if (res.data && res.data.length > 0) {
      const newArray = res.data?.map((item: any) => {
        return {
          ...item,
          isActive: false,
          unitAllowed: "",
          totalprice: 0,
          unitPrice: "",
        };
      });
      setInitalAllowanceOption(newArray);
    }
  };
  const onFinish = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    if (allowedOptions?.length > 0) {
      const data = {
        organization_Id: organizationId,
        company_Id: values.companyId || comapnyId || null,
        branch_Id: values.branchId || branchId || null,
        name: capitalizeWords(values.grade),
        allowed_option: allowedOptions,
      };
      const res = await setGradeApi(data, organizationId);
      if (res.status == 201) {
        form.resetFields();
        setInitalAllowanceOption([]);
        setshowAlert(true);
        setAlertType("success");
        setButtonLoading(false);
        props.onCancel();
      } else if (res.error) {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertType("error");
      }
    } else {
      setshowAlert(true);
      setAlertType("info");
      setAlertInfoMessage(
        "Please select at least one allowance option to proceed"
      );
    }
    setAlertType("error");
    timeoutRef.current = setTimeout(() => {
      setshowAlert(false);
    }, 3000);
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            {!comapnyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="companyId"
                  label="Select Company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Company",
                    },
                  ]}
                  initialValue={comapnyId}
                >
                  <Select
                    disabled={comapnyId}
                    onChange={(value) => setSelectedCompanyId(value)}
                    placeholder="Select a Company"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredCompanies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="branchId"
                  label="Select Branch (If any)"
                  initialValue={branchId}
                >
                  <Select
                    disabled={branchId}
                    placeholder="Select a Branch"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={(value) => setSelectedBranchId(value)}
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="grade"
                label="Enter Grade"
                rules={[
                  {
                    required: true,
                    message: "Please Enter an Grade",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter grade..." />
              </Form.Item>
            </Col>
          </Row>
          {initalAllowanceOption?.length > 0 && (
            <Row>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <strong>Name </strong>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <strong>Unit(if any) </strong>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <strong>Price/Unit(if any) </strong>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <strong>Unit Allowed(if any) </strong>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <strong>Total Price </strong>
              </Col>
            </Row>
          )}
          {initalAllowanceOption?.map((option: any, index: any) => (
            <Row
              key={index}
              gutter={8}
              className={
                option.isActive
                  ? "payrollgrads-form-container-set-row-postion-check"
                  : "payrollgrads-form-container-set-row-postion-uncheck"
              }
            >
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <Checkbox
                  onChange={() => handleCheckboxChange(index)}
                  value={option.isActive}
                >
                  {option.name}
                </Checkbox>
              </Col>
              {option.isActive && (
                <>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Input
                      value={option.unit}
                      placeholder="Enter Unit"
                      disabled
                      size="large"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Input
                      value={option.unitPrice}
                      placeholder="Enter Price/Unit"
                      onChange={(e) =>
                        handleInputChange(index, "unitPrice", e.target.value)
                      }
                      disabled={!option.unit}
                      size="large"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                    <Input
                      placeholder="Enter Unit Allowed"
                      onChange={(e) =>
                        handleInputChange(index, "unitAllowed", e.target.value)
                      }
                      disabled={!option.unit}
                      size="large"
                    />
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                    <Input
                      placeholder="Enter Total Price"
                      size="large"
                      onChange={(e) =>
                        handleInputChange(index, "totalprice", e.target.value)
                      }
                      value={option.totalprice}
                      disabled={option.unitPrice && option.unit ? true : false}
                    />
                  </Col>
                </>
              )}
            </Row>
          ))}
          <Row
            gutter={[16, 16]}
            className="global-modal-bottom-row payrollgrads-form-container-last-row"
          >
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          showIcon={true}
          autoCloseDuration={3000}
          message={alertInfoMessage}
        />
      )}
    </>
  );
};

export default PayrollGradeCreate;
