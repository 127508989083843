import supabase, {
  supabase_client_for_Org_schema,
} from "../../services/supabase";

//------------------------------------------Application Submission------------------------------------

//Function Responsible for handling the uploading of resumes to supabase storage

export const submitResume = async (fileName: any, file: any): Promise<any> => {
  try {
    const resumeResponse = await supabase.storage
      .from("resumes")
      .upload(fileName, file);

    return resumeResponse;
  } catch (error) {
    return error;
  }
};

export const getThePublicURL = async (filePath: any): Promise<any> => {
  try {
    const publicURLResponse = await supabase.storage
      .from("resumes")
      .getPublicUrl(filePath);

    return publicURLResponse;
  } catch (error) {
    return error;
  }
};

export const sendDataToJobApplicationsTable = async (
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("job_application")
      .insert(data);

    return response;
  } catch (error) {
    return error;
  }
};

//Getting the Job Applications based on the organization or company id
export const fetchTotalApplicationsByOrganizationOrCompanyIdApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("job")
      .select(`*`)
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return error;
  }
};

//Function to fetch the application submission form data
export const fetchApplicationSubmissionFormData = async (
  orgid: any,
  jobid: any
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(orgid)
      .from("job")
      .select(`*, company!job_company_id_fkey(name,logo)`)
      .eq("organization_id", `${orgid}`)
      .eq("id", `${jobid}`)
      .single();

    if (res.data) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

//---------------------------------------------Hired Candidates APIs-----------------------------------

//Function to get the job details
export const getTheJobDetails = async (
  jobId: any,
  searchInput: any,
  organizationId: string
): Promise<any> => {
  try {
    let response = supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .select("*")
      .eq("job_id", jobId);

    if (searchInput) {
      response = response.ilike("name", `%${searchInput}%`);
    }
    response = response.eq("application_status", "hired");
    return response;
  } catch (error) {
    return error;
  }
};

//Function to delete the resume
export const deleteTheResume = async (decodedFileName: any): Promise<any> => {
  try {
    const response = await supabase.storage
      .from("resumes")
      .remove([`${decodedFileName}`]);

    return response;
  } catch (error) {
    return error;
  }
};

//Function to delete the corresponding record from the database table along with the resume
export const removeCorrespondingRecord = async (
  recordId: string,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .delete()
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//--------------------------------------------Application Submission Viewmodal------------------------

//Function to fetch the Application details
export const fetchApplicantDetailsApi = async (
  id: any,
  organizationId: string
): Promise<any> => {
  try {
    const { data, error } = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .select("*")
      .eq("id", id)
      .single();
    return { data, error };
  } catch (error) {
    return error;
  }
};

//---------------------------------------Job Applicants------------------------------------------------

//Function to get the job details
export const getJobDetails = async (
  jobId: any,
  searchInput: any
): Promise<any> => {
  try {
    let response = supabase
      // .from("jobApplicationSubmissions")
      .from("job_application")
      .select("*")
      .eq("jobId", jobId);

    if (searchInput) {
      response = response.ilike("name", `%${searchInput}%`);
    }

    const finalResponse = await response;

    return finalResponse;
  } catch (error) {
    return error;
  }
};

export const retrieveApplicationDetails = async (
  JobId: any,
  searchInput: any,
  jobApplicationStatusFilter: any,
  organizationId: string
): Promise<any> => {
  try {
    let response = supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .select("*")
      .eq("job_id", JobId)
      .eq("application_status", "submitted");
    if (searchInput) {
      response = response.ilike("name", `%${searchInput}%`);
    }
    if (jobApplicationStatusFilter) {
      if (jobApplicationStatusFilter === "submitted") {
        response = response.eq("application_status", "submitted");
      } else if (jobApplicationStatusFilter === "shortlisted") {
        response = response.eq("application_status", "shortlisted");
      }
    }
    const result = await response;
    return result;
  } catch (error) {
    return error;
  }
};

//Function to handle the accepted applications
export const handleAcceptedApplications = async (
  recordId: string,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .update({ application_status: "shortlisted" })
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//Function to get the job filters
export const retrieveJobFilters = async (
  jobId: string,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_filter")
      .select("*")
      .eq("job_id", jobId)
      .single();

    return response;
  } catch (error) {
    return error;
  }
};

//Function to delete the resume
export const deleteResume = async (decodedFileName: any): Promise<any> => {
  try {
    let response = supabase.storage
      .from("resumes")
      .remove([`${decodedFileName}`]);

    const finalResponse = await response;

    return finalResponse;
  } catch (error) {
    return error;
  }
};

//function to delete the relevant record from the database
export const deleteCorrespondingRecord = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .delete()
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//----------------------------------------------Job Applicants Filter Modal----------------------------

//Function to get the filters from database
export const getFiltersDataFromDb = async (
  recordId: string,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_filter")
      .select("*")
      .eq("job_id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//Function to submit the data to database
export const submitData = async (
  id: any,
  formData: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_filter")
      .insert({
        job_id: id,
        filters: formData,
      });
    return response;
  } catch (error) {
    return error;
  }
};

//Function to update the data
export const updatePayload = async (
  id: any,
  formData: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_filter")
      .update({
        job_id: id,
        filters: formData,
      })
      .eq("job_id", id);

    return response;
  } catch (error) {
    return error;
  }
};

//--------------------------------Job Post Dashboard APIs--------------------------------------------
//----------------------------------------{JobByTypes}---------------------------------------------

//function to fetch the all types of jobs
export const fetchAllTypeJobApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("job")
      .select(`id, basic_details`)
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return error;
  }
};

//function toi fetch the companies
export const fetchCompanies = async (organizationId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name")
      .eq("organization_Id", organizationId);

    return response;
  } catch (error) {
    return error;
  }
};

//function to fetch the branches
export const fetchBranches = async (
  selectedCompany: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", selectedCompany);

    return response;
  } catch (error) {
    return error;
  }
};

//--------------------------------------{Shortlisted Application Chart}------------------------------

//function to get the application status
export const fetchApplicationsStatusByOrganizationidandJobidApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("job_application")

      .select(
        `job_id, application_status, job!job_application_job_id_fkey(basic_details)`
      )
      .eq("organization_id", data.organizationId);

    if (data.jobId) {
      query = query.eq("job_id", data.jobId);
    }
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return error;
  }
};

//---------------------------------------{Job Details}-------------------------------

//function to get the job data
export const fetchJobData = async (organizationId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .select("*")
      .eq("organization_id", organizationId);

    return response;
  } catch (error) {
    return error;
  }
};

//API call to fetch the total applications against a job
export const fetchTotalApplicationsApi = async (data: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.organizationId)
      .from("job_application")
      .select(`id`)
      .eq("organization_id", data.organizationId);
    return res;
  } catch (error) {
    return error;
  }
};

//----------------------------------------Job Posting && Job Posting Card---------------------------------------------

//function to handle the data submission
export const handleJobDataSubmission = async (finalData: any): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(
      finalData.organization_id
    )
      .from("job")
      .insert([finalData]);
    return response;
  } catch (error) {
    return error;
  }
};

export const handleSwitchChanging = async (
  checked: any,
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .update({ status: checked })
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//function to delete the record from jobs table
export const deleteRecordFromAddJobs = async (
  jobId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .delete()
      .eq("id", jobId);
    return response;
  } catch (error) {
    return error;
  }
};
//function to get the data
export const getTheData = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("job")
      .select("*")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq(" company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query.order("created_at", { ascending: false });
    return response;
  } catch (error) {
    return error;
  }
};

//--------------------------------------------Edit Job Details-----------------------------------------

//function to handle the fetching of job details
export const handleGetJobDetails = async (
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .select("*")
      .eq("id", id)
      .single();

    return response;
  } catch (error) {
    return error;
  }
};

//function to handle the updation of job details
export const handleJobDetailsUpdation = async (
  finalData: any,
  id: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(
      finalData.organization_id
    )
      .from("job")
      .update([finalData])
      .eq("id", id)
      .single();

    return response;
  } catch (error) {
    return error;
  }
};
//------------------------------------------Preview Job----------------------------------------------

export const getAllJobDetails = async (
  id: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .select("*")
      .eq("id", id)
      .single();
    return response;
  } catch (error) {
    return error;
  }
};

//-------------------------------------------------View Job------------------------------------

//function to fetch the data
export const fetchData = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("job")
      .select("*")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq(" company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query.order("created_at", { ascending: false });
    return response;
  } catch (error) {
    return error;
  }
};


//funciton to get organizaiotn domain
export const fetchOrgDomain = async (
  organizationId: any,
): Promise<any> => {
  try {
 
    const response = await supabase.rpc("get_organization_domain", {
      org_id: organizationId,
    })
    .maybeSingle()
    return response;
  } catch (error) {
    return error;
  }
};

//function to delete the corresponding record from the database
export const jobDeletion = async (
  jobId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .delete()
      .eq("id", jobId);
    return response;
  } catch (error) {
    return error;
  }
};

export const handleTheSwitchChanging = async (
  checked: any,
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job")
      .update({ status: checked })
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};
//----------------------------------------------Resume Bank---------------------------

//function to get all the details
export const getDetails = async (
  organizationId: any,
  companyId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .select("*")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return error;
  }
};

//handle the deletion of resume from storage
export const resumeDeletion = async (decodedFileName: any): Promise<any> => {
  try {
    let response = supabase.storage
      .from("resumes")
      .remove([`${decodedFileName}`]);

    const finalResponse = await response;

    return finalResponse;
  } catch (error) {
    return error;
  }
};

//handle the deletion of record from database
export const deleteFromRecord = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .delete()
      .eq("id", recordId);
    return response;
  } catch (error) {
    return error;
  }
};

//-------------------------------------Shortlisted Candidates---------------------------------------

//function to fetch the job details
export const jobDetailsFetching = async (
  JobId: any,
  searchInput: any,
  organizationId: string
): Promise<any> => {
  try {
    let response = supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .select("*")
      .eq("job_id", JobId);
    if (searchInput) {
      response = response.ilike("name", `%${searchInput}%`);
    }
    response = response.eq("application_status", "shortlisted");
    const finalResponse = await response;
    return finalResponse;
  } catch (error) {
    return error;
  }
};

//function to get the job filters
export const jobFilters = async (jobId: any) => {
  try {
    const response = await supabase
      .from("jobFilters")
      .select("*")
      .eq("jobId", jobId)
      .single();
    return response;
  } catch (error) {
    return error;
  }
};

//function to handle the application acceptance
export const handleApplicationAcceptance = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .update({ application_status: "hired" })
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//handle resume deletion logic
export const handleResumeDeletionLogic = async (
  decodedFileName: any
): Promise<any> => {
  try {
    let response = supabase.storage
      .from("resumes")
      .remove([`${decodedFileName}`]);
    const finalResponse = await response;
    return finalResponse;
  } catch (error) {
    return error;
  }
};

//handle the deletion of the corresponding record from database associated with that particular resume
export const jobApplicationDeletionLogic = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("job_application")
      .delete()
      .eq("id", recordId);

    return response;
  } catch (error) {
    return error;
  }
};

//--------------------------------------------{General APIs}----------------------------------------

export const getQuestions = async (id: any, organizationId: string) => {
  const response = await supabase_client_for_Org_schema(organizationId)
    .from("job")
    .select("questions")
    .eq("id", id)
    .single();

  return response;
};

export const downloadFilesFromSupabase = async (
  fileName: any
): Promise<any> => {
  try {
    const response = await supabase.storage.from("resumes").download(fileName);
    return response;
  } catch (error) {
    return error;
  }
};
