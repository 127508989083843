import React from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface AttendanceSummaryProps {
  data: {
    title: string;
    value: string | number;
    color: string;
    icon: IconDefinition;
    onClick?: () => void;
  }[];
}

const AttendanceSummary: React.FC<AttendanceSummaryProps> = ({ data }) => {
  return (
    <div className="attendance-summary">
      {data &&
        data.map((item, index: number) => (
          <div
            key={index}
            className="summary-item"
            style={{
              border: `1px solid ${item.color}`,
              color: item.color,
            }}
            onClick={item.onClick}
          >
            <style>
              {`
              .summary-item:nth-child(${index + 1}):hover {
                background-color: ${item.color};
                color: var(--white-colour);
              }
            `}
            </style>
            <span className="summary-title">
              <FontAwesomeIcon
                icon={item.icon}
                className="tab-space summary-icon"
                style={{ color: item.color }}
              />
              {item.title}
            </span>
            <span className="summary-value" style={{ color: item.color }}>
              {item.value || "---"}
            </span>
          </div>
        ))}
    </div>
  );
};

export default AttendanceSummary;
