import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Row, Col, InputNumber } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { setEmployeeSkillsModalDataApi } from "../services";
import CustomTable from "../../../CustomTable";

interface SkillsModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (values: any) => void;
  data: any;
}

const SkillsModal: React.FC<SkillsModalProps> = ({
  visible,
  onClose,
  onFinish,
  data,
}) => {
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    const incomingData = data.flatMap((item: any) => {
      return item.skill_details;
    });
    setTableData(incomingData);
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getAllFormValues = () => {
    const values = form.getFieldsValue();
    const valuesToBeAdded = {
      experience: values.experience,
      skill: values.skill,
      comments: values.comments,
      id: Date.now(),
    };
    setTableData([...tableData, valuesToBeAdded]);
  };

  const handleSubmit: any = async (values: any) => {
    try {
      setButtonLoading(true);

      const formData = {
        employee_id: loggedInEmployee?.employeeId || null,
        organization_id: loggedInEmployee?.employee?.organization_id || null,
        company_id: loggedInEmployee?.employee?.company_id || null,
        branch_id: loggedInEmployee?.employee?.branch_id || null,
        skill_details: tableData,
      };

      const { data, error } = await setEmployeeSkillsModalDataApi(
        formData,
        loggedInEmployee?.employee.organization_id
      );
      if (data) {
        setshowAlert(true);

        setAlertType("success");
        setButtonLoading(false);
      }
      if (error) {
        setButtonLoading(false);
        setshowAlert(true);

        setAlertType("error");
      }
      setButtonLoading(false);

      onFinish(values);
      form.resetFields();
      onClose();
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const columns = [
    {
      title: "Skill",
      dataIndex: "skill",
      key: "skill",
    },
    {
      title: "Years Of Experience",
      dataIndex: "experience",
      key: "experience",
    },
  ];

  return (
    <Modal
      open={visible}
      width={1000}
      onCancel={onClose}
      footer={false}
      className="global-modal-main-container"
      title={<span className="global-modal-title">Add Skills </span>}
    >
      <div className="global-card-inner-container">
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row>
            <Col className="modal-content">
              <Form.Item
                label="Skill"
                name="skill"
                rules={[{ required: true, message: "Please enter skill" }]}
              >
                <Input size="large" placeholder="Enter Skills ..." />
              </Form.Item>
              <Form.Item
                label="Years of Experience"
                name="experience"
                rules={[
                  {
                    required: true,
                    message: "Please enter years of experience",
                  },
                ]}
              >
                <InputNumber
                  className="global-input-number-width"
                  placeholder="Enter Years of Experience ..."
                  size="large"
                />
              </Form.Item>
              <Form.Item label="Comments" name="comments">
                <Input.TextArea placeholder="Enter Comments ..." rows={3} />
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width skillsModal-btn-position"
                  onClick={getAllFormValues}
                  type="primary"
                >
                  Add Data
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CustomTable dataSource={tableData} columns={columns} />
        <Form.Item>
          <Button
            key="submit"
            className="global-btn-width skillsModal-btn-position"
            htmlType="submit"
            loading={buttonLoading}
            onClick={handleSubmit}
            type="primary"
          >
            Submit
          </Button>
        </Form.Item>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </Modal>
  );
};

export default SkillsModal;
