import React, { useState, useEffect } from "react";
import "./index.css";
import {
  Table,
  Popconfirm,
  TablePaginationConfig,
  Space,
  Modal,
  Empty,
} from "antd";
import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert";
import { deleteTaxationApi } from "../../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import TaxationEditView from "../Edit";
import { fetchTaxationApi } from "../../../../services/Global";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface TaxationListProps {
  searchValue?: string;
}

const TaxationListView: React.FC<TaxationListProps> = ({ searchValue }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [tableData, setTableData] = useState<any>();
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [tableLoader, setTableLoader] = useState<boolean>(true);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Payroll", "Taxation");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;
  const organizationId = loggedInEmployee?.employee?.organization_id;

  useEffect(() => {
    fetchtableData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchtableData = async () => {
    setTableLoader(true);
    setSearchQuery("");
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      searchQuery: searchQuery,
    };
    const res = await fetchTaxationApi(data);
    setTableLoader(false);
    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const deleteTexation = async (record: any) => {
    const data = {
      id: record,
    };
    const res = await deleteTaxationApi(data, organizationId);

    if (res.status == 204) {
      setshowAlert(true);

      setAlertType("success");
      fetchtableData();
    } else {
      setshowAlert(true);

      setAlertType("error");
    }
  };

  const expandedRowRender = (tableData: any) => {
    const subcolumns = [
      {
        title: "Tax will apply if Over",
        dataIndex: "taxApplyFrom",
        key: "taxApplyFrom",
        render: (_: any, item: any) =>
          item?.taxApplyFrom
            ? parseFloat(item?.taxApplyFrom)?.toLocaleString()
            : "",
      },
      {
        title: "Not Over",
        dataIndex: "taxApplyTo",
        key: "taxApplyTo",
        render: (_: any, item: any) =>
          item?.taxApplyTo
            ? parseFloat(item?.taxApplyTo)?.toLocaleString()
            : "",
      },
      {
        title: "% of applied",
        dataIndex: "taxPercentage",
        key: "taxPercentage",
      },
      {
        title: "Fix Amount",
        dataIndex: "fix_amount",
        key: "fix_amount",
        render: (_: any, item: any) =>
          item?.fix_amount
            ? parseFloat(item?.fix_amount)?.toLocaleString()
            : "",
      },
    ];

    return (
      <>
        <CustomTable dataSource={tableData.slabs} columns={subcolumns} rowKey="id" />
      </>
    );
  };

  const columns = [
    {
      title: "Version Name",
      dataIndex: "taxation_version_name",
      key: "taxation_version_name",
      sorter: (a: any, b: any) => {
        const nameA = a?.taxationVersion || "";
        const nameB = b?.taxationVersion || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <span
              className="link"
              onClick={() => {
                setSelectedRecordId(record.id);
                setShowEditModel(true);
              }}
            >
              <EditOutlined />
            </span>
          )}
          <Popconfirm placement="topLeft"
            title="Are you sure you want to delete this Tasation Version?"
            onConfirm={() => deleteTexation(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link">
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handelCancel = () => {
    setShowEditModel(false);
    setSelectedRecordId("");
    fetchtableData();
  };

  const handleSearch = (value: any) => {
    setTableLoader(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "taxation_version_name"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setTableLoader(false);
  };

  return (
    <>
      <div className="taxation-main-container  ">
        {read ? (
          <CustomTable
            dataSource={tableData}
            columns={columns}
            loading={tableLoader}
            rowKey="id"
            onChange={handleTableChange}
            expandable={{
              expandedRowRender,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}

        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}

        {selectedRecordId && showEditModel && (
          <Modal
            open={showEditModel}
            onCancel={handelCancel}
            width={1000}
            footer={false}
            title={<span className="global-modal-title">Edit Details</span>}
          >
            <TaxationEditView
              recordId={selectedRecordId}
              onCancel={handelCancel}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default TaxationListView;
