import React, { useEffect, useState } from "react";
import {
  Space,
  TablePaginationConfig,
  Modal,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Tag,
  Button,
  Typography,
  Empty,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import { useForm } from "antd/es/form/Form";
import { employeeResignationListViewData } from "../services";
import { filterLogicHandler } from "../../../../common/SearchAndFilterFunction";
import ResignationFilterModal from "../Filter";
import CustomTable from "../../../../common/CustomTable";
import CreateEmployeeResignation from "../CreateEmployeeResignation";
import { FiPlus } from "react-icons/fi";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface ResignationListProps {
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const ResignationListView: React.FC<ResignationListProps> = ({
  openFilterModal,
  toggleFilterModal,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);

  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any | null>(null);
  const loggedInEmployee =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const [form] = useForm();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  useEffect(() => {
    if (accessObj) {
      handleRadioChange();
    }
  }, []);

  const handleRadioChange = () => {
    const parentMenu = "Requests";

    const access = extractAccessRightObject(
      accessObj,
      parentMenu,
      "Resignation"
    );
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await employeeResignationListViewData(
      loggedInEmployee,
      organizationId
    );

    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.length,
        },
      });
      setData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleView = async (record: any) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "Notice Period Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Notice Period End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record: any) => {
        let color =
          capitalizeWords(record) == "Requested"
            ? "red"
            : capitalizeWords(record) == "Rejected"
            ? "orange"
            : capitalizeWords(record) == "Accepted"
            ? "green"
            : capitalizeWords(record) == "Terminate"
            ? "blue"
            : "blue";
        return (
          <div>
            <Tag color={color} className="reignation-list-view-tag">
              {capitalizeWords(record)}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <a onClick={() => handleView(record)}>
            <EyeOutlined />
          </a>
        </Space>
      ),
    },
  ];

  const getFilterDataHandler = (Status: string) => {
    const filterArray = [{ path: "status", value: Status }];
    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setData(response);
    }
  };

  const onCreateClick = () => {
    setCreateModalVisible(true);
  };
  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    fetchData();
  };

  return (
    <div className="dashboard-cards-for-requests global-request-container-styling resignation-container">
      {readAccess ? (
        <>
          {employeeRole !== "employee" && createAccess && (
            <div className="resignation-view-card-header">
              <Button
                size="large"
                type="primary"
                onClick={onCreateClick}
                icon={<FiPlus className="tab-header-icon" />}
              >
                Create
              </Button>
            </div>
          )}
          <CustomTable
            dataSource={data}
            columns={columns}
            loading={loading}
            onChange={handleTableChange}
          />{" "}
          <Modal
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={false}
            width={1000}
            title={
              <span className="global-modal-title">Resignation Details</span>
            }
            className="global-modal-main-container"
          >
            <div className="global-card-inner-container">
              <Form layout="vertical" form={form}>
                <Row gutter={16}>
                  {selectedRecord?.employee?.full_name && (
                    <Col span={24} md={12}>
                      <Form.Item
                        label="Approved/Rejected By"
                        name={"fullName"}
                        initialValue={selectedRecord?.employee?.full_name}
                      >
                        <Input
                          value={selectedRecord?.employee?.full_name}
                          size="large"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={24} md={12}>
                    <Form.Item label="Notice Period Span">
                      <Input
                        size="large"
                        value={selectedRecord?.notice_period}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label="Notice Period Start Date">
                      <DatePicker
                        style={{ width: "100%" }}
                        value={
                          selectedRecord
                            ? dayjs(selectedRecord.start_date)
                            : null
                        }
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label="Notice Period End Date">
                      <DatePicker
                        style={{ width: "100%" }}
                        value={
                          selectedRecord ? dayjs(selectedRecord.end_date) : null
                        }
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label="reason">
                      <Input
                        value={selectedRecord?.reason}
                        placeholder="Enter a  reason..."
                        disabled
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Description">
                      <Input.TextArea
                        rows={3}
                        value={selectedRecord?.description}
                        disabled
                        placeholder="Enter a description..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
          <Modal
            className="global-modal-main-container"
            title={<span className="global-modal-title">Filter</span>}
            open={openFilterModal}
            footer={false}
            onCancel={toggleFilterModal}
          >
            <ResignationFilterModal
              getFilterDataHandler={(Status) => getFilterDataHandler(Status)}
            />
          </Modal>
          <Modal
            title={
              <span className="global-modal-title">Apply Resignation</span>
            }
            open={createModalVisible}
            onCancel={handleCreateModalCancel}
            width={1000}
            footer={false}
            destroyOnClose
            className="global-modal-main-container"
          >
            <CreateEmployeeResignation />
          </Modal>
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};

export default ResignationListView;
