import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//-------------------------------------Create Company------------------------------------------
export const handleDataSubmission = async (payload: any, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("company")
      .insert(payload)
      .select("id");

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleDefaultTemplateDataSetup = async (
  organizationId: number,
  companyId: number
) => {
  try {
    let query = supabase.rpc("default_template_data", {
      org_id: organizationId,
      company_id: companyId,
    });
    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

interface insertIntoAccessRightsType {
  company_id: string;
  access_rights: any[];
  group: string;
  organization_Id: string;
}

export const handleCompanyLogoUploading = async (
  fileName: any,
  file: any
): Promise<any> => {
  try {
    const response = await supabase.storage
      .from("companyLogos")
      .upload(fileName, file);

    if (response.data) {
      return response;
    }
  } catch (error) {
    return catch_return;
  }
};

export const getPublicURLForLogo = async (fileName: any) => {
  try {
    const response = await supabase.storage
      .from("companyLogos")
      .getPublicUrl(fileName);

    if (response.data) {
      return response;
    }
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    return catch_return;
  }
};
//-------------------------------View Companies------------------------------------------------
export const fetchCompaniesApi = async (Ids: any, org_Id: string) => {
  try {

    let query = supabase_client_for_Org_schema(org_Id)
      .from("company")
      .select("*")
      .eq("organization_Id", Ids.organizationId);
    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const deleteCompany = async (id: string, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("company")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//-----------------------------------------Edit Company Details--------------------------------

export const handleDataUpdation = async (
  body: any,
  id: any,
  org_Id: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("company")
      .update(body)
      .eq("id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};
