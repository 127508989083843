import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//-----------------------------Bank Details-------------------------------------
export const fetchEmployeeDetailsApi = async (
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase
      .from("Employees")
      .select("organizationId, branch_id, company_id")
      .eq("id", employeeId)
      .single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setEmployeeBankDetailsApi = async (
  body: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .upsert(body, { onConflict: "employee_id" });

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getExistingEmployeeBankRecords = async (
  employeeId: number,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .select("*")
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const handleDeleteEmployeeBankRecords = async (
  updatedData: any,
  employeeId: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .update({ bank_information: updatedData })
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

//-----------------------------Contact Information-------------------------------------
export const fetchEmployeeRecordsApi = async (
  employeeId: number,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        `* ,branch!employee_branch_id_fkey(name),company(name),
        job_title(name)
        `
      )
      .eq("id", employeeId)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEmployeeAddressesRecordsApi = async (
  organizationId: string | null | undefined,
  employeeId: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .select(`*`)
      .eq("employee_id", employeeId)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEmployeeEmergencyRecordsApi = async (
  employeeId: number,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_emergency_contact_information")
      .select("*")
      .eq("employee_id", employeeId)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const hanldeSendEmailApi = async (
  updateBody: any,
  employeeId: number
): Promise<any> => {
  try {
    let query = supabase
      .from("employee")
      .update(updateBody)
      .eq("id", employeeId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

//-----------------------------Personal Information-----------------------------
export const fetchEmployeesApi = async (
  organizationId: number | string,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        `* ,branch!employee_branch
        _id_fkey(name),company(name),
        job_title(name),
        team!employee_team_id_fkey(name),
        department!employee_department_id_fkey(name),
        designation!employee_designation_id_fkey(name),
        access_right!employee_sub_access_rights_group_id_fkey(sub_group_name)
        `
      )
      .eq("organization_id", organizationId)
      .eq("id", employeeId)
      .single();
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAccessRightGroupNameApi = async (
  organizationId: number | string,
  companyId: string | number,
  branchId: string | number
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .select("id, group,company_id,branch_id")
      .eq("organization_Id", organizationId)
      .is("sub_group_name", null);

    if (companyId) {
      query.eq("company_id", companyId);
    }
    if (branchId) {
      query.eq("branch_id", branchId);
    } else {
      query.is("branch_id", null);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAccessRightSubGroupNameApi = async (
  organizationId: number | string,
  companyId: number,
  parentGroupid: number | string,
  branchId: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("access_right")
      .select("id,sub_group_name")
      .eq("organization_Id", organizationId);

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (parentGroupid) {
      query = query.eq("parent_group_id", parentGroupid);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateEmployeePersonlDetailsApi = async (
  body: any,
  Id: any,
  organization_id: any
): Promise<any> => {
  try {
    const employeeupdateres = await supabase_client_for_Org_schema(
      organization_id
    )
      .from("employee")
      .update(body)
      .eq("id", Id);
    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

export const uploadEmployeePictureApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("employeeProfile")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from("employeeProfile")
        .getPublicUrl(filePath);
      return publicURL;
    }
  } catch {
    return catch_return;
  }
};

//-----------------------------Qualificaiotn And Other-------------------------------------
export const fetchEmployeeSkillsData = async (
  organizationId: string | null | undefined,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .select("id,skill_details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeEducationData = async (
  organizationId: string | null | undefined,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .select("id,qualification_details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeBankDetailData = async (
  organizationId: string | null | undefined,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_bank")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeWorkExperienceData = async (
  organizationId: string | null | undefined,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeLanguageData = async (
  organizationId: string | null | undefined,
  employeeId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_language")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
//-----------------------------Responsibility And Report-------------------------------------
export const fetchEmployeeData = async (
  employeeId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("LoginUser")
      .select("*")
      .eq("id", employeeId)
      .single();

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetchBranchesData = async (
  companyId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id ", companyId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllDepartmentData = async (
  companyId: string,
  selectedBranchId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("department")
      .select("id, name")
      .eq("company_id", companyId);
    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllTeamsData = async (
  companyId: string,
  selectedBranchId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("team")
      .select("id, name")
      .eq("company_id", companyId);

    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllEmployeeListData = async (
  companyId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id,full_name")
      .eq("company_id", companyId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllDesignationData = async (
  companyId: string,
  selectedBranchId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("designation")
      .select("id, name")
      .eq("company_id", companyId);

    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchAllJobTitlesData = async (
  companyId: string,
  selectedBranchId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("job_title")
      .select("id, name")
      .eq("company_id", companyId);

    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const handleUpdateData = async (
  body: any,
  employeeId: string,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .update(body)
      .eq("id", employeeId)
      .select("user_id");

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

//-----------------------------Salary Details-------------------------------------
export const fetchSalayByEmployeeIdApi = async (
  employeeId: string | number,
  organizationId: string | number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_salary")
      .select("*,grade(name)")
      .eq("employee_id", employeeId)
      .eq("organization_id", organizationId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchSalaryGradeApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("grade")
      .select("*")
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_Id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_Id", data.branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

//-----------------------------My Documents-------------------------------------
export const uploadEMployeeDocumentsApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("EmployeeMyDocuments")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL = await supabase.storage
        .from("EmployeeMyDocuments")
        .getPublicUrl(filePath);
      return publicURL;
    } else {
      return res;
    }
  } catch (error) {
    return catch_return;
  }
};

export const insertEmployeeDocumentsDataApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_documents")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEmployeeDocumentsDataApi = async (
  organization_id: any,
  companyId: any,
  branchId: any,
  employee_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee_documents")
      .select("*")
      .eq("employee_id", employee_id);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSalayHistoryByEmployeeIdApi = async (
  employeeId: string | number,
  organizationId: string | number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_salary_history")
      .select("*")
      .eq("employee_id", employeeId)
      .eq("organization_id", organizationId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const deleteEmployeeDocumentFromTable = async (
  organizationId: any,
  recordId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_documents")
      .delete()
      .eq("id", recordId);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const handleDocumentDeletion = async (record: any): Promise<any> => {
  try {
    if (record.attachment) {
      const attachmentPath = record.attachment.split(
        "/EmployeeMyDocuments/"
      )[1];

      const refinedAttachmentPath = decodeURI(attachmentPath);

      if (!attachmentPath) {
        return;
      }

      const response = await supabase.storage
        .from("EmployeeMyDocuments")
        .remove([refinedAttachmentPath]);

      if (response.error) {
        return false;
      }

      return response;
    }
  } catch (error) {
    return error;
  }
};

export const fetchEmployeeDetailDataApi = async (
  employeeId: number,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("employee")
      .select("*")
      .eq("id", employeeId)
      .single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
