import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, Row, Col } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { setEmployeeLanguageModalDataApi } from "../services";
import CustomTable from "../../../CustomTable";
import NoDataFoundIcon from "../../../NoDataFoundIcon";

interface LanguageModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (values: any) => void;
  data: any;
}

const LanguageModal: React.FC<LanguageModalProps> = ({
  visible,
  onClose,
  onFinish,
  data,
}) => {
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [tableData, setTableData] = useState<any>();

  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  useEffect(() => {
    const incomingData = data.flatMap((item: any) => {
      return item.language_details;
    });
    setTableData(incomingData);
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const getAllFormValues = () => {
    const values = form.getFieldsValue();
    const valuesToBeAdded = {
      language: values.language,
      fluency: values.fluency,
      id: Date.now(),
    };
    setTableData([...tableData, valuesToBeAdded]);
  };

  const handleSubmit = async (values: any) => {
    try {
      setButtonLoading(true);
      const formData = {
        employee_id: loggedInEmployee?.employeeId || null,
        organization_id: loggedInEmployee?.employee?.organization_id || null,
        company_id: loggedInEmployee?.employee?.company_id || null,
        branch_id: loggedInEmployee?.employee?.branch_id || null,
        language_details: tableData,
      };
      const { data, error } = await setEmployeeLanguageModalDataApi(
        formData,
        loggedInEmployee?.employee.organization_id
      );

      if (data) {
        setshowAlert(true);

        setAlertType("success");
        setButtonLoading(false);
      }
      if (error) {
        setButtonLoading(false);
        setshowAlert(true);

        setAlertType("error");
      }
      setButtonLoading(false);

      onFinish(values);
      form.resetFields();
      onClose();
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const columns = [
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "Fluency",
      dataIndex: "fluency",
      key: "fluency",
    },
  ];

  return (
    <Modal
      open={visible}
      width={1000}
      onCancel={onClose}
      footer={false}
      className="global-modal-main-container"
      title={<span className="global-modal-title">Add Language </span>}
    >
      <div className="global-card-inner-container">
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row>
            <Col className="modal-content">
              <Form.Item
                label="Language"
                name="language"
                rules={[{ required: true, message: "Please enter language" }]}
              >
                <Input size="large" placeholder="Enter Language ..." />
              </Form.Item>
              <Form.Item
                label="Fluency"
                name="fluency"
                rules={[{ required: true, message: "Please select fluency" }]}
              >
                <Select
                  placeholder="Select Proficiency"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  <Select.Option value="Elementary">
                    Elementary Proficiency
                  </Select.Option>
                  <Select.Option value="Limited Working">
                    Limited Working Proficiency
                  </Select.Option>
                  <Select.Option value="Professional Working">
                    Professional Working Proficiency
                  </Select.Option>
                  <Select.Option value="Native">
                    Full Professional Proficiency
                  </Select.Option>
                  <Select.Option value="Bilingual">
                    Native or Bilingual Proficiency
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width languageModal-btn-position"
                  onClick={getAllFormValues}
                  type="primary"
                >
                  Add Data
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <CustomTable dataSource={tableData} columns={columns} />
        <Form.Item>
          <Button
            key="submit"
            className="global-btn-width languageModal-btn-position"
            htmlType="submit"
            loading={buttonLoading}
            onClick={handleSubmit}
            type="primary"
          >
            Submit
          </Button>
        </Form.Item>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </Modal>
  );
};

export default LanguageModal;
