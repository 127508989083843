import {
  Modal,
  Typography,
  Row,
  Col,
  Input,
  Radio,
  Select,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";

import "./index.css";
import TextArea from "antd/es/input/TextArea";
import { getAllJobDetails } from "../../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface jobpreview {
  id: any;
  onCancel: () => void;
  visible: boolean;
}

const JobPreview: React.FC<jobpreview> = ({ id, onCancel, visible }) => {
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const [jobDetails, setJobDetails] = useState<any>(null);
  const getJobDetails = async () => {
    const response = await getAllJobDetails(id, organizationId);
    if (response.data) {
      setJobDetails(response.data);
    }
  };

  useEffect(() => {
    getJobDetails();
  }, [id]);

  const renderExpectedHours = () => {
    const { fixedHours, minOnlyHours, maxOnlyHours, maxHours, minHours } =
      jobDetails?.basic_details || {};

    if (jobDetails?.basic_details?.expectedHoursType === "Range") {
      return (
        <>
          <Col span={12}>
            <Typography.Text strong>Minimum Hours:</Typography.Text>{" "}
            {minHours || "N/A"}
          </Col>
          <Col span={12}>
            <Typography.Text strong>Maximum Hours:</Typography.Text>{" "}
            {maxHours || "N/A"}
          </Col>
        </>
      );
    } else if (jobDetails?.basic_details?.expectedHoursType === "Fixed hours") {
      return (
        <Col span={24}>
          <Typography.Text strong>Fixed Hours:</Typography.Text>{" "}
          {fixedHours || "N/A"}
        </Col>
      );
    } else if (jobDetails?.basic_details?.expectedHoursType === "Maximum") {
      return (
        <Col span={24}>
          <Typography.Text strong>Maximum Hours:</Typography.Text>{" "}
          {maxOnlyHours || "N/A"}
        </Col>
      );
    } else if (jobDetails?.basic_details?.expectedHoursType === "Minimum") {
      return (
        <Col span={24}>
          <Typography.Text strong>Minimum Hours:</Typography.Text>{" "}
          {minOnlyHours || "N/A"}
        </Col>
      );
    } else {
      return null;
    }
  };
  const renderQuestions = () => {
    return jobDetails?.questions?.map((question: any) => (
      <Col span={24} key={question.id}>
        <Typography.Text strong>
          {question.label === "Experience"
            ? "What is your experience?"
            : question.label === "Able to relocate"
            ? "Are you able to relocate?"
            : question.label === "Language"
            ? "What languages do you speak?"
            : question.label === "Skills"
            ? "What are your skills?"
            : question.label === "Qualifications"
            ? "What are your qualifications?"
            : ""}
        </Typography.Text>{" "}
        {question.type === "number" ? (
          <InputNumber
            size="large"
            className="global-input-number-width"
            placeholder="Enter a value..."
            required={question.required}
            defaultValue={question.answer[0] || ""}
          />
        ) : question.type === "input" ? (
          <Input
            placeholder="Enter a value..."
            size="large"
            required={question.required}
            defaultValue={question.answer[0] || ""}
          />
        ) : question.type === "select" ? (
          <Select
            showSearch
            defaultValue={question.answer[0] || undefined}
            notFoundContent={<NoDataFoundIcon />}
          >
            {question.options?.map((option: any) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        ) : question.type === "radio" ? (
          <Radio.Group defaultValue={question.answer[0] || undefined}>
            {question.options?.map((option: any) => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        ) : question.type === "textarea" ? (
          <TextArea
            required={question.required}
            defaultValue={question.answer[0] || ""}
          />
        ) : (
          ""
        )}
      </Col>
    ));
  };

  const renderExpectedPay = () => {
    const { minPay, maxPay, exactAmount, maximumPay, minimumPay } =
      jobDetails?.basic_details || {};

    if (jobDetails?.basic_details?.payType === "Range") {
      return (
        <>
          <Col span={12}>
            <Typography.Text strong>Minimum Pay:</Typography.Text>{" "}
            {minPay || "N/A"}
          </Col>
          <Col span={12}>
            <Typography.Text strong>Maximum Pay:</Typography.Text>{" "}
            {maxPay || "N/A"}
          </Col>
        </>
      );
    } else if (jobDetails?.basic_details?.payType === "Exact amount") {
      return (
        <Col span={24}>
          <Typography.Text strong>Exact Amount:</Typography.Text>{" "}
          {exactAmount || "N/A"}
        </Col>
      );
    } else if (jobDetails?.basic_details?.payType === "Maximum") {
      return (
        <Col span={24}>
          <Typography.Text strong>Maximum Pay:</Typography.Text>{" "}
          {maximumPay || "N/A"}
        </Col>
      );
    } else if (jobDetails?.basic_details?.payType === "Starting") {
      return (
        <Col span={24}>
          <Typography.Text strong>Minimum Pay:</Typography.Text>{" "}
          {minimumPay || "N/A"}
        </Col>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="job-preview-main-container">
      <Modal
        open={visible}
        onCancel={onCancel}
        footer={false}
        width={1000}
        className="job-preview-modal"
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
      >
        <div className="job-preview-header">
          <Typography.Title level={3}>
            {jobDetails?.basic_details?.jobTitle || "Job Title"}
          </Typography.Title>
        </div>

        <div className="job-preview-body">
          <Row gutter={[16, 16]} className="job-details-row">
            <Col span={12} className="job-title-col">
              <Typography.Text strong>Job Title:</Typography.Text>{" "}
              {jobDetails?.basic_details?.jobTitle}
            </Col>
            <Col span={12} className="job-type-col">
              <Typography.Text strong>Job Type:</Typography.Text>{" "}
              {jobDetails?.basic_details?.jobType?.join(", ")}
            </Col>
            <Col span={12} className="job-location-col">
              <Typography.Text strong>Job Location:</Typography.Text>{" "}
              {jobDetails?.basic_details?.jobLocation}
            </Col>
            <Col span={12} className="hiring-timeline-col">
              <Typography.Text strong>Hiring Timeline:</Typography.Text>{" "}
              {jobDetails?.basic_details?.hiringTimeline}
            </Col>
            <Col span={12} className="expected-hours-type-col">
              <Typography.Text strong>Expected Hours Type:</Typography.Text>{" "}
              {jobDetails?.basic_details?.expectedHoursType}
            </Col>
            <Col span={12} className="expected-hours-col">
              {renderExpectedHours()}
            </Col>
            <Col span={12} className="people-to-hire-col">
              <Typography.Text strong>
                Number of People to Hire:
              </Typography.Text>{" "}
              {jobDetails?.basic_details?.numberOfPeopleToHire}
            </Col>
            <Col span={12} className="pay-type-col">
              <Typography.Text strong>Pay Type:</Typography.Text>{" "}
              {jobDetails?.basic_details?.payType}
            </Col>
            <Col span={24} className="expected-pay-col">
              {renderExpectedPay()}
            </Col>
            <Col span={24} className="application-deadline-col">
              <Typography.Text strong>Application Deadline:</Typography.Text>{" "}
              {jobDetails?.basic_details?.applicationDeadline === "no"
                ? "No deadline"
                : jobDetails?.basic_details?.applicationDeadline}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="job-description-row">
            <Col span={24} className="job-description-col">
              <Typography.Title level={3}>Job Description</Typography.Title>
              {jobDetails?.basic_details?.jobDescription}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="job-questions-row">
            <Col span={24} className="job-questions-col">
              <Typography.Title level={4}>Questions:</Typography.Title>
              {renderQuestions()}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default JobPreview;
