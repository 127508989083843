import React from "react";
import { Modal, Row, Col, Form } from "antd";
import "../index.css";
import TextArea from "antd/es/input/TextArea";

interface Props {
  visible: boolean;
  onRequestClose: () => void;
  selectedComplaintResponse: any;
}

const ComplaintViewModal: React.FC<Props> = ({
  visible,
  onRequestClose,
  selectedComplaintResponse,
}) => {
  return (
    <Modal
      className="global-modal-main-container"
      width={1000}
      open={visible}
      onCancel={onRequestClose}
      footer={null}
      title={<span className="global-modal-title">Complaint Details</span>}
    >
      <Form layout="vertical" className="global-card-inner-container">
        <Row>
          <Col span={10} className="modal-content-container">
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-headings">Name:</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-text">
                  {selectedComplaintResponse?.name}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={14} className="modal-content-container">
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-headings">Email:</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-text">
                  {selectedComplaintResponse?.email}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="modal-content-container">
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-headings">
                  Complaint Type:
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-text">
                  {selectedComplaintResponse?.type}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="modal-content-container">
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-headings">
                  Complaint Message:
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span className="complaint-modal-data-text">
                  {selectedComplaintResponse?.complaint_message}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item label="Your Complain Response">
              <TextArea
                value={selectedComplaintResponse?.response}
                placeholder="You haven't got any response yet."
                rows={3}
                disabled={true}
              >
                {selectedComplaintResponse?.response}
              </TextArea>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ComplaintViewModal;
