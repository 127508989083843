import {
  Col,
  Row,
  Form,
  Button,
  Input,
  Select,
  Modal,
  DatePicker,
  AutoComplete,
} from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import CustomAlert from "../../../../common/Alert";
import dayjs from "dayjs";
import { fetchAccountDataAPI, updateAccountAPI } from "../../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { currencies } from "../../../../common/Currencies";
import { banks } from "../../../../common/Constants";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface UpdateCompanyAccountDetailsPorps {
  id: number | null;
  isEditmodalVisisble: boolean;
  onCancel: () => void;
}

const UpdateCompanyAccountDetails: React.FC<
  UpdateCompanyAccountDetailsPorps
> = (props) => {
  const [form] = useForm();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("error");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [dateComing, setDateComing] = useState<any>();
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );
  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const fetchData = async () => {
    try {
      if (props.id) {
        const response = await fetchAccountDataAPI(organizationId, props.id);
        if (response.data) {
          form.setFieldsValue({
            accountName: response.data?.account_name,
            currency: response.data?.currency,
            bankName: response.data?.bank_name,
            accountTitle: response.data?.account_title,
            accountNumber: response.data?.account_number,
            branchName: response.data?.branch_name,
            branchCode: response.data?.branch_code,
          });
          const parsedDate = dayjs(
            response.data?.date_effective,
            "DD-MM-YYYY",
            true
          );
          form.setFieldsValue({ dateEffective: parsedDate });

          if (parsedDate.isValid()) {
            setDateComing(parsedDate);
          }
        }
      }
    } catch (error) {
      return error;
    }
  };

  const updateDataToDatabase = async () => {
    try {
      if (props.id) {
        form.validateFields();
        setBtnLoading(true);
        const data = {
          account_name: form.getFieldValue("accountName"),
          bank_name: form.getFieldValue("bankName"),
          branch_name: form.getFieldValue("branchName"),
          branch_code: form.getFieldValue("branchCode"),
          account_number: form.getFieldValue("accountNumber"),
          account_title: form.getFieldValue("accountTitle"),
          currency: form.getFieldValue("currency"),
          organization_id: organizationId,
          company_id: companyId,
          branch_id: branchId,
          date_effective: dayjs(form.getFieldValue("dateEffective")).format(
            "DD-MM-YYYY"
          ),
        };
        const response = await updateAccountAPI(organizationId, data, props.id);
        if (response.status == 201 || response.status == 204) {
          setBtnLoading(false);
          setShowAlert(true);
          setAlertType("success");
          props.onCancel();
        } else {
          setBtnLoading(false);
          setShowAlert(true);
          setAlertType("error");
        }
      }
    } catch (error) {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };
  return (
    <>
      <Modal
        open={props.isEditmodalVisisble}
        onCancel={props.onCancel}
        width={1000}
        footer={null}
        title={<span className="global-modal-title">Edit Account Details</span>}
        className="global-modal-main-container"
        destroyOnClose
      >
        <div className="global-card-inner-container">
          <Form form={form} layout="vertical" onFinish={updateDataToDatabase}>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name={"accountName"}
                  label="Account Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Account Name",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Account Name" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name={"currency"}
                  label="Currency"
                  rules={[
                    {
                      required: true,
                      message: "Please choose a currency...",
                    },
                  ]}
                >
                  <Select showSearch allowClear placeholder="Enter currency"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                  >
                    {currencies.map((item: any) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <span className="create-axxount-modal-subheading">
              Add Bank Account
            </span>
            <br />
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Enter/Select Bank Name"
                  name="bankName"
                  rules={[
                    { required: true, message: "Please Enter Bank Name" },
                  ]}
                >
                  <AutoComplete
                    options={options}
                    onSearch={handleSearch}
                    placeholder="Select bank..."
                    allowClear
                    filterOption={(inputValue, option) =>
                      option?.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Account Title"
                  name="accountTitle"
                  rules={[
                    { required: true, message: "Please Enter Account Title" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Account Title" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  rules={[
                    { required: true, message: "Please Enter Account Number" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Account Number" />
                </Form.Item>
              </Col>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Branch Name"
                  name="branchName"
                  rules={[
                    { required: true, message: "Please Enter Branch Name" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Branch Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label="Bank Code"
                  name="branchCode"
                  rules={[
                    { required: true, message: "Please Enter Branch Code" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Branch Code" />
                </Form.Item>
              </Col>

              <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Effective up to" name="dateEffective">
                  <DatePicker
                    style={{ width: "98%", height: "2.5rem" }}
                    format="DD-MM-YYYY"
                    value={dateComing}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="global-modal-bottom-row add-account-btn">
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                htmlType="submit"
                loading={btnLoading}
              >
                Update
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default UpdateCompanyAccountDetails;
