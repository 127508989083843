import React, { useEffect, useState } from "react";
import "../index.css";
import { Card, Empty, Modal } from "antd";
import EmployeeLeaveRequest from "../../EmployeePortal/EmployeeLeaveRequest/Layout";
import AllowanceClaimRequestListView from "../../Payroll/AllowanceClaimRequest/List";
import OverTimeApproveHistoryView from "../../Payroll/OverTimeApprove/History";
import ResignationListView from "../EmployeeResignation/ListView";
import LeaveRequestForm from "../../EmployeePortal/EmployeeLeaveRequest/CreateLeaveRequest";
import AllowanceClaimRequestCreateView from "../../Payroll/AllowanceClaimRequest/Create";
import OverTimeApproveCreate from "../../Payroll/OverTimeApprove/Create";
import CreateEmployeeResignation from "../EmployeeResignation/CreateEmployeeResignation";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../redux/AccessRight";
import { useSelector } from "react-redux";
import TabsHeader from "../../../common/TabsHeader";
import AdvanceRequestHistory from "../../Payroll/AdvanceRequest/History";
import CreateAdvanceRequest from "../../Payroll/AdvanceRequest/Create";

const RequestComp: React.FC = () => {
  const [selected, setSelected] = useState("Leave Requests");
  const accessObj = useSelector(selectAccess);
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);

  const radioOptions = [
    {
      label: "Leave Requests",
      value: "Leave Requests",
      create: true,
      filter: false,
      showSearch: false,
    },
    {
      label: "Allowance Claim",
      value: "Allowance Claim",
      create: true,
      filter: false,
      showSearch: false,
    },
    {
      label: "Overtime Claim",
      value: "Overtime Claim",
      create: true,
      filter: false,
      showSearch: false,
    },
    {
      label: "Advance",
      value: "Advance",
      create: true,
      filter: true,
      showSearch: false,
    },
    {
      label: "Resignation",
      value: "Resignation",
      create: true,
      filter: false,
      showSearch: false,
    },
  ];

  useEffect(() => {
    handleRadioChange(selected);
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    let childValue =
      value === "Leave Requests"
        ? "Leave Request"
        : value === "Allowance Claim"
        ? "Allowance Claim"
        : value === "Overtime Claim"
        ? "Overtime Claim"
        : value === "Advance"
        ? "Advance Request"
        : "Resignation";
    // special case
    let parentMenu = "";
    if (childValue == "Resignation & Termination") {
      parentMenu = "Resignation & Termination";
      childValue = "";
    } else {
      parentMenu = "Requests";
    }
    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };

  const handleSearchChange = (input: string) => {
    setSearchValue(input);
  };

  return (
    <>
      <div className="requests-module-card-container">
        <Card className="requests-module-card-inner-container">
          <TabsHeader
            radioOptions={radioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("")
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handleSearchChange}
            createAccess={createAccess}
            showFullwidthHeader
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Leave Requests" ? (
                <EmployeeLeaveRequest />
              ) : selected === "Allowance Claim" ? (
                <AllowanceClaimRequestListView />
              ) : selected === "Overtime Claim" ? (
                <OverTimeApproveHistoryView />
              ) : selected === "Advance" ? (
                <AdvanceRequestHistory />
              ) : (
                <ResignationListView
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              )
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>

          <Modal
            title={
              <span className="global-modal-title">
                {selected == "Leave Requests" ? selected : "Request " + selected}{" "}
              </span>
            }
            open={createModalVisible}
            onCancel={handleCreateModalCancel}
            width={1000}
            footer={false}
            className="global-modal-main-container"
          >
            {createModalVisible && selected === "Leave Requests" && (
              <LeaveRequestForm />
            )}
            {createModalVisible && selected === "Allowance Claim" && (
              <AllowanceClaimRequestCreateView />
            )}
            {createModalVisible && selected === "Overtime Claim" && (
              <OverTimeApproveCreate />
            )}
            {createModalVisible && selected === "Resignation" && (
              <CreateEmployeeResignation />
            )}
            {createModalVisible && selected === "Advance" && (
              <CreateAdvanceRequest
                createModalCloseHanlder={handleCreateModalCancel}
              />
            )}
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default RequestComp;
