import React, { useState, useEffect } from "react";
import { Button, Col, Descriptions, Divider, Row, message } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { getTheGeneralPolicyForEmployeeView } from "../services";
import { DownloadOutlined } from "@ant-design/icons";
import { TriangleLoader } from "../../../../common/ReactLoader";

const GeneralPolicyEmployeeView = () => {
  const [policies, setPolicies] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const role = useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee.company_id;
  const branchId = loggedInEmployee?.employee.branch_id;

  useEffect(() => {
    getGeneralPolicy();
  }, []);

  const getGeneralPolicy = async () => {
    const response = await getTheGeneralPolicyForEmployeeView(
      organizationId,
      companyId,
      branchId
    );

    if (response.data) {
      setPolicies(response.data);
    }
    setLoading(false);
  };

  const handleDownload = async (record: any) => {
    if (record.attachment) {
      try {
        const response = await fetch(record.attachment);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${record.version_name}_document_${record.effective_up_to}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        message.error("Error during Download");
      }
    }
  };

  return (
    <>
      <div className="job-leave-table-container">
        <div className="job-leave-table-content">
          <Row className="title-row">
            <Col span={24}>
              <span className="leaves-policy-title">
                General Policy
                <Divider />
              </span>
            </Col>
          </Row>

          {policies.length > 0 ? (
            <Row gutter={16}>
              {policies?.map((policy, index) => (
                <>
                  {policy.attachment && (
                    <Col className="gernrall_policy_content_right">
                      {" "}
                      <a onClick={() => handleDownload(policy)}>
                        <Button icon={<DownloadOutlined />} className="global-btn-width">
                          Other Attachment
                        </Button>
                      </a>{" "}
                    </Col>
                  )}
                  {policy.description && (
                    <Col key={index} span={24}>
                      <Descriptions>
                        <Descriptions.Item
                          label={
                            <strong className="employee-view-general-policy-bold">
                              Description
                            </strong>
                          }
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: policy.description,
                            }}
                          />
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                </>
              ))}
            </Row>
          ) : (
            <NoDataFoundIcon />
          )}
        </div>
      </div>
      {loading && <TriangleLoader />}
    </>
  );
};

export default GeneralPolicyEmployeeView;
