import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";

// Define the structure of the user-related state
interface PersonalDetails {
  firstname?: string | null;
  surname?: string | null;
  name?: string | null;
  nationality?: string | null;
  dob?: string | Dayjs | null;
  cnic?: string | null;
  gender?: string | null;
  maritalstatus?: string | null;
  accessrightgroup?: string | null;
  selectedCompanyId?: string | null;
  selectedBranchId?: string | null;
  selectedDepartmentId?: string | null;
  selectedTeamId?: string | null;
  accessparentGroupId?: string | null;
  accesssubGroupId?: string | null;
}

interface ActiveMenubutton {
  key: string;
  status: "Active" | "InActive";
}

interface ContactDetails {
  street?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  fulladdress?: string | null;
  mobile?: string | null;
  workmobilenumber?: string | null;
  workemail?: string | null;
  otheremail?: string | null;
}
interface BankDetails {
  bankName?: string | null;
  branchCode?: string | null;
  branchName?: string | null;
  accountTitle?: string | null;
  accountNumber?: string | null;
  dateEffective?: string | Dayjs | null;
  employeeName?: string | null;
  comments?: string | null;
  EffectiveUpTo?: string | null;
  country?: string | null;
  iban?: string | null
}

interface EmergencyContact {
  name?: string | null;
  relation?: string | null;
  worktelephone?: string | null;
  email?: string | null;
  mobile?: string | null;
  address? : string | null
}
interface WorkExperience {
  jobTitle?: string | null;
  company?: string | null;
  from?: string | Dayjs | null;
  to?: string | Dayjs | null;
  id?: string | null;
}
interface Education {
  specialization?: string | null;
  institute?: string | null;
  level?: string | null;
  from?: string | Dayjs | null;
  to?: string | Dayjs | null;
  id?: string | null;
}
interface skill {
  skill?: string | null;
  comments?: string | null;
  yearsOfExperience?: string | null;
  id?: string | null;
}
interface Language {
  language?: string | null;
  fluency?: string | null;
  id?: string | null;
}
interface Attachment {
  fileName?: string | null;
  data?: string | null | any;
  id?: string | null;
}

interface ResponsibilityReportto {
  roleToReport?: string | null;
  reportToEmployee?: string | null;
  employeeDesignation?: string | null;
  employeeResponsibility?: string | null;
  selectedCompany?: string | null;
  selectedBranch?: string | null;
  selectedJobTitle?: string | null;
  selectedDepartment?: string | null;
  selectedTeam?: string | null;
  // joiningDate?: string | Dayjs | null;
  // workType?: string | null;
}

interface EmployeeFormData {
  Employee: {
    PersonalDetails?: PersonalDetails | null;
    ContactDetails?: ContactDetails | null;
    EmergencyContact?: EmergencyContact | null;
    WorkExperience?: WorkExperience[] | null;
    Education?: Education[] | null;
    skill?: skill[] | null;
    Language?: Language[] | null;
    Attachment?: Attachment[] | null;
    ResponsibilityReportto?: ResponsibilityReportto | null;
    BankDetails?: BankDetails | null;
    Activebutton?: ActiveMenubutton[];
    Avatarurl?: string;
    EmployeeId?: string | null;
    emailSent?: boolean;
  };
}

interface RootState {
  Employee: EmployeeFormData;
}

const initialState: EmployeeFormData = {
  Employee: {
    PersonalDetails: null,
    ContactDetails: null,
    ResponsibilityReportto: null,
    EmergencyContact: null,
    WorkExperience: [],
    Education: null,
    skill: null,
    Language: null,
    Attachment: null,
    BankDetails: null,
    Activebutton: [{ key: "Personl_Details", status: "Active" }],
    EmployeeId: null,
    emailSent: false,
  },
};

export const EmployeeSlice: any = createSlice({
  name: "Employee",
  initialState,
  reducers: {
    setEmployeeProfileDetails: (
      state,
      action: PayloadAction<Partial<EmployeeFormData>>
    ) => {
      state.Employee = { ...state.Employee, ...action.payload.Employee };
    },
    removeEducationDetail: (state, action: PayloadAction<number>) => {
      if (state.Employee.Education) {
        state.Employee.Education = state.Employee.Education.filter(
          (_, index) => index !== action.payload
        );
      }
    },
    removeExperienceDetail: (state, action: PayloadAction<number>) => {
      if (state.Employee.WorkExperience) {
        state.Employee.WorkExperience = state.Employee.WorkExperience?.filter(
          (_, index) => index !== action.payload
        );
      }
    },
    removeSkillDetail: (state, action: PayloadAction<number>) => {
      if (state.Employee.skill) {
        state.Employee.skill = state.Employee.skill?.filter(
          (_, index) => index !== action.payload
        );
      }
    },
    removeLanguageDetail: (state, action: PayloadAction<number>) => {
      if (state.Employee.Language) {
        state.Employee.Language = state.Employee.Language?.filter(
          (_, index) => index !== action.payload
        );
      }
    },
  },
});

export const {
  setEmployeeProfileDetails,
  removeEducationDetail,
  removeExperienceDetail,
  removeSkillDetail,
  removeLanguageDetail,
} = EmployeeSlice.actions;

export const selectEmployee = (state: RootState) => state.Employee;

export default EmployeeSlice.reducer;
