import React, { useState } from "react";
import { Col, Row, Button, Tooltip } from "antd";
import { PlusCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { GrPowerReset } from "react-icons/gr";

interface CommonButtonsProps {
  handleCreateClick?: (record?: any) => void;
  toggleFilterMenu?: () => void;
  handleReset?: () => void;
  filterModalVisible?: boolean;
  showFilterButton?: boolean;
  read?: boolean;
  create?: boolean;
  showCreateButton?: boolean;
}

const TableHeadCommonButtons: React.FC<CommonButtonsProps> = ({
  handleCreateClick,
  toggleFilterMenu,
  handleReset,
  filterModalVisible,
  showFilterButton = true,
  read = true,
  create = true,
  showCreateButton = true,
}) => {
  return (
    <Col
      xxl={16}
      xl={16}
      lg={16}
      md={24}
      sm={24}
      xs={24}
      className="company-col-btns"
    >
      <Row gutter={[8, 40]}>
        <Col>
          {showCreateButton && create && (
            <Button
              type="primary"
              onClick={handleCreateClick}
              icon={<PlusCircleOutlined className="access-right-create-btn" />}
            >
              Create
            </Button>
          )}
        </Col>
        {showFilterButton && (
          <Col>
            {read && (
              <Button
                type="default"
                onClick={toggleFilterMenu}
                icon={<FilterOutlined />}
              >
                Filter
              </Button>
            )}
          </Col>
        )}
        <Col>
          <Tooltip title="Refresh">
            {read && (
              <Button
                type="default"
                onClick={handleReset}
                icon={<GrPowerReset />}
              ></Button>
            )}
          </Tooltip>
        </Col>
      </Row>
    </Col>
  );
};

export default TableHeadCommonButtons;
