import React, { useEffect, useState } from "react";
import { Modal, Popconfirm, Space, TablePaginationConfig } from "antd";
import "./index.css";
import { SortOrder } from "antd/lib/table/interface";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { deleteAbsentPolicyData, fetchAbsentPolicyData } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import AbsentEmployeeView from "../EmployeeView";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface AbsentListProps {
  searchValue?: string;
}

const AbsentListView: React.FC<AbsentListProps> = ({ searchValue }) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [showAbsentViewModal, setAbsentViewModal] = useState<boolean>(false);
  const [absentViewModalId, setAbsentViewModalId] = useState<any>(null);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInemployee = useSelector(selectLogedEmployee).logedInemployee;

  const organizationId = loggedInemployee?.employee?.organization_id;

  const companyId = loggedInemployee?.employee?.company_id;
  const branch_id = loggedInemployee?.employee?.branch_id;

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const accessObj = useSelector(selectAccess);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const access = extractAccessRightObject(
    accessObj,
    "Policy Document",
    "Absent Policy"
  );

  const deletee = access?.deleteAccess;

  const fetchData = async () => {
    setLoading(true);

    const response = await fetchAbsentPolicyData(
      organizationId,
      companyId,
      branch_id
    );
    if (response.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: data?.length,
        },
      });
      setData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setLoading(false);
    }
    if (response.error) {
    }
    setLoading(false);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setData(originalData.apiData);
    }
  }, [searchValue]);

  const handleDelete = async (record: any) => {
    const { data, error } = await deleteAbsentPolicyData(
      record.id,
      organizationId
    );

    if (error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "version_name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setData(filterData);

    setLoading(false);
  };

  const hanldeViewAbsentModalVisible = (value: any) => {
    setAbsentViewModal(true);
    setAbsentViewModalId(value);
  };

  const hanldeViewAbsentModalCancel = () => {
    setAbsentViewModal(false);
    setAbsentViewModalId(null);
  };

  const columns = [
    {
      title: "Policy Name",
      dataIndex: "version_name",
      key: "version_name",
      sorter: (a: any, b: any) => {
        const nameA = a.version_name || "";
        const nameB = b.version_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Absentees per month",
      dataIndex: "allowed_per_month",
      key: "allowed_per_month",
    },
    {
      title: "No. of consecutive absentees",
      dataIndex: "max_consecutive",
      key: "max_consecutive",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link">
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
          <a>
            <EyeOutlined onClick={() => hanldeViewAbsentModalVisible(record)} />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CustomTable
        dataSource={data}
        columns={columns}
        loading={loading}
        onChange={handleTableChange}
      />

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      <Modal
        open={showAbsentViewModal}
        onCancel={hanldeViewAbsentModalCancel}
        width={1000}
        footer={false}
        className="global-modal-main-container"
        destroyOnClose
        title="Absent Policy"
      >
        <div className="global-card-inner-container">
          <AbsentEmployeeView propsData={absentViewModalId} />
        </div>
      </Modal>
    </div>
  );
};

export default AbsentListView;
