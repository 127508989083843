import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import CustomAlert from "../../../../common/Alert";
import { TriangleLoader } from "../../../../common/ReactLoader";
import { saveAdvanceRequestDataApprove } from "../../services";

interface EditAdvanceRequestProps {
  data: {
    id: number;
    request_type: string;
    amount: number;
    description: string;
    comments?: string;
    status: string;
    reviewer_id?: string;
    employee_id: string;
    start_date: string;
    end_date: string;
    organization_id: number;
    company_id: number;
    branch_id: number;
    employee: {
      full_name: string;
    };
  };
  editModalCloseHanlder: () => void;
}

const EditAdvanceRequest: React.FC<EditAdvanceRequestProps> = ({
  data,
  editModalCloseHanlder,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<string>("");
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const employeeId = loggedInEmployee?.employeeId;
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (data.id) {
      form.setFieldsValue({
        requested_by: data?.employee?.full_name,
        request_type: data?.request_type,
        amount: data?.amount,
        description: data?.description,
        comments: data?.comments ? data?.comments : "",
      });
    }
  }, [data.id]);

  const onFinish = async () => {
    try {
      if (data?.id) {
        setLoading(true);
        const body = {
          comments: form.getFieldValue("comments"),
          reviewer_id: employeeId,
          status: requestStatus,
        };
        const response = await saveAdvanceRequestDataApprove(
          organizationId,
          body,
          data.id
        );
        if (response.status == 201 || response.status == 204) {
          setLoading(false);
          setshowAlert(true);
          setAlertType("success");
          form.resetFields();
          editModalCloseHanlder();
        } else {
          setLoading(false);
          setshowAlert(true);
          setAlertType("error");
        }
      }
    } catch (error) {
      setLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name={"requested_by"} label="Requested By">
              <Input size="large" disabled={true} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name={"request_type"}
              label="Select Advance Request Type"
            >
              <Input size="large" disabled={true} />
            </Form.Item>
          </Col>
          {data?.request_type === "withdrawProvidentFund" ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"pfValue"}
                label="Provident Fund Value Available"
                initialValue={"200000"}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {data?.request_type === "advanceSalary" ? (
              <Form.Item
                name={"noOfMonths"}
                label="Select Range"
                initialValue={data?.start_date + " - " + data?.end_date}
              >
                <Input size="large" disabled />
              </Form.Item>
            ) : (
              <Form.Item name={"amount"} label="Enter Amount">
                <Input size="large" disabled placeholder="Enter an amount" />
              </Form.Item>
            )}
          </Col>
          {data?.description ? (
            <Col span={24}>
              <Form.Item name={"description"} label={"Enter Description"}>
                <TextArea disabled placeholder="Enter Description" />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}

          <Col span={24}>
            <Form.Item name={"comments"} label={"Enter Comments"}>
              <TextArea
                disabled={data?.reviewer_id ? true : false}
                placeholder="Enter Comments"
              />
            </Form.Item>
          </Col>
          <Row className="global-modal-bottom-row advance-request-edit-btns">
            {!data?.reviewer_id ? (
              <>
                <Button
                  className="global-btn-width"
                  type="default"
                  htmlType="submit"
                  onClick={() => setRequestStatus("rejected")}
                  size="large"
                >
                  Reject
                </Button>

                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  onClick={() => setRequestStatus("approved")}
                  size="large"
                >
                  Approve
                </Button>
              </>
            ) : (
              ""
            )}
          </Row>
        </Row>
      </Form>
      {showAlert && (
        <CustomAlert
          type={alertType}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
      {loading && <TriangleLoader />}
    </div>
  );
};

export default EditAdvanceRequest;
