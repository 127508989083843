import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Row, Col, DatePicker } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "antd/es/form/Form";
import "./index.css";
import {
  fetchEditModalPolicydoumentData,
  hanldeSaveEditModalPolicydoumentData,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import dayjs from "dayjs";
interface PolicyDocumentEditViewProps {
  policy: any;
  onClose: () => void;
  fetchData: () => void;
}

const PolicyDocumentEditView: React.FC<PolicyDocumentEditViewProps> = ({
  policy,
  onClose,
  fetchData,
}) => {
  const [editedPolicy, setEditedPolicy] = useState({ ...policy });
  const [isSaving, setIsSaving] = useState(false);
  const [form] = useForm();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [effectiveUpTo, setEffectiveUpTo] = useState<string | string[]>();

  useEffect(() => {
    form.setFieldsValue({
      policyName: editedPolicy.version_name,
      policyText: editedPolicy.description,
      effective_up_to: editedPolicy.effective_up_to
        ? dayjs(editedPolicy.effective_up_to)
        : "",
    });
  }, []);

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const { data: policyData, error } =
          await fetchEditModalPolicydoumentData(
            editedPolicy.id,
            organizationId
          );

        if (policyData && policyData.length > 0) {
          setEditedPolicy({ ...editedPolicy, ...policyData[0] });
        }
      } catch (error) {}
    };

    fetchPolicyData();
  }, [editedPolicy.id]);

  const handleSave = async () => {
    setIsSaving(true);
    setButtonLoading(true);
    const values = form.getFieldsValue();

    try {
      const res = await hanldeSaveEditModalPolicydoumentData(
        editedPolicy.id,
        values.policyName,
        values.policyText,
        organizationId,
        effectiveUpTo || values.effective_up_to
      );

      setIsSaving(false);
      onClose();
      fetchData();
      setButtonLoading(false);
    } catch (error) {
      setIsSaving(false);
      setButtonLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      onOk={handleSave}
      onCancel={onClose}
      confirmLoading={isSaving}
      width={1000}
      footer={null}
      title={<span className="global-modal-title">Edit Policy Document</span>}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form}>
          <Row gutter={24}>
            <Col span={24} md={12}>
              <Form.Item label="Title" name={"policyName"}>
                <Input
                size="large"
                  placeholder="Enter policy name..."
                  value={editedPolicy.version_name}
                  onChange={(value) =>
                    setEditedPolicy({ ...editedPolicy, version_name: value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="Effective up to" name="effective_up_to">
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  onChange={(d: any, ds: string | string[]) => {
                    setEffectiveUpTo(ds);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Policy Text" name={"policyText"}>
                <ReactQuill
                  theme="snow"
                  value={editedPolicy.description}
                  onChange={(value: any) =>
                    setEditedPolicy({ ...editedPolicy, description: value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="global-modal-bottom-row">
              <Col span={24} className="policy-document-modal-btn">
                <Button
                  className="global-btn-width"
                  onClick={handleSave}
                  type="primary"
                  size="large"
                  loading={buttonLoading}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PolicyDocumentEditView;
