import React, { useState, useEffect } from "react";
import { Col, Descriptions, Divider, Row } from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchEarlyLeftLeavePolicy } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { TriangleLoader } from "../../../../common/ReactLoader";

const LeavesEmployeeView: React.FC<any> = ({ propsData }) => {
  const [policies, setPolicies] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const role = useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee.company_id;
  const branchId = loggedInEmployee?.employee.branch_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;



  const fetchEarlyLeftPolicies = async () => {
    if (employeeRole !== "employee") {
      const propDataArray = [propsData];

      setPolicies(propDataArray);
    } else {
      let matchCondition =
        role?.toLowerCase() === "employee" ? "employee" : "hr";

      const { data, error } = await fetchEarlyLeftLeavePolicy(
        organizationId,
        matchCondition,
        companyId,
        branchId
      );
      if (error) {
        return error;
      } else {
        setPolicies(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEarlyLeftPolicies();
  }, []);

  const generateItemsArray = (policy: any): any[] => {
    return [
      {
        key: "1",
        span: 3,
        label: <span className="policy-description-title">Leave Type</span>,
        children: (
          <span className="description">
            The type of leave provided by this policy is{" "}
            <strong>{policy?.leaveType}</strong>.
          </span>
        ),
      },
      {
        key: "2",
        span: 3,
        label: (
          <span className="policy-description-title policy-descriptions">
            Maximum Leaves Allowed
          </span>
        ),
        children: (
          <span className="description">
            This policy specifies the maximum number of leaves an employee is
            entitled to take within a given timeframe. As per this policy,
            employees are allowed a maximum of{" "}
            <strong>{policy?.maxAllowed}</strong> leaves.
          </span>
        ),
      },
      {
        key: "3",
        span: 3,
        label: (
          <span className="policy-description-title">
            Consecutive Leaves Allowed
          </span>
        ),
        children: (
          <span className="description">
            The policy addresses whether consecutive leaves are allowed or not.
            According to this policy, consecutive leaves are{" "}
            <strong>{policy?.consecutiveAllowed}</strong>.
          </span>
        ),
      },
      {
        key: "4",
        span: 3,
        label: (
          <span className="policy-description-title">
            Maximum Consecutive Leaves Allowed
          </span>
        ),
        children: (
          <span className="description">
            This policy outlines the maximum number of consecutive leaves
            allowed for an employee. As per this policy, the maximum consecutive
            leaves allowed are <strong>{policy?.maxConsecutiveAllowed}</strong>.
          </span>
        ),
      },
      {
        key: "5",
        span: 3,
        label: (
          <span className="policy-description-title">
            Description
          </span>
        ),
        children: (
          <div
            dangerouslySetInnerHTML={{
              __html: policy?.description ? policy?.description : "N/A",
            }}
          />
        ),
      },
    ];
  };

  return (
    <>
       
        <div className="job-leave-table-content">
          <Row justify="center">
            <Col span={24}>
              {policies.length === 0 ? (
                <div className="no-data-found-policy">
                  <NoDataFoundIcon />
                </div>
              ) : (
                <>
                  <Row>
                    <Col span={24}>
                      <span>
                        Our leaves policy provides guidelines for various types
                        of leave, including annual and maternity leave, ensuring
                        employees can effectively balance their work and
                        personal responsibilities. Key aspects of the policy
                        include:
                        <br />
                     
                      </span>
                    </Col>
                  </Row>
                  {policies[0]?.policy_types?.map(
                    (policy: any, index: number) => (
                      <>
                        {index !== 0 && <Divider key={`divider-${index}`} />}
                        <Descriptions
                          className="leaves-policy-descriptions"
                          key={policy.id}
                          title={
                            <span className="leaves-policy-subtitle policy-descriptions">
                              {policy?.leaveType} Leaves Policy
                            </span>
                          }
                          layout="horizontal"
                          bordered
                          items={generateItemsArray(policy)}
                        />
                      </>
                    )
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
       
      {loading && <TriangleLoader />}
    </>
  );
};

export default LeavesEmployeeView;
