import React, { useEffect, useState, Suspense, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  AllEmployeePortalRoutes,
  AllHrRoutes,
  AllOrganizationRoutes,
} from "./AllRoute";
import { TriangleLoader } from "../../HCM/common/ReactLoader";
import { capitalizeWords } from "../../HCM/common/validation";
import { selectLogedEmployee } from "../../redux/EmployeeProfileSlice";
import {
  selectOrganizationModulePermission,
  setOrganizationModulePermission,
} from "../../redux/organizationModulePermission";
import {
  subscribeToRealTimeAccessRights,
  subscribeToRealTimeEmployeeChanges,
  subscribeToRealTimeOrgnizationPermissionModules,
} from "../services/Global";
import supabase, { supabase_admin } from "../services/supabase";
import { setAccess } from "../../redux/AccessRight";
import PageNotFound from "../common/PageNoteFound";
import RouteRedirect from "../common/Domainredirect";

const Login = React.lazy(() => import("../../HCM/common/Login/index"));
const OrganizationManagementLayout = React.lazy(
  () => import("../../HCM/components/OrganisationManagement/Layout/index")
);
const ResetPassword = React.lazy(
  () => import("../../HCM/common/ResetPassword")
);
const UpdatePassword = React.lazy(
  () => import("../../HCM/common/UpdatePassword")
);

const HrManagementLayout = React.lazy(
  () => import("../../HCM/components/HrManagement/HrLayout/index")
);
const EmployeeLayout = React.lazy(
  () => import("../components/EmployeePortal/Layout")
);

const ApplicationSubmission = React.lazy(
  () => import("../../HCM/components/Recruitment/ApplicationSubmission/index")
);

interface Route {
  pageUrl: string;
  title: string;
}

const RouterIndex: React.FC = () => {
  const loggedInEmployee =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee;
  const organizationPermission = useSelector(
    selectOrganizationModulePermission
  )?.organizationModulePermission;
  const [filteredRoutes, setFilteredRoutes] = useState<any[]>(
    loggedInEmployee?.access_right_group === "admin"
      ? AllOrganizationRoutes
      : loggedInEmployee?.access_right_group === "hr"
      ? AllHrRoutes
      : loggedInEmployee?.access_right_group === "employee"
      ? AllEmployeePortalRoutes
      : []
  );
  const organizaitonId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const accessRightGroupId =
    useSelector(selectLogedEmployee).logedInemployee?.employee
      ?.access_right_group_id;
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employeeId;

  const dispatch = useDispatch();
  
  useEffect(()=>{
   setFilteredRoutes( loggedInEmployee?.access_right_group === "admin"
    ? AllOrganizationRoutes
    : loggedInEmployee?.access_right_group === "hr"
    ? AllHrRoutes
    : loggedInEmployee?.access_right_group === "employee"
    ? AllEmployeePortalRoutes
    : [])
  },[loggedInEmployee?.access_right_grou])

  useEffect(() => {
    const handleRealtimeUpdate = (plansData: any) => {
      dispatch(setOrganizationModulePermission(plansData));
    };
    const handleRealtimeAccessRightUpdate = (access_rights: any) => {
      dispatch(
        setAccess({
          access: {
            group: capitalizeWords(access_rights?.group),
            AccessRights: access_rights?.access_rights,
            organizationId: access_rights?.organization_Id,
            employeeId: access_rights?.id,
          },
        })
      );
    };

    const handleRealtimeEmployeeAccessUpdate = (data: any) => {
      if (
        data.new.parent_access_right_group_id !==
        data.old.parent_access_right_group_id
      ) {
        checkSession();
      }
    };

    if (organizaitonId) {
      const channel = subscribeToRealTimeOrgnizationPermissionModules(
        organizaitonId,
        handleRealtimeUpdate
      );
    }
    if (organizaitonId && accessRightGroupId) {
      subscribeToRealTimeAccessRights(
        organizaitonId,
        accessRightGroupId,
        handleRealtimeAccessRightUpdate
      );
    }

    if (organizaitonId && employeeId) {
      subscribeToRealTimeEmployeeChanges(
        organizaitonId,
        employeeId,
        handleRealtimeEmployeeAccessUpdate
      );
    }

    return () => {
      if (supabase.channel("real-time-Organization-permission-modules")) {
        supabase.removeChannel(
          supabase.channel("real-time-Organization-permission-modules")
        );
      }
      if (supabase.channel("real-time-access-right-changes")) {
        supabase.removeChannel(
          supabase.channel("real-time-access-right-changes")
        );
      }

      if (supabase.channel("real-time-employee-access-changes")) {
        supabase.removeChannel(
          supabase.channel("real-time-employee-access-changes")
        );
      }
    };
  }, [organizaitonId, accessRightGroupId, employeeId]);

  const checkSession = async () => {
    const {
      data: { user },
    } = await supabase_admin.auth.getUser();
    if (user && user.user_metadata.session_valid === false) {
      Cookies.set("userId", "");
      await supabase.auth.signOut();
      window.location.href = "/hrm";
    }
  };

  const excludeKeysOrg = [
    "Dashboard",
    "Profile",
    "System Settings",
    "Complaints",
  ];
  const excludeKeysHr = [
    "Dashboard",
    "Profile",
    "System Settings",
    "Complaints",
  ];
  const excludeKeysEmployee = [
    "Dashboard",
    "Profile",
    "System Settings",
    "Complaints",
    "Task Tracking",
  ];

  const filterRoutes = (
    excludeKeys: string[],
    routes: any[],
    organizationPermission: any
  ) => {
    return routes?.filter((item: any) => {
      const parentPage = item?.parentpage;

      const hasReadAccess = (page: string) =>
        organizationPermission[page]?.readAccess === true ||
        organizationPermission?.Requests?.child[page]?.readAccess === true;

      return excludeKeys.includes(parentPage) || hasReadAccess(parentPage);
    });
  };

  const computedRoutes = useMemo(() => {
    if (organizationPermission) {
      let temp_exculde_key =
        loggedInEmployee?.access_right_group === "admin"
          ? excludeKeysOrg
          : loggedInEmployee?.access_right_group === "hr"
          ? excludeKeysHr
          : excludeKeysEmployee;

      let routes =
        loggedInEmployee?.access_right_group === "admin"
          ? AllOrganizationRoutes
          : loggedInEmployee?.access_right_group === "hr"
          ? AllHrRoutes
          : AllEmployeePortalRoutes;

      return filterRoutes(temp_exculde_key, routes, organizationPermission);
    } else {
      return loggedInEmployee?.access_right_group === "admin"
        ? AllOrganizationRoutes
        : loggedInEmployee?.access_right_group === "hr"
        ? AllHrRoutes
        : AllEmployeePortalRoutes;
    }
  }, [organizationPermission]);

  useEffect(() => {
    setFilteredRoutes(computedRoutes);
  }, [computedRoutes]);

  return (
    <div>
      <Suspense
        fallback={
          <div className="suspense-blur-screen">
            <TriangleLoader />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              Cookies.get("userId") && loggedInEmployee ? (
                <Navigate
                  to={`/${loggedInEmployee?.access_right_group?.toLowerCase()}/dashboard`}
                />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/confirm/:subdomain" element={<RouteRedirect />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/updatepassword" element={<UpdatePassword />} />

          <Route
            path="/applicationsubmission/:orgid/:jobid"
            element={<ApplicationSubmission />}
          />
          {/* Not Found */}
          <Route path="*" element={<PageNotFound />} />

          {/*Route only admin can acess */}
          <Route element={<OrganizationManagementLayout />}>
            {capitalizeWords(loggedInEmployee?.access_right_group) ===
              capitalizeWords("admin") &&
              filteredRoutes &&
              filteredRoutes?.map((route, index) => (
                <Route
                  key={index}
                  path={`/${route.path}`}
                  element={
                    Cookies.get("userId") ? route.element : <Navigate to="/" />
                  }
                />
              ))}
          </Route>
          {/* Route only HR can acess */}
          <Route element={<HrManagementLayout />}>
            {capitalizeWords(loggedInEmployee?.access_right_group) ===
              capitalizeWords("hr") &&
              filteredRoutes &&
              filteredRoutes?.map((route, index) => (
                <Route
                  key={index}
                  path={`/${route.path}`}
                  element={
                    Cookies.get("userId") ? route.element : <Navigate to="/" />
                  }
                />
              ))}
          </Route>
          {/* Route For Employee */}
          <Route element={<EmployeeLayout />}>
            {capitalizeWords(loggedInEmployee?.access_right_group) ===
              capitalizeWords("employee") &&
              filteredRoutes &&
              filteredRoutes?.map((route, index) => (
                <Route
                  key={index}
                  path={`/${route.path}`}
                  element={
                    Cookies.get("userId") ? route.element : <Navigate to="/" />
                  }
                />
              ))}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default RouterIndex;
