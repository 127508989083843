import { AdminAccessRight, HrAccessRight, employeeAccessRight } from "../../HCM/common/BasicAcessRight";
import { fetchOrgDomain } from "../../HCM/components/Recruitment/services";
import supabase, { supabase_client_for_Org_schema } from "../../HCM/services/supabase";


const baseUrl = process.env.REACT_APP_BASE_URL;
const reactAppBaseURL = process.env.REACT_APP_BASE_URL;
const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const sendResetEmailApi = async (values: any): Promise<any> => {
  try {
    const res = await supabase.auth.resetPasswordForEmail(values.email, {
      redirectTo: `${baseUrl}/updatepassword`,
    });
    return res;
  } catch {
    return catch_return;
  }
};

export const UpdateUserPassword = async (values: any): Promise<any> => {
  try {
    const res = await supabase.auth.updateUser({
      password: values.password,
    });
    return res;
  } catch {
    return catch_return;
  }
};

export const createEmployeeApi = async (values: any): Promise<any> => {
  try {
    const employeeBody = {
      firstName: values.firstName,
      surname: values.surname,
      full_name: `${values.firstName} ${values.surname}`,
      nationality: values.nationality,
      cnic: values.cnic,
      status: "pending",
      avatarUrl: values.userprofile,
      accessRightGroup: "admin",
      workEmail: values.adminemail,
    };
    const employeeres = await supabase
      .from("Employees")
      .insert(employeeBody)
      .select()
      .single();
    return employeeres;
  } catch {
    return catch_return;
  }
};

export const createOrganizationApi = async (
  org_data: any,
  values: any,
  organizationId: any,
  adminId: any
): Promise<any> => {
  try {
    const orgBody = {
      adminId: adminId,
      email: org_data.officalCompanyemail,
      password: values.password,
      organizationName: org_data.organizationName,
      headquarter: org_data.headquarter,
      organization_Id: organizationId,
      phoneNumber: org_data.phoneNumber,
      adminName: `${values.firstName} ${values.surname}`,
      Profile_pic: org_data.orglogo,
      cnic: values.cnic,
    };

    const employeeres = await supabase
      .from("Organizations")
      .insert(orgBody)
      .select()
      .single();
    return employeeres;
  } catch {
    return catch_return;
  }
};



export const signUpApi = async (
  org_data: any,
  values_employee: any
): Promise<any> => {
  try {

    const orgSubDomain = await fetchOrgDomain(org_data.org_id)


    const signUp_res = await supabase.auth.signUp({

      email: values_employee.adminemail,
      password: values_employee.password,
      options: {
        emailRedirectTo: `https://${orgSubDomain}.misgenie.app/`,
        data: {
          organization_name: org_data.organizationName,
          org_id: org_data.org_id,
          role_name: "admin",
          name: values_employee.adminName,
          employee_id: values_employee.employeeId,
          role_id: "admin",
          subdomain: orgSubDomain
        },
      },
    });
    return signUp_res;
  } catch {
    return catch_return;
  }
};

export const updateOrganizationApi = async (
  org_id: any,
  userId: any
): Promise<any> => {
  try {
    const orgupdateres = await supabase
      .from("Organizations")
      .update({
        user_id: userId,
      })
      .eq("id", org_id);
    return orgupdateres;
  } catch {
    return catch_return;
  }
};

export const updateEmployeeinfoForLoginbyIdApi = async (
  employee_id: any,
  userId: any,
  org_id: any,
  groupId: any

): Promise<any> => {
  try {
    const employeeupdateres = await supabase
      .from("Employees")
      .update({
        user_id: userId,
        status: "active",
        organizationId: org_id,
        parentAccessRightGroupId: groupId,
        accessRightGroupId: groupId
      })
      .eq("id", employee_id);
    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

export const createAcessRightApi = async (
  org_id: any,
  group: any
): Promise<any> => {
  try {
    const createAcessRightres = await supabase.from("AccessRights").insert([
      {
        group: "admin",
        organizationId: org_id,
        AccessRights: AdminAccessRight,
      },
      {
        group: "hr",
        organizationId: org_id,
        AccessRights: HrAccessRight,
      },
      {
        group: "employee",
        organizationId: org_id,
        AccessRights: employeeAccessRight,
      },
    ]).select("id, group");

    return createAcessRightres;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeecompanyandbranchbyemployeeidApi = async (employeeId: string, organizationId: string): Promise<any> => {
  try {

    const employeeres = await supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select('branch_id , company_id').eq("id", employeeId).single()
    return employeeres;
  } catch {
    return catch_return;
  }
};
