import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import {
  createOrUpdateSkills,
  getSkills,
  getTheCompanyAndBranchId,
} from "../qualificationServices";

interface props {
  onClose: () => void;
}

const CreateSkillsForm: React.FC<props> = ({ onClose }) => {
  const [form] = Form.useForm();

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const { id } = useParams<{ id: string }>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const getExistingSkills = async () => {
      const response = await getSkills(organizationId, id);
      if (response.data) {
        setTableData(response.data[0]?.skill_details || []);
      } else {
        setTableData([]);
      }
    };
    getExistingSkills();
  }, [organizationId, id]);

  const handleSubmitData = async (values: any) => {
    setButtonLoading(true);

    const newRecord = {
      id: Date.now(),
      skill: values.skill,
      experience: values.yearsOfExperience,
    };
    const updatedTableData = [...tableData, newRecord];

    const companyBranchResponse = await getTheCompanyAndBranchId(
      organizationId,
      id
    );

    if (companyBranchResponse.data.company_id) {
      const data = {
        organization_id: organizationId,
        company_id: companyBranchResponse.data.company_id,
        branch_id: companyBranchResponse.data.branch_id,
        employee_id: id,
        skill_details: updatedTableData,
      };
      const response = await createOrUpdateSkills(organizationId, id, data);
      if (
        (response && response.status === 201) ||
        (response && response.status === 204)
      ) {
        setShowAlert(true);
        setAlertType("success");
        setTableData(updatedTableData);
        form.resetFields();
        setButtonLoading(false);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setShowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmitData}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Skill"
                name="skill"
                rules={[{ required: true, message: "Please Enter Skill!" }]}
              >
                <Input size="large" placeholder="Enter Skill" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Years of Experience"
                name="yearsOfExperience"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Years of Experience!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Years of Experience"
                  suffix={"years"}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="add-btn">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-primary-btn global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={buttonLoading}
                >
                  Create
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default CreateSkillsForm;
