import React, { useEffect, useState } from "react";
import { Card, Select } from "antd";
import { ResponsiveLine } from "@nivo/line";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import dayjs from "dayjs";
import { fetcAllEmployeeApi } from "../../../../services/Global";
import { ProgressLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface ChartData {
  id: string;
  data: { x: string; y: number }[];
}

const EmployeeSalariesInspection: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const loggedInEmployeeId = loggedInEmployee?.employeeId;
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<any>(loggedInEmployeeId);
  const [salaryChartData, setSalaryChartData] = useState<ChartData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Fetch all employees once when the component mounts
  useEffect(() => {
    setEmployees([])
    const fetchEmployees = async () => {
      const res = await fetcAllEmployeeApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employee?.company_id,
        loggedInEmployee?.employee?.branch_id
      );
       
      if (res.data) {
        const temp_employee_list = res.data.map((emp: any) => ({
          label: emp.full_name,
          value: emp.id,
        }))
        setEmployees(temp_employee_list);
      }
    };
    fetchEmployees();
  }, [organizationId]);

  useEffect(() => {
    if (!selectedEmployeeId) return;

    const fetchSalaryData = async () => {
      setIsLoading(true);
      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);

      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_salary_history")
        .select("created_at, gross_salary")
        .eq("employee_id", selectedEmployeeId)
        .gte("created_at", startDate.toISOString())
        .lte("created_at", endDate.toISOString());

      if (response.data) {
        handleSalaryData(response.data);
      }
      setIsLoading(false);
    };
    fetchSalaryData();
  }, [selectedEmployeeId, organizationId]);

  const handleSalaryData = async (response: any) => {
    let monthlySalaryData = {};
    console.log("response", response)
    response.forEach((record: any) => {
      const monthKey = dayjs(record.created_at).format("MMM YYYY");
      if (!(monthlySalaryData as any)[monthKey]) {
        (monthlySalaryData as any)[monthKey] = 0;
      }
      (monthlySalaryData as any)[monthKey] += parseFloat(record.gross_salary);
    });
    console.log("monthlySalaryData", monthlySalaryData)
    const startDate = dayjs().subtract(11, "month");
    const formattedData = [];

    for (let i = 0; i < 12; i++) {
      const monthKey = startDate.add(i, "month").format("MMM YYYY");
      formattedData.push({
        x: monthKey,
        y: (monthlySalaryData as any)[monthKey] || 0,
      });
    }
    setSalaryChartData([{ id: "Salary", data: formattedData }]);
  };

  const handleEmployeeChange = (value: string) => {
    setSelectedEmployeeId(value);
  };

  const numberWithCommas = (value: number) => value.toLocaleString();

  const hasSalaries = salaryChartData.length > 0 && salaryChartData[0].data.some((d: any) => d.y > 0);

  return (
    <div className="payroll-dashboard-total-expense-container">
      <Card
        title={
          <div className="payroll-dashboard-allowance-card-header">
            <div className="payroll-dashboard-total-expense-title-container">
              <span>Employees Yearly Salary</span>
            </div>
            <div>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select Employee"
                onChange={handleEmployeeChange}
                options={employees}
                defaultValue={selectedEmployeeId}
                notFoundContent={
                  <NoDataFoundIcon />
                }
                filterOption={(input: any, option: any) =>
                  option && option.label
                    ? (option.label  as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              />
            </div>
          </div>
        }
        className="payroll-dashboard-total-expense"
      >
        {isLoading ? (
          <div
            style={{
              height: "22rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <ProgressLoader />
          </div>
        ) : hasSalaries ? (
          <div style={{ height: 350 }}>
            <ResponsiveLine
              data={salaryChartData}
              margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat={(value) => numberWithCommas(Number(value))}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Month",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: -10,
                tickRotation: 0,
                legend: "Gross Salary",
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0,
                format: (value) => numberWithCommas(Number(value)),
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              colors={"var(--green-color-primary-light)"}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="data.yFormatted"
              pointLabelYOffset={-12}
              enableTouchCrosshair={true}
              useMesh={true}
              
            />
          </div>
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#888",
            }}
          >
            <span>😕 No salary data available for the selected employee for this year. Please select a different 👨‍⚖️employee.</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default EmployeeSalariesInspection;
