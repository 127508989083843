import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface setEmployeementTypeModelprops {
  setData: (data: any) => void;
}
const SetEmploymentTypeModel: React.FC<setEmployeementTypeModelprops> = (
  props
) => {
  const [form] = Form.useForm();
  const [type, setType] = useState<string>();
  const [startDate, setStartDate] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [completeDate, setCompleteDate] = useState("");
  useEffect(() => {
    if (startDate && duration) {
      const start = dayjs(startDate, "YYYY-MM-DD");
      const durationInMonths = parseInt(duration, 10);
      const newCompleteDate = start.add(durationInMonths, "month");
      form.setFieldValue(
        "completionDate",
        dayjs(newCompleteDate, "YYYY-MM-DD")
      );
      setCompleteDate(newCompleteDate.format("YYYY-MM-DD"));
    }
  }, [startDate, duration]);
  const onChangeStartDate = (date: any, dateString: any) => {
    setStartDate(dateString);
  };
  const onFinish = (values: any) => {
    props.setData(values);
  };
  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col
            span={
              type == "internship" ||
              type == "probation" ||
              type == "contractor"
                ? 12
                : 24
            }
          >
            <Form.Item
              label="Selecte Employment Type"
              name="type"
              rules={[
                { required: true, message: "Please Selecte Employment Type" },
              ]}
            >
              <Select
                placeholder="Select Employment Type"
                onChange={(value) => setType(value)}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                <Select.Option value="permanent">Permanent</Select.Option>
                <Select.Option value="internship">Internship</Select.Option>
                <Select.Option value="probation">Probation</Select.Option>
                <Select.Option value="contractor">Contractor</Select.Option>
                <Select.Option value="consultant">Consultant</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {(type == "internship" ||
            type == "probation" ||
            type == "contractor") && (
            <Col span={12}>
              <Form.Item label="Duration(In Months)" name="duration">
                <InputNumber
                  size="large"
                  className="global-input-number-width"
                  placeholder="Enter Duration(In Months)"
                  onChange={(e:any) => setDuration(e.target.value)}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {(type == "internship" ||
          type == "probation" ||
          type == "contractor") &&
        duration ? (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Start Date" name="startDate">
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    onChange={onChangeStartDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Completion Date" name="completionDate">
                  <DatePicker
                    disabled
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    value={
                      completeDate ? dayjs(completeDate, "YYYY-MM-DD") : null
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    placeholder="Enter Description.........."
                    rows={3}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default SetEmploymentTypeModel;
