import React, { useEffect, useState } from "react";
import "./index.css";
import { Space, Popconfirm, Empty, Collapse, Pagination, Spin } from "antd";
import { useSelector } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { selectAccess } from "../../../../../../redux/AccessRight";
import CustomAlert from "../../../../../common/Alert";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { getAllSkills, handleRecordDeletion } from "../services";
import EditSkills from "../Edit";
import CustomTable from "../../../../../common/CustomTable";

const { Panel } = Collapse;

interface SkillsListProps {
  searchValue?: string;
}

interface SkillDetail {
  id: number;
  skill: string;
  experience: string;
}

interface Employee {
  employee_id: number;
  full_name: string;
  skill_details: SkillDetail[];
}

interface GroupedSkill {
  skill: string;
  details: Employee[];
}

const SkillList: React.FC<SkillsListProps> = ({ searchValue }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [skills, setSkills] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    uniqueSkillData: [],
    searchedData: [],
  });
   const [uniqueSkills, setUniqueSkills] = useState<string[]>([]);
  const [recordId, setRecordId] = useState<any>(null);
  const [searchedSkills, setSearchedSkills] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Employee", "Skills");
  const loggedInUser = useSelector(selectLogedEmployee).logedInemployee;
  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    getSkillsData();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setSkills(originalData.apiData);
      setUniqueSkills(originalData.uniqueSkillData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchTerm === "") {
      setSkills(originalData.apiData);
    } else {
      const filteredEmployees = skills.filter(
        (item: any) =>
          item.employee.full_name &&
          item.employee.full_name
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase())
      );
      setSkills(filteredEmployees);
    }
  }, [searchTerm]);

  useEffect(() => {
    const grouped = groupEmployeesBySkills(skills);
    setSearchedSkills(grouped);
   }, [skills]);

  const getSkillsData = async () => {
    setLoader(true);
    const Ids = {
      organizationId: loggedInUser?.employee.organization_id,
      companyId: loggedInUser?.employee.company_id,
      branchId: loggedInUser?.employee.branch_id,
    };
    const response = await getAllSkills({
      ...Ids,
      page: currentPage,
      pageSize: pageSize,
    });
    if (response && response.data) {
      setLoader(false);
      setSkills(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
     
      extractUniqueSkills(response.data);
    } else {
      setLoader(false);
    }
  };

  const extractUniqueSkills = (skillsData: any[]) => {
    const skillSet = new Set<string>();
    skillsData.forEach((record) => {
      record.skill_details.forEach((skill: any) => {
        skillSet.add(skill.skill);
      });
    });

    setUniqueSkills(Array.from(skillSet));
    setOriginalData((prevState: any) => ({
      ...prevState,
      uniqueSkillData: Array.from(skillSet),
    }));
  };

  const handleSkillClick = (skill: string) => {
    const filteredEmployees = originalData.apiData
      ?.filter((record: any) =>
        record?.skill_details?.some((item: any) => item?.skill === skill)
      )
      ?.map((record: any) => {
        const filteredSkills = record?.skill_details?.filter(
          (item: any) => item.skill === skill
        );
        return { ...record, skill_details: filteredSkills };
      });

    return filteredEmployees;
  };

  function groupEmployeesBySkills(employees: Employee[]): GroupedSkill[] {
    const skillGroups: GroupedSkill[] = [];
  
    employees.forEach((employee) => {
      employee.skill_details.forEach((skillDetail) => {
        const skillGroup = skillGroups.find(
          (group) => group.skill === skillDetail.skill
        );
        if (skillGroup) {
          skillGroup.details.push(employee);
        } else {
          skillGroups.push({ skill: skillDetail.skill, details: [employee] });
        }
      });
    });
  
    return skillGroups;
  }
  

  const handleSearch = (value: string) => {
    const filteredData = uniqueSkills?.filter((item) =>
      item?.toLowerCase().includes(value?.toLowerCase())
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filteredData,
    }));
    setUniqueSkills(filteredData);
  };

  const handleOpeningOfEditModal = (recordId: any) => {
    setIsModalVisible(true);
    setRecordId(recordId);
  };

  const handleRecordDelete = async (recordId: any) => {
    const response = await handleRecordDeletion(
      recordId,
      loggedInUser?.employee.organization_id
    );
    if (response.status == 201 || response.status == 204) {
      setShowAlert(true);
      setAlertType("success");
      getSkillsData();
    } else {
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  const tableColumns = [
    {
      title: "Skill Name",
      dataIndex: "skill",
      key: "skill",
      render: (skill: any) => <span>{skill}</span>,
    },
  ];

  const expandedRowRender = (record: any) => {
    const skills = record.details.map((detail: any) => ({
      ...detail,
      key: detail.id,
    }));

    const columns = [
      {
        title: "Employee Name",
        dataIndex: "employee",
        key: "employee",
        render: (_: any, record: any) => record.employee?.full_name || "N/A",
        sorter: (a: any, b: any) =>
          a.employee?.full_name?.localeCompare(b.employee?.full_name),
      },
      {
        title: "Years of Experience",
        dataIndex: "skill_details",
        key: "experience",
        render: (_: any, record: any) =>
          record.skill_details?.map(
            (item: any) => item.experience + " " + "year(s)"
          ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (text: any, record: any) => (
          <Space size="middle">
            {edit && (
              <a onClick={() => handleOpeningOfEditModal(record.id)}>
                <EditOutlined />
              </a>
            )}
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this record?"
              onConfirm={() => handleRecordDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              {deletee && (
                <a>
                  <DeleteOutlined />
                </a>
              )}
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <CustomTable
        dataSource={skills}
        columns={columns}
        pagination={false}
      />
    );
  };

  return (
    <>
      <Spin spinning={loader}>
        <div className="skills-list">
          {read ? (
            <CustomTable
              columns={tableColumns}
              dataSource={searchedSkills}
              rowKey="skill"
              loading={loader}
              expandable={{
                expandedRowRender,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <a onClick={(e) => onExpand(record, e)}>
                      <MinusOutlined />
                    </a>
                  ) : (
                    <a onClick={(e) => onExpand(record, e)}>
                      <PlusOutlined />
                    </a>
                  ),
              }}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
          {/* <Collapse>
            {uniqueSkills
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((skill) => (
                <Panel header={skill} key={skill}>
                  <div className="skills-table">
                    {read ? (
                      <CustomTable
                        columns={columns}
                        dataSource={handleSkillClick(skill)}
                        loading={loader}
                      />
                    ) : (
                      <Empty
                        description="Not Authorized For This Action"
                        image={Empty.PRESENTED_IMAGE_DEFAULT}
                      />
                    )}
                  </div>
                </Panel>
              ))}
          </Collapse> */}
        </div>
        
      </Spin>
      {isModalVisible && (
        <EditSkills
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          recordId={recordId}
          refreshTable={getSkillsData}
        />
      )}

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default SkillList;
