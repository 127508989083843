import React from "react";
import RequestComp from "./view";

const RequestModule: React.FC = () => {
  return (
    <div>
      <RequestComp />
    </div>
  );
};

export default RequestModule;
