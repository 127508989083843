import React from "react";
import { Row, Col, Button } from "antd";
import genieImage from "../../assets/images/genie.png";
import "./index.css";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <img
        src={genieImage}
        alt="Page Not Found"
        className="notfound-genie-image"
      />

      <Row justify="start" align="middle" className="notfound-main-container">
        <Col xs={24} sm={18} md={14} lg={12} xl={10} className="column">
          <h1 className="notfound-tagline">
            Oops, You've Wandered Off Course!
          </h1>
          <p className="notfount-content">
            Oh, dear seeker of knowledge, it seems we've wandered into the sands
            of the unknown! <br />
            Alas, the lamp has been rubbed in the wrong way, and the pages you
            seek are currently lost in the mystical realms of cyberspace.
          </p>
          <Link to={"/"}>
            <Button
              size="large"
              type="primary"
            >
              Take Me Home!
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default PageNotFound;
