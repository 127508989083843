  import { createSlice, PayloadAction } from "@reduxjs/toolkit";

 interface AccessRight {
    access?:{
      group?:string | null,
      AccessRights?:any |null,
      organizationId?:string | number | null,
      employeeId?:string | number | null
    }
}

 interface RootState {
    Access: AccessRight;
}

 const initialState: AccessRight = {
    access:{
        group:null,
        AccessRights:null,
        organizationId: null ,
        employeeId:null,
    }
};

 export const AccessSlice: any = createSlice({
  name: "Access", 
  initialState, 
  reducers: {
     setAccess: (state, action: PayloadAction<Partial<AccessRight>>) => {
      state.access = { ...state.access, ...action.payload.access };
    },
  },
});

 export const { setAccess } = AccessSlice.actions;

export const selectAccess = (state: RootState) => state.Access;

 export default AccessSlice.reducer;
