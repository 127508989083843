import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface logedemployeeState {
  logedInemployee?: {
    employee?: any | null;
    employeeName?: any | null | string;
    employeeId?: null | number | string;
    employeeRole?: null | string;
    tour?: {
      employee_dashboard_tour: boolean,
      create_employee_tour: boolean,
      recruitment_tour: boolean,
      payroll_tour: boolean,
      employee_contact_tour: boolean
    },
    currentactivepage? : string | null
  };
}

interface RootState {
  logedInemployee: logedemployeeState;
}

const initialState: logedemployeeState = {
  logedInemployee: {
    employee: null,
    employeeName: null,
    employeeId: null,
    employeeRole: null,
    tour: {
      employee_dashboard_tour: false,
      employee_contact_tour: false,
      recruitment_tour: false,
      payroll_tour: false,
      create_employee_tour: false
    },
    currentactivepage : null
  },
};

export const logedEmployeeSlice = createSlice({
  name: "logedInemployee",
  initialState,
  reducers: {
    setlogedInemployee: (state, action: PayloadAction<Partial<logedemployeeState>>) => {
      state.logedInemployee = { ...state.logedInemployee, ...action.payload.logedInemployee };
    },
  },
});

export const { setlogedInemployee } = logedEmployeeSlice.actions;
export const selectLogedEmployee = (state: RootState) => state.logedInemployee;

export default logedEmployeeSlice.reducer;
