import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Select, Checkbox, TimePicker, InputNumber } from "antd";
import "./index.css";
import TextArea from "antd/es/input/TextArea";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import dayjs from "dayjs";
import {
  fetchDepartmentsData,
  fetchTeamsData,
  fetchTimingPolicyByIdApi,
} from "../services";
import {
  allBranchesForCompanyDataApi,
  allOrgCompaniesDataApi,
  fetchAllDeductionPoliciesApi,
} from "../../Deduction/services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface EditViewTimingProps {
  id: string;
  oncancel: () => void;
}

const EditViewTiming: React.FC<EditViewTimingProps> = (props) => {
  const { Option } = Select;

  const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const TimezoneOptions = moment.tz.names();
  const timePickerRef = useRef<any>(null);
  const [form] = Form.useForm();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [departments, setDepartments] = useState<any[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [teams, setTeams] = useState<any[]>([]);
  const [dedectionVersion, setDedectionVersion] = useState<any[]>([]);

  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const id = props.id;

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("company", companyId);
      if (branchId) {
        setSelectedBranch(branchId);
        form.setFieldValue("branch", branchId);
      }
    } else {
      fetchCompanies();
    }
    fetchPolicydetailByid();
  }, []);

  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompany]);

  useEffect(() => {
    fetchDepartments();
  }, [selectedBranch, selectedCompany]);

  useEffect(() => {
    fetchTeams();
  }, [selectedBranch, selectedCompany, selectedDepartment]);

  useEffect(() => {
    fetchDeductionVersion();
  }, [selectedBranch, selectedCompany]);

  const fetchPolicydetailByid = async () => {
    const res = await fetchTimingPolicyByIdApi(organizationId, id);
    if (res.data) {
      setSelectedCompany(res.data.company_id);
      setSelectedBranch(res.data.branch_id);
      setSelectedDepartment(res.data.department_id);

      form.setFieldsValue({
        company: res?.data?.company_id,
        branch: res?.data?.branch_id,
        team: res?.data?.team_id,
        department: res?.data?.department_id,
        policyName: res?.data?.version_name,
        dedectionVersion: res?.data?.deduction_version_id,
        "workinghour/day": res?.data?.working_hours_per_day,
        from: dayjs(res?.data?.day_start_time, "HH:MM"),
        to: dayjs(res?.data?.day_end_time, "HH:MM"),
        timezone: res?.data?.timezone,
        WorkingDays: Object.keys(res?.data?.working_days).filter(
          (day) => res.data.working_days[day]
        ),

        description: res?.data?.description,
      });
    }
  };
  const fetchDeductionVersion = async () => {
    const res = await fetchAllDeductionPoliciesApi(
      selectedBranch,
      organizationId,
      selectedCompany
    );
    if (res.data) {
      setDedectionVersion(res.data);
    }
  };

  const fetchCompanies = async () => {
    const { data } = await allOrgCompaniesDataApi(organizationId);
    if (data) {
      setCompanies(data);
    }
  };
  const fetchBranches = async () => {
    if (branchId) {
      setSelectedBranch(branchId);
      form.setFieldValue("branch", branchId);
    } else {
      const { data } = await allBranchesForCompanyDataApi(
        selectedCompany,
        organizationId
      );
      if (data) {
        setBranches(data);
      }
    }
  };
  const fetchDepartments = async () => {
    const response = await fetchDepartmentsData(
      selectedCompany,
      selectedBranch,
      organizationId
    );

    if (response.data) {
      setDepartments(response.data);
    }
  };
  const fetchTeams = async () => {
    const response = await fetchTeamsData(
      selectedCompany,
      selectedBranch,
      selectedDepartment,
      organizationId
    );

    if (response.data) {
      setTeams(response.data);
    }
  };

  return (
    <div className="timing-policy-edit-view-container">
      <div className="modal-content">
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            {!companyId && (
              <Col span={24} md={12}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select Company"
                    onChange={(value) => setSelectedCompany(value)}
                    allowClear
                    disabled
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!(companyId || branchId) && (
              <Col span={24} md={12}>
                <Form.Item name="branch" label="Branch">
                  <Select
                    placeholder="Select Branch"
                    onChange={setSelectedBranch}
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    disabled
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {branches.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={24} md={12}>
              <Form.Item name="department" label="Departments">
                <Select
                  placeholder="Select Departments"
                  onChange={(value) => setSelectedDepartment(value)}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  disabled
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {departments.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="team" label="Team">
                <Select
                  placeholder="Select Team"
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  disabled
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {teams.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="policyName"
                label="Policy Name"
                rules={[{ required: true }]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="dedectionVersion"
                label="Dedection Version"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select Deduction Version"
                  disabled
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {dedectionVersion.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.version_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="workinghour/day"
                label="Number of working hours per day"
                rules={[
                  {
                    required: true,
                    message: "Please enter the working days.",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  placeholder="Enter the number of working hours per day"
                  className="global-input-number-width"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="From"
                name={"from"}
                rules={[
                  {
                    required: true,
                    message: "Please select a time",
                  },
                ]}
              >
                <TimePicker
                  ref={timePickerRef}
                  disabled
                  name="from"
                  className="from-time-picker"
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label="To"
                name={"to"}
                rules={[
                  {
                    required: true,
                    message: "Please select a time",
                  },
                ]}
              >
                <TimePicker
                  name="to"
                  disabled
                  className="from-time-picker"
                  format="HH:mm"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="timezone" label="Timezone">
                <Select
                  placeholder="Select Timezone"
                  allowClear
                  showSearch
                  disabled
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {TimezoneOptions.map((timezone, index) => (
                    <Option key={index} value={timezone}>
                      {timezone}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="WorkingDays"
                label="Working Days"
                rules={[
                  {
                    required: true,
                    message: "Please select a Working Days",
                  },
                ]}
              >
                <Checkbox.Group>
                  {daysOfTheWeek.map((day, dayIndex) => (
                    <Checkbox key={dayIndex} value={day} disabled>
                      {day}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder="Enter Description..."
                  rows={3}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default EditViewTiming;
