import React, { useEffect, useState } from "react";
import "./index.css";
import { TablePaginationConfig, Empty, Modal } from "antd";
import { useSelector } from "react-redux";
import CustomTable from "../../../../../common/CustomTable";
import { Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import EditQualificationModal from "../Edit";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import { deleteEducationByIdApi, fetchEducationApi } from "../services";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";

import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../../common/SearchAndFilterFunction";
import QualificationFilterModal from "../Filter";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface QualificationsListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const Qualifications: React.FC<QualificationsListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [tableDataSource, setTableDataSource] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const accessObj = useSelector(selectAccess);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const [searchInput, setSearchInput] = useState<any | string | null>(null);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const access = extractAccessRightObject(
    accessObj,
    "Employee",
    "Qualifications"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  /**
   * useEffect hook that fetches data when the component mounts.
   * @param {Function} fetchData - The function to fetch data.
   * @param {Array} dependencies - An empty array to ensure the effect runs only once.
   * @returns None
   */
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableDataSource(originalData.apiData);
    }
  }, [searchValue]);

  /**
   * Fetches education data from the API based on the provided search input and employee details.
   * Updates the table data source with the fetched data and sets the pagination total.
   * @returns None
   */
  const fetchData = async () => {
    setIsModalVisible(false);
    setSearchInput("");
    setLoading(true);
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: logedInemployee?.employee?.company_id,
      branchId: logedInemployee?.employee?.branch_id,
      searchInput: searchInput,
    };
    const res = await fetchEducationApi(data);

    if (res.data) {
      const updatedData = res.data.filter((item: any) => item.employee);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tableDataSource.length,
        },
      });
      setTableDataSource(updatedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
    } else {
      setTableDataSource([]);
    }
    setLoading(false);
  };

  /**
   * Handles changes in table pagination and sorting by updating the table parameters.
   * @param {TablePaginationConfig | undefined} pagination - The pagination configuration for the table.
   * @param {any} sorter - The sorting configuration for the table.
   * @returns {void}
   */
  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  /**
   * Handles the editing of a record with the given record ID by setting the selected record ID
   * and making the modal visible.
   * @param {number} recordId - The ID of the record to edit.
   * @returns None
   */
  const handleEdit = (recordId: number) => {
    setSelectedRecordId(recordId);
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  /**
   * Handles the deletion of an education record by making an API call to delete the record with the given ID.
   * If the deletion is successful, a success alert is shown and the data is fetched again.
   * If there is an error during deletion, an error alert is shown.
   * @param {number} recordId - The ID of the education record to be deleted.
   * @returns None
   */
  const handleDelete = async (recordId: number) => {
    const res = await deleteEducationByIdApi(
      recordId,
      logedInemployee?.employee.organization_id
    );
    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const tableColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) =>
        a.employee.full_name.localeCompare(b.employee.full_name),
      render: (employee: any) => <span>{employee.full_name}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleEdit(record.id)}>
              <EditOutlined />
            </a>
          )}
          {deletee && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this Qualification?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchInput(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableDataSource(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (level: string) => {
    const filterArray = [{ path: "level", value: level }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableDataSource(response);
    }
  };

  const expandedRowRender = (record: any) => {
    const qualifications = record.qualification_details.map((detail: any) => ({
      ...detail,
      key: detail.id,
    }));

    const columns = [
      { title: "Level", dataIndex: "level", key: "level" },
      { title: "Institute", dataIndex: "institute", key: "institute" },
      {
        title: "Specialization",
        dataIndex: "specialization",
        key: "specialization",
      },
      { title: "From", dataIndex: "from", key: "from" },
      { title: "To", dataIndex: "to", key: "to" },
    ];

    return (
      <CustomTable
        dataSource={qualifications}
        columns={columns}
        pagination={false}
      />
    );
  };

  return (
    <>
      <div className="qualifications-table">
        {read ? (
          <CustomTable
            columns={tableColumns}
            dataSource={tableDataSource}
            rowKey="id"
            loading={loading}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) =>
                record.qualification_details.length > 0,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <QualificationFilterModal
          getFilterDataHandler={(level) => getFilterDataHandler(level)}
        />
      </Modal>

      {isModalVisible && (
        <EditQualificationModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          selectedRecordId={selectedRecordId}
          fetchData={fetchData}
        />
      )}

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default Qualifications;
