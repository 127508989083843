import React, { useEffect, useState } from "react";
import { MdCoPresent } from "react-icons/md";
import { FaBusinessTime, FaCalendarTimes } from "react-icons/fa";
import { GiSandsOfTime, GiBackwardTime } from "react-icons/gi";
import "./index.css";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import EmployeeCard from "../../../DashboardAdminandHr/EmployeeCard";
import {
  calcNumOfPresentsbyEmployeeIdApi,
  getEmployeeAttendanceDetails,
  getLateArrivals,
  getMissingPunchesCount,
  getTheEarlyLeftCount,
  getTheHolidays,
  getTheTimePolicyByEmployeeIdApi,
  getTheTotalNumOfleaves,
} from "../services";
import { CustomformatDate } from "../../../../common/validation";

const EmployeesAttendenceCard: React.FC = () => {
  const [employeeAttendanceData, setEmployeeAttendanceData] = useState<any[]>(
    []
  );
  const [timingPolicyData, setTimingPolicyData] = useState<any[]>([]);
  const [missingPunchesCount, setMissingPunchesCount] = useState<number>(0);
  const [numberOfAbsences, setNumberOfAbsences] = useState<any>(null);
  const [leavesCount, setLeavesCount] = useState<number>(0);
  const [workingDays, setNumberOfWorkingDays] = useState<any>(null);
  const [presentCount, setPresentCount] = useState<any>(0);
  const [lateArrivalsCount, setLateArrivalsCount] = useState<number>(0);
  const [earlyLeftsCount, setEarlyLeftsCount] = useState<number>(0);
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const joiningDate = loggedInEmployee.logedInemployee?.employee?.joiningDate;
  const [holidays, setHolidays] = useState<any>(null);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;

  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  useEffect(() => {
    calculatePresentCount();
    fetchTheLatestTimePolicy();
    fetchTheLateArrivalsCount();
    fetchTheEarlyLeftCount();
    getEmployeeAttendance();
    getLeavesCount();
    getHolidaysResponse();
    calculateMissingPunches();
  }, []);
  useEffect(() => {
    if (timingPolicyData) {
      calculateWorkableHoursPerMonth();
    }
  }, [timingPolicyData]);
  useEffect(() => {
    if (employeeAttendanceData && timingPolicyData) {
      calculateAbsences();
    }
  }, [employeeAttendanceData, joiningDate, timingPolicyData]);

  const calculatePresentCount = async () => {
    try {
      const currentDate = new Date();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      const startDate = new Date(year, month, 1).toISOString();
      const endDate = new Date(year, month + 1, 0, 23, 59, 59).toISOString();
      const response = await calcNumOfPresentsbyEmployeeIdApi(
        startDate,
        endDate,
        loggedInEmployee.logedInemployee?.employeeId,
        loggedInEmployee.logedInemployee?.employee.organization_id
      );
      setPresentCount(response.data?.length);
    } catch (error) {
      return error;
    }
  };

  const getHolidaysResponse = async () => {
    const response = await getTheHolidays(organizationId, companyId, branchId);
    if (response.data) {
      const filteredRecord = response.data?.filter(
        (item: any) => item.type_of_event == "holiday"
      );

      setHolidays(filteredRecord);
    }
  };

  const fetchTheLatestTimePolicy = async () => {
    const response = await getTheTimePolicyByEmployeeIdApi(
      loggedInEmployee.logedInemployee?.employee?.id,
      loggedInEmployee.logedInemployee?.employee?.organization_id
    );
    
    if (response.data && response.data.length > 0) {
      
      setTimingPolicyData(response.data);
    }
  };
  const fetchTheLateArrivalsCount = async () => {
    try {
      const currentDate = new Date();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      const startDate = new Date(year, month, 1).toISOString();
      const endDate = new Date(year, month + 1, 0, 23, 59, 59).toISOString();
      const response = await getLateArrivals(
        startDate,
        endDate,
        organizationId,
        loggedInEmployee.logedInemployee?.employee?.id
      );
      if (response) {
        setLateArrivalsCount(response.data?.length);
      }
    } catch (error) {
      return error;
    }
  };

  const fetchTheEarlyLeftCount = async () => {
    try {
      const currentDate = new Date();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      const startDate = new Date(year, month, 1).toISOString();
      const endDate = new Date(year, month + 1, 0, 23, 59, 59).toISOString();
      const response = await getTheEarlyLeftCount(
        startDate,
        endDate,
        organizationId,
        loggedInEmployee.logedInemployee?.employee?.id
      );

      if (response) {
        setEarlyLeftsCount(response.data?.length);
      }
    } catch (error) {
      return error;
    }
  };
  const calculateMissingPunches = async () => {
    try {
      const currentDate = new Date();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();
      const startDate = new Date(year, month, 1).toISOString();
      const endDate = new Date(year, month + 1, 0, 23, 59, 59).toISOString();
      const response = await getMissingPunchesCount(
        startDate,
        endDate,
        organizationId,
        loggedInEmployee.logedInemployee?.employee?.id
      );

      if (response) {
        setMissingPunchesCount(response.data?.length);
      }
    } catch (error) {
      return error;
    }
  };

  const calculateAbsences = () => {
    if (
      employeeAttendanceData.length === 0 ||
      !timingPolicyData ||
      timingPolicyData.length === 0
    ) {
      return;
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    let startDate;

    if (joiningDate) {
      const joiningDateTime = new Date(joiningDate);
      if (
        joiningDateTime.getFullYear() === currentYear &&
        joiningDateTime.getMonth() === currentMonth
      ) {
        startDate = joiningDateTime;
      } else {
        startDate = new Date(currentYear, currentMonth, 1);
      }
    } else {
      startDate = new Date(currentYear, currentMonth, 1);
    }

    const workingDays = timingPolicyData[0]?.working_days || {};
    const workingHoursPerDay = timingPolicyData[0]?.working_hours_per_day;
    if (!workingHoursPerDay || !Object.keys(workingDays).length) {
      return;
    }

    const holidayDates = new Set();
    if (holidays && holidays.length > 0) {
      holidays.forEach((holiday: any) => {
        const start = new Date(holiday.start_date_and_time);
        const end = holiday.end_date_and_time
          ? new Date(holiday.end_date_and_time)
          : start;

        for (
          let date = new Date(start);
          date <= end;
          date.setDate(date.getDate() + 1)
        ) {
          holidayDates.add(CustomformatDate(new Date(date)));
        }
      });
    }

    let absentDays = 0;

    const currentMonthCheckIns = employeeAttendanceData.filter((record) => {
      const checkInDate = new Date(record.check_in);
      return (
        checkInDate.getFullYear() === currentYear &&
        checkInDate.getMonth() === currentMonth
      );
    });

    const uniqueCheckInDates = new Set(
      currentMonthCheckIns.map((record) =>
        CustomformatDate(new Date(record.check_in))
      )
    );

    const absentDates = [];

    for (
      let day = new Date(startDate);
      day.getMonth() === currentMonth && day <= now;
      day.setDate(day.getDate() + 1)
    ) {
      const dayName = day.toLocaleString("en-US", { weekday: "short" });
      const formattedDate = CustomformatDate(day);

      if (workingDays[dayName] && !holidayDates.has(formattedDate)) {
        if (!uniqueCheckInDates.has(formattedDate)) {
          absentDays++;
          absentDates.push(formattedDate);
        }
      }
    }

    setNumberOfAbsences(absentDays);
  };

  const getEmployeeAttendance = async () => {
    const response = await getEmployeeAttendanceDetails(
      loggedInEmployee.logedInemployee?.employeeId,
      loggedInEmployee.logedInemployee?.employee.organization_id
    );

    if (response.data) {
      setEmployeeAttendanceData(response.data);
    }
  };
  const calculateWorkableHoursPerMonth = () => {
    if (!timingPolicyData || timingPolicyData.length === 0) {
      return;
    }

    const workingHoursPerDay = timingPolicyData[0].working_hours_per_day;
    const workingDays = timingPolicyData[0].working_days || 7;

    if (!workingHoursPerDay || !Object.keys(workingDays).length) {
      return;
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const today = now.getDate();

    let totalWorkingDays = 0;

    for (let day = 1; day <= today; day++) {
      const currentDay = new Date(year, month, day);
      const dayName = currentDay.toLocaleString("en-US", { weekday: "short" });

      if (workingDays[dayName]) {
        totalWorkingDays++;
      }
    }
    const totalWorkableHours = totalWorkingDays * workingHoursPerDay;
    // Calculate the number of working days till today
    const expectedWorkingHours = timingPolicyData[0]?.working_hours_per_day;
    if (totalWorkableHours && expectedWorkingHours) {
      const workingDaysTillToday = totalWorkableHours / expectedWorkingHours;
      setNumberOfWorkingDays(workingDaysTillToday);
    }
  };
  const getLeavesCount = async () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31, 23, 59, 59);
    const firstResponse = await getTheTotalNumOfleaves(
      CustomformatDate(startDate),
      CustomformatDate(endDate),
      loggedInEmployee.logedInemployee?.employeeId,
      loggedInEmployee.logedInemployee?.employee.organization_id
    );
    if (firstResponse.data) {
      let totalLeaveCount = firstResponse.data?.reduce(
        (sum, record) => sum + (record?.leave_count || 0),
        0
      );
      setLeavesCount(totalLeaveCount);
    }
  };

  return (
    <>
      <div className="emp-card-container-layout">
        <Row gutter={[10, 10]}>
          <Col
            xs={24}
            sm={12}
            md={8}
            
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-1st-card-icon-div">
                  <MdCoPresent className="employee-present-icon" />
                </div>
              }
              digit={presentCount > 0 ? presentCount : 0}
              heading="Presents"
              subheading={
                presentCount && workingDays
                  ? Math.round((presentCount / workingDays) * 100)
                  : "---"
              }
              percentageWord={presentCount ? "% count" : "---"}
              iconBackgroundColor=""
            />
          </Col>

          {/* Second Card */}
          <Col
            xs={24}
            sm={12}
            md={8}
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-2nd-card-icon-div">
                  <FaCalendarTimes className="employee-absent-icon" />
                </div>
              }
              digit={numberOfAbsences > 0 ? numberOfAbsences : 0}
              heading="Absentees"
              subheading={
                numberOfAbsences && workingDays
                  ? Math.round((numberOfAbsences / workingDays) * 100)
                  : "---"
              }
              percentageWord={numberOfAbsences ? "% count" : "---"}
              iconBackgroundColor=""
            />
          </Col>

          {/* Third Card */}

          <Col
            xs={24}
            sm={12}
            md={8}
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-3rd-card-icon-div">
                  <FaBusinessTime className="employee-short-icon" />
                </div>
              }
              digit={missingPunchesCount ? missingPunchesCount : "0"}
              percentageWord={missingPunchesCount ? "% count" : "---"}
              subheading={
                missingPunchesCount && workingDays
                  ? Math.round((missingPunchesCount / workingDays) * 100)
                  : "---"
              }
              heading="Missing Punches"
              iconBackgroundColor=""
            />
          </Col>

          {/* Fourth Card */}

          <Col
            xs={24}
            sm={12}
            md={8}
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-4th-card-icon-div">
                  <GiSandsOfTime className="employee-late-icon" />
                </div>
              }
              digit={lateArrivalsCount > 0 ? lateArrivalsCount : "0"}
              heading="Late Arrivals"
              iconBackgroundColor=""
              subheading={
                lateArrivalsCount && workingDays
                  ? Math.round((lateArrivalsCount / workingDays) * 100)
                  : "---"
              }
              percentageWord={lateArrivalsCount ? "% count" : "---"}
            />
          </Col>
          {/* Fifth Card */}
          <Col
            xs={24}
            sm={12}
            md={8}
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-4th-card-icon-div">
                  <GiBackwardTime className="employee-late-icon" />
                </div>
              }
              digit={earlyLeftsCount > 0 ? earlyLeftsCount : "0"}
              heading="Early Left"
              iconBackgroundColor=""
              subheading={
                earlyLeftsCount && workingDays
                  ? Math.round((earlyLeftsCount / workingDays) * 100)
                  : "---"
              }
              percentageWord={earlyLeftsCount ? "% count" : "---"}
            />
          </Col>
          {/* Sixth Card */}
          <Col
            xs={24}
            sm={12}
            md={8}
            className="employee-dashboard-attendance-cards-column"
          >
            <EmployeeCard
              className={""}
              icon={
                <div className="employee-6th-card-icon-div">
                  <FaCalendarTimes className="employee-leaves-icon" />
                </div>
              }
              digit={leavesCount > 0 ? leavesCount : "0"}
              heading="Leaves"
              iconBackgroundColor=""
              subheading={
                leavesCount && workingDays
                  ? Math.round((leavesCount / workingDays) * 100)
                  : "---"
              }
              showPercentage={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmployeesAttendenceCard;
