import React, { useState, useEffect } from "react";
import { Col, Row, Input, Form, Select, Button, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import {
  allBranchesForCompanyDataApi,
  allOrgCompaniesDataApi,
} from "../../../PolicyDocumentMangement/Deduction/services";
import { insertAttandanceMachineConfigurationApi } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const AttendanceMachineCreateView = () => {
  const { Option } = Select;
  const [form] = useForm();
  const [companies, setCompanies] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);

  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee.organization_id;
  const companyId = loggedInEmployee.logedInemployee?.employee?.company_id;
  const branchId = loggedInEmployee.logedInemployee?.employee?.branch_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      form.setFieldsValue({ company: companyId });
      setSelectedCompany(companyId);
      if (branchId) {
        form.setFieldsValue({ branchId: branchId });
        setSelectedBranch(branchId);
      }
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (!(branchId || companyId)) {
      getBranches();
    }
  }, [selectedCompany]);

  const getCompanies = async () => {
    const { data } = await allOrgCompaniesDataApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldsValue({ company: data[0]?.id });
      setSelectedCompany(data[0]?.id);
    }
  };

  const getBranches = async (companyId?: number) => {
    if (branchId) {
      form.setFieldsValue({ branchId: branchId });
      setSelectedBranch(branchId);
    } else {
      const companyIdIncoming = form.getFieldValue("company")
        ? form.getFieldValue("company")
        : companyId;
      const { data } = await allBranchesForCompanyDataApi(
        companyIdIncoming,
        organizationId
      );
      if (data) {
        setFilteredBranches(data);
        form.setFieldsValue({ branchId: data[0]?.id });
        setSelectedBranch(data[0]?.id);
      }
    }
  };

  const validateIpAddress = (ip: string) => {
    const regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  };

  const validatePort = (port: string) => {
    // Implement port validation logic here
    // For example, check if it's a number within a certain range
    return /^\d+$/.test(port);
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = {
        ip: values.ip,
        port: values.port,
        organization_id:
          loggedInEmployee?.logedInemployee?.employee?.organization_id,
        status_codes: {
          "check-in": values.check_in || "",
          "check-out": values.check_out || "",
          "break-in": values.break_in || "",
          "break-out": values.break_out || "",
        },
        location_name: values.location,
        password: values.password,
        employee_id: [],
        branch_id: selectedBranch,
        company_id: selectedCompany,
        machine_id: values.machineId,
      };
      const res = await insertAttandanceMachineConfigurationApi(data);
      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      }
      if (res.status == 201 || res.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        form.resetFields();
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            {!companyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name={"company"}
                  label="Select a company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a company..."
                    onChange={(value) => setSelectedCompany(value)}
                    allowClear
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!branchId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item name="branchId" label="Select Branch (If any)">
                  <Select
                    placeholder="Select a Branch"
                    onChange={(value) => setSelectedBranch(value)}
                    allowClear
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={24} md={12}>
              <Form.Item
                name={"machineId"}
                label="Machine Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Machine Name",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Machine Name..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"ip"}
                label="Machine Ip"
                rules={[
                  {
                    required: true,
                    message: "Please enter Machine IP",
                  },
                  {
                    validator: (_, value) => {
                      if (!validateIpAddress(value)) {
                        return Promise.reject("Invalid IP address format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Machine Ip..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"port"}
                label="Port"
                rules={[
                  {
                    required: true,
                    message: "Please enter Port",
                  },
                  {
                    validator: (_, value) => {
                      if (!validatePort(value)) {
                        return Promise.reject("Invalid port format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Port..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"location"}
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "Please enter Location",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Location..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name={"password"} label="Password(if Any)">
                <Input
                  size="large"
                  placeholder="Enter Machine Password(if nay)..."
                />
              </Form.Item>
            </Col>
            <Col span={24} className="machine-inte-sub-heading">
              {" "}
              Machine Code Configuration{" "}
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"check_in"}
                label="Check In"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !validatePort(value)) {
                        return Promise.reject("Invalid check in format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter check in..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"check_out"}
                label="Check Out"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !validatePort(value)) {
                        return Promise.reject("Invalid check Out format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Check Out..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"break_in"}
                label="Break In"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !validatePort(value)) {
                        return Promise.reject("Invalid Break In format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Port..." />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name={"break_out"}
                label="Break Out"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !validatePort(value)) {
                        return Promise.reject("Invalid Break Out format");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Port..." />
              </Form.Item>
            </Col>
          </Row>

          <div className="global-row-content-right">
            <Space>
              <Button
                type="primary"
                className="global-btn-width"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </div>
        </Form>
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};

export default AttendanceMachineCreateView;
