import OpenAI from "openai";
const API = process.env.REACT_APP_OPENAI_API_KEY;

const openai = new OpenAI({
  apiKey: API,
  dangerouslyAllowBrowser: true,
});

const aiModel = "gpt-3.5-turbo-1106";

export const getResponse = async (jobtitle) => {
  const messages = [
    {
      role: "system",
      content: "you are helpful in writing job descriptions",
    },
    {
      role: "user",
      content: `Write an interesting job description for ${jobtitle}. `,
    },
  ];
  const completion = await openai.chat.completions.create({
    model: aiModel,
    messages: messages,
    max_tokens: 250,
  });
  const response = completion.choices[0].message.content;

  if (response) {
    return response;
  } else {
    return "We are facing some issues generating this job description. Please try again after some time.";
  }
};
