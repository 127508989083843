import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Radio,
  Select,
  Button,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import {
  getFiltersDataFromDb,
  getQuestions,
  submitData,
  updatePayload,
} from "../../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface filtersModal {
  id: any;
  onCancel: () => void;
  visible: boolean;
}

const FiltersModal: React.FC<filtersModal> = ({ id, onCancel, visible }) => {
  const [questions, setQuestions] = useState<any>(null);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [form] = useForm();

  const getQuestionss = async () => {
    const response = await getQuestions(id, organizationId);
    if (response.data) {
      setQuestions(response.data);
    }
  };

  const getFiltersDataFromDatabase = async () => {
    const response = await getFiltersDataFromDb(id, organizationId);

    if (response.data && response.data.length > 0) {
      setIsDataAvailable(true);
      const initialValues: any = {};
      response.data[0]?.filters?.forEach((item: any) => {
        initialValues[`dynamicField_${item.questionId}`] = item.value;
      });

      form.setFieldsValue(initialValues);
    }
  };

  const submitDataToSupbase = async () => {
    const formData: any = [];
    questions?.questions?.forEach((question: any) => {
      const fieldName = `dynamicField_${question.id}`;
      const label = question.label;
      const value = form.getFieldValue(fieldName);
      const formDataItem = {
        questionId: question.id,
        label: label,
        value: value,
      };

      formData.push(formDataItem);
    });

    const response = await submitData(id, formData, organizationId);

    if (response.status == 201) {
      onCancel();
    }
  };

  const updateData = async () => {
    const formData: any = [];
    questions?.questions?.forEach((question: any) => {
      const fieldName = `dynamicField_${question.id}`;
      const label = question.label;
      const value = form.getFieldValue(fieldName);
      const formDataItem = {
        questionId: question.id,
        label: label,
        value: value,
      };

      formData.push(formDataItem);
    });

    const response = await updatePayload(id, formData, organizationId);

    if (response.status == 201 || response.status == 204) {
      onCancel();
    }
  };

  useEffect(() => {
    getQuestionss();
    getFiltersDataFromDatabase();
  }, [id, visible]);

  const renderQuestions = () => {
    return questions?.questions?.map((question: any, index: any) => (
      <Row key={index} gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name={`dynamicField_${question?.id}`}
            label={question?.label}
          >
            {question?.inputType === "number" ? (
              <InputNumber size="large" className="global-input-number-width" />
            ) : question?.inputType === "input" ? (
              <Input size="large" />
            ) : question?.inputType === "select" ? (
              <Select
                placeholder="Select a question..."
                mode={question?.options ? undefined : "tags"}
                className="global-input-number-width"
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {question?.options &&
                  question?.options.map((option: any) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
              </Select>
            ) : question?.inputType === "radio" ? (
              <Radio.Group>
                {question?.options?.map((option: any) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onCancel}
        title="Select Filters"
        width={500}
        className="global-modal-main-container"
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          className="global-card-inner-container"
        >
          {renderQuestions()}
          <Row className="global-row-content-right">
            <Button
              type="primary"
              onClick={
                isDataAvailable && isDataAvailable
                  ? updateData
                  : submitDataToSupbase
              }
            >
              Save
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FiltersModal;
