import React from "react";
import "./index.css";
import { Button, Row } from "antd";
import slackSvg from "../../../assets/svgs/slack.svg";
import teamsSvg from "../../../assets/svgs/microsoft-teams.svg";
import clickupSvg from "../../../assets/svgs/clickup.svg";

interface CardDataTypes {
  logo: JSX.Element;
  toggleOnChange: (checked: boolean) => void;
  heading: string;
  description: string;
  buttonText: string;
  buttonOnClick: () => void;
}

const cardData: CardDataTypes[] = [
  {
    logo: <img src={slackSvg} className="si-card-svg" />,
    toggleOnChange: (checked: boolean) => {},
    heading: "Slack",
    description:
      "Seamlessly integrate Slack with your system to enhance team collaboration and communication. Get real-time updates and notifications directly in your Slack channels.",
    buttonText: "Coming Soon",
    buttonOnClick: () => {},
  },
  {
    logo: <img src={teamsSvg} className="si-card-svg" />,
    toggleOnChange: (checked: boolean) => {},
    heading: "Microsoft Teams",
    description:
      "Connect Microsoft Teams to your system to streamline workflows and improve team productivity. Receive instant alerts and manage tasks without leaving Teams.",
    buttonText: "Coming Soon",
    buttonOnClick: () => {},
  },
  {
    logo: <img src={clickupSvg} className="si-card-svg" />,
    toggleOnChange: (checked: boolean) => {},
    heading: "ClickUp",
    description:
      "Integrate ClickUp with your system to manage tasks and projects efficiently. Sync your data and stay organized with powerful features of ClickUp.",
    buttonText: "Coming Soon",
    buttonOnClick: () => {},
  },
];

const SoftwareIntegrations: React.FC = () => {
  return (
    <div className="software-integrations-container">
      <Row gutter={16}>
        <div className="si-card-container">
          {cardData.map((data, index) => (
            <div key={index} className="si-card">
              <div className="si-card-header">
                <div className="si-card-logo">{data.logo}</div>
                <div className="si-card-checkbox">
                  <label className="si-switch">
                    <input
                      type="checkbox"
                      onChange={(e) => data.toggleOnChange(e.target.checked)}
                    />
                    <span className="si-slider"></span>
                  </label>
                </div>
              </div>
              <div className="si-card-heading-container">
                <span className="si-card-heading">{data.heading}</span>
              </div>
              <div className="si-card-description-container">
                <span className="si-card-description">{data.description}</span>
              </div>
              <div className="si-card-button-container">
                <Button
                  className="storefront-green-button si-card-btn"
                  onClick={data.buttonOnClick}
                  disabled
                >
                  {data.buttonText}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default SoftwareIntegrations;
