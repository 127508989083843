import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Col, Form, Input, Row, Space } from "antd";
import CustomAlert from "../../../../common/Alert";

import { updateAttandanceMachineConfigurationApi } from "../services";

interface EditAttendanceMachineIntegerationProps {
  company_id: any;
  branch_id: any;
  employee_id: [
    {
      branch: number;
      company: number;
      employee_id: number;
      id: number;
      machineUserId: string;
    }
  ];
  id: number;
  ip: string;
  password?: string;
  last_time_update: any;
  location_name: string;
  machine_id: string;
  machine_status: boolean;
  organization_id: any;
  port: string;
  status_codes: {
    "check-in": string;
    "check-out": string;
    "break-in": string;
    "break-out": string;
  };
  hanldeEditModalClose: () => void;
}

const EditAttendanceMachineIntegeration: React.FC<
  EditAttendanceMachineIntegerationProps
> = (props) => {
  const [form] = Form.useForm();

  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [btnLoading, setbtnLoading] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  useEffect(() => {
    if (props) {
      form.setFieldsValue({
        machineId: props.machine_id,
        ip: props.ip,
        port: props.port,
        location: props.location_name,
        check_in: props.status_codes["check-in"],
        check_out: props.status_codes["check-out"],
        break_in: props.status_codes["break-in"],
        break_out: props.status_codes["break-out"],
        password: props.password,
      });
    }
  }, [props.id]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const handleUpdate = async () => {
    try {
      setbtnLoading(true);

      const body = {
        id: props.id,
        ip: form.getFieldValue("ip"),
        port: form.getFieldValue("port"),
        status_codes: {
          "check-in": form.getFieldValue("check_in"),
          "check-out": form.getFieldValue("check_out"),
          "break-in": form.getFieldValue("break_in"),
          "break-out": form.getFieldValue("break_out"),
        },
        location_name: form.getFieldValue("location"),
        password: form.getFieldValue("password"),
        machine_id: form.getFieldValue("machineId"),
      };
      const res = await updateAttandanceMachineConfigurationApi(body);
      if (res.status === 204) {
        setAlertType("success");
        setshowAlert(true);
        setbtnLoading(false);
        props.hanldeEditModalClose();
      } else {
        setAlertType("error");
        setshowAlert(true);
        setbtnLoading(false);
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
      setbtnLoading(false);
    }
  };

  const validateIpAddress = (ip: string) => {
    const regex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(ip);
  };

  const validatePort = (port: string) => {
    // Implement port validation logic here
    // For example, check if it's a number within a certain range
    return /^\d+$/.test(port);
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              name={"machineId"}
              label="Machine Id"
              rules={[
                {
                  required: true,
                  message: "Please enter Machine Id",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Machine Id..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"ip"}
              label="Machine Ip"
              rules={[
                {
                  required: true,
                  message: "Please enter Machine IP",
                },
                {
                  validator: (_, value) => {
                    if (!validateIpAddress(value)) {
                      return Promise.reject("Invalid IP address format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Machine Ip..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"port"}
              label="Port"
              rules={[
                {
                  required: true,
                  message: "Please enter Port",
                },
                {
                  validator: (_, value) => {
                    if (!validatePort(value)) {
                      return Promise.reject("Invalid port format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Port..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"location"}
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please enter Location",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Location..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name={"password"} label="Password(if Any)">
              <Input
                size="large"
                placeholder="Enter Machine Password(if nay)..."
              />
            </Form.Item>
          </Col>
          <Col span={24} className="machine-inte-sub-heading">
            {" "}
            Machine Code Configuration{" "}
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"check_in"}
              label="Check In"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !validatePort(value)) {
                      return Promise.reject("Invalid check in format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter check in..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"check_out"}
              label="Check Out"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !validatePort(value)) {
                      return Promise.reject("Invalid check Out format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Check Out..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"break_in"}
              label="Break In"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !validatePort(value)) {
                      return Promise.reject("Invalid port format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Port..." />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"break_out"}
              label="Break Out"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !validatePort(value)) {
                      return Promise.reject("Invalid port format");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" placeholder="Enter Port..." />
            </Form.Item>
          </Col>
        </Row>

        <div className="global-row-content-right">
          <Space>
            <Button
              className="global-btn-width"
              type="primary"
              htmlType="submit"
              loading={btnLoading}
            >
              Update
            </Button>
          </Space>
        </div>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default EditAttendanceMachineIntegeration;
