import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Upload,
  Space,
} from "antd";
import { supabase_client_for_Org_schema } from "../../../../../services/supabase";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./index.css";

import CustomAlert from "../../../../../common/Alert";
import {
  insertCertificatesAttachmentsApi,
  updateCertificaionsbyIdApi,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";

interface EditCertificateProps {
  visible: boolean;
  onCancel: () => void;
  certificateId: string | null;
}

const EditCertificate: React.FC<EditCertificateProps> = ({
  visible,
  onCancel,
  certificateId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  useEffect(() => {
    if (visible && certificateId) {
      fetchCertificateDetails();
    }
  }, [visible, certificateId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  async function fetchCertificateDetails() {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_certificate")
      .select(`*,employee(full_name)`)
      .eq("id", certificateId)
      .single();

    if (response.data) {
      const certDetail = response.data;
      if (certDetail.attachment) {
        setShowDownloadButton(true);
      }

      form.setFieldsValue({
        employeeId: certDetail?.employee.full_name,
        name: certDetail.name,
        attachment: certDetail.attachment,
        instituteName: certDetail.institute_name,
        certificationDate: certDetail?.certification_date
          ? dayjs(certDetail?.certification_date)
          : null,
      });
    }
  }
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target?.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          setFileBase64(event.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setshowAlert(false);
    const values = await form.validateFields();
    let attachres = "";
    if (values.attachment) {
      const storageBody = {
        file: values.attachment.file,
        filePath:
          values.name +
          values.employeeid +
          new Date().toISOString().replace(/[^0-9]/g, ""),
      };
      attachres = await insertCertificatesAttachmentsApi(storageBody);
    }
    const updateData = {
      institute_name: values.instituteName,
      name: values.name,
      certification_date: values.certificationDate.format("YYYY-MM-DD"),
      ...(values.attachment && {
        attachment: (attachres as any)?.data.publicUrl,
      }),
    };
    const data = {
      updatedData: updateData,
      id: certificateId,
      organizationId: organizationId,
    };
    const { error } = await updateCertificaionsbyIdApi(data);
    setshowAlert(true);
    if (error) {
      setAlertType("error");
    } else {
      setAlertType("success");
    }
    setLoading(false);
    onCancel();
  };
  const openUrlInNewTab = () => {
    window.open(
      form.getFieldValue("attachment"),
      "_blank",
      "noopener,noreferrer"
    );
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
    setFileBase64(null);
  };
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1000}
      title={<span className="global-modal-title">Edit Certification</span>}
      footer={false}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form form={form} layout="vertical">
          <Row>
            <Col className="modal-content" span={24}>
              <Row gutter={[16, 0]}>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Employee Name"
                    name="employeeId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Employee Name!",
                      },
                    ]}
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please Enter Name!" }]}
                  >
                    <Input size="large" placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Institute Name"
                    name="instituteName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Institute Name!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Institute" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Certification Date"
                    name="certificationDate"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Certification Date!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="certification-date-picker"
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={20}>
                  <Form.Item label="Attachment" name="attachment">
                    <Upload
                      accept=".pdf, .doc, .docx"
                      beforeUpload={(file: any) => {
                        handleFileChange(file);
                        return false;
                      }}
                      onRemove={() => {
                        handleFileRemove();
                      }}
                      listType="text"
                    >
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  {showDownloadButton && (
                    <Row>
                      <Button
                        className="global-primary-btn global-btn-width"
                        size="large"
                        onClick={openUrlInNewTab}
                      >
                        View old one
                      </Button>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  className="global-modal-bottom-row edit-modal-buttons-certification"
                >
                  <Space>
                    <Button
                      className="global-btn-width"
                      type="default"
                      key="cancel"
                      onClick={onCancel}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="global-btn-width"
                      type="primary"
                      key="save"
                      onClick={handleSave}
                      loading={loading}
                      size="large"
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </Modal>
  );
};

export default EditCertificate;
