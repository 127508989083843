import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const customCatchError = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
export const fetchAttendaceDataById = async (
  id: number,
  organizationId: string
) => {
  try {
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("*")
      .eq("id", id)
      .maybeSingle();
    return res;
  } catch {
    return customCatchError;
  }
};
