import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const setPolicydoumentData = async (body: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(body.organization_id)
      .from("genral_policy")
      .insert([body])
      .select();

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchPolicydoumentData = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("genral_policy")
      .select("version_name,  description, id, effective_up_to")
      .eq("organization_id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const deletePolicydoumentData = async (
  policyId: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("genral_policy")
      .delete()
      .eq("id", policyId);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEditModalPolicydoumentData = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("genral_policy")
      .select("id,version_name,  description")
      .eq("id", id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const hanldeSaveEditModalPolicydoumentData = async (
  id: number,
  policyName: string,
  policyText: string,
  organizationId: string,
  effective_up_to: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("genral_policy")
      .update({
        version_name: policyName,
        description: policyText,
        effective_up_to: effective_up_to,
      })
      .eq("id", id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const getTheGeneralPolicyForEmployeeView = async (
  organizationId: any,
  companyId: any,
  branchId?: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("genral_policy")
      .select("*")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query
      .order("created_at", { ascending: false })
      .limit(1);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const genralPolicyfilteinsertApi = async (
  filePath: any,
  file: any,
  organizationid: string
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from(`general_policy/${organizationid}`)
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from(`general_policy/${organizationid}`)
        .getPublicUrl(filePath);
      return publicURL;
    } else {
      return res;
    }
  } catch {
    return catch_return;
  }
};
