import React, { useState, useEffect } from "react";
import "./index.css";
import { Space, Popconfirm, TablePaginationConfig, Empty, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { SortOrder } from "antd/lib/table/interface";
import EmployeeDetailsModal from "../EditSalaries";
import CustomAlert from "../../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { fetchSalariesApi, UpdateSalaryApi } from "../services";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface ViewSalariesListProps {
  searchValue?: string;
}

const ViewSalaries: React.FC<ViewSalariesListProps> = ({ searchValue }) => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [salaries, setSalaries] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState<any | string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState<string>("");
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    searchedData: [],
    filteredData: [],
  });
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Salaries");
  const organizationId = logedInemployee?.employee?.organization_id;
  const edit = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchSalaries();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setSalaries(originalData.apiData);
    }
  }, [searchValue]);

  const fetchSalaries = async () => {
    setLoading(true);
    setSearchInput("");
    setshowAlert(false);
    const data = {
      organizationId: organizationId,
      companyId: logedInemployee?.employee?.company_id,
      branchId: logedInemployee?.employee?.branch_id,
      searchInput: searchInput,
    };
    const res = await fetchSalariesApi(data);
    setLoading(false);

    if (res.data) {
      const updatedData = res.data.filter((item: any) => item.employee);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: salaries.length,
        },
      });
      setSalaries(updatedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
      setLoading(false);
    }
  };
  const handleUpdate = async (record: any) => {
    setshowAlert(false);
    const updateddata = {
      block_salary: !record.block_salary,
    };

    const res = await UpdateSalaryApi(
      {
        updateddata,
        id: record.id,
      },
      organizationId
    );
    setshowAlert(true);
    if (res.error) {
      setAlertType("error");
    } else {
      setAlertType("success");
      fetchSalaries();
    }
  };
  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  const showEmployeeDetailsModal = (record: any) => {
    setSelectedEmployeeName(record.employee?.full_name);
    setSelectedRecordId(record.id);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    fetchSalaries();
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchInput(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setSalaries(filterData);

    setLoading(false);
  };

  const columns: any = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = (a.Employees && a.employee.full_name) || "";
        const nameB = (b.Employees && b.employee.full_name) || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (employee: any) => employee?.full_name,
    },
    {
      title: "Base Salary",
      dataIndex: "base_salary",
      key: "base_salary",
      sorter: (a: any, b: any) => {
        const salaryA = parseFloat(a.base_salary) || 0;
        const salaryB = parseFloat(b.base_salary) || 0;
        return salaryB - salaryA;
      },
      defaultSortOrder: "ascend",

      sortDirections: ["ascend", "descend"] as SortOrder[],

      render: (_: any, item: any) =>
        item?.base_salary
          ? parseFloat(item?.base_salary)?.toLocaleString()
          : "",
    },

    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => showEmployeeDetailsModal(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to Update this record?"
            onConfirm={() => handleUpdate(record)}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <Switch
                checkedChildren="Block"
                unCheckedChildren="Unblock"
                checked={record.block_salary}
              />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="salaries-table">
        {read ? (
          <CustomTable
            columns={columns}
            dataSource={salaries}
            loading={loading}
            rowKey="employeeId"
            onChange={handleTableChange}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>
      {isModalVisible && (
        <EmployeeDetailsModal
          recordId={selectedRecordId}
          isVisible={isModalVisible}
          employeeName={selectedEmployeeName}
          onCancel={handleCancel}
        />
      )}

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};
export default ViewSalaries;
