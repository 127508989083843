import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  AutoComplete,
} from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useForm } from "antd/es/form/Form";
import { currencies } from "../../../../common/Currencies";
import { createAccountAPI } from "../../services";
import CustomAlert from "../../../../common/Alert";
import dayjs from "dayjs";
import {
  allorgCompanyApi,
  allorgCompanyBranchesApi,
} from "../../../../services/Global";
import { banks } from "../../../../common/Constants";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface CreateAccount {
  onCancel: () => void;
}

const CreateAccountDetails: React.FC<CreateAccount> = ({ onCancel }) => {
  const [form] = useForm();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("error");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [options, setOptions] = useState(
    banks.map((item) => ({
      value: item.label + "/" + item.short,
      label: item.label + "/" + item.short,
    }))
  );
  const handleSearch = (value: string) => {
    const filteredOptions = banks
      .filter((bank) =>
        (bank.label + "/" + bank.short)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
      .map((bank) => ({
        value: bank.label + "/" + bank.short,
        label: bank.label + "/" + bank.short,
      }));
    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    form.setFieldValue("branchId", "");

    fetchBranches();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
  };
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      const resBranches = await allorgCompanyBranchesApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
      }
    }
  };
  const submitDataToDatabase = async () => {
    form.validateFields();
    setBtnLoading(true);
    const data = {
      account_name: form.getFieldValue("accountName"),
      bank_name: form.getFieldValue("bankName"),
      branch_name: form.getFieldValue("branchName"),
      branch_code: form.getFieldValue("branchCode"),
      account_number: form.getFieldValue("accountNumber"),
      account_title: form.getFieldValue("accountTitle"),
      currency: form.getFieldValue("currency"),
      organization_id: organizationId,
      company_id: form.getFieldValue("companyId") || companyId,
      branch_id: form.getFieldValue("branchId") || branchId,
      date_effective: dayjs(form.getFieldValue("dateEffective")).format(
        "DD-MM-YYYY"
      ),
    };
    const response = await createAccountAPI(organizationId, data);
    if (response.status == 201 || response.status == 204) {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("success");
      onCancel();
    } else {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };
  return (
    <>
      <div className="global-card-inner-container">
        <Form form={form} layout="vertical" onFinish={submitDataToDatabase}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="companyId"
                label="Select Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  disabled={companyId}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Select Branch (If any)">
                <Select
                  disabled={branchId}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"accountName"}
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Account Name",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Account Name" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"currency"}
                label="Currency"
                rules={[
                  {
                    required: true,
                    message: "Please choose a currency...",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Enter currency"
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {currencies.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <span className="create-axxount-modal-subheading">
            Add Bank Account
          </span>
          <br />
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Enter/Select Bank Name"
                name="bankName"
                rules={[{ required: true, message: "Please Enter Bank Name" }]}
              >
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  placeholder="Select bank..."
                  allowClear
                  filterOption={(inputValue, option) =>
                    option?.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Account Title"
                name="accountTitle"
                rules={[
                  { required: true, message: "Please Enter Account Title" },
                ]}
              >
                <Input size="large" placeholder="Enter Account Title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[
                  { required: true, message: "Please Enter Account Number" },
                ]}
              >
                <Input size="large" placeholder="Enter Account Number" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Branch Name"
                name="branchName"
                rules={[
                  { required: true, message: "Please Enter Branch Name" },
                ]}
              >
                <Input size="large" placeholder="Enter Branch Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Bank Code"
                name="branchCode"
                rules={[
                  { required: true, message: "Please Enter Branch Code" },
                ]}
              >
                <Input size="large" placeholder="Enter Branch Code" />
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="Effective up to" name="dateEffective">
                <DatePicker
                  style={{ width: "98%", height: "2.5rem" }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="global-modal-bottom-row add-account-btn">
            <Button
              className="global-btn-width"
              type="primary"
              size="large"
              htmlType="submit"
              loading={btnLoading}
            >
              Create
            </Button>
          </Row>
        </Form>
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default CreateAccountDetails;
