import React from "react";
import JobByTypesChart from "./Charts/JobByTypes";
import ShortlistedApplicationsChart from "./Charts/ShorlistedApplicationChart";
import JobPostCard from "./PostJobs";
import { Col, Row } from "antd";
import "./index.css";
import JobDetailCards from "./JobDetails";

const JobPostDashboard = () => {
  return (
    <>
      <div className="job-post-dashboard-main-container">
        <div>
          <JobDetailCards />
        </div>
        <div className="job-dashboard-policy-container">
          <JobPostCard />
        </div>
        <div className="job-by-type-card-container">
          <Row className="job-by-type-card-row" gutter={[16, 16]}>
            <Col span={24} lg={12} className="job-by-type-card-row">
              <JobByTypesChart />
            </Col>
            <Col span={24} lg={12} className="job-by-type-card-row">
              <ShortlistedApplicationsChart />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default JobPostDashboard;
