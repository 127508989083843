import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Space,
  Popconfirm,
  TablePaginationConfig,
  Modal,
  Empty,
  Row,
  Button,
} from "antd";
import { capitalizeWords } from "../../../../../common/validation";
import { DeleteOutlined, EditOutlined, TableOutlined } from "@ant-design/icons";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import EditAddressModal from "../Edit";
import "./index.css";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import {
  deleteEmployeeAddressesByIdApi,
  fetchEmployeeAddressesApi,
} from "../services";
import { searchLogicHandler } from "../../../../../common/SearchAndFilterFunction";
import AddressesFilterModal from "../Filter";
import MapView from "./MapView";
import { MapOutlined } from "@mui/icons-material";
import CustomTable from "../../../../../common/CustomTable";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface AddressListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

/**
 * Functional component for displaying a list of addresses with various actions.
 * Retrieves and displays employee addresses based on the logged-in employee's organization, company, and branch.
 * Allows searching for addresses by employee name, resetting the search input, and deleting addresses.
 * @returns JSX element containing the address list view component.
 */
const AddressListView: React.FC<AddressListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [fetchedEmployees, setFetchedEmployees] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string | null | any>(null);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [toggleView, setToggleView] = useState<boolean>(true);

  const [filterCity, setFilterCity] = useState<string>("");

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Employee", "Addresses");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  /**
   * useEffect hook that fetches addresses when the component mounts.
   * @returns None
   */

  useEffect(() => {
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setFetchedEmployees(originalData.apiData);
    }
  }, [searchValue]);

  const toggleViewHandler = () => {
    setToggleView((prevState) => !prevState);
  };

  /**
   * Fetches addresses for the logged-in employee based on the provided data.
   * Sets loading states during the fetch operation and updates the fetched employee data.
   * @returns None
   */

  const toggleButtonText =
    toggleView == true ? <MapOutlined /> : <TableOutlined />;

  const fetchAddresses = async () => {
    setSelectedRecordId(null);
    setSearchInput("");
    setFilterCity("");
    setIsLoading(true);
    setLoading(true);
    const data = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      searchInput: searchInput,
      filterCity: filterCity,
    };
    const res = await fetchEmployeeAddressesApi(data);

    if (res.data) {
      const updatedData = res.data.filter((item: any) => item.employee);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: fetchedEmployees.length,
        },
      });
      setFetchedEmployees(updatedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
    }
    setIsLoading(false);
    setLoading(false);
  };
  /**
   * Handles changes in table pagination and sorting by updating the table parameters.
   * @param {TablePaginationConfig | undefined} pagination - The pagination configuration for the table.
   * @param {any} sorter - The sorting configuration for the table.
   * @returns {void}
   */
  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  /**
   * Function to hide the modal by resetting the selected record ID to null and fetching addresses.
   * @returns None
   */
  const hideModal = () => {
    setSelectedRecordId(null);
    setCreateModalVisible(false);
    fetchAddresses();
  };

  /**
   * Handles the deletion of an employee address record.
   * @param {any} record - The record of the employee address to be deleted.
   * @returns None
   */
  const handleDelete = async (record: any) => {
    const { error } = await deleteEmployeeAddressesByIdApi(
      record.id,
      loggedInEmployee?.employee?.organization_id
    );
    if (error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
    }
    const updatedDataSource = fetchedEmployees.filter(
      (item: any) => item.id !== record.id
    );
    setFetchedEmployees(updatedDataSource);
  };

  const handleSearch = (value: any) => {
    setLoading(true);
    setSearchInput(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setFetchedEmployees(filterData);

    setLoading(false);
  };

  /**
   * An array of objects representing columns for a table component.
   * Each object in the array defines a column with specific properties such as title, dataIndex, key, sorter, sortDirections, and render.
   * @type {Array}
   */
  const tableColumns = [
    {
      title: "Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = (a.employee && a.employee.full_name) || "";
        const nameB = (b.employee && b.employee.full_name) || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (record: any) => {
        return (
          <span>
            {record && record?.full_name
              ? capitalizeWords(record?.full_name)
              : "N/A"}
          </span>
        );
      },
    },
    {
      title: "City",
      dataIndex: "present_city",
      key: "city",
      render: (text: any, record: any) => {
        return (
          <span>
            {record.present_address_detail?.present_city
              ? capitalizeWords(record.present_address_detail?.present_city)
              : "N/A"}
          </span>
        );
      },
    },
    {
      title: "State",
      key: "present_state",
      render: (text: any, record: any) => {
        return (
          <span>{record.present_address_detail?.present_state || "N/A"}</span>
        );
      },
    },
    {
      title: "Postal Code",
      key: "present_postcode",
      render: (text: any, record: any) =>
        record.present_address_detail?.present_postcode,
    },

    {
      title: "Street",
      key: "present_street",
      render: (text: any, record: any) => {
        return (
          <span>{record.present_address_detail?.present_street || "N/A"}</span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => setSelectedRecordId(record.id)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this Address?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <Row className="address-toggle-button">
        <Button
          size="large"
          onClick={toggleViewHandler}
          className="global-primary-btn global-primary-width"
        >
          {toggleButtonText}
        </Button>
      </Row>
      {toggleView && (
        <div className="addresses-table">
          {read ? (
            <CustomTable
              loading={loading}
              dataSource={fetchedEmployees}
              columns={tableColumns}
              onChange={handleTableChange}
              rowKey={(record) => record.id}
            />
          ) : (
            <>
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            </>
          )}
          <Modal
            className="global-modal-main-container"
            title={<span className="global-modal-title">Filter</span>}
            open={openFilterModal}
            footer={false}
            onCancel={toggleFilterModal}
          >
            <AddressesFilterModal
              getFilterDataHandlerr={(city) => setFilterCity(city)}
            />
          </Modal>

          {selectedRecordId && (
            <EditAddressModal
              visible={selectedRecordId ? true : false}
              oncancel={hideModal}
              selectedRecordId={selectedRecordId}
              isLoading={isLoading}
            />
          )}
          {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
        </div>
      )}

      {!toggleView && <MapView />}
    </>
  );
};

export default AddressListView;
