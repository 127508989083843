import React, { useEffect, useState } from "react";
import "./index.css";
import { Row, Col, Empty } from "antd";
import ScreenWidth from "../../../common/ScreenWidth";
import { useSelector } from "react-redux";
import SetPolicyWarningModel from "../../../common/SetPolicyWarningModel";
import { checkReamingPolicySet, fetchTimingPolicyDataApi } from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

import MyCalendar from "../Calender";
import EventList from "../EventList";
import { fetcAllEmployeeApi } from "../../../services/Global";
import {
  checkTotalEmployeeApi,
  fetchTheNumberOfAbsentees,
  fetchTodayOnLeavesEmployeeApi,
  fetchTodayActiveEmployeeApi,
} from "./services";
import {
  UserDeleteOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import EmployeeCard from "../EmployeeCard";
import EarlyRisers from "../EarlyandLate/EarlyRisers";
import LateComers from "../EarlyandLate/LateComers";
import MyCheckinCheckoutOnDashBoard from "../CheckinCheckOut";
import { fetchBranches, fetchCompanies } from "./services";
import ChartsMenu from "../../../common/ChartsMenu/AdminMenu/index";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectOrganizationModulePermission } from "../../../../redux/organizationModulePermission";
const DashboardLayout: React.FC = () => {
  let screenwidth = ScreenWidth();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [absentEmployees, setAbsentEmployees] = useState<any>(null);
  const [totalEmployees, setTotalEmployees] = useState<any>(null);
  const [ploicyNotSetList, setPloicyNotSetList] = useState<any>();
  const [showSetPolicyWarningModel, setshowSetPolicyWarningModel] = useState<boolean>(false);
  const [numberOfTotalEmployees, setNumberOfTotalEmployees] =
    useState<number>(0);
  const [numberOfActiveEmployees, setNumberOfActiveEmployees] =
    useState<number>(0);
  const [numberOfLeaveRequests, setNumberOfLeaveRequests] = useState<number>(0);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Dashboard");

  const read = access?.readAccess;

  const [isTodayWorkingDay, setIsTodayWorkingDay] = useState<boolean>(false);
  const isShowSetPolicyModalAccess = useSelector(
    selectOrganizationModulePermission
  )?.organizationModulePermission?.Attendance?.readAccess;

  useEffect(() => {
    const absentEmployeeCount = () => {
      const absentEmployees = numberOfTotalEmployees - numberOfActiveEmployees;

      setAbsentEmployees(absentEmployees);
    };

    absentEmployeeCount();
  }, [numberOfTotalEmployees, numberOfActiveEmployees]);


  useEffect(() => {
    checkPolicySet();
    countTotalEmployee();
    countTodayActiveEmployee();
    countTodayOnLeaves();
    getAbsentData();
    getTImingPolicyData();
  }, []);

  const checkPolicySet = async () => {
    const data = {
      orgid: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
    };
    const res = await checkReamingPolicySet(data);
    if (res?.data && res?.data.length > 0) {
      setPloicyNotSetList(res?.data);
      setshowSetPolicyWarningModel(true);
    }
  };

  const getTImingPolicyData = async () => {
    try {
      const res = await fetchTimingPolicyDataApi(
        organizationId,
        loggedInEmployee?.employee?.company_id,
        loggedInEmployee?.employee?.branch_id
      );

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const today = new Date().getDay();

      const todayDayString = daysOfWeek[today];

      const todayIsWorkingDay = Boolean(
        res?.data[0]?.working_days[todayDayString]
      );

      setIsTodayWorkingDay(todayIsWorkingDay);
    } catch (error) { }
  };

  const getAbsentData = async () => {
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
    };
    const response = await fetchTheNumberOfAbsentees(data);

    if (response) {
      setTotalEmployees(response.employeeResponse);
    }
  };
  const countTotalEmployee = async () => {
    const totalemployeeCount_res = await checkTotalEmployeeApi(
      organizationId,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id
    );

    if (totalemployeeCount_res.data) {
      setNumberOfTotalEmployees(totalemployeeCount_res.data.length);
    }
  };

  const countTodayActiveEmployee = async () => {
    const totalemployeeCount_res = await fetchTodayActiveEmployeeApi(
      organizationId,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id
    );

    if (totalemployeeCount_res?.data) {
      setNumberOfActiveEmployees(totalemployeeCount_res.data.length);
    }
  };
  const countTodayOnLeaves = async () => {
    const totalemployeeCount_res = await fetchTodayOnLeavesEmployeeApi(
      organizationId,
      loggedInEmployee?.employee?.company_id,
      loggedInEmployee?.employee?.branch_id
    );

    if (totalemployeeCount_res?.data) {
      setNumberOfLeaveRequests(totalemployeeCount_res.data.length);
    }
  };




  return (
    <div>
      {read ? (
        <>
          {screenwidth > 1280 ? (
            <Row
              className="admin-hr-dashboard-main-container"
              gutter={[10, 10]}
            >
              <Col
                span={17}
                className="admin-hr-dashboard-cards-scrollable-container"
                style={{ height: "calc(88vh - 2em)", overflowY: "auto" }}
              >
                <MyCheckinCheckoutOnDashBoard />
                <Row gutter={[10, 10]} style={{ marginTop: "1em" }}>
                  <Col span={6}>
                    <EmployeeCard
                      icon={
                        <UsergroupAddOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      className={"dashboard-employee-mini-card"}
                      digit={numberOfTotalEmployees}
                      heading="Employees"
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={6}>
                    <EmployeeCard
                      icon={
                        <UserSwitchOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      digit={
                        numberOfActiveEmployees ? numberOfActiveEmployees : "0"
                      }
                      className={"dashboard-activieemployee-mini-card"}
                      heading="Present Today"
                      subheading={Math.round(
                        (numberOfActiveEmployees / numberOfTotalEmployees) * 100
                      )}
                      percentageWord={` % Present `}
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={6}>
                    <EmployeeCard
                      icon={
                        <UserDeleteOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      digit={
                        numberOfLeaveRequests ? numberOfLeaveRequests : "0"
                      }
                      heading="On Leave"
                      className={"dashboard-leaves-mini-card"}
                      subheading={Math.round(
                        (numberOfLeaveRequests / numberOfTotalEmployees) * 100
                      )}
                      percentageWord={`% On Leave `}
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={6}>
                    <EmployeeCard
                      icon={
                        <UserDeleteOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      digit={
                        isTodayWorkingDay === true && absentEmployees
                          ? absentEmployees
                          : "0"
                      }
                      heading="Absentees"
                      className={"dashboard-absent-mini-card"}
                      subheading={
                        isTodayWorkingDay === true && absentEmployees
                          ? Math.round(
                            (absentEmployees / totalEmployees?.data?.length) *
                            100
                          )
                          : "---"
                      }
                      percentageWord={`% Absent `}
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>

                  <Col
                    span={12}
                    className="admin-hr-dashboard-data-cards-height"
                  >
                    <EarlyRisers />
                  </Col>
                  <Col
                    span={12}
                    className="admin-hr-dashboard-data-cards-height"
                  >
                    <LateComers />
                  </Col>
                </Row>
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <ChartsMenu />
                  </Col>
                </Row>
              </Col>
              <Col span={7} className="admin-hr-dashboard-side-column">
                <div className="admin-hr-dashboard-side-column-container">
                  <div className="admin-hr-dashboard-side-column-calendar">
                    <MyCalendar />
                  </div>
                  <div className="admin-hr-dashboard-side-column-event">
                    <EventList />
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                span={24}
                className="dashboard-left-container"
                style={{ height: "calc(88vh - 2em)", overflowY: "auto" }}
              >
                <div className="dashboard-small-screen-punch">
                  <MyCheckinCheckoutOnDashBoard />
                </div>
                <Row gutter={[10, 10]}>
                  <Col span={24} md={12} lg={6}>
                    <EmployeeCard
                      icon={
                        <UsergroupAddOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      className={"dashboard-employee-mini-card"}
                      digit={
                        numberOfTotalEmployees ? numberOfTotalEmployees : "0"
                      }
                      heading="Employees"
                      subheading={Math.round(
                        (numberOfTotalEmployees / numberOfTotalEmployees) * 100
                      )}
                      percentageWord="% Count"
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={24} md={12} lg={6}>
                    <EmployeeCard
                      icon={
                        <UserSwitchOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      digit={
                        numberOfActiveEmployees ? numberOfTotalEmployees : "0"
                      }
                      className={"dashboard-activieemployee-mini-card"}
                      heading="Active Today"
                      subheading={Math.round(
                        (numberOfActiveEmployees / numberOfTotalEmployees) * 100
                      )}
                      percentageWord="% Present"
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={24} md={12} lg={6}>
                    <EmployeeCard
                      icon={
                        <UserDeleteOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      className={"dashboard-leaves-mini-card"}
                      digit={
                        numberOfLeaveRequests ? numberOfLeaveRequests : "0"
                      }
                      heading="On Leave"
                      subheading={Math.round(
                        (numberOfLeaveRequests / numberOfLeaveRequests) * 100
                      )}
                      percentageWord="% On Leave"
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col span={24} md={12} lg={6}>
                    <EmployeeCard
                      icon={
                        <UserDeleteOutlined
                          style={{ color: "var(--green-color-primary-light)" }}
                        />
                      }
                      className={"dashboard-absent-mini-card"}
                      digit={
                        isTodayWorkingDay === true && absentEmployees
                          ? absentEmployees
                          : "0"
                      }
                      heading="Absentees"
                      subheading={
                        isTodayWorkingDay === true && absentEmployees
                          ? Math.round(
                            (absentEmployees / totalEmployees?.data?.length) *
                            100
                          )
                          : "---"
                      }
                      percentageWord="% count"
                      iconBackgroundColor="var(--green-color-secondary-light-alpha)"
                    />
                  </Col>
                  <Col
                    span={24}
                    lg={12}
                    className="admin-hr-dashboard-cards-height-small-view"
                  >
                    <MyCalendar />
                  </Col>
                  <Col
                    span={24}
                    lg={12}
                    className="admin-hr-dashboard-cards-height-small-view"
                  >
                    <EventList />
                  </Col>
                  <Col
                    span={24}
                    lg={12}
                    className="admin-hr-dashboard-data-cards-height-small-view"
                  >
                    <EarlyRisers />
                  </Col>
                  <Col
                    span={24}
                    lg={12}
                    className="admin-hr-dashboard-data-cards-height-small-view"
                  >
                    <LateComers />
                  </Col>

                  <Col
                    span={24}
                    className="admin-hr-dashboard-data-cards-height-small-view"
                  >
                    <ChartsMenu />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {showSetPolicyWarningModel &&
            ploicyNotSetList &&
            ploicyNotSetList.length > 0 &&
            isShowSetPolicyModalAccess && (
              <SetPolicyWarningModel
                data={ploicyNotSetList}
                showModel={showSetPolicyWarningModel}
                setShowModel={() => setshowSetPolicyWarningModel(false)}
              />
            )}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </div>
  );
};
export default DashboardLayout;
