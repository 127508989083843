import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import CustomTable from "../../../../common/CustomTable";
import { Button, Checkbox, List, Modal, Popover, Select, Space } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import jspdfHtml2canvas from "jspdf-html2canvas";
import EmployeePaySlipDownload from "../EmployeePaySlipDownload";
import { fetchSalarySheetTemplateApi } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
interface SummaryViewProps {
  searchValue?: string;
  payrollId: any;
}

const SummaryView: React.FC<SummaryViewProps> = ({
  searchValue,
  payrollId,
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredTableColumn, setFilteredTableColumn] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [modalVisisble, setModalVisisble] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedRecordData, setSelectedRecordData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [allAllowanceNames, setAllAllowanceNames] = useState<any[]>([]);
  const [salarySheet, setSalarySheet] = useState<any[]>([
    { id: "detailed", name: "Detailed Report" },
  ]);
  const [selectedSheets, setSelectedSheets] = useState<any[]>([]);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [refCounter, setRefCounter] = useState<number>(0);
  const [isDivVisible, setIsDivVisible] = useState<boolean>(false);
  const organizationName =
    useSelector(selectLogedEmployee).logedInemployee?.employee
      ?.organization_name;
  const companyName =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_name;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const payslipDataRef = useRef(null);
  useEffect(() => {
    fetchSalarySheetTemplat();
  }, []);

  useEffect(() => {
    const currentDate = dayjs().format("MMMM");
    getEmployeeSalarySummary(currentDate);
  }, []);
  useEffect(() => {
    const payslipElement = payslipDataRef.current;

    if (isDivVisible && payslipElement) {
      downloadPDFReport(selectedRecordData, payslipElement);
    }
  }, [refCounter]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (tableData) {
      const dynamicColumns = columns.filter((column: any) => {
        if (column.dataIndex) {
          return tableData.some(
            (record) =>
              record[column.dataIndex] != null &&
              record[column.dataIndex] !== "" &&
              record[column.dataIndex] !== 0 &&
              record[column.dataIndex] !== "0"
          );
        }
        return true;
      });
      setFilteredTableColumn(dynamicColumns);
    }
  }, [tableData]);

  const fetchSalarySheetTemplat = async () => {
    const data = {
      organization_Id: organizationId,
    };
    const res = await fetchSalarySheetTemplateApi(data);

    if (res.data) {
      const temp = [...salarySheet, ...res.data];
      setSalarySheet(temp);
    }
  };
  const handleCheckboxChange = (e: any, item: any) => {
    if (e.target.checked) {
      setSelectedSheets([...selectedSheets, item]);
    } else {
      setSelectedSheets(selectedSheets.filter((sheet) => sheet.id !== item.id));
    }
  };
  const calculateTotals = (data: any, keys: any) => {
    const totals: any = {};
    keys.forEach((key: any) => (totals[key] = 0));

    data.forEach((item: any) => {
      keys.forEach((key: any) => {
        const value = parseFloat((item[key] || "0").replace(/,/g, "")) || 0;
        totals[key] += value;
      });
    });

    keys.forEach((key: any) => {
      totals[key] = totals[key].toFixed(2);
      totals[key] = parseFloat(totals[key]).toLocaleString();
    });

    return totals;
  };

  function downloadExcelFile(employees: any[]) {
    const totalKeys = [
      "Base Salary",
      "Provident Fund",
      "Tax",
      "EOBI",
      "Total Allowance Claim",
      "Total Over Time",
      "Leave Deduction",
      "Total Short Hours",
      "Gross Salary",
      "Gross Deduction",
      "Net Salary",
    ];
    const totals = calculateTotals(employees, totalKeys);

    const allowanceTotalColumns: any = allAllowanceNames?.reduce(
      (acc: any, name: any) => {
        acc[name] = "";
        return acc;
      },
      {} as { [key: string]: string }
    );

    const totalRow = {
      SRNO: "Total",
      Code: "",
      Employee: "",
      Gender: "",
      "Account Name": "",
      "Account Number": "",
      "Base Salary": totals["Base Salary"],
      Currency: "",
      "Provident Fund": totals["Provident Fund"],
      "Provident Fund Percentage": "",
      Tax: totals["Tax"],
      "Tax Percentage": "",
      EOBI: totals["EOBI"],
      "Total Allowance Claim": totals["Total Allowance Claim"],
      ...allowanceTotalColumns,
      "Total Over Time": totals["Total Over Time"],
      "Leave Deduction": totals["Leave Deduction"],
      "Total Short Hours": totals["Total Short Hours"],
      "Gross Salary": totals["Gross Salary"],
      "Gross Deduction": totals["Gross Deduction"],
      "Net Salary": totals["Net Salary"],
    };

    employees.push(totalRow);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(employees);

    const headerRange = XLSX.utils.decode_range(worksheet["!ref"] as string);
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      if (!worksheet[cellAddress]) continue;
      if (!worksheet[cellAddress].s) worksheet[cellAddress].s = {};
      worksheet[cellAddress].s.font = { bold: true };
      worksheet[cellAddress].s.fill = { fgColor: { rgb: "D3D3D3" } };
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, "Employee Pays");
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const filename = `${month}-${year} Employee Pays.xlsx`;
    XLSX.writeFile(workbook, filename);
  }

  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Full Year",
  ];

  const getEmployeeSalarySummary = async (month: string) => {
    try {
      setSelectedMonth(month);
      let startDate;
      let endDate;
      const year = new Date().getFullYear();

      if (month.toLowerCase() === "full year") {
        startDate = new Date(year, 0, 1).toISOString();
        endDate = new Date(year, 11, 31, 23, 59, 59).toISOString();
      } else {
        const dayjsmonthNumber = dayjs(month, "MMMM", true);
        const monthNumber: any = dayjsmonthNumber.format("MM");
        startDate = new Date(year, monthNumber - 1, 1).toISOString();
        endDate = new Date(year, monthNumber, 0, 23, 59, 59).toISOString();
      }

      setTableLoading(true);
      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_salary_history")
        .select(
          "*,employee:employee!employee_salary_history_employee_id_fkey(full_name,joining_date, department:department_id(name),designation:designation_id(name)),company:company!employee_salary_history_company_id_fkey(name,location,email)"
        )
        .eq("organization_id", organizationId)
        .gte("created_at", startDate)
        .lte("created_at", endDate)
        .eq("payroll_id", payrollId);

      if (response.data) {
        const reutrnedData = prepareTransformedData(response.data);

        setTableData(reutrnedData.transformedArray);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: reutrnedData,
        }));
      }
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
    }
  };

  const prepareTransformedData = (employees: any) => {
    const allAllowancesNames = Array?.from(
      new Set(
        employees?.flatMap((employee: any) =>
          employee?.allowance_detail
            ? employee?.allowance_detail
              ?.map((ad: any) => ad?.name)
              .filter((name: any) => name != null)
            : []
        )
      )
    );

    setAllAllowanceNames(allAllowancesNames);

    const words = companyName
      ? companyName.split(" ")
      : organizationName.split(" ");
    const firstLetters = words?.map((word: any) => word.charAt(0)).join("");
    const transformedArray = employees?.map((item: any, index: any) => {
      const SRNO = index + 1;
      const Code = `${firstLetters}-${(index + 1).toString().padStart(3, "0")}`;

      const allowanceColumns: any = allAllowanceNames?.reduce(
        (acc: any, name: any) => {
          acc[name] = "";
          return acc;
        },
        {} as { [key: string]: string }
      );

      item.allowance_detail?.forEach((ad: any) => {
        allowanceColumns[ad?.name] = parseFloat(ad?.amount)?.toLocaleString();
      });

      return {
        SRNO,
        Code,
        Employee: item?.employee?.full_name,
        "Employee Department": item?.employee?.department?.name,
        "Employee Designation": item?.employee?.designation?.name,
        "Employee Joining Date": item?.employee?.joining_date,
        Gender: item?.gender,
        "Account Name": item?.account_title,
        "Account Number": item?.account_number,
        "Bank Name": item?.bank_name,
        "Company Name": item?.company?.name,
        "Company Email": item?.company?.email,
        "Company Location": item?.company?.location,
        "Base Salary":
          item?.base_salary && parseFloat(item?.base_salary)?.toLocaleString(),
        Currency: item?.currency,
        "Provident Fund":
          item.provident_fund_amount &&
          parseFloat(item.provident_fund_amount)?.toLocaleString(),
        "Provident Fund Percentage": item.provident_fund_percentage,
        Tax:
          item.tax &&
          parseFloat(parseFloat(item.tax)?.toFixed(2))?.toLocaleString(),
        "Tax Percentage": item.tax_percentage,
        EOBI: item.EOBI && item.EOBI?.toLocaleString(),
        "Total Allowance Claim":
          item.total_allowance_claim &&
          parseFloat(item.total_allowance_claim)?.toLocaleString(),
        ...allowanceColumns,
        "Total Over Time":
          item.total_over_time &&
          parseFloat(
            parseFloat(item.total_over_time)?.toFixed(2)
          )?.toLocaleString(),
        "Leave Deduction":
          item.leave_deduction_amount &&
          parseFloat(
            parseFloat(item.leave_deduction_amount)?.toFixed(2)
          )?.toLocaleString(),
        "Total Short Hours":
          item.total_short_hour_payable &&
          parseFloat(
            parseFloat(item.total_short_hour_payable)?.toFixed(2)
          )?.toLocaleString(),
        "Gross Salary":
          item.gross_salary && parseFloat(item.gross_salary)?.toLocaleString(),
        "Gross Deduction":
          item.gross_deduction &&
          parseFloat(item.gross_deduction)?.toLocaleString(),
        "Net Salary":
          item.net_salary && parseFloat(item.net_salary)?.toLocaleString(),
        employee_bank_details: item.employee_bank_details,
      };
    });

    return { transformedArray, allAllowanceNames };
  };

  const handleSearch = (value: any) => {
    setTableLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setTableLoading(false);
  };

  const dynamicColumns = allAllowanceNames.map((allowance) => ({
    title: allowance,
    dataIndex: allowance,
    key: allowance,
  }));

  const columns: any = [
    {
      title: "Employee",
      dataIndex: "Employee",
      key: "Employee",
      fixed: "left",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
    },
    {
      title: "Account Name",
      dataIndex: "Account Name",
      key: "Account Name",
    },
    {
      title: "Base Salary",
      dataIndex: "Base Salary",
      key: "Base Salary",
      sorter: (a: any, b: any) => a["Base Salary"] - b["Base Salary"],
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Provident Fund",
      dataIndex: "Provident Fund",
      key: "Provident Fund",
    },
    {
      title: "Tax",
      dataIndex: "Tax",
      key: "Tax",
    },
    {
      title: "EOBI",
      dataIndex: "EOBI",
      key: "EOBI",
    },
    {
      title: "Total Allowance Claim",
      dataIndex: "Total Allowance Claim",
      key: "Total Allowance Claim",
      render: (record: any) => (record ? record : "0"),
    },
    ...dynamicColumns,
    {
      title: "Total Over Time",
      dataIndex: "Total Over Time",
      key: "Total Over Time",
    },
    {
      title: "Leave Deduction",
      dataIndex: "Leave Deduction",
      key: "Leave Deduction",
    },
    {
      title: "Total Short Hours",
      dataIndex: "Total Short Hours",
      key: "Total Short Hours",
    },

    {
      title: "Gross Deduction",
      dataIndex: "Gross Deduction",
      key: "Gross Deduction",
    },
    {
      title: "Gross Salary",
      dataIndex: "Gross Salary",
      key: "Gross Salary",
    },
    {
      title: "Account Number",
      dataIndex: "Account Number",
      key: "Account Number",
    },
    {
      title: "Net Salary",
      dataIndex: "Net Salary",
      key: "Net Salary",
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_: any, record: any) => (
        <Space>
          <a onClick={() => paySlipModalVisible(record)}>
            <EyeOutlined />
          </a>
          <a
            onClick={() => {
              setRefCounter(refCounter + 1);
              setSelectedRecordData(record);
              setIsDivVisible(true);
            }}
          >
            <DownloadOutlined />
          </a>
        </Space>
      ),
    },
  ];
  const downloadPDFReport = async (data?: any, payslipElement?: any) => {
    if (payslipDataRef.current) {
      jspdfHtml2canvas(payslipDataRef.current, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scale: 2,
        },
      }).then((pdf) => {
        pdf.save(`${data.Employee} payslip.pdf`);
        if (payslipElement && document.body.contains(payslipElement)) {
          setIsDivVisible(false);
          setSelectedRecordData([]);
        }
      });
    }
  };

  const paySlipModalVisible = (data: any) => {
    setModalVisisble(true);
    setSelectedRecordData(data);
  };
  const closePaySlipModal = (data: any) => {
    setModalVisisble(false);
    setSelectedRecordData([]);
  };
  const popoverContent = (
    <div>
      <List
        dataSource={salarySheet}
        renderItem={(item) => (
          <List.Item>
            <Checkbox onChange={(e) => handleCheckboxChange(e, item)}>
              {item.name}
            </Checkbox>
          </List.Item>
        )}
      />
      <div className="salary-sheet-Download-button">
        <Button className="global-primary-btn" onClick={() => handleDownload()}>
          Download
        </Button>
      </div>
    </div>
  );
  const handleDownload = () => {
    if (selectedSheets && tableData) {
     
      selectedSheets.map((item: any) => {
        if (item.id == "detailed") {
          let modifiedTableData =  [...tableData];
          if (modifiedTableData.length > 0) {
            
            modifiedTableData = modifiedTableData.map(row => {
              const { employee_bank_details, ...rest } = row;
              return rest;
            });
            downloadExcelFile(modifiedTableData);
          }
        } else {
          downloadOtherExcel(item, item.type);
        }
      });
    }
  };

  const downloadOtherExcel = (item: any, type: string) => {
    // Extract relevant data based on options

    const dataToExport = originalData?.apiData.transformedArray
      .filter((record: any) => {
        if (
          type === "IBFT" &&
          record["Bank Name"]?.toLowerCase() !==
          record?.employee_bank_details?.bankName?.toLowerCase()
        ) {
          return true;
        }
        if (
          type === "FT" &&
          record["Bank Name"]?.toLowerCase() ===
          record?.employee_bank_details?.bankName?.toLowerCase()
        ) {
          return true;
        }
        return false;
      })
      .map((record: any) => {
        const row: any = {};
        item.options.forEach((option: any) => {
          const customlabel = option.customName || option.label
          if (option.checked == true) {
            switch (option.value) {
              case "bankAccount":
                row[customlabel] =
                  record?.employee_bank_details?.accountNumber || "";
                break;
              case "bankIban":
                row[customlabel] = record?.employee_bank_details?.iban || "";
                break;
              case "customerRefNo":
                const companyName = record?.["Company Name"] || "";
                const companyShortForm = companyName
                  .split(" ")
                  .map((word: string) => word[0])
                  .join("")
                  .toUpperCase();
                row[option.label] =
                  `${companyShortForm}${selectedMonth}${String(
                    record.SRNO
                  ).padStart(2, "0")}` || "";
                break;
              case "employeeName":
                row[customlabel] =
                  record?.employee_bank_details?.accountTitle || "";
                break;
              case "GrossSalary":
                row[customlabel] = record["Gross Salary"] || "";
                break;
              case "bankCode":
                row[customlabel] =
                  record?.employee_bank_details?.branchCode || "";
                break;
              default:
                row[option] = record[option];
            }
          }
        });
        return row;
      });

    // Create Excel file
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Name the file
    const fileName = `${item.type}_${selectedMonth}_salarysheet.xlsx`;

    // Download the file
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      <div className="report-download-button">
        <Select
          style={{ width: 200 }}
          placeholder="Select a month"
          onChange={(value) => getEmployeeSalarySummary(value)}
          value={selectedMonth}
          notFoundContent={
            <NoDataFoundIcon />
          }
        >
          {monthsArray.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>

        <div>
          <Popover
            content={popoverContent}
            title="Select Salary Sheets"
            trigger="click"
            open={popoverVisible}
            onVisibleChange={setPopoverVisible}
          >
            <Button
              icon={<DownloadOutlined />}
              className="global-primary-btn"
            ></Button>
          </Popover>
        </div>
      </div>
      <CustomTable
        dataSource={tableData}
        columns={filteredTableColumn}
        loading={tableLoading}
        scroll={{ x: 2500 }}
      />
      <Modal
        open={modalVisisble}
        onCancel={closePaySlipModal}
        width={1000}
        destroyOnClose
        footer={false}
        className="global-modal-main-container"
        title={<span className="global-modal-title">Pay Slip Preview</span>}
      >
        <div className="global-card-inner-container">
          <EmployeePaySlipDownload paySlipData={selectedRecordData} />
        </div>
      </Modal>

      <div
        ref={payslipDataRef}
        key={refCounter}
        style={{ display: isDivVisible ? "block" : "none" }}
      >
        <EmployeePaySlipDownload paySlipData={selectedRecordData} />
      </div>
    </>
  );
};

export default SummaryView;
