import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface menu_breadcrumb_type {
  menu_breadcrumb?: {
    parent: string;
    child?: string | null;
  }[];
}

interface RootState {
  menu_breadcrumb: menu_breadcrumb_type;
}

const initialState: menu_breadcrumb_type = {
  menu_breadcrumb: [
    {
      parent: "dashboard",
      child: "dashboard",
    },
    {
      parent: "organization",
      child: "Company",
    },
    {
      parent: "employee",
      child: "Employee",
    },
    {
      parent: "asset",
      child: "Assets Creation",
    },
    {
      parent: "payroll",
      child: "Dashboard",
    },
    {
      parent: "attendance",
      child: "Attendance logs",
    },
    {
      parent: "assessment",
      child: "Internship",
    },
    {
      parent: "employment",
      child: "Permanent",
    },
    {
      parent: "performance",
      child: "KPI Attributes",
    },
    {
      parent: "policies",
      child: "Deductions",
    },
    {
      parent: "recruitment",
      child: "Dashboard",
    },
    {
      parent: "My Profile",
      child: "Personal Information",
    },
    {
      parent: "settings",
      child: "Access Rights",
    },
  ],
};

export const MenuBreadcrumbSlice: any = createSlice({
  name: "menu_breadcrumb",
  initialState,
  reducers: {
    setMenuBreadcrumb: (
      state,
      action: PayloadAction<Partial<menu_breadcrumb_type>>
    ) => {
      if (action.payload.menu_breadcrumb) {
        action.payload.menu_breadcrumb.forEach((newMenuItem) => {
          if (state.menu_breadcrumb) {
            const index = state.menu_breadcrumb.findIndex(
              (item) => item.parent === newMenuItem.parent
            );

            if (index !== -1) {
              state.menu_breadcrumb[index] = {
                ...state.menu_breadcrumb[index],
                child: newMenuItem.child,
              };
            }
          }
        });
      }
    },
    setInitialBreadcrumb: (state) => {
      state.menu_breadcrumb = initialState.menu_breadcrumb;
    },
  },
});

export const { setMenuBreadcrumb, setInitialBreadcrumb } =
  MenuBreadcrumbSlice.actions;

export const selectMenuBreadcrumb = (state: RootState) => state.menu_breadcrumb;

export default MenuBreadcrumbSlice.reducer;
