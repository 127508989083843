import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getSingleAssetDataApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("asset")
      .select(
        "id,name,type,unique_identifier,manufacturer,condition,model,current_value,acquired_date"
      )
      .eq("id", data);
    return response;
  } catch {
    return catch_return;
  }
};

export const updateAssetsApi = async (
  data: any,
  recordId: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("asset")
      .update({
        name: data.assetName,
        type: data.assetType,
        manufacturer: data.manufacturer,
        condition: data.condition,
        model: data.model,
        current_value: data.currentValue,
        acquired_date: data.acquiredDate,
      })
      .eq("id", recordId);

    return response;
  } catch {
    return catch_return;
  }
};

export const createAssetsApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("asset")
      .insert(data);
    return response;
  } catch {
    return catch_return;
  }
};

export const getAllAssetsApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("asset")
      .select("id,name,type,condition,unique_identifier,manufacturer,model")
      .eq("organization_Id", data.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    if (data.searchInput) {
      query = query.ilike("name", `%${data.searchInput}%`);
    }

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const deleteAssetApi = async (recordId: any, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("asset")
      .delete()
      .eq("id", recordId);

    return response;
  } catch {
    return catch_return;
  }
};
