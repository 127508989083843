import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Button, Select, Modal } from "antd";
import { useSelector } from "react-redux";
import { getCountries, getStates, getCities } from "vk-country-state-city";
import CustomAlert from "../../../../../common/Alert";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { getEmployeeAddressAPI, updateAddressesApi } from "../services";

import axios from "axios";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  visible: boolean;
  oncancel: () => void;
  selectedRecordId: any;
  isLoading: boolean;
}

const EditEmployeeAddress: React.FC<props> = ({
  visible,
  oncancel,
  selectedRecordId,
}) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [permenantCountries, setPermenantCountries] = useState<string[]>([]);
  const [permenantStates, setPermenantStates] = useState<string[]>([]);
  const [permenantCities, setPermenantCities] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedPermenantCountry, setselectedPermenantCountry] =
    useState<string>("");
  const [selectedPermanentState, setselectedPermanentState] =
    useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [countrySelectLoading, setCountrySelectLoading] =
    useState<boolean>(false);
  const [stateSelectLoading, setStateSelectLoading] = useState<boolean>(false);
  const [citySelectLoading, setCitySelectLoading] = useState<boolean>(false);

  const { Option } = Select;
  const [form] = useForm();
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;

  const organization_id = loggedInEmployee?.employee?.organization_id;
  const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY;

  useEffect(() => {
    async function fetchCountries() {
      setCountrySelectLoading(true);
      const countries = await getCountries();
      setCountries(countries);
      setPermenantCountries(countries);
      setCountrySelectLoading(false);
    }
    fetchCountries();
    getDetailsAgainstRecord();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      if (selectedCountry) {
        setStateSelectLoading(true);
        const states = await getStates(selectedCountry);
        setStates(states);

        setStateSelectLoading(false);
      }
      if (selectedPermenantCountry) {
        const permenanetStates = await getStates(selectedPermenantCountry);
        setPermenantStates(permenanetStates);
      }
    }
    if (selectedCountry) fetchStates();
  }, [selectedCountry, selectedPermenantCountry]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedState) {
        setCitySelectLoading(true);
        const cities = await getCities(selectedCountry, selectedState);
        setCities(cities);

        setCitySelectLoading(false);
      }
      if (selectedPermanentState) {
        const permenantCities = await getCities(
          selectedPermenantCountry,
          selectedPermanentState
        );
        setPermenantCities(permenantCities);
      }
    }
    if (selectedState) fetchCities();
  }, [
    selectedState,
    selectedCountry,
    selectedPermenantCountry,
    selectedPermanentState,
  ]);

  const handleSubmit = async (values: any) => {
    setButtonLoading(true);

    const response = await getGeoCode();

    const body = {
      present_address_detail: {
        present_street: form.getFieldValue("present_street"),
        present_city: form.getFieldValue("present_city"),
        present_postcode: form.getFieldValue("present_postcode"),
        present_state: form.getFieldValue("present_state"),
        present_country: form.getFieldValue("present_country"),
        geocoordinates: [response.lat, response.lon],
      },
      permanent_address_detail: {
        permanent_street: form.getFieldValue("permanent_street"),
        permanent_city: form.getFieldValue("permanent_city"),
        permanent_postcode: form.getFieldValue("permanent_postcode"),
        permanent_state: form.getFieldValue("permanent_state"),
        permanent_country: form.getFieldValue("permanent_country"),
      },
      employee_id: loggedInEmployee?.employeeId,
      organization_id: loggedInEmployee?.employee?.organization_id,
    };
    const res = await updateAddressesApi(
      body,
      organization_id,
      selectedRecordId
    );

    if (res.status === 204) {
      setshowAlert(true);
      setAlertType("success");
      form.resetFields();
      oncancel();
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };

  const getDetailsAgainstRecord = async () => {
    const response = await getEmployeeAddressAPI(
      selectedRecordId,
      organization_id
    );
    if (response.data) {
      form.setFieldsValue({
        employeeid: response.data.employee?.full_name,
        present_country: response.data?.present_address_detail?.present_country,
        present_state: response.data?.present_address_detail?.present_state,
        present_city: response.data?.present_address_detail?.present_city,
        present_street: response.data?.present_address_detail?.present_street,
        present_postcode:
          response.data?.present_address_detail?.present_postcode,
        permanent_street:
          response.data?.permanent_address_detail?.permanent_street,
        permanent_postcode:
          response.data?.permanent_address_detail?.permanent_postcode,
        permanent_state:
          response.data?.permanent_address_detail?.permanent_state,
        permanent_city: response.data?.permanent_address_detail?.permanent_city,
        permanent_country:
          response.data.permanent_address_detail?.permanent_country,
      });
      setSelectedCountry(
        response.data?.present_address_detail?.present_country
      );
      setSelectedState(response.data?.present_address_detail?.present_state);
      setselectedPermenantCountry(
        response.data.permanent_address_detail?.permanent_country
      );
      setselectedPermanentState(
        response.data?.permanent_address_detail?.permanent_state
      );
    }
  };

  const getGeoCode = async () => {
    const params = {
      q: `${form.getFieldValue("present_postcode")}, Pakistan`,
      format: "json",
      key: API_KEY,
    };
    const response = await axios.get("https://us1.locationiq.com/v1/search", {
      params,
    });

    if (response && response.data) {
      return response.data[0];
    }
  };
  return (
    <Modal
      open={visible}
      onCancel={oncancel}
      width={1000}
      title={<span>Edit Address</span>}
      footer={null}
    >
      <div className="global-card-inner-container">
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter={[16, 16]}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <Form.Item
                name="employeeid"
                label="Employee Name"
                rules={[
                  { required: true, message: "Please select an employee!" },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row className="permanent-heading">
            <span className="permanent-address-heading">Present Address</span>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="present_country"
                label="Country"
                rules={[
                  { required: true, message: "Please select a country!" },
                ]}
              >
                <Select
                  loading={countrySelectLoading}
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select Country"
                  onChange={(value) => setSelectedCountry(value)}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {countries.map((item: any) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="present_state"
                label="State/Province"
                rules={[{ required: true, message: "Please select a state!" }]}
              >
                <Select
                  loading={stateSelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Select State"
                  onChange={(value) => setSelectedState(value)}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {states.map((item: any) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="present_city"
                label="City"
                rules={[{ required: true, message: "Please select a city!" }]}
              >
                <Select
                  loading={citySelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Select City"
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {cities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="present_street"
                label="Street"
                rules={[{ required: true, message: "Please enter street!" }]}
              >
                <Input size="large" placeholder="Enter Street" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="present_postcode"
                label="Zip/Postal Code"
                rules={[
                  { required: true, message: "Please enter postal code!" },
                ]}
              >
                <Input size="large" placeholder="Enter Zip/Postal Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="permanent-heading">
            <span className="permanent-address-heading">Permanent Address</span>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="permanent_country"
                label="Country"
                rules={[
                  { required: true, message: "Please select a country!" },
                ]}
              >
                <Select
                  loading={countrySelectLoading}
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Select Country"
                  onChange={setselectedPermenantCountry}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {permenantCountries?.map((item: any) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="permanent_state"
                label="State/Province"
                rules={[{ required: true, message: "Please select a state!" }]}
              >
                <Select
                  loading={stateSelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Select State"
                  onChange={(value) => setselectedPermanentState(value)}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {permenantStates?.map((item: any) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="permanent_city"
                label="City"
                rules={[{ required: true, message: "Please select a city!" }]}
              >
                <Select
                  loading={citySelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Select City"
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {permenantCities?.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="permanent_street"
                label="Street"
                rules={[{ required: true, message: "Please enter street!" }]}
              >
                <Input size="large" placeholder="Enter Street" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="permanent_postcode"
                label="Zip/Postal Code"
                rules={[
                  { required: true, message: "Please enter postal code!" },
                ]}
              >
                <Input size="large" placeholder="Enter Zip/Postal Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="global-modal-bottom-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                  loading={buttonLoading}
                  size="large"
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </Modal>
  );
};

export default EditEmployeeAddress;
