import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { featchEmployeeByDesignationIdApi } from "../services";

import CustomAlert from "../../../../common/Alert";
import { setsalaryProveConfigurationApi } from "../../services";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface SalaryProveConfigurationsEditprops {
  record: any;
  onCancel: () => void;
}

const SalaryProveConfigurationsEdit: React.FC<
  SalaryProveConfigurationsEditprops
> = (props) => {
  const [form] = Form.useForm();
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredEmployee, setFilteredEmployee] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [originalEmployeeIds, setOriginalEmployeeIds] = useState<string[]>([]); // Store original employee IDs

  const organizationId = logedInemployee?.employee.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    featchEmployeeByDesignationId(
      props.record.company_id,
      props.record.branch_id
    );
    const temp_empl = props.record.employees.map(
      (item: any) => item.employee_id
    );
    setOriginalEmployeeIds(temp_empl);
    form.setFieldValue("employeeId", temp_empl);
  }, [props.record]);

  const featchEmployeeByDesignationId = async (
    companyId: string,
    branchId: string
  ) => {
    const data = {
      organizationId: logedInemployee?.employee.organization_id,
      selectedCompanyId: companyId,
      selectedBranchId: branchId,
    };
    const res = await featchEmployeeByDesignationIdApi(data);
    if (res.data) {
      const boption = res.data?.map((item: any) => ({
        id: item.id,
        label: item.full_name,
      }));
      setFilteredEmployee(boption);
    }
  };

  const onFinish = async (values: any) => {
    const updatedEmployeeIds = values.employeeId;

    // Find employees to add
    const employeesToAdd = updatedEmployeeIds.filter(
      (id: string) => !originalEmployeeIds.includes(id)
    );

    // Find employees to remove
    const employeesToRemove = originalEmployeeIds?.filter(
      (id: string) => !updatedEmployeeIds.includes(id)
    );

    setshowAlert(false);

    let deletionMessages = [];

    // Remove employees
    if (employeesToRemove.length > 0) {
      for (const employeeId of employeesToRemove) {
        const employee = props.record.employees?.find(
          (item: any) => item.employee_id === employeeId
        );
        const id = employee?.id;

        const res = await supabase_client_for_Org_schema(organizationId)
          .from("salary_prove_configuration")
          .delete()
          .eq("id", id);

        if (res.error) {
          deletionMessages.push(
            `Failed to delete employee with ID: ${employeeId}`
          );
        } else {
          deletionMessages.push(
            `Successfully deleted employee with ID: ${employeeId}`
          );
        }
      }
    }
    // Add employees
    if (employeesToAdd.length > 0) {
      const employee_list = employeesToAdd.map((employeeId: string) => ({
        employee_id: employeeId,
        organization_Id: logedInemployee?.employee.organization_id || null,
        company_id: props.record.company_id || null,
        branch_id: props.record.branch_id || null,
      }));
      const res = await setsalaryProveConfigurationApi(employee_list);

      if (res.status !== 201 && res.status !== 204) {
        setshowAlert(true);
        setAlertMessage("");
        setAlertDescription(
          res.error?.message ||
            "oops we are facing some temporary issue with your request"
        );
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
        return;
      }
    }
    // Handle successful updates or deletions
    const alertDescription =
      deletionMessages.length > 0
        ? deletionMessages.join("\n") + "\nDetails Updated Successfully"
        : "Details Updated Successfully";

    setshowAlert(true);
    setAlertMessage("");
    setAlertDescription(alertDescription);
    setAlertType("success");
    timeoutRef.current = setTimeout(() => {
      setshowAlert(false);
      props.onCancel();
    }, 3000);
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row>
            <Col className="modal-content">
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Employee Employee"
                    name="employeeId"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Employee",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a Employee"
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      mode="multiple"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {filteredEmployee.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="global-modal-bottom-row salary-prove-configurations-edit-container-last-row">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                size="large"
              >
                Update
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          description={alertDescription}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default SalaryProveConfigurationsEdit;
