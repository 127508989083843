import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches Certificates details from the Supabase API based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, branchId, and searchQuery.
 * @returns {Promise<any>} A promise that resolves to the bank details fetched from the API.
 */
export const fetchCertificaionsDetailsApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_certificate")
      .select(`*, employee(full_name)`)
      .eq("organization_id", data?.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchQuery) {
      query = query.ilike("employee.full_name", `%${data.searchQuery}%`);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes Certificates details from the database by the specified ID.
 * @param {any} id - The ID of the Certificates details to be deleted.
 * @returns {Promise<any>} A promise that resolves to the result of the deletion operation.
 */
export const deleteCertificaionsByIdApi = async (
  id: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_certificate")
      .delete()
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts Certificates details into the "Certificates" table in the Supabase database.
 * @param {any} data - The Certificates details to be inserted.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertCertificaionsApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_certificate")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches Certificates details by ID from the Supabase database.
 * @param {any} id - The ID of the Certificates details to fetch.
 * @returns {Promise<any>} A promise that resolves to the bank details object.
 */
export const fetchCertificaionsbyIdApi = async (id: any): Promise<any> => {
  try {
    let query = supabase
      .from("employee_certificate")
      .select(`*, employee(full_name)`)
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateCertificaionsbyIdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_certificate")
      .update(data?.updatedData)
      .eq("id", data?.id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchAllEmployeeWithOutCertificaionsApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    if (res.data) {
      const EmergencyContactres = await supabase_client_for_Org_schema(
        data.organizationId
      )
        .from("employee_certificate")
        .select("employee_id");
      const employeeIdsWithSalary = new Set(
        EmergencyContactres?.data?.map((item: any) => item.employee_id)
      );
      const employeeswithoutsalary = res.data
        ?.filter((employee: any) => !employeeIdsWithSalary.has(employee.id))
        .map((employee: any) => ({
          value: employee.id,
          label: employee.full_name,
        }));
      return employeeswithoutsalary;
    }
    return [];
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts an employee attachment into the Supabase storage.
 * @param {any} data - The data object containing the file path and file to upload.
 * @returns {Promise<any>} A promise that resolves to the public URL of the uploaded file.
 * @throws {any} Throws an error if the upload fails.
 */
export const insertCertificatesAttachmentsApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("CertificatesAttachments")
      .upload(data.filePath, data.file);

    if (res.error) {
      throw res.error;
    }
    const publicURL = supabase.storage
      .from("CertificatesAttachments")
      .getPublicUrl(data.filePath);
    return publicURL;
  } catch (error) {
    return catch_return;
  }
};

export const getAllEmployeesAPI = async (
  organization_id: any,
  branch_id: any,
  company_id: any
) => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", organization_id);

    if (company_id) {
      query = query.eq("company_id", company_id);
    }

    if (branch_id) {
      query = query.eq("branch_id", branch_id);
    }

    const response = await query;
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
