import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getAllAttendanceDataApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_attendance")
      .select(
        `*, employee!employee_attendance_employee_id_fkey(full_name, avatar_url, cnic, id), company! employee_attendance_company_id_fkey(name, unique_identifier)`
      )
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    if (data.status) {
      query = query.eq("check_in_status", data.status);
    }

    if (data.date) {
      const localStartDate = new Date(data.date);
      localStartDate.setHours(0, 0, 0, 0);
      const utcStartDate = new Date(
        localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000
      );
      const localEndDate = new Date(data.date);
      localEndDate.setHours(23, 59, 59, 999);
      const utcEndDate = new Date(
        localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000
      );

      query = query
        .gte("date", utcStartDate.toISOString())
        .lte("date", utcEndDate.toISOString());
    }

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const getAllEmployeeDataApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select(`full_name, avatar_url, cnic, id`)
      .eq("organization_id", data.organizationId)
      .ilike("status", "active");

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    const response = await query;

    return response;
  } catch {
    return catch_return;
  }
};

export const updateEmployeeAttendanceAaccommodateDataApi = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .update(data.body)
      .eq("id", data.id);
    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getCurrentAttendanceDataByEmployeeIdApi = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_attendance")
      .select(
        `*, employee!employee_attendance_employee_id_fkey(full_name, avatar_url, cnic, id), 
         company!employee_attendance_company_id_fkey(name, unique_identifier)`
      )
      .eq("organization_id", data.organizationId)
      .eq("employee_id", data.employeeId)
      .gte("date", firstDayOfMonth.toISOString())
      .lt(
        "date",
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        ).toISOString()
      );

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchPunhesData = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("attendance_punch")
      .select(
        `id,date,type,time,from, employee!attendance_punch_employee_id_fkey(full_name, avatar_url, id)`
      )
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    if (data.date) {
      const localStartDate = new Date(data.date);
      localStartDate.setHours(0, 0, 0, 0);
      const utcStartDate = new Date(
        localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000
      );
      const localEndDate = new Date(data.date);
      localEndDate.setHours(23, 59, 59, 999);
      const utcEndDate = new Date(
        localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000
      );

      query = query
        .gte("date", utcStartDate.toISOString())
        .lte("date", utcEndDate.toISOString());
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};
