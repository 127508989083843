import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchDesignationsApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("designation")
      .select(`id, name`)
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchReportToEmployeeApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(`id,  full_name , department!employee_department_id_fkey(name)`)
      .eq("organization_id", data.organizationId)
      .neq("id", data.employeeId);
    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }
    if (data.employeeDesignation) {
      query.eq("designation_id", data.employeeDesignation);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const fetchJobTitleApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("job_title")
      .select(`id,name, company(name)`)
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateEmployeeApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .update(data.body)
      .eq("id", data.id)
      .select()
      .single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
