import { catch_return } from "../../../common/Constants";
import {
  supabase_client_for_Org_schema,
  supabase_client_for_operation_portal_schema,
} from "../../../services/supabase";

export const insertAttandanceMachineConfigurationApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("attendance_machine_configuration")
      .insert(data);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
export const updateAttandanceMachineConfigurationApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("attendance_machine_configuration")
      .update(data)
      .eq("id", data.id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateAttandanceMachineStatusApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("attendance_machine_configuration")
      .update(data)
      .eq("id", data.id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
export const updateAttandanceMachineEmployeesApi = async (
  data: any,
  id: number
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("attendance_machine_configuration")
      .update(data)
      .eq("id", id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAttandanceMachineConfigurationApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_operation_portal_schema
      .from("attendance_machine_configuration")
      .select("*")
      .eq("organization_id", data.organizationId);
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEmployeesDataApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee")
      .select("id,full_name")
      .eq("organization_id", data.organization_id);
    if (data.company_id) {
      query = query.eq("company_id", data.company_id);
    }
    if (data.branch_id) {
      query = query.eq("branch_id", data.branch_id);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
