import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row } from "antd";

import { FaBusinessTime } from "react-icons/fa";

import settings from "../../../assets/images/Settings.png";
import { Link } from "react-router-dom";
import Help from "../../../common/Help/Layout";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import UserProfileUpdate from "../../../common/UserProfileUpdate";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
interface MenuItem {
  label: string;
  icon?: JSX.Element;
  submenus?: { label: React.ReactNode }[];
}

const EmployeeSettings = () => {
  const activeTabMenu = useSelector((state: any) => state?.help?.variable);
  const [activeSetting, setActiveSetting] = useState<string>("organization");
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;

  useEffect(() => {
    setActiveSetting(activeTabMenu);
  }, [activeTabMenu]);
 
  const menuData: { [key: string]: MenuItem[] } = {
    organization: [
      {
        label: "Time off Settings",
        icon: <FaBusinessTime />,
        submenus: [
          {
            label: (
              <Link
                to={`/employee/leave/request`}
                className="link-style"
              >
                Leave Request
              </Link>
            ),
          },
          {
            label: (
              <Link
                to={`/employee/policies/leaves`}
                className="link-style"
              >
                Leave Policies
              </Link>
            ),
          },
        ],
      },
      {
        label: "Submitted Complaints",
        icon: <LiaMoneyCheckSolid />,
        submenus: [
          {
            label: (
              <Link
                to={`/employee/complaints`}
                className="link-style"
              >
                Complaints
              </Link>
            ),
          },
        ],
      },
    ],
    profile: [],
    help: [],
  };

  const handleSettingChange = (setting: string) => {
    setActiveSetting(setting);
  };

  const renderSubmenus = (submenus: { label: React.ReactNode }[]) => {
    return submenus.map((submenu, index) => (
      <p key={index}> {submenu.label} </p>
    ));
  };

  const renderSettingsContent = () => {
    const activeMenuItems = menuData[activeSetting];
    if (!activeMenuItems) {
      return null;
    }
    return (
      <>
        {activeSetting == "organization" && (
          <div className="settings">
            <Row>
              <Col md={6} lg={8} xl={17}>
                {activeMenuItems.map((menuItem) => (
                  <div key={menuItem.label}>
                    <h6 className="settings-menu-label">
                      <span className="settings-menu-icon">
                        {" "}
                        {menuItem.icon}{" "}
                      </span>
                      {menuItem.label}
                    </h6>
                    <text className="settings-menu-items">
                      {menuItem.submenus && renderSubmenus(menuItem.submenus)}
                    </text>
                  </div>
                ))}
              </Col>
              <Col md={4} lg={6} xl={6}>
                <div className="settings-image-container">
                  <img
                    src={settings}
                    alt="settings"
                    className="settings-image"
                  />
                </div>
              </Col>
            </Row>{" "}
          </div>
        )}
        {activeSetting == "help" && <Help />}
        {activeSetting == "profile" && <UserProfileUpdate />}
      </>
    );
  };

  return (
    <>
      <div className="Settings-card dasboard-cards">
        <div className="settings-header">
          <button
            className={`internal-button ${
              activeSetting === "organization" ? "active" : ""
            } settings-button`}
            onClick={() => handleSettingChange("organization")}
          >
            Settings
          </button>
          <button
            className={`internal-button ${
              activeSetting === "profile" ? "active" : ""
            } settings-button`}
            onClick={() => handleSettingChange("profile")}
          >
            Profile Settings
          </button>
          <button
            className={`internal-button ${
              activeSetting === "help" ? "active" : ""
            } settings-button`}
            onClick={() => handleSettingChange("help")}
          >
            Help and Support
          </button>
          <hr className="header-line-settings" />
        </div>
        <div className="settings-content">{renderSettingsContent()}</div>
      </div>
    </>
  );
};

export default EmployeeSettings;
