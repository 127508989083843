import { Col, Input, Popconfirm, Row, Space, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import { DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "./index.css";
import { IoCheckmarkDone } from "react-icons/io5";
import CustomAlert from "../../../common/Alert";
import ApplicationSubmissionViewModal from "../ApplicationSubmissionViewModal";
import {
  downloadFilesFromSupabase,
  handleApplicationAcceptance,
  handleResumeDeletionLogic,
  jobApplicationDeletionLogic,
  jobDetailsFetching,
} from "../services";
import CustomTable from "../../../common/CustomTable";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

const ShortlistedCandidates = () => {
  const [jobApplications, setJobApplications] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [showalert, setShowalert] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [applicantId, setApplicantId] = useState<number>(0);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const getTheId = window.location.href;
  const splitTheId = getTheId.split("/");
  const id = splitTheId.length - 1;
  const JobId = splitTheId[id];

  const hideViewApplicationModal = () => {
    setApplicationModalVisible(false);
  };
  const showViewApplicationModal = (id: any) => {
    setApplicantId(id);
    setApplicationModalVisible(true);
  };

  useEffect(() => {
    getJobDetails();
  }, [JobId]);

  useEffect(() => {
    if (showalert) {
      setTimeout(() => {
        setShowalert(false);
      }, 3000);
    }
  }, [showalert]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: any) =>
        record?.email && <span className="email-column">{record?.email}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => (
        <Space size={"middle"}>
          <Tooltip title="Download Resume">
            <FaFileDownload
              onClick={() => handleDownloadResume(record.resume_url)}
            />{" "}
          </Tooltip>
          <Tooltip title="View Application Details">
            <EyeOutlined onClick={() => showViewApplicationModal(record.id)} />{" "}
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            okText="Ok"
            cancelText="Cancel"
            title="Are you sure you want to delete this Job?"
            onConfirm={() => handleDeleteResume(record)}
          >
            <DeleteOutlined />
          </Popconfirm>
          <Tooltip title="Click to accept application">
            {" "}
            <IoCheckmarkDone
              onClick={() => handleApplicationAcceptancee(record.id)}
            />{" "}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getJobDetails = async () => {
    setIsLoading(true);

    const finalResponse = await jobDetailsFetching(
      JobId,
      searchInput,
      organizationId
    );

    if (finalResponse.data) {
      setJobApplications(finalResponse.data);
      setIsLoading(false);
    }
  };

  function downloadBlob(blob: any, fileName: any) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleDownloadResume = async (resumeURL: any) => {
    const splittedResume = resumeURL.split("/");
    const indexOfLastValue = splittedResume.length - 1;
    const lastValue = splittedResume[indexOfLastValue];
    const response = await downloadFilesFromSupabase(lastValue);
    if (response.data instanceof Blob) {
      downloadBlob(response.data, lastValue);
    }
  };

  const handleApplicationAcceptancee = async (recordId: any) => {
    const response = await handleApplicationAcceptance(
      recordId,
      organizationId
    );
    if (response.status == 201 || response.status == 204) {
      setShowalert(true);
      setAlertType("success");
      getJobDetails();
    }
  };
  const handleDeleteResume = async (record: any) => {
    const splitURL = record?.resume_url?.split("/");
    const getLastIndex = splitURL.length - 1;
    const finalFileName = splitURL[getLastIndex];
    const decodedFileName = decodeURIComponent(finalFileName);
    const finalResponse = await handleResumeDeletionLogic(decodedFileName);
    if (finalResponse.error) {
      setShowalert(true);
      setAlertType("error");
    } else {
      const response = await jobApplicationDeletionLogic(
        record.id,
        organizationId
      );

      if (response.status == 201 || response.status == 204) {
        setShowalert(true);
        setAlertType("success");
        getJobDetails();
      }
    }
  };

  return (
    <>
      <div className="recruitment-shortlisted-card">
        <div className="recruitment-shortlisted-card-header">
          <span className="recruitment-shortlisted-card-title">Shortlisted Candidates</span>
          <Input
            placeholder="Enter candidate name..."
            value={searchInput}
            size="large"
            onChange={(e) => setSearchInput(e.target.value)}
            onPressEnter={getJobDetails}
            prefix={<SearchOutlined />}
            className="recruitment-shortlisted-card-search"
          />
        </div>
        <CustomTable
          columns={columns}
          dataSource={jobApplications}
          loading={isLoading}
        />
      </div>
      {showalert && <CustomAlert type={alertType} showAlert={showalert} />}
      <ApplicationSubmissionViewModal
        id={applicantId}
        visible={applicationModalVisible}
        onClose={hideViewApplicationModal}
      />
    </>
  );
};

export default ShortlistedCandidates;
