import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//-----------------------------------Create Branch--------------------------------------------
export const companyOfBranch = async (Id: any, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("company")
      .select("email")
      .eq("id", Id)
      .maybeSingle();

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const branchManagerNames = async (organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", organizationId)
      .eq("status", "active");

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getCompanies = async (Ids: any) => {
  try {
    const response = await supabase
      .from("company")
      .select("id, name")
      .eq("organization_Id", Ids.organizationId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const insertIntoBranchesTable = async (payload: any, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .insert(payload)
      .select("id");
    return response;
  } catch (error) {
    return catch_return;
  }
};
interface insertIntoAccessRightsTableType {
  company_id: string | null;
  access_rights: any[];
  group: string;
  organization_Id: string | null | undefined;
  branch_id: string;
}

//--------------------------------------------View Branch Details---------------------------------
export const getBranches = async (Ids: any, org_Id: string) => {
  try {
    let query = supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .select("id, name")
      .eq("company_id", Ids.companyId);

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getTheTableData = async (Ids: any, org_Id: string) => {
  try {
    let query = supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .select(
        `*,employee!branch_head_manager_id_fkey(full_name), company!branch_company_id_fkey(name)`
      )
      .eq("organization_Id", Ids.organizationId);

    if (Ids.companyId) {
      query = query.eq("company_id", Ids.companyId);
    }
    if (Ids.searchQuery) {
      query = query.ilike("unique_identifier", `%${Ids.searchQuery}%`);
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleDelete = async (id: string, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//--------------------------------------------Edit Branch Details--------------------------------

export const getCompany = async (data: string, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("company")
      .select("email")
      .eq("id", data)
      .maybeSingle();

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getManagerNames = async (data: string) => {
  try {
    const response = await supabase_client_for_Org_schema(data)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getOrganizationCompanies = async (data: string) => {
  try {
    const response = await supabase_client_for_Org_schema(data)
      .from("company")
      .select("id, name")
      .eq("organization_Id", data);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getBranchesBasedOnCompanies = async (id: any, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .select("*")
      .eq("id", id)
      .maybeSingle();

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleSaveRecords = async (data: any, org_Id: string) => {
  try {
    const response = await supabase_client_for_Org_schema(org_Id)
      .from("branch")
      .update(data.body)
      .eq("id", data.id);

    return response;
  } catch (error) {
    return catch_return;
  }
};
