import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchCanlderDataApi = async (
  orgid: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("event_scheduler")
      .select("*")
      .eq("organization_id", orgid)
      .order("start_date_and_time", { ascending: false });
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
