import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Modal,
  Empty,
  Space,
  Tag,
  Dropdown,
  Menu,
} from "antd";
import "./index.css";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomTable from "../../../../common/CustomTable";
import AttendanceMachineCreateView from "../Create";
import {
  fetchAttandanceMachineConfigurationApi,
  updateAttandanceMachineStatusApi,
} from "../services";
import EditAttendanceMachineIntegeration from "../Edit";
import CustomAlert from "../../../../common/Alert";
import AddEmployeesAttendanceMachine from "../AddEmployees";
import { VscLayersActive } from "react-icons/vsc";

interface EditAttendanceMachineIntegerationProps {
  company_id: number;
  branch_id: number;
  employee_id: [
    {
      branch: number;
      company: number;
      employee_id: number;
      id: number;
      machineUserId: string;
    }
  ];
  id: number;
  ip: string;

  last_time_update: any;
  location_name: string;

  machine_id: string;
  machine_status: boolean;

  organization_id: number;
  port: string;
  password?: string;
  status_codes: {
    "check-in": string;
    "check-out": string;
    "break-in": string;
    "break-out": string;
  };
}

const ViewMachineIntergration = () => {
  const [tableData, setTableData] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [addEmployeeModalVisible, setAddEmployeeModalVisible] =
    useState<boolean>(false);
  const [selectedDataAddEmployeeModal, setSelectedDataAddEmployeeModal] =
    useState<any>();
  const [selectedRecord, setSelectedRecord] =
    useState<EditAttendanceMachineIntegerationProps | null>(null);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee.organization_id;
  const companyId = loggedInEmployee.logedInemployee?.employee?.company_id;
  const branchId = loggedInEmployee.logedInemployee?.employee?.branch_id;
  const read = true;

  useEffect(() => {
    fetchTabledata();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchTabledata = async () => {
    setLoading(true);
    const data = {
      organizationId: organizationId,
      companyId: companyId,
      branchId: branchId,
    };
    const res = await fetchAttandanceMachineConfigurationApi(data);
    if (res.data) {
      setTableData(res.data);
    }
    setLoading(false);
  };
  const getRecordId = async (value: any) => {
    try {
      if (value) {
        const body = {
          id: value.id,
          machine_status: !value.machine_status,
        };

        const res = await updateAttandanceMachineStatusApi(body);
        if (res.status == 204) {
          setAlertType("success");
          setshowAlert(true);
          fetchTabledata();
        } else {
          setAlertType("error");
          setshowAlert(true);
        }
      }
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  const columns: any = [
    {
      title: "Machine Id",
      dataIndex: "machine_id",
      key: "machine_id",
    },
    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
    },
    {
      title: "Ip",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Status",
      dataIndex: "machine_status",
      key: "machine_status",
      render: (record: any) =>
        record == true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">InActive</Tag>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  onClick={() => hanldeEditModalVisible(record)}
                >
                  <EditOutlined /> Edit
                </Menu.Item>
                <Menu.Item
                  key="addEmployee"
                  onClick={() => hanldeAddEmployeeModalVisible(record)}
                >
                  <PlusCircleOutlined /> Add Employee
                </Menu.Item>

                {record.machine_status == true && (
                  <Menu.Item key="delete">
                    <a onClick={() => getRecordId(record)}>
                      <DeleteOutlined /> InActive Machine
                    </a>
                  </Menu.Item>
                )}
                {record.machine_status == false && (
                  <Menu.Item key="delete">
                    <a onClick={() => getRecordId(record)}>
                      <VscLayersActive /> Active Machine
                    </a>
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const hanldeEditModalVisible = (data: any) => {
    setSelectedRecord(data);
    setEditModalVisible(true);
  };
  const hanldeEditModalClose = () => {
    setSelectedRecord(null);
    setEditModalVisible(false);
    fetchTabledata();
  };
  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const hanldeAddEmployeeModalVisible = (data: any) => {
    setAddEmployeeModalVisible(true);
    setSelectedDataAddEmployeeModal({
      id: data.id,
      organization_id: data.organization_id,
      company_id: data.company_id,
      branch_id: data.branch_id,
      employees: data.employee_id,
      ip: data.ip,
      port: data.port,
    });
  };
  const hanldeAddEmployeeModalCLose = () => {
    setAddEmployeeModalVisible(false);
    setSelectedDataAddEmployeeModal(null);
    fetchTabledata();
  };

  const onCnancelCreateModal = () => {
    setCreateModalVisible(false);
    fetchTabledata();
  };

  return (
    <>
      <Row>
        <Col span={24} className="globel-justify-right machine-create-button">
          <Button
            type="primary"
            onClick={handleCreateClick}
            icon={
              <PlusCircleOutlined className="attendance-machine-creation-icon" />
            }
          >
            Create
          </Button>
        </Col>
      </Row>
      {read ? (
        <CustomTable
          dataSource={tableData}
          columns={columns}
          loading={loading}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}

      <Modal
        title="Create Attendance Machine"
        open={createModalVisible}
        onCancel={onCnancelCreateModal}
        width={1000}
        footer={false}
      >
        <AttendanceMachineCreateView />
      </Modal>
      <Modal
        title={<span className="global-modal-title">Edit Details</span>}
        open={editModalVisible}
        onCancel={hanldeEditModalClose}
        width={1000}
        footer={false}
        destroyOnClose
        className="global-modal-main-container"
      >
        {selectedRecord && (
          <EditAttendanceMachineIntegeration
            {...selectedRecord}
            hanldeEditModalClose={hanldeEditModalClose}
          />
        )}
      </Modal>

      <Modal
        title={<span className="global-modal-title">Add Employees</span>}
        open={addEmployeeModalVisible}
        onCancel={hanldeAddEmployeeModalCLose}
        width={1000}
        footer={false}
        destroyOnClose
        className="global-modal-main-container"
      >
        <AddEmployeesAttendanceMachine
          data={selectedDataAddEmployeeModal}
          hanldeAddEmployeeModalCLose={hanldeAddEmployeeModalCLose}
        />
      </Modal>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default ViewMachineIntergration;
