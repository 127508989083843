import { supabase_client_for_Org_schema } from "../../../services/supabase";

const catch_return = {
    data: null,
    error: {
      message: "oops we are facing some temporary issue with your request",
      status: "custom_400",
    },
  };
  export const getLeavesApprovalEmployeeIds = async (data: any): Promise<any> => {
    try {
      let query =  supabase_client_for_Org_schema(data.orgainzationId)
      .from("employee_leave_request")
      .select("*,employee(first_name,avatar_url)")

      if(data.companyId){
        query = query.eq("company_id",data.companyId)
      }
      if(data.branchId){
        query = query.eq("branch_id",data.branchId)

      }
      const res = await query

      return res;
    } catch {
      return catch_return;
    }
  };  