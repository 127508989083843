import React, { useEffect, useState } from "react";
import { Card, Row, Col, DatePicker, Divider, Avatar, Tag } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import moment from "moment";
import d_dp from "../../../../assets/images/blankProfile.png";
import { getAllAttendanceData } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { ProgressLoader } from "../../../../common/ReactLoader";
import dayjs from "dayjs";
import { FaStar } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { generateFormatedId } from "../../../../common/validation";
import supabase from "../../../../services/supabase";

const EarlyRisers = () => {
  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [earlyRisers, setEarlyRisers] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  useEffect(() => {
    getAttendanceData();
  }, [selectedDate, organizationId]);

  const getAttendanceData = async () => {
    setLoadingState(true);
    setEmployeeData([]);
    const data = {
      organizationId,
      companyId,
      branchId,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      status: "early",
    };
    const response = await getAllAttendanceData(data);
    if (response && response.data) {
      setEarlyRisers(response.data);
      setLoadingState(false);
    }
  };
  const parseShortHours = (shortHours: any) => {
    if (typeof shortHours !== "string" || !shortHours.includes(":")) {
      return 0;
    }
    const [hours, minutes] = shortHours.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const formatMinutesToHours = (minutes: any) => {
    if (isNaN(minutes)) {
      return "0h 0m";
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours > 0 ? `${hours}h ` : ""}${remainingMinutes}m`;
  };

  const create_table = async (tableName: any) => {
    const query = `CREATE TABLE IF NOT EXISTS ${tableName}`;
    const { data, error } = await supabase.rpc("run_sql", { query });
    if (error) {
    } else {
    }
  };

  return (
    <Card
      className="main-early-riser-container dasboard-cards"
      title={
        <div className="early-riser-title-div">
          <span className="early-riser-title">Early Risers</span>
          <DatePicker
            defaultValue={dayjs(selectedDate)}
            onChange={(date, dateString) => {
              const newDate = date ? dateString : moment().format("YYYY-MM-DD");
              setSelectedDate(newDate);
            }}
            allowClear
          />
        </div>
      }
      bordered={true}
    >
      <div className="early-riser-scrollable-content">
        {loadingState ? (
          <ProgressLoader />
        ) : earlyRisers?.length === 0 ? (
          <div className="no-data-icon-div">
            <p className="no-data-text no-data-container">
              No early birds today 🐦
            </p>{" "}
          </div>
        ) : (
          <div>
            {earlyRisers?.map((item: any, index: number) => (
              <div key={item.id}>
                <div className="early-riser-custom-new-container">
                  <div className="early-riser-custom-new-avatarCol">
                    <Avatar
                      shape="circle"
                      size={{
                        xxl: 60,
                        xl: 60,
                        lg: 60,
                        md: 60,
                        sm: 60,
                        xs: 60,
                      }}
                      src={item?.employee?.avatar_url || d_dp}
                    />
                    <FaStar className="early-riser-custom-new-newWord" />
                  </div>
                  <Row className="early-risers-row">
                    <Col
                      xxl={19}
                      xl={19}
                      lg={19}
                      md={19}
                      sm={18}
                      xs={18}
                      className="early-riser-title-col"
                    >
                      <Row>
                        <p className="early-riser-title">
                          {item?.employee?.full_name}
                        </p>
                      </Row>
                      <Row>
                        <p className="early-riser-designation">
                          {" "}
                          {item?.company?.unique_identifier
                            ? generateFormatedId(
                                item?.company?.unique_identifier,
                                item.employee.cnic
                              )
                            : "No Data"}
                        </p>
                      </Row>
                    </Col>
                    <Col
                      xxl={5}
                      xl={5}
                      lg={5}
                      md={5}
                      sm={6}
                      xs={6}
                      className="early-riser-data-col"
                    >
                      <Tag className="early-riser-join-date">
                        <BsClockHistory />
                        {"  "}
                        {formatMinutesToHours(
                          parseShortHours(item?.late_checkin_duration)
                        )}
                        {/* {"Shining Star!"} */}
                      </Tag>
                    </Col>
                  </Row>
                </div>
                {index < employeeData.length - 1 && (
                  <Divider className="early-riser-divider" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default EarlyRisers;
