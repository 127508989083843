import React, { useEffect } from "react";
import RouterIndex from "./HCM/routes/RouterIndex";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "antd/dist/reset.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmployeeProfileDetails,
  selectEmployee,
} from "./redux/EmployeeFormSlice";
 
 
 

const App: React.FC = () => {
  const dispatch = useDispatch();

  const existingState = useSelector(selectEmployee);
  useEffect(() => {
    if (
      !existingState ||
      !existingState.Employee ||
      !existingState.Employee.Activebutton
    ) {
      dispatch(
        setEmployeeProfileDetails({
          Employee: {
            Activebutton: [{ key: "Personl_Details", status: "Active" }],
          },
        })
      );
    }
  }, [dispatch]);



  return (
    <>
      <BrowserRouter basename="/hrm">
        <RouterIndex />
      </BrowserRouter>
    </>
  );
};

export default App;
