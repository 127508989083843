import React, { useEffect, useState } from "react";
import "./index.css";
import CustomTable from "../../../../common/CustomTable";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { fetchAdvanceRequestDataList } from "../../services";
import searchIcon from "../../../../assets/images/search-icon.png";
import { SortOrder } from "antd/lib/table/interface";

import { Col, Input, Modal, Row, Tag } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import EditAdvanceRequest from "../Edit";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import d_dp from "../../../../assets/images/blankProfile.png";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../../redux/AccessRight";

const AdvanceRequestListView = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRecordData, setSelectedRecordData] = useState<any>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [writeAccess, setWriteAccess] = useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  useEffect(() => {
    if (accessObj) {
      handleRadioChange();
    }
  }, []);

  const handleRadioChange = () => {
    const parentMenu = "Requests";

    const access = extractAccessRightObject(
      accessObj,
      parentMenu,
      "Advances Approval"
    );
    setReadAccess(access?.readAccess);
    setWriteAccess(access?.writeAccess);
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchAdvanceRequestDataList(
        organizationId,
        companyId,
        branchId
      );
      if (response) {
        setTableData(response.data);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: response.data,
        }));
        setTableLoading(false);
      }
    } catch (error) {
      setTableLoading(false);
    }
  };

  const columns: any = [
    {
      title: "Name",
      index: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = a?.employee?.full_name || "";
        const nameB = b?.employee?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={8} xxl={8} className="img-col">
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={
                  record?.employee?.avatar_url
                    ? record?.employee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={16}
            xxl={16}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.employee?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Request Type",
      index: "request_type",
      key: "request_type",
      render: (_: any, record: any) => (
        <div>
          {record?.request_type === "withdrawProvidentFund"
            ? "Withdraw Provident Fund"
            : record?.request_type === "advanceSalary"
            ? "Advance Salary"
            : record?.request_type === "personalLoan"
            ? "Personal Loan"
            : ""}
        </div>
      ),
    },
    {
      title: "Amount/Month Period",
      index: "amount",
      key: "amount",
      render: (_: any, record: any) => (
        <div>
          {record?.request_type === "advanceSalary"
            ? record?.start_date + " - " + record?.end_date
            : record?.amount}
        </div>
      ),
    },
    {
      title: "Next Requestee",
      index: "nextRequesteeEmployee",
      key: "nextRequesteeEmployee",
      render: (record: any) =>
        !record?.reviewer_id && (
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xl={8} xxl={8} className="img-col">
              <div className="advance-request-circle-avatarUrl-cell">
                <img
                  src={
                    record?.nextRequesteeEmployee?.avatar_url
                      ? record?.nextRequesteeEmployee?.avatar_url
                      : d_dp
                  }
                  alt={d_dp}
                  className="advance-request-circle-avatarUrl-cell-img"
                />
              </div>
            </Col>
            <Col
              span={24}
              lg={24}
              xl={16}
              xxl={16}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <strong>{record?.nextRequesteeEmployee?.full_name}</strong>
              </div>
            </Col>
          </Row>
        ),
    },
    {
      title: "Required By Date",
      index: "required_date",
      key: "required_date",
      render: (record: any) => <div>{record?.required_date}</div>,
    },
    {
      title: "Status",
      index: "status",
      key: "status",
      render: (_: any, record: any) => (
        <div>
          {record?.status === "rejected" ? (
            <Tag color="red">Rejected</Tag>
          ) : record?.status === "approved" ? (
            <Tag color="green">Approved</Tag>
          ) : (
            <Tag>Requested</Tag>
          )}
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) =>
        record?.reviewer_id ? (
          <a onClick={() => editModalOpenHanlder(record)}>
            <EyeOutlined />
          </a>
        ) : (
          <a onClick={() => editModalOpenHanlder(record)}>
            <EditOutlined />
          </a>
        ),
    },
  ];

  const editModalOpenHanlder = (data: any) => {
    setShowEditModal(true);
    setSelectedRecordData(data);
  };
  const editModalCloseHanlder = () => {
    setShowEditModal(false);
    setSelectedRecordData(null);
    fetchData();
  };

  const onSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const handleSearch = (value: any) => {
    setTableLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setTableLoading(false);
  };

  return (
    <div className="global-request-container-styling advance-request-approval-container">
      <Row gutter={[16, 16]}>
        <Col span={24} className="allowance-request-search-col">
          <Input
            className="org-search-input"
            size="large"
            placeholder="Search"
            prefix={<img src={searchIcon} alt="Search icon" />}
            onChange={(e: any) => {
              onSearchChange(e.target.value);
            }}
          />
        </Col>
      </Row>
      {readAccess && (
        <CustomTable
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
        />
      )}
      {showEditModal && writeAccess && (
        <Modal
          open={showEditModal}
          onCancel={editModalCloseHanlder}
          width={1000}
          title={<span className="global-modal-title">Advance Request</span>}
          className="global-modal-main-container"
          footer={false}
          destroyOnClose
        >
          <EditAdvanceRequest
            data={selectedRecordData}
            editModalCloseHanlder={editModalCloseHanlder}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdvanceRequestListView;
