import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Space,
  Row,
  Col,
  Checkbox,
  Select,
  Divider,
} from "antd";
import "./index.css";
import TextArea from "antd/es/input/TextArea";

import { useSelector } from "react-redux";

import { CheckboxValueType } from "antd/es/checkbox/Group";

import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { AbsentPolicyCreationVersion, setAbsentPolicyData } from "../services";
import { allBranchesForCompanyDataApi, allOrgCompaniesDataApi } from "../../Deduction/services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const staticQuestions = [
  {
    question: "How many absences are permitted in a month?",
    type: "number",
  },
  {
    question: "What is the maximum consecutive days for leave?",
    type: "number",
  },
];

const AbsentPolicy: React.FC = () => {
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [tempVersion, setTempVersion] = useState<any>();
  const [companies, setCompanies] = useState<any[]>([]);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [companySelectLoading, setCompanySelectLoading] = useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;
  const branchId = logedInemployee?.employee?.branch_id
  const companyId = logedInemployee?.employee?.company_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    
      getBranches();
    
  }, [selectedCompany]);

  useEffect(() => {
    getVersion();
  }, [selectedBranch , selectedCompany]);

  const getBranches = async (companyId?: number) => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      const companyIdIncoming = form.getFieldValue("company")
        ? form.getFieldValue("company")
        : companyId;
      const { data, error } = await allBranchesForCompanyDataApi(
        companyIdIncoming,
        organizationId
      );

      if (data) {
        setFilteredBranches(data);
        form.setFieldValue("branchId", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };

  const getVersion = async () => {
    const { data, error } = await AbsentPolicyCreationVersion(
      organizationId,
      selectedCompany,
      selectedBranch
    );

    if (data) {

      const tempVersionValue = "Version #" + (data.length + 1);
      setTempVersion(tempVersionValue);
      form.setFieldsValue({
        policyName: tempVersionValue,
      });
    }
  };

  const getCompanies = async () => {
    setCompanySelectLoading(true);
    const { data, error } = await allOrgCompaniesDataApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
    setCompanySelectLoading(false);
  };
  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const data = {
      version_name: values.policyName,
      allowed_per_month: values["How many absences are permitted in a month?"],
      max_consecutive:
        values["What is the maximum consecutive days for leave?"],
      description: values.Description,
      company_id: form.getFieldValue("company") || selectedCompany || null,
      organization_id: organizationId,
      branch_id: form.getFieldValue("branchId") || selectedBranch || null,
    };

    try {
      const { data: insertedData, error } = await setAbsentPolicyData(data);

      if (error) {
        setAlertType("error");
        setButtonLoading(true);
      } else {
        setshowAlert(true);
        setAlertType("success");
        setCounter(counter + 1);
      }
      form.resetFields();
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  return (
    <div className=" global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          {!companyId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"company"}
                label="Select a company"
                rules={[
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  placeholder="Select a company..."
                  onChange={(value) => setSelectedCompany(value)}
                  loading={companySelectLoading}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(branchId) && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="branchId" label="Select Branch (If any)">
                <Select
                  disabled={disabled}
                  placeholder="Select a Branch"
                  allowClear
                  loading={branchSelectLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="policyName"
              label="Policy Name"
              rules={[{ required: true }]}
            >
              <Input size="large" value={tempVersion} placeholder="Enter policy name..." />
            </Form.Item>
          </Col>


        </Row>

        <Row gutter={24}>
          {staticQuestions.map((questionConfig, index) => (
            <Col span={12} key={index}>
              <Form.Item
                name={questionConfig.question}
                label={questionConfig.question}
                rules={[{ required: true }]}
              >
                {questionConfig.type === "number" && (
                  <InputNumber
                  size="large"
                    className="global-input-number-width"
                    placeholder={`${questionConfig.question}`}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="Description" label="Description">
              <TextArea placeholder="Enter Description..." rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row">
          <Col span={24}>
            <Form.Item shouldUpdate={true}>
              {() => (
                <div className="absent-btn">
                  <Space>
                    <Button
                      className="global-btn-width"
                      htmlType="submit"
                      size="large"
                      type="primary"
                      loading={buttonLoading}
                    >
                      Create
                    </Button>
                  </Space>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default AbsentPolicy;
