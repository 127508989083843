import { supabase_client_for_Org_schema } from "../../../services/supabase";

// Function which gets the company and branch id for the employee
export const getTheCompanyAndBranchId = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("company_id, branch_id")
      .eq("id", employeeId)
      .maybeSingle();

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to insert or update the employee language
export const createOrUpdateLanguage = async (
  organizationId: any,
  employeeId: any,
  data: any
): Promise<any> => {
  try {
    const { data: existingData } = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_language")
      .select("id")
      .eq("employee_id", employeeId)
      .maybeSingle();

    let response;
    if (existingData) {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_language")
        .update(data)
        .eq("employee_id", employeeId);
    } else {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_language")
        .insert({ ...data, employee_id: employeeId });
    }

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to insert or update the employee skills
export const createOrUpdateSkills = async (
  organizationId: any,
  employeeId: any,
  data: any
): Promise<any> => {
  try {
    const { data: existingData } = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_skill")
      .select("id")
      .eq("employee_id", employeeId)
      .maybeSingle();

    let response;
    if (existingData) {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_skill")
        .update(data)
        .eq("employee_id", employeeId);
    } else {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_skill")
        .insert({ ...data, employee_id: employeeId });
    }

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to insert or update the employee experiences
export const createOrUpdateExperiences = async (
  organizationId: any,
  employeeId: any,
  data: any
): Promise<any> => {
  try {
    const { data: existingData } = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_work_experience")
      .select("id")
      .eq("employee_id", employeeId)
      .maybeSingle();

    let response;
    if (existingData) {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_work_experience")
        .update(data)
        .eq("employee_id", employeeId);
    } else {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_work_experience")
        .insert({ ...data, employee_id: employeeId });
    }

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to insert or update the employee qualifications
export const createOrUpdateQualifications = async (
  organizationId: any,
  employeeId: any,
  data: any
): Promise<any> => {
  try {
    const { data: existingData } = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_qualification")
      .select("id")
      .eq("employee_id", employeeId)
      .maybeSingle();

    let response;
    if (existingData) {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_qualification")
        .update(data)
        .eq("employee_id", employeeId);
    } else {
      response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_qualification")
        .insert({ ...data, employee_id: employeeId });
    }

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to get the employee qualifications
export const getQualifications = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .select("qualification_details")
      .eq("employee_id", employeeId);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to get the employee work experience
export const getWorkExperience = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .select("details")
      .eq("employee_id", employeeId);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to get the employee languages
export const getLanguage = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_language")
      .select("language_details")
      .eq("employee_id", employeeId);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Function to get the employee skills
export const getSkills = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .select("skill_details")
      .eq("employee_id", employeeId);
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
