import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row, Select, Form, Input, Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useForm } from "antd/es/form/Form";
import {
  fetchSalarySheetTemplateByIdApi,
  updateSalarySheetTemplateApi,
} from "../../services";
import CustomAlert from "../../../../../common/Alert";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import CustomTable from "../../../../../common/CustomTable";

interface EditSalarySheetTemplateProps {
  onCancel: () => void;
  recordId: string;
}
const EditSalarySheetTemplate: React.FC<EditSalarySheetTemplateProps> = ({
  onCancel,
  recordId,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [form] = useForm();

  const [optionsState, setOtionState] = useState<any[]>([
    {
      label: "Bank Account",
      value: "bankAccount",
      customName: "Bank Account",
      checked: false,
    },
    {
      label: "Bank IBAN",
      value: "bankIban",
      customName: "Bank IBAN",
      checked: false,
    },
    {
      label: "Account Title",
      value: "employeeName",
      customName: "Account Title",
      checked: false,
    },
    {
      label: "Customer Ref No",
      value: "customerRefNo",
      customName: "Customer Ref No",
      checked: false,
    },
    {
      label: "Gross Salary",
      value: "GrossSalary",
      customName: "Gross Salary",
      checked: false,
    },
    {
      label: "Bank Code",
      value: "bankCode",
      customName: "Bank Code",
      checked: false,
    },
  ]);

  const organizationId = loggedInEmployee?.employee.organization_id;

  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [recordId]);

  const fetchData = async () => {
    const data = {
      organization_Id: organizationId,
      id: recordId,
    };
    const res = await fetchSalarySheetTemplateByIdApi(data);
    if (res.data) {
      form.setFieldsValue({
        name: res.data.name,
        type: res.data.type,
      });
      setOtionState(res.data.options);
    }
  };
  const handleSubmit = async (values: any) => {
    const data = {
      organization_id: organizationId,
      id: recordId,
      details: {
        name: values.name,
        type: values.type,
        options: optionsState,
      },
    };
    const response = await updateSalarySheetTemplateApi(data);
    if (response.status == 201 || response.status == 204) {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("success");
      setTimeout(() => {
        onCancel();
      }, 1000);
    } else {
      setBtnLoading(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const handleInputChange = (itemValue: string, newCustomName: string) => {
    setOtionState(
      optionsState.map((optionItem) =>
        optionItem.value === itemValue
          ? { ...optionItem, customName: newCustomName }
          : optionItem
      )
    );
  };

  const onCheckedChange = (itemValue: string) => {
    setOtionState(
      optionsState.map((optionItem) =>
        optionItem.value === itemValue
          ? { ...optionItem, checked: !optionItem?.checked }
          : optionItem
      )
    );
  };

  const onChangeOfType = (typeComing: string) => {
    if (typeComing === "FT") {
      const valueToChecked = ["bankAccount", "GrossSalary", "customerRefNo"];
      setOtionState(
        optionsState.map((optionItem) => ({
          ...optionItem,
          checked: valueToChecked.includes(optionItem.value),
        }))
      );
    } else if (typeComing === "IBFT") {
      const valueToChecked = [
        "bankAccount",
        "GrossSalary",
        "customerRefNo",
        "bankCode",
      ];
      setOtionState(
        optionsState.map((optionItem) => ({
          ...optionItem,
          checked: valueToChecked.includes(optionItem.value),
        }))
      );
    }
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "checked",
      render: (checked: boolean, record: any) => (
        <Checkbox
          checked={checked}
          onChange={() => onCheckedChange(record.value)}
        />
      ),
    },
    {
      title: "Label",
      dataIndex: "label",
      render: (text: string) => <Input value={text} disabled />,
    },
    {
      title: "Custom Name",
      dataIndex: "customName",
      render: (text: string, record: any) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(record.value, e.target.value)}
        />
      ),
    },
  ];

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter name!" }]}
            >
              <Input size="large" placeholder="Enter Sheet Name" />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: "Please select a Type!" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Type"
                onChange={onChangeOfType}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {["FT", "IBFT"].map((item: any) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <CustomTable
              columns={columns}
              dataSource={optionsState}
              pagination={false}
              rowKey="value"
            />
          </Col>
        
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={btnLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};
export default EditSalarySheetTemplate;
