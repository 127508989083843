import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getAllAttendanceData = async (data: any) => {
  let query = supabase_client_for_Org_schema(data.organizationId)
    .from("employee_attendance")
    .select(
      `*, employee!employee_attendance_employee_id_fkey(full_name, avatar_url, cnic, id), company! employee_attendance_company_id_fkey(name, unique_identifier)`
    )
    .eq("organization_id", data.organizationId);

  if (data.companyId) {
    query = query.eq("company_id", data.companyId);
  }
  if (data.branchId) {
    query = query.eq("branch_id", data.branchId);
  }

  if (data.status) {
    query = query.ilike("check_in_status", data.status);
  }
  if (data.employeename) {
    query = query.ilike(
      "employee!employee_attendance_employee_id_fkey.full_name",
      `%${data.employeename}%`
    );
  }

  if (data.date) {
    const localStartDate = new Date(data.date);
    localStartDate.setHours(0, 0, 0, 0);

    const utcStartDate = new Date(
      localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000
    );

    const localEndDate = new Date(data.date);
    localEndDate.setHours(23, 59, 59, 999);

    const utcEndDate = new Date(
      localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000
    );

    query = query
      .gte("date", utcStartDate.toISOString())
      .lte("date", utcEndDate.toISOString());
  }

  const response = await query;

  return response;
};
