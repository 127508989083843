import React, { useState, useEffect } from "react";
import { Steps, Button, Form, Input, Row, Col, Select } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getBranches } from "../../Branches/services";
import {
  branchManagerNamesTeam,
  dataInsertion,
  getDepartments,
} from "../services";
import { getCompanies } from "../../AccessRights/services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Step } = Steps;

interface props {
  onCancel: (status: string) => void;
}

const CreateTeams: React.FC<props> = ({ onCancel }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const org_details = useSelector(selectLogedEmployee);
  const [form] = Form.useForm();
  const [fetchedDep, setFetchedDep] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    teamName: "",
    projectLeadName: "",
    teamMembers: "",
    goalsAndResponsibilities: "",
    details: "",
    selectedCompany: "",
    selectedBranch: "",
  });
  const organizationId = org_details.logedInemployee?.employee.organization_id;
  const [managerNames, setManagerNames] = useState<string[] | any>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [teamLeaderSelectLoading, setTeamLeaderSelectLoading] =
    useState<boolean>(false);
  const [departmentSelectLoading, setDepartmentSelectLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchCompanyNames();
  }, [organizationId]);

  useEffect(() => {
    fetchBranchManagerNames();
  }, [organizationId, formData.selectedCompany, formData.selectedBranch]);

  useEffect(() => {
    if (formData.selectedBranch) {
      fetchDepartments();
    }
  }, [formData.selectedBranch]);

  const fetchBranchManagerNames = async () => {
    try {
      const data = {
        organizationId: organizationId,
        companyId: form.getFieldValue("selectedCompany"),
        branchId: form.getFieldValue("selectedBranch"),
      };
      const response = await branchManagerNamesTeam(data);
      setTeamLeaderSelectLoading(true);

      if (response.data) {
        const names = response.data.map((item: any) => ({
          id: item.id,
          name: item.full_name,
        }));
        setManagerNames(names);
        setTeamLeaderSelectLoading(false);
      }
      setTeamLeaderSelectLoading(false);
    } catch (error) {
      setTeamLeaderSelectLoading(false);
    }
  };

  const fetchDepartments = async () => {
    try {
      const data = {
        branchId: form.getFieldValue("selectedBranch"),
        companyId: form.getFieldValue("selectedCompany"),
      };

      setDepartmentSelectLoading(true);
      const response = await getDepartments(data, organizationId);
      if (response.data) {
        setFetchedDep(response.data);
        setDepartmentSelectLoading(false);
      }
      setDepartmentSelectLoading(false);
    } catch (error) {
      setDepartmentSelectLoading(false);
    }
  };

  const fetchCompanyNames = async () => {
    try {
      const response = await getCompanies(organizationId);
      setCompanySelectLoading(true);

      if (response.data) {
        setCompanies(response.data);
        setCompanySelectLoading(false);
      } else {
        setCompanySelectLoading(false);
      }
    } catch (error) {
      setCompanySelectLoading(false);
    }
  };

  const fetchBranchesByCompany = async (companyId: string) => {
    try {
      const Ids = {
        companyId: companyId,
      };
      const response = await getBranches(Ids, organizationId);
      setBranchSelectLoading(true);
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
      } else {
        setBranchSelectLoading(false);
      }
    } catch (error) {
      setBranchSelectLoading(false);
    }
  };

  const handleCompanyChange = (value: string) => {
    setFormData({ ...formData, selectedCompany: value, selectedBranch: "" });

    fetchBranchesByCompany(value);
    fetchDepartments();
  };

  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const nextStep = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const body = {
      name: formData.teamName,

      manager_id: formData.projectLeadName,
      mission: formData.goalsAndResponsibilities,
      branch_id: selectedBranch,
      organization_Id: organizationId,
      company_id: formData.selectedCompany,
      department_id: form.getFieldValue("selectDepartment"),
    };
    const payload = {
      body: body,
    };
    const response = await dataInsertion(payload, organizationId);

    if (
      response.status == 200 ||
      response.status == 201 ||
      response.status == 204
    ) {
      setButtonLoading(false);
      form.resetFields();
      setCurrentStep(0);
      onCancel("success");
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };
  const { TextArea } = Input;
  const { Option } = Select;
  const steps = [
    {
      title: "Teams Information",
      content: (
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Team Name"
                name="teamName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the team name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Team Name"
                  size="large"
                  value={formData.teamName}
                  onChange={(e) => handleFormChange("teamName", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company"
                name="selectedCompany"
                rules={[
                  {
                    required: true,
                    message: "Please select a company",
                  },
                ]}
              >
                <Select
                  placeholder="Enter Company"
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  value={formData.selectedCompany}
                  onChange={handleCompanyChange}
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companies.map((company: any) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item label="Select Branch (If any)" name="selectedBranch">
                <Select
                  placeholder="Enter Branch"
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  value={formData.selectedBranch}
                  disabled={!formData.selectedCompany}
                  onChange={(value) => {
                    handleFormChange("selectedBranch", value);
                    setSelectedBranch(value);
                  }}
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((branch: any) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Select Department "
                name="selectDepartment"
                rules={[
                  {
                    required: true,
                    message: "This is a required field.",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Department"
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  disabled={!formData.selectedCompany}
                  onChange={(value) => {
                    handleFormChange("selectedBranch", value);
                  }}
                  loading={departmentSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedDep.map((branch: any) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Team Leader"
                name="projectLeadName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the team leader name",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Enter Team Lead's Name"
                  onChange={(value) =>
                    handleFormChange("projectLeadName", value)
                  }
                  loading={teamLeaderSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {managerNames.map((manager: any) => (
                    <Option key={manager.id} value={manager.id}>
                      {manager.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="global-modal-bottom-row team-buttons">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={nextStep}
                size="large"
              >
                Next
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Goals and Responsibilities",
      content: (
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                label="Goals/Objectives"
                name="goalsAndResponsibilities"
                rules={[
                  {
                    required: true,
                    message: "Please enter the goals and responsibilities",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter Goals"
                  value={formData.goalsAndResponsibilities}
                  onChange={(e) =>
                    handleFormChange("goalsAndResponsibilities", e.target.value)
                  }
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="global-modal-bottom-row team-buttons">
              <Button
                style={{ marginRight: "8px", marginLeft: "10px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                className="global-btn-width"
                type="default"
                size="large"
              >
                Previous
              </Button>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleSubmit}
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={`Step ${index + 1}`}
          />
        ))}
      </Steps>

      <div className="department-steps-content">
        {steps[currentStep].content}
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateTeams;
