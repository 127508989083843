import { Col, Row } from "antd";
import React from "react";
import TotalYearExpense from "./TotalYearExpenseChart";
import "./index.css";
import MonthlySalaryInspection from "./MonthlySalaryInspectionChart";
import MonthlyAllowanceInspection from "./MonthlyAllowanceInspectionChart";
import EmployeeSalariesInspection from "./EmployeeSalariesInspection";

interface DashboardProps {
  searchValue?: string;
}
const Dashboard: React.FC<DashboardProps> = ({ searchValue }) => {
  // const [openModal, setOpenModal] = useState<boolean>(true);

  return (
    <>
      <Row>
        <Col span={24}>
          <TotalYearExpense />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <EmployeeSalariesInspection />
        </Col>
      </Row>
      <Row>
        <Col span={24} xl={12}>
          <MonthlySalaryInspection />
        </Col>
        <Col span={24} xl={12}>
          <MonthlyAllowanceInspection />
        </Col>
      </Row>

      {/* <Modal
        title={false}
        width={700}
        open={openModal}
        onCancel={}
        footer={null}
      >
        <div>
          <Row>
            <Col span={24}>
              <span>
                <ExclamationCircleFilled /> You have to create one of the
                following to continue.
              </span>
            </Col>
            <Row>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
              <Col span={4}>
                <button className="payroll-dashboard-modal-btn">
                  Create Allowance
                </button>
              </Col>
            </Row>
          </Row>
        </div>
      </Modal> */}
    </>
  );
};

export default Dashboard;
