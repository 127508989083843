import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

interface DeductionPolicyApiData {
  company_id: number;
  branch_id: number;
  organization_id: number;
  version_name: string;
  slabs: any[];
}

//also used another time in create timing policy and its edit view,  overtime policy, create absent policy,leave policy
export const allOrgCompaniesDataApi = async (orgid: number): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("company")
      .select(`id, name`)
      .eq("organization_Id", orgid);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

//also used another time in create timing policy, leave policy
export const allBranchesForCompanyDataApi = async (
  companyId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setDeductionPolicyApi = async (
  data: DeductionPolicyApiData
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("short_hours_deduction")
      .insert(data);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchDeductionByIdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("short_hours_deduction")
      .select("id,branch_id,company_id,version_name,slabs")
      .eq("organization_id", data.organizationId)
      .eq("id", data.id)
      .single();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

//also used another time in overtime policy as well
export const allOrgCompanyBranchesApi = async (
  orgid: string,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("branch")
      .select(`id, name`)
      .eq("organization_Id", orgid);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const updateDeductionPolicyApi = async (
  data: any,
  id: string
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.organization_id)
      .from("short_hours_deduction")
      .update(data)
      .eq("id", id);
    return res;
  } catch {
    return catch_return;
  }
};

//called another time in create timing policy and edit timing policy
export const fetchAllDeductionPoliciesApi = async (
  branchId: string,
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("short_hours_deduction")
      .select("id, version_name,created_at")
      .eq("organization_id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const deleteDeductionpolicyApi = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("short_hours_deduction")
      .delete()
      .eq("id", id);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
