import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Row, Col, DatePicker } from "antd";
import "./index.css";
import CustomAlert from "../../../../../common/Alert";
import { fetchEducationbyIdApi, updateEducationByIdApi } from "../services";
import { useSelector } from "react-redux";
import CustomTable from "../../../../../common/CustomTable";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
interface EditQualificationModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedRecordId: number | null;
  fetchData: () => void;
}

const EditQualificationModal: React.FC<EditQualificationModalProps> = ({
  visible,
  onCancel,
  selectedRecordId,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [qualifications, setQualifications] = useState<any[]>([]);
  const loggedInEmployee =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [employeeId, setEmployeeId] = useState<string>("");

  const onAddQualification = async (values: any) => {
    const newData = {
      employeeId: employeeId,
      id: Date.now(),
      institute: values.institute,
      level: values.level,
      specialization: values.specialization,
      from: values.from ? values.from.format("YYYY-MM-DD") : "",
      to: values.to ? values.to.format("YYYY-MM-DD") : "",
    };
    form.setFieldsValue({
      institute: "",
      level: "",
      specialization: "",
      from: "",
      to: "",
    });
    setQualifications([...qualifications, newData]);
  };

  const onRemoveQualification = (index: any) => {
    setQualifications(qualifications.filter((_, i) => i !== index));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Start Date",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "End Date",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any, index: any) => (
        <Button onClick={() => onRemoveQualification(index)}>Remove</Button>
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    const response = await fetchEducationbyIdApi(
      selectedRecordId,
      loggedInEmployee
    );
    if (response.data) {
      setLoading(false);
      form.setFieldsValue({
        employeeId: response.data.employee.full_name,
      });
      setEmployeeId(response?.data?.employee_id);
      const qualifications = Array.isArray(response.data.qualification_details)
        ? response.data.qualification_details
        : [response.data.qualification_details];
      setQualifications(qualifications);
    } else {
      setLoading(false);
      setQualifications([]);
    }
  };

  const updateData = async () => {
    setBtnLoading(true);
    const data = qualifications;
    const response = await updateEducationByIdApi(
      data,
      selectedRecordId,
      loggedInEmployee
    );
    if (response.status == 204 || response.status == 201) {
      setBtnLoading(false);
      setshowAlert(true);
      setAlertType("success");
      setTimeout(() => {
        onCancel();
        fetchData();
      }, 1000);
    } else {
      setshowAlert(true);
      setAlertType("error");
      setBtnLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1000}
      title={<span className="global-modal-title">Edit Qualification</span>}
      footer={false}
      className="global-modal-main-container"
    >
      <div className="global-card-inner-container">
        <Form
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={onAddQualification}
        >
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="employeeId"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Employee Name!",
                  },
                ]}
              >
                <Input size="large" disabled />
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="Level" name="level">
                <Input size="large" placeholder="Enter Level" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Institute"
                name="institute"
                rules={[{ required: true, message: "Please Enter Institute!" }]}
              >
                <Input size="large" placeholder="Enter Institute" />
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Specialization"
                name="specialization"
                rules={[
                  { required: true, message: "Please Enter Specialization!" },
                ]}
              >
                <Input size="large" placeholder="Enter Specialization" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Start Date"
                name="from"
                rules={[
                  { required: true, message: "Please Enter Starting Date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="End Date" name="to">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="qualification-btn-position">
            <Col>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Add Qualification
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <CustomTable
          dataSource={qualifications}
          columns={columns}
          rowKey="id"
          loading={loading}
        />
        <Row className="global-modal-bottom-row last-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                onClick={updateData}
                loading={btnLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {showAlert && (
          <CustomAlert
            type={alertType}
            showIcon={true}
            autoCloseDuration={3000}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditQualificationModal;
