import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches education data from the API based on the provided data object.
 * @param {any} data - The data object containing parameters for the API request.
 * @returns {Promise<any>} A promise that resolves to the API response data.
 */
export const fetchEducationApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_qualification")
      .select(`*,  employee(full_name)`)
      .eq("organization_id", data?.organizationId);

    if (data?.companyId) {
      query = query.eq("company_id", data?.companyId);
    }
    if (data?.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }
    if (data?.searchInput) {
      query = query.ilike("employee.full_name", `%${data?.searchInput}%`);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes an education record from the database by its ID.
 * @param {number} id - The ID of the education record to delete.
 * @returns {Promise<any>} A promise that resolves with the result of the deletion operation.
 */
export const deleteEducationByIdApi = async (
  id: number,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .delete()
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts education data into the "Education" table in the Supabase database.
 * @param {any} data - The data to be inserted into the table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertEducationByIdApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_qualification")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches all employees without education information based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, and branchId.
 * @returns {Promise<any>} A promise that resolves to an array of employees without education.
 */
export const fetchAllEmployeeWithOutEducationApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    if (res.data) {
      const EmergencyContactres = await supabase_client_for_Org_schema(
        data.organizationId
      )
        .from("employee_qualification")
        .select("employee_id");
      const employeeIdsWithSalary = new Set(
        EmergencyContactres?.data?.map((item: any) => item.employee_id)
      );
      const employeeswithoutsalary = res.data
        ?.filter((employee: any) => !employeeIdsWithSalary.has(employee.id))
        .map((employee: any) => ({
          value: employee.id,
          label: employee.full_name,
        }));
      return employeeswithoutsalary;
    }
    return [];
  } catch (error) {
    return catch_return;
  }
};

/**
 * Updates the education record in the database with the provided data.
 * @param {any} data - The data to update the education record with.
 * @returns {Promise<any>} A promise that resolves to the result of the update operation.
 */
export const updateEducationByIdApi = async (
  data: any,
  recordId: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .update({ qualification_details: data })
      .eq("id", recordId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEducationbyIdApi = async (
  id: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .select(
        `id, employee_id, qualification_details, employee(full_name) as employee`
      )
      .eq("id", id)
      .maybeSingle();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
