import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchEmployeeSkillsDataApi = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .select("id, skill_details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeEducationDataApi = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .select("id, qualification_details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeWorkExperienceDataApi = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .select("id, details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeLanguageDataApi = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_language")
      .select("id, language_details")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId)
      .order("id", { ascending: false });

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const setEmployeeEducationModalDataApi = async (
  formData: any,
  organizationId: any
): Promise<any> => {
  try {
    const firstResponse = await supabase_client_for_Org_schema(organizationId)
      .from("employee_qualification")
      .select("qualification_details")
      .eq("employee_id", formData.employee_id);
    if (firstResponse.data && firstResponse?.data?.length > 0) {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_qualification")
        .update({ qualification_details: formData.qualification_details })
        .eq("employee_id", formData.employee_id);
      return { data, error };
    } else {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_qualification")
        .insert(formData);

      return { data, error };
    }
  } catch {
    return catch_return;
  }
};

export const setEmployeeLanguageModalDataApi = async (
  formData: any,
  organizationId: any
): Promise<any> => {
  try {
    const firstResponse = await supabase_client_for_Org_schema(organizationId)
      .from("employee_language")
      .select("language_details")
      .eq("employee_id", formData.employee_id);
    if (firstResponse.data && firstResponse?.data?.length > 0) {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_language")
        .update({ language_details: formData.language_details })
        .eq("employee_id", formData.employee_id);
      return { data, error };
    } else {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_language")
        .insert(formData);

      return { data, error };
    }
  } catch {
    return catch_return;
  }
};

export const setEmployeeSkillsModalDataApi = async (
  formData: any,
  organizationId: any
): Promise<any> => {
  try {
    const firstResponse = await supabase_client_for_Org_schema(organizationId)
      .from("employee_skill")
      .select("skill_details")
      .eq("employee_id", formData.employee_id);
    if (firstResponse.data && firstResponse?.data?.length > 0) {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_skill")
        .update({ skill_details: formData.skill_details })
        .eq("employee_id", formData.employee_id);
      return { data, error };
    } else {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_skill")
        .insert(formData);

      return { data, error };
    }
  } catch {
    return catch_return;
  }
};

export const setEmployeeExperienceModalDataApi = async (
  formData: any,
  organizationId: any,
  employee_Id: any
): Promise<any> => {
  try {
    const firstResponse = await supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .select("details")
      .eq("employee_id", employee_Id);
    if (firstResponse.data && firstResponse?.data?.length > 0) {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_work_experience")
        .update({ details: formData.details })
        .eq("employee_id", employee_Id);
      return { data, error };
    } else {
      const { data, error } = await supabase_client_for_Org_schema(
        organizationId
      )
        .from("employee_work_experience")
        .insert(formData);

      return { data, error };
    }
  } catch {
    return catch_return;
  }
};

export const updateLanguageTable = async (
  organizationId: any,
  recordId: any,
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_language")
      .update({ language_details: data })
      .eq("id", recordId);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
