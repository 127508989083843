import {
  AutoComplete,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { RiUserSearchFill } from "react-icons/ri";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import d_dp from "../../../assets/images/blankProfile.png";
import { capitalizeWords } from "../../../common/validation";
import CustomAlert from "../../../common/Alert";
import { IoFlagSharp } from "react-icons/io5";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../services/Global";
import {
  fetchPunhesData,
  getAllAttendanceDataApi,
  getAllEmployeeDataApi,
  updateEmployeeAttendanceAaccommodateDataApi,
} from "./services";
import CustomTable from "../../../common/CustomTable";
import { useForm } from "antd/es/form/Form";
import { MdCoPresent } from "react-icons/md";
import { MdGroupRemove } from "react-icons/md";
import { TiThList } from "react-icons/ti";
import { AiOutlineAudit } from "react-icons/ai";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

const AttendanceLogs: React.FC = () => {
  const employeeStatusArray = [
    {
      label: "Present",
      value: "present",
      icon: <MdCoPresent />,
      tooltip: "Click to view all present.",
    },
    {
      label: "Absent",
      value: "absent",
      icon: <MdGroupRemove />,
      tooltip: "Click to view all absent.",
    },
    {
      label: "Logs",
      value: "logs",
      icon: <TiThList />,
      tooltip: "Click to view attendance logs.",
    },
    {
      label: "Audit",
      value: "audit",
      icon: <AiOutlineAudit />,
      tooltip: "Click to go attendance aduit.",
    },
  ];

  const [selectViewState, setSelectedViewState] = useState<string>("present");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const loggedInEmployeeId = loggedInEmployee?.employeeId;
  const companyId = loggedInEmployee?.employee.company_id;
  const branchId = loggedInEmployee?.employee.branch_id;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [tableData, setTableData] = useState<any[]>([]);
  const [allLogs, setAllLogs] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [companyDataList, setCompanyDataList] = useState<any[]>([]);
  const [branchDataList, setBranchDataList] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<any>(companyId);
  const [selectedBranch, setSelectedBranch] = useState<any>(branchId);
  const [accomodateModalVisible, setAccomodateModalVisible] =
    useState<boolean>(false);
  const [shortHours, setShortHours] = useState<any>(null);
  const [selectedRecordId, setSelectedRecordId] = useState<any>(null);
  const [shortHoursOriginalValue, setShortHoursOriginalValue] =
    useState<any>(null);
  const [accomodatedHours, setAccomodatedHours] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(
    moment().format("YYYY-MM-DD")
  );
  const [form] = useForm();
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Attendance",
    "Attendance Logs"
  );

  const read = access?.readAccess;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      if (branchId) {
        setSelectedBranch(branchId);
      }
    } else {
      fetchCompanies();
    }
  }, []);
  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompany]);
  useEffect(() => {
    fetchTableData();
  }, [selectedCompany, selectedBranch, selectViewState]);
  const fetchCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanyDataList(data);
      setSelectedCompany(data[0]?.id);
    } else {
    }
  };
  const fetchBranches = async () => {
    if (branchId) {
      setSelectedBranch(branchId);
    } else {
      if (selectedCompany) {
        const res = await fetchAllBranchesByComapnyIdApi(
          organizationId,
          selectedCompany
        );
        if (res.data) {
          setBranchDataList(res.data);
          setSelectedBranch(res?.data[0]?.id);
        }
      }
    }
  };
  const fetchTableData = async () => {
    const item = employeeStatusArray.filter(
      (item: any) => item.value === selectViewState
    )[0];

    setTableLoading(true);
    const data = {
      organizationId: organizationId,
      branchId: selectedBranch,
      companyId: selectedCompany,
      date: selectedDate ? moment(selectedDate)?.format("YYYY-MM-DD") : "",
    };
    const attendance_res = await getAllAttendanceDataApi(data);

    if (attendance_res.data) {
      if (selectedEmployee) {
        const filteredData = attendance_res?.data?.filter((item: any) =>
          item?.employee?.full_name
            ?.toLowerCase()
            ?.includes(selectedEmployee?.toLowerCase())
        );
        setAllLogs(filteredData);
        handleSataeTabClick(item, filteredData);
      } else {
        setAllLogs(attendance_res.data);
        handleSataeTabClick(item, attendance_res.data);
      }
    }
    setTableLoading(false);
  };
  const handleAaccommodate = async () => {
    const data = {
      body: {
        short_working_hours: shortHours,
        accomodated_hours: accomodatedHours,
        manually_accomodated: true,
      },
      id: selectedRecordId,
    };
    setButtonLoading(true);
    const res = await updateEmployeeAttendanceAaccommodateDataApi(
      data,
      organizationId
    );
    if (res.status == 204) {
      fetchTableData();
      setshowAlert(true);
      setAlertType("success");
      accomodateModalCloseHanlder();
    }
    setButtonLoading(false);
  };
  const storeData = (details: any[]) => {
    const sorted_data = details.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });
    return sorted_data;
  };
  const accomodateModalVisibleHanlder = (value: any) => {
    if (value?.short_working_hours) {
      setSelectedRecordId(value.id);
      setShortHours(value?.short_working_hours);
      setShortHoursOriginalValue(value?.short_working_hours);
    }
    setAccomodateModalVisible(true);
  };
  const accomodateModalCloseHanlder = () => {
    setAccomodateModalVisible(false);
    setShortHours(null);
    setAccomodatedHours(null);
    setSelectedRecordId(null);
    form.setFieldValue("accomodatedHSortHours", "");
  };
  const handleTimeChange = (
    time: Dayjs | null,
    timeString: string | string[]
  ) => {
    if (time) {
      const hours = time.hour();
      const minutes = time.minute();
      const seconds = time.second();
      const initialMoment = dayjs(shortHoursOriginalValue, "HH:mm:ss");
      const newTime = initialMoment
        .subtract(hours, "hour")
        .subtract(minutes, "minute")
        .subtract(seconds, "second");
      setShortHours(newTime.format("HH:mm:ss"));
      form.setFieldValue("accomodatedHSortHours", newTime.format("HH:mm:ss"));
      const formattedInput = dayjs()
        .startOf("day")
        .add(hours, "hour")
        .add(minutes, "minute")
        .add(seconds, "second")
        .format("HH:mm:ss");
      setAccomodatedHours(formattedInput);
    } else {
      setShortHours(shortHoursOriginalValue);
    }
  };
  const handleViewPunchesData = async () => {
    try {
      setTableLoading(true);
      const data = {
        organizationId: organizationId,
        branchId: selectedBranch,
        companyId: selectedCompany,
        date: selectedDate ? moment(selectedDate)?.format("YYYY-MM-DD") : "",
      };
      const response = await fetchPunhesData(data);

      setTableLoading(false);
      if (response.data) {
        if (selectedEmployee) {
          const filteredData = response?.data?.filter((item: any) =>
            item?.employee?.full_name
              ?.toLowerCase()
              ?.includes(selectedEmployee?.toLowerCase())
          );
          return filteredData;
        }
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      setTableLoading(false);
    }
  };
  const logsColumns: any = [
    {
      title: "Employee",
      fixed: "left",
      width: 250,
      key: "employee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={5} xxl={5} className="img-col">
            <div className="circle-avatarUrl-cell">
              <img
                src={
                  record?.employee?.avatar_url
                    ? record?.employee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.employee?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Punch Type",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => (
        <span>
          {record?.type === "Unknown_events" ? "Punch" : record?.type}
        </span>
      ),
    },
    {
      title: "Punch Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Source",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];
  const presentColumns: any = [
    {
      title: "Employee",
      fixed: "left",
      width: 250,
      key: "employee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={5} xxl={5} className="img-col">
            <div className="circle-avatarUrl-cell">
              <img
                src={
                  record?.employee?.avatar_url
                    ? record?.employee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.employee?.full_name}</strong>
              <div className="attendance-logs-manual-flag">
                {record?.manually_accomodated ? (
                  <Tooltip title="Accomodate Manually">
                    <IoFlagSharp className="attendance-logs-set-flag" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Check In",
      dataIndex: "check_in",
      key: "check_in",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "from",
      dataIndex: "fetch_from",
      key: "fetch_from",
    },
    {
      title: "Checkin Status",
      dataIndex: "check_in_status",
      key: "check_in_status",
      render: (record: any) => (record ? capitalizeWords(record) : "---"),
    },
    {
      title: "Check Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "Checkout Status",
      dataIndex: "check_out_status",
      key: "check_out_status",
      render: (record: any) => (record ? capitalizeWords(record) : "---"),
    },
    {
      title: "Short Hr",
      dataIndex: "short_working_hours",
      key: "short_working_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Worked Hr",
      dataIndex: "worked_hours",
      key: "worked_hours",
      render: (record: any) => (record ? record : "---"),
    },
  ];
  const absentColums: any = [
    {
      title: "Employee",
      fixed: "left",
      width: 250,
      key: "employee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={5} xxl={5} className="img-col">
            <div className="circle-avatarUrl-cell">
              <img
                src={
                  record?.employee?.avatar_url
                    ? record?.employee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.employee?.full_name}</strong>
              <div className="attendance-logs-manual-flag">
                {record?.manually_accomodated ? (
                  <Tooltip title="Accomodate Manually">
                    <IoFlagSharp className="attendance-logs-set-flag" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Check In",
      dataIndex: "check_in",
      key: "check_in",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "Checkin Status",
      dataIndex: "check_in_status",
      key: "check_in_status",
      render: (record: any) => (record ? capitalizeWords(record) : "---"),
    },
    {
      title: "Check Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "Checkout Status",
      dataIndex: "check_out_status",
      key: "check_out_status",
      render: (record: any) => (record ? capitalizeWords(record) : "---"),
    },
    {
      title: "Short Hr",
      dataIndex: "short_working_hours",
      key: "short_working_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Worked Hr",
      dataIndex: "worked_hours",
      key: "worked_hours",
      render: (record: any) => (record ? record : "---"),
    },
  ];
  const auditColums: any = [
    {
      title: "Employee",
      fixed: "left",
      width: 250,
      key: "employee",

      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={5} xxl={5} className="img-col">
            <div className="circle-avatarUrl-cell">
              <img
                src={
                  record?.employee?.avatar_url
                    ? record?.employee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.employee?.full_name}</strong>
              <div className="attendance-logs-manual-flag">
                {record?.manually_accomodated ? (
                  <Tooltip title="Accomodate Manually">
                    <IoFlagSharp className="attendance-logs-set-flag" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Check In",
      dataIndex: "check_in",
      key: "check_in",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "Check Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "---",
    },
    {
      title: "Expected Hr",
      dataIndex: "expected_working_hours",
      key: "expected_working_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Short Hr",
      dataIndex: "short_working_hours",
      key: "short_working_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Accomodated Hr",
      dataIndex: "accomodated_hours",
      key: "accomodated_hours",
      render: (record: any) => {
        if (record) {
          const [hours, minutes] = record?.split(":");
          return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""}`;
        }
        return "--";
      },
    },
    {
      title: "Worked Hr",
      dataIndex: "worked_hours",
      key: "worked_hours",
      render: (record: any) => (record ? record : "---"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => {
        const [hours, minutes] = record?.short_working_hours?.split(":") || [
          0, 0,
        ];
        return !record.manually_accomodated &&
          loggedInEmployeeId !== record?.employee?.id &&
          ((hours && hours > 0) || (minutes && minutes > 0)) ? (
          <Button onClick={() => accomodateModalVisibleHanlder(record)}>
            Accommodate
          </Button>
        ) : null;
      },
    },
  ];

  const filterAbsent = async (alldata: any[]) => {
    if (alldata) {
      const presentEmployeeIds = alldata?.map((item: any) => {
        return item?.employee?.id;
      });
      const data = {
        organizationId: organizationId,
        branchId: selectedBranch,
        companyId: selectedCompany,
        date: selectedDate ? moment(selectedDate)?.format("YYYY-MM-DD") : "",
      };
      const allEmployeesId = await getAllEmployeeDataApi(data);
      const absentEmployees =
        allEmployeesId &&
        allEmployeesId?.data
          ?.filter((item: any) => !presentEmployeeIds?.includes(item.id))
          ?.map((item: any) => {
            return {
              employee: {
                id: item.id,
                full_name: item.full_name,
                avatar_url: item.avatar_url,
              },
            };
          });
      if (selectedEmployee) {
        const filteredData = absentEmployees?.data?.filter((item: any) =>
          item?.employee?.full_name
            ?.toLowerCase()
            ?.includes(selectedEmployee?.toLowerCase())
        );
        return filteredData;
      } else {
        return absentEmployees;
      }
    }
  };
  const handleSataeTabClick = async (item: any, data: any) => {
    setTableLoading(true);

    if (item.value == "present" || item.value == "audit") {
      setTableData(data);
      setAllLogs(data);
    } else if (item.value == "logs") {
      const punch_logs = await handleViewPunchesData();
      setTableData(punch_logs);
      setAllLogs(punch_logs);
    } else if (item.value == "absent") {
      const absent_logs = await filterAbsent(data);
      setTableData(absent_logs);
      setAllLogs(absent_logs);
    }
    setTableLoading(false);
  };

  return (
    <>
      <div className="body-content-card-container attendance-logs-mai-container">
        <Card className="body-content-card-inner-container">
          {read ? (
            <div className="attendancelogs-table-content">
              <Row
                gutter={[16, 16]}
                className="attendancelogs-table-content-upper-row"
              >
                {!companyId && (
                  <Col
                    span={24}
                    md={
                      !companyId && !branchId
                        ? 5
                        : companyId && !branchId
                        ? 6
                        : 10
                    }
                  >
                    <Select
                      showSearch
                      className="set-attendance-log-select"
                      placeholder="Select Company"
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={(value) => setSelectedCompany(value)}
                      value={selectedCompany}
                      disabled={loggedInEmployee?.employee?.company_id}
                      allowClear
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companyDataList?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}
                {!branchId && (
                  <Col span={24} md={5}>
                    <Select
                      showSearch
                      className="set-attendance-log-select"
                      placeholder="Select Branch"
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      value={selectedBranch}
                      onChange={(value) => setSelectedBranch(value)}
                      allowClear
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branchDataList?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}
                <Col
                  span={24}
                  md={
                    !companyId && !branchId
                      ? 5
                      : companyId && !branchId
                      ? 7
                      : 10
                  }
                >
                  <Input
                    size="large"
                    className="attendance-logs-search-employee-field"
                    placeholder="Search Employee..."
                    style={{ width: "100%", height: "2.5rem" }}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                    value={selectedEmployee}
                  />
                </Col>
                <Col
                  span={24}
                  md={
                    !companyId && !branchId
                      ? 5
                      : companyId && !branchId
                      ? 7
                      : 10
                  }
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    defaultValue={dayjs(selectedDate)}
                    onChange={(date, dateString) =>
                      setSelectedDate(date ? dateString : null)
                    }
                    allowClear
                  />
                </Col>
                <Col span={24} md={4} className="attendance-col-button">
                  <Button
                    className="attandance-log-search-button  "
                    onClick={fetchTableData}
                    icon={<RiUserSearchFill />}
                    size="large"
                    type="primary"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
              <div className="employee-top-card">
                <Row gutter={[16, 12]}>
                  {employeeStatusArray.map((item) => (
                    <Col span={24} md={6} key={item.value}>
                      <Tooltip title={item.tooltip}>
                        <Button
                          size="large"
                          className={"employee-list-status-row"}
                          type={
                            selectViewState === item.value
                              ? "primary"
                              : "default"
                          }
                          style={{ width: "100%" }}
                          onClick={() => {
                            setSelectedViewState(item.value);
                          }}
                          defaultValue={selectViewState}
                          icon={item.icon}
                        >
                          {item.label}
                        </Button>
                      </Tooltip>
                    </Col>
                  ))}
                </Row>
              </div>

              <CustomTable
                dataSource={tableData ? storeData(tableData) : []}
                columns={
                  selectViewState == "present"
                    ? presentColumns
                    : selectViewState == "absent"
                    ? absentColums
                    : selectViewState == "logs"
                    ? logsColumns
                    : auditColums
                }
                loading={tableLoading}
                scroll={{ x: 1300 }}
              />
              <Modal
                title={
                  <span className="global-modal-title">
                    Accomodate Short Hours
                  </span>
                }
                open={accomodateModalVisible}
                onCancel={accomodateModalCloseHanlder}
                width={800}
                footer={false}
                className="global-modal-main-container"
                destroyOnClose
              >
                <div className="global-card-inner-container">
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleAaccommodate}
                  >
                    <Row
                      gutter={[16, 16]}
                      className="attendance-logs-accomodate-modal"
                    >
                      <Col span={24}>
                        <Form.Item
                          label="Total Short Hours"
                          name="totalShortHours"
                          initialValue={shortHoursOriginalValue}
                        >
                          <Input
                            size="large"
                            disabled
                            value={shortHoursOriginalValue}
                          />
                        </Form.Item>
                      </Col>{" "}
                      <Col span={24}>
                        <Form.Item
                          label="Accomate Hours"
                          name="accomodatedHours"
                          rules={[
                            { required: true, message: "Please enter a value" },
                          ]}
                        >
                          <TimePicker
                            placeholder="Enter number of hours to accomodate"
                            format="HH:mm:ss"
                            onChange={handleTimeChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>{" "}
                      <Col span={24}>
                        <Form.Item
                          label="Short hours After Accomodation"
                          name="accomodatedHSortHours"
                        >
                          <Input size="large" disabled value={shortHours} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="global-modal-bottom-row">
                      <Button
                        className="global-btn-width"
                        type="primary"
                        htmlType="submit"
                        loading={buttonLoading}
                        size="large"
                      >
                        Update
                      </Button>
                    </Row>
                  </Form>
                </div>
              </Modal>
              {showAlert && (
                <CustomAlert type={alertType} showAlert={showAlert} />
              )}
            </div>
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default AttendanceLogs;
