import React from "react";
import OverTimeApproveHistoryView from "./History";

const OverTimeClaimRequest = () => {

  return (
    <div>
      <OverTimeApproveHistoryView />
    </div>
  );
};

export default OverTimeClaimRequest;
