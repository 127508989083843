import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import EmployeeFormReducer from "./EmployeeFormSlice";
import logedInEmployeeReducer from "./EmployeeProfileSlice";
import AccessReduces from "./AccessRight";
import emailDomain from "./emailDomain";
import organizationModulePermissionReducer from "./organizationModulePermission";
import MenuBreadcrumbSlice from "./MenuBreadcrumbSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  Employee: EmployeeFormReducer,
  logedInemployee: logedInEmployeeReducer,
  emailDomain: emailDomain,
  Access: AccessReduces,
  menu_breadcrumb: MenuBreadcrumbSlice,
  organizationModulePermission: organizationModulePermissionReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
});

export default store;
