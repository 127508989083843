import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const leavePolicyCreationVersion = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .select("version_name,id,company_id,branch_id,organization_id")
      .eq("organization_id", organizationId);
    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setLeavePolicyCreation = async (insertData: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(insertData.organization_id)
      .from("leave_policy")
      .insert([insertData]);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchEarlyLeftLeavePolicy = async (
  organizationId: number,
  matchCondition: string,
  companyId: string,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .select("*")
      .eq("organization_id", organizationId)

      .order("id", { ascending: true })
      .limit(1);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchLeavePolicies = async (
  organizationId: number,
  companyId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .select("id,version_name,policy_types")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const deleteLeavePolicy = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("leave_policy")
      .delete()
      .eq("id", id);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};
