import supabase, { supabase_client_for_private_schema } from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
//-------------------------------Organization Update--------------------------------------------
export const updateOrganization = async (body: any): Promise<any> => {
  try {
   
    const response = await supabase.rpc('update_org_details', {
      p_org_id: body.id,
      p_name: body.organizationName,
      p_industry_type: body.industryType,
      p_head_quarter: body.headquarter,
      p_phone_number: body.phoneNumber,
      p_website: body.website,
      p_logo_avatar : body.logo_avatar
    });

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const updateorganizationLogoApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("organizationLogos")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from("organizationLogos")
        .getPublicUrl(filePath);
      return publicURL;
    }
  } catch {
    return catch_return;
  }
};

export const fetchOrganizationInfoByIdApi = async (
  orgid: any
): Promise<any> => {
  try {
 
    let query = supabase.rpc("get_org_info", { org_id: orgid }).maybeSingle();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
