import React, { useState, useEffect } from "react";
import { Space, Modal, Empty, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import CustomTable from "../../../../../common/CustomTable";
import EditBankModal from "../Edit";
import CustomAlert from "../../../../../common/Alert";
import BanksFilterModal from "../Filter";
import { fetchBankDetailsApi, deleteBankDetailsByIdApi } from "../services";
import { useSelector } from "react-redux";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../../common/SearchAndFilterFunction";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import { TablePaginationConfig, SortOrder } from "antd/lib/table/interface";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface BanksListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const BanksListView: React.FC<BanksListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const loggedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [tableDataSource, setTableDataSource] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Employee", "Banks");

  const read = access?.readAccess;

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableDataSource(originalData.apiData);
    }
  }, [searchValue]);

  const fetchData = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const data = {
      organizationId: loggedInemployee?.employee?.organization_id,
      companyId: loggedInemployee?.employee?.company_id,
      branchId: loggedInemployee?.employee?.branch_id,
    };
    const res = await fetchBankDetailsApi(data);

    setLoading(false);
    if (res.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tableDataSource?.current?.length,
        },
      });
      setTableDataSource(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
      setCounter(counter + 1);
    }
  };

  const handleDelete = async (bankId: any) => {
    const res = await deleteBankDetailsByIdApi(
      bankId,
      loggedInemployee?.employee?.organization_id
    );
    if (res.error) {
      setShowAlert(true);
      setAlertType("error");
    } else {
      setShowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const handleEdit = (recordId: number) => {
    setIsModalVisible(true);
    setSelectedRecordId(recordId);
  };

  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableDataSource(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (branchName: string) => {
    const filterArray = [{ path: "name", value: branchName }];

    let response: any;
    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableDataSource(response);
    }
  };

  const expandedRowRender = (record: any) => {
    const columns = [
      {
        title: "Bank Name",
        dataIndex: "bankName",
        key: "bankName",
        editable: true,
      },
      {
        title: "Branch Name",
        dataIndex: "branchName",
        key: "branchName",
        editable: true,
      },
      {
        title: "Account Title",
        dataIndex: "accountTitle",
        key: "accountTitle",
        editable: true,
      },
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        key: "accountNumber",
        editable: true,
      },
      {
        title: "Bank Code",
        dataIndex: "branchCode",
        key: "branchCode",
        editable: true,
      },
    ];

    const nestedDataSource = Array.isArray(record.bank_information)
      ? record.bank_information
      : [record.bank_information];

    return (
      <CustomTable
        columns={columns}
        dataSource={nestedDataSource}
        pagination={false}
        rowKey="id"
      />
    );
  };

  const onExpand = (expanded: boolean, record: any) => {
    const recordKey = record.id || record.bank_information?.accountNumber;
    setExpandedRowKeys(expanded ? [recordKey] : []);
  };

  const tableColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = (a.employee && a.employee.full_name) || "";
        const nameB = (b.employee && b.employee.full_name) || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (text: any, record: any) => (
        <span>{record.employee?.full_name || ""}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => (
        <Space size={"middle"}>
          <Popconfirm
            placement="topLeft"
            okText="Ok"
            cancelText="Cancel"
            title="Are you sure you want to remove this record?"
            onConfirm={() => handleDelete(record.id)}
          >
            <DeleteOutlined />
          </Popconfirm>
          <a onClick={() => handleEdit(record.id)}>
            <EditOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div key={counter} className="banks-table">
        {read ? (
          <CustomTable
            dataSource={tableDataSource}
            columns={tableColumns}
            loading={loading}
            onChange={handleTableChange}
            expandable={{ expandedRowRender, expandedRowKeys, onExpand, 
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ), }}
            rowKey={(record) =>
              record.id || record.bank_information?.accountNumber
            }
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <BanksFilterModal
          getFilterDataHandler={(branchName) =>
            getFilterDataHandler(branchName)
          }
        />
      </Modal>

      {isModalVisible && (
        <EditBankModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          fetchData={fetchData}
          selectedRecordId={selectedRecordId}
        />
      )}

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default BanksListView;
