import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchAllEmployeeOvertimeClaim = async (
  orgid: string | null,
  companyId: string | null,
  branchId: string | null,
  Report_to_employee_id?: string | null | number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("employee")
      .select(`id, full_name`)
      .eq("organization_id", orgid)
      .eq("status", "active");
    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }
    if (Report_to_employee_id) {
      query = query.eq("id", Report_to_employee_id);
    }
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const setOnFinishOverTimeClaimRequestApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_Id)
      .from("over_time_claim")
      .insert(data);
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeClaimRequestById = async (
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_claim")
      .select(
        `branch_Id,reviewer_id,company_Id,date,end_time,id,requested_employee_Id,reviewer_comment,starting_time,status,task_description,total_working_houre,
            company(name) , branch(name) ,
          employee!over_time_claim_requested_employee_Id_fkey(full_name) as requestedEmployee,
        `
      )
      .eq("id", id)
      .single();

    const res = await query;
    if (res.data) {
      if ((res.data as any).checkedBy) {
        let query = await supabase
          .from("Employees")
          .select(`full_name`)
          .eq("id", (res.data as any).checkedBy)
          .single();

        if (query.data) {
          (res.data as any).checkybydetails = query.data;
        }
      }
    }
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeClaimRequestupdateById = async (
  data: any,
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(organizationId)
      .from("over_time_claim")
      .update(data)
      .eq("id", id);

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeClaimRequestByEmployeeId = async (
  employeeId: string | number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("over_time_claim")
      .select(
        `total_working_houre, date,id,reviewer_id,status,
            employee!over_time_claim_requested_employee_Id_fkey(full_name,avatar_url) as requestedEmployee,
        `
      )
      .eq("requested_employee_Id", employeeId);
    const res = await query;
    if (res.data) {
      const promises = res.data.map(async (item: any) => {
        if (item.reviewer_id) {
          let query = await supabase_client_for_Org_schema(organizationId)
            .from("employee")
            .select(`full_name,avatar_url`)
            .eq("id", item.reviewer_id)
            .single();

          if (query.data) {
            item.reviewer_comment = query.data;
          }
        }
        return item;
      });

      const temp_data = await Promise.all(promises);

      res.data = temp_data;
    }
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchOverTimeClaimRequestList = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("over_time_claim")
      .select(
        `total_working_houre, date,id,reviewer_id,status,
        requestedEmployee:employee!over_time_claim_requested_employee_Id_fkey(full_name,avatar_url),
    reviewerEmployee:employee!over_time_claim_reviewer_id_fkey(full_name,avatar_url)
        `
      )
      .eq("organization_Id", data.organizationId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());
    if (data.companyId) {
      query = query.eq("company_Id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_Id", data.branchId);
    }
    const res = await query;
    if (res.data) {
      const promises = res.data.map(async (item: any) => {
        if (item.checkedBy) {
          let query = await supabase_client_for_Org_schema(data.organizationId)
            .from("employee")
            .select(`full_name`)
            .eq("organization_id", data.organizationId)
            .eq("id", item.checkedBy)
            .single();

          if (query.data) {
            item.checkybydetails = query.data;
          }
        }
        return item;
      });

      const temp_data = await Promise.all(promises);
      res.data = temp_data;
    }
    return res;
  } catch (error) {
    return catch_return;
  }
};
