export const handleAddSlabFunction = (Slabs: any[]) => {
  let defaultStart = "";
  if (Slabs.length > 0) {
    const lastSlab = Slabs[Slabs.length - 1];
    defaultStart = (parseFloat(lastSlab.stop) + 1).toString();
  } else {
    defaultStart = "1";
  }

  return { Slabs, defaultStart };
};

export const hanldeInputChangeForSlabs = async (
  slabs: any[],
  slabId: string,
  field: string,
  value: string
) => {
  try {
    const updatedSlabs = slabs.map((slab) => {
      if (slab.id === slabId) {
        return { ...slab, [field]: value };
      }
      return slab;
    });

    return updatedSlabs;
  } catch (error) {}
};
