import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Steps,
  Input,
  Select,
  Row,
  Col,
  Button,
  DatePicker,
  Radio,
  Checkbox,
  Modal,
} from "antd";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import {
  hiringTimelineArray,
  jobTypesArray,
  payArray,
  peopleToHireArray,
  predefinedQuestions,
  showByArray,
} from "../CommonData";
import CustomAlert from "../../../common/Alert";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { handleGetJobDetails, handleJobDetailsUpdation } from "../services";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

const { Step } = Steps;
const { Item } = Form;

interface EditJobstepProps {
  id: any;
  onCancel: () => void;
  visible: any;
}
const EditJobSteps: React.FC<EditJobstepProps> = ({
  id,
  onCancel,
  visible,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [expectedHoursType, setExpectedHoursType] = useState<string>("");
  const [expectedPayType, setExpectedPayType] = useState<string>("");
  const [jobDescription, setJobDescription] = useState<string>("");
  const [isApplicationDeadline, setIsApplicationDeadline] = useState<
    "yes" | "no"
  >("no");
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [customQuestionText, setCustomQuestionText] = useState<any>("");
  const [customQuestionType, setCustomQuestionType] = useState<any>(null);
  const [customQuestionOptions, setCustomQuestionOptions] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [jobRequirements, setJobRequirements] = useState<string>("");
  const [date, setDate] = useState<any>(null);
  const [form] = useForm();

  const onExpectedHoursTypeChange = (value: string) => {
    setExpectedHoursType(value);
  };

  useEffect(() => {
    getJobDetails();
  }, [id]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const validateEditorContent = () => {
    const content = jobDescription;
    if (!content || content.trim() === "") {
      return false;
    }

    return true;
  };

  const onExpectedPayTypeChange = (value: string) => {
    setExpectedPayType(value);
  };

  const handleApplicationDeadlineChange = (e: any) => {
    setIsApplicationDeadline(e.target.value);
  };

  const predefineQuestion = predefinedQuestions;

  const handleSelectPredefinedQuestion = (id: any) => {
    const question = predefineQuestion.find((q) => q?.id === id);
    if (question) {
      setSelectedQuestions((prevQuestions) => [
        ...prevQuestions,
        { ...question, required: false, disabled: true },
      ]);
    }
  };

  const getJobDetails = async () => {
    const response = await handleGetJobDetails(id, organizationId);

    if (response.data) {
      setJobRequirements(response?.data.requirements);
      setSelectedQuestions(response?.data?.questions);

      const fetchedData = response.data;

      const fetchedHoursType = fetchedData?.basic_details?.expectedHoursType;

      const fetchedPayType = fetchedData?.basic_details?.payType;

      if (fetchedData?.deadline) {
        const deadline = moment(fetchedData?.deadline);

        setDate(deadline);
        form.setFieldsValue({
          deadlineDate: deadline,
        });
      }

      if (fetchedHoursType) {
        setExpectedHoursType(fetchedHoursType);

        if (fetchedHoursType === "Range") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            minPay: fetchedData?.basic_details?.minHours,
            maxHours: fetchedData?.basic_details?.maxHours,
          });
        } else if (fetchedHoursType === "Fixed hours") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            fixedHours: fetchedData?.basic_details?.fixedHours,
          });
        } else if (fetchedHoursType === "Maximum") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            maxOnlyHours: fetchedData?.basic_details?.maxOnlyHours,
          });
        } else if (fetchedHoursType === "Minimum") {
          form.setFieldsValue({
            expectedHoursType: fetchedHoursType,
            minOnlyHours: fetchedData?.basic_details?.minOnlyHours,
          });
        }
      }
      if (fetchedPayType) {
        setExpectedPayType(fetchedPayType);

        if (fetchedPayType === "Range") {
          form.setFieldsValue({
            payType: fetchedPayType,
            minPay: fetchedData?.basic_details?.minPay,
            maxPay: fetchedData?.basic_details?.maxPay,
          });
        } else if (fetchedPayType === "Exact amount") {
          form.setFieldsValue({
            payType: fetchedPayType,
            exactAmount: fetchedData?.basic_details?.exactAmount,
          });
        } else if (fetchedPayType === "Maximum") {
          form.setFieldsValue({
            payType: fetchedPayType,
            maximumPay: fetchedData?.basic_details?.maximumPay,
          });
        } else if (fetchedPayType === "Starting") {
          form.setFieldsValue({
            payType: fetchedPayType,
            minOnlyHours: fetchedData?.basic_details?.minimumPay,
          });
        }
      }
      if (fetchedData?.description) {
        setJobDescription(fetchedData?.description);
      }

      if (fetchedData?.requirements) {
        setJobRequirements(fetchedData?.requirements);
      }

      if (fetchedData?.basic_details) {
        form.setFieldsValue({
          applicationDeadline:
            fetchedData?.basic_details?.applicationDeadline || "no",
        });
        setIsApplicationDeadline(
          fetchedData?.basic_details?.applicationDeadline || "no"
        );
      }

      form.setFieldsValue({
        jobDescription: fetchedData?.description,
        jobTitle: fetchedData?.basic_details?.jobTitle,
        numberOfPeopleToHire: fetchedData?.basic_details?.numberOfPeopleToHire,
        jobType: fetchedData?.basic_details?.jobType,
        jobLocation: fetchedData?.basic_details?.jobLocation,
        hiringTimeline: fetchedData?.basic_details?.hiringTimeline,
      });
    }
  };

  const handleNext = async () => {
    if (currentStep == 1 && !validateEditorContent()) {
      setshowAlert(true);

      setAlertType("error");
      return;
    }
    try {
      const values = await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {}
  };

  const handleAddCustomQuestion = () => {
    if (customQuestionText) {
      if (
        (customQuestionType === "radio" || customQuestionType === "select") &&
        customQuestionOptions.length === 0
      ) {
        setshowAlert(true);

        setAlertType("error");
        return;
      }
      setSelectedQuestions([
        ...selectedQuestions,
        {
          label: customQuestionText,
          id: Date.now(),
          inputType: customQuestionType,
          options: customQuestionOptions,
          required: false,
          isCustom: true,
          isFilterable: false,
          answer: [],
        },
      ]);
      setCustomQuestionText("");
      setCustomQuestionOptions([]);
    } else {
      setshowAlert(true);

      setAlertType("error");
    }
  };

  const handleRequiredChange = (questionId: any, requiredStatus: any) => {
    const updatedQuestions = selectedQuestions?.map((q) =>
      q?.id === questionId ? { ...q, required: requiredStatus } : q
    );
    setSelectedQuestions(updatedQuestions);
  };

  const currentDate = new Date();
  const disabledDate = (current: any) => {
    return current && current < currentDate;
  };

  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;

  const jobPosterId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;

  const handleRemoveQuestion = (questionId: any) => {
    const updatedQuestions = selectedQuestions.filter(
      (q) => q.id !== questionId
    );
    setSelectedQuestions(updatedQuestions);
  };

  const collectAndSubmitFormData = async () => {
    const basic_details = form.getFieldsValue([
      "jobTitle",
      "numberOfPeopleToHire",
      "jobType",
      "payType",
      "expectedHoursType",
      "jobLocation",
      "applicationDeadline",
      "hiringTimeline",
    ]);

    const hoursData = {};
    if (expectedHoursType === "Range") {
      Object.assign(hoursData, form.getFieldsValue(["minHours", "maxHours"]));
    } else if (expectedHoursType === "Fixed hours") {
      Object.assign(hoursData, form.getFieldsValue(["fixedHours"]));
    } else if (expectedHoursType === "Maximum") {
      Object.assign(hoursData, form.getFieldsValue(["maxOnlyHours"]));
    } else if (expectedHoursType === "Minimum") {
      Object.assign(hoursData, form.getFieldsValue(["minOnlyHours"]));
    }

    const payData = {};
    if (expectedPayType === "Range") {
      Object.assign(payData, form.getFieldsValue(["minPay", "maxPay"]));
    } else if (expectedPayType === "Exact amount") {
      Object.assign(payData, form.getFieldsValue(["exactAmount"]));
    } else if (expectedPayType === "Maximum") {
      Object.assign(payData, form.getFieldsValue(["maximumPay"]));
    } else if (expectedPayType === "Starting") {
      Object.assign(payData, form.getFieldsValue(["mininumPay"]));
    }

    Object.assign(basic_details, hoursData, payData);

    if (jobDescription) {
      basic_details.jobDescription = jobDescription;
    }
    const trimmedDescription = jobDescription.trim();

    const finalData = {
      basic_details,
      questions: selectedQuestions,
      deadline: moment(date),
      organization_id: organizationId,
      company_id: companyId,
      description: trimmedDescription,
      employee_id: jobPosterId,
      requirements: jobRequirements,
    };

    try {
      const response = await handleJobDetailsUpdation(finalData, id);
      if (response.status == 201 || response.status == 204) {
        setshowAlert(true);

        setAlertType("success");
        form.resetFields();
        setJobDescription("");

        setCurrentStep(0);
        setTimeout(() => {
          onCancel();
        }, 3000);
      } else {
        setshowAlert(true);

        setAlertType("error");
      }
    } catch (error) {}
  };
  const calculateSpan = (type: any) => {
    switch (type) {
      case "Range":
        return 8;
      case "Fixed hours":
      case "Maximum":
      case "Minimum":
        return 12;
      default:
        return 24;
    }
  };

  const calculatePaySpan = (type: any) => {
    switch (type) {
      case "Range":
        return 8;
      case "Exact amount":
      case "Maximum":
      case "Starting":
        return 12;
      default:
        return 24;
    }
  };

  const steps = [
    {
      title: "Job Basics",
      content: (
        <>
          <Form layout="vertical" form={form} className="scrollable-modal">
            <Row>
              <Col span={24}>
                <Item
                  label="Job Title"
                  name={"jobTitle"}
                  rules={[
                    { required: true, message: "Please enter a job title." },
                  ]}
                >
                  <Input size="large" placeholder="Enter a Job Title." />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label="Number of people to hire for this role"
                  name={"numberOfPeopleToHire"}
                  rules={[
                    {
                      required: true,
                      message: "Please fill out this field.",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Number of people to hire..."
                    showSearch
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {peopleToHireArray.map((item: any) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label="Job type"
                  name={"jobType"}
                  rules={[
                    {
                      required: true,
                      message: "Please fill this form field.",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    placeholder="Select a job type.."
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {jobTypesArray.map((item: any) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Row gutter={[10, 10]} className="work-hours-modal-step-1">
                <Col span={calculateSpan(expectedHoursType)}>
                  <Form.Item
                    label="Work Hours/Week"
                    name="expectedHoursType"
                    rules={[
                      {
                        required: true,
                        message: "Please select the expected hours type.",
                      },
                    ]}
                  >
                    <Select
                      onChange={onExpectedHoursTypeChange}
                      allowClear
                      showSearch
                      placeholder="Select expected hours type"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {showByArray.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {expectedHoursType === "Range" && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="minHours"
                        label="Min Hrs"
                        rules={[
                          {
                            required: true,
                            message: "Please enter minimum hours.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Min Hours" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Max Hrs"
                        name="maxHours"
                        rules={[
                          {
                            required: true,
                            message: "Please enter maximum hours.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Max Hours" />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {expectedHoursType === "Fixed hours" && (
                  <Col span={12}>
                    <Form.Item
                      name="fixedHours"
                      label="Fixed Hours"
                      rules={[
                        { required: true, message: "Please enter the hours." },
                      ]}
                    >
                      <Input
                        placeholder="Hours"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedHoursType === "Maximum" && (
                  <Col span={12}>
                    <Form.Item
                      name="maxOnlyHours"
                      label="Max Hrs"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the maximum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No more than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedHoursType === "Minimum" && (
                  <Col span={12}>
                    <Form.Item
                      name="minOnlyHours"
                      label="Min Hrs"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the minimum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No less than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Col span={24}>
                <Item
                  label="Job Location"
                  name={"jobLocation"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a location",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter a city..." />
                </Item>
              </Col>

              <Row gutter={[10, 10]} className="work-hours-modal-step-1">
                <Col span={calculatePaySpan(expectedPayType)}>
                  <Form.Item
                    label="Pay"
                    name="payType"
                    rules={[
                      {
                        required: true,
                        message: "Please select the expected hours type.",
                      },
                    ]}
                  >
                    <Select
                      onChange={onExpectedPayTypeChange}
                      allowClear
                      showSearch
                      placeholder="Choose pay type"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {payArray.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {expectedPayType === "Range" && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="minPay"
                        label="Min Pay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter min pay",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Min Pay" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Max Pay"
                        name="maxPay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter max pay.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Max Pay" />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {expectedPayType === "Exact amount" && (
                  <Col span={12}>
                    <Form.Item
                      name="exactAmount"
                      label="Amount"
                      rules={[
                        { required: true, message: "Please enter the amount." },
                      ]}
                    >
                      <Input
                        placeholder="Enter amount"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedPayType === "Maximum" && (
                  <Col span={12}>
                    <Form.Item
                      name="maximumPay"
                      label="Max Pay"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the maximum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No more than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedPayType === "Starting" && (
                  <Col span={12}>
                    <Form.Item
                      name="mininumPay"
                      label="Min Pay"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the minimum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No less than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Col span={8}>
                <Item
                  label="Is there an application deadline?"
                  name={"applicationDeadline"}
                  initialValue={isApplicationDeadline}
                >
                  <Radio.Group
                    onChange={handleApplicationDeadlineChange}
                    value={isApplicationDeadline}
                  >
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                </Item>
              </Col>

              {isApplicationDeadline && isApplicationDeadline === "yes" && (
                <Col span={16}>
                  <Item label="Select a date" name={"deadlineDate"}>
                    <DatePicker
                      className="job-post-edit-job-modal-date-picker-step-1"
                      disabledDate={disabledDate}
                      onChange={(date, dateString) => setDate(date)}
                      value={date ? dayjs(date, "YYYY-MM-DD") : null}
                    />
                  </Item>
                </Col>
              )}
              <Col span={24}>
                <Item
                  label="Hiring timeline for this job"
                  name={"hiringTimeline"}
                  rules={[
                    {
                      required: true,
                      message: "Please fill out this detail",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Hiring timeline for this job..."
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {hiringTimelineArray.map((item: any) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
          </Form>
          <Col span={24} className="global-modal-bottom-row next-btn-step-1">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleNext}
                size="large"
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </>
      ),
    },
    {
      title: "Job Description",
      content: (
        <>
          <Row>
            <Col span={24}>
              <div className="editor-container scrollable-modal">
                <ReactQuill
                  value={jobDescription}
                  theme="snow"
                  style={{ height: "350px" }}
                  onChange={setJobDescription}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Col>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                  type="default"
                >
                  Previous
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  onClick={handleNext}
                  size="large"
                  type="primary"
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Requirements",
      content: (
        <>
          <Row>
            <Col span={24}>
              <div className="editor-container scrollable-modal">
                <ReactQuill
                  value={jobRequirements}
                  theme="snow"
                  style={{ height: "350px" }}
                  onChange={setJobRequirements}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Col>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                  type="default"
                >
                  Previous
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  onClick={handleNext}
                  size="large"
                  
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Applicants Screening",
      content: (
        <>
          <Row className="edit-job-modal-step-4-container scrollable-modal">
            <Col span={24}>
              <div className="predefined-questions-container">
                {predefinedQuestions.map((question) => (
                  <Button
                    key={question?.id}
                    onClick={() => handleSelectPredefinedQuestion(question.id)}
                    disabled={selectedQuestions.some(
                      (q) => q.id === question.id && q.disabled
                    )}
                    className="predefined-question-btn"
                  >
                    {question.label}
                  </Button>
                ))}
              </div>
            </Col>
            <Col span={24}>
              <Input
                value={customQuestionText}
                onChange={(e) => setCustomQuestionText(e.target.value)}
                placeholder="Custom Question"
                className="custom-question-input"
                size="large"
              />
            </Col>
            <Col span={24}>
              <Row gutter={10} className="custom-questions-container">
                <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24}>
                  <Select
                    defaultValue="input"
                    showSearch
                    placeholder="Select an option..."
                    onChange={setCustomQuestionType}
                    className="custom-question-select"
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    <Select.Option value="input">Input Field</Select.Option>
                    <Select.Option value="radio">Radio Buttons</Select.Option>
                    <Select.Option value="select">Select Field</Select.Option>
                    <Select.Option value="textarea">Text Area</Select.Option>
                  </Select>
                </Col>
                {customQuestionType === "radio" ||
                customQuestionType === "select" ? (
                  <Col xxl={12} xl={12} lg={8} md={8} sm={24} xs={24}>
                    <Select
                      showSearch
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Add options"
                      value={customQuestionOptions}
                      onChange={setCustomQuestionOptions}
                      tokenSeparators={[","]}
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    />
                  </Col>
                ) : null}
                <Col xxl={6} xl={6} lg={8} md={8} sm={24} xs={24}>
                  <Button
                    onClick={handleAddCustomQuestion}
                    className="add-custom-question-btn global-primary-btn global-btn-width"
                  >
                    Add Custom Question
                  </Button>
                </Col>
              </Row>
            </Col>
            <div>
              <div className="edit-job-step-4-custom-questions-heading">
                <span className="job-editing-questions-heading">
                  {" "}
                  Questions for this post!
                </span>
              </div>
              <div>
                <Row gutter={10}>
                  {selectedQuestions.map((question, key) => (
                    <Col span={24}>
                      <div className="job-editing-step-4-selected-questions">
                        <div key={question.id}>
                          <div className="question-heading">
                            <span className="question-key-style">
                              Q{key + 1}:
                            </span>{" "}
                            {question.label}
                          </div>
                          <div className="question-points">
                            {(question.inputType === "radio" ||
                              question.inputType === "select") &&
                              question.options && (
                                <ul>
                                  {question.options.map(
                                    (option: any, index: any) => (
                                      <li key={index}>{option}</li>
                                    )
                                  )}
                                </ul>
                              )}
                          </div>
                          <Row className="edit-job-step-4-cards-btn">
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              lg={6}
                              xl={6}
                              xxl={6}
                              className="job-post-selected-questions-checkbox-container"
                            >
                              <Button
                                className="global-default-btn custom-primary-btn-width job-post-step-4-remove-btn"
                                onClick={() =>
                                  handleRemoveQuestion(question.id)
                                }
                              >
                                Remove
                              </Button>

                              <Checkbox
                                checked={question.required}
                                onChange={(e) =>
                                  handleRequiredChange(
                                    question.id,
                                    e.target.checked
                                  )
                                }
                              >
                                Required
                              </Checkbox>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Col>
              <Form.Item>
                <Button
                  className="global-default-btn global-btn-width"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                >
                  Previous
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  className="global-primary-btn global-btn-width"
                  onClick={() => collectAndSubmitFormData()}
                  size="large"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      width={1000}
      className="global-modal-main-container"
      title={<span className="global-modal-title">Edit Job Details</span>}
    >
      <div className="global-card-inner-container">
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <div className="global-steps-container-margin">
          {steps[currentStep].content}
        </div>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </Modal>
  );
};

export default EditJobSteps;
