
export const  customunits:  string[]= [
    "Meters", "Kilometers", "Miles", "Feet", "Inches", "Centimeters", "Millimeters",
    "Kilograms", "Grams", "Pounds", "Ounces", "Stones",
    "Liters", "Milliliters", "Gallons", "Quarts", "Pints", "Cups", "Fluid Ounces",
    "Square Meters", "Square Kilometers", "Square Miles", "Acres", "Hectares",
    "Celsius", "Fahrenheit", "Kelvin",
    "Bytes", "Kilobytes", "Megabytes", "Gigabytes", "Terabytes",
    "Bits", "Kilobits", "Megabits", "Gigabits", "Terabits"

];
