import React, { useState } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { FaBug, FaExclamationCircle } from "react-icons/fa";
import ComplaintReporting from "..";
import BugReporting from "../BugReporting";
import "./index.css"; // Ensure this imports your custom CSS

const ReportingDashboard:React.FC = () => {
  const [view, setView] = useState<string>("Complaint");

  return (
    <div>
      <Row gutter={[12, 12]} className="status-btns-row">
        <Col xs={12} sm={12} md={12}>
          <Tooltip title="Click to view complaint reporting">
            <Button
              size="large"
              className="global-btn-width"
              style={{ width: "100%", whiteSpace: "normal", height: "auto" }}
              onClick={() => setView("Complaint")}
              icon={<FaExclamationCircle />}
              type={
                view === "Complaint" ? "primary" : "default"
              }
            >
              <span className="button-text">Complaint</span>
            </Button>
          </Tooltip>
        </Col>
        <Col xs={12} sm={12} md={11}>
          <Tooltip title="Click to view bug reporting">
            <Button
              size="large"
              className="global-btn-width"
              style={{ width: "100%", whiteSpace: "normal", height: "auto" }}
              onClick={() => setView("Bug")}
              icon={<FaBug />}
              type={
                view === "Bug" ? "primary" : "default"
              }
            >
              <span className="button-text">Bug</span>
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {view === "Complaint" && <ComplaintReporting />}
      {view === "Bug" && <BugReporting />}
    </div>
  );
};

export default ReportingDashboard;
