import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Button, Select, Table } from "antd";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../../common/Alert";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  createSkills,
  getAllTheEmployeesNotPresentInSkillsTable,
  getBranchesAgainstCompany,
  getCompaniesForOrganization,
} from "../services";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const SkillCreateForm: React.FC<props> = ({ onCancel }) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [branches, setBranches] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setselectedBranch] = useState<any>(null);
  const [employees, setEmployees] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const loggedInUser = useSelector(selectLogedEmployee)?.logedInemployee;
  const organizationId = loggedInUser?.employee?.organization_id;
  const [form] = useForm();

  useEffect(() => {
    setTableData([]);
  }, [selectedEmployee]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    listOfEmployees();
  }, [
    selectedCompany,
    selectedBranch,
    loggedInUser?.employee?.company_id,
    loggedInUser?.employee?.branch_id,
  ]);

  useEffect(() => {
    listOfCompanies();
  }, []);

  useEffect(() => {
    listOfBranches();
    if (!selectedCompany) {
      setBranches([]);
    }
  }, [selectedCompany]);

  //This function fetches the complete list of Employees based on the provided constraints i.e. Company ID or Branch ID and by default Organization ID
  const listOfEmployees = async () => {
    const Ids = {
      organizationId: loggedInUser?.employee?.organization_id,
      companyId: loggedInUser?.employee?.company_id,
      branchId: loggedInUser?.employee?.branch_id,
      selectedCompany: selectedCompany,
      selectedBranch: selectedBranch,
    };
    const response = await getAllTheEmployeesNotPresentInSkillsTable(Ids);

    if (response.data) {
      setEmployees(response.data);
    }
  };

  const listOfCompanies = async () => {
    const response = await getCompaniesForOrganization(organizationId);

    if (response.data) {
      setCompanies(response.data);
    }
  };

  const listOfBranches = async () => {
    const response = await getBranchesAgainstCompany(
      selectedCompany,
      organizationId
    );

    if (response.data) {
      setBranches(response.data);
    }
  };

  const handleAddToTable = () => {
    form
      .validateFields()
      .then(() => {
        const newRecord = {
          id: Date.now(),
          skill: form.getFieldValue("skill"),
          experience: form.getFieldValue("yearsOfExperience"),
          comment: form.getFieldValue("comments"),
        };

        setTableData([...tableData, newRecord]);
      })
      .catch((errorInfo) => {
        return errorInfo;
      });
  };

  const handleSkillsCreation = async () => {
    const body = {
      employee_id: selectedEmployee,
      organization_id: loggedInUser?.employee.organization_id,
      company_id: loggedInUser?.employee.company_id,
      branch_id: loggedInUser?.employee.branch_id,
      skill_details: tableData,
    };
    if (tableData.length == 0) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      const response = await createSkills(body);

      if (response.status == 201 || response.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        form.resetFields();
        setTableData([]);
        onCancel();
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
    }
  };
  const handleRemoveFromTable = async (recordId: any) => {
    const filteredRecords = tableData.filter(
      (item: any) => item.id !== recordId
    );
    setTableData(filteredRecords);
  };

  const columns = [
    {
      title: "Skills",
      dataIndex: "skill",
      key: "skill",
    },
    {
      title: "Years of Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <a>
          <DeleteOutlined onClick={() => handleRemoveFromTable(record.id)} />
        </a>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" autoComplete="off" form={form}>
        {!loggedInUser?.employee.company_id && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a company..."
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty.",
                  },
                ]}
              >
                <Select
                  placeholder="Select a company..."
                  onChange={setSelectedCompany}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"selectedBranch"}
                label="Please choose a branch..."
              >
                <Select
                  placeholder="Select a branch..."
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setselectedBranch}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="employeeid"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Employees"
                optionFilterProp="children"
                onChange={setSelectedEmployee}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {employees &&
                  employees.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Skill"
              name="skill"
              rules={[{ required: true, message: "Please Enter Skill!" }]}
            >
              <Input size="large" placeholder="Enter Skill" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Years of Experience"
              name="yearsOfExperience"
              rules={[
                {
                  required: true,
                  message: "Please Enter Years of Experience!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Year of Experience"
                suffix={"years"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Comments" name="comments">
              <Input.TextArea placeholder="Enter Comment..." />
            </Form.Item>
          </Col>
        </Row>
        <Row className="add-btn">
          <Col span={24} className="save-profile-btn">
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleAddToTable}
              size="large"
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form>
      <CustomTable
        className="skill-table-class"
        columns={columns}
        dataSource={tableData}
      />
      <Row className="global-modal-bottom-row last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSkillsCreation}
              size="large"
            >
              Create
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default SkillCreateForm;
