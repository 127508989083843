import React from "react";
import RequestComp from "./view";
import AssessmentComp from "./view";

const AssessmentModule: React.FC = () => {
  return (
    <div>
      <AssessmentComp />
    </div>
  );
};

export default AssessmentModule;
