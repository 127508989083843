import { Card, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import "../index.css";
import { ResponsiveLine } from "@nivo/line";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import dayjs from "dayjs";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import { ProgressLoader } from "../../../../common/ReactLoader";

const TotalYearExpense: React.FC = () => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [chartData, setChartData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getEmployeeSalarySummary = async (year: number) => {
    setIsLoading(true);
    try {
      const promises = monthsArray.map(async (month, index) => {
        const startDate = new Date(year, index, 1).toISOString();
        const endDate = new Date(year, index + 1, 0, 23, 59, 59).toISOString();

        const response = await supabase_client_for_Org_schema(organizationId)
          .from("employee_salary_history")
          .select("*")
          .eq("organization_id", organizationId)
          .gte("created_at", startDate)
          .lte("created_at", endDate);

        return response.data;
      });

      const results = await Promise.all(promises);
      const monthlySalaries = results.map((monthData, index) => {
        const totalNetSalary = monthData
          ? monthData.reduce(
              (acc, employee) => acc + parseFloat(employee.net_salary),
              0
            )
          : 0;
        return {
          x: monthsArray[index],
          y: totalNetSalary,
        };
      });
      setChartData([
        {
          id: "Net Salary",
          color: "hsl(200, 70%, 50%)",
          data: monthlySalaries,
        },
      ]);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeSalarySummary(selectedYear);
  }, [selectedYear]);

  const handleYearChange = (date: any, dateString: string | string[]) => {
    const year = Array.isArray(dateString)
      ? parseInt(dateString[0], 10)
      : parseInt(dateString, 10);
    setSelectedYear(year);
  };

  const numberWithCommas = (value: number) => value.toLocaleString();

  const hasSalaries =
    chartData.length > 0 && chartData[0].data.some((d: any) => d.y > 0);

  return (
    <div className="payroll-dashboard-total-expense-container">
      <Card
        title={
          <div className="payroll-dashboard-total-expense-card-header">
            <div className="payroll-dashboard-total-expense-title-container">
              <span>Yearly Expenses</span>
            </div>
            <div>
              <DatePicker
                picker="year"
                defaultValue={dayjs()}
                onChange={handleYearChange}
              />
            </div>
          </div>
        }
        className="payroll-dashboard-total-expense"
      >
        {isLoading ? (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader />
          </div>
        ) : hasSalaries ? (
          <div style={{ height: 350 }}>
            <ResponsiveLine
              data={chartData}
              margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat={(value) => numberWithCommas(Number(value))}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Month",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: -20,
                tickRotation: 0,
                legend: "Net Salary",
                legendOffset: -55,
                legendPosition: "middle",
                truncateTickAt: 0,
                format: (value) => numberWithCommas(Number(value)),
              }}
              pointSize={10}
              colors={"var(--green-color-primary-light)"}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="data.yFormatted"
              pointLabelYOffset={-12}
              enableTouchCrosshair={true}
              useMesh={true}
              
            />
          </div>
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#888",
            }}
          >
            <span>
              😕 No salary data available for the selected year. Please try a
              different year.
            </span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default TotalYearExpense;
