import { Row, Form, Col, Select, Button, Space, Popover, Table } from "antd";
import CustomAlert from "../../../common/Alert";
import React, { useEffect, useRef, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import "./index.css";
import { fetchAllEmployeebyOrganizationComapnyorBranchId } from "../../../services/Global";
import {
  updateBranchHeadMangerbyIdApi,
  updateDepartmentHeadMangerbyIdApi,
  updateEmployeeHeadMangerbyIdApi,
  updateTeamHeadMangerbyIdApi,
} from "./services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import CustomTable from "../../../common/CustomTable";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
interface Deleteprops {
  record: any;
  headid?: number | string;
  onCancel: () => void;
  comapnayDetails?: any;
}



const EmployeeDeleteModel: React.FC<Deleteprops> = (props) => {

  const [updetedobjeId, setUpdatedObjectId] = useState<string>(); // can be branch id , deparmentid, employeeId  we need to update record
  const [selectRecordId, setSelectedRecordId] = useState<string>();
  const [showEditModel, setEditModel] = useState<boolean>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [option, setOption] = useState<any[]>([]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  useEffect(() => {
    fetchOptions();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [props.headid]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchOptions = async () => {
    const options_res = await fetchAllEmployeebyOrganizationComapnyorBranchId(
      props?.comapnayDetails?.organizationId,
      props?.comapnayDetails?.company_id,
      props?.comapnayDetails?.branch_id
    );
    if (options_res.data) {
      setOption(options_res.data);
    }
  };
  const handleDepartmentUpdate = async (values: any) => {
    setshowAlert(false);
    const data = {
      managerId: values.selectedHead,
      recordId: updetedobjeId,
    };
    const department_res = await updateDepartmentHeadMangerbyIdApi(data, loggedInEmployee?.employee?.organization_id);
    if (department_res.error) {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    } else if (department_res.status === 204) {
      setshowAlert(true);
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      setUpdatedObjectId("");
      setEditModel(false);
    }
  };
  const handleTeamHeadUpdate = async (values: any) => {
    setshowAlert(false);
    try {
      const data = {
        managerId: values.selectedHead,
        recordId: updetedobjeId,
      };
      const team_res = await updateTeamHeadMangerbyIdApi(data, loggedInEmployee?.employee?.organization_id);
      if (team_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (team_res.status === 204) {
        setshowAlert(true);

        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
        setUpdatedObjectId("");
        setEditModel(false);
      }
    } catch {
      setshowAlert(true);

      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  const handleBranchHeadUpdate = async (values: any) => {
    setshowAlert(false);
    try {
      const data = {
        managerId: values.selectedHead,
        recordId: updetedobjeId,
      };
      const team_res = await updateBranchHeadMangerbyIdApi(data, loggedInEmployee?.employee?.organization_id);
      if (team_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (team_res.status === 204) {
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
        setUpdatedObjectId("");
        setEditModel(false);
      }
    } catch {
      setshowAlert(true);

      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  const handleEmployeeHeadUpdate = async (values: any) => {
    setshowAlert(false);
    try {
      const data = {
        managerId: values.selectedHead,
        recordId: updetedobjeId,
      };
      const team_res = await updateEmployeeHeadMangerbyIdApi(data, loggedInEmployee?.employee?.organization_id);
      if (team_res.error) {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else if (team_res.status === 204) {
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
        setUpdatedObjectId("");
        setEditModel(false);
      }
    } catch {
      setshowAlert(true);

      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const columns_department = [
    {
      title: "Branch",
      dataIndex: "head_branch_name",
      key: "head_branch_name",
      render: (record: any) => <div>{record}</div>,
    },
    {
      title: "Department Name",
      dataIndex: "name_of_head_of_department",
      key: "name_of_head_of_department",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Popover
            title="Update Details"
            trigger="click"
            onOpenChange={() => {
              setUpdatedObjectId(record?.department_head);
              setSelectedRecordId(record?.uniqueKey);
              setEditModel(true);
            }}
            open={showEditModel && record?.uniqueKey == selectRecordId}
            content={
              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={handleDepartmentUpdate}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Select New Head"
                      name="selectedHead"
                      rules={[
                        {
                          required: true,
                          message: "New Head Is Required!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select head..."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {option?.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.full_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="employee-delete-model-last-row">
                  <Col
                    span={24}
                    className="employee-delete-model-save-profile-btn "
                  >
                    <Form.Item className="employee-delete-model-button-mergin">
                      <Button
                        type="default"
                        onClick={() => {
                          setUpdatedObjectId("");
                          setEditModel(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
          >
            <a>
              <EditOutlined />
            </a>
          </Popover>
        </Space>
      ),
    },
  ];
  const columns_teams = [
    {
      title: "Team",
      dataIndex: "head_team_name",
      key: "head_team_name",
    },
    {
      title: "Branch",
      dataIndex: "head_branch_name",
      key: "head_branch_name",
      render: (record: any) => <div>{record}</div>,
    },
    {
      title: "Department Name",
      dataIndex: "name_of_head_of_department",
      key: "name_of_head_of_department",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Popover
            title="Update Details"
            trigger="click"
            onOpenChange={() => {
              setUpdatedObjectId(record?.team_head);
              setSelectedRecordId(record?.uniqueKey);
              setEditModel(true);
            }}
            open={showEditModel && record?.uniqueKey == selectRecordId}
            content={
              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={handleTeamHeadUpdate}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Select New Head"
                      name="selectedHead"
                      rules={[
                        {
                          required: true,
                          message: "New Head Is Required!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select head..."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {option?.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.full_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="employee-delete-model-last-row">
                  <Col
                    span={24}
                    className="employee-delete-model-save-profile-btn "
                  >
                    <Form.Item className="employee-delete-model-button-mergin">
                      <Button
                        type="default"
                        onClick={() => {
                          setUpdatedObjectId("");
                          setEditModel(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
          >
            <a>
              <EditOutlined />
            </a>
          </Popover>
        </Space>
      ),
    },
  ];
  const columns_branch = [
    {
      title: "Branch",
      dataIndex: "head_branch_name",
      key: "head_branch_name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Popover
            title="Update Details"
            trigger="click"
            visible={showEditModel}
            onOpenChange={() => {
              setUpdatedObjectId(record?.branchhead);
              setSelectedRecordId(record?.uniqueKey);
              setEditModel(true);
            }}
            open={showEditModel && record?.uniqueKey == selectRecordId}
            content={
              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={handleBranchHeadUpdate}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Select New Head"
                      name="selectedHead"
                      rules={[
                        {
                          required: true,
                          message: "New Head Is Required!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select head..."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {option?.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.full_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="employee-delete-model-last-row">
                  <Col
                    span={24}
                    className="employee-delete-model-save-profile-btn "
                  >
                    <Form.Item className="employee-delete-model-button-mergin">
                      <Button
                        type="default"
                        onClick={() => {
                          setUpdatedObjectId("");
                          setEditModel(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
          >
            <a>
              <EditOutlined />
            </a>
          </Popover>
        </Space>
      ),
    },
  ];
  const columns_employess = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (record: any) => <div>{record.name ? record.name : ""}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Popover
            title="Update Details"
            trigger="click"
            visible={showEditModel}
            onOpenChange={() => {
              setUpdatedObjectId(record?.id);
              setSelectedRecordId(record?.uniqueKey);
              setEditModel(true);
            }}
            open={showEditModel && record?.uniqueKey == selectRecordId}
            content={
              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={handleEmployeeHeadUpdate}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="Select New Head" name="selectedHead">
                      <Select
                        placeholder="Select head..."
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {option?.map((item: any) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.full_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="employee-delete-model-last-row">
                  <Col
                    span={24}
                    className="employee-delete-model-save-profile-btn "
                  >
                    <Form.Item className="employee-delete-model-button-mergin">
                      <Button
                        type="default"
                        onClick={() => {
                          setUpdatedObjectId("");
                          setEditModel(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            }
          >
            <a>
              <EditOutlined />
            </a>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <div className="delete-employee">
      {props?.record?.team_head?.length > 0 && (
        <div>
          <div className="inactive-modal-heading-margin">
            <strong>
              You can't in-active this employee because he/she is head of
              following team's
            </strong>
          </div>
          <div>
            <CustomTable
              loading={props?.record?.team_head ? false : true}
              dataSource={props?.record?.team_head}
              columns={columns_teams}
            />
          </div>
        </div>
      )}
      {props?.record?.department_head?.length > 0 && (
        <div>
          <div className="inactive-modal-heading-margin">
            <strong >
              You can't in-active this employee because he/she is head of
              following departments
            </strong>
          </div>
          <div>
            <CustomTable
              loading={props?.record?.department_head ? false : true}
              dataSource={props?.record?.department_head}
              columns={columns_department}
            />
          </div>

        </div>
      )}
      {props?.record?.branch_head?.length > 0 && (
        <div>
           <div className="inactive-modal-heading-margin">
          <strong >
            You can't in-active this employee because he/she is head of
            following branches
          </strong>
          </div>
          <div>
          <CustomTable
            loading={props?.record?.branch_head ? false : true}
            dataSource={props?.record?.branch_head}
            columns={columns_branch}
          />
          </div>
        </div>
      )}
      {props?.record?.report_to_Employee?.length > 0 && (
        <div>
           <div className="inactive-modal-heading-margin">
          <strong>
            You can't in-active this employee because he/she is head of
            following employees
          </strong>
          </div>
          <div>
          <CustomTable
            loading={props?.record?.report_to_Employee ? false : true}
            dataSource={props?.record?.report_to_Employee}
            columns={columns_employess}
          />
            </div>
        </div>
      )}
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default EmployeeDeleteModel;
