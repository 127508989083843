import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import { DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { GrPowerReset } from "react-icons/gr";
import { RiUserSearchFill } from "react-icons/ri";
import "./index.css";
import FiltersModal from "./FiltersModal";
import { IoCheckmarkDone } from "react-icons/io5";
import CustomAlert from "../../../common/Alert";
import ApplicationSubmissionViewModal from "../ApplicationSubmissionViewModal";
import ScreenWidth from "../../../common/ScreenWidth";
import { CgDetailsMore } from "react-icons/cg";
import {
  deleteCorrespondingRecord,
  deleteResume,
  downloadFilesFromSupabase,
  handleAcceptedApplications,
  retrieveApplicationDetails,
  retrieveJobFilters,
} from "../services";
import CustomTable from "../../../common/CustomTable";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

const JobApplication = () => {
  const [jobApplications, setJobApplications] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>(null);
  const [counter, setCounter] = useState<number>(0);
  const [resumeURLs, setResumeURLs] = useState<any[]>([]);
  const [showalert, setShowalert] = useState<boolean>(false);
  const [jobFilters, setJobFilters] = useState<any>(null);
  const screenwidth = ScreenWidth();
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [jobApplicationStatusFilter, setJobApplicationStatusFilter] =
    useState<string>("");
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const [
    isjobApplicationStatusFilterModalOpen,
    setisjobApplicationStatusFilterModalOpen,
  ] = useState<boolean>(false);

  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [applicantId, setApplicantId] = useState<number>(0);
  const getTheId = window.location.href;
  const splitTheId = getTheId.split("/");
  const id = splitTheId.length - 1;
  const JobId = splitTheId[id];

  useEffect(() => {
    getJobFilters();
  }, [counter]);
  useEffect(() => {
    getJobDetails();
  }, [JobId, jobApplicationStatusFilter]);
  useEffect(() => {
    if (showalert) {
      setTimeout(() => {
        setShowalert(false);
      }, 3000);
    }
  }, [showalert]);
  const showjobApplicationStatusFilterModalModal = () => {
    setisjobApplicationStatusFilterModalOpen(true);
  };
  const closejobApplicationStatusFilterModalModal = () => {
    setisjobApplicationStatusFilterModalOpen(false);
  };

  const handleRadioChangeForJobApplFilter = (e: any) => {
    setJobApplicationStatusFilter(e.target.value);
    setisjobApplicationStatusFilterModalOpen(false);
  };

  const hideViewApplicationModal = () => {
    setApplicationModalVisible(false);
  };
  const showViewApplicationModal = (id: any) => {
    setApplicantId(id);
    setApplicationModalVisible(true);
  };

  function downloadBlob(blob: any, fileName: any) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleDownloadResume = async (resumeURL: any) => {
    const splittedResume = resumeURL.split("/");
    const indexOfLastValue = splittedResume.length - 1;
    const lastValue = splittedResume[indexOfLastValue];
    const response = await downloadFilesFromSupabase(lastValue);
    if (response.data instanceof Blob) {
      downloadBlob(response.data, lastValue);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: any) =>
        record?.email && <span className="email-column">{record?.email}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => (
        <Space size={"middle"}>
          <Tooltip title="Download Resume">
            <FaFileDownload
              onClick={() => handleDownloadResume(record.resume_url)}
            />{" "}
          </Tooltip>
          <Tooltip title="View Application Details">
            <EyeOutlined onClick={() => showViewApplicationModal(record.id)} />{" "}
          </Tooltip>
          <Popconfirm
            placement="topLeft"
            okText="Ok"
            cancelText="Cancel"
            title="Are you sure you want to delete this Job?"
            onConfirm={() => handleDeleteResume(record)}
          >
            <DeleteOutlined />
          </Popconfirm>
          <Tooltip title="Click to accept application">
            {" "}
            <IoCheckmarkDone
              onClick={() => handleApplicationAcceptance(record.id)}
            />{" "}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getJobDetails = async () => {
    setIsLoading(true);
    const finalResponse = await retrieveApplicationDetails(
      JobId,
      searchInput,
      jobApplicationStatusFilter,
      organizationId
    );
    if (finalResponse.data) {
      setJobApplications(finalResponse.data);
      setIsLoading(false);
      const resumeURLS = finalResponse?.data?.map(
        (item: any) => item.resume_url
      );
      setResumeURLs(resumeURLS);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApplicationAcceptance = async (recordId: any) => {
    const response = await handleAcceptedApplications(recordId, organizationId);
    if (response.status == 201 || response.status == 204) {
      setShowalert(true);
      setAlertType("success");
      getJobDetails();
    }
  };

  const getJobFilters = async () => {
    const response = await retrieveJobFilters(JobId, organizationId);
    if (response.data) {
      setJobFilters(response.data);
    } else if (response.error?.message) {
    }
  };

  const filterJobApplications = (jobApplications: any, jobFilters: any) => {
    const filteredApplications = jobApplications?.filter((application: any) => {
      let matchCount = 0;

      jobFilters.filters?.forEach((filter: any) => {
        const answer = application.answer?.find(
          (a: any) => a.id === filter.questionId
        );
        if (!answer) {
          return;
        }
        const filterValue = filter.value;
        const answerValue = answer.answer[0];
        if (Array.isArray(filterValue)) {
          const isMatch = filterValue?.some((val) =>
            answerValue?.includes(val)
          );
          if (isMatch) {
            matchCount++;
          }
        } else {
          if (filterValue?.toString() === answerValue?.toString()) {
            matchCount++;
          }
        }
      });

      return matchCount >= 2;
    });

    return filteredApplications;
  };

  const applyFilters = () => {
    setIsLoading(true);
    if (jobFilters && jobApplications) {
      const filteredJobApplications = filterJobApplications(
        jobApplications,
        jobFilters
      );
      setJobApplications(filteredJobApplications);
    }
    setIsLoading(false);
  };

  const handleDeleteResume = async (record: any) => {
    const splitURL = record?.resume_url?.split("/");
    const getLastIndex = splitURL.length - 1;
    const finalFileName = splitURL[getLastIndex];
    const decodedFileName = decodeURIComponent(finalFileName);

    const finalResponse = await deleteResume(decodedFileName);

    if (finalResponse.error) {
      setShowalert(true);

      setAlertType("error");
    } else {
      const response = await deleteCorrespondingRecord(
        record.id,
        organizationId
      );

      if (response.status == 201 || response.status == 204) {
        setShowalert(true);

        setAlertType("success");
        getJobDetails();
      }
    }
  };

  return (
    <>
      <Card className="job-application-card-class">
        <div className="job-applications-main-container">
          <Typography.Title level={3}>Job Applicants</Typography.Title>
          {screenwidth > 1216 ? (
            <Row gutter={[16, 16]} className="jopapplication-searching-row">
              <Col xxl={8} xl={8} lg={4} md={24} sm={24} xs={24}>
                <Input
                  placeholder="Enter candidate name..."
                  value={searchInput}
                  size="large"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onPressEnter={getJobDetails}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xxl={16} xl={16} lg={16} md={24} sm={24} xs={24}>
                <div className="filter-btns-container">
                  <Space>
                    <Button
                      className="banks-search-button banks-btn-view custom-primary-btn"
                      icon={<RiUserSearchFill />}
                      onClick={handleOpenModal}
                    >
                      Configure Filters
                    </Button>
                    <Button
                      className=" global-default-btn"
                      icon={<GrPowerReset />}
                      onClick={applyFilters}
                    >
                      Apply Filters
                    </Button>
                    <Button
                      type="default"
                      icon={<GrPowerReset />}
                      onClick={getJobDetails}
                    >
                      Remove Filters
                    </Button>
                    <Button
                      type="default"
                      onClick={showjobApplicationStatusFilterModalModal}
                    >
                      Set Job Status
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          ) : (
            <Row gutter={[16, 16]} className="jopapplication-searching-row">
              <Col xxl={8} xl={8} lg={20} md={20} sm={20} xs={20}>
                <Input
                  placeholder="Enter candidate name..."
                  value={searchInput}
                  size="large"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onPressEnter={getJobDetails}
                  prefix={<SearchOutlined />}
                />
              </Col>
              <Col xxl={16} xl={16} lg={4} md={4} sm={4} xs={4}>
                <div>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="ConfigureFilters"
                          onClick={handleOpenModal}
                          icon={<RiUserSearchFill />}
                          title="Configure Filters"
                        >
                          Configure Filters
                        </Menu.Item>

                        <Menu.Item
                          key="Apply Filters"
                          onClick={applyFilters}
                          icon={<GrPowerReset />}
                          title="Apply Filters"
                        >
                          Apply Filters
                        </Menu.Item>

                        <Menu.Item
                          onClick={getJobDetails}
                          icon={<GrPowerReset />}
                          title="Remove Filters"
                          key={"Remove Filters"}
                        >
                          Remove Filters
                        </Menu.Item>

                        <Menu.Item
                          onClick={showjobApplicationStatusFilterModalModal}
                          key={"Set Job Status"}
                          icon={<RiUserSearchFill />}
                          title="Set Job Status"
                        >
                          Set Job Status
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <CgDetailsMore className="employee-view-top-row-more-outlined" />
                  </Dropdown>
                </div>
              </Col>
            </Row>
          )}

          <Modal
            title="Set Job Status"
            open={isjobApplicationStatusFilterModalOpen}
            onCancel={closejobApplicationStatusFilterModalModal}
            footer={false}
          >
            <Radio.Group
              onChange={handleRadioChangeForJobApplFilter}
              value={jobApplicationStatusFilter}
            >
              <Space direction="vertical">
                <Radio value="">All</Radio>
                <Radio value="submitted">Submitted</Radio>
                <Radio value="shortlisted">ShortListed</Radio>
              </Space>
            </Radio.Group>
          </Modal>
          <CustomTable
            columns={columns}
            dataSource={jobApplications}
            loading={isLoading}
          />
          <FiltersModal
            id={JobId}
            onCancel={handleCloseModal}
            visible={isModalOpen}
          />
        </div>
      </Card>
      {showalert && <CustomAlert type={alertType} showAlert={showalert} />}
      <ApplicationSubmissionViewModal
        id={applicantId}
        visible={applicationModalVisible}
        onClose={hideViewApplicationModal}
      />
    </>
  );
};

export default JobApplication;
