import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "./index.css";
import "react-international-phone/style.css";
import React, { useState } from "react";
import { Steps, Button, Form, Input, Row, Col, Upload, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import infoSvg from "../../../../assets/svgs/info.svg";
import {
  getPublicURLForLogo,
  handleCompanyLogoUploading,
  handleDataSubmission,
  handleDefaultTemplateDataSetup,
} from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import d_dp from "../../../../assets/images/blankProfile.png";

const { Step } = Steps;

interface props {
  onCancel: (status: string) => void;
}

const CreateCompany: React.FC<props> = ({ onCancel }) => {
  const { TextArea } = Input;
  const [currentStep, setCurrentStep] = useState(0);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const loggedInemployee = useSelector(selectLogedEmployee);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(
    null
  );
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    companyName: "",
    area_of_focus: "",
    location: "",
    logo: "",
    headManagerName: "",
    phoneNumber: "",
    mission: " ",
    email: "",
  });

  const [uniqueCompanyId, setUniqueCompanyId] = useState<string>(
    "Generating a Unique Company Id..."
  );
  const phoneUtil = PhoneNumberUtil.getInstance();
  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
    if (
      field === "companyName" ||
      field === "area_of_focus" ||
      field === "location"
    ) {
      const newCompanyId =
        field === "companyName" ? value : formData.companyName;
      const newAreaOfFocus =
        field === "area_of_focus" ? value : formData.area_of_focus;
      const newLocation = field === "location" ? value : formData.location;
      setUniqueCompanyId(`${newCompanyId}-${newAreaOfFocus}-${newLocation}`);
    }
  };

  const handleSubmit = async (defaultTemplateData: boolean) => {
    setButtonLoading(true);

    const fileName = selectedFile?.name;
    const currentTime = new Date().toLocaleString();
    const logEntry = `File: ${fileName}, Time: ${currentTime}`;
    const file = selectedFile;
    let logoPublicUrl = "";
    if (selectedFile) {
      const logoUploading = await handleCompanyLogoUploading(logEntry, file);
      if (logoUploading) {
        const getURL = await getPublicURLForLogo(logEntry);
        logoPublicUrl = getURL?.data?.publicUrl || "";
      }
    }

    const data = {
      name: formData.companyName,
      area_of_focus: formData.area_of_focus,
      location: formData.location,
      phone_number: formData.phoneNumber,
      email: formData.email,
      mission: formData.mission,
      organization_Id:
        loggedInemployee.logedInemployee?.employee.organization_id,
      logo: logoPublicUrl,
      unique_identifier: uniqueCompanyId,
    };

    try {
      const res = await handleDataSubmission(data, organizationId);
      if (res) {
        if (res?.data) {
          const companyId = res?.data[0]?.id;

          if (defaultTemplateData === true) {
            await handleDefaultTemplateDataSetup(
              loggedInemployee.logedInemployee?.employee.organization_id,
              companyId
            );
          }
        }
        setButtonLoading(false);
        onCancel("success");
        setSelectedFile(null);
        setPreviewImage(null);
        form.resetFields();
        setUniqueCompanyId("Generating a Unique Company Id...");
        setCurrentStep(0);
      } else {
        setButtonLoading(false);
      }
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const nextStep = async () => {
    try {
      if (currentStep == 1) {
        const phoneNumber = form.getFieldValue("phoneNumber");
        const phoneNumberLength = phoneNumber ? phoneNumber.length : 0;

        if (phoneNumberLength < 13) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: ["Phone number is invalid"],
            },
          ]);
          return;
        }
      }
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      return error;
    }
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file);
    setShowUploadButton(false);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const steps = [
    {
      title: "Company Information",
      content: (
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the company name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Company Name"
                  value={formData.companyName}
                  size="large"
                  onChange={(e) =>
                    handleFormChange("companyName", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Area of focus"
                name="area_of_focus"
                rules={[
                  {
                    required: true,
                    message: "Please select the Area of focus",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Area of focus"
                  value={formData.area_of_focus}
                  size="large"
                  onChange={(e) =>
                    handleFormChange("area_of_focus", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Head Quarter Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter or select the head quarter location  ",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Head Quarter Location"
                  value={formData.headManagerName}
                  size="large"
                  onChange={(e) => handleFormChange("location", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={3}>
              <Form.Item label="Logo" name="logo">
                <div className="company-modal">
                  <Upload
                    name="orglogo"
                    accept=".png,.jpg,.jpeg"
                    listType="picture-card"
                    maxCount={1}
                    multiple={false}
                    beforeUpload={(file) => {
                      handleFileChange(file);
                      return false;
                    }}
                    onRemove={() => {
                      setPreviewImage(d_dp);
                      setSelectedFile(null);
                      setShowUploadButton(true);
                    }}
                    showUploadList={{
                      showPreviewIcon: false,
                    }}
                    defaultFileList={[
                      {
                        uid: "-1",
                        name: "test.png",
                        status: "done",
                        url: d_dp,
                      },
                    ]}
                  >
                    {showUploadButton && (
                      <button
                        className="user-profile-upload-button"
                        type="button"
                      >
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} lg={7} className="warning-container">
              <img
                src={infoSvg}
                alt="info"
                width="20"
                height="20"
                className="warning-logo"
              />
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                Please upload your logo in a square format with a minimum size
                of 512x512 pixels.
              </p>
            </Col>
          </Row>
          <div className="global-modal-bottom-row">
            <Form.Item>
              <Row className="company-buttons">
                <Button
                  size="large"
                  className="global-btn-width"
                  onClick={nextStep}
                  type="primary"
                >
                  Next
                </Button>
              </Row>
            </Form.Item>
          </div>
        </Form>
      ),
    },
    {
      title: "Contact Information",
      content: (
        <Form layout="vertical">
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company's Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Company's phone number",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        value.length > 3 &&
                        !phoneUtil.isValidNumber(
                          phoneUtil.parseAndKeepRawInput(value)
                        )
                      ) {
                        return Promise.reject("Invalid phone number");
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                getValueFromEvent={(e) => e}
                trigger="onChange"
                normalize={(value) => value || ""}
              >
                <PhoneInput
                  defaultCountry="pk"
                  value={formData.phoneNumber}
                  style={{ height: "40px" }}
                  onChange={(phone) => handleFormChange("phoneNumber", phone)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Company Email",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email address.",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Company Email"
                  size="large"
                  value={formData.email}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="company-buttons global-modal-bottom-row">
              <Button
                style={{ marginRight: "8px", marginLeft: "12px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                size="large"
                type="default"
                className="global-btn-width"
              >
                Previous
              </Button>
              <Button
                className="global-btn-width"
                onClick={nextStep}
                size="large"
                type="primary"
              >
                Next
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Mission/Vision",
      content: (
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label="Mission/Vision" name="mission">
                <TextArea
                  rows={4}
                  onChange={(e) => handleFormChange("mission", e.target.value)}
                  value={formData.mission}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row className="company-buttons global-modal-bottom-row">
              <Button
                style={{ marginRight: "8px", marginLeft: "10px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                size="large"
                className="global-btn-width"
                type="default"
              >
                Previous
              </Button>
              <Popconfirm
                placement="topLeft"
                title={
                  <div>
                    <div>
                      Do you want us to add for you a default data into these
                      modules while creating this company?
                    </div>
                    <ul>
                      <li>Designation</li>
                      <li>Job Title</li>
                      <li>Taxation</li>
                    </ul>
                  </div>
                }
                onConfirm={() => {
                  handleSubmit(true);
                }}
                onCancel={() => {
                  handleSubmit(false);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="large"
                  className="global-btn-width"
                  loading={buttonLoading}
                  type="primary"
                >
                  Create
                </Button>
              </Popconfirm>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={`Step ${index + 1}`}
          />
        ))}
      </Steps>
      <div className="department-steps-content">
        {steps[currentStep].content}
      </div>
    </div>
  );
};

export default CreateCompany;
