import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Input, Button, Select } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { TriangleLoader } from "../../../../common/ReactLoader";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import dayjs from "dayjs";
import { Option } from "antd/es/mentions";
import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../services/Global";
import {
  fetchEmployeesForTerminationForm,
  getExistingTerminationRecords,
  onFinishTerminationForm,
} from "../services";
import { hr_end_of_employeement_reason } from "../../../../common/Constants";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { RangePicker } = DatePicker;

interface terminationProps {
  onCancel: () => void;
}
const CreateTermination: React.FC<terminationProps> = (props) => {
  const [form] = Form.useForm();
  const defaultStartDate = moment();
  const defaultEndDate = moment().add(30, "days");
  const defaultRange = [
    dayjs(defaultStartDate.format("YYYY-MM-DD")),
    dayjs(defaultEndDate.format("YYYY-MM-DD")),
  ];
  const [fullLoader, setFullLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const loggedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [noticePeriodSpan, setNoticePeriodSpan] = useState(30);
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [successFlag, setSuccessFlag] = useState<boolean>(false);
  const [existingRecords, setExistingRecords] = useState<any[]>([]);
  const userId = loggedInemployee?.employee?.id;
  const organizationId = loggedInemployee?.employee?.organization_id;
  const companyId = loggedInemployee?.employee?.company_id;
  const branchId = loggedInemployee?.employee?.branch_id;

  useEffect(() => {
    form.setFieldsValue({
      noticePeriodRange: defaultRange,
      noticePeriod: 30,
    });
    if (companyId) {
      form.setFieldValue("companyId", companyId);
      setSelectedCompanyId(companyId);
      if (branchId) {
        form.setFieldValue("branchId", branchId);
        setSelectedBranchId(branchId);
      }
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    form.setFieldValue("employeeId", null);
    fetchEmployees();
  }, [selectedCompanyId, selectedBranchId, successFlag]);

  useEffect(() => {
    const getExistingRecords = async () => {
      const response = await getExistingTerminationRecords(organizationId);
      if (response.data) {
        setExistingRecords(response.data);
      }
    };
    getExistingRecords();
  }, [selectedCompanyId, selectedBranchId, successFlag]);

  const fetchCompanies = async () => {
    const resCompanies = await fetchallCompanyByOrgIdApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies?.data[0]?.id);
      setSelectedCompanyId(resCompanies?.data[0]?.id);
    }
  };

  const fetchBranches = async () => {
    if (branchId) {
      form.setFieldValue("branchId", branchId);
      setSelectedBranchId(branchId);
    } else {
      const resBranches = await fetchAllBranchesByComapnyIdApi(
        organizationId,
        selectedCompanyId
      );

      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches?.data[0]?.id);
        setSelectedBranchId(resBranches?.data[0]?.id);
      }
    }
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchEmployees = async () => {
    try {
      const res = await fetchEmployeesForTerminationForm(
        organizationId,
        selectedCompanyId,
        selectedBranchId
      );
      if (res.data) {
        const existingEmployeeIds = existingRecords.map(
          (item: any) => item.employeeId
        );
        form.setFieldValue("employeeId", res?.data[0]?.id);
        const filteredData = res.data.filter((record: any) => {
          return !existingEmployeeIds.includes(record.id);
        });

        setFilteredEmployees(filteredData);
      }
    } catch (error) {
      return error;
    }
  };

  const onRangeChange = (dates: any) => {
    if (dates) {
      const diffDays = dates[1].diff(dates[0], "days");
      setNoticePeriodSpan(diffDays);
      form.setFieldsValue({ noticePeriod: diffDays });
    }
  };
  const disabledDate = (current: any) => {
    const today = moment().startOf("day");
    const lastMonthSameDay = moment().subtract(1, "months").startOf("day");
    return current && current < lastMonthSameDay;
  };

  const onFinish = async (values: any) => {
    setShowAlert(false);
    setFullLoader(true);
    try {
      const response = await onFinishTerminationForm({
        organization_id: organizationId || null,
        company_id: values.companyId || companyId || null,
        branch_id: values.branchId || branchId || null,
        employee_Id: values.employeeId,
        start_date: values.noticePeriodRange[0]?.format("YYYY-MM-DD"),
        end_date: values.noticePeriodRange[1]?.format("YYYY-MM-DD"),
        description: values.description,
        notice_period: values.noticePeriod,
        status: "Terminate",
        reason: values.reason,
      });

      if (response.error) {
        setFullLoader(false);
        setShowAlert(true);
        setAlertType("error");
      } else {
        setFullLoader(false);
        setShowAlert(true);
        setSuccessFlag(true);
        setAlertType("success");
        const sendNotificationBody = {
          type: "termination",
          senderId: userId,
          Receiver: values?.employeeId,
          name: loggedInemployee?.employee?.full_name,
          avatar: loggedInemployee?.employee?.avatarUrl,
        };

        await pushNotifictionIntoNotificationTable(
          sendNotificationBody,
          organizationId
        );

        setShowAlert(true);
        setAlertType("success");
        props.onCancel();
        form.resetFields();
      }
    } catch (error) {
      setFullLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          {!companyId && (
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="companyId"
                label="Select Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  disabled={disabled}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredCompanies.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item name="branchId" label="Select Branch">
                <Select
                  disabled={disabled}
                  onChange={(value) => setSelectedBranchId(value)}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="employeeId"
              label="Select Employee"
              rules={[
                {
                  required: true,
                  message: "Please select an employee",
                },
              ]}
            >
              <Select
                placeholder="Select an Employee"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item?.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="reason"
              label="Select Reason"
              rules={[
                {
                  required: true,
                  message: "Please select Reason",
                },
              ]}
            >
              <Select
                placeholder="Select an Reason"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {hr_end_of_employeement_reason.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please write a reason to resign...",
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                placeholder="Write a reason to resign..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Notice Period"
              name="noticePeriodRange"
              rules={[
                {
                  type: "array",
                  required: true,
                  message: "Please select the Notice Period Range",
                },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                defaultValue={[
                  dayjs(defaultStartDate.format("YYYY-MM-DD")),
                  dayjs(defaultEndDate.format("YYYY-MM-DD")),
                ]}
                onChange={onRangeChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={8}>
            <Form.Item label="Notice Period Span (days)" name="noticePeriod">
              <Input size="large" disabled value={noticePeriodSpan.toString()} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          className="global-modal-bottom-row create-resign-btn"
          shouldUpdate={true}
        >
          <Button
             
            htmlType="submit"
            size="large"
            type="primary"
          >
            Create
          </Button>
        </Form.Item>
      </Form>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      {fullLoader && (
        <div className="request-demo-suspense-blur-screen">
          <TriangleLoader />
        </div>
      )}
    </div>
  );
};

export default CreateTermination;
