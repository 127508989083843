import React, { useState } from "react";
import { Tabs } from "antd";
import "./index.css";
import { useLocation } from "react-router-dom";
import UserPersonalInfoUpdate from "./PersonalInformation";
import UserContactInfoUpdate from "./ContactInformation";
import UserResponsibilityandReportInfoUpdate from "./ResponsibilityandReport";
import UserQualifictionAndOtherInfoUpdate from "./QualificationandOther";
import UserBankInfoUpdate from "../../components/EmployeesManagement/EmployeeViewDetails/BankDetails";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import UserSalaryDetails from "../../components/EmployeesManagement/EmployeeViewDetails/SalaryDetails";

const UserProfileUpdate: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const items = [
    {
      label: "Personal Information",
      key: "1",
      children: <UserPersonalInfoUpdate />,
    },
    {
      label: "Contact details",
      key: "2",
      children: <UserContactInfoUpdate />,
    },
    {
      label: "Employment",
      key: "3",
      children: <UserResponsibilityandReportInfoUpdate />,
    },
    {
      label: "Qualification",
      key: "4",
      children: <UserQualifictionAndOtherInfoUpdate />,
    },
    {
      label: "Bank Details",
      key: "5",
      children: (
        <UserBankInfoUpdate employeeId={loggedInEmployee?.employeeId} />
      ),
    },
    {
      label: "Salary Details",
      key: "6",
      children: <UserSalaryDetails employeeId={loggedInEmployee?.employeeId} />,
    },
  ];

  return (
    <div className="table-main-container">
      <div className="profile-update-container">
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          tabBarGutter={8}
          className="antd-tab-custamization update-profile-tabs"
          items={items}
        />
      </div>
    </div>
  );
};

export default UserProfileUpdate;
