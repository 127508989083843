import { Col, Empty, Input, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { EditOutlined } from "@ant-design/icons";
import OverTimeApproveEdit from "../Edit";
import { fetchOverTimeClaimRequestList } from "../services";
import { SortOrder } from "antd/lib/table/interface";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../../../redux/AccessRight";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import searchIcon from "../../../../assets/images/search-icon.png";
import d_dp from "../../../../assets/images/blankProfile.png";

const OverTimeApproveListView: React.FC = () => {
  const [tableloader, setTableLoader] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const organizationId = logedInemployee?.employee?.organization_id;
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Overtime Approval"
  );

  const edit = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchRequeste();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchQuery]);

  const fetchRequeste = async () => {
    setTableLoader(true);
    setSearchQuery("");
    const data = {
      organizationId: organizationId,
      companyId: logedInemployee?.employee?.company_id,
      branchId: logedInemployee?.employee?.branch_id,
    };
    const res = await fetchOverTimeClaimRequestList(data);

    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
    setTableLoader(false);
  };
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "requestedEmployee",
      key: "requestedEmployee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={4} xxl={4}>
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={record?.avatar_url ? record?.avatar_url : d_dp}
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={20}
            xxl={20}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
            }}
          >
            <div>
              <strong>{record?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.full_name || "";
        const nameB = b?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Hours",
      dataIndex: "total_working_houre",
      key: "total_working_houre",
    },
    {
      title: "Reviewed By",
      dataIndex: "reviewerEmployee",
      key: "reviewerEmployee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={4} xxl={4}>
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={record?.avatar_url ? record?.avatar_url : d_dp}
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={20}
            xxl={20}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "15px",
            }}
          >
            <div>
              <strong>{record?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Status",
      key: "actions",
      render: (text: any, record: any) =>
        record.status == "Requested" ? (
          <Space size="middle">
            {edit && (
              <span
                className="link"
                onClick={() => {
                  setSelectedRecordId(record.id);
                  setShowEditModel(true);
                }}
              >
                <EditOutlined />
              </span>
            )}
          </Space>
        ) : (
          record.status
        ),
    },
  ];

  const onCancel = async () => {
    setSelectedRecordId("");
    fetchRequeste();
    setShowEditModel(false);
  };

  const handleSearch = (value: any) => {
    setTableLoader(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "requestedEmployee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setTableLoader(false);
  };

  const onSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div className="over-time-approve-ListView-card global-request-container-styling overtime-approval-container">
        <Row gutter={[16, 16]} className="global-request-list-top-row">
          <Col span={24} className="overtime-approve-search-col">
            <Input
              className="org-search-input"
              size="large"
              placeholder="Search"
              prefix={<img src={searchIcon} alt="Search icon" />}
              onChange={(e: any) => {
                onSearchChange(e.target.value);
              }}
            />
          </Col>
        </Row>
        {read ? (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={columns}
            loading={tableloader}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>
      {showEditModel && (
        <Modal
          className="overTime-approve-ListView-edit"
          title={"Edit Details"}
          open={showEditModel}
          onCancel={onCancel}
          width={1000}
          footer={false}
        >
          <OverTimeApproveEdit
            onCancel={() => onCancel()}
            recordId={selectedRecordId}
          />
        </Modal>
      )}
    </>
  );
};

export default OverTimeApproveListView;
