import React from "react";
import DashboardLayout from "./DashBoardLayout";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../common/ReactLoader";

const DashboardAdminHr: React.FC = () => {
  const userSession = useSelector(selectLogedEmployee);

  if (!userSession) {
    return <TriangleLoader />;
  } else {
    return <DashboardLayout />;
  }
};

export default DashboardAdminHr;
