import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Select, Button, Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { Tree, TreeNode } from "react-organizational-chart";
import "./index.css";
import CustomAlert from "../../../common/Alert";
import maleicon from "../../../assets/images/male-icon1.svg";
import { BackwardFilled } from "@ant-design/icons";
import { capitalizeWords } from "../../../common/validation";
import { useNavigate } from "react-router-dom";
import {
  fetchDepartmentsinfoApi,
  fetchReportToEmployessInfobyemployeeidanddepartmentIdApi,
} from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
import PlaceholderProfile from "../../../assets/images/blankProfile.png";

interface DepartmentHierarchyprops {
  goBack: () => void;
}
const { Text } = Typography;
/**
 * Functional component that displays a department hierarchy tree.
 * @param {DepartmentHierarchyprops} props - The props for the component.
 * @returns JSX element displaying the department hierarchy tree.
 */
const DepartmentHierarchy: React.FC<DepartmentHierarchyprops> = (props) => {
  const navigate = useNavigate();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [rfCounter, setRfCounter] = useState<number>(0);
  const [reportingEmployees, setreportingEmployee] = useState<any>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const selectedOrganization = useSelector(selectLogedEmployee);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );

  /**
   * useEffect hook that fetches department options on component mount and clears any existing timeout on component unmount.
   * @returns None
   */
  useEffect(() => {
    fetchDepartmentOptions();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * Fetches the department options asynchronously and updates the state with the fetched data.
   * It also sets the selected department and fetches the head manager name for the selected department.
   * @returns None
   */
  const fetchDepartmentOptions = async () => {
    const res = await fetchDepartmentsinfoApi(organizationId);
    if (res?.data) {
      setDepartmentList(res?.data);
      // this for set Inital value in drop down
      // if (res?.data && res?.data.length > 0) {
      //   const initial_value = res?.data[0].id
      //   setSelectedDepartment(initial_value);
      //   fetchHeadManagerName(initial_value);
      // }
    }
  };

  /**
   * Fetches the head manager's name for a given department ID and updates the state with the retrieved data.
   * @param {string} departmentId - The ID of the department to fetch the head manager's name for.
   * @returns None
   */
  const fetchHeadManagerName = async (departmentId: string) => {
    setShowLoader(true);
    const headManagerdata = departmentList.find(
      (item) => item.id == departmentId
    );
    if (headManagerdata) {
      const child_profile = await fetchHeadManagerNameRecursive(
        headManagerdata?.head_manager_id,
        departmentId
      );
      const chartdata = {
        id: headManagerdata.head_manager_id,
        Name: headManagerdata?.employee?.full_name,
        avatarUrl: headManagerdata?.employee?.avatar_url,
        designation: headManagerdata?.employee?.designation?.name,
        child: child_profile,
      };
      setreportingEmployee(chartdata);
    }
    setShowLoader(false);
    setRfCounter(rfCounter + 1);
  };

  /**
   * Fetches the head manager name recursively by employee ID and department ID.
   * @param {string | number} headId - The ID of the head manager.
   * @param {string | number} departmentId - The ID of the department.
   * @returns {Promise<Array>} A promise that resolves to an array of profiles data.
   */
  const fetchHeadManagerNameRecursive = async (
    headId: string | number,
    departmentId: string | number
  ) => {
    try {
      const employee_res =
        await fetchReportToEmployessInfobyemployeeidanddepartmentIdApi(
          headId,
          departmentId,
          organizationId
        );
      if (employee_res.data && employee_res.data.length > 0) {
        const profiles_data_promises: any = employee_res.data.map(
          async (item: any) => ({
            id: item?.id,
            Name: item?.full_name,
            avatarUrl: item?.avatar_url,
            designation: item?.designation?.name,
            child: await fetchHeadManagerNameRecursive(item?.id, departmentId),
          })
        );
        const profiles_data = await Promise.all(profiles_data_promises);

        return profiles_data;
      }
      return [];
    } catch {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };
  /**
   * Handles the selection of a department by setting the reporting employee to null,
   * updating the selected department, hiding the loader, and fetching the head manager name
   * if a department is selected.
   * @returns None
   */
  const handleDepartmentSelect = (value: any) => {
    setreportingEmployee(null);
    setShowLoader(false);
    if (value) {
      fetchHeadManagerName(value);
    }
  };
  /**
   * Handles the click event on a node with the given value.
   * If the value is truthy, it removes spaces from the selected organization's name,
   * and navigates to the employee page with the value in the URL.
   * @param {string} value - The value associated with the clicked node.
   * @returns None
   */
  const handleOnNodeClick = (value: string) => {
    if (value) {
      navigate(`/employee/${value}`);
    }
  };

  /**
   * Functional component that renders a card for an employee with their name and avatar.
   * If employee data is available, it displays the employee's name and avatar image.
   * If employee data is not available, it displays a loading message.
   * @param {any} employee - The employee object containing name and avatar information.
   * @returns JSX element representing the card component for the employee.
   */
  const CardComponent = (employee: any) => {
    if (employee && employee?.employee?.Name) {
      return (
        <div
          className="card-component"
          onClick={() => handleOnNodeClick(employee?.employee.id)}
        >
          <img
            src={
              employee?.employee?.avatarUrl
                ? employee?.employee?.avatarUrl
                : PlaceholderProfile
            }
            alt={maleicon}
            className="avatar-in-hierarchy"
          />
          <div className="info-container-hierarchy">
            <div className="info-line">{employee?.employee?.Name}</div>

            <Text className="employee-card-tag-text-style" strong>
              {capitalizeWords(employee?.employee?.designation || "--")}
            </Text>
          </div>
        </div>
      );
    } else {
      return <div className="loading-component">Loading...</div>;
    }
  };
  /**
   * Renders the root node of an employee hierarchy tree.
   * @param {any} employee - The employee object to render.
   * @returns {JSX.Element} - The JSX element representing the root node.
   */
  const RenderRootNode = (employee: any) => {
    if (employee && employee?.employee?.Name) {
      return (
        <div
          className="root-node"
          onClick={() => handleOnNodeClick(employee?.employee.id)}
        >
          <img
            src={
              employee?.employee?.avatarUrl
                ? employee?.employee?.avatarUrl
                : PlaceholderProfile
            }
            alt={maleicon}
            className="avatar-in-hierarchy"
          />
          <div className="info-container-hierarchy">
            <div className="info-line-hierarchy">
              {employee?.employee?.Name}
            </div>

            <Text className="employee-card-tag-text-style" strong>
              {capitalizeWords(employee?.employee?.designation || "--")}
            </Text>
          </div>
        </div>
      );
    } else {
      return <div className="loading-component">Loading...</div>;
    }
  };
  /**
   * Renders a tree node for the given employee object.
   * @param {any} employee - The employee object to render as a tree node.
   * @returns The rendered tree node component.
   */
  const renderTreeNode = (employee: any) => {
    if (employee) {
      return (
        <TreeNode label={<CardComponent employee={employee} />}>
          {employee.child && employee.child.map(renderTreeNode)}
        </TreeNode>
      );
    }
  };

  /**
   * Renders a tree structure based on the reportingEmployees data.
   * If reportingEmployees and reportingEmployees.Name exist, it creates a tree starting from the root employee.
   * @returns JSX element representing the tree structure
   */
  const renderTree = () => {
    if (reportingEmployees && reportingEmployees.Name) {
      const rootEmployee = reportingEmployees;
      const rootNode = (
        <div className="root-div">
          <RenderRootNode employee={rootEmployee} />
        </div>
      );
      return (
        <Tree
          lineWidth={"2px"}
          lineColor={"var(--green-color-primary-light)"}
          lineBorderRadius={"10px"}
          label={rootNode}
        >
          {rootEmployee.child && rootEmployee.child.map(renderTreeNode)}
        </Tree>
      );
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <>
      <div className="hierarchy-container ">
        <Row gutter={24}>
          <Col span={12} className="custom-col-left">
            <Select
              filterOption={(input: any, option: any) =>
                option && option.children
                  ? (option.children as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
              showSearch
              placeholder="Select Department"
              onChange={(value) => {
                setSelectedDepartment(value);
                if (value) {
                  handleDepartmentSelect(value);
                }
              }}
              allowClear={true}
              className="depar-select-dropdown"
              value={selectedDepartment}
              notFoundContent={
                <NoDataFoundIcon />
              }
            >
              {departmentList?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={12} className="custom-col-right">
            <Button
              className="hierarchy-view-btn global-default-btn"
              onClick={props.goBack}
              icon={<BackwardFilled />}
            >
              Go Back
            </Button>
          </Col>
        </Row>

        <div key={rfCounter} className="tree-container ">
          {showLoader ? (
            <div className="center-spinner-container">
              <Spin size="small" />
            </div>
          ) : reportingEmployees && !showLoader ? (
            reportingEmployees && renderTree()
          ) : (
            <div className="center-spinner-container">
              <NoDataFoundIcon />
            </div>
          )}
        </div>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </>
  );
};

export default DepartmentHierarchy;
