import React from "react";
import Salaries from "../../HCM/components/EmployeesManagement/EmployeeData/Salaries";
import OrganisationUpdate from "../../HCM/components/OrganisationManagement/OrganisationUpdate";
import EmployeeLeaveRequest from "../../HCM/components/EmployeePortal/EmployeeLeaveRequest/Layout";
import MyTimeTracking from "../../HCM/components/EmployeePortal/MyTimeTracking/Layout";
import EmployeePortalDashboard from "../../HCM/components/EmployeePortal/Dashboard";
import UserProfileUpdate from "../../HCM/common/UserProfileUpdate";
import DashboardAdminHr from "../../HCM/components/DashboardAdminandHr";
import EmployeeSettings from "../../HCM/components/EmployeePortal/Settings";
import CustomScheduler from "../../HCM/components/OrganisationManagement/Scheduler";
import MyPerformance from "../../HCM/components/EmployeePortal/MyPerformance";
import AllowanceClaimRequest from "../../HCM/components/Payroll/AllowanceClaimRequest/index";
import EmployeePaySlip from "../../HCM/components/Payroll/EmployeePaySlip";
import GenratePaySlip from "../../HCM/components/Payroll/GenratePays/index";
import EmployeeProfileUpdate from "../../HCM/components/EmployeesManagement/EmployeeViewDetails/MainLayout";
import PaySlipPreview from "../../HCM/components/Payroll/EmployeePaySlip/PayslipPreview";
import ClaimToApprove from "../../HCM/components/Payroll/ClaimToApprove";
import OverTimeApproveListView from "../components/Payroll/OverTimeApprove/List";
import OverTimeClaimRequest from "../../HCM/components/Payroll/OverTimeApprove";
import AttendanceLogs from "../../HCM/components/OrganisationManagement/AttendanceLogs";
import JobApplication from "../../HCM/components/Recruitment/JobApplicants/index";
import ResignationPolicy from "../../HCM/components/ResignationFlowManagement/EmployeeResignation";
import Termination from "../../HCM/components/ResignationFlowManagement/Termination";
import ShortlistedCandidates from "../../HCM/components/Recruitment/shortlistedCandidates";
import HiredCandidates from "../../HCM/components/Recruitment/HiredCandidates";
import EmployeeCreateLoanRequest from "../../HCM/components/EmployeeLoanManagement";
import CreateEmployes from "../../HCM/components/EmployeesManagement/CreateEmployee";
import Organization from "../components/OrganisationManagement/Organization";
import Employees from "../components/EmployeesManagement/Employee";
import Payroll from "../components/Payroll";
import PolicyDocuments from "../components/PolicyDocumentMangement";
import RecruitmentComp from "../components/Recruitment";
import RequestModule from "../components/ResignationFlowManagement";
import AssessmentModule from "../components/EmployeesManagement/Assessment";
import PerformanceModule from "../components/KPIs";
import HrAttendanceModule from "../components/OrganisationManagement/HrAttendanceModule";
import PolicyDocumentViewForEmployee from "../components/PolicyDocumentMangement/PolicyDocumentsViewForEmployee";
import AdvanceRequestHistory from "../components/Payroll/AdvanceRequest/History";
import AdvanceRequestListView from "../components/Payroll/AdvanceRequest/List";
import SystemSetting from "../components/SystemSetting";
import AssetManagement from "../components/AssetManagement";
import EmloyeeRequestApprovalsComp from "../components/EmployeePortal/RequestApprovals";
import LeaveApprovalTable from "../components/OrganisationManagement/LeaveApproval/LeaveApprovalTable";
import EmploymentTypeComp from "../components/EmployeesManagement/Assessment/employementType";

const AllOrganizationRoutes = [
  {
    path: "admin/dashboard",
    element: <DashboardAdminHr />,
    parentpage: "Dashboard",
  },
  {
    path: "admin/organization",
    element: <Organization />,
    parentpage: "Organization",
  },
  {
    path: "admin/employee",
    element: <Employees />,
    parentpage: "Employee",
  },
  {
    path: "admin/assets",
    element: <AssetManagement />,
    parentpage: "Assets",
  },

  {
    path: "admin/profile",
    element: <UserProfileUpdate />,
    parentpage: "Profile",
  },
  {
    path: "admin/scheduler",
    element: <CustomScheduler />,
    parentpage: "Scheduler",
  },

  {
    path: "admin/payroll",
    element: <Payroll />,
    parentpage: "Payroll",
  },
  {
    path: "admin/policydocuments",
    element: <PolicyDocuments />,
    parentpage: "Policies",
  },
  {
    path: "admin/recruitment",
    element: <RecruitmentComp />,
    parentpage: "Recruitment",
  },

  {
    path: "admin/assessments",
    element: <AssessmentModule />,
    parentpage: "Assessment",
  },
  {
    path: "admin/employment",
    element: <EmploymentTypeComp />,
    parentpage: "Employment",
  },
  {
    path: "admin/performances",
    element: <PerformanceModule />,
    parentpage: "Performance",
  },

  {
    path: "admin/organization/update",
    element: <OrganisationUpdate />,
    parentpage: "Organization",
  },

  {
    path: "admin/leaveapproval",
    element: <LeaveApprovalTable />,
    parentpage: "Leave Approval",
  },

  {
    path: "employee/:id",
    element: <EmployeeProfileUpdate />,
    parentpage: "Employee",
  },

  {
    path: "admin/claimed/approve",
    element: <ClaimToApprove />,
    parentpage: "Allowance Approval",
  },
  {
    path: "admin/claim/overTime",
    element: <OverTimeApproveListView />,
    parentpage: "Overtime Approval",
  },
  {
    path: "admin/myattendance",
    element: <MyTimeTracking />,
    parentpage: "Attendance",
  },
  {
    path: "admin/attendancelogs",
    element: <AttendanceLogs />,
    parentpage: "Attendance",
  },

  {
    path: "admin/recruitment/applicationlist/:id",
    element: <JobApplication />,
    parentpage: "Recruitment",
  },
  {
    path: "admin/allreignations",
    element: <Termination />,
    parentpage: "End Of Employment",
  },

  {
    path: "admin/recruitment/shortlistedcandidates/:id",
    element: <ShortlistedCandidates />,
    parentpage: "Recruitment",
  },
  {
    path: "admin/recruitment/hiredcandidates/:id",
    element: <HiredCandidates />,
    parentpage: "Recruitment",
  },

  {
    path: "admin/leave/request",
    element: <EmployeeLeaveRequest />,
    parentpage: "Leave Request",
  },
  {
    path: "admin/allowanceclaimrequest",
    element: <AllowanceClaimRequest />,
    parentpage: "Allowance Request",
  },
  {
    path: "admin/overtimeclaimrequest",
    element: <OverTimeClaimRequest />,
    parentpage: "Overtime Request",
  },
  {
    path: "admin/applyresignation",
    element: <ResignationPolicy />,
    parentpage: "Resignation",
  },

  {
    path: "create/admin/employees",
    element: <CreateEmployes />,
    parentpage: "Employee",
  },

  {
    path: "employee/:employeeid/payslipPreview/:id",
    element: <PaySlipPreview />,
    parentpage: "Pay Slip",
  },
  {
    path: "admin/advancerequest",
    element: <AdvanceRequestHistory />,
    parentpage: "Advance Request",
  },
  {
    path: "admin/advanceapprove",
    element: <AdvanceRequestListView />,
    parentpage: "Advance Approval",
  },
  {
    path: "admin/systemsettings",
    element: <SystemSetting />,
    parentpage: "System Settings",
  },
  {
    path: "admin/policies",
    element: <PolicyDocumentViewForEmployee />,
    parentpage: "Policies",
  },
];

const AllHrRoutes = [
  {
    path: "create/hr/employees",
    element: <CreateEmployes />,
    parentpage: "Employee",
  },

  {
    path: "hr/recruitment",
    element: <RecruitmentComp />,
    parentpage: "Recruitment",
  },
  {
    path: "hr/dashboard",
    element: <DashboardAdminHr />,
    parentpage: "Dashboard",
  },
  {
    path: "hr/organization",
    element: <Organization />,
    parentpage: "Organization",
  },
  {
    path: "hr/employee",
    element: <Employees />,
    parentpage: "Employee",
  },
  {
    path: "hr/assets",
    element: <AssetManagement />,
    parentpage: "Assets",
  },
  {
    path: "hr/performances",
    element: <PerformanceModule />,
    parentpage: "Performance",
  },
  {
    path: "hr/assessment",
    element: <AssessmentModule />,
    parentpage: "Assessment",
  },
  {
    path: "hr/employment",
    element: <EmploymentTypeComp />,
    parentpage: "Employment",
  },

  {
    path: "hr/recruitment/applicationlist/:id",
    element: <JobApplication />,
    parentpage: "Recruitment",
  },

  {
    path: "hr/profile",
    element: <UserProfileUpdate />,
    parentpage: "Profile",
  },
  {
    path: "hr/systemsettings",
    element: <SystemSetting />,
    parentpage: "System Settings",
  },

  {
    path: "hr/scheduler",
    element: <CustomScheduler />,
    parentpage: "Scheduler",
  },
  {
    path: "hr/leaveapproval",
    element: <LeaveApprovalTable />,
    parentpage: "Leave Approval",
  },

  {
    path: "employee/:id",
    element: <EmployeeProfileUpdate />,
    parentpage: "Employee",
  },
  {
    path: "hr/allowanceclaimrequest",
    element: <AllowanceClaimRequest />,
    parentpage: "Allowance Request",
  },
  {
    path: "hr/payslip",
    element: <EmployeePaySlip />,
    parentpage: "Pay Slip",
  },
  {
    path: "hr/salary/generate",
    element: <GenratePaySlip />,
  },
  {
    path: "employee/:employeeid/payslipPreview/:id",
    element: <PaySlipPreview />,
    parentpage: "Pay Slip",
  },
  {
    path: "hr/claimed/approve",
    element: <ClaimToApprove />,
    parentpage: "Allowance Approval",
  },
  {
    path: "hr/myattendance",
    element: <MyTimeTracking />,
    parentpage: "Attendance",
  },

  {
    path: "hr/overtimeclaimrequest",
    element: <OverTimeClaimRequest />,
    parentpage: "Overtime Request",
  },
  {
    path: "hr/claim/overTime",
    element: <OverTimeApproveListView />,
    parentpage: "Overtime Approval",
  },
  {
    path: "hr/allreignations",
    element: <Termination />,
    parentpage: "End Of Employment",
  },
  {
    path: "hr/payslip",
    element: <EmployeePaySlip />,
    parentpage: "Pay Slip",
  },

  {
    path: "hr/leave/request",
    element: <EmployeeLeaveRequest />,
    parentpage: "Leave Request",
  },
  {
    path: "hr/applyresignation",
    element: <ResignationPolicy />,
    parentpage: "Resignation",
  },

  {
    path: "hr/recruitment/hiredcandidates/:id",
    element: <HiredCandidates />,
    parentpage: "Recruitment",
  },
  {
    path: "hr/recruitment/shortlistedcandidates/:id",
    element: <ShortlistedCandidates />,
    parentpage: "Recruitment",
  },

  {
    path: "hr/payroll",
    element: <Payroll />,
    parentpage: "Payroll",
  },
  {
    path: "hr/policydocuments",
    element: <PolicyDocuments />,
    parentpage: "Policies",
  },
  {
    path: "hr/attendance",
    element: <HrAttendanceModule />,
    parentpage: "Attendance",
  },
  {
    path: "hr/advancerequest",
    element: <AdvanceRequestHistory />,
    parentpage: "Advance Request",
  },
  {
    path: "hr/advanceapprove",
    element: <AdvanceRequestListView />,
    parentpage: "Advance Approval",
  },
];

const AllEmployeePortalRoutes = [
  {
    path: "employee/dashboard",
    element: <EmployeePortalDashboard />,
    parentpage: "Dashboard",
  },
  {
    path: "employee/requests",
    element: <RequestModule />,
    parentpage: "Requests",
  },
  {
    path: "employee/myattendance",
    element: <MyTimeTracking />,
    parentpage: "Attendance",
  },
  {
    path: "employee/myperformance",
    element: <MyPerformance />,
    parentpage: "Performance",
  },
  {
    path: "employee/profile",
    element: <UserProfileUpdate />,
    parentpage: "Profile",
  },

  {
    path: "employee/policies",
    element: <PolicyDocumentViewForEmployee />,
    parentpage: "Policies",
  },

  {
    path: "employee/settings",
    element: <EmployeeSettings />,
    parentpage: "System Settings",
  },

  {
    path: "employee/payslip",
    element: <EmployeePaySlip />,
    parentpage: "Pay Slip",
  },
  {
    path: "employee/:employeeid/payslipPreview/:id",
    element: <PaySlipPreview />,
    parentpage: "Pay Slip",
  },
  {
    path: "employee/claimed/approve",
    element: <ClaimToApprove />,
    parentpage: "Allowance Approval",
  },
  {
    path: "employee/overtimeclaimrequest",
    element: <OverTimeClaimRequest />,
    parentpage: "Overtime Request",
  },
  {
    path: "employee/claim/overTime",
    element: <OverTimeApproveListView />,
    parentpage: "Overtime Approval",
  },
  {
    path: "employee/applyresignation",
    element: <ResignationPolicy />,
    parentpage: "Resignation",
  },
  {
    path: "employee/systemsettings",
    element: <SystemSetting />,
    parentpage: "System Settings",
  },
  {
    path: "employee/payroll",
    element: <Payroll />,
    parentpage: "Payroll",
  },

  {
    path: "employee/leaveapproval",
    element: <EmloyeeRequestApprovalsComp />,
    parentpage: "Request Approvals",
  },
];

export { AllOrganizationRoutes, AllHrRoutes, AllEmployeePortalRoutes };
