import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Empty,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useForm } from "antd/es/form/Form";
import { Form } from "antd";
import CustomAlert from "../../../../common/Alert";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  deleteKpiAttributeNames,
  editKpiAttributeNames,
  getBranchesByCompanyId,
  getKpiAttributeNames,
} from "../services";

import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import CustomTable from "../../../../common/CustomTable";
import { capitalizeWords } from "../../../../common/validation";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const KPIAttributesList = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  const [form] = useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [refCounter, setRefCounter] = useState<number>(0);
  const [currentRecord, setCurrentRecord] = useState<any>(null);
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Performance",
    "KPI Attributes"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
      if (branchId) {
        form.setFieldValue("branch", branchId);
        setSelectedBranch(branchId);
      }
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    getAttributes();
  }, [companyId, selectedCompany, selectedBranch, refCounter]);

  useEffect(() => {
    if (!(branchId || companyId)) {
      getBranches();
    }
  }, [selectedCompany]);

  const columns = [
    {
      title: "Attribute Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => capitalizeWords(text),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <>
          <Space size={"middle"}>
            {edit && (
              <a>
                <EditOutlined onClick={() => handleModalOpen(record)} />
              </a>
            )}

            <Popconfirm
              placement="topLeft"
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteAttributes(record)}
            >
              {deletee && (
                <a>
                  <DeleteOutlined />
                </a>
              )}
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const getCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);

    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };

  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      const { data } = await getBranchesByCompanyId(
        form.getFieldValue("company"),
        organizationId
      );

      if (data) {
        setBranches(data);
        form.setFieldValue("branch", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
    }
  };

  const handleModalOpen = async (record: any) => {
    setCurrentRecord(record);
    setOpenModal(true);

    form.setFieldsValue({
      attributeName: record.name,
      percentage: record.percentage,
    });
  };

  const handleEditAttributes = async () => {
    if (!currentRecord) return;

    const newPercentageValue = parseInt(form.getFieldValue("percentage"));
    const newAttributeName = form.getFieldValue("attributeName");
    const totalPercentage = tableData.reduce((acc, item) => {
      return item.id !== currentRecord.id
        ? acc + parseInt(item.percentage, 10)
        : acc;
    }, 0);

    const isDuplicateName = tableData.some(
      (item) =>
        item.attributeName === newAttributeName && item.id !== currentRecord.id
    );

    if (totalPercentage + newPercentageValue > 100) {
      setshowAlert(true);
      setAlertType("error");
      return;
    }

    if (isDuplicateName) {
      setshowAlert(true);
      setAlertType("error");

      return;
    }

    const formattedData = {
      id: currentRecord.id,
      attributeName: newAttributeName,
      percentage: newPercentageValue,
    };

    const response = await editKpiAttributeNames(formattedData, organizationId);
    if (response.status) {
      setshowAlert(true);
      setAlertType("success");

      const updatedTableData = tableData.map((item) =>
        item.id === currentRecord.id ? { ...item, ...formattedData } : item
      );
      setTableData(updatedTableData);
    } else if (response.error) {
    }

    setCurrentRecord(null);
    setOpenModal(false);
  };

  const deleteAttributes = async (record: any) => {
    const id = record.id;
    const response = await deleteKpiAttributeNames(record.id, organizationId);
    if (response.status) {
      setshowAlert(true);
      setAlertType("success");
      setRefCounter(refCounter + 1);
    }
  };

  const getAttributes = async () => {
    if (selectedCompany) {
      const data = {
        companyId: selectedCompany || companyId,
        branchId: selectedBranch || branchId,
      };
      setLoading(true);
      const response = await getKpiAttributeNames(data, organizationId);

      if (response.data) {
        setTableData(response.data);

        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentRecord(null);
  };

  return (
    <>
      {read ? (
        <>
          <Form
            layout="vertical"
            form={form}
            className="global-kpi-list-header"
          >
            <Row gutter={16} className="kpi-attribute-first-row">
              {!companyId && (
                <Col span={24} md={12}>
                  <Form.Item
                    name={"company"}
                    label="Companies"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company...",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={setSelectedCompany}
                      placeholder="Please select a company"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companies?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {!branchId && (
                <Col span={24} md={12}>
                  <Form.Item name={"branch"} label="Branches">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={setSelectedBranch}
                      placeholder="Please select a branch"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branches.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>

          <CustomTable
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            loading={loading}
          />
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      <Modal
        open={openModal}
        width={1000}
        title={<span className="global-modal-title">Edit Attributes</span>}
        onCancel={handleCloseModal}
        footer={null}
      >
        <div className="global-card-inner-container">
          <Form layout="vertical" form={form}>
            <Row>
              <Col className="modal-content">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={"attributeName"}
                      label="Attribute Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter an attribute name ",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter attribute name..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Percentage"
                      name={"percentage"}
                      rules={[
                        {
                          required: true,
                          message: "This field cannot be left empty",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter percentage..." />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="global-modal-bottom-row modal-btn-row">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleEditAttributes}
                size="large"
              >
                Save
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default KPIAttributesList;
