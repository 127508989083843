import React from "react";
import { Empty } from "antd";

const NoDataFoundIcon: React.FC = () => {
  return (
    <div className="custom-empty-container">
      <Empty
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        imageStyle={{ height: 60 }}
        description={
          <span>
            No Data Available
          </span>
        }
      />
    </div>
  );
};

export default NoDataFoundIcon;
