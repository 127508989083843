import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Button, Upload, Image, Modal } from "antd";
import "./index.css";
import d_dp from "./../../../assets/images/blankProfile.png";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import CustomAlert from "../../../common/Alert";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { fetchOrganizationInfoByIdApi } from "./services";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../redux/EmployeeProfileSlice";
import { updateOrganization, updateorganizationLogoApi } from "./services";

const OrganisationUpdate: React.FC = () => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  let org_details = useSelector(selectLogedEmployee);
  const [organizationName, setOrganizationName] = useState<string>(
    org_details.logedInemployee?.employee.organization_name || ""
  );
  const [industryType, setIndustryType] = useState<string>("");
  const [organizationId, setOrganizationId] = useState<string | number>(
    org_details.logedInemployee?.employee.organization_id || ""
  );
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [initiallogoUrl, setInitialLogoUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(
    null
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(true);
  const [employescounter, setEmployesCounter] = useState<number>(0);
  const loggedInEmployee =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrgData();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    generateId();
  }, [organizationName, industryType]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const [form] = Form.useForm();

  const fetchOrgData = async () => {
    const res = await fetchOrganizationInfoByIdApi(
      org_details.logedInemployee?.employee.organization_id
    );

    if (res.data) {
      form.setFieldsValue({
        organizationName: res?.data?.name || "",
        industryType: res?.data?.industry_type || "",
        headquarter: res?.data?.head_quarter || "",
        adminname: res?.data?.admin_name || "",
        phoneNumber: res?.data?.phone_number || "",
        website: res?.data?.website || "",
        email: res?.data?.email || "",
        password: "***********",
      });
      setPreviewImage(res?.data?.logo_avatar);
      setInitialLogoUrl(res?.data?.logo_avatar);

      setEmployesCounter(employescounter + 1);
    }
  };

  const generateId = () => {
    const words = organizationName?.split(" ");
    const idPrefix = words.map((word) => word[0]).join("");
    if (organizationName.charAt(0).toLowerCase() === "n") {
      setOrganizationId(idPrefix + "-" + industryType);
    } else {
      setOrganizationId(idPrefix + "-" + industryType);
    }
  };
  const onUpdate = async (values: any) => {
    setshowAlert(false);
    try {
      const body = {
        organizationName: values.organizationName,
        industryType: values.industryType,
        headquarter: values.headquarter,
        organization_Id: organizationId,
        phoneNumber: values.phoneNumber,
        website: values.website,
        id: org_details.logedInemployee?.employee.organization_id,
        logo_avatar: initiallogoUrl,
      };
      if (selectedFile) {
        let fileResponse = await uploadImage(selectedFile);

        if (fileResponse && fileResponse.data) {
          (body as any).logo_avatar = fileResponse.data.publicUrl;
          dispatch(
            setlogedInemployee({
              logedInemployee: {
                employee: {
                  ...loggedInEmployee,
                  organization_logo: fileResponse.data.publicUrl,
                },
              },
            })
          );
        }
      }
      const response = await updateOrganization(body);

      if (response.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      } else {
        setshowAlert(true);
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const handleFileChange = (file: File) => {
    setShowUploadButton(false);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: File | null) => {
    try {
      if (file) {
        const uniqueFileName = `${Date.now()}-${file.name}`;
        const res = await updateorganizationLogoApi(uniqueFileName, file);
        return res;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="org-update-card">
      <div className="org-update-form-container">
        <Form
          layout="vertical"
          onFinish={onUpdate}
          form={form}
          key={employescounter}
        >
          <Row gutter={16} className="org-update-top-row">
            <Upload
              name="orglogo"
              accept=".png,.jpg,.jpeg"
              listType="picture-card"
              maxCount={1}
              multiple={false}
              beforeUpload={(file) => {
                handleFileChange(file);
                return false;
              }}
              onRemove={() => {
                setPreviewImage(initiallogoUrl);
                setSelectedFile(null);
                setShowUploadButton(true);
              }}
              onPreview={() => setPreviewOpen(true)}
              defaultFileList={[
                {
                  uid: "-1",
                  name: "test.png",
                  status: "done",
                  url: initiallogoUrl || d_dp,
                },
              ]}
            >
              {showUploadButton && (
                <button className="user-profile-upload-button" type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              )}
            </Upload>
          </Row>
          <div className="org-form-fields">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Organization Name" name="organizationName">
                  <Input
                    disabled
                    size="large"
                    onChange={(e) => setOrganizationName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Industry Type" name="industryType">
                  <Input
                    size="large"
                    onChange={(e) => setIndustryType(e.target.value)}
                    placeholder="Enter your industry type..."
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Headquarter" name="headquarter">
                  <Input
                    size="large"
                    placeholder="Enter your head quarter..."
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          value &&
                          value.length > 3 &&
                          !phoneUtil.isValidNumber(
                            phoneUtil.parseAndKeepRawInput(value)
                          )
                        ) {
                          return Promise.reject("Invalid phone number");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <PhoneInput className="org-update-phone-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email" name="email">
                  <Input size="large" value={""} disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Website" name="website">
                  <Input size="large" placeholder="Enter website..." />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item className="submit-button-orgainzation-profile">
                  <Button
                    size="large"
                    htmlType="submit"
                    className="global-btn-width"
                    type="primary"
                  >
                    Update Profile
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
      <Modal
        open={previewOpen}
        title={"Image Preview"}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img src={previewImage || d_dp} className="user-update-avatar-image" />
      </Modal>
    </div>
  );
};

export default OrganisationUpdate;
