export const alertMessages = {
    success: {
      message: "Operation Successful",
      description: "Your request has been processed successfully.",
    },
    info: {
      message: "Information",
      description: "",
    },
    warning: {
      message: "Partial Success",
      description: "Something may not have been processed due to existing conditions that need your attention. Please review the details and make any necessary adjustments.",
    },
    error: {
      message: "Operation Failed",
      description: "Unfortunately, the operation could not be completed. Please try again or contact support if the problem persists.",
    },
  };