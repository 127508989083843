import React, { useEffect, useState } from "react";
import "./index.css";
import { Card } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchLeaveRequestListDataApi } from "../services";
import CustomTable from "../../../../common/CustomTable";

const LeaveRequestsHistory = () => {
  const [tableData, setTableData] = useState<any>();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [tableLoader, setTableLoader] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, [loggedInEmployee?.employeeId]);

  const fetchData = async () => {
    setTableLoader(true);
    const res = await fetchLeaveRequestListDataApi(
      loggedInEmployee?.employeeId,
      organizationId
    );
    setTableLoader(false);
    if (res.data) {
      setTableData(res.data);
    }
  };

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
    },
    {
      title: "Count",
      dataIndex: "leave_count",
      key: "leave_count",
    },
    {
      title: "From",
      dataIndex: "starting_date",
      key: "starting_date",
    },
    {
      title: "To",
      dataIndex: "ending_date",
      key: "ending_date",
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        const dateTime = new Date(text);
        const formattedDateTime = dateTime.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      title: "Status",
      dataIndex: "leave_status",
      key: "leave_status",
    },
  ];

  return (
    <>
      <Card title="" bordered={false}>
        <CustomTable
          dataSource={tableData}
          columns={columns}
          loading={tableLoader}
        />
      </Card>
    </>
  );
};

export default LeaveRequestsHistory;
