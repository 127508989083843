import React, { useEffect, useState } from "react";
import "./index.css";
import {
  fetchEmployeesDataApi,
  updateAttandanceMachineEmployeesApi,
} from "../services";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  AutoComplete,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { TriangleLoader } from "../../../../common/ReactLoader";
import CustomAlert from "../../../../common/Alert";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const AddEmployeesAttendanceMachine = ({
  data,
  hanldeAddEmployeeModalCLose,
}: any) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState<boolean>(true);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [selectedEmployeesData, setSelectedEmployeeData] = useState<any[]>([
    {
      id: Date.now(),
      employee_id: null,
      company: data.company_id,
      branch: data.branch_id,
      machineUserId: "",
    },
  ]);
  const [machineUser, setMachineUser] = useState<any[]>([]);
  const [filteredEmployee, setFilteredEmployee] = useState<any[]>([]);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  useEffect(() => {
    if (data) {
      if (data?.employees.length > 0) {
        setSelectedEmployeeData(data?.employees);
      }

      fetchEmployeeData();

      if (data.ip && data.port) {
        fetchMachineUsers(data.ip, data.port);
      }
    }
  }, [data.id]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchEmployeeData = async () => {
    try {
      setLoading(true);

      const res = await fetchEmployeesDataApi(data);

      const filteredData = res.data?.map((item: any) => {
        return { employee_id: item.id, full_name: item.full_name };
      });

      setFilteredEmployee(filteredData);
      setEmployeeSelectLoading(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchMachineUsers = async (ip: string, port: number) => {
    try {
      setLoading(true);
      const response = await fetch(
        "https://ideally-enabling-giraffe.ngrok-free.app/get_machine_users",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ip, port }),
        }
      );
      const data = await response.json();

      if (data?.users) {
        setMachineUser(data.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEmployeeChange = (
    value: number,
    id: number,
    userMachineId?: string
  ) => {
    const newData = selectedEmployeesData?.map((item: any) => {
      if (item.id === id) {
        const updatedItem = { ...item, employee_id: value };
        if (userMachineId) {
          updatedItem.machineUserId = userMachineId;
        }
        return updatedItem;
      }
      return item;
    });
    setSelectedEmployeeData(newData);
  };
  const addEmployee = () => {
    setSelectedEmployeeData([
      ...selectedEmployeesData,
      {
        id: Date.now(),
        employee_id: null,
        company: data.company_id,
        branch: data.branch_id,
        machineUserId: "",
      },
    ]);
  };

  const removeEmployee = (id: number) => {
    setSelectedEmployeeData(
      selectedEmployeesData?.filter((item: any) => item.employee_id !== id)
    );
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const body = {
        employee_id: selectedEmployeesData,
      };
      const res = await updateAttandanceMachineEmployeesApi(body, data.id);
      if (res.status == 204) {
        setAlertType("success");
        setshowAlert(true);
        setLoading(false);
        hanldeAddEmployeeModalCLose();
      } else {
        setAlertType("error");
        setshowAlert(true);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setAlertType("error");
      setshowAlert(true);
      setLoading(false);
    }
  };
  const transformedMachineUser = (data: any) => {
    return data.map((user: any) => ({
      value: user.uid,
      label: `${user.uid} (${user.name})`,
    }));
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={handleUpdate}>
        {selectedEmployeesData?.map((item: any) => (
          <Row gutter={16} key={item.id}>
            <Col span={24} md={10}>
              <Form.Item
                label="Select Employee"
                name={`employee-${item.id}`}
                rules={[
                  {
                    required: true,
                    message: "Please select an Employee",
                  },
                ]}
                initialValue={item.employee_id}
              >
                <Select
                  placeholder="Select an Employee"
                  onChange={(value) =>
                    handleEmployeeChange(
                      value,
                      item.id,
                      form.getFieldValue(`usermachineid-${item.id}`)
                    )
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                  loading={employeeSelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  disabled={item.employee_id !== null}
                >
                  {filteredEmployee?.map((emp) => (
                    <Option
                      key={emp.employee_id}
                      value={emp.employee_id}
                      disabled={selectedEmployeesData?.some(
                        (t: any) => t.employee_id === emp.employee_id
                      )}
                    >
                      {emp.full_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={10}>
              <Form.Item
                label="Enter User Machine ID"
                name={`usermachineid-${item.id}`}
                rules={[
                  {
                    required: true,
                    message: "Please enter User Machine ID",
                  },
                ]}
                initialValue={item.machineUserId}
              >
                <AutoComplete
                  options={transformedMachineUser(machineUser)}
                  onSelect={(value) =>
                    handleEmployeeChange(item.employee_id, item.id, value)
                  }
                  onChange={(value) =>
                    handleEmployeeChange(item.employee_id, item.id, value)
                  }
                  disabled={item.employee_id === null}
                  className=""
                >
                  <Input
                    size="large"
                    className="attendace-machine-input"
                    placeholder="Enter User Machine ID..."
                  />
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item label=" ">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => removeEmployee(item.employee_id)}
                  className="global-default-btn"
                >
                  Remove
                </Button>
              </Form.Item>
            </Col>
          </Row>
        ))}
        <div className="global-row-content-right">
          <Space>
            <Button
              className="global-btn-width"
              type="default"
              onClick={addEmployee}
              size="large"
            >
              Add Employee
            </Button>
            <Button
              className="global-btn-width"
              htmlType="submit"
              type="primary"
              size="large"
            >
              Update
            </Button>
          </Space>
        </div>
      </Form>
      {loading && <TriangleLoader />}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default AddEmployeesAttendanceMachine;
