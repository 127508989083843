import React, { useEffect, useState } from "react";
import "./index.css";
import { Space } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { fetchEmployeesApi } from "../../../components/EmployeesManagement/EmployeeViewDetails/services";

interface ResponsibilityProps {
  employeeId: any;
  loadingState: (state: boolean) => void;
}

const Responsibility: React.FC<ResponsibilityProps> = ({
  employeeId,
  loadingState,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const [employeeData, setEmployeeData] = useState<any>(null);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    loadingState(true);
    const employeesRes = await fetchEmployeesApi(organizationId, employeeId);
    loadingState(false);
    if (employeesRes.data) {
      setEmployeeData(employeesRes.data);
    }
  };

  return (
    <div className="res-container">
      <strong className="res-text-bold">Assignment</strong>
      {employeeData?.company?.name && (
        <div className="res-text-container">
          <span className="res-text">Company: </span>
          <span className="res-text-value">{employeeData.company.name}</span>
        </div>
      )}
      {employeeData?.branch?.name && (
        <div className="res-text-container">
          <span className="res-text">Branch: </span>
          <span className="res-text-value">{employeeData.branch.name}</span>
        </div>
      )}
      {employeeData?.department?.name && (
        <div className="res-text-container">
          <span className="res-text">Department: </span>
          <span className="res-text-value">{employeeData.department.name}</span>
        </div>
      )}
      {employeeData?.team?.name && (
        <div className="res-text-container">
          <span className="res-text">Team: </span>
          <span className="res-text-value">{employeeData.team.name}</span>
        </div>
      )}

      <strong className="res-text-bold">Position and Responsibility</strong>
      {employeeData?.designation?.name && (
        <div className="res-text-container">
          <span className="res-text">Employee Designation: </span>
          <span className="res-text-value">
            {employeeData.designation.name}
          </span>
        </div>
      )}
      {employeeData?.job_title?.name && (
        <div className="res-text-container">
          <span className="res-text">Job Title: </span>
          <span className="res-text-value">{employeeData.job_title.name}</span>
        </div>
      )}
      {employeeData?.work_type && (
        <div className="res-text-container">
          <span className="res-text">Work Type: </span>
          <span className="res-text-value">{employeeData.work_type}</span>
        </div>
      )}
      {employeeData?.employment_type && (
        <div className="res-text-container">
          <span className="res-text">Employment Type: </span>
          <span className="res-text-value">{employeeData.employment_type}</span>
        </div>
      )}
      {employeeData?.joining_date && (
        <div className="res-text-container">
          <span className="res-text">Joining Date: </span>
          <span className="res-text-value">
            {new Date(employeeData.joining_date).toLocaleDateString()}
          </span>
        </div>
      )}
      {employeeData?.responsibilities && (
        <>
          {" "}
          <div className="res-text-container">
            <span className="res-text">Responsibility: </span>
          </div>
          <div className="res-text-container">
            <span className="res-text-value">
              {employeeData.responsibilities}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Responsibility;
