import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { ReactNode } from "react";
import "./index.css";

const { Text } = Typography;

interface CardData {
  icon: ReactNode;
  digit: number | any;
  heading: string;
  subheading?: number | string | any;
  percentageWord?: string | any;
  iconBackgroundColor: string | any;
  className: string | any;
  showPercentage?: boolean; // New prop to control percentage display
}

const EmployeeCard: React.FC<CardData> = ({
  icon,
  digit,
  heading,
  subheading,
  percentageWord,
  iconBackgroundColor,
  className,
  showPercentage = true,
}) => {
  return (
    <Card
      className={className + "your-card"}
      bodyStyle={{ padding: 16}}
      bordered
    >
      <Row gutter={[10, 10]}>
        <Col span={20}>
          <div className="text-container">
            <Text className="heading-text">{heading}</Text>
            <Text className="digit-text">{digit}</Text>
            {showPercentage &&
            subheading !== undefined &&
            subheading !== 0 &&
            !isNaN(subheading) ? (
              <Text className="subheading-text">
                {subheading}
                {percentageWord ? percentageWord : ""}
              </Text>
            ) : (
              <Text className="subheading-text">---</Text>
            )}
          </div>
        </Col>

        <Col span={4}>
          <div
            className="icon-container"
            style={{ backgroundColor: iconBackgroundColor }}
          >
            {icon}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default EmployeeCard;
