import React, { useEffect, useState } from "react";
import {
  Space,
  Modal,
  Row,
  Col,
  Popconfirm,
  TablePaginationConfig,
  Empty,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import EditDepartmentModal from "../Edit";
import { SortOrder } from "antd/lib/table/interface";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { deleteDepartment, getDepartments } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import DepartmentFilterModal from "../Filter";

interface Department {
  name: string;
  unique_identifier: string;
  head_manager_id: string;
  phone_number: string;
  id: string | number | any;
  employee: any;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface DepartmentListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const DepartmentList: React.FC<DepartmentListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [editedDepartment, setEditedDepartment] = useState<Department | null>(
    null
  );
  const [viewedDepartment, setViewedDepartment] = useState<Department | null>(
    null
  );
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [selectedDepartmentId, setSelectedDepartmentId] = useState<
    number | null
  >(null);

  const [alertType, setAlertType] = useState<
    "error" | "success" | "info" | "warning"
  >("info");

  const [alertDescription, setAlertDescription] = useState<string>("");

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Organization",
    "Departments"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  useEffect(() => {
    if (organizationId) {
      fetchDepartments();
    }
  }, [organizationId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setDepartments(originalData.apiData);
    }
  }, [searchValue]);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const data = {
        organizationId: organizationId,
      };
      const response = await getDepartments(data);

      if (response.data) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: departments.length,
          },
        });
        setDepartments(response.data);
        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: response.data,
        }));
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      return error;
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleEditClick = (record: Department) => {
    setEditedDepartment(record);
    setEditModalVisible(true);

    setViewModalVisible(false);
    setSelectedDepartmentId(record.id);
  };

  const handleDeleteClick = (record: Department) => {
    setEditedDepartment(record);
    setDeleteConfirmVisible(true);
  };

  const handleViewClick = (record: Department) => {
    setViewedDepartment(record);
    setSelectedDepartmentId(record.id);
    setViewModalVisible(true);
    setEditModalVisible(false);
  };

  const handleViewModalCancel = () => {
    setViewModalVisible(false);
    setViewedDepartment(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      setAlertDescription("");
      if (editedDepartment) {
        const { id } = editedDepartment;

        const data = id;

        const response = await deleteDepartment(data, organizationId);

        if (response.error) {
          setshowAlert(true);
          setAlertDescription(
            "Deletion failed: The department has assigned employees. Please reassign or remove employees before deleting."
          );
          setAlertType("error");
        } else {
          setDeleteConfirmVisible(false);
          setEditedDepartment(null);
          fetchDepartments();
          setshowAlert(true);
          setAlertType("success");
        }
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmVisible(false);
    setEditedDepartment(null);
  };

  const columns = [
    {
      title: "Department Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Manager",
      dataIndex: "employee",
      key: "employee",
      render: (employee: any) => (employee ? employee?.full_name : ""),
    },
    {
      title: "Head Count",
      dataIndex: "head_count",
      render: (record: number) => (record && record > 0 ? record : 0),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: Department) => (
        <Space size="middle">
          <span className="link" onClick={() => handleViewClick(record)}>
            <EyeOutlined />
          </span>
          {edit && (
            <span className="link" onClick={() => handleEditClick(record)}>
              <EditOutlined />
            </span>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this department?"
            onConfirm={() => handleDeleteConfirm()}
            onCancel={handleDeleteCancel}
            open={
              deleteConfirmVisible &&
              editedDepartment?.unique_identifier === record.unique_identifier
            }
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link" onClick={() => handleDeleteClick(record)}>
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setDepartments(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (full_name: string) => {
    const filterArray = [{ path: "employee.full_name", value: full_name }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setDepartments(response);
    }
  };

  return (
    <>
      {read ? (
        <CustomTable
          dataSource={departments}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      {editModalVisible && (
        <EditDepartmentModal
          selectedDepartmentId={selectedDepartmentId}
          onClose={() => setEditModalVisible(false)}
          fetchDepartments={fetchDepartments}
        />
      )}

      <Modal
        open={viewModalVisible}
        onCancel={handleViewModalCancel}
        footer={null}
        width={700}
        title={<span className="global-modal-title">Department Details</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Row gutter={10} className="department-row-1">
            <Col
              span={24}
              md={12}
              className="department-view-modal-heading-container"
            >
              <b>Department Name</b>
              <br />{" "}
              {viewedDepartment?.name ? viewedDepartment?.name : "No Data"}
            </Col>
            <Col span={24} md={12} className="">
              <b>Department Code</b>
              <br />{" "}
              {viewedDepartment?.unique_identifier
                ? viewedDepartment?.unique_identifier
                : "No Data"}
            </Col>
          </Row>
          <Row gutter={10} className="department-row-2">
            <Col span={24} md={12}>
              <b>Head Manager Name</b>
              <br />{" "}
              {viewedDepartment?.employee?.full_name
                ? viewedDepartment?.employee?.full_name
                : "No Data"}
            </Col>
            <Col span={24} md={12}>
              <b>Phone Number</b>
              <br />{" "}
              {viewedDepartment?.phone_number &&
              viewedDepartment?.phone_number.length > 3
                ? viewedDepartment?.phone_number
                : "No Data"}
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <DepartmentFilterModal
          getFilterDataHandler={(full_name) => getFilterDataHandler(full_name)}
        />
      </Modal>

      {showAlert && (
        <CustomAlert
          showAlert={showAlert}
          type={alertType}
          description={alertDescription}
        />
      )}
    </>
  );
};

export default DepartmentList;
