import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./index.css";
import { ConfigProvider } from "antd";
import { theme } from "antd";
import {
  config,
  tableTheme,
  buttonTheme,
  avatarTheme,
  spinTheme,
  paginationTheme,
  inputNumberTheme,
  inputTheme,
  datePickerTheme,
  selectInputTheme,
  radioTheme,
  checkboxTheme,
  switchTheme,
  tooltipTheme,
  tourTheme,
  cardTheme,
  menuTheme,
  stepsTheme,
  emptyTheme,
  tagsTheme,
  descriptionTheme,
} from "./themeConfig";

const { getDesignToken } = theme;

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);
let persistor = persistStore(store);
const globalToken = getDesignToken(config);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfigProvider
          theme={{
            token: globalToken,
            components: {
              Table: {
                ...tableTheme,
              },
              Button: {
                ...buttonTheme,
              },
              Avatar: {
                ...avatarTheme,
              },
              Spin: {
                ...spinTheme,
              },
              Pagination: {
                ...paginationTheme,
              },
              Input: {
                ...inputTheme,
              },
              InputNumber: {
                ...inputNumberTheme,
              },
              DatePicker: {
                ...datePickerTheme,
              },
              Select: {
                ...selectInputTheme,           
              },
              Radio: {
                ...radioTheme,
              },
              Checkbox: {
                ...checkboxTheme,
              },
              Switch: {
                ...switchTheme,
              },
              Tooltip: {
                ...tooltipTheme,
              },
              Tour: {
                ...tourTheme,
              },
              Menu: {
                ...menuTheme,
              },
              Card: {
                ...cardTheme,
              },
              Steps: {
                ...stepsTheme,
              },
              Empty: {
                ...emptyTheme
              },
              Tag: {
                ...tagsTheme,
              },
              Descriptions: {
                ...descriptionTheme,
              }
            },
          }}
        >
          <App />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
