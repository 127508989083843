import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../../redux/EmployeeProfileSlice";
import { getEmployeesPicturesAndPostalCodes } from "../../services";
import { Map, Overlay } from "pigeon-maps";
import "./index.css";

const MapView = () => {
  const [coordinates, setCoordinates] = useState<any[]>([]);
  const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);

  const loggedEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const organizationId = loggedEmployee?.employee?.organization_id;
  const companyId = loggedEmployee?.employee?.company_id;
  const branchId = loggedEmployee?.employee?.branch_id;

  useEffect(() => {
    getAllRecordsData();
  }, []);

  const getAllRecordsData = async () => {
    try {
      const response = await getEmployeesPicturesAndPostalCodes(
        organizationId,
        companyId,
        branchId
      );

      if (response) {
        const filteredData = response.data.filter(
          (item: any) => item.present_address_detail?.geocoordinates
        );

        const coords = filteredData.map((item: any) => ({
          lat: Number(item.present_address_detail.geocoordinates[0]),
          lng: Number(item.present_address_detail.geocoordinates[1]),
          avatar: item.employee.avatar_url,
          name: item.employee.full_name,
          street: item.present_address_detail.present_street,
          city: item.present_address_detail.present_city,
        }));

        setCoordinates(coords);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="main-map-container">
      <Map height={600} defaultCenter={[31.5497, 74.3436]} defaultZoom={8}>
        {coordinates.map((coord, index) => (
          <Overlay key={index} anchor={[coord.lat, coord.lng]}>
            <div
              onMouseEnter={() => setTooltipIndex(index)}
              onMouseLeave={() => setTooltipIndex(null)}
              style={{ position: "relative", textAlign: "center" }}
            >
              {coord.avatar ? (
                <img
                  src={coord.avatar}
                  alt={coord.name}
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
              ) : (
                <div className="tooltip-index-1">{coord.name[0]}</div>
              )}
              {tooltipIndex === index && (
                <div className="tooltip-index-2">
                  {coord.name}
                  <br />
                  {`${coord.street}, ${coord.city}`}
                </div>
              )}
            </div>
          </Overlay>
        ))}
      </Map>
    </div>
  );
};

export default MapView;
