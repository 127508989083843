import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//--------------------------------------Create Teams------------------------------------------
export const getDepartments = async (Ids: any, orgId: string) => {
  try {
    let fetchDep = supabase_client_for_Org_schema(orgId)
      .from("department")
      .select("*")
      .eq("company_id", Ids.companyId);

    if (Ids.branchId) {
      fetchDep = fetchDep.eq("branch_id", Ids.branchId);
    }

    const response = await fetchDep;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const dataInsertion = async (data: any, orgId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("team")
      .insert(data.body);
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const branchManagerNamesTeam = async (data: any) => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

//--------------------------------------------------Table View----------------------------------

export const getData = async (Ids: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("team")
      .select(
        `*, employee!team_manager_id_fkey(full_name,avatar_url,job_title!employee_job_title_id_fkey(name)), department(name, unique_identifier)`
      )
      .eq("organization_Id", Ids.organizationId);

    if (Ids.companyId) {
      query = query.eq("company_id", Ids.companyId);
    }

    if (Ids.searchQuery) {
      query = query.ilike("name", `%${Ids.searchQuery}%`);
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleDeletion = async (
  id: string | number,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("team")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//-------------------------------------------Edit Teams--------------------------------------

export const getTeamData = async (
  id: string | number | null,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("team")
      .select(`*, employee!team_manager_id_fkey(full_name)`)
      .eq("id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployees = async (id: string | number): Promise<any> => {
  try {
    const response = await supabase
      .from("Employees")
      .select("id, full_name")
      .eq("organizationId", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};
export const getEmployeesEditData = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleUpdation = async (
  id: string | number,
  body: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("team")
      .update(body)
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};
