import { catch_return } from "../../../common/Constants";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

export const getTheLeaveRequestData = async (
  id: any,
  organizationId: string
) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select(`*, employee(first_name)`)
      .eq("id", id)
      .maybeSingle();

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchRequestedLeavesApi = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select(`*, employee(first_name,avatar_url)`)
      .eq("organization_id", organizationId);
    // .eq("leave_status","Requested")
    if (companyId) {
      query.eq("company_id", companyId);
    }
    if (branchId) {
      query.eq("branch_id", branchId);
    }
    const res = await query.order("created_at", { ascending: false });
    return res;
  } catch {
    return catch_return;
  }
};

export const ResponseRequestedLeavesApi = async (
  body: any,
  id: any,
  organizationId: string
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .update(body)
      .eq("id", id);
    return res;
  } catch {
    return catch_return;
  }
};
