import React, { useEffect, useState } from "react";
import { Space, Table, Tooltip } from "antd";
import "./index.css";
import { capitalizeWords } from "../../../../common/validation";
import moment from "moment";
import { IoFlagSharp } from "react-icons/io5";
import { EyeOutlined } from "@ant-design/icons";
import { getAnEmployeesAttendanceDetails } from "../services";
import AttendanceSheetView from "../DetailView";
import CustomTable from "../../../../common/CustomTable";

interface AttendanceSheetProps {
  employeeAttendanceData: any[];
  loading: boolean;
}

const AttendanceSheet: React.FC<AttendanceSheetProps> = (props) => {
  const [employeeAttendanceId, setEmployeeAttendanceId] = useState<string>("");
  const [viewModalVisible, setViewModalVisible] = useState<boolean>(false);

  const storeData = (details: any[]) => {
    const sorted_data = details.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });
    return sorted_data;
  };

  const columns: any = [
    {
      title: "Date",
      key: "date",
      width: 150,
      fixed: "left",
      render: (record: any) => (
        <>
          {record?.date}
          <div className="employee-attendance-logs-manual-flag">
            {record?.manually_accomodated ? (
              <Tooltip title="Manually Aaccommodate">
                <IoFlagSharp className="employee-attendance-logs-set-flag" />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    },
    {
      title: "Checked In",
      dataIndex: "check_in",
      key: "check_in",
      render: (record: any) => moment(record).format("MMM-DD hh:mm:ss"),
    },
    {
      title: "Checkin Status",
      dataIndex: "check_in_status",
      key: "check_in_status",
      render: (record: any) => capitalizeWords(record),
    },
    {
      title: "Checked Out",
      dataIndex: "check_out",
      key: "check_out",
      render: (record: any) =>
        record ? moment(record).format("MMM-DD hh:mm:ss") : "--",
    },
    {
      title: "Checkout Status",
      dataIndex: "check_out_status",
      key: "check_out_status",
      render: (record: any) => (record ? capitalizeWords(record) : "--"),
    },
    {
      title: "From",
      dataIndex: "fetch_from",
      key: "fetch_from",
    },
    {
      title: "Short Hours",
      dataIndex: "short_working_hours",
      key: "short_working_hours",
      render: (record: any) => {
        if (!record) return "--";

        const timeParts = record.split(":");
        if (timeParts.length === 2) {
          const hours = timeParts[0].padStart(2, "0");
          const minutes = timeParts[1].padStart(2, "0");
          return `${hours}h : ${minutes}m`;
        }
        return capitalizeWords(record);
      },
    },
    {
      title: "Worked Hours",
      dataIndex: "worked_hours",
      key: "worked_hours",
      render: (record: any) => {
        if (!record) return "--";
        const timeParts = record.split(":");
        if (timeParts.length === 2) {
          const hours = timeParts[0].padStart(2, "0");
          const minutes = timeParts[1].padStart(2, "0");
          return `${hours} : ${minutes}`;
        }
        return capitalizeWords(record);
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size={"middle"}>
          <Tooltip title={"Click to view Message Details."}>
            <EyeOutlined
              onClick={() => {
                setViewModalVisible(true);
                setEmployeeAttendanceId(record.id);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="employee-attendances">
      <CustomTable
        scroll={{ x: true }}
        dataSource={storeData(props.employeeAttendanceData)}
        columns={columns}
        loading={props.loading}
        className="employee-attendances-table"
      />

      {viewModalVisible && employeeAttendanceId && (
        <AttendanceSheetView
          visible={viewModalVisible}
          onRequestClose={() => {
            setViewModalVisible(false);
            setEmployeeAttendanceId("");
          }}
          recordId={employeeAttendanceId}
        />
      )}
    </div>
  );
};

export default AttendanceSheet;
