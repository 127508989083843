import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type EmailDomain = string;

interface RootState {
  emailDomain: EmailDomain;
}


const initialState: EmailDomain = "";

export const emailDomainSlice = createSlice({
  name: "emailDomain",
  initialState,
  reducers: {
   
    setEmailDomain: (state, action: PayloadAction<EmailDomain>) => {
   
      return action.payload;
    },
  },
});

export const { setEmailDomain } = emailDomainSlice.actions;


export const selectEmailDomain = (state: RootState) => state.emailDomain;

export default emailDomainSlice.reducer;
