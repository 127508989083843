import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Select,
  Space,
} from "antd";
import { useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  deleteKPIScalesDataApi,
  getBranchesByCompanyId,
  getKPIScalesData,
  setKPIScalesData,
} from "../services";
import type { ColumnsType, SortOrder } from "antd/lib/table/interface";
import { capitalizeWords } from "../../../../common/validation";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const AddKPIScale = () => {
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [tableData, setTableData] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [scaleNumbers, setScaleNumbers] = useState<number[]>([1, 2, 3, 4, 5]);
  const userRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id ||
    selectedCompany;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id ||
    selectedBranch;

  useEffect(() => {
    if (companyId) {
      form.setFieldsValue({ company: companyId });
      setSelectedCompany(companyId);
      setIsDisabled(false);
      if (branchId) {
        form.setFieldsValue({ branch: branchId });
        setSelectedBranch(branchId);
        setIsDisabled(false);
      }
    } else {
      getCompanies();
    }
  }, [companyId, branchId, form]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getCompanies = async () => {
    const { data } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Performance Metric",
      dataIndex: "performance_metrics",
      key: "performance_metrics",
      render: (text: any) => capitalizeWords(text),
    },
    {
      title: "Scale",
      dataIndex: "scale",
      key: "scale",
      sorter: (a: any, b: any) => a.scale - b.scale,
      defaultSortOrder: "ascend" as SortOrder,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <>
          <Space size={"middle"}>
            <Popconfirm
              placement="topLeft"
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDeleteMetric(record)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const handleAddToTable = () => {
    const newEntry = form.getFieldValue("performance_metrics")?.toLowerCase();
    const scale = form.getFieldValue("scale");
    const isDuplicate = tableData.some(
      (item) => item.performance_metrics?.toLowerCase() === newEntry
    );

    if (isDuplicate) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      form.validateFields();
      setTableData([
        ...tableData,
        { performance_metrics: newEntry, scale: scale },
      ]);
    }
  };

  const getAvailableScaleNumbers = () => {
    const usedScales = new Set(tableData.map((item: any) => item.scale));
    return scaleNumbers?.filter((scale) => !usedScales?.has(scale));
  };

  const handleDeleteMetric = async (record: any) => {
    const deleteResponse = await deleteKPIScalesDataApi(
      record.id,
      organizationId
    );
    if (deleteResponse) {
      setshowAlert(true);
      setAlertType("success");
    }
    const updatedTableData = tableData?.filter(
      (item: any) => item.performance_metrics !== record.performance_metrics
    );
    setTableData(updatedTableData);
  };

  const handleSaveToDatabase = async () => {
    setButtonLoading(true);
    const formattedData = tableData.map((item: any) => ({
      companyId: selectedCompany || companyId,
      organizationId,
      branchId: selectedBranch || branchId || null,
      ...item,
    }));

    if (formattedData) {
      const response = await setKPIScalesData(formattedData, organizationId);
      if (response) {
        setshowAlert(true);
        setAlertType("success");
        setTableData([]);
        setButtonLoading(false);
      }
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    const getScales = async () => {
      const data = {
        companyId: selectedCompany,
        branchId: selectedBranch,
      };
      setLoading(true);
      const response = await getKPIScalesData(data, organizationId);

      if (response.data) {
        if (selectedBranch) {
          setTableData(response.data);
        } else {
          const filteredData = response.data.filter(
            (item: any) => item.branch_id === null
          );

          setTableData(filteredData);
        }
        setLoading(false);
      } else if (response.error) {
        setLoading(false);
        setTableData([]);
      }
    };
    getScales();
  }, [selectedCompany, userRole, selectedBranch]);

  useEffect(() => {
    getBranches(selectedCompany);
  }, [selectedCompany]);

  const getBranches = async (value: any) => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      if (!selectedCompany) {
        setBranches([]);
        setSelectedBranch(null);
        form.setFieldsValue({ branch: undefined });
        setIsDisabled(true);
      } else {
        setIsDisabled(false);

        const response = await getBranchesByCompanyId(
          selectedCompany,
          organizationId
        );

        if (response.data) {
          setBranches(response.data);
          form.setFieldValue("branch", response.data[0]?.id);
          setSelectedBranch(response.data[0]?.id);
        }
      }
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            {!companyId && (
              <Col span={12}>
                <Form.Item
                  name={"company"}
                  label="Companies"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company...",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option?.children
                        ? (option?.children as string)
                            .toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedCompany}
                    placeholder="Select a company"
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!(companyId || branchId) && (
              <Col span={12}>
                <Form.Item name={"branch"} label="Branches">
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option?.children
                        ? (option?.children as string)
                            .toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        : false
                    }
                    defaultValue={selectedBranch}
                    placeholder="Select a branch..."
                    onChange={setSelectedBranch}
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {branches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Performance Metric"
                name="performance_metrics"
                rules={[
                  {
                    required: true,
                    message: "Please enter a performance metric.",
                  },
                ]}
              >
                <Input
                  placeholder="Enter a performance metric..."
                  disabled={isDisabled}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Scale"
                name="scale"
                rules={[
                  {
                    required: true,
                    message: "Please enter a percentage.",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  placeholder="Please select a scale..."
                  disabled={isDisabled}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {getAvailableScaleNumbers().map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item} ({item * 20} out of 100)
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row className="add-to-table-btn">
          <Button
            className="global-btn-width"
            type="primary"
            onClick={handleAddToTable}
            size="large"
          >
            Add Record
          </Button>
        </Row>
        <CustomTable
          columns={columns}
          pagination={false}
          dataSource={tableData}
          loading={loading}
        />

        <Row className="global-modal-bottom-row save-to-database-btn">
          <Button
            className="global-btn-width"
            type="primary"
            onClick={handleSaveToDatabase}
            loading={buttonLoading}
            size="large"
          >
            Create{" "}
          </Button>
        </Row>
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};

export default AddKPIScale;
