import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  Button,
  Col,
  Empty,
  Form,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../common/Alert";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import {
  fetchEmployeeAssetsDataApi,
  getAllAssetsForEmployeesApi,
  getAllBranchesDataApi,
  getEmployeesWithNoAssetsApi,
  submitAssignAssets,
} from "../services";
import CustomTable from "../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const AssetAssigning: React.FC<props> = ({ onCancel }) => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [counter, setCounter] = useState<number>(0);
  const [assetsList, setAssetsList] = useState<any[]>([]);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>(null);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<"success" | "info" | "warning" | "error">("info");
  const [companySelectLoading, setCompanySelectLoading] =useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] = useState<boolean>(false);
  
  const [selectLoading1, setSelectLoading1] = useState<boolean>(false);
  const [selectLoading2, setSelectLoading2] = useState<boolean>(false);
  const [selectedAssest, setSelectedAsset] = useState<any>(null);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [tableloader, setTableLoader] = useState<boolean>(false)
 
  const [form] = useForm();
  const companyId = useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId = useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const organizationId = useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const formattedData = {
    company_id: selectedCompanyId || companyId || null,
    organization_Id: organizationId,
    branch_id: selectedBranch || branchId || null,
    employee_Id: selectedEmployee?.value,
    assets: dataArray.map((item: any) => ({
      assetId: item.assetId,
      name: item.name,
      manufacturer: item.manufacturer,
    })),
  };

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    }
    if (selectedCompanyId) {
      getAllBranchess();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompanyId(companyId);
      form.setFieldValue("companyId", companyId);

    } else {
      getCompanyOptions();
    }
  }, []);

  useEffect(() => {
    getAssetsList();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      getAllEmployees();
    }
  }, [selectedCompanyId, selectedBranch]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 500);
    }
  }, [showAlert]);

  useEffect(() => {
    setDataArray([])
  }, [selectedEmployee])

  const getAllEmployees = async () => {
    try {
      setSelectLoading1(true);
      setEmployees([]);
      form.setFieldValue("employeeId", "");
      const response: any = await getEmployeesWithNoAssetsApi(
        selectedCompanyId,
        selectedBranch,
        organizationId
      );

      if (response && response.data.length) {
        setEmployees(response.data);
        setSelectLoading1(false);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      setEmployees([]);
    }
  };
  const filterAssetsNotInTable = (assetsList: any, dataArray: any) => {
   
    const assetNamesInTable = dataArray.map((data: any) => data.assetId);

    const filteredAssets = assetsList.filter(
      (asset: any) => !assetNamesInTable.includes(asset.id)
    );
    return filteredAssets;
  };

   
  const handleAssetChange = (value: any) => {
    const selectedAsset = assetsList.find((asset) => asset.id  === value);
    if (selectedAsset) {
      setSelectedAsset(selectedAsset);
    }  
  };
  const handleRemoveItem = (assetId: any) => {
    
    const updatedDataArray = dataArray.filter(
      (item) => item.assetId !== assetId
    );
    setDataArray(updatedDataArray);
  };
  const addDataToTable = () => {
    const data = {
      assetId: selectedAssest.id,
      name:  selectedAssest.name,
      manufacturer:  selectedAssest.manufacturer,
    };
    form.setFieldValue("assetName", "")
    setDataArray((prevDataArray) => [...prevDataArray, data]);
  };
  const getAssetsList = async () => {
    setSelectLoading2(true);
    const response = await getAllAssetsForEmployeesApi(
      selectedCompanyId,
      branchId,
      organizationId
    );
    if (response.data) {
      setSelectLoading2(false);
      setAssetsList(response.data);
    }
  };
  const getDataBasedOnSelectedEmployee = async (employeeId: string) => {
    setTableLoader(true)
    if (employeeId) {
      const response = await fetchEmployeeAssetsDataApi(
        employeeId,
        organizationId
      );
      if (response.data) {
        const formattedData = response.data.flatMap((item: any) =>
          item.assets.map((asset: any) => ({
            key: asset.assetId,
            name: asset.name,
            manufacturer: asset.manufacturer,
            assetId: asset.assetId,
          }))
        );

        setDataArray(formattedData);
      }


    }
    setTableLoader(false)
  };

  const submitData = async () => {
    try {
      const res = await form.validateFields();
      if (!res.errorFields) {
        setButtonLoading(true);

        const response = await submitAssignAssets(
          formattedData,
          organizationId
        );

        if (response.status) {
          setshowAlert(true);
          setButtonLoading(false);
          setAlertType("success");
          form.resetFields();
          setEmployees([]);
          setBranches([]);
          setDataArray([]);
          setSelectedCompanyId(null);
          setSelectedBranch(null);
          setSelectedEmployee(null);
          setCounter(counter + 1);
          setTimeout(()=>{
            onCancel();
          },2000)
        
        } else {
          setButtonLoading(false);
        }
      } else {
      }
    } catch (error) { }
  };
  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      setSelectedBranch(null);
      const Ids = selectedCompanyId;
      const response = await getAllBranchesDataApi(Ids, organizationId);
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
      }
      setBranchSelectLoading(false);
    }
  };
  const getCompanyOptions = async () => {
    setCompanySelectLoading(true);
    const { data, error } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanyOptions(data);
      setCompanySelectLoading(false);
    }
    setCompanySelectLoading(false);
  };

  const columns = [
    {
      title: "Name of the Asset",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Asset's Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size={"middle"}>
          <Popconfirm
            placement="topLeft"
            title="Do you want to remove this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRemoveItem(record.assetId)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleEmployeeChange = (employeeData: any) => {
    getDataBasedOnSelectedEmployee(employeeData.value)
    setSelectedEmployee(employeeData)
  }

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form}>
        <Row gutter={[16, 16]}>
          {!companyId && (
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                name={"companyId"}
                label="Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
              >
                <Select
                  placeholder="Select a company..."
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompanyId}
                  value={selectedCompanyId}
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companyOptions.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(branchId) && (
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item name="selectedBranch" label="Select a branch">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Please select a branch (if any)"
                  onChange={setSelectedBranch}
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12}>
            <Form.Item
              name="employeeId"
              label="Select an Employee"
              rules={[
                {
                  required: true,
                  message: "Please select an Employee",
                },
              ]}
            >
              <Select
                labelInValue
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                showSearch
                onChange={handleEmployeeChange}
                loading={selectLoading1}
                placeholder="Select an Employee"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {employees.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={8}>
            <Form.Item
              name={"assetName"}
              label="Choose an asset"
              rules={[
                {
                  required: dataArray?.length > 0 ? false : true,
                  message: "Please select an Asset",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={handleAssetChange}
                loading={selectLoading2}
                placeholder="Select an asset"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filterAssetsNotInTable(assetsList, dataArray).map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}-{item.manufacturer}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} md={4}>
            <Form.Item name={"submissionButton"} label=" ">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={addDataToTable}
                size="large"
              >
                Add Asset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomTable
        columns={columns}
        dataSource={dataArray}

        loading={tableloader}
      />
      <Row className="global-modal-bottom-row submit-btn-for-assets">
        <Form.Item>
          <Button
            className="global-btn-width"
            type="primary"
            onClick={submitData}
            loading={buttonLoading}
            size="large"
          >
            Create
          </Button>
        </Form.Item>
      </Row>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default AssetAssigning;
