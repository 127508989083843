import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Select, Divider } from "antd";
import "./index.css";
import moment from "moment";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { ProgressLoader } from "../../../common/ReactLoader";

import d_dp from "../../../assets/images/blankProfile.png";
import { MdCelebration } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface BirthdayReminderProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const BirthdayReminder: React.FC<BirthdayReminderProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [employees, setEmployees] = useState<any[] | null>(null);
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    filterUpcomingBirthdays(props.employeeData);
  }, [props.employeeData]);

  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);

  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);

  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);

  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    filterUpcomingBirthdays(tempData);
  };

  const filterbranches = () => {
    if (props.branches && selectedCompany) {
      const filterbranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );

      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const filterUpcomingBirthdays = (data: any[]) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const upcomingBirthdays = data
      .filter((person) => {
        const personBirthDate = new Date(person.dob);
        const birthMonth = personBirthDate.getMonth();
        return (
          birthMonth >= currentMonth && birthMonth <= (currentMonth + 2) % 12
        );
      })
      .sort((a, b) => {
        const dateA = new Date(a.dob);
        const dateB = new Date(b.dob);
        const monthA = dateA.getMonth();
        const monthB = dateB.getMonth();
        const dayA = dateA.getDate();
        const dayB = dateB.getDate();

        if (monthA === monthB) {
          return dayA - dayB;
        } else {
          return monthA - monthB;
        }
      });
    setEmployees(upcomingBirthdays);
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <>
      <Card
        className="main-birthday-container dasboard-cards"
        title={
          <Row gutter={16} className="icon-toggle-container">
            <Col
              xxl={6}
              xl={6}
              lg={8}
              md={8}
              sm={12}
              xs={24}
              className="byattendance-title-text"
            >
              Upcoming Birthdays
            </Col>
            <Col xxl={18} xl={18} lg={16} md={16} sm={12} xs={24}>
              {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id && loggedInEmployee?.employeeRole !== "Hr" && (
                <Row gutter={16} className="byattendace-card-selectors-row">
                  {isToggled && (
                    <>
                      <Col
                        span={7}
                        className={`header-inputs ${
                          isToggled ? "visible" : "hidden"
                        }`}
                      >
                        <Select
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option && option.children
                              ? (option.children as string)
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              : false
                          }
                          allowClear
                          placeholder="Select a company..."
                          onChange={setSelectedCompany}
                          className="byattendance-card-selectors-width"
                          notFoundContent={
                            <NoDataFoundIcon />
                          }
                        >
                          {companiesOption.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                      <Col
                        span={7}
                        className={`header-inputs ${
                          isToggled ? "visible" : "hidden"
                        }`}
                      >
                        <Select
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option && option.children
                              ? (option.children as string)
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              : false
                          }
                          allowClear
                          placeholder="Select a branch..."
                          onChange={setSelectedBranch}
                          className="byattendance-card-selectors-width"
                          notFoundContent={
                            <NoDataFoundIcon />
                          }
                        >
                          {branchOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </>
                  )}
                  <Col
                    span={2}
                    className="birthday-icon-container"
                    onClick={handleToggle}
                  >
                    {isToggled ? (
                      <div className="birthday-search-icon-container">
                        <IoSearch className="birthday-search-icon" />
                      </div>
                    ) : (
                      <div className="birthday-search-icon-container">
                        <IoSearch className="birthday-search-icon" />
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        }
        bordered={true}
      >
        <div className="birthday-scrollable-content">
          {props.employeeDataShow ? (
            <ProgressLoader />
          ) : employees && employees?.length > 0 ? (
            <div>
              {employees.map((employee: any, index: number) => (
                <div key={employee.id}>
                  <div className="birthday-custom-new-container">
                    <div className="birthday-custom-new-avatarCol">
                      <Avatar
                        shape="circle"
                        size={{
                          xxl: 60,
                          xl: 60,
                          lg: 60,
                          md: 60,
                          sm: 60,
                          xs: 60,
                        }}
                        src={employee.avatar_url || d_dp}
                      />
                      <MdCelebration className="birthday-custom-new-newWord" />
                    </div>
                    <Row className="birthday-card-data-row">
                      <Col
                        xxl={19}
                        xl={19}
                        lg={19}
                        md={19}
                        sm={18}
                        xs={18}
                        className="birthday-title-col"
                      >
                        <Row className="birthday-title">
                          {employee.full_name}
                        </Row>
                        <Row className="birthday-designation">
                          {employee?.Designation?.designation}
                        </Row>
                      </Col>
                      <Col
                        xxl={5}
                        xl={5}
                        lg={5}
                        md={5}
                        sm={6}
                        xs={6}
                        className="birthday-data-col"
                      >
                        <div className="birthday-join-date">
                          {moment(employee?.dob).format("MMM D, YYYY")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {index < employees.length - 1 && (
                    <Divider className="birthday-card-divider" />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="no-data-text">
              No birthdays to celebrate 🎉 - Let's keep rocking! 🚀
            </p>
          )}
        </div>
      </Card>
    </>
  );
};

export default BirthdayReminder;
