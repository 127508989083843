import { supabase_client_for_Org_schema } from "../../../../services/supabase";

export const getAllEmployeesWithoutContactDetails = async (
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id, full_name")
      .or(
        'phone_number.is.null,phone_number.eq."",' +
          'work_phone_number.is.null,work_phone_number.eq."",' +
          'work_email.is.null,work_email.eq.""'
      )
      // .eq("status", "active");
 
    return response.data;
  } catch (error) {
    return error;
  }
};
