import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../../redux/EmployeeProfileSlice";
import {
  Modal,
  Popconfirm,
  Space,
  Switch,
  Tag,
  Tooltip,
  Tour,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOutlined,
  LinkOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import EditJobDetails from "../Edit";
import JobPreview from "../JobPreview";
import CustomAlert from "../../../../common/Alert";
import { FolderOpenOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMan } from "react-icons/io5";
import { TourProps } from "antd/lib";
import {
  fetchData,
  fetchOrgDomain,
  handleTheSwitchChanging,
  jobDeletion,
} from "../../services";

import CustomTable from "../../../../common/CustomTable";
import { updatedEmployeeTourStatusApi } from "../../../../services/Global";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import JobPostingViewFilterModal from "../Filter";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";

interface ViewJobListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const ViewJob: React.FC<ViewJobListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const dispatch = useDispatch();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = useSelector(selectLogedEmployee);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobId, setJobId] = useState<any>(null);
  const [isJobModelOpen, setIsJobModelOpen] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "Recruitment", "Job Post");

  const deleteAccess = access?.deleteAccess;
  const edit = access?.writeAccess;

  const [open, setOpen] = useState<boolean>(false);
  const [orgSubDomain, setOrgSubDomain] = useState<any>(null);

  const orgId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchDataa();
    getOrgDomain();
    setOpen(!loggedInEmployee?.tour?.recruitment_tour || false);
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setData(originalData.apiData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchDataa = async () => {
    setIsLoading(true);
    const response = await fetchData(
      organizationId.logedInemployee?.employee.organization_id,
      organizationId.logedInemployee?.employee.company_id,
      organizationId.logedInemployee?.employee.branch_id
    );

    if (response.data) {
      setData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getOrgDomain = async () => {
    const response = await fetchOrgDomain(
      organizationId.logedInemployee?.employee.organization_id
    );
    if (response.data) {
      setOrgSubDomain(response.data?.sub_domain);
    }
  };

  const handleDeleteJob = async (jobId: any) => {
    const response = await jobDeletion(
      jobId,
      organizationId.logedInemployee?.employee.organization_id
    );
    if (
      response.status === 201 ||
      response.status === 204 ||
      response.status === 200
    ) {
      setShowAlert(true);
      setAlertType("success");
      fetchDataa();
    } else {
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const handleSwitchChange = async (checked: boolean, recordId: any) => {
    try {
      const response = await handleTheSwitchChanging(
        checked,
        recordId,
        organizationId.logedInemployee?.employee.organization_id
      );
      if (response.error) {
        setShowAlert(true);
        setAlertType("error");
      } else {
        const newData = data.map((item: any) =>
          item.id === recordId ? { ...item, status: checked } : item
        );

        setData(newData);
        fetchDataa();
        setShowAlert(true);
        setAlertType("success");
        setTimeout(() => {
          setShowAlert(false);
        }, 1500);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertType("error");
      setTimeout(() => {
        setShowAlert(false);
      }, 1500);
    }
  };
  const navigate = useNavigate();
  const organizationName =
    useSelector(selectLogedEmployee).logedInemployee?.employee
      ?.organization_name;

  const navigateToJobApplication = (jobId: any) => {
    navigate(
      `/${loggedInEmployee?.employee?.access_right_group}/recruitment/applicationlist/${jobId}`
    );
  };

  const navigateToAcceptedApplications = (jobId: any) => {
    navigate(
      `/${loggedInEmployee?.employee?.access_right_group}/recruitment/shortlistedcandidates/${jobId}`
    );
  };
  const navigateToHiredCandidates = (jobId: any) => {
    navigate(
      `/${loggedInEmployee?.employee?.access_right_group}/recruitment/hiredcandidates/${jobId}`
    );
  };

  const handleCopyToClipBoard = (id: any) => {
    if (orgSubDomain) {
      const link = `https://${orgSubDomain}.misgenie.app/hrm/applicationsubmission/${orgId}/${id}`;

      navigator.clipboard.writeText(link).then(() => {
        message.success("Link copied successfully!");
      });
    }
  };

  const columns = [
    {
      title: "Job Title",
      dataIndex: "basic_details",
      key: "jobTitle",
      render: (basicDetails: any) => basicDetails?.jobTitle || "N/A",
      sorter: (a: any, b: any) =>
        a.basic_details?.jobTitle?.localeCompare(b.basic_details?.jobTitle),
    },
    {
      title: "Job Type",
      dataIndex: "basic_details",
      key: "jobType",
      render: (basicDetails: any) => {
        const jobTypes = basicDetails?.jobType;
        return (
          <>
            {Array.isArray(jobTypes) ? (
              jobTypes.map((type, index) => (
                <Tag key={index} className="job-posts-tags">
                  {type}
                </Tag>
              ))
            ) : (
              <Tag>N/A</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Job Location",
      dataIndex: "basic_details",
      key: "jobLocation",
      render: (basicDetails: any) => basicDetails?.jobLocation || "N/A",
    },
    {
      title: "Actions",

      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          {deleteAccess && (
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this job?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDeleteJob(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
          {edit && <EditOutlined onClick={() => handleOpenModel(record.id)} />}

          <EyeOutlined onClick={() => openJobPostLink(record.id)} />
          <Tooltip title="Click to copy job link.">
            <LinkOutlined onClick={() => handleCopyToClipBoard(record.id)} />
          </Tooltip>
          <Tooltip title="Click to see applications against this job.">
            <FolderOutlined
              onClick={() => navigateToJobApplication(record.id)}
            />
          </Tooltip>
          <Tooltip title="View Shortlisted Candidates">
            <CheckCircleOutlined
              onClick={() => navigateToAcceptedApplications(record.id)}
            />{" "}
          </Tooltip>
          <Tooltip title="View Hired Candidates">
            <UsergroupAddOutlined onClick={() => navigateToHiredCandidates(record.id)} />{" "}
          </Tooltip>
          <Tooltip title="Toggle to change Job Status">
            <Switch
              checked={record.status}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => handleSwitchChange(checked, record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleOpenModel = (id: any) => {
    setJobId(id);
    setIsJobModelOpen(true);
  };

  const onCancel = () => {
    setIsJobModelOpen(false);
  };

  const previewModalCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const openJobPostLink = (id: any) => {
    if (orgSubDomain) {
      const link = `https://${orgSubDomain}.misgenie.app/hrm/applicationsubmission/${orgId}/${id}`;
      window.open(link, "_blank");
    }
  };

  const handleSearch = (value: string) => {
    setIsLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(
      value,
      originalData,
      "basic_details",
      "jobTitle"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setData(filterData);
    setIsLoading(false);
  };
  const getFilterDataHandler = (jobLocation: string) => {
    const filterArray = [
      { path: "basic_details.jobLocation", value: jobLocation },
    ];
    let response: any;
    response = filterLogicHandler(filterArray, originalData);

    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));
      setData(response);
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: <span className="custom-tour-heading">Delete Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to delete job post.
        </span>
      ),
      target: document.getElementById("iconToDeleteJobPost"),
      placement: "top",
    },
    {
      title: <span className="custom-tour-heading">Edit Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to edit job post, and you will be able to
          update the details of job post.
        </span>
      ),
      target: document.getElementById("iconToEditJobPost"),
      placement: "bottom",
    },
    {
      title: <span className="custom-tour-heading">View Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to view job post.
        </span>
      ),
      target: document.getElementById("iconToViewJobPost"),
      placement: "top",
    },
    {
      title: <span className="custom-tour-heading">Copy Link of Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to copy the link of the post. You will be able
          to view the post in the new tab of the browser.
        </span>
      ),
      target: document.getElementById("iconToCopyLinkJobPost"),
      placement: "bottom",
    },
    {
      title: <span className="custom-tour-heading">CV Bank</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to navigate to CV Bank where all the
          applications will be displayed against this job post.
        </span>
      ),
      target: document.getElementById("iconToSeeApplicationsJobPost"),
      placement: "top",
    },
    {
      title: <span className="custom-tour-heading">Sortlisted Candidates</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to navigate to shortlisted applications folder
          where all the applications will be displayed against this job post.
        </span>
      ),
      target: document.getElementById("iconToViewShortListedCandidates"),
      placement: "bottom",
    },
    {
      title: <span className="custom-tour-heading">Hired Candidates</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to navigate to view Hired candidate's
          applications against this job post.
        </span>
      ),
      target: document.getElementById("iconToViewHiredCandidates"),
      placement: "top",
    },
  ];

  const TourCloas = () => {
    setOpen(false);
    const toureObject = loggedInEmployee?.tour;
    if (toureObject) {
      const updatedToureObject = {
        ...toureObject,
        recruitment_tour: true,
      };
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            tour: updatedToureObject,
          },
        })
      );
      updatedEmployeeTourStatusApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employeeId,
        "recruitment_tour"
      );
    }
  };

  return (
    <>
      {data && (
        <Tour
          open={open}
          onClose={() => TourCloas()}
          steps={steps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      )}

      <div className="view-job-policy-container">
        <CustomTable dataSource={data} columns={columns} loading={isLoading} />
        <EditJobDetails
          id={jobId}
          visible={isJobModelOpen}
          onCancel={onCancel}
        />
        <JobPreview
          id={jobId}
          visible={isPreviewModalOpen}
          onCancel={previewModalCancel}
        />

        <Modal
          title={<span className="global-modal-title">Filter</span>}
          open={openFilterModal}
          footer={false}
          onCancel={toggleFilterModal}
          className="global-modal-main-container"
        >
          <JobPostingViewFilterModal
            getFilterDataHandler={(focusArea) =>
              getFilterDataHandler(focusArea)
            }
          />
        </Modal>

        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
      </div>
    </>
  );
};

export default ViewJob;
