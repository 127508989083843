import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Select,
  Divider,
  Checkbox,
  TimePicker,
  Tooltip,
  InputNumber,
} from "antd";
import "./index.css";
import TextArea from "antd/es/input/TextArea";
import moment from "moment-timezone";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import {
  allBranchesForCompanyDataApi,
  allOrgCompaniesDataApi,
  fetchAllDeductionPoliciesApi,
} from "../../Deduction/services";
import {
  fetchDepartmentsData,
  fetchTeamsData,
  getTimingPolicyVersion,
  setTimingPolicyData,
} from "../services";
import { IoSettingsOutline } from "react-icons/io5";
import { SettingOutlined } from "@ant-design/icons";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const TimezoneOptions = moment.tz.names();

const Timing: React.FC = () => {
  const timePickerRef = useRef<any>(null);
  const [form] = Form.useForm();
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [departments, setDepartments] = useState<any[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string>("");
  const [dedectionVersion, setDedectionVersion] = useState<any[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [from, setFrom] = useState<any>(null);
  const [to, setTo] = useState<any>(null);
  const [earlyCheckIn, setEarlyCheckIn] = useState<any>(null);
  const [lateCheckIn, setLateCheckIn] = useState<any>(null);
  const [tempVersion, setTempVersion] = useState<any>();
  const [disabledState, setDisabledState] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showCheckinRow, setShowCheckinRow] = useState<boolean>(false);

  const organizationId = loggedInEmployee?.employee?.organization_id;
  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("company", companyId);
      if (branchId) {
        setSelectedBranch(branchId);
        form.setFieldValue("branch", branchId);
      }
    } else {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (!(branchId || companyId)) {
      fetchBranches();
    }
  }, [selectedCompany]);

  useEffect(() => {
    fetchDepartments();
  }, [selectedBranch, selectedCompany]);

  useEffect(() => {
    fetchTeams();
  }, [selectedBranch, selectedCompany, selectedDepartment]);

  useEffect(() => {
    fetchDeductionVersion();
  }, [selectedBranch, selectedCompany]);

  useEffect(() => {
    getVersion();
  }, [selectedBranch, selectedCompany, selectedDepartment, selectedTeams]);

  const fetchDeductionVersion = async () => {
    form.setFieldValue("dedectionVersion", null);

    const res = await fetchAllDeductionPoliciesApi(
      selectedBranch,
      organizationId,
      selectedCompany
    );

    if (res.data) {
      setDedectionVersion(res.data);
    }
  };

  const getVersion = async () => {
    const response = await getTimingPolicyVersion(
      organizationId,
      selectedCompany,
      selectedBranch,
      selectedDepartment,
      selectedTeams
    );

    const data = response?.data;

    if (data) {
      const tempVersionValue = "Version #" + (data.length + 1);
      setTempVersion(tempVersionValue);
      form.setFieldsValue({
        policyName: tempVersionValue,
      });
    }
  };

  const fetchCompanies = async () => {
    const { data } = await allOrgCompaniesDataApi(organizationId);

    if (data) {
      setCompanies(data);

      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };

  const fetchBranches = async () => {
    if (branchId) {
      setSelectedBranch(branchId);
      form.setFieldValue("branch", branchId);
    } else {
      const { data } = await allBranchesForCompanyDataApi(
        selectedCompany,
        organizationId
      );
      if (data) {
        setBranches(data);
        form.setFieldValue("branch", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
    }
  };

  const fetchDepartments = async () => {
    const response = await fetchDepartmentsData(
      selectedCompany,
      selectedBranch,
      organizationId
    );

    if (response.data) {
      setDepartments(response.data);
      form.setFieldValue("department", response.data[0]?.id);
      setSelectedDepartment(response.data[0]?.id);
    }
  };

  const fetchTeams = async () => {
    const response = await fetchTeamsData(
      selectedCompany,
      selectedBranch,
      selectedDepartment,
      organizationId
    );

    if (response.data) {
      setTeams(response.data);
      form.setFieldValue("team", response.data[0]?.id);
      setSelectedTeams(response.data[0]?.id);
    }
  };

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    const data = {
      organization_id: organizationId,
      company_id: values.company || companyId || null,
      branch_id: values.branch || branchId || null,
      department_id: values.department,
      team_id: values.team,
      version_name: values.policyName,
      deduction_version_id: values.dedectionVersion,
      working_hours_per_day: values["workinghour/day"],
      working_days: daysOfTheWeek.reduce((acc, day) => {
        acc[day] = values["Working Days"].includes(day);
        return acc;
      }, {} as Record<string, boolean>),
      description: values.Description,
      day_start_time: from,
      day_end_time: to,
      timezone: values.timezone,
      early_checkin_time: earlyCheckIn,
      late_checkin_time: lateCheckIn,
    };
    try {
      const res = await setTimingPolicyData(data);

      if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      } else if (res.status == 201 || res.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        setCounter(counter + 1);
        form.resetFields();
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };

  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          {!companyId && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="company"
                label="Company"
                rules={[{ required: true }]}
                initialValue={companies[0]?.id}
              >
                <Select
                  placeholder="Select Company"
                  onChange={(value) => setSelectedCompany(value)}
                  allowClear
                  disabled={disabledState}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companies.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="branch"
                label="Branch"
                initialValue={branches[0]?.id}
              >
                <Select
                  placeholder="Select Branch"
                  defaultValue={branches[0]?.id}
                  onChange={setSelectedBranch}
                  allowClear
                  disabled={disabledState}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="department"
              label="Departments"
              initialValue={departments[0]?.id}
            >
              <Select
                placeholder="Select Departments"
                onChange={(value) => setSelectedDepartment(value)}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {departments.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="team" label="Team">
              <Select
                placeholder="Select Team"
                onChange={setSelectedTeams}
                defaultValue={teams[0]?.id}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {teams.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="policyName"
              label="Policy Name"
              rules={[{ required: true }]}
            >
              <Input size="large" value={tempVersion} placeholder="Enter policy name..." />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="dedectionVersion"
              label="Deduction Version"
              rules={[{ required: true }]}
              initialValue={dedectionVersion[0]?.id}
            >
              <Select
                placeholder="Select Dedection Version"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {dedectionVersion.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.version_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="workinghour/day"
              label="Number of working hours per day"
              rules={[
                {
                  required: true,
                  message: "Please enter the working days.",
                },
              ]}
            >
              <InputNumber
                size="large"
                placeholder="Enter the number of working hours per day"
                className="global-input-number-width"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label="From"
              name={"from"}
              rules={[
                {
                  required: true,
                  message: "Please select a time",
                },
              ]}
            >
              <TimePicker
                ref={timePickerRef}
                name="from"
                className="from-time-picker"
                format="HH:mm"
                onChange={(e, value) => {
                  setFrom(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Form.Item
              label="To"
              name={"to"}
              rules={[
                {
                  required: true,
                  message: "Please select a time",
                },
              ]}
            >
              <TimePicker
                name="to"
                className="from-time-picker"
                format="HH:mm"
                onChange={(e, value) => setTo(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="timing-policy-advanced-configuration-row">
            <span
              onClick={() => setShowCheckinRow(!showCheckinRow)}
              className="timing-policy-advanced-configuration-row-content"
            >
              <SettingOutlined />
              Advanced Setting
            </span>
          </Col>
        </Row>

        {showCheckinRow && (
          <Row gutter={[16, 16]}>
            <Col span={24} className="advance-setting-restrict-text">
              Timing Restriction
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Tooltip title=" Set a start time restriction for employee check-ins. Check-ins before this time will not be recorded.">
                <Form.Item
                  label="Check-In Start Time"
                  name={"earlyCheckinTime"}
                >
                  <TimePicker
                    name="to"
                    className="from-time-picker"
                    format="HH:mm"
                    onChange={(e, value) => setEarlyCheckIn(value)}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Tooltip title="Set an end time restriction for employee check-ins. Check-ins after this time will not be counted but will still appear in attendance logs.">
                <Form.Item
                  label="Check-In End Time"
                  name={"checkInAfterPrescribedTime"}
                >
                  <TimePicker
                    name="to"
                    className="from-time-picker"
                    format="HH:mm"
                    onChange={(e, value) => setLateCheckIn(value)}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item name="timezone" label="Timezone">
              <Select
                placeholder="Select Timezone"
                allowClear
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {TimezoneOptions.map((timezone, index) => (
                  <Option key={index} value={timezone}>
                    {timezone}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="Working Days"
              label="Working Days"
              rules={[
                {
                  required: true,
                  message: "Please select a Wroking Days",
                },
              ]}
            >
              <Checkbox.Group>
                {daysOfTheWeek.map((day, dayIndex) => (
                  <Checkbox key={dayIndex} value={day}>
                    {day}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="Description"
              label="Description"
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Enter Description..." rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row">
          <Col span={24}>
            <Form.Item shouldUpdate={true}>
              {() => (
                <div className="timing-policy-btn">
                  <Space>
                    <Button
                      className="global-btn-width"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={buttonLoading}
                    >
                      Create
                    </Button>
                  </Space>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default Timing;
