import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//--------------------------------------------Create Department---------------------------------------
export const getBranches = async (Ids: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("branch")
      .select("id, unique_identifier, name");

    if (Ids.companyId) {
      query.eq("company_id", Ids.companyId);
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getCompanies = async (organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name")
      .eq("organization_Id", organizationId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const insertData = async (body: any, orgId: string): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("department")
      .insert(body);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//---------------------------------------View Department Details---------------------------------

export const getDepartments = async (data: any) => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("department")
      .select(`*, employee!department_head_manager_id_fkey(full_name)`)
      .eq("organization_Id", data.organizationId);
    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const deleteDepartment = async (id: any, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("department")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//------------------------------------Edit Department Details-------------------------------------

export const getBranchManagers = async (Ids: any) => {
  try {
    const query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", Ids.organizationId);
    if (Ids.companyId) {
      query.eq("company_id", Ids.companyId);
    }
    if (Ids.branchName) {
      query.eq("branch_id", Ids.branchName);
    }
    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const submitData = async (
  data: any,
  id: string | number | null,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("department")
      .update(data)
      .eq("id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const fetchDepartmentData = async (departmentId: any, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("department")
      .select("*")
      .eq("id", departmentId)
      .maybeSingle();
    return response;
  } catch (error) {
    return catch_return;
  }
};
