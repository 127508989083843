import React from "react";
import ResignationListView from "./View";

const Termination: React.FC = () => {
  return (
    <div>
      <ResignationListView />
    </div>
  );
};

export default Termination;
