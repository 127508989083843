import React, { useEffect, useState } from "react";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import { fetchEmployeeprobationinIntershipApi } from "../../services";
import { Empty, Modal, Space } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { searchLogicHandler } from "../../../../../common/SearchAndFilterFunction";
import { capitalizeWords } from "../../../../../common/validation";
import EditEmploymentTypeModel from "../../Edit";
import ViewEmploymentTypeModel from "../../ViewDetails";
import CustomTable from "../../../../../common/CustomTable";

interface filterType {
  EmploymentType: string | null;
  startingmonth: string | null;
  endingmonth: string | null;
}

interface InternshipListProps {
  searchValue: string;
}

const InternshipListView: React.FC<InternshipListProps> = ({ searchValue }) => {
  const initialFilter = {
    EmploymentType: "all",
    startingmonth: "",
    endingmonth: "",
  };
  const [loading, setLoading] = useState<boolean>(true);
  const [tabledata, setTableData] = useState<any>([]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [selectedRecordData, setSelectedRecordData] = useState<any>("");
  const [modelType, setModelType] = useState<"edit" | "view" | null>(null);
  const [showTypedModel, setShowTypedModel] = useState<boolean>(false);
  const [filterData, setfilterData] = useState<filterType>(initialFilter);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Assessment",
    "Internship"
  );

  const edit = access?.writeAccess;
  const read = access?.readAccess;

  /**
   * useEffect hook that fetches table data when any of the dependencies (startingmonth, endingmonth, EmploymentType)
   * change.
   */
  useEffect(() => {
    fetchTableData();
  }, [
    filterData.startingmonth,
    filterData.endingmonth,
    filterData.EmploymentType,
  ]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  /**
   * Fetches table data based on the provided filters and search query.
   * @returns None
   */
  const fetchTableData = async () => {
    setLoading(true);
    const body = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: logedInemployee?.employee?.company_id,
      employmentType: "internship",
      branchId: logedInemployee?.employee?.branch_id,
    };
    const res = await fetchEmployeeprobationinIntershipApi(body);
    setLoading(false);
    if (res.data) {
      const filterdata = res.data.filter(
        (item: any) => item.employee.status != "inactive"
      );
      setTableData(filterdata);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: filterdata,
      }));
    }
  };

  const handleSearch = (value: string) => {
    setLoading(true);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  /**
   * Resets the state values and fetches table data when cancel action is triggered.
   * @returns None
   */
  const handelCancel = () => {
    setSelectedRecordId("");
    setModelType(null);
    setShowTypedModel(false);
    setSelectedRecordData("");
    fetchTableData();
    setfilterData(initialFilter);
  };

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (record: any) => (record?.full_name ? record?.full_name : ""),
    },
    {
      title: "On",
      dataIndex: "employment_type",
      key: "employment_type",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Start",
      dataIndex: "starting_date",
      key: "starting_date",
    },
    {
      title: "Completion",
      dataIndex: "completion_date",
      key: "completion_date",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a
              onClick={() => {
                setSelectedRecordId(record.id);
                setSelectedRecordData(record);
                setModelType("edit");
                setShowTypedModel(true);
              }}
            >
              <EditOutlined />
            </a>
          )}
          <a>
            <EyeOutlined
              onClick={() => {
                setModelType("view");
                setSelectedRecordId(record.id);
                setSelectedRecordData(record);
                setShowTypedModel(true);
              }}
            />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="probation-main-container">
        <div className="probationListView-custom-style">
          {read ? (
            <CustomTable
              loading={loading}
              dataSource={tabledata}
              columns={tableColumns}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </div>
        {modelType && selectedRecordId && (
          <Modal
            title={
              modelType == "edit" ? (
                <span className="global-modal-title">
                  {capitalizeWords(modelType)} Details
                </span>
              ) : (
                <span className="global-modal-title">View Details</span>
              )
            }
            open={showTypedModel}
            onCancel={handelCancel}
            width={1000}
            footer={false}
            className="global-modal-main-container"
          >
            {modelType == "edit" ? (
              <EditEmploymentTypeModel
                id={selectedRecordId}
                data={selectedRecordData}
                closeModel={handelCancel}
              />
            ) : (
              <ViewEmploymentTypeModel
                id={selectedRecordId}
                data={selectedRecordData}
                closeModel={handelCancel}
              />
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default InternshipListView;
