import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { Button, Col, Empty, Row, Select } from "antd";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import "./index.css";
import {
  getBranchesByComapnyId,
  getEmployeeDataKPIs,
  getEmployeeKPIHistoryData,
} from "../services";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import CustomTable from "../../../../common/CustomTable";
import { capitalizeWords } from "../../../../common/validation";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const ListEmployeeKPIs = () => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [employeeKPIData, setEmployeeKPIData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "Performance",
    "Performance Metrics"
  );
  const read = access?.readAccess;
  const [form] = useForm();
  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
    } else {
      getCompanies();
    }
  }, []);
  useEffect(() => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      if (selectedCompany) {
        getBranches();
      }
    }
  }, [selectedCompany]);
  useEffect(() => {
    form.resetFields(["employee"]);
    setEmployeeKPIData([]);
  }, [selectedCompany, selectedBranch]);
  useEffect(() => {
    setEmployees([]);
    setEmployeeKPIData([]);
    getEmployeeRecords();
  }, [selectedCompany, selectedBranch]);

  const getEmployeeRecords = async () => {
    const Ids = {
      companyId: selectedCompany,
      employeeId: form.getFieldValue("employee"),
      branchId: selectedBranch,
    };
    const response = await getEmployeeDataKPIs(Ids, organizationId);

    if (response.data) {
      const namesSet = new Set();
      const filteredEmployees = response.data.filter((item) => {
        const isEmployeeNotNull = item?.employee !== null;
        const isNameUnique = !namesSet.has(item?.employee?.full_name);

        if (isEmployeeNotNull && isNameUnique) {
          namesSet.add(item?.employee?.full_name);
          return true;
        }
        return false;
      });

      setEmployees(filteredEmployees);

      if (filteredEmployees.length > 0) {
        const firstEmployeeId = filteredEmployees[0]?.employee_id;
        form.setFieldValue("employee", firstEmployeeId);
      } else {
        form.setFieldValue("employee", null);
      }
    }
  };

  const getEmployeeKPIDetails = async () => {
    setLoading(true);
    const response = await getEmployeeKPIHistoryData(
      form.getFieldValue("employee"),
      organizationId
    );

    if (response.data) {
      setEmployeeKPIData(response.data);

      setLoading(false);
    }
  };
  const getCompanies = async () => {
    const { data, error } = await fetchallCompanyByOrgIdApi(organizationId);
    if (data) {
      setCompanies(data);
      form.setFieldValue("company", data[0]?.id);
      setSelectedCompany(data[0]?.id);
    }
  };
  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      const { data, error } = await getBranchesByComapnyId(
        selectedCompany,
        organizationId
      );

      if (data) {
        setBranches(data);
        form.setFieldValue("branch", data[0]?.id);
        setSelectedBranch(data[0]?.id);
      }
    }
  };
  const formatKPIData = (kpisData: any) => {
    return kpisData.map((kpi: any) => ({
      key: kpi.id,
      month: moment(kpi.created_at).format("MMMM, YYYY"),
      details: kpi.data,
      aggregate: kpi.aggregate,
    }));
  };

  const columns = [
    { title: "Month", dataIndex: "month", key: "month" },
    { title: "Obtained Aggregate", dataIndex: "aggregate", key: "aggregate" },
  ];
  const renderKPIDetails = (record: any) => {
    const detailColumns = [
      {
        title: "Attribute Name",
        dataIndex: "name",
        key: "name",
        render: (text: any) => capitalizeWords(text),
      },
      {
        title: "Points",
        dataIndex: "percentage",
        key: "percentage",
        render: (text: any) => `${text}`,
      },
      {
        title: "Obtained Percentage",
        dataIndex: "selectedMetric",
        key: "obtainedMarks",
        render: (_: any, detail: any) => (
          <span>{detail.selectedMetric?.obtainedMarks}</span>
        ),
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
      },
    ];

    return (
      <CustomTable
        columns={detailColumns}
        dataSource={record.details}
        rowKey="id"
      />
    );
  };

  return (
    <>
      {read ? (
        <>
          <Form
            form={form}
            layout="vertical"
            className="global-kpi-list-header"
          >
            <Row gutter={12} className="employee-kpi-list-top-row">
              {!companyId && (
                <Col span={24} md={12} lg={12} xl={7} xxl={7}>
                  <Form.Item
                    name={"company"}
                    label="Companies"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company...",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={setSelectedCompany}
                      placeholder="Please select a company..."
                    >
                      {companies?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {!branchId && (
                <Col span={24} md={12} lg={12} xl={7} xxl={7}>
                  <Form.Item name={"branch"} label="Branches">
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      onChange={(value: any) => setSelectedBranch(value)}
                      placeholder="Please select a branch..."
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branches.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name={"employee"} label="Select Employee">
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    allowClear
                    placeholder="Select an Employee"
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {employees?.map((item: any) => (
                      <Select.Option
                        key={item.employee_id}
                        value={item.employee_id}
                      >
                        {(item?.employee && item?.employee?.full_name) ||
                          (item.employee && item?.employee?.first_name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} md={12} lg={12} xl={4} xxl={4}>
                <Form.Item label=" ">
                  <Button
                    className="employee-kpi-list-btn"
                    type="primary"
                    onClick={getEmployeeKPIDetails}
                    size="large"
                  >
                    Get Employee KPIs History
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <CustomTable
            dataSource={formatKPIData(employeeKPIData)}
            loading={loading}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => renderKPIDetails(record),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};

export default ListEmployeeKPIs;
