import React, { useEffect, useMemo, useState } from "react";
import "../index.css";
import TabsHeader from "../../../common/TabsHeader";
import { Card, Empty, Modal } from "antd";
import DeductionListView from "../Deduction/List";
import TimingListView from "../Timing/View";
import OvertimeListView from "../Overtime/View";
import LeavesListView from "../Leaves/View";
import PolicyDocumentListView from "../PolicyDocument/View";
import DeductionCreateView from "../Deduction/Create";
import Timing from "../Timing/Create";
import OverTime from "../Overtime/Create";
import AbsentPolicy from "../Absent/Create";
import LeavesPolicy from "../Leaves/Create";
import CreatePolicyDocument from "../PolicyDocument/Create";
import { selectAccess } from "../../../../redux/AccessRight";
import { useDispatch, useSelector } from "react-redux";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import AbsentListView from "../Absent/View";
import { selectOrganizationModulePermission } from "../../../../redux/organizationModulePermission";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../../redux/MenuBreadcrumbSlice";

const PolicyDocumentView: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const accessObj = useSelector(selectAccess);
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [selected, setSelected] = useState("Deductions");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const dispatch = useDispatch();

  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.Policies;
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  const radioOptions = useMemo(
    () => [
      {
        label: "Deductions",
        value: "Deductions",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Timings",
        value: "Timings",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Overtime",
        value: "Overtime",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Absents",
        value: "Absents",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Leaves",
        value: "Leaves",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "General",
        value: "General",
        create: true,
        filter: false,
        showSearch: true,
      },
    ],
    []
  );

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "policies"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
  }, [filteredRadioOptions]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "policies",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Policy Document";
    let childValue =
      value == "Deductions"
        ? "Deduction Policy"
        : value == "Timings"
        ? "Timing Policy"
        : value == "Overtime"
        ? "Overtime Policy"
        : value == "Absents"
        ? "Absent Policy"
        : value == "Leaves"
        ? "Leaves Policy"
        : "General Policy";

    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={filteredRadioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("")
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            selected={selected}
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Deductions" ? (
                <DeductionListView searchValue={searchValue} />
              ) : selected === "Timings" ? (
                <TimingListView searchValue={searchValue} />
              ) : selected === "Overtime" ? (
                <OvertimeListView searchValue={searchValue} />
              ) : selected === "Absents" ? (
                <AbsentListView searchValue={searchValue} />
              ) : selected === "Leaves" ? (
                <LeavesListView searchValue={searchValue} />
              ) : (
                <PolicyDocumentListView searchValue={searchValue} />
              )
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <div key={fetchModalData}>
            <Modal
              title={
                <span className="global-modal-title">
                  Create {selected} Policy
                </span>
              }
              open={createModalVisible}
              onCancel={handleCreateModalCancel}
              width={1000}
              footer={false}
              className="global-modal-main-container"
              destroyOnClose
            >
              {createModalVisible && selected === "Deductions" && (
                <DeductionCreateView />
              )}
              {createModalVisible && selected === "Timings" && <Timing />}
              {createModalVisible && selected === "Overtime" && <OverTime />}
              {createModalVisible && selected === "Absents" && <AbsentPolicy />}
              {createModalVisible && selected === "Leaves" && (
                <LeavesPolicy onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "General" && (
                <CreatePolicyDocument />
              )}
            </Modal>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PolicyDocumentView;
