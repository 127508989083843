import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, Button, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useParams } from "react-router-dom";
import { getCountries } from "vk-country-state-city";
import {
  createOrUpdateExperiences,
  getTheCompanyAndBranchId,
  getWorkExperience,
} from "../qualificationServices";
import CustomAlert from "../../../../common/Alert";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

interface props {
  onClose: () => void;
}

const ExperienceForm: React.FC<props> = ({ onClose }) => {
  const [form] = Form.useForm();

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const { id } = useParams<{ id: string }>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await getCountries();
      if (response) {
        setCountries(response);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const getTheExperiences = async () => {
      const response = await getWorkExperience(organizationId, id);
      if (response.data) {
        setTableData(response.data[0]?.details || []);
      } else {
        setTableData([]);
      }
    };

    getTheExperiences();
  }, [organizationId, id]);

  const handleSubmitData = async (values: any) => {
    setButtonLoading(true);

    const newRecord = {
      country: values.country,
      company: values.company,
      jobTitle: values.jobTitle,
      from: values.from.format("YYYY-MM-DD"),
      to: values.to?.format("YYYY-MM-DD"),
      id: Date.now(),
    };

    const updatedTableData = [...tableData, newRecord];

    const companyBranchResponse = await getTheCompanyAndBranchId(
      organizationId,
      id
    );

    if (companyBranchResponse.data.company_id) {
      const data = {
        organization_id: organizationId,
        company_id: companyBranchResponse.data.company_id,
        branch_id: companyBranchResponse.data.branch_id,
        employee_id: id,
        details: updatedTableData,
      };
      const response = await createOrUpdateExperiences(
        organizationId,
        id,
        data
      );

      if (
        (response && response.status === 201) ||
        (response && response.status === 204)
      ) {
        setShowAlert(true);
        setAlertType("success");
        setTableData(updatedTableData);
        form.resetFields();
        setButtonLoading(false);
        setTimeout(() => onClose(), 1500);
      } else {
        setShowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      }
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form} onFinish={handleSubmitData}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Please enter Country Name!",
                  },
                ]}
              >
                <Select placeholder="Enter Country" showSearch allowClear notFoundContent={
                        <NoDataFoundIcon />
                      }>
                  {countries.map((item) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Company"
                name="company"
                rules={[{ required: true, message: "Please Enter Company!" }]}
              >
                <Input size="large" placeholder="Enter Company" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[{ required: true, message: "Please Enter Job Title!" }]}
              >
                <Input size="large" placeholder="Enter Job Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="from"
                rules={[
                  { required: true, message: "Please Enter Starting Date!" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="End Date" name="to">
                <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>

          <Row className="last-row">
            <Col span={24} className="save-profile-btn">
              <Form.Item>
                <Button
                  className="global-primary-btn global-btn-width"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={buttonLoading}
                >
                  Create
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default ExperienceForm;
