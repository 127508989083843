import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Upload,
  Radio,
  Select,
  DatePicker,
  Modal,
  Empty,
} from "antd";
import "./index.css";
import d_dp from "../../../assets/images/blankProfile.png";

import CustomAlert from "../../../common/Alert";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import "react-phone-input-2/lib/style.css";
import { Country } from "country-state-city";
import { AccessRightgroups } from "../../../common/BasicAcessRight";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import { TriangleLoader } from "../../ReactLoader";
import {
  fetchEmployeePersonalDetailsApi,
  updateEmployeePersonlDetailsApi,
  updateProfilePicApi,
} from "../services";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../ExtractAccessRightObj";
import NoDataFoundIcon from "../../NoDataFoundIcon";
const UserPersonalInfoUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const [DOB, setDOB] = useState<string | string[]>();
  const loggedInemployeed = useSelector(selectLogedEmployee);
  const employeeId = loggedInemployeed?.logedInemployee?.employeeId;
  const employesres = useRef<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [employescounter, setEmployesCounter] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const RadioGroup = Radio.Group;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(
    null
  );
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [initialSelectedFile, setInitialSelectedFile] = useState<
    string | null | undefined
  >(null);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(true);

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Profile Information"
  );
  const write = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchEmployee = async () => {
    setLoading(true);

    const employes_res = await fetchEmployeePersonalDetailsApi(
      loggedInemployeed.logedInemployee?.employee?.organization_id,
      employeeId
    );

    if (employes_res.data) {
      setLoading(false);
      setPreviewImage(employes_res?.data?.avatar_url);
      if (employes_res?.data?.avatar_url) {
        setShowUploadButton(false);
      }
      setInitialSelectedFile(employes_res?.data?.avatar_url);
      employesres.current = employes_res?.data;
      setEmployesCounter(employescounter + 1);
    }
  };

  const onFinish = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    const fileRes = await uploadImage(selectedFile);
    if (fileRes) {
      const updatedUplodatedEmployee = {
        ...loggedInemployeed?.logedInemployee?.employee,
        avatar_url: fileRes.data.publicUrl,
      };
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            employee: updatedUplodatedEmployee,
          },
        })
      );
    }
    const body = {
      gender: values.gender,
      dob: DOB,
      avatar_url: fileRes?.data?.publicUrl,
      marital_status: values.maritalStatus,
      nationality: values.nationality,
    };
    const res = await updateEmployeePersonlDetailsApi(
      body,
      employeeId,
      loggedInemployeed.logedInemployee?.employee?.organization_id
    );
    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
    } else if (res.status === 201 || res.status === 204) {
      setshowAlert(true);
      setAlertType("success");
    }
    setButtonLoading(false);
  };

  const handleFileChange = (file: File) => {
    setShowUploadButton(false);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file: File | null) => {
    try {
      if (file) {
        const fileExt = file.name.split(".").pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;
        const res = await updateProfilePicApi(filePath, file);
        return res;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      {read ? (
        <>
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              first_name: employesres.current?.first_name || "",
              sur_name: employesres.current?.last_name || "",
              nationality: employesres.current?.nationality || "",
              cnic: employesres.current?.cnic || "",
              DOB: employesres.current?.dob
                ? dayjs(employesres.current?.dob, "YYYY-MM-DD")
                : "",
              maritalStatus: employesres.current?.marital_status || "",
              accessRightGroup: employesres.current?.access_right_group || "",
              gender: employesres.current?.gender || "",
            }}
            key={employescounter}
          >
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
              <Col xs={15} sm={15} md={20} lg={20} xl={21} xxl={22}>
                <Upload
                  name="orglogo"
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={(file) => {
                    handleFileChange(file);
                    return false;
                  }}
                  onRemove={() => {
                    setPreviewImage(initialSelectedFile);
                    setSelectedFile(null);
                    setShowUploadButton(true);
                  }}
                  onPreview={() => setPreviewOpen(true)}
                  defaultFileList={[
                    {
                      uid: "-1",
                      name: "test.png",
                      status: "done",
                      url: initialSelectedFile || d_dp,
                    },
                  ]}
                >
                  {showUploadButton && (
                    <button
                      className="user-profile-upload-button"
                      type="button"
                    >
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                  )}
                </Upload>
              </Col>
            </Row>
            <div className="org-form-fields">
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="First Name" name="first_name">
                    <Input size="large" placeholder="First Name..." disabled />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="Last Name" name="sur_name">
                    <Input
                      size="large"
                      placeholder="Enter last name..."
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="Nationality" name="nationality">
                    <Select
                      placeholder="Select a country..."
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {Country.getAllCountries().map((country) => (
                        <Select.Option key={country.name} value={country.name}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="CNIC/ID" name="cnic">
                    <Input size="large" placeholder="Enter cnic..." disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Date of Birth"
                    name="DOB"
                    rules={[
                      { required: true, message: "Please enter Date of Birth" },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      disabledDate={(current) => {
                        const fifteenYearsAgo = moment().subtract(15, "years");
                        return current && current > fifteenYearsAgo;
                      }}
                      onChange={(d: any, ds: string | string[]) => {
                        setDOB(ds);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please Select Gender" },
                    ]}
                  >
                    <RadioGroup
                      options={[
                        { label: "Female", value: "female" },
                        { label: "Male", value: "male" },
                        { label: "Non Binary", value: "nonbinary" },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Marital Status" name="maritalStatus">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select marital status..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      <Select.Option value="single">Single</Select.Option>
                      <Select.Option value="married">Married</Select.Option>
                      <Select.Option value="widowed">Widowed</Select.Option>
                      <Select.Option value="divorced">Divorced</Select.Option>
                      <Select.Option value="separated">
                        Separated{" "}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label="Access Right's Group"
                    name="accessRightGroup"
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      placeholder="Please select a company"
                      disabled
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {AccessRightgroups.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="personal-data-update-row">
                  <Form.Item className="submit-button">
                    {write && (
                      <Button
                        className="send-invitation-btn global-btn-width"
                        htmlType="submit"
                        loading={buttonLoading}
                        type="primary"
                      >
                        Save Changes
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
          {loading && <TriangleLoader />}
          <Modal
            open={previewOpen}
            title={"Image Preview"}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img
              src={previewImage || d_dp}
              className="user-update-avatar-image"
            />
          </Modal>
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};

export default UserPersonalInfoUpdate;
