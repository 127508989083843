import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Button, Select, DatePicker } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import {
  fetchAllEmployeeWithOutEducationApi,
  insertEducationByIdApi,
} from "../services";
import CustomTable from "../../../../../common/CustomTable";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateQualificationDetails: React.FC<props> = ({ onCancel }) => {
  const loggedInemployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [qualifications, setQualifications] = useState<any[]>([]);
  const [form] = useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(0);

  const companyId = loggedInemployee?.employee?.company_id;
  const branchId = loggedInemployee?.employee?.branch_id;

  /**
   * useEffect hook that triggers a function to get employees without skills when either a selected company or branch changes.
   * @param {function} getEmployeesWithoutSkills - Function to get employees without skills.
   * @param {string} selectedCompany - The selected company.
   * @param {string} selectedBranch - The selected branch.
   * @returns None
   */
  useEffect(() => {
    if (selectedCompany || selectedBranch) {
      getEmployeesWithoutSkills();
    }
  }, [selectedCompany, selectedBranch]);

  /**
   * useEffect hook that calls the getAllCompanies function when the component mounts.
   * @returns None
   */
  useEffect(() => {
    if (companyId) {
      setSelectedCompany(companyId);
      form.setFieldValue("selectedCompany", companyId);
      if (branchId) {
        setSelectedBranch(branchId);
        form.setFieldValue("selectedBranch", branchId);
      }
    } else {
      getAllCompaniess();
    }
  }, []);

  /**
   * useEffect hook that calls getAllBranches() when the selectedCompany changes.
   * This hook is triggered after the component is mounted and whenever the selectedCompany changes.
   * @returns None
   */
  useEffect(() => {
    if (!(branchId || companyId)) {
      getAllBranchess();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  /**
   * Asynchronously fetches all companies associated with the logged-in employee's organization
   * and updates the state with the fetched companies.
   * @returns None
   */
  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await fetchallCompanyByOrgIdApi(
      loggedInemployee?.employee?.organization_id
    );
    if (response.data) {
      setFetchedCompanies(response.data);
      form.setFieldValue("selectedCompany", response?.data[0]?.id);
      setSelectedCompany(response?.data[0]?.id);
    }
    setCompanySelectLoading(false);
  };
  /**
   * Asynchronously fetches all branches by company ID and updates the state with the response data.
   * @returns None
   */
  const getAllBranchess = async () => {
    if (branchId) {
      setSelectedBranch(branchId);
      form.setFieldValue("selectedBranch", branchId);
    } else {
      setBranches([]);
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      const response = await fetchAllBranchesByComapnyIdApi(
        loggedInemployee?.employee?.organization_id,
        form.getFieldValue("selectedCompany")
      );
      if (response.data) {
        setBranches(response.data);
        setBranchSelectLoading(false);
        form.setFieldValue("selectedBranch", response?.data[0]?.id);
        setSelectedBranch(response?.data[0]?.id);
      }
      setBranchSelectLoading(false);
    }
  };
  /**
   * Adds a new qualification to the list of qualifications.
   * @param {any} values - The values of the new qualification to add.
   * @returns None
   */
  const onAddQualification = async (values: any) => {
    const newData = {
      employeeId: selectedEmployeeId,
      id: Date.now(),
      institute: values.institute,
      level: values.level,
      specialization: values.specialization,
      from: values.from ? values.from.format("YYYY-MM-DD") : "",
      to: values.to ? values.to.format("YYYY-MM-DD") : "",
    };
    setQualifications([...qualifications, newData]);
  };

  /**
   * Removes a qualification from the qualifications array based on the index provided.
   * @param {any} index - The index of the qualification to be removed.
   * @returns None
   */
  const onRemoveQualification = (index: any) => {
    setQualifications(qualifications.filter((_, i) => i !== index));
  };
  /**
   * Submits the qualifications of an employee to the Supabase database.
   * @returns None
   */
  const submitQualificationsToSupabase = async () => {
    setButtonLoading(true);
    const formattedQualifications = {
      organization_id: loggedInemployee?.employee.organization_id,
      company_id: selectedCompany || companyId || null,
      branch_id: selectedBranch || branchId || null,
      employee_id: selectedEmployeeId,
      qualification_details: qualifications,
    };
    let res = await insertEducationByIdApi(formattedQualifications);
    setButtonLoading(false);
    if (res?.error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      getEmployeesWithoutSkills();

      setBranches([]);
      setFilteredEmployees([]);
      onCancel();
      form.resetFields();
    }
    setQualifications([]);
  };

  /**
   * Retrieves a list of employees without specific skills asynchronously.
   * Clears the "employeeId" field in the form, sets the filtered employees list to empty,
   * and sets the loading state for the employee select component to true.
   * Fetches a list of employees without education based on the provided data object containing
   * organizationId, companyId, and branchId.
   * Updates the filteredEmployees state with the fetched list of employees.
   * @returns None
   */
  const getEmployeesWithoutSkills = async () => {
    form.setFieldValue("employeeId", "");
    setFilteredEmployees([]);
    setEmployeeSelectLoading(true);
    const data = {
      organizationId: loggedInemployee?.employee?.organization_id,
      companyId: form.getFieldValue("selectedCompany"),
      branchId: form.getFieldValue("selectedBranch"),
    };
    const res = await fetchAllEmployeeWithOutEducationApi(data);
    setFilteredEmployees(res);
    form.setFieldValue("employeeId", res[0]?.label);
    setSelectedEmployeeId(res[0]?.value);
    setEmployeeSelectLoading(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employeeId",
      key: "employeeId",
      render: (text: any, record: any) =>
        filteredEmployees.find(
          (employee) => employee.value === record.employeeId
        )?.label || text,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Start Date",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "End Date",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any, index: any) => (
        <Button onClick={() => onRemoveQualification(index)}>Remove</Button>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={onAddQualification}
      >
        <Row gutter={16}>
          {!companyId && (
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name={"selectedCompany"}
                label="Select a company..."
                rules={[
                  {
                    required: true,
                    message: "Please select a company...",
                  },
                ]}
              >
                <Select
                  placeholder="Select a company..."
                  allowClear
                  onChange={setSelectedCompany}
                  loading={companySelectLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name={"selectedBranch"} label="Select a branch...">
                <Select
                  allowClear
                  placeholder="Select a branch..."
                  onChange={setSelectedBranch}
                  loading={branchSelectLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="employeeId"
              label="Employee Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee Name!",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Employee"
                loading={employeeSelectLoading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={setSelectedEmployeeId}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {filteredEmployees.map((item: any) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="Level" name="level">
              <Input size="large" placeholder="Enter Level" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Institute"
              name="institute"
              rules={[{ required: true, message: "Please Enter Institute!" }]}
            >
              <Input size="large" placeholder="Enter Institute" />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Specialization"
              name="specialization"
              rules={[
                { required: true, message: "Please Enter Specialization!" },
              ]}
            >
              <Input size="large" placeholder="Enter Specialization" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="Start Date"
              name="from"
              rules={[
                { required: true, message: "Please Enter Starting Date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="End Date" name="to">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row className="qualification-btn-position">
          <Col>
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                htmlType="submit"
              >
                Add Qualification
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CustomTable dataSource={qualifications} columns={columns} rowKey="id" />

      <Row className="global-modal-bottom-row last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={submitQualificationsToSupabase}
              loading={buttonLoading}
              size="large"
            >
              Create
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateQualificationDetails;
