import React, { useEffect, useState } from "react";
import AttendanceSummary from "../AttendanceSummary";
import AttendanceSheet from "../AttendanceSheet";
import "./index.css";
import {
  faClock,
  faUserClock,
  faExclamationCircle,
  faTimesCircle,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import AbsenteesModal from "./Modals/absenteesModal";
import moment from "moment";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { Empty } from "antd";
import {
  calcNumOfPresentsbyEmployeeIdApi,
  getEmployeeAttendanceDetails,
  getTheHolidays,
} from "../../Dashboard/services";
import { CustomformatDate } from "../../../../common/validation";
import { getTheTimingPoliciesForEmployees } from "../services";

const MyTimeTracking: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const joiningDate = loggedInEmployee.logedInemployee?.employee?.joiningDate;
  const [workableShifts, setWorkableShifts] = useState<number>(7);
  const [numberOfWorkableHours, setNumberOfWorkableHours] = useState<any>(null);
  const [workedHours, setWorkedHours] = useState<string>("");
  const [timingPolicyData, setTimingPolicyData] = useState<any[]>([]);
  const [missingPunchesCount, setMissingPunchesCount] = useState<any>(null);
  const [numberOfAbsences, setNumberOfAbsences] = useState<any>(null);
  const [lateArrivalCount, setLateArrivalCount] = useState<string | number>("");
  const [earlyLeftCount, setEarlyLeftCount] = useState<string | number>("");
  const [shortHours, setShortHours] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [holidays, setHolidays] = useState<any>(null);
  const [timeZone, setTimeZone] = useState<string>("");
  const [numberOfPresents, setNumberOfPresents] = useState<any>(null);
  const [employeeAttendanceData, setEmployeeAttendanceData] = useState<any[]>(
    []
  );
  const employeeRole = loggedInEmployee?.logedInemployee?.employeeRole;

  const accessObj: any = useSelector(selectAccess);

  const access = extractAccessRightObject(accessObj, "My Time Tracking");

  const read = access?.readAccess;

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [absentDates, setAbsentDates] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      fetchTheLatestTimePolicy();
      getEmployeeAttendance();
      getHolidaysResponse();

      calculatePresentCount();
      calculateAbsences();

      setLoading(false);
    };

    fetchData();
  }, [timingPolicyData, employeeAttendanceData]);

  useEffect(() => {
    calculateWorkableHoursPerMonth();
  }, [timingPolicyData]);

  useEffect(() => {
    calculateAbsences();
  }, [timingPolicyData, employeeAttendanceData]);

  const fetchTheLatestTimePolicy = async () => {
    const response = await getTheTimingPoliciesForEmployees(
      organizationId,
      loggedInEmployee.logedInemployee?.employeeId
    );
  
    if (response.data && response.data.length > 0) {
      setTimingPolicyData(response.data);
      const latestPolicy = response.data[0];
      setTimeZone(latestPolicy?.timezone);

      const workingDays = latestPolicy?.working_days;
     
      if (workingDays) {
        const trueCount = Object.values(workingDays).filter(
          (value: any) => value == true
        ).length;
        setWorkableShifts(trueCount);
      }

    }
  };

  //function responsible for getting the holidays.
  const getHolidaysResponse = async () => {
    const response = await getTheHolidays(organizationId, companyId, branchId);
    if (response.data) {
      const filteredRecord = response.data?.filter(
        (item: any) => item.type_of_event == "holiday"
      );

      setHolidays(filteredRecord);
    }
  };

  //function responsible for calculating the absentees
  // function responsible for calculating the absentees
  const calculateAbsences = () => {
    if (
      employeeAttendanceData.length === 0 ||
      !timingPolicyData ||
      timingPolicyData.length === 0
    ) {
      return;
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    let startDate;

    if (joiningDate) {
      const joiningDateTime = new Date(joiningDate);
      if (
        joiningDateTime.getFullYear() === currentYear &&
        joiningDateTime.getMonth() === currentMonth
      ) {
        startDate = joiningDateTime;
      } else {
        startDate = new Date(currentYear, currentMonth, 1);
      }
    } else {
      startDate = new Date(currentYear, currentMonth, 1);
    }

    const workingDays = timingPolicyData[0]?.working_days || {};
    const workingHoursPerDay = timingPolicyData[0]?.working_hours_per_day;
    if (!workingHoursPerDay || !Object.keys(workingDays).length) {
      return;
    }

    const holidayDates = new Set();
    if (holidays && holidays.length > 0) {
      holidays.forEach((holiday: any) => {
        const start = new Date(holiday.start_date_and_time);
        const end = holiday.end_date_and_time
          ? new Date(holiday.end_date_and_time)
          : start;

        for (
          let date = new Date(start);
          date <= end;
          date.setDate(date.getDate() + 1)
        ) {
          holidayDates.add(CustomformatDate(new Date(date)));
        }
      });
    }

    let absentDays = 0;

    const currentMonthCheckIns = employeeAttendanceData.filter((record) => {
      const checkInDate = new Date(record.check_in);
      return (
        checkInDate.getFullYear() === currentYear &&
        checkInDate.getMonth() === currentMonth
      );
    });

    // Create a set of unique check-in dates for the current month
    const uniqueCheckInDates = new Set(
      currentMonthCheckIns.map((record) =>
        CustomformatDate(new Date(record.check_in))
      )
    );

    const absentDates = [];

    for (
      let day = new Date(startDate);
      day.getMonth() === currentMonth && day <= now;
      day.setDate(day.getDate() + 1)
    ) {
      const dayName = day.toLocaleString("en-US", { weekday: "short" });
      const formattedDate = CustomformatDate(day);

      if (workingDays[dayName] && !holidayDates.has(formattedDate)) {
        if (!uniqueCheckInDates.has(formattedDate)) {
          absentDays++;
          absentDates.push(formattedDate);
        }
      }
    }

    setNumberOfAbsences(absentDays);
    setAbsentDates(absentDates);
  };

  //function to get the Employee attendance
  const getEmployeeAttendance = async () => {
    const response = await getEmployeeAttendanceDetails(
      loggedInEmployee.logedInemployee?.employeeId,
      organizationId
    );

    if (response.data) {
      setWorkedHours(calculateTotalWorkedHours(response.data) || "00:00");
      setEmployeeAttendanceData(response.data);
      calculateShortHours(response.data);
      calculateLateArrivals(response.data);
      calculateEarlyLeaves(response.data);
      calculateMissingPunches(response.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const parseTime = (timeString: any) => {
    if (!timeString || timeString === "00:00") {
      return { hours: 0, minutes: 0 };
    }
    const [hours, minutes] = timeString
      .replace("h", "")
      .replace("m", "")
      .split(":")
      .map((part: any) => parseInt(part.trim(), 10));
    return {
      hours: isNaN(hours) ? 0 : hours,
      minutes: isNaN(minutes) ? 0 : minutes,
    };
  };

  //function to calculate the workable hours per month
  const calculateWorkableHoursPerMonth = () => {
    if (!timingPolicyData || timingPolicyData.length === 0) {
      return;
    }
    const workingHoursPerDay = timingPolicyData[0].working_hours_per_day;
    const workingDays = timingPolicyData[0].working_days || 7;
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    let totalWorkingDays = 0;

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDay = new Date(year, month, day);
      const dayName = currentDay.toLocaleString("en-US", { weekday: "short" });

      if (workingDays[dayName]) {
        totalWorkingDays++;
      }
    }
    const totalWorkableHours = totalWorkingDays * workingHoursPerDay;
    if (totalWorkableHours === null) {
      setNumberOfWorkableHours("0");
    } else {
      setNumberOfWorkableHours(totalWorkableHours);
    }
  };

  //function to calculate the worked hours
  const calculateTotalWorkedHours = (data: any) => {
    let totalWorkedMinutes = 0;

    data.forEach((entry: any) => {
      const { hours, minutes } = parseTime(entry.worked_hours);
      totalWorkedMinutes += hours * 60 + minutes;
    });

    const totalWorkedHours = Math.floor(totalWorkedMinutes / 60);
    const remainingMinutes = totalWorkedMinutes % 60;

    return `${String(totalWorkedHours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}`;
  };

  //function to calculate the missing punches

  const calculatePresentCount = async () => {
    try {
      const startDate = moment()
        .startOf("month")
        .tz(timeZone)
        .format("YYYY-MM-DD");

      const endDate = moment().endOf("month").tz(timeZone).format("YYYY-MM-DD");

      const response = await calcNumOfPresentsbyEmployeeIdApi(
        startDate,
        endDate,
        loggedInEmployee.logedInemployee?.employeeId,
        loggedInEmployee.logedInemployee?.employee.organization_id
      );

      setNumberOfPresents(response.data?.length);
    } catch (error) {
      return error;
    }
  };

  const calculateShortHours = (data: any) => {
    let totalHours = 0;
    let totalMinutes = 0;
    for (const item of data) {
      if (item?.short_working_hours) {
        const [hours, minutes] = item?.short_working_hours
          ?.split(":")
          .map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      }
    }
    if (totalMinutes >= 60) {
      const extraHours = Math.floor(totalMinutes / 60);
      totalHours += extraHours;
      totalMinutes %= 60;
    }
    const formattedTotal = `${totalHours?.toString().padStart(2, "0")}:${totalMinutes?.toString().padStart(2, "0") }`;
    
    setShortHours(formattedTotal);
    return formattedTotal;
  };

  const calculateLateArrivals = (data: any) => {
    let lateCount = 0;
    for (const item of data) {
      if (item.check_in_status === "Late") {
        lateCount++;
      }
    }
    setLateArrivalCount(lateCount);
    return lateCount;
  };

  const calculateEarlyLeaves = (data: any) => {
    let earlyCount = 0;

    for (const item of data) {
      if (item.check_out_status === "Early") {
        earlyCount++;
      }
    }
    setEarlyLeftCount(earlyCount);
    return earlyCount;
  };

  const calculateMissingPunches = (data: any) => {
    let missingPunches = 0;

    for (const item of data) {
      if (item.check_out_status === "Missing") {
        missingPunches++;
      }
    }
    setMissingPunchesCount(missingPunches);
    return missingPunches;
  };

  const workableHoursDisplay =
    numberOfWorkableHours !== null ? numberOfWorkableHours + "h" : "---";

  //function responsible for sanitizing the value to prevent NaN in any of the readings
  const sanitizeValue = (value: any) => {
    if (typeof value === "number") {
      return isNaN(value) ? 0 : value;
    }
    if (typeof value === "string") {
      return value.trim() === "" ? "0" : value;
    }
    return value;
  };

  const attendanceData = [
    {
      title: "Workable Shifts/Week",
      value: sanitizeValue(workableShifts),
      color: "#ff8c00",
      icon: faClock,
    },
    {
      title: "Workable Hours/Month",
      value: sanitizeValue(workableHoursDisplay),
      color: "#6333ff",
      icon: faUserClock,
    },
    {
      title: "Worked Hours Till Today",
      value: sanitizeValue(workedHours),
      color: "#08ab0e",
      icon: faExclamationCircle,
    },
    {
      title: "Presents",
      value: sanitizeValue(numberOfPresents),
      color: "#08ab0e",
      icon: faClock,
    },
    {
      title: "Missing Punches",
      value: sanitizeValue(missingPunchesCount),
      color: "#ea1212",
      icon: faTimesCircle,
    },
    {
      title: "Absents",
      value: sanitizeValue(numberOfAbsences),
      color: "#ea1212",
      icon: faTimesCircle,
    },
    {
      title: "Short Hours",
      value: sanitizeValue(shortHours),
      color: "#e46666",
      icon: faClock,
    },
    {
      title: "Late Arrivals",
      value: sanitizeValue(lateArrivalCount),
      color: "#f7ce00",
      icon: faCalendarDay,
    },
    {
      title: "Early Lefts",
      value: sanitizeValue(earlyLeftCount),
      color: "#f7ce00",
      icon: faCalendarDay,
    },
  ];
  return (
    <>
      <div className="my-time-container">
        {employeeRole === "employee" &&
          (read ? (
            <>
              <AttendanceSummary data={attendanceData} />
              <AttendanceSheet
                employeeAttendanceData={employeeAttendanceData}
                loading={loading}
              />
              {isModalOpen && (
                <AbsenteesModal
                  absentDates={absentDates}
                  onClose={() => setIsModalOpen(false)}
                />
              )}
            </>
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          ))}

        {employeeRole !== "employee" && (
          <>
            <AttendanceSummary data={attendanceData} />
            <AttendanceSheet
              employeeAttendanceData={employeeAttendanceData}
              loading={loading}
            />
            {isModalOpen && (
              <AbsenteesModal
                absentDates={absentDates}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MyTimeTracking;
