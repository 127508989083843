import React, { useState, useEffect } from "react";
import {
  Space,
  Popconfirm,
  Modal,
  TablePaginationConfig,
  Empty,
  Select,
} from "antd";

import "./index.css";

import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import EditJobTitleModal from "../Edit";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import { deleteRecord, getTableDetails } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import JobTitleFilterModal from "../Filter";
import CustomTable from "../../../../common/CustomTable";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

type TableData = {
  jobtitle: string;
  companyid: string[];
  companyname: string[];
  id: string[] | number[] | any | null;
};

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface JobListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const { Option } = Select;

const JobListTable: React.FC<JobListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.company_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobTitles, setJobTitles] = useState<TableData[]>([]);
  const [selectedRecordId, setSelectdRecordId] = useState<number | string>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Organization",
    "Job Titles"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchData();
    }
  }, [organizationId, selectedCompany]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setJobTitles(originalData.apiData);
    }
  }, [searchValue]);

  const fetchCompanies = async () => {
    try {
      setCompanySelectLoading(true);
      const res = await fetchallCompanyByOrgIdApi(organizationId);
      if (res.data) {
        setCompanyOptions(res.data);
        setSelectedCompany(res.data[0]?.id);
      }
      setCompanySelectLoading(false);
    } catch (error) {}
  };

  const fetchData = async () => {
    setIsEditModalVisible(false);
    setIsLoading(true);

    const data = {
      organizationId: organizationId,
      companyId: employeeRole === "admin" ? selectedCompany : companyId,
    };
    const response = await getTableDetails(data);

    if (response.data) {
      setIsLoading(false);
      setJobTitles(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: jobTitles.length,
        },
      });
    } else {
      setIsLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const columns = [
    {
      title: "Job Title",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Company Names",
      dataIndex: "company",
      key: "company",
      render: (record: any) => (
        <div>{record && record.name ? record.name : ""}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleEdit(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this job title?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDelete = async (record: TableData) => {
    if (organizationId) {
      const { id } = record;
      try {
        const response = await deleteRecord(id, organizationId);
        if (response.error) {
          setshowAlert(true);
          setAlertType("error");
        } else {
          setshowAlert(true);
          setAlertType("success");
          setJobTitles((prevJobTitles) =>
            prevJobTitles.filter((jobTitle) => jobTitle.id !== record.id)
          );
          fetchData();
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleEdit = (record: any) => {
    setSelectdRecordId(record.id);
    setIsEditModalVisible(true);
  };

  const handleCancelEdit = () => {
    setIsEditModalVisible(false);
  };

  const handleSearch = (value: string) => {
    setIsLoading(true);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setJobTitles(filterData);

    setIsLoading(false);
  };

  const getFilterDataHandler = (companyName: string) => {
    const filterArray = [{ path: "company.name", value: companyName }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setJobTitles(response);
    }
  };

  return (
    <>
      {read ? (
        <>
          <Select
            showSearch
            placeholder="Select Company"
            filterOption={(input, option: any) =>
              option && option.children
                ? (option.children as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                : false
            }
            loading={companySelectLoading}
            onChange={setSelectedCompany}
            className="jobtitle-company-select"
            value={selectedCompany}
            notFoundContent={
              <NoDataFoundIcon />
            }
          >
            {companyOptions?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          <CustomTable
            columns={columns}
            dataSource={jobTitles}
            loading={isLoading}
            onChange={handleTableChange}
          />
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      {isEditModalVisible && selectedRecordId && (
        <EditJobTitleModal
          visible={isEditModalVisible}
          onCancel={handleCancelEdit}
          id={selectedRecordId}
          fetchData={fetchData}
        />
      )}

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <JobTitleFilterModal
          getFilterDataHandler={(companyName) =>
            getFilterDataHandler(companyName)
          }
        />
      </Modal>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default JobListTable;
