import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Retrieves the timing policy version based on the provided parameters.
 * @param {number} orgId - The organization ID.
 * @param {string} selectedCompanyId - The selected company ID.
 * @param {string} selectedBranchId - The selected branch ID.
 * @param {string} selectedDepartmentId - The selected department ID.
 * @param {string} selectedTeam - The selected team.
 * @returns {Promise<any>} A promise that resolves to the timing policy version information.
 */
export const getTimingPolicyVersion = async (
  orgId: number,
  selectedCompanyId: string,
  selectedBranchId: string,
  selectedDepartmentId: string,
  selectedTeam: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("timing_policy")
      .select("id")
      .eq("organization_id", orgId);
    if (selectedCompanyId) {
      query = query.eq("company_id", selectedCompanyId);
    }
    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }
    if (selectedDepartmentId) {
      query = query.eq("department_id", selectedDepartmentId);
    }
    if (selectedTeam) {
      query = query.eq("team_id", selectedTeam);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches department data based on the selected company and branch IDs.
 * @param {string} selectedCompanyId - The ID of the selected company.
 * @param {string} selectedBranchId - The ID of the selected branch.
 * @returns {Promise<any>} A promise that resolves to the fetched department data.
 */
export const fetchDepartmentsData = async (
  selectedCompanyId: string,
  selectedBranchId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("department")
      .select("id, name")
      .eq("company_id", selectedCompanyId);

    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches teams data based on the selected company, branch, and department IDs.
 * @param {string} selectedCompanyId - The ID of the selected company.
 * @param {string} selectedBranchId - The ID of the selected branch.
 * @param {string} selectedDepartmentId - The ID of the selected department.
 * @returns {Promise<any>} A promise that resolves to the response containing team data.
 */
export const fetchTeamsData = async (
  selectedCompanyId: string,
  selectedBranchId: string,
  selectedDepartmentId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("team")
      .select("id , name")
      .eq("company_id", selectedCompanyId);

    if (selectedBranchId) {
      query = query.eq("branch_id", selectedBranchId);
    }
    if (selectedDepartmentId) {
      query = query.eq("department_id", selectedDepartmentId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Sets timing policy data by inserting the provided data into the "timingPolicy" table.
 * @param {any} data - The data to be inserted into the "timingPolicy" table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const setTimingPolicyData = async (data: any): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.organization_id)
      .from("timing_policy")
      .insert(data);

    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches a timing policy by its ID from the database.
 * @param {number} organizationId - The ID of the organization.
 * @param {string} id - The ID of the timing policy.
 * @returns {Promise<any>} A promise that resolves to the timing policy object.
 */
export const fetchTimingPolicyByIdApi = async (
  organizationId: number,
  id: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("timing_policy")
      .select(
        "deduction_version_id,description,day_start_time,day_end_time,id,version_name,branch_id,company_id,department_id,team_id,timezone,working_days,working_hours_per_day"
      )
      .eq("organization_id", organizationId)
      .eq("id", id)
      .single();

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Fetches timing policy data based on the provided organization, company, and branch IDs.
 * @param {number} organizationId - The ID of the organization.
 * @param {number} companyId - The ID of the company.
 * @param {number} branchId - The ID of the branch.
 * @returns {Promise<any>} A promise that resolves to the timing policy data.
 */
export const fetchTimingPolicyData = async (
  organizationId: number,
  companyId: number,
  branchId: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("timing_policy")
      .select(
        "id, version_name , working_hours_per_day , working_days,short_hours_deduction(slabs)"
      )
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes a timing policy with the specified ID from the "timingPolicy" table in the database.
 * @param {number} id - The ID of the timing policy to be deleted.
 * @returns {Promise<any>} A promise that resolves with the response from the deletion operation.
 */
export const deleteTimingPolicy = async (
  id: number,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("timing_policy")
      .delete()
      .eq("id", id);
    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches the early left timing policy for a given organization and match condition.
 * @param {number} organizationId - The ID of the organization to fetch the timing policy for.
 * @param {string} matchCondition - The condition to match for the timing policy.
 * @returns {Promise<any>} A promise that resolves to the fetched timing policy data.
 */

export const getEmployeeTimingPolicyData = async (
  employeeId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_timing_policies")
      .select(
        "working_hours_per_day,working_days,policy_description,deduction_version_id"
      )
      .eq("employee_id", employeeId);

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchDeductionSlabs = async (
  organizationId: number,
  deduction_version_id: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("short_hours_deduction")
      .select(`slabs`)
      .eq("id", deduction_version_id)
      .single();
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
