import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import "./index.css";
import { alertMessages } from "./AlertMessage";

interface CustomAlertProps {
  message?: string;
  description?: string;
  type: "success" | "info" | "warning" | "error";
  showIcon?: boolean;
  closable?: boolean;
  autoCloseDuration?: number;
  showAlert?: boolean;
  onClose?: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  type,
  message,
  description,
  showAlert,
  showIcon = true,
  closable = true,
  autoCloseDuration = 4000,
  onClose,
}) => {
  const [visible, setVisible] = useState<boolean>(showAlert ?? true);

  const alertMessage = message || alertMessages[type].message;
  const alertDescription = description || alertMessages[type].description;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (visible) {
      timeoutId = setTimeout(() => {
        setVisible(false);
        onClose?.();
      }, autoCloseDuration);
    }

    return () => clearTimeout(timeoutId);
  }, [autoCloseDuration, onClose, visible]);

  return visible ? (
    <Alert
      className="custom-alert"
      message={alertMessage}
      description={alertDescription}
      type={type}
      showIcon={type == "success" ?  true :  false}
      closable={closable}
      onClose={() => {
        setVisible(false);
        onClose?.();
      }}
    />
  ) : null;
};

export default CustomAlert;
