import React, { useEffect, useState } from "react";
import "./index.css";
import { TablePaginationConfig, Empty, Modal } from "antd";
import { useSelector } from "react-redux";
import CustomTable from "../../../../../common/CustomTable";
import { Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditRelativeModal from "../Edit";
import { selectAccess } from "../../../../../../redux/AccessRight";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import {
  deleteEmergencyContactApi,
  fetchEmergencyContactApi,
} from "../services";
import { extractAccessRightObject } from "../../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../../common/SearchAndFilterFunction";
import RelativeFilterModal from "../Filter";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface RelativesListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const Relatives: React.FC<RelativesListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [loading, setLoading] = useState<boolean>(true);

  const [tableData, setTableData] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const accessObj = useSelector(selectAccess);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: { current: 1, pageSize: 10 },
  });

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const access = extractAccessRightObject(accessObj, "Employee", "Relatives");

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  /**
   * Fetches data from the emergency contact API based on the logged-in employee's organization, company, branch, and search query.
   * Updates the table data source with the retrieved data after filtering out items without Employees.
   * @returns None
   */
  const fetchData = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const data = {
      organizationId: logedInemployee?.employee?.organization_id,
      companyId: logedInemployee?.employee?.company_id,
      branchId: logedInemployee?.employee?.branch_id,
    };
    const res = await fetchEmergencyContactApi(data);
    setLoading(false);
    if (res.data) {
      const updatedData = res.data.filter((item: any) => item.employee);
      setTableData(updatedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
      setCounter(counter + 1);
    }
  };

  /**
   * Handles the editing of a record with the given record ID by setting the selected record ID
   * and making the modal visible.
   * @param {number} recordId - The ID of the record to edit.
   * @returns None
   */
  const handleEdit = (recordId: number) => {
    setSelectedRecordId(recordId);
    setIsModalVisible(true);
  };

  /**
   * Handles changes in table pagination and sorting by updating the table parameters.
   * @param {TablePaginationConfig | undefined} pagination - The pagination configuration for the table.
   * @param {any} sorter - The sorting configuration for the table.
   * @returns {void}
   */
  const handleTableChange = (
    pagination?: TablePaginationConfig,
    sorter?: any
  ): void => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  /**
   * Handles the deletion of an emergency contact with the given relativeId.
   * Makes an asynchronous call to the deleteEmergencyContactApi with the provided relativeId.
   * If the deletion is successful, sets showAlert to true with AlertType as "success" and fetches updated data.
   * If there is an error during deletion, sets showAlert to true with AlertType as "error.
   * @param {any} relativeId - The id of the emergency contact to be deleted.
   * @returns None
   */
  const handleDelete = async (relativeId: any) => {
    const res = await deleteEmergencyContactApi({
      id: relativeId,
      organizationId: logedInemployee?.employee.organization_id,
    });
    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");
      fetchData();
    }
  };

  const tableColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee",
      sorter: (a: any, b: any) => {
        const nameA = (a.employee && a.employee.full_name) || "";
        const nameB = (b.employee && b.employee.full_name) || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (record: any) => (
        <span>{record && record?.full_name ? record?.full_name : ""}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "relative_name",
      key: "relative_name",
    },
    {
      title: "Relationship",
      dataIndex: "relation_with_employee",
      key: "relation_with_employee",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },

    {
      title: "address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleEdit(record.id)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this Relative?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDelete(record.id)}
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));

    setTableData(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (relation: string) => {
    const filterArray = [{ path: "relation_with_employee", value: relation }];

    let response: any;

    response = filterLogicHandler(filterArray, originalData);

    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableData(response);
    }
  };

  return (
    <>
      <div className="relatives-table">
        {read ? (
          <CustomTable
            dataSource={tableData}
            columns={tableColumns}
            loading={loading}
            onChange={handleTableChange}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <RelativeFilterModal
          getFilterDataHandler={(relation) => getFilterDataHandler(relation)}
        />
      </Modal>

      {isModalVisible && (
        <EditRelativeModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          selectedRecordId={selectedRecordId}
          fetchData={fetchData}
        />
      )}

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default Relatives;
