import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  DatePicker,
  Form,
  Collapse,
  Table,
  Space,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeExperienceDetail,
  selectEmployee,
  setEmployeeProfileDetails,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { submitWorkExperienceAPI } from "./services";
import CustomTable from "../../../common/CustomTable";
import "./index.css";

interface WorkExperienceType {
  company: string;
  jobTitle: string;
  from: string;
  to: string;
}

const WorkExperience: React.FC = () => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [workExperienceList, setWorkExperienceList] = useState<
    WorkExperienceType[]
  >([]);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const employee = useSelector(selectEmployee);
  const organizationDetails = useSelector(selectLogedEmployee);
  const employeeDetails = useSelector(selectEmployee);
  const organizationId =
    organizationDetails?.logedInemployee?.employee.organization_id;
  const companyId = employeeDetails.Employee.PersonalDetails?.selectedCompanyId;
  const branchId = employeeDetails.Employee.PersonalDetails?.selectedBranchId;
  const employeeId = employeeDetails?.Employee?.EmployeeId;

  const handleDeleteRecord = async (index: any) => {
    dispatch(removeExperienceDetail(index));
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const body = {
      organization_id: organizationId,
      employee_id: employeeId,
      company_id: companyId,
      branch_id: branchId,
      details: workExperienceList,
    };

    const WorkExperience_res = await submitWorkExperienceAPI(body);

    if (WorkExperience_res.status === 201) {
      setShowAlert(true);
      setButtonLoading(false);
      setAlertMessage("");
      setAlertDescription("Progress Saved Successfully!");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (WorkExperience_res.error) {
      setShowAlert(true);
      setButtonLoading(false);
      setAlertMessage("");
      setAlertDescription(
        WorkExperience_res.error.message ||
          "Oops, we are facing some temporary issue with your request"
      );
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const onFinish = (values: any) => {
    values.from = values?.from?.format("YYYY-MM-DD") || "";
    values.to = values?.to?.format("YYYY-MM-DD") || "";
    values.id = values.from + values.company;
    const updatedList = [...workExperienceList, values];
    setShowAlert(false);

    if (values?.from && values?.company) {
      setWorkExperienceList(updatedList);
      dispatch(
        setEmployeeProfileDetails({
          Employee: {
            WorkExperience: updatedList,
          },
        })
      );
      form.resetFields();
      setShowAlert(true);
      setAlertMessage("");
      setAlertDescription("Work Experience Added Successfully");
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      setShowAlert(true);
      setAlertMessage("");
      setAlertDescription("Please fill all required fields");
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: any, index: any) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this record?"
            onConfirm={() => handleDeleteRecord(index)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Collapse
        items={[
          {
            key: "1",
            label: "Add Work Experience",
            children: (
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Company"
                      name="company"
                      rules={[
                        { required: true, message: "Please Enter Company!" },
                      ]}
                    >
                      <Input size="large" placeholder="Enter Company" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Job Title" name="jobTitle">
                      <Input size="large" placeholder="Enter Job Title" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Start Date"
                      name="from"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Starting Date!",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="End Date" name="to">
                      <DatePicker
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Form.Item>
                    <Button
                      className="global-btn-width"
                      type="primary"
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            ),
          },
        ]}
        className="employee-create-qualification-collapse-style"
      />
      <CustomTable
        className="table-class"
        columns={columns}
        dataSource={
          employee?.Employee?.WorkExperience
            ? employee?.Employee?.WorkExperience
            : []
        }
      />
      <Row className="last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Save progress
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          showAlert={showAlert}
        />
      )}
    </>
  );
};

export default WorkExperience;
