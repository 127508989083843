import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker as AntDatePicker,
  Select,
  Upload,
  Button,
  Row,
  Col,
  Switch,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import { TriangleLoader } from "../../../../common/ReactLoader";
import CustomAlert from "../../../../common/Alert";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { DateObject } from "react-multi-date-picker";
import {
  fetchLeavePolicyDataApi,
  getEmployeeApprovedLeavesData,
  getEmployeeWorkingDaysData,
  getHeadManagerId,
  getLeaveRequestReceiverIdApi,
  onFinishLeaveRequestApi,
  updateEmployeeArrayForApproval,
} from "../services";
import { Dayjs } from "dayjs";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;
const { RangePicker } = AntDatePicker;

interface LeaveType {
  leaveType: string;
  maxAllowed: number;
  id: number;
}
interface LeaveCount {
  [key: string]: number;
}

interface CurrentMonthData {
  leaveType: string;
  Balance: number;
  Availed: number;
  Available: number;
  id: number;
}

interface LeaveRequestFormOnCancelProps {
  onCreateClose?: () => void;
}

const LeaveRequestForm: React.FC<LeaveRequestFormOnCancelProps> = ({
  onCreateClose,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showfullScreenLoader, setShowfullScreenLoader] =
    useState<boolean>(false);
  const [leaveCount, setLeaveCount] = useState<number | any>(0);
  const [files, setFiles] = useState<string[]>();
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [switchMode, setSwitchMode] = useState<any>("multipleDates");
  const [multipleDates, setMultipleDates] = useState<any>(null);
  const [form] = Form.useForm();
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState<string>("");

  const [tableData, setTableData] = useState<any>();
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;
  const [leaveNames, setLeaveNames] = useState<LeaveType[]>([]);
  const [toApprovalEmployeesData, setToApprovalEmployeesData] = useState<any>(
    []
  );

  const [leaveTypesLoading, setLeaveTypesLoading] = useState<boolean>(true);

  const [employeeWorkingDays, setEmployeeWorkingDays] = useState<any>({});
  const [leaveRequestDbId, setLeaveRequestDbId] = useState<number>(0);

  const handleMultipleDatesChange = (selectedDates: any) => {
    const filteredDates = selectedDates?.filter((date: any) => {
      const dayName: any = new DateObject(date).format("ddd");
      return employeeWorkingDays[dayName];
    });

    const formattedDates = filteredDates?.map((date: any) =>
      new DateObject(date).format("YYYY-MM-DD")
    );

    setMultipleDates(formattedDates);

    setLeaveCount(formattedDates.length);
    form.setFieldsValue({ count: formattedDates.length });
  };

  function convertFilesToBase64(
    files: File[],
    callback: (base64Array: string[]) => void
  ) {
    let count = files.length;
    const base64Array: string[] = [];

    if (count === 0) {
      callback(base64Array);
      return;
    }

    files.forEach((file, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        base64Array[index] = reader.result as string;
        count--;

        if (count === 0) {
          callback(base64Array);
        }
      };

      reader.onerror = function (error) {};
    });
  }

  const handleModeChange = (checked: any) => {
    const newMode = checked ? "range" : "multipleDates";
    setSwitchMode(newMode);

    form.setFieldsValue({
      leave_period: undefined,
      count: 0,
    });
    setLeaveCount(0);
  };
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    getLatestRecord();
    getEmployeeWorkingDays();
  }, []);
  useEffect(() => {
    getEmployeeLeaves();
  }, [leaveNames]);

  //send employee array of priority data to leave and notify them

  useEffect(() => {
    const sendDatatoLeaveAndNotification = async () => {
      const hasHeadManager = toApprovalEmployeesData?.some(
        (item: any) => item?.employeeID === "manager"
      );
      if (hasHeadManager) {
        return;
      } else {
        const dataToSendInEmployeeLeaveRequest = {
          orgid: organizationId,
          id: leaveRequestDbId,
          leave_approval_employee_ids: toApprovalEmployeesData,
        };

        await updateEmployeeArrayForApproval(dataToSendInEmployeeLeaveRequest);

        toApprovalEmployeesData?.map(async (item: any) => {
          const sendNotificationHeadManagerBody = {
            type: "leaveRequest",
            sender_Id: loggedInEmployee?.employeeId || null,
            receiver_id: item?.employeeID,
            name: loggedInEmployee?.employee?.first_name,
            avatar: loggedInEmployee?.employee?.avatar_url,
          };

          await pushNotifictionIntoNotificationTable(
            sendNotificationHeadManagerBody,
            organizationId
          );
        });
      }
    };
    sendDatatoLeaveAndNotification();
  }, [toApprovalEmployeesData]);

  const getEmployeeWorkingDays = async () => {
    try {
      const response = await getEmployeeWorkingDaysData(
        organizationId,
        employeeId
      );

      if (response?.data?.length > 0 && response.data[0]?.working_days) {
        setEmployeeWorkingDays(response?.data[0]?.working_days);
      } else {
        setEmployeeWorkingDays({
          Fri: true,
          Mon: true,
          Sat: true,
          Sun: true,
          Thu: true,
          Tue: true,
          Wed: true,
        });
      }
    } catch (error) {}
  };

  const getLatestRecord = async () => {
    const response = await fetchLeavePolicyDataApi(organizationId, companyId);
    if (response.data) {
      const employeeIdsData = response.data[0]?.slabs?.map(
        (item: any) =>
          item && {
            priority: item?.priority,
            employeeID: item?.employeeID,
            isApproved: null,
          }
      );
      setToApprovalEmployeesData(employeeIdsData);
      const leaveTypes: LeaveType[] = response.data[0]?.policy_types?.map(
        (policyType: any) => ({
          leaveType: policyType.leaveType,
          maxAllowed: policyType.maxAllowed,
          id: policyType.id,
        })
      );
      setLeaveNames(leaveTypes);
    }
  };

  const getEmployeeLeaves = async () => {
    const currentYear = new Date().getFullYear();
    const response = await getEmployeeApprovedLeavesData(
      organizationId,
      employeeId,
      companyId
    );

    if (response.data) {
      const leaveTypeCounts: LeaveCount = {};
      response.data.forEach((leaveRequest: any) => {
        const requestYear = new Date(leaveRequest.created_at).getFullYear();
        if (requestYear === currentYear) {
          const leaveType = leaveRequest.leave_type?.toLowerCase();
          leaveTypeCounts[leaveType] =
            (leaveTypeCounts[leaveType] || 0) + leaveRequest.leave_count;
        }
      });
      const updatedCurrentMonthData: CurrentMonthData[] = leaveNames?.map(
        (leaveName) => {
          const availed =
            leaveTypeCounts[leaveName.leaveType?.toLowerCase()] || 0;

          return {
            leaveType: leaveName.leaveType,
            Balance: leaveName.maxAllowed - availed,
            Availed: availed,
            Available: leaveName.maxAllowed,
            id: leaveName.id,
          };
        }
      );

      setTableData(updatedCurrentMonthData);
      setLeaveTypesLoading(false);
    } else {
      setLeaveTypesLoading(false);
    }
  };

  const handleLeaveTypeChange = (value: number) => {
    const filteredData = tableData.filter((item: any) => item.id === value);
    form.setFieldValue(
      "availableLeaves",
      filteredData[0].Balance < 0 ? 0 : filteredData[0].Balance
    );
    form.setFieldValue(
      "allowedLeaves",
      filteredData[0].Available < 0 ? 0 : filteredData[0].Available
    );
    setSelectedLeaveType(filteredData[0].leaveType);

    if (filteredData[0].leaveType === "Sick") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const onFinish = async (values: any) => {
    setShowfullScreenLoader(true);
    setshowAlert(false);

    const body = {
      employee_id: loggedInEmployee?.employeeId || null,
      branch_id: loggedInEmployee?.employee?.branch_id || null,
      organization_id: organizationId || null,
      company_id: loggedInEmployee?.employee?.company_id || null,
      leave_type: selectedLeaveType,
      leave_count: values.count,
      reason_for_leave: values.reason,
      attachment: files,
      leave_status: "Requested",
      leave_dates: multipleDates,
      date_format_type: switchMode,
      starting_date: multipleDates[0],
      ending_date:
        multipleDates.length > 1
          ? multipleDates[multipleDates.length - 1]
          : multipleDates[0],
    };

    const res = await onFinishLeaveRequestApi(body);

    setShowfullScreenLoader(false);
    if (res.error) {
      setshowAlert(true);

      setAlertType("error");
    } else if (res.status == 201 || res.status == 204) {
      const getDepHeadmanagerbody = {
        id: loggedInEmployee?.employeeId,
        orgid: organizationId,
      };
      const getHeadManagerManagerId = await getHeadManagerId(
        getDepHeadmanagerbody
      );

      if (getHeadManagerManagerId?.data?.department?.head_manager_id) {
        setLeaveRequestDbId(res?.data[0]?.id);
        setToApprovalEmployeesData((prev: any) =>
          prev?.map((item: any) =>
            item?.employeeID === "manager"
              ? {
                  ...item,
                  employeeID:
                    getHeadManagerManagerId?.data?.department?.head_manager_id,
                }
              : item
          )
        );
      }

      const reciverId_body = {
        role: "hr",
        org_id: organizationId || null,
        company_id: loggedInEmployee?.employee?.company_id || null,
        branch_id: loggedInEmployee?.employee?.branch_id || null,
      };

      const reciverIdres = await getLeaveRequestReceiverIdApi(reciverId_body);

      if (reciverIdres.data && reciverIdres.data.length > 0) {
        reciverIdres?.data?.map(async (item: any) => {
          const sendNotificationBody = {
            type: "leaveRequest",
            sender_Id: loggedInEmployee?.employeeId || null,
            receiver_id: item.id,
            name: loggedInEmployee?.employee?.first_name,
            avatar: loggedInEmployee?.employee?.avatar_url,
          };

          const sendres = await pushNotifictionIntoNotificationTable(
            sendNotificationBody,
            organizationId
          );

          if (sendres.status == 201 || sendres.status == 204) {
            form.resetFields();
            setshowAlert(true);

            setAlertType("success");
          }
        });
      } else if (!reciverIdres.data || reciverIdres.data.length == 0) {
        const reciveradmin_body = {
          role: "admin",
          org_id: organizationId || null,
          company_id: loggedInEmployee?.employee?.company_id || null,
          branch_id: loggedInEmployee?.employee?.branch_id || null,
        };
        const reciveradminres = await getLeaveRequestReceiverIdApi(
          reciveradmin_body
        );

        if (reciveradminres.data && reciveradminres.data.length > 0) {
          reciveradminres?.data?.map(async (item: any) => {
            const sendNotificationBody = {
              type: "leaveRequest",
              sender_Id: loggedInEmployee?.employeeId || null,
              receiver_id: item.id,
              name: loggedInEmployee?.employee?.first_name,
              avatar: loggedInEmployee?.employee?.avatar_url,
            };
            const sendres = await pushNotifictionIntoNotificationTable(
              sendNotificationBody,
              organizationId
            );

            if (sendres.status == 201 || sendres.status == 204) {
              form.resetFields();
              setshowAlert(true);
              setAlertType("success");
            }
          });
        } else {
          const reciverorgadmin_body = {
            role: "admin",
            org_id: organizationId || null,
          };
          const reciverorgadminres = await getLeaveRequestReceiverIdApi(
            reciverorgadmin_body
          );

          if (reciverorgadminres.data && reciverorgadminres.data.length > 0) {
            reciverorgadminres?.data?.map(async (item: any) => {
              const sendNotificationBody = {
                type: "leaveRequest",
                sender_Id: loggedInEmployee?.employeeId || null,
                receiver_id: item.id,
                name: loggedInEmployee?.employee?.first_name,
                avatar: loggedInEmployee?.employee?.avatar_url,
              };
              const sendres = await pushNotifictionIntoNotificationTable(
                sendNotificationBody,
                organizationId
              );

              if (sendres.status == 201 || sendres.status == 204) {
                form.resetFields();
                setshowAlert(true);
                setAlertType("success");
              }
            });
          }
        }
      }
      if (onCreateClose) {
        onCreateClose();
      }
    }
  };

  const today = new Date();
  const nextMonthLastDay = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    0
  );
  const updateLeaveCount = (
    dates: [Dayjs | null, Dayjs | null],
    dateStrings: any
  ) => {
    if (dates && dates[0] && dates[1]) {
      const [startDate, endDate] = dates;
      const allDatesArray: { date: string; dayName: string }[] = [];

      let currentDate = startDate.clone();
      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        allDatesArray.push({
          date: currentDate.format("YYYY-MM-DD"),
          dayName: currentDate.format("dddd"),
        });
        currentDate = currentDate.add(1, "day");
      }

      const filteredDates = allDatesArray.filter((date) => {
        const dayName: any = date.dayName.slice(0, 3);
        return employeeWorkingDays[dayName];
      });

      const formattedDates = filteredDates.map((date) => date.date);
      setMultipleDates(formattedDates);
      setLeaveCount(formattedDates.length);
      form.setFieldsValue({ count: formattedDates.length });
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          layout="vertical"
          onFinish={onFinish}
          name="leave_request"
          form={form}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Leave Type"
                name="leave_type"
                rules={[
                  {
                    required: true,
                    message: "Please select leave type",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select a leave type..."
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      : false
                  }
                  onChange={(item) => {
                    handleLeaveTypeChange(item);
                  }}
                  loading={leaveTypesLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {tableData?.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.leaveType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Available Leaves" name={"availableLeaves"}>
                <Input
                  size="large"
                  placeholder="Available Leaves Count..."
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Allowed Leaves" name={"allowedLeaves"}>
                <Input
                  size="large"
                  placeholder="Allowed Leaves Count..."
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={"calendarModeToggle"}
                label="Select a calendar mode"
              >
                <Switch
                  checkedChildren="Range"
                  unCheckedChildren="Multiple Dates"
                  onChange={handleModeChange}
                  checked={switchMode === "range"}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Leave Period" name="leave_period">
                {switchMode === "range" ? (
                  <RangePicker
                    className="range-picker"
                    format="YYYY-MM-DD"
                    onChange={(dates, dateStrings) =>
                      updateLeaveCount(dates, dateStrings)
                    }
                  />
                ) : (
                  <div className="create-leave-date-picker">
                    <DatePicker
                      multiple
                      placeholder="Click to add multiple dates..."
                      minDate={moment().format("YYYY-MM-DD")}
                      maxDate={moment(nextMonthLastDay).format("YYYY-MM-DD")}
                      onChange={(selectedDates: any) => {
                        handleMultipleDatesChange(selectedDates);
                      }}
                    />
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Count" name="count">
                <InputNumber
                  size="large"
                  className="global-input-number-width"
                  disabled={leaveCount > 0 ? true : false}
                  placeholder="Count of leaves..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Reason"
                name="reason"
                rules={[
                  {
                    required: disabled,
                    message: "Please enter reason",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Add the reason for leave here..."
                />
              </Form.Item>
            </Col>
            {disabled && (
              <Col span={12}>
                <Form.Item
                  label="Attachment"
                  name="attachment"
                  valuePropName="fileList"
                  getValueFromEvent={(e: any) => e.fileList}
                >
                  <Upload
                    name="attachment"
                    listType="text"
                    multiple
                    beforeUpload={(file: File, fileList: File[]) => {
                      const allowedExtensions = [
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "image/gif",
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ];

                      if (allowedExtensions.includes(file.type)) {
                        return false;
                      }
                      return true;
                    }}
                    onChange={(info: any) => {
                      const { fileList } = info;
                      const actualFiles = fileList
                        ?.map((item: any) => item.originFileObj)
                        .filter((file: File | undefined) => file);
                      convertFilesToBase64(
                        actualFiles,
                        (base64Strings: string[]) => {
                          setFiles(base64Strings);
                        }
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row className="global-modal-bottom-row leave-approvel-row">
            <Form.Item>
              <Button
                htmlType="submit"
                className="global-primary-btn global-btn-width"
                size="large"
                type="primary"
              >
                Request
              </Button>
            </Form.Item>
          </Row>
        </Form>
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
        {showfullScreenLoader && <TriangleLoader />}
      </div>
    </>
  );
};

export default LeaveRequestForm;
