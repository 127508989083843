import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Row,
  Col,
  Input,
  Modal,
  Dropdown,
  Tag,
  Select,
  Popconfirm,
  Tooltip,
  Form,
} from "antd";
import { capitalizeWords } from "./../../../common/validation";
import {
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { UnorderedListOutlined, IdcardOutlined } from "@ant-design/icons";
import { GrPowerReset } from "react-icons/gr";
import "./index.css";
import { Menu, Radio } from "antd";
import { useSelector } from "react-redux";
import { BsFillSendFill } from "react-icons/bs";
import { Spin } from "antd";
import DepartmentHierarchy from "../DepartmentHierarchy";
import moment from "moment";
import SendEmail from "../SendEmail";
import { SearchOutlined } from "@ant-design/icons";
import EmployeeDeleteModel from "../EmployeeDeleteModel";
import EmployeeProfileCard from "../EmployeeCard";
import PlaceholderAvatar from "../../../assets/images/blankProfile.png";
import { CgDetailsMore } from "react-icons/cg";
import { TbHierarchy3 } from "react-icons/tb";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { v4 as uuidv4 } from "uuid";
import {
  fetchEmployeesProfileCompletionProgressfilters,
  fetchTotalEmployeesNumber,
} from "./services";
import CustomAlert from "../../../common/Alert";
import { useNavigate } from "react-router-dom";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
import { handleResendApi } from "../../../services/Global/index";
import {
  ActiveEmployeeByIdApi,
  fetchHeadDetailByIdApi,
  fetchReportToEmployeebyEmployeeIdApi,
  inActiveEmployeeByIdApi,
} from "../EmployeeCard/sevices";
import { VscLayersActive } from "react-icons/vsc";
import CustomTable from "../../../common/CustomTable";
import { CiFilter } from "react-icons/ci";
import {
  FaUserCheck,
  FaUserTimes,
  FaUserPlus,
  FaEnvelopeOpenText,
  FaListUl,
  FaUsers,
} from "react-icons/fa";

interface filterType {
  organizationId: string | null;
  companyId: string | null;
  branchId: string | null;
  employeeName: string | null;
  departmentName: string | null;
  gender: string | null;
  worktype: string | null;
  designation: string | null;
  jobTitle: string | null;
  type: string | null;
  sortOrder: string | null;
}

const EmployeeList: React.FC = () => {
  const logedInemployee = useSelector(selectLogedEmployee);
  const initialFilter = {
    organizationId: logedInemployee.logedInemployee?.employee?.organization_id,
    companyId: logedInemployee.logedInemployee?.employee?.company_id,
    branchId: logedInemployee.logedInemployee?.employee?.branch_id,
    employeeName: null,
    departmentName: null,
    gender: "all",
    worktype: "all",
    designation: null,
    jobTitle: null,
    type: "all",
    sortOrder: "asc",
  };
  const [companyDetails, setComapnyDetails] = useState<any>();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<"card" | "table">("card");
  const [employees, setEmployees] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showNotDeleteModel, setShowNotDeleteModel] = useState<boolean>(false);
  const [deletedEmployee, setDeletedEmployee] = useState<any>();
  const [isHierarchyModalVisible, setIsHierarchyModalVisible] = useState(false);
  const [showEmailSentModel, setShowEmailSentModel] = useState<boolean>(false);
  const [showEmailSentData, setEmailSentData] = useState<any>();
  const [deletedEmployeeId, setDeletedEmployeeId] = useState<number | string>();
  const [filters, setFilters] = useState<filterType>(initialFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [targetPageNumber, setTargetPageNumber] = useState<number>(1);
  const [totalEmployeeNumber, setTotalEmployeeNumber] = useState<any[]>([1]);
  const [pageSize, setPageSize] = useState(20);
  const organization_id =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const [byPageNumber, setByPageNumber] = useState<boolean>(false);

  const [employeeStatusSelected, setEmployeeStatusSelected] =
    useState<string>("active");

  const employeeStatusArray = [
    {
      label: "Active",
      value: "active",
      icon: <FaUserCheck />,
      tooltip: "Employees who have accepted their invitation email.",
    },
    {
      label: "InActive",
      value: "inactive",
      icon: <FaUserTimes />,
      tooltip: "Employees who are currently inactive.",
    },
    {
      label: "Created",
      value: "Created",
      icon: <FaUserPlus />,
      tooltip: "Employees with only basic information filled.",
    },
    {
      label: "Invited",
      value: "Invited",
      icon: <FaEnvelopeOpenText />,
      tooltip:
        "Employees who have been sent an invitation but have not yet accepted.",
    },
    {
      label: "Listed",
      value: "Listed",
      icon: <FaListUl />,
      tooltip: "Employees with their work email added.",
    },
    {
      label: "All",
      value: "all",
      icon: <FaUsers />,
      tooltip: "All employees regardless of status.",
    },
  ];

  /**
   * useEffect hook that fetches employee data and clears any existing timeout when the component unmounts.
   * @returns None
   */
  useEffect(() => {
    fetchEmployee();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchEmployee();
  }, [employeeStatusSelected]);

  function calculateIndices(
    currentPage: number,
    targetPage: number,
    pageSize: number
  ) {
    const pageNumberDifference = targetPage - currentPage;

    const startIndex =
      (currentPage - 1) * pageSize + pageNumberDifference * pageSize;

    const endIndex = startIndex + pageSize - 1;

    return { startIndex, endIndex };
  }

  /**
   * Fetches a list of employees based on the provided filters asynchronously.
   * Sets loading state to true while fetching data and false once data is fetched.
   * Updates the employees state with the fetched data if available.
   * @returns None
   */
  const fetchEmployee = async (refreshFilters?: any) => {
    let startIndex;
    let endIndex;
    if (byPageNumber) {
      let response = calculateIndices(
        currentPageNumber,
        targetPageNumber,
        pageSize
      );
      startIndex = response.startIndex;
      endIndex = response.endIndex;
      setCurrentPageNumber(targetPageNumber);
    } else {
      startIndex = (currentPage - 1) * pageSize;
      endIndex = startIndex + pageSize - 1;
    }

    setLoading(true);
    const filterCriteria = refreshFilters ? refreshFilters : filters;
    const profileCompletionPer =
      await fetchEmployeesProfileCompletionProgressfilters(
        filterCriteria,
        startIndex,
        endIndex,
        organization_id,
        employeeStatusSelected
      );

    const totalemployees = await fetchTotalEmployeesNumber(
      filterCriteria,
      organization_id,
      employeeStatusSelected
    );
    if (totalemployees) {
      const theLengtht =
        totalemployees?.data?.length &&
        Math.ceil(totalemployees?.data?.length / pageSize);

      if (typeof theLengtht === "number" && theLengtht > 0) {
        setTotalEmployeeNumber(
          Array.from({ length: theLengtht }, (_, i) => i + 1)
        );
      } else {
        setTotalEmployeeNumber([1]);
      }
    }

    if (profileCompletionPer.data) {
      setEmployees(profileCompletionPer?.data);
      const lengthOfData = profileCompletionPer.data.length;
      if (lengthOfData < pageSize) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
    } else {
      setIsNextDisabled(true);
    }

    setLoading(false);
    setByPageNumber(false);
  };

  /**
   * Handles the click event on a view button for a specific record.
   * If the record has an id, it navigates to the employee details page of the corresponding organization.
   * @param {any} record - The record object for which the view button is clicked.
   * @returns None
   */
  const handleViewClick = (record: any) => {
    if (record.employee_id) {
      navigate(`/employee/${record.employee_id}`);
    }
  };

  const handleDeleteConfirm = async (editedEmployee: any) => {
    try {
      setDeletedEmployeeId(editedEmployee.employee_id);
      setComapnyDetails({
        company_id: editedEmployee?.company_id,
        branch_id: editedEmployee?.branch_id,
        organizationId: editedEmployee?.organization_id,
      });
      const deleteModelData = {
        team_head: [],
        department_head: [],
        branch_head: [],
        report_to_Employee: [],
      };
      setShowNotDeleteModel(false);
      const res = await fetchHeadDetailByIdApi(
        editedEmployee.employee_id,
        editedEmployee?.organization_id
      );

      if (res.data && res.data.length > 0) {
        let Check = "team_head";
        //Check Head For Branch
        if (Check == "team_head") {
          const uniqueTeamHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.team_head) {
              uniqueTeamHeads.add(item.team_head);
            }
          });
          if (uniqueTeamHeads.size > 0) {
            const uniqueTeamArray = Array.from(uniqueTeamHeads);
            const team_head = res.data.filter((item: any) => {
              return uniqueTeamArray.includes(item.team_head);
            });
            const departementHeadWithKeys = team_head.map((item: any) => ({
              ...item,
              uniqueKey: uuidv4(),
            }));
            deleteModelData.team_head = departementHeadWithKeys;
          } else {
            Check = "department_head";
          }
        }
        // Check Head For Department
        if (Check == "department_head") {
          const uniqueDepartmentHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.department_head) {
              let exists = false;
              for (let obj of uniqueDepartmentHeads) {
                if ((obj as any).department_head === item.department_head) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                uniqueDepartmentHeads.add(item);
              }
            }
          });
          if (uniqueDepartmentHeads.size > 0) {
            const uniqueDepartmentsArray = Array.from(
              uniqueDepartmentHeads
            ) as any;
            const departementHeadWithKeys = uniqueDepartmentsArray.map(
              (item: any) => ({
                ...item,
                uniqueKey: uuidv4(),
              })
            );
            deleteModelData.department_head = departementHeadWithKeys;
          } else {
            Check = "branch_head";
          }
        }
        // Check Head For Branch
        if ((Check = "branch_head")) {
          const uniqueBranchHeads = new Set();
          res.data.forEach((item: any) => {
            if (item.branch_head) {
              let exists = false;
              for (let obj of uniqueBranchHeads) {
                if ((obj as any).branch_head === item.branch_head) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                uniqueBranchHeads.add(item);
              }
            }
          });
          if (uniqueBranchHeads.size > 0) {
            const uniqueBranchArray = Array.from(uniqueBranchHeads) as any;
            const branchHeadWithKeys = uniqueBranchArray.map((item: any) => ({
              ...item,
              uniqueKey: uuidv4(),
            }));
            deleteModelData.branch_head = branchHeadWithKeys;
          }
        }
      } else if (res.error) {
        setshowAlert(true);
        setAlertType("error");
      }
      //now we will check any any employee repoted him
      const employees_head = await fetchReportToEmployeebyEmployeeIdApi(
        editedEmployee.employee_id,
        editedEmployee?.organization_id
      );

      if (employees_head.data && employees_head.data.length > 0) {
        const employeeHeadWithKeys = employees_head.data.map((item: any) => ({
          ...item,
          uniqueKey: uuidv4(),
        }));
        deleteModelData.report_to_Employee = employeeHeadWithKeys as any;
      } else if (employees_head.error) {
        setshowAlert(true);
        setAlertType("error");
      }
      // Check if any key has an array length greater than 0
      const hasNonEmptyArray = Object.values(deleteModelData).some(
        (arr) => arr.length > 0
      );
      if (!hasNonEmptyArray) {
        const inactiveRes = await inActiveEmployeeByIdApi(
          editedEmployee.employee_id,
          editedEmployee?.organization_id
        );

        if (inactiveRes.status == 204) {
          setshowAlert(true);
          setAlertType("success");
          setTimeout(() => {
            fetchEmployee();
          }, 1000);
        } else if (inactiveRes.error) {
          setshowAlert(true);
          setAlertType("error");
        }
      } else {
        setShowNotDeleteModel(true);
        setDeletedEmployee(deleteModelData);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };
  const handleOpenEmailSentModel = (record: any) => {
    setEmailSentData(record);
    setShowEmailSentModel(true);
  };

  const handleSearching = () => {
    fetchEmployee();
    setFilterModalVisible(false);
  };
  const toggleView = () => {
    setViewMode((prevMode) => (prevMode === "card" ? "table" : "card"));
  };
  const handleReset = () => {
    setFilters(initialFilter);
    fetchEmployee(initialFilter);
  };
  const handleDeleteModalCancel = () => {
    setDeletedEmployee(null);
    setDeletedEmployeeId("");
    setShowNotDeleteModel(false);
  };
  const handleEmailModelCancel = () => {
    setShowEmailSentModel(false);
  };
  const resetFilter = () => {
    setFilters(initialFilter);
    fetchEmployee(initialFilter);
    setFilterModalVisible(false);
  };
  const comeBackFromHierarchyView = () => {
    setIsHierarchyModalVisible(false);
    fetchEmployee();
    setViewMode("card");
  };
  const handleResend = async (record: any) => {
    try {
      const data = {
        email: record.workEmail,
        employeeId: record.employee_id,
      };
      const res = await handleResendApi(data, organization_id);
      if (res.data) {
        setshowAlert(true);
        setAlertType("success");
      }
    } catch {
      setshowAlert(true);
      setAlertType("error");
    }
  };
  //
  const columns: any = [
    {
      title: "Employee",
      fixed: "left",
      width: 300,
      render: (record: any) => (
        <Row gutter={16}>
          <Col span={5}>
            <div className="circle-avatarUrl-cell">
              <img
                src={record.avatar_url ? record.avatar_url : PlaceholderAvatar}
                alt={PlaceholderAvatar}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col span={19}>
            <strong>{capitalizeWords(record.full_name || "")}</strong>
            <div className="emlist-dob-font-col">
              {record?.joining_date
                ? moment(record.joining_date).format("MMMM D, YYYY")
                : "Joining date not mentioned"}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
    },

    {
      title: "Designation",
      dataIndex: "designation_name",
      key: "designation_name",
    },
    {
      title: "Email",
      dataIndex: "work_email",
      key: "work_email",
      render: (_: any, record: any) =>
        record?.work_email && (
          <span className="email-column">{record?.work_email}</span>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (record: any) => {
        let color =
          record?.toLowerCase() == "created"
            ? "pink"
            : record?.toLowerCase() == "pending"
            ? "orange"
            : record?.toLowerCase() == "active"
            ? "green"
            : record?.toLowerCase() == "invited"
            ? "blue"
            : record?.toLowerCase() == "inactive"
            ? "gray"
            : "red";
        return (
          <div>
            <Tag color={color} className="employee-Tag-cell">
              <strong className="employee-status-tag-text">
                {capitalizeWords(record)}
              </strong>
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,

      render: (text: any, record: any) => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit" onClick={() => handleViewClick(record)}>
                  <EditOutlined /> Edit
                </Menu.Item>
                {record.status === "Created" && (
                  <Menu.Item
                    key="send"
                    onClick={() => handleOpenEmailSentModel(record)}
                  >
                    <BsFillSendFill /> Send Email
                  </Menu.Item>
                )}
                {record.status === "Invited" && (
                  <Menu.Item key="send" onClick={() => handleResend(record)}>
                    <BsFillSendFill /> ReSend Email
                  </Menu.Item>
                )}
                {record.status === "active" &&
                  record?.employee_id !==
                    logedInemployee?.logedInemployee?.employeeId && (
                    <Menu.Item key="delete" className="employee-inactive-tag">
                      <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to InActive this record?"
                        okText="Ok"
                        cancelText="Cancel"
                        onConfirm={() => handleDeleteConfirm(record)}
                      >
                        <DeleteOutlined /> Inactive
                      </Popconfirm>
                    </Menu.Item>
                  )}
                {record.status === "inactive" &&
                  record?.employee_id !==
                    logedInemployee?.logedInemployee?.employeeId && (
                    <Menu.Item key="delete">
                      <Popconfirm
                        placement="topLeft"
                        title="Are you sure you want to Active this record?"
                        okText="Ok"
                        cancelText="Cancel"
                        onConfirm={() => handleActiveConfirm(record)}
                      >
                        <VscLayersActive /> Active
                      </Popconfirm>
                    </Menu.Item>
                  )}
              </Menu>
            }
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    if (byPageNumber !== true) {
      setCurrentPageNumber((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    if (byPageNumber !== true) {
      setCurrentPageNumber((prev) => prev + 1);
    }
  };

  const handlePageSizeChange = (value: number) => {
    if (value !== pageSize) {
      setCurrentPageNumber(1);
      setCurrentPage(1);
      setTotalEmployeeNumber([1]);
    }
    setPageSize(value);
  };

  const handleActiveConfirm = async (record: any) => {
    try {
      const res = await ActiveEmployeeByIdApi(
        record.employee_id,
        logedInemployee?.logedInemployee?.employee?.organization_id
      );
      if (res.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        setTimeout(() => {
          fetchEmployee();
        }, 1000);
      }
    } catch {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const handlePageNumberChange = (value: number) => {
    if (value > currentPageNumber) {
      handleNext();
    } else if (value < currentPageNumber) {
      handlePrevious();
    }
    setByPageNumber(true);

    setTargetPageNumber(value);
  };

  return (
    <div>
      <div className="employee-main-container">
        {!isHierarchyModalVisible && (
          <>
            <div className="employee-top-card">
              <Row gutter={[16, 12]}>
                {employeeStatusArray.map((item) => (
                  <Col xs={10} sm={10} md={4} lg={4} key={item.value}>
                    <Tooltip title={item.tooltip}>
                      <Button
                        size="large"
                        type={
                          employeeStatusSelected === item.value
                            ? "primary"
                            : "default"
                        }
                        className={"employee-list-status-row"}
                        style={{ width: "100%" }}
                        onClick={() => {
                          setEmployeeStatusSelected(item.value);
                        }}
                        defaultValue={employeeStatusSelected}
                        icon={item.icon}
                      >
                        {item.label}
                      </Button>
                    </Tooltip>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="employee-top-card">
              <Row gutter={[16, 12]}>
                <Col
                  xs={24}
                  sm={24}
                  md={7}
                  lg={7}
                  xl={9}
                  className="employee-seach-row-left-Col-row-cols"
                >
                  <Input
                    prefix={<SearchOutlined />}
                    size="large"
                    placeholder="Enter Employee name..."
                    onChange={(e) =>
                      setFilters((prevFilters) => {
                        return {
                          ...prevFilters,
                          employeeName: e.target.value,
                        };
                      })
                    }
                    value={filters.employeeName ? filters.employeeName : ""}
                    onPressEnter={handleSearching}
                    allowClear
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={7}
                  lg={7}
                  xl={8}
                  className="employee-seach-row-left-Col-row-cols"
                >
                  <Input
                    prefix={<SearchOutlined />}
                    size="large"
                    placeholder="Enter Department Name..."
                    onChange={(e) =>
                      setFilters((prevFilters) => {
                        return {
                          ...prevFilters,
                          departmentName: e.target.value,
                        };
                      })
                    }
                    value={filters.departmentName ? filters.departmentName : ""}
                    onPressEnter={handleSearching}
                    allowClear
                  />
                </Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={3}>
                  <Button
                    type="primary"
                    icon={
                      <SearchOutlined className="employee-search-btn-icon" />
                    }
                    onClick={handleSearching}
                    style={{ width: "100%" }}
                    size="large"
                  >
                    Search
                  </Button>
                </Col>
                <Col xs={10} sm={10} md={4} lg={4} xl={3}>
                  <Button
                    size="large"
                    type="default"
                    onClick={() => setFilterModalVisible(true)}
                    icon={<CiFilter />}
                    style={{ width: "100%" }}
                  >
                    Filter
                  </Button>
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={1}
                  className="employee-details-view-dropdown-alignment"
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="Hierarchy"
                          onClick={() =>
                            setIsHierarchyModalVisible(!isHierarchyModalVisible)
                          }
                          icon={<TbHierarchy3 className="global-icon-color" />}
                          title="Departmental Hierarchy"
                        >
                          Departmental Hierarchy
                        </Menu.Item>
                        <Menu.Item
                          key="Refresh"
                          onClick={handleReset}
                          icon={<GrPowerReset className="global-icon-color" />}
                          title="Refresh"
                        >
                          Refresh
                        </Menu.Item>

                        <Menu.Item
                          icon={
                            viewMode == "card" ? (
                              <UnorderedListOutlined />
                            ) : (
                              <IdcardOutlined />
                            )
                          }
                          onClick={toggleView}
                        >
                          {viewMode == "card" ? "ListView" : "CardView"}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <CgDetailsMore className="employee-view-top-row-more-outlined employee-list-pointer" />
                  </Dropdown>
                </Col>
              </Row>
            </div>
          </>
        )}
        {loading ? (
          <div className="center-spinner-container">
            <Spin size="small" />
          </div>
        ) : !isHierarchyModalVisible && viewMode == "card" ? (
          <div>
            <div className="employee-card-outer-div">
              <Row gutter={[16, 16]}>
                {employees.length > 0 ? (
                  employees?.map((employee) => (
                    <>
                      <Col
                        key={employee.id}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        xxl={6}
                      >
                        <EmployeeProfileCard
                          key={employee.id}
                          employee={employee}
                          loading={loading}
                          onCancel={() => fetchEmployee()}
                        />
                      </Col>
                    </>
                  ))
                ) : (
                  <div className="employee-list-no-data-container">
                    <NoDataFoundIcon />
                  </div>
                )}
              </Row>
            </div>
          </div>
        ) : !isHierarchyModalVisible && viewMode == "table" ? (
          <CustomTable
            loading={loading}
            dataSource={employees}
            columns={columns}
          />
        ) : (
          <DepartmentHierarchy goBack={() => comeBackFromHierarchyView()} />
        )}

        {showNotDeleteModel && deletedEmployee && (
          <Modal
            className="global-modal-main-container"
            title={
              <span className="global-modal-title">
                Can't in-active employee
              </span>
            }
            onCancel={handleDeleteModalCancel}
            open={showNotDeleteModel}
            width={1000}
            footer={false}
          >
            <EmployeeDeleteModel
              record={deletedEmployee}
              headid={deletedEmployeeId}
              onCancel={() => setShowNotDeleteModel(false)}
              comapnayDetails={companyDetails}
            />
          </Modal>
        )}
        {showEmailSentModel && (
          <Modal
            title={
              <span className="global-modal-title">Send Email Invitation</span>
            }
            onCancel={handleEmailModelCancel}
            open={showEmailSentModel}
            width={1000}
            footer={false}
            className="global-modal-main-container"
          >
            <SendEmail
              record={showEmailSentData}
              onCancel={handleEmailModelCancel}
            />
          </Modal>
        )}
        {filterModalVisible && (
          <Modal
            open={filterModalVisible}
            onCancel={() => setFilterModalVisible(false)}
            title={<span className="global-modal-title">Add Filters</span>}
            footer={null}
            className="global-modal-main-container"
          >
            <Form layout="vertical">
              <div className="global-card-inner-container">
                <Row className="filter-input" gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item label="Designation">
                      <Input
                        placeholder="Enter Designation"
                        size="large"
                        onChange={(e) =>
                          setFilters((prevFilters) => {
                            return {
                              ...prevFilters,
                              designation: e.target.value,
                            };
                          })
                        }
                        value={filters.designation ? filters.designation : ""}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="filter-input" gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item label="Job Title">
                      <Input
                        placeholder="Enter Job Title"
                        size="large"
                        onChange={(e) =>
                          setFilters((prevFilters) => {
                            return {
                              ...prevFilters,
                              jobTitle: e.target.value,
                            };
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="filter-input" gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item label="Gender">
                      <Radio.Group
                        defaultValue={"all"}
                        options={[
                          { label: "Female", value: "female" },
                          { label: "Male", value: "male" },
                          { label: "Non Binary", value: "nonbinary" },
                          { label: "All", value: "all" },
                        ]}
                        onChange={(e) =>
                          setFilters((prevFilters) => {
                            return {
                              ...prevFilters,
                              gender: e.target.value,
                            };
                          })
                        }
                        value={filters.gender ? filters.gender : "all"}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} className="filter-input">
                  <Col span={24}>
                    {" "}
                    <Form.Item label="Work Type">
                      <Radio.Group
                        options={[
                          { label: "On Site", value: "onsite" },
                          { label: "Remote", value: "remote" },
                          { label: "hybrid", value: "hybrid" },
                          { label: "All", value: "all" },
                        ]}
                        defaultValue={"all"}
                        onChange={(e) =>
                          setFilters((prevFilters) => {
                            return {
                              ...prevFilters,
                              worktype: e.target.value,
                            };
                          })
                        }
                        value={filters.worktype ? filters.worktype : ""}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="Employee-filter-btn-row">
                  <div className="Employee-filter-btn-row-btn">
                    <Button
                      className="global-btn-width"
                      type="default"
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </Button>
                  </div>
                  <Button
                    className="global-btn-width"
                    type="primary"
                    onClick={handleSearching}
                  >
                    Set Filter
                  </Button>
                </Row>
              </div>
            </Form>
          </Modal>
        )}
        {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
        {!isHierarchyModalVisible && viewMode == "card" && (
          <Row>
            <Col span={24} className="employee-management-pagination-position">
              <div className="employee-management-pagination-arrows">
                <LeftOutlined
                  onClick={() => {
                    if (currentPage !== 1) {
                      handlePrevious();
                    }
                  }}
                  className={`employee-list-next-prev-btn ${
                    currentPage === 1
                      ? "employee-list-next-prev-btn-disabled"
                      : ""
                  }`}
                />
                {totalEmployeeNumber?.length > 0 &&
                  totalEmployeeNumber?.map((item) => (
                    <span
                      className={
                        currentPageNumber === item
                          ? `employee-list-next-prev-btn employee-list-pagination-page-number`
                          : "employee-list-next-prev-btn"
                      }
                      key={item}
                      onClick={() => handlePageNumberChange(item)}
                    >
                      {item}
                    </span>
                  ))}

                <RightOutlined
                  onClick={() => {
                    if (!isNextDisabled) {
                      handleNext();
                    }
                  }}
                  className={`employee-list-next-prev-btn ${
                    isNextDisabled ? "employee-list-next-prev-btn-disabled" : ""
                  }`}
                />
              </div>

              <Select
                defaultValue={pageSize}
                onChange={handlePageSizeChange}
                className="employee-list-sect-btn"
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                <Select.Option value={20}>20 / page</Select.Option>
                <Select.Option value={50}>50 / page</Select.Option>
                <Select.Option value={70}>70 / page</Select.Option>
                <Select.Option value={100}>100 / page</Select.Option>
              </Select>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default EmployeeList;
