import React, { useEffect, useState } from "react";
import "./index.css";
import { Form, Row, Col, Input, Button, Upload, Space } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { UploadOutlined } from "@ant-design/icons";
import {
  saveToAllowanceClaimAttachmentStorageApi,
  setAllowanceClaimApi,
} from "../services";

interface ClaimQuestionModelProps {
  onCancel: () => void;
  selectedRecordData: any;
  allowanceData: any;
}

const ClaimQuestionModel: React.FC<ClaimQuestionModelProps> = (props) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const beforeUpload = (file: any) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      return Upload.LIST_IGNORE;
    }
    setFileList([file]);
    return false;
  };

  const onRemove = () => {
    setFileList([])
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    setshowAlert(false);
    let data = {
      organization_Id: loggedInEmployee?.employee.organization_id,
      company_id: loggedInEmployee?.employee.company_id,
      branch_id: loggedInEmployee?.employee.branch_id,
      employee_id: loggedInEmployee?.employeeId,
      name: values.allowanceName,
      amount_to_claim: values.amountToClaim,
      question: values.question || [],
      attachment: "",
      allowance_Id: props.selectedRecordData.id,
      status: "Requested",
    };
    try {
      if (props?.allowanceData?.allow_attachment && values.attachment) {
        const storageBody = {
          file: values.attachment.file,
          filePath: values.attachment.file.uid,
        };
        const attachres = await saveToAllowanceClaimAttachmentStorageApi(
          storageBody
        );
        if (attachres.data) {
          data.attachment = attachres.data.path;
        }
      }
      if (!props?.allowanceData?.allow_attachment) {
        data.status = "Approved";
      }
      const res = await setAllowanceClaimApi(
        data,
        loggedInEmployee?.employee.organization_id
      );

      if (res.status == 201 || res.status == 204) {
        form.resetFields();
        props.onCancel();
        setshowAlert(true);
        setAlertType("success");
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          allowanceName: props.selectedRecordData?.name,
          amountToClaim: props?.selectedRecordData?.totalprice,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="allowanceName" label="Allowance Name">
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="amountToClaim"
              label="Enter A Amount You Want to Claim"
              rules={[
                {
                  required: true,
                  message: "Please Enter Claim Amount",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      parseInt(props?.selectedRecordData?.totalprice) >=
                        parseInt(value)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Value must be less than ${props?.selectedRecordData?.totalprice}`
                      )
                    );
                  },
                },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "Value must be a number",
                },
              ]}
            >
              <Input size="large" placeholder="Enter A Amount You Want to Claim" />
            </Form.Item>
          </Col>
        </Row>
        {props?.allowanceData?.questions?.map((item: any) => (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name={["question", item]}
                label={item}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Answer",
                  },
                ]}
              >
                <Input size="large" placeholder={item} />
              </Form.Item>
            </Col>
          </Row>
        ))}
        {props?.allowanceData?.allow_attachment && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="File Upload"
                name="attachment"
                rules={[
                  {
                    required: true,
                    message: "Please Attach File",
                  },
                ]}
              >
                <Upload
                  beforeUpload={beforeUpload}
                  accept=".pdf,.zip,image/png,image/jpeg"
                  onRemove={onRemove}
                  maxCount={1}
                >
                  {fileList.length >= 1 ? null : (
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        )}
        {
          <Row
            gutter={[16, 16]}
            className="claimquestion-model-form-container-last-row"
          >
            <Space>
              <Form.Item>
                <Button
                  className="global-default-btn"
                  onClick={() => props.onCancel()}
                  loading={loading}
                >
                  close
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="custom-primary-btn"
                  htmlType="submit"
                  loading={loading}
                >
                  {props?.allowanceData.needApproval
                    ? "Send Claim Request"
                    : "Claim"}
                </Button>
              </Form.Item>
            </Space>
          </Row>
        }
      </Form>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default ClaimQuestionModel;
