import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row } from "antd";
import CustomTable from "../../../../common/CustomTable";
import PlaceholderAvatar from "../../../../assets/images/blankProfile.png";

import moment from "moment";
import { capitalizeWords } from "../../../../common/validation";
import { fetchPayrollEmployeesByPayrollIdApi } from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";

interface GenrateViewProps {
  payrollid: any;
}

const PayrollEmployeeList: React.FC<GenrateViewProps> = ({ payrollid }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [tableData, setTableData] = useState<any[]>();
  const columns: any = [
    {
      title: "Employee",
      fixed: "left",
      dataIndex: "employee",
      width: 300,
      render: (record: any) => (
        <Row gutter={16}>
          <Col span={5}>
            <div className="circle-avatarUrl-cell">
              <img
                src={record.avatar_url ? record.avatar_url : PlaceholderAvatar}
                alt={PlaceholderAvatar}
                className="circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col span={19}>
            <strong>{capitalizeWords(record.full_name || "")}</strong>
            <div className="emlist-dob-font-col">
              {record?.joining_date
                ? moment(record.designation_name).format("MMMM D, YYYY")
                : "Joining date not mentioned"}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Joining Date",
      key: "name",
      dataIndex: "employee",
      render: (record: any) => <div>{record.joining_date}</div>,
    },
    {
      title: "Company",
      key: "company_name",
      dataIndex: "employee",
      render: (record: any) => <div>{record.company?.name}</div>,
    },
    {
      title: "Branch",
      key: "name",
      dataIndex: "employee",
      render: (record: any) => <div>{record.branch?.name}</div>,
    },
  ];

  useEffect(() => {
    fetchPayrollEmployee();
  }, [payrollid]);

  const fetchPayrollEmployee = async () => {
    const data = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      payroll_id: payrollid,
    };

    const res = await fetchPayrollEmployeesByPayrollIdApi(data);
    if (res.data) {
      setTableData(res.data);
    }
  };
  return (
    <div className="global-card-inner-container">
      <CustomTable dataSource={tableData} columns={columns} />
    </div>
  );
};
export default PayrollEmployeeList;
