import React, { useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Space, TablePaginationConfig, Empty } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { useSelector } from "react-redux";
import "../index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import { fetchTaxRebateApi } from "../services";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface PayrollTaxRebatesListProps {
  searchValue: string;
}

const PayrollTaxRebatesList: React.FC<PayrollTaxRebatesListProps> = ({
  searchValue,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [loading, setLoading] = useState<boolean>(true);
  const [tabledata, setTableData] = useState<any>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Tax Rebates");

  const read = access?.readAccess;

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchTableData = async () => {
    setLoading(true);
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
    };

    const res = await fetchTaxRebateApi(data);

    setLoading(false);
    if (res.data) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tabledata.length,
        },
      });
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleSearch = (value: any) => {
    setLoading(true);
    let filterData: any;
    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  const handleDownload = async (record: any) => {
    if (record.file_url) {
      try {
        const response = await fetch(record.file_url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${record.employee.full_name}_tax_rebate_${record.date}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {}
    }
  };

  const tableColumns = [
    {
      title: "Employee Name",
      dataIndex: "employee",
      key: "employee_name",
      sorter: (a: any, b: any) => {
        const nameA = a?.full_name || "";
        const nameB = b?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
      render: (record: any) => record.full_name,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_: any, item: any) =>
        item?.amount ? parseFloat(item?.amount)?.toLocaleString() : "",
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a onClick={() => handleDownload(record)}>
            <DownloadOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      {read ? (
        <CustomTable
          loading={loading}
          dataSource={tabledata}
          columns={tableColumns}
          rowKey="id"
          onChange={handleTableChange}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};

export default PayrollTaxRebatesList;
