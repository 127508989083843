import React from "react";
import { LineWave, ThreeCircles, Triangle } from "react-loader-spinner";
import "./index.css"; 

export const TriangleLoader: React.FC = () => {
  return (
    <div className="blur-screen">
      <div className="loader-wrapper">
        <Triangle
          height="60"
          width="60"
          color="var(--green-color-primary-light)"
          visible={true} 
          ariaLabel="triangle-loading"
        />
      </div>
    </div>
  );
};



export const ProgressLoader = () => {
  return (
    <div className="Linewave-loader-render-center">
      <LineWave
        height="100"   
        width="100"   
        color="#4fa94d"
        ariaLabel="line-wave"
        wrapperStyle={{ }}
        wrapperClass=""
        visible={true}
        firstLineColor="gray"
        middleLineColor="gray"
        lastLineColor="gray"
      />
    </div>
  )
}

export const ThreeCirclesLoader = () => {
  const outerCircleColor = "#4169e1";
  const innerCircleColor = "#290066";
  const middleCircleColor = "#000080";
  return (
    <div className="blur-screen">
      <div className="loader-wrapper">
        <ThreeCircles
          height="60"
          width="60"
          color="#ff8c00"
          visible={true}
          ariaLabel="triangle-loading"
          outerCircleColor={outerCircleColor}
          innerCircleColor={innerCircleColor}
          middleCircleColor={middleCircleColor}
        />
      </div>
    </div>
  )
}
