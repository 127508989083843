import moment from "moment";
import { catch_return } from "../../../common/Constants";
import { CustomformatDate } from "../../../common/validation";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

export const getLateArrivals = async (
  startDate: any,
  endDate: any,
  organization_id: string,
  employee_id: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organization_id)
      .from("employee_attendance")
      .select("check_in")
      .gte("date", startDate)
      .lte("date", endDate)
      .eq("check_in_status", "Late")
      .eq("employee_id", employee_id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getTheEarlyLeftCount = async (
  startDate: any,
  endDate: any,
  organization_id: string,
  employee_id: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organization_id)
      .from("employee_attendance")
      .select("check_in")
      .gte("date", startDate)
      .lte("date", endDate)
      .eq("check_out_status", "Early")
      .eq("employee_id", employee_id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getTheTimePolicyByEmployeeIdApi = async (
  employee_id: any,
  organizationId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_timing_policies")
      .select("*")
      .eq("employee_id", employee_id);

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeAttendanceDetails = async (
  employeeId: any,
  organizationId: any
): Promise<any> => {
  try {
    const timeZone = "Asia/Karachi";
    const now = moment.tz(timeZone);

    const startOfMonth = now.clone().startOf("month").toISOString();
    const endOfMonth = now.clone().endOf("month").toISOString();

    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("*")
      .eq("employee_id", employeeId)
      .gte("created_at", startOfMonth)
      .lte("created_at", endOfMonth);

    return response;
  } catch (error) {
    return { error };
  }
};

export const calcNumOfPresentsbyEmployeeIdApi = async (
  startDate: any,
  endDate: any,
  employeeId: any,
  organizationId: any
) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("check_in")
      .gte("date", startDate)
      .lte("date", endDate)
      .eq("employee_id", employeeId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getTheTotalNumOfleaves = async (
  startDate: any,
  endDate: any,
  employeeId: any,
  organizationId: any
) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_leave_request")
      .select("employee_id, leave_count")
      .eq("employee_id", employeeId)
      .gte("created_at", startDate)
      .lte("created_at", endDate)
      .eq("leave_status", "Approved");

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEmployeeKPIHistoryForGraph = async (
  employeeIdObject: any,
  organizationId: any
) => {
  try {
    const { employeeId, selectedYear } = employeeIdObject;

    let yearToUse;

    if (selectedYear && selectedYear !== null) {
      yearToUse = selectedYear;
    } else {
      yearToUse = new Date().getFullYear();
    }

    const startDate = new Date(yearToUse, 0, 1).toISOString();
    const endDate = new Date(yearToUse + 1, 0, 1).toISOString();

    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_kpi")
      .select("*")
      .eq("employee_id", employeeId)
      .gte("created_at", startDate)
      .lt("created_at", endDate);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getWorkingHoursApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_timing_policies")
      .select("*")
      .eq("employee_id", data.employee_id);

    const resemployeeattrnd = await query.single();

    return resemployeeattrnd;
  } catch {
    return catch_return;
  }
};

export const calculateWorkingHours = (
  workingHoursPerDay: any,
  workingDays: any
): number => {
  if (!workingDays) {
    return 0;
  }

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  let workingDayCount = 0;
  for (
    let day = new Date(firstDayOfMonth);
    day <= currentDate;
    day.setDate(day.getDate() + 1)
  ) {
    const dayOfWeekString = day.toLocaleDateString("en-US", {
      weekday: "short",
    });

    if (workingDays[dayOfWeekString]) {
      workingDayCount++;
    }
  }

  const totalWorkingHours = workingDayCount * workingHoursPerDay;
  return totalWorkingHours;
};

export const getAttendanceApi = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const firstDayOfMonthString = CustomformatDate(firstDayOfMonth);
    const currentDateString = CustomformatDate(currentDate);
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("*")
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId)
      .gte("date", firstDayOfMonthString)
      .lte("date", currentDateString);
    const resemployeeattrnd = await query;

    return resemployeeattrnd;
  } catch {
    return catch_return;
  }
};

export const calculateProductiveHours = (
  data: any,
  WorkingHoursperday: string
): number => {
  let totalWorkingHours = 0;
  data.forEach((item: any) => {
    if (item.check_out && item.check_in) {
      let checkInTime: any = new Date(item.check_in);
      let checkOutTime: any = new Date(item.check_out);
      let hoursWorked = (checkOutTime - checkInTime) / (1000 * 60 * 60);
      totalWorkingHours += hoursWorked;
    } else if (item.check_in && !item.check_out) {
      totalWorkingHours += Number(WorkingHoursperday);
    }
  });
  return totalWorkingHours;
};

export const prepareChatData = (data: any, normalHour: number) => {
  return data.map((item: any) => {
    let todaytotalWorkingHours = normalHour;
    if (item.check_out && item.check_in) {
      let checkInTime: any = new Date(item.check_in);
      let checkOutTime: any = new Date(item.check_out);
      let hoursWorked = (checkOutTime - checkInTime) / (1000 * 60 * 60);
      todaytotalWorkingHours = hoursWorked;
    }

    let extraHours = 0;
    if (todaytotalWorkingHours > normalHour) {
      extraHours = +(todaytotalWorkingHours - normalHour).toFixed(2);
    }
    let shortHours = 0;
    if (todaytotalWorkingHours < normalHour) {
      shortHours = +(todaytotalWorkingHours - normalHour).toFixed(2);
    }
    return {
      name: item.date.slice(5),
      WorkedHours: todaytotalWorkingHours.toFixed(2),
      ExtraHours: extraHours.toFixed(2),
      ShortHours: shortHours.toFixed(2),
    };
  });
};

export const getMissingPunchesCount = async (
  startDate: any,
  endDate: any,
  organization_id: string,
  employee_id: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organization_id)
      .from("employee_attendance")
      .select("check_in")
      .gte("date", startDate)
      .lte("date", endDate)
      .or("check_out_status.eq.Missing,check_in_status.eq.Missing")
      .eq("employee_id", employee_id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getTheHolidays = async (
  organizationId: any,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("event_scheduler")
      .select("start_date_and_time, end_date_and_time, type_of_event")
      .eq("organization_id", organizationId);

    if (companyId) {
      query = query.eq("company_id", companyId);
    }

    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const response = await query;
    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
