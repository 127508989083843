import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Modal,
  Form,
  Button,
  Select,
  Typography,
  Row,
  Col,
  Input,
  Checkbox,
  Radio,
  Upload,
} from "antd";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import CustomAlert from "../../../../../common/Alert";
import {
  fetchPayrollApi,
  fetchsalaryGradeApi,
} from "../../../../../services/Global/index";
import { fetchSalaryDetailsbyId } from "../../../../Payroll/services";
import currencyInfoMap from "currency-info-map/map.js";
import {
  calculatePerDaySalary,
  currencyArrayMapping,
} from "../FormView/viewmodel";
import { fetchAllEmployeeBanksApi, UpdateSalaryApi } from "../services";
import {
  insertEmployeeDocumentsDataApi,
  uploadEMployeeDocumentsApi,
} from "../../../EmployeeViewDetails/services";
import { UploadFile } from "antd/lib";
import { UploadOutlined } from "@ant-design/icons";
import CustomTable from "../../../../../common/CustomTable";
import { render } from "react-dom";
import { CustomformatDate } from "../../../../../common/validation";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface EditSalaries {
  isVisible: boolean;
  employeeName: string;
  onCancel: () => void;
  recordId: string;
}

const EmployeeDetailsModal: React.FC<EditSalaries> = (props) => {
  const [form] = useForm();
  const [initialBasicSalary, setInitailBasicSalary] = useState<any[]>();
  const [startedFrom, setStartedFrom] = useState<string>();
  const [updateCounter, setUpdateCounter] = useState<number>(0);
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [gradsData, setGradsData] = useState<any[]>([]);
  const [basicSalary, setBasicSalary] = useState<string>();
  const [isBasicSalaryTaxAble, setisBasicSalaryTaxAble] =
    useState<boolean>(false);
  const [isBasicSalaryPF, setisBasicSalaryPF] = useState<boolean>(false);
  const [pfPct, setPfPct] = useState<string>();
  const [selectedCurrency, setSelectedCurrency] = useState<string>("PKR");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [EOBI, setEOBI] = useState<number>(0);
  const [goWithpecentage, setGoWithPercentage] = useState("percentage");
  const [payrollData, setPayrollData] = useState<any[]>([]);
  const [pfMethod, setPfMethod] = useState<"amount" | "percentage">(
    "percentage"
  );
  const [selectedPayementMethod, setSelectedPaymentMethed] =
    useState<string>("");
  const [bankAccount, setBankAccount] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string>("");
  const [intialBank, setIntialBank] = useState<any>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [taxPaid, setTaxPaid] = useState<string>("");
  const [counter, setRefCounter] = useState<number>(0);
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [branchId, setBranchId] = useState<string | undefined>();
  const [intialSalaryRevision, setInitailSalaryRevision] = useState<any[]>([]);
  const organizationId = logedInemployee?.employee.organization_id;

  useEffect(() => {
    getGrade();
    fetchSelectedRecordDetails();
    fetchPayrollaccounts();
  }, [props.recordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    form.setFieldValue("bankAccount", "");
    setBankAccount([]);
    if (selectedPayementMethod == "bank") {
      const data = {
        employee_id: selectedEmployee,
        organizationId: organizationId,
      };
      fetchBankAccount(data);
    }
  }, [selectedPayementMethod, selectedEmployee]);

  useEffect(() => {
    form.setFieldValue("pfAmount", "");
    if (pfPct && basicSalary) {
      const amount = (parseInt(pfPct) * parseInt(basicSalary)) / 100;
      form.setFieldValue("pfAmount", amount);
    }
  }, [pfPct, basicSalary]);

  useEffect(() => {
    if (intialBank && bankAccount.length > 0) {
      const init = bankAccount?.filter((item) => {
        if (item.row_id == intialBank.row_id) {
          return item;
        }
      });

      form.setFieldValue("bankAccount", (init as any)[0]?.row_id);
    }
  }, [intialBank, bankAccount]);

  const fetchBankAccount = async (data: any) => {
    const res = await fetchAllEmployeeBanksApi(data);
    if (res.data) {
      let bankInformation = res.data.bank_information;
      // Normalize to an array if it's not already one
      if (!Array.isArray(bankInformation)) {
        bankInformation = [bankInformation];
      }

      const banks = bankInformation.map((item: any) => {
        return {
          id: res.data.id,
          label: `${item.accountNumber} (${item.bankName})`,
          row_id: item.id,
        };
      });
      setBankAccount(banks);
    }
  };

  const currencySelector = () => {
    return (
      <Select
        placeholder="Select a currency..."
        defaultValue={"PKR"}
        style={{ width: 80 }}
        showSearch
        filterOption={(input: any, option: any) =>
          option && option.children
            ? (option.children as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            : false
        }
        className="payslip-set-currency-selector"
        onChange={setSelectedCurrency}
        value={selectedCurrency}
        disabled
        notFoundContent={
          <NoDataFoundIcon />
        }
      >
        {currencyArrayMapping(currencyInfoMap)?.map((item: any) => (
          <Select.Option key={item.name} value={item.name}>
            {item.name}-{item.symbol}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const fetchSelectedRecordDetails = async () => {
    if (props.recordId) {
      const res = await fetchSalaryDetailsbyId(props.recordId, organizationId);
      if (res.data) {
        form.setFieldsValue({
          selectedGrade: res.data?.grade_id,
          baseSalary: res.data.base_salary,
          perDaySalary: res.data?.per_day_salary,
          workingDays: res.data?.working_day,
          basicSalaryTaxable: res.data?.is_base_salary_taxable,
          isProvidentFund:
            res.data?.is_provident_fund_apply_on_base_salary || false,
          pfPct: res.data?.provident_fund_value,
          EOBI: res.data?.EOBI || 0,
          payrollAcount: res.data.payroll_id,
          paymentMethod: res.data.payment_type,
          pfMethod: res.data.pf_dedection_method,
          taxationMethod:
            res.data.go_with_percentage == true ? "percentage" : "fixAmount",
          pfAmount: res.data?.pf_amount,
          companyId: res.data.company_id || "",
          branchId: res.data.branch_id || "",
          employee_id: res.data?.employee_id,
        });
        setStartedFrom(res.data?.created_at);
        setInitailSalaryRevision(
          res.data?.revision_history ? res.data?.revision_history : []
        );
        setInitailBasicSalary(res.data.base_salary);
        setCompanyId(res.data.company_id);
        setBranchId(res.data.branch_id);
        setIntialBank(res.data.bank_account);
        setGoWithPercentage(
          res.data.go_with_percentage == true ? "percentage" : "fixAmount"
        );
        if (res.data.pf_dedection_method) {
          setPfMethod(res.data.pf_dedection_method);
        }
        setSelectedPaymentMethed(res.data.payment_type);
        setSelectedEmployee(res.data.employee_id);
        setGoWithPercentage(res.data?.go_with_percentage || "");
        setSelectedCurrency(res.data?.currency);
        setBasicSalary(res.data.base_salary);
        setEOBI(res.data?.EOBI || 0);
        setisBasicSalaryTaxAble(res.data?.is_base_salary_taxable);
        setPfPct(res.data?.provident_fund_value);
        setisBasicSalaryPF(
          res.data?.is_provident_fund_apply_on_base_salary
            ? res.data?.is_provident_fund_apply_on_base_salary
            : false
        );
        setUpdateCounter(updateCounter + 1);
      }
    }
  };

  const getGrade = async () => {
    const data = {
      organizationId: organizationId,
      companyId: companyId,
      branchId: branchId,
    };
    const res = await fetchsalaryGradeApi(data);

    if (res.data) {
      setGradsData(res.data);
    }
  };

  const handleFormChange = (_: any, allValues: any) => {
    const { baseSalary, workingDays } = allValues;
    if (baseSalary && workingDays) {
      const perDaySalary = calculatePerDaySalary(baseSalary, workingDays);
      form.setFieldsValue({
        perDaySalary: perDaySalary,
      });
    }
  };
  /**
   * Handles the submission of form values to update salary information.
   * @param {any} values - The form values to be submitted.
   * @returns None
   */
  const onFinish = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);
    const bank_details = bankAccount?.filter(
      (item) => item.row_id == values.bankAccount
    );
    if (fileList.length > 0) {
      await handleFileSubmit({
        organization_id: organizationId || null,
        company_id: values.companyId || companyId || null,
        branch_id: values.branchId || branchId || null,
        employee_id: values.employee_id || selectedEmployee,
      });
    }
    try {
      const updateddata = {
        base_salary: values.baseSalary,
        grade_id: values.selectedGrade,
        per_day_salary: values.perDaySalary,
        working_day: values.workingDays,
        is_base_salary_taxable: values.basicSalaryTaxable,
        taxation_version_Id: values.taxation,
        custom_tax_value: values.customTaxValue,
        is_provident_fund_apply_on_base_salary: values.isProvidentFund,
        provident_fund_value: values.pfPct,
        currency: selectedCurrency,
        EOBI: values.EOBI || EOBI || 0,
        go_with_percentage:
          (goWithpecentage == "percentage" ? true : false) || true,
        paid_tax: values.paid_tax || 0,
        payroll_id: values.payrollAcount,
        payment_type: values.paymentMethod,
        pf_dedection_method: values.pfMethod,
        bank_account: bank_details[0] || {},
        pf_amount: values.pfAmount,
      };

      if (values.baseSalary != initialBasicSalary) {
        const currentMonth = new Date();
        let revsion = [];
        if (intialSalaryRevision.length == 0) {
          ///for those salary who already created
          revsion = [
            ...intialSalaryRevision,
            { month: currentMonth, amount: values.baseSalary },
            { month: startedFrom, amount: initialBasicSalary },
          ];
        } else {
          revsion = [
            ...intialSalaryRevision,
            { month: currentMonth, amount: values.baseSalary },
          ];
        }
        (updateddata as any).revision_history = revsion;
      }
      const data = {
        updateddata: updateddata,
        id: props.recordId,
      };
      const res = await UpdateSalaryApi(data, organizationId);

      if (res.status == 201 || res.status == 204) {
        setshowAlert(true);
        setAlertType("success");
        setTimeout(() => {
          props.onCancel();
        }, 3000);
      } else {
        setshowAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };

  const fetchPayrollaccounts = async () => {
    const data = {
      organizationId: organizationId,
      companyId: companyId,
      branchId: branchId,
    };
    const res = await fetchPayrollApi(data);
    if (res.data) {
      setPayrollData(res.data);
    }
  };

  const handleChange = (info: any) => {
    setFileList(info.fileList);
  };
  const handleUpload = async () => {
    const file = fileList[0];
    const { name } = file;
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${name}`;
    const res = await uploadEMployeeDocumentsApi(
      fileName,
      (file as any).originFileObj
    );
    return res;
  };

  const handleFileSubmit = async (data: any) => {
    const url_res = await handleUpload();
    if (url_res && url_res.data) {
      const url = url_res.data.publicUrl;
      const formData = {
        employee_id: data.employee_id,
        name: "Tax Paid in " + new Date().getFullYear(),
        attachment: url || "",
        organization_id: data.organization_id,
        company_id: data.company_id || null,
        branch_id: data.branch_id || null,
      };

      const res = await insertEmployeeDocumentsDataApi(formData);
    } else {
      setshowAlert(true);
      setAlertType("error");
      return;
    }
  };
  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (record: string) => (record ? formatDate(record) : ""),
    },
    {
      title: "Salary",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <>
      <Modal
        open={props.isVisible}
        onCancel={props.onCancel}
        width={1000}
        footer={null}
        title={<span className="global-modal-title">Edit Salaries</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onValuesChange={handleFormChange}
            onFinish={onFinish}
            key={updateCounter}
          >
            <div>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="employeeId"
                    label="Select Employee"
                    initialValue={props.employeeName}
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label="Payment Method"
                    name="paymentMethod"
                    rules={[
                      {
                        required: true,
                        message: "Select Payment Method.",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setSelectedPaymentMethed(e.target.value);
                        setRefCounter(counter + 1);
                      }}
                    >
                      <Radio value="cash">Cash</Radio>
                      <Radio value="bank">Bank</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9} key={counter}>
                  <Form.Item
                    name="bankAccount"
                    label="Bank Account"
                    rules={[
                      {
                        required: selectedPayementMethod == "bank",
                        message: "Select Bank Account.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Bank Account"
                      allowClear
                      showSearch
                      disabled={
                        !selectedPayementMethod ||
                        selectedPayementMethod !== "bank"
                      }
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {bankAccount?.map((item) => (
                        <Select.Option key={item.row_id} value={item.row_id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="EOBI"
                    label="EOBI Amount"
                    rules={[
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const numValue = parseFloat(value);
                            if (isNaN(numValue) || numValue <= 0) {
                              return Promise.reject(
                                new Error(
                                  "Invalid EOBI! Please Enter a Number Greater Than Zero"
                                )
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the EOBI"
                      size="large"
                      onChange={(e) => setEOBI(parseFloat(e.target.value))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item name="selectedGrade" label="Grade">
                    <Select
                      placeholder="Select Grade"
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {gradsData?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                  <Form.Item
                    label="Payroll"
                    name="payrollAcount"
                    rules={[
                      {
                        required: true,
                        message: "Please select an payroll",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Payroll"
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {payrollData?.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.payroll}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* GROUP 2 */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="baseSalary"
                    label="Basic Salary"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the base salary.",
                      },
                      {
                        type: "number",
                        message: "Please enter a Number",
                        transform(value) {
                          return Number(value);
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter the base salary of Employee"
                      addonAfter={currencySelector()}
                      onChange={(e) => setBasicSalary(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="workingDays"
                    label="Working Days"
                    initialValue={7}
                    rules={[
                      {
                        required: true,
                        message: "Please Number Of Working Days",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a Number Of Working Days"
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {[1, 2, 3, 4, 5, 6, 7].map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                  <Form.Item name="perDaySalary" label="Per Day Salary">
                    <Input
                      size="large"
                      placeholder="Per Day Salary"
                      disabled
                      addonAfter={selectedCurrency}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* GROUP 3 */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="isProvidentFund"
                    valuePropName="checked"
                    label="Provident Fund Applicable?"
                  >
                    <Checkbox
                      value={isBasicSalaryPF}
                      onChange={() => {
                        setisBasicSalaryPF(!isBasicSalaryPF);
                        form.setFieldValue("pfPct", "");
                        form.setFieldValue("pfAmount", "");

                        setPfPct("");
                      }}
                    >
                      IS Provident Fund Applicable?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={5}>
                  <Form.Item
                    label="Deduction Method"
                    name="pfMethod"
                    rules={[
                      {
                        required: isBasicSalaryPF,
                        message: "Select Deduction Method.",
                      },
                    ]}
                  >
                    <Radio.Group
                      disabled={isBasicSalaryPF ? false : true}
                      value={isBasicSalaryPF ? pfMethod : ""}
                      onChange={(e) => setPfMethod(e.target.value)}
                    >
                      <Radio value="amount">Fix Amount</Radio>
                      <Radio value="percentage">Percentage</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    name="pfPct"
                    label="PF % deduct."
                    rules={[
                      {
                        required: isBasicSalaryPF && pfMethod == "percentage",
                        message: "Enter PF % deduct from salary.",
                      },
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const numValue = parseFloat(value);
                            if (isNaN(numValue) || numValue < 0) {
                              return Promise.reject(
                                new Error("Invalid  Amount!")
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setPfPct(e.target.value)}
                      disabled={
                        (isBasicSalaryPF && pfMethod == "percentage") ==
                          false && true
                      }
                      size="large"
                      addonAfter={"%"}
                      placeholder="Enter provident fund on base salary..."
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item
                    name="pfAmount"
                    label="PF amount deduct."
                    rules={[
                      {
                        required: isBasicSalaryPF,
                        message: "Enter PF amount deduct from salary.",
                      },
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const numValue = parseFloat(value);
                            if (isNaN(numValue) || numValue < 0) {
                              return Promise.reject(
                                new Error("Invalid  Amount!")
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      disabled={
                        (isBasicSalaryPF && pfMethod == "amount") == false &&
                        true
                      }
                      size="large"
                      placeholder="Enter provident fund on base salary..."
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* GROUP 4 */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Item
                    name="basicSalaryTaxable"
                    valuePropName="checked"
                    label="Taxable Salary"
                  >
                    <Checkbox
                      value={isBasicSalaryTaxAble}
                      onChange={() =>
                        setisBasicSalaryTaxAble(!isBasicSalaryTaxAble)
                      }
                    >
                      Basic Salary Taxable?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label="Paid any tax this year? Enter amount:"
                    name="paid_tax"
                    rules={[
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                      {
                        validator: (_, value) => {
                          if (value) {
                            const numValue = parseFloat(value);
                            if (isNaN(numValue) || numValue < 0) {
                              return Promise.reject(
                                new Error("Invalid  Amount!")
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Paid any tax this year? Enter amount"
                      disabled={isBasicSalaryTaxAble ? false : true}
                      onChange={(e) => setTaxPaid(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                  <Form.Item label="Taxation Method" name="taxationMethod">
                    <Radio.Group
                      disabled={isBasicSalaryTaxAble ? false : true}
                      value={isBasicSalaryTaxAble ? goWithpecentage : ""}
                      onChange={(e) => setGoWithPercentage(e.target.value)}
                    >
                      <Radio value="fixAmount">Fix Amount</Radio>
                      <Radio value="percentage">Percentage</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {taxPaid && (
                  <Col span={24}>
                    <Form.Item name="upload" label="Upload Tax Paid Document">
                      <Upload
                        accept=".png,.jpg,.jpeg,.zip,.pdf,.doc,.docx,.txt"
                        maxCount={1}
                        onChange={handleChange}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row className="global-modal-bottom-row create-button">
                <Button
                  htmlType="submit"
                  className="global-btn-width"
                  type="primary"
                  loading={buttonLoading}
                  size="large"
                >
                  Update
                </Button>
              </Row>
            </div>
          </Form>

          {intialSalaryRevision.length > 1 && (
            <div style={{ marginTop: "5px" }}>
              <h3> Revision </h3>
              <CustomTable
                dataSource={intialSalaryRevision}
                columns={columns}
              />
            </div>
          )}
        </div>
      </Modal>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default EmployeeDetailsModal;
