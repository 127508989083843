import React from "react";
import { Card } from "antd";
import "./index.css";
import { HelpAndSupport } from "../../Constants";
 

const MisGenieIntro: React.FC = () => {
  const intro = HelpAndSupport.Getting_Started;

  return (
    <div className="get-started-container">
      <Card className="get-started-card">
        <span className="getting-started-heading">{intro.heading}</span>
        <p className="getting-started-description">{intro.text}</p>
        <span className="getting-started-sub-heading">
          {intro.mission_heading}
        </span>
        <p className="getting-started-description">{intro.mission_text}</p>
        <span className="getting-started-sub-heading">{intro.why_heading}</span>
        <ul>
          <li className="getting-started-description">{intro.first_point}</li>
          <li className="getting-started-description">{intro.second_point}</li>
          <li className="getting-started-description">{intro.third_point}</li>
          <li className="getting-started-description">{intro.fourth_point}</li>
          <li className="getting-started-description">{intro.fifth_point}</li>
          <li className="getting-started-description">{intro.sixth_point}</li>
        </ul>
        <span className="getting-started-sub-heading">
          {intro.features_heading}
        </span>
        <ol className="getting-started-sub-heading">
          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.employee_manag_heading}
            </span>: {" "}
            {intro.employee_manag_text}
          </li>

          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.asset_manag_heading}
            </span>: {" "}
            {intro.asset_manag_text}
          </li>
          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.paroll_heading}
            </span>: {" "}
            {intro.paroll_text}
          </li>

          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.attendance_heading}
            </span>: {" "}
            {intro.attendance_text}
          </li>

          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.performance_heading}
            </span>: {" "}
            {intro.performance_text}
          </li>

          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.policies_heading}
            </span>: {" "}
            {intro.policies_text}
          </li>

          <li className="getting-started-description">
            <span className="getting-started-bold-text">
              {intro.recruitment_heading}
            </span>: {" "}
            {intro.recruitment_text}
          </li>
        </ol>
      </Card>
    </div>
  );
};

export default MisGenieIntro;
