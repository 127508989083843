import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchDepartmentsinfoApi = async (
  organizationId: any
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("department")
      .select(
        "id,name, employee!department_head_manager_id_fkey(full_name,avatar_url,designation!employee_designation_id_fkey(name)),head_manager_id"
      )
      .eq("organization_Id", organizationId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchReportToEmployessInfobyemployeeidanddepartmentIdApi = async (
  headId: any,
  departmentId: any,
  organizationId: string
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        "id,full_name,avatar_url,designation!employee_designation_id_fkey(name)"
      )
      .eq("report_to_employee_id", headId)
      .eq("department_id", departmentId)
      .eq("status", "active");
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
