import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const onFinishEmployeeResignationForm = async (
  data: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(data.organization_id)
      .from("employee_resignation")
      .insert(data);
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const employeeResignationCheckRequest = async (
  employeeId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .select("id,status,created_at,employee_Id")
      .eq("employee_Id", employeeId);

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const employeeResignationListViewData = async (
  loggedInEmployee: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee_resignation")
      .select(
        `description,authorized_person_id,status,start_date,end_date,reviwer_comment,employee_Id,notice_period,reason,id, employee!resignation_authorized_person_id_fkey(full_name)`
      )
      .eq("employee_Id", loggedInEmployee);

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};
