import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Card, Col, Row, Select } from "antd";
import "./index.css";
import { capitalizeWords } from "../../../../common/validation";
import { ProgressLoader } from "../../../../common/ReactLoader";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";

interface ByWorkingFormateProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const WorkingFormate: React.FC<ByWorkingFormateProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [workTypeData, setWorkTypeData] = useState<
    { id: string; value: number }[]
  >([]);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    setWorkFormatData(props.employeeData);
  }, [props.employeeData]);
  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);
  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);
  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);
  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    setWorkFormatData(tempData);
  };
  const filterbranches = () => {
    if (props.branches && selectedCompany) {
      const filterbranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );
      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };
  const setWorkFormatData = async (data: any[]) => {
    if (data) {
      const workTypeCounts = data.reduce((counts: any, employee: any) => {
        const workType = employee.work_type || "Unknown";
        counts[workType] = (counts[workType] || 0) + 1;
        return counts;
      }, {});

      const workTypeDataArray = Object.keys(workTypeCounts).map((work) => ({
        id: capitalizeWords(work),
        value: workTypeCounts[work],
      }));
      workTypeDataArray.sort((a, b) => b.value - a.value);
      setWorkTypeData(workTypeDataArray);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      className="work-format-main-container dasboard-cards"
      style={{ fontFamily: "var(--storefront-heading-font-family)" }}
      title={
        <Row className="working-formate-icon-toggle-container">
          <Col span={24} md={6} className="working-formate-title-text">
            Work Format
          </Col>
          <Col span={24} md={18}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id &&loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="working-formate-card-selectors-row">
                {isToggled && (
                  <>
                    <Col
                      span={7}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a company..."
                        onChange={setSelectedCompany}
                        className="totalemployee-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col
                      span={7}
                      className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a branch"
                        onChange={setSelectedBranch}
                        className="totalemployee-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOptions.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col
                  span={2}
                  className="working-icon-container"
                  onClick={handleToggle}
                >
                  {isToggled ? (
                    <div className="working-search-icon-container">
                      <IoSearch className="working-search-icon" />
                    </div>
                  ) : (
                    <div className="working-search-icon-container">
                      <IoSearch className="working-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      <div style={{ height: 300 }}>
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : workTypeData && workTypeData.length > 0 ? (
          <ResponsivePie
            data={workTypeData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.3}
            padAngle={2}
            cornerRadius={5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={3}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom1",

                background: "inherit",
                color: "#218b8c",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom2",

                background: "inherit",
                color: "#c8eae1",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: "custom3",
                type: "none",
                background: "inherit",
                color: "var(--green-color-primary-light)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={workTypeData.map((entry, index) => ({
              match: { id: entry.id },
              id:
                index % 3 === 0
                  ? "custom1"
                  : index % 3 === 1
                  ? "custom2"
                  : "custom3",
            }))}
          />
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataFoundIcon />
          </div>
        )}
      </div>
    </Card>
  );
};

export default WorkingFormate;
