import React, { useState, useEffect } from "react";
import { Space, Modal, Popconfirm, Empty, Select } from "antd";
import "./index.css";

import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DsignationEdit from "../Edit";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import CustomAlert from "../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import { getData, handleDelete } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import DesignationFilterModal from "../Filter";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

interface DesignationListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const { Option } = Select;

const DesignationList: React.FC<DesignationListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const selectedOrganization = useSelector(selectLogedEmployee);
  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [dsignationData, setDsignationData] = useState<any>(null);
  const organizationId =
    selectedOrganization?.logedInemployee?.employee?.organization_id;
  const [designationList, setDesignationList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [editedDesignation, setEditedDesignation] = useState<any>(null);
  const [searchInput, setSearchInput] = useState<any | null>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Organization",
    "Designations"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchData();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setDesignationList(originalData.apiData);
    }
  }, [searchValue]);

  const fetchCompanies = async () => {
    try {
      setCompanySelectLoading(true);
      const res = await fetchallCompanyByOrgIdApi(organizationId);
      if (res.data) {
        setCompanyOptions(res.data);
        setSelectedCompany(res.data[0]?.id);
      }
      setCompanySelectLoading(false);
    } catch (error) {}
  };

  const fetchData = async () => {
    setIsLoading(true);
    setShowEditModel(false);
    if (organizationId) {
      const data = {
        organizationId: organizationId,
        searchInput: searchInput,
        companyId: selectedCompany,
      };
      const response = await getData(data, organizationId);
      setIsLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: designationList.length,
        },
      });

      const designationListWithCompany: any = [];
      response.data?.map((item: any) => {
        designationListWithCompany.push({
          designationname: item?.name,
          companyname: item?.company?.name,
          companyIds: item?.company_id,
          designationid: item.id,
          comment: item?.mission,
          id: item.id,
          branchId: item.branch_id,
        });
      });
      if (response.data) {
        const designationListWithCompany = response.data.map((item: any) => ({
          designationname: item?.name,
          companyname: item?.company?.name,
          companyIds: item?.company_id,
          designationid: item.id,
          comment: item?.mission,
          id: item.id,
          branchId: item.branch_id,
        }));

        setDesignationList(designationListWithCompany);

        setOriginalData((prevState: any) => ({
          ...prevState,
          apiData: designationListWithCompany,
        }));
      }
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setDesignationList([]);
    }
  };

  const handleEditModalCancel = () => {
    setShowEditModel(false);
  };

  const clickEdit = (record: any) => {
    setDsignationData(record);

    setShowEditModel(true);
  };

  const handleDeleteConfirm = async () => {
    setshowAlert(false);
    setAlertDescription("");
    try {
      const response: any = await handleDelete(
        editedDesignation.id,
        organizationId
      );

      if (response.error) {
        setshowAlert(true);
        setAlertDescription(
          "Deletion failed: This Designation has assigned employee. Please reassign or remove employee before deleting."
        );
        setAlertType("error");
      }

      if (response.status == 201 || response.status == 204) {
        setshowAlert(true);

        setAlertType("success");
        fetchData();
      }
    } catch (error) {
      return error;
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmVisible(false);
    setEditedDesignation(null);
  };

  const handleDeleteClick = (record: any) => {
    setEditedDesignation(record);
    setDeleteConfirmVisible(true);
  };

  const columns = [
    {
      title: "Designation",
      dataIndex: "designationname",
      key: "designationname",
      sorter: (a: any, b: any) => {
        const nameA = a.designationname || "";
        const nameB = b.designationname || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Company",
      dataIndex: "companyname",
      key: "companyname",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => clickEdit(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this designation?"
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            open={deleteConfirmVisible && editedDesignation.id == record.id}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a onClick={() => handleDeleteClick(record)}>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setIsLoading(true);
    setSearchInput(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "designationname");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setDesignationList(filterData);

    setIsLoading(false);
  };

  const getFilterDataHandler = (companyName: string) => {
    const filterArray = [{ path: "companyname", value: companyName }];
    let response: any;

    response = filterLogicHandler(filterArray, originalData);

    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setDesignationList(response);
    }
  };

  return (
    <>
      {read ? (
        <>
          <Select
            showSearch
            placeholder="Select Company"
            filterOption={(input, option: any) =>
              option && option.children
                ? (option.children as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                : false
            }
            loading={companySelectLoading}
            onChange={setSelectedCompany}
            className="designation-company-select"
            value={selectedCompany}
            notFoundContent={
              <NoDataFoundIcon />
            }
          >
            {companyOptions?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          <CustomTable
            columns={columns}
            dataSource={designationList}
            loading={isLoading}
            onChange={handleTableChange}
          />
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      {showEditModel && (
        <Modal
          title={<span className="global-modal-title">Edit Details</span>}
          onCancel={handleEditModalCancel}
          open={showEditModel}
          width={1000}
          footer={false}
          className="global-modal-main-container"
          destroyOnClose
        >
          <DsignationEdit data={dsignationData} fetchData={fetchData} />
        </Modal>
      )}
      {showAlert && (
        <CustomAlert
          type={alertType}
          showAlert={showAlert}
          description={alertDescription}
        />
      )}

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <DesignationFilterModal
          getFilterDataHandler={(companyName) =>
            getFilterDataHandler(companyName)
          }
        />
      </Modal>
    </>
  );
};

export default DesignationList;
