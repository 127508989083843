import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Input, Button, Select } from "antd";
import "./index.css";
import CustomAlert from "../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import { storeBugFormData } from "./services";

interface ContactUsPageProps {
  onCancel: () => void;
}
const { Option } = Select;

const ContactUsPage: React.FC<ContactUsPageProps> = ({ onCancel }) => {
  const selectedUser = useSelector(selectLogedEmployee);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const onFinish = async (values: any) => {
    const body = {
      reported_by: selectedUser.logedInemployee?.employeeId,
      organization_id: selectedUser.logedInemployee?.employee.organization_id,
      name: values.name,
      email: values.email,
      category: values.bugCategory,
      severity: values.bugSeverity,
      actual_behavior: values.actualBehavior,
      expected_behavior: values.expectedBehavior,
      message: values.message,
    };

    const data = await storeBugFormData(body);

    if (data.status == 201) {
      form.resetFields();
      setshowAlert(true);
      setAlertType("success");
      setTimeout(() => {
        onCancel();
      }, 500);
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <div className="contact-us-container">
      <Form
        name="contactForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input className="name-input" placeholder="Your Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              initialValue={selectedUser.logedInemployee?.employee?.workEmail}
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input className="email-input" placeholder="Your Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="bugCategory"
              label="Bug Category"
              rules={[
                { required: true, message: "Please select a bug category" },
              ]}
            >
              <Select
                placeholder="Select Bug Category"
                showSearch
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              >
                <Option value="UI/UX">UI/UX</Option>
                <Option value="Functional">Functional</Option>
                <Option value="Data">Data</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="bugSeverity"
              label="Bug Severity"
              rules={[
                { required: true, message: "Please select a bug severity" },
              ]}
            >
              <Select
                placeholder="Select Bug Severity"
                showSearch
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              >
                <Option value="Critical">Critical</Option>
                <Option value="Major">Major</Option>
                <Option value="Minor">Minor</Option>
                <Option value="Cosmetic">Cosmetic</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="actualBehavior" label="Actual Behavior">
              <Input.TextArea
                placeholder="Describe the actual behavior"
                rows={3}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expectedBehavior" label="Expected Behavior">
              <Input.TextArea
                placeholder="Describe the expected behavior"
                rows={3}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="message" label="Message">
              <Input.TextArea
                className="message-input"
                placeholder="Your Message"
                rows={6}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="contact-submit-button">
            <Button
                type="primary"
                className="global-btn-width"
                htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
      {showAlert && (
        <CustomAlert
          type={alertType}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </div>
  );
};

export default ContactUsPage;
