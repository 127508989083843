import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { ProgressLoader } from "../../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";
import { ResponsivePie } from '@nivo/pie';
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import "./index.css";
import { IoSearch } from "react-icons/io5";

interface ByAgeProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const ByAge: React.FC<ByAgeProps> = (props) => {
  const [ageData, setAgeData] = useState<{ id: string; value: number }[]>([]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    fetchAgeData(props.employeeData);
  }, [props.employeeData]);

  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);

  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);

  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);

  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    fetchAgeData(tempData);
  };

  const filterbranches = () => {
    if (props.branches && selectedCompany) {
      const filterbranches = props.branches.filter(
        (item) => item.company_id == selectedCompany
      );
      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const fetchAgeData = async (data: any[]) => {
    if (data) {
      const ageBrackets: any = {
        "11-20": 0,
        "21-30": 0,
        "31-40": 0,
        "41-50": 0,
        "51-60": 0,
        "61-70": 0,
      };

      const currentDate = moment();
      data.forEach((employee: any) => {
        const dob = moment(employee.dob);
        const age = currentDate.diff(dob, "years");

        if (age >= 11 && age <= 20) {
          ageBrackets["11-20"]++;
        } else if (age >= 21 && age <= 30) {
          ageBrackets["21-30"]++;
        } else if (age >= 31 && age <= 40) {
          ageBrackets["31-40"]++;
        } else if (age >= 41 && age <= 50) {
          ageBrackets["41-50"]++;
        } else if (age >= 51 && age <= 60) {
          ageBrackets["51-60"]++;
        } else if (age >= 61 && age <= 70) {
          ageBrackets["61-70"]++;
        }
      });
      const ageDataArray = Object.keys(ageBrackets)
        .map((id) => ({
          id,
          value: ageBrackets[id],
        }))
        .filter((entry) => entry.value > 0);
      ageDataArray.sort((a: any, b: any) => b.value - a.value);
      setAgeData(ageDataArray);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <Card
      className="dasboard-cards"
      title={
        <Row className="byage-icon-toggle-container">
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={24}
            xs={24}
            className="byage-title-text"
          >
            By Age
          </Col>
          <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id && loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="byage-card-selectors-row">
                {isToggled && (
                  <>
                    <Col span={9} className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}>
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Company..."
                        onChange={setSelectedCompany}
                        className="byage-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={9} className={`header-inputs ${
                        isToggled ? "visible" : "hidden"
                      }`}>
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Branch..."
                        onChange={setSelectedBranch}
                        className="byage-card-selectors-width"
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOptions.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                )}
                <Col
                  span={4}
                  className="byage-icon-container"
                  onClick={handleToggle}
                >
                   {isToggled ? (
                    <div className="byage-search-icon-container">
                      <IoSearch className="byteam-search-icon" />
                    </div>
                  ) : (
                    <div className="byage-search-icon-container">
                      <IoSearch className="byteam-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      <div style={{ height: 300 }}>
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : ageData && ageData.length > 0 ? (
          <ResponsivePie
            data={ageData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.3}
            padAngle={2}
            cornerRadius={5}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="var(--green-color-primary-light)"
            arcLinkLabelsThickness={3}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots", // Ensure the type is specified
                background: "inherit",
                color: "rgb(251, 207, 205)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines", // Ensure the type is specified
                background: "inherit",
                color: "rgb(178, 218, 227)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={ageData.map((entry, index) => ({
              match: { id: entry.id },
              id: index % 2 === 0 ? "lines" : "dots",
            }))}
            
          />
        ) : (
          <div
          style={{
            height: "18.5rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          >
            <NoDataFoundIcon />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ByAge;
