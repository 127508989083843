import { catch_return } from "../../../common/Constants";
import supabase, { supabase_client_for_Org_schema } from "../../../services/supabase";

export const TaxRebatsfileinsertApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("tax_rebate")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from("tax_rebate")
        .getPublicUrl(filePath);
      return publicURL;
    }
    else {
      return res
    }
  } catch {
    return catch_return;
  }
};


export const createTaxRebateApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("tax_rebate")
      .insert(data);
    return response;
  } catch {
    return catch_return;
  }
};
export const fetchTaxRebateApi = async (
  data: any,

): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("tax_rebate")
      .select(
        `*, employee(full_name)`
      )
      .eq("organization_id", data.organizationId);

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    const response = await query
    return response;
  } catch {
    return catch_return;
  }
};