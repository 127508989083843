import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import TabsHeader from "./../../../common/TabsHeader";
import CompanyList from "./../Companies/View";
import { Card, Empty, Modal } from "antd";
import CreateCompany from "./../Companies/Create";
import BranchesListView from "./../Branches/View";
import DepartmentList from "./../Department/View";
import DesignationList from "../Designation/View";
import JobListTable from "../JobTitle/View";
import TeamsList from "../Teams/View";
import CreateBranchForm from "./../Branches/Create";
import CreateDepartment from "./../Department/Create";
import CreateDesignation from "../Designation/Create";
import CreateJobTitle from "../JobTitle/Create";
import CreateTeams from "../Teams/Create";
import { useDispatch, useSelector } from "react-redux";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { selectOrganizationModulePermission } from "../../../../redux/organizationModulePermission";
import CustomAlert from "../../../common/Alert";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../../redux/MenuBreadcrumbSlice";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

const Organization: React.FC = () => {
  const accessObj = useSelector(selectAccess);
  const dispatch = useDispatch();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const [fetchTableData, setFetchTableData] = useState<number>(
    Math.random() + 1
  );
  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );
  const [selected, setSelected] = useState<string>("Company");
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.Organization;
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  let radioOptions = useMemo(
    () => [
      {
        label: "Companies",
        value: "Company",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Branches",
        value: "Branch",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Departments",
        value: "Department",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Designations",
        value: "Designation",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Job Title",
        value: "Job Title",
        create: true,
        filter: true,
        showSearch: true,
      },
      {
        label: "Teams",
        value: "Team",
        create: true,
        filter: true,
        showSearch: true,
      },
    ],
    []
  );

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  const [menuTabsOrg, setMenuTabsOrg] = useState<any[]>(filteredRadioOptions);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 1000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "organization"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
    const filteredMenuTabs =
      filtertabstocheckcomapnyAdmin(filteredRadioOptions);
    if (filteredMenuTabs) {
      setMenuTabsOrg(filteredMenuTabs);
      setSelected(filteredMenuTabs[0]?.value);

      setFetchTableData(Math.random() + 1);
    }
  }, [filteredRadioOptions]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "organization",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Organization";
    let childValue =
      value == "Company"
        ? "Companies"
        : value == "Branch"
        ? "Branches"
        : value == "Department"
        ? "Departments"
        : value == "Designation"
        ? "Designations"
        : value == "Job Title"
        ? "Job Titles"
        : "Teams";

    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = (alertStatus: string) => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
    if (alertStatus === "success") setshowAlert(true);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };
  const filtertabstocheckcomapnyAdmin = (tabslist: any[]) => {
    // just if not supper admin remove company and branch
    if (
      loggedInEmployee?.employeeRole === "hr" ||
      (loggedInEmployee?.employee?.company_id &&
        loggedInEmployee?.employee?.branch_id)
    ) {
      const containsCompanyOrBranch = tabslist.some(
        (item: any) => item?.value === "Company" || item?.value === "Branch"
      );
      const filteredResults = containsCompanyOrBranch
        ? tabslist.filter(
            (item: any) => item.value !== "Company" && item.value !== "Branch"
          )
        : tabslist;
      return filteredResults;
    } else if (
      loggedInEmployee?.employee?.company_id &&
      loggedInEmployee?.employee?.branch_id === null
    ) {
      const containsCompanyOrBranch = tabslist.some(
        (item: any) => item?.value === "Company"
      );
      const filteredResults = containsCompanyOrBranch
        ? tabslist.filter((item: any) => item.value !== "Company")
        : tabslist;
      return filteredResults;
    }
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={menuTabsOrg}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("");
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            selected={selected}
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Company" ? (
                <CompanyList
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Branch" ? (
                <BranchesListView
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Department" ? (
                <DepartmentList
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Designation" ? (
                <DesignationList
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Job Title" ? (
                <JobListTable
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : selected === "Team" ? (
                <TeamsList
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : null
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <div key={fetchModalData}>
            <Modal
              title={
                <span className="global-modal-title">Create {selected}</span>
              }
              open={createModalVisible}
              onCancel={() => handleCreateModalCancel("fail")}
              width={1000}
              footer={false}
              className="global-modal-main-container"
            >
              {createModalVisible && selected === "Company" && (
                <CreateCompany onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Branch" && (
                <CreateBranchForm onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Department" && (
                <CreateDepartment onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Designation" && (
                <CreateDesignation onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Job Title" && (
                <CreateJobTitle onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Team" && (
                <CreateTeams onCancel={handleCreateModalCancel} />
              )}
            </Modal>{" "}
          </div>
        </Card>
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default Organization;
