import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Row, Col, Button } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getRecordByIdAPI } from "../services";
import { currencies } from "../../../../common/Currencies";
import {
  getTaxationVersionAPI,
  updatePayrollGenerationData,
} from "../../services";
import CustomAlert from "../../../../common/Alert";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface props {
  visible: boolean;
  onCancel: () => void;
  recordId: any;
}

const EditGeneratePay: React.FC<props> = ({ visible, onCancel, recordId }) => {
  const [form] = Form.useForm();
  const [accountsList, setAccountsList] = useState<any>(null);
  const [taxationVersion, setTaxationVersion] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const organization_id =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    getRecordDetails();
  }, [recordId]);

  const getRecordDetails = async () => {
    const response = await getRecordByIdAPI(recordId, organization_id);
    if (response.data) {
      form.setFieldsValue({
        accountName: response.data?.account_id,
        description: response.data?.description,
        selectTaxStructure: response?.data.taxation_id,
        selectCurrency: response?.data.currency,
        payroll: response?.data?.payroll,
      });
      getAccounts(
        organization_id,
        response?.data?.company_id,
        response?.data?.branch_id
      );
      getTaxStructure(
        organization_id,
        response?.data?.company_id,
        response?.data?.branch_id
      );
    }
  };
  const onFinish = async () => {
    setLoader(true);
    await form.validateFields();
    const payload = {
      taxation_id: form.getFieldValue("selectTaxStructure"),
      description: form.getFieldValue("description"),
      account_id: form.getFieldValue("accountName"),
      currency: form.getFieldValue("selectCurrency"),
      payroll: form.getFieldValue("payroll"),
    };

    const response = await updatePayrollGenerationData(
      organization_id,
      payload,
      recordId
    );
    if (response.status == 201 || response.status == 204) {
      setShowAlert(true);
      setAlertType("success");
      setLoader(false);
    } else {
      setLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };
  const getAccounts = async (
    organizationId: string,
    companyId: string,
    branchId: string
  ) => {
    try {
      let query = supabase_client_for_Org_schema(organizationId)
        .from("company_account_list")
        .select("id, account_name");

      if (companyId) {
        query = query.eq("company_id", companyId);
      }
      if (branchId) {
        query = query.eq("branch_id", branchId);
      }
      const res = await query;
      if (res.data) {
        setAccountsList(res.data);
      }
    } catch (error) {
      return error;
    }
  };
  const getTaxStructure = async (
    organizationId: string,
    companyId: string,
    branchId: string
  ) => {
    const response = await getTaxationVersionAPI(
      organizationId,
      companyId,
      branchId
    );
    if (response.data) {
      setTaxationVersion(response.data);
    }
  };
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  return (
    <div>
      <Modal
        title="Payroll"
        open={visible}
        onCancel={onCancel}
        footer={null}
        okText="Submit"
        cancelText="Cancel"
        width={1000}
        destroyOnClose
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="payroll"
                label="Payroll"
                rules={[
                  {
                    required: true,
                    message: "Please enter payroll name",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter payroll.." />
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="accountName"
                label="Account Name"
                rules={[
                  {
                    required: true,
                    message: "Enter the account name",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select an account"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {accountsList?.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.account_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="selectTaxStructure"
                label="Select a tax structure"
                rules={[
                  {
                    required: true,
                    message: "Please select a tax structure",
                  },
                ]}
              >
                <Select allowClear showSearch placeholder="Select placeholder"
                notFoundContent={
                  <NoDataFoundIcon />
                }
                >
                  {taxationVersion?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.taxation_version_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="selectCurrency"
                label="Select a currency"
                rules={[
                  {
                    required: true,
                    message: "Please select a currency",
                  },
                ]}
              >
                <Select showSearch allowClear placeholder="Select currency"
                notFoundContent={
                  <NoDataFoundIcon />
                }
                >
                  {currencies?.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="description" label="Enter description">
                <Input.TextArea rows={4} placeholder="Enter description..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className="gen-pay-add-btn">
            <Button
              size="large"
              className="global-btn-width"
              type="primary"
              loading={loader}
              htmlType="submit"
            >
              Update
            </Button>
          </Row>
        </Form>
      </Modal>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default EditGeneratePay;
