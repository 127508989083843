import React, { useState, useEffect } from "react";
import { Steps, Button, Form, Input, Row, Col, Select } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import {
  domaincheck,
  generateRandom4DigitNumber,
  removeSpaces,
} from "../../../../common/validation";
import "react-international-phone/style.css";
import CustomAlert from "../../../../common/Alert";
import { branchManagerNames, companyOfBranch } from "../../Branches/services";
import { getBranches, getCompanies, insertData } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface companiesOptiontype {
  label: string;
  id: string;
}
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

interface DepartmentformData {
  departmentName: string;
  departmentCode: string;
  headManagerName: number;
  phoneNumber: string;
  departmentDetails: string;
  policiesAndGuidelines: string;
  branchId: string;
  branchName: string;
  email: string;
  companyId: string;
  companyName: string;
}

interface props {
  onCancel: (status: string) => void;
}

const CreateDepartment: React.FC<props> = ({ onCancel }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [companiesOption, setCompaniesOption] = useState<companiesOptiontype[]>(
    []
  );
  const [branchOption, setBranchOption] = useState<companiesOptiontype[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [departmentCode, setDepartmentCode] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [branchManagerSelectLoading, setBranchManagerSelectLoading] =
    useState<boolean>(false);

  const [form] = Form.useForm();
  const [formData, setFormData] = useState<DepartmentformData>({
    departmentName: "",
    departmentCode: "",
    headManagerName: -1,
    phoneNumber: "",
    departmentDetails: "",
    policiesAndGuidelines: "",
    branchId: "",
    branchName: "",
    email: "",
    companyId: "",
    companyName: "",
  });
  const [managerNames, setManagerNames] = useState<string[] | any>([]);
  const [branchCompany, setBranchCompany] = useState<any>(null);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    fetchBranchManagerNames();
    fetchCompanies();
  }, []);
  useEffect(() => {
    fetchBranches();
    getCompanyOfBranch();
  }, [formData.companyId]);

  useEffect(() => {
    generateId();
  }, [
    form.getFieldValue("companyname"),
    form.getFieldValue("branchname"),
    form.getFieldValue("departmentName"),
  ]);

  const fetchBranchManagerNames = async () => {
    try {
      setBranchManagerSelectLoading(true);
      const response = await branchManagerNames(organizationId);

      if (response.data) {
        const names = response.data.map((item: any) => ({
          id: item.id,
          name: item.full_name,
        }));
        setManagerNames(names);
        setBranchManagerSelectLoading(false);
      }
      setBranchManagerSelectLoading(false);
    } catch (error) {
      setBranchManagerSelectLoading(false);
    }
  };
  const phoneUtil = PhoneNumberUtil.getInstance();

  const nextStep = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {}
  };

  const getCompanyOfBranch = async () => {
    const response = await companyOfBranch(formData.companyId, organizationId);
    if (response.data) {
      setBranchCompany(response.data.email);
    }
  };

  const generateId = () => {
    const matchingCompany = companiesOption.find(
      (item) => item.id == form.getFieldValue("companyname")
    );
    if (matchingCompany) {
      const words =
        loggedInEmployee?.logedInemployee?.employee.organization_name?.split(
          " "
        );
      const idPrefix = words?.map((word: any) => word[0]).join("");
      const code =
        idPrefix +
        "-" +
        removeSpaces(matchingCompany.label || "") +
        "-" +
        removeSpaces(form.getFieldValue("departmentName") || "");
      if (
        form.getFieldValue("departmentName")?.charAt(0)?.toLowerCase() == "n"
      ) {
        setDepartmentCode(code);
      } else {
        setDepartmentCode(code + "-" + generateRandom4DigitNumber());
      }
    }
  };

  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const formValidaiton = await form.validateFields();
      if (
        !formValidaiton.errorFields ||
        formValidaiton.errorFields.length === 0
      ) {
        const body = {
          name: formData.departmentName,
          unique_identifier: departmentCode,
          head_manager_id:
            formData.headManagerName && formData.headManagerName !== -1
              ? formData.headManagerName
              : null,
          phone_number: formData.phoneNumber,
          mission: formData.departmentDetails || null,
          email: formData.email,
          branch_id: formData.branchId || null,
          organization_Id: organizationId,
          company_id: formData.companyId,
        };

        const departmentData = await insertData(body, organizationId);

        if (departmentData.status == 201 || departmentData.status == 204) {
          setButtonLoading(false);
          form.resetFields();
          setCurrentStep(0);
          onCancel("success");
        } else {
          setButtonLoading(false);
          setshowAlert(true);
          setAlertType("error");
        }
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const fetchCompanies = async () => {
    setCompanySelectLoading(true);
    const companies_data = await getCompanies(organizationId);

    if (companies_data.data) {
      const option: companiesOptiontype[] = companies_data.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      setCompaniesOption(option);
      setCompanySelectLoading(false);
    }
    setCompanySelectLoading(false);
  };

  const fetchBranches = async () => {
    const data = {
      companyId: formData.companyId,
      organizationId: organizationId,
    };

    const response = await getBranches(data);
    if (response.data) {
      const option: companiesOptiontype[] = response.data?.map((item: any) => ({
        id: item.id,
        label: item.name,
      }));
      setBranchOption(option);
      setBranchSelectLoading(false);
    }
    setBranchSelectLoading(false);
  };

  const steps = [
    {
      title: "Department Information",
      content: (
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company"
                name="companyname"
                rules={[
                  {
                    required: true,
                    message: "Please Select Company",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Company"
                  optionFilterProp="children"
                  onChange={(value) => handleFormChange("companyId", value)}
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companiesOption.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item label="Branch Name (If any)" name="branchname">
                <Select
                  showSearch
                  placeholder="Select Branch"
                  optionFilterProp="children"
                  onChange={(value) =>
                    setFormData({ ...formData, branchId: value })
                  }
                  loading={branchSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {branchOption.map((branch) => (
                    <Option key={branch.id} value={branch.id}>
                      {branch.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Department Name"
                name="departmentName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Department name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Department Name"
                  value={formData.departmentName}
                  size="large"
                  onChange={(e) =>
                    handleFormChange("departmentName", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item label="Manager" name="headManagerName">
                <Select
                  placeholder="Enter Branch Manager's Name"
                  onChange={(value) =>
                    handleFormChange("headManagerName", value)
                  }
                  loading={branchManagerSelectLoading}
                  showSearch
                  optionFilterProp="children"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {managerNames.map((manager: any) => (
                    <Option key={manager.id} value={manager.id}>
                      {manager.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}></Col>
          </Row>
          <div className="global-modal-bottom-row">
            <Form.Item>
              <Row className="department-buttons">
                <Button
                  className="global-btn-width"
                  type="primary"
                  onClick={nextStep}
                  size="large"
                >
                  Next
                </Button>
              </Row>
            </Form.Item>
          </div>
        </Form>
      ),
    },
    {
      title: "Contact Information",
      content: (
        <Form layout="vertical" form={form}>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        value.length > 3 &&
                        !phoneUtil.isValidNumber(
                          phoneUtil.parseAndKeepRawInput(value)
                        )
                      ) {
                        return Promise.reject("Invalid phone number");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  defaultCountry={"pk"}
                  value={formData.phoneNumber}
                  onChange={(value) => handleFormChange("phoneNumber", value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && !domaincheck(branchCompany, value)) {
                        return Promise.reject("Invalid Company Email!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  value={formData.email}
                  size="large"
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="global-modal-bottom-row department-buttons ">
              <Button
                style={{ marginRight: "8px", marginLeft: "7px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                className="global-btn-width"
                type="default"
                size="large"
              >
                Previous
              </Button>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
                size="large"
              >
                Next
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },

    {
      title: "Goals and Responsibilities",
      content: (
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item label="Description" name="departmentDetails">
                <TextArea
                  placeholder="Enter Description..."
                  rows={3}
                  onChange={(e) =>
                    handleFormChange("departmentDetails", e.target.value)
                  }
                  value={formData.departmentDetails}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="global-modal-bottom-row  department-buttons">
              <Button
                className="global-btn-width"
                type="default"
                style={{ marginRight: "8px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                size="large"
              >
                Previous
              </Button>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleSubmit}
                loading={buttonLoading}
                size="large"
              >
                Submit
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <div className="global-card-inner-container">
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={`Step ${index + 1}`}
          />
        ))}
      </Steps>
      <div className="department-steps-content">
        {steps[currentStep].content}
      </div>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </div>
  );
};

export default CreateDepartment;
