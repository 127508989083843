import React, { useState, useEffect } from "react";
import { Input, Col, Row, Form, Button, Select } from "antd";
import { useSelector } from "react-redux";
import { getCountries, getStates, getCities } from "vk-country-state-city";
import CustomAlert from "../../../../../common/Alert";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import {
  fetchAllEmployeeWithOutAdresssApi,
  insetAddressesApi,
} from "../services";
import {
  fetchAllBranchesByComapnyIdApi,
  fetchallCompanyByOrgIdApi,
} from "../../../../../services/Global";
import axios from "axios";
import NoDataFoundIcon from "../../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateEmployeeAdress: React.FC<props> = ({ onCancel }) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [selectedState, setSelectedState] = useState<string>("");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);
  const [employeeSelectLoading, setEmployeeSelectLoading] =
    useState<boolean>(false);
  const [countrySelectLoading, setCountrySelectLoading] =
    useState<boolean>(false);
  const [stateSelectLoading, setStateSelectLoading] = useState<boolean>(false);
  const [citySelectLoading, setCitySelectLoading] = useState<boolean>(false);
  const [fetchedCompanies, setFetchedCompanies] = useState<any[]>([]);
  const { Option } = Select;
  const [form] = useForm();
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [permenantCountries, setPermenantCountries] = useState<string[]>([]);
  const [permenantStates, setPermenantStates] = useState<string[]>([]);
  const [permenantCities, setPermenantCities] = useState<string[]>([]);
  const [selectedPermenantCountry, setselectedPermenantCountry] =
    useState<string>("");
  const [selectedPermanentState, setselectedPermanentState] =
    useState<string>("");

  const companyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;

  const organization_id = loggedInEmployee?.employee.organization_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("selectedCompany", companyId);
      setSelectedCompany(companyId);
    } else {
      getAllCompaniess();
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY;

  useEffect(() => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      getAllBranchess();
    }
  }, [selectedCompany]);

  useEffect(() => {
    async function fetchCountries() {
      setCountrySelectLoading(true);
      const countries = await getCountries();
      setCountries(countries);
      setCountrySelectLoading(false);
      form.setFieldValue("country", countries[0]);
      setSelectedCountry(countries[0]);
      const permenantCountries = await getCountries();
      setPermenantCountries(permenantCountries);
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      if (selectedCountry) {
        setStateSelectLoading(true);
        const states = await getStates(selectedCountry);
        setStates(states);
        setStateSelectLoading(false);
      }
      if (selectedPermenantCountry) {
        const states = await getStates(selectedPermenantCountry);
        setPermenantStates(states);
      }
    }
    if (selectedCountry) fetchStates();
  }, [selectedCountry, selectedPermenantCountry]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedState && selectedCountry) {
        setCitySelectLoading(true);
        try {
          const cities = await getCities(selectedCountry, selectedState);
          setCities(cities);
        } catch (error) {
          return error;
        }
        if (selectedPermenantCountry && selectedPermanentState) {
          const cities = await getCities(
            selectedPermenantCountry,
            selectedPermanentState
          );
          setPermenantCities(cities);
        }
        setCitySelectLoading(false);
      }
    }
    fetchCities();
  }, [
    selectedState,
    selectedCountry,
    selectedPermenantCountry,
    selectedPermanentState,
  ]);

  useEffect(() => {
    if (selectedCompany || selectedBranch) {
      getEmployeesWithoutSkills();
    }
  }, [selectedCompany, selectedBranch]);

  const getEmployeesWithoutSkills = async () => {
    setFilteredEmployees([]);
    form.setFieldValue("employeeid", "");
    setEmployeeSelectLoading(true);
    const data = {
      organizationId: loggedInEmployee?.employee?.organization_id,
      companyId: companyId,
      branchId: branchId,
    };
    const res = await fetchAllEmployeeWithOutAdresssApi(data);

    setFilteredEmployees(res);
    setEmployeeSelectLoading(false);
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const response = await getGeoCode();
    const body = {
      present_address_detail: {
        present_street: form.getFieldValue("present_street"),
        present_city: form.getFieldValue("present_city"),
        present_postcode: form.getFieldValue("present_postcode"),
        present_state: form.getFieldValue("present_state"),
        present_country: form.getFieldValue("present_country"),
        geocoordinates: [response.lat, response.lon],
      },
      permanent_address_detail: {
        permanent_street: form.getFieldValue("permanent_street"),
        permanent_city: form.getFieldValue("permanent_city"),
        permanent_postcode: form.getFieldValue("permanent_postcode"),
        permanent_state: form.getFieldValue("permanent_state"),
        permanent_country: form.getFieldValue("permanent_country"),
      },
      employee_id: selectedEmployee,
      organization_id: loggedInEmployee?.employee?.organization_id,
      company_id: selectedCompany || companyId || null,
      branch_id: selectedBranch || branchId || null,
    };
    const res = await insetAddressesApi(body, organization_id);
    if (res.status == 201) {
      setshowAlert(true);
      setAlertType("success");
      getEmployeesWithoutSkills();
      form.resetFields();
      setBranches([]);
      setFilteredEmployees([]);
      setButtonLoading(false);
      onCancel();
    } else {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };

  const getAllBranchess = async () => {
    if (branchId) {
      form.setFieldValue("selectedBranch", branchId);
      setSelectedBranch(branchId);
    } else {
      setBranchSelectLoading(true);
      form.setFieldValue("selectedBranch", "");
      setBranches([]);
      const response = await fetchAllBranchesByComapnyIdApi(
        organization_id,
        form.getFieldValue("selectedCompany")
      );

      if (response.data) {
        setBranches(response.data);
        form.setFieldValue("selectedBranch", response.data[0]?.id);
        setSelectedBranch(response.data[0]?.id);
        setBranchSelectLoading(false);
      }
      setBranchSelectLoading(false);
    }
  };

  const getAllCompaniess = async () => {
    setCompanySelectLoading(true);
    const response = await fetchallCompanyByOrgIdApi(
      loggedInEmployee?.employee?.organization_id
    );

    if (response.data) {
      setFetchedCompanies(response.data);
      form.setFieldValue("selectedCompany", response.data[0]?.id);
      setSelectedCompany(response.data[0]?.id);
      setCompanySelectLoading(false);
    }
    setCompanySelectLoading(false);
  };

  const getGeoCode = async () => {
    const params = {
      q: `${form.getFieldValue("present_postcode")}, Pakistan`,
      format: "json",
      key: API_KEY,
    };
    const response = await axios.get("https://us1.locationiq.com/v1/search", {
      params,
    });

    if (response && response.data) {
      return response.data[0];
    }
  };
  return (
    <div className="global-card-inner-container">
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter={16}>
          {!companyId && (
            <Col span={12}>
              <Form.Item
                label="Select Company"
                name={"selectedCompany"}
                rules={[
                  {
                    required: true,
                    message: "Please select a company...",
                  },
                ]}
              >
                <Select
                  loading={companySelectLoading}
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedCompany}
                  placeholder="Please select a company"
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {fetchedCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {!(companyId || branchId) && (
            <Col span={12}>
              <Form.Item label="Select Branch" name={"selectedBranch"}>
                <Select
                  loading={branchSelectLoading}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  allowClear
                  placeholder="Please select a branch (if any)"
                  onChange={setSelectedBranch}
                  notFoundContent={<NoDataFoundIcon />}
                >
                  {branches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col sm={24} xs={24} md={12} lg={12}>
            <Form.Item
              name="employeeid"
              label="Employee Name"
              rules={[
                { required: true, message: "Please select an employee!" },
              ]}
            >
              <Select
                showSearch
                allowClear
                onChange={setSelectedEmployee}
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Employee"
                loading={employeeSelectLoading}
                notFoundContent={<NoDataFoundIcon />}
              >
                {filteredEmployees?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="present_country"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select
                loading={countrySelectLoading}
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Country"
                onChange={setSelectedCountry}
                notFoundContent={<NoDataFoundIcon />}
              >
                {countries.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="permanent-heading">
          <span className="permanent-address-heading">Present Address</span>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="present_state"
              label="State/Province"
              rules={[{ required: true, message: "Please select a state!" }]}
            >
              <Select
                loading={stateSelectLoading}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                placeholder="Select State"
                onChange={setSelectedState}
                notFoundContent={<NoDataFoundIcon />}
              >
                {states.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="present_city"
              label="City"
              rules={[{ required: true, message: "Please select a city!" }]}
            >
              <Select
                loading={citySelectLoading}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                placeholder="Select City"
                notFoundContent={<NoDataFoundIcon />}
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="present_street"
              label="Street"
              rules={[{ required: true, message: "Please enter street!" }]}
            >
              <Input size="large" placeholder="Enter Street" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="present_postcode"
              label="Zip/Postal Code"
              rules={[{ required: true, message: "Please enter postal code!" }]}
            >
              <Input size="large" placeholder="Enter Zip/Postal Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="permanent-heading">
          <span className="permanent-address-heading">Permanent Address</span>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="permanent_country"
              label="Country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Select
                loading={countrySelectLoading}
                showSearch
                allowClear
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select Country"
                onChange={setselectedPermenantCountry}
                notFoundContent={<NoDataFoundIcon />}
              >
                {permenantCountries?.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="permanent_state"
              label="State/Province"
              rules={[{ required: true, message: "Please select a state!" }]}
            >
              <Select
                loading={stateSelectLoading}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                placeholder="Select State"
                onChange={setselectedPermanentState}
                notFoundContent={<NoDataFoundIcon />}
              >
                {permenantStates?.map((item: any) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="permanent_city"
              label="City"
              rules={[{ required: true, message: "Please select a city!" }]}
            >
              <Select
                loading={citySelectLoading}
                showSearch
                filterOption={(input: any, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                allowClear
                placeholder="Select City"
                notFoundContent={<NoDataFoundIcon />}
              >
                {permenantCities?.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="permanent_street"
              label="Street"
              rules={[{ required: true, message: "Please enter street!" }]}
            >
              <Input size="large" placeholder="Enter Street" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="permanent_postcode"
              label="Zip/Postal Code"
              rules={[{ required: true, message: "Please enter postal code!" }]}
            >
              <Input size="large" placeholder="Enter Zip/Postal Code" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="global-modal-bottom-row">
          <Col span={24} className="save-profile-btn">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateEmployeeAdress;
