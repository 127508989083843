import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Upload,
  Radio,
  Select,
  DatePicker,
  Modal,
} from "antd";
import "./index.css";
import d_dp from "../../../assets/images/blankProfile.png";
import CustomAlert from "../../../common/Alert";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { Country } from "country-state-city";
import dayjs from "dayjs";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import { TriangleLoader } from "../../../common/ReactLoader";
import {
  fetchEmployeesApi,
  updateEmployeePersonlDetailsApi,
  uploadEmployeePictureApi,
} from "./services";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

interface props {
  employeeId: any;
}
const UserPersonalInfoUpdate: React.FC<props> = ({ employeeId }) => {
  const loggedInemployeed = useSelector(selectLogedEmployee);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const employesres = useRef<any>();
  const [employescounter, setEmployesCounter] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const RadioGroup = Radio.Group;
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [initialSelectedFile, setInitialSelectedFile] = useState<
    string | null | undefined
  >(null);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(true);

  const [refCounter, setRefCounter] = useState<number>(0);
  const [DOB, setDOB] = useState<string | string[]>();
  const organizationId =
    loggedInemployeed?.logedInemployee?.employee?.organization_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Profile Information"
  );

  const read = access?.readAccess;
  const edit = access?.writeAccess;

  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchEmployee = async () => {
    setLoadingState(true);
    const employes_res = await fetchEmployeesApi(organizationId, employeeId);
    if (employes_res.data) {
      setLoadingState(false);
      setPreviewImage(employes_res.data?.avatar_url);
      setInitialSelectedFile(employes_res?.data.avatar_url);
      employesres.current = employes_res?.data;
      setEmployesCounter(employescounter + 1);

      form.setFieldsValue({
        first_name: employes_res?.data?.first_name || "",
        sur_name: employes_res?.data?.last_name || "",
        nationality: employes_res?.data?.nationality || "",
        cnic: employes_res?.data?.cnic || "",
        DOB: employes_res?.data?.dob
          ? dayjs(employes_res?.data?.dob, "YYYY-MM-DD")
          : "",
        maritalStatus: employes_res?.data?.marital_status || "",
        accessRightGroupName: employes_res?.data?.access_right_group || "",
        accessRightSubGroupName:
          employes_res?.data?.access_right?.sub_group_name || "",

        gender: employes_res?.data?.gender || "",
      });

      setRefCounter(refCounter + 1);
    }
  };

  const [form] = useForm();

  const onFinish = async (values: any) => {
    setButtonLoading(true);
    setshowAlert(false);
    const fileRes = await uploadImage(selectedFile);

    const body = {
      gender: values.gender,
      dob: DOB,
      nationality: values.nationality,
      avatar_url: fileRes?.data?.publicUrl,
      marital_status: values.maritalStatus,
      first_name: values.first_name,
      last_name: values.sur_name,
    };

    const res = await updateEmployeePersonlDetailsApi(
      body,
      employeeId,
      organizationId
    );

    if (res.error) {
      setshowAlert(true);
      setButtonLoading(false);

      setAlertType("error");
    } else if (res.status == 201 || res.status == 204) {
      setshowAlert(true);
      setButtonLoading(false);

      setAlertType("success");
    }
    setButtonLoading(false);
  };

  const uploadImage = async (file: File | null) => {
    try {
      if (file) {
        const fileExt = file.name.split(".").pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;
        const res = await uploadEmployeePictureApi(filePath, file);
        return res;
      }
    } catch (error) {
      return null;
    }
  };

  const handleFileChange = (file: File) => {
    setShowUploadButton(false);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {read && (
        <>
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            key={employescounter}
          >
            <Row>
              <Col xs={15} sm={15} md={20} lg={20} xl={21} xxl={22}>
                <Upload
                  name="orglogo"
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                  maxCount={1}
                  multiple={false}
                  beforeUpload={(file) => {
                    handleFileChange(file);
                    return false;
                  }}
                  onRemove={() => {
                    setPreviewImage(initialSelectedFile);
                    setSelectedFile(null);
                    setShowUploadButton(true);
                  }}
                  onPreview={() => setPreviewOpen(true)}
                  defaultFileList={[
                    {
                      uid: "-1",
                      name: "test.png",
                      status: "done",
                      url: initialSelectedFile || d_dp,
                    },
                  ]}
                >
                  {showUploadButton && (
                    <button
                      className="user-profile-upload-button"
                      type="button"
                    >
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </button>
                  )}
                </Upload>
              </Col>
            </Row>
            <div className="org-form-fields">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="First Name" name="first_name">
                    <Input
                      size="large"
                      placeholder="Enter your first name..."
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name" name="sur_name">
                    <Input size="large" placeholder="Enter your last name..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Nationality" name="nationality">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select a country..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {Country.getAllCountries().map((country) => (
                        <Select.Option key={country.name} value={country.name}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="CNIC/ID" name="cnic">
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={9}>
                  <Form.Item
                    label="Date of Birth"
                    name="DOB"
                    rules={[
                      { required: true, message: "Please enter Date of Birth" },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      onChange={(d: any, ds: string | string[]) => {
                        setDOB(ds);
                      }}
                      disabledDate={(current) => {
                        const fifteenYearsAgo = moment().subtract(15, "years");
                        return current && current > fifteenYearsAgo;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please Select Gender" },
                    ]}
                  >
                    <RadioGroup
                      options={[
                        { label: "Female", value: "female" },
                        { label: "Male", value: "male" },
                        { label: "Non Binary", value: "nonbinary" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="Marital Status" name="maritalStatus">
                    <Select
                      allowClear
                      showSearch
                      placeholder="Select your marital status..."
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      <Select.Option value="single">Single</Select.Option>
                      <Select.Option value="married">Married</Select.Option>
                      <Select.Option value="widowed">Widowed</Select.Option>
                      <Select.Option value="divorced">Divorced</Select.Option>
                      <Select.Option value="separated">
                        Separated{" "}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Access Right's Group"
                    name="accessRightGroupName"
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Access Right's Sub-Group"
                    name="accessRightSubGroupName"
                  >
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24} className="personal-data-update-row">
                  <Form.Item className="submit-button">
                    {edit && (
                      <Button
                        className="global-btn-width send-invitation-btn"
                        type="primary"
                        htmlType="submit"
                        loading={buttonLoading}
                      >
                        Save
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
          {loadingState && <TriangleLoader />}
          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
          <Modal
            open={previewOpen}
            title={"Image Preview"}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img
              src={previewImage || d_dp}
              className="user-update-avatar-image"
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default UserPersonalInfoUpdate;
