import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Empty, Modal, Popconfirm, Space } from "antd";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchAllowanceApi } from "../../services";
import CustomTable from "../../../../common/CustomTable";
import PayrollAllowanceEdit from "../Edit";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import CustomAlert from "../../../../common/Alert";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";

interface PayrollAllowanceListProps {
  searchValue?: string;
}

const PayrollAllowanceList: React.FC<PayrollAllowanceListProps> = ({
  searchValue,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const [loading, setLoading] = useState<boolean>(true);
  const [tabledata, setTableData] = useState<any>([]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [showTypedModel, setShowTypedModel] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(accessObj, "Payroll", "Allowances");
  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchTableData = async () => {
    setSearchQuery("");
    setLoading(true);
    const data = {
      organizationId: organizationId,
      companyId: loggedInEmployee?.employee?.company_id,
      branchId: loggedInEmployee?.employee?.branch_id,
      searchQuery: searchQuery,
    };

    const res = await fetchAllowanceApi(data);

    setLoading(false);
    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
  };

  const handelCancel = () => {
    setSearchQuery("");
    setSelectedRecordId("");
    setShowTypedModel(false);
    fetchTableData();
  };

  const handleDelete = async (id: any) => {
    try {
      const { error } = await supabase_client_for_Org_schema(organizationId)
        .from("allowance")
        .delete()
        .eq("id", id);
      if (error) {
        setshowAlert(true);
        setAlertType("error");
      } else {
        setshowAlert(true);
        setAlertType("success");
        fetchTableData();
      }
    } catch (error) {}
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.allowanceName || "";
        const nameB = b.allowanceName || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (record: any) => <div>{record?.name || ""}</div>,
    },
    {
      title: "branch",
      dataIndex: "branch",
      key: "branch",
      render: (record: any) => <div>{record?.name || ""}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a
              onClick={() => {
                setSelectedRecordId(record.id);

                setShowTypedModel(true);
              }}
            >
              <EditOutlined />
            </a>
          )}

          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this Allowance?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <a>
                <DeleteOutlined />
              </a>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: any) => {
    setLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  return (
    <>
      {read ? (
        <CustomTable
          loading={loading}
          dataSource={tabledata}
          columns={tableColumns}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}

      {selectedRecordId && (
        <Modal
          open={showTypedModel}
          onCancel={handelCancel}
          width={1000}
          title={<span className="global-modal-title">Edit Details</span>}
          className="global-modal-main-container"
          footer={false}
        >
          <PayrollAllowanceEdit
            recordid={selectedRecordId}
            oncancel={handelCancel}
          />
        </Modal>
      )}

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default PayrollAllowanceList;
