import React, { useEffect, useState } from "react";
import "./index.css";
import { Card, Empty, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectAccess } from "../../../redux/AccessRight";
import TabsHeader from "../../common/TabsHeader";
import { selectLogedEmployee } from "../../../redux/EmployeeProfileSlice";
import ViewAccessRights from "../OrganisationManagement/AccessRights/ViewAccessRights";
import OrganisationUpdate from "../OrganisationManagement/OrganisationUpdate";
import CreateAccessRights from "../OrganisationManagement/AccessRights/CreateAccessRights";
import Help from "../../common/Help/Layout";
import ViewMachineIntergration from "./AttendanceMachineIntegration/View";
import { extractAccessRightObject } from "../../common/ExtractAccessRightObj";
import ReportingDashboard from "../../common/ComplainView/ReportingDashboard";
import SoftwareIntegerations from "./SoftwareIntegerations";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../redux/MenuBreadcrumbSlice";
import PrimaryProfileSetting from "./PrimaryProfileSetting";

var radioOptions = [
  {
    label: "Access Rights",
    value: "Access Rights",
    create: false,
    filter: false,
    showSearch: false,
  },
  {
    label: "Organization",
    value: "Organization",
    create: false,
    filter: false,
    showSearch: false,
  },
  {
    label: "Attendance Machine",
    value: "attendance machine integration",
    create: false,
    filter: false,
    showSearch: false,
  },

  // {
  //     label: "Feature Request",
  //     value: "feature request",
  //     create: false,
  //     filter: false,
  //     showSearch: false,
  // },
  {
    label: "Integration",
    value: "integration",
    create: false,
    filter: false,
    showSearch: false,
  },
  {
    label: "Switch Profile",
    value: "Switch profile",
    create: false,
    filter: false,
    showSearch: false,
  },
  {
    label: "Help and Support",
    value: "Help and Support",
    create: false,
    filter: false,
    showSearch: false,
  },
  {
    label: "Reporting",
    value: "complaint",
    create: false,
    filter: false,
    showSearch: false,
  },
];

const SystemSetting: React.FC = () => {
  const accessObj = useSelector(selectAccess);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [readAccess, setReadAccess] = useState<boolean>(true);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const [selected, setSelected] = useState<string>("");
  const [filterOption, setFilterOption] = useState<any[]>(radioOptions);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const dispatch = useDispatch();
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  useEffect(() => {
    if (
      (loggedInEmployee?.employee?.company_id ||
        loggedInEmployee?.employee?.branch_id) &&
      loggedInEmployee?.employeeRole != "employee"
    ) {
      const temp_filtered_data = radioOptions?.filter(
        (item) => item.value !== "Organization"
      );
      setFilterOption(temp_filtered_data);
    }
    if (loggedInEmployee?.employeeRole == "employee") {
      const temp_filtered_data = radioOptions?.filter(
        (item) =>
          item.value == "Help and Support" ||
          item.value == "Switch profile" ||
          item.value == "complaint"
      );
      setFilterOption(temp_filtered_data);
      setSelected("Help and Support");
    }
  }, [
    loggedInEmployee?.employee?.company_id,
    loggedInEmployee?.employee?.branch_id,
  ]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "settings"
      );
      if (filteredChild?.child) {
        setSelected(filteredChild?.child);
      }
    } else {
      setSelected(radioOptions[0]?.value);
    }
  }, [currentSelectedTab]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "settings",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "System Settings";
    let childValue =
      value == "Access Rights"
        ? "Access Rights"
        : value == "Organization"
        ? "Organization Update"
        : value == "attendance machine integration"
        ? "Attendance Machine"
        : "Help & Support";

    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };

  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          <TabsHeader
            radioOptions={filterOption}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("");
            }}
            searchValue={searchValue}
            onCreateClick={handleCreateClick}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={(e) => handelseachChanges(e)}
            createAccess={createAccess}
            showFullwidthHeader={true}
            selected={selected}
          />
          <div key={fetchTableData} className="">
            {readAccess ? (
              selected === "Access Rights" ? (
                <ViewAccessRights />
              ) : selected === "Organization" ? (
                <OrganisationUpdate />
              ) : selected === "attendance machine integration" ? (
                <ViewMachineIntergration />
              ) : selected === "complaint" ? (
                <ReportingDashboard />
              ) : selected === "feature request" ? (
                <>this feature is under development</>
              ) : selected === "integration" ? (
                <SoftwareIntegerations />
              ) : selected === "Switch profile" ? (
                <PrimaryProfileSetting />
              ) : selected === "Help and Support" ? (
                <Help />
              ) : null
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <Modal
            title={
              <span className="global-modal-title">Create {selected}</span>
            }
            open={createModalVisible}
            onCancel={handleCreateModalCancel}
            width={1000}
            footer={false}
            className="global-modal-main-container"
          >
            {createModalVisible && selected === "Access Rights" && (
              <CreateAccessRights />
            )}
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default SystemSetting;
