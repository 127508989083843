import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Select, Typography } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "./index.css";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { getEmployeeKPIHistoryForGraph } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Text } = Typography;

const PerformanceMetrics: React.FC = () => {
  const [employeePerformance, setEmployeePerformance] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const parsedDate = moment(selectedYear).format("YYYY");
  const integarDate = parseInt(parsedDate);
  const employeeId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const formattedData = {
    employeeId: employeeId,
    selectedYear: integarDate,
  };

  const getResponse = async () => {
    setEmployeePerformance([]);
    const response = await getEmployeeKPIHistoryForGraph(
      formattedData,
      organizationId
    );

    if (response?.data) {
      setEmployeePerformance(response?.data);
    }
  };

  useEffect(() => {
    getResponse();
  }, [employeeId, selectedYear]);

  const chartData = employeePerformance?.map((entry) => {
    const formattedDate = moment(entry.created_at).format("MMMM, YYYY");

    return {
      month: formattedDate,
      aggregate: entry.aggregate,
    };
  });

  const yearsList = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2022; year <= currentYear; year++) {
    yearsList.push(year.toString());
  }

  return (
    <>
      <Card
        className="area-chart-card dasboard-cards dasboard-card-sizes"
        title={
          <>
            <Row gutter={[10, 10]} className="year-selector-btn">
              <Col span={20}>
                <p>Monthly KPI Aggregate</p>
              </Col>
              <Col span={4}>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={setSelectedYear}
                  placeholder="Select a year..."
                  className="select-field"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {yearsList.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        }
        bordered={true}
      >
        <ResponsiveContainer width="100%" height={300}>
          {chartData.length === 0 ? (
            <div className="performance-metrices-nodata">
              <NoDataFoundIcon />
            </div>
          ) : (
            <BarChart
              data={chartData}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="aggregate" fill="#8884d8" barSize={20} />
            </BarChart>
          )}
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export default PerformanceMetrics;
