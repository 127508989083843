import {
  EyeOutlined,
  FolderOpenOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Tour,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLogedEmployee,
  setlogedInemployee,
} from "../../../../../redux/EmployeeProfileSlice";
import { useNavigate } from "react-router-dom";
import JobPreview from "../../JobPosting/JobPreview";
import CustomAlert from "../../../../common/Alert";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { ProgressLoader } from "../../../../common/ReactLoader";
import EditJobDetails from "../../JobPosting/Edit";
import "./index.css";
import { TourProps } from "antd/lib";
import { fetchOrgDomain, getTheData } from "../../services";
import { JobDescription } from "../../../../common/TextTruncating/textTruncating";
import { updatedEmployeeTourStatusApi } from "../../../../services/Global";
import CreateJobSteps from "../../CreateJobJSX/steps";


const JobPostCard = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const dispatch = useDispatch();
  const organizationId = useSelector(selectLogedEmployee);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [noJobPosts, setNoJobPosts] = useState(false);
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [jobId, setJobId] = useState<any>(null);
  const [isJobModelOpen, setIsJobModelOpen] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);
  const [orgSubDomain, setOrgSubDomain] = useState<any>(null)


  const ref0 = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLSpanElement>(null);
  const ref2 = useRef<HTMLSpanElement>(null);
  const ref3 = useRef<HTMLSpanElement>(null);
  const ref4 = useRef<HTMLSpanElement>(null);
  const ref5 = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    fetchData();
    getOrgDomain()
    setOpen(!loggedInEmployee?.tour?.recruitment_tour || false);
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchData = async () => {
    setIsLoading(true);
    setShowLoader(true);
    const response = await getTheData(
      organizationId.logedInemployee?.employee.organization_id,
      organizationId.logedInemployee?.employee.company_id,
      organizationId.logedInemployee?.employee.branch_id
    );
    if (response.data) {
      setData(response.data);
      setNoJobPosts(response.data.length === 0 ? true : false);
      setIsLoading(false);
      setShowLoader(false);
    } else {
      setIsLoading(false);

      setShowLoader(false);
    }
  };

  const getOrgDomain =async ()=>{
    const response = await fetchOrgDomain(organizationId.logedInemployee?.employee.organization_id)
    if(response.data){
      setOrgSubDomain(response.data?.sub_domain)

    }
  }

  const navigate = useNavigate();
  const organizationName =
    useSelector(selectLogedEmployee).logedInemployee?.employee
      ?.organization_name;

  const navigateToJobApplication = (jobId: any) => {
    navigate(
      `/${loggedInEmployee?.employee?.access_right_group}/recruitment/applicationlist/${jobId}`
    );
  };

  const onCancel = () => {
    setIsJobModelOpen(false);
  };

  const previewModalCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const openJobPostLink = (id: any) => {
    if(orgSubDomain){

    
    const link = `https://${orgSubDomain}.misgenie.app/hrm/applicationsubmission/${orgId}/${id}`;
    window.open(link, "_blank");
    }
  };

  const orgId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  const handleCopyToClipBoard = (id: any) => {
    if(orgSubDomain){
    const link = `https://${orgSubDomain}.misgenie.app/hrm/applicationsubmission/${orgId}/${id}`;

    navigator.clipboard.writeText(link).then(() => {
      message.success("Link copied successfully!");
    });}
  };

  function truncateDescription(description: string, maxLength = 100) {
    if (!description) return "N/A";
    return description.length > maxLength
      ? `${description.substring(0, maxLength)}...`
      : description;
  }

  const steps: TourProps["steps"] = [
    {
      title: <span className="custom-tour-heading">Job Posts View</span>,
      description: (
        <div>
          <span className="custom-tour-description">
            {" "}
            In this section, all jobs that you posted will be displayed here.
          </span>
        </div>
      ),
      placement: "top",
      target: () => ref0.current!,
    },
    {
      title: <span className="custom-tour-heading">Delete Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to delete job post.
        </span>
      ),
      target: () => ref1.current!,
      placement: "top",
    },
    {
      title: <span className="custom-tour-heading">Edit Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to edit job post, and you will be able to
          update the details of job post.
        </span>
      ),
      target: () => ref2.current!,
      placement: "bottom",
    },
    {
      title: <span className="custom-tour-heading">View Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to view job post.
        </span>
      ),
      target: () => ref3.current!,
      placement: "top",
    },
    {
      title: <span className="custom-tour-heading">Copy Link of Job Post</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to copy the link of the post. You will be able
          to view the post in the new tab of the browser.
        </span>
      ),
      target: () => ref4.current!,
      placement: "bottom",
    },
    {
      title: <span className="custom-tour-heading">CV Bank</span>,
      description: (
        <span className="custom-tour-description">
          This icon will help you to navigate to CV Bank where all the
          applications will be displayed against this job post.
        </span>
      ),
      target: () => ref5.current!,
      placement: "top",
    },
  ];

  const TourClose = () => {
    setOpen(false);
    const toureObject = loggedInEmployee?.tour;
    if (toureObject) {
      const updatedToureObject = {
        ...toureObject,
        recruitment_tour: true,
      };
      dispatch(
        setlogedInemployee({
          logedInemployee: {
            tour: updatedToureObject,
          },
        })
      );
      updatedEmployeeTourStatusApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employeeId,
        "recruitment_tour"
      );
    }
  };

  const handleCreateClick = () => {
    setCreateJobModalOpen(true);
  };

  const handleCreateModalCancel = () => {
    setCreateJobModalOpen(false);
    setNoJobPosts(false);
  };

  return (
    <>
      <div className="job-dashboard-policy-content">
        {data && (
          <Tour
            open={open}
            onClose={() => TourClose()}
            steps={steps}
            indicatorsRender={(current, total) => (
              <span>
                {current + 1} / {total}
              </span>
            )}
          />
        )}
        <Card
          title={
            <span className="custom-tour-heading" ref={ref0}>
              Job Posts
            </span>
          }
        >
          {showLoader ? (
            <ProgressLoader />
          ) : data.length > 0 ? (
            <div className="job-posts-card-container ">
              <Row gutter={[16, 16]}>
                {data?.map((job: any, key) => (
                  <Col
                    span={24}
                    lg={12}
                    className="dashbaord-job-card-container"
                  >
                    <Card
                      size="small"
                      hoverable
                      bordered={false}
                      className="dashbaord-job-card"
                    >
                      <Row className="job-card-inner-row">
                        <Col span={24} md={12}>
                          <span className="job-dashboard-card-heading">
                            {job.basic_details?.jobTitle || "N/A"}
                          </span>
                        </Col>
                        <Col
                          span={24}
                          md={12}
                          className="job-card-data-actions-container"
                        >
                          <Space size="middle">
                            <EyeOutlined
                              ref={key == 0 ? ref3 : null}
                              onClick={() => openJobPostLink(job.id)}
                            />
                            <Tooltip title="Click to copy job link.">
                              <LinkOutlined
                                ref={key == 0 ? ref4 : null}
                                onClick={() => handleCopyToClipBoard(job.id)}
                              />
                            </Tooltip>
                            <Tooltip title="Click to see applications against this job.">
                              <FolderOpenOutlined
                                ref={key == 0 ? ref5 : null}
                                onClick={() => navigateToJobApplication(job.id)}
                              />
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>
                      <Row className="card-data-row-gap" gutter={[10, 10]}>
                        <Col span={24}>
                          <JobDescription
                            description={truncateDescription(
                              job.basic_details?.jobDescription
                            )}
                          />
                        </Col>
                        <Col span={24} className="job-card-data-tag-container">
                          <Tag className="job-card-data-tag">
                            {job.basic_details?.jobType.join(" | ") || "N/A"}
                          </Tag>
                        </Col>
                        <Col span={24}>
                          <div className="job-card-data-location-container">
                            {job.basic_details?.jobLocation || "N/A"}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                height: "19rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NoDataFoundIcon />
            </div>
          )}
        </Card>
        <JobPreview
          id={jobId}
          visible={isPreviewModalOpen}
          onCancel={previewModalCancel}
        />
        <EditJobDetails
          id={jobId}
          visible={isJobModelOpen}
          onCancel={onCancel}
        />
        <Modal
          title={
            <span className="global-modal-title">No Jobs Created Yet</span>
          }
          open={noJobPosts}
          onCancel={handleCreateModalCancel}
          footer={[
            <Button
              type="primary"
              onClick={handleCreateClick}
            >
              Create Job Now
            </Button>,
          ]}
        >
          <p className="storefront-description">
            It looks like you haven't created any jobs yet. To view your
            recruitment dashboard and track candidate progress, start by
            creating a job posting. Once your jobs are set up, your dashboard
            will display all the key recruitment metrics you need.
          </p>
        </Modal>
        <Modal
          title={<span className="global-modal-title">Create Job Post</span>}
          open={createJobModalOpen}
          onCancel={handleCreateModalCancel}
          width={1000}
          footer={false}
          className="global-modal-main-container"
        >
          <CreateJobSteps onCancel={handleCreateModalCancel} />
        </Modal>
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default JobPostCard;
