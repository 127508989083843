import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import TabsHeader from "./../../common/TabsHeader";
import { Card, Empty, Modal } from "antd";
import PayrollAllowanceList from "./Allowance/List";
import PayrollGradeList from "./Grade/ListView";
import TaxationListView from "./Taxation/List";
import ViewSalaries from "./../EmployeesManagement/EmployeeData/Salaries/ListView";
import GenratePaySlip from "./GenratePays";

import SalaryProveConfigurationsListView from "./SalaryProveConfigurations/List";
import PayrollAllowanceCreate from "./Allowance/Create";
import PayrollGradeCreate from "./Grade/CreateView";
import TaxationCreateView from "./Taxation/Create";
import CreateSalaries from "./../EmployeesManagement/EmployeeData/Salaries/FormView";
import SalaryProveConfigurationsCreate from "./SalaryProveConfigurations/Create";
import { extractAccessRightObject } from "../../common/ExtractAccessRightObj";
import { selectAccess } from "../../../redux/AccessRight";
import { useDispatch, useSelector } from "react-redux";
import CreateAccountDetails from "./CreateAccount/Create";
import CompanyAccountList from "./CreateAccount/List";
import Dashboard from "./Dashboard";
import TaxRebates from "./TaxRebates";
import TaxRebatesCreate from "./TaxRebates/Create";
import CompanyPayroll from "./CompanyPayroll/index";
import ListSalarySheetTemplate from "./GenratePays/SalarySheetTemplates/ListView";
import CreateSalarySheetTemplate from "./GenratePays/SalarySheetTemplates/CreateView";
import { selectOrganizationModulePermission } from "../../../redux/organizationModulePermission";
import {
  selectMenuBreadcrumb,
  setMenuBreadcrumb,
} from "../../../redux/MenuBreadcrumbSlice";

const Payroll: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const accessObj = useSelector(selectAccess);
  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const [selected, setSelected] = useState("Dashboard");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [fetchTableData, setFetchTableData] = useState<number>(0);
  const dispatch = useDispatch();

  const [fetchModalData, setFetchModalData] = useState<number>(
    Math.random() + 1
  );

  const organizationPermission = useSelector(selectOrganizationModulePermission)
    ?.organizationModulePermission?.["Payroll"];
  const currentSelectedTab = useSelector(selectMenuBreadcrumb)?.menu_breadcrumb;

  const radioOptions = useMemo(
    () => [
      {
        label: "Dashboard",
        value: "Dashboard",
        create: false,
        filter: false,
        showSearch:  false,
      },
      {
        label: "Allowances",
        value: "Allowances",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Grades",
        value: "Grades",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Taxations",
        value: "Taxations",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Tax Rebates",
        value: "Tax Rebates",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Salaries",
        value: "Salaries",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Payroll",
        value: "Generate Pay",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Configurations",
        value: "Configurations",
        create: true,
        filter: false,
        showSearch: true,
      },
      {
        label: "Accounts",
        value: "Accounts",
        create: true,
        filter: false,
        showSearch: false,
      },
      {
        label: "Templates",
        value: "Templates",
        create: true,
        filter: false,
        showSearch: false,
      },
    ],
    []
  );

  const filteredRadioOptions = useMemo(() => {
    const filterOutRadioOptions = (childObjects: any, options: any) => {
      return options.filter((option: any) => {
        return (
          childObjects[option.label] && childObjects[option.label].readAccess
        );
      });
    };
    return filterOutRadioOptions(organizationPermission?.child, radioOptions);
  }, [organizationPermission, radioOptions]);

  useEffect(() => {
    if (currentSelectedTab) {
      const filteredChild = currentSelectedTab?.find(
        (item) => item.parent === "payroll"
      );
      if (
        filteredChild?.child &&
        filteredRadioOptions.some(
          (option: any) => option.value === filteredChild?.child
        )
      ) {
        setSelected(filteredChild?.child);
      } else {
        setSelected(filteredRadioOptions[0]?.value);
      }
    } else {
      setSelected(filteredRadioOptions[0]?.value);
    }
    setFetchTableData(Math.random() + 1);
    setFetchModalData(Math.random() + 1);
  }, [filteredRadioOptions]);

  useEffect(() => {
    handleRadioChange(selected);
    const newMenuItem = {
      parent: "payroll",
      child: selected,
    };

    dispatch(
      setMenuBreadcrumb({
        menu_breadcrumb: [newMenuItem],
      })
    );
  }, [selected, accessObj]);

  const handleRadioChange = (value: string) => {
    const parentMenu = "Payroll";
    let childValue =
      value == "Dashboard"
        ? "Dashboard"
        : value == "Allowances"
        ? "Allowances"
        : value == "Grades"
        ? "Grades"
        : value == "Taxations"
        ? "Taxation"
        : value == "Tax Rebates"
        ? "Tax Rebates"
        : value == "Salaries"
        ? "Salaries"
        : value == "Generate Pay"
        ? "Generate Pay"
        : value == "Configurations"
        ? "Payroll Configuration"
        : value == "Accounts"
        ? "Accounts"
        : "Templates";
    const access = extractAccessRightObject(accessObj, parentMenu, childValue);
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  const handleCreateClick = () => {
    setCreateModalVisible(true);
  };

  const handleToggleFilterModal = () => {
    setFilterModalVisible(!filterModalVisible);
  };

  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    setFilterModalVisible(false);
    setFetchTableData(fetchTableData + 1);
  };
  const handelseachChanges = (input: string) => {
    setSearchValue(input);
  };
  return (
    <>
      <div className="body-content-card-container">
        <Card className="body-content-card-inner-container">
          {/* <PayrollTour /> */}
          <TabsHeader
            radioOptions={filteredRadioOptions}
            onChange={(value) => {
              setSelected(value);
              setSearchValue("")
            }}
            onCreateClick={handleCreateClick}
            searchValue={searchValue}
            onFilterClick={handleToggleFilterModal}
            onSearchChange={handelseachChanges}
            createAccess={createAccess}
            showFullwidthHeader
            selected={selected}
          />
          <div key={fetchTableData}>
            {readAccess ? (
              selected === "Dashboard" ? (
                <Dashboard searchValue={searchValue} />
              ) : selected === "Allowances" ? (
                <PayrollAllowanceList searchValue={searchValue} />
              ) : selected === "Grades" ? (
                <PayrollGradeList searchValue={searchValue} />
              ) : selected === "Taxations" ? (
                <TaxationListView searchValue={searchValue} />
              ) : selected === "Tax Rebates" ? (
                <TaxRebates searchValue={searchValue} />
              ) : selected === "Salaries" ? (
                <ViewSalaries searchValue={searchValue} />
              ) : selected === "Generate Pay" ? (
                <GenratePaySlip searchValue={searchValue} />
              ) : selected === "Templates" ? (
                <ListSalarySheetTemplate />
              ) : selected === "Configurations" ? (
                <SalaryProveConfigurationsListView
                  searchValue={searchValue}
                  openFilterModal={filterModalVisible}
                  toggleFilterModal={handleToggleFilterModal}
                />
              ) : (
                <CompanyAccountList />
              )
            ) : (
              <Empty
                description="Not Authorized For This Action"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
              />
            )}
          </div>
          <div key={fetchModalData}>
            <Modal
              title={
                <span className="global-modal-title">
                  {selected === "Generate Pay"
                    ? "Payroll"
                    : `Create ${selected}`}
                </span>
              }
              open={createModalVisible}
              onCancel={handleCreateModalCancel}
              width={1000}
              footer={false}
              className="global-modal-main-container"
              destroyOnClose
            >
              {createModalVisible && selected === "Generate Pay" && (
                <CompanyPayroll onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Allowances" && (
                <PayrollAllowanceCreate onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Grades" && (
                <PayrollGradeCreate onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Taxations" && (
                <TaxationCreateView />
              )}
              {createModalVisible && selected === "Tax Rebates" && (
                <TaxRebatesCreate onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Salaries" && (
                <CreateSalaries onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Configurations" && (
                <SalaryProveConfigurationsCreate />
              )}
              {createModalVisible && selected === "Accounts" && (
                <CreateAccountDetails onCancel={handleCreateModalCancel} />
              )}
              {createModalVisible && selected === "Templates" && (
                <CreateSalarySheetTemplate onCancel={handleCreateModalCancel} />
              )}
            </Modal>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Payroll;
