import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Inserts education data into the "Education" table in the Supabase database.
 * @param {any} data - The education data to be inserted.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertEducationApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_qualification")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
