import React, { useState, useEffect } from "react";
import { Row, Col, Descriptions, Divider, Modal } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
import { fetchEarlyLeftAbsentPolicies } from "../services";
import { TriangleLoader } from "../../../../common/ReactLoader";

const AbsentEmployeeView: React.FC<any> = ({ propsData }) => {
  const [earlyLeftPolicy, setEarlyLeftPolicy] = useState<any[]>([]);
  useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const role = useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  const fetchEarlyLeftPolicies = async () => {
    if (employeeRole !== "employee") {
      const propDataArray = [propsData];
      setEarlyLeftPolicy(propDataArray);
    } else {
      let matchCondition =
        role?.toLowerCase() === "employee" ? "employee" : "hr";

      const organizationId = loggedInEmployee?.employee?.organization_id;

      const companyId = loggedInEmployee?.employee?.company_id;
      const branchId = loggedInEmployee?.employee?.branch_id;

      const { data, error } = await fetchEarlyLeftAbsentPolicies(
        organizationId,
        companyId,
        matchCondition,
        branchId
      );

      if (!error) {
        setEarlyLeftPolicy(data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEarlyLeftPolicies();
  }, []);

  const generateItemsArray = (policy: any): any[] => {
    return [
      {
        key: "1",
        label: (
          <span className="policy-description-title">
            Absents Allowed in a Month
          </span>
        ),
        span: 3,
        children: (
          <span className="description">
            The policy on absents allowed in a month specifies the maximum
            number of days an employee can be absent from work within a single
            month. As per this policy, employees are permitted to have up to{" "}
            <strong>{policy?.allowed_per_month}</strong> absences per month.
          </span>
        ),
      },
      {
        key: "2",
        span: 3,
        label: (
          <span className="policy-description-title policy-descriptions">
            Consecutive Absents Allowed
          </span>
        ),
        children: (
          <span className="description">
            The policy on consecutive absents allowed defines the maximum number
            of consecutive days an employee can be absent from work without
            repercussions. According to this policy, employees are allowed to
            have up to <strong>{policy?.max_consecutive}</strong> consecutive
            absences.
          </span>
        ),
      },
      {
        key: "3",
        span: 3,
        label: <span className="policy-description-title">Description</span>,
        children: (
          <span className="description">
            The policy description provides additional context and details
            regarding the overall absent policy. This includes any specific
            guidelines, expectations, or consequences related to absences from
            work. <br /> {policy?.description}
          </span>
        ),
      },
    ];
  };

  return (
    <div className="hr-absent-policy-view-container">
      <div className="hr-absent-policy-view-content">
        <Row justify="center">
          <Col span={24}>
            {earlyLeftPolicy.length < 1 && (
              <div className="no-data-found-policy">
                <NoDataFoundIcon />
              </div>
            )}
            {earlyLeftPolicy?.map((policy) => (
              <>
                <Row>
                  <Col span={24}>
                    <p>
                      Our absenteeism policy aims to provide guidelines and
                      expectations regarding employee attendance while balancing
                      the need for flexibility and understanding individual
                      circumstances. Key aspects of the policy include:
                      <br />
                    </p>
                  </Col>
                </Row>
                <Descriptions
                  className="absent-policy-descriptions"
                  key={policy.id}
                  layout="horizontal"
                  bordered
                  items={generateItemsArray(policy)}
                />
              </>
            ))}
          </Col>
        </Row>
      </div>
      {loading && <TriangleLoader />}
    </div>
  );
};

export default AbsentEmployeeView;
