import React, { useEffect, useRef, useState } from "react";
import "../index.css";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  DatePicker,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomAlert from "../../../../common/Alert";
import {
  allorgCompanyBranchesApi,
  fetcAllEmployeeApi,
} from "../../../../services/Global";
import { allorgCompanyApi } from "../../../../services/Global";
import { TaxRebatsfileinsertApi, createTaxRebateApi } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface TaxRebatesCreateprops {
  onCancel: () => void;
}
const TaxRebatesCreate: React.FC<TaxRebatesCreateprops> = (props) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const comapnyId = loggedInEmployee?.employee?.company_id;
  const branchId = loggedInEmployee?.employee?.branch_id;
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>();
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<any>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [filteredEmployees, setFilteredEmployees] = useState<any[]>([]);
  const [alertDescription, setAlertDescription] = useState<string>("");
  useEffect(() => {
    if (loggedInEmployee?.employee.company_id) {
      setSelectedCompanyId(loggedInEmployee?.employee.company_id);
      form.setFieldValue("companyId", loggedInEmployee?.employee.company_id);
    }
    if (loggedInEmployee?.employee.branch_id) {
      setSelectedBranchId(loggedInEmployee?.employee.branch_id);
      form.setFieldValue("branchId", loggedInEmployee?.employee.branch_id);
    }
    fetchCompanies();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!(branchId || comapnyId)) {
      fetchBranches();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    fetchEmployees();
  }, [selectedCompanyId, selectedBranchId]);

  const fetchCompanies = async () => {
    const resCompanies = await allorgCompanyApi(organizationId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
      form.setFieldValue("companyId", resCompanies.data[0]?.id);
      setSelectedCompanyId(resCompanies.data[0]?.id);
    }
  };
  const fetchBranches = async () => {
    if (selectedCompanyId) {
      setSelectedBranchId("");
      form.setFieldValue("branchId", "");
      form.setFieldValue("employee", "");
      setFilteredEmployees([]);
      const resBranches = await allorgCompanyBranchesApi(
        organizationId,
        selectedCompanyId
      );
      if (resBranches?.data) {
        setFilteredBranches(resBranches.data);
        form.setFieldValue("branchId", resBranches.data[0]?.id);
        setSelectedBranchId(resBranches.data[0]?.id);
      }
    }
  };
  const fetchEmployees = async () => {
    form.setFieldValue("employee", "");
    setFilteredEmployees([]);
    const Employees_res = await fetcAllEmployeeApi(
      loggedInEmployee?.employee?.organization_id,
      selectedCompanyId,
      selectedBranchId
    );
    if (Employees_res?.data && Employees_res?.data?.length > 0) {
      setFilteredEmployees(Employees_res?.data);
    }
  };

  const onFinish = async (values: any) => {
    const url_res = await handleUpload();
    if (url_res && url_res.data) {
      values.url = url_res.data.publicUrl;
    } else {
      setshowAlert(true);
      setAlertType("error");
      setAlertDescription("invalid file");
      return;
    }

    const body = {
      organization_id: loggedInEmployee?.employee?.organization_id,
      company_id: selectedCompanyId || null,
      branch_id: selectedBranchId || null,
      employee_id: values.employee || null,
      date: values.date,
      amount: values.amount,
      file_url: values.url || "",
    };
    setButtonLoading(true);
    const res = await createTaxRebateApi(
      body,
      loggedInEmployee?.employee?.organization_id
    );
    if (res.status == 201 || res.status == 200) {
      setshowAlert(true);
      setAlertType("success");
      props.onCancel();
    } else {
      setshowAlert(true);
      setAlertType("error");
    }
    setButtonLoading(false);
  };
  const handleChange = (info: any) => {
    setFileList(info.fileList);
  };
  const handleUpload = async () => {
    if (fileList.length === 0) {
      message.error("Please select a file to upload.");
      return;
    }
    const file = fileList[0];
    const { name } = file;
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${name}`;
    const res = await TaxRebatsfileinsertApi(
      fileName,
      (file as any).originFileObj
    );
    return res;
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            {!comapnyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="companyId"
                  label="Select Company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Company",
                    },
                  ]}
                  initialValue={comapnyId}
                >
                  <Select
                    disabled={comapnyId}
                    onChange={(value) => setSelectedCompanyId(value)}
                    placeholder="Select a Company"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredCompanies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!(comapnyId || branchId) && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="branchId"
                  label="Select Branch (If any)"
                  initialValue={branchId}
                >
                  <Select
                    disabled={branchId}
                    placeholder="Select a Branch"
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={(value) => setSelectedBranchId(value)}
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {filteredBranches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="employee"
                label="Employee Name"
                rules={[
                  {
                    required: true,
                    message: "Please Select an Employee.",
                  },
                ]}
              >
                <Select
                  placeholder="Select an Employee..."
                  allowClear
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredEmployees.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please Select a date.",
                  },
                ]}
              >
                <DatePicker className="payroll-tax-rebates-create-date" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter an amount.",
                  },
                ]}
              >
                <Input
                  placeholder="Enter amount..."
                  size="large"
                  className="payroll-tax-rebates-create-date"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="attachment"
                label="Attachment"
                rules={[
                  {
                    required: true,
                    message: "Please upload an Attachment.",
                  },
                ]}
              >
                <Upload
                  accept=".png,.jpg,.jpeg,.zip,.pdf,.doc,.docx,.txt"
                  maxCount={1}
                  onChange={handleChange}
                >
                  <Button
                    className="global-btn-width"
                    icon={<UploadOutlined />}
                    type="default"
                  >
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            className="global-modal-bottom-row payrollgrads-form-container-last-row"
          >
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                size="large"
              >
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          showIcon={true}
          autoCloseDuration={3000}
          description={alertDescription}
        />
      )}
    </>
  );
};

export default TaxRebatesCreate;
