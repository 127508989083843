import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import CustomAlert from "../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import {
  determineWetherItsAHoliday,
  handleBreakInLogic,
  handleBreakOutLogic,
  handleCheckInComputations,
  handleCheckoutComputations,
} from "./viewmodal";
import {
  checkIn,
  checkOut,
  fetchTodayCheckInApi,
  getAllData,
  getTheWorkedHours,
  getTodaysCheckoutResponse,
  insertAttendancePunchApi,
} from "./services";
import moment from "moment";
import { AiOutlineFieldTime } from "react-icons/ai";
const MyCheckinCheckoutOnDashBoard = () => {
  const [disableCheckIn, setDisableCheckIn] = useState<boolean>(false);
  const [disableCheckOut, setDisableCheckOut] = useState<boolean>(false);
  const [disableBreakIn, setDisableBreakIn] = useState<boolean>(false);
  const [disableBreakOut, setDisableBreakOut] = useState<boolean>(false);
  const [refCounter, setRefCounter] = useState<number>(0);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [timeZone, setTimeZone] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const [checkInTime, setCheckInTime] = useState<any>(null);
  const [checkOutTime, setCheckOutTime] = useState<any>(null);
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [checkinload, setCheckInLoad] = useState<boolean>(false);
  const [checkOutload, setCheckOutLoad] = useState<boolean>(false);
  const [breakInload, setBreakInLoad] = useState<boolean>(false);
  const [breakOutload, setBreakOutLoad] = useState<boolean>(false);
  const [checkOutResponse, setCheckOutResponse] = useState<string>("");
  const [earlyCheckInTime, setEarlyCheckInTime] = useState<any>(null);
  const [lateCheckInTime, setLateCheckInTime] = useState<any>(null);

  const [restrictBreakInBreakout, setRestrictBreakInBreakout] =
    useState<boolean>(false);

  const tz = timeZone || moment.tz.guess() || "Asia/Karachi";

  const Ids = {
    organizationId: loggedInEmployee?.employee?.organization_id,
    companyId: loggedInEmployee?.employee?.company_id,
    branchId: loggedInEmployee?.employee?.branch_id,
    departmentId: loggedInEmployee?.employee?.department_id,
    teamId: loggedInEmployee?.employee?.team_id,
    employeeId: loggedInEmployee?.employeeId,
  };

  useEffect(() => {
    fetchTodayRecord();
    getData();
    getEmployeeTodayCheckout();
  }, []);

  useEffect(() => {
    fetchTodayRecord();
  }, [refCounter]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchTodayRecord = async () => {
    const res = await fetchTodayCheckInApi(Ids.employeeId, Ids.organizationId);
    if (res.data && res.data.length > 0 && res.status == "200") {
      //If check in diable check In
      if (res.data[0].check_in) {
        setDisableCheckIn(true);
      }
      //If check Out diable check Out
      if (!res.data[0].check_in) {
        setDisableCheckOut(true);
      } else {
        setDisableCheckOut(false);
      }
      if (
        (res.data[0].check_in &&
          res.data[0].break_in &&
          !res.data[0].break_out) ||
        (res.data[0].check_in &&
          res.data[0].break_out &&
          res.data[0].break_in.length > res.data[0].break_out.length)
      ) {
        setDisableBreakIn(true);
      } else {
        setDisableBreakIn(false);
      }
      if (
        (res.data[0].check_in &&
          res.data[0]?.break_in &&
          !res.data[0]?.break_out) ||
        res.data[0]?.break_in?.length > res.data[0]?.break_out?.length
      ) {
        setDisableBreakOut(false);
      } else {
        setDisableBreakOut(true);
      }
    } else {
      setDisableBreakIn(true);
      setDisableBreakOut(true);
      setDisableCheckOut(true);
    }
  };

  const getData = async () => {
    const response = await getAllData(Ids.employeeId, Ids.organizationId);
    if (response) {
      setCheckInTime(response.data?.day_start_time);
      setCheckOutTime(response.data?.day_end_time);
      setEarlyCheckInTime(response.data?.early_checkin_time);
      setLateCheckInTime(response.data?.late_checkin_time);
      setTimeZone(response.data?.timezone);
      const holidayResponse = await determineWetherItsAHoliday(
        response.data?.working_days
      );
      if (holidayResponse) {
        setIsHoliday(!holidayResponse);
      }
    }
  };

  //Handles the check-in of an Employee
  const manageEmployeeCheckin = async () => {
    setAlertDescription("");
    setCheckInLoad(true);
    const currentTime = moment.tz(tz || moment.tz.guess() || "Asia/Karachi");
    if (
      earlyCheckInTime &&
      currentTime.isBefore(moment(earlyCheckInTime, "HH:mm:ss"))
    ) {
      setshowAlert(true);
      setAlertType("error");
      setAlertDescription(
        "You are not allowed to check in before the early check-in time."
      );
      setCheckInLoad(false);
      return;
    }

    if (
      lateCheckInTime &&
      currentTime.isAfter(moment(lateCheckInTime, "HH:mm:ss"))
    ) {
      setshowAlert(true);
      setAlertType("error");
      setAlertDescription(
        "You are not allowed to check in after the late check-in time."
      );
      setCheckInLoad(false);
      return;
    }

    // Existing logic for handling check-in
    const lastCheckOut = checkOutResponse;
    if (lastCheckOut) {
      const lastCheckoutMoment = moment.tz(lastCheckOut, tz);
      const hoursDifference = currentTime.diff(lastCheckoutMoment, "hours");

      if (hoursDifference < 12) {
        setshowAlert(true);
        setAlertType("error");
        setAlertDescription(
          `Check-in is not allowed until 12 hours have passed since the last checkout`
        );
        setCheckInLoad(false);
        return;
      }
    }

    const response = await handleCheckInComputations(
      loggedInEmployee?.employeeId,
      Ids.organizationId,
      Ids.companyId,
      checkInTime,
      timeZone,
      Ids.branchId
    );

    if (response) {
      const checkInCalculations = await checkIn(response, tz);

      if (checkInCalculations == false) {
        setshowAlert(true);
        setAlertType("info");
        setAlertDescription(
          "A CheckIn for today is already present. Please Checkout first."
        );
      } else if (checkInCalculations.data) {
        const punch_data = {
          organization_id: loggedInEmployee?.employee?.organization_id,
          company_id: loggedInEmployee?.employee?.company_id,
          branch_id: loggedInEmployee?.employee?.branch_id,
          from: "Portal",
          type: "check-in",
          time: moment
            .tz(tz || moment.tz.guess() || "Asia/Karachi")
            .format("HH:mm"),
          date: moment
            .tz(tz || moment.tz.guess() || "Asia/Karachi")
            .format("YYYY-MM-DD"),
          employee_id: loggedInEmployee?.employeeId,
        };
        const res = await insertAttendancePunchApi(punch_data);
        setRefCounter(refCounter + 1);
        setshowAlert(true);
        setAlertType("success");
      }
    }
    setCheckInLoad(false);
  };

  const getEmployeeTodayCheckout = async () => {
    setAlertDescription("");
    const response = await getTodaysCheckoutResponse(
      Ids.organizationId,
      Ids.employeeId,
      timeZone
    );
    if (response) {
      setCheckOutResponse(response);
      setRestrictBreakInBreakout(true);
    }
  };

  //Handles the checking-out of an Employee
  const manageEmployeeCheckOut = async () => {
    setAlertDescription("");
    setCheckOutLoad(true);
    const response = await handleCheckoutComputations(
      loggedInEmployee?.employeeId,
      tz,
      checkOutTime,
      Ids.organizationId
    );

    if (response && response != false) {
      const secondResponse = await checkOut(
        response,
        loggedInEmployee?.employeeId,
        Ids.organizationId
      );

      if (secondResponse.status == 201 || secondResponse.status == 204) {
        const punch_data = {
          organization_id: loggedInEmployee?.employee?.organization_id,
          company_id: loggedInEmployee?.employee?.company_id,
          branch_id: loggedInEmployee?.employee?.branch_id,
          from: "Portal",
          type: "check-out",
          time: moment
            .tz(tz || moment.tz.guess() || "Asia/Karachi")
            .format("HH:mm"),
          date: moment
            .tz(tz || moment.tz.guess() || "Asia/Karachi")
            .format("YYYY-MM-DD"),
          employee_id: loggedInEmployee?.employeeId,
        };
        await insertAttendancePunchApi(punch_data);
        setRefCounter(refCounter + 1);
        setshowAlert(true);
        setAlertDescription("Checkout added successfully!");
        setAlertType("success");
        getEmployeeTodayCheckout();
      }
    } else {
      setshowAlert(true);
      setAlertDescription("Please CheckIn First before adding a CheckOut");
      setAlertType("error");
    }
    setCheckOutLoad(false);
  };

  //Handles the breakin of user
  const manageBreakIn = async () => {
    setAlertDescription("");
    if (restrictBreakInBreakout) {
      setshowAlert(true);
      setAlertType("error");
      setAlertDescription(
        "Break-in is not allowed as you have already checked out for today."
      );
      return;
    }

    const response = await handleBreakInLogic(
      loggedInEmployee?.employeeId,
      tz,
      Ids.organizationId
    );

    if (response && response.status == 204) {
      const punch_data = {
        organization_id: loggedInEmployee?.employee?.organization_id,
        company_id: loggedInEmployee?.employee?.company_id,
        branch_id: loggedInEmployee?.employee?.branch_id,
        from: "Portal",
        type: "Break-in",
        time: moment
          .tz(tz || moment.tz.guess() || "Asia/Karachi")
          .format("HH:mm"),
        date: moment
          .tz(tz || moment.tz.guess() || "Asia/Karachi")
          .format("YYYY-MM-DD"),
        employee_id: loggedInEmployee?.employeeId,
      };
      await insertAttendancePunchApi(punch_data);

      setRefCounter(refCounter + 1);
      setshowAlert(true);
      setAlertType("success");
      setAlertDescription("BreakIn successfully");
    } else {
      setshowAlert(true);
      setAlertType("error");
      setAlertDescription(
        "Break-In was not added. Please Check-In or Break-Out first"
      );
    }
    setBreakInLoad(false);
  };

  const manageBreakOut = async () => {
    setAlertDescription("");
    setBreakOutLoad(true);
    const response = await handleBreakOutLogic(
      loggedInEmployee?.employeeId,
      tz,
      Ids.organizationId
    );
    if (response.status == 204) {
      setRefCounter(refCounter + 1);
      const punch_data = {
        organization_id: loggedInEmployee?.employee?.organization_id,
        company_id: loggedInEmployee?.employee?.company_id,
        branch_id: loggedInEmployee?.employee?.branch_id,
        from: "Portal",
        type: "Break-out",
        time: moment
          .tz(tz || moment.tz.guess() || "Asia/Karachi")
          .format("HH:mm"),
        date: moment
          .tz(tz || moment.tz.guess() || "Asia/Karachi")
          .format("YYYY-MM-DD"),
        employee_id: loggedInEmployee?.employeeId,
      };
      await insertAttendancePunchApi(punch_data);
      setshowAlert(true);
      setAlertType("success");
      setAlertDescription("Breakout successfully");
    } else {
      setAlertDescription(
        "Break-Out was not added. Please Break-In first"
      );
      setshowAlert(true);
      setAlertType("error");
    }
    setBreakOutLoad(false);
  };

  return (
    <>
      <Row gutter={[16, 16]} className="check-in-check-out-row">
        <Col span={12} md={6}>
          <Button
            className="check-in-btn "
            onClick={manageEmployeeCheckin}
            icon={<AiOutlineFieldTime size={20} className="checkin-icon" />}
            loading={checkinload}
            // disabled={disableCheckIn}
          >
            {"  "} Check In
          </Button>
        </Col>
        <Col span={12} md={6}>
          <Button
            className="check-out-btn"
            onClick={manageEmployeeCheckOut}
            icon={<AiOutlineFieldTime size={20} className="checkout-icon" />}
            loading={checkOutload}
            // disabled={disableCheckOut}
          >
            Check Out{" "}
          </Button>
        </Col>
        <Col span={12} md={6}>
          <Button
            className="break-in-btn"
            onClick={manageBreakIn}
            icon={<AiOutlineFieldTime size={20} />}
            loading={breakInload}
            // disabled={disableBreakIn}
          >
            Break In{" "}
          </Button>
        </Col>
        <Col span={12} md={6}>
          <Button
            className="break-out-btn"
            onClick={manageBreakOut}
            icon={<AiOutlineFieldTime size={20} className="breakout-icon" />}
            loading={breakOutload}
            // disabled={disableBreakOut}
          >
            Break Out{" "}
          </Button>
        </Col>
      </Row>

      {showAlert && (
        <CustomAlert
          showAlert={showAlert}
          type={alertType}
          description={alertDescription}
          showIcon={alertType == "success" ?  true :  false}
        />
      )}
    </>
  );
};

export default MyCheckinCheckoutOnDashBoard;
