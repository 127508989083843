import React, { useState, useEffect } from "react";
import "./index.css";
import { Modal, Empty } from "antd";
import { Popconfirm, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import EditAssets from "../Edit";
import { deleteAssetApi, getAllAssetsApi } from "../services";

import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import AssetCreationFilterModal from "../Filter";

interface AssetListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const AssetsList: React.FC<AssetListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const formattedData = {
    companyId: companyId,
    organizationId: organizationId,
    searchInput: searchInput,
    branchId: branchId,
  };

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Assets",
    "Asset Creation"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    getAllAssetData();
  }, []);
  const getAllAssetData = async () => {
    setIsModalVisible(false);
    setLoading(true);
    const response = await getAllAssetsApi(formattedData, organizationId);
    if (response.data) {
      setLoading(false);
      setTableData(response.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: response.data,
      }));
    } else {
      setLoading(false);
    }
  };

  const handleRecordDeletion = async (recordId: any) => {
    const response = await deleteAssetApi(recordId, organizationId);
    if (!response.error) {
      getAllAssetData();
      setshowAlert(true);
      setAlertType("success");
    }
  };

  const handleOpenModal = (record: any) => {
    setIsModalVisible(true);
    setSelectedRecord(record.id);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const tableColumns = [
    {
      title: "Asset Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Asset Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
    {
      title: "Identification Number",
      dataIndex: "unique_identifier",
      key: "unique_identifier",
    },

    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a onClick={() => handleOpenModal(record)}>
              <EditOutlined />
            </a>
          )}
          <Popconfirm
            placement="topLeft"
            title="Are you sure you want to delete this asset?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRecordDeletion(record.id)}
          >
            {deletee && <DeleteOutlined />}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setLoading(true);
    setSearchInput(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setLoading(false);
  };

  const getFilterDataHandler = (assetType: string) => {
    const filterArray = [{ path: "type", value: assetType }];
    let response: any;
    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableData(response);
    }
  };

  return (
    <>
      <div className="asset-creation-main-container">
        <div className="assets-table">
          {read ? (
            <CustomTable
              dataSource={tableData}
              columns={tableColumns}
              loading={loading}
              onChange={getAllAssetData}
            />
          ) : (
            <Empty
              description="Not Authorized For This Action"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
            />
          )}
        </div>

        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}

        <Modal
          className="global-modal-main-container"
          title={<span className="global-modal-title">Filter</span>}
          open={openFilterModal}
          footer={false}
          onCancel={toggleFilterModal}
        >
          <AssetCreationFilterModal
            getFilterDataHandler={(assetType) =>
              getFilterDataHandler(assetType)
            }
          />
        </Modal>
      </div>

      <EditAssets
        visible={isModalVisible}
        onCancel={handleModalClose}
        recordId={selectedRecord}
        getAllAssetData={getAllAssetData}
      />
    </>
  );
};

export default AssetsList;
