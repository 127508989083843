import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
/**
 * Fetches departments from the Supabase API based on the provided data.
 * @param {any} data - The data object containing organizationId, companyId, and branchId.
 * @returns {Promise<any>} A promise that resolves to the response from the API call.
 */
export const fetchDepartmentsApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("department")
      .select("id, name")
      .eq("organization_Id", data.organizationId);
    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchTeamsApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("team")
      .select("id, name")
      .eq("organization_Id", data.organizationId);

    if (data?.companyId) {
      query.eq("company_id", data?.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }
    if (data.departmentId) {
      query.eq("department_id", data.departmentId);
    }
    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAcessRigtGroupApi = async (data: any): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(data.organizationId)
      .from("access_right")
      .select("id, group")
      .eq("organization_Id", data.organizationId)
      .is("sub_group_name", null);

    if (data?.companyId) {
      query.eq("company_id", data?.companyId);
    }
    if (data.branchId) {
      query.eq("branch_id", data.branchId);
    }
    if (!data.branchId) {
      query.is("branch_id", null);
    }

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSubGroupAcessRigtbyIdApi = async (
  data: any
): Promise<any> => {
  try {
    const query = supabase
      .from("access_right")
      .select("id, sub_group_name")
      .eq("group", data.id)
      .eq("organization_Id", data.organizationId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchSubGroupByParentGroup = async (
  value: number,
  organization_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("access_right")
      .select("id,sub_group_name")
      .eq("parent_group_id", value);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setPersonalDetailsInfoApi = async (
  body: any,
  organization_id: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("employee")
      .insert(body)
      .select()
      .single();

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setProbationAndInternshipInfoApi = async (
  body: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(body.organization_id)
      .from("employee_internship_and_probation")
      .insert(body);

    const res = await query;

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const uploadEmployeeCreatePicApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("employeeProfile")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from("employeeProfile")
        .getPublicUrl(filePath);
      return publicURL;
    }
  } catch {
    return catch_return;
  }
};

export const insertEmployeeMultipleAccountRemainingData = async (
  orgid: any,
  companyId: number,
  branchId: number,
  oldemployeeid: number,
  newemployeeid: number
): Promise<any> => {
  try {
    const companyid = companyId ? companyId : null;
    const branchid = branchId ? branchId : null;
    const { status, error } = await supabase.rpc(
      "insert_multiple_accounts_employee_data",
      {
        orgid,
        oldemployeeid,
        newemployeeid,
        companyid,
        branchid,
      }
    );

    return { status, error };
  } catch (error) {
    return catch_return;
  }
};
