import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, Select, Space, Empty } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import { MdContactEmergency } from "react-icons/md";
import { BiSolidContact } from "react-icons/bi";
import "./index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import CustomAlert from "../../../common/Alert";
import {
  capitalizeWords,
  generateRandomPassword,
  validateEmail,
} from "../../../common/validation";
import { getCountries, getStates, getCities } from "vk-country-state-city";

import { useForm } from "antd/es/form/Form";
import { handleResendApi } from "../../../services/Global/index";
import { TriangleLoader } from "../../../common/ReactLoader";
import {
  fetchEmployeeAddressesRecordsApi,
  fetchEmployeeEmergencyRecordsApi,
  fetchEmployeeRecordsApi,
  hanldeSendEmailApi,
} from "./services";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";
import axios from "axios";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { fetchOrgDomain } from "../../Recruitment/services";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
interface props {
  employeeId: any;
}

const UserContactInfoUpdate: React.FC<props> = ({ employeeId }) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const loggedInemployeed = useSelector(selectLogedEmployee);
  const organizationId =
    loggedInemployeed?.logedInemployee?.employee?.organization_id;
  const [personalWorkEmail, setPersonalWorkEmail] = useState<string>("");
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>(null);
  const [selectedBranchId, setSelectedBranchId] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [form] = useForm();
  const [sendEmailStatus, setSendEmailStatus] = useState<string>("");
  const [infoRequriedtoSendEmail, setInfoRequriedToSendEmail] =
    useState<any>("");
  const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY;

  const accessObj = useSelector(selectAccess);
  const [orgSubDomain, setOrgSubDomain] = useState<any>(null);

  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Contact Details"
  );

  const read = access?.readAccess;
  const edit = access?.writeAccess;

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
    getOrgDomain();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      if (selectedCountry) {
        const states = await getStates(selectedCountry);
        setStates(states);
      }
    }
    if (selectedCountry) fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedState) {
        const cities = await getCities(selectedCountry, selectedState);
        setCities(cities);
      }
    }
    if (selectedState) fetchCities();
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    if (employeeId) {
      featchEmployee();
      featchAddressesdata();
      featchEmergencyContactdata();
    }
  }, [employeeId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const getOrgDomain = async () => {
    const response = await fetchOrgDomain(
      organizationId.logedInemployee?.employee.organization_id
    );
    if (response.data) {
      setOrgSubDomain(response.data?.sub_domain);
    }
  };

  const getGeoCode = async () => {
    const params = {
      q: `${form.getFieldValue("postcode")}, Pakistan`,
      format: "json",
      key: API_KEY,
    };
    const response = await axios.get("https://us1.locationiq.com/v1/search", {
      params,
    });

    if (response && response.data) {
      return response.data[0];
    }
  };
  const featchEmployee = async () => {
    setLoadingState(true);
    const employes_res = await fetchEmployeeRecordsApi(
      employeeId,
      organizationId
    );

    if (employes_res.data) {
      setLoadingState(false);
      const data = employes_res.data;
      const temp_data = {
        organizationId: data.organization_id,
        accessRightGroup: data.accessRightGroup,
        full_name: data.full_name,
        employeeId: data.id,
      };
      setInfoRequriedToSendEmail(temp_data);
      setSendEmailStatus(data.status);
      setSelectedCompanyId(data?.company_id);
      setSelectedBranchId(data?.branch_id);

      form.setFieldsValue({
        mobileNumber: data.phone_number || "",
        workNumber: data.work_phone_number || "",
        workEmail: data.work_email,
        otherEmail: data.other_email,
      });
      setPersonalWorkEmail(data.work_email);
    } else if (employes_res.error) {
      setLoadingState(false);
    }
  };

  const featchAddressesdata = async () => {
    setLoadingState(true);
    const addresses_res = await fetchEmployeeAddressesRecordsApi(
      organizationId,
      employeeId
    );

    if (addresses_res.data && addresses_res.data.present_address_detail) {
      setLoadingState(false);
      form.setFieldsValue({
        country: addresses_res.data.present_address_detail.present_country,
        state: addresses_res.data.present_address_detail.present_state,
        city: addresses_res.data.present_address_detail.present_city,
        street: addresses_res.data.present_address_detail.present_street,
        postcode: addresses_res.data.present_address_detail.present_postcode,
      });
    }
  };

  const featchEmergencyContactdata = async () => {
    setLoadingState(true);
    const EmergencyContact_res = await fetchEmployeeEmergencyRecordsApi(
      employeeId,
      organizationId
    );
    if (EmergencyContact_res.data) {
      setLoadingState(false);
      const data = EmergencyContact_res.data;
      if (data) {
        const phoneNumberOfRelative = `+${data.phone_number}`;
        const workPhoneNumberOfRelative = `+${data.work_phone_number}`;
        form.setFieldsValue({
          relative_name: data.relative_name || "",
          Relationship: data.relation_with_employee || "",
          relativehometelephone: phoneNumberOfRelative || "",
          relativeworktelephone: workPhoneNumberOfRelative || "",
          relativeemail: data.email || "",
          address: data.address || "",
        });
      }
    } else {
      setLoadingState(false);
      form.setFieldsValue({
        relative_name: "",
        Relationship: "",
        relativehometelephone: "",
        relativeworktelephone: "",
        relativeemail: "",
      });
    }
  };

  const handleUpdateFields = async () => {
    setButtonLoading(true);

    const body = {
      phone_number: form.getFieldValue("mobileNumber"),
      work_phone_number: form.getFieldValue("workNumber"),
      work_email: form.getFieldValue("workEmail"),
      other_email: form.getFieldValue("otherEmail"),
      company_id: selectedCompanyId,
      branch_id: selectedBranchId,
    };

    const employeeDetailsResponse = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee")
      .select("*")
      .eq("id", employeeId);

    if (
      employeeDetailsResponse?.data &&
      employeeDetailsResponse?.data?.length > 0
    ) {
      if (sendEmailStatus == "Listed" && form.getFieldValue("workEmail")) {
        (body as any).status = "Created";
      }

      await supabase_client_for_Org_schema(organizationId)
        .from("employee")
        .update(body)
        .eq("id", employeeId);
    }
    const response = await getGeoCode();
    const addressBody = {
      present_address_detail: {
        present_country: form.getFieldValue("country"),
        present_state: form.getFieldValue("state"),
        present_city: form.getFieldValue("city"),
        present_street: form.getFieldValue("street"),
        present_postcode: form.getFieldValue("postcode"),
        geocoordinates: [response.lat, response.lon],
      },
      employee_id: employeeId,
      organization_id: organizationId,
    };

    const address_res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_address")
      .upsert(addressBody, { onConflict: "employee_id" });

    const emergencyContactBody = {
      relation_with_employee: form.getFieldValue("Relationship"),
      relative_name: form.getFieldValue("relative_name"),
      work_phone_number: form.getFieldValue("relativehometelephone"),
      phone_number: form.getFieldValue("relativehometelephone"),
      email: form.getFieldValue("relativeemail"),
      employee_id: employeeId,
      organization_id: organizationId,
      address: form.getFieldValue("address"),
    };

    const emergencyContactResponse = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_emergency_contact_information")
      .upsert(emergencyContactBody, { onConflict: "employee_id" });

    if (
      address_res.status == 204 ||
      address_res.status == 201 ||
      emergencyContactResponse.status == 204 ||
      emergencyContactResponse.status == 201
    ) {
      setshowAlert(true);
      setAlertType("success");
    }

    setButtonLoading(false);
    featchEmergencyContactdata();
  };

  const handleReSendEmail = async () => {
    if (personalWorkEmail) {
      try {
        const data = {
          email: personalWorkEmail,
          employeeId: employeeId,
        };
        const res = await handleResendApi(data, organizationId);
        if (res.data) {
          setshowAlert(true);
          setAlertType("success");
          featchEmergencyContactdata();
        }
      } catch {
        setshowAlert(true);
        setAlertType("error");
      }
    }
  };

  const handleSendEmail = async (values: any) => {
    setshowAlert(false);
    try {
      const randompassword = generateRandomPassword();
      const body = {
        email: personalWorkEmail,
        password: randompassword,
        options: {
          emailRedirectTo: `https://${orgSubDomain}.misgenie.app/`,
          data: {
            organization_name: infoRequriedtoSendEmail.organizationName,
            org_id: infoRequriedtoSendEmail.organizationId,
            role_name: capitalizeWords(
              infoRequriedtoSendEmail.accessRightGroup
            ),
            name: infoRequriedtoSendEmail.full_name,
            employee_id: infoRequriedtoSendEmail.employeeId,
            role_id: infoRequriedtoSendEmail.accessRightGroup,
            password: randompassword,
            subdomain: orgSubDomain,
          },
        },
      };

      const res = await supabase.auth.signUp(body);
      if (res.error) {
        setshowAlert(true);

        setAlertType("error");
      } else {
        setshowAlert(true);

        setAlertType("success");
        const updateBody = {
          status: "Invited",
          workEmail: values.workEmail,
          user_id: res.data?.user?.id,
        };
        await hanldeSendEmailApi(
          updateBody,
          infoRequriedtoSendEmail.employeeId
        );
      }
    } catch {
      setshowAlert(true);

      setAlertType("error");
    }
  };
  return (
    <>
      {read ? (
        <>
          <Form layout="vertical" form={form}>
            <div className="org-form-fields">
              <h5>
                {" "}
                <BiSolidContact /> Personal Contact
              </h5>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        validator: (_, value) => {
                          const formattedValue = `${value}`;
                          if (
                            formattedValue &&
                            formattedValue?.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(formattedValue)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      className="employee-contact-phone-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Work Phone"
                    name="workNumber"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      className="employee-contact-phone-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={"country"}
                    label="Select a Country"
                    rules={[
                      {
                        required: true,
                        message: "Please select a country",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder="Select a country..."
                      showSearch
                      onChange={setSelectedCountry}
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {countries.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please select a state",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a state.."
                      onChange={setSelectedState}
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {states.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please select a city",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a city..."
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {cities.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Street"
                    name="street"
                    rules={[
                      {
                        required: true,
                        message: "Please select a street",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter a street..." />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Work Email"
                    name="workEmail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      disabled={personalWorkEmail ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Other Email"
                    name="otherEmail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your other email..."
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Postcode" name="postcode">
                    <Input size="large" placeholder="Enter your post code..." />
                  </Form.Item>
                </Col>
              </Row>

              <h5>
                {" "}
                <MdContactEmergency /> Emergency Contact
              </h5>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Relative Name"
                    name="relative_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your relative name..."
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Relationship" name="Relationship">
                    <Input
                      size="large"
                      placeholder="Enter your realtionship..."
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="Home Telephone"
                    name="relativehometelephone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry="pk"
                      className="employee-contact-phone-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Work Telephone"
                    name="relativeworktelephone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry="pk"
                      className="employee-contact-phone-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="relativeemail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter an email..." />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Street Address" name="address">
                    <Input size="large" placeholder="Enter street address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="contact-info-data-update-row">
                  <Space>
                    {(sendEmailStatus == "Created" ||
                      sendEmailStatus == "Listed") && (
                      <Form.Item className="submit-button">
                        <Button
                          className="send-invitation-btn"
                          type="primary"
                          onClick={handleSendEmail}
                        >
                          Send Email
                        </Button>
                      </Form.Item>
                    )}
                    {sendEmailStatus == "Invited" && (
                      <Form.Item className="submit-button">
                        <Button
                          className="send-invitation-btn"
                          type="primary"
                          onClick={handleReSendEmail}
                        >
                          Resend Email
                        </Button>
                      </Form.Item>
                    )}
                    <Form.Item className="submit-button">
                      {edit && (
                        <Button
                          className="global-btn-width send-invitation-btn"
                          type="primary"
                          onClick={handleUpdateFields}
                          loading={buttonLoading}
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      )}
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </div>
          </Form>

          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
          {loadingState && <TriangleLoader />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};
export default UserContactInfoUpdate;
