import HTMLReactParser from "html-react-parser";
import React, { useState } from "react";

interface JD {
  description: any;
}

export const JobDescription: React.FC<JD> = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const getDescriptionPreview = (htmlContent: any) => {
    const textContent = htmlContent?.replace(/<[^>]*>?/gm, "");
    const words = textContent.split(" ");
    const isLong = words.length > 50;

    if (!isLong || isExpanded) {
      return { text: htmlContent, isLong };
    }

    return { text: words.slice(0, 50).join(" ") + "...", isLong };
  };

  const { text, isLong } = getDescriptionPreview(description);

  return (
    <div>
      {HTMLReactParser(text)}
      {isLong && (
        <a
          onClick={toggleExpanded}
          style={{ color: "blue", cursor: "pointer" }}
        >
          {isExpanded ? "Show less" : "Show more"}
        </a>
      )}
    </div>
  );
};
