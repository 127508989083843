import { supabase_client_for_Org_schema } from "../../../../services/supabase";

export const updateData = async (
  data: any,
  organizationId: any,
  recordId: any
): Promise<any> => {
  try {
    const response = supabase_client_for_Org_schema(organizationId)
      .from("employee_work_experience")
      .update({
        details: data,
      })
      .eq("id", recordId);
    return response;
  } catch (error) {
    return error;
  }
};
