import React, { useEffect, useState } from "react";
import "./index.css";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import ClaimQuestionModel from "../ClaimQuestionModel";
import {
  fetchAllowanceDetailsByIdApi,
  fetchSalaryDetailsbyEmployeeIDApi,
  getCurentMonthClaimAllowanceDetailsApi,
} from "../services";
import CustomTable from "../../../../common/CustomTable";

const AllowanceClaimRequestCreateView = () => {
  const [allowanceData, setAllowanceData] = useState<any[]>([]);
  const [selectedRecordData, setSelectedRecordData] = useState<any>("");
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [allowanceDetails, setAllowanceDetails] = useState<any[]>([]);
  const organizationId = loggedInEmployee?.employee?.organization_id;

  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  useEffect(() => {
    fetchSalarybyEmployeeID();
  }, []);

  const fetchSalarybyEmployeeID = async () => {
    setLoadingTable(true);
    if (loggedInEmployee?.employeeId) {
      const res = await fetchSalaryDetailsbyEmployeeIDApi(
        loggedInEmployee.employeeId,
        organizationId
      );

      if (res.data) {
        if (res.data) {
          setAllowanceDetails(res.data.grade.allowed_option);
        }
        const cureentMonthRes = await getCurentMonthClaimAllowanceDetailsApi({
          employeeId: loggedInEmployee?.employeeId,
          organizationId: organizationId,
        });
        if (cureentMonthRes.data) {
          const filteredData = res?.data?.grade.allowed_option?.filter(
            (allowanceDetail: any) =>
              !cureentMonthRes.data.some(
                (currentMonthItem: any) =>
                  currentMonthItem.allowance_Id === allowanceDetail.id
              )
          );
          setAllowanceDetails(filteredData);
        }
      }
    }
    setLoadingTable(false);
  };

  const clickClaimButton = async (record: any) => {
    if (record) {
      setSelectedRecordData(record);
      const data = {
        id: record?.id,
        organizationId: organizationId,
      };
      const res = await fetchAllowanceDetailsByIdApi(data);

      if (res.data) {
        setAllowanceData(res.data);
      }
    }
  };
  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Unit/price",
      dataIndex: "unitPrice",
      key: "unit_price",
    },
    {
      title: "Unit Allowed",
      dataIndex: "unitAllowed",
      key: "unit_allowed",
    },
    {
      title: "Total Price",
      dataIndex: "totalprice",
      key: "totalprice",
      render: (record: any) => record || "N/A",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => {return (
        !(selectedRecordData && selectedRecordData?.id == record.id) &&  <Button onClick={() => clickClaimButton(record)} disabled={selectedRecordData}>Claim</Button>
      )},
    },
  ];
  const onCancel = () => {
    setSelectedRecordData(null);
    setAllowanceData([]);
    fetchSalarybyEmployeeID();
  };
  return (
    <>
      {selectedRecordData && allowanceData && (
        <div>
          <ClaimQuestionModel
            selectedRecordData={selectedRecordData}
            allowanceData={allowanceData}
            onCancel={() => onCancel()}
          />
        </div>
      )}

      <CustomTable
        dataSource={allowanceDetails}
        columns={tableColumns}
        loading={loadingTable}
      />
    </>
  );
};

export default AllowanceClaimRequestCreateView;
