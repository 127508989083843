import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Col,
  Row,
  Collapse,
  Form,
  DatePicker,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmployee,
  setEmployeeProfileDetails,
  removeEducationDetail,
} from "../../../../redux/EmployeeFormSlice";
import CustomAlert from "../../../common/Alert";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { insertEducationApi } from "./services";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../../../common/CustomTable";

const Education: React.FC = () => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const employeeEducationDetails =
    useSelector(selectEmployee).Employee?.Education;
  const employeeId = useSelector(selectEmployee)?.Employee?.EmployeeId;
  const company_id =
    useSelector(selectEmployee).Employee.PersonalDetails?.selectedCompanyId;
  const branch_id =
    useSelector(selectEmployee).Employee.PersonalDetails?.selectedBranchId;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const onFinish = (values: any) => {
    values.from = values?.from?.format("YYYY-MM-DD") || "";
    values.to = values?.to?.format("YYYY-MM-DD") || "";
    values.id = values.from + values.institute;
    const updatedList = [
      ...(employeeEducationDetails ? employeeEducationDetails : []),
      values,
    ];
    setshowAlert(false);
    dispatch(
      setEmployeeProfileDetails({
        Employee: {
          Education: updatedList,
        },
      })
    );
    form.resetFields();
  };

  const handleDelete = (index: number) => {
    dispatch(removeEducationDetail(index));
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const body = {
      qualification_details: employeeEducationDetails,
      organization_id: organizationId,
      employee_id: employeeId,
      company_id: company_id,
      branch_id: branch_id,
    };
    const WorkExperience_res = await insertEducationApi(body);

    if (WorkExperience_res.status == 201) {
      setshowAlert(true);
      setAlertType("success");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
      setButtonLoading(false);
    } else if (WorkExperience_res.error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
      timeoutRef.current = setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  };

  const columns = [
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Starting Year",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Passing Year",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <Popconfirm
          placement="topLeft"
          title="Are you sure to delete this record?"
          onConfirm={() => handleDelete(index)}
          okText="Yes"
          cancelText="No"
        >
          <a type="link">
            <DeleteOutlined />
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Collapse className="employee-create-qualification-collapse-style">
        <Collapse.Panel key="1" header="Add Education Details">
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            form={form}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Level" name="level">
                  <Input size="large" placeholder="Enter Level" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Institute"
                  name="institute"
                  rules={[
                    { required: true, message: "Please Enter Institute!" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Institute" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Specialization"
                  name="specialization"
                  rules={[
                    { required: true, message: "Please Enter Specialization!" },
                  ]}
                >
                  <Input size="large" placeholder="Enter Specialization" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="from"
                  rules={[
                    { required: true, message: "Please Enter Starting Date!" },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="End Date" name="to">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  className="global-btn-width"
                  type="primary"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CustomTable
        className="table-class"
        columns={columns}
        bordered
        dataSource={employeeEducationDetails ? employeeEducationDetails : []}
      />
      <Row className="last-row">
        <Col span={24} className="save-profile-btn">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              onClick={handleSubmit}
              loading={buttonLoading}
            >
              Save progress
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </>
  );
};

export default Education;
