import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select } from "antd";
import { ResponsiveContainer } from "recharts";
import "./index.css";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";
import { fetchMonthlyAttendanceEmployeeApi } from "./services";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";
import { ProgressLoader } from "../../../common/ReactLoader";
import { FaSearch } from "react-icons/fa";
import { ResponsiveLine } from "@nivo/line";
import { IoSearch } from "react-icons/io5";
interface ByAttendenceProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}

const Attendence: React.FC<ByAttendenceProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [chartData, setChartData] = useState<any>();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [isToggled, setIsToggled] = useState(false);
  const [yAxisTickValues, setYAxisTickValues] = useState<any[]>([]);

  useEffect(() => {
    prepareData(props.employeeData);
  }, [props.employeeData]);
  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);
  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);
  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);
  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    prepareData(tempData);
  };
  const filterbranches = () => {
    if (props?.branches && selectedCompany) {
      const filterbranches = props?.branches.filter(
        (item) => item.company_id == selectedCompany
      );

      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };
  const prepareData = async (data: any[]) => {
    if (data) {
      const monthlycountres = await fetchMonthlyAttendanceEmployeeApi(
        loggedInEmployee?.employee?.organization_id,
        selectedCompany,
        selectedBranch
      );
      const presentCountByDay: any = {};
      if (monthlycountres.data) {
        monthlycountres.data.forEach((record: any) => {
          if (record.check_in) {
            const checkInDate = record.check_in.split("T")[0];

            if (!presentCountByDay[checkInDate]) {
              presentCountByDay[checkInDate] = 0;
            }
            presentCountByDay[checkInDate]++;
          }
        });
        const shortMonthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const finalResult = Object.keys(presentCountByDay)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          ?.map((date) => {
            const dateObj = new Date(date);
            const day = dateObj.getDate().toString().padStart(2, "0");
            const month = shortMonthNames[dateObj.getMonth()];
            return {
              name: `${day}-${month}`,
              present: presentCountByDay[date],
              absent: Math.abs(data.length - presentCountByDay[date]),
            };
          });

        const chartDataFiltered = [
          {
            id: "present",
            data: finalResult.map((item) => ({
              x: item.name,
              y: item.present,
            })),
          },
          {
            id: "absent",
            data: finalResult.map((item) => ({
              x: item.name,
              y: item.absent,
            })),
          },
        ];
        setChartData(chartDataFiltered);

        const allValues = chartDataFiltered?.flatMap((d: any) =>
          d.data.map((point: any) => point.y)
        );
        const minValue = Math.min(...allValues);
        const maxValue = Math.max(...allValues);

        let numberOfTicks = maxValue <= 10 ? maxValue : Math.ceil(maxValue / 2);
        numberOfTicks = Math.max(numberOfTicks, 2);

        const generatedTickValues = Array.from(
          { length: numberOfTicks },
          (_, i) => {
            return Math.round(
              minValue + i * ((maxValue - minValue) / (numberOfTicks - 1))
            );
          }
        );

        if (!generatedTickValues.includes(maxValue)) {
          generatedTickValues[generatedTickValues.length - 1] = maxValue;
        }
        setYAxisTickValues(generatedTickValues);
      }
    } else {
      setChartData([]);
    }
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  const lineColors = ["#30928e", "#f5666b"];

  return (
    <Card
      className="bar-card dasboard-cards"
      style={{ fontFamily: "var(--storefront-heading-font-family)" }}
      title={
        <Row className="byattendance-icon-toggle-container">
          <Col span={24} md={6} className="byattendance-title-text">
            Attendance
          </Col>
          <Col span={24} md={18}>
            {!loggedInEmployee?.employee?.branch_id && !loggedInEmployee?.employee?.company_id &&  loggedInEmployee?.employeeRole !== "Hr" && (
              <Row gutter={16} className="byattendance-card-selectors-row">
                {isToggled && (
                  <>
                     
                      <Col
                        span={7}
                        className={`header-inputs ${isToggled ? "visible" : "hidden"
                          }`}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option && option.children
                              ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                              : false
                          }
                          placeholder="Select a company..."
                          onChange={setSelectedCompany}
                          className="byattendance-card-selectors-width"
                          notFoundContent={
                            <NoDataFoundIcon />
                          }
                        >
                          {companiesOption?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    
                     
                      <Col
                        span={7}
                        className={`header-inputs ${isToggled ? "visible" : "hidden"
                          }`}
                      >
                        <Select
                          showSearch
                          filterOption={(input: any, option: any) =>
                            option && option.children
                              ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                              : false
                          }
                          allowClear
                          placeholder="Select a branch"
                          onChange={setSelectedBranch}
                          className="byattendance-card-selectors-width"
                          notFoundContent={
                            <NoDataFoundIcon />
                          }
                        >
                          {branchOptions?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                     
                  </>
                )}
                <Col
                  span={2}
                  className="byattendance-icon-container"
                  onClick={handleToggle}
                >
                  {isToggled ? (
                    <div className="byattendance-search-icon-container">
                      <IoSearch className="byattendance-search-icon" />
                    </div>
                  ) : (
                    <div className="byattendance-search-icon-container">
                      <IoSearch className="byattendance-search-icon" />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      }
      bordered={true}
    >
      <ResponsiveContainer width="100%" height={300}>
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : chartData &&
          chartData[0]?.data?.length > 0 &&
          chartData[1]?.data?.length > 0 ? (
          <ResponsiveLine
            enableArea
            data={chartData}
            margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Date",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Number of Employees",
              legendOffset: -40,
              legendPosition: "middle",
              tickValues: yAxisTickValues,
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="yFormatted"
            pointLabelYOffset={-12}
            colors={lineColors}
            enableSlices="x"
            useMesh={true}
          />
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataFoundIcon />
          </div>
        )}
      </ResponsiveContainer>
    </Card>
  );
};

export default Attendence;
