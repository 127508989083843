import supabase, {
  supabase_client_for_Org_schema,
} from "../../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches salary data from the API based on the provided data object.
 * @param {any} data - The data object containing organizationId, companyId, branchId, and searchInput.
 * @returns {Promise<any>} A promise that resolves to the fetched salary data.
 */
export const fetchSalariesApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_salary")
      .select(`id, base_salary,currency,employee(full_name), block_salary`)
      .eq("organization_id", data.organizationId);
    if (data?.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    if (data.searchInput) {
      query.ilike("employee.full_name", `%${data.searchInput}%`);
    }
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Deletes a salary entry from the "Salaries" table in the database based on the provided id.
 * @param {string | number} id - The id of the salary entry to be deleted.
 * @returns {Promise<any>} A promise that resolves to the result of the deletion operation.
 */
export const deleteSalariesbyIdApi = async (
  id: string | number,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("employee_salary")
      .delete()
      .eq("id", id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Fetches all employees without salary information based on the provided data.
 * @param {any} data - The data object containing organizationId, CompanyId, and BranchId.
 * @returns {Promise<any>} A promise that resolves to an array of employees without salary information.
 */
export const fetchAllEmployeeWithOutSalaryApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee")
      .select("id, full_name")
      .eq("organization_id", data.organizationId)
      // .eq("status", "active");
    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }
    let res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

/**
 * Inserts a new salary record into the "Salaries" table in the database.
 * @param {any} data - The data to be inserted into the table.
 * @returns {Promise<any>} A promise that resolves to the result of the insertion operation.
 */
export const insertSalaryApi = async (data: any): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organization_id)
      .from("employee_salary")
      .insert(data);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
/**
 * Updates the salary information in the "Salaries" table of the Supabase database.
 * @param {any} data - The data object containing the updated salary information and the id of the salary entry to update.
 * @returns {Promise<any>} A promise that resolves to the result of the update operation.
 */
export const UpdateSalaryApi = async (
  data: any,
  orgId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgId)
      .from("employee_salary")
      .update(data.updateddata)
      .eq("id", data.id);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAllEmployeeBanksApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_bank")
      .select("id, bank_information")
      .eq("organization_id", data.organizationId)
      .eq("employee_id", data.employee_id).single()
    
    let res = await query;
   
    return res;
  } catch (error) {
    return catch_return;
  }
};

 