import React from "react";
import AllowanceClaimRequestListView from "./List";
 


const AllowanceClaimRequest = () => {
    return (
        <div>
             
           <AllowanceClaimRequestListView />
        </div>
    );
};

export default AllowanceClaimRequest;
