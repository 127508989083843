import React, { useState } from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faInfoCircle,
  faBook,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import ContactUsPage from "../../../common/ContactUs";
import FAQ, { FAQ_items } from "../../../common/Help/FAQs";
import MisGenieIntro from "../../../common/Help/GetStarted";
import AboutProduct from "../../../common/AboutProduct";
import { Col, Modal, Row, Select } from "antd";
import ComplaintForm from "../../ComplaintForm";

interface HelpQuriesProps {
  searchQuery: string;
}

interface CardProps {
  id: number;
  title: string;
  content: string;
  icon: any;
  color: string;
  className?: string;
  setSelectedCard: (id: number) => void;
}

const HelpSupportCard: React.FC<CardProps> = ({
  id,
  title,
  content,
  icon,
  color,
  className,
  setSelectedCard,
}) => {
  const iconStyle = {
    color: color,
  };

  const handleClick = () => {
    setSelectedCard(id);
  };

  return (
    <div className={`help-and-support-card ${className}`} onClick={handleClick}>
      <div>
        <FontAwesomeIcon icon={icon} style={iconStyle} />
      </div>
      <h3> {title} </h3>
      <p> {content} </p>
    </div>
  );
};

const HelpQuries: React.FC<HelpQuriesProps> = ({ searchQuery }) => {
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("bug");

  const cards = [
    {
      id: 1,
      title: "Getting Started",
      content: "Welcome to our platform!",
      icon: faQuestionCircle,
      color: "blue",
      className: "help-and-support-custom-card dasboard-cards",
    },
    {
      id: 2,
      title: "FAQs",
      content: "Frequently Asked Questions.",
      icon: faInfoCircle,
      color: "green",
      className: "help-and-support-custom-card dasboard-cards",
    },
    {
      id: 3,
      title: "Knowledge Base",
      content: "Learn more about products",
      icon: faBook,
      color: "orange",
      className: "help-and-support-custom-card dasboard-cards",
    },
  ];

  const filteredItems = FAQ_items?.filter((item: any) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const bugAndComplaintModal = () => {
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setModalVisible(false);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleSelectFormType = (value: string) => {
    setFormType(value);
    setModalVisible(true);
  };

  return (
    <>
      <Row className="help-and-support-card-upper-row-container">
        <Col span={24}>
          <Row gutter={[16, 16]} className="help-and-support-card-row">
            {cards.map((card) => (
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <HelpSupportCard
                  key={card.id}
                  id={card.id}
                  title={card.title}
                  content={card.content}
                  icon={card.icon}
                  color={card.color}
                  className={card.className}
                  setSelectedCard={setSelectedCard}
                />
              </Col>
            ))}
            <Col span={12} lg={6}>
              <div
                className={`help-and-support-card dasboard-cards`}
                onClick={bugAndComplaintModal}
              >
                <div>
                  <FontAwesomeIcon icon={faChartBar} style={{ color: "red" }} />
                </div>
                <h3>Feedback</h3>
                <p> Send us a feedback. </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        open={modalVisible}
        onOk={handleModalOk}
        width={800}
        onCancel={handleModalCancel}
        footer={false}
        className="global-modal-main-container"
        title={<span className="global-modal-title">Feedback</span>}
      >
        <div className="global-card-inner-container">
          <Row>
            <Col span={24}>
              <ul>
                <li>
                  Thank you for reaching out to us. We appreciate you taking the
                  time to report any complaints or bugs you encounter.
                </li>
                <li>
                  Please select the appropriate option below to submit your
                  complaint or report a bug:
                </li>
              </ul>
            </Col>
            <Col span={12}>
              <Select
                defaultValue="bug"
                style={{ width: 400 }}
                onChange={handleSelectFormType}
              >
                <Select.Option value="complaint">
                  Submit a Complaint
                </Select.Option>
                <Select.Option value="bug">Report a Bug</Select.Option>
              </Select>
            </Col>
          </Row>
          {formType === "complaint" ? (
            <ComplaintForm onCancel={handleModalCancel} />
          ) : null}
          {formType === "bug" ? (
            <ContactUsPage onCancel={handleModalCancel} />
          ) : null}
        </div>
      </Modal>

      <div className="help-and-support-card-row2">
        {selectedCard === 1 && <MisGenieIntro />}
        {selectedCard === 2 && <FAQ items={filteredItems} />}
        {selectedCard === 3 && <AboutProduct />}
      </div>
    </>
  );
};

export default HelpQuries;
