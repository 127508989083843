import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Typography,
  Steps,
  Row,
  Col,
  Select,
} from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { domaincheck } from "../../../../common/validation";

import CustomAlert from "../../../../common/Alert";
import {
  fetchDepartmentData,
  getBranchManagers,
  getBranches,
  getCompanies,
  submitData,
} from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { companyOfBranch } from "../../Branches/services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";
const phoneUtil = PhoneNumberUtil.getInstance();
const { Text } = Typography;
const { Step } = Steps;

interface EditDepartmentModalProps {
  selectedDepartmentId: number | null;
  onClose: () => void;
  children?: React.ReactNode;
  fetchDepartments: () => void;
}

interface companiesOptiontype {
  label: string;
  id: string;
}
const EditDepartmentModal: React.FC<EditDepartmentModalProps> = ({
  selectedDepartmentId,
  onClose,
  fetchDepartments,
}) => {
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [companiesOption, setCompaniesOption] = useState<companiesOptiontype[]>(
    []
  );
  const [managerNames, setManagerNames] = useState<string[] | any>([]);
  const [departmentCode, setDepartmentCode] = useState<string>();
  const [branchOption, setBranchOption] = useState<companiesOptiontype[]>([]);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [branchCompany, setBranchCompany] = useState<string>("");
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    fetchCompanies();
    fetchBranchManagerNames();
    form.setFieldValue("headManagerName", null);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    getCompanyOfBranch();
  }, [form.getFieldValue("companyname")]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (selectedDepartmentId !== null) {
      const fetchDepartmentDataa = async () => {
        try {
          setLoading(true);
          const departmentId = selectedDepartmentId;
          const res = await fetchDepartmentData(departmentId, organizationId);
          if (res.data) {
            form.setFieldsValue({
              companyname: res.data.company_id,
              branchname: res.data.branch_id,
              departmentName: res.data.name,
              headManagerName: res.data.head_manager_id,
              email: res.data.email,
              phoneNumber: res.data.phone_number,
              departmentDetails: res.data.mission,
            });
            setDepartmentCode(res.data.unique_identifier);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      };
      fetchDepartmentDataa();
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [selectedDepartmentId]);

  const getCompanyOfBranch = async () => {
    const response = await companyOfBranch(
      form.getFieldValue("companyname"),
      organizationId
    );

    if (response.data) {
      setBranchCompany(response.data.email);
    }
  };

  const handleNext = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      setCurrentStep(currentStep);
    }
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOk = async () => {
    setButtonLoading(true);
    try {
      const body = {
        name: form.getFieldValue("departmentName"),
        unique_identifier: departmentCode,
        head_manager_id: form.getFieldValue("headManagerName"),
        phone_number: form.getFieldValue("phoneNumber"),
        email: form.getFieldValue("email"),
        mission: form.getFieldValue("departmentDetails"),
      };

      const response = await submitData(
        body,
        selectedDepartmentId,
        organizationId
      );

      if (response.error) {
        setButtonLoading(false);
        setshowAlert(true);
        setAlertType("error");
      } else if (response.status == 204 || response.status == 201) {
        fetchDepartments();
        setshowAlert(true);
        setButtonLoading(false);
        setAlertType("success");
        onClose();
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }

    timeoutRef.current = setTimeout(() => {
      setshowAlert(false);
    }, 3000);
  };

  const fetchCompanies = async () => {
    const companies_data = await getCompanies(organizationId);
    if (companies_data.data) {
      const option: companiesOptiontype[] = companies_data.data?.map(
        (item: any) => ({
          id: item.id,
          label: item.name,
        })
      );
      setCompaniesOption(option);
      fetchBranches();
    }
  };

  const fetchBranches = async () => {
    const data = {
      companyId: form.getFieldValue("companyname"),
      organizationId: organizationId,
    };
    const response = await getBranches(data);

    if (response?.data) {
      const option: companiesOptiontype[] = response.data?.map((item: any) => ({
        id: item.id,
        label: item.name,
      }));
      setBranchOption(option);
    }
  };
  const fetchBranchManagerNames = async () => {
    try {
      const data = {
        organizationId: organizationId,
        companyId: form.getFieldValue("companyname"),
        branchId: form.getFieldValue("branchName"),
      };

      const res = await getBranchManagers(data);
      if (res.data) {
        const names = res.data.map((item: any) => ({
          id: item.id,
          name: item.full_name,
        }));
        setManagerNames(names);
      }
    } catch (error) {
      return error;
    }
  };

  const steps = [
    {
      title: "Department Name",
      content: (
        <div>
          <Form layout="vertical" form={form}>
            <Row>
              <Col span={24} className="modal-content">
                <Row gutter={16}>
                  <Col span={24} md={12}>
                    <Form.Item label="Company (If any)" name="companyname">
                      <Select
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Select a company"
                        disabled
                        value={form.getFieldValue("companyname")}
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {companiesOption.map((company) => (
                          <Select.Option key={company.id} value={company.id}>
                            {company.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label="Branch Name (If any)" name="branchname">
                      <Select
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="The Company's branch shall be displayed here"
                        value={form.getFieldValue("branchname")}
                        disabled
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {branchOption.map((branch) => (
                          <Select.Option key={branch.id} value={branch.id}>
                            {branch.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      name="departmentName"
                      label="Department Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the department name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Department"
                        size="large"
                        value={form.getFieldValue("departmentName")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item label="Head/Manager Name" name="headManagerName">
                      <Select
                        showSearch
                        filterOption={(input: any, option: any) =>
                          option && option.children
                            ? (option.children as string)
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            : false
                        }
                        placeholder="Enter the branch manager's name"
                        value={form.getFieldValue("headManagerName")}
                        notFoundContent={
                          <NoDataFoundIcon />
                        }
                      >
                        {managerNames.map((manager: any) => (
                          <Select.Option key={manager.id} value={manager.id}>
                            {manager.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <strong>Department Code/ID :</strong>
                    {departmentCode}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="edit-department-modal-btn-container-row">
              <Col span={24} className="edit-department-modal-btn-container">
                <Button
                  key="next"
                  className="global-btn-width"
                  type="primary"
                  onClick={handleNext}
                  size="large"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      title: "Contact Information",
      content: (
        <>
          <Form layout="vertical" form={form}>
            <Row>
              <Col className="modal-content">
                <Row gutter={[32, 32]}>
                  <Col span={24} md={12}>
                    <Form.Item
                      label="Phone Number"
                      name="phoneNumber"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              value.length > 3 &&
                              !phoneUtil.isValidNumber(
                                phoneUtil.parseAndKeepRawInput(value)
                              )
                            ) {
                              return Promise.reject("Invalid phone number");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <div className="department-phone-input">
                        <PhoneInput
                          defaultCountry="ua"
                          value={form.getFieldValue("phoneNumber")}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && !domaincheck(branchCompany, value)) {
                              return Promise.reject("Invalid Company Email!");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        value={form.getFieldValue("email")}
                        size="large"
                        placeholder="Enter an email..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="global-modal-bottom-row edit-department-modal-btn-container-row">
              <Col span={24} className="edit-department-modal-btn-container">
                <Space>
                  <Button
                    className="global-btn-width"
                    type="default"
                    key="prev"
                    onClick={handlePrev}
                    size="large"
                  >
                    Previous
                  </Button>
                  <Button
                    key="next"
                    className="global-btn-width"
                    type="primary"
                    onClick={handleNext}
                    size="large"
                  >
                    Next
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
    {
      title: "Additional Details",
      content: (
        <div>
          <Form layout="vertical" form={form}>
            <Row>
              <Col className="modal-content">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="departmentDetails"
                      label="Department Details"
                    >
                      <Input.TextArea
                        placeholder="Enter  department details..."
                        rows={4}
                        value={form.getFieldValue("departmentDetails")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="global-modal-bottom-row edit-department-modal-btn-container-row">
              <Col span={24} className="edit-department-modal-btn-container">
                <Space>
                  <Button
                    className="global-btn-width"
                    type="default"
                    key="prev"
                    onClick={handlePrev}
                    size="large"
                  >
                    Previous
                  </Button>
                  <Button
                    key="submit"
                    className="global-btn-width"
                    type="primary"
                    onClick={handleOk}
                    loading={buttonLoading}
                    size="large"
                  >
                    Update
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onOk={handleOk}
      onCancel={onClose}
      width={1000}
      title={
        <span className="global-modal-title">Edit Department Details</span>
      }
      footer={false}
      className="global-modal-main-container"
    >
      <div className=" global-card-inner-container">
        {loading ? (
          <Space direction="vertical">
            <Text>Loading department data...</Text>
          </Space>
        ) : (
          <>
            <Steps current={currentStep}>
              {steps.map((step, index) => (
                <Step
                  key={step.title}
                  title={step.title}
                  description={`Step ${index + 1}`}
                />
              ))}
            </Steps>
            <Form form={form} layout="vertical">
              <div className="global-steps-container-margin">
                {steps[currentStep].content}
              </div>
            </Form>
          </>
        )}
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </Modal>
  );
};

export default EditDepartmentModal;
