import { supabase_client_for_Org_schema } from "../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchLoggInEmployeeDetailsApi = async (
  employeeId: any,
  orgid: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("LoginUser")
      .select(`*`)
      .eq("id", employeeId)
      .maybeSingle();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchLoggInEmployeeActiveStatusApi = async (
  employeeId: any,
  orgid: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("employee")
      .select(`status`)
      .eq("id", employeeId);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};



export const fetchRequestCountApi = async (
  orgid: number
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(orgid)
      .from("request_count")
      .select(`*`)
      .maybeSingle();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};