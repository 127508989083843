import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
/**
 * Fetches employee probation and internship data from the database based on the provided filters.
 * @param {any} data - The data object containing filter parameters.
 * @returns {Promise<any>} A promise that resolves to the fetched data.
 */
export const fetchEmployeeprobationinIntershipApi = async (
  data: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(data.organizationId)
      .from("employee_internship_and_probation")
      .select(`*,  employee(full_name, status)`)
      .eq("organization_id", data.organizationId)
      .eq("employment_type", data.employmentType)

    if (data.companyId) {
      query = query.eq("company_id", data.companyId);
    }
    if (data.branchId) {
      query = query.eq("branch_id", data.branchId);
    }

    if (data.startingmonth) {
      const startDate = new Date(`${data.startingmonth}-01T00:00:00Z`);
      const endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(0);
      query = query
        .gte("starting_date", startDate.toISOString())
        .lte("starting_date", endDate.toISOString());
    }

    if (data.endingmonth) {
      const startDate = new Date(`${data.endingmonth}-01T00:00:00Z`);
      const endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(0);
      query = query
        .gte("completion_date", startDate.toISOString())
        .lte("completion_date", endDate.toISOString());
    }
    const res = await query;
    if (data.searchQuery && res.data) {
      res.data = res.data?.filter((item: any) => item?.employee?.full_name);
    }

    return res;
  } catch {
    return catch_return;
  }
};

/**
 * Updates the employment type of an employee with the specified ID in the database.
 * @param {any} data - The data to update for the employee.
 * @param {string} id - The ID of the employee to update.
 * @returns {Promise<any>} A promise that resolves to the result of the update operation.
 */
export const updateEmployeeEmploymentTypebyIdApi = async (
  data: any,
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .update(data)
      .eq("id", id);
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
/**
 * Updates a record in the "ProbationsandInternships" table by its ID using the provided data.
 * @param {any} data - The data to update the record with.
 * @param {string} id - The ID of the record to update.
 * @returns {Promise<any>} A promise that resolves to the updated record.
 */
export const updateProbationsandInternshipsbyIdApi = async (
  data: any,
  id: string,
  organizationId: string
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee_internship_and_probation")
      .update(data)
      .eq("id", id);
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const insertProbationsandInternshipsData = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee_internship_and_probation")
      .insert(data);

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const getProbationsandInternshipsEmployeeData = async (
  organizationId: string
): Promise<any> => {
  try {
    const query = supabase_client_for_Org_schema(organizationId)
      .from("employee_internship_and_probation")
      .select("employee_id");

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const getAllEmployees = async (
  organizationId: string,
  companyId: any,
  branchId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select("id,full_name");

    if (companyId) {
      query = query.eq("company_id", companyId);
    }
    if (branchId) {
      query = query.eq("branch_id", branchId);
    }

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};
