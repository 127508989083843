import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Form, Row, Col, Select, Space } from "antd";
import { useSelector } from "react-redux";
import CustomAlert from "../../../../common/Alert";
import { getOtherData, handlingDataUpdation } from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchCompanies } from "../../Designation/services";
import { getBranches } from "../../Department/services";
import "./index.css";
import { TriangleLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

interface EditJobTitleModalProps {
  visible: boolean;
  onCancel: () => void;
  id: string | number;
  fetchData: () => void;
}

const EditJobTitleModal: React.FC<EditJobTitleModalProps> = ({
  visible,
  onCancel,
  id,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [otherData, setOtherData] = useState<any>({});
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const companyId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee?.company_id;

  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  useEffect(() => {
    fetchBranches();
  }, [organizationId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    const fetchOtherData = async () => {
      if (id) {
        const response = await getOtherData(id, organizationId);

        if (response.data && response.data.length > 0) {
          form.setFieldsValue({
            jobtitle: response?.data[0]?.name,
            companyid: response?.data[0]?.company_id,
            branch: response?.data[0]?.branch?.name,
            comment: response?.data[0]?.mission,
          });
          setOtherData(response.data[0]);
        }
      }
      setLoading(false);
    };

    const getCompanies = async () => {
      const response = await fetchCompanies(organizationId);
      if (response.error) {
        return response.error;
      } else {
        setCompanies(response.data);
      }
    };

    if (visible) {
      fetchOtherData();
      getCompanies();
    }
  }, [id, visible, form]);

  const fetchBranches = async () => {
    const data = {
      organizationId: organizationId,
      companyId: companyId,
    };
    const response = await getBranches(data);
    if (response.data) setBranches(response.data);
  };

  const handleUpdate = async () => {
    try {
      const editedFields = await form.validateFields();
      const { jobtitle, comment } = editedFields;
      setButtonLoading(true);
      const payload = {
        name: jobtitle,
        mission: comment,
      };
      const body = {
        body: payload,
        id: id,
      };
      const response = await handlingDataUpdation(body, organizationId);
      if (response.error) {
        setshowAlert(true);
        setAlertType("error");
        setButtonLoading(false);
      } else {
        fetchData();
        setshowAlert(true);
        setAlertType("success");
        setButtonLoading(false);
      }
    } catch (errorInfo) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <Modal
      title={<span className="global-modal-title">Edit Job Title</span>}
      open={visible}
      onCancel={onCancel}
      width={1000}
      footer={false}
      className="global-modal-main-container"
      destroyOnClose
    >
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form}>
          <Row>
            <Col className="modal-content">
              <Row gutter={16}>
                <Col span={24} md={8}>
                  <Form.Item
                    label="Job Title"
                    name="jobtitle"
                    rules={[
                      {
                        required: true,
                        message: "Please input the job title!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter job title..." />
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item
                    label="Company"
                    name="companyid"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company ID!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled
                      placeholder="Select a company..."
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {companies.map((company) => (
                        <Option key={company.id} value={company.id}>
                          {company.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item label="Branch" name={"branch"}>
                    <Select
                      showSearch
                      disabled
                      // placeholder="Select a branch..."
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {branches.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Comment" name="comment">
                    <Input.TextArea
                      rows={3}
                      placeholder="Enter your comment..."
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              className="global-modal-bottom-row job-title-edit-modal-btn-container"
            >
              <Button
                className="global-btn-width"
                type="primary"
                key="save"
                onClick={handleUpdate}
                loading={buttonLoading}
                size="large"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {loading && <TriangleLoader />}
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </Modal>
  );
};

export default EditJobTitleModal;
