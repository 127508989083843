import { Button, Col, Form, Input, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { pushNotifictionIntoNotificationTable } from "../../../../services/Global";
import {
  fetchOverTimeClaimRequestById,
  fetchOverTimeClaimRequestupdateById,
} from "../services";

interface OverTimeApproveEditProps {
  recordId: string;
  onCancel: () => void;
}
const OverTimeApproveEdit: React.FC<OverTimeApproveEditProps> = (props) => {
  const [form] = Form.useForm();
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [requestStatus, setRequestStatus] = useState<"Approved" | "Rejected">();
  useEffect(() => {
    fetchRequesteDetails();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchRequesteDetails = async () => {
    const res = await fetchOverTimeClaimRequestById(
      props?.recordId,
      logedInemployee?.employee?.organization_id
    );

    if (res.data) {
      setSelectedRecord(res.data);
      form.setFieldsValue({
        selectedCompany: res.data.company?.name || "",
        selectedBranch: res.data.branch?.name || "",
        emplayeeName: res.data.employee?.full_name || "",
        checkedBy: res.data?.reviewer_id?.full_name || "",
        totalHours: res.data.total_working_houre || "",
        endTime: res.data.end_time || "",
        startTime: res.data.starting_time || "",
        date: res.data?.date || "",
        taskDescription: res.data?.task_description || "",
      });
    }
  };
  const onFinish = async (values: any) => {
    let body = {
      status: requestStatus,
      reviewer_comment: values.finalDescription,
    };

    const res = await fetchOverTimeClaimRequestupdateById(
      body,
      props.recordId,
      logedInemployee?.employee?.organization_id
    );
    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
    } else if (res.status == 201 || res.status == 204) {
      const sendNotificationBody = {
        type:
          requestStatus == "Approved"
            ? "OverTimeClaimApproved"
            : "OverTimeClaimRejection",
        senderId: logedInemployee?.employeeId || null,
        Receiver: selectedRecord.requestedEmployeeId,
        name: logedInemployee?.employee?.first_name,
        avatar: logedInemployee?.employee?.avatar_url,
      };
      await pushNotifictionIntoNotificationTable(
        sendNotificationBody,
        logedInemployee?.employee?.organization_id
      );
      setshowAlert(true);

      setAlertType("success");

      props.onCancel();
    }
  };
  return (
    <>
      <div className="overTime-approve-edit-model">
        <div className="overTime-approve-edit-model-form-container">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Company" name="selectedCompany">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Branch" name="selectedBranch">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Employee" name="emplayeeName">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Next Requestee" name="checkedBy">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="Date" name="date">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="Start Time" name="startTime">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="End Time" name="endTime">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="Total Hours" name="totalHours">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="Task Description" name="taskDescription">
                  <Input.TextArea
                    placeholder="Task Description"
                    rows={3}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="Enter Your Comment" name="finalDescription">
                  <Input.TextArea
                    placeholder="Enter Your Comment......."
                    rows={3}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={[16, 16]}
              className="overTime-approve-edit-model-last-row"
            >
              <Col span={24} className="leave-approvel-model-button-row">
                <Space>
                  <Button
                    key="reject"
                    htmlType="submit"
                    onClick={() => setRequestStatus("Rejected")}
                    className="global-default-btn"
                  >
                    Reject
                  </Button>
                  <Button
                    key="submit"
                    className="global-primary-btn"
                    htmlType="submit"
                    onClick={() => setRequestStatus("Approved")}
                  >
                    Approved
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default OverTimeApproveEdit;
