import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

//--------------------------------Scheduler----------------------------------------------
export const updateEventScheduler = async (payload: any) => {
  try {
    const response = await supabase_client_for_Org_schema(
      payload.organization_id
    )
      .from("event_scheduler")
      .update(payload)
      .eq("event_id", payload.event_id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleDeletion = async (id: string, organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("event_scheduler")
      .delete()
      .eq("event_id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getEvents = async (Ids: any) => {
  try {
    let query = supabase_client_for_Org_schema(Ids.organizationId)
      .from("event_scheduler")
      .select(
        "id,event_id,title,description,type_of_event,branch_id,company_id,start_date_and_time,end_date_and_time"
      )
      .eq("organization_id", Ids.organizationId);

    if (Ids.companyId) {
      query = query.eq("company_id", Ids.companyId);
    }
    if (Ids.branchId) {
      query = query.eq("branch_id", Ids.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const updation = async (data: any, organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("event_scheduler")
      .update(data.body)
      .eq("event_id", data.id)
      .select();

    return response;
  } catch (error) {
    return catch_return;
  }
};
