import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col, Input, Space } from "antd";
import "./index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import CustomAlert from "../../../../../common/Alert";
import {
  fetchEmergencyContactByIdApi,
  updateEmergencyContactByIdApi,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../../../../common/ReactLoader";
import TextArea from "antd/es/input/TextArea";

interface EditRelativeModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedRecordId: number | string;
  fetchData: () => void;
}

const EditRelativeModal: React.FC<EditRelativeModalProps> = ({
  visible,
  onCancel,
  selectedRecordId,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(true);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error" | "info">(
    "info"
  );

  useEffect(() => {
    if (visible && selectedRecordId !== null) {
      fetchRelativeDetails();
    }
  }, [visible, selectedRecordId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  /**
   * Fetches the emergency contact details for the selected record ID and updates the form fields accordingly.
   * @returns None
   */
  const fetchRelativeDetails = async () => {
    const res = await fetchEmergencyContactByIdApi(
      selectedRecordId,
      organizationId
    );

    if (res.data) {
      form.setFieldsValue({
        employeeId: res.data.employee?.full_name,
        email: res.data?.email,
        name: res.data?.relative_name,
        relation: res.data?.relation_with_employee,
        worktelephone: "+" + res.data?.work_phone_number || "",
        mobile: "+" + res.data?.phone_number || "",
        address: res.data.address || "",
      });
    }
    setModalLoading(false);
  };
  /**
   * Handles the update of a relative's information by validating form fields, updating the data,
   * and making an API call to update the emergency contact by ID.
   * @returns None
   */
  const handleUpdateRelative = async () => {
    const values = await form.validateFields();
    setLoading(true);
    const updatedData = {
      relative_name: values.name,
      relation_with_employee: values.relation,
      phone_number: values.mobile,
      work_phone_number: values.worktelephone,
      email: values.email,
      address: values.address,
    };
    const data = {
      updatedData: updatedData,
      id: selectedRecordId,
    };
    const res = await updateEmergencyContactByIdApi(data, organizationId);
    setLoading(false);
    if (res.error) {
      setshowAlert(true);
      setAlertType("error");
    } else {
      setshowAlert(true);
      setAlertType("success");

      fetchData();

      onCancel();
    }
  };
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={1000}
      title={<span className="global-modal-title">Edit Relative</span>}
      footer={false}
      className="global-modal-main-container"
      destroyOnClose
    >
      <div className="global-card-inner-container">
        <Form form={form} layout="vertical">
          <Row>
            <Col className="modal-content">
              <Row gutter={16}>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Employee Name"
                    name="employeeId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Input size="large" disabled placeholder="Employee Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Enter a valid email.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Email"></Input>
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Relation Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the home telephone number",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Relation"
                    name="relation"
                    rules={[
                      {
                        required: true,
                        message: "This field cannot be left empty",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Relation" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    label="Home Telephone"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the home telephone number",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput inputProps={{ required: true }} />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    label="Work Telephone"
                    name="worktelephone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput placeholder="Enter your phone number" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Street Address" name="address">
                    <TextArea placeholder="Enter street address" />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="global-modal-bottom-row">
                <Col span={24} className="modal-button-container">
                  <Space>
                    <Button
                      className="global-btn-width"
                      type="default"
                      key="cancel"
                      onClick={onCancel}
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      key="update"
                      className="global-btn-width"
                      type="primary"
                      loading={loading}
                      onClick={handleUpdateRelative}
                      size="large"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      {modalLoading && <TriangleLoader />}
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </Modal>
  );
};

export default EditRelativeModal;
