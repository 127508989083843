import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchSalaryDetailsbyEmployeeIDApi = async (
  employeeId: string | number,
  organizationId: string
): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(organizationId)
      .from("employee_salary")
      .select(`*, grade(allowed_option)`)
      .eq("employee_id", employeeId)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchGradeDetailsByIdApi = async (
  id: string | number,
  organizationId: string
): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(organizationId)
      .from("grade")
      .select("allowed_option")
      .eq("id", id)
      .single();

    return res;
  } catch {
    return catch_return;
  }
};

export const getCurentMonthClaimAllowanceDetailsApi = async (
  data: any
): Promise<any> => {
  try {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const res = await supabase_client_for_Org_schema(data.organizationId)
      .from("allowance_claim")
      .select(`allowance_Id`)
      .eq("employee_id", data.employeeId)
      .gte("created_at", firstDayOfMonth.toISOString())
      .lte("created_at", lastDayOfMonth.toISOString());

    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchAllowanceDetailsByIdApi = async (data: any): Promise<any> => {
  try {
    let res = await supabase_client_for_Org_schema(data.organizationId)
      .from("allowance")
      .select("*")
      .eq("organization_Id", data.organizationId)
      .eq("id", data.id)
      .single();
    return res;
  } catch {
    return catch_return;
  }
};

export const saveToAllowanceClaimAttachmentStorageApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("AllowanceAttachment")
      .upload(data.filePath, data.file);

    return res;
  } catch {
    return catch_return;
  }
};

export const setAllowanceClaimApi = async (
  data: any,
  organizationId: any
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(organizationId)
      .from("allowance_claim")
      .insert(data);
    return res;
  } catch {
    return catch_return;
  }
};

export const getEmployeeAllowanceHistoryDetailsApi = async (
  data: any
): Promise<any> => {
  try {
    const res = await supabase_client_for_Org_schema(data.organizationId)
      .from("allowance_claim")
      .select(`created_at, name,amount_to_claim ,status,id`)
      .eq("employee_id", data.employeeId);
    return res;
  } catch (error) {
    return catch_return;
  }
};
