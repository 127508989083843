import React, { useState, useEffect } from "react";
import { Input, Button, Col, Row, Form, Select } from "antd";
import "./index.css";
import CustomAlert from "../../../../common/Alert";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchCompanies, getBranches, submitData } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Option } = Select;

interface props {
  onCancel: (status: string) => void;
}

const CreateDesignation: React.FC<props> = ({ onCancel }) => {
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [fetchBranches, setFetchBranches] = useState<any[]>([]);
  const [companyId, setCompanyId] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchSelectLoading, setBranchSelectLoading] =
    useState<boolean>(false);

  const [formData, setFormData] = useState<{
    designation: string;
    comment: string;
    company: string | undefined;
    branch: string;
    department: string;
  }>({
    designation: "",
    comment: "",
    company: undefined,
    branch: "",
    department: "",
  });

  const [companyData, setCompanyData] = useState<
    { id: string; name: string }[]
  >([]);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      fetchBranchesData();
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompanyNames();
  }, []);

  const [form] = useForm();

  const fetchCompanyNames = async () => {
    try {
      setCompanySelectLoading(true);
      const response = await fetchCompanies(organizationId);

      if (response.data) {
        const companyData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setCompanyData(companyData);
        setCompanySelectLoading(false);
      }
      setCompanySelectLoading(false);
    } catch (error) {
      setCompanySelectLoading(false);
    }
  };

  const fetchBranchesData = async () => {
    setBranchSelectLoading(true);
    const response = await getBranches(companyId, organizationId);

    if (response.data) {
      setFetchBranches(response.data);
      setBranchSelectLoading(false);
    } else {
      setBranchSelectLoading(false);
    }
  };

  const sendRecords = async () => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const companyId = formData.company;
      const insertData = {
        mission: formData.comment,
        company_id: companyId || null,
        name: formData.designation,
        organization_id: organizationId || null,
        branch_id: formData.branch || null,
      };

      const response = await submitData(insertData, organizationId);

      if (response.error) {
        setshowAlert(true);
        setButtonLoading(false);
        setAlertType("error");
      } else if (response.status == 201) {
        form.resetFields();
        setFormData({
          designation: "",
          comment: "",
          company: undefined,
          branch: "",
          department: "",
        });
        setButtonLoading(false);
        onCancel("success");
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };
  return (
    <div className="global-card-inner-container">
      <Form layout="vertical" form={form} onFinish={sendRecords}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Designation"
              name={"designation"}
              rules={[{ required: true, message: "Please Enter Designation!" }]}
            >
              <Input
                placeholder="Enter Designation"
                size="large"
                onChange={(e) =>
                  setFormData({ ...formData, designation: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Companies"
              name={"companies"}
              rules={[{ required: true, message: "Please Select an Option" }]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Company"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                onChange={(value) => {
                  setFormData({ ...formData, company: value });
                  setCompanyId(value);
                }}
                loading={companySelectLoading}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {companyData?.map((company) => (
                  <Option key={company.id} value={company.id}>
                    {company.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Select a Branch (if any)" name={"branch"}>
              <Select
                showSearch
                allowClear
                filterOption={(input, option: any) =>
                  option && option.children
                    ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    : false
                }
                placeholder="Select a branch"
                optionFilterProp="children"
                onChange={(value) => {
                  setFormData({ ...formData, branch: value });
                }}
                loading={branchSelectLoading}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {fetchBranches.map((branch: any) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Description" name={"comment"}>
              <Input.TextArea
                placeholder="Enter Description..."
                onChange={(e) =>
                  setFormData({ ...formData, comment: e.target.value })
                }
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="global-modal-bottom-row button-row">
          <Form.Item>
            <Button
              className="global-btn-width"
              type="primary"
              htmlType="submit"
              loading={buttonLoading}
              size="large"
            >
              Create
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateDesignation;
