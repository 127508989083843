import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

export const getAnEmployeesAttendanceDetails = async (
  employeeId: string | number | null,
  organizationId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_attendance")
      .select("*")
      .eq("employee_id", employeeId);
    return response;
  } catch (error) {
    return error;
  }
};

export const getTheTimingPoliciesForEmployees = async (
  organizationId: any,
  employeeId: any
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("employee_timing_policies")
      .select("*")
      .eq("employee_id", employeeId);

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
};
