import React, { useEffect, useState } from "react";
import EmployeeCard from "../../../DashboardAdminandHr/EmployeeCard";
import { Col, Row } from "antd";
import "./index.css";
import { FaCubesStacked } from "react-icons/fa6";
import { VscCompassActive } from "react-icons/vsc";
import { IoCloseCircleSharp } from "react-icons/io5";
import { HiRectangleStack } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { fetchJobData, fetchTotalApplicationsApi } from "../../services";

function JobDetailCards() {
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const [data, setData] = useState<any[]>([]);
  const [applicationList, setApplicationList] = useState<any[]>([]);

  useEffect(() => {
    fetchJObData();
  }, []);

  const fetchJObData = async () => {
    const response = await fetchJobData(organizationId);

    if (response.data) {
      const mappedData = response.data.map((job: any) => ({
        ...job,
        jobStatus: job.status,
      }));
      setData(mappedData);
    } else {
    }
  };

  useEffect(() => {
    fetchApplicationsCount();
  }, [organizationId]);

  const fetchApplicationsCount = async () => {
    const applicationsCount = {
      organizationId: organizationId,
      jobId: data,
    };
    const res = await fetchTotalApplicationsApi(applicationsCount);
    if (res.data) {
      setApplicationList(res.data);
    }
  };

  const getTotalJobs = (): number => {
    return data.length;
  };

  const getTotalActiveJobs = (): number => {
    return data.filter((job) => job.status === true).length;
  };

  const getTotalClosedJobs = (): number => {
    return data.filter((job) => job.status === false).length;
  };

  const getTotalApplications = (): number => {
    return applicationList.length;
  };

  const totalJobs = getTotalJobs();
  const totalActiveJobs = getTotalActiveJobs();
  const totalClosedJobs = getTotalClosedJobs();
  const totalApplications = getTotalApplications();

  return (
    <Row gutter={[10, 10]} className="empl-cards-margin-top-style">
      <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <EmployeeCard
          className={""}
          icon={
            <FaCubesStacked
              style={{ color: "var(--green-color-primary-light)" }}
            />
          }
          digit={totalJobs ? totalJobs : "---"}
          heading="Total Jobs"
          iconBackgroundColor="var(--green-color-secondary-light-alpha)"
          subheading={Math.round((totalJobs / totalJobs) * 100)}
          percentageWord={totalJobs ? "% total jobs" : "---"}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <EmployeeCard
          className={""}
          icon={
            <VscCompassActive
              style={{ color: "var(--green-color-primary-light)" }}
            />
          }
          digit={totalActiveJobs ? totalActiveJobs : "---"}
          heading="Active Jobs"
          iconBackgroundColor="var(--green-color-secondary-light-alpha)"
          subheading={Math.round((totalActiveJobs / totalJobs) * 100)}
          percentageWord={totalActiveJobs ? "% active jobs" : "---"}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <EmployeeCard
          className={""}
          icon={
            <IoCloseCircleSharp
              style={{ color: "var(--green-color-primary-light)" }}
            />
          }
          digit={totalClosedJobs ? totalClosedJobs : "---"}
          heading="Closed Jobs"
          iconBackgroundColor="var(--green-color-secondary-light-alpha)"
          subheading={Math.round((totalClosedJobs / totalJobs) * 100) || "---"}
          percentageWord={totalClosedJobs ? "% closed jobs" : "---"}
        />
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <EmployeeCard
          className={""}
          icon={
            <HiRectangleStack
              style={{ color: "var(--green-color-primary-light)" }}
            />
          }
          digit={totalApplications ? totalApplications : "---"}
          heading="Total Applications"
          iconBackgroundColor="var(--green-color-secondary-light-alpha)"
          subheading={Math.round((totalApplications / totalApplications) * 100)}
          percentageWord={totalApplications ? "% total applications" : "---"}
        />
      </Col>
    </Row>
  );
}

export default JobDetailCards;
