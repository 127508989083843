import React, { useEffect, useState } from "react";
import { Card, Avatar, Row, Col } from "antd";
import "./index.css";
import d_dp from "../../../assets/images/blankProfile.png";
import moment from "moment";
import { ProgressLoader } from "../../../common/ReactLoader";
import { generateFormatedId } from "../../../common/validation";
import NewIcon from "../../../assets/images/dashboard-view/new.svg";
import { FaStar } from "react-icons/fa";

interface OnboardingDetailsProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}
const OnboardingDetails: React.FC<OnboardingDetailsProps> = (props) => {
  const [employees, setEmployees] = useState<any>(null);
  useEffect(() => {
    fetchData(props?.employeeData);
  }, [props?.employeeData]);

  const fetchData = async (data: any[]) => {
    if (data) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const filteredEmployees = data?.filter((employee: any) => {
        const joiningDate = new Date(employee?.joining_date);
        const joiningYear = joiningDate.getFullYear();
        const joiningMonth = joiningDate.getMonth() + 1;
        return joiningYear === currentYear && joiningMonth === currentMonth;
      });
      let tempEmployeeList = filteredEmployees?.sort((a: any, b: any) => {
        const dayA = new Date(a.joining_date).getDate();
        const dayB = new Date(b.joining_date).getDate();
        return dayA - dayB;
      });

      setEmployees(tempEmployeeList);
    }
  };
  return (
    <Card
      className="main-onboarding-container dasboard-cards"
      title={"Onboarding Details"}
      bordered={true}
    >
      <div className="onboarding-scrollable-content">
        {props.employeeDataShow ? (
          <ProgressLoader />
        ) : employees && employees?.length > 0 ? (
          <div>
            {employees?.map((employee: any, index: number) => (
              <div key={employee.id}>
                <div className="onboarding-custom-new-container">
                  <div className="onboarding-custom-new-avatarCol">
                    <Avatar
                      shape="circle"
                      size={{
                        xxl: 60,
                        xl: 60,
                        lg: 60,
                        md: 60,
                        sm: 60,
                        xs: 60,
                      }}
                      src={employee.avatar_url || d_dp}
                    />

                    <FaStar className="early-riser-custom-new-newWord" />

                  </div>
                  <Row className="onboard-card-data-row">
                    <Col
                      xxl={19}
                      xl={19}
                      lg={19}
                      md={19}
                      sm={18}
                      xs={18}
                      className="onboard-title-col"
                    >
                      <Row className="onboard-title">{employee.full_name}</Row>
                      <Row className="onboard-designation">
                        {employee?.Designation?.designation
                          ? generateFormatedId(
                            employee?.Designation?.designation,
                            employee.cnic
                          )
                          : " "}
                      </Row>
                    </Col>
                    <Col
                      xxl={5}
                      xl={5}
                      lg={5}
                      md={5}
                      sm={6}
                      xs={6}
                      className="onboard-data-col"
                    >
                      <div className="onboard-join-date">
                        {moment(employee?.joining_date).format("MMM D, YYYY")}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="onboarding-nodata">
            <p className="no-data-text no-data-container">
              No new members this month 🎈
            </p>{" "}
          </div>
        )}
      </div>
    </Card>
  );
};

export default OnboardingDetails;
