import React from "react";
import { Table, Modal } from "antd";
import CustomTable from "../../../../../common/CustomTable";

interface Absentees {
  absentDates: { date: string; day: string }[];
  onClose: () => void;
}

const AbsenteesModal: React.FC<Absentees> = ({ absentDates, onClose }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
  ];

  return (
    <Modal
      title="Absent Dates"
      open={true}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <CustomTable
        dataSource={absentDates}
        columns={columns}
        rowKey={(record) => record.date}
        pagination={false}
      />
    </Modal>
  );
};

export default AbsenteesModal;
