import { Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { capitalizeWords } from "../../../../common/validation";

const EmployeePaySlipDownload = ({ paySlipData }: any) => {
  const payslipPDFTableRef = useRef(null);
  const [dynamicAllowanceFields, setDynamicAllowanceFields] = useState<any[]>(
    []
  );

  const knownFields = [
    "Account Name",
    "Account Number",
    "Base Salary",
    "Code",
    "Currency",
    "EOBI",
    "Employee",
    "Gender",
    "Gross Deduction",
    "Gross Salary",
    "Leave Deduction",
    "Net Salary",
    "Provident Fund",
    "Provident Fund Percentage",
    "SRNO",
    "Tax",
    "Tax Percentage",
    "Total Allowance Claim",
    "Total Over Time",
    "Total Short Hours",
    "Company Email",
    "Company Location",
    "Company Name",
    "Employee Department",
    "Employee Designation",
    "Employee Joining Date",
  ];

  useEffect(() => {
    preparingDynamicFieldsData();
  }, [paySlipData.SRNO]);
  const preparingDynamicFieldsData = () => {
    const dymaicFields = Object.keys(paySlipData)
      .map((key) => {
        if (!knownFields.includes(key)) {
          return {
            title: key,
            value: paySlipData[key],
          };
        }
        return undefined;
      })
      .filter((field) => field !== undefined);
    setDynamicAllowanceFields(dymaicFields);
  };

  return (
    <div>
      {" "}
      <Row gutter={16}>
        <div ref={payslipPDFTableRef} className="preview-slip-main-div">
          <table className="payslip-preview-table employee-payslip-download-font">
            <thead className="payslip-preview-header">
              <tr className="payslip-preview-table-heading-details-row">
                <td colSpan={5}>
                  <span className="payslip-preview-table-heading-details employee-payslip-download-font">
                    Employee Information
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>Name</td>
                <td colSpan={3} className="payslip-preview-table-amount-col">
                  {paySlipData.Employee && paySlipData["Employee"]}
                </td>
              </tr>
              {paySlipData.Gender ? (
                <tr>
                  <td colSpan={3}>Gender</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData.Gender &&
                      capitalizeWords(paySlipData["Gender"])}
                  </td>
                </tr>
              ) : null}
              {/* designaiotn */}
              {paySlipData["Employee Designation"] ? (
                <tr>
                  <td colSpan={3}>Designation</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Employee Designation"] &&
                      capitalizeWords(paySlipData["Employee Designation"])}
                  </td>
                </tr>
              ) : null}
              {/* department */}
              {paySlipData["Employee Department"] ? (
                <tr>
                  <td colSpan={3}>Department</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Employee Department"] &&
                      capitalizeWords(paySlipData["Employee Department"])}
                  </td>
                </tr>
              ) : null}
              {/* Date of Joining */}
              {paySlipData["Employee Joining Date"] ? (
                <tr>
                  <td colSpan={3}>Date of Joining</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Employee Joining Date"] &&
                      capitalizeWords(paySlipData["Employee Joining Date"])}
                  </td>
                </tr>
              ) : null}
              <tr className="payslip-preview-table-heading-details-row">
                <td colSpan={5}>
                  <span className="payslip-preview-table-heading-details employee-payslip-download-font">
                    Employer Information
                  </span>
                </td>
              </tr>
              {/* Company Name */}
              {paySlipData["Company Name"] ? (
                <tr>
                  <td colSpan={3}>Company Name</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Company Name"] &&
                      capitalizeWords(paySlipData["Company Name"])}
                  </td>
                </tr>
              ) : null}
              {/* Company Address: */}
              {paySlipData["Company Location"] ? (
                <tr>
                  <td colSpan={3}>Company Address</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Company Location"] &&
                      capitalizeWords(paySlipData["Company Location"])}
                  </td>
                </tr>
              ) : null}
              {/* Contact Information: */}
              {paySlipData["Company Email"] ? (
                <tr>
                  <td colSpan={3}>Contact Information</td>
                  <td colSpan={3} className="payslip-preview-table-amount-col">
                    {paySlipData["Company Email"] &&
                      capitalizeWords(paySlipData["Company Email"])}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th colSpan={3}>Earnings</th>
                <th colSpan={2}>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="payslip-preview-table-earnings-col" colSpan={3}>
                  Account Name{" "}
                </td>
                <td className="payslip-preview-table-amount-col" colSpan={2}>
                  {paySlipData["Account Name"]
                    ? paySlipData["Account Name"]
                    : 0}
                </td>
              </tr>
              {paySlipData["Account Number"] !== "0" && (
                <tr>
                  <td
                    className="payslip-preview-table-earnings-col"
                    colSpan={3}
                  >
                    Account Number{" "}
                  </td>
                  <td className="payslip-preview-table-amount-col" colSpan={2}>
                    {paySlipData["Account Number"]
                      ? paySlipData["Account Number"]
                      : 0}
                  </td>
                </tr>
              )}

              <tr>
                <td className="payslip-preview-table-earnings-col" colSpan={3}>
                  Basic Salary{" "}
                </td>
                <td className="payslip-preview-table-amount-col" colSpan={2}>
                  {`${
                    paySlipData["Base Salary"] ? paySlipData["Base Salary"] : 0
                  }${" "}${
                    paySlipData["Currency"] ? paySlipData["Currency"] : ""
                  }`}
                </td>
              </tr>

              {paySlipData["Provident Fund"] !== "0" && (
                <>
                  <tr className="payslip-preview-table-heading-details-row">
                    <td colSpan={5}>
                      <span className="payslip-preview-table-heading-details employee-payslip-download-font">
                        Provident Fund on Basic Salary
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      {paySlipData["Provident Fund Percentage"]
                        ? paySlipData["Provident Fund Percentage"]
                        : "0"}{" "}
                      % Provident Fund will be deducted from the Basic Salary
                    </td>
                    <th
                      colSpan={2}
                      className="payslip-preview-table-amount-col"
                    >
                      -
                      {paySlipData["Provident Fund"]
                        ? paySlipData["Provident Fund"]
                        : "0"}
                      {`${" "}${
                        paySlipData["Currency"] ? paySlipData["Currency"] : ""
                      }`}
                    </th>
                  </tr>
                </>
              )}
              {paySlipData?.EOBI !== 0 && paySlipData?.EOBI !== null && (
                <>
                  <tr className="payslip-preview-table-heading-details-row">
                    <td colSpan={5}>
                      <span className="payslip-preview-table-heading-details employee-payslip-download-font">
                        EOBI Details
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>EOBI will be deducted from the Salary</td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      - {paySlipData["EOBI"] ? paySlipData["EOBI"] : 0}{" "}
                      {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                    </th>
                  </tr>
                </>
              )}

              {paySlipData["Total Allowance Claim"] && (
                <>
                  <tr className="payslip-preview-table-heading-details-row">
                    <td colSpan={5}>
                      <span className="payslip-preview-table-heading-details employee-payslip-download-font">
                        All Given Allowance Details
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={3}>Name</th>
                    <th
                      colSpan={2}
                      className="payslip-preview-table-amount-col"
                    >
                      Total Price
                    </th>
                  </tr>
                  {dynamicAllowanceFields?.map((item: any) => (
                    <tr>
                      <td colSpan={3}>{item.title}</td>
                      <td
                        colSpan={2}
                        className="payslip-preview-table-amount-col"
                      >
                        {item.value ? item.value : 0}{" "}
                        {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <th colSpan={3}>Total</th>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      +
                      {paySlipData["Total Allowance Claim"]
                        ? paySlipData["Total Allowance Claim"]
                        : 0}{" "}
                      {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                    </th>
                  </tr>
                </>
              )}

              {paySlipData["Tax"] !== "0" && (
                <>
                  <tr className="salary-slip-table-heading-details-row">
                    <td colSpan={5}>
                      <span className="salary-slip-table-heading-details employee-payslip-download-font">
                        Tax Details
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      Based on the chosen taxation,{" "}
                      {paySlipData["Tax Percentage"] || 0} % will be deducted
                      from the Basic Salary
                    </td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      {-paySlipData["Tax"] || "0"}{" "}
                      {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                    </th>
                  </tr>
                </>
              )}

              {paySlipData["Total Over Time"] !== "0" && (
                <>
                  <tr className="salary-slip-table-heading-details-row">
                    <td colSpan={5}>
                      <span className="salary-slip-table-heading-details employee-payslip-download-font">
                        Other Claims
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Over-Time</td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      +{paySlipData["Total Over Time"]}{" "}
                      {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                    </th>
                  </tr>
                </>
              )}

              <tr className="salary-slip-table-heading-details-row">
                <td colSpan={5}>
                  <span className="salary-slip-table-heading-details employee-payslip-download-font">
                    Other Deductions
                  </span>
                </td>
              </tr>
              {paySlipData["Total Short Hours"] !== 0 && (
                <tr>
                  <td colSpan={3}>Short Hours</td>
                  <th colSpan={2} className="salary-slip-table-amount-col">
                    -{paySlipData["Total Short Hours"]}{" "}
                    {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                  </th>
                </tr>
              )}
              {paySlipData["Leave Deduction"] !== 0 && (
                <tr>
                  <td colSpan={3}>Leave Deductions</td>
                  <th colSpan={2} className="salary-slip-table-amount-col">
                    -{paySlipData["Leave Deduction"]}{" "}
                    {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                  </th>
                </tr>
              )}
              <tr>
                <th colSpan={3}>Gross Salary</th>
                <th colSpan={2} className="salary-slip-table-amount-col">
                  {paySlipData["Gross Salary"]
                    ? paySlipData["Gross Salary"]
                    : "0"}{" "}
                  {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Gross Deduction</th>
                <th colSpan={2} className="salary-slip-table-amount-col">
                  {paySlipData["Gross Deduction"]
                    ? paySlipData["Gross Deduction"]
                    : "0"}{" "}
                  {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                </th>
              </tr>
              <tr>
                <th colSpan={3}>Total Payable Salary</th>
                <th colSpan={2} className="salary-slip-table-amount-col">
                  {paySlipData["Net Salary"] ? paySlipData["Net Salary"] : "0"}{" "}
                  {paySlipData["Currency"] ? paySlipData["Currency"] : ""}
                </th>
              </tr>
            </tbody>
          </table>
          <strong className="employee-payslip-download-font employee-payslip-bottom-row">
            This document is system generated and does not require any
            signatures or the company’s stamp to be considered valid.
          </strong>
        </div>
      </Row>
    </div>
  );
};

export default EmployeePaySlipDownload;
