import React from "react";

import RegnationListView from "./ListView";

const ResignationPolicy: React.FC = () => {

  return (
    <div>
       <RegnationListView />
    </div>
  );
};

export default ResignationPolicy;
