import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

/**
 * Fetches the employee profile completion progress filters based on the provided filters.
 * @param {any} filters - The filters to apply to the query.
 * @returns {Promise<any>} A promise that resolves to the result of the query.
 */
export const fetchEmployeesProfileCompletionProgressfilters = async (
  filters: any,
  startIndex: number,
  endIndex: number,
  organization_id: any,
  empoyeeStatus: string
) => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("calculateemployeeprofilecompletionprogress")
      .select("*")
      .eq("organization_id", organization_id)
      .range(startIndex, endIndex);
    if (filters.companyId !== null) {
      query = query.eq("company_id", filters.companyId);
    }
    if (filters.branchId !== null) {
      query = query.eq("branch_id", filters.branchId);
    }

    if (filters.employeeName !== null) {
      query = query.ilike("full_name", `%${filters.employeeName}%`);
    }

    if (filters.departmentName !== null) {
      query = query.ilike(`department_name`, `%${filters.departmentName}%`);
    }

    if (filters.gender !== "all") {
      query = query.eq("gender", filters.gender);
    }

    if (empoyeeStatus !== "all") {
      query = query.ilike("status", empoyeeStatus);
    }

    if (filters.worktype !== "all") {
      query = query.eq("work_type", filters.worktype);
    }
    if (filters.designation !== null) {
      query = query.ilike("designation_name", `%${filters.designation}%`);
    }
    if (filters.jobTitle !== null) {
      query = query.ilike("job_title_name", `%${filters.jobTitle}%`);
    }
    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const fetchTotalEmployeesNumber = async (
  filters: any,
  organization_id: any,
  employeeStatusSelected: string
) => {
  try {
    let query = supabase_client_for_Org_schema(organization_id)
      .from("calculateemployeeprofilecompletionprogress")
      .select("employee_id")
      .eq("organization_id", organization_id);

    if (filters.companyId !== null) {
      query = query.eq("company_id", filters.companyId);
    }
    if (filters.branchId !== null) {
      query = query.eq("branch_id", filters.branchId);
    }

    if (filters.employeeName !== null) {
      query = query.ilike("full_name", `%${filters.employeeName}%`);
    }

    if (filters.departmentName !== null) {
      query = query.ilike(`department_name`, `%${filters.departmentName}%`);
    }

    if (filters.gender !== "all") {
      query = query.eq("gender", filters.gender);
    }
    if (employeeStatusSelected !== "all") {
      query = query.ilike("status", employeeStatusSelected);
    }

    if (filters.worktype !== "all") {
      query = query.eq("work_type", filters.worktype);
    }
    if (filters.designation !== null) {
      query = query.ilike("designation_name", `%${filters.designation}%`);
    }
    if (filters.jobTitle !== null) {
      query = query.ilike("job_title_name", `%${filters.jobTitle}%`);
    }
    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};
