import React, { useEffect, useState } from "react";
import { Avatar, Card, Row, Col } from "antd";
import d_dp from "../../../assets/images/blankProfile.png";
import "./index.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { ProgressLoader } from "../../../common/ReactLoader";
import { MdCelebration } from "react-icons/md";
import { generateFormatedId } from "../../../common/validation";

interface ByWorkAnniversaryProps {
  employeeData: any[];
  companies: any[];
  branches: any[];
  employeeDataShow: boolean;
}
const WorkAnniversary: React.FC<ByWorkAnniversaryProps> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [companiesOption, setCompaniesOption] = useState<any[]>(
    [] || props?.companies
  );
  const [branchOptions, setBranchOptions] = useState<any[]>(
    [] || props?.branches
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [employees, setEmployees] = useState<any[] | null>(null);

  useEffect(() => {
    filterUpcomingAnniversary(props.employeeData);
  }, [props.employeeData]);
  useEffect(() => {
    filterbranches();
  }, [selectedCompany]);
  useEffect(() => {
    setCompaniesOption(props.companies);
    setBranchOptions(props.branches);
    filterbranches();
  }, [props.companies, props.branches]);
  useEffect(() => {
    filterEmployeeData();
  }, [selectedCompany, selectedBranch]);
  const filterEmployeeData = () => {
    let tempData = props.employeeData;
    if (selectedCompany && selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) =>
          item?.company_id == selectedCompany &&
          item?.branch_id == selectedBranch
      );
    } else if (selectedCompany) {
      tempData = props?.employeeData?.filter(
        (item) => item?.company_id == selectedCompany
      );
    } else if (selectedBranch) {
      tempData = props?.employeeData?.filter(
        (item) => item?.branch_id == selectedBranch
      );
    }
    filterUpcomingAnniversary(tempData);
  };
  const filterbranches = () => {
    if (props.branches && selectedCompany) {
      const filterbranches = props.branches.filter(
        (item) => item.companyId == selectedCompany
      );
      setBranchOptions(filterbranches);
    } else {
      setBranchOptions(props.branches);
    }
  };

  const filterUpcomingAnniversary = (data: any) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const upcomingAnniversaries = data
      ?.filter((person: any) => {
        if (
          !person.joining_date ||
          new Date(person.joining_date).toString() === "Invalid Date"
        ) {
          return false;
        }
        const personJoinDate = new Date(person.joining_date);
        const currentYear = currentDate.getFullYear();
        const joinYear = personJoinDate.getFullYear();
        const joinMonth = personJoinDate.getMonth();
        return joinMonth === currentMonth && joinYear !== currentYear;
      })
      .sort((a: any, b: any) => {
        const dayA = new Date(a.joining_date).getDate();
        const dayB = new Date(b.joining_date).getDate();
        return dayA - dayB;
      });

    setEmployees(upcomingAnniversaries);
  };

  return (
    <>
      <Card
        className="main-anniversary-container dasboard-cards"
        title={"Work Anniversaries"}
        bordered={true}
      >
        <div className="anniversary-scrollable-content">
          {props.employeeDataShow ? (
            <ProgressLoader />
          ) : employees && employees?.length > 0 ? (
            <div>
              {employees?.map((employee: any, index: number) => (
                <div key={employee.id}>
                  <div className="anniversary-custom-new-container">
                    <div className="anniversary-custom-new-avatarCol">
                      <Avatar
                        shape="circle"
                        size={{
                          xxl: 60,
                          xl: 60,
                          lg: 60,
                          md: 60,
                          sm: 60,
                          xs: 60,
                        }}
                        src={employee.avatar_url || d_dp}
                      />
                      <MdCelebration className="anniversary-custom-new-newWord" />
                    </div>
                    <Row className="anniversary-card-data-row">
                      <Col
                        xxl={19}
                        xl={19}
                        lg={19}
                        md={19}
                        sm={18}
                        xs={18}
                        className="anniversary-title-col"
                      >
                        <Row className="anniversary-title">
                          {employee.full_name}
                        </Row>
                        <Row className="anniversary-designation">
                          {employee?.Designation?.designation
                            ? generateFormatedId(
                                employee?.Designation?.designation,
                                employee.cnic
                              )
                            : " "}
                        </Row>
                      </Col>
                      <Col
                        xxl={5}
                        xl={5}
                        lg={5}
                        md={5}
                        sm={6}
                        xs={6}
                        className="anniversary-data-col"
                      >
                        <div className="anniversary-join-date">
                          {employee?.joining_date &&
                            moment(employee.joining_date)
                              .set("year", new Date().getFullYear())
                              .format("MMM D, YYYY")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-data-icon-div">
              <p className="no-data-text no-data-container">
                No work anniversaries this month 🎂
              </p>{" "}
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default WorkAnniversary;
