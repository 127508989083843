import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Space,
  Tooltip,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { capitalizeWords } from "../../../../common/validation";
import { fetchTaxationbyIdApi, updateTaxationApi } from "../../services";
import CustomAlert from "../../../../common/Alert";
import { DeleteOutlined } from "@ant-design/icons";
import CustomTable from "../../../../common/CustomTable";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

interface TaxationEditViewprops {
  recordId: string;
  onCancel: () => void;
}

const TaxationEditView: React.FC<TaxationEditViewprops> = (props) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const [form] = Form.useForm();
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [tableData, setTableData] = useState<any>([]);
  const [recordIndex, setRecordIndex] = useState<number>(0);
  const [taxApplyFrom, setTaxApplyFrom] = useState<string>();
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const organizationId = loggedInEmployee?.employee.organization_id;

  useEffect(() => {
    fetchTaxationbyId();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const fetchTaxationbyId = async () => {
    const data = {
      organizationId: organizationId,
      id: props.recordId,
    };
    const res = await fetchTaxationbyIdApi(data);
    if (res.data) {
      setTableData(res.data.slabs);
      setRecordIndex(res.data.slabs.length);
      form.setFieldsValue({
        [`taxApplyFrom_${res.data.slabs.length + 1}`]: "",
        [`taxApplyTo_${res.data.slabs.length + 1}`]: "",
        [`taxPercentage_${res.data.slabs.length + 1}`]: "",
        taxationversion: res.data.taxation_version_name,
        taxationEndMonth: dayjs(res.data?.taxation_end_date),
        taxationStartMonth: dayjs(res.data?.taxation_start_date),
      });
      res.data.slabs.forEach((record: any, index: number) => {
        form.setFieldsValue({
          [`taxApplyFrom_${record.id}`]: record.taxApplyFrom,
          [`taxApplyTo_${record.id}`]: record.taxApplyTo,
          [`taxPercentage_${record.id}`]: record.taxPercentage,
          [`fix_amount_${record.id}`]: record.fix_amount,
        });
      });
    }
  };

  const onFinish = async () => {
    form
      .validateFields([
        "taxationversion",
        "taxationStartMonth",
        "taxationEndMonth",
      ])
      .then(async () => {
        if (tableData.length > 0) {
          const data = {
            taxation_version_name: capitalizeWords(
              form.getFieldValue("taxationversion")
            ),
            slabs: tableData,
            id: props.recordId,
            taxation_start_date: form.getFieldValue("taxationStartMonth"),
            taxation_end_date: form.getFieldValue("taxationEndMonth"),
          };
          const res = await updateTaxationApi(data, organizationId);
          if (res.status == 201 || res.status == 204) {
            form.resetFields();
            setTableData([]);
            setTaxApplyFrom("");
            props.onCancel();
            setshowAlert(true);
            setAlertMessage("");
            setAlertDescription("Taxation Version Details Update Sucessfully");
            setAlertType("success");
          } else {
            setshowAlert(true);
            setAlertMessage("");
            setAlertDescription(
              res.error?.message ||
                "oops we are facing some temporary issue with your request"
            );
            setAlertType("error");
            timeoutRef.current = setTimeout(() => {
              setshowAlert(false);
            }, 3000);
          }
        } else {
          setshowAlert(true);
          setAlertMessage("");
          setAlertDescription("Please Add At-least One Taxation details");
          setAlertType("error");
          timeoutRef.current = setTimeout(() => {
            setshowAlert(false);
          }, 3000);
        }
      })
      .catch((errorInfo) => {
        setshowAlert(true);
        setAlertMessage("");
        setAlertDescription("Please fill in the required fields correctly");
        setAlertType("error");
        timeoutRef.current = setTimeout(() => {
          setshowAlert(false);
        }, 3000);
      });
  };
  const handleDelete = (rid: number) => {
    const updatedTableData = tableData.filter((item: any) => item.id != rid);
    setTableData(updatedTableData);
  };
  const handleAdd = (values: any) => {
    const newRecord = {
      id: recordIndex + 1,
      taxApplyFrom: form.getFieldValue(`taxApplyFrom_${recordIndex + 1}`),
      taxApplyTo: form.getFieldValue(`taxApplyTo_${recordIndex + 1}`),
      taxPercentage: form.getFieldValue(`taxPercentage_${recordIndex + 1}`),
    };
    const tempList = [...tableData, newRecord];
    setTableData(tempList);
    setRecordIndex(tempList.length);
    form.setFieldsValue({
      [`taxApplyTo_${tempList.length + 1}`]: 0,
      [`taxApplyFrom_${tempList.length + 1}`]: 0,
      [`taxPercentage_${tempList.length + 1}`]: 0,
    });
  };
  const CustomValidator = (rule: any, value: number, callback: any) => {
    if (taxApplyFrom && value && value < parseInt(taxApplyFrom)) {
      callback("Must be greater than tax apply starting Value");
    } else {
      callback();
    }
  };
  const handleInputChange = (value: string, dataIndex: string, id: number) => {
    const updatedTableData = [...tableData];
    const recordToUpdate = updatedTableData.find((record) => record.id == id);
    if (recordToUpdate) {
      recordToUpdate[dataIndex] = value;
      setTableData(updatedTableData);
    }
  };
  const tableColumns = [
    {
      title: "Tax will apply if Over",
      dataIndex: "taxApplyFrom",
      key: "taxApplyFrom",
      render: (_: any, record: any) => (
        <Form.Item
          name={`taxApplyFrom_${record.id}`}
          rules={[
            {
              required: true,
              message: "Please Enter a % of applied",
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: "Please Enter a Positive Number",
            },
          ]}
        >
          <Input
            placeholder="Enter a value..."
            size="large"
            onChange={(e) => {
              setTaxApplyFrom(e.target.value);
              handleInputChange(e.target.value, "taxApplyFrom", record.id);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Not Over",
      dataIndex: "taxApplyTo",
      key: "taxApplyTo",
      render: (_: any, record: any) => (
        <Form.Item
          name={`taxApplyTo_${record.id}`}
          rules={[
            {
              required: true,
              message: "Please Enter a Not Over Value",
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: "Please Enter a Positive Number",
            },
            {
              validator: CustomValidator,
            },
          ]}
        >
          <Input
            placeholder="Enter a value..."
            size="large"
            onChange={(e) =>
              handleInputChange(e.target.value, "taxApplyTo", record.id)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "% of applied",
      dataIndex: "taxPercentage",
      key: "taxPercentage",
      render: (_: any, record: any) => (
        <Form.Item
          name={`taxPercentage_${record.id}`}
          rules={[
            {
              required: true,
              message: "Please Enter a % of applied",
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: "Please Enter a Positive Number",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter tax percentage..."
            onChange={(e) =>
              handleInputChange(e.target.value, "taxPercentage", record.id)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "fix_amount",
      dataIndex: "fix_amount",
      key: "fix_amount",
      render: (_: any, record: any) => (
        <Form.Item
          name={`fix_amount_${record.id}`}
          rules={[
            {
              pattern: /^\d+(\.\d+)?$/,
              message: "Please Enter a Positive Number",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter Fix Amount..."
            onChange={(e) =>
              handleInputChange(e.target.value, "fix_amount", record.id)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => (
        <Space size="middle">
          <a onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </a>
        </Space>
      ),
    },
  ];
  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date ? moment(date.toISOString()) : null);
    form.setFieldsValue({ taxationEndDate: null });
  };
  const disableEndDate = (current: Dayjs) => {
    return startDate ? current && current < startDate.endOf("month") : true;
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleAdd}
        >
          <Row>
            <Col className="modal-content">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="taxationversion"
                    label="Enter Taxation version"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter a Taxation version",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Taxation version" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="taxationStartMonth"
                    label="Taxation Starting Month"
                    rules={[
                      {
                        required: true,
                        message: "Please select a starting date of Taxation.",
                      },
                    ]}
                  >
                    <DatePicker
                      className="taxation-date-picker"
                      picker="month"
                      onChange={handleStartDateChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="taxationEndMonth"
                    label="Taxation Ending Month"
                    rules={[
                      {
                        required: true,
                        message: "Please select a ending date of Taxation.",
                      },
                    ]}
                  >
                    <DatePicker
                      className="taxation-date-picker"
                      picker="month"
                      disabledDate={disableEndDate}
                      disabled={!startDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} md={6}>
                  <Form.Item
                    name={`taxApplyFrom_${recordIndex + 1}`}
                    label="Tax will apply(if Over)"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter a Tax will apply(if Over)",
                      },
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => setTaxApplyFrom(e.target.value)}
                      placeholder="Tax will apply if Over"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={5}>
                  <Form.Item
                    name={`taxApplyTo_${recordIndex + 1}`}
                    label="Not Over"
                    rules={[
                      {
                        required: taxApplyFrom ? true : false,
                        message: "Please Enter a Not Over Value",
                      },
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                      {
                        validator: CustomValidator,
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Not Over" />
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item
                    name={`taxPercentage_${recordIndex + 1}`}
                    label="% of applied"
                    rules={[
                      {
                        required: taxApplyFrom ? true : false,
                        message: "Please Enter a % of applied",
                      },
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="% of applied" />
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item
                    name={`fix_amount_${recordIndex + 1}`}
                    label="Fix Amount"
                    rules={[
                      {
                        pattern: /^\d+(\.\d+)?$/,
                        message: "Please Enter a Positive Number",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter Fix Amount" />
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item label=" ">
                    <Tooltip title="This Action Will Not Save Details To Database. it will add Details in Table Blow">
                      <Button
                        className="global-btn-width"
                        type="primary"
                        htmlType="submit"
                        size="large"
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <CustomTable dataSource={tableData} columns={tableColumns} />
              </Row>
            </Col>
          </Row>

          <Row className="global-modal-bottom-row">
            <Col span={24} className="edit-taxtion-payroll-modal-btn-container">
              <Button
                className="global-btn-width"
                type="primary"
                onClick={() => onFinish()}
                size="large"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {showAlert && (
        <CustomAlert
          type={alertType}
          description={alertDescription}
          message={alertMessage}
          showIcon={true}
          autoCloseDuration={3000}
        />
      )}
    </>
  );
};

export default TaxationEditView;
