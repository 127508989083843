import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const getBranchesByCompanyId = async (
  companyId: string,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const setKPIAttributesData = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    const responses = await Promise.all(
      data.map(async (item: any) => {
        if (item.id) {
          return await supabase_client_for_Org_schema(organizationId)
            .from("kpi_attribute")
            .update({
              name: item.name,
              percentage: item.percentage,
            })
            .match({ id: item.id });
        } else {
          return await supabase_client_for_Org_schema(organizationId)
            .from("kpi_attribute")
            .insert([
              {
                name: item.name,
                percentage: item.percentage,
                organization_id: item.organizationId,
                company_id: item.companyId,
                branch_id: item.branchId,
              },
            ]);
        }
      })
    );

    if (responses) {
      checkKpiThePercentage(data, organizationId);
      return responses;
    }
  } catch (error) {
    return catch_return;
  }
};

export const checkKpiThePercentage = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .select("*")
      .eq("company_id", data?.companyId);

    if (data.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const editKpiAttributeNames = async (
  record: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .update({
        name: record.attributeName,
        percentage: record.percentage,
      })
      .eq("id", record.id);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const deleteKpiAttributeNames = async (
  recordId: any,
  organizationId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .delete()
      .eq("id", recordId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getKpiAttributeNames = async (
  data: any,
  organizationId: string
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("kpi_attribute")
      .select("id, name, percentage,branch_id")
      .eq("company_id", data?.companyId);

    if (data.branchId) {
      query = query.eq("branch_id", data?.branchId);
    }

    const response = await query;

    return response;
  } catch (error) {
    return catch_return;
  }
};
