export const notificationDetailsList = [
  {
    type: "leaveApproval",
    title: "Leave Approval by {Name}",
    details:
      "Your leave request has been approved by {Name}. Please check under Leave section for more details.",
    link: "leaveapproval",
  },
  {
    type: "leaveRejection",
    title: "Leave Rejection By {Name}",
    details:
      "Your leave request has been declined by {Name}. Please check under Leave section for more details.",
    link: "leaveapproval",
  },
  {
    type: "leaveRequest",
    title: "{Name} Leave Request Received",
    details:
      "A new leave request from {Name} has been received. Please review and process it accordingly.",
    link: "leaveapproval",
  },
  {
    type: "WorkAnniversary",
    title: "Today, {Name} has Work Anniversary",
    details: "Reminder: Celebrate your colleagues {Name}' work milestones.",
    link: "",
  },
  {
    type: "todaybirthday",
    title: "Say Happy Birthday to {Name}",
    details:
      "Don't forget to wish a happy birthday to Your team members born Today!",
    link: "",
  },
  {
    type: "tomorrowbirthday",
    title: "Tomorrow is {Name} birthday",
    details: "Don't forget to wish a happy birthday! to {Name}, Tomorrow",
  },
  {
    type: "Profileupdate",
    title: "Profile Update",
    details:
      "has updated their profile. Please review the changes for accuracy and completeness.",
  },
  {
    type: "EmployeeCreate",
    title: "Employee Creation",

    details:
      "A new team member has joined our company family! Let's give them a warm welcome.",
  },
  {
    type: "joiningDateconfirmation",
    title: "Joining Date Confirmation",
    details:
      "has confirmed their joining date. Please review and update the records accordingly.",
  },

  {
    type: "probationEnd",
    title: "Congratulations on Completing Your Probation!",
    details:
      "You've successfully completed your probation period! We're excited about your continued contributions to our team.",
  },
  {
    type: "probationDateChange",
    title: "Probation Period Date Update",
    details:
      "Please note that there has been a change to the end date of your probation period. Check your portal for the new details.",
  },
  {
    type: "internshipEnd",
    title: "Internship Successfully Completed",
    details:
      "Well done on completing your internship! We appreciate your hard work and dedication during your time with us.",
  },
  {
    type: "internshipDateChange",
    title: "Internship Period Adjustment",
    details:
      "Your internship duration has been updated. Check your portal for the new details.",
  },
  {
    type: "termination",
    title: "Termination Initiated",
    details:
      "Dear Employee, unfortunately you have been terminated. Please refer to the concerned department for more details",
  },
  {
    type: "canceltermination",
    title: "Termination Cancelled",
    details: "Dear Employee, your termination has been cancelled.",
  },
];
