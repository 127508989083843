import React, { useEffect, useRef, useState } from "react";
import { Steps, Button, Form, Input, Row, Col, Select } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import { PhoneNumberUtil } from "google-libphonenumber";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { getCountries, getStates, getCities } from "vk-country-state-city";
import { domaincheck } from "../../../../common/validation";
import CustomAlert from "../../../../common/Alert";

import {
  branchManagerNames,
  companyOfBranch,
  insertIntoBranchesTable,
} from "../services";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getAllCompanies } from "../../../../services/Global";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const { Step } = Steps;
interface formData {
  companyname: string;
  companyid: null | string;
  branchName: string;
  industryType: string;
  legalEntityType: string;
  organizationName: string;
  registrationNumber: number;
  phoneNumber: string;
  email: string;
  password: string;
  branchCode: string;
  branchManagerName: string;
  city: string;
  province: string;
  country: string;
  street: string;
}
interface companiesOptiontype {
  label: string;
  id: string;
}

interface props {
  onCancel: (status: string) => void;
}

const CreateBranchForm: React.FC<props> = ({ onCancel }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee)?.logedInemployee;
  const organizationId =
    useSelector(selectLogedEmployee)?.logedInemployee?.employee
      ?.organization_id;
  const [currentStep, setCurrentStep] = useState(0);
  const [companiesOption, setCompaniesOption] = useState<companiesOptiontype[]>(
    []
  );
  const [branchName, setBranchName] = useState<string>("");
  const currentbranchcode = useRef<string>("");
  const [countryOptions, setCountryOptions] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [managerNames, setManagerNames] = useState<string[] | any>([]);
  const [branchCompany, setBranchCompany] = useState<any>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);
  const [branchManagerSelectLoading, setBranchManagerSelectLoading] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<formData>({
    companyname: "",
    companyid: null,
    branchName: "",
    industryType: "",
    legalEntityType: "",
    organizationName: "",
    registrationNumber: 0,
    phoneNumber: "",
    email: "",
    password: "",
    branchCode: "",
    branchManagerName: "",
    city: " ",
    province: " ",
    country: "",
    street: "",
  });

  const branchParentOrg: any = loggedInEmployee?.employee?.organization_name;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountryOptions(countries);
    }
    fetchCountries();
    fetchCompanies();
    fetchBranchManagerNames();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      const states = await getStates(selectedCountry);
      setStates(states);
    }
    fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    getCompanyOfBranch();
  }, [formData.companyid]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedCountry && selectedState) {
        const cities = await getCities(selectedCountry, selectedState);

        setCities(cities);
      }
    }
    fetchCities();
  }, [selectedState]);

  const getCompanyOfBranch = async () => {
    const response = await companyOfBranch(formData.companyid, organizationId);
    if (response.data) {
      setBranchCompany(response.data.email);
    }
  };

  useEffect(() => {
    generateId(formData.companyid);
  }, [formData.companyid, companiesOption, branchName]);

  const fetchBranchManagerNames = async () => {
    try {
      setBranchManagerSelectLoading(true);

      const response = await branchManagerNames(organizationId);
      if (response.data) {
        const names = response.data.map((item: any) => ({
          id: item.id,
          name: item.full_name,
        }));
        setManagerNames(names);
        setBranchManagerSelectLoading(false);
      }
      setBranchManagerSelectLoading(false);
    } catch (error) {
      setBranchManagerSelectLoading(false);
    }
  };

  const fetchCompanies = async () => {
    setCompanySelectLoading(true);
    const companies_data = await getAllCompanies(organizationId);
    if (companies_data.data) {
      const option: companiesOptiontype[] = companies_data.data?.map(
        (item) => ({
          id: item.id,
          label: item.name,
        })
      );
      setCompanySelectLoading(false);
      setCompaniesOption(option);
    }
    setCompanySelectLoading(false);
  };

  const generateId = (companyId: any) => {
    const matchingCompany = companiesOption.find(
      (item) => item.id === companyId
    );
    const companyLabel = matchingCompany?.label || "";
    const words = loggedInEmployee?.employee?.organization_name?.split(" ");
    const idPrefix = words?.map((word: any) => word[0]).join("");
    const random4Digits = generateRandom4DigitNumber();
    let code = "";

    code = `${idPrefix}-${companyLabel}-${branchName}-${random4Digits}`;

    if (formData.organizationName.charAt(0).toLowerCase() === "n") {
      formData.branchCode = code;
      currentbranchcode.current = code;
      form.setFieldValue("branchCode", code);
    } else {
      formData.branchCode = code;
      currentbranchcode.current = code;
      form.setFieldValue("branchCode", code);
    }
  };

  const handleFormChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleBranchReg = async () => {
    setButtonLoading(true);
    setshowAlert(false);
    try {
      const formValidaiton = await form.validateFields();
      if (
        !formValidaiton.errorFields ||
        formValidaiton.errorFields.length === 0
      ) {
        const payload = {
          name: formData.branchName,
          phone_number: formData.phoneNumber,
          head_manager_id: formData.branchManagerName,
          organization_Id: organizationId,
          company_id: formData.companyid,
          email: formData.email,
          unique_identifier: currentbranchcode.current,
          address: {
            street: formData.street,
            province: formData.province,
            city: formData.city,
            country: formData.country,
          },
        };
        const brancheres = await insertIntoBranchesTable(
          payload,
          organizationId
        );

        if (brancheres.data) {
          setButtonLoading(false);
          form.resetFields();
          setCurrentStep(0);
          onCancel("success");
        } else {
          setshowAlert(true);
          setButtonLoading(false);
          setAlertType("error");
        }
      }
    } catch (error) {
      setButtonLoading(false);
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const nextStep = async () => {
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {
      return error;
    }
  };

  const generateRandom4DigitNumber = () => {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const { Option } = Select;
  const steps = [
    {
      title: "Branch Information",
      content: (
        <Form layout="vertical" form={form} autoComplete="off">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item label="Parent Organization" name="organizationName">
                <Input
                  size="large"
                  defaultValue={branchParentOrg}
                  onChange={(e) =>
                    handleFormChange("organizationName", e.target.value)
                  }
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Company"
                name="companyname"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Company before proceeding.",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Company"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={(value) => {
                    setFormData({ ...formData, companyid: value });
                    generateId(value);
                  }}
                  loading={companySelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {companiesOption.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Branch Name"
                name="branchName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Branch Name",
                  },
                ]}
              >
                <Input
                  size="large"
                  onChange={(e) => {
                    setFormData({ ...formData, branchName: e.target.value });
                    setBranchName(e.target.value);
                  }}
                ></Input>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Branch Manager Name"
                name="branchManagerName"
                rules={[
                  {
                    required: true,
                    message: "Please enter the branch manager's name",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Enter Branch Manager's name"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={(value) =>
                    handleFormChange("branchManagerName", value)
                  }
                  loading={branchManagerSelectLoading}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {managerNames.map((manager: any) => (
                    <Option key={manager.id} value={manager.id}>
                      {manager.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email" },
                  {
                    type: "email",
                    message: "Enter a valid email address",
                  },
                  {
                    validator: (_, value) => {
                      if (value && !domaincheck(branchCompany, value)) {
                        return Promise.reject(
                          "Email domain not Match with comapany Email"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Contact Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value &&
                        value.length > 3 &&
                        !phoneUtil.isValidNumber(
                          phoneUtil.parseAndKeepRawInput(value)
                        )
                      ) {
                        return Promise.reject("Invalid phone number");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <PhoneInput
                  placeholder="Enter Phone Number"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                  defaultCountry="pk"
                  value={formData.phoneNumber}
                  onChange={(phone) => handleFormChange("phoneNumber", phone)}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="global-modal-bottom-row">
            <Form.Item>
              <Row className="company-buttons">
                <Button
                  size="large"
                  className="global-btn-width"
                  type="primary"
                  onClick={nextStep}
                >
                  Next
                </Button>
              </Row>
            </Form.Item>
          </div>
        </Form>
      ),
    },
    {
      title: "Address Details",
      content: (
        <Form layout="vertical" form={form} autoComplete="off">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Enter your country",
                  },
                ]}
              >
                <Select
                  placeholder="Enter country..."
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  onChange={(value: any) => {
                    setFormData({ ...formData, country: value });
                    setSelectedCountry(value);
                  }}
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                  showSearch
                  onFocus={() => {
                    const selectInput = document.querySelector(
                      ".ant-select-search__field"
                    );
                    if (selectInput) {
                      selectInput.setAttribute("autocomplete", "off");
                    }
                  }}
                >
                  {countryOptions.map((item: any) => (
                    <Option
                      key={item.iso2}
                      value={item.name}
                      autoComplete="off"
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="State/Province"
                name="province"
                rules={[
                  {
                    message: "Please enter the province",
                  },
                ]}
              >
                <Select
                  placeholder="Enter a state..."
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  value={formData.province}
                  onChange={(value: any) => {
                    setFormData({ ...formData, province: value });
                    setSelectedState(value);
                  }}
                  showSearch
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {states?.map((state: any) => (
                    <Option key={state.code} value={state.name}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    message: "Enter your city",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Enter City.."
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  value={formData.city}
                  onChange={(value: any) =>
                    setFormData({ ...formData, city: value })
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {cities.map((city: any) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Street Address"
                name="street"
                rules={[
                  {
                    required: true,
                    message: "Enter your street address",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Street Address"
                  value={formData.street}
                  onChange={(e) => handleFormChange("street", e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row className="global-modal-bottom-row branch-form-bottm-btns">
              <Button
                style={{ marginRight: "8px", marginLeft: "12px" }}
                onClick={() => setCurrentStep(currentStep - 1)}
                size="large"
                className="global-btn-width"
                type="default"
              >
                Previous
              </Button>

              <Button
                className="global-btn-width"
                type="primary"
                size="large"
                onClick={handleBranchReg}
                loading={buttonLoading}
              >
                Submit
              </Button>
            </Row>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <>
      <div className="global-card-inner-container">
        <Steps current={currentStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              description={`Step ${index + 1}`}
            />
          ))}
        </Steps>
        <div className="global-steps-container-margin">
          {steps[currentStep].content}
        </div>
      </div>

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default CreateBranchForm;
