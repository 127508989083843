import supabase, {
  supabase_client_for_Org_schema,
} from "../../../services/supabase";

//---------------------------------------------Create Designation--------------------------------
const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
export const fetchCompanies = async (organizationId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(organizationId)
      .from("company")
      .select("id, name")
      .eq("organization_Id", organizationId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const getBranches = async (
  companyId: string | number | null,
  orgId: string
) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("branch")
      .select("id, name")
      .eq("company_id", companyId);

    return response;
  } catch (error) {
    return catch_return;
  }
};

export const submitData = async (payload: any, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("designation")
      .insert(payload);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//---------------------------------------Designation List----------------------------------------

export const getData = async (data: any, orgId: string) => {
  try {
    const query = supabase_client_for_Org_schema(orgId)
      .from("designation")
      .select(`* , company(id, name), branch(name) `)
      .eq("organization_id", data.organizationId);

    if (data.searchInput) {
      query.ilike("designation", `%${data.searchInput}%`);
    }
    if (data.companyId) {
      query.eq("company_id", data.companyId);
    }

    const response = await query;
    return response;
  } catch (error) {
    return catch_return;
  }
};

export const handleDelete = async (id: string, orgId: string) => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("designation")
      .delete()
      .eq("id", id);
    return response;
  } catch (error) {
    return catch_return;
  }
};

//---------------------------------------Edit Designation Details-------------------------------

export const updateRecords = async (
  data: any,
  id: string | number | null,
  orgId: string
): Promise<any> => {
  try {
    const response = await supabase_client_for_Org_schema(orgId)
      .from("designation")
      .update(data)
      .eq("id", id);

    return response;
  } catch (error) {
    return catch_return;
  }
};
