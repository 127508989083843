import React, { useEffect, useState } from "react";
import "./index.css";
import { fetchAdvanceRequestDataForEmployee } from "../../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import CustomTable from "../../../../common/CustomTable";
import { SortOrder } from "antd/lib/table/interface";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Tag } from "antd";
import CreateAdvanceRequest from "../Create";
import { FiPlus } from "react-icons/fi";
import TextArea from "antd/es/input/TextArea";
import d_dp from "../../../../assets/images/blankProfile.png";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";

const AdvanceRequestHistory = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const employeeId = loggedInEmployee?.employeeId;
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRecordData, setSelectedRecordData] = useState<any>();
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);

  const [readAccess, setReadAccess] = useState<boolean>(false);
  const [createAccess, setCreateAccess] = useState<boolean>(false);
  const accessObj = useSelector(selectAccess);

  useEffect(() => {
    if (accessObj) {
      handleRadioChange();
    }
  }, []);

  const handleRadioChange = () => {
    const parentMenu = "Requests";

    const access = extractAccessRightObject(
      accessObj,
      parentMenu,
      "Advances Request"
    );
    setReadAccess(access?.readAccess);
    setCreateAccess(access?.createAccess);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (employeeId) {
        const response = await fetchAdvanceRequestDataForEmployee(
          organizationId,
          employeeId
        );
        if (response) {
          setTableData(response.data);
          setTableLoading(false);
        }
      }
    } catch (error) {
      setTableLoading(false);
    }
  };

  const columns = [
    {
      title: "Request Type",
      index: "request_type",
      key: "request_type",
      render: (_: any, record: any) => (
        <div>
          {record?.request_type === "withdrawProvidentFund"
            ? "Withdraw Provident Fund"
            : record?.request_type === "advanceSalary"
            ? "Advance Salary"
            : record?.request_type === "personalLoan"
            ? "Personal Loan"
            : null}
        </div>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a.request_type || "";
        const nameB = b.request_type || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Amount/Month Period",
      index: "amount",
      key: "amount",
      render: (_: any, record: any) => (
        <div>
          {record?.request_type === "advanceSalary"
            ? record?.start_date + " - " + record?.end_date
            : record?.amount}
        </div>
      ),
    },
    {
      title: "Next Requestee",
      index: "nextRequesteeEmployee",
      key: "nextRequesteeEmployee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={4} xxl={4} className="img-col">
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={
                  record?.nextRequesteeEmployee?.avatar_url
                    ? record?.nextRequesteeEmployee?.avatar_url
                    : d_dp
                }
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={19}
            xxl={19}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div>
              <strong>{record?.nextRequesteeEmployee?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Status",
      index: "status",
      key: "status",
      render: (_: any, record: any) => (
        <div>
          {record?.status === "rejected" ? (
            <Tag color="red">Rejected</Tag>
          ) : record?.status === "approved" ? (
            <Tag color="green">Approved</Tag>
          ) : (
            <Tag>Requested</Tag>
          )}
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <a onClick={() => viewModalOpenHanlder(record)}>
          <EyeOutlined />
        </a>
      ),
    },
  ];

  const viewModalOpenHanlder = (data: any) => {
    setShowViewModal(true);
    setSelectedRecordData(data);
  };
  const viewModalCloseHanlder = (data: any) => {
    setShowViewModal(false);
  };
  const createModalOpenHanlder = () => {
    setShowCreateModal(true);
  };
  const createModalCloseHanlder = () => {
    setShowCreateModal(false);
    fetchData();
  };

  return (
    <div
      className={`${
        employeeRole === "employee"
          ? ""
          : "global-request-container-styling global-requests-container"
      }`}
    >
      {employeeRole !== "employee" && createAccess && (
        <div className="advance-request-header">
          <span className="advance-request-heading">Advance Request</span>
          <Button
            size="large"
            type="primary"
            onClick={createModalOpenHanlder}
            icon={<FiPlus className="tab-header-icon" />}
          >
            Create
          </Button>
        </div>
      )}
      {readAccess && (
        <CustomTable
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
        />
      )}

      {employeeRole === "employee" && (
        <CustomTable
          loading={tableLoading}
          dataSource={tableData}
          columns={columns}
        />
      )}

      {showCreateModal && (
        <Modal
          open={showCreateModal}
          onCancel={createModalCloseHanlder}
          width={1000}
          title={
            <span className="global-modal-title">Create Advance Request</span>
          }
          className="global-modal-main-container"
          footer={false}
          destroyOnClose
        >
          <CreateAdvanceRequest
            createModalCloseHanlder={createModalCloseHanlder}
          />
        </Modal>
      )}

      {showViewModal && (
        <Modal
          open={showViewModal}
          onCancel={viewModalCloseHanlder}
          width={1000}
          title={
            <span className="global-modal-title">View Advance Request</span>
          }
          className="global-modal-main-container"
          footer={false}
          destroyOnClose
        >
          <div className="global-card-inner-container">
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="Request Type">
                    <Input
                      size="large"
                      disabled
                      value={
                        selectedRecordData?.request_type ===
                        "withdrawProvidentFund"
                          ? "Withdraw Provident Fund"
                          : selectedRecordData?.request_type === "advanceSalary"
                          ? "Advance Salary"
                          : selectedRecordData?.request_type === "personalLoan"
                          ? "Personal Loan"
                          : ""
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  {selectedRecordData?.request_type === "advanceSalary" ? (
                    <Form.Item
                      name={"noOfMonths"}
                      label="Month Period Range"
                      initialValue={
                        selectedRecordData?.start_date +
                        " - " +
                        selectedRecordData?.end_date
                      }
                    >
                      <Input size="large" disabled />
                    </Form.Item>
                  ) : (
                    <Form.Item label="Amount">
                      <Input
                        size="large"
                        disabled
                        value={selectedRecordData?.amount}
                      />{" "}
                    </Form.Item>
                  )}
                </Col>
                {selectedRecordData?.employee?.full_name ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item label="Reviewed By">
                      <Input
                        disabled
                        size="large"
                        value={selectedRecordData?.employee?.full_name}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
                {selectedRecordData?.description ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item label="Description">
                      <TextArea
                        disabled
                        value={selectedRecordData?.description}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                {selectedRecordData?.comments ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item label="Comments">
                      <TextArea disabled value={selectedRecordData?.comments} />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdvanceRequestHistory;
