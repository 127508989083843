export const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const employee_end_of_employeement_reason = [
  {
    id: "resignation",
    label: "Resignation",
  },
  {
    id: "retirement",
    label: "Retirement",
  },
  {
    id: "transfer",
    label: "Transfer",
  },
  {
    id: "other",
    label: "Other",
  },
];
export const hr_end_of_employeement_reason = [
  {
    id: "termination",
    label: "Termination",
  },

  {
    id: "retirement",
    label: "Retirement",
  },
  {
    id: "transfer",
    label: "Transfer",
  },
  {
    id: "other",
    label: "Other",
  },
];

export const advance_salary_pf_request = [
  {
    id: "personalLoan",
    label: "Personal Loan",
  },
  {
    id: "advanceSalary",
    label: "Advance Salary",
  },

  {
    id: "withdrawProvidentFund",
    label: "Withdraw Provident Fund",
  },
];
export const LEAVE_OPTIONS = [
 "Sick",
 "Casual",
 "Annual",
 "Maternity",
 "Paternity",
 "Hajj",
 "Marriage",
 "Bereavement",
 "Public Holidays",
 "Compensatory",
 "Unpaid",
 "Medical",
 "Leave of Absence",
 "Emergency",
 "Rest Day",
 "Urgent"
];

export const banks = [
  { label: "AlBaraka Bank (Pakistan) Limited", code: "AIIN", bankIdentifier: "PK58AIIN", short: "AlBaraka" },
  { label: "Allied Bank Limited", code: "ABPA", bankIdentifier: "PK63ABPA", short: "ABL" },
  { label: "Askari Bank Limited", code: "ASCM", bankIdentifier: "PK29ASCM", short: "Askari" },
  { label: "Bank AL Habib Limited", code: "BAHL", bankIdentifier: "PK34BAHL", short: "Bank AL Habib" },
  { label: "Bank Alfalah Limited", code: "ALFH", bankIdentifier: "PK93ALFH", short: "Alfalah" },
  { label: "The Bank of Khyber", code: "KHYB", bankIdentifier: "PK86KHYB", short: "Khyber" },
  { label: "The Bank of Punjab", code: "BPUN", bankIdentifier: "PK57BPUN", short: "Punjab" },
  { label: "BankIslami Pakistan Limited", code: "BKIP", bankIdentifier: "PK59BKIP", short: "BankIslami" },
  { label: "Citibank N.A.", code: "CITI", bankIdentifier: "PK71CITI", short: "Citibank" },
  { label: "Deutsche Bank AG", code: "DEUT", bankIdentifier: "PK52DEUT", short: "Deutsche" },
  { label: "Dubai Islamic Bank Pakistan Limited", code: "DUIB", bankIdentifier: "PK35DUIB", short: "Dubai Islamic" },
  { label: "Faysal Bank Limited", code: "FAYS", bankIdentifier: "PK89FAYS", short: "Faysal" },
  { label: "First Women Bank Limited", code: "FWOM", bankIdentifier: "PK24FWOM", short: "First Women" },
  { label: "Habib Bank Limited", code: "HABB", bankIdentifier: "PK69HABB", short: "HBL" },
  { label: "Habib Metropolitan Bank Limited", code: "MPBL", bankIdentifier: "PK11MPBL", short: "Habib Metro" },
  { label: "Industrial and Commercial Bank of China Limited", code: "ICBK", bankIdentifier: "PK52ICBK", short: "ICBC" },
  { label: "JS Bank Limited", code: "JSBL", bankIdentifier: "PK41JSBL", short: "JS Bank" },
  { label: "MCB Bank Limited", code: "MUCB", bankIdentifier: "PK09MUCB", short: "MCB" },
  { label: "MCB Islamic Bank", code: "MCIB", bankIdentifier: "PK18MCIB", short: "MCB Islamic" },
  { label: "Meezan Bank Limited", code: "MEZN", bankIdentifier: "PK98MEZN", short: "Meezan" },
  { label: "National Bank of Pakistan", code: "NBPA", bankIdentifier: "PK33NBPA", short: "NBP" },
  { label: "Punjab Provincial Cooperative Bank Ltd.", code: "PPCP", bankIdentifier: "", short: "PPCBL" },
  { label: "Samba Bank Limited", code: "MBPL", bankIdentifier: "PK06SAMB", short: "Samba" },
  { label: "Silkbank Limited", code: "PRUC", bankIdentifier: "PK81SAUD", short: "Silkbank" },
  { label: "Sindh Bank Limited", code: "SIND", bankIdentifier: "PK32SIND", short: "Sindh" },
  { label: "SME Bank Limited", code: "SMES", bankIdentifier: "PK25SMES", short: "SME" },
  { label: "Soneri Bank Limited", code: "SONE", bankIdentifier: "PK90SONE", short: "Soneri" },
  { label: "Standard Chartered Bank (Pakistan) Ltd", code: "SCBL", bankIdentifier: "PK70SCBL", short: "Standard Chartered" },
  { label: "Summit Bank Limited", code: "BCEY", bankIdentifier: "PK54SUMB", short: "Summit" },
  { label: "United Bank Limited", code: "UNIL", bankIdentifier: "PK44UNIL", short: "UBL" },
  { label: "Zarai Taraqiati Bank Ltd.", code: "ZTBL", bankIdentifier: "PK31ZTBL", short: "ZTBL" },
  { label: "Telenor Microfinance Bank", code: "TMFB", bankIdentifier: "PK10TMFB", short: "Easypaisa" },
  { label: "Mobilink Microfinance Bank", code: "MMFB", bankIdentifier: "PK50WMBL", short: "JazzCash" },
  { label: "First Microfinance Bank", code: "FMFB", bankIdentifier: "PK16FMFB", short: "First Micro" },
  { label: "FINCA Microfinance Bank", code: "FINC", bankIdentifier: "PK33SBPP", short: "FINCA" },
  { label: "Sindh Microfinance Bank", code: "SMFB", bankIdentifier: "PK94SMFB", short: "Sindh Micro" },
  { label: "Khushhali Bank Limited", code: "KBL", bankIdentifier: "", short: "Khushhali" },
  { label: "NRSP Microfinance Bank", code: "NRSP", bankIdentifier: "", short: "NRSP" },
  { label: "APNA Microfinance Bank", code: "APNA", bankIdentifier: "", short: "APNA" },
  { label: "U Microfinance Bank", code: "UMFB", bankIdentifier: "", short: "U Micro" },
  { label: "Pak Oman Microfinance Bank", code: "POMB", bankIdentifier: "", short: "Pak Oman" },
  { label: "ADVANS Pakistan Microfinance Bank", code: "ADV", bankIdentifier: "", short: "ADVANS" },
  { label: "Sadapay", code: "SADP", bankIdentifier: "PK47SADP", short: "Sadapay" },
];
export const HelpAndSupport = {
  Getting_Started: {
       heading : "Welcome to HRGenie",
       text: "Welcome to HRGenie, a powerful and intuitive Human Resource Management (HRM) system designed to revolutionize the way you manage your workforce. HRGenie is part of the MisGenie suite of tools, focused on streamlining HR processes and enhancing organizational efficiency. With features such as Employee Management, Asset Management, Payroll, Attendance, Performance, Policies, and Recruitment, HRGenie provides a comprehensive solution to meet all your HR needs.",
       mission_heading: "Our Mission",
       mission_text: "At HRGenie, our mission is to empower organizations to manage their human resources effectively and efficiently. We aim to simplify HR processes, improve employee engagement, and drive productivity through our user-friendly platform. By leveraging advanced technology, HRGenie helps businesses of all sizes to optimize their HR operations and achieve their strategic goals.",
       why_heading: "Why HRGenie?",
       first_point: "HRGenie offers a full suite of HR tools to manage everything from employee records to recruitment.",
       second_point: "Designed with ease of use in mind, HRGenie ensures a seamless experience for HR professionals and employees alike.",
       third_point: "Leverage powerful analytics to gain insights into employee performance, attendance trends, and payroll data.",
       fourth_point: "Whether you're a small business or a large enterprise, HRGenie scales to meet your needs.",
       fifth_point: " HRGenie integrates seamlessly with other systems, ensuring a smooth flow of information across your organization.",
       sixth_point: "Our solutions are designed to be affordable, making advanced HR management accessible to businesses with different budgets.",
       features_heading: "Important Features",
       employee_manag_heading: "Employee Management",
       employee_manag_text: "Manage employee records, personal information, job details, and more. HRGenie helps you keep track of all your employees' data in one secure place.",
       asset_manag_heading: "Asset Management",
       asset_manag_text: "Track and manage company assets assigned to employees. Ensure accountability and streamline the process of asset distribution and retrieval.",
       paroll_heading: "Payroll",
       paroll_text: "Automate payroll processes, manage salary structures, and ensure timely and accurate salary disbursements. HRGenie simplifies payroll management with integrated compliance and tax calculations.",
       attendance_heading: "Attendance Management",
       attendance_text: "Monitor and manage employee attendance with ease. HRGenie offers tools for time tracking, leave management, and attendance reporting.",
       performance_heading: "Performance Tracking",
       performance_text: "Track and evaluate employee performance with comprehensive performance management tools. Set goals, conduct appraisals, and provide feedback to foster growth and development.",
       policies_heading: "Policies",
       policies_text: "Create, manage, and communicate company policies effectively. HRGenie ensures that all employees are aware of and comply with organizational policies.",
       recruitment_heading: "Recruitment",
       recruitment_text: "Streamline your recruitment process with HRGenie's integrated recruitment tools. Manage job postings, track applications, and simplify candidate evaluations.",
   },
   FAQs: {
       question1: "What is HRGenie?",
       answer1: "HRGenie is an advanced Human Resource Management (HRM) system designed to streamline HR processes and enhance organizational efficiency. It offers features such as Employee Management, Asset Management, Payroll, Attendance, Performance, Policies, and Recruitment.",
       question2: "How does HRGenie benefit small businesses?",
       answer2: "For small businesses, HRGenie provides an affordable and accessible platform that consolidates essential HR functions. This helps streamline processes, reduce costs, and improve overall efficiency.",
       question3: "How does HRGenie improve employee management?",
       answer3: "HRGenie offers comprehensive tools for managing employee records, tracking attendance, evaluating performance, and ensuring compliance with company policies. This helps organizations maintain accurate employee data and improve overall HR management.",
       question4: "What makes HRGenie different from other HRM systems?",
       answer4: "HRGenie stands out due to its comprehensive suite of HR tools, user-friendly interface, and focus on empowering businesses to optimize their HR operations. Our solution is designed to be affordable for small businesses, advanced for medium-sized businesses, and robust for large enterprises, making it a versatile and powerful choice for any organization.",
       question5: "How can HRGenie help with payroll management?",
       answer5: "HRGenie automates payroll processes, manages salary structures, and ensures timely and accurate salary disbursements. It simplifies payroll management with integrated compliance and tax calculations, reducing the risk of errors and ensuring employees are paid correctly.",
   }
}
