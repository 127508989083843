import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Empty,
} from "antd";
import { useSelector } from "react-redux";
import "./index.css";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import EditBranchDetails from "../Edit";
import CustomAlert from "../../../../common/Alert/index";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { SortOrder } from "antd/lib/table/interface";
import { getTheTableData, handleDelete } from "../services";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandler,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import BranchFilterModal from "../Filter";

interface Branch {
  id: number;
  name: string;
  organization_Id: string;
  company_id: string;
  head_manager_id: string;
  email: string;
  phone_number: string;
  unique_identifier: string;
  company: {
    name: string;
  };
  address: {
    street: string;
    province: string;
    city: string;
    country: string;
  };
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface BranchListProps {
  searchValue: string;
  openFilterModal: boolean;
  toggleFilterModal: () => void;
}

const BranchesListView: React.FC<BranchListProps> = ({
  searchValue,
  openFilterModal,
  toggleFilterModal,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<Branch[]>([]);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<Branch | undefined>();
  const [searchQuery, setSearchQuery] = useState<string | null | any>(null);

  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;
  const companyDet = useSelector(selectLogedEmployee);
  const companyId = companyDet.logedInemployee?.employee?.company_id;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [editedBranch, setEditedBranch] = useState<any>(null);

  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Organization",
    "Branches"
  );

  const edit = access?.writeAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (organizationId) {
      fetchTableData();
    }
  }, [organizationId]);

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const fetchTableData = async () => {
    setLoading(true);
    setIsModalVisible(false);

    const Ids = {
      companyId: companyId,
      organizationId: organizationId,
      searchQuery: searchQuery,
    };

    const response = await getTheTableData(Ids, organizationId);

    if (response.data) {
      const updatedData = response.data.filter((item: any) => item.employee);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: tableData.length,
        },
      });
      setTableData(updatedData);

      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: updatedData,
      }));
      setLoading(false);
    } else {
    }
    setLoading(false);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,

    sorter: any
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleViewDetails = (record: Branch) => {
    setSelectedBranch(record);
    setDetailModalVisible(true);
  };

  const handleDetailModalCancel = () => {
    setSelectedBranch(undefined);
    setDetailModalVisible(false);
    setIsModalVisible(false);
    fetchTableData();
  };

  const handleEdit = (record: Branch) => {
    setSelectedBranch(record);

    setIsModalVisible(true);
    setSelectedRecordId(record.id);
  };

  const handleDeleteClick = (record: Branch) => {
    setEditedBranch(record);
    setDeleteConfirmVisible(true);
  };

  const handleDeleteConfirm = async () => {
    setshowAlert(false);
    try {
      if (editedBranch) {
        const response: any = await handleDelete(
          editedBranch.id,
          organizationId
        );

        if (response.error) {
          setshowAlert(true);

          setAlertType("error");
        }
        if (response.status == 201 || response.status == 204) {
          setshowAlert(true);

          setAlertType("success");
          fetchTableData();
        }
      }
    } catch (error) {}
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmVisible(false);
    setEditedBranch(null);
  };

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Branch Code",
      dataIndex: "unique_identifier",
      key: "unique_identifier",
    },

    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      render: (item: any) => item?.name,
    },
    {
      title: "Branch Manager",
      dataIndex: "employee",
      key: "employee",
      render: (record: any) => <div>{record?.full_name || " "}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_: any, record: any) =>
        record.email && <span className="email-column">{record.email}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: any, record: Branch) => (
        <Space size="middle">
          <span className="link" onClick={() => handleViewDetails(record)}>
            <EyeOutlined />
          </span>
          {edit && (
            <span className="link" onClick={() => handleEdit(record)}>
              <EditOutlined />
            </span>
          )}
          <Popconfirm
            placement="topLeft"
            title={`Are you sure you want to delete the record for ${record.name}?`}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            open={
              deleteConfirmVisible &&
              editedBranch?.unique_identifier === record.unique_identifier
            }
            okText="Yes"
            cancelText="No"
          >
            {deletee && (
              <span className="link" onClick={() => handleDeleteClick(record)}>
                <DeleteOutlined />
              </span>
            )}
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setLoading(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "name");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoading(false);
  };

  const getFilterDataHandler = (full_name: string) => {
    const filterArray = [{ path: "employee.full_name", value: full_name }];
    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableData(response);
    }
  };

  return (
    <>
      {read ? (
        <CustomTable
          dataSource={tableData}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
        />
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
      {isModalVisible && (
        <EditBranchDetails
          branchId={selectedRecordId}
          onCancel={handleDetailModalCancel}
          visible={isModalVisible}
          fetchData={fetchTableData}
        />
      )}

      <Modal
        open={detailModalVisible}
        onCancel={handleDetailModalCancel}
        footer={null}
        width={700}
        title={<span className="global-modal-title">Branch Details</span>}
        className="global-modal-main-container"
      >
        <div className="global-card-inner-container">
          <Row gutter={10} className="branch-view-modal-row1">
            <Col span={8} className="">
              <b> Name </b>
              <br /> {selectedBranch?.name ? selectedBranch?.name : "No Data"}
            </Col>

            <Col span={8} className="">
              <b>Company</b>
              <br />{" "}
              {selectedBranch?.company?.name
                ? selectedBranch?.company?.name
                : "No Data"}
            </Col>
            <Col span={8}>
              <b> Phone </b>
              <br />{" "}
              {selectedBranch?.phone_number
                ? selectedBranch?.phone_number
                : "No Data"}
            </Col>
          </Row>

          <Row gutter={10} className="branch-view-modal-row2">
            <Col span={8}>
              <b> Mail </b>
              <br /> {selectedBranch?.email ? selectedBranch?.email : "No Data"}
            </Col>
            <Col span={8}>
              <b> Branch Code/Id </b>
              <br />{" "}
              {selectedBranch?.unique_identifier
                ? selectedBranch?.unique_identifier
                : "No Data"}
            </Col>
            <Col span={8}>
              <b> Address </b>
              <br />
              {selectedBranch?.address?.street
                ? selectedBranch?.address?.street
                : "No Data"}{" "}
              {selectedBranch?.address?.province
                ? selectedBranch?.address?.province
                : "No Data"}
              ,{" "}
              {selectedBranch?.address?.city
                ? selectedBranch?.address?.city
                : "No Data"}
              ,{" "}
              {selectedBranch?.address?.country
                ? selectedBranch?.address?.country
                : "No Data"}
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <BranchFilterModal
          getFilterDataHandler={(full_name) => getFilterDataHandler(full_name)}
        />
      </Modal>
      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default BranchesListView;
