import supabase, {
  supabase_client_for_Org_schema,
} from "../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};

export const fetchEmployeePersonalDetailsApi = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        `id,other_email,phone_number,work_phone_number,work_email,avatar_url,first_name,last_name,nationality,cnic,dob,marital_status,access_right_group,gender ,branch!employee_branch_id_fkey(name),company!employee_company_id_fkey(name),
        job_title!employee_job_title_id_fkey(name)
        `
      )
      .eq("organization_id", organizationId)
      .eq("id", employeeId)
      .single();

    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const updateEmployeePersonlDetailsApi = async (
  body: any,
  Id: any,
  organizationId: any
): Promise<any> => {
  try {
    const employeeupdateres = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee")
      .update(body)
      .eq("id", Id);
    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeBasedonIdApi = async (employeeId: any) => {
  try {
    let query = supabase
      .from("employee")
      .select("*")
      .eq("id", employeeId)
      .single();
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeAddressDetailsApi = async (
  organizationId: number,
  employeeId: any
) => {
  try {
    let query = supabase
      .from("employee_address")
      .select(`id,permanent_address_detail`)
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId)
      .single();

    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeEmergencyContactDetails = async (
  organizationId: number,
  employeeId: any
) => {
  try {
    let query = supabase
      .from("employee_emergency_contact")
      .select(
        `id,relative_name,email,work_phone_number, relation_with_employee,phone_number`
      )
      .eq("organization_id", organizationId)
      .eq("employee_id", employeeId)
      .single();
    const res = await query;
    return res;
  } catch {
    return catch_return;
  }
};

export const updateEmployeeAddressesDetailsApi = async (
  body: any,
  organization_id: any
): Promise<any> => {
  try {
    const employeeupdateres = await supabase_client_for_Org_schema(
      organization_id
    )
      .from("employee_address")
      .upsert(body, { onConflict: "employee_id" });
    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

export const updateEmployeeEmergencyContactDetailsApi = async (
  body: any,
  organizationId: any
): Promise<any> => {
  try {
    const employeeupdateres = await supabase_client_for_Org_schema(
      organizationId
    )
      .from("employee_emergency_contact_information")
      .upsert(body, { onConflict: "employee_id" });

    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeResponsibilityAndReports = async (
  organizationId: number,
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        `id,responsibilities, full_name, 
              branch!employee_branch_id_fkey(name),
              company!employee_company_id_fkey(name),
              department!employee_department_id_fkey(name),
              team!employee_team_id_fkey(name),
              designation!employee_designation_id_fkey(name),
              reporting_to_role_id:report_to_employee_id(full_name , work_email),
              job_title! employee_job_title_id_fkey(name)`
      )
      .eq("organization_id", organizationId)
      .eq("id", employeeId)
      .eq("status", "active")
      .single();

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const setEmployeeBankDetails = async (body: any): Promise<any> => {
  try {
    let query = supabase.from("employee_bank").insert(body);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const getEmployeeExistingBankRecords = async (
  employeeId: any
): Promise<any> => {
  try {
    let query = supabase
      .from("employee_bank")
      .select("id, bank_information")
      .eq("employee_id", employeeId)
      .order("id", { ascending: false });

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const deleteEmployeeBankRecord = async (id: number): Promise<any> => {
  try {
    let query = supabase.from("employee_bank").delete().eq("id", id);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const getEmployeeBankDetailEditModalApi = async (
  recordId: string
): Promise<any> => {
  try {
    let query = supabase
      .from("employee_bank")
      .select("id, bank_information")
      .eq("id", recordId)
      .single();

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const saveEmployeeBankDetailEditModalApi = async (
  body: any,
  recordId: string
): Promise<any> => {
  try {
    let query = supabase.from("employee_bank").update(body).eq("id", recordId);

    const res = await query;

    return res;
  } catch {
    return catch_return;
  }
};

export const updateProfilePicApi = async (
  filePath: any,
  file: any
): Promise<any> => {
  try {
    const res = await supabase.storage
      .from("employeeProfile")
      .upload(filePath, file);

    if (!res.error) {
      const publicURL: any = await supabase.storage
        .from("employeeProfile")
        .getPublicUrl(filePath);
      return publicURL;
    }
  } catch {
    return catch_return;
  }
};

export const fetchEmployeeAllBasicDetailsApi = async (
  employeeId: any,
  organization_id: any
): Promise<any> => {
  try {
    const employeeupdateres = await supabase_client_for_Org_schema(
      organization_id
    )
      .from("employeefulldetails")
      .select("*")
      .eq("employeeid", employeeId)
      .single();
   
    return employeeupdateres;
  } catch {
    return catch_return;
  }
};

 
