import React, { useEffect, useState } from "react";
import "./index.css";
import { Divider, Row, Form, Col, Input, Space, Button, Select, InputNumber } from "antd";
import CustomAlert from "../../../../common/Alert";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { useSelector } from "react-redux";

import { GrSubtractCircle } from "react-icons/gr";
import { IoIosAddCircle } from "react-icons/io";
import {
  allOrgCompaniesDataApi,
  allOrgCompanyBranchesApi,
  fetchDeductionByIdApi,
  updateDeductionPolicyApi,
} from "../services";
import { hanldeInputChangeForSlabs } from "../viewmodel";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface DeductionEditViewProps {
  id: string;
  oncancel: () => void;
}
const DeductionEditView: React.FC<DeductionEditViewProps> = (props) => {
  const [slabs, setSlabs] = useState<any[]>([{ id: Date.now() }]);
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [filteredCompanies, setFilteredCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [filteredBranches, setFilteredBranches] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const orgId = loggedInEmployee?.employee?.organization_id;

  useEffect(() => {
    fetchCompanies();
    fetchRecordDate();
  }, []);

  useEffect(() => {
    form.setFieldValue("branchId", null);
    fetchBranches();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchRecordDate = async () => {
    const data = {
      organizationId: orgId,
      id: props.id,
    };
    const res = await fetchDeductionByIdApi(data);
    if (res.data) {
      form.setFieldValue("companyId", res.data.company_id);
      form.setFieldValue("branchId", res.data.branch_id);
      form.setFieldValue("deductionName", res.data.version_name);
      setSlabs(res.data.slabs);
      res.data?.slabs?.map((item: any, key: any) => {
        form.setFieldValue(`${item.id}start`, item.start);
        form.setFieldValue(`${item.id}stop`, item.stop);
        form.setFieldValue(`${item.id}%`, item.percent);
      });
    }
  };

  const fetchCompanies = async () => {
    const resCompanies = await allOrgCompaniesDataApi(orgId);
    if (resCompanies?.data) {
      setFilteredCompanies(resCompanies.data);
    }
  };

  const fetchBranches = async () => {
    const resBranches = await allOrgCompanyBranchesApi(
      orgId,
      selectedCompanyId
    );
    if (resBranches?.data) {
      setFilteredBranches(resBranches.data);
    }
  };

  const handleAddSlab = () => {
    setSlabs([...slabs, { id: Date.now(), start: "", stop: "", percent: "" }]);
  };

  const handleRemoveSlab = (slabId: string) => {
    const updatedSlabs = slabs?.filter((slab) => slab.id !== slabId);
    setSlabs(updatedSlabs);
  };

  const handleInputChange = async (
    slabId: string,
    field: string,
    value: string
  ) => {
    const updatedSlabs = await hanldeInputChangeForSlabs(
      slabs,
      slabId,
      field,
      value
    );
    if (updatedSlabs) {
      setSlabs(updatedSlabs);
    }
  };
  const validateSlabs = () => {
    const sortedSlabs = [...slabs].sort(
      (a, b) => parseFloat(a.start) - parseFloat(b.start)
    );
    let missingRanges = [];
    for (let i = 1; i < sortedSlabs.length; i++) {
      const prevStop = parseFloat(sortedSlabs[i - 1].stop) + 1;
      const currentStart = parseFloat(sortedSlabs[i].start);
      if (currentStart > prevStop) {
        missingRanges.push(`${prevStop} - ${currentStart}`);
      }
    }
    return missingRanges;
  };
  const onFinish = async (values: any) => {
    setShowAlert(false);

    setButtonLoader(true);
    if (slabs.length > 0) {
      const missingRanges = validateSlabs();
      if (missingRanges.length > 0) {
        setAlertType("error");
        setShowAlert(true);
        setAlertMessage(
          `You missed a slab or slabs for values between: ${missingRanges.join(
            ", "
          )}`
        );
        setButtonLoader(false);
        return;
      }
      const data = {
        company_id: values.companyId,
        branch_id: values.branchId,
        organization_id: orgId,
        version_name: values.deductionName,
        slabs: slabs,
      };
      const res = await updateDeductionPolicyApi(data, props.id);
      setButtonLoader(false);
      if (res.status == 201 || res.status == 204) {
        setShowAlert(true);
        setAlertType("success");

        props.oncancel();
      }
      if (res.error) {
        setShowAlert(true);
        setAlertType("error");
      }

      setButtonLoader(false);
    } else {
      setButtonLoader(false);
      setShowAlert(true);
      setAlertType("error");
    }
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="companyId"
                label="Select Company"
                rules={[
                  {
                    required: true,
                    message: "Please select a Company",
                  },
                ]}
                initialValue={loggedInEmployee?.employee?.company_id}
              >
                <Select
                  disabled={loggedInEmployee?.employee?.company_id}
                  onChange={(value) => setSelectedCompanyId(value)}
                  placeholder="Select a Company"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredCompanies.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="branchId"
                label="Select Branch (If any)"
                initialValue={loggedInEmployee?.employee?.branch_id}
              >
                <Select
                  disabled={loggedInEmployee?.employee?.branch_id}
                  placeholder="Select a Branch"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {filteredBranches.map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="deductionName"
                label="Deduction version"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Deduction version",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Deduction Version Name....." />
              </Form.Item>
            </Col>
          </Row>

          {slabs.map((slab, key) => (
            <div key={slab.id}>
              {key >= 1 && <Divider />}
              <Row gutter={24}>
                <Col span={24} md={7}>
                  <Form.Item
                    name={`${slab.id}start`}
                    label="Threshold Start(minutes)"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Threshold Start(minutes)",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const stopValue = getFieldValue(`${slab.id}stop`);
                          const slabIndex = slabs.findIndex(
                            (s) => s.id === slab.id
                          );
                          const previousSlabStop =
                            slabIndex > 0 ? slabs[slabIndex - 1].stop : null;

                          if (slabIndex > 0) {
                            if (
                              value &&
                              stopValue &&
                              parseFloat(value) > parseFloat(stopValue)
                            ) {
                              return Promise.resolve();
                            }
                            if (
                              value &&
                              previousSlabStop &&
                              parseFloat(value) <= parseFloat(previousSlabStop)
                            ) {
                              return Promise.reject(
                                "This field's value should be greater than the previous slab's Threshold Stop(minutes) value"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="global-input-number-width"
                      placeholder="Threshold (Start - minutes)"
                      onChange={(e:any) =>
                        handleInputChange(slab.id, "start", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={7}>
                  <Form.Item
                    name={`${slab.id}stop`}
                    label="Threshold Stop(minutes)"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Threshold Stop(minutes)",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const stopValue = getFieldValue(`${slab.id}start`);
                          if (
                            value &&
                            stopValue &&
                            parseFloat(value) > parseFloat(stopValue)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Threshold Stop(minutes) must be greater than Threshold Start(minutes)"
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="global-input-number-width"
                      placeholder="Threshold (Stop - minutes)"
                      onChange={(e:any) =>
                        handleInputChange(slab.id, "stop", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={6}>
                  <Form.Item
                    name={`${slab.id}%`}
                    label="Deduction%"
                    rules={[
                      {
                        required: true,
                        message: "Deduction%",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="global-input-number-width"
                      placeholder="Deduction%"
                      onChange={(e:any) =>
                        handleInputChange(slab.id, "percent", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={2}>
                  <Form.Item label=" ">
                    <Button
                      onClick={() => handleRemoveSlab(slab.id)}
                      icon={<GrSubtractCircle />}
                      type="default"
                      size="large"
                    >
                      Slab
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
          <Row className="global-modal-bottom-row">
            <Col span={24} className="policy-deduction-button-edit-position">
              <Space>
                <Button
                  onClick={handleAddSlab}
                  icon={<IoIosAddCircle />}
                  className="global-btn-width"
                  size="large"
                  type="primary"
                >
                  Short Hours Slab
                </Button>

                <Button
                  className="global-btn-width"
                  htmlType="submit"
                  loading={buttonLoader}
                  type="primary"
                  size="large"
                >
                  Update Dedection Version
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        {showAlert && (
          <CustomAlert
            type={alertType}
            showAlert={showAlert}
            description={alertMessage}
          />
        )}
      </div>
    </>
  );
};

export default DeductionEditView;
