import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Upload,
  DatePicker,
} from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import { v4 as uuidv4 } from "uuid";
import { UploadOutlined } from "@ant-design/icons";
import CustomAlert from "../../../../common/Alert";
import { employeeAssets } from "../../CommonFiles";
import { fetchallCompanyByOrgIdApi } from "../../../../services/Global";
import { createAssetsApi } from "../services";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

interface props {
  onCancel: () => void;
}

const CreateAssets: React.FC<props> = ({ onCancel }) => {
  const [form] = useForm();
  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;

  const [imageToBase64, setImageToBase64] = useState<any>(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);

  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const [companySelectLoading, setCompanySelectLoading] =
    useState<boolean>(false);

  const conditionOptions = ["New", "Old"];

  let uploadedImageFile: any = null;
  const employeeAssetsOptions = employeeAssets;

  const currentRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 1000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompanyId(companyId);
      form.setFieldValue("companyId", companyId);
    } else {
      getCompanyOptions();
    }
  }, [currentRole]);

  function getBase64(file: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  const sendDataToSupabase = async () => {
    try {
      const formattedData = {
        name: form.getFieldValue("assetName"),
        type: form.getFieldValue("assetType"),
        unique_identifier: identificationNumber,
        manufacturer: form.getFieldValue("manufacturer"),
        condition: form.getFieldValue("condition"),
        model: form.getFieldValue("model"),
        image: imageToBase64,
        current_value: form.getFieldValue("currentValue"),
        acquired_date: form.getFieldValue("acquiredDate"),
        company_id: selectedCompanyId || companyId,
        organization_Id: organizationId,
        branch_id: branchId || null,
      };
      const formValidaiton = await form.validateFields();
      if (
        !formValidaiton.errorFields ||
        formValidaiton.errorFields.length === 0
      ) {
        setButtonLoading(true);

        const response = await createAssetsApi(formattedData, organizationId);
        if (response.status === 201 || response.status === 204) {
          setButtonLoading(false);
          setshowAlert(true);
          setAlertType("success");
          form.resetFields();
          setTimeout(() => {
            onCancel();
          }, 2000)

        }
        setButtonLoading(false);
      }
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    }
  };

  const identificationNumber = uuidv4();

  const getCompanyOptions = async () => {
    setCompanySelectLoading(true);

    const { data, error } = await fetchallCompanyByOrgIdApi(organizationId);

    if (data) {
      setCompanyOptions(data);
      setCompanySelectLoading(false);
    }
    setCompanySelectLoading(false);
  };

  return (
    <>
      <div className="global-card-inner-container">
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                name={"assetName"}
                label="Asset Name"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty.",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Asset name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"assetType"}
                label="Asset Type"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
              >
                <Select allowClear showSearch placeholder="Select Asset type" notFoundContent={
                  <NoDataFoundIcon />
                }>
                  {employeeAssetsOptions.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!companyId && (
              <Col span={8}>
                <Form.Item name={"companyId"} label="Company">
                  <Select
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    allowClear
                    onChange={setSelectedCompanyId}
                    value={selectedCompanyId}
                    loading={companySelectLoading}
                    placeholder="Select a company..."
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companyOptions.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Identification No."
                name={"identificationNumber"}
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
                initialValue={identificationNumber}
              >
                <Input size="large" value={identificationNumber} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"manufacturer"}
                label="Manufacturer"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Manufacturer" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Condition of product"
                name={"condition"}
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Select condition of product"
                  filterOption={(input: any, option: any) =>
                    option && option.children
                      ? (option.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {conditionOptions.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"model"}
                label="Model"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Asset model" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"productImage"} label="Attachment">
                <Upload
                  name="productImage"
                  beforeUpload={(file: any) => {
                    getBase64(file, (base64: any) => {
                      setImageToBase64(base64);
                    });

                    return false;
                  }}
                  onRemove={() => {
                    uploadedImageFile = null;
                  }}
                  accept=".png, .jpeg, .jpg"
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={"currentValue"}
                label="Current Value"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty.",
                  },
                  {
                    validator: (_, value) => {
                      if (!value || isNaN(Number(value))) {
                        return Promise.reject(
                          new Error("Please enter numeric values only")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter current value of asset"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"acquiredDate"}
                label="Acquired Date"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty",
                  },
                ]}
              >
                <DatePicker name="acquiredDate" className="date-picker" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="global-modal-bottom-row submission-btn">
            <Button
              className="global-btn-width"
              type="primary"
              onClick={sendDataToSupabase}
              loading={buttonLoading}
              size="large"
            >
              Create
            </Button>
          </Row>
        </Form>
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};

export default CreateAssets;
