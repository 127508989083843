import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Row, Col, Button, Select, Empty } from "antd";
import "./index.css";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { PhoneInput } from "react-international-phone";
import { MdContactEmergency } from "react-icons/md";
import { BiSolidContact } from "react-icons/bi";
import "./index.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import CustomAlert from "../../../common/Alert";
import { validateEmail } from "../../../common/validation";
import { getCountries, getStates, getCities } from "vk-country-state-city";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../ReactLoader";
import {
  fetchEmployeeAllBasicDetailsApi,
  updateEmployeeAddressesDetailsApi,
  updateEmployeeEmergencyContactDetailsApi,
  updateEmployeePersonlDetailsApi,
} from "../services";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../ExtractAccessRightObj";
import NoDataFoundIcon from "../../NoDataFoundIcon";

const UserContactInfoUpdate: React.FC = () => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const loggedIneemployee = useSelector(selectLogedEmployee).logedInemployee;
  const employeeId = loggedIneemployee?.employeeId;
  const organizationId = loggedIneemployee?.employee?.organization_id;
  const employesres = useRef<any>();
  const [employesCounter, setEmployesCounter] = useState<number>(0);
  const addressesres = useRef<any>();
  const EmergencyContactres = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  const accessObj = useSelector(selectAccess);
  const access = extractAccessRightObject(
    accessObj,
    "My Profile",
    "Contact Details"
  );
  const write = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    async function fetchCountries() {
      const countries = await getCountries();
      setCountries(countries);
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchStates() {
      if (selectedCountry) {
        const states = await getStates(selectedCountry);
        setStates(states);
      }
    }
    if (selectedCountry) fetchStates();
  }, [selectedCountry]);

  useEffect(() => {
    async function fetchCities() {
      if (selectedState) {
        const cities = await getCities(selectedCountry, selectedState);
        setCities(cities);
      }
    }
    if (selectedState) fetchCities();
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    if (employeeId) {
      fetchEmployee(employeeId);
    }
  }, [employeeId]);

  const fetchEmployee = async (id: any) => {
    setLoading(true);
    const employes_res = await fetchEmployeeAllBasicDetailsApi(
      id,
      organizationId
    );

    if (employes_res?.data) {
      const personal_details = {
        otherEmail: employes_res?.data?.other_email,
        workEmail: employes_res?.data?.work_email,
        workNumber: employes_res?.data?.work_phone_number,
        mobileNumber: employes_res?.data?.phone_number,
      };
      const address_details = {
        personalAddress: employes_res.data.permanent_address_detail,
      };
      const emergency_details = {
        email: employes_res?.data?.relative_email,
        worktelephone: employes_res?.data?.relative_work_phone_number,
        mobile: employes_res?.data?.emergency_contact_phone_number,
        name: employes_res?.data?.relative_name,
        relation: employes_res?.data?.relation_with_employee,
      };
      employesres.current = personal_details;
      addressesres.current = address_details;
      EmergencyContactres.current = emergency_details;
      setEmployesCounter(employesCounter + 1);
      setLoading(false);
    } else if (employes_res?.error) {
      setLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setshowAlert(false);
    setButtonLoading(true);

    try {
      const personalDetailsUpdateRequired =
        values?.otherEmail !== employesres?.current?.otherEmail ||
        values?.mobileNumber !== employesres?.current?.mobileNumber ||
        values?.workNumber !== employesres?.current?.workNumber;

      const addressDetailsUpdateRequired =
        values?.city !==
          addressesres.current?.personalAddress?.permanent_city ||
        values?.country !==
          addressesres.current?.personalAddress?.permanent_country ||
        values?.street !==
          addressesres.current?.personalAddress?.permanent_street ||
        values?.postcode !==
          addressesres.current?.personalAddress?.permanent_postcode ||
        values?.state !==
          addressesres.current?.personalAddress?.permanent_state;

      const emergencyContactUpdateRequired =
        values?.relative_name !== EmergencyContactres?.current?.name ||
        values?.Relationship !== EmergencyContactres?.current?.relation ||
        values?.relativehometelephone !==
          EmergencyContactres?.current?.mobile ||
        values?.relativeworktelephone !==
          EmergencyContactres?.current?.worktelephone ||
        values?.relativeemail !== EmergencyContactres?.current?.email;

      if (personalDetailsUpdateRequired) {
        const body = {
          other_email: values.otherEmail,
          phone_number: values.mobileNumber,
          work_phone_number: values.workNumber,
        };
        const res_EmployeePersonlInfo = await updateEmployeePersonlDetailsApi(
          body,
          employeeId,
          organizationId
        );
        if (res_EmployeePersonlInfo.error) {
          setshowAlert(true);
          setButtonLoading(false);
          setAlertType("error");
          return;
        }
      }

      if (addressDetailsUpdateRequired) {
        const body = {
          permanent_address_detail: {
            permanent_city: values.city,
            permanent_state: values.state,
            permanent_country: values.country,
            permanent_postcode: values.postcode,
            permanent_street: values.street,
          },
          organization_id: organizationId,
          employee_id: employeeId,
          company_id: loggedIneemployee?.employee.company_id
            ? loggedIneemployee?.employee.company_id
            : null,
        };

        const res_EmployeeAddresses = await updateEmployeeAddressesDetailsApi(
          body,
          organizationId
        );

        if (res_EmployeeAddresses.error) {
          setshowAlert(true);
          setAlertType("error");
          setButtonLoading(false);
          return;
        }
      }

      if (emergencyContactUpdateRequired) {
        const body = {
          relative_name: values.relative_name,
          relation_with_employee: values.Relationship,
          phone_number: values.relativehometelephone,
          work_phone_number: values.relativeworktelephone,
          email: values.relativeemail,
          organization_id: organizationId,
          employee_id: employeeId,
          company_id: loggedIneemployee?.employee.company_id
            ? loggedIneemployee?.employee.company_id
            : null,
        };

        const res_EmergencyContact =
          await updateEmployeeEmergencyContactDetailsApi(body, organizationId);

        if (res_EmergencyContact.error) {
          setshowAlert(true);
          setAlertType("error");
          setButtonLoading(false);
          return;
        }
      }

      setshowAlert(true);
      setAlertType("success");
    } catch (error) {
      setshowAlert(true);
      setAlertType("error");
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      {read ? (
        <>
          <Form
            layout="vertical"
            initialValues={{
              mobileNumber: employesres.current?.mobileNumber || "",
              workNumber: employesres.current?.workNumber || "",
              workEmail: employesres.current?.workEmail || "",
              otherEmail: employesres.current?.otherEmail || "",
              street:
                addressesres.current?.personalAddress?.permanent_street || "",
              city: addressesres.current?.personalAddress?.permanent_city || "",
              state:
                addressesres.current?.personalAddress?.permanent_state || "",
              country:
                addressesres.current?.personalAddress?.permanent_country || "",
              postcode:
                addressesres.current?.personalAddress?.permanent_postcode || "",
              relative_name: EmergencyContactres.current?.name || "",
              Relationship: EmergencyContactres.current?.relation || "",
              relativehometelephone:
                `+${EmergencyContactres.current?.mobile}` || "",
              relativeworktelephone:
                `+${EmergencyContactres.current?.worktelephone}` || "",
              relativeemail: EmergencyContactres.current?.email || "",
            }}
            onFinish={onFinish}
            key={employesCounter}
          >
            <div className="org-form-fields">
              <h5>
                <BiSolidContact /> Personal Contact
              </h5>
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      value=""
                      className="contact-info-phone-input"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Work Phone"
                    name="workNumber"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      className="contact-info-phone-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    name={"country"}
                    label="Select a Country"
                    rules={[
                      {
                        required: true,
                        message: "Please select a country",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a country..."
                      allowClear
                      showSearch
                      onChange={setSelectedCountry}
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {countries.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="State"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please select a state",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a state..."
                      showSearch
                      allowClear
                      onChange={setSelectedState}
                      filterOption={(input, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {states.map((item: any) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please select a city",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a city..."
                      showSearch
                      allowClear
                      filterOption={(input: any, option: any) =>
                        option && option.children
                          ? (option.children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false
                      }
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {cities.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Street"
                    name="street"
                    rules={[
                      {
                        required: true,
                        message: "Please select a street",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter a street..." />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="Postcode" name="postcode">
                    <Input size="large" placeholder="Enter a postal code..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Work Email"
                    name="workEmail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Work Email" disabled />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Other Email"
                    name="otherEmail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Other Email" />
                  </Form.Item>
                </Col>
              </Row>

              <h5>
                <MdContactEmergency /> Emergency Contact
              </h5>

              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="Relative Name" name="relative_name">
                    <Input size="large" placeholder="Relative's Name" />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item label="Relationship" name="Relationship">
                    <Input size="large" placeholder="Enter relationship..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Home Telephone"
                    name="relativehometelephone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      className="contact-info-phone-input"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Work Telephone"
                    name="relativeworktelephone"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            value.length > 3 &&
                            !phoneUtil.isValidNumber(
                              phoneUtil.parseAndKeepRawInput(value)
                            )
                          ) {
                            return Promise.reject("Invalid phone number");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <PhoneInput
                      defaultCountry={"pk"}
                      className="contact-info-phone-input"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="form-lable-font"
                >
                  <Form.Item
                    label="Email"
                    name="relativeemail"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && !validateEmail(value)) {
                            return Promise.reject("Invalid email address!");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter email..." />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="contact-info-data-update-row">
                  <Form.Item>
                    {write && (
                      <Button
                        className="send-invitation-btn global-btn-width"
                        type="primary"
                        htmlType="submit"
                        loading={buttonLoading}
                      >
                        Save
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>

          {loading && <TriangleLoader />}

          {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
        </>
      ) : (
        <Empty
          description="Not Authorized For This Action"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </>
  );
};

export default UserContactInfoUpdate;
