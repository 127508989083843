import { Dayjs } from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, Card, Tooltip, Typography, theme } from "antd";
import "./index.css";
import dayjs from "dayjs";
import tinycolor from "tinycolor2";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import ScreenWidth from "../../../common/ScreenWidth";
import { fetchCanlderDataApi } from "./services";
import { ProgressLoader } from "../../../common/ReactLoader";
import anniversery_icon from "../../../assets/images/scheduler-images/scheduler-anniversery-icon.png";
import appointment_icon from "../../../assets/images/scheduler-images/scheduler-appointment-icon.svg";
import birthday_icon from "../../../assets/images/scheduler-images/scheduler-birthday-icon.svg";
import holiday_icon from "../../../assets/images/scheduler-images/scheduler-holiday-icon.svg";
import meeting_icon from "../../../assets/images/scheduler-images/scheduler-meeting-icon.svg";
import reminder_icon from "../../../assets/images/scheduler-images/scheduler-reminder-icon.svg";
import announcement_icon from "../../../assets/images/scheduler-images/scheduler-announcement-icon.svg";
import { capitalizeWords } from "../../../common/validation";

const MyCalendar: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { token } = theme.useToken();
  const eventListRef = useRef<any>([]);
  const [eventDates, setEventDates] = useState<Record<string, string>>({});
  let screenwidth = ScreenWidth();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setShowLoader(true);
    try {
      const eventRes = await fetchCanlderDataApi(
        loggedInEmployee?.employee?.organization_id,
        loggedInEmployee?.employee?.company_id,
        loggedInEmployee?.employee?.branch_id
      );

      setShowLoader(false);
      if (eventRes.data) {
        const events = eventRes.data.map((item: any) => ({
          event_id: item.event_id,
          title: item.title,
          description: item.description,
          type_of_event: item.type_of_event,
          start: new Date(item.start_date_and_time),
          end: new Date(item.end_date_and_time),
          color: item.color,
        }));

        eventListRef.current = events;

        const dateColors: Record<string, string> = {};
        events.forEach((event: any) => {
          const startDate = moment(event.start).format("YYYY-MM-DD");
          dateColors[startDate] = event.color;
        });

        setEventDates(dateColors);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const dateFullCellRender = (value: Dayjs) => {
    const dateString = value.format("YYYY-MM-DD");
    const Color = eventDates[dateString];
    let bgColor = Color;
    if (tinycolor(bgColor).isDark()) {
      bgColor = tinycolor(Color).lighten(45).toHexString();
    } else {
      bgColor = tinycolor(Color).lighten(25).toHexString();
    }

    function extractDateFromDate(dateString: any) {
      const dateObject = new Date(dateString);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();

      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

      return formattedDate;
    }

    function extractTimeFromDate(dateString: any) {
      const dateObject = new Date(dateString);
      const hours = dateObject.getHours();
      const minutes = dateObject.getMinutes();

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;

      return formattedTime;
    }

    const eventsForDate = eventListRef.current.filter((event: any) => {
      const eventDate = extractDateFromDate(event.start);
      return eventDate === dateString;
    });

    const isPastDate = value.isBefore(dayjs().startOf("day"));
    if (Color && eventsForDate.length > 0) {
      return (
        <Tooltip
          color={Color}
          title={
            <div>
              {eventsForDate.map((event: any) => (
                <div key={event.id} className="calender-tooltip-container">
                  <Typography.Text className="tooltip-text-color tooltip-title-content">
                    <strong>{capitalizeWords(event.title)}</strong>
                  </Typography.Text>
                  <Typography.Text className="tooltip-text-color">
                    {event.description.split(" ").length > 10
                      ? `${event.description
                          .split(" ")
                          .slice(0, 10)
                          .join(" ")}...`
                      : event?.description}
                  </Typography.Text>
                  {event.type_of_event === "appointment" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={appointment_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "anniversary" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={anniversery_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "reminder" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={reminder_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "birthday" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={birthday_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "holiday" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={holiday_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "announcement" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={announcement_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  {event.type_of_event === "meeting" && (
                    <div>
                      <img
                        className="svg-icon-class"
                        src={meeting_icon}
                        alt=""
                      />{" "}
                    </div>
                  )}
                  <Typography.Text className="tooltip-text-color">
                    {extractTimeFromDate(event?.start)}-
                    {extractTimeFromDate(event?.end)}
                  </Typography.Text>
                </div>
              ))}
            </div>
          }
        >
          <div
            className="event-date-cell"
            style={{
              color: isPastDate ? "gray" : Color,
              borderColor: isPastDate ? "gray" : Color,
              backgroundColor: isPastDate ? "lightgray" : bgColor,
            }}
          >
            {value.date()}
          </div>
        </Tooltip>
      );
    } else {
      return <div> {value.date()} </div>;
    }
  };
  return (
    <div>
      {screenwidth > 1280 ? (
        <Card
          style={{ fontFamily: "var(--storefront-heading-font-family)" }}
          title="Dates to Remember"
          bordered={true}
          className="calendar-card-container"
        >
          <div>
            {showLoader ? (
              <div className="admin-calendar-container-loader calendar-loader-height-set">
                <ProgressLoader />
              </div>
            ) : (
              <div className="calendar-dashboard">
                <div className="calender-dashboard-scroll">
                  <Calendar
                    mode="month"
                    fullscreen={false}
                    dateFullCellRender={dateFullCellRender}
                    className="dashboard-calender"
                  />
                </div>
              </div>
            )}
          </div>
        </Card>
      ) : (
        <Card
          style={{ fontFamily: "var(--storefront-heading-font-family)" }}
          title="Dates to Remember"
          bordered={true}
          className="calendar-card-container"
        >
          <div>
            {showLoader ? (
              <ProgressLoader />
            ) : (
              <div className="calendar-dashboard">
                <Calendar
                  mode="month"
                  fullscreen={false}
                  dateFullCellRender={dateFullCellRender}
                  style={{ width: "100%", height: "100%" }}
                  className="dashboard-calender"
                />
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default MyCalendar;
