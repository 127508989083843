import React, { useEffect, useState } from "react";
import "./index.css";
import {
  getAllowanceToApproveApi,
  updateAllowanceToApproveApi,
} from "../services";
import searchIcon from "../../../assets/images/search-icon.png";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import { Button, Col, Empty, Input, Row, Space } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { selectAccess } from "../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../common/ExtractAccessRightObj";
import { searchLogicHandler } from "../../../common/SearchAndFilterFunction";
import CustomTable from "../../../common/CustomTable";
import CustomAlert from "../../../common/Alert";
import d_dp from "../../../assets/images/blankProfile.png";

interface ClaimToApproveListProps {
  searchValue?: string;
}

const ClaimToApprove: React.FC<ClaimToApproveListProps> = ({ searchValue }) => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showAlert, setshowAlert] = useState<boolean>(false);

  const organizationId = loggedInEmployee?.employee?.organization_id;
  const [tableData, setTableData] = useState<any[]>();

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Allowance Approval"
  );

  const create = access?.createAccess;
  const deletee = access?.deleteAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchAllowanceToApprove();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchQuery]);

  const fetchAllowanceToApprove = async () => {
    setLoadingTable(true);
    setSearchQuery("");
    const data = {
      organizationId: organizationId,
      branchId: loggedInEmployee?.employee?.branch_id,
      companyId: loggedInEmployee?.employee?.company_id,
    };
    const res = await getAllowanceToApproveApi(data);
    setLoadingTable(false);
    if (res.data) {
      setTableData(res.data);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: res.data,
      }));
    }
  };
  const updatedRecord = async (status: string, record: any) => {
    const data = {
      details: { status: status },
      id: record.id,
    };
    const res = await updateAllowanceToApproveApi(data, organizationId);

    if (res.status == 204) {
      setAlertType("success");
      setshowAlert(true);
      fetchAllowanceToApprove();
    } else {
      setAlertType("error");
      setshowAlert(true);
    }
  };

  const handleSearch = (value: any) => {
    setLoadingTable(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(
      value,
      originalData,
      "employee",
      "full_name"
    );

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);

    setLoadingTable(false);
  };

  const columns = [
    {
      title: "Employees",
      dataIndex: "employee",
      key: "employee",
      render: (record: any) => (
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} xl={4} xxl={4}>
            <div className="advance-request-circle-avatarUrl-cell">
              <img
                src={record?.avatar_url ? record?.avatar_url : d_dp}
                alt={d_dp}
                className="advance-request-circle-avatarUrl-cell-img"
              />
            </div>
          </Col>
          <Col
            span={24}
            lg={24}
            xl={20}
            xxl={20}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <strong>{record?.full_name}</strong>
            </div>
          </Col>
        </Row>
      ),
      sorter: (a: any, b: any) => {
        const nameA = a?.employee?.full_name || "";
        const nameB = b?.employee?.full_name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Allowance Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Claim Amount",
      dataIndex: "amount_to_claim",
      key: "amount_to_claim",
      render: (_: any, item: any) =>
        item?.amount_to_claim
          ? parseFloat(item?.amount_to_claim)?.toLocaleString()
          : "",
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any) => {
        const dateTime = new Date(text);
        const formattedDateTime = dateTime.toLocaleString();
        return formattedDateTime;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record: any) =>
        record.status == "Approved" ? (
          "Approved"
        ) : record.status == "Rejected" ? (
          "Rejected"
        ) : (
          <Space>
            {create && (
              <Button
                className=" global-primary-btn"
                onClick={() => updatedRecord("Approved", record)}
              >
                Approved
              </Button>
            )}
            {deletee && (
              <Button
                className=" global-default-btn"
                onClick={() => updatedRecord("Rejected", record)}
              >
                Rejected
              </Button>
            )}
          </Space>
        ),
    },
  ];

  const onSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div className="global-request-container-styling allowance-approve-container">
        <div className="allowance-approve-header">
          <Input
            className="org-search-input"
            size="large"
            placeholder="Search"
            prefix={<img src={searchIcon} alt="Search icon" />}
            onChange={(e: any) => {
              onSearchChange(e.target.value);
            }}
          />
        </div>
        {read ? (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={columns}
            loading={loadingTable}
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
        {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
      </div>
    </>
  );
};
export default ClaimToApprove;
