import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Button, Row } from "antd";
import { fetchGenratedSalaryByIdApi } from "../../services";
import jspdfHtml2canvas from "jspdf-html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { TriangleLoader } from "../../../../common/ReactLoader";

const PaySlipPreview: React.FC = () => {
  const [paySlipData, setPaySlipData] = useState<any>();
  const payslipTableRef = useRef(null);
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee.organization_id;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const path = window.location.pathname;
    const segments = path.split("/");
    const lastSegment = segments[segments.length - 1];
    if (lastSegment) {
      fetchSalaryDetailsByemployeeId(lastSegment);
    }
  }, []);

  const fetchSalaryDetailsByemployeeId = async (id: string) => {
    setLoading(true);
    const res = await fetchGenratedSalaryByIdApi({ id: id }, organizationId);
    if (res.data) {
      setPaySlipData(res.data);
    }
    setLoading(false);
  };
  function formatTimestamp(timestamp: any) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    return `${year}-${month}`;
  }

  const downloadPdfDocument = () => {
    if (payslipTableRef.current) {
      jspdfHtml2canvas(payslipTableRef.current, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scale: 2,
        },
      }).then((pdf) => {
        pdf.save(
          `${paySlipData?.employee?.full_name}-${formatTimestamp(
            paySlipData?.created_at
          )} payslip.pdf`
        );
      });
    }
  };

  return (
    <>
      <div className="payslip-preview-parent table-main-container">
        <div className="payslip-preview-container">
          <div className="watermark"></div>
          <Row className="employee-preview-row-1">
            <Button
              className="custom-primary-btn payslip-download-text"
              icon={<DownloadOutlined className="btn-download-icon" />}
              onClick={() => downloadPdfDocument()}
            >
              Download Payslip
            </Button>
          </Row>
          <Row>
            <div ref={payslipTableRef} className="preview-slip-main-div">
              <h3 className="preview-slip-heading-above-table">Payslip</h3>
              <table className="payslip-preview-table employee-payslip-preview-download-font">
                <thead className="payslip-preview-header">
                  <tr>
                    <td colSpan={5}>
                      <span className="payslip-preview-table-heading-details employee-payslip-preview-download-font">
                        Employee Information
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-data-text-bold" colSpan={3}>
                      Name
                    </td>
                    <td
                      colSpan={2}
                      className="payslip-preview-table-amount-col"
                    >
                      {paySlipData?.employee?.full_name}
                    </td>
                  </tr>
                  {paySlipData?.gender ? (
                    <tr>
                      <td colSpan={3} className="table-data-text-bold">
                        Gender
                      </td>
                      <td
                        colSpan={2}
                        className="payslip-preview-table-amount-col"
                      >
                        {paySlipData?.gender}
                      </td>
                    </tr>
                  ) : null}
                  {paySlipData?.employee?.designation?.name ? (
                    <tr>
                      <td colSpan={3} className="table-data-text-bold">
                        Designation
                      </td>
                      <td
                        colSpan={2}
                        className="payslip-preview-table-amount-col"
                      >
                        {paySlipData?.employee?.designation?.name}
                      </td>
                    </tr>
                  ) : null}
                  {paySlipData?.employee?.department?.name ? (
                    <tr>
                      <td colSpan={3} className="table-data-text-bold">
                        Department
                      </td>
                      <td
                        colSpan={2}
                        className="payslip-preview-table-amount-col"
                      >
                        {paySlipData?.employee?.department?.name}
                      </td>
                    </tr>
                  ) : null}

                  {paySlipData?.employee?.joining_date ? (
                    <tr>
                      <td colSpan={3} className="table-data-text-bold">
                        Date of Joining
                      </td>
                      <td
                        colSpan={2}
                        className="payslip-preview-table-amount-col"
                      >
                        {paySlipData?.employee?.joining_date}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan={5}>
                      <span className="payslip-preview-table-heading-details employee-payslip-preview-download-font">
                        Employer Information
                      </span>
                    </td>
                  </tr>
                  {paySlipData?.company?.name ? (
                    <>
                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Company Name
                        </td>
                        <td
                          colSpan={2}
                          className="payslip-preview-table-amount-col"
                        >
                          {paySlipData?.company?.name}
                          {/* {paySlipData?.branch
                        ? "-" + paySlipData?.branch.name
                        : ""} */}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  {paySlipData?.company?.location ? (
                    <>
                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Company Address
                        </td>
                        <td
                          colSpan={2}
                          className="payslip-preview-table-amount-col"
                        >
                          {paySlipData?.company?.location}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  {paySlipData?.company?.email ? (
                    <>
                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Company Information
                        </td>
                        <td
                          colSpan={2}
                          className="payslip-preview-table-amount-col"
                        >
                          {paySlipData?.company?.email}
                        </td>
                      </tr>
                    </>
                  ) : null}

                  <tr>
                    <th colSpan={3}>Earnings</th>
                    <th colSpan={2}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="payslip-preview-table-earnings-col table-data-text-bold"
                      colSpan={3}
                    >
                      Account Name{" "}
                    </td>
                    <td
                      className="payslip-preview-table-amount-col"
                      colSpan={2}
                    >
                      {paySlipData?.account_title || ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="payslip-preview-table-earnings-col table-data-text-bold"
                      colSpan={3}
                    >
                      Account Number{" "}
                    </td>
                    <td
                      className="payslip-preview-table-amount-col"
                      colSpan={2}
                    >
                      {paySlipData?.account_number || 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="payslip-preview-table-earnings-col table-data-text-bold"
                      colSpan={3}
                    >
                      Basic Salary{" "}
                    </td>
                    <td
                      className="payslip-preview-table-amount-col"
                      colSpan={2}
                    >
                      {`${
                        parseFloat(paySlipData?.base_salary).toLocaleString() ||
                        0
                      }${" "}${paySlipData?.currency}`}
                    </td>
                  </tr>
                  {paySlipData?.provident_fund_amount !== "0" && (
                    <>
                      <tr className="payslip-preview-table-heading-details-row">
                        <td colSpan={5}>
                          <span className="payslip-preview-table-heading-details employee-payslip-preview-download-font">
                            Provident Fund on Basic Salary:
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          {paySlipData?.provident_fund_percentage} % Provident
                          Fund will be deducted from the Basic Salary.
                        </td>
                        <th
                          colSpan={2}
                          className="payslip-preview-table-amount-col"
                        >
                          -
                          {parseFloat(
                            paySlipData?.provident_fund_amount
                          ).toLocaleString() || 0}
                          {`${" "}${paySlipData?.currency}`}
                        </th>
                      </tr>
                    </>
                  )}
                  {paySlipData?.EOBI !== 0 && paySlipData?.EOBI !== null && (
                    <>
                      <tr className="payslip-preview-table-heading-details-row">
                        <td colSpan={5}>
                          <span className="payslip-preview-table-heading-details employee-payslip-preview-download-font">
                            EOBI Details
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          EOBI will be deducted from the Salary.
                        </td>
                        <th
                          colSpan={2}
                          className="salary-slip-table-amount-col"
                        >
                          - {parseFloat(paySlipData?.EOBI).toLocaleString()}{" "}
                          {paySlipData?.currency}
                        </th>
                      </tr>
                    </>
                  )}
                  {paySlipData?.allowance_detail && (
                    <>
                      <tr className="payslip-preview-table-heading-details-row">
                        <td colSpan={5}>
                          <span className="payslip-preview-table-heading-details employee-payslip-preview-download-font">
                            All Given Allowance Details:
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={3}>Name</th>
                        <th
                          colSpan={2}
                          className="payslip-preview-table-amount-col table-data-text-bold"
                        >
                          Total Price
                        </th>
                      </tr>

                      {paySlipData?.allowance_detail?.map((item: any) => (
                        <tr>
                          <td colSpan={3}>{item.name}</td>
                          <td
                            colSpan={2}
                            className="payslip-preview-table-amount-col"
                          >
                            {item.value ? item.amount : 0}{" "}
                            {paySlipData?.currency ? paySlipData?.currency : ""}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th colSpan={3}>Total:</th>
                        <th
                          colSpan={2}
                          className="salary-slip-table-amount-col"
                        >
                          +
                          {paySlipData?.total_allowance_claim
                            ? parseFloat(
                                paySlipData?.total_allowance_claim
                              ).toLocaleString()
                            : 0}{" "}
                          {paySlipData?.currency}
                        </th>
                      </tr>
                    </>
                  )}
                  {parseInt(paySlipData?.tax || "0").toFixed(0) !== "0" && (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <span className="salary-slip-table-heading-details employee-payslip-preview-download-font">
                            Tax Details
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Based on the chosen taxation,{" "}
                          {paySlipData?.tax_percentage || 0} % will be deducted
                          from the Basic Salary.
                        </td>
                        <th
                          colSpan={2}
                          className="salary-slip-table-amount-col"
                        >
                          {
                            -parseInt(paySlipData?.tax || "0")
                              .toFixed(2)
                              .toLocaleString()
                          }{" "}
                          {paySlipData?.currency}
                        </th>
                      </tr>
                    </>
                  )}
                  {paySlipData?.total_over_time !== "0" && (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <span className="salary-slip-table-heading-details employee-payslip-preview-download-font">
                            Other Claims:
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Over-Time
                        </td>
                        <th
                          colSpan={2}
                          className="salary-slip-table-amount-col"
                        >
                          +
                          {parseInt(
                            paySlipData?.total_over_time || "0"
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          {paySlipData?.currency}
                        </th>
                      </tr>
                    </>
                  )}
                  {paySlipData?.total_short_hour_payable !== 0 && (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <span className="salary-slip-table-heading-details employee-payslip-preview-download-font">
                            Other Deductions
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3} className="table-data-text-bold">
                          Short Hours
                        </td>
                        <th
                          colSpan={2}
                          className="salary-slip-table-amount-col"
                        >
                          -
                          {parseFloat(
                            paySlipData?.total_short_hour_payable || "0"
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          {paySlipData?.currency}
                        </th>
                      </tr>
                    </>
                  )}
                  {paySlipData?.leave_deduction_amount !== 0 && (
                    <tr>
                      <td colSpan={3} className="table-data-text-bold">
                        Leave Deduction
                      </td>
                      <th colSpan={2} className="salary-slip-table-amount-col">
                        -
                        {parseFloat(
                          paySlipData?.leave_deduction_amount || "0"
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {paySlipData?.currency}
                      </th>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={3} className="table-data-text-bold">
                      Total Salary
                    </td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      {parseFloat(
                        paySlipData?.gross_salary || "0"
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {paySlipData?.currency}
                    </th>
                  </tr>{" "}
                  <tr>
                    <td colSpan={3} className="table-data-text-bold">
                      Total Deduction
                    </td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      {parseFloat(
                        paySlipData?.gross_deduction || "0"
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {paySlipData?.currency}
                    </th>
                  </tr>{" "}
                  <tr>
                    <td colSpan={3} className="table-data-text-bold">
                      Total Earning
                    </td>
                    <th colSpan={2} className="salary-slip-table-amount-col">
                      {parseFloat(
                        paySlipData?.net_salary || "0"
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {paySlipData?.currency}
                    </th>
                  </tr>
                </tbody>
              </table>
              <strong className="employee-payslip-preview-download-font">
                This document is system generated and does not require any
                signatures or the company’s stamp to be considered valid.
              </strong>
            </div>
          </Row>
        </div>
      </div>
      {loading && <TriangleLoader />}
    </>
  );
};

export default PaySlipPreview;
