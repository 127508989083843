import { Card, Select } from "antd";
import React, { useEffect, useState } from "react";
import "../index.css";
import { ResponsivePie } from "@nivo/pie";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { supabase_client_for_Org_schema } from "../../../../services/supabase";
import dayjs from "dayjs";
import { ProgressLoader } from "../../../../common/ReactLoader";
import NoDataFoundIcon from "../../../../common/NoDataFoundIcon";

const MonthlySalaryInspection: React.FC = () => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = logedInemployee?.employee?.organization_id;
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [chartData, setChartData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const monthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getEmployeeSalarySummary = async (month: string) => {
    try {
      setSelectedMonth(month);
      setIsLoading(true);
      let startDate;
      let endDate;
      const year = new Date().getFullYear();

      const dayjsmonthNumber = dayjs(month, "MMMM", true);
      const monthNumber: any = dayjsmonthNumber.format("MM");
      startDate = new Date(year, monthNumber - 1, 1).toISOString();
      endDate = new Date(year, monthNumber, 0, 23, 59, 59).toISOString();

      const response = await supabase_client_for_Org_schema(organizationId)
        .from("employee_salary_history")
        .select("*")
        .eq("organization_id", organizationId)
        .gte("created_at", startDate)
        .lte("created_at", endDate);

      if (response.data) {
        const fieldsToInclude = [
          "total_allowance_claim",
          "gross_salary",
          "gross_deduction",
          "provident_fund_amount",
        ];

        const fieldLabels: any = {
          total_allowance_claim: "Total Allowance",
          gross_salary: "Gross Salary",
          gross_deduction: "Gross Deduction",
          provident_fund_amount: "Provident Fund",
        };

        const colors = [
          "hsl(50, 70%, 50%)",
          "hsl(100, 70%, 50%)",
          "hsl(0, 70%, 50%)",
          "hsl(200, 70%, 50%)",
        ];

        const newData = fieldsToInclude
          .map((field, index) => {
            const totalValue = response.data.reduce(
              (acc, employee) => acc + parseFloat(employee[field] || 0),
              0
            );
            return {
              id: fieldLabels[field],
              label: fieldLabels[field],
              value: totalValue,
              color: colors[index],
            };
          })
          .filter((data) => data.value > 0); // Filter out zero values

        setChartData(newData);
      } else {
        setChartData([]);
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeSalarySummary(monthsArray[new Date().getMonth()]);
  }, []);

  const handleMonthChange = (value: string) => {
    getEmployeeSalarySummary(value);
  };

  const numberWithCommas = (value: number) => value.toLocaleString();

  return (
    <div className="payroll-dashboard-monthly-salary-container">
      <Card
        title={
          <div className="payroll-dashboard-salary-card-header">
            <div className="payroll-dashboard-salary-title-container">
              <span>Salaries</span>
            </div>
            <div>
              <Select
                style={{ width: 200 }}
                placeholder="Select a month"
                onChange={handleMonthChange}
                value={selectedMonth}
                notFoundContent={
                  <NoDataFoundIcon />
                }
              >
                {monthsArray.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        }
        className="payroll-dashboard-monthly-salary"
      >
        {isLoading ? (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressLoader />
          </div>
        ) : chartData.length > 0 ? (
          <div style={{ height: 350 }}>
            <ResponsivePie
              data={chartData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.2}
              padAngle={1}
              cornerRadius={5}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="var(--green-color-primary-light)"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLinkLabel={(d) => `${d.id}`}
              arcLabel={(d) => numberWithCommas(d.value)}
              valueFormat={(value) => numberWithCommas(value)}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "rgba(255, 255, 255, 0.3)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom1",
                  background: "inherit",
                  color: "#218b8c",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom2",
                  background: "inherit",
                  color: "#c8eae1",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
                {
                  id: "custom3",
                  type: "none",
                  background: "inherit",
                  color: "var(--green-color-primary-light)",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
             
            />
          </div>
        ) : (
          <div
            style={{
              height: "18.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#888",
            }}
          >
            <span>
              😕 No salary data available for the selected month. Please try a
              different month.
            </span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default MonthlySalaryInspection;
