import supabase, { supabase_client_for_Org_schema } from "../../../services/supabase";

const catch_return = {
  data: null,
  error: {
    message: "oops we are facing some temporary issue with your request",
    status: "custom_400",
  },
};
/**
 * Fetches head details from the database based on the provided employee ID.
 * @param {string} employeeId - The ID of the employee to fetch head details for.
 * @returns {Promise<any>} A promise that resolves with the head details fetched from the database.
 */
export const fetchHeadDetailByIdApi = async (
  employeeId: string,
  organzationId :string
): Promise<any> => {
  try {
    const query =  supabase_client_for_Org_schema(organzationId)
      .from("headmanager")
      .select(
        "employee_id,head_team_name,name_of_head_of_department,head_branch_name,team_head,branch_head,department_head"
      )
      .eq("employee_id", employeeId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const inActiveEmployeeByIdApi = async (
  employeeId: string,
  organizationId:string
): Promise<any> => {
  try {
    const query =  supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .update({ status: "inactive" })
      .eq("id", employeeId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
export const ActiveEmployeeByIdApi = async (
  employeeId: string,
  organizationId :string
): Promise<any> => {
  try {
    const query =  supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .update({ status: "active" })
      .eq("id", employeeId);
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};

export const fetchReportToEmployeebyEmployeeIdApi = async (
  employeeId: string,
  organizationId :string
) => {
  try {
    const query =  supabase_client_for_Org_schema(organizationId)
      .from("employee")
      .select(
        "id,full_name,department_id,team_id,department!employee_department_id_fkey(name)"
      )
      .eq("report_to_employee_id", employeeId)
      .eq("status", "active");
    const res = await query;
    return res;
  } catch (error) {
    return catch_return;
  }
};
