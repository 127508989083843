import React, { useState, useEffect, useRef } from "react";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Form,
  Steps,
  Input,
  Select,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Radio,
  Checkbox,
  Divider,
} from "antd";
import "./index.css";
import { useForm } from "antd/es/form/Form";
import {
  hiringTimelineArray,
  jobTypesArray,
  payArray,
  peopleToHireArray,
  predefinedQuestions,
  showByArray,
} from "../CommonData";
import CustomAlert from "../../../common/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getResponse } from "../../../common/ChatGPTResponse";
import {
  fetchBranches,
  fetchCompanies,
  handleJobDataSubmission,
} from "../services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";
import dayjs from "dayjs";
import NoDataFoundIcon from "../../../common/NoDataFoundIcon";

const { Step } = Steps;
const { Item } = Form;

interface props {
  onCancel?: () => void;
}

const CreateJobSteps: React.FC<props> = ({ onCancel }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [expectedHoursType, setExpectedHoursType] = useState<string>("");
  const [expectedPayType, setExpectedPayType] = useState<string>("");
  const [jobDescription, setJobDescription] = useState<string>("");
  const [jobRequirements, setJobRequirements] = useState<string>("");
  const [isApplicationDeadline, setIsApplicationDeadline] = useState<any>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
  const [customQuestionText, setCustomQuestionText] = useState<any>("");
  const [customQuestionType, setCustomQuestionType] = useState<any>(null);
  const [customQuestionOptions, setCustomQuestionOptions] = useState<any[]>([]);
  const [chatgptResponse, setChatgptResponse] = useState<any>(null);
  const [jobDescriptionText, setJobDescriptionText] = useState<string>(" ");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [jobStatus, setJobStatus] = useState<string>("true");
  const [companies, setCompanies] = useState<any[]>([]);
  const [branches, setBranches] = useState<any[]>([]);
  const [descriptionCounter, setDescriptionCounter] = useState<number>(0);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [jobDescriptionLoader, setJobDescriptionLoader] =
    useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [date, setDate] = useState<any>(null);

  const onExpectedHoursTypeChange = (value: string) => {
    setExpectedHoursType(value);
  };
  const containerRef = useRef<HTMLDivElement>(null);

  const companyId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.company_id;
  const branchId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.branch_id;
  const organizationId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.organization_id;

  useEffect(() => {
    if (companyId) {
      form.setFieldValue("company", companyId);
      setSelectedCompany(companyId);
      if (branchId) {
        form.setFieldValue("branch", branchId);
        setSelectedBranch(branchId);
      }
    } else {
      getCompanies();
    }
  }, []);
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);
  useEffect(() => {
    if (!(branchId || companyId)) {
      getBranches();
    }
  }, [selectedCompany]);

  const getChatgptResponse = async () => {
    if (descriptionCounter < 2) {
      setJobDescriptionLoader(true);
      const jobTitle = form.getFieldValue("jobTitle");
      const response = await getResponse(jobTitle);
      if (response) {
        setDescriptionCounter(descriptionCounter + 1);
        setChatgptResponse(response);
        const words = response.trim().split(/\s+/);
        const trimmedResponse = words.slice(0, 100).join(" ");
        const finalResponse =
          trimmedResponse + (words.length > 100 ? "..." : "");
        setJobDescriptionText(finalResponse);
        setJobDescriptionLoader(false);
      }
    }
  };

  const setTheResponse = async () => {
    form.setFieldsValue({
      jobDescription: chatgptResponse,
    });
  };
  const validateEditorContent = () => {
    const content = jobDescription;
    if (!content || content.trim() === "") {
      return false;
    }
    return true;
  };

  const onExpectedPayTypeChange = (value: string) => {
    setExpectedPayType(value);
  };

  const handleApplicationDeadlineChange = (e: any) => {
    setIsApplicationDeadline(e.target.value);
  };

  const predefineQuestion = predefinedQuestions;

  const handleSelectPredefinedQuestion = (id: any) => {
    const question = predefineQuestion.find((q) => q.id === id);
    if (question) {
      setSelectedQuestions((prevQuestions) => [
        ...prevQuestions,
        { ...question, required: false, disabled: true },
      ]);
    }
  };

  const getCompanies = async () => {
    const response = await fetchCompanies(organizationId);
    if (response.data) {
      setCompanies(response.data);
      form.setFieldValue("company", response.data[0]?.id);
      setSelectedCompany(response.data[0]?.id);
    }
  };

  const getBranches = async () => {
    if (branchId) {
      form.setFieldValue("branch", branchId);
      setSelectedBranch(branchId);
    } else {
      const response = await fetchBranches(selectedCompany, organizationId);
      if (response.data) {
        setBranches(response.data);
        form.setFieldValue("branch", response.data[0]?.id);
        setSelectedBranch(response.data[0]?.id);
      }
    }
  };

  const handleNext = async () => {
    getChatgptResponse();
    if (currentStep == 1 && !validateEditorContent()) {
      setShowAlert(true);

      setAlertType("error");
      return;
    }
    try {
      await form.validateFields();
      setCurrentStep(currentStep + 1);
    } catch (error) {}
  };

  const handleAddCustomQuestion = () => {
    if (customQuestionText) {
      if (
        (customQuestionType === "radio" || customQuestionType === "select") &&
        customQuestionOptions.length === 0
      ) {
        setShowAlert(true);

        setAlertType("error");
        return;
      }

      setSelectedQuestions([
        ...selectedQuestions,
        {
          label: customQuestionText,
          id: Date.now(),
          inputType: customQuestionType,
          options: customQuestionOptions,
          required: false,
          isCustom: true,
          isFilterable: false,
          answer: [],
        },
      ]);
      setCustomQuestionText("");
      setCustomQuestionOptions([]);
    } else {
      setShowAlert(true);

      setAlertType("error");
    }
  };

  const handleRequiredChange = (questionId: any, requiredStatus: any) => {
    const updatedQuestions = selectedQuestions.map((q) =>
      q.id === questionId ? { ...q, required: requiredStatus } : q
    );
    setSelectedQuestions(updatedQuestions);
  };

  const currentDate = new Date();
  const disabledDate = (current: any) => {
    return current && current < currentDate;
  };
  const peopleToHire = peopleToHireArray;
  const jobTypes = jobTypesArray;
  const showBy = showByArray;
  const hiringTimeline = hiringTimelineArray;
  const pay = payArray;

  const jobPosterId =
    useSelector(selectLogedEmployee).logedInemployee?.employee?.id;

  const handleRemoveQuestion = (questionId: any) => {
    const updatedQuestions = selectedQuestions.filter(
      (q) => q.id !== questionId
    );
    setSelectedQuestions(updatedQuestions);
  };

  const collectAndSubmitFormData = async () => {
    const basic_details = form.getFieldsValue([
      "jobTitle",
      "numberOfPeopleToHire",
      "jobType",
      "payType",
      "expectedHoursType",
      "jobLocation",
      "applicationDeadline",
      "hiringTimeline",
    ]);
    const hoursData = {};
    if (expectedHoursType === "Range") {
      Object.assign(hoursData, form.getFieldsValue(["minHours", "maxHours"]));
    } else if (expectedHoursType === "Fixed hours") {
      Object.assign(hoursData, form.getFieldsValue(["fixedHours"]));
    } else if (expectedHoursType === "Maximum") {
      Object.assign(hoursData, form.getFieldsValue(["maxOnlyHours"]));
    } else if (expectedHoursType === "Minimum") {
      Object.assign(hoursData, form.getFieldsValue(["minOnlyHours"]));
    }
    const payData = {};
    if (expectedPayType === "Range") {
      Object.assign(payData, form.getFieldsValue(["minPay", "maxPay"]));
    } else if (expectedPayType === "Exact amount") {
      Object.assign(payData, form.getFieldsValue(["exactAmount"]));
    } else if (expectedPayType === "Maximum") {
      Object.assign(payData, form.getFieldsValue(["maximumPay"]));
    } else if (expectedPayType === "Starting") {
      Object.assign(payData, form.getFieldsValue(["mininumPay"]));
    }
    Object.assign(basic_details, hoursData, payData);

    if (jobDescription) {
      basic_details.jobDescription = jobDescription;
    }
    const trimmedDescription = jobDescription.trim();
    const trimmedRequirements = jobRequirements.trim();
    const formattedDate = dayjs(date);
    const finalData = {
      basic_details,
      questions: selectedQuestions,
      deadline: formattedDate,
      organization_id: organizationId,
      company_id: selectedCompany || companyId,
      description: trimmedDescription,
      employee_id: jobPosterId,
      status: jobStatus,
      requirements: trimmedRequirements,
      branch_id: selectedBranch || branchId || null,
    };

    try {
      const response = await handleJobDataSubmission(finalData);

      if (response.status == 201 || response.status == 204) {
        setShowAlert(true);
        setAlertType("success");
        form.resetFields();
        setJobDescription("");
        setCurrentStep(0);
        if (onCancel) {
          onCancel();
        }
      } else {
        setShowAlert(true);
        setAlertType("error");
      }
    } catch (error) {
      return error;
    }
  };

  const calculateSpan = (type: any) => {
    switch (type) {
      case "Range":
        return 8;
      case "Fixed hours":
      case "Maximum":
      case "Minimum":
        return 12;
      default:
        return 24;
    }
  };

  const calculateSpanForPay = (type: any) => {
    switch (type) {
      case "Range":
        return 8;
      case "Exact amount":
      case "Maximum":
      case "Starting":
        return 12;
      default:
        return 24;
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      setTheResponse();
    }
  };

  const handleAdvanceSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const [form] = useForm();

  const steps = [
    {
      title: "Job Basics",
      content: (
        <Form layout="vertical" form={form} className="step-1-create-job-form">
          <Row gutter={[16, 16]}>
            {!companyId && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name={"company"}
                  label="Select a company"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedCompany}
                    value={selectedCompany}
                    placeholder="Select a company..."
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {companies.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {!(companyId || branchId) && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item name={"branch"} label="Select a branch">
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option && option.children
                        ? (option.children as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    onChange={setSelectedBranch}
                    value={selectedBranch}
                    placeholder="Select a branch..."
                    notFoundContent={
                      <NoDataFoundIcon />
                    }
                  >
                    {branches.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Item
                label="Job Title"
                name={"jobTitle"}
                rules={[
                  { required: true, message: "Please enter a job title." },
                ]}
              >
                <Input size="large" placeholder="Enter a Job Title." />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Item
                label="Number of people to hire for this role"
                name={"numberOfPeopleToHire"}
                rules={[
                  {
                    required: true,
                    message: "Please fill out this field.",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Number of people to hire..."
                  showSearch
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {peopleToHire.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Item
                label="Job type"
                name={"jobType"}
                rules={[
                  {
                    required: true,
                    message: "Please fill this form field.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select a job type.."
                  showSearch
                  size="large"
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {jobTypes.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row gutter={[16, 16]}>
                <Col span={calculateSpan(expectedHoursType)}>
                  <Form.Item
                    label="Working Hours"
                    name="expectedHoursType"
                    rules={[
                      {
                        required: true,
                        message: "Please select the expected hours type.",
                      },
                    ]}
                  >
                    <Select
                      onChange={onExpectedHoursTypeChange}
                      allowClear
                      showSearch
                      placeholder="Select expected hours type"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {showBy.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {expectedHoursType === "Range" && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="minHours"
                        label="Min Hrs"
                        rules={[
                          {
                            required: true,
                            message: "Please enter minimum hours.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter hours..." />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Max Hrs"
                        name="maxHours"
                        rules={[
                          {
                            required: true,
                            message: "Please enter maximum hours.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter hours..." />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {expectedHoursType === "Fixed hours" && (
                  <Col span={12}>
                    <Form.Item
                      name="fixedHours"
                      label="Fixed Hours"
                      rules={[
                        { required: true, message: "Please enter the hours." },
                      ]}
                    >
                      <Input
                        placeholder="Enter hours..."
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedHoursType === "Maximum" && (
                  <Col span={12}>
                    <Form.Item
                      name="maxOnlyHours"
                      label="Max Hrs"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the maximum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter hours..."
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedHoursType === "Minimum" && (
                  <Col span={12}>
                    <Form.Item
                      name="minOnlyHours"
                      label="Min Hrs"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the minimum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter hours..."
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Item
                label="Job Location"
                name={"jobLocation"}
                rules={[
                  {
                    required: true,
                    message: "Please enter a location",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter a city..." />
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row gutter={[16, 16]}>
                <Col span={calculateSpanForPay(expectedPayType)}>
                  <Form.Item
                    label="Pay"
                    name="payType"
                    rules={[
                      {
                        required: true,
                        message: "Please select the expected hours type.",
                      },
                    ]}
                  >
                    <Select
                      onChange={onExpectedPayTypeChange}
                      allowClear
                      showSearch
                      placeholder="Choose pay type"
                      notFoundContent={
                        <NoDataFoundIcon />
                      }
                    >
                      {pay.map((item: any) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {expectedPayType === "Range" && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="minPay"
                        label="Min Pay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter min pay",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Min Pay" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Max Pay"
                        name="maxPay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter max pay.",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Max Pay" />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {expectedPayType === "Exact amount" && (
                  <Col span={12}>
                    <Form.Item
                      name="exactAmount"
                      label="Amount"
                      rules={[
                        { required: true, message: "Please enter the amount." },
                      ]}
                    >
                      <Input
                        placeholder="Enter amount"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedPayType === "Maximum" && (
                  <Col span={12}>
                    <Form.Item
                      name="maximumPay"
                      label="Max Pay"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the maximum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No more than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
                {expectedPayType === "Starting" && (
                  <Col span={12}>
                    <Form.Item
                      name="mininumPay"
                      label="Min Pay"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the minimum hours.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="No less than"
                        size="large"
                        className="conditionally-rendered-fields"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Item
                label="Hiring timeline for this job"
                name={"hiringTimeline"}
                rules={[
                  {
                    required: true,
                    message: "Please fill out this detail",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Hiring timeline for this job..."
                  notFoundContent={
                    <NoDataFoundIcon />
                  }
                >
                  {hiringTimeline.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Item label="Post deadline" name={"applicationDeadline"}>
                    <Radio.Group onChange={handleApplicationDeadlineChange}>
                      <Radio value={"yes"}>Yes</Radio>
                      <Radio value={"no"}>No</Radio>
                    </Radio.Group>
                  </Item>
                </Col>
                {isApplicationDeadline === "yes" && (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Item
                      label="Select a date"
                      name={"dealineDate"}
                      rules={[
                        { required: true, message: "Please select date" },
                      ]}
                    >
                      <DatePicker
                        className="job-posting-step-1-date-picker"
                        disabledDate={disabledDate}
                        onChange={(date, dateString) => setDate(date)}
                        value={date}
                      />
                    </Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Form.Item>
              <Button
                className="global-btn-width"
                type="primary"
                onClick={handleNext}
                size="large"
              >
                Next
              </Button>
            </Form.Item>
          </Row>
        </Form>
      ),
    },
    {
      title: "Job Description",
      content: (
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <div className="editor-container" key={jobDescriptionText.length}>
                <Item
                  label="Job Description"
                  name={"jobDescription"}
                  rules={[
                    {
                      required: true,
                      message: "Please add the job description",
                    },
                  ]}
                >
                  <ReactQuill
                    value={jobDescription}
                    theme="snow"
                    style={{ height: "350px" }}
                    onChange={(value: any) => setJobDescription(value)}
                    placeholder={jobDescriptionText}
                    onKeyDown={handleKeyDown}
                  />
                </Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Space size={"middle"}>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="default"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                >
                  Previous
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  onClick={handleNext}
                  size="large"
                >
                  Next
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Form>
      ),
    },
    {
      title: "Requirements",
      content: (
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <div className="editor-container">
                <Item
                  label="Requirements"
                  name={"requirements"}
                  rules={[
                    {
                      required: true,
                      message: "Please add requirements.",
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    style={{ height: "350px" }}
                    value={jobRequirements}
                    onChange={(value: any) => setJobRequirements(value)}
                  />
                </Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Space size={"middle"}>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="default"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                >
                  Previous
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  onClick={handleNext}
                  type="primary"
                  size="large"
                >
                  Next
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Form>
      ),
    },

    {
      title: "Applicants Screening",
      content: (
        <>
          <div className="predefined-questions-container">
            {predefinedQuestions.map((question) => (
              <Button
                key={question.id}
                onClick={() => handleSelectPredefinedQuestion(question.id)}
                disabled={selectedQuestions.some(
                  (q) => q.id === question.id && q.disabled
                )}
                type="primary"
                size="large"
                className="predefined-question-btn"
              >
                {question.label}
              </Button>
            ))}
          </div>

          <Row>
            <Col span={24} className="create-job-hide-and-show-container">
              <span
                className="create-job-hide-and-show-text"
                onClick={handleAdvanceSettingsClick}
              >
                <SettingOutlined /> Advance Settings
              </span>
            </Col>
          </Row>
          {showSettings && (
            <div className="hide-and-show-container">
              <div className="custom-questions-content">
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Input
                          value={customQuestionText}
                          onChange={(e) =>
                            setCustomQuestionText(e.target.value)
                          }
                          placeholder="Custom Question"
                          className="custom-question-input"
                          size="large"
                        />
                      </Col>
                    </Row>
                    <Row gutter={16} className="custom-questions-container">
                      <Col xxl={6} xl={6} lg={7} md={7} sm={24} xs={24}>
                        <Select
                          value={customQuestionType}
                          defaultValue="input"
                          placeholder="Select an option..."
                          onChange={setCustomQuestionType}
                          className="custom-question-select"
                          showSearch
                          notFoundContent={
                            <NoDataFoundIcon />
                          }
                        >
                          <Select.Option value="input">
                            Input Field
                          </Select.Option>
                          <Select.Option value="radio">
                            Radio Buttons
                          </Select.Option>
                          <Select.Option value="select">
                            Select Field
                          </Select.Option>
                          <Select.Option value="textarea">
                            Text Area
                          </Select.Option>
                        </Select>
                      </Col>
                      {customQuestionType === "radio" ||
                      customQuestionType === "select" ? (
                        <Col xxl={18} xl={16} lg={10} md={10} sm={24} xs={24}>
                          <Select
                            mode="tags"
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Add options"
                            value={customQuestionOptions}
                            onChange={setCustomQuestionOptions}
                            tokenSeparators={[","]}
                          />
                        </Col>
                      ) : null}
                      <Col xxl={6} xl={6} lg={7} md={7} sm={24} xs={24}>
                        <Button
                          onClick={handleAddCustomQuestion}
                          size="large"
                          type="default"
                        >
                          Add Custom Question
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <div>
                <div>
                  <span className="job-posting-questions-heading">
                    {" "}
                    Screening Questions
                  </span>
                </div>
                <div>
                  <Row gutter={16}>
                    {selectedQuestions.map((question, key) => (
                      <Col span={24}>
                        {key > 0 && <Divider />}
                        <div className="job-posting-step-4-selected-questions">
                          <div key={question.id}>
                            <Row>
                              <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={18}
                                xl={18}
                                xxl={18}
                              >
                                <div className="question-heading">
                                  <span className="question-key-style">
                                    Q{key + 1}:
                                  </span>{" "}
                                  {question.label}
                                </div>
                                <div className="question-points">
                                  {(question.inputType === "radio" ||
                                    question.inputType === "select") &&
                                    question.options && (
                                      <ul>
                                        {question.options.map(
                                          (option: any, index: any) => (
                                            <li key={index}>{option}</li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                </div>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}
                                xxl={6}
                                className="job-post-selected-questions-checkbox-container"
                              >
                                <Button
                                  className="global-default-btn  job-post-step-4-remove-btn"
                                  onClick={() =>
                                    handleRemoveQuestion(question.id)
                                  }
                                >
                                  Remove
                                </Button>

                                <Checkbox
                                  checked={question.required}
                                  onChange={(e) =>
                                    handleRequiredChange(
                                      question.id,
                                      e.target.checked
                                    )
                                  }
                                >
                                  Required
                                </Checkbox>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          )}

          <Row gutter={16} className="global-modal-bottom-row next-btn-step-1">
            <Space size={"middle"}>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="default"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  size="large"
                >
                  Previous
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="global-btn-width"
                  type="primary"
                  onClick={collectAndSubmitFormData}
                  size="large"
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </>
      ),
    },
  ];
  return (
    <div className="global-card-inner-container">
      <Steps current={currentStep}>
        {steps.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={`Step ${index + 1}`}
          />
        ))}
      </Steps>
      <div className="global-steps-container-margin">
        {steps[currentStep].content}
      </div>
      {showAlert && <CustomAlert showAlert={showAlert} type={alertType} />}
    </div>
  );
};

export default CreateJobSteps;
