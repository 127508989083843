import { Modal, Space, Empty, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import "./index.css";
import { fetchSalaryProveConfigurationApi } from "../../services";
import { EditOutlined } from "@ant-design/icons";
import { SortOrder } from "antd/lib/table/interface";

import CustomAlert from "../../../../common/Alert";
import SalaryProveConfigurationsEdit from "../Edit";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import {
  filterLogicHandler,
  searchLogicHandlerForConfigurations,
} from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import SalaryProveConfigurationsFilterModal from "../Filter";

interface SalaryProveConfigurationsListProps {
  searchValue?: string;
  openFilterModal?: boolean;
  toggleFilterModal?: () => void;
}

const SalaryProveConfigurationsListView: React.FC<
  SalaryProveConfigurationsListProps
> = ({ searchValue, openFilterModal, toggleFilterModal }) => {
  const logedInemployee = useSelector(selectLogedEmployee).logedInemployee;
  const [showAlert, setshowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<
    "success" | "info" | "warning" | "error"
  >("info");
  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRecord, setSelectedRecord] = useState<any>("");
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const organizationId = logedInemployee?.employee?.organization_id;

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Payroll",
    "Payroll Configuration"
  );

  const edit = access?.writeAccess;
  const read = access?.readAccess;

  useEffect(() => {
    fetchsalaryProveConfiguration();
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setshowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const fetchsalaryProveConfiguration = async () => {
    setTableLoader(true);
    const data = {
      organization_Id: organizationId,
      company_id: logedInemployee?.employee.company_id,
      branch_id: logedInemployee?.employee.branch_id,
    };
    const res = await fetchSalaryProveConfigurationApi(data);

    if (res.data) {
      const transformedData = transformTableData(res.data);
      setTableData(transformedData);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: transformedData,
      }));
    }
    setTableLoader(false);
  };

  const transformTableData = (data: any[]) => {
    const groupedData: any = {};
    data.forEach((item) => {
      const key = `${item.company?.name}_${item.branch?.name || "null"}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          company: item.company,
          branch: item.branch,
          branch_id: item.branch_id,
          company_id: item.company_id,
          employees: [],
        };
      }
      (item.employee as any).id = item.id;
      (item.employee as any).employee_id = item.employee_id;
      groupedData[key].employees.push(item.employee);
    });

    return Object.values(groupedData);
  };

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      setTableData(originalData.apiData);
    }
  }, [searchValue]);

  const handleSearch = (value: any) => {
    setTableLoader(true);
    let filterData: any;

    filterData = searchLogicHandlerForConfigurations(
      value,
      originalData,
      "employees",
      "full_name"
    );
    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    setTableData(filterData);
    setTableLoader(false);
  };

  const getFilterDataHandler = (full_name: string) => {
    const filterArray = [{ path: "Employees.full_name", value: full_name }];
    let response: any;

    response = filterLogicHandler(filterArray, originalData);
    if (response) {
      setOriginalData((prevState: any) => ({
        ...prevState,
        filteredData: response,
      }));

      setTableData(response);
    }
  };

  const columns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (record: any) => record?.name,
      sorter: (a: any, b: any) => {
        const nameA = a?.company?.name || "";
        const nameB = b?.company?.name || "";
        return nameA.localeCompare(nameB);
      },
      sortDirections: ["ascend", "descend"] as SortOrder[],
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (record: any) => record?.name,
    },
    {
      title: "Employees",
      key: "employees",
      render: (record: any) => (
        <Space size="small">
          {record.employees?.map((employee: any) => (
            <Tag key={employee.id}>{employee.full_name}</Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          {edit && (
            <a>
              <EditOutlined onClick={() => handleEdit(record)} />
            </a>
          )}
        </Space>
      ),
    },
  ];

  const handleEdit = (record: any) => {
    setSelectedRecord(record);
    setShowEditModel(true);
  };
  const handelCancel = () => {
    setShowEditModel(false);
    fetchsalaryProveConfiguration();
  };

  return (
    <>
      <div>
        {read ? (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            loading={tableLoader}
            columns={columns}
            rowKey="id"
          />
        ) : (
          <Empty
            description="Not Authorized For This Action"
            image={Empty.PRESENTED_IMAGE_DEFAULT}
          />
        )}
      </div>

      <Modal
        className="global-modal-main-container"
        title={<span className="global-modal-title">Filter</span>}
        open={openFilterModal}
        footer={false}
        onCancel={toggleFilterModal}
      >
        <SalaryProveConfigurationsFilterModal
          getFilterDataHandler={(full_name) => getFilterDataHandler(full_name)}
        />
      </Modal>

      {selectedRecord && showEditModel && (
        <Modal
          open={showEditModel}
          onCancel={handelCancel}
          width={1000}
          footer={false}
          title={<span className="global-modal-title">Edit Details</span>}
          className="global-modal-main-container"
        >
          <SalaryProveConfigurationsEdit
            record={selectedRecord}
            onCancel={handelCancel}
          />
        </Modal>
      )}

      {showAlert && <CustomAlert type={alertType} showAlert={showAlert} />}
    </>
  );
};

export default SalaryProveConfigurationsListView;
