import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Input, Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../../redux/EmployeeProfileSlice";
import { getEmployeeAllowanceHistoryDetailsApi } from "../services";
import searchIcon from "../../../../assets/images/search-icon.png";

import { searchLogicHandler } from "../../../../common/SearchAndFilterFunction";
import CustomTable from "../../../../common/CustomTable";
import { FiPlus } from "react-icons/fi";
import AllowanceClaimRequestCreateView from "../Create";
import { selectAccess } from "../../../../../redux/AccessRight";
import { extractAccessRightObject } from "../../../../common/ExtractAccessRightObj";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const AllowanceClaimRequestListView: React.FC = () => {
  const loggedInEmployee = useSelector(selectLogedEmployee).logedInemployee;
  const organizationId = loggedInEmployee?.employee?.organization_id;
  const employeeRole =
    useSelector(selectLogedEmployee).logedInemployee?.employeeRole;
  const [tableData, seTableData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<any>({
    apiData: [],
    filteredData: [],
    searchedData: [],
  });

  const accessObj = useSelector(selectAccess);

  const access = extractAccessRightObject(
    accessObj,
    "Requests",
    "Allowance Request"
  );

  const create = access?.createAccess;
  const read = access?.readAccess;

  useEffect(() => {
    getEmployeeAllowanceHistory();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      seTableData(originalData.apiData);
    }
  }, [searchQuery]);

  const getEmployeeAllowanceHistory = async () => {
    setLoadingTable(true);
    setSearchQuery("");
    const res = await getEmployeeAllowanceHistoryDetailsApi({
      employeeId: loggedInEmployee?.employeeId,
      organizationId: organizationId,
    });

    if (res.data) {
      const temptabledata = groupDataByMonth(res.data);

      seTableData(temptabledata);
      setOriginalData((prevState: any) => ({
        ...prevState,
        apiData: temptabledata,
      }));
    }
    setLoadingTable(false);
  };

  const groupDataByMonth = (data: any) => {
    const groupedData: any = {};
    data.forEach((item: any) => {
      const date = new Date(item.created_at);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!groupedData[monthYear]) {
        groupedData[monthYear] = [];
      }

      groupedData[monthYear].push({
        requestedAt: `${day}-${month} ${hours}:${minutes}`,
        allowanceName: item.name,
        amountToClaim: item.amount_to_claim,
        status: item.status,
      });
    });

    return Object.keys(groupedData).map((month) => ({
      month: month,
      allowanceDetails: groupedData[month],
    }));
  };

  const tableColumns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
  ];

  const expandedRowRender = (tableData: any) => {
    const columns = [
      {
        title: "Allowance Name",
        dataIndex: "allowanceName",
        key: "allowanceName",
      },
      {
        title: "Claim Amount",
        dataIndex: "amountToClaim",
        key: "amount_to_claim",
      },
      {
        title: "Requested At",
        dataIndex: "requestedAt",
        key: "requestedAt",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    ];

    return (
      <>
        <CustomTable
          dataSource={tableData.allowanceDetails}
          columns={columns}
          rowKey="month"
        />
      </>
    );
  };

  const handleSearch = (value: any) => {
    setLoadingTable(true);

    setSearchQuery(value);
    let filterData: any;

    filterData = searchLogicHandler(value, originalData, "month");

    setOriginalData((prevState: any) => ({
      ...prevState,
      searchedData: filterData,
    }));
    seTableData(filterData);

    setLoadingTable(false);
  };

  const onCreateClick = () => {
    setCreateModalVisible(true);
  };
  const handleCreateModalCancel = () => {
    setCreateModalVisible(false);
    getEmployeeAllowanceHistory();
  };
  const onSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <>
      <div
        className={`${
          employeeRole === "employee"
            ? ""
            : "global-request-container-styling global-requests-container"
        }`}
      >
        {employeeRole !== "employee" && (
          <div className="allowance-request-header">
            <span className="allowance-request-heading">
              Allowance Claim Request
            </span>
            <div className="allowance-request-input-header">
              <Input
                className="global-input-number-width"
                size="large"
                placeholder="Search"
                prefix={<img src={searchIcon} alt="Search icon" />}
                onChange={(e: any) => {
                  onSearchChange(e.target.value);
                }}
              />
              {create && (
                <Button
                  size="large"
                  type="primary"
                  onClick={onCreateClick}
                  icon={<FiPlus className="tab-header-icon" />}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        )}
        {read && (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={tableColumns}
            loading={loadingTable}
            rowKey="month"
            expandable={{
              expandedRowRender,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        )}
        {employeeRole === "employee" && (
          <CustomTable
            scroll={{ x: true }}
            dataSource={tableData}
            columns={tableColumns}
            loading={loadingTable}
            rowKey="month"
            expandable={{
              expandedRowRender,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a onClick={(e) => onExpand(record, e)}>
                    <MinusOutlined />
                  </a>
                ) : (
                  <a onClick={(e) => onExpand(record, e)}>
                    <PlusOutlined />
                  </a>
                ),
            }}
          />
        )}
        <Modal
          title={
            <span className="global-modal-title">Request Allowance Claim</span>
          }
          open={createModalVisible}
          onCancel={handleCreateModalCancel}
          width={1000}
          footer={false}
          destroyOnClose
          className="global-modal-main-container"
        >
          <AllowanceClaimRequestCreateView />
        </Modal>
      </div>
    </>
  );
};

export default AllowanceClaimRequestListView;
