import moment from "moment";
import {
  breakInUpdate,
  breakOutUpdate,
  getTheCheckInTimeForToday,
  insertAttendancePunchApi,
} from "./services";

//Determines whether its a Holiday or not as per the Company's policy.
export const determineWetherItsAHoliday = async (data: any): Promise<any> => {
  const allWorkingDays = data;

  const currentShortDay = moment().format("ddd");

  if (allWorkingDays?.hasOwnProperty(currentShortDay)) {
    if (allWorkingDays[currentShortDay]) {
      return true;
    } else {
      return false;
    }
  }
};

//Performs most of the logic required to handle an Employee's Checkin.
export const handleCheckInComputations = async (
  employeeId: string | number | any,
  organizationId: string | number | any,
  companyId: string | number | any,
  checkInTime: string,
  timeZone: string | null,
  branchId: string | number | any
): Promise<any> => {
  const tz = timeZone || moment.tz.guess() || "Asia/Karachi";
  const now = moment().tz(tz);
  const nowString = now.format("YYYY-MM-DD HH:mm:ss");
  const todaysDateAsPerTimeZone = moment().tz(tz).format("YYYY-MM-DD");

  if (!checkInTime) {
    return {
      timeDifference: "00:00",
      status: "punched",
      currentTimeStamp: nowString,
      employeeId: employeeId,
      organizationId: organizationId,
      companyId: companyId,
      date: todaysDateAsPerTimeZone,
    };
  }

  const [expectedHour, expectedMinute] = checkInTime.split(":").map(Number);
  const expectedCheckInTime = moment.tz(
    { hour: expectedHour, minute: expectedMinute },
    tz
  );
  const timeDifference = now.diff(expectedCheckInTime);
  let status =
    timeDifference === 0 ? "on time" : timeDifference > 0 ? "late" : "early";

  let formattedTimeDifference = "";
  if (status === "late") {
    const timeDifferenceInMinutes = Math.abs(timeDifference) / (1000 * 60);
    const hours = Math.floor(timeDifferenceInMinutes / 60);
    const minutes = Math.floor(timeDifferenceInMinutes % 60);
    formattedTimeDifference = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  return {
    timeDifference: formattedTimeDifference,
    status,
    currentTimeStamp: now.format("YYYY-MM-DD HH:mm:ss"),
    employeeId: employeeId,
    organizationId: organizationId,
    companyId: companyId,
    branchId: branchId,
    date: todaysDateAsPerTimeZone,
    fetch_from: "Portal",
  };
};

//This function handles the checkout
export const handleCheckoutComputations = async (
  employeeId: any,
  timezone: any,
  expectedCheckoutTime: any,
  organizationId: string
): Promise<any> => {
  const tz = timezone || moment.tz.guess() || "Asia/Karachi";
  const response = await getTheCheckInTimeForToday(
    employeeId,
    tz,
    organizationId
  );

  const todayCheckInTime = response?.data[0]?.check_in;

  const recordId = response?.data[0]?.id;
  const checkInShortHours = response?.data[0]?.short_working_hours;
  if (!todayCheckInTime) {
    return false;
  }
  const formatDateToISO = (date: any) => {
    return new Date(date).toLocaleString("sv-SE", { timeZone: tz });
  };
  const checkinTime: any = new Date(todayCheckInTime);
  const checkoutTime: any = new Date();
  const formattedCheckoutTime = formatDateToISO(checkoutTime);

  let shortHours = "";
  if (checkInShortHours !== null && checkInShortHours !== undefined) {
    shortHours = checkInShortHours;
  }
  if (!expectedCheckoutTime) {
    return {
      status: "punched",

      checkoutTime: formattedCheckoutTime,
      recordId: recordId,
      shortHours: shortHours,
    };
  }

  const [expectedHour, expectedMinute] = expectedCheckoutTime
    .split(":")
    .map(Number);
  const expectedCheckoutDate: any = new Date(checkinTime);
  expectedCheckoutDate.setHours(expectedHour, expectedMinute, 0);

  let status = "on time";
  let timeDifferenceMs = checkoutTime - expectedCheckoutDate;

  if (timeDifferenceMs > 0) {
    status = "late";
  } else if (timeDifferenceMs < 0) {
    status = "early";
    timeDifferenceMs = Math.abs(timeDifferenceMs);
    if (shortHours !== "") {
      expectedCheckoutDate.setHours(
        expectedCheckoutDate.getHours() + parseInt(shortHours.split(":")[0]),
        expectedCheckoutDate.getMinutes() + parseInt(shortHours.split(":")[1]),
        0
      );
    }
  }

  const timeDifferenceHours = Math.floor(timeDifferenceMs / 3600000);
  const timeDifferenceMinutes = Math.floor(
    (timeDifferenceMs % 3600000) / 60000
  );
  const timeDifference = `${timeDifferenceHours
    .toString()
    .padStart(2, "0")}:${timeDifferenceMinutes.toString().padStart(2, "0")}`;

  return {
    status,

    timeDifference: timeDifference,
    checkoutTime: formattedCheckoutTime,
    recordId: recordId,
    shortHours: shortHours,
  };
};

//This function handles the breakin logic
export const handleBreakInLogic = async (
  employeeId: any,
  timezone: any,
  organizationId: string
): Promise<any> => {
  const tz = timezone || moment.tz.guess() || "Asia/Karachi";
  const response = await getTheCheckInTimeForToday(
    employeeId,
    tz,
    organizationId
  );

  if (!response) {
    return false;
  }

  const recordId = response?.data[0]?.id;
  const checkIn = response?.data[0]?.check_in;
  const breakIn = response?.data[0]?.break_in || [];
  const breakOut = response?.data[0]?.break_out || [];

  if (breakIn.length !== breakOut.length) {
    return false;
  }

  const now = moment.tz(tz).format("HH:mm");

  const arrayOfBreakIns = response?.data[0]?.break_in || [];
  arrayOfBreakIns.push({ break_in: now });
  if (checkIn) {
    const updateBreakIn = breakInUpdate(
      arrayOfBreakIns,
      recordId,
      organizationId
    );
    return updateBreakIn;
  } else {
    return false;
  }
};

//This function handles the breakout logic
export const handleBreakOutLogic = async (
  employeeId: any,
  timezone: any,
  organizationId: string
): Promise<any> => {
  const tz = timezone || moment.tz.guess() || "Asia/Karachi";
  const response = await getTheCheckInTimeForToday(
    employeeId,
    tz,
    organizationId
  );

  if (!response) {
    return false;
  }

  const recordId = response?.data[0]?.id;
  const checkIn = response?.data[0]?.check_in;
  const breakIn = response?.data[0]?.break_in || [];
  const breakOut = response?.data[0]?.break_out || [];

  if (!checkIn || breakIn.length === 0) {
    return false;
  }

  if (breakIn.length === breakOut.length) {
    return false;
  }

  const now = moment.tz(tz).format("HH:mm");

  const arrayOfBreakIns = response?.data[0]?.break_out || [];
  arrayOfBreakIns.push({ break_out: now });
  if (checkIn) {
    const updateBreakIn = breakOutUpdate(
      arrayOfBreakIns,
      recordId,
      organizationId
    );
    return updateBreakIn;
  } else {
    return false;
  }
};
 