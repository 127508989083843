import React, { useEffect, useState } from "react";
import "./index.css";
import { Space } from "antd";
import {
  fetchEmployeeAddressesRecordsApi,
  fetchEmployeeEmergencyRecordsApi,
  fetchEmployeeRecordsApi,
} from "../../../components/EmployeesManagement/EmployeeViewDetails/services";
import { useSelector } from "react-redux";
import { selectLogedEmployee } from "../../../../redux/EmployeeProfileSlice";

interface ContactDetailsProps {
  employeeId: any;
  loadingState: (state: boolean) => void;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  employeeId,
  loadingState,
}) => {
  const loggedInEmployee = useSelector(selectLogedEmployee);
  const [employeeData, setEmployeeData] = useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [emergencyContactData, setEmergencyContactData] = useState<any>(null);
  const organizationId =
    loggedInEmployee.logedInemployee?.employee?.organization_id;

  useEffect(() => {
    fetchEmployee();
    fetchAddressesData();
    fetchEmergencyContactData();
  }, []);

  const fetchEmployee = async () => {
    loadingState(true);
    const employeeRes = await fetchEmployeeRecordsApi(
      employeeId,
      organizationId
    );
    loadingState(false);
    if (employeeRes.data) {
      setEmployeeData(employeeRes.data);
    }
  };

  const fetchAddressesData = async () => {
    loadingState(true);
    const addressesRes = await fetchEmployeeAddressesRecordsApi(
      organizationId,
      employeeId
    );
    loadingState(false);
    if (addressesRes.data) {
      setAddressData(addressesRes.data.present_address_detail);
    }
  };

  const fetchEmergencyContactData = async () => {
    loadingState(true);
    const emergencyContactRes = await fetchEmployeeEmergencyRecordsApi(
      employeeId,
      organizationId
    );
    loadingState(false);
    if (emergencyContactRes.data) {
      setEmergencyContactData(emergencyContactRes.data);
    }
  };

  return (
    <div className="cd-container">
      {employeeData && (
        <>
          {employeeData.phone_number && (
            <div className="cd-text-container">
              <span className="cd-text">Mobile Number: </span>
              <span className="cd-text-value">{employeeData.phone_number}</span>
            </div>
          )}
          {employeeData.work_phone_number && (
            <div className="cd-text-container">
              <span className="cd-text">Work Phone: </span>
              <span className="cd-text-value">
                {employeeData.work_phone_number}
              </span>
            </div>
          )}
          {addressData && (
            <>
              {addressData.present_country && (
                <div className="cd-text-container">
                  <span className="cd-text">Country: </span>
                  <span className="cd-text-value">
                    {addressData.present_country}
                  </span>
                </div>
              )}
              {addressData.present_state && (
                <div className="cd-text-container">
                  <span className="cd-text">State: </span>
                  <span className="cd-text-value">
                    {addressData.present_state}
                  </span>
                </div>
              )}
              {addressData.present_city && (
                <div className="cd-text-container">
                  <span className="cd-text">City: </span>
                  <span className="cd-text-value">
                    {addressData.present_city}
                  </span>
                </div>
              )}
              {addressData.present_street && (
                <div className="cd-text-container">
                  <span className="cd-text">Street: </span>
                  <span className="cd-text-value">
                    {addressData.present_street}
                  </span>
                </div>
              )}
              {addressData.present_postcode && (
                <div className="cd-text-container">
                  <span className="cd-text">Post Code: </span>
                  <span className="cd-text-value">
                    {addressData.present_postcode}
                  </span>
                </div>
              )}
            </>
          )}
          {employeeData.work_email && (
            <div className="cd-text-container">
              <span className="cd-text">Work Email: </span>
              <span className="cd-text-value">{employeeData.work_email}</span>
            </div>
          )}
          {employeeData.other_email && (
            <div className="cd-text-container">
              <span className="cd-text">Other Email: </span>
              <span className="cd-text-value">{employeeData.other_email}</span>
            </div>
          )}
        </>
      )}
      {emergencyContactData && (
        <>
          <strong className="cd-text-bold">Emergency Contact</strong>
          {emergencyContactData.relative_name && (
            <div className="cd-text-container">
              <span className="cd-text">Relative Name: </span>
              <span className="cd-text-value">
                {emergencyContactData.relative_name}
              </span>
            </div>
          )}
          {emergencyContactData.relationship && (
            <div className="cd-text-container">
              <span className="cd-text">Relationship: </span>
              <span className="cd-text-value">
                {emergencyContactData.relationship}
              </span>
            </div>
          )}
          {emergencyContactData.home_telephone && (
            <div className="cd-text-container">
              <span className="cd-text">Home Telephone: </span>
              <span className="cd-text-value">
                {emergencyContactData.home_telephone}
              </span>
            </div>
          )}
          {emergencyContactData.email && (
            <div className="cd-text-container">
              <span className="cd-text">Email: </span>
              <span className="cd-text-value">
                {emergencyContactData.email}
              </span>
            </div>
          )}
          {emergencyContactData.street_address && (
            <div className="cd-text-container">
              <span className="cd-text">Street Address: </span>
              <span className="cd-text-value">
                {emergencyContactData.street_address}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContactDetails;
