import React from "react";
import PayrollTaxRebatesList from "./View";

interface TaxRebatesProps {
  searchValue: string;
}
const TaxRebates: React.FC<TaxRebatesProps> = ({ searchValue }) => {
  return (
    <div>
      <PayrollTaxRebatesList searchValue={searchValue} />
    </div>
  );
};

export default TaxRebates;
